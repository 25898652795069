import React from 'react';

import DefaultAuthPage from '../../_components/DefaultAuthPage';
import ResetPasswordRequestForm from '../_components/RequestResetPasswordForm';

const ResetPasswordPage = () => (
  <DefaultAuthPage captionId="authResetPassword" descriptionId="authResetPasswordDescr">
    <ResetPasswordRequestForm />
  </DefaultAuthPage>
);

export default ResetPasswordPage;
