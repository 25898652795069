import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CRMSelect {
    position: absolute;
    top: 100%;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    .CRMSelect__Item {
      display: flex;
      padding: 5px 7px 5px 7px;
      color: #0f0f0f;
      align-items: center;

      > span {
        font-size: 12px;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: left;
      }

      svg {
        padding: 12px 7px;
      }

      :hover {
        background: #e9f6fc;
        border: 1px solid #0aa1e2;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
