import React from 'react';
import PropTypes from 'prop-types';

const FeedTask = ({ description }) => <div className="TabLogs__item-description">{description}</div>;

FeedTask.propTypes = {
  description: PropTypes.string.isRequired,
};

export default FeedTask;
