import React from 'react';
import css from 'styled-jsx/css';

const staticStyle = css`
  .DeclineIcon {
    border-radius: 8px;
    border: 1px solid #ee3235;
    display: flex;
    width: 32px;
    height: 32px;
    background: #ee3235;
    color: white;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
    cursor: pointer;
  }
`;

const DeclineIcon = () => (
  <div className="DeclineIcon">
    X<style jsx>{staticStyle}</style>
  </div>
);

export default DeclineIcon;
