import { put, call, fork, take } from 'redux-saga/effects';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getStatusesListSaga() {
  try {
    const { data } = yield call(api.getStatusesList);
    yield put(ActionTypes.getStatusesList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStatusesList.failure());
  }
}

export function* getStatusesListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_STATUSES_LIST[REQUEST]);
    yield call(getStatusesListSaga);
  }
}

export function* getStatusInfoSaga({ id }) {
  try {
    const { data } = yield call(api.getStatusInfo, id);
    yield put(ActionTypes.getStatusInfo.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStatusInfo.failure());
  }
}

export function* getStatusInfoWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_STATUS_INFO[REQUEST]);
    yield call(getStatusInfoSaga, payload);
  }
}

export function* getStatusDescriptionSaga() {
  try {
    const { data } = yield call(api.getStatusDescription);
    yield put(ActionTypes.getStatusDescription.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStatusDescription.failure());
  }
}

export function* getStatusDescriptioWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_STATUS_DESCRIPTION[REQUEST]);
    yield call(getStatusDescriptionSaga);
  }
}

export default [fork(getStatusesListWatcherSaga), fork(getStatusInfoWatcherSaga), fork(getStatusDescriptioWatcherSaga)];
