import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  files: {},
  filesAreLoaded: false,

  file: {},
  fileIsLoaded: false,

  fileExtensions: {},

  error: '',
  deleteFileError: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FILES[REQUEST]:
      return { ...state, filesAreLoaded: false };
    case ActionTypes.GET_FILES[SUCCESS]:
      return { ...state, files: action.data, filesAreLoaded: true };
    case ActionTypes.GET_FILES[FAILURE]:
      return { ...state, error: action.error, filesAreLoaded: false };

    case ActionTypes.GET_FILE[REQUEST]:
      return {
        ...state,
        fileIsLoaded: false,
      };
    case ActionTypes.GET_FILE[SUCCESS]:
      return { ...state, file: { ...state.file, ...action.data }, fileIsLoaded: true };
    case ActionTypes.GET_FILE[FAILURE]:
      return { ...state, error: action.error, fileIsLoaded: false };

    case ActionTypes.GET_FILE_EXTENSIONS[REQUEST]:
      return { ...state };
    case ActionTypes.GET_FILE_EXTENSIONS[SUCCESS]:
      return { ...state, fileExtensions: action.data };
    case ActionTypes.GET_FILE_EXTENSIONS[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.createFile[REQUEST]:
      return {
        ...state,
        fileIsLoaded: false,
      };
    case ActionTypes.createFile[SUCCESS]:
      return { ...state, file: action.payload, fileIsLoaded: true };
    case ActionTypes.createFile[FAILURE]:
      return { ...state, error: action.error, fileIsLoaded: false };

    case ActionTypes.uploadFiles[REQUEST]:
      return {
        ...state,
        filesAreLoaded: false,
      };
    case ActionTypes.uploadFiles[SUCCESS]:
      return { ...state, files: action.payload, filesAreLoaded: true };
    case ActionTypes.uploadFiles[FAILURE]:
      return { ...state, error: action.error, filesAreLoaded: false };

    case ActionTypes.DELETE_FILE[REQUEST]:
      return { ...state };
    case ActionTypes.DELETE_FILE[SUCCESS]:
      return { ...state };
    case ActionTypes.DELETE_FILE[FAILURE]:
      return { ...state, deleteFileError: action.error };

    case ActionTypes.CLOSE_ERROR_MODAL:
      return { ...state, deleteFileError: '' };

    default:
      return state;
  }
};

export default reducer;
