import { DefaultButton } from 'common/components';
import React from 'react';

import { staticStyles } from './style';

const BudgetSelector = ({ data, onChange, selectedChallengeId }) => (
  <div className="BudgetSelector">
    <div className="BudgetSelector__ChooseBudgetText">Choose budget:</div>
    <div className="BudgetSelector__Values">
      {data.map(({ id, initialAccountBalance }) => (
        <DefaultButton
          key={`${id} BudgetSelector`}
          selected={selectedChallengeId === id}
          textId={initialAccountBalance}
          noTranslate
          onClick={() => onChange(id)}
        />
      ))}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default BudgetSelector;
