import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const EllipsisIcon = ({ grayColor }) => (
  <svg width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 14.4226C0.895431 14.4226 5.13818e-07 15.3312 4.15834e-07 16.452C3.1785e-07 17.5728 0.895431 18.4814 2 18.4814C3.10457 18.4814 4 17.5728 4 16.452C4 15.3312 3.10457 14.4226 2 14.4226Z"
      fill={grayColor.hex}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.21119C0.895431 7.21119 5.13826e-07 8.11979 4.15839e-07 9.24061C3.17853e-07 10.3614 0.895431 11.27 2 11.27C3.10457 11.27 4 10.3614 4 9.24061C4 8.11979 3.10457 7.21119 2 7.21119Z"
      fill={grayColor.hex}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 -0.000232871C0.895431 -0.000232968 5.13818e-07 0.908366 4.15834e-07 2.02918C3.1785e-07 3.14999 0.895431 4.05859 2 4.05859C3.10457 4.05859 4 3.14999 4 2.02918C4 0.908367 3.10457 -0.000232775 2 -0.000232871Z"
      fill={grayColor.hex}
    />
  </svg>
);

EllipsisIcon.propTypes = {
  grayColor: colorType,
};

EllipsisIcon.defaultProps = {
  grayColor: {},
};

export default withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(EllipsisIcon);
