import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { ReactDataTable, Collapsible, StatusCell, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const TaskEditHistory = ({ taskEditHistory, intl, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const renderField = (key, value) => {
    switch (key) {
      case 'title':
        return intl.formatMessage({ id: 'taskTitleUpdated' });
      case 'expiration_date':
        return intl.formatMessage({ id: 'taskExpirationTimeChanged' });
      case 'icon':
        return intl.formatMessage({ id: 'taskIconChanged' });
      case 'status':
        return (
          <span>
            {intl.formatMessage({ id: 'taskStatusChanged' })} <StatusCell statusCode={value.new} noDot />
          </span>
        );
      case 'subject':
        return intl.formatMessage({ id: 'taskSubjectReassigned' }, { userFrom: value.old, userTo: value.new });
      case 'assigned_to':
        return intl.formatMessage({ id: 'taskReassigned' }, { userFrom: value.old, userTo: value.new });
      default:
        return intl.formatMessage({ id: 'taskTextUpdated' });
    }
  };

  const renderComment = data =>
    Object.entries(data).map(([key, value]) => (
      <li className="TaskEditHistory__comment-item" key={key}>
        {renderField(key, value)}
      </li>
    ));

  /* eslint-disable */
  const columns = [
    {
      Header: 'justDate',
      accessor: 'date',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      Header: 'justAuthor',
      accessor: 'author',
      Cell: ({
        row: {
          original: { actor },
        },
      }) => (
        <span className="TaskEditHistory__author">
          {actor.firstName} {actor.lastName}
        </span>
      ),
      isPreviewCell: true,
    },
    {
      Header: 'justComment',
      accessor: 'comment',
      Cell: ({
        row: {
          original: { editData },
        },
      }) => <ul className="TaskEditHistory__comments">{renderComment(editData)}</ul>,
    },
  ];
  /* eslint-enable */

  return (
    <div className="TaskEditHistory">
      <Collapsible captionId="justTaskEditHistory">
        <ReactDataTable data={taskEditHistory} columns={columns} />
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TaskEditHistory.propTypes = {
  taskEditHistory: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

TaskEditHistory.defaultProps = {
  theme: {},
};

export default withTheme()(injectIntl(TaskEditHistory));
