import React, { useEffect } from 'react';

import bg from './bg.png';
// import logo from './logo.svg';

const BlockedBrokerPage = () => {
  useEffect(() => {
    document.body.style.margin = 0;
  });

  return (
    <div className="BlockedBrokerPage">
      {/* TODO: Показывать тут лого брокера */}
      {/* <div className="BlockedBrokerPage__logo">
        <img src={logo} alt="Uptrader" />
      </div> */}
      <div className="BlockedBrokerPage__text">This service is disabled now.</div>
      <style jsx>
        {`
          @import 'src/common/styling/_mixins-scss/font-face';

          @include font-face('ubuntu-font', '/fonts/UbuntuRegular/UbuntuRegular');

          .BlockedBrokerPage {
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: url(${bg}) center / cover no-repeat;

            &__logo {
              position: relative;

              &::after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #dadada;
                display: block;
                margin: 25px 0;
              }
            }

            &__text {
              font-family: 'ubuntu-font' !important;
              font-size: 20px;
              line-height: 20px;
              text-align: center;
              color: #ffffff;
            }
          }
        `}
      </style>
    </div>
  );
};

export default BlockedBrokerPage;
