import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BonusSettingsPanel {
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    padding: 25px 30px;
    @include respond-to(sm) {
      padding: 20px 15px 1px;
    }

    .BonusSettingsPanel__caption {
      font-weight: 300;
      font-size: rem(24px);
      margin-bottom: rem(20px);
    }

    .BonusSettingsPanel__fields {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      :global(> div) {
        flex: 1;
        min-width: 250px;
        margin-right: rem(${isRTL ? '0' : '20px'});
        margin-left: rem(${isRTL ? '20px' : '0'});
      }

      &:last-child {
        font-size: rem(13px);
        line-height: rem(18px);
      }
    }

    .BonusSettingsPanel__conversion-fields {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-switch,
      &-text {
        display: flex;
      }

      &-switch {
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 48px;
      }

      .BonusSettingsPanel__conversion {
        display: flex;
        margin-right: rem(${isRTL ? '0' : '10px'});
        margin-left: rem(${isRTL ? '10px' : '0'});

        span {
          font-size: rem(15px);
          margin-right: rem(${isRTL ? '0' : '20px'});
          margin-left: rem(${isRTL ? '20px' : '0'});
        }
      }

      .BonusSettingsPanel__requireFunds {
        display: flex;
        margin-right: rem(${isRTL ? '0' : '15px'});
        margin-left: rem(${isRTL ? '15px' : '0'});

        span {
          font-size: rem(15px);
          margin-right: rem(${isRTL ? '0' : '20px'});
          margin-left: rem(${isRTL ? '20px' : '0'});
        }
      }

      :global(.ReduxTextField, .ReduxSelectField) {
        flex: 1;
        min-width: 250px;
        margin-right: rem(${isRTL ? '0' : '20px'});
        margin-left: rem(${isRTL ? '20px' : '0'});
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .BonusSettingsPanel {
      background: ${colors.white.hex};

      .BonusSettingsPanel__fields {
        color: ${colors.gray.hex};
      }

      .BonusSettingsPanel__convertation {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
