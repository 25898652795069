import css from 'styled-jsx/css';
import { isRTL, gradient } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserTransactionsHistory {
    :global(.Collapsible) {
      background: #ffffff;
      margin-bottom: 20px;

      :global(.ReactTable) {
        :global(.rt-thead.-header) {
          min-width: 600px !important;
          :global(.rt-tr) {
            margin: 0 28px;
            @include respond-to(md) {
              margin: 0 10px;
            }

            :global(.rt-th) {
              flex: 56 !important;
              text-align: center;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                flex: 30 !important;
                text-align: left;
              }
            }
          }
        }

        :global(.rt-tbody) {
          min-width: 600px !important;
          :global(.rt-tr-group) {
            margin: 0 0 2px 0;
            cursor: default;
            :global(.rt-tr) {
              padding: rem(10px 29px);
              @include respond-to(lg) {
                padding: rem(10px);
              }

              :global(.rt-td) {
                flex: 60 !important;
                text-align: center;

                :global(span) {
                  font-size: 1rem;
                }

                &:first-child {
                  text-align: left;
                }

                &:last-child {
                  flex: 30 !important;
                  padding-right: rem(10px);
                  text-align: left;
                }

                :global(.MoneyCell__inner) {
                  justify-content: center;
                }

                :global(.time) {
                  display: block;
                  color: #777777;
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }

    :global(.Collapsible__header) {
      box-shadow: none;
      padding: rem(15px 28px);
      position: relative;

      @include respond-to(sm) {
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
      }
    }

    :global(.Collapsible__content) {
      :global(.Calendar) {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        padding-right: 30px;
      }

      :global(.Calendar) {
        @include respond-to(sm) {
          padding: 25px 15px 10px 15px;

          :global(.Calendar__data-info) {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }

    :global(.Collapsible__caption) {
      line-height: 32px;
      font-size: 26px;
      font-weight: 300;

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    :global(.Collapsible__arrow) {
      position: absolute;
      right: ${isRTL ? 'auto' : '30px'};
      left: ${isRTL ? '30px' : 'auto'};
      top: 50%;
    }

    :global(.Collapsible__arrow-right) {
      transform: ${isRTL
        ? 'translateY(-50%) rotate(135deg) !important'
        : ' translateY(-50%) rotate(-45deg) !important'};
    }

    :global(.Collapsible__arrow-down) {
      top: 40%;
      transform: rotate(45deg) translateY(-50%) !important;
    }

    :global(.NoItemsPanel) {
      height: auto;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserTransactionsHistory {
      :global(.Collapsible) {
        :global(.ReactTable) {
          :global(.rt-tfoot) {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: -2px;
              left: 0;
              width: 100%;
              height: 2px;
              background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
