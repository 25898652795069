import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  IntroPage,
  LoginPage,
  ResetPasswordPage,
  EmailConfirmationPage,
  ConfirmResetPasswordPage,
  RegistrationPage,
  Steps,
} from './_pages';

import { staticStyles } from './style';

const WelcomeSetup = ({ match, location, authorized }) => {
  const token = localStorage.getItem('wsJwtToken');

  const beforeAuthRoutes = [
    '/welcome-setup/login',
    '/welcome-setup/password-reset',
    '/welcome-setup/email-confirmation/',
    '/welcome-setup/confirm-reset-password/',
    '/welcome-setup/registration',
    '/welcome-setup/intro',
  ];
  const isBeforeAuthRoute = beforeAuthRoutes.some(route => location.pathname.includes(route));

  if (token && authorized && isBeforeAuthRoute) return <Redirect to="/welcome-setup/steps" />;

  if (!token && !authorized && !isBeforeAuthRoute) return <Redirect to="/welcome-setup/registration" />;

  return (
    <div className="WelcomeSetup">
      <Switch>
        <Route exact path={`${match.path}/login`} component={LoginPage} />
        <Route exact path={`${match.path}/intro`} component={IntroPage} />
        <Route exact path={`${match.path}/password-reset`} component={ResetPasswordPage} />
        <Route exact path={`${match.path}/confirm-reset-password/:token`} component={ConfirmResetPasswordPage} />
        <Route exact path={`${match.path}/registration`} component={RegistrationPage} />
        <Route exact path={`${match.path}/steps`} component={Steps} />
        <Route exact path={`${match.path}/email-confirmation/:token`} component={EmailConfirmationPage} />
        <Redirect to="/welcome-setup/steps" />
      </Switch>

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

WelcomeSetup.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  authorized: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    authorized: state.welcomeSetup.authorized,
  }),
  {}
)(WelcomeSetup);
export { WelcomeSetup };
