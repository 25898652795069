import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const DepositsIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="4.248%" y1="86.836%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="-UI" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-524 -744)" fill="url(#linearGradient-4)" fillRule="nonzero">
          <g id="deposits1" transform="translate(524 744)">
            <path
              d="M18.0295531,2.34473845 L16.271918,2.34473845 L15.7438393,0.384541519 C15.7076108,0.24821483 15.6149583,0.132346215 15.4876817,0.0641980516 C15.360295,-0.00516825573 15.2084925,-0.0190267406 15.0699478,0.0260619245 L7.51132857,2.34092484 C7.50738768,2.34092484 7.5034468,2.34473845 7.49556502,2.34473845 L1.97832473,2.34473845 C0.887353015,2.34494618 0.00239007829,3.19962279 0,4.25535842 L0,16.0889987 C0.00173527808,17.1435704 0.884617852,17.9981103 1.97438385,18 L18.0256122,18 C19.1152247,17.9981109 19.9980439,17.1438028 19.9999961,16.08938 L19.9999961,4.25535842 C20.0010426,3.7489815 19.7939051,3.26299754 19.4242618,2.90457675 C19.0546186,2.54615595 18.5528306,2.34473744 18.0295531,2.34473845 Z M11.0226579,2.34473845 L14.853199,1.17014574 L15.1684699,2.34473845 L11.0226579,2.34473845 Z M15,12 C13.8954305,12 13,11.1045695 13,10 C13,8.8954305 13.8954305,8 15,8 C16.1045695,8 17,8.8954305 17,10 C17,11.1045695 16.1045695,12 15,12 Z M11.0226586,8.13541899 C11.022393,7.83415638 11.096513,7.54510953 11.228657,7.33208448 C11.3608009,7.11905943 11.5401029,6.99957291 11.7269824,7.00000115 L19,7 L19,13 L11.7269824,13 C11.3383699,12.9977888 11.0237548,12.4902482 11.0226586,11.8637769 L11.0226586,8.13541899 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

DepositsIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

DepositsIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedDepositsIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(DepositsIconActive);

export default ThemedDepositsIconActive;
