import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';

import { StatusCell, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const IssueInfo = ({ issue, onClick, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="Issue__info">
      <ul>
        <li className="IssueInfo__user-inner">
          <div className="IssueInfo__avatar-inner">
            <Gravatar md5={md5(issue.user.email)} size={50} onClick={onClick} />
          </div>
          <div className="IssueInfo__user-info">
            <FormattedMessage id="boUser" />
            <p>{issue.user.email}</p>
          </div>
        </li>
        <li className="IssueInfo__title">
          <FormattedMessage id="justTitle" />
          <p>{issue.title}</p>
        </li>
        <li className="IssueInfo__date">
          <FormattedMessage id="justDate" /> <DateCell value={issue.created} />
        </li>
        <li className="IssueInfo__status">
          <FormattedMessage id="boStatus" />
          <StatusCell statusCode={issue.status} />
        </li>
      </ul>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

IssueInfo.propTypes = {
  issue: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.object,
};

IssueInfo.defaultProps = {
  onClick: null,
  theme: {},
};

export default withTheme()(IssueInfo);
export { IssueInfo };
