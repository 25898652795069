import reducer from './_redux/reducer';
import rootSaga from './_redux/saga';

const loadInterface = () =>
  new Promise(resolve =>
    import('./Interface').then(module => {
      module.default().then(module => {
        resolve(module);
      });
    })
  );

export { reducer, rootSaga, loadInterface };
