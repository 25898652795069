import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksListTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(tr) {
          grid-template-columns: minmax(60px, 0.2fr) repeat(4, minmax(100px, 1.2fr)) repeat(2, minmax(100px, 1fr)) minmax(
              80px,
              0.5fr
            );
        }

        :global(.ReactDataTable__thead-cell) {
          &:first-child,
          &:last-child {
            text-align: left;
          }
        }

        :global(.ReactDataTable__thead-row) {
          :global(th) {
            :global(span) {
              white-space: normal;
            }
          }
        }

        :global(.ReactDataTable__tbody-row) {
          height: 75px;
          margin-left: 0;
        }

        :global(.ReactDataTable__tbody-cell) {
          position: relative;
          overflow: visible;

          &:first-child,
          &:last-child {
            justify-content: flex-start;
          }

          :global(.TasksListTable__expirationDate) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          :global(.time) {
            font-size: rem(13px);
            color: #777777;
          }

          :global(.overdue) {
            display: block;
            color: red;
            font-weight: 100;
            font-size: rem(13px);
            text-align: center;
          }

          :global(.actions) {
            display: flex;
            align-items: center;

            :global(.ActionsButton) {
              :global(svg) {
                width: rem(20px);
                height: rem(20px);
              }
            }
          }
        }
      }
    }
    :global(.ResponsiveTable__thead) {
      min-width: 320px !important;
      :global(span) {
        text-align: center;
        padding: 5px 0;
        &:first-child {
          padding-left: rem(5px);
          flex: 1;
          opacity: 0;
        }
        &:nth-child(2) {
          flex: 10;
        }

        &:nth-child(3) {
          flex: 10;
        }
      }
    }

    :global(.ResponsiveTable__row-item) {
      flex: 5;
      display: flex;

      &:first-child {
        padding-left: rem(5px);
        flex: 1;
      }

      &:nth-child(2) {
        flex: 10;
      }

      &:nth-child(3) {
        flex: 10;
      }
    }
  }
`;

export { staticStyles };
