import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector, change, untouch } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { numericality, required } from 'redux-form-validators';
import { BasicModal, DefaultButton, PageContentPreloader } from 'common/components';
import { ReduxTextField } from 'common/components/form-fields';
import { withTheme } from 'common/styling/theme';
import { FormMessageHOC } from 'common/HOCs';
import { getTradersTransferInfo, getInnerTransferOptions, createTradersTransfer } from '../../_redux';
import InternalTransferConfirmation from '../InternalTransferConfirmation';

import { staticStyles, getDynamicStyles } from './style';

const selector = formValueSelector('traders-transfer-form');
const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

const WalletTransfer = ({
  formValues,
  tradersTransferInfo,
  tradersTransferInfoIsLoaded,
  getInnerTransferOptions,
  getTradersTransferInfo,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  transfersError,
  profile,
  dispatch,
  error,
  theme,
  intl,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReceiveAmountNegative, setIsReceiveAmountNegative] = useState(false);
  const submit = handleSubmit(values => createTradersTransfer({ ...values, senderAccount: 'wallet' }, dispatch));

  const { senderCurrencyDigit = 2, recipientCurrencyDigit = 2 } = useMemo(() => {
    if (tradersTransferInfo.details) {
      const { senderCurrencyDigit, recipientCurrencyDigit } = tradersTransferInfo.details;
      return { senderCurrencyDigit, recipientCurrencyDigit };
    }
    return {};
  }, [tradersTransferInfo.details]);

  useEffect(() => {
    getInnerTransferOptions();
  }, [getInnerTransferOptions]);

  const toggleTransferConfirmationModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getWalletTransferCommission = (_, recipient) => {
    const { sendAmount } = formValues;
    if (recipient) {
      getTradersTransferInfo({
        senderAccount: 'wallet',
        recipient,
      });
    }
    if (sendAmount) {
      dispatch(change('traders-transfer-form', 'sendAmount', null));
      dispatch(untouch('traders-transfer-form', 'sendAmount'));
      dispatch(change('traders-transfer-form', 'receiveAmount', null));
    }
  };

  const calculateReceiveAmount = (_, value) => {
    const sendAmount = parseFloat(value);
    if (
      typeof sendAmount === 'number' &&
      sendAmount <= tradersTransferInfo.availableAmount &&
      tradersTransferInfo.details
    ) {
      const { exchangeCoefficient = 1, commission } = tradersTransferInfo.details;
      let calculatedCommission =
        (sendAmount / 100) * commission.percentage +
        commission.fix * tradersTransferInfo.details.exchangeCoefficientForFix;
      if (calculatedCommission > tradersTransferInfo.details.maxCommission.amount)
        calculatedCommission = tradersTransferInfo.details.maxCommission.amount;
      if (calculatedCommission < tradersTransferInfo.details.minCommission.amount)
        calculatedCommission = tradersTransferInfo.details.minCommission.amount;
      const receiveAmount = (sendAmount - calculatedCommission) * exchangeCoefficient;
      let roundedReceiveAmount = 0;
      if (receiveAmount > 0) {
        roundedReceiveAmount = getFixedNumber(receiveAmount, recipientCurrencyDigit);
        setIsReceiveAmountNegative(false);
      } else {
        setIsReceiveAmountNegative(true);
      }
      dispatch(change('traders-transfer-form', 'receiveAmount', roundedReceiveAmount.toString()));
    }
  };

  const checkForDigitsAfterDecimal = useCallback(
    value => {
      const regex = new RegExp(`^\\d+\\.?\\d{0,${senderCurrencyDigit}}$`);
      if (!regex.test(value)) {
        return intl.formatMessage(
          { id: 'maximumNumberOfDigitsAfterDecimalPoint' },
          { maximumDigits: senderCurrencyDigit }
        );
      }
      return null;
    },
    [senderCurrencyDigit]
  );

  return (
    <PageContentPreloader ready type="bigLine">
      <form className="WalletTransfer" onSubmit={submit}>
        <div className="WalletTransfer__line">
          <div className="WalletTransfer__wallet-balance">
            <FormattedMessage id="justWalletBalance">
              {txt => <span className="WalletTransfer__wallet-caption">{txt}:</span>}
            </FormattedMessage>
            <span className="WalletTransfer__wallet-amount">{`${profile.walletBalance} USD`}</span>
          </div>
          <Field
            component={ReduxTextField}
            type="text"
            name="recipient"
            textId="justRecipientEmail"
            validate={[required()]}
            disabled={submitting}
            onBlur={getWalletTransferCommission}
          />
        </div>
        <div className="WalletTransfer__line">
          <Field
            component={ReduxTextField}
            type="text"
            name="sendAmount"
            textId="clientSendAmount"
            validate={[
              required(),
              checkForDigitsAfterDecimal,
              numericality({
                msg: tradersTransferInfo.requestedAmount
                  ? {
                      '<=': intl.formatMessage(
                        { id: 'availableAmountForTransfer' },
                        {
                          available: tradersTransferInfo.availableAmount,
                          requested: tradersTransferInfo.requestedAmount,
                        }
                      ),
                    }
                  : {},
                '>=': 0,
                '<=': tradersTransferInfo.availableAmount ?? 10000,
              }),
            ]}
            disabled={submitting || !tradersTransferInfoIsLoaded}
            onChange={calculateReceiveAmount}
            description="USD"
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="receiveAmount"
            textId="justWillReceive"
            validate={[required()]}
            disabled
          />
        </div>
        {transfersError && <span className="WalletTransfer__error">{transfersError}</span>}
        {tradersTransferInfo.details && (
          <div className="WalletTransfer__description">
            <FormattedMessage
              id={
                tradersTransferInfo.details.exchangeRate
                  ? 'commissionForInternalTransferDifferentCurrencies'
                  : 'commissionForInternalTransferSameCurrencies'
              }
              values={{
                commission: `${tradersTransferInfo.details.commission.percentage}% + ${tradersTransferInfo.details.commission.fix} USD.`,
              }}>
              {txt => <span>{txt}</span>}
            </FormattedMessage>
            {tradersTransferInfo.details.exchangeRate && <span>{tradersTransferInfo.details.exchangeRate}</span>}
            {tradersTransferInfo.details.minCommission && tradersTransferInfo.details.maxCommission && (
              <FormattedMessage
                id="commissionLimitDescription"
                values={{
                  min: `${tradersTransferInfo.details.minCommission.amount} ${tradersTransferInfo.details.minCommission.currency}`,
                  max: `${tradersTransferInfo.details.maxCommission.amount} ${tradersTransferInfo.details.maxCommission.currency}`,
                }}>
                {txt => <span>{txt}</span>}
              </FormattedMessage>
            )}
          </div>
        )}

        <DefaultButton
          textId="clientSend"
          onClick={toggleTransferConfirmationModal}
          type="button"
          disabled={isReceiveAmountNegative || invalid || pristine || submitting || !tradersTransferInfoIsLoaded}
          filled
        />
      </form>

      <BasicModal
        isOpen={isModalOpen}
        captionId="internalTransferConfirmation"
        onRequestClose={toggleTransferConfirmationModal}>
        <InternalTransferConfirmation
          payload={{
            senderAccount: {
              id: 'wallet',
              login: intl.formatMessage({ id: 'menuWallet' }),
              balance: {
                amount: profile.walletBalance,
                currency: 'USD',
              },
            },
            recipientAccount: formValues.recipient,
            sendAmount: `${formValues.sendAmount} USD`,
            receiveAmount: formValues.receiveAmount,
          }}
          submitting={submitting}
          handleSubmit={submit}
          handleCancel={toggleTransferConfirmationModal}
          error={error}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageContentPreloader>
  );
};

WalletTransfer.propTypes = {
  formValues: PropTypes.object.isRequired,
  tradersTransferInfo: PropTypes.object.isRequired,
  tradersTransferInfoIsLoaded: PropTypes.bool.isRequired,
  transfersError: PropTypes.string.isRequired,
  getInnerTransferOptions: PropTypes.func.isRequired,
  getTradersTransferInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  error: PropTypes.bool,
  theme: PropTypes.object,
};

WalletTransfer.defaultProps = {
  error: '',
  theme: '',
};

export default compose(
  withTheme(),
  withRouter,
  injectIntl,
  FormMessageHOC,
  connect(
    state => ({
      formValues: selector(state, 'recipient', 'sendAmount', 'receiveAmount'),
      tradersTransferInfo: state.client.transfers.tradersTransferInfo,
      tradersTransferInfoIsLoaded: state.client.transfers.tradersTransferInfoIsLoaded,
      transfersError: state.client.transfers.error,
      profile: state.user.profile,
    }),
    {
      getInnerTransferOptions: () => getInnerTransferOptions.request(),
      getTradersTransferInfo: data => getTradersTransferInfo.request(data),
    }
  ),
  reduxForm({
    form: 'traders-transfer-form',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageTextId);
      props.history.push('/payments?tab=transfers');
    },
  })
)(WalletTransfer);
export { WalletTransfer };
