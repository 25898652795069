import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from 'common/styling/theme';

import { HideIcon, ShowIcon } from '../../icons';

import BaseTextField from '../BaseTextField';
import FieldHelpText from '../../FieldHelpText';
import ActionsButton from '../../ActionsButton';

import { staticStyles, getDynamicStyles } from './style';

const ReduxTextField = ({
  input,
  meta: { dirty, touched, error },
  type,
  tooltipId,
  tooltipTextId,
  tooltipFormattedValues,
  customDefaultValue,
  action,
  actionTooltipId,
  actionClassName,
  description,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [stateValue, setStateValue] = useState(typeof input?.value === 'object' ? '' : input?.value);

  if (!input) {
    return 'no input';
  }

  const handleChange = e => {
    if (typeof e !== 'string') {
      const { target } = e;

      if (type === 'number' && e.target.value) {
        // eslint-disable-next-line
        if (!isNaN(target.value)) {
          input.onChange(target.value);
          setStateValue(target.value);
        }
      } else {
        input.onChange(target.value);
        setStateValue(target.value);
      }
    } else {
      input.onChange(e);
      setStateValue(e);
    }
  };

  const handleBlur = e => {
    if (typeof e !== 'string') {
      const { target } = e;

      if (type === 'number' && target.value) {
        // eslint-disable-next-line
        if (!isNaN(target.value)) {
          if (target.value.slice(-1) === '.') {
            input.onBlur(target.value);
            setStateValue(target.value);
          } else {
            input.onBlur(Number(target.value));
            setStateValue(Number(target.value));
          }
        }
      } else {
        input.onBlur(target.value);
        setStateValue(target.value);
      }
    } else {
      input.onBlur(e);
      setStateValue(e);
    }
  };
  const value = input.value ? stateValue : input.value;

  return (
    <BaseTextField
      {...props}
      {...input}
      type={type === 'number' ? 'text' : type}
      additionalClass
      value={value || (typeof input.value === 'object' ? '' : input.value)}
      name={input.name}
      error={(dirty || touched) && error}
      customDefaultValue={customDefaultValue}
      onChange={handleChange}
      onBlur={handleBlur}>
      <span className="ReduxTextField__error">{(dirty || touched) && error}</span>

      <FieldHelpText tooltipId={tooltipId} tooltipTextId={tooltipTextId} formattedValues={tooltipFormattedValues} />

      {!!action && (
        <ActionsButton
          condition={!!action}
          tooltipId={input.name}
          tooltipTextId={actionTooltipId}
          watch
          className={actionClassName}
          onClickFunc={action}>
          <ShowIcon />
          <HideIcon />
        </ActionsButton>
      )}
      {!!description && (
        <span
          className={classNames('ReduxTextField__description', {
            'ReduxTextField__description--tooltip': tooltipTextId,
          })}>
          {description}
        </span>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </BaseTextField>
  );
};

ReduxTextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    dirty: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  type: PropTypes.string,
  tooltipId: PropTypes.string,
  tooltipTextId: PropTypes.string,
  tooltipFormattedValues: PropTypes.object,
  customDefaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  action: PropTypes.func,
  actionTooltipId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  actionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  theme: PropTypes.object,
};

ReduxTextField.defaultProps = {
  type: 'text',
  tooltipId: '',
  tooltipTextId: '',
  tooltipFormattedValues: {},
  customDefaultValue: '',
  action: null,
  actionTooltipId: null,
  actionClassName: null,
  description: '',
  theme: {},
};

export default withTheme()(ReduxTextField);
export { ReduxTextField };
