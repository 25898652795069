import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { DefaultButton, BaseSelectField } from 'common/components';
import { editUserInfo, setClientType } from 'backoffice/users/_redux';

import { staticStyles } from './style';

const EditClientTypeForm = ({
  chosenClientType,
  clientType,
  setClientType,
  user,
  intl,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const userId = user.id;
  const submit = handleSubmit(() => editUserInfo({ client_type: chosenClientType, userId }, dispatch));

  const options = [
    { value: 'new', label: intl.formatMessage({ id: 'justNew' }) },
    { value: 'retail_individual', label: intl.formatMessage({ id: 'justRetailIndividual' }) },
    { value: 'retail_joined', label: intl.formatMessage({ id: 'justRetailJoined' }) },
    { value: 'corporate', label: intl.formatMessage({ id: 'justCorporate' }) },
    { value: 'ib', label: intl.formatMessage({ id: 'justIB' }) },
    { value: 'payment_agent', label: intl.formatMessage({ id: 'justPaymentAgent' }) },
  ];
  const chosenValue = chosenClientType
    ? options.find(option => option.value === chosenClientType)
    : options.find(option => option.value === clientType);

  return (
    <form className="EditClientTypeForm" onSubmit={submit}>
      <div className="EditClientTypeForm__inputs-inner">
        <BaseSelectField
          name="client_type"
          textId="justClientType"
          options={options}
          onChange={option => setClientType(option.value)}
          maxMenuHeight={170}
          value={chosenValue}
        />
      </div>

      <DefaultButton textId="justChange" type="submit" loading={submitting} disabled={invalid || submitting} filled />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditClientTypeForm.propTypes = {
  chosenClientType: PropTypes.string.isRequired,
  clientType: PropTypes.string.isRequired,
  setClientType: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  intl: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditClientTypeForm.defaultProps = {
  error: '',
};

export default compose(
  connect(
    state => ({
      chosenClientType: state.backoffice.users.user?.chosenClientType,
      clientType: state.backoffice.users.user?.clientType,
    }),
    {
      setClientType: type => setClientType.request(type),
    }
  ),
  reduxForm({
    form: 'edit-user-status',
  })
)(injectIntl(EditClientTypeForm));

export { EditClientTypeForm };
