import React from 'react';

const CalendarIcon = () => (
  <div className="CalendarIcon">
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path
        d="M2.5 7.21875C2.5 6.61445 2.98973 6.125 3.59375 6.125H5.78125C6.38555 6.125 6.875 6.61445 6.875 7.21875V9.40625C6.875 10.0105 6.38555 10.5 5.78125 10.5H3.59375C2.98973 10.5 2.5 10.0105 2.5 9.40625V7.21875ZM3.375 7.21875V9.40625C3.375 9.52656 3.47289 9.625 3.59375 9.625H5.78125C5.90156 9.625 6 9.52656 6 9.40625V7.21875C6 7.09844 5.90156 7 5.78125 7H3.59375C3.47289 7 3.375 7.09844 3.375 7.21875ZM4.25 1.75H9.5V0.4375C9.5 0.195891 9.69687 0 9.9375 0C10.1781 0 10.375 0.195891 10.375 0.4375V1.75H11.25C12.2152 1.75 13 2.5334 13 3.5V12.25C13 13.2152 12.2152 14 11.25 14H2.5C1.5334 14 0.75 13.2152 0.75 12.25V3.5C0.75 2.5334 1.5334 1.75 2.5 1.75H3.375V0.4375C3.375 0.195891 3.57188 0 3.8125 0C4.05312 0 4.25 0.195891 4.25 0.4375V1.75ZM1.625 12.25C1.625 12.734 2.01684 13.125 2.5 13.125H11.25C11.734 13.125 12.125 12.734 12.125 12.25V5.25H1.625V12.25ZM1.625 3.5V4.375H12.125V3.5C12.125 3.01602 11.734 2.625 11.25 2.625H2.5C2.01684 2.625 1.625 3.01602 1.625 3.5Z"
        fill="#CECECE"
      />
    </svg>
  </div>
);

export default CalendarIcon;
