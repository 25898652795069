import React, { useEffect } from 'react';
import { MoneyCell } from 'common/components';
import { FormattedMessage as FM } from 'react-intl';
import { ClientAccountOrderHistoryOrdersTable } from 'crm/users/_components/';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCrmUserAccountData, getCrmUserData } from 'crm/users/_redux';

import arrowBack from './images/arrowBack.svg';
import { staticStyles } from './style';

const ClientPageHistory = () => {
  const { accountData } = useSelector(state => state.crm.users);
  const dispatch = useDispatch();
  const { id, idAccount } = useParams();

  const { userData } = useSelector(state => state.crm.users);

  const hasAccountData = !!Object.keys(accountData).length;

  useEffect(() => {
    dispatch(getCrmUserData.request(id));
    dispatch(getCrmUserAccountData.request({ id, accountId: idAccount }));
  }, [id, idAccount, dispatch]);

  return (
    <div className="ClientPageHistory">
      {/* info */}
      <div className="ClientPageHistory__back">
        <img className="ClientPageHistory__back-icon" src={arrowBack} alt="arrow back" />
        <button type="button" className="ClientPageHistory__back-text" onClick={() => window.history.back()}>
          <FM id="justBack">{txt => txt}</FM>
        </button>
      </div>
      <div className="ClientPageHistory__title">
        <FM id="crmLogin">{txt => txt}</FM> : {hasAccountData && `${accountData.login} ${accountData.platformTitle}`}
      </div>
      <div className="ClientPageHistory__broker">
        <div className="ClientPageHistory__broker-title">
          <FM id="crmClientName">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__leverage-data">{userData && userData.fullName}</div>
      </div>
      <div className="ClientPageHistory__leverage">
        <div className="ClientPageHistory__leverage-title">
          <FM id="crmLeverage">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__leverage-data">{hasAccountData && `1:${accountData.leverage}`}</div>
      </div>
      <div className="ClientPageHistory__leverage">
        <div className="ClientPageHistory__leverage-title">
          <FM id="crmType">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__leverage-data">{hasAccountData && accountData.accountTypeTitle}</div>
      </div>
      <div className="ClientPageHistory__leverage">
        <div className="ClientPageHistory__leverage-title">
          <FM id="crmAccountCurrency">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__leverage-data">{hasAccountData && accountData.currency}</div>
      </div>
      <div className="ClientPageHistory__leverage">
        <div className="ClientPageHistory__leverage-title">
          <FM id="crmAccountBalance">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__leverage-data">
          {hasAccountData && <MoneyCell crmStyle value={accountData.balance} heightMaxContent />}
        </div>
      </div>
      <div className="ClientPageHistory__leverage">
        <div className="ClientPageHistory__leverage-title">
          <FM id="crmAccountEquity">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__leverage-data">
          <MoneyCell crmStyle value={accountData.equity} heightMaxContent />
        </div>
      </div>
      <div className="ClientPageHistory__currency">
        <div className="ClientPageHistory__currency-title">
          <FM id="crmPartnerCode">{txt => txt}</FM> :
        </div>
        <div className="ClientPageHistory__currency-data">{hasAccountData && (accountData.partnerCode ?? '----')}</div>
      </div>

      {/* table main */}

      {/* bottom */}

      <ClientAccountOrderHistoryOrdersTable platformSlug={accountData.platformSlug} />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default ClientPageHistory;
