import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.ClientsPageAnalytics) {
    background: white;
    border-radius: 16px;
    padding: 24px;
  }

  :global(.ClientsPageAnalytics__Charts) {
    display: flex;
    width: 100%;
    padding-top: 10px;
    position: relative;
    :global(.month-picker) {
      position: absolute;
      top: -50px;
      right: 0;
      :global(.rmp-popup) {
        right: -8rem;
        @include respond-to(sm) {
          right: 0;
        }
      }
    }
    @include respond-to(xl) {
      flex-direction: column;
    }
  }

  :global(.ClientsPageAnalytics__title) {
    font-size: rem(24px);
    line-height: rem(28px);
    font-weight: 300;
    margin-bottom: 25px;
  }

  :global(.ClientsPageAnalytics__Chart) {
    width: 100%;
    margin-bottom: 50px;
    @include respond-to(xl) {
      padding-bottom: 35px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
