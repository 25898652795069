import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  dynamicResourceList: {},
  dynamicResourceListIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DYNAMIC_RESOURCE_LIST[REQUEST]:
      return { ...state, dynamicResourceListIsLoaded: false };
    case ActionTypes.GET_DYNAMIC_RESOURCE_LIST[SUCCESS]:
      return { ...state, dynamicResourceList: action.data, dynamicResourceListIsLoaded: true };
    case ActionTypes.GET_DYNAMIC_RESOURCE_LIST[FAILURE]:
      return { ...state, error: action.error, dynamicResourceListIsLoaded: false };

    case ActionTypes.saveDynamicSetting[FAILURE]:
      return { ...state, error: action.payload.errors };

    case ActionTypes.saveDynamicResource[FAILURE]:
      return { ...state, error: action.payload.errors };

    case ActionTypes.createDynamicResource[FAILURE]:
      return { ...state, error: action.payload.errors };

    default:
      return state;
  }
};

export default reducer;
