import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BasicModal, DefaultButton } from 'common/components';
import RadialChart from 'common/dashboards/_components/RadialChart';
import TargetForm from 'common/dashboards/_components/TargetForm';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const FulfillmentOfPlans = ({
  theme,
  lastFunnels,
  target,
  partnerId,
  viewMode,
  intl,
  currentMonth,
  lastMonth,
  currentMonthName,
}) => {
  const { users: registeredTarget = 100, realUsers: realUsersTarget = 50, depositedUsers: ftdTarget = 10 } = target;
  const {
    users: currentUsers = 0,
    real_users: currentRealUsers = 0,
    deposited_users: currentFtd = 0,
  } = lastFunnels[currentMonth] || {};
  const {
    users: lastMonthUsers = 0,
    real_users: lastMonthRealUsers = 0,
    deposited_users: lastMonthFtd = 0,
  } = lastFunnels[lastMonth] || {};
  const registeredPercent = (currentUsers / registeredTarget) * 100;
  const realUsersPercent = (currentRealUsers / realUsersTarget) * 100;
  const depositedPercent = (currentFtd / ftdTarget) * 100;

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="FulfillmentOfPlans">
        <div className="FulfillmentOfPlans__top-line">
          <FormattedMessage id="boFulfillmentOfPlans" values={{ month: currentMonthName }}>
            {txt => <h3>{txt}</h3>}
          </FormattedMessage>
          {!partnerId && <DefaultButton textId="justEditTarget" type="button" onClick={openModal} filled plus />}
        </div>
        <div className="FulfillmentOfPlans__left-inner">
          <div className="ChartInner">
            <RadialChart
              percent={registeredPercent}
              color="#43B6E8"
              gradientEndColor="#33EEC7"
              captionId={intl.formatMessage({ id: 'boRegistered' })}
              amountNow={currentUsers}
              target={registeredTarget}
              amountLastMonth={lastMonthUsers}
            />
          </div>
          <div className="ChartInner">
            <RadialChart
              percent={realUsersPercent}
              color="#6ec448"
              gradientEndColor="#a0d54f"
              captionId={intl.formatMessage({ id: 'boOpenedLiveAccount' })}
              amountNow={currentRealUsers}
              target={realUsersTarget}
              amountLastMonth={lastMonthRealUsers}
            />
          </div>
        </div>
        <div className="FulfillmentOfPlans__right-inner">
          <div className="ChartInner">
            <RadialChart
              percent={depositedPercent}
              color="#39DBD3"
              gradientEndColor="#39DBD3"
              captionId={intl.formatMessage({ id: 'boFTD' })}
              amountNow={currentFtd}
              target={ftdTarget}
              amountLastMonth={lastMonthFtd}
            />
          </div>
        </div>

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
      <BasicModal captionId="justTargets" isOpen={isModalOpen} onRequestClose={closeModal}>
        <TargetForm
          initialValues={{
            users: registeredTarget,
            realUsers: realUsersTarget,
            depositedUsers: ftdTarget,
          }}
          lastMonthUsers={lastMonthUsers}
          lastMonthRealUsers={lastMonthRealUsers}
          lastMonthFtd={lastMonthFtd}
          closeModal={closeModal}
          viewMode={viewMode}
        />
      </BasicModal>
    </>
  );
};

FulfillmentOfPlans.propTypes = {
  intl: PropTypes.object.isRequired,
  partnerId: PropTypes.number.isRequired,
  theme: PropTypes.object,
  lastFunnels: PropTypes.object,
  target: PropTypes.object,
  viewMode: PropTypes.string,
  currentMonth: PropTypes.string.isRequired,
  lastMonth: PropTypes.string.isRequired,
  currentMonthName: PropTypes.string.isRequired,
};

FulfillmentOfPlans.defaultProps = {
  theme: {},
  lastFunnels: {},
  target: {},
  viewMode: '',
};

export default compose(injectIntl, withTheme())(FulfillmentOfPlans);
export { FulfillmentOfPlans };
