import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageLayout, NoItemsPanel, PageContentPreloader } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getAllBonuses } from '../../_redux';

import Bonus from '../../_components/Bonus';

const AllBonusesPage = ({ allBonuses, allBonusesAreLoaded, getAllBonuses }) => {
  useEffect(() => {
    getAllBonuses();
  }, [getAllBonuses]);

  return (
    <PageLayout captionSlug="menuAllBonuses" showReload onReloadClick={getAllBonuses} reloading={!allBonusesAreLoaded}>
      <PageContentPreloader ready={allBonusesAreLoaded} type="bigLine" firstLaunchOnly>
        {allBonuses.length ? (
          <div>
            {allBonuses.map((bonus, id) => (
              <Bonus form={`bonusForm${id}`} data={bonus} key={id} bonusForm={`bonusForm${id}`} id={bonus.id} />
            ))}
          </div>
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noBonuses" />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

AllBonusesPage.propTypes = {
  allBonuses: PropTypes.array,
  allBonusesAreLoaded: PropTypes.bool,
  getAllBonuses: PropTypes.func.isRequired,
};

AllBonusesPage.defaultProps = {
  allBonuses: [],
  allBonusesAreLoaded: true,
};

export default connect(
  state => ({
    allBonuses: state.client.bonuses.allBonuses,
    allBonusesAreLoaded: state.client.bonuses.allBonusesAreLoaded,
  }),
  { getAllBonuses: () => getAllBonuses.request() }
)(AllBonusesPage);
