import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

import { FormMessageHOC } from 'common';
import { DefaultButton } from 'common/components';

import StatusDescriptionPanel from '../StatusDescriptionPanel';

const CreateStatusDescriptionForm = ({
  submitFunction,
  promoTexts,
  submitting,
  handleSubmit,
  dispatch,
  invalid,
  pristine,
  ...props
}) => {
  const submit = handleSubmit(submitFunction, dispatch);

  return (
    <form className="CreateStatusDescriptionForm" onSubmit={submit}>
      <StatusDescriptionPanel {...props} promoTexts={promoTexts} submitting={submitting} />
      <DefaultButton
        textId="justSave"
        type="submit"
        disabled={invalid || submitting || pristine}
        loading={submitting}
        filled
      />
    </form>
  );
};

CreateStatusDescriptionForm.propTypes = {
  submitFunction: PropTypes.func.isRequired,
  promoTexts: PropTypes.array,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

CreateStatusDescriptionForm.defaultProps = {
  promoTexts: [],
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'CreateStatusDescriptionForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageId);
    },
  })
)(CreateStatusDescriptionForm);
export { CreateStatusDescriptionForm };
