import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';

import { withTheme, taggedStylesToString } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const RadioButton = ({ externalClass, theme, textId, active, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <div className={classNames('RadioButton', externalClass)} {...props}>
      <div className={classNames('RadioButton__icon', { 'RadioButton__icon--active': active })} />
      {textId && <FM id={textId}>{txt => <div className="RadioButton__txt">{txt}</div>}</FM>}

      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

RadioButton.propTypes = {
  externalClass: PropTypes.string,
  textId: PropTypes.string,
  active: PropTypes.bool,
  theme: PropTypes.object,
};

RadioButton.defaultProps = {
  externalClass: '',
  textId: '',
  theme: {},
  active: false,
};

export default withTheme()(RadioButton);
