import React from 'react';
import css from 'styled-jsx/css';

const staticStyle = css`
  .CallIcon {
    border-radius: 8px;
    border: 1px solid #0aa1e2;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
    cursor: pointer;
  }

  .CallIcon:hover {
    background-color: #0aa1e2;
    background-color: calc(50% + #0aa1e2);
    cursor: pointer;
  }
`;

const IconPhone = () => (
  <div className="CallIcon">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M13.2508 9.03984L10.4672 7.84601C9.96132 7.63579 9.36796 7.78107 9.0248 8.20367L8.11863 9.31027C6.67734 8.52577 5.47695 7.32539 4.69218 5.88437L5.80015 4.97683C6.22863 4.62355 6.36972 4.04277 6.15125 3.53199L4.96015 0.748399C4.72226 0.199036 4.12343 -0.102183 3.54375 0.0315556L0.961679 0.630547C0.395664 0.75961 0 1.25508 0 1.83531C0 8.54218 5.45781 14 12.1652 14C12.7457 14 13.2428 13.6051 13.373 13.0394L13.9691 10.4562C14.1012 9.87382 13.8004 9.27773 13.2508 9.03984ZM12.5207 12.8434C12.4831 13.0066 12.3336 13.1253 12.1658 13.1253C5.9396 13.1253 0.875546 8.0612 0.875546 1.83504C0.875546 1.66584 0.99132 1.52113 1.15828 1.48285L3.7398 0.88375C3.76715 0.877341 3.79449 0.874349 3.8214 0.874349C3.96451 0.874349 4.09784 0.958951 4.15636 1.09395L5.348 3.8748C5.41132 4.025 5.37031 4.19726 5.24453 4.30117L3.86367 5.4332C3.70781 5.56172 3.65859 5.78047 3.74883 5.96367C4.66156 7.81785 6.18542 9.34062 8.03906 10.2539C8.22065 10.3445 8.44183 10.2958 8.57062 10.1385L9.70237 8.75768C9.80363 8.63291 9.98019 8.59189 10.1262 8.65257L12.9043 9.84366C13.0658 9.91374 13.1547 10.0889 13.1154 10.2598L12.5207 12.8434Z"
        fill="#333333"
      />
    </svg>
    <style jsx>{staticStyle}</style>
  </div>
);

export default IconPhone;
