import React from 'react';

const BonusesMyIcon = () => (
  <div>
    <svg viewBox="0 0 26 29" width="26" height="29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.952 21.056a.517.517 0 00-.48-.383l-3.893-.574-1.779-3.541a.591.591 0 00-.528-.335.59.59 0 00-.529.335l-1.586 3.254-1.201-.67c-.721-.383-1.154-1.149-1.154-1.962v-1.053c.385-.478 1.778-2.297 1.97-3.35l.577-.478c.289-.24.529-.718.529-1.101V8.805c0-.478-.192-.909-.577-1.196V5.455C17.301 2.393 14.898 0 11.92 0h-1.154C7.834 0 5.431 2.393 5.431 5.312v2.153c-.385.287-.577.718-.577 1.197v2.393c0 .382.24.86.529 1.1l.576.479c.193 1.053 1.586 2.871 1.97 3.35v1.052c0 .814-.432 1.58-1.153 1.962L2.163 21.63C.817 22.444 0 23.88 0 25.41v1.676h15.139l-.24 1.244c-.049.24.047.43.24.574.096.096.24.096.336.096.096 0 .192 0 .288-.048l3.509-1.866 3.508 1.866a.593.593 0 00.625-.048c.192-.143.288-.335.24-.574l-.673-3.924 2.836-2.776a.455.455 0 00.144-.574zM.72 26.368v-.957c0-1.292.72-2.489 1.826-3.11l4.614-2.633a2.957 2.957 0 001.538-2.584v-1.292l-.096-.096c-.721-.861-1.875-2.536-1.923-3.206v-.143l-.817-.67c-.144-.096-.24-.383-.24-.527V8.757c0-.287.144-.574.384-.717l.145-.096V5.408c0-2.537 2.066-4.594 4.613-4.594h1.154c2.547 0 4.613 2.057 4.613 4.594v2.536l.145.096c.24.143.384.43.384.717v2.393c0 .144-.144.43-.24.527l-.817.67v.143c-.048.67-1.202 2.297-1.923 3.206V17.084c0 1.053.577 2.058 1.538 2.584l.817.479-3.364.478a.66.66 0 00-.48.383c-.097.192 0 .43.144.575l2.835 2.775-.336 1.962H.72v.048zm21.482-2.201l.673 4.02-3.604-1.867-3.605 1.866.673-4.02-2.931-2.823 4.036-.574 1.827-3.637 1.826 3.637 4.037.574-2.932 2.824z" />
    </svg>
  </div>
);

export default BonusesMyIcon;
