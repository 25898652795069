import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.AccountTypeButton__inner) {
    width: 30%;
    margin: rem(0 25px 25px 0);
    &:nth-child(3n) {
      margin-right: 0;
    }
    @include respond-to(xl) {
      &:nth-child(3n) {
        margin: rem(0 25px 25px 0);
      }
    }
    @media only screen and (max-width: 1200px) {
      width: 49%;
      margin: rem(0 0 20px 0);
      &:nth-child(3n) {
        margin: rem(0 0 20px 0);
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include respond-to(xs) {
      width: 100%;
    }
    :global(.Tooltip__text) {
      width: 100%;
    }
  }

  .AccountTypeButton {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 25px 25px 15px);
    cursor: pointer;
    position: relative;
    width: 100%;
    &:hover {
      .AccountTypeButton__hover {
        opacity: 1;
        transform: scale(1);
      }
    }
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      .AccountTypeButton__hover {
        display: none;
      }
    }
  }

  .AccountTypeButton__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: rem(60px);
    height: rem(60px);
    font-size: rem(36px);
    font-weight: 300;
    margin-right: rem(20px);
    text-transform: uppercase;
  }

  .AccountTypeButton__text-inner {
    width: calc(100% - 85px);
    @include respond-to(sm) {
      width: calc(100% - 75px);
    }
  }

  .AccountTypeButton__caption {
    margin-bottom: 10px;
    height: rem(25px);
    overflow: hidden;
    text-align: left;
  }

  .AccountTypeButton__description {
    font-size: 13px;
    line-height: 18px;
    height: rem(88px);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left !important;
    :global(p) {
      text-align: left !important;
    }
  }

  .AccountTypeButton__hover {
    opacity: 0;
    position: absolute;
    transform: scale(0.9);
    cursor: pointer;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    :global(span) {
      text-transform: uppercase;
      font-size: rem(14px);
      font-weight: 500;
      letter-spacing: 2px;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .AccountTypeButton {
      background-color: ${colors.white.hex};
      border-radius: ${border.radius};
      &:disabled {
        .AccountTypeButton__logo {
          background: ${colors.lightGray.hex};
        }
      }
    }

    .AccountTypeButton__logo {
      background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      color: ${colors.white.hex};
    }

    .AccountTypeButton__description {
      color: ${colors.gray.hex};
    }

    .AccountTypeButton__hover {
      background: ${gradient(53.07, [colors.gradientStart.rgb, 0.85], 0, [colors.gradientEnd.rgb, 0.85], 100)};
      border-radius: ${border.radius};
      :global(span) {
        color: ${colors.white.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
