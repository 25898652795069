import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import { getAvailablePartnerCodes } from 'crm/contacts/api';
import { editContactsPartnerCode } from 'crm/contacts/_redux';
import BaseAsyncSelectField from '../../fields/BaseAsyncSelectField';
import { staticStyles } from './style';

const SetPartnerCodeForm = ({ handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const selectedContactsId = useSelector(state => state.crm.contacts.selectedContactsIds);
  const location = useLocation();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const submit = handleSubmit(values =>
    editContactsPartnerCode(
      { ...values, userId: selectedContactsId[0].id, contactsId: selectedContactsId, search: query },
      dispatch
    )
  );

  const loadOptions = search =>
    // temp 2 instead of id
    getAvailablePartnerCodes(2, search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: manager.partnerCode }))
    );
  return (
    <form className="SetPartnerCodeForm" onSubmit={submit}>
      <Field
        name="partnerAccount"
        component={BaseAsyncSelectField}
        validate={[required()]}
        loadOptions={loadOptions}
        disabled={submitting}
        maxMenuHeight={150}
        labelTextId="crmNewCode"
      />
      <BaseButton type="submit" textId="justSave" maxContent disabled={pristine || invalid || submitting} />
      {error && <span>{error}</span>}
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

SetPartnerCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

SetPartnerCodeForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-set-partner-code-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage(props.successMessageId);
      props.onRequestClose();
    },
  })
)(SetPartnerCodeForm);
