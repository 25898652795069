import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { StatusCell } from 'common/components';
import StatementCard from '../StatementCard';
import { STATUSES } from '../../consts';
import { staticStyles, getDynamicStyles } from './style';

const InvestStatementPanel = ({ investStatement, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const strategyCells = [
    {
      captionId: 'justId',
      displayValue: investStatement.strategy.id,
    },
    {
      captionId: 'justName',
      displayValue: investStatement.strategy.title,
    },
  ];

  const investmentsCells = [
    {
      captionId: 'investmentId',
      displayValue: investStatement.id,
    },
    {
      captionId: 'justCreated',
      displayValue: <FormattedDate month="2-digit" day="2-digit" year="numeric" value={investStatement.created} />,
    },
    {
      captionId: 'justCurrency',
      displayValue: investStatement.strategy.currency,
    },
    {
      captionId: 'justStatus',
      displayValue: <StatusCell statusCode={STATUSES[investStatement.status]} />,
    },
  ];

  return (
    <div className="InvestStatementPanel">
      <StatementCard cells={strategyCells} captionId="justStrategy" />
      <StatementCard cells={investmentsCells} captionId="justInvestment" />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestStatementPanel.propTypes = {
  investStatement: PropTypes.object,
  theme: PropTypes.object,
};

InvestStatementPanel.defaultProps = {
  investStatement: {},
  theme: {},
};

export default withTheme()(InvestStatementPanel);
