import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { BaseTable, Collapsible, MoneyCell, Calendar, NoItemsPanel, TableLoader, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getUserTransations } from '../../payments/_redux';
import { staticStyles, getDynamicStyles } from './style';

const UserTransactionsHistory = ({
  userTransactions,
  getUserTransations,
  userTransactionsAreLoaded,
  userId,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [startDate, setStartDate] = useState(moment().add(-7, 'day').startOf('day'));

  const [endDate, setEndDate] = useState(moment().startOf('day'));

  const handleOpenCollapse = (
    dateRange = `${moment(new Date()).add(-7, 'days').format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}`
  ) => {
    getUserTransations({
      id: userId,
      dateRange,
    });
  };

  const hadleChangeDate = (startDate, endDate) => {
    setStartDate(startDate * 1000);
    setEndDate(endDate * 1000);
    handleOpenCollapse(
      `${moment(startDate * 1000).format('YYYY-MM-DD')}_${moment(endDate * 1000).format('YYYY-MM-DD')}`
    );
  };

  const columnsNames = {
    deposit: 'justDeposit',
    internal_transfer: 'boInternalTransfer',
    withdrawal: 'justWithdrawal',
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justDate',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
      Footer: <FormattedMessage id="justTotal">{txt => <span>{txt}</span>}</FormattedMessage>,
      isPreviewCell: true,
    },
    {
      key: 'justType',
      Cell: ({ original: { type } }) => (
        <FormattedMessage id={columnsNames[type]}>{txt => <span>{txt}</span>}</FormattedMessage>
      ),
      isPreviewCell: true,
    },
    {
      key: 'accountFrom',
      Cell: ({ original: { from, senderAccount } }) => <span>{from || senderAccount || '—'}</span>,
    },
    {
      key: 'accountTo',
      Cell: ({ original: { to, recipientAccount } }) => <span>{to || recipientAccount || '—'}</span>,
      Footer: (
        <div className="TotalCell">
          <FormattedMessage id="justTotalIncome">{txt => <span className="subtext">{txt}</span>}</FormattedMessage>
          <MoneyCell className="success" value={userTransactions.summary ? userTransactions.summary.income : {}} />
        </div>
      ),
    },
    {
      key: 'justAmount',
      Cell: ({ original: { amount, sendAmount } }) => <MoneyCell value={amount || sendAmount} />,
      Footer: (
        <div className="TotalCell">
          <FormattedMessage id="justTotalOutcome">{txt => <span className="subtext">{txt}</span>}</FormattedMessage>
          <MoneyCell className="danger" value={userTransactions.summary ? userTransactions.summary.outcome : {}} />
        </div>
      ),
      isPreviewCell: true,
    },
    {
      key: 'paymentSystem',
      Cell: ({ original: { paymentSystemNiceName } }) => <span>{paymentSystemNiceName || '—'}</span>,
      Footer: (
        <div className="TotalCell">
          <FormattedMessage id="justSaldo">{txt => <span className="subtext">{txt}</span>}</FormattedMessage>
          <MoneyCell value={userTransactions.summary ? userTransactions.summary.saldo : {}} />
        </div>
      ),
    },
  ];
  /* eslint-enable */
  const onRowClick = () => {};

  return (
    <div className="UserTransactionsHistory">
      <Collapsible captionId="justTransactionHistory" handleOpen={handleOpenCollapse}>
        <Calendar daysLimit={31} onChangeFunc={hadleChangeDate} defaultStartDate={startDate} defaultEndDate={endDate} />
        {!userTransactionsAreLoaded && <TableLoader />}
        {userTransactionsAreLoaded && (
          <div>
            {userTransactions.transactions.length ? (
              <BaseTable
                columns={columns}
                data={userTransactions.transactions.map(item => ({ ...item, id: `${item.id}${item.created}` }))}
                onRowClick={onRowClick}
                isResponsive
              />
            ) : (
              <NoItemsPanel icon={noPaymentsImage} captionSlug="noWithdrawals" />
            )}
          </div>
        )}
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserTransactionsHistory.propTypes = {
  userTransactions: PropTypes.object.isRequired,
  getUserTransations: PropTypes.func.isRequired,
  userTransactionsAreLoaded: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      profile: state.user.profile,
      userTransactions: state.backoffice.payments.userTransactions,
      userTransactionsAreLoaded: state.backoffice.payments.userTransactionsAreLoaded,
    }),
    {
      getUserTransations: data => getUserTransations.request(data),
    }
  )
)(UserTransactionsHistory);
export { UserTransactionsHistory };
