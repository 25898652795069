import React from 'react';
import PropTypes from 'prop-types';

import Message, { messageType } from '../Message';

import { staticStyles } from './style';

const MessagesPanel = ({ messages, onMessageClick, onMessageDeleteClick, activeMessage }) => (
  <div className="MessagesPanel">
    {messages.map(item => (
      <Message
        {...item}
        onCaptionClick={onMessageClick}
        onBucketClick={onMessageDeleteClick}
        active={item.id === activeMessage}
        key={item.id}
      />
    ))}
    <style jsx>{staticStyles}</style>
  </div>
);

MessagesPanel.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape(messageType)).isRequired,
  activeMessage: PropTypes.number.isRequired,
  onMessageClick: PropTypes.func.isRequired,
  onMessageDeleteClick: PropTypes.func.isRequired,
};

export default MessagesPanel;
