import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const EditIcon = ({ grayColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
    <path
      fill={grayColor.hex}
      d="M17.834 2.165a3.97 3.97 0 0 0-5.619 0L1.914 12.463a.516.516 0 0 0-.146.296l-.763 5.654c-.022.16.032.32.146.433a.524.524 0 0 0 .433.15l3.406-.46a.519.519 0 0 0-.138-1.028l-2.731.368.533-3.943 4.15 4.15a.508.508 0 0 0 .729 0L17.833 7.785a3.968 3.968 0 0 0 0-5.62zm-5.42 1.266l1.73 1.73-9.4 9.4-1.73-1.73 9.4-9.4zM7.173 16.985l-1.691-1.691 9.4-9.4 1.691 1.69-9.4 9.401zm10.121-10.14l-4.138-4.14a2.94 2.94 0 0 1 4.138 4.139z"
    />
  </svg>
);

EditIcon.propTypes = {
  grayColor: colorType,
};

EditIcon.defaultProps = {
  grayColor: {},
};

const ThemedEditIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(EditIcon);

export default ThemedEditIcon;
