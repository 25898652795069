import axios from 'axios';

export const addStrategyInvestments = payload => axios.post(`/invest/investments/add/`, { ...payload });
export const setInvestmentsAttr = (investmentId, action, data) =>
  axios.post(`/invest/investments/${investmentId}/set_${action}/`, { ...data }); // target, factor, protection
export const setInvestmentsAction = (investmentId, action, data) =>
  axios.post(`/invest/investments/${investmentId}/${action}/`, { ...data }); // deposit, withdraw, pause, resume, close, approve, reject
export const closeInvestment = (investmentId, data) =>
  axios.post(`/invest/investments/${investmentId}/close/`, { ...data });
export const getInvestments = search => axios.get(`/invest/investments/search/`, { params: { ...search } });
export const getInvestStatement = investmentId => axios.get(`/invest/investments/${investmentId}/statement/`);
export const getInvestmentTrades = (investmentId, tradeType = 'open', start, end) =>
  axios.get(`/invest/investments/${investmentId}/${tradeType}_trades/`, {
    params: start && end && tradeType === 'closed' ? { start, end } : undefined,
  }); // open, closed, delayed
export const editInvestment = (investmentId, data) =>
  axios.put(`/invest/investments/${investmentId}/change/`, { ...data });
export const getInvestmentsRequests = search => axios.get(`/invest/investment_requests/`, { params: { ...search } });
export const closeInvestmentRequest = requestId => axios.post(`/invest/investments/${requestId}/cancel/`);

export const getStrategyInvestments = (strategyId, search) =>
  axios.get(`/invest/strategies/${strategyId}/accounts/`, {
    params: { ...search },
    validateStatus: status => status < 300 || status === 400,
  });

export const getInvestYieldChart = strategyId =>
  axios.get(`/invest/strategies/${strategyId}/chart/`, {
    validateStatus: status => status < 300 || status === 400,
  });
export const retrieveInvestStrategy = strategyId => axios.get(`/invest/strategies/${strategyId}/`);
export const getInvestStrategies = search => axios.get(`/invest/strategies/all/`, { params: { ...search } });
export const getMyInvestStrategies = search => axios.get(`/invest/strategies/my/`, { params: { ...search } });
export const createInvestStrategy = data => axios.post(`/invest/strategies/create/`, { ...data });
export const editInvestStrategy = ({ strategyId, data }) =>
  axios.post(`/invest/strategies/${strategyId}/change/`, { ...data });
export const setInvestStrategyAction = (strategyId, action, data) =>
  axios.post(`/invest/strategies/${strategyId}/${action}/`, { ...data }); // pause, resume, close, chart, symbols_stats
export const closeInvestStrategy = (strategyId, data) =>
  axios.post(`/invest/strategies/${strategyId}/close/`, { ...data });
export const getRammStrategyScript = (strategyId, data) =>
  axios.post(`/invest/strategies/${strategyId}/get_script/`, { ...data }, { responseType: 'blob' });
export const getInvestStrategyApprove = (strategyId, search) =>
  axios.get(`/invest/strategies/${strategyId}/awaiting_approve/`, { params: { ...search } });
