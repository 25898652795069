import React, { useEffect, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import { staticStyles } from './style';

const getTime = time => time.replace(/T.+$/, '');

const InputDate = ({ values, label, min, max, onChange }) => {
  const [minValue, maxValue] = useMemo(() => {
    if (values.length) {
      return values;
    }
    return [min, max];
  }, [values, min, max]);

  useEffect(() => {
    onChange([minValue, maxValue]);
  }, []);

  return (
    <>
      <div className="InputDate">
        <span className="InputDate__Label">
          <FM id={label}>{txt => txt}</FM>
        </span>
        <div className="InputDate__InputWrapper">
          <input
            value={getTime(minValue)}
            min={getTime(min)}
            max={getTime(max)}
            type="date"
            className="InputDate__Input"
            onChange={e => {
              const newValue = e.target.value;
              if (newValue >= min && newValue <= max) {
                onChange([newValue, maxValue]);
              }
            }}
          />
        </div>

        <div className="InputDate__Delimetr">~</div>
        <div className="InputDate__InputWrapper">
          <input
            value={getTime(maxValue)}
            min={getTime(min)}
            max={getTime(max)}
            type="date"
            className="InputDate__Input"
            onChange={e => {
              const newValue = +e.target.value;
              if (newValue >= min && newValue <= max) {
                onChange([minValue, newValue]);
              }
            }}
          />
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </>
  );
};

InputDate.propTypes = {
  values: PropTypes.array,
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

InputDate.defaultProps = {
  values: [],
};

export default InputDate;
