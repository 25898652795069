import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Message {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.4s ease;
    width: 95%;
    margin: 0 auto 2px;
    position: relative;
    &:hover {
      z-index: 2;
    }
  }

  .Message__new {
    .Message__title {
      font-weight: 500;
    }
  }

  .Message__title {
    @include respond-to(sm) {
      width: 50%;
      margin-bottom: 5px;
    }
  }

  .Message__caption {
    cursor: pointer;
    padding: rem(20px 55px 20px 25px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    transition: all 0.4s ease;
    @include respond-to(sm) {
      padding: rem(10px 35px 10px 15px);
    }
  }

  .Message__caption_left-inner {
    display: flex;
    align-items: center;
    width: 85%;
    @include respond-to(xl) {
      width: 70%;
    }
    @include respond-to(sm) {
      width: 95%;
    }
  }

  .Message__sender {
    font-weight: 600;
    margin-right: 30px;
  }

  .Message__date {
    font-size: 13px;
    line-height: 1.38;
  }

  .Message__caption-bucket {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 17px;
    cursor: pointer;
  }

  .Message__text {
    white-space: normal;
    padding: rem(20px 55px 20px 35px);
    @include respond-to(sm) {
      padding: rem(10px 35px 10px 25px);
    }
    :global(p) {
      display: inline;
    }
  }

  .Message__open {
    z-index: 2;
    margin: 10px auto 25px;
    width: 100%;
    @include respond-to(sm) {
      position: relative;
      left: 2.2%;
    }

    .Message__caption {
      padding: rem(20px 55px 20px 35px);
      @include respond-to(sm) {
        padding: rem(10px 35px 10px 25px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .Message {
      background-color: ${colors.white.hex};
      &:hover {
        box-shadow: 0 3px 8px 4px ${rgba([colors.primary.rgb, 0.25])};
      }
    }

    .Message__new {
      background-color: ${colors.newMessage.hex};
    }

    .Message__date {
      color: ${colors.gray.hex};
    }

    .Message__text {
      border-top: solid 1px ${colors.lightGray.hex};
    }

    .Message__open {
      box-shadow: 0 3px 8px 4px ${rgba([colors.primary.rgb, 0.25])};
    }

    .Message__caption-bucket {
      &:hover {
        :global(svg) {
          :global(g) {
            fill: ${colors.error.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
