import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { AsyncSelectField, ReduxTextField, ReduxSelectField, TextAreaField, DefaultButton } from 'common/components';

import { getTemplateList } from 'backoffice/templates/_redux';
import { getTemplatesListWithSearch } from 'backoffice/templates/api';
import { rejectDepositProof } from 'backoffice/payments/_redux';
import { rejectUserDocument } from '../../_redux';

import staticStyles from './style';

const RejectDocumentForm = ({
  id,
  paymentId,
  documentId,
  isProof,
  availableLangs,
  profile,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const isUserCanViewTemplateMessages = profile.perms.templateMessagesViewPerm;

  const [isManually, setIsManually] = useState(!isUserCanViewTemplateMessages);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const languages = useMemo(
    () =>
      Array.isArray(availableLangs)
        ? availableLangs.map(language => ({ value: language.slug, label: language.niceName }))
        : [],
    [availableLangs]
  );

  const toggleManually = () => setIsManually(!isManually);

  const submit = useMemo(
    () =>
      handleSubmit(values => {
        if (isProof) {
          rejectDepositProof(
            {
              id: paymentId,
              ...values,
              ...(isManually
                ? []
                : {
                    title: selectedTemplate.title,
                    description: selectedTemplate.text,
                  }),
            },
            dispatch
          );
        } else {
          rejectUserDocument(
            {
              id,
              documentId,
              ...values,
              ...(isManually
                ? []
                : {
                    title: selectedTemplate.title,
                    description: selectedTemplate.text,
                  }),
            },
            dispatch
          );
        }
      }),
    [handleSubmit, id, documentId, isManually, selectedTemplate, paymentId, isProof, dispatch]
  );

  const loadOptions = search =>
    getTemplatesListWithSearch(search).then(({ data: templatesList }) =>
      Array.isArray(templatesList)
        ? templatesList
            .filter(item => item.language === selectedLanguage)
            .map(template => ({ value: template.id, label: template.title, ...template }))
        : []
    );
  return (
    <div className="RejectModal__content">
      <form className="RejectDocumentForm" onSubmit={submit}>
        <div className="RejectDocumentForm__inputs-inner">
          {!isManually ? (
            <>
              <Field
                component={ReduxSelectField}
                name="language"
                maxMenuHeight={100}
                textId="justChooseLanguage"
                options={languages}
                validate={[required()]}
                disabled={submitting}
                onChange={setSelectedLanguage}
              />
              <Field
                component={AsyncSelectField}
                name="template"
                loadOptions={loadOptions}
                maxMenuHeight={100}
                textId="justChooseTemplate"
                afterChange={template => setSelectedTemplate(template)}
                disabled={submitting}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </>
          ) : (
            <>
              <Field
                component={ReduxTextField}
                autoComplete="off"
                type="text"
                name="title"
                textId="justTitle"
                validate={[required()]}
                disabled={submitting}
              />
              <Field
                component={TextAreaField}
                name="description"
                textId="justDescription"
                rows="4"
                cols="150"
                validate={[required()]}
                disabled={submitting}
              />
            </>
          )}
        </div>
        <div className="RejectDocumentForm__btns">
          <DefaultButton textId="justSend" type="submit" loading={submitting} disabled={invalid || submitting} filled />
          <DefaultButton
            textId="justCancel"
            type="button"
            onClick={() => toggleModal()}
            loading={submitting}
            disabled={submitting}
          />
          <DefaultButton
            textId={isManually ? 'justBackToTemplates' : 'justTypeManually'}
            type="button"
            onClick={toggleManually}
            disabled={submitting || !isUserCanViewTemplateMessages}
            noBorder
          />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

RejectDocumentForm.propTypes = {
  id: PropTypes.number.isRequired,
  documentId: PropTypes.number.isRequired,
  availableLangs: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  paymentId: PropTypes.number,
  isProof: PropTypes.bool,
  error: PropTypes.string,
};

RejectDocumentForm.defaultProps = {
  paymentId: null,
  isProof: false,
  error: '',
};

export default compose(
  connect(
    state => ({
      availableLangs: state.interfaceConfig.availableLangs,
      templatesList: state.backoffice.templates?.templates?.results ?? [],
      profile: state.user.profile,
    }),
    {
      getTemplateList: search => getTemplateList.request(search),
    }
  ),
  reduxForm({
    form: 'RejectDocumentForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(RejectDocumentForm);
export { RejectDocumentForm };
