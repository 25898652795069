import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ReassignItem {
    position: relative;
    display: flex;
    flex-direction: column;

    @include respond-to(xs) {
      padding-bottom: 16px;
    }

    &--bold {
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }

    &__ActionWrapper {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 12px;
    }

    &__Action {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      line-height: 1.4;
      color: #999;

      @include respond-to(xs) {
        margin-left: 44px;
      }
    }

    &__Author {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      color: #333;

      @include respond-to(lg) {
        position: absolute;
        right: 0;
        top: 0;
      }

      @include respond-to(xs) {
        top: initial;
        left: 0;
        bottom: -40px;
      }
    }

    &__Reason {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 1.4;
      color: #333;
    }

    &__Comment {
      padding: 12px 16px;
      max-width: 500px;
      border-radius: 4px;
      background-color: #fefbfb;

      &--content {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }
`;

export { staticStyles };
