import { put, call, fork, take } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* requestPasswordResetSaga(email) {
  try {
    const { status, data } = yield call(api.requestPasswordReset, email);
    if (status < 300) {
      yield put(ActionTypes.requestPasswordReset.success());
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.requestPasswordReset.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.requestPasswordReset.failure());
    throw e;
  }
}

export function* requestPasswordResetWatcherSaga() {
  while (true) {
    const {
      payload: { email },
    } = yield take(ActionTypes.requestPasswordReset.REQUEST);
    yield call(requestPasswordResetSaga, email);
  }
}

export function* confirmPasswordResetSaga(password, code = '', finishAllSession) {
  try {
    const { status, data } = yield call(api.confirmPasswordReset, password, code, finishAllSession);
    if (status < 300) {
      yield put(ActionTypes.confirmPasswordReset.success());
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.confirmPasswordReset.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.confirmPasswordReset.failure());
    throw e;
  }
}

export function* confirmPasswordResetWatcherSaga() {
  while (true) {
    const {
      payload: { password, code, finishAllSession },
    } = yield take(ActionTypes.confirmPasswordReset.REQUEST);
    yield call(confirmPasswordResetSaga, password, code, finishAllSession);
  }
}

export default [fork(requestPasswordResetWatcherSaga), fork(confirmPasswordResetWatcherSaga)];
