import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxTextField, DefaultButton } from 'common/components';

import { createDemoDeposit } from '../../accounts/_redux';
import { getTradingAccount } from '../../_redux';

const DemoDepositForm = ({ accountId, pristine, submitting, invalid, handleSubmit, dispatch, error }) => {
  const submit = handleSubmit(values => createDemoDeposit({ ...values, accountId }, dispatch));

  return (
    <form className="DemoDepositForm" onSubmit={submit}>
      <Field
        component={ReduxTextField}
        textId="justAmount"
        name="amount"
        type="number"
        validate={[required()]}
        disabled={submitting}
      />
      {error && <p className="formError">{error}</p>}
      <DefaultButton
        textId="clientDeposit"
        type="submit"
        disabled={invalid || pristine || submitting}
        loading={submitting}
        filled
      />
    </form>
  );
};

DemoDepositForm.propTypes = {
  accountId: PropTypes.number.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

DemoDepositForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'demo-deposit',
  onSubmitSuccess: (result, dispatch, { onSuccessfulDeposit, accountId }) => {
    dispatch(getTradingAccount.request(accountId));
    onSuccessfulDeposit();
  },
})(DemoDepositForm);
export { DemoDepositForm };
