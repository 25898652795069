import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { ExportsPaginationTable } from 'backoffice/payments/_components';

const InternalTransferExportsPage = () => (
  <ExportsPaginationTable captionSlug="listOfInternalTransferExports" exportsType="internal_transfer_export" />
);

export default compose(withRouter)(InternalTransferExportsPage);
export { InternalTransferExportsPage };
