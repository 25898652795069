import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const LoginCell = ({ login, title, inTable, challenge, noColor, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div
      className={classNames('LoginCell__inner', {
        LoginCell__inTable: inTable,
        LoginCell__challenge: challenge,
        LoginCell__noColor: noColor,
      })}>
      <span className="LoginCell__type">
        <i>{title[0]}</i>
      </span>
      {!!login && <span className="LoginCell__number">{login}</span>}
      <span className="LoginCell__name">{title}</span>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

const baseProps = {
  login: PropTypes.string,
  title: PropTypes.string.isRequired,
};

LoginCell.propTypes = {
  ...baseProps,
  inTable: PropTypes.bool,
  theme: PropTypes.object,
};

LoginCell.defaultProps = {
  inTable: false,
  theme: {},
};

const ThemedLoginCell = withTheme()(LoginCell);

ThemedLoginCell.propTypes = baseProps;

export default ThemedLoginCell;
export { LoginCell };
