import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FormMessage {
    padding: 15px 25px;
    display: inline-block;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    z-index: -2;
    opacity: 0;
    transition: all 0.4s ease;
    @include respond-to(sm) {
      left: 10px;
      transform: translateX(0) translateY(20px);
      right: 10px;
      text-align: center;
      font-size: 12px;
      padding: 10px 15px;
    }
    p {
      position: relative;
      display: inline;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .FormMessage {
      transition: none;
    }
  }

  .FormMessage__active {
    z-index: 25;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    @include respond-to(sm) {
      transform: translateX(0) translateY(0);
    }
  }

  .success {
    p {
      padding-left: 35px;
      @include respond-to(sm) {
        padding-left: 25px;
      }
      &:after {
        content: '';
        height: 2px;
        width: rem(23px);
        transform: rotate(-51deg);
        position: absolute;
        left: rem(6px);
        top: rem(8px);
        @include respond-to(sm) {
          width: rem(18px);
          left: rem(3px);
          top: rem(5px);
        }
      }
      i {
        height: 2px;
        width: rem(13px);
        transform: rotate(50deg);
        position: absolute;
        left: 0;
        top: rem(12px);
        @include respond-to(sm) {
          width: rem(9px);
          top: rem(8px);
        }
      }
    }
  }

  .fail {
    p {
      padding-left: 25px;
      @include respond-to(sm) {
        padding-left: 20px;
      }
      &:after,
      i {
        position: absolute;
        left: 0;
        top: -4px;
        height: 25px;
        width: 2px;
        @include respond-to(sm) {
          height: 20px;
          top: -3px;
        }
      }
      &:after {
        content: '';
        transform: rotate(45deg);
      }
      i {
        transform: rotate(-45deg);
      }
    }
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .FormMessage {
      border-radius: ${border.radius};
    }

    .success {
      background: ${rgba([colors.warning.rgb, 0.33])};
      p:after,
      p i {
        background: ${colors.success.hex};
      }
    }
    .fail {
      background: ${rgba([colors.error.rgb, 0.33])};
      p:after,
      p i {
        background: ${colors.error.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
