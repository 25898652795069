import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { compose } from 'redux';
import PropTypes from 'prop-types';

import { getAllCrmTasks } from 'crm/tasks/_redux';
import TasksTable from '../TasksTable';

import { staticStyles } from './style';
import InnerTabs from '../InnerTabs';

const AllTasksWrapper = ({ allOpenTasks, allClosedTasks, allTasksAreLoaded, getAllCrmTasks, location }) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const [currentInnerTab, setCurrentInnerTab] = useState('open');

  const currentlySelectedTasks = useMemo(
    () => (currentInnerTab === 'open' ? allOpenTasks : allClosedTasks),
    [currentInnerTab, allOpenTasks, allClosedTasks]
  );

  useEffect(() => {
    getAllCrmTasks({ search: query });
  }, [query]);
  return (
    <div className="AllTasksWrapper">
      <InnerTabs
        currentTab={currentInnerTab}
        setCurrentInnerTab={setCurrentInnerTab}
        openCount={allOpenTasks?.count}
        closedCount={allClosedTasks?.count}
      />
      <TasksTable tasks={currentlySelectedTasks} tasksAreLoaded={allTasksAreLoaded} />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

AllTasksWrapper.propTypes = {
  allOpenTasks: PropTypes.object.isRequired,
  allClosedTasks: PropTypes.object.isRequired,
  getAllCrmTasks: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      allOpenTasks: state.crm.tasks.allOpenTasks,
      allClosedTasks: state.crm.tasks.allClosedTasks,
      allTasksAreLoaded: state.crm.tasks.allTasksAreLoaded,
    }),
    {
      getAllCrmTasks: search => getAllCrmTasks.request(search),
    }
  )
)(AllTasksWrapper);
