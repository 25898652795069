import React from 'react';

const DepositsIcon = () => (
  <div>
    <svg viewBox="0 0 18 17" version="1.1">
      <defs />
      <g id="-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-527.000000, -713.000000)" fill="#000000" fillRule="nonzero">
          <g id="deposits" transform="translate(527.000000, 713.000000)">
            <path
              d="M16.2106299,3.07725702 L15.2185039,3.07725702 L14.2355906,0.370475162 C14.203017,
              0.239221257 14.1197118,0.127664171 14.0052756,0.0620518359 C13.8907404,-0.00473331626 13.7542526,
              -0.0180761199 13.629685,0.0253347732 L6.75354331,3.07285097 C6.75,3.07285097 6.74645669,
              3.07652268 6.73937008,3.07652268 L1.77874016,3.07652268 C0.797555756,3.07672354 0.00175872608,
              3.90004379 0,4.91678186 L0,15.1454212 C0.00175519002,16.1606084 0.795510647,16.9831272 1.77519685,
              16.984946 L16.2070866,16.984946 C17.1867728,16.9831272 17.9805283,16.1606084 17.9822835,
              15.1454212 L17.9822835,4.91678186 C17.9832244,4.42924748 17.7969841,3.96134723 17.4646326,
              3.61626345 C17.132281,3.27117967 16.6811162,3.07725604 16.2106299,3.07725702 Z M9,
              3.07762419 C8.7933321,3.10005646 8.7933321,3.07418173 9,3 C9.2066679,2.92581827 10.6849356,
              2.30143382 13.4348031,1.12684665 L14.2267323,3.07762419 L9,3.07762419 Z M11.3651575,
              10.8711879 L11.3651575,9.1711879 C11.364969,9.03382276 11.4175455,8.90202756 11.5112807,
              8.80489564 C11.6050159,8.70776371 11.7322023,8.65328206 11.8647638,8.65347732 L17.0255906,
              8.6534768 L17.0255906,11.3888985 L11.8665354,11.3888985 C11.5910141,11.3878914 11.367901,
              11.1566934 11.3669291,10.8711879 L11.3651575,10.8711879 Z M10.4084646,9.17485961 L10.4084646,
              10.8748596 C10.4096354,11.7077936 11.0609574,12.3827176 11.8647638,12.3839309 L17.0255906,
              12.3839309 L17.0255906,15.1487257 C17.0242286,15.616568 16.6585685,15.9954786 16.2070866,
              15.9968898 L1.77874016,15.9968898 C1.32725826,15.9954786 0.961598166,15.616568 0.96023622,
              15.1487257 L0.96023622,4.91678186 C0.961598166,4.44893956 1.32725826,4.07002901 1.77874016,
              4.06861771 L16.2106299,4.06861771 C16.6621118,4.07002901 17.0277719,4.44893956 17.0291339,
              4.91678186 L17.0291339,7.66211663 L11.8665354,7.66211663 C11.0620755,7.66514736 10.411206,
              8.34124344 10.4102362,9.17485961 L10.4084646,9.17485961 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default DepositsIcon;
