import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SecurityPanelContent {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .SecurityPanelContent {
      background-color: ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
