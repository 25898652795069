import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .FaqPage {
    .FaqPage {
      &__search {
        .SearchPanel__input-inner {
          width: 100%;
        }
      }

      &__categories {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
`

export { staticStyles }
