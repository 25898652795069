import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { getFixedNumber } from 'common/dashboards/helpers';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const RadialChart = ({ percent, color, gradientEndColor, captionId, amountNow, target, amountLastMonth, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const options = {
    chart: {
      fontFamily: 'ubuntu-font, sans-serif',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1500,
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
          background: '#fff',
          position: 'front',
        },
        track: {
          background: '#F2F2F2',
        },
        dataLabels: {
          value: {
            formatter(val) {
              return `${val}%`;
            },
            offsetY: -3,
            fontSize: '28px',
            color,
            show: true,
          },
        },
      },
    },
    colors: [color],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [gradientEndColor],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  };

  const series = [getFixedNumber(percent, 2)];

  return (
    <div className="RadialChart">
      <h3>{captionId}</h3>

      <div className="RadialChart__chart-wrapper">
        <div className="RadialChart__chart">
          <Chart options={options} series={series} type="radialBar" height="270" />
        </div>
        <div className="RadialChart__description">
          <div className="Description__inner">
            <FormattedMessage id="justNow" />
            <p>{getFixedNumber(amountNow, 2)}</p>
          </div>
          <div className="Description__inner">
            <FormattedMessage id="justTarget" />
            <p>{getFixedNumber(target, 2)}</p>
          </div>
          <div className="Description__inner">
            <FormattedMessage id="lastMonth" />
            <p>{getFixedNumber(amountLastMonth, 2)}</p>
          </div>
        </div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

RadialChart.propTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  gradientEndColor: PropTypes.string.isRequired,
  captionId: PropTypes.string.isRequired,
  amountNow: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
  amountLastMonth: PropTypes.number.isRequired,
  theme: PropTypes.object,
};

RadialChart.defaultProps = {
  theme: {},
};

export default withTheme()(RadialChart);
export { RadialChart };
