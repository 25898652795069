import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import whiteLogo from '../../_public/images/whiteLogo.svg';
import { LoginForm, CrossIcon, ActionsButton } from '../../_components';
import wsAuthBackground from '../../_public/images/wsAuthBackground.png';

import { staticStyles } from './style';

const LoginPage = ({ location, intl }) => {
  const history = useHistory();
  const query = queryString.parse(location.search);

  useEffect(() => {
    const wasIntroPageReaded = localStorage.getItem('wasIntroPageReaded');
    if (!wasIntroPageReaded) history.push('/welcome-setup/intro');
  }, []);

  return (
    <div className="LoginPage">
      <Helmet>
        <title>{intl.formatMessage({ id: 'welcomeSetupLoginTitle' })}</title>
      </Helmet>
      <img className="LoginPage__background-image" src={wsAuthBackground} alt="ws-auth-background" />
      <div className="LoginPage__inner">
        <div className="LoginPage__info-block">
          <div className="innerHeader">
            <img src={whiteLogo} alt="uptr-logo" />
            <ActionsButton
              tooltipId="Edit__tooltip"
              tooltipTextId="justBackToRegistration"
              edit
              onClickFunc={() => history.push('/welcome-setup/registration')}>
              <CrossIcon />
            </ActionsButton>
          </div>
          <FM id="welcomeSetupLogin">{txt => <span className="innerLocationText">{txt}</span>}</FM>
        </div>
        {query.message && <FM id={query.message}>{txt => <div className="LoginPage__message">{txt}</div>}</FM>}
        <LoginForm />
      </div>

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

LoginPage.propTypes = {
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LoginPage);
