import React from 'react';
import PropTypes from 'prop-types';

const ColumnFilter = ({ column }) => (
  <input
    value={column.filterValue || ''}
    onChange={e => {
      column.setFilter(e.target.value || undefined);
    }}
    placeholder="Search"
  />
);

ColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

export default ColumnFilter;
