import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ImportCsvForm__form {
    padding: 0 25px 10px 25px;
    position: relative;
    margin-top: 20px;
    min-width: 500px;
    max-width: 500px;
    @include respond-to(sm) {
      padding: 0 20px 15px;
      margin-top: 0;
      min-width: 100%;
    }

    &-caption {
      margin-bottom: 12px;
    }
  }

  .ImportCsvForm__form-field {
    position: relative;

    :global(.TextAreaField__inp) {
      padding-bottom: 30px;
    }
  }

  :global(.ImportCsvForm__attach) {
    margin-left: 0;
  }

  :global(.FileField__wrapper) {
    display: none;

    &.active {
      display: block;
    }
  }

  :global(.FileField__wrapper.active) {
    display: block;
  }

  .ImportCsvForm__form-input {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }

  .ImportCsvForm__form-button {
    display: flex;
    align-items: center;

    :global(.DefaultButton) {
      margin-right: 15px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .ImportCsvForm__form {
      background-color: ${colors.white.hex};

      &-caption {
        color: ${colors.gray.hex};
      }
    }

    .ImportCsvFormSwitcher__tab {
      font-size: 15px;
      margin-right: ${isRTL ? '0' : '30px'};
      margin-left: ${isRTL ? '30px' : '0'};
      padding-bottom: 5px;
      cursor: pointer;
      border-bottom: 3px solid transparent;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: ${colors.primary.hex};
        border-bottom-color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
