import { put, call, fork, take } from 'redux-saga/effects';
// import { SubmissionError } from 'redux-form';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getChallengesSaga() {
  try {
    const { data } = yield call(api.getChallenges);
    yield put(ActionTypes.getChallenges.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getChallenges.failure());
  }
}

export function* getChallengesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CHALLENGES[REQUEST]);
    yield call(getChallengesSaga);
  }
}

export function* getUserChallengesSaga() {
  try {
    const { data } = yield call(api.getUserChallenges);
    yield put(ActionTypes.getUserChallenges.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserChallenges.failure());
  }
}

export function* getUserChallengesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_USER_CHALLENGES[REQUEST]);
    yield call(getUserChallengesSaga);
  }
}

export function* activateChallengeSaga({ id }) {
  try {
    const { data, status } = yield call(api.activateChallenge, id);

    console.log({ status });

    if (status < 300) {
      console.log('succ', { status });
      yield put(ActionTypes.activateChallenge.success(data));
      yield call(getUserChallengesSaga);
    } else {
      // FIXME: Ошибка не отображается в форме, а форма сама закрывается
      console.log('fail');
      const error = data.detail ? data.detail : data;
      yield put(acceptError(error, false));
      // yield put(ActionTypes.activateChallenge.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.activateChallenge.failure());
  }
}

export function* activateChallengeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.activateChallenge.REQUEST);
    yield call(activateChallengeSaga, payload);
  }
}

export function* getChallengeSaga({ id }) {
  try {
    const { data } = yield call(api.getChallenge, id);
    yield put(ActionTypes.getChallenge.success(data));
  } catch (e) {
    yield put(acceptError(e, false));
    yield put(ActionTypes.getChallenge.failure());
  }
}

export function* getChallengeWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_CHALLENGE[REQUEST]);
    yield call(getChallengeSaga, payload);
  }
}

export function* restartChallengeSaga({ id }) {
  try {
    const { data } = yield call(api.restartChallenge, id);
    yield put(ActionTypes.restartChallenge.success(data));
    yield call(getUserChallengesSaga);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.restartChallenge.failure());
  }
}

export function* restartChallengeWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.RESTART_CHALLENGE[REQUEST]);
    yield call(restartChallengeSaga, payload);
  }
}

export function* archiveChallengeSaga({ id }) {
  try {
    const { data } = yield call(api.archiveChallenge, id);
    yield put(ActionTypes.archiveChallenge.success(data));
    yield call(getUserChallengesSaga);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.archiveChallenge.failure());
  }
}

export function* archiveChallengeWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.ARCHIVE_CHALLENGE[REQUEST]);
    yield call(archiveChallengeSaga, payload);
  }
}

export default [
  fork(getChallengesWatcherSaga),
  fork(getUserChallengesWatcherSaga),
  fork(activateChallengeWatcherSaga),
  fork(getChallengeWatcherSaga),
  fork(restartChallengeWatcherSaga),
  fork(archiveChallengeWatcherSaga),
];
