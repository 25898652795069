import { action, createRequestTypes, REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';

export const GET_CLIENT_CUSTOM_PAGES = createRequestTypes('GET_CLIENT_CUSTOM_PAGES');
export const getClientCustomPages = {
  request: () => action(GET_CLIENT_CUSTOM_PAGES[REQUEST]),
  success: data => action(GET_CLIENT_CUSTOM_PAGES[SUCCESS], { data }),
  failure: error => action(GET_CLIENT_CUSTOM_PAGES[FAILURE], { error }),
};

export const GET_BACKOFFICE_CUSTOM_PAGES = createRequestTypes('GET_BACKOFFICE_CUSTOM_PAGES');
export const getBackofficeCustomPages = {
  request: () => action(GET_BACKOFFICE_CUSTOM_PAGES[REQUEST]),
  success: data => action(GET_BACKOFFICE_CUSTOM_PAGES[SUCCESS], { data }),
  failure: error => action(GET_BACKOFFICE_CUSTOM_PAGES[FAILURE], { error }),
};

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const toggleMenu = () => action(TOGGLE_MENU);

export const CLOSE_MENU = 'CLOSE_MENU';
export const closeMenu = () => action(CLOSE_MENU);

export const GET_PROFILE = createRequestTypes('GET_PROFILE');
export const getProfile = {
  request: () => action(GET_PROFILE[REQUEST]),
  success: data => action(GET_PROFILE[SUCCESS], { data }),
  failure: error => action(GET_PROFILE[FAILURE], { error }),
};

export const STOP_IMPERSONATE = createRequestTypes('STOP_IMPERSONATE');
export const stopImpersonate = {
  request: () => action(STOP_IMPERSONATE[REQUEST]),
  success: () => action(STOP_IMPERSONATE[SUCCESS]),
  failure: error => action(STOP_IMPERSONATE[FAILURE], { error }),
};

export const START_WS = 'START_WS';
export const startWS = () => action(START_WS);

export const STOP_WS = 'STOP_WS';
export const stopWS = () => action(STOP_WS);

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const profileUpdate = data => action(PROFILE_UPDATE, { data });

export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX';
export const openLightBox = (id, lightBoxImages) => action(OPEN_LIGHTBOX, { id, lightBoxImages });

export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX';
export const closeLightBox = () => action(CLOSE_LIGHTBOX);

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = name => action(OPEN_MODAL, { name });

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = name => action(CLOSE_MODAL, { name });

export const CHANGE_CURRENT_INTERFACE = 'CHANGE_CURRENT_INTERFACE';
export const changeCurrentInterface = data => action(CHANGE_CURRENT_INTERFACE, { data });
