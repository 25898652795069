import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BOUsersTable {
    position: relative;

    :global(.RowLink) {
      justify-content: flex-start;
    }

    :global(.ReactDataTable) {
      tr {
        grid-template-columns:
          minmax(50px, 1fr) minmax(120px, 2fr) repeat(2, minmax(140px, 3fr)) minmax(140px, 3fr) repeat(
            4,
            minmax(100px, 2fr)
          )
          minmax(60px, 1fr);
      }
    }

    :global(.ReactDataTable__wrapper) {
      @include respond-to(xl) {
        overflow-x: auto;
      }
    }

    &--withManagers {
      :global(.ReactDataTable) {
        tr {
          grid-template-columns:
            minmax(50px, 1fr) minmax(120px, 2fr) repeat(2, minmax(140px, 3fr)) repeat(5, minmax(100px, 2fr))
            minmax(60px, 1fr);
        }
      }
    }

    &--withStatuses {
      :global(.ReactDataTable) {
        tr {
          grid-template-columns:
            minmax(50px, 1fr) minmax(120px, 2fr) repeat(2, minmax(140px, 3fr)) minmax(140px, 3fr) repeat(
              5,
              minmax(100px, 2fr)
            )
            minmax(60px, 1fr);
        }
      }
    }

    &--withManagersAndCall {
      :global(.ReactDataTable) {
        tr {
          grid-template-columns:
            minmax(50px, 1fr) minmax(120px, 2fr) repeat(2, minmax(140px, 3fr)) repeat(6, minmax(100px, 2fr))
            minmax(60px, 1fr);
        }
      }
    }

    :global(img) {
      width: 35px;
      min-width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    .BOUsersTable__client {
      font-size: rem(15px);
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        flex: 60;
        text-align: center;
        padding: 5px 0;

        &:first-child {
          padding-left: rem(9px);
          flex: 1;
          opacity: 0;

          @include respond-to(xs) {
            padding-left: rem(35px);
          }
        }

        &:last-child {
          min-width: 60px;
          padding-right: rem(30px);
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;
      .ResponsiveTable__preview-main {
        height: 40px;
        .ResponsiveTable__row-item {
          flex: 60;
          display: flex;
          align-items: center;
          justify-content: center;
          text-overflow: ellipsis;

          &:first-child {
            flex: 35 0 auto !important;
            width: 35px !important;

            @include respond-to(sm) {
              max-width: 55px;
              flex: 55;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            margin: 0 auto;
            flex: 120 0 auto !important;
            width: 120px !important;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            @include respond-to(sm) {
              max-width: 160px;
              display: flex;
              justify-content: flex-start;
            }

            @include respond-to(xs) {
              max-width: 120px;
            }
          }
        }
      }

      .ResponsiveTable__preview-description {
        .ResponsiveTable__row-item {
          justify-content: flex-start;
          padding-left: 19%;
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 18%;
      }
    }

    .BOUsersTable__action-buttons {
      text-align: right;
      margin-block-end: rem(10px);

      :global(.DefaultButton) {
        margin-inline-start: rem(20px);
        margin-block-end: rem(10px);
      }
    }
    .BOUsersTable__IbAccounts {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
    .BOUsersTable__provider-popup {
      position: absolute;
      background: grey;
      padding: 10px;
      z-index: 1;
      top: 0;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .BOUsersTable {
    }
  `;
};

export { staticStyles, getDynamicStyles };
