import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import { createContactsExports } from 'crm/contacts/_redux';
import { staticStyles } from './style';

const SaveSelectionForm = ({ dispatch, handleSubmit, submitting, error }) => {
  const selectedContactsId = useSelector(state => state.crm.contacts.selectedContactsIds);

  const submit = handleSubmit(() => createContactsExports({ contactsId: selectedContactsId }, dispatch));
  return (
    <form className="SaveSelectionForm">
      <BaseButton type="submit" textId="justSave" onClick={submit} maxContent disabled={submitting} />
      {error && <span>{error}</span>}
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-reassign-manager',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmReportsWereCreated');
      props.onRequestClose();
    },
  })
)(SaveSelectionForm);
