import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const SecurityIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 16 21" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="4.248%" y1="86.836%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="Symbols" fill="none" fillRule="evenodd">
        <g
          id="menu-/-item-/-admin-settings"
          transform="translate(-27 -10)"
          fill="url(#linearGradient-4)"
          fillRule="nonzero">
          <g transform="translate(27 10)">
            <path d="M14.8441738,9.15161558 C15.481871,9.15161558 16,9.66052196 16,10.2868683 L16,17.5985915 C16,19.4732809 14.4456131,21 12.5369499,21 L3.4630501,21 C1.55438694,21 0,19.4732809 0,17.5985915 L0,10.2868683 C0,9.66052196 0.518128979,9.15161558 1.15582618,9.15161558 L13.3340714,9.15161558 L13.3340714,6.41570008 C13.3340714,3.52319801 10.9382784,1.17439934 7.99778577,1.17439934 C5.05286466,1.17439934 2.66150014,3.52754764 2.66150014,6.41570008 C2.66150014,6.74192212 2.39579297,7.00289975 2.06365901,7.00289975 C1.73152505,7.00289975 1.46581788,6.74192212 1.46581788,6.41570008 C1.46581788,2.87510356 4.39745364,0 7.99778577,0 C11.6025464,0 14.5297537,2.87945319 14.5297537,6.41570008 L14.5297537,9.15161558 L14.8441738,9.15161558 Z M8,18 C9.1049505,18 10,17.1009901 10,16 C10,14.8950495 9.1009901,14 8,14 C6.8950495,14 6,14.8990099 6,16 C6,17.1009901 6.8950495,18 8,18 Z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

SecurityIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

SecurityIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedSecurityIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(SecurityIconActive);

export default ThemedSecurityIconActive;
