import React from 'react';

export const CrmReportsIconActive = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
      <path
        d="M14.7695 4.89453L10.6094 0.734375C10.1367 0.263359 9.50391 0 8.83984 0H3C1.61914 0 0.5 1.11914 0.5 2.5L0.500254 17.5C0.500254 18.8809 1.61939 20 3.00025 20H13C14.3809 20 15.5 18.8809 15.5 17.5V6.66016C15.5 5.99609 15.2383 5.36328 14.7695 4.89453ZM9.25 1.33125C9.42488 1.39242 9.58852 1.48148 9.72344 1.61637L13.8836 5.77652C14.0195 5.91016 14.1094 6.07422 14.168 6.25H9.875C9.53125 6.25 9.25 5.96875 9.25 5.625V1.33125ZM14.25 17.5C14.25 18.1891 13.6891 18.75 13 18.75H3C2.31094 18.75 1.75 18.1891 1.75 17.5V2.5C1.75 1.81094 2.31094 1.25 3 1.25H8V5.625C8 6.66016 8.83984 7.5 9.875 7.5H14.25V17.5ZM3.625 3.75H6.125C6.46875 3.75 6.75 3.47031 6.75 3.125C6.75 2.77969 6.46875 2.5 6.125 2.5H3.625C3.27969 2.5 3 2.77969 3 3.125C3 3.47031 3.27969 3.75 3.625 3.75ZM3.625 6.25H6.125C6.46875 6.25 6.75 5.96875 6.75 5.625C6.75 5.28125 6.46875 5 6.125 5H3.625C3.27969 5 3 5.28125 3 5.625C3 5.96875 3.27969 6.25 3.625 6.25ZM7.55469 14.1953L7.0125 12.5691C6.88672 12.1836 6.53125 11.9258 6.125 11.9258C5.71875 11.9258 5.36328 12.1846 5.23633 12.5703L4.52344 14.7031C4.46484 14.8789 4.29883 15 4.10859 15H3.625C3.27852 15 3 15.2773 3 15.625C3 15.9727 3.27852 16.25 3.625 16.25H4.10859C4.83594 16.25 5.48086 15.7859 5.71016 15.0977L6.125 13.8516L6.78516 15.8203C6.86328 16.0645 7.07812 16.2305 7.33203 16.25H7.375C7.57422 16.25 7.82891 16.1182 7.93164 15.9033L8.23438 15.3025C8.3418 15.0877 8.54199 15.0633 8.625 15.0633C8.70801 15.0633 8.9082 15.0877 9.02031 15.3172C9.30859 15.8945 9.89062 16.25 10.5312 16.25H12.375C12.7217 16.25 13 15.9717 13 15.625C13 15.2783 12.7227 15 12.375 15H10.5293C10.3682 15 10.2119 14.9023 10.134 14.7461C9.67188 13.8125 8.32812 13.5586 7.55469 14.1953Z"
        fill="white"
      />
    </svg>
  </div>
);

export default CrmReportsIconActive;
