import css from 'styled-jsx/css';

const staticStyles = css`
  .EditTemplateForm {
    &__top {
      display: flex;

      :global(.ReduxTextField) {
        width: 100%;
        margin-right: 15px;
      }
      :global(.ReduxSelectField) {
        max-width: 300px;
        width: 100%;
      }
    }

    &__btns {
      :global(.DefaultButton) {
        margin-right: 30px;
      }
    }
  }
`;

export default staticStyles;
