import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const MailIcon = ({ gradientStartColor, gradientEndColor }) => (
  <svg viewBox="0 0 26 19" version="1.1">
    <defs>
      <linearGradient x1="49.9102156%" y1="100.052329%" x2="49.9102156%" y2="0.0348610068%" id="linearGradient-1">
        <stop stopColor={gradientStartColor.hex} offset="0%" />
        <stop stopColor={gradientEndColor.hex} offset="100%" />
      </linearGradient>
    </defs>
    <g id="-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="ui---blocks"
        transform="translate(-278.000000, -758.000000)"
        className="white"
        fill="url(#linearGradient-1)"
        fillRule="nonzero">
        <g id="mail-blue" transform="translate(278.000000, 758.000000)">
          <path
            d="M25.4090909,0 L0.590909091,0 C0.264136364,0 0,0.26540625 0,0.59375 L0,18.40625 C0,18.7345937 0.264136364,19 0.590909091,19 L25.4090909,19 C25.7352727,19 26,18.7345937 26,18.40625 L26,0.59375 C26,0.26540625 25.7352727,0 25.4090909,0 Z M24.8181818,16.3762187 L17.498,9.0214375 L16.6624545,9.861 L24.5759091,17.8125 L1.42409091,17.8125 L9.33754545,9.861 L8.502,9.0214375 L1.18181818,16.3762187 L1.18181818,1.1875 L24.8181818,1.1875 L24.8181818,16.3762187 Z"
            id="Shape"
          />
          <path
            d="M22.837925,1 L12.5,11.5793829 L2.162075,1 L1,1 L1,1.47486301 L12.093475,12.8274429 C12.3183,13.057519 12.6817,13.057519 12.906525,12.8274429 L24,1.47486301 L24,1 L22.837925,1 Z"
            id="Shape"
          />
          <path
            d="M25.4090909,0 L0.590909091,0 C0.264136364,0 0,0.26540625 0,0.59375 L0,18.40625 C0,18.7345937 0.264136364,19 0.590909091,19 L25.4090909,19 C25.7352727,19 26,18.7345937 26,18.40625 L26,0.59375 C26,0.26540625 25.7352727,0 25.4090909,0 Z M24.8181818,16.3762187 L17.498,9.0214375 L16.6624545,9.861 L24.5759091,17.8125 L1.42409091,17.8125 L9.33754545,9.861 L8.502,9.0214375 L1.18181818,16.3762187 L1.18181818,1.1875 L24.8181818,1.1875 L24.8181818,16.3762187 Z"
            id="Shape"
          />
          <path
            d="M22.837925,1 L12.5,11.5793829 L2.162075,1 L1,1 L1,1.47486301 L12.093475,12.8274429 C12.3183,13.057519 12.6817,13.057519 12.906525,12.8274429 L24,1.47486301 L24,1 L22.837925,1 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

MailIcon.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

MailIcon.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedMailIcon = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(MailIcon);

export default ThemedMailIcon;
