/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import classNames from 'classnames';

import queryString from 'query-string';

import { UpdatedCRMTable, NewCRMPagination } from 'crm/_newComponents';

import { PageContentPreloader, DateCell, MoneyCell } from 'common/components';

import NewFullNameCell from 'crm/contacts/_components/NewContactsTable/NewFullNameCell';
import { getCrmManagersAnalytics } from 'crm/analytics/_redux';

import { staticStyles } from './style';

const ManagersTable = ({ managers, managersAreLoaded, getCrmManagersAnalytics, location }) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const tableSetup = {
    columns: [
      {
        onTopInMobile: true,
        accessor: 'id',
        isSortable: true,
        Header: 'justId',
        Cell: ({ rowData: { id } }) => id,
      },
      {
        accessor: 'fullName',
        Header: 'justName',
        onTopInMobile: true,
        isSortable: true,
        Cell: ({ rowData: { id, fullName, isManager } }) => (
          <NewFullNameCell id={id} analytics fullName={fullName} isManager={isManager} />
        ),
      },
      {
        accessor: 'lastSeen',
        isSortable: true,
        Header: 'justLastSeen',
        Cell: ({ rowData: { lastSeen } }) => (lastSeen ? <DateCell value={lastSeen} hideTime grey /> : '---'),
        removeInMobile: true,
      },
      {
        accessor: 'totalIn',
        isSortable: true,
        Header: 'justIn',
        HeaderTop: <MoneyCell heightMaxContent crmStyle value={managers?.total?.sum_total_in} />,
        Cell: ({ rowData: { totalIn } }) => <MoneyCell crmStyle heightMaxContent value={totalIn} />,
        removeInMobile: true,
      },
      {
        accessor: 'totalOut',
        isSortable: true,
        Header: 'justOut',
        HeaderTop: <MoneyCell crmStyle heightMaxContent value={managers?.total?.sum_total_out} />,
        Cell: ({ rowData: { totalOut } }) => <MoneyCell crmStyle heightMaxContent value={totalOut} />,
        removeInMobile: true,
      },
      {
        accessor: 'totalDiff',

        isSortable: true,
        Header: 'justInAndOut',
        HeaderTop: <MoneyCell crmStyle heightMaxContent value={managers?.total?.sum_total_dif} />,
        Cell: ({ rowData: { totalDiff } }) => <MoneyCell crmStyle heightMaxContent value={totalDiff} />,
        removeInMobile: true,
      },
      {
        accessor: 'clientsCount',
        removeInMobile: true,
        isSortable: true,
        Header: 'justClientsCount',
        HeaderTop: (
          <div className="ManagersTable__CustomMoneyHeaderCellWrapper">{managers?.total?.sum_clients_count}</div>
        ),
        Cell: ({ rowData: { managerClientsCount } }) => managerClientsCount,
      },
      {
        accessor: 'tasksOpenCount',
        removeInMobile: true,
        isSortable: true,
        Header: 'crmTasksOpenCount',
        HeaderTop: (
          <div className="ManagersTable__CustomMoneyHeaderCellWrapper">{managers?.total?.sum_tasks_open_count}</div>
        ),
        Cell: ({ rowData: { tasksOpenCount } }) => tasksOpenCount,
      },
      {
        accessor: 'tasksClosedCount',
        removeInMobile: true,
        isSortable: true,
        blueBg: true,
        Header: 'crmTasksClosedCount',
        HeaderTop: (
          <div className="ManagersTable__CustomMoneyHeaderCellWrapper">{managers?.total?.sum_tasks_closed_count}</div>
        ),
        Cell: ({ rowData: { tasksClosedCount } }) => tasksClosedCount,
      },
      {
        accessor: 'tasksAllCount',
        isSortable: true,
        removeInMobile: true,
        Header: 'crmTasksAllCount',
        HeaderTop: (
          <div className="ManagersTable__CustomMoneyHeaderCellWrapper">{managers?.total?.sum_tasks_all_count}</div>
        ),
        Cell: ({ rowData: { tasksAllCount } }) => tasksAllCount,
      },
    ],
    initSubRow: [],
  };

  useEffect(() => {
    getCrmManagersAnalytics({ search: query });
  }, [query]);

  return (
    <div className={classNames('ManagersTable')}>
      <PageContentPreloader ready={managersAreLoaded} type="crmTable" noPadding>
        <UpdatedCRMTable data={managers.results} tableSetup={tableSetup} whereOpenSubRowIconIndex={1} />
        {!!managers.count && <NewCRMPagination count={managers.count} />}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ManagersTable.propTypes = {
  managers: PropTypes.object.isRequired,
  managersAreLoaded: PropTypes.bool.isRequired,
  getCrmManagersAnalytics: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      managers: state.crm.analytics.managers,
      managersAreLoaded: state.crm.analytics.managersAreLoaded,
    }),
    {
      getCrmManagersAnalytics: search => getCrmManagersAnalytics.request(search),
    }
  )
)(ManagersTable);
