import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .TabFeedItem__Icon {
    display: flex;
    width: 48px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    margin: 0 24px 0 0;

    border-radius: 48px;
    border: 2px solid #f3f3f3;

    @include respond-to(xs) {
      position: absolute;
      top: 5px;
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: 32px;

      img {
        width: 14px;
      }
    }

    &--success {
      border-color: #39b841;
    }

    &--warning {
      border-color: #f2b233;
    }

    &--failed {
      border-color: #ee3235;
    }

    &--task {
      border-color: #0aa1e2;
    }

    &--accounts {
      min-height: 48px;
      border-color: #333;

      @include respond-to(xs) {
        min-height: 32px;
      }
    }

    &--withdrawals,
    &--deposits,
    &--internalTransfers {
      @include respond-to(xs) {
        top: 18px;
      }
    }

    &--impersonate {
      img {
        transform: translateY(1px);
      }
    }

    &--accountcreate {
      img {
        transform: translateY(1px);
      }
    }

    &--userstatuschanged {
      img {
        transform: translateY(1px);
      }
    }

    &--registration {
      img {
        transform: translateY(1px);
      }
    }

    &--internaltransferchange {
      img {
        transform: translateY(1px);
      }
    }

    &--requestchange {
      img {
        transform: translateY(1px);
      }
    }

    &--login {
      img {
        transform: translateY(1px);
      }
    }

    &--bonusactivate {
      img {
        transform: translateY(1px);
      }
    }
  }
`;

export { staticStyles };
