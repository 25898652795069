import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .LeverageCell__inner {
    height: rem(45px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: rem(50px);
    p {
      line-height: 0;
      display: inline-block;
      font-size: rem(16px);
      &:last-child {
        margin-left: rem(5px);
      }
    }
  }
`

export { staticStyles }
