import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';

import PropTypes from 'prop-types';

import { NewDefaultButton } from '..';

import { staticStyles } from './style';

import { CustomizationIcon, LanguageIcon, MailIcon, PartnershipIcon, StaffIcon, CheckIcon } from './icons';

const NavToCRMBlock = ({ userDomain }) => {
  const cleanUserDomain = userDomain.replace(/^https?:\/\/|\//g, '');
  const items = [
    {
      title: 'welcomeSetupNavToCRMStaffAndManagers',
      Icon: StaffIcon,
      link: `https://${cleanUserDomain}/admin/staff/staff`,
    },
    {
      title: 'welcomeSetupNavToCRMPartnership',
      Icon: PartnershipIcon,
      link: `https://${cleanUserDomain}/admin/PartnershipCategory/PartnershipSetting`,
    },
    {
      title: 'welcomeSetupNavToCRMEmailTemplates',
      Icon: MailIcon,
      link: `https://${cleanUserDomain}/admin/EmailCategory/SMTPSetting`,
    },
    {
      title: 'welcomeSetupNavToCRMLanguage',
      Icon: LanguageIcon,
      link: `https://${cleanUserDomain}/admin/CommonCategory/LanguageSetting`,
    },
    {
      title: 'welcomeSetupNavToCRMInterface',
      Icon: CustomizationIcon,
      link: `https://${cleanUserDomain}/admin/customization`,
    },
  ];

  return (
    <div className="NavToCRM">
      <div className="NavToCRM__body">
        <span className="CheckIcon">
          <CheckIcon />
        </span>
        <div className="CongratsTexts">
          <FM id="welcomeSetupNavToCRMYoureSetToGo">{txt => <span className="CongratsTexts__title">{txt}</span>}</FM>
          <FM id="welcomeSetupNavToCRMCheckOptionalConfigurations">
            {txt => <span className="CongratsTexts__instructions">{txt}</span>}
          </FM>
        </div>
        <div className="NavToCRMItems">
          {items.map(({ title, Icon, link }, ind) => (
            <div key={`NavToCRMItem-${ind}`} className="NavToCRMItem">
              <span className="IconAndTitle">
                <span className="icon">
                  <Icon />
                </span>
                <FM id={title}>{txt => <span className="title">{txt}</span>}</FM>
              </span>
              <NewDefaultButton
                type="button"
                textId="justOpen"
                onClick={() => window.open(link)}
                variant="ghost"
                iconVariant="next"
                isUpperCase
              />
            </div>
          ))}
        </div>
        <NewDefaultButton
          type="button"
          textId="welcomeSetupNavToCRMGoToCRM"
          onClick={() => window.open(`https://${cleanUserDomain}/`)}
          variant="ghost"
          isUpperCase
          wide
        />
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

NavToCRMBlock.propTypes = {
  userDomain: PropTypes.string.isRequired,
};

export default connect(state => ({ userDomain: state.welcomeSetup.userDomain }))(NavToCRMBlock);
