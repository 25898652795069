import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .VerificationPanel {
    .WarningMessage {
      padding: 20px;
      margin-bottom: 40px;
      text-align: left;
      @include respond-to(sm) {
        padding: 10px;
      }
    }
    .WarningMessage__text-inner {
      margin: 0;
    } 
  }
  @include respond-to(sm) {
    .VerificationPanel {
      position: relative;
      &.inView::after {
        display: block;
      }
      &.inView2::before {
        display: block;
      }
    }
    .VerificationPanel:before, .VerificationPanel:after {
      content: "";
      display: none;
      position: absolute;
      top: -10px;
      left: 0;
      width: 20px;
      height: 45px;
      background: transparent;
      background: linear-gradient(90deg, rgba(34, 34, 34, 0.15) 0%, rgba(34, 34, 34, 0) 100%);
    }
    .VerificationPanel:after {
      background: transparent;
      background: linear-gradient(90deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 0.15) 100%);
      left: auto;
      right: 0;
    }
  }

`

export default staticStyles
