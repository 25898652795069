import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { getCompanyNews } from 'interface/connected-intl/';
import { DateCell } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: false,
  arrows: true,
  draggable: true,
  verticalSwiping: true,
  vertical: true,
};

const CompanyNews = ({ companyNews, companyNewsAreLoaded, getCompanyNews, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getCompanyNews();
  }, [getCompanyNews]);

  return (
    <div className="CompanyNews">
      {!!companyNews.length && (
        <FormattedMessage id="justLastNews">{txt => <h2 className="CompanyNews__title">{txt}</h2>}</FormattedMessage>
      )}
      {companyNewsAreLoaded && (
        <Slider className="CompanyNews__slider" {...settings}>
          {!!companyNews.length &&
            companyNews.map(story => (
              <div className="CompanyNews__item" key={story.id}>
                <div className="CompanyNews__date">
                  <DateCell value={story.created} />
                </div>
                <h3 className="CompanyNews__name">{story.title}</h3>
                <FormattedMessage id="justSeeMoreDetails">
                  {txt => (
                    <Link className="CompanyNews__link" to={`/backoffice/news/release/${story.id}`}>
                      {txt}
                    </Link>
                  )}
                </FormattedMessage>
              </div>
            ))}
        </Slider>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

CompanyNews.propTypes = {
  companyNews: PropTypes.array.isRequired,
  companyNewsAreLoaded: PropTypes.bool.isRequired,
  getCompanyNews: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

CompanyNews.defaultProps = {
  theme: {},
};

export default connect(
  state => ({
    companyNews: state.interfaceConfig.companyNews,
    companyNewsAreLoaded: state.interfaceConfig.companyNewsAreLoaded,
  }),
  {
    getCompanyNews: () => getCompanyNews.request(),
  }
)(withTheme()(CompanyNews));

export { CompanyNews };
