import css from 'styled-jsx/css';

import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .LanguageSelectorContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .LanguageSelectorButton {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 4px;
    padding-right: 4px;
    height: 42px;
    &__Image {
      height: 100%;
      width: 100%;
      padding: 8px;
      > img {
        height: 24px;
        object-fit: cover;
      }
    }
  }

  @keyframes opacityAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .LanguageSelectorSelect {
    position: absolute;
    width: 160px;
    top: 61px;
    display: flex;
    flex-direction: column;
    z-index: 3;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    animation: opacityAnimation 0.3s 1;
    &__Item {
      cursor: pointer;
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 6px;
      &:hover {
        background: linear-gradient(62.43deg, rgba(67, 182, 232, 0.5) 0%, rgba(51, 238, 199, 0.5) 100%), #ffffff;
        box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
      }

      > span {
        color: #0c0c3d;
        &:hover {
          color: #005479;
        }
      }
    }
    &__Image {
      height: 100%;
      padding: 8px;
      > img {
        height: 24px;
        object-fit: cover;
      }
    }
  }
`;

export { staticStyles };
