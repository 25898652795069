import css from 'styled-jsx/css';

const staticStyles = css.global`
  .IssueForm {
    &__agreements {
      margin-bottom: 24px;
    }
  }
`;

export { staticStyles };
