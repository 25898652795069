import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .DownloadReportForm {
    max-width: 430px;

    :global(.DownloadReportForm__line) {
      display: flex;
      margin-top: rem(15px);

      :global(.CheckboxField) {
        margin-right: rem(20px) !important;
      }
    }
    :globel(.DownloadReportForm__btns) {
      display: flex;
      gap: 10px;
    }
  }
`;

export default staticStyles;
