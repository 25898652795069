import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from '../BaseSelectField/style';

const AsyncSelectField = ({
  textId,
  textValues,
  loadingPlaceholderId,
  searchPromptTextId,
  noResultsTextId,
  input,
  afterChange,
  loadOptions,
  minSearchLengthForLoad,
  disabled,
  meta: { touched, active, error },
  isMulti,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [searchTextId, setSearchTextId] = useState(searchPromptTextId);
  const [selectedOption, setSelectedOption] = useState(input.value || '');

  const isLengthEnough = value => value.length >= minSearchLengthForLoad;

  const getOptions = (value, callback) => {
    if (isLengthEnough(value)) {
      return loadOptions(value);
    }
    callback();
  };

  const onInputChange = value => {
    const newSearchTextId = isLengthEnough(value) ? noResultsTextId : searchPromptTextId;

    if (searchTextId !== newSearchTextId) {
      setSearchTextId(newSearchTextId);
    }
  };
  const onChange = option => {
    setSelectedOption(option);
    let value;
    if (Array.isArray(option)) {
      value = option.length ? option.map(o => o.value) : null;
    } else {
      value = option ? option.value : null;
    }
    input.onChange(value);

    if (afterChange) {
      afterChange(option);
    }
  };

  return (
    <div
      className={classNames('AsyncSelectField', 'BaseSelectField', 'ReduxSelectField', {
        ReduxSelectField__error: touched && error,
        isMulti,
      })}>
      <div>
        <Async
          {...props}
          name={input.name}
          placeholder={<FM id={searchTextId} values={{ mintextlength: minSearchLengthForLoad }} />}
          classNamePrefix="Select"
          isClearable={false}
          loadOptions={getOptions}
          onChange={onChange}
          value={selectedOption}
          onInputChange={onInputChange}
          loadingPlaceholder={loadingPlaceholderId}
          isDisabled={disabled}
          onBlur={() => input.onBlur(input.value)}
          onFocus={input.onFocus}
          blurInputOnSelect={false}
          isMulti={isMulti}
        />
        <label
          className={classNames('BaseSelectField__label haveText', { active }, { haveText: input.value })}
          htmlFor={input.name}>
          <FM id={textId} values={textValues} />
        </label>
      </div>
      <span className="ReduxSelectField__error-text">{touched && error}</span>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

AsyncSelectField.propTypes = {
  textId: PropTypes.string.isRequired,
  textValues: PropTypes.object,
  minSearchLengthForLoad: PropTypes.number,
  loadOptions: PropTypes.func.isRequired,
  noResultsTextId: PropTypes.string,
  searchPromptTextId: PropTypes.string,
  loadingPlaceholderId: PropTypes.string,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  afterChange: PropTypes.func,
  theme: PropTypes.object,
};

AsyncSelectField.defaultProps = {
  textValues: {},
  minSearchLengthForLoad: 3,
  noResultsTextId: 'noResultsTextSelectField',
  searchPromptTextId: 'searchTextAsyncSelectField',
  loadingPlaceholderId: 'loadingPlaceholderId',
  afterChange: null,
  disabled: false,
  isMulti: false,
  theme: {},
};

export default withTheme()(AsyncSelectField);
export { AsyncSelectField };
