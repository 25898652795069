import axios from 'axios';

export const getInternalTransfers = search => axios.get(`/backoffice/internal_transfers/`, { params: { ...search } });

export const getTransferDetails = transferId => axios.get(`/backoffice/internal_transfers/${transferId}/`);

export const actionTransfer = (id, actionType) => axios.post(`/backoffice/internal_transfers/${id}/${actionType}/`);

export const rejectTransfer = (id, params) => axios.post(`/backoffice/internal_transfers/${id}/reject/`, { ...params });

export const editTransfer = (id, params) => axios.post(`/backoffice/internal_transfers/${id}/edit/`, { ...params });

export const getBackofficeManagerList = search => axios.get(`/backoffice/managers/list/?search=${search}`);
