import React from 'react';
import PropTypes from 'prop-types';

import { ModalHOC } from 'common';
import { EditIcon, BasketIcon } from 'common/icons';
import { PaginationTable, ActionsButton } from 'common/components';

import { staticStyles } from './style';

const TemplatesTable = ({
  data,
  onRowClick,
  getList,
  handleDelete,
  showModal,
  loading,
  location,
  history,
  profile,
  params,
}) => {
  const rowProps = () => ({
    onClick: () => {},
  });

  const editTemplateClick = (e, id) => {
    e.stopPropagation();
    history.push(`/backoffice/templates/edit/${id}`);
  };

  const deleteTemplateClick = (e, id) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmDeleteTemplate' },
      content: {
        id: 'confirmText',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => handleDelete({ id, queryParams: params }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  /* eslint-disable */
  const columns = [
    {
      accessor: 'title',
      Header: 'justTitle',
      Cell: ({
        row: {
          original: { title },
        },
      }) => <span>{title}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'text',
      Header: 'justText',
      Cell: ({
        row: {
          original: { text },
        },
      }) => <span>{text}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'language',
      Header: 'justLanguage',
      Cell: ({
        row: {
          original: { language },
        },
      }) => <span>{language}</span>,
    },
    {
      accessor: 'action',
      Header: () => null,
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <>
          {profile.perms.templateMessagesEditPerm && (
            <ActionsButton
              tooltipId={`Edit__tooltip-${id}`}
              tooltipTextId="justEdit"
              edit
              onClickFunc={e => editTemplateClick(e, id)}>
              <EditIcon />
            </ActionsButton>
          )}
          {profile.perms.fullAccessTemplateMessagesPerm && (
            <ActionsButton
              tooltipId={`Delete__tooltip-${id}`}
              tooltipTextId="justDelete"
              deleteType
              onClickFunc={e => deleteTemplateClick(e, id)}>
              <BasketIcon />
            </ActionsButton>
          )}
        </>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="TemplatesTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        loading={loading}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TemplatesTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

TemplatesTable.defaultProps = {
  loading: false,
};

export default ModalHOC(TemplatesTable);
