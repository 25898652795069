import css from "styled-jsx/css";

import { gradient, isRTL } from "common/styling/theme";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .BigCharts {
    display: flex;
    flex-direction: column;
  }

  .BigCharts__tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .BigCharts__tabsItem {
    display: flex;
    position: relative;
    align-items: center;
    margin-right: ${isRTL ? '0' : '20px'};
    margin-left: ${isRTL ? '20px' : '0'};
    padding: 10px 0;
    cursor: pointer;

    &:after {
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -2px;
      z-index: 2;
      transition: 0.6s width ease;
      left: ${isRTL ? '0' : 'auto'};
      right: ${isRTL ? 'auto' : '0'};
    }

    &:hover,
    &_selected {
      &:after {
        width: 100%;
        left: ${isRTL ? '100%' : '0'};
        right: ${isRTL ? '0' : '100%'};
      }
    }
  }

  .BigCharts__tabsItem-index {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(25px);
    height: rem(25px);
    line-height: rem(25px);
    margin-right: ${isRTL ? '0' : '10px'};
    margin-left: ${isRTL ? '10px' : '0'};
    border-radius: 50%;
    font-size: rem(17px);
  }

  .BigCharts__tabsItem-text {
    font-size: 18px;
    margin-right: ${isRTL ? '0' : '5px'};
    margin-left: ${isRTL ? '5px' : '0'};
  }

  .BigCharts__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 500px;
    padding: 40px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);

    @include respond-to(sm) {
      height: 600px;
      padding: 20px 5px;
    }
  }

  .BigCharts__content-top-line {
    display: flex;
    justify-content: space-between;
    margin-left: 25px;
  }

  .BigCharts__account-types-list {
    display: flex;
    width: 70%;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 10px;

    @include respond-to(xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .BigCharts__accountTypesItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 30px;
    cursor: pointer;
    transition: 0.3s color ease;
    font-size: 16px;

    &:before {
      position: absolute;
      left: -10px;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }

    @include respond-to(xs) {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .BigCharts__periodItem {
    margin: 0 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: rem(14px);
  }

  .BigCharts__chart {
    width: 100%;

    :global(.apexcharts-legend-marker) {
      margin-right: ${isRTL ? '0' : '3px'} !important;
      margin-left: ${isRTL ? '3px' : '0'} !important;
    }
  }

  .BigCharts__statistics {
    display: flex;
    margin-left: 25px;

    @include respond-to(sm) {
      flex-direction: column;
      align-items: center;
    }
  }

  .BigCharts__statistical-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 30px;

    @include respond-to(sm) {
      padding: 0 5px;
      justify-content: flex-start;
    }
  }

  .statistical-item__title {
    text-align: center;
  }

  .statistical-item__info {
    margin-right: ${isRTL ? '10px' : '0'};
    margin-left: ${isRTL ? '0' : '10px'};
    text-transform: uppercase;
    font-size: 20px;

    @include respond-to(xs) {
      margin-top: 5px;
      font-size: 16px;
    }
  }

  .BigCharts__ftd-description-text {
    position: relative;
    top: 10px;
    margin-left: 50px;
    font-size: 12px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .BigCharts__tabsItem {
      color: ${colors.gray.hex};

      &:hover,
      &_selected {
        color: ${colors.primary.hex};

        .BigCharts__tabsItem-index {
          background: ${gradient(
            73.29,
            [colors.gradientStart.rgb, 1],
            0,
            [colors.gradientEnd.rgb, 1],
            100
          )};
        }

        :global(svg) {
          :global(path) {
            fill: ${colors.primary.hex};
            stroke: ${colors.primary.hex};
          }
        }
      }

      &:after {
        background: ${gradient(
          73.29,
          [colors.gradientStart.rgb, 1],
          0,
          [colors.gradientEnd.rgb, 1],
          100
        )};
      }
    }

    .BigCharts__tabsItem-index {
      color: ${colors.white.hex};
      background-color: ${colors.lightGray.hex};
    }

    .BigCharts__content {
      background-color: ${colors.white.hex};
    }

    .BigCharts__accountTypesItem {
      color: ${colors.gray.hex};

      &:before {
        background-color: ${colors.lightGray.hex};
      }
    }

    .BigCharts__item {
      color: ${colors.gray.hex};

      &:before {
        background-color: ${colors.lightGray.hex};
      }

      &_blue {
        &:hover, &_selected {
          color: ${colors.primary.hex} !important;

          &:before {
            background-color: ${colors.primary.hex} !important;
          }
        }
      }

      &_orange {
        &:hover, &_selected {
          color: ${colors.warning.hex} !important;

          &:before {
            background-color: ${colors.warning.hex} !important;
          }
        }
      }

      &_green {
        &:hover, &_selected {
          color: ${colors.success.hex} !important;

          &:before {
            background-color: ${colors.success.hex} !important;
          }
        }
      }

      &_pink {
        &:hover, &_selected {
          color: ${colors.error.hex} !important;

          &:before {
            background-color: ${colors.error.hex} !important;
          }
        }
      }

      &_gray {
        &:hover, &_selected {
          color: #39DBD3 !important;

          &:before {
            background-color: #39DBD3 !important;
          }
        }
      }

      &_violet {
        &:hover, &_selected {
          color: #6A5ACD !important;

          &:before {
            background-color: #6A5ACD !important;
          }
        }
      }
    }

    .BigCharts__periodItem {
      color: ${colors.gray.hex};

      &_selected,
      &:hover {
        color: ${colors.primary.hex};
      }
    }

    .statistical-item__title {
      color: ${colors.gray.hex};
    }

    .statistical-item__info {
      color: ${colors.primary.hex};
    }

  .BigCharts__ftd-description-text {
      color: ${colors.gray.hex};
  }
  `;
};

export { staticStyles, getDynamicStyles };
