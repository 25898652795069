import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.UpdatedCRMTable__Thead-Row, .UpdatedCRMTable__Tbody-Row) {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(56px, auto);
    gap: 2px;
    word-break: break-word;

    @media only screen and (max-width: 1150px) {
      grid-template-columns: 44px;
    }
  }
`;

export { staticStyles };
