import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { withTheme } from 'common/styling/theme';
import { FileField } from 'common/components/form-fields';

import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';

const DocumentsLoadPanel = ({
  captionId,
  descriptionId,
  status,
  name,
  onClickAction,
  reupload,
  uploadError,
  profileStatus,
  fileNameId,
  mandatory,
  onUplaod,
  docLimit,
  uploadedDocs,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const verifiedStatus = status === 'verified' && profileStatus === 'verified';

  const allowedToUploadDocumentsStatuses = ['notUploaded', 'rejected', 'enabled', 'unverificated'];
  const acceptedFormats = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/msword', // doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  ];

  const [moreLimit, setMoreLimit] = useState(false);

  const handleUpload = files => {
    if (docLimit && files.length > docLimit - uploadedDocs) {
      setMoreLimit(true);
    } else {
      setMoreLimit(false);
      onUplaod({ name, files });
    }
  };

  const captionIdNew =
    captionId === 'proofOfLife' &&
    (document.location.hostname === 'my.tixee.com' || document.location.hostname === 'in.tredero.com')
      ? 'proofOfLifeTixee'
      : captionId;

  return (
    <div className="DocumentsLoadPanel" id={`client-document-${name}`}>
      <div className="DocumentsLoadPanel__caption">
        <FormattedMessage id={captionIdNew}>{txt => <h2>{txt}</h2>}</FormattedMessage>

        <FormattedMessage id={descriptionId}>{txt => <p>{txt}</p>}</FormattedMessage>

        {!mandatory && ['unverified', 'incomplete'].includes(profileStatus) && (
          <FormattedMessage id="kycMandatoryHelpText">{txt => <p>{txt}</p>}</FormattedMessage>
        )}
      </div>

      <div
        className={classNames('DocumentsLoadPanel__input-inner', {
          'DocumentsLoadPanel__input-inner--error': moreLimit,
        })}>
        {(allowedToUploadDocumentsStatuses.includes(status) || reupload) && (
          <Field
            component={FileField}
            handleAfterUpload={handleUpload}
            name={name}
            formatNameId="documentsFormats"
            fileNameId={fileNameId}
            accept={acceptedFormats.join(',')}
            multiple
          />
        )}

        {status === 'unverified' && !reupload && profileStatus !== 'verified' && (
          <div className="DocumentsForm__upload">
            <div className="DocumentsForm__upload-text">
              <FormattedMessage id="clientDocumentUpload">
                {txt => (
                  <p>
                    {txt}
                    <i />
                  </p>
                )}
              </FormattedMessage>
              <FormattedMessage id="clientDocumentUploadDescription" />
            </div>

            {!docLimit && (
              <div className="DocumentsForm__upload-button">
                <FormattedMessage id="clientDocumentUploadAnother">
                  {txt => (
                    <button type="button" onClick={() => onClickAction(name)}>
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              </div>
            )}

            {docLimit && docLimit > uploadedDocs && (
              <div className="DocumentsForm__upload-button">
                <FormattedMessage id="clientDocumentUploadAnother">
                  {txt => (
                    <button type="button" onClick={() => onClickAction(name)}>
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              </div>
            )}
          </div>
        )}

        {verifiedStatus && (
          <FormattedMessage id="status_verified">
            {txt => (
              <p className="Document__status-verified">
                {txt}
                <i />
              </p>
            )}
          </FormattedMessage>
        )}
      </div>

      {uploadError && <span className="DocumentsLoadPanel__error">{uploadError}</span>}
      {moreLimit && (
        <span className="DocumentsLoadPanel__error">
          <FormattedMessage id="clientMaxDocError" />
        </span>
      )}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

DocumentsLoadPanel.propTypes = {
  captionId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
  reupload: PropTypes.bool,
  uploadError: PropTypes.string,
  profileStatus: PropTypes.string.isRequired,
  fileNameId: PropTypes.string.isRequired,
  mandatory: PropTypes.bool.isRequired,
  onUplaod: PropTypes.func.isRequired,
  docLimit: PropTypes.number,
  uploadedDocs: PropTypes.number,
  theme: PropTypes.object,
};

DocumentsLoadPanel.defaultProps = {
  reupload: false,
  uploadError: '',
  docLimit: 0,
  uploadedDocs: 0,
  theme: {},
};

const ThemedDocumentsLoadPanel = withTheme()(DocumentsLoadPanel);

export default ThemedDocumentsLoadPanel;
export { DocumentsLoadPanel };
