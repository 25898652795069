import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { length, required } from 'redux-form-validators';
import { withTheme } from 'common/styling/theme';

import { ReduxTextField, TextAreaField, FileField, DefaultButton, CheckboxField } from 'common/components';

import { createUserNote, createDocument } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const UserNoteCreateForm = ({ id, file, handleSubmit, pristine, submitting, invalid, dispatch, error, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [documentsSelected, setDocumentsSelected] = useState(false);
  const [maxSizeError, setMaxSizeError] = useState({ error: false, size: 0 });
  const documentsRef = useRef(null);
  const documentss = JSON.stringify(file) === '{}' || !file ? [] : [file];
  const submit = handleSubmit(values => createUserNote({ id, documentss, ...values }, dispatch));

  const handleAfterUpload = files => {
    if (files.length) {
      const formData = new FormData();
      const trimmerFileName = files[0].name.length > 30 ? files[0].name.substring(0, 30) : files[0].name;

      formData.set('name', trimmerFileName.replace(/\.[^.]+$/, ''));
      formData.append('file', files[0]);
      if (files) {
        setDocumentsSelected(true);
      }
      return createDocument({ id, body: formData }, dispatch);
    }
  };

  const maxSize = 5 * 1024 * 1024;

  return (
    <form className="UserNoteCreateForm" onSubmit={submit}>
      <div className="UserNoteCreateForm__inputs-inner">
        <Field
          component={ReduxTextField}
          name="title"
          textId="justTitle"
          autoComplete="off"
          type="text"
          validate={[required(), length({ max: 100 })]}
          disabled={submitting}
        />
        <Field
          component={TextAreaField}
          name="text"
          textId="justDescription"
          rows="4"
          cols="150"
          validate={[required()]}
          disabled={submitting}
        />
        <div className="UserNoteCreateForm__attach">
          <FormattedMessage id="justUploadFiles">{txt => <label htmlFor="documents">{txt}</label>}</FormattedMessage>
          <label className="UserNoteCreateForm__attach-label" htmlFor="documents" ref={documentsRef}>
            <FormattedMessage id={documentsSelected ? 'justFileSelected' : 'justChoseFile'} />
          </label>
          <Field
            component={FileField}
            handleAfterUpload={handleAfterUpload}
            onSizeError={setMaxSizeError}
            name="documents"
            formatNameId="documentsFormats"
            fileNameId="justAttachments"
            accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .csv"
            maxSize={maxSize}
            multiple={false}
            disabled={submitting}
          />
        </div>
        {maxSizeError.error && (
          <FormattedMessage
            id="maxSizeIsExceeded"
            values={{ size: maxSizeError.size, maxSize: +(maxSize / 1024 / 1024).toFixed(1) }}>
            {txt => <p className="formError">{txt}</p>}
          </FormattedMessage>
        )}
        <div className="UserNoteCreateForm__pin">
          <Field component={CheckboxField} name="isPinned" textId="justPinNote" disabled={submitting} />
        </div>
      </div>
      <DefaultButton
        textId="justSubmit"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

UserNoteCreateForm.propTypes = {
  id: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

UserNoteCreateForm.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      file: state.backoffice.users.notesDocuments,
    }),
    null
  ),
  reduxForm({
    form: 'user-note-create-form',
  })
)(UserNoteCreateForm);
export { UserNoteCreateForm };
