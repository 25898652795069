import css from 'styled-jsx/css';
import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AddCardForm {
    width: 980px;

    @include respond-to(xl) {
      width: 820px;
    }

    @include respond-to(md) {
      width: 100%;
    }

    &__face {
      margin-top: 40px;

      &-title {
        @include respond-to(md) {
          font-weight: 700;
          font-size: 15px;
        }
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to(md) {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    &__fields {
      width: 48%;

      @include respond-to(md) {
        width: 100%;
      }

      &-half {
        display: flex;
        justify-content: space-between;

        .ReduxTextField {
          width: 48%;
        }
      }

      &-attach {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .FileField {
          display: none;
        }
      }

      &-label {
        position: relative;
        display: inline-block;
        padding: rem(9px 20px);
        cursor: pointer;
        font-size: rem(15px);
        line-height: rem(22px);
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        min-width: rem(105px);
        letter-spacing: rem(0.5px);
        margin: rem(0 20px);

        &:active {
          box-shadow: none;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      &-prompt {
        font-size: 12px;
        line-height: 14px;
        color: #979797;
        max-width: 200px;

        @include respond-to(xl) {
          margin-top: 10px;
        }

        @include respond-to(md) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &__example {
      width: 48%;
      padding: 40px 20px;
      border: 1px dashed #dadada;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to(md) {
        width: 100%;
        margin-top: 20px;
        border: none;
        padding: 20px 0;
      }

      &-img {
        max-width: 180px;
        @include respond-to(xl) {
          max-width: 120px;

          img {
            width: 100%;
          }
        }

        @include respond-to(sm) {
          max-width: 100px;
        }
      }

      &-text {
        max-width: 210px;
        color: #979797;
        font-size: 12px;

        p {
          margin-bottom: 10px !important;
        }

        @include respond-to(xs) {
          margin-bottom: 0 !important;
          margin-left: 20px;
        }
      }

      &-list {
        max-width: 210px;
        margin-bottom: 10px !important;

        li {
          color: #979797;
          font-size: 12px;
        }
      }
    }

    &__btns {
      margin-top: 30px;

      .DefaultButton:first-child {
        margin-right: ${isRTL ? '0' : '30px'};
        margin-left: ${isRTL ? '30px' : '0'};
      }
    }

    .formError {
      margin-bottom: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .AddCardForm {
      &__fields {
        &-label {
          border-radius: ${border.radius};
          border: 1px solid ${colors.primary.hex};
          color: ${colors.primary.hex};
          &:hover {
            border-color: ${rgba([colors.primary.rgb, 0.8])};
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
            color: ${rgba([colors.primary.rgb, 0.8])};
          }
          &:disabled {
            border-color: transparent;
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
