import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .PersonInfo {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 60px 10px 30px);
    margin-bottom: rem(20px);
    position: relative;
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }

    .PersonInfo__content {
      display: flex;
      align-items: center;
      margin-bottom: rem(15px);
    }

    .PersonInfo__caption {
      font-size: 18px;
      margin-bottom: rem(25px);
    }

    .PersonInfo__gravatar-inner {
      margin-right: rem(60px);

      :global(.react-gravatar) {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 145px !important;
          height: 145px !important;
        }
        @include respond-to(sm) {
          display: block;
          width: 100px !important;
          height: 100px !important;
          margin: 0 auto;
        }
      }
      
      @include respond-to(sm) {
        margin-right: rem(30px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PersonInfo {
      background-color: ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
