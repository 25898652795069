import css from 'styled-jsx/css';

import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CreaeteLetterForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > .BaseButton {
      margin-top: 5px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
