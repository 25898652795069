import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .PasswordChangeForm {
    max-width: 360px;
    @include respond-to(sm) {
      max-width: 100%;
    }
  }
`

export default staticStyles
