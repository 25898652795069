import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, reset, Field, formValueSelector } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';

import { TextAreaField, FileField } from 'common/components/form-fields';
import { ActionsButton, DefaultButton } from 'common/components';
import { AttachIcon } from 'common/icons';

import { staticStyles, getDynamicStyles } from './style';

const IssueCreateMessage = ({
  handleSubmit,
  createMessage,
  editMessage,
  isBroker,
  isMessageEditing,
  setMessageEditing,
  attachments,
  pristine,
  submitting,
  invalid,
  error,
  disabled,
  dispatch,
  theme,
}) => {
  const [isNote, setIsNote] = useState(false);
  const [activeTab, setActiveTab] = useState('message');
  const [fileExist, setFileExist] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const labelRef = useRef(null);
  const submit = () => {
    setIsNote(false);
    setFileExist(false);
    if (isMessageEditing) {
      handleSubmit(values => editMessage({ ...values, deletedFiles }, dispatch))();
      setMessageEditing(false);
      setDeletedFiles([]);
    } else {
      handleSubmit(createMessage)();
    }
  };

  const submitNote = () => {
    setIsNote(true);
    setFileExist(false);
    handleSubmit(values => createMessage({ ...values, isNote: true }, dispatch))();
  };
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleKeydown = event => {
    if (event.ctrlKey && event.keyCode === 13 && !invalid) {
      // eslint-disable-next-line
      activeTab === 'message' ? submit() : submitNote();
    }
  };

  const handleAttachClick = () => {
    labelRef.current.click();
  };

  const handleExistFile = (name, files) => {
    if (name || files) {
      setFileExist(true);
    }
  };

  const handleDeleteFile = file => {
    setDeletedFiles([...deletedFiles, file.id]);
  };

  return (
    <form className="IssueCreateMessage__form" onSubmit={submit}>
      {isBroker && (
        <div className="IssueCreateMessageSwitcher">
          <button
            type="button"
            className={classNames('IssueCreateMessageSwitcher__tab', {
              active: activeTab === 'message',
            })}
            onClick={() => {
              setActiveTab('message');
            }}>
            <FormattedMessage id="justMessage">{txt => <span>{txt}</span>}</FormattedMessage>
          </button>
          <button
            type="button"
            className={classNames('IssueCreateMessageSwitcher__tab', {
              active: activeTab === 'comment',
            })}
            onClick={() => {
              setActiveTab('comment');
            }}>
            <FormattedMessage id="supportComment">{txt => <span>{txt}</span>}</FormattedMessage>
          </button>
        </div>
      )}

      <div className="IssueCreateMessage__form-input">
        <Field
          component={TextAreaField}
          disabled={disabled || submitting}
          name="text"
          textId="boYourAnswer"
          rows="4"
          cols="150"
          validate={[required()]}
          handleKeydown={handleKeydown}
        />
        <div
          className={classNames('FileField__wrapper', {
            active:
              fileExist || (isMessageEditing && !!attachments.length && deletedFiles.length !== attachments.length),
          })}>
          <Field
            withCrossButton={isMessageEditing}
            sendHandler={handleExistFile}
            handleDeleteFile={handleDeleteFile}
            component={FileField}
            deletedFiles={deletedFiles}
            name="attachments"
            formatNameId="imagesFormats"
            fileNameId="justAttachments"
            accept="image/png,image/jpeg"
            multiple
            disabled={submitting}
            dropZoneDisabled={isMessageEditing}
          />
        </div>
      </div>
      <div className="IssueCreateMessage__form-button">
        {activeTab === 'comment' && (
          <DefaultButton
            textId="boLeaveNote"
            onClick={submitNote}
            loading={submitting && isNote}
            disabled={disabled || invalid || pristine || submitting}
            filled
          />
        )}
        {activeTab === 'message' && (
          <DefaultButton
            textId={isMessageEditing ? 'editMessage' : 'sendMessage'}
            onClick={submit}
            loading={submitting && !isNote}
            disabled={disabled || invalid || pristine || submitting}
            filled
          />
        )}
        {!isMessageEditing && (
          <ActionsButton
            className="IssueCreateMessage__attach"
            edit
            buttonId="attachImage"
            condition
            onClickFunc={handleAttachClick}
            disabled={disabled}>
            <AttachIcon />
            <label htmlFor="attachments" ref={labelRef} />
          </ActionsButton>
        )}
      </div>

      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

IssueCreateMessage.propTypes = {
  createMessage: PropTypes.func.isRequired,
  editMessage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setMessageEditing: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMessageEditing: PropTypes.bool.isRequired,
  attachments: PropTypes.array.isRequired,
  isBroker: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  theme: PropTypes.object,
};

IssueCreateMessage.defaultProps = {
  isBroker: false,
  disabled: false,
  error: '',
  theme: {},
};

const afterSubmit = (result, dispatch) => dispatch(reset('issueCreateMessage'));

const selector = formValueSelector('issueCreateMessage');

export default compose(
  withTheme(),
  connect(state => ({
    attachments: selector(state, 'attachments') || [],
  })),
  reduxForm({ form: 'issueCreateMessage', onSubmitSuccess: afterSubmit })
)(IssueCreateMessage);
export { IssueCreateMessage };
