import { put, call, fork, take, delay, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import Raven from 'raven-js';

import { acceptError } from 'common/_redux';
import { stopImpersonateSaga } from 'interface/user/_redux/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

const AUTH_TYPES = {
  default: 'default',
  telegram: 'telegram',
};

export function* getOTPTokenSaga(payload, authType) {
  try {
    const { status, data } = yield call(api.getOTPToken, payload);

    if (status < 300) {
      localStorage.setItem('otpToken', data.otpToken);
      yield put(ActionTypes.getOTPToken.success(data.nextAction));
      return { nextAction: data.nextAction };
    }

    let formError;

    if (payload.brokerToken) formError = data.brokerToken;
    else if (payload.oneoffToken) formError = data;
    else {
      switch (authType) {
        case AUTH_TYPES.telegram:
          formError = data;
          break;
        case AUTH_TYPES.default:
        default:
          formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
          break;
      }
    }

    yield put(ActionTypes.getOTPToken.failure(formError));
    return { formError };
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getOTPToken.failure());
    throw e;
  }
}

export function* getOTPTokenWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.getOTPToken.REQUEST);
    yield call(getOTPTokenSaga, payload);
  }
}

export function* resendOTPSaga() {
  try {
    const { data, status } = yield call(api.resendOTPToken);
    if (status < 300) {
      yield put(ActionTypes.resendOTPToken.success());
      for (let i = 30; i >= 0; i -= 1) {
        yield put(ActionTypes.reduceCodeTimer(i));
        yield delay(1000);
      }
    } else {
      yield put(ActionTypes.resendOTPToken.failure());
      yield put(ActionTypes.resetAuth(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.resendOTPToken.failure());
  }
}

export function* resendOTPWatcherSaga() {
  while (true) {
    yield take(ActionTypes.resendOTPToken.REQUEST);
    yield call(resendOTPSaga);
  }
}

export function* getJWTTokenSaga(payload) {
  try {
    const { status, data } = yield call(api.getJWTToken, payload);

    if (status < 300) {
      localStorage.setItem('jwtToken', data.token);
      localStorage.removeItem('otpToken');
      yield put(ActionTypes.getJWTToken.success());
      return 1;
    }
    if (status === 401) {
      yield put(ActionTypes.getJWTToken.failure());
      yield put(ActionTypes.resetAuth(data.details));
      return 0;
    }
    const formError = new SubmissionError(status === 429 ? { _error: data } : { _error: data.detail });
    yield put(ActionTypes.getJWTToken.failure(formError));
    return 0;
  } catch (e) {
    localStorage.removeItem('otpToken');
    yield put(acceptError(e, true));
    yield put(ActionTypes.getJWTToken.failure());
    throw e;
  }
}

export function* getJWTTokenWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.getJWTToken.REQUEST);
    yield call(getJWTTokenSaga, payload);
  }
}

export function* loginSaga(payload) {
  try {
    const { nextAction, formError } = yield call(getOTPTokenSaga, payload);

    if (nextAction) {
      if (nextAction === 'login') {
        yield call(getJWTTokenSaga, payload);
      }
      yield put(ActionTypes.login.success(nextAction === 'login' ? 'success' : nextAction));
    } else {
      yield put(ActionTypes.login.failure(formError));
    }
  } catch (e) {
    yield put(ActionTypes.login.failure());
    throw e;
  }
}

/* eslint-disable */
export function* loginWatcherSaga() {
  while (true) {
    const {
      payload: {
        emailOrPhone,
        password,
        rememberMe,
        telegramId,
        telegramFirstName,
        telegramLastName,
        telegramUserName,
        telegramPhotoUrl,
        telegramAuthDate,
        telegramHash,
        broker_token: brokerToken,
        user_token: userToken,
        user_id: userId,
        oneoff_token: oneoffToken,
      },
    } = yield take(ActionTypes.login.REQUEST);

    let payload;

    if (brokerToken) payload = { brokerToken };
    else if (userToken) payload = { userToken, userId };
    else if (oneoffToken) payload = { oneoffToken };
    else if (telegramHash)
      payload = {
        emailOrPhone: emailOrPhone.toLowerCase(),
        password,
        rememberMe,
        telegramId,
        telegramFirstName,
        telegramLastName,
        telegramUserName,
        telegramPhotoUrl,
        telegramAuthDate,
        telegramHash,
      };
    else payload = { emailOrPhone: emailOrPhone.toLowerCase(), password, rememberMe };

    yield call(loginSaga, payload);
  }
}
/* eslint-enable */

export function* loginWithTelegramSaga(telegramData) {
  try {
    const { nextAction, formError } = yield call(getOTPTokenSaga, telegramData, AUTH_TYPES.telegram);

    if (nextAction) {
      if (nextAction === 'login') {
        yield call(getJWTTokenSaga);
      }
      sessionStorage.removeItem('telegramData');
      yield put(ActionTypes.loginWithTelegram.success(nextAction === 'login' ? 'success' : nextAction));
    } else {
      sessionStorage.setItem('telegramData', JSON.stringify(telegramData));
      yield put(ActionTypes.loginWithTelegram.failure(formError));
    }
  } catch (e) {
    yield put(ActionTypes.loginWithTelegram.failure());
    throw e;
  }
}

export function* loginWithTelegramWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.LOGIN_WITH_TELEGRAM.REQUEST);

    yield call(loginWithTelegramSaga, data);
  }
}

export function* bindPhoneSaga(phone) {
  try {
    const { data, status } = yield call(api.bindPhone, phone);
    if (status < 300) {
      yield put(ActionTypes.bindPhone.success());
    } else if (status === 401) {
      yield put(ActionTypes.bindPhone.failure());
      yield put(ActionTypes.resetAuth(data.detail));
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.bindPhone.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.bindPhone.failure());
  }
}

export function* bindPhoneWatcherSaga() {
  while (true) {
    const {
      payload: { phone },
    } = yield take(ActionTypes.bindPhone.REQUEST);
    yield call(bindPhoneSaga, phone);
  }
}

export function* logoutWatcherSaga() {
  while (true) {
    yield take(ActionTypes.LOGOUT_REQUEST);
    const profile = yield select(state => state.user.profile);
    if (profile && profile.isImpersonate) {
      yield call(stopImpersonateSaga);
    }
    yield put(ActionTypes.logoutSuccess());
    localStorage.removeItem('jwtToken');
    Raven.setUserContext();
  }
}

export default [
  fork(loginWatcherSaga),
  fork(loginWithTelegramWatcherSaga),
  fork(getOTPTokenWatcherSaga),
  fork(getJWTTokenWatcherSaga),
  fork(bindPhoneWatcherSaga),
  fork(resendOTPWatcherSaga),
  fork(logoutWatcherSaga),
];
