import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';
import Select from 'react-select';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const BaseSelectField = ({
  value,
  onChange,
  name,
  textId,
  textValues,
  label,
  options,
  searchable,
  disabled,
  error,
  children,
  additionalClass,
  customDefaultValue,
  isMulti,
  noLabel,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const [isActive, setActive] = useState(false);
  const initWindowHeight = useMemo(() => window.innerHeight, []);

  useEffect(() => {
    const func = () => {
      if (window.innerWidth < 479 && window.innerHeight !== initWindowHeight) {
        setActive(false);
        setActive(true);
      }
    };
    window.addEventListener('resize', func);
    return () => window.removeEventListener('resize', func);
  }, []);

  useEffect(() => {
    if (value !== customDefaultValue && !disabled && !isMulti && customDefaultValue) {
      onChange(options.find(option => option.value === customDefaultValue));
    }
  }, [customDefaultValue, value, disabled, isMulti, options, onChange]);

  const haveText = value?.length ?? value;

  return (
    <div
      className={classNames('BaseSelectField', {
        ReduxSelectField: additionalClass,
        ReduxSelectField__error: error,
        BaseSelectField__noLabel: noLabel,
        active: isActive,
        disabled,
        isMulti,
      })}>
      <div onFocus={() => setActive(true)} onBlur={() => setActive(false)}>
        <Select
          {...props}
          value={value}
          onChange={onChange}
          id={name}
          placeholder=""
          classNamePrefix="Select"
          isClearable={false}
          isSearchable={searchable}
          isDisabled={disabled}
          noOptionsMessage={() => null}
          hideSelectedOptions
          options={options}
          maxMenuHeight={270}
          menuPosition="fixed"
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          isMulti={isMulti}
        />

        {!noLabel && (
          <label className={classNames('BaseSelectField__label', { active: isActive }, { haveText })} htmlFor={name}>
            {label || <FM id={textId} values={textValues} />}
          </label>
        )}
      </div>

      {children}

      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

BaseSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  additionalClass: PropTypes.bool,
  input: PropTypes.object,
  textId: PropTypes.string,
  textValues: PropTypes.object,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  customDefaultValue: PropTypes.string,
  onFocus: PropTypes.func,
  noLabel: PropTypes.bool,
  theme: PropTypes.object,
};

BaseSelectField.defaultProps = {
  value: null,
  children: false,
  additionalClass: false,
  input: {},
  disabled: false,
  textId: '',
  textValues: {},
  label: '',
  searchable: false,
  error: null,
  isMulti: false,
  customDefaultValue: undefined,
  onFocus: null,
  noLabel: false,
  theme: {},
};

export default withTheme()(BaseSelectField);
export { BaseSelectField };
