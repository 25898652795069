import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const ReloadIcon = ({ primaryColor }) => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <path
        d="M3.538 9.165l-1.49-.317C3.334 4.9 6.479 2 10.905 2a9.431 9.431 0 0 1 8.134 4.643l.544-1.946A.725.725 0 0 1 21 5l-.906 3.631a.723.723 0 0 1-.86.556L15.082 8.3a.723.723 0 1 1 .304-1.413l2.417.516a7.98 7.98 0 0 0-6.9-3.957c-3.753 0-6.128 2.328-7.366 5.719zM20.23 13.266a9.269 9.269 0 0 1-2.367 4.582 9.382 9.382 0 0 1-6.876 2.962 9.427 9.427 0 0 1-7.946-4.338l-.642 1.9A.724.724 0 0 1 1 18l1.123-3.682a.73.73 0 0 1 .887-.509l3.99.509a.72.72 0 1 1-.375 1.39l-2.413-.089a7.978 7.978 0 0 0 7.04 3.747 7.938 7.938 0 0 0 5.553-2.501 7.841 7.841 0 0 0 2.022-3.975l1.403.376z"
        fill={primaryColor.hex}
      />
    </svg>
  </div>
);

ReloadIcon.propTypes = {
  primaryColor: colorType,
};

ReloadIcon.defaultProps = {
  primaryColor: {},
};

const ThemedReloadIcon = withTheme(({ colors: { primary } }) => ({
  primaryColor: primary,
}))(ReloadIcon);

export default ThemedReloadIcon;
