import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';

import { FirstStep, SecondStep, ThirdStep } from './_components';

import { staticStyles } from './style';

const FilterEdit = ({
  editCrmCustomFilter,
  id,
  getCRMFiltersOptions,
  getCrmFilter,
  filtersOptions,
  filtersOptionsPending,
  crmFilter,
  crmFilterIsLoaded,
  onRequestClose,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dataFilterValues, setDataFilterValues] = useState({ title: '', query: {} });
  const [initialValues, setInitialValues] = useState({});
  const [step, setStep] = useState(1);

  const selectedFilterTypes = useMemo(() => {
    if (step === 2) {
      const result = {};
      Object.keys(filtersOptions).forEach(filterKey => {
        const selectedFilter = selectedOptions.find(({ value }) => value === filterKey);
        if (selectedFilter) {
          result[filterKey] = filtersOptions[filterKey];
        }
      });
      return result;
    }
  }, [step]);

  const selectFiltersOptions = useMemo(
    () => Object.keys(filtersOptions).map(item => ({ value: item, label: <FM id={item}>{txt => txt}</FM> })),
    [filtersOptions]
  );
  useEffect(() => {
    getCRMFiltersOptions();
    getCrmFilter({ id });
  }, []);

  useEffect(() => {
    if (crmFilterIsLoaded) {
      const options = [];
      Object.keys(crmFilter?.query).forEach(property => {
        options.push({ value: property, label: <FM id={property}>{txt => txt}</FM> });
      });

      setSelectedOptions(options);
      setInitialValues(crmFilter.query);
    }
  }, [crmFilterIsLoaded]);
  useEffect(() => {
    const newInitialValues = {};
    selectedOptions.forEach(item => {
      newInitialValues[item.value] = initialValues[item.value];
    });
    setInitialValues(newInitialValues);
  }, [selectedOptions]);

  const onNewFilterSelect = newValues => {
    setSelectedOptions(newValues);
  };

  return (
    <div className="FilterEdit__Wrapper">
      {step === 1 && (
        <FirstStep
          options={selectFiltersOptions}
          loading={filtersOptionsPending}
          selectedOptions={selectedOptions}
          onChange={onNewFilterSelect}
          setNextStep={() => {
            setStep(prevValue => prevValue + 1);
          }}
        />
      )}
      {step === 2 && (
        <SecondStep
          selectedFilterTypes={selectedFilterTypes}
          initialValues={initialValues}
          setNextStep={newDataFilterValues => {
            setDataFilterValues(prevState => ({ ...prevState, query: newDataFilterValues }));
            setStep(prevValue => prevValue + 1);
          }}
        />
      )}
      {step === 3 && (
        <ThirdStep
          editCrmCustomFilter={editCrmCustomFilter}
          onRequestClose={onRequestClose}
          dataFilterValues={dataFilterValues}
          id={id}
          initialValues={{ title: crmFilter?.title || '' }}
        />
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

FilterEdit.propTypes = {
  editCrmCustomFilter: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  filtersOptions: PropTypes.array.isRequired,
  filtersOptionsPending: PropTypes.bool.isRequired,
  crmFilter: PropTypes.object.isRequired,
  crmFilterIsLoaded: PropTypes.bool.isRequired,
  getCRMFiltersOptions: PropTypes.func.isRequired,
  getCrmFilter: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default connect(state => state)(FilterEdit);
