import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { addContactTag } from 'crm/users/_redux/actions';
import BaseButton from 'crm/_components/BaseButton';
import { getAvailableTags } from 'crm/users/api';
import { required } from 'redux-form-validators';
import BaseAsyncSelectField from '../../fields/BaseAsyncSelectField';
import { staticStyles } from './style';

const CRMAddContactTagForm = ({ addContactTag, handleSubmit, pristine, submitting, invalid, error }) => {
  const { id } = useParams();
  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    getAvailableTags('')
      .then(({ data }) => setDefaultOptions(data.map(tag => ({ value: tag.id, label: tag.name }))))
      .catch(() => console.log('ERROR'));
  }, []);

  const loadTagsOptions = search =>
    getAvailableTags(search).then(({ data }) => data.map(tag => ({ value: tag.id, label: tag.name })));

  const submit = handleSubmit(values => {
    addContactTag({ ...values, id });
  });

  return (
    <form className="CRMAddContactTagForm" onSubmit={submit}>
      <div className="CRMAddContactTagForm__inputs-inner">
        <Field
          defaultOptions={defaultOptions}
          name="tag"
          minSearchLengthForLoad={0}
          component={BaseAsyncSelectField}
          validate={[required()]}
          loadOptions={loadTagsOptions}
          disabled={submitting}
          maxMenuHeight={150}
          labelTextId="crmTag"
        />
      </div>

      <BaseButton type="submit" textId="justAdd" maxContent disabled={invalid || pristine || submitting} />

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

CRMAddContactTagForm.propTypes = {
  addContactTag: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

CRMAddContactTagForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(() => ({}), {
    addContactTag: data => addContactTag.request(data),
  }),
  reduxForm({
    form: 'crm-add-tag',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
  })
)(CRMAddContactTagForm);
export { CRMAddContactTagForm };
