import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { PageLayout, PageContentPreloader } from 'common/components';

import { getIssue, verifyPartnerFromTicket } from '../_redux';
import { IssueContent } from '../_components';

const IssuePage = ({
  issue,
  getIssue,
  issueIsLoaded,
  profile,
  match,
  availableLangs,
  verifyPartnerFromTicket,
  history,
}) => {
  useEffect(() => {
    getIssue(match.params.id);
  }, [getIssue, match.params.id]);

  const handleAvatarClick = id => history.push(`/backoffice/users/${id}`);

  return (
    issueIsLoaded && (
      <PageLayout
        captionSlug="boIssue"
        extraCaptionText={match.params.id}
        showReload={false}
        reloading={!issueIsLoaded}>
        <PageContentPreloader ready={issueIsLoaded} type="bigBlock">
          <IssueContent
            verifyPartnerFromTicket={verifyPartnerFromTicket}
            handleAvatarClick={handleAvatarClick}
            issue={issue}
            profile={profile}
            availableLangs={availableLangs}
            issueIsLoaded={issueIsLoaded}
            history={history}
          />
        </PageContentPreloader>
      </PageLayout>
    )
  );
};

IssuePage.propTypes = {
  profile: PropTypes.object.isRequired,
  issue: PropTypes.object.isRequired,
  issueIsLoaded: PropTypes.bool.isRequired,
  getIssue: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  availableLangs: PropTypes.object.isRequired,
  verifyPartnerFromTicket: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      issue: state.backoffice.support.issue,
      issueIsLoaded: state.backoffice.support.issueIsLoaded,
      profile: state.user.profile,
      availableLangs: state.interfaceConfig.availableLangs,
    }),
    { getIssue: id => getIssue.request(id), verifyPartnerFromTicket: id => verifyPartnerFromTicket.request(id) }
  )
)(IssuePage);
export { IssuePage };
