import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import queryString from 'query-string';

import {
  PageLayout,
  NoItemsPanel,
  PageContentPreloader,
  SearchPanel,
  DefaultButton,
  BaseSelectField,
  BasicModal,
  Calendar,
  RadioButton,
} from 'common/components';
import { withTheme } from 'common/styling/theme';
import noPartnersImage from 'common/images/noPartners.svg';

import { createPartnerAccountsExport } from 'backoffice/partnership/_redux';
import { getPartners } from '../../../_redux';
import { PartnersTable } from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const PartnersPage = ({
  location,
  getPartners,
  createPartnerAccountsExport,
  partners,
  partnersAreLoaded,
  isPartnerAccountsExportRedirect,
  history,
  theme,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getPartners({ search: query });
  }, [getPartners, query]);

  useEffect(() => {
    if (isPartnerAccountsExportRedirect) {
      history.push('/backoffice/partners/exports');
    }
  }, [isPartnerAccountsExportRedirect, history]);

  const handleGetPartners = useCallback(() => {
    getPartners({ search: query });
  }, [getPartners, query]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const parsedQuery = queryString.parse(location.search);
  const handleRowClick = (_rowId, rowInfo) => {
    history.push(`/backoffice/users/${rowInfo.original.id}`);
  };

  const inputs = [
    {
      title: 'justMasterIb',
      accessor: 'master_ib',
    },
  ];

  const [isCreateFullExport, setIsCreateFullExport] = useState(true);

  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('');
  const [exportExt, setExportExt] = useState(null);

  const [startDate, setStartDate] = useState(moment().add(-7, 'day').startOf('day'));

  const [endDate, setEndDate] = useState(moment().startOf('day'));

  const hadleChangeDate = (startDate, endDate) => {
    setStartDate(startDate * 1000);
    setEndDate(endDate * 1000);
    setSelectedTimePeriod(
      `${moment(startDate * 1000).format('YYYY-MM-DD')}_${moment(endDate * 1000).format('YYYY-MM-DD')}`
    );
  };

  useEffect(() => {
    if (isExportMenuModalOpen) {
      setSelectedTimePeriod(`${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format('YYYY-MM-DD')}`);
    }
  }, [isExportMenuModalOpen]);

  return (
    <PageLayout captionSlug="boPartners" showReload onReloadClick={handleGetPartners} reloading={!partnersAreLoaded}>
      <PageContentPreloader ready={partnersAreLoaded} firstLaunchOnly>
        {!partners.count && !Object.keys(parsedQuery).length ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getPartners}
            params={{ search: parsedQuery }}
            inputs={inputs}
          />
        )}
        {partners.count ? (
          <>
            <div className="PartnersPage__export-select">
              <BaseSelectField
                name="import"
                textId="boExportPartners"
                options={[
                  { value: 'showExports', label: 'Show exports' },
                  { value: 'xlsx', label: 'Export in xlsx' },
                  { value: 'csv', label: 'Export in csv' },
                ]}
                onChange={option => {
                  if (option.value === 'showExports') {
                    history.push('/backoffice/partners/exports');
                  } else {
                    setExportExt(option.value);
                    setIsExportMenuModalOpen(true);
                  }
                }}
                maxMenuHeight={170}
              />
            </div>
            <PartnersTable
              data={partners}
              loading={!partnersAreLoaded}
              location={location}
              history={history}
              getList={getPartners}
              params={{ search: parsedQuery }}
              onRowClick={handleRowClick}
            />
          </>
        ) : (
          <NoItemsPanel
            icon={noPartnersImage}
            captionSlug={Object.keys(parsedQuery).length ? 'noPartnersMatchingTheParameters' : 'noPartnersYet'}
          />
        )}
        <BasicModal
          captionId="selectTimePeriodOfExport"
          isOpen={isExportMenuModalOpen}
          onRequestClose={() => setIsExportMenuModalOpen(false)}>
          <div className="PartnersPageExports">
            <RadioButton
              name="investFrom"
              textId="createExportForAllTime"
              value="all"
              onChange={() => setIsCreateFullExport(true)}
              checked={isCreateFullExport}
            />
            <RadioButton
              name="investFrom"
              textId="createExportForTimePeriod"
              value="all"
              onChange={() => setIsCreateFullExport(false)}
              checked={!isCreateFullExport}
            />
            <div style={{ width: 'max-content', display: !isCreateFullExport ? 'flex' : 'none' }}>
              <Calendar
                setInMiddle
                daysLimit={31}
                setCorrectLastDay
                onChangeFunc={hadleChangeDate}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
              />
            </div>
            <DefaultButton
              textId="justExport"
              type="button"
              filled
              disabled={!selectedTimePeriod}
              onClick={() => {
                setIsExportMenuModalOpen(false);
                createPartnerAccountsExport({
                  ext: exportExt,
                  timePeriod: isCreateFullExport ? undefined : selectedTimePeriod,
                });
              }}
            />
          </div>
        </BasicModal>
        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </PageContentPreloader>
    </PageLayout>
  );
};

PartnersPage.propTypes = {
  location: PropTypes.object.isRequired,
  getPartners: PropTypes.func.isRequired,
  createPartnerAccountsExport: PropTypes.func.isRequired,
  partners: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  partnersAreLoaded: PropTypes.bool.isRequired,
  isPartnerAccountsExportRedirect: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PartnersPage.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  injectIntl,
  connect(
    state => ({
      partners: state.backoffice.common.partners,
      partnersAreLoaded: state.backoffice.common.partnersAreLoaded,
      isPartnerAccountsExportRedirect: state.backoffice.partnership.isPartnerAccountsExportRedirect,
    }),
    {
      getPartners: search => getPartners.request(search),
      createPartnerAccountsExport: ({ ext, timePeriod }) => createPartnerAccountsExport.request({ ext, timePeriod }),
    }
  )
)(PartnersPage);
export { PartnersPage };
