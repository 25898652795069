import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isRTL } from 'common/utils';
import classNames from 'classnames';
import BurgerButton from '../BurgerButton';
import { RocketIconActive } from './Icons';

import { staticStyles } from './style';

import { items } from './items';
import LeftMenuItem from './_components/LeftMenuItem';

const LeftMenu = ({ isMenuOpen }) => {
  const place = isRTL ? 'left' : 'right';
  return (
    <div
      className={classNames('LeftMenu', {
        opened: isMenuOpen,
      })}>
      <div className="LeftMenu__mobile-block">
        <BurgerButton />
        <FormattedMessage id="adminInterface">
          {txt => <div className="LeftNavigation__mobile-interface-title">{txt}</div>}
        </FormattedMessage>
      </div>
      <div className="LeftMenu__link active">
        <div className="LeftMenu__icon">
          <RocketIconActive />
        </div>
        <FormattedMessage id="menuCrmSetup">{txt => <span className="LeftMenu__p">{txt}</span>}</FormattedMessage>
      </div>
      {items.map(({ title, Icon, items }) => (
        <LeftMenuItem key={`${title}-left-menu`} title={title} place={place} items={items} Icon={Icon} />
      ))}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

LeftMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isMenuOpen: state.welcomeSetup.isMenuOpen,
}))(LeftMenu);
