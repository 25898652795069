import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { withTheme } from 'common/styling/theme';

import { ReduxTextField, TextAreaField } from 'common/components';

import { staticStyles, getDynamicStyles } from './style';

const StatusDescriptionPanel = ({ languages, currentLang, submitting, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isTitleTranslationOpen, setTitleTranslationOpen] = useState(false);
  const [isDescriptionTranslationOpen, setDescriptionTranslationOpen] = useState(false);
  const toggleTitleBlock = () => setTitleTranslationOpen(!isTitleTranslationOpen);
  const toggleDescriptionBlock = () => setDescriptionTranslationOpen(!isDescriptionTranslationOpen);

  return (
    <div className="StatusDescriptionPanel">
      <FormattedMessage id="justPageDescription">
        {txt => <h2 className="StatusDescriptionPanel__title">{txt}</h2>}
      </FormattedMessage>
      <div className="StatusDescriptionPanel__line">
        <div className="StatusDescriptionPanel__left-block">
          <Field
            component={ReduxTextField}
            type="text"
            name={`title.${currentLang}`}
            textId="justTitle"
            disabled={submitting}
            maxLength="100"
            validate={[required()]}
          />
          {isTitleTranslationOpen &&
            languages.map(lang => (
              <Field
                component={ReduxTextField}
                type="text"
                name={`title.${lang}`}
                textId={`lang_${lang}`}
                disabled={submitting}
                maxLength="100"
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                key={lang}
              />
            ))}
        </div>
        <div className="StatusDescriptionPanel__right-block">
          <button type="button" onClick={toggleTitleBlock} disabled={!languages.length}>
            <FormattedMessage id="justShowTranslations">
              {txt => <span className="StatusDescriptionPanel__translations">{txt}</span>}
            </FormattedMessage>
            <i
              className={classNames('StatusDescriptionPanel__arrow', {
                'StatusDescriptionPanel__arrow-up': isTitleTranslationOpen,
                'StatusDescriptionPanel__arrow-down': !isTitleTranslationOpen,
              })}
            />
          </button>
        </div>
      </div>
      <div className="StatusDescriptionPanel__line">
        <div className="StatusDescriptionPanel__left-block">
          <Field
            component={TextAreaField}
            disabled={submitting}
            name={`description.${currentLang}`}
            textId="justDescription"
            rows="4"
            cols="150"
            validate={[required()]}
          />
          {isDescriptionTranslationOpen &&
            languages.map(lang => (
              <Field
                component={TextAreaField}
                disabled={submitting}
                name={`description.${lang}`}
                textId={`lang_${lang}`}
                rows="4"
                cols="150"
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                key={lang}
              />
            ))}
        </div>
        <div className="StatusDescriptionPanel__right-block">
          <button type="button" onClick={toggleDescriptionBlock} disabled={!languages.length}>
            <FormattedMessage id="justShowTranslations">
              {txt => <span className="StatusDescriptionPanel__translations">{txt}</span>}
            </FormattedMessage>
            <i
              className={classNames('StatusDescriptionPanel__arrow', {
                'StatusDescriptionPanel__arrow-up': isDescriptionTranslationOpen,
                'StatusDescriptionPanel__arrow-down': !isDescriptionTranslationOpen,
              })}
            />
          </button>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

StatusDescriptionPanel.propTypes = {
  submitting: PropTypes.bool.isRequired,
  currentLang: PropTypes.string.isRequired,
  languages: PropTypes.array,
  theme: PropTypes.object,
};

StatusDescriptionPanel.defaultProps = {
  languages: [],
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => {
    const availableLangs = state.interfaceConfig.availableLangs;
    const currentLang = state.interfaceConfig.lang;
    const languages = availableLangs.map(item => item.slug).filter(slug => slug !== currentLang);
    return { languages, currentLang };
  })
)(StatusDescriptionPanel);
