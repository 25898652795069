import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FavoritItem {
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;

    &.active {
      background: rgba(255, 255, 255, 0.1);
    }

    &__Tooltip {
      position: absolute;
      display: flex;
      align-items: center;
      width: max-content;
      border-radius: 2px;
      height: 100%;
      z-index: 100;
      left: calc(100% + 1px);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: left;
      background: #ceecf9;
      padding-left: 7px;
      padding-right: 7px;
      color: #0aa1e2;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease-in-out;
    }

    &:hover .FavoritItem__Tooltip {
      height: 100%;
      opacity: 1;
      visibility: visible;
    }

    .FavoritItem__user {
      position: relative;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(12px);
      font-weight: 500;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }
  }
`;

export { staticStyles };
