import React from 'react';
import { FormattedMessage } from 'react-intl';

import ThankForRegistrationImage from 'common/images/message.svg';

import { staticStyles } from './style';

const ThankForRegistration = () => (
  <div className="ThankForRegistration">
    <div className="ThankForRegistration__image">
      <img src={ThankForRegistrationImage} alt="ThankForRegistration" />
    </div>
    <FormattedMessage id="registrationModalHeader">{txt => <h3>{txt}</h3>}</FormattedMessage>
    <FormattedMessage id="registrationModalBody">{txt => <p>{txt}</p>}</FormattedMessage>

    <style jsx>{staticStyles}</style>
  </div>
);

export default ThankForRegistration;
