import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BonusDescriptionPanel {
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    padding: 25px 30px;
    @include respond-to(sm) {
      padding: 20px 15px 1px;
    }

    .BonusDescriptionPanel__caption {
      font-weight: 300;
      font-size: rem(24px);
      margin-bottom: rem(20px);
    }

    .BonusDescriptionPanel__line {
      display: flex;
      width: 100%;

      .BonusDescriptionPanel__left-block {
        flex: 8;
        margin-right: rem(${isRTL ? '0' : '30px'});
        margin-left: rem(${isRTL ? '30px' : '0'});
      }

      .BonusDescriptionPanel__right-block {
        display: flex;
        flex: 2;
        align-items: flex-start;
        justify-content: center;

        .BonusDescriptionPanel__translations {
          font-size: rem(14px);
          font-weight: 500;
        }

        .BonusDescriptionPanel__arrow {
          display: inline-block;
          padding: rem(5px);
          margin-left: rem(${isRTL ? '0' : '30px'});
          margin-right: rem(${isRTL ? '30px' : '0'});
        }

        .BonusDescriptionPanel__arrow-up {
          transform: translateY(5px) rotate(-135deg);
          transition: transform 0.3s ease;
        }

        .BonusDescriptionPanel__arrow-down {
          transform: rotate(45deg);
          transition: transform 0.3s ease;
        }
      }

      .BonusDescriptionPanel__bullets-left-block {
        display: flex;
        flex: 8;
        margin-right: rem(${isRTL ? '0' : '30px'});
        margin-left: rem(${isRTL ? '30px' : '0'});

        .BonusDescriptionPanel__bullets-content {
          flex: 7;
          margin-right: rem(${isRTL ? '0' : '30px'});
          margin-left: rem(${isRTL ? '30px' : '0'});
        }

        .BonusDescriptionPanel__bullets-position {
          display: flex;
          padding-top: rem(10px);
          flex: 1;

          .BonusDescriptionPanel__bullets-count {
            margin-right: rem(${isRTL ? '0' : '10px'});
            margin-left: rem(${isRTL ? '10px' : '0'});
          }

          .BonusDescriptionPanel__bullets-caption {
            margin-right: rem(${isRTL ? '0' : '10px'});
            margin-left: rem(${isRTL ? '10px' : '0'});
          }

          .BonusDescriptionPanel__move-buttons {
            position: relative;
          }

          .BonusDescriptionPanel__bullets-arrow-up {
            position: absolute;
            top: 0;
            width: rem(8px);
            height: rem(8px);
            transform: rotate(-135deg);
          }
          .BonusDescriptionPanel__bullets-arrow-down {
            position: absolute;
            top: 10px;
            width: rem(8px);
            height: rem(8px);
            transform: rotate(45deg);
          }
        }
      }

      @include respond-to(sm) {
        flex-direction: column;

        .BonusDescriptionPanel__right-block {
          justify-content: flex-start;
          margin-bottom: rem(30px);
        }
      }
    }

    .BonusDescriptionPanel__bonus-logo {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: rem(20px);

      .BonusDescriptionPanel__file-field-wrapper {
        margin-right: rem(${isRTL ? '20px' : '0'});
        margin-left: rem(${isRTL ? '0' : '20px'});

        :global(.FileField) {
          display: none;
        }
        :global(.BonusDescriptionPanel__file-upload-image) {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }

      .BonusDescriptionPanel__file-field--active {
        :global(.FileField) {
          display: block;
          margin-bottom: 0;
          width: 50px;
          height: 50px;

          :global(.FileField__dropzone > div) {
            min-height: auto !important;
            padding: 0 !important;
            border: none !important;
          }

          :global(.FileField__item-inner) {
            margin-right: 0;
            width: 50px;
            height: 50px;

            :global(img) {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
          }

          :global(.FileField__file) {
            width: 50px;
            height: 50px;
          }
        }
        :global(.BonusDescriptionPanel__file-upload-image) {
          display: none;
        }
      }
    }

    :global(.DefaultButton) {
      max-width: rem(180px) !important;
      margin-bottom: rem(30px);
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .BonusDescriptionPanel {
      background: ${colors.white.hex};

      .BonusDescriptionPanel__translations {
        color: ${colors.primary.hex};
      }

      .BonusDescriptionPanel__arrow {
        border: solid ${colors.primary.hex};
        border-width: 0 2px 2px 0;
      }

      .BonusDescriptionPanel__bullets-caption {
        color: ${colors.gray.hex};
      }

      .BonusDescriptionPanel__bullets-arrow-up,
      .BonusDescriptionPanel__bullets-arrow-down {
        border: solid ${colors.primary.hex};
        border-width: 0 2px 2px 0;
      }

      button:disabled {
        .BonusDescriptionPanel__translations {
          color: ${colors.lightGray.hex};
        }

        .BonusDescriptionPanel__arrow {
          border: solid ${colors.lightGray.hex};
          border-width: 0 2px 2px 0;
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
