import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InnerPartnershipUsersTable {
    padding-left: rem(15px);
    display: revert;

    &__wrapper {
      padding-left: rem(15px);

      &--max-level {
        padding-left: 0;
        padding-top: rem(15px);
      }
    }

    &__row {
      display: grid;
      min-width: 100%;
      box-shadow: none;
      z-index: 1;
      background-color: #ffffff;
      border-radius: 3px;
      position: relative;
      margin: 0.13333rem 0.26667rem;
      grid-template-columns: repeat(6, minmax(100px, 1fr));

      &--inner {
        margin-left: rem(15px);
      }

      &--with-field {
        grid-template-columns: repeat(5, minmax(100px, 1fr));
      }

      &--short {
        grid-template-columns: repeat(4, minmax(100px, 1fr));
      }
    }

    &__cell {
      white-space: normal;
      display: flex;
      align-items: center;
      padding: rem(15px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__user {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    &__load {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      position: relative;
      margin-top: rem(10px);
      height: 22px;

      &-btn {
        &.loading {
          animation: spin 1s linear infinite;
          cursor: progress;
          @keyframes spin {
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
  :global(.InnerPartnershipUsersTable__no-clients) {
    padding: 20px 0;
    background-color: #ffffff;
    border-radius: 3px;
    position: relative;
    margin: 0.13333rem 0;
    text-align: center;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    :global(.InnerPartnershipUsersTable__row) {
      background-color: ${colors.white.hex};
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent;
      border-radius: 5px;

      &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:hover {
        z-index: 1;
        box-shadow: 0 4px 9px 4px ${rgba([colors.primary.rgb, 0.25])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
