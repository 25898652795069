import css from 'styled-jsx/css';

const getDynamicStyles = ({ colors, border }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .NoChallengesPanel__inner {
      border-radius: ${border.radius};
      border: 1px dashed ${colors.lightGray.hex};
      padding: rem(30px 20px);
      text-align: center;
      h3 {
        color: ${colors.gray.hex};
      }
      :global(button) {
        margin-top: 15px;
      }
    }
  `;
};

export { getDynamicStyles };
