import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_NEWS_LIST = createRequestTypes('GET_NEWS_LIST');
export const getNewsList = {
  request: search => action(GET_NEWS_LIST[REQUEST], { search }),
  success: data => action(GET_NEWS_LIST[SUCCESS], { data }),
  failure: error => action(GET_NEWS_LIST[FAILURE], { error }),
};

export const createNews = createFormAction('CREATE_NEWS');

export const GET_BO_NEWS = createRequestTypes('GET_BO_NEWS');
export const getBONews = {
  request: id => action(GET_BO_NEWS[REQUEST], { id }),
  success: data => action(GET_BO_NEWS[SUCCESS], { data }),
  failure: error => action(GET_BO_NEWS[FAILURE], { error }),
};

export const editNews = createFormAction('EDIT_NEWS');

export const DELETE_NEWS = createRequestTypes('DELETE_NEWS');
export const deleteNews = {
  request: (id, queryParams) => action(DELETE_NEWS[REQUEST], { id, queryParams }),
  success: () => action(DELETE_NEWS[SUCCESS]),
  failure: error => action(DELETE_NEWS[FAILURE], { error }),
};

export const PUBLISH_NEWS = createRequestTypes('PUBLISH_NEWS');
export const publishNews = {
  request: (id, queryParams) => action(PUBLISH_NEWS[REQUEST], { id, queryParams }),
  success: () => action(PUBLISH_NEWS[SUCCESS]),
  failure: error => action(PUBLISH_NEWS[FAILURE], { error }),
};

export const UNPUBLISH_NEWS = createRequestTypes('UNPUBLISH_NEWS');
export const unPublishNews = {
  request: (id, queryParams) => action(UNPUBLISH_NEWS[REQUEST], { id, queryParams }),
  success: () => action(UNPUBLISH_NEWS[SUCCESS]),
  failure: error => action(UNPUBLISH_NEWS[FAILURE], { error }),
};

export const GET_STORY = createRequestTypes('GET_STORY');
export const getStory = {
  request: storyId => action(GET_STORY[REQUEST], { storyId }),
  success: data => action(GET_STORY[SUCCESS], { data }),
  failure: error => action(GET_STORY[FAILURE], { error }),
};
