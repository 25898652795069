import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ModalHOC } from 'common';
import { PageContentPreloader, DefaultButton, BasicModal } from 'common/components';
import noInfoImage from 'common/images/noInfo.svg';
import UserAccounts from '../UserAccounts';
import UserAddAccountForm from '../UserAddAccountForm';

import {
  getUserAccounts,
  blockUserAccount,
  unblockUserAccount,
  deleteUserAccount,
  restoreUserAccount,
} from '../../_redux';

import { staticStyles } from './style';

const UserAccountsPanel = ({
  id,
  realAccounts,
  demoAccounts,
  archivedAccounts,
  accountsAreLoaded,
  blockUserAccount,
  unblockUserAccount,
  deleteUserAccount,
  restoreUserAccount,
  profile,
  partnershipSystem,
  getUserAccounts,
  showModal,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    getUserAccounts({ userId: id });
  }, [getUserAccounts, id]);

  return (
    <PageContentPreloader ready={accountsAreLoaded}>
      <div className="UserAccountsPanel__head">
        <DefaultButton textId="justAddAccount" type="button" onClick={toggleModal} filled plus />
      </div>
      <UserAccounts
        data={realAccounts}
        blockFunc={blockUserAccount}
        unBlockFunc={unblockUserAccount}
        deleteFunc={deleteUserAccount}
        restoreFunc={restoreUserAccount}
        userId={id}
        profile={profile}
        captionId="boUserRealAccounts"
        imageNoAccount={noInfoImage}
        noAccountsTextId="boNoAccounts"
        partnershipSystem={partnershipSystem}
      />
      <UserAccounts
        isDemo
        data={demoAccounts}
        userId={id}
        captionId="boUserDemoAccounts"
        imageNoAccount={noInfoImage}
        noAccountsTextId="boNoAccounts"
      />
      <UserAccounts
        data={archivedAccounts}
        captionId="boUserArchivedAccounts"
        imageNoAccount={noInfoImage}
        blockFunc={blockUserAccount}
        unBlockFunc={unblockUserAccount}
        deleteFunc={deleteUserAccount}
        restoreFunc={restoreUserAccount}
        userId={id}
        profile={profile}
        partnershipSystem={partnershipSystem}
        noAccountsTextId="boNoArchivedAccounts"
      />

      <BasicModal isOpen={isModalOpen} captionId="justAddAccount" onRequestClose={toggleModal}>
        <UserAddAccountForm
          toggleModal={toggleModal}
          userId={id}
          getUserAccounts={getUserAccounts}
          showModal={showModal}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </PageContentPreloader>
  );
};

UserAccountsPanel.propTypes = {
  id: PropTypes.string.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  realAccounts: PropTypes.array,
  demoAccounts: PropTypes.array,
  archivedAccounts: PropTypes.array,
  accountsAreLoaded: PropTypes.bool.isRequired,
  blockUserAccount: PropTypes.func.isRequired,
  unblockUserAccount: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  restoreUserAccount: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  partnershipSystem: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
};

UserAccountsPanel.defaultProps = {
  realAccounts: [],
  demoAccounts: [],
  archivedAccounts: [],
};

const ConnectedUserAccountsPanel = connect(
  state => ({
    partnershipSystem: state.interfaceConfig.partnership.partnershipSystem,
    profile: state.user.profile,
    realAccounts: state.backoffice.users.accounts.filter(account => !account.isDemo && !account.isArchived),
    demoAccounts: state.backoffice.users.accounts.filter(account => account.isDemo && !account.isArchived),
    archivedAccounts: state.backoffice.users.accounts.filter(account => account.isArchived),
    accountsAreLoaded: state.backoffice.users.accountsAreLoaded,
  }),
  {
    getUserAccounts: user => getUserAccounts.request(user),
    blockUserAccount: (userId, accountId) => blockUserAccount.request(userId, accountId),
    unblockUserAccount: (userId, accountId) => unblockUserAccount.request(userId, accountId),
    deleteUserAccount: (userId, accountId) => deleteUserAccount.request(userId, accountId),
    restoreUserAccount: (userId, accountId) => restoreUserAccount.request(userId, accountId),
  }
)(UserAccountsPanel);

export default ModalHOC(ConnectedUserAccountsPanel);
export { UserAccountsPanel };
