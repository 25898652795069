import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getNewsListSaga({ search }) {
  try {
    const { data } = yield call(api.getNewsList, search);
    yield put(ActionTypes.getNewsList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getNewsList.failure(e));
  }
}
export function* getNewsListWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_NEWS_LIST[REQUEST]);
    yield call(getNewsListSaga, search);
  }
}

export function* createNewsSaga(title, body) {
  try {
    yield call(api.createNews, title, body);
    yield put(ActionTypes.createNews.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createNews.failure(e));
  }
}
export function* createNewsWatcherSaga() {
  while (true) {
    const {
      payload: { title, body },
    } = yield take(ActionTypes.createNews.REQUEST);
    yield call(createNewsSaga, title, body);
  }
}

export function* getBONewsSaga(id) {
  try {
    const { data } = yield call(api.getBONews, id);
    yield put(ActionTypes.getBONews.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getBONews.failure(e));
  }
}
export function* getBONewsWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_BO_NEWS[REQUEST]);
    yield call(getBONewsSaga, id);
  }
}

export function* editNewsSaga(id, title, body) {
  try {
    yield call(api.editNews, id, title, body);
    yield put(ActionTypes.editNews.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editNews.failure(e));
  }
}
export function* editNewsWatcherSaga() {
  while (true) {
    const {
      payload: { id, title, body },
    } = yield take(ActionTypes.editNews.REQUEST);
    yield call(editNewsSaga, id, title, body);
  }
}

export function* deleteNewsSaga(id, queryParams) {
  try {
    yield call(api.deleteNews, id);
    yield put(ActionTypes.deleteNews.success());
    if (queryParams) {
      yield call(getNewsListSaga, queryParams);
    } else {
      yield call(getBONewsSaga, id);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteNews.failure(e));
  }
}
export function* deleteNewsWatcherSaga() {
  while (true) {
    const { id, queryParams } = yield take(ActionTypes.DELETE_NEWS[REQUEST]);
    yield call(deleteNewsSaga, id, queryParams);
  }
}

export function* publishNewsSaga(id, queryParams) {
  try {
    yield call(api.publishNews, id);
    yield put(ActionTypes.publishNews.success());
    if (queryParams) {
      yield call(getNewsListSaga, queryParams);
    } else {
      yield call(getBONewsSaga, id);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.publishNews.failure(e));
  }
}
export function* publishNewsWatcherSaga() {
  while (true) {
    const { id, queryParams } = yield take(ActionTypes.PUBLISH_NEWS[REQUEST]);
    yield call(publishNewsSaga, id, queryParams);
  }
}

export function* unPublishNewsSaga(id, queryParams) {
  try {
    yield call(api.unpublishNews, id);
    yield put(ActionTypes.unPublishNews.success());
    if (queryParams) {
      yield call(getNewsListSaga, queryParams);
    } else {
      yield call(getBONewsSaga, id);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.unPublishNews.failure(e));
  }
}
export function* unPublishNewsWatcherSaga() {
  while (true) {
    const { id, queryParams } = yield take(ActionTypes.UNPUBLISH_NEWS[REQUEST]);
    yield call(unPublishNewsSaga, id, queryParams);
  }
}

export function* getStorySaga({ storyId }) {
  try {
    const { data } = yield call(api.getStory, storyId);
    yield put(ActionTypes.getStory.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getStory.failure());
  }
}

export function* getStoryWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_STORY[REQUEST]);
    yield call(getStorySaga, payload);
  }
}

export default [
  fork(getNewsListWatcherSaga),
  fork(createNewsWatcherSaga),
  fork(getBONewsWatcherSaga),
  fork(editNewsWatcherSaga),
  fork(deleteNewsWatcherSaga),
  fork(publishNewsWatcherSaga),
  fork(unPublishNewsWatcherSaga),
  fork(getStoryWatcherSaga),
];
