import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  issues: {},
  issuesAreLoaded: false,

  issue: {},
  issueIsLoaded: false,

  messageIsCreating: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ISSUE[REQUEST]:
      return { ...state, issueIsLoaded: false };
    case ActionTypes.GET_ISSUE[SUCCESS]:
      return { ...state, issue: action.data, issueIsLoaded: true };
    case ActionTypes.GET_ISSUE[FAILURE]:
      return { ...state, error: action.error, issueIsLoaded: false };

    case ActionTypes.GET_ISSUES[REQUEST]:
      return { ...state, issuesAreLoaded: false };
    case ActionTypes.GET_ISSUES[SUCCESS]:
      return { ...state, issues: action.data, issuesAreLoaded: true };
    case ActionTypes.GET_ISSUES[FAILURE]:
      return { ...state, error: action.error, issuesAreLoaded: false };

    case ActionTypes.createMessage[REQUEST]:
      return { ...state, messageIsCreating: true };
    case ActionTypes.createMessage[SUCCESS]:
      return { ...state, messageIsCreating: false };
    case ActionTypes.createMessage[FAILURE]:
      return { ...state, error: action.error, messageIsCreating: false };

    default:
      return state;
  }
};

export default reducer;
