import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AccountInfoPanel__wrapper {
    margin-bottom: rem(45px);
    :global(.WarningMessage) {
      margin-bottom: rem(25px);
    }
  }

  .AccountInfoPanel {
    margin-bottom: 25px;
    padding: rem(30px 30px 20px 20px);
    position: relative;
    @include respond-to(sm) {
      overflow: hidden;
    }
    :global(.LoginCell__inner) {
      :global(.LoginCell__name) {
        max-width: 90%;
        @include respond-to(xs) {
          max-width: 65%;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        padding: rem(0 10px);
        margin-bottom: rem(15px);
        min-width: rem(115px);
        .AccountInfoPanel__second-line {
          display: flex;
          height: rem(45px);
          align-items: center;
        }
        .AccountInfoPanel__caption {
          &:first-child {
            margin-bottom: 5px;
            font-size: 13px;
          }
        }
      }
      .Leverage__wrapper {
        min-width: auto;
      }
      .AccountInfoPanel__payments-buttons {
        flex-direction: row;
        height: rem(70px);
        align-items: center;
        .DefaultButton,
        span {
          margin-bottom: 0 !important;
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
        }

        .DefaultButton__text {
          margin-right: 0;
        }
      }
    }
  }

  .AccountInfoPanel__top {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    .AccountInfoPanel__second-line {
      p {
        font-size: rem(16px);
      }
    }
  }

  .AccountInfoPanel--MarginBottom {
    margin-bottom: 10px !important;
  }

  .AccountInfoPanel__bottom {
    padding: rem(20px 30px 10px 20px);
    margin: 0;
    ul {
      li {
        height: rem(67px);
        justify-content: center;
        &:first-child {
          .AccountInfoPanel__second-line {
            font-size: rem(24px);
          }
        }
      }
    }
  }

  .Account__partner-code {
    .AccountInfoPanel__second-line {
      font-size: rem(16px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .AccountInfoPanel {
      background-color: ${colors.white.hex};

      &__caption {
        color: ${colors.gray.hex};
      }
    }

    .AccountInfoPanel__top {
      &:after {
        background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }
    }

    .AccountInfoPanel__bottom {
      ul {
        li {
          &:first-child {
            .AccountInfoPanel__second-line {
              color: ${colors.secondary.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
