import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

import { changeLanguage } from 'interface/connected-intl';

import { staticStyles } from './style';

export const FlagImage = ({ flag }) => <img src={`/countryIcons/${flag}.png`} alt="country" />;

FlagImage.propTypes = {
  flag: PropTypes.string.isRequired,
};

const ChangeLangBar = ({ availableLangs, currentLang, changeLanguage, className, defaultLang }) => {
  const { flag, niceName } = availableLangs.find(lang => lang.slug === currentLang) || defaultLang;

  const handleChangeLanguage = ({ value }) => changeLanguage(value);

  return (
    <div className={classNames('LangButton', className)}>
      <div className="LangButton__image">
        <FlagImage flag={flag === 'ar' ? 'sa' : flag} />
      </div>
      <div className="LangButton__select-inner">
        <Select
          classNamePrefix="Select"
          placeholder=""
          hideSelectedOptions
          menuPlacement="top"
          onChange={handleChangeLanguage}
          options={availableLangs.map(({ slug, niceName, flag }) => ({
            value: slug,
            label: (
              <div className="LangButton__label">
                <FlagImage flag={flag === 'ar' ? 'sa' : flag} /> {niceName}
              </div>
            ),
          }))}
          value={{ value: currentLang, label: niceName }}
          noOptionsMessage={() => null}
          isClearable={false}
          isSearchable={false}
        />
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {`
          .LangButton__select-inner {
            width: ${10 * niceName.length + 30}px;
          }
        `}
      </style>
    </div>
  );
};

ChangeLangBar.propTypes = {
  className: PropTypes.string,
  availableLangs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      niceName: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentLang: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  defaultLanguage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    niceName: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
  }),
  defaultLang: PropTypes.shape({
    niceName: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
  }),
};

ChangeLangBar.defaultProps = {
  className: '',
  defaultLanguage: {
    slug: 'gb',
    niceName: 'English',
    flag: 'gb',
  },
  defaultLang: {
    flag: 'gb',
    niceName: 'English',
  },
};

const ThemedChangeLangBar = connect(
  state => ({
    currentLang: state.interfaceConfig.lang,
    availableLangs: state.interfaceConfig.availableLangs,
    isLanguageChanging: state.interfaceConfig.isLanguageChanging,
  }),
  { changeLanguage }
)(ChangeLangBar);

export default ThemedChangeLangBar;
export { ChangeLangBar };
