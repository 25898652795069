import css from 'styled-jsx/css';
import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .App[dir='rtl'] .Select__single-value {
    left: 0;
  }

  .ClientInfo__inner {
    padding: 15px 0 15px 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 15;
    &:hover {
      .ClientInfo__menu {
        display: block;
      }
    }
    @include respond-to(md) {
      padding: 0;
      padding-left: 10px;
    }
    @include respond-to(sm) {
      display: flex;
      flex-direction: column;
      border: none !important;
      justify-content: center;
      padding: 0 3px 0 10px;
      gap: 4px;
      align-items: flex-start;
    }
  }

  .ClientInfo__mobile-status-name {
    display: none;
    @include respond-to(sm) {
      display: flex;
    }
  }

  .ClientInfo__image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0 15px;
    margin-left: ${isRTL ? '15px' : '0'};
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    @include respond-to(sm) {
      height: 35px;
      width: 35px;

      position: relative;
      margin: 0;
      margin-right: 30px;
      &:after {
        content: '';
        position: absolute;
        right: -20px;
        top: 35%;
        width: 8px;
        height: 8px;
        border: solid;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
      }
    }
  }

  .ClientInfo__status-name {
    strong {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      position: relative;
      transition: all 0.4s ease;
      display: inline-block;
      max-width: 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include respond-to(sm) {
        display: none;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transition: none;
      }
      &:after {
        content: '';
        position: absolute;
        top: 24%;
        width: 8px;
        height: 8px;
        border: solid;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
      }
    }
    span {
      @include respond-to(sm) {
        display: none;
      }
    }
  }

  .ClientInfo__menu {
    position: absolute;
    z-index: 4;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    min-width: 200px;
    display: none;
    @media only screen and (max-width: 1070px) {
      min-width: 160px;
    }

    @include respond-to(sm) {
      left: -100px;
    }
    ul {
      .ClientInfo__mobile-fullname-li {
        width: 100%;
      }
      li {
        cursor: pointer;
        &:last-child {
          a {
            border-bottom: none;
          }
        }
        a,
        button,
        .ClientInfo__user-id,
        .ClientInfo__user-security-code {
          width: 100%;
          text-align: left;
          font-size: rem(15px);
          display: block;
          padding: rem(14px 10px 14px 20px);
        }
        .ClientInfo__mobile-fullname {
          width: 100%;
          text-align: left;
          display: block;
          padding: rem(14px 10px 14px 20px);
        }
      }
    }
  }

  .ClientInfo__langButton {
    position: relative;
    .LangButton__image {
      position: absolute;
      top: 54%;
      left: 20px;
      transform: translateY(-50%);
      :global(img) {
        top: 0;
      }
    }
    .LangButton__select-inner {
      width: 100%;
    }
    .Select__menu {
      width: 105% !important;
      min-width: 100%;
    }
    .Select__indicators {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(15px);
    }
    .Select__control {
      cursor: pointer !important;
      padding: rem(14px 10px 14px 45px) !important;
    }
    .Select__option {
      padding: rem(15px 20px) !important;
    }
    .Select__menu {
      left: -104% !important;
      top: -1px !important;
    }
  }

  .ClientInfo__langButton_show {
    .Select__indicators {
      display: block;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .ClientInfo__inner {
      border-left: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      border-left-width: ${isRTL ? '0' : '0.5px'};

      &:hover {
        .ClientInfo__status-name {
          strong {
            color: ${colors.gray.hex};
            &:after {
              border-color: ${colors.gray.hex};
            }
          }
        }
      }

      .ClientInfo__status-name {
        strong {
          padding-right: ${isRTL ? '0' : '20px'};
          padding-left: ${isRTL ? '20px' : '0'};

          &::after {
            right: ${isRTL ? 'auto' : '0'};
            left: ${isRTL ? '0' : 'auto'};
          }
        }
      }
    }

    .ClientInfo__image {
      background-color: ${colors.gray.hex};
      @include respond-to(sm) {
        &:after {
          border-color: ${colors.black.hex};
        }
      }
    }

    .ClientInfo__status-name {
      strong {
        &:after {
          border-color: ${colors.black.hex};
        }
      }
    }

    .ClientInfo__menu {
      background-color: ${colors.white.hex};
      ul {
        li {
          &.ChangeLangButton__li {
            border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.6])};
            &:hover {
              background-color: ${rgba([colors.lightGray.rgb, 0.4])};
            }
          }
          &.ClientInfo__mobile-fullname-li {
            border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.6])};
            &:hover {
              background-color: ${rgba([colors.lightGray.rgb, 0.4])};
            }
          }
          a,
          button {
            border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.6])};
            color: ${colors.black.hex};
            &:hover {
              background-color: ${rgba([colors.lightGray.rgb, 0.4])};
            }
            &.active {
              background-color: ${rgba([colors.lightGray.rgb, 0.4])};
            }
          }
          .ClientInfo__user-id,
          .ClientInfo__user-security-code {
            cursor: initial;
            border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.6])};
            color: #777777;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
