import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ModalHOC } from 'common';
import { ReloadIcon } from 'common/icons';
import { LoginCell, ChallengeInfoElem, ActionsButton } from 'common/components';

import { restartChallenge } from 'client/challenges/_redux';
import { staticStyles } from './style';

const ChallengeHistoryItem = ({ archiveChallenge, restartChallenge, showModal }) => (
  <div className="ChallengeHistoryItemWrapper">
    <div className="ChallengeHistoryItems--Left">
      {console.log({ archiveChallenge })}
      <LoginCell noColor title={archiveChallenge.challenge.title} login={archiveChallenge.account?.login} />
      <ChallengeInfoElem>{archiveChallenge.challenge.title}</ChallengeInfoElem>
      <ChallengeInfoElem innerDotColor="blue">{archiveChallenge.level.name}</ChallengeInfoElem>
      <ChallengeInfoElem borderColor="#0AA1E2">{archiveChallenge.challenge.initialAccountBalance}</ChallengeInfoElem>
    </div>

    <div className="ChallengeHistoryItem ChallengeHistoryItem--Right">
      <ActionsButton
        tooltipId="Restart__tooltip"
        tooltipTextId="justRestartChallenge"
        deleteType
        onClickFunc={() => {
          showModal({
            caption: { id: 'justRestartChallenge' },
            content: { id: 'justAreYouSureYouWantToRestartThisChallenge' },
            actionButton: {
              textId: 'justRestartChallenge',
              handler: () => restartChallenge(archiveChallenge.challenge.id),
            },
            cancelButton: null,
          });
        }}>
        <div className="ReloadIcon">
          <ReloadIcon />
        </div>
      </ActionsButton>
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default compose(
  ModalHOC,
  connect(null, {
    restartChallenge: id => restartChallenge.request(id),
  })
)(ChallengeHistoryItem);
