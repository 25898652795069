import css from 'styled-jsx/css';

const staticStyles = css.global`
  .FlagImage {
    display: flex;
    align-items: center;
    gap: 4px;

    &__image {
      width: 40px;
      height: 30px;
    }
  }
`;

export { staticStyles };
