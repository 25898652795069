import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .AuthSlider {
    width: calc(100% - 28%);

    @media only screen and (min-height: 1400px) {
      height: 100%;
    }
    @media only screen and (max-width: 1880px) {
      width: calc(100% - 30%);
    }
    @media only screen and (max-width: 1700px) {
      width: calc(100% - 32%);
    }
    @media only screen and (max-width: 1350px) {
      width: calc(100% - 35%);
    }
    @media only screen and (max-width: 1180px) {
      width: calc(100% - 45%);
    }
    @include respond-to(md) {
      width: calc(100% - 40%);
    }
    @media only screen and (max-width: 920px) {
      width: calc(100% - 45%);
    }
    @media only screen and (max-width: 830px) {
      width: calc(100% - 50%);
    }
    @include respond-to(sm) {
      display: none;
    }

    &__item {
      width: 100vw;
      height: 100vh;

      &-image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }

    .slick-dots {
      display: flex !important;
      position: absolute;
      bottom: 100px;
      left: 50px;

      li {
        margin-right: 15px;

        button {
          background: transparent;
          border: 1px solid;
          width: 15px;
          height: 15px;
          display: block;
          border-radius: 50%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .AuthSlider {
      :global(.slick-dots) {
        :global(li) {
          :global(button) {
            border-color: ${colors.primary.hex};
          }
        }
        :global(li.slick-active) {
          :global(button) {
            background: ${colors.primary.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
