import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import RangeSelector from './_components/DateSelector';

import { staticStyles } from './style';

const InputRange = ({ name, enteredValues, onChange, isDisabled }) => (
  <div className="InputRange">
    <div className="InputRange__Title">
      <FM id={name}>{txt => <span className="InputRange__Title-text">{txt}</span>}</FM>
    </div>
    <RangeSelector isDisabled={isDisabled} enteredValues={enteredValues} dropDownTitle={name} onChange={onChange} />
    <style jsx>{staticStyles}</style>
  </div>
);

InputRange.propTypes = {
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

InputRange.defaultProps = {
  isDisabled: false,
};

export default InputRange;
