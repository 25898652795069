import { put, call, fork, take, select } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import { addLocaleData } from 'react-intl';
import Raven from 'raven-js';
import queryString from 'query-string';

import moment from 'moment';
import 'moment/min/locales';

import { acceptError } from 'common/_redux';
import { getCookieObj } from 'common/utils/helpers';
import * as api from '../api';
import * as ActionTypes from './actions';
import { stateSelector } from './reducer';

export function* loadLanguage({ slug, file }, changeUser) {
  yield put(ActionTypes.loadLanguage.request(slug));

  try {
    const { data, status } = yield call(api.loadLanguage, file, changeUser);
    const reactIntlSlug = slug.split('_')[0];

    // info:  https://goo.gl/8AijvJ
    addLocaleData(require(`react-intl/locale-data/${reactIntlSlug}`)); // eslint-disable-line

    yield put(ActionTypes.loadLanguage.success(slug, data));
    return status;
  } catch (e) {
    yield put(ActionTypes.loadLanguage.failure(slug, e.message));
    return -1;
  }
}

export function* changeLanguage(langCode, changeUser) {
  const cookie = getCookieObj();
  const userWrongLanguage = cookie.user_wrong_language === 'True';
  const browserLanguageCode = cookie.browser_language_code;
  langCode = userWrongLanguage ? browserLanguageCode : langCode;

  // load current store state
  const state = yield select(stateSelector);
  const langInfo = state.availableLangs.find(item => item.slug === langCode);

  if (langInfo) {
    if (langCode in state.loadedLangs || (yield call(loadLanguage, langInfo, changeUser)) !== -1) {
      const momentSlug = langCode.toLowerCase().replace('_', '-'); // transform zh_CN to zh-cn
      moment.locale(momentSlug);
      localStorage.setItem('locale', langCode);
      if (state.isLanguageChanging) window.location.reload();
      yield put(ActionTypes.changeLanguage.success(langCode));
      return 0;
    }
  }
  return -1;
}

export function* getInterfaceConfig() {
  try {
    const { data, status } = yield call(api.getInterfaceConfig);
    if (status === 401) {
      yield put(ActionTypes.getInterfaceConfig.failure(data.error));
      localStorage.removeItem('jwtToken');
      window.location.reload();
    } else {
      yield put(ActionTypes.getInterfaceConfig.success(data));

      return {
        availableLangs: { locales: data.locales, baseLocale: data.baseLocale },
        analytics: data.analytics,
        error: 0,
        brokeree: data.brokeree,
      };
    }
  } catch (e) {
    yield put(ActionTypes.getInterfaceConfig.failure(e.message));
    return { availableLangs: {}, error: e.response.status };
  }
}

export function* getBrokereeConfig() {
  try {
    const { data } = yield call(api.getBrokereeConfig);
    yield put(ActionTypes.getBrokereeConfig.success(data));
  } catch (e) {
    yield put(ActionTypes.getBrokereeConfig.failure(e.message));
  }
}

export function* getCustomCss() {
  try {
    const { data } = yield call(api.getCustomCss);
    yield put(ActionTypes.getCustomCss.success(data));
    return data;
  } catch (e) {
    yield put(ActionTypes.getCustomCss.failure(e.message));
    return {};
  }
}

export function* getCustomJs() {
  try {
    const { data } = yield call(api.getCustomJs);
    yield put(ActionTypes.getCustomJs.success(data));
    return data;
  } catch (e) {
    yield put(ActionTypes.getCustomJs.failure(e.message));
    return {};
  }
}

// This code should be refactoring later. Wrote by backender.
export function* getTelegramAuthWidget() {
  try {
    const { data } = yield call(api.getTelegramAuthWidget);
    yield put(ActionTypes.getTelegramAuthWidget.success(data));
    localStorage.setItem('telegramWidgetSrc', data.telegramWidget);
    return data;
  } catch (e) {
    yield put(ActionTypes.getTelegramAuthWidget.failure(e.message));
    return {};
  }
}

export function* initializeAppSaga() {
  const { availableLangs, analytics, error, brokeree } = yield call(getInterfaceConfig);

  yield call(getCustomCss);
  yield call(getCustomJs);
  yield call(getTelegramAuthWidget);
  if (brokeree) {
    yield call(getBrokereeConfig);
  }
  if (error) {
    yield put(ActionTypes.initializeApp.failure(error));
  } else if (!availableLangs.locales) {
    Raven.captureMessage('No langs available');
  } else {
    // Initialize trackers
    Object.keys(analytics).forEach(tracker => {
      if (analytics[tracker].enabled) {
        localStorage.setItem(tracker, analytics[tracker].id);
      } else {
        localStorage.removeItem(tracker);
      }
    });

    // Locales
    const locale = localStorage.getItem('locale');
    const queryLang = queryString.parse(window.location.search).lang;
    const browserLang = (navigator.languages && navigator.languages[0]) || navigator.language;
    const browserLangShort = (browserLang && browserLang.slice(0, 2)) || '';
    const userLangs = [locale, queryLang, browserLang, browserLangShort, availableLangs.baseLocale, 'en'];

    for (let i = 0; i < userLangs.length; i += 1) {
      if (
        availableLangs.locales.find(lang => lang.slug === userLangs[i]) &&
        (yield call(changeLanguage, userLangs[i])) !== -1
      ) {
        yield put(ActionTypes.initializeApp.success());
        return 0;
      }
    }
  }
}

export function* watchChangeLanguage() {
  while (true) {
    const { lang, changeUser } = yield take(ActionTypes.CHANGE_LANGUAGE.REQUEST);
    yield call(changeLanguage, lang, changeUser);
  }
}

export function* watchInitializeApp() {
  while (true) {
    yield take(ActionTypes.INITIALIZE_APP.REQUEST);
    yield call(initializeAppSaga);
  }
}

export function* getCompanyNewsSaga() {
  try {
    const { status, data } = yield call(api.getCompanyNews);
    if (status < 300) {
      yield put(ActionTypes.getCompanyNews.success(data));
    } else {
      yield put(ActionTypes.getCompanyNews.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCompanyNews.failure());
  }
}

export function* getCompanyNewsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_COMPANY_NEWS[REQUEST]);
    yield call(getCompanyNewsSaga);
  }
}

export default [fork(watchChangeLanguage), fork(watchInitializeApp), fork(getCompanyNewsWatcherSaga)];
