import css from 'styled-jsx/css';

const staticStyles = css`
  .ConfirmEmail {
    display: flex;
    flex-direction: column;

    :global(.DefaultButton) {
      align-self: center;
      margin-top: 20px;
    }
  }
`;

export default staticStyles;
