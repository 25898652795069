import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .EditOrderForm {
    .EditOrderForm__fields {
      display: grid;
      grid-template-columns: repeat(3, minmax(250px, 1fr));
      grid-column-gap: 2vw;
      margin-top: rem(10px);

      :global(.EditOrderForm__wide-field) {
        grid-column: span 2;
      }

      @include respond-to(sm) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

        :global(.EditOrderForm__wide-field) {
          grid-column: span 1;
        }
      }
    }
    :global(.DefaultButton) {
      margin-right: rem(20px);
    }
  }
`;

export { staticStyles };
