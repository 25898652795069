import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_ALL_BONUSES = createRequestTypes('GET_ALL_BONUSES');
export const getAllBonuses = {
  request: id => action(GET_ALL_BONUSES[REQUEST], { id }),
  success: data => action(GET_ALL_BONUSES[SUCCESS], { data }),
  failure: error => action(GET_ALL_BONUSES[FAILURE], { error }),
};

export const GET_USER_BONUSES = createRequestTypes('GET_USER_BONUSES');
export const getUserBonuses = {
  request: search => action(GET_USER_BONUSES[REQUEST], { search }),
  success: data => action(GET_USER_BONUSES[SUCCESS], { data }),
  failure: error => action(GET_USER_BONUSES[FAILURE], { error }),
};

export const GET_PER_BONUSES = createRequestTypes('GET_PER_BONUSES');
export const getPerBonuses = {
  request: search => action(GET_PER_BONUSES[REQUEST], { search }),
  success: data => action(GET_PER_BONUSES[SUCCESS], { data }),
  failure: error => action(GET_PER_BONUSES[FAILURE], { error }),
};

export const REJECT_PER_BONUS = createRequestTypes('REJECT_PER_BONUS');
export const rejectPerBonus = {
  request: data => action(REJECT_PER_BONUS[REQUEST], data),
  success: data => action(REJECT_PER_BONUS[SUCCESS], { data }),
  failure: error => action(REJECT_PER_BONUS[FAILURE], { error }),
};

export const REJECT_BONUS = createRequestTypes('REJECT_BONUS');
export const rejectBonus = {
  request: data => action(REJECT_BONUS[REQUEST], data),
  success: data => action(REJECT_BONUS[SUCCESS], { data }),
  failure: error => action(REJECT_BONUS[FAILURE], { error }),
};

export const activateBonus = createFormAction('ACTIVATE_BONUS');
