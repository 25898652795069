import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ConfirmResetPasswordPage {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0C0C3D;

    @include respond-to(xs) {
      justify-content: flex-end;
      padding: 50px 20px 20px 20px;
    }

    &__inner {
      max-width: 320px;
      width: 100%;

      > h3 {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }
`;

export { staticStyles };
