import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const CopyTextIcon = ({ grayColor }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.5" y="4.5" width="8" height="10" rx="1.5" stroke={grayColor.hex} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2 2H7.8C8.37655 2 8.74883 2.00078 9.03216 2.02393C9.30383 2.04612 9.40455 2.0838 9.45399 2.10899C9.64215 2.20487 9.79513 2.35785 9.89101 2.54601C9.9162 2.59545 9.95388 2.69617 9.97607 2.96784C9.97694 2.97843 9.97777 2.98915 9.97858 3H10.9808C10.9569 2.60779 10.903 2.32953 10.782 2.09202C10.5903 1.71569 10.2843 1.40973 9.90798 1.21799C9.48016 1 8.9201 1 7.8 1H5.2C4.0799 1 3.51984 1 3.09202 1.21799C2.71569 1.40973 2.40973 1.71569 2.21799 2.09202C2 2.51984 2 3.07989 2 4.2V8.8C2 9.9201 2 10.4802 2.21799 10.908C2.40973 11.2843 2.71569 11.5903 3.09202 11.782C3.32953 11.903 3.60779 11.9569 4 11.9808V10.9786C3.98915 10.9778 3.97843 10.9769 3.96784 10.9761C3.69617 10.9539 3.59545 10.9162 3.54601 10.891C3.35785 10.7951 3.20487 10.6422 3.10899 10.454C3.0838 10.4045 3.04612 10.3038 3.02393 10.0322C3.00078 9.74883 3 9.37655 3 8.8V4.2C3 3.62345 3.00078 3.25117 3.02393 2.96784C3.04612 2.69617 3.0838 2.59545 3.10899 2.54601C3.20487 2.35785 3.35785 2.20487 3.54601 2.10899C3.59545 2.0838 3.69617 2.04612 3.96784 2.02393C4.25117 2.00078 4.62345 2 5.2 2Z"
      fill={grayColor.hex}
    />
  </svg>
);

CopyTextIcon.propTypes = {
  grayColor: colorType,
};

CopyTextIcon.defaultProps = {
  grayColor: {},
};

const ThemedCopyTextIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(CopyTextIcon);

export default ThemedCopyTextIcon;
