import React from 'react';
import PropTypes from 'prop-types';

import Issue from '../Issue';

import staticStyles from './style';

const IssuesPanel = ({
  profile,
  issue,
  activeIssue,
  issueIsLoaded,
  issues,
  getIssue,
  markIssueAsViewed,
  toggleIssue,
  createMessage,
  closeIssue,
}) => (
  <div className="IssuesPanel">
    {issues.map(item => (
      <Issue
        key={item.id}
        id={item.id}
        profile={profile}
        item={item}
        issue={issue}
        active={item.id === activeIssue && issueIsLoaded}
        getIssue={getIssue}
        markIssueAsViewed={markIssueAsViewed}
        toggleIssue={toggleIssue}
        createMessage={createMessage}
        closeIssue={closeIssue}
      />
    ))}
    <style jsx>{staticStyles}</style>
  </div>
);

IssuesPanel.propTypes = {
  profile: PropTypes.object.isRequired,
  issue: PropTypes.object.isRequired,
  activeIssue: PropTypes.number.isRequired,
  issues: PropTypes.array.isRequired,
  getIssue: PropTypes.func.isRequired,
  markIssueAsViewed: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  closeIssue: PropTypes.func.isRequired,
  toggleIssue: PropTypes.func.isRequired,
  issueIsLoaded: PropTypes.bool,
};

IssuesPanel.defaultProps = {
  issueIsLoaded: false,
};

export default IssuesPanel;
