import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseStaticSelect {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: column;

    &__OpenOptionsButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      height: 100%;
      position: relative;
      font-size: 14px;
      line-height: 1.4;
      color: #cecece;
      background-color: #fff;

      &:disabled {
        background: #f9f9f9;
      }
    }
    .highliteSelected {
      color: black;
    }

    &__OptionMulti--Selected {
      background: #f3fafe;
    }

    &__OpenOptionsButton--FastFilter {
      justify-content: flex-end;
    }

    &__Options {
      max-height: 300px;
      height: max-content;
      width: 100%;
      top: calc(100% + 4px);
      position: absolute;
      z-index: 4;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 1.237px 2.714px 0px rgba(0, 0, 0, 0.02), 0px 3.127px 6.863px 0px rgba(0, 0, 0, 0.03),
        0px 6.38px 13.999px 0px rgba(0, 0, 0, 0.04), 0px 13.141px 28.836px 0px rgba(0, 0, 0, 0.05),
        0px 36px 79px 0px rgba(0, 0, 0, 0.07);
      overflow-x: hidden;
      overflow-y: scroll;

      &--mobile {
        position: static;
        box-shadow: none;
        max-height: 273px;
        height: min-content;
      }
    }

    &__Option {
      position: relative;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      text-align: left;
      height: 44px;
      font-size: 14px;
      line-height: 1.4;
      color: #333;

      &:not(:last-child) {
        border-bottom: 1px solid #e9e9e9;
      }

      &:hover {
        background: #f3fafe;
      }
    }

    &__Option--FastFilter {
      height: 44px;
      min-height: 44px !important;
    }
  }

  .ModalDropDown {
    .BasicModal__inner {
      padding: 16px 0 !important;
    }
  }
`;

export { staticStyles };
