import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_APPROVE_PHASE_LIST = createRequestTypes('GET_APPROVE_PHASE_LIST');
export const getApprovePhaseList = {
  request: () => action(GET_APPROVE_PHASE_LIST[REQUEST]),
  success: data => action(GET_APPROVE_PHASE_LIST[SUCCESS], { data }),
  failure: error => action(GET_APPROVE_PHASE_LIST[FAILURE], { error }),
};

export const mutateApprovePhase = createFormAction('MUTATE_APPROVE_PHASE');

export const DELETE_APPROVE_PHASE = createRequestTypes('DELETE_APPROVE_PHASE');
export const deleteApprovePhase = {
  request: data => action(DELETE_APPROVE_PHASE[REQUEST], data),
  success: () => action(DELETE_APPROVE_PHASE[SUCCESS]),
  failure: error => action(DELETE_APPROVE_PHASE[FAILURE], { error }),
};
