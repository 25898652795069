import { put, call, fork, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';
import * as api from '../api';
import * as ActionTypes from './actions';

export function* getAuthBannersSaga() {
  try {
    const { status, data } = yield call(api.getAuthBanners);
    if (status < 300) {
      yield put(ActionTypes.getAuthBanners.success(data));
    } else {
      yield put(ActionTypes.getAuthBanners.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAuthBanners.failure());
    throw e;
  }
}
export function* getAuthBannersWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_AUTH_BANNERS[REQUEST]);
    yield call(getAuthBannersSaga);
  }
}

export function* createAuthBannerSaga({ body }) {
  try {
    const { status, data } = yield call(api.createAuthBanner, body);
    if (status < 300) {
      yield put(ActionTypes.createAuthBanner.success(data));
      yield call(getAuthBannersSaga);
    } else if (status === 413) {
      yield put(ActionTypes.createAuthBanner.failure(new SubmissionError({ _error: 'Request Entity Too Large' })));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.createAuthBanner.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createAuthBanner.failure());
  }
}

export function* createAuthBannerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createAuthBanner.REQUEST);
    yield call(createAuthBannerSaga, payload);
  }
}

export function* deleteAuthBannerSaga({ bannerId }) {
  try {
    const { status } = yield call(api.deleteAuthBanner, bannerId);
    if (status < 300) {
      yield put(ActionTypes.deleteAuthBanner.success());
      yield call(getAuthBannersSaga);
    } else {
      yield put(ActionTypes.deleteAuthBanner.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteAuthBanner.failure(e));
  }
}
export function* deleteAuthBannerWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.DELETE_AUTH_BANNER[REQUEST]);
    yield call(deleteAuthBannerSaga, payload);
  }
}

export function* editAuthBannerSaga({ bannerId, formData }) {
  try {
    const { status, data } = yield call(api.editAuthBanner, bannerId, formData);
    if (status < 300) {
      yield put(ActionTypes.editAuthBanner.success(data));
      yield call(getAuthBannersSaga);
    } else if (status === 413) {
      yield put(ActionTypes.editAuthBanner.failure(new SubmissionError({ _error: 'Request Entity Too Large' })));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editAuthBanner.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editAuthBanner.failure());
  }
}

export function* editAuthBannerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editAuthBanner.REQUEST);
    yield call(editAuthBannerSaga, payload);
  }
}

export function* updateBannerPositionsSaga({ banners }) {
  try {
    const { status, data } = yield call(api.updateBannersPositions, banners);
    if (status < 300) {
      yield put(ActionTypes.updateBannersPositions.success(data));
      yield call(getAuthBannersSaga);
    } else {
      yield put(ActionTypes.updateBannersPositions.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.updateBannersPositions.failure());
    throw e;
  }
}
export function* updateBannersPositionsWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.UPDATE_BANNERS_POSITIONS[REQUEST]);
    yield call(updateBannerPositionsSaga, payload);
  }
}

export default [
  fork(getAuthBannersWatcherSaga),
  fork(createAuthBannerWatcherSaga),
  fork(deleteAuthBannerWatcherSaga),
  fork(editAuthBannerWatcherSaga),
  fork(updateBannersPositionsWatcherSaga),
];
