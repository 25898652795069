import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { closeModal, openModal } from 'interface/user/_redux/actions';

import { BasicModal, DefaultButton, PageContentPreloader, Tooltip } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';

import classNames from 'classnames';
import { UserNoInfoPanel } from '../../../_components';

import { getAllTags, getUserTags, deleteUserTag } from '../../_redux';
import UserAddTagForm from '../UserAddTagForm';

import { staticStyles, getDynamicStyles } from './style';
import arrowTopKYC from './arrowTopKYC.svg';

class UserTagsPanel extends React.Component {
  constructor(props) {
    super(props);
    props.getAllTags();
    props.getUserTags(props.userId);
    this.state = { openBlock: false };
  }

  render() {
    const {
      profile,
      userId,
      allTags,
      getUserTags,
      userTags,
      modals,
      openModal,
      closeModal,
      deleteUserTag,
      userTagsIsLoaded,
      theme,
    } = this.props;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
    const unselectedTags = allTags.filter(tag => userTags.every(userTag => userTag.id !== tag.id));

    return (
      <div className="UserTagsPanel">
        {/* eslint-disable */}
        <div
          className={classNames('UserTagsPanel__open-close', {
            'UserTagsPanel__open-close--close': !this.state.openBlock,
          })}
          onClick={() => this.setState({ openBlock: !this.state.openBlock })}
        />
        {/* eslint-enable */}
        <div
          className={classNames('UserTagsPanel__head', {
            'UserTagsPanel__head--hide': !this.state.openBlock,
          })}>
          <FormattedMessage id="boTags">{txt => <h2>{txt}</h2>}</FormattedMessage>
          {(profile.perms.tagsUser || profile.perms.managerTagsUser) && (
            <div
              className={classNames('UserTagsPanel__add-tag-button', {
                'UserTagsPanel__add-tag-button--hide': !this.state.openBlock,
              })}>
              <DefaultButton onClick={() => openModal('addTags')} textId="addTags" plus />
            </div>
          )}
        </div>
        {/* eslint-disable */}
        <img
          className={classNames('UserTagsPanel__arrowTopKYC', {
            'UserTagsPanel__arrowTopKYC--hide': !this.state.openBlock,
          })}
          src={arrowTopKYC}
          alt="arrowTopKYC"
          onClick={() => {
            this.setState({ openBlock: !this.state.openBlock });
          }}
        />
        {/* eslint-enable */}
        <div
          className={classNames('UserTagsPanel__content', {
            'UserTagsPanel__content--hide': !this.state.openBlock,
          })}>
          <PageContentPreloader ready={userTagsIsLoaded} firstLaunchOnly type="boUserTags">
            {userTags.length ? (
              <>
                {userTags.map((tag, index) => (
                  <Tooltip id={`UserTag__${index}`} textId={tag.description} key={index} noTranslate place="bottom">
                    <div className="UserTagsPanel__tag">
                      <p>{tag.name}</p>
                      {(profile.perms.tagsUser || profile.perms.managerTagsUser) && (
                        <button type="button" onClick={() => deleteUserTag(userId, tag.id)} />
                      )}
                    </div>
                  </Tooltip>
                ))}
              </>
            ) : (
              <UserNoInfoPanel image={noInfoImage} textId="boTagsNoInform" />
            )}
          </PageContentPreloader>
        </div>

        <BasicModal isOpen={modals.addTags} captionId="addTags" onRequestClose={() => closeModal('addTags')}>
          <UserAddTagForm
            id={userId}
            tags={unselectedTags}
            onSubmitSuccess={() => {
              closeModal('addTags');
              getUserTags(userId);
            }}
          />
        </BasicModal>

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    );
  }
}

UserTagsPanel.propTypes = {
  profile: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  getAllTags: PropTypes.func.isRequired,
  allTags: PropTypes.array.isRequired,
  getUserTags: PropTypes.func.isRequired,
  userTags: PropTypes.array.isRequired,
  modals: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteUserTag: PropTypes.func.isRequired,
  userTagsIsLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

UserTagsPanel.defaultProps = {
  theme: {},
};

const ConnectedUserTagsPanel = connect(
  state => ({
    profile: state.user.profile,
    allTags: state.backoffice.users.allTags,
    userTags: state.backoffice.users.userTags,
    userTagsIsLoaded: state.backoffice.users.userTagsIsLoaded,
    modals: state.user.modals,
  }),
  {
    getAllTags: () => getAllTags.request(),
    getUserTags: id => getUserTags.request(id),
    openModal: name => openModal(name),
    closeModal: name => closeModal(name),
    deleteUserTag: (userId, tagId) => deleteUserTag.request(userId, tagId),
  }
)(UserTagsPanel);

export default withTheme()(ConnectedUserTagsPanel);
export { UserTagsPanel };
