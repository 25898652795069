import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SmallAreaCharts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: rem(30px);
    width: 100%;
    :global(.SmallAreaChart) {
      width: 49%;
      @include respond-to(lg) {
        width: 100%;
      }
    }
  }
`

export { staticStyles }
