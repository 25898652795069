import React from 'react';
import PropTypes from 'prop-types';

const ValueLoader = WrappedComponent => {
  const wrapper = ({ loading, loadingFailed, ...props }) => {
    if (loading) {
      return <span>...</span>;
    }
    if (loadingFailed) {
      return <span>–</span>;
    }
    return <WrappedComponent {...props} />;
  };

  wrapper.propTypes = {
    loading: PropTypes.bool,
    loadingFailed: PropTypes.bool,
  };
  wrapper.defaultProps = {
    loading: false,
    loadingFailed: false,
  };

  return wrapper;
};

export default ValueLoader;
