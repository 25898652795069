import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import { crmSetUserName } from 'crm/users/_redux';
import TextFormField from '../../fields/TextFormField';
import { staticStyles } from './style';

const EditContactName = ({ handleSubmit, pristine, submitting, invalid, dispatch, error, match }) => {
  const submit = handleSubmit(values => crmSetUserName({ id: match.params.id, ...values }, dispatch));
  return (
    <form className="EditContactName" onSubmit={submit}>
      <Field name="firstName" component={TextFormField} labelTextId="justFirstName" />
      <Field name="lastName" component={TextFormField} labelTextId="justLastName" />
      <BaseButton textId="justSave" maxContent type="submit" disabled={pristine || invalid || submitting} />
      {error && <span>{error}</span>}
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

EditContactName.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditContactName.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  FormMessageHOC,
  reduxForm({
    form: 'crm-edit-user-name',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage(props.successMessageId);
      props.onRequestClose();
    },
  })
)(EditContactName);
