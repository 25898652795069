import React from 'react';
import classNames from 'classnames';
import { BaseTable, Pagination } from 'crm/_components';

import { useDispatch, useSelector } from 'react-redux';
import { getCrmUserInternalTransfers } from 'crm/users/_redux';

import { DateCell, MoneyCell } from 'common/components';

import paymentIcon from './icons/payment.svg';

import { staticStyles } from './style';

const TabInOutTableIT = () => {
  const { internalTransfers, internalTransfersAreLoaded, userData } = useSelector(state => state.crm.users);
  const hasInternalTransfers = !!Object.keys(internalTransfers).length;

  const dispatch = useDispatch();

  const handleChangePage = (page, size) => {
    dispatch(getCrmUserInternalTransfers.request({ id: userData.userId, search: { page, page_size: size } }));
  };

  /* eslint-disable */
  const columns = [
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { sendAmount, operation },
        },
      }) => (
        <div className="amount">
          <div className="amount--icon">
            <img src={paymentIcon} alt={operation} />
          </div>
          <MoneyCell value={sendAmount} />
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <div className="id">{id}</div>,
    },
    {
      accessor: 'from',
      Header: 'justFrom',
      Cell: ({
        row: {
          original: { senderAccount, transferType },
        },
      }) => (
        <div className="from">
          {senderAccount ? senderAccount : transferType === 'FROM_WALLET' ? 'Wallet' : transferType}
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'to',
      Header: 'justTo',
      Cell: ({
        row: {
          original: { recipientAccount },
        },
      }) => (
        <div className="to">
          <div className="to__number">{recipientAccount}</div>
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'date',
      Header: 'crmTransactionDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      Cell: ({
        row: {
          original: { status },
        },
      }) => (
        <div className="status">
          <div className={classNames('status__icon', `status__icon--${status.toLowerCase()}`)}></div>
          <div className="status__text">
            {status.toLowerCase() === 'completed' && 'Completed'}
            {status.toLowerCase() === 'waiting_for_approval' && 'Waiting for approval'}
            {status.toLowerCase() === 'canceled' && 'Canceled'}
          </div>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="TabInOutTableIT">
      {hasInternalTransfers && <BaseTable columns={columns} data={internalTransfers.results} onRowClick={() => {}} />}
      <Pagination
        externalClass="TabInOutTableIT__pagination"
        dataCount={hasInternalTransfers ? internalTransfers.count : 1}
        handleChangePage={handleChangePage}
        sizes={[10, 50, 'all']}
        searchId="tabInOutIT"
        pending={!internalTransfersAreLoaded}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default TabInOutTableIT;
