import React, { useEffect } from 'react';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PageContentPreloader, NoItemsPanel } from 'common/components';
import noReportImage from 'common/images/noFilesDownload.svg';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getSavedReports, deleteReport } from 'common/_redux';
import { SavedReportsTable } from '../../_components';
import { staticStyles } from './style';

interface MatchParams {
  name: string;
}

interface ISavedReportsPageProps extends RouteComponentProps<MatchParams> {
  savedReports: any;
  savedReportsAreLoaded: boolean;
  getSavedReports: any;
  deleteReport: any;
}

const SavedReportsPage: React.FC<ISavedReportsPageProps> = ({
  match,
  history,
  location,
  savedReports,
  savedReportsAreLoaded,
  getSavedReports,
  deleteReport,
}: ISavedReportsPageProps) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };
  const isBackOfficePage = match.url.includes('backoffice');

  /* eslint-disable */
  useEffect(() => {
    getSavedReports({ search: query, isBackOfficePage });
  }, [getSavedReports]);
  /* eslint-enable */

  return (
    <PageContentPreloader ready={savedReportsAreLoaded} firstLaunchOnly type="bigLine">
      <FormattedMessage id="savedReportsDescription">
        {txt => <div className="SavedReportsPage__description">{txt}</div>}
      </FormattedMessage>
      {savedReports.count ? (
        <SavedReportsTable
          data={savedReports}
          loading={!savedReportsAreLoaded}
          location={location}
          history={history}
          getList={getSavedReports}
          deleteReport={deleteReport}
          params={{ search: query, isBackOfficePage }}
        />
      ) : (
        <NoItemsPanel icon={noReportImage} captionSlug="noSavedReportsYet" />
      )}
      <style jsx>{staticStyles}</style>
    </PageContentPreloader>
  );
};

export default compose<React.FC<ISavedReportsPageProps>>(
  withRouter,
  connect(
    (state: any) => ({
      savedReports: state.common.savedReports,
      savedReportsAreLoaded: state.common.savedReportsAreLoaded,
    }),
    {
      getSavedReports: (data: any) => getSavedReports.request(data),
      deleteReport: (data: any) => deleteReport.request(data),
    }
  )
)(SavedReportsPage);
export { SavedReportsPage };
