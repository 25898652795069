import React from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';

const YM_ID = localStorage.getItem('ym');

export const YandexMetrikaInitialize = () => (
  <>
    {YM_ID && (
      <YMInitializer
        accounts={[+YM_ID]}
        options={{ webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true, trackHash: true }}
        version="2"
      />
    )}
  </>
);

export const yandexTrackPage = page => {
  if (YM_ID) {
    ym('hit', page);
  }
};

export const sendYandexMetrikaEvent = action => {
  if (YM_ID) {
    ym('reachGoal', action);
  }
};
