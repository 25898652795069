import css from 'styled-jsx/css';
import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Issue {
    position: relative;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    transition: all 0.4s ease;
    margin: 0 auto 2px;
    display: flex;
    flex: auto 1;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
    &:hover {
      z-index: 2;
    }
  }

  .Issue__caption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: rem(20px 25px 19px);
    cursor: pointer;
    transition: all 0.4s ease;
    border-bottom: 1px solid transparent;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include respond-to(sm) {
      flex-wrap: wrap;
      padding: rem(20px 15px 19px);
    }
  }

  .Issue__sender {
    width: 25%;
    span {
      font-weight: 600;
    }
    @media only screen and (max-width: 620px) {
      width: calc(100% - 120px);
    }
  }

  .Issue__title {
    width: 40%;

    @include respond-to(sm) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .Issue__date {
    width: 20%;

    span {
      font-size: 12px;
    }

    @media only screen and (max-width: 1300px) {
      width: 155px;
    }

    @include respond-to(sm) {
      width: 50%;
    }
  }

  .Issue__status {
    width: 15%;
    @media only screen and (max-width: 1300px) {
      width: 135px;
    }

    @include respond-to(sm) {
      width: 50%;
    }
  }

  .Issue__open {
    z-index: 2;
    margin: 10px auto 25px;
    width: 100%;
    .IssueClose__button-inner {
      text-align: right;
      left: ${isRTL ? '0' : 'auto'};
      @include respond-to(sm) {
        padding: 15px 0;
        width: 100%;

        button {
          display: block;
          width: 100%;
        }
      }
    }

    .IssueMessages__inner {
      padding: 20px 40px;
    }
  }

  .Issue__new_messages {
    .Issue__title {
      font-weight: 500;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .Issue {
      background-color: ${colors.white.hex};
      &:hover {
        box-shadow: 0 3px 8px 4px ${rgba([colors.primary.rgb, 0.25])};
      }
    }

    .Issue__date {
      span {
        font-weight: 900;
        color: ${colors.black.hex};
      }
    }

    .Issue__open {
      box-shadow: 0 3px 8px 4px ${rgba([colors.primary.rgb, 0.25])};
    }

    .Issue__new_messages {
      .Issue__caption {
        background-color: ${colors.newMessage.hex};
      }
    }

    .Issue__key {
      font-size: 12px;
      margin-right: ${isRTL ? '0' : '15px'};
      margin-left: ${isRTL ? '15px' : '0'};
      font-weight: normal !important;
      color: ${rgba([colors.gray.rgb, 0.6])} !important;

      @include respond-to(sm) {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 5px;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
