import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const gradient = (angle, from, percentFrom, to, percentTo) =>
  `linear-gradient(${angle}deg, rgba(${from}) ${percentFrom}%, rgba(${to}) ${percentTo}%)`;

const rgba = color => `rgba(${color})`;

const isRTL = localStorage.getItem('locale') === 'ar';

export const defaultTheme = {
  colors: {
    gradientStart: { hex: '#43B6E8', rgb: [67, 182, 232] },
    gradientEnd: { hex: '#33EEC7', rgb: [51, 238, 199] },
    primary: { hex: '#0AA1E2', rgb: [10, 161, 226] },
    secondary: { hex: '#005479', rgb: [0, 84, 121] },
    white: { hex: '#FFFFFF', rgb: [255, 255, 255] },
    black: { hex: '#1D1D1D', rgb: [29, 29, 29] },
    pale: { hex: '#5D647D', rgb: [93, 100, 125] },
    gray: { hex: '#777777', rgb: [119, 119, 119] },
    lightGray: { hex: '#C5C5C5', rgb: [197, 197, 197] },
    disabled: { hex: '#ECECEC', rgb: [255, 255, 255] },
    error: { hex: '#ff4058', rgb: [255, 64, 88] },
    success: { hex: '#389700', rgb: [56, 151, 0] },
    warning: { hex: '#F5A623', rgb: [245, 166, 35] },
    bodyBackground: { hex: '#F0F0F0', rgb: [240, 240, 240] },
    mobileMenu: { hex: '#212121', rgb: [33, 33, 33] },
    newMessage: { hex: '#ffe6bd', rgb: [255, 230, 189] },
    topMenu: { hex: '#FFFFFF', rgb: [255, 255, 255] },
    columHead: { hex: '#F8FAFF' },
  },
  border: {
    radius: '3px',
  },
  images: { logo: null, authImage: null },
};

// ThemeProvider
const { Provider, Consumer } = createContext();

const ThemeProvider = ({ theme, children }) => <Provider value={theme}>{children}</Provider>;

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
};

ThemeProvider.defaultProps = {
  theme: {},
};

// ReduxThemeProvider
const ReduxThemeProvider = connect((state, ownProps) => ({
  theme: {
    ...defaultTheme,
    ...state.interfaceConfig.clientThemeConfig[ownProps.forInterface],
  },
}))(ThemeProvider);

ReduxThemeProvider.propTypes = {
  forInterface: PropTypes.string,
};

ReduxThemeProvider.defaultProps = {
  forInterface: 'client',
};

// withTheme
const defaultGetThemePart = theme => ({ theme });

const withTheme =
  (getThemeParts = defaultGetThemePart) =>
  WrappedComponent =>
  props =>
    <Consumer>{theme => <WrappedComponent {...{ ...props, ...getThemeParts(theme || defaultTheme) }} />}</Consumer>;

const colorType = PropTypes.shape({
  hex: PropTypes.string,
  rgb: PropTypes.arrayOf(PropTypes.number),
}).isRequired;

// TestThemeHOC for jest
const TestThemeHOC = Wrapped => props =>
  (
    <Provider value={defaultTheme}>
      <Wrapped {...props} />
    </Provider>
  );

/*
  Styled-jsx css tag expression returns
  object in the development mode
  and array in the production mode.
  That is why we need process them in different ways
  in the case of inserting css tagged template styles
  in style tag without jsx prop.
*/
const taggedStylesToString = styles => (styles.join ? styles.join('') : styles.toString());

export { ReduxThemeProvider, withTheme, gradient, rgba, isRTL, colorType, TestThemeHOC, taggedStylesToString };
