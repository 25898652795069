import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import TextFormField from 'crm/_components/forms/fields/TextFormField';
import { staticStyles } from './style';

const ThirdStep = ({
  editCrmCustomFilter,
  dataFilterValues,
  id,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const submit = handleSubmit(({ title }) =>
    editCrmCustomFilter({ editData: { ...dataFilterValues, title }, id }, dispatch)
  );
  return (
    <>
      <form className="ThirdStep" onSubmit={submit}>
        <Field
          name="title"
          component={TextFormField}
          labelTextId="crmFilterName"
          placeholderId="saveAs"
          required={required()}
        />

        <BaseButton textId="justChange" maxContent type="submit" disabled={invalid || submitting} />
        {error && <span>{error}</span>}
      </form>
      <style jsx>{staticStyles}</style>
    </>
  );
};

ThirdStep.propTypes = {
  editCrmCustomFilter: PropTypes.func.isRequired,
  dataFilterValues: PropTypes.object.isRequired,
  id: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ThirdStep.defaultProps = {
  error: '',
  id: null,
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-create-crm-filter',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmFilterWasChanged');
      props.onRequestClose();
    },
  })
)(ThirdStep);
