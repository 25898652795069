import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const PauseIcon = ({ grayColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
    <path
      d="M11 0C4.934 0 0 4.935 0 11s4.934 11 11 11 11-4.935 11-11S17.066 0 11 0zm0 21.083C5.44 21.083.917 16.56.917 11 .917 5.44 5.44.917 11 .917 16.56.917 21.083 5.44 21.083 11c0 5.56-4.523 10.083-10.083 10.083z"
      fill={grayColor.hex}
    />
    <rect x="6.375" y="6.375" width="3.25" height="9.25" rx=".625" stroke={grayColor.hex} />
    <rect x="12.375" y="6.375" width="3.25" height="9.25" rx=".625" stroke={grayColor.hex} />
  </svg>
);

PauseIcon.propTypes = {
  grayColor: colorType,
};

PauseIcon.defaultProps = {
  grayColor: {},
};

const ThemedPauseIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(PauseIcon);

export default ThemedPauseIcon;
