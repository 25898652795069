import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BasicModal, DefaultButton } from 'common/components';
import { setAuthorized } from 'auth/login/_redux';

import staticStyles from './style';
import { confirmEmail, toggleConfirmEmailModal } from '../../_redux/actions';

export const ConfirmEmail = ({
  confirmEmail,
  match,
  emailConfirmation,
  confirmEmailModalIsOpen,
  emailConfirmationError,
  toggleConfirmEmailModal,
  setAuthorized,
  history,
}) => {
  useEffect(() => {
    confirmEmail(match.params.code);
  }, [confirmEmail, match.params.code]);

  let isEmailConfirmation;
  if (emailConfirmation) {
    isEmailConfirmation = (
      <div className="ConfirmEmail">
        <FormattedMessage id="confirmEmailModalBody" />
        <DefaultButton
          textId="justOk"
          onClick={() => {
            toggleConfirmEmailModal();
            if (emailConfirmation) {
              setAuthorized();
            }
            history.push('/');
          }}
        />
        <style jsx>{staticStyles}</style>
      </div>
    );
  } else if (!emailConfirmation && emailConfirmationError) {
    isEmailConfirmation = <strong className="formError">{emailConfirmationError}</strong>;
  } else {
    isEmailConfirmation = <FormattedMessage id="emailConfirmationInProgress" />;
  }

  return (
    <BasicModal
      isOpen={confirmEmailModalIsOpen}
      captionId="confirmEmailModalHeader"
      onRequestClose={() => {
        toggleConfirmEmailModal();
        history.push('/');
      }}>
      {isEmailConfirmation}
    </BasicModal>
  );
};

ConfirmEmail.propTypes = {
  match: PropTypes.object.isRequired,
  emailConfirmationError: PropTypes.string,
  confirmEmail: PropTypes.func.isRequired,
  setAuthorized: PropTypes.func.isRequired,
  toggleConfirmEmailModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  emailConfirmation: PropTypes.number.isRequired,
  confirmEmailModalIsOpen: PropTypes.bool.isRequired,
};

ConfirmEmail.defaultProps = {
  emailConfirmationError: '',
};

export default withRouter(
  connect(
    state => ({
      emailConfirmation: state.auth.registration.emailConfirmation,
      emailConfirmationError: state.auth.registration.emailConfirmationError,
      confirmEmailModalIsOpen: state.auth.registration.confirmEmailModalIsOpen,
    }),
    {
      confirmEmail: code => confirmEmail.request(code),
      toggleConfirmEmailModal: () => toggleConfirmEmailModal(),
      setAuthorized: () => setAuthorized(),
    }
  )(ConfirmEmail)
);
