import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ClientPageHistory {
    width: 100%;
    padding: 25px 35px 25px 35px;

    @include respond-to(md) {
      padding: 15px 15px 15px 15px;
    }

    &__back {
      display: flex;
      cursor: pointer;
      align-items: center;
      margin: 0 0 20px 0;
    }

    &__back-icon {
      margin: 0 10px 0 0;
    }

    &__back-text {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #0aa1e2;
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 0 0 12px 0;
    }

    &__broker {
      display: flex;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      margin: 0 0 3px 0;
    }

    &__broker-title {
      margin: 0 5px 0 0;
    }

    &__broker-data {
      color: #37a1e2;
    }

    &__leverage {
      display: flex;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      margin: 0 0 3px 0;
    }

    &__leverage-title {
      margin: 0 5px 0 0;
    }

    &__leverage-data {
    }

    &__currency {
      display: flex;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      margin: 0 0 30px 0;
    }

    &__currency-title {
      margin: 0 5px 0 0;
    }

    &__currency-data {
    }

    &__bottom {
    }

    &__radio-list {
      margin: 50px 0 50px 0;
      display: flex;
      flex-wrap: wrap;
    }

    &__radio-open-orders {
      margin: 0 60px 0 0;
    }

    &__radio-closed-orders {
      margin: 0 60px 0 0;
    }

    &__radio-pending-orders {
      margin: 0 60px 0 0;
    }

    &__table-open-orders {
    }

    &__pagination-open-orders {
      margin: 15px 0 0 0;
    }

    &__table-closed-orders {
    }

    &__pagination-closed-orders {
      margin: 15px 0 0 0;
    }

    &__table-pending-orders {
    }

    &__pagination-pending-orders {
      margin: 15px 0 0 0;
    }
  }
`;

export { staticStyles };
