import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from 'common/styling/theme';
import { FormattedMessage } from 'react-intl';
import { staticStyles, getDynamicStyles } from './style';

const ChooseLpaButton = ({ onClick, active, disabled, screenSize, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [activeInfo, setActiveInfo] = useState(false);

  /* eslint-disable */
  return (
    <div
      className={classNames('ChooseLpaButton', {
        'ChooseLpaButton--active': active,
        'ChooseLpaButton--disabled': disabled,
      })}
      onClick={() => {
        if (disabled) return;
        if (screenSize <= 767) {
          onClick();
          setActiveInfo(!activeInfo);
        } else {
          onClick();
        }
      }}
      onKeyPress={disabled ? null : onClick}
      onMouseOver={() => (screenSize > 767 ? setActiveInfo(true) : null)}
      onMouseOut={() => (screenSize > 767 ? setActiveInfo(false) : null)}>
  {/* eslint-enable */}

      {/* payment-system-inner */}
      <div className="ChooseLpaButton__inner">
        <span className="ChooseLpaButton__title">
          <FormattedMessage id="justLocalPayment">{txt => txt}</FormattedMessage>
        </span>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ChooseLpaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  screenSize: PropTypes.number,
  theme: PropTypes.object,
};

ChooseLpaButton.defaultProps = {
  active: false,
  disabled: false,
  screenSize: 1920,
  theme: {},
};

export default withTheme()(ChooseLpaButton);
export { ChooseLpaButton };
