import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { FormMessageHOC } from 'common';
import { ReduxTextField, TinyMCEField, DefaultButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const NewsForm = ({ submitFunction, buttonTextId, handleSubmit, pristine, submitting, invalid, error, theme }) => {
  const submit = handleSubmit(submitFunction);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <form className="NewsForm" onSubmit={submit}>
      <div className="NewsForm__input-inner">
        <FormattedMessage id="justTitle">{txt => <p>{txt}</p>}</FormattedMessage>
        <Field
          component={ReduxTextField}
          type="text"
          name="title"
          textId="justTitle"
          disabled={submitting}
          validate={[required()]}
        />
      </div>

      <Field component={TinyMCEField} name="body" textId="justBody" disabled={submitting} validate={[required()]} />

      <DefaultButton
        textId={buttonTextId}
        type="submit"
        disabled={invalid || pristine || submitting}
        loading={submitting}
        filled
      />

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

NewsForm.propTypes = {
  submitFunction: PropTypes.func.isRequired,
  buttonTextId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

NewsForm.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  FormMessageHOC,
  reduxForm({
    form: 'NewsForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageId);
      props.history.push('/backoffice/news/');
    },
  })
)(NewsForm);
export { NewsForm };
