import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .TaskItem {
    position: relative;

    &__Title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: #333;

      @include respond-to(xs) {
        margin-left: 44px;
      }
    }

    &__ExpirationDate {
      display: flex;
      margin-top: 4px;
      margin-bottom: 12px;
      gap: 4px;

      color: #cecece;
      font-size: 12px;
      line-height: 1.4;

      .DateCell__time {
        font-weight: 400;
        color: #cecece;
      }
    }

    &__Description {
      color: #333;
      font-size: 14px;
      line-height: 1.4;
    }

    &__AssignedTo {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 8px 0 12px;

      color: #333;
      font-size: 14px;

      &--userIcon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #0aa1e2;
      }
    }

    &__CheckBtn {
      position: absolute !important;
      right: 0;
      bottom: 0;
    }

    &__Comment {
      max-width: 500px;
      margin: 8px 0;
      padding: 12px 16px;
      background-color: #fefbfb;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
`;

export { staticStyles };
