import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { NoItemsPanel, PageContentPreloader, SearchPanel } from 'common/components';

import BonusesTable from 'common/components/tables/BonusesTable';

import noPaymentsImage from 'common/images/noPayments.svg';
import { getPerBonuses, rejectPerBonus } from '../../_redux';

const PerBonusesPage = ({
  userPerBonuses,
  userPerBonusesAreLoaded,
  getPerBonuses,
  rejectPerBonus,
  cancelBonusModal,
  intl,
  history,
  location,
}) => {
  const query = { page: 1, page_size: 10, ...queryString.parse(location.search) };

  /* eslint-disable */
  useEffect(() => {
    getPerBonuses({ search: query });
  }, [getPerBonuses]);
  /* eslint-enabled */

  const filters = [
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'ACTIVE', label: intl.formatMessage({ id: 'status_active' }) },
        { value: 'EXPIRED', label: intl.formatMessage({ id: 'status_expired' }) },
        { value: 'CANCELED', label: intl.formatMessage({ id: 'status_canceled' }) },
      ],
    },
  ];

  return (
    <PageContentPreloader ready={userPerBonusesAreLoaded} type="boUserInfo" firstLaunchOnly>
      {!userPerBonuses.count && !Object.keys(query).length ? (
        ''
      ) : (
        <SearchPanel
          location={location}
          history={history}
          getList={getPerBonuses}
          filters={filters}
          params={{ search: query }}
          withoutSearch
        />
      )}
      {userPerBonuses.count ? (
        <BonusesTable
          data={userPerBonuses}
          loading={!userPerBonusesAreLoaded}
          location={location}
          history={history}
          getList={getPerBonuses}
          params={{ search: query }}
          query={query}
          onRowClick={() => {}}
          rejectBonus={rejectPerBonus}
          cancelBonusModal={cancelBonusModal}
          isPeriodical
        />
      ) : (
        <NoItemsPanel icon={noPaymentsImage} captionSlug="noBonuses" />
      )}
    </PageContentPreloader>
  );
};

PerBonusesPage.propTypes = {
  userPerBonuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  userPerBonusesAreLoaded: PropTypes.bool.isRequired,
  getPerBonuses: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  cancelBonusModal: PropTypes.bool.isRequired,
  rejectPerBonus: PropTypes.func.isRequired,
};

PerBonusesPage.defaultProps = {
  userPerBonuses: [],
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    state => ({
      userPerBonuses: state.client.bonuses.userPerBonuses,
      userPerBonusesAreLoaded: state.client.bonuses.userPerBonusesAreLoaded,
      cancelBonusModal: state.client.bonuses.cancelBonusModal,
    }),
    {
      getPerBonuses: search => getPerBonuses.request(search),
      rejectPerBonus: id => rejectPerBonus.request(id),
    }
  )
)(PerBonusesPage);
