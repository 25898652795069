import React from 'react';
import { PageLayout } from 'common/components';

export default customPagesRaw => {
  const basicAdditionalMenuPart = {
    title: 'menuAdditional',
    caption: 'menuAdditional',
    url: '/',
    isAvailable: true,
    isBlock: true,
    routes: [],
  };
  const customPages = customPagesRaw.map(({ html, title, url, imageIcon }) => ({
    title,
    url: url[0] === '/' ? url : `/${url}`,
    isAvailable: true,
    isBlock: true,
    imageIcon,
    routes: [
      {
        path: '',
        component: () => (
          <PageLayout caption={title}>
            <div style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: html }} />
          </PageLayout>
        ),
      },
    ],
  }));
  if (customPages.length) {
    return [basicAdditionalMenuPart, ...customPages];
  }
  return [];
};
