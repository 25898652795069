import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BonusesTable {
    .rt-thead {
      min-width: 500px !important;
      .rt-tr {
        .rt-th {
          text-align: center;
          flex: auto !important;
          width: 100px !important;

          @include respond-to(sm) {
            text-align: left;
          }

          span {
            white-space: pre-wrap;
          }

          &:first-child {
            flex: 40px !important;
            width: 40px !important;
          }

          &:nth-child(2) {
            flex: 150px !important;
            width: 150px !important;
          }

          &:nth-child(4) {
            width: 170px !important;
          }

          &:nth-child(5) {
            width: 150px !important;
          }

          &:nth-child(7) {
            flex: auto !important;
            width: 100px !important;
          }
          &:nth-child(8) {
            flex: auto !important;
            width: 100px !important;
          }
          &:nth-child(9) {
            flex: auto !important;
            width: 140px !important;
          }
          &:last-child {
            flex: auto !important;
            width: 180px !important;

            @include respond-to(lg) {
              width: 200px !important;
            }
          }

          span {
            font-size: 13px !important;

            @include respond-to(lg) {
              font-size: 11px !important;
            }
          }
        }
      }
    }
    .rt-tbody {
      min-width: 500px !important;
      .rt-tr-group {
        cursor: default;
        box-shadow: none;

        .rt-tr {
          .rt-td {
            text-align: center;
            flex: auto !important;
            width: 100px !important;

            @include respond-to(sm) {
              text-align: left;
            }

            &:first-child {
              flex: 40px !important;
              width: 40px !important;
            }
            &:nth-child(2) {
              flex: 150px !important;
              width: 150px !important;

              .BonusesTable__types {
                display: flex;
                flex-direction: column;
              }
            }
            
            &:nth-child(4) {
              font-weight: 300;
              width: 170px !important;
            }

            &:nth-child(5) {
              width: 150px !important;
            }

            &:nth-child(6) {
              .MoneyCell__inner {
                justify-content: center;

                span {
                  font-size: 13px !important;
                }
              }
            }
            &:nth-child(7) {
              flex: auto !important;
              width: 100px !important;
            }
            &:nth-child(8) {
              font-weight: 600;
              flex: auto !important;
              width: 100px !important;
            }
            &:nth-child(9) {
              flex: auto !important;
              width: 140px !important;

              .StatusCell {
                font-size: 13px !important;
                @include respond-to(lg) {
                  font-size: 10px !important;
                }
              }
            }

            &:last-child {
              flex: auto !important;
              width: 180px !important;
            }

            span {
              font-size: 13px;

              @include respond-to(lg) {
                font-size: 11px !important;
              }
            }
            p {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
  .BonusModal {
    :global(&__btns) {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
    }
  }

  .BonusesTable__cancel-error {
    max-width: 360px;
    margin-top: rem(10px);
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .BonusesTable {
      &__until {
        &.active,
        &.completed {
          color: ${colors.success.hex};
        }

        &.expired {
          color: ${colors.error.hex};
        }

        &.rejected {
          color: ${colors.gray.hex};
        }
      }

      .defaultType {
        color: ${colors.gray.hex};
      }
    }

    .BonusesTable__cancel-error {
      color: ${colors.error.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
