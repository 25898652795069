import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .ExternalTransfer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: rem(25px);

    .ExternalTransfer__line {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      :global(.ReduxSelectField, .ReduxTextField) {
        width: 400px;
        margin-right: rem(30px);
      }
    }

    .ExternalTransfer__description {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
    }
    
    :global(.DefaultButton) {
      width: 120px;
      margin-top: rem(30px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ExternalTransfer {
      .ExternalTransfer__description {
        color: ${colors.gray.hex};
      }
      
      .ExternalTransfer__error {
        color: ${colors.error.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
