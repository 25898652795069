import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatusesPage {
    &__status {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      :global(.StatusPlate) {
        margin-left: ${isRTL ? '0' : '10px'};
        margin-right: ${isRTL ? '10px' : '0'};
      }
    }

    &__description {
      &-title {
        margin-bottom: 25px;
      }
      &-text {
        margin-bottom: 25px;

        p {
          margin-bottom: 25px;
        }
      }
    }
  }
`;

export default staticStyles;
