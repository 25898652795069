import React from 'react';
import PropTypes from 'prop-types';

import BaseSelectField from '../BaseSelectField';

const ReduxSelectField = ({ input, options, multi, meta: { touched, error }, disabled, noErrorMessage, ...props }) => {
  const handleChange = option => {
    let value;
    if (Array.isArray(option)) {
      value = option.length ? option.map(o => o.value) : null;
    } else {
      value = option ? option.value : null;
    }
    return input.onChange(value);
  };

  const mobileChange = e => {
    if (e && e.target && e.target.value) {
      let value = e.target.value;
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      }
      input.onChange(value);
    }
  };

  let value;
  if (Array.isArray(input.value)) {
    value = [];
    input.value.forEach(inputOption => {
      value.push(options.find(option => option.value === inputOption)); // eslint-disable-line
    });
  } else {
    value = options.find(option => option.value === input.value); // eslint-disable-line
  }

  return (
    <BaseSelectField
      {...props}
      {...input}
      additionalClass
      name={input.name}
      value={value}
      error={touched && error}
      onChange={handleChange}
      onMobileChange={mobileChange}
      onBlur={() => input.onBlur(input.value)}
      blurInputOnSelect={false}
      options={options}
      isMulti={multi}
      disabled={disabled || !options.length}>
      <span className="ReduxSelectField__error-text">{!options.length && noErrorMessage}</span>
      <span className="ReduxSelectField__error-text">{touched && error}</span>
    </BaseSelectField>
  );
};

ReduxSelectField.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  }).isRequired,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  noErrorMessage: PropTypes.string,
};

ReduxSelectField.defaultProps = {
  disabled: false,
  multi: false,
  noErrorMessage: '',
};

export default ReduxSelectField;
