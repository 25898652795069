import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const UsersIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 17 18" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="15.561%" y1="126.812%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="-UI" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-491 -744)" fill="url(#linearGradient-4)">
          <g id="users1" transform="translate(490 743)">
            <path
              d="M6.49373922,2.73293854 C7.08319884,1.58291347 8.26211809,1 10.0015582,1 C11.7409984,1 12.9194724,1.58335241 13.5102677,2.7324996 C14,3.69465797 14,4.85653444 14,5.8819003 C14,7.14429423 13.3642384,8.61737978 12.3081975,10.040865 C10.9280427,11.3651496 9.18771217,11.317744 7.60276031,9.90084287 C6.57877456,8.55548912 6,7.10917896 6,5.8819003 C6.00133563,4.85653444 6.00133563,3.69465797 6.49373922,2.73293854 Z"
              id="Path"
            />
            <path
              d="M17.9861472,18.5615734 C17.9881478,18.6772997 17.944617,18.7889827 17.8653636,18.871457 C17.7861102,18.9539313 17.6778019,19.0002582 17.5648437,18.9999989 L1.44845324,18.9999989 C1.33575077,18.9997084 1.2278605,18.9531606 1.14889859,18.8707599 C1.06927992,18.7885662 1.02471093,18.6773811 1.02496005,18.5615734 C1.02846361,18.3443801 0.78014857,16.7217122 1.77822612,15.6976724 C2.23894468,15.2255909 3.20373838,14.7140197 4.62180556,13.9866014 C6.13534484,13.2116159 6.97488616,12.7498556 7.36159194,12.3751522 C7.45475075,12.2558577 7.54032666,12.1305484 7.61779,12 C8.27470809,12.3657285 8.95264755,12.5604846 9.6323388,12.5717032 L9.69627883,12.5717032 C10.3653652,12.5696237 11.0226029,12.3906354 11.6044069,12.0520546 C11.7086379,12.2212321 11.8076136,12.3522661 11.8076136,12.3522661 C12.1938814,12.7260721 13.0080219,13.2107184 14.522875,13.9866014 C15.9409422,14.7126735 16.905298,15.2246934 17.3664544,15.6976724 C18.1766534,16.5300974 17.9734468,17.7731256 17.9861472,18.5620222 L17.9861472,18.5615734 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

UsersIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

UsersIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedUsersIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(UsersIconActive);

export default ThemedUsersIconActive;
