import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { getContactNoteDocument, removeFeedNoteDocument } from 'crm/users/_redux/actions';

import { BaseIconButton } from 'crm/_components';
import ContactNoteDocuments from 'crm/users/_components/TabNotes/_components/ContactNoteDocuments';

import user from '../../newImages/user.svg';
import { staticStyles } from './style';

const NoteItem = ({ createdBy, title, text, handleEditNote, openDeleteModal, id, userId, documents }) => {
  const dispatch = useDispatch();

  const handleDownloadNote = documentId => {
    dispatch(getContactNoteDocument.request({ userId, documentId }));
  };

  const handleRemoveNoteDocument = documentId => {
    dispatch(removeFeedNoteDocument.request({ userId, documentId }));
  };

  return (
    <div className="NoteItem">
      <div className="TabFeedItem__Title">
        <FM id="crmNote" />
      </div>
      <div className="NoteItem__Title">
        <span>{title}</span>
        <div className="NoteItem__Author">
          <img src={user} alt="user" />
          <span>{createdBy}</span>
        </div>
      </div>
      <div className="NoteItem__Description">{text !== null ? text : ''}</div>
      <div className="NoteItem__Documents">
        {documents.length ? (
          <>
            <FM id="crmAttachedDocuments">{txt => <span className="NoteItem__FilesText">{txt}:</span>}</FM>
            <ContactNoteDocuments
              documents={documents}
              onDownload={handleDownloadNote}
              onRemove={handleRemoveNoteDocument}
            />
          </>
        ) : null}
      </div>

      <div className="TabFeedItem__Buttons">
        <BaseIconButton onClick={() => handleEditNote(id)} iconType="edit" />
        <BaseIconButton onClick={() => openDeleteModal()} iconType="remove" color="red" />
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

NoteItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleEditNote: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  createdBy: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

NoteItem.defaultProps = {
  createdBy: '',
  title: '',
  text: '',
};

export default NoteItem;
