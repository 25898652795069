import css from 'styled-jsx/css'

export const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .BonusValueLevels {
    margin: 0 0 25px 0;

    &__top {
      background: #fff;
      border-radius: 3px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
      padding: 28px 36px 3px 36px;

      @include respond-to(sm) {
        padding: 22px 20px 3px 20px;
      }

      &--no-levels {
        padding: 28px 36px 28px 36px;

        @include respond-to(sm) {
          padding: 22px 20px 22px 20px;
        }
      }
    }

    &__title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 20px 0;

      @include respond-to(sm) {
        display: block;
      }

      &--no-levels {
        margin: 0 0 0 0;
      }
    }

    &__title {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 20px;
        margin: 0 0 22px 0;
      };
    }

    &__title-button {}

    &__titles-list {
      display: flex;

      @include respond-to(sm) {
        display: none;
      }
    }

    &__titles-item {
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: #777777;
      width: calc(100% / 3 - (74px / 3));
      margin: 0 18px 0 0;
    }

    &__list {
      margin: 2px 0 0 0;
    }

    &__item {
      background: #fff;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
      padding: 17px 36px 0 36px;
      border-radius: 3px;
      display: flex;
      align-items: flex-start;
      margin: 0 0 2px 0;

      @include respond-to(sm) {
        display: block;
        padding: 17px 20px 0 20px;
      }
    }

    :global(.ReduxTextField) {
      width: calc(100% / 3 - (74px / 3));
      margin: 0 18px 0 0;

      @include respond-to(sm) {
        width: 100%;
      }
    }

    &__item-cross {
      cursor: pointer;
      position: relative;
      width: 20px;
      height: 20px;
      transform: translateY(17px);

      @include respond-to(sm) {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 21px;
        height: 1px;
        background: #777777;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 21px;
        height: 1px;
        background: #777777;
      }
    }

    &__item-level-wrap {
      display: none;

      @include respond-to(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 22px 0;
      }
    }

    &__item-level-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }

    &__item-level-cross {
      cursor: pointer;
      position: relative;
      width: 20px;
      height: 20px;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 21px;
        height: 1px;
        background: #777777;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 21px;
        height: 1px;
        background: #777777;
      }
    }

    &__item-field-title {
      display: none;

      @include respond-to(sm) {
        display: block;
        font-size: 15px;
        line-height: 20px;
        margin: 0 0 10px 0;
      }
    }
  }
`
