import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .AccountsBlock {
    margin-bottom: 45px;
    &:last-child {
      margin: 45px 0 0 0;
    }
  }

  .AccountsBlock__head {
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    h3 {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      padding-right: 25px;
      @include respond-to(xs) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
`

export { staticStyles }
