import { put, call, fork, take } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import { getCurrentStep } from '../_helpers';

import * as api from '../_api';
import * as ActionTypes from './actions';

export function* wsRegistrationSaga(payload) {
  try {
    const { status, data } = yield call(api.wsRegister, payload);

    if (status < 300) {
      yield put(ActionTypes.wsSetEmail(payload.email));
      yield put(ActionTypes.wsRegistration.success());
    } else {
      const errorDetails = data.details || data.detail;
      const formError = new SubmissionError(errorDetails ? { _error: errorDetails } : data);
      yield put(ActionTypes.wsRegistration.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsRegistration.failure());
  }
}

export function* wsRegistrationWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsRegistration.REQUEST);
    yield call(wsRegistrationSaga, payload);
  }
}

export function* wsGetRegistrationStatusSaga() {
  try {
    const { status, data } = yield call(api.wsGetRegistrationStatus);

    if (status === 401) {
      yield put(ActionTypes.wsGetRegistrationStatus.failure());
      localStorage.removeItem('wsJwtToken');
      window.location.reload();
    }

    if (status < 300) {
      const currentStep = getCurrentStep(data);

      yield put(ActionTypes.wsGetRegistrationStatus.success({ ...data, wsStep: currentStep }));
    } else {
      yield put(ActionTypes.wsGetRegistrationStatus.failure());
    }
  } catch (e) {
    yield put(ActionTypes.wsGetRegistrationStatus.failure());
    throw e;
  }
}

export function* wsGetRegistrationStatusWatcherSaga() {
  while (true) {
    yield take(ActionTypes.WS_GET_REGISTRATION_STATUS[REQUEST]);
    yield call(wsGetRegistrationStatusSaga);
  }
}

export function* wsGetOtpTokenSaga(payload) {
  try {
    const { status, data } = yield call(api.wsGetOtpToken, payload);

    if (status < 300) {
      localStorage.setItem('wsOtpToken', data.otpToken);
      yield put(ActionTypes.wsGetOtpToken.success(data.nextAction));
      return { nextAction: data.nextAction };
    }

    if (status === 403) {
      yield put(
        ActionTypes.wsShowSendNotification({
          showButtonToResendEmailConfirmation: true,
          emailThatNeedsConfirmation: data.email,
        })
      );
    }
    const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
    yield put(ActionTypes.wsGetOtpToken.failure(formError));
    return { formError };
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGetOtpToken.failure());
    throw e;
  }
}

export function* wsGetOtpTokenWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsGetOtpToken.REQUEST);
    yield call(wsGetOtpTokenSaga, payload);
  }
}

export function* wsGetJwtTokenSaga(payload) {
  try {
    const { status, data } = yield call(api.wsGetJwtToken, payload);

    if (status < 300) {
      localStorage.setItem('wsJwtToken', data.token);
      localStorage.removeItem('wsOtpToken');
      yield put(ActionTypes.wsGetJwtToken.success());
      yield put(ActionTypes.wsSetAuthorized());
      yield call(wsGetRegistrationStatusSaga);
      return 1;
    }
    if (status === 401) {
      yield put(ActionTypes.wsGetJwtToken.failure());
      yield put(ActionTypes.wsResetAuth(data.detail));
      return 0;
    }
    const formError = new SubmissionError(status === 429 ? { _error: data } : data);
    yield put(ActionTypes.wsGetJwtToken.failure(formError));
    return 0;
  } catch (e) {
    localStorage.removeItem('wsOtpToken');
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGetJwtToken.failure());
    throw e;
  }
}

export function* wsGetJwtTokenWatcherSaga() {
  while (true) {
    const {
      payload: { code },
    } = yield take(ActionTypes.wsGetJwtToken.REQUEST);
    yield call(wsGetJwtTokenSaga, code);
  }
}

export function* wsLoginSaga(payload) {
  try {
    const { nextAction, formError, status } = yield call(wsGetOtpTokenSaga, payload);

    if (nextAction) {
      if (nextAction === 'login') {
        yield call(wsGetJwtTokenSaga, payload);
      }
      yield put(ActionTypes.wsLogin.success(nextAction === 'login' ? 'success' : nextAction));
    } else {
      if (status === 403) {
        yield put(ActionTypes.wsShowSendNotification(true));
      } else {
        yield put(ActionTypes.wsLogin.failure(formError));
      }
      yield put(ActionTypes.wsLogin.failure(formError));
    }
  } catch (e) {
    yield put(ActionTypes.wsLogin.failure());
    throw e;
  }
}

export function* wsLoginWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsLogin.REQUEST);
    yield call(wsLoginSaga, payload);
  }
}

export function* wsConfirmEmailSaga(token) {
  try {
    const { status, data } = yield call(api.wsConfirmEmail, { token });
    if (status < 300) {
      yield put(ActionTypes.wsConfirmEmail.success(data));
      localStorage.setItem('wsJwtToken', data.token);
    } else {
      yield put(ActionTypes.wsConfirmEmail.failure(data[0]));
    }
  } catch (e) {
    yield put(ActionTypes.wsConfirmEmail.failure('Critical'));
    throw e;
  }
}

export function* wsConfirmEmailWatcherSaga() {
  while (true) {
    const { token } = yield take(ActionTypes.WS_CONFIRM_EMAIL.REQUEST);
    yield call(wsConfirmEmailSaga, token);
  }
}

export function* wsLogoutWatcherSaga() {
  while (true) {
    yield take(ActionTypes.WS_LOGOUT_REQUEST);
    yield put(ActionTypes.wsLogoutSuccess());
    localStorage.removeItem('wsJwtToken');
  }
}

export function* wsRequestResetPasswordSaga(payload) {
  try {
    const { status, data } = yield call(api.wsRequestResetPassword, payload);
    if (status < 300) {
      yield put(ActionTypes.wsRequestResetPassword.success());
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.wsRequestResetPassword.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsRequestResetPassword.failure());
    throw e;
  }
}

export function* wsRequestResetPasswordWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsRequestResetPassword.REQUEST);
    yield call(wsRequestResetPasswordSaga, payload);
  }
}

export function* wsConfirmPasswordResetSaga(payload) {
  try {
    const { status, data } = yield call(api.wsConfirmPasswordReset, payload);
    if (status < 300) {
      yield put(ActionTypes.wsConfirmPasswordReset.success());
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.wsConfirmPasswordReset.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsConfirmPasswordReset.failure());
    throw e;
  }
}

export function* wsConfirmPasswordResetWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsConfirmPasswordReset.REQUEST);
    yield call(wsConfirmPasswordResetSaga, payload);
  }
}

export function* wsGetAvailableLangsSaga() {
  try {
    const { status, data } = yield call(api.wsGetAvailableLangs);
    if (status < 300) {
      yield put(ActionTypes.wsGetAvailableLangs.success(data));
    } else {
      yield put(ActionTypes.wsGetAvailableLangs.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGetAvailableLangs.failure());
  }
}

export function* wsGetAvailableLangsSagaWatcher() {
  while (true) {
    yield take(ActionTypes.WS_GET_AVAILABLE_LANGS[REQUEST]);
    yield call(wsGetAvailableLangsSaga);
  }
}

export function* wsGetMtDataSaga() {
  try {
    const { status, data } = yield call(api.wsGetMtData);
    if (status < 300) {
      yield put(ActionTypes.wsGetMtData.success(data.mtConfig));
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.wsConfirmPasswordReset.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGetMtData.failure());
  }
}

export function* wsGetMtDataSagaWatcher() {
  while (true) {
    yield take(ActionTypes.WS_GET_MT_DATA[REQUEST]);
    yield call(wsGetMtDataSaga);
  }
}

export function* wsConfigureMtSaga(payload) {
  const { mtType, ...mtConfig } = payload;

  try {
    const { status, data } = yield call(api.wsConfigureMt, { mtType, mtConfig });
    if (status < 300) {
      yield put(ActionTypes.wsConfigureMt.success());
    } else {
      const errorDetails = data.details || data.detail;
      const formError = new SubmissionError(errorDetails ? { _error: errorDetails } : data);
      yield put(ActionTypes.wsConfigureMt.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsConfigureMt.failure());
    throw e;
  }
}

export function* wsConfigureMtWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsConfigureMt.REQUEST);
    yield call(wsConfigureMtSaga, payload);
  }
}

export function* wsGetConfiguretedDnsTxtAndCNAMESaga() {
  try {
    const { status, data } = yield call(api.wsGetConfiguretedDnsTxtAndCNAME);
    if (status < 300) {
      yield put(ActionTypes.wsGetConfiguretedDnsTxtAndCNAME.success(data));
    } else {
      const errorDetails = data.details || data.detail;
      const formError = new SubmissionError(errorDetails ? { _error: errorDetails } : data);
      yield put(ActionTypes.wsGetConfiguretedDnsTxtAndCNAME.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGetConfiguretedDnsTxtAndCNAME.failure());
    throw e;
  }
}

export function* wsGetConfiguretedDnsTxtAndCNAMEWatcherSaga() {
  while (true) {
    yield take(ActionTypes.WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME.REQUEST);
    yield call(wsGetConfiguretedDnsTxtAndCNAMESaga);
  }
}

export function* wsGenerateDnsTxtSaga({ userDomain }) {
  try {
    const { status, data } = yield call(api.wsConfigureDomain, { userDomain });
    if (status < 300) {
      yield put(ActionTypes.wsSetUserDomain(userDomain));
      yield put(ActionTypes.wsGenerateDnsTxt.success(data));
    } else {
      const errorDetails = data.details || data.detail;
      const formError = new SubmissionError(errorDetails ? { _error: errorDetails } : data);
      yield put(ActionTypes.wsGenerateDnsTxt.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGenerateDnsTxt.failure());
    throw e;
  }
}

export function* wsGenerateDnsTxtWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsGenerateDnsTxt.REQUEST);
    yield call(wsGenerateDnsTxtSaga, payload);
  }
}

export function* wsCheckDomainSaga() {
  try {
    const { status, data } = yield call(api.wsCheckDomain);
    if (status < 300) {
      yield put(ActionTypes.wsCheckDomain.success(data));
    } else {
      const errorDetails = data.details || data.detail || data.error;
      const formError = new SubmissionError(errorDetails ? { _error: errorDetails } : data);
      yield put(ActionTypes.wsCheckDomain.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsCheckDomain.failure());
  }
}

export function* wsCheckDomainWatcherSaga() {
  while (true) {
    yield take(ActionTypes.wsCheckDomain.REQUEST);
    yield call(wsCheckDomainSaga);
  }
}

export function* wsGetMtGroupsSaga() {
  try {
    const { status, data } = yield call(api.wsGetMtGroups);
    if (status < 300) {
      yield put(ActionTypes.wsGetMtGroups.success(data.mt_groups));
    } else {
      const error = data.details || data.detail;
      yield put(ActionTypes.wsGetMtGroups.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsGetMtGroups.failure());
    throw e;
  }
}

export function* wsGetMtGroupsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.WS_GET_MT_GROUPS.REQUEST);
    yield call(wsGetMtGroupsSaga);
  }
}

export function* wsConfigureAccountAndCreateBrokerSaga(payload) {
  try {
    const { status, data } = yield call(api.wsConfigureAccountAndCreateBroker, payload);
    if (status < 300) {
      yield put(ActionTypes.wsConfigureAccountAndCreateBroker.success());
    } else {
      const errorDetails = data.details || data.detail;
      const formError = new SubmissionError(errorDetails ? { _error: errorDetails } : data);
      yield put(ActionTypes.wsConfigureAccountAndCreateBroker.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.wsConfigureAccountAndCreateBroker.failure());
    throw e;
  }
}

export function* wsConfigureAccountAndCreateBrokerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.wsConfigureAccountAndCreateBroker.REQUEST);
    yield call(wsConfigureAccountAndCreateBrokerSaga, payload);
  }
}

export default [
  fork(wsRegistrationWatcherSaga),
  fork(wsLoginWatcherSaga),
  fork(wsGetOtpTokenWatcherSaga),
  fork(wsGetJwtTokenWatcherSaga),
  fork(wsConfirmEmailWatcherSaga),
  fork(wsLogoutWatcherSaga),
  fork(wsRequestResetPasswordWatcherSaga),
  fork(wsConfirmPasswordResetWatcherSaga),
  fork(wsGetRegistrationStatusWatcherSaga),
  fork(wsGetAvailableLangsSagaWatcher),
  fork(wsGetMtDataSagaWatcher),
  fork(wsConfigureMtWatcherSaga),
  fork(wsGetConfiguretedDnsTxtAndCNAMEWatcherSaga),
  fork(wsGenerateDnsTxtWatcherSaga),
  fork(wsCheckDomainWatcherSaga),
  fork(wsGetMtGroupsWatcherSaga),
  fork(wsConfigureAccountAndCreateBrokerWatcherSaga),
];
