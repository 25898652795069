import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TopTab {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  .TopTab__Items {
    display: grid;
    grid-template-columns: 3fr 2fr minmax(min-content, 140px);
    @include respond-to(lg) {
      display: none;
    }
  }
  .TopTab__Item {
    color: #696969;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    &:first-of-type {
      padding-left: 100px;
    }
  }
  .TopTab__GreyLine {
    height: 0px;
    width: 100%;
    border: 1px solid #c2c7ce;
    @include respond-to(lg) {
      display: none;
    }
  }
  .TopTab__CreateNoteButtonWrapper {
    align-self: end;
  }
`;

export { staticStyles };
