import React from 'react';

import PropTypes from 'prop-types';

const RetryIcon = ({ className }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.84311 17.6569C7.58515 18.4009 8.46692 18.9909 9.43772 19.393C10.4085 19.7951 11.4492 20.0014 12.5 20C16.9182 20 20.5 16.4182 20.5 12C20.5 7.58178 16.9182 4 12.5 4C10.2911 4 8.29111 4.89556 6.84311 6.34311C6.10622 7.08 4.5 8.88889 4.5 8.88889"
      stroke="#0AA1E2"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.5 5V9H8.5" stroke="#0AA1E2" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

RetryIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

export default RetryIcon;
