import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'redux-form-validators';

import { DefaultButton, ReduxTextField } from 'common/components';

import { requestEmailConfirmation } from '../../_redux/actions';

import { staticStyles } from './style';

const ResendEmailConfirmationForm = ({ handleSubmit, pristine, submitting, invalid, error }) => {
  const submit = handleSubmit(requestEmailConfirmation);
  return (
    <form className="ResendEmailConfirmationForm" onSubmit={submit}>
      <Field
        name="email"
        type="email"
        textId="authEmail"
        component={ReduxTextField}
        validate={[required(), email()]}
        disabled={submitting}
      />
      <div className="ResendEmailConfirmationForm__button-inner">
        <DefaultButton
          textId="authDoResendEmailConfirmation"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

ResendEmailConfirmationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

ResendEmailConfirmationForm.defaultProps = {
  error: '',
};

export default withRouter(
  reduxForm({
    form: 'resend-email-confirmation-form',
    onSubmitSuccess: (result, dispatch, { history }) => {
      history.push('/auth/login?message=authResendEmailConfirmationHasBeenSent');
    },
  })(ResendEmailConfirmationForm)
);
export { ResendEmailConfirmationForm };
