import { all } from 'redux-saga/effects';

import { rootSaga as intlSaga } from 'interface/connected-intl';
import { rootSaga as userSaga } from 'interface/user';
import { rootSaga as authSaga } from 'auth/_redux';
import { rootSaga as commonSaga } from 'common/_redux';
import { rootSaga as dashboardsSaga } from 'common/dashboards';
import { rootSaga as clientSaga } from 'client';
import { rootSaga as backofficeSaga } from 'backoffice';
import { rootSaga as adminSaga } from 'admin';
import { rootSaga as crmSaga } from 'crm';
import { rootSaga as welcomeSetupSaga } from 'welcome-setup/_redux';

// place your sagas here https://goo.gl/1bAsJX
const sagas = [
  ...intlSaga,
  ...userSaga,
  ...authSaga,
  ...commonSaga,
  ...dashboardsSaga,
  ...clientSaga,
  ...backofficeSaga,
  ...adminSaga,
  ...crmSaga,
  ...welcomeSetupSaga,
];

export default function* rootSaga() {
  yield all(sagas);
}
