import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { NoItemsPanel, PageContentPreloader, SearchPanel } from 'common/components';

import BonusesTable from 'common/components/tables/BonusesTable';

import noPaymentsImage from 'common/images/noPayments.svg';
import { getUserBonuses, rejectBonus } from '../../_redux';

const MyBonusesPage = ({
  userBonuses,
  userBonusesAreLoaded,
  getUserBonuses,
  rejectBonus,
  cancelBonusModal,
  intl,
  history,
  location,
}) => {
  const query = {
    page: 1,
    page_size: 10,
    tab: 'bonuses',
    ...queryString.parse(location.search),
  };

  /* eslint-disable */
  useEffect(() => {
    getUserBonuses({ search: query });
  }, [getUserBonuses]);
  /* eslint-enable */

  const filters = [
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'ACTIVE', label: intl.formatMessage({ id: 'status_active' }) },
        { value: 'COMPLETED', label: intl.formatMessage({ id: 'status_completed' }) },
        { value: 'REJECTED', label: intl.formatMessage({ id: 'status_rejected' }) },
        { value: 'EXPIRED', label: intl.formatMessage({ id: 'status_expired' }) },
        { value: 'CANCELED', label: intl.formatMessage({ id: 'status_canceled' }) },
      ],
    },
    {
      title: 'justBonusType',
      accessor: 'bonusType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'bonus_monthly_interest', label: intl.formatMessage({ id: 'bonusMonthlyInterest' }) },
        { value: 'bonus_welcome', label: intl.formatMessage({ id: 'bonusWelcome' }) },
        { value: 'bonus_on_deposit', label: intl.formatMessage({ id: 'bonusOnDeposit' }) },
        { value: 'bonus_compensation', label: intl.formatMessage({ id: 'bonusCompensation' }) },
        { value: 'bonus_manual', label: intl.formatMessage({ id: 'bonusManual' }) },
        { value: 'bonus_risk_free', label: intl.formatMessage({ id: 'bonusRiskFree' }) },
      ],
    },
  ];

  return (
    <PageContentPreloader ready={userBonusesAreLoaded} type="boUserInfo" firstLaunchOnly>
      {!userBonuses.count && !Object.keys(query).length ? (
        ''
      ) : (
        <SearchPanel
          location={location}
          history={history}
          getList={getUserBonuses}
          filters={filters}
          params={{ search: query }}
          withoutSearch
        />
      )}
      {userBonuses.count ? (
        <BonusesTable
          data={userBonuses}
          loading={!userBonusesAreLoaded}
          location={location}
          history={history}
          getList={getUserBonuses}
          params={{ search: query }}
          query={query}
          onRowClick={() => {}}
          rejectBonus={rejectBonus}
          cancelBonusModal={cancelBonusModal}
        />
      ) : (
        <NoItemsPanel icon={noPaymentsImage} captionSlug="noBonuses" />
      )}
    </PageContentPreloader>
  );
};

MyBonusesPage.propTypes = {
  userBonuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  userBonusesAreLoaded: PropTypes.bool.isRequired,
  getUserBonuses: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  rejectBonus: PropTypes.func.isRequired,
  cancelBonusModal: PropTypes.bool.isRequired,
};

MyBonusesPage.defaultProps = {
  userBonuses: [],
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    state => ({
      userBonuses: state.client.bonuses.userBonuses,
      userBonusesAreLoaded: state.client.bonuses.userBonusesAreLoaded,
      cancelBonusModal: state.client.bonuses.cancelBonusModal,
    }),
    {
      getUserBonuses: search => getUserBonuses.request(search),
      rejectBonus: id => rejectBonus.request(id),
    }
  )
)(MyBonusesPage);
