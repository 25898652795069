import css from "styled-jsx/css";

import { rgba } from "common/styling/theme";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .FileField {
    position: relative;
    margin-bottom: 10px;
  }

  .FileField__label {
    display: block;
    margin-bottom: 4px;
  }

  .FileField__error {
    min-height: 15px;
    width: 100%;
    display: inline-block;
  }

  .FileField__dropzone {
    :global(> div) {
      padding: 5px !important;
      width: 100% !important;
      min-height: rem(111px) !important;
      height: auto !important;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      transition: all 0.4s ease;
      :global(span) {
        display: block;
        font-size: rem(14px);
        font-weight: 300;
      }
      :global(p) {
        position: relative;
        font-size: rem(15px);
        font-weight: 500;
        padding-left: rem(38px);
        margin-bottom: 5px;
        &:after,
        &:before {
          content: "";
          position: absolute;
          width: rem(16px);
          height: 2px;
          top: rem(8px);
          left: rem(13px);
        }
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .FileField__dropzone {
      :global(> div > div) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .FileField__descr {
    display: block;
    margin-top: 10px;
  }

  .FileField__file {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .FileField__item-inner {
      width: rem(85px);
      height: rem(65px);
      border-radius: rem(7px);
      margin-right: rem(20px);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent !important;
      &:last-child {
        margin-top: 0;
      }
      :global(> *) {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
    .FileField__itemInner_notImage {
      width: rem(85px) !important;
      height: rem(65px) !important;
    }
  }

  .FileField__dropzone-plus-active {
    display: none;
  }

  .FileField__cross-button {
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    font-size: rem(16px);
    right: rem(-20px);
    top: 0;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
  }

  .FileField__cross-button:after {
    content: "×";
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .FileField__error {
      color: ${colors.error.hex};
    }

    .FileField__dropzone {
      :global(> div) {
        border: 1px dashed ${rgba([colors.gray.rgb, 0.5])} !important;
        border-radius: ${border.radius} !important;
        &:hover {
          border-color: ${colors.primary.hex} !important;
        }
        :global(span) {
          color: ${colors.gray.hex};
        }
        :global(p) {
          color: ${colors.primary.hex};
          &:after,
          &:before {
            background-color: ${colors.primary.hex};
          }
        }
      }
    }

    .FileField__disabled {
      .FileField__dropzone {
        :global(> div) {
          cursor: not-allowed;
          opacity: 1 !important;
          background: ${colors.disabled.hex};
          border: 1px dashed transparent !important;
        }
      }
    }

    .FileField__cross-button {
      background-color: ${rgba([colors.lightGray.rgb, 0.5])};
      color: ${colors.gray.hex};

      &:hover {
        background-color: ${rgba([colors.lightGray.rgb, 0.8])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
