import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  news: {},
  newsAreLoaded: false,

  analyticReviews: {},
  analyticReviewsAreLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_NEWS[REQUEST]:
      return { ...state, newsAreLoaded: false };
    case ActionTypes.GET_NEWS[SUCCESS]:
      return {
        ...state,
        newsAreLoaded: true,
        news: action.data,
      };
    case ActionTypes.GET_NEWS[FAILURE]:
      return { ...state, newsAreLoaded: false };

    case ActionTypes.GET_ANALYTIC_REVIEWS[REQUEST]:
      return { ...state, analyticReviewsAreLoaded: false };
    case ActionTypes.GET_ANALYTIC_REVIEWS[SUCCESS]:
      return {
        ...state,
        analyticReviewsAreLoaded: true,
        analyticReviews: action.data,
      };
    case ActionTypes.GET_ANALYTIC_REVIEWS[FAILURE]:
      return { ...state, analyticReviewsAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
