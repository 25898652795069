import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import { AppEntryPreloader } from 'common/components';

import { ModalProvider, FormMessageProvider } from 'common';
import { ReduxThemeProvider } from 'common/styling/theme';

import { loadInterface as loadCrmInterface } from 'crm';
import { loadInterface as loadAdminInterface } from 'admin';
import { loadInterface as loadBackofficeInterface } from 'backoffice';
import { loadInterface as loadClientInterface } from 'client';

import UserWrapper from './UserWrapper';
import { getProfile, startWS, stopWS } from './_redux/actions';

const UserWrapperHOC = ({ Interface, getMenu }) => {
  const UserInner = ({ interfaces, currentInterface, ...rest }) => (
    <UserWrapper interfaces={interfaces} currentInterface={currentInterface} getMenu={getMenu}>
      <Interface {...rest} />
    </UserWrapper>
  );

  UserInner.propTypes = {
    interfaces: PropTypes.array.isRequired,
    currentInterface: PropTypes.object.isRequired,
  };

  return UserInner;
};

const asyncUserWrappedInterface = loadInterface =>
  Loadable({
    loader: () =>
      new Promise(resolve => {
        loadInterface().then(module => {
          resolve(UserWrapperHOC(module));
        });
      }),
    loading: AppEntryPreloader,
  });

const CRM_INTERFACE = {
  slug: 'crm',
  label: 'salesInterface',
  mainUrl: '/crm',
  Component: asyncUserWrappedInterface(loadCrmInterface),
};

const ADMIN_INTERFACE = {
  slug: 'admin',
  label: 'adminInterface',
  mainUrl: '/admin',
  Component: asyncUserWrappedInterface(loadAdminInterface),
};
const BACKOFFICE_INTERFACE = {
  slug: 'backoffice',
  label: 'backofficeInterface',
  mainUrl: '/backoffice',
  Component: asyncUserWrappedInterface(loadBackofficeInterface),
};
const CLIENT_INTERFACE = {
  slug: 'client',
  label: 'clientInterface',
  mainUrl: '',
  Component: asyncUserWrappedInterface(loadClientInterface),
};

const getUserInterfaces = (profile, addons) => {
  const interfaces = [];
  if (addons.includes('crmInterface') && profile.hasCRMAccess) {
    interfaces.push(CRM_INTERFACE);
  }
  if (profile.hasAdminAccess || profile.perms?.superUserPerm) {
    interfaces.push(ADMIN_INTERFACE);
  }
  if (profile.hasBackofficeAccess) {
    interfaces.push(BACKOFFICE_INTERFACE);
  }
  if (profile.hasClientAccess) {
    interfaces.push(CLIENT_INTERFACE);
  }

  return interfaces;
};

const User = ({ profile, addons, getProfile, startWS, stopWS }) => {
  const interfaces = profile ? getUserInterfaces(profile, addons) : [];

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    startWS();
    return () => {
      stopWS();
    };
  }, [startWS, stopWS]);

  return profile ? (
    <Switch>
      {interfaces.map(({ slug, mainUrl, label, Component }) => (
        <Route
          key={slug}
          path={mainUrl}
          render={props => (
            <ReduxThemeProvider forInterface={slug}>
              <ModalProvider>
                <FormMessageProvider>
                  <Component interfaces={interfaces} currentInterface={{ slug, label, mainUrl }} {...props} />
                </FormMessageProvider>
              </ModalProvider>
            </ReduxThemeProvider>
          )}
        />
      ))}
    </Switch>
  ) : null;
};

User.propTypes = {
  profile: PropTypes.object,
  getProfile: PropTypes.func.isRequired,
  startWS: PropTypes.func.isRequired,
  stopWS: PropTypes.func.isRequired,
  addons: PropTypes.array,
};

User.defaultProps = {
  profile: {},
  addons: [],
};

export default connect(
  ({ user: { profile, currentInterface }, interfaceConfig }) => ({
    profile,
    currentInterfaceSlug: currentInterface,
    addons: interfaceConfig.addons,
  }),
  {
    getProfile: () => getProfile.request(),
    startWS,
    stopWS,
  }
)(User);
