import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestStrategyForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 360px;
    margin-top: rem(25px);

    .InvestStrategyForm__line {
      display: flex;
      justify-content: space-between;

      :global(.DefaultButton),
      :global(.BaseTextField) {
        width: 48%;
      }
    }

    .InvestStrategyForm__desc {
      color: #777777;
      font-size: rem(12px);
      margin-top: -10px;
      margin-bottom: 25px;

      :global(.MoneyCell__inner) {
        display: inline-block;
        height: auto;
        margin-left: 5px;
        font-weight: 500;

        :global(span) {
          color: #777777 !important;
          font-size: rem(12px) !important;
        }
      }
    }

    .formError {
      margin: rem(0 0 20px);
    }
  }
`;

export { staticStyles };
