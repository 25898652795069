import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NotificationSettingsForm {
    display: flex;
    flex-direction: column;
    margin-top: rem(20px);

    .NotificationSettingsForm__block {
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
      padding: rem(40px 40px 20px);
      margin-bottom: rem(30px);
    }

    :global(.NotificationSettingsForm__settings) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-gap: 2vw;
      margin-top: rem(20px);
    }

    .NotificationSettingsForm__caption,
    .NotificationSettingsForm__description {
      margin-bottom: rem(30px);
    }

    :global(.ReduxSelectField) {
      width: 400px;

      @include respond-to(sm) {
        width: 300px;
      }
    }

    :global(.DefaultButton) {
      align-self: flex-start;
      margin-top: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .NotificationSettingsForm__block {
      background-color: ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
