import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';

import StatusPlate from '../StatusPlate';

import { staticStyles, getDynamicStyles } from './style';

const StatusItem = ({
  title,
  subTitle,
  level,
  description,
  statusColor,
  promoTexts,
  isButtonEnabled,
  buttonText,
  buttonLink,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="StatusItem">
      <div className="StatusItem__header">
        <div className="StatusItem__title">
          <h3>{title}</h3>
          <div className="StatusItem__subtitle">{subTitle}</div>
        </div>
        <StatusPlate title={title} level={level} statusColor={statusColor} />
      </div>
      <p className="StatusItem__description">{description}</p>
      <ul className="StatusItem__list">
        {promoTexts.map((text, index) => (
          <li className="StatusItem__list-item" key={index}>
            {text}
          </li>
        ))}
      </ul>
      {isButtonEnabled && (
        <a className="StatusItem__btn" target="_blank" rel="noopener noreferrer" href={buttonLink}>
          {buttonText}
        </a>
      )}
      <style jsx global>
        {dynamicStyles}
      </style>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

StatusItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  statusColor: PropTypes.string.isRequired,
  promoTexts: PropTypes.array.isRequired,
  isButtonEnabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

StatusItem.defaultProps = {
  theme: {},
};

export default withTheme()(StatusItem);
