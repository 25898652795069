import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { PageLayout, PageContentPreloader } from 'common/components';
import { getUsers } from 'backoffice/users/_redux';
import { TaskPanel, TaskEditHistory } from '../../_components';

import { getTask, getManagers } from '../../_redux';

const TaskDetailPage = ({
  task,
  taskIsLoaded,
  getTask,
  managers,
  getManagers,
  users,
  usersAreLoaded,
  getUsers,
  profile,
}) => {
  const match = useRouteMatch();

  useEffect(() => {
    getManagers();
    getUsers();
  }, [getManagers, getUsers]);

  useEffect(() => {
    getTask({ id: match.params.id });
  }, [getTask, match.params.id]);

  const usersList = useMemo(
    () =>
      Array.isArray(users) && usersAreLoaded
        ? users.map(user => ({
            value: user.id,
            label: user.fullName,
          }))
        : [],
    [users, usersAreLoaded]
  );

  return (
    <PageLayout captionSlug="justTask" extraCaptionText={match.params.id}>
      <PageContentPreloader ready={taskIsLoaded} type="bigLine" firstLaunchOnly>
        <TaskPanel task={task} user={profile} managers={managers} users={usersList} />
        <TaskEditHistory taskEditHistory={task.changes} />
      </PageContentPreloader>
    </PageLayout>
  );
};

TaskDetailPage.propTypes = {
  task: PropTypes.object.isRequired,
  taskIsLoaded: PropTypes.bool.isRequired,
  getTask: PropTypes.func.isRequired,
  managers: PropTypes.array.isRequired,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  usersAreLoaded: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired,
  getManagers: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

export default compose(
  connect(
    state => ({
      profile: state.user.profile,
      task: state.backoffice.managers.task,
      taskIsLoaded: state.backoffice.managers.taskIsLoaded,
      managers: state.backoffice.managers.managers,
      managersAreLoaded: state.backoffice.managers.managersAreLoaded,
      users: state.backoffice.users.users,
      usersAreLoaded: state.backoffice.users.usersAreLoaded,
    }),
    {
      getTask: id => getTask.request(id),
      getManagers: () => getManagers.request(),
      getUsers: () => getUsers.request({ search: {} }),
    }
  )
)(TaskDetailPage);
export { TaskDetailPage };
