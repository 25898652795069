import css from 'styled-jsx/css';
import { gradient, rgba, isRTL } from './theme';

/* eslint-disable */
const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/grid';
  @import 'src/common/styling/_mixins-scss/font-face';
  @import 'src/common/styling/_mixins-scss/rem';

  @include font-face('ubuntu-font', '/fonts/UbuntuRegular/UbuntuRegular');
  @include font-face('ubuntu-font', '/fonts/UbuntuLight/UbuntuLight', 300);
  @include font-face('ubuntu-font', '/fonts/UbuntuMedium/UbuntuMedium', 500);
  @include font-face('ubuntu-font', '/fonts/UbuntuBold/UbuntuBold', 600);

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    &:after,
    &:before {
      box-sizing: border-box;
    }
  }

  html {
    -webkit-text-size-adjust: none;
    position: relative;
    height: 100%;
    @include rem-baseline;
    @include respond-to(sm) {
      @include rem-baseline(90%);
    }
  }

  body {
    min-width: 320px;
    position: relative;
    line-height: 1.35;
    font-family: $ubuntu-font;
    overflow: auto;
    cursor: default;
    height: 100%;
    @include respond-to(md) {
      &.Left__menu-open {
        overflow: hidden;
      }
    }
  }

  .Body__no-scroll {
    padding: 0 !important;
  }

  .Body__touch-screen {
    @media (hover: none) and (pointer: coarse) and (max-width: 1000px) {
      padding: 0 !important;
    }
    @media (hover: none) and (pointer: fine) and (max-width: 1000px) {
      padding: 0 !important;
    }
    @media (hover: hover) and (pointer: coarse) and (max-width: 1000px) {
      padding: 0 !important;
    }
  }

  .Body__crm {
    background: #fff !important;
  }

  form {
    position: relative;
  }

  select {
    font-family: $ubuntu-font;
    outline: none;
  }

  input,
  textarea {
    font-family: $ubuntu-font;
    border: 1px solid;
    display: inline-block;
    width: 100%;
    font-size: rem(15px);
    padding: rem(${isRTL ? '25px 13px 10px 32px' : '25px 32px 10px 13px'});
    outline: none;
    transition: all 0.4s ease;
    background-color: transparent;
    position: relative;
    z-index: 2;
  }

  input[type='date'] {
    position: relative;
    z-index: 4;
    padding: rem(23px 32px 7.5px 13px);
    width: 100%;
  }

  select {
    font-family: $ubuntu-font;
    font-size: rem(15px);
  }

  .polyfill-right-aligned {
    z-index: 10;
    background: #fff;
  }

  textarea {
    resize: none;
  }

  input,
  textarea,
  select,
  input:disabled,
  textarea:disabled,
  select:disabled,
  input[type='date'],
  input[type='date']:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    opacity: 1;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span,
  strong,
  label,
  i {
    display: inline-block;
  }

  button {
    font-family: $ubuntu-font;
    outline: none;
    display: inline-block;
    cursor: pointer;
    border: none;
    background-color: transparent;
    transition: all 0.3s ease;
  }

  a {
    transition: all 0.3s ease;
    outline: none;
    text-decoration: none;
    display: inline-block;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
    }
  }

  pre {
    font-family: $ubuntu-font;
    white-space: pre-wrap;
    font-size: rem(15px);
  }

  ul {
    list-style-type: none;
  }

  h1 {
    line-height: 42px;
    font-size: 36px;
    font-weight: 300;

    @include respond-to(sm) {
      font-size: 26px;
      line-height: 31px;
    }
  }

  h2 {
    line-height: 32px;
    font-size: 26px;
    font-weight: 300;

    @include respond-to(sm) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;

    @include respond-to(sm) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  small {
    line-height: 22px;
    font-size: 13px;
  }

  section {
    width: 100%;
  }

  select::-ms-expand {
    display: none;
  }

  .formError {
    font-weight: 400;
    margin-top: 10px;
  }

  .App {
    height: 100%;
  }

  .App-intro {
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    > span {
      display: block;
      line-height: 32px;
      font-size: 26px;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }

  // Style for select
  .Select__placeholder {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 2px;
  }

  .Select__control {
    border: 1px solid;
    box-shadow: none !important;
    padding: rem(0 0 0 13px);
    transition: all 0.4s ease;
    min-height: 0 !important;
    cursor: pointer !important;
    z-index: 6;
    border-radius: 0 !important;
    background-color: transparent !important;
    &:focus {
      border: none !important;
    }
  }

  .Select__control--is-focused {
    min-height: 0 !important;
    z-index: 7;
  }

  .Select__dropdown-indicator {
    width: 8px !important;
    height: 8px !important;
    background-image: none !important;
    position: relative;
    bottom: 2px;
    right: 7px;
    border-width: 0 1px 1px 0 !important;
    display: inline-block;
    padding: 1px !important;
    transform: rotate(45deg);
    svg {
      display: none;
    }
  }

  .Select__menu {
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2) !important;
    border: none !important;
    margin: 0 !important;
    z-index: 11 !important;
    border-radius: 0 !important;
  }

  .Select__option {
    padding: rem(12px 15px) !important;
    z-index: 8 !important;
    position: relative;
    cursor: pointer !important;
    &:last-child {
      border-radius: 0 !important;
    }
  }

  .Select__menu-list {
    padding: 0 !important;
  }

  .Select__indicator-separator {
    display: none;
  }

  .pixel-inner {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: 25px 30px 25px;
    direction: ltr;
    @include respond-to(sm) {
      padding: 15px;
    }
  }

  .pixel-code {
    padding: rem(15px);
    border: 1px solid;
    display: block;
    word-break: break-all;
    font-size: rem(19px);
  }

  .widget-inner {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: 25px 30px 25px;
    direction: ltr;
    @include respond-to(sm) {
      padding: 15px;
    }
  }

  .widget-code {
    padding: rem(15px);
    border: 1px solid;
    display: block;
    word-break: break-all;
    font-size: rem(19px);
  }

  /* default style for Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slider {
    .slick-track,
    .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    &:before {
      display: table;
      content: '';
    }
    &:after {
      display: table;
      content: '';
      clear: both;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide {
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
  /* end */
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    body {
      color: ${colors.black.hex};
      background-color: ${colors.bodyBackground.hex};
    }

    input,
    textarea {
      border-radius: ${border.radius};
      border-color: ${colors.lightGray.hex};
    }

    a {
      color: ${colors.secondary.hex};
    }

    .formError {
      color: ${colors.error.hex};
    }

    .App-intro {
      > span {
        color: ${colors.primary.hex};
      }
    }

    .Select__value-container {
      color: ${colors.black.hex};
    }

    .Select__control {
      border-radius: ${border.radius};
      border-color: ${colors.lightGray.hex};
      color: ${colors.black.hex};
      &:focus {
        border-color: ${colors.primary.hex};
      }
    }

    .Select__control--is-focused {
      border: 1px solid ${colors.primary.hex};
    }

    .Select__dropdown-indicator {
      border: solid ${colors.black.hex};
    }

    .Select__control--is-focused .Select__dropdown-indicator {
      border-color: ${colors.black.hex};
    }

    .Select__menu,
    .css-11unzgr {
      background-color: ${colors.white.hex} !important;
    }

    .Select__option {
      background-color: ${colors.white.hex};
      color: ${colors.black.hex};
      border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.5])} !important;
      &:last-child {
        border-bottom: none !important;
      }

      &--is-selected {
        color: ${colors.secondary.hex};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 0.25], 0, [colors.gradientEnd.rgb, 0.25], 100)};
      }

      &--is-focused {
        color: ${colors.secondary.hex};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
      }

      &--is-disabled {
        color: ${colors.gray.hex};
        background-color: ${colors.lightGray.hex};
      }
    }

    .pixel-inner {
      background: ${colors.white.hex};
    }

    .pixel-code {
      border-radius: ${border.radius};
      border-color: ${colors.lightGray.hex};
    }

    .widget-inner {
      background: ${colors.white.hex};
    }

    .widget-code {
      border-radius: ${border.radius};
      border-color: ${colors.lightGray.hex};
    }
  `;
};
/* eslint-enable */

export { staticStyles, getDynamicStyles };
