import axios from 'axios';
import { baseClient } from '../_api';

const sessionClient = axios.create({
  baseURL: '/api/auth',
  validateStatus: status => status < 300 || status === 401 || status === 409 || status === 429,
});

sessionClient.interceptors.request.use(config => {
  const token = localStorage.getItem('otpToken');
  const locale = localStorage.getItem('locale');

  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  if (locale) {
    config.headers.locale = locale;
  }

  return config;
});

export const registration = data =>
  baseClient.post('/registration/', data, {
    withCredentials: true,
    validateStatus: status => status < 300 || status === 400 || status === 409,
  });

export const confirmEmail = token => baseClient.post('/confirm_email/', { token });

export const getOTPToken = payload => baseClient.post('/get_otp_token/', { ...payload });

export const getJWTToken = payload => sessionClient.post('/get_token/', { ...payload });
