import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  PageLayout,
  PageContentPreloader,
  StatusCell,
  DateCell,
  PaginationTable,
  DefaultButton,
} from 'common/components';
import { getBackofficeUserExports, downloadBackofficeUserExport } from 'common/_redux';

import { staticStyles } from './style';

const ExportUsersPage = ({
  userExportData,
  userExportDataIsLoaded,
  getBackofficeUserExports,
  downloadBackofficeUserExport,
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getBackofficeUserExports({ search: query });
  }, [getBackofficeUserExports, query]);

  const downloadExport = (id, name) => {
    downloadBackofficeUserExport({ id, name });
  };

  const STATUSES = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
  };

  /* eslint-disable */
  const columns = [
    {
      Header: 'justId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
    },
    {
      Header: 'justName',
      accessor: 'name',
      Cell: ({
        row: {
          original: { name },
        },
      }) => <span>{name}</span>,
    },
    {
      Header: 'justCreatedBy',
      accessor: 'createdBy',
      Cell: ({
        row: {
          original: { createdBy },
        },
      }) => <span>{createdBy}</span>,
    },
    {
      Header: 'created',
      accessor: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      Header: () => null,
      accessor: 'justStatus',
      Cell: ({
        row: {
          original: { id, name, status },
        },
      }) =>
        status === STATUSES.SUCCESS ? (
          <DefaultButton textId="justDownload" type="button" onClick={() => downloadExport(id, name)} />
        ) : (
          <StatusCell statusCode={status} />
        ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  return (
    <PageLayout captionSlug="listOfUserExports">
      <PageContentPreloader ready={userExportDataIsLoaded} type="bigLine">
        <div className="ExportUsersPage">
          <PaginationTable
            data={userExportData}
            columns={columns}
            loading={!userExportDataIsLoaded}
            getList={getBackofficeUserExports}
            onRowClick={() => {}}
            location={location}
            history={history}
            params={{ search: query }}
            isNewTable
            withoutSort
          />
        </div>
        <style jsx global>
          {staticStyles}
        </style>
      </PageContentPreloader>
    </PageLayout>
  );
};

ExportUsersPage.propTypes = {
  userExportData: PropTypes.array.isRequired,
  userExportDataIsLoaded: PropTypes.bool.isRequired,
  getBackofficeUserExports: PropTypes.func.isRequired,
  downloadBackofficeUserExport: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    userExportData: state.common.userExportData,
    userExportDataIsLoaded: state.common.userExportDataIsLoaded,
  }),
  {
    getBackofficeUserExports: search => getBackofficeUserExports.request(search),
    downloadBackofficeUserExport: data => downloadBackofficeUserExport.request(data),
  }
)(ExportUsersPage);
export { ExportUsersPage };
