import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates-gte-react-17';
import { useMediaQuery } from 'react-responsive';
import { injectIntl } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';

const yesterday = moment().add(-1, 'day').startOf('day');
const today = moment().startOf('day');
const tomorrow = moment().add(1, 'day').startOf('day');

function checkCondition(screenWidth, buttonPosition) {
  const result = screenWidth / buttonPosition;
  return result > 1.6 && result < 2.4;
}

const Calendar = ({
  isUnix,
  onChangeFunc,
  onChangeParams,
  defaultStartDate,
  defaultEndDate,
  setCorrectLastDay,
  daysLimit,
  setInMiddle,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [startDate, setStartDate] = useState(defaultStartDate || yesterday);
  const [endDate, setEndDate] = useState(defaultEndDate || tomorrow);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const [isRightPosition, setIsRightPosition] = useState(true);
  const [isMiddlePosition, setIsMiddlePosition] = useState(false);

  useEffect(() => {
    const { left, right } = wrapperRef.current.getBoundingClientRect();
    const middlePositionOfButton = left + (right - left) / 2;
    const windowWidth = window.innerWidth;
    if (checkCondition(windowWidth, middlePositionOfButton)) {
      setIsMiddlePosition(true);
    } else if (windowWidth - middlePositionOfButton < 619) {
      setIsRightPosition(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) setOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (defaultStartDate) setStartDate(defaultStartDate);
    if (defaultEndDate) setEndDate(defaultEndDate);
  }, [onChangeParams]);

  const handleCalendarDatesChange = ({ startDate, endDate }) => {
    const now = moment();
    let correctedStartDate = startDate && startDate.clone().startOf('day');
    let correctedEndDate = endDate && endDate.clone().endOf('day');

    if (now < correctedEndDate) correctedEndDate = now;

    setStartDate(correctedStartDate);
    if (correctedEndDate) {
      if (!setCorrectLastDay) {
        setEndDate(correctedEndDate.add(1, 'day').startOf('day'));
      } else {
        setEndDate(correctedEndDate.endOf('day'));
      }
    }

    if (startDate && endDate && focusedInput === 'endDate') {
      if (isUnix) {
        correctedStartDate = correctedStartDate.unix();
        correctedEndDate = correctedEndDate.unix();
      }

      if (onChangeFunc) {
        const correctedParams = Object.values({
          ...onChangeParams,
          correctedStartDate,
          correctedEndDate,
        });

        onChangeFunc(...correctedParams);
        setOpen(false);
      }
    }
  };

  const handleOutsideRange = day => {
    const correctedDay = day.startOf('day');

    if (focusedInput === 'startDate') return correctedDay.isAfter(today);

    if (focusedInput === 'endDate')
      return (
        correctedDay.isBefore(startDate) ||
        correctedDay.isAfter(tomorrow) ||
        correctedDay.isAfter(startDate.clone().add(daysLimit, 'day'))
      );

    return false;
  };

  const handleCalendarFocusedInputChange = focusedInput => setFocusedInput(focusedInput || 'startDate');

  const handleCalendarOpen = () => {
    setOpen(!isOpen);
    setEndDate(null);
  };

  return (
    <div ref={wrapperRef} className={setInMiddle ? 'Calendar CalendarSpecificSize' : 'Calendar'}>
      <button className="Calendar__data-info" type="button" onClick={handleCalendarOpen}>
        {`${startDate ? startDate.format('ll') : intl.formatMessage({ id: 'justFrom' })} — ${
          endDate ? endDate.format('ll') : intl.formatMessage({ id: 'justTo' })
        }`}
      </button>

      {isOpen && (
        <div
          className={classNames('Calendar__datePicker-inner', {
            'Calendar__datePicker-inner--isRightPosition': isRightPosition && !isMiddlePosition,
            'Calendar__datePicker-inner--isMiddlePosition': isMiddlePosition,
            'Calendar__datePicker-inner--setInMiddle': setInMiddle,
          })}>
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleCalendarDatesChange}
            onFocusChange={handleCalendarFocusedInputChange}
            isOutsideRange={handleOutsideRange}
            focusedInput={focusedInput}
            minimumNights={0}
            initialVisibleMonth={() => startDate}
            orientation={isTabletOrMobile ? 'vertical' : 'horizontal'}
            numberOfMonths={2}
            verticalHeight={100}
            hideKeyboardShortcutsPanel
          />
        </div>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

Calendar.propTypes = {
  onChangeFunc: PropTypes.func,
  onChangeParams: PropTypes.object,
  isUnix: PropTypes.bool,
  daysLimit: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  setCorrectLastDay: PropTypes.bool,
  theme: PropTypes.object,
  defaultStartDate: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  defaultEndDate: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  setInMiddle: PropTypes.bool,
};

Calendar.defaultProps = {
  onChangeFunc: null,
  onChangeParams: {},
  isUnix: true,
  defaultStartDate: null,
  setInMiddle: false,
  setCorrectLastDay: false,
  defaultEndDate: null,
  theme: {},
};

export default withTheme()(injectIntl(Calendar));
export { Calendar };
