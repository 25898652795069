import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { DefaultButton } from 'common/components';

import { closeIssueBackoffice } from '../../_redux';

import staticStyles from './style';

const CloseIssueForm = ({ issueId, handleSubmit, closeIssueBackoffice, dispatch, submitting, error }) => {
  const submit = useMemo(
    () =>
      handleSubmit(() =>
        closeIssueBackoffice(
          {
            issueId,
          },
          dispatch
        )
      ),
    [handleSubmit, issueId, dispatch]
  );
  return (
    <div className="RejectModal__content">
      <form className="CloseIssueForm" onSubmit={submit}>
        <div className="CloseIssueForm__btns">
          <DefaultButton butColor="error" textId="justClose" type="submit" loading={submitting} disabled={submitting} />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

CloseIssueForm.propTypes = {
  issueId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeIssueBackoffice: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

CloseIssueForm.defaultProps = {
  error: '',
};

export default compose(
  connect(null, {
    closeIssueBackoffice: search => closeIssueBackoffice.request(search),
  }),
  reduxForm({
    form: 'CloseIssueForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.onSubmitSuccess();
    },
  })
)(CloseIssueForm);
export { CloseIssueForm };
