import css from 'styled-jsx/css';

const staticStyles = css.global`
  .PartnersPageExports {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .PartnersPageExports > .RadioButton {
    width: 700px;
  }
  .PartnersPage__export-select {
    display: flex;
    margin-bottom: 10px;

    .BaseSelectField {
      width: 180px;
      margin-bottom: 0;
      &__label {
        font-size: rem(15px);
        line-height: rem(22px);
        font-weight: 500;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .Select__control {
      min-height: 100% !important;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PartnersPage__export-select {
      .BaseSelectField {
        .Select__control {
          border-color: ${colors.primary.hex} !important;
          &::after {
            border-color: ${colors.primary.hex};
          }
        }

        &__label {
          color: ${colors.primary.hex};
        }
      }
    }
  `;
};
export { staticStyles, getDynamicStyles };
