import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';

import {
  PageLayout,
  PageContentPreloader,
  StatusCell,
  DateCell,
  PaginationTable,
  DefaultButton,
} from 'common/components';

import { getBackOfficePaymentExports, downloadBackofficePaymentExport } from '../../_redux';

import { staticStyles } from './style';

const ExportsPaginationTable = ({
  captionSlug,
  data,
  dataIsLoaded,
  exportsType,
  getList,
  downloadBackofficePaymentExport,
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getList({ search: query, exportsType });
  }, [getList, query, exportsType]);

  const onReloadClick = () => getList({ search: query, exportsType });

  const STATUSES = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
  };

  /* eslint-disable */
  const columns = [
    {
      Header: 'justId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
    },
    {
      Header: 'justName',
      accessor: 'name',
      Cell: ({
        row: {
          original: { name },
        },
      }) => <span>{name}</span>,
    },
    {
      Header: 'justCreatedBy',
      accessor: 'justCreatedBy',
      Cell: ({
        row: {
          original: { createdBy },
        },
      }) => <span>{createdBy}</span>,
    },
    {
      Header: 'justCreated',
      accessor: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      Header: () => null,
      accessor: 'justStatus',
      Cell: ({
        row: {
          original: { id, name, status },
        },
      }) =>
        status === STATUSES.SUCCESS ? (
          <DefaultButton
            textId="justDownload"
            type="button"
            onClick={() => downloadBackofficePaymentExport({ id, name, exportsType })}
          />
        ) : (
          <StatusCell statusCode={status} />
        ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  return (
    <PageLayout captionSlug={captionSlug} reloading={!dataIsLoaded} showReload onReloadClick={onReloadClick}>
      <PageContentPreloader ready={dataIsLoaded} type="bigLine">
        <div className="ExportPaginationTable">
          <PaginationTable
            data={data}
            columns={columns}
            loading={!dataIsLoaded}
            getList={({ search }) => {
              getList({ search, exportsType });
            }}
            onRowClick={() => {}}
            location={location}
            history={history}
            params={{ search: query }}
            isNewTable
            withoutSort
          />
        </div>
        <style jsx global>
          {staticStyles}
        </style>
      </PageContentPreloader>
    </PageLayout>
  );
};

ExportsPaginationTable.propTypes = {
  captionSlug: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dataIsLoaded: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  downloadBackofficePaymentExport: PropTypes.func.isRequired,
  exportsType: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      data: state.backoffice.payments.paymentExports,
      dataIsLoaded: state.backoffice.payments.paymentExportsIsLoaded,
    }),
    {
      getList: ({ search, exportsType }) => getBackOfficePaymentExports.request({ exportsType, search }),
      getBackOfficePaymentExports: ({ search, exportsType }) =>
        getBackOfficePaymentExports.request({ search, exportsType }),
      downloadBackofficePaymentExport: ({ id, name, exportsType }) =>
        downloadBackofficePaymentExport.request({ exportsType, id, name }),
    }
  )
)(ExportsPaginationTable);
export { ExportsPaginationTable };
