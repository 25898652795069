import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ClientPage {
    width: 100%;
    padding-top: 24px;
    padding-left: 24px;
    display: flex;

    @include respond-to(sm) {
      padding: 16px;
      flex-direction: column;
    }

    @include respond-to(xs) {
      overflow: hidden;
    }

    /* left */

    &__left {
      padding: 24px;
      margin-bottom: 14px;
      width: 100%;
      max-width: 430px;

      border-radius: 16px;
      box-shadow: 0px 0.408px 1.087px 0px rgba(0, 0, 0, 0.01), 0px 1.127px 3.005px 0px rgba(0, 0, 0, 0.01),
        0px 2.713px 7.235px 0px rgba(0, 0, 0, 0.02), 0px 9px 24px 0px rgba(0, 0, 0, 0.03);

      @include respond-to(lg) {
        max-width: 350px;
      }

      @include respond-to(sm) {
        max-width: none;
        width: 100%;
        padding: 16px;
      }
    }

    /* left => iconEdit */

    &__left-icon-edit {
      svg {
        path {
          transition: all 0.3s ease;
        }
      }

      &:hover {
        svg {
          path {
            fill: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    /* left => name */

    &__left-name-wrap {
      margin: 0 0 16px 0;
      display: grid;
      grid-template-columns: 20px auto 20px;
    }

    &__left-name {
      font-weight: 500;
      font-size: 32px;
      line-height: 1.4;
      padding: 0 6px 0 16px;
    }

    &__left-name-icon {
      transform: translateY(1px);
    }

    /* left => status stage */

    &__left-status-plug {
      height: 40px;
    }

    &__left-status-stage-wrap {
      margin: 0 0 40px 0;
      display: flex;
      align-items: center;
      button {
        margin-left: auto;
      }

      @include respond-to(xs) {
        margin-bottom: 24px;
      }
    }

    &__left-status-stage {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      color: #999;

      &-color {
        width: 16px;
        height: 16px;
        border-radius: 3px;
      }

      &-title {
        color: #333;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__left-name-edit {
      width: 24px;
    }

    /* left => status*/

    &__left-status-wrap {
      margin-bottom: 40px;
      .StatusPlate {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: fit-content;
      }
    }

    &__left-tags {
      margin-bottom: 32px;

      &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }

      .Tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        &--collapsed {
          overflow: hidden;
          height: 50px;
        }

        &--none {
          font-size: 14px;
          line-height: 1.4;
          color: #999;
        }
      }
      .Tags__button {
        margin-left: auto;
        display: block;
        margin-top: 15px;
        color: #696969;
      }

      @include respond-to(sm) {
        margin-bottom: 16px;
      }
    }

    /* left => managers */

    &__left-managers-wrap {
      margin: 0 0 60px 0;
    }

    &__left-managers-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 0 12px 0;
      margin: 0 0 12px 0;

      &:not(&:last-child)::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, #f3f3f3 48.44%, rgba(243, 243, 243, 0) 100%);
      }
    }

    &__left-managers-title {
      font-size: 14px;
      line-height: 1.4;
      color: #999;
    }

    &__left-managers-name {
      margin: 0 10px 0 auto;
      font-size: 14px;
      line-height: 1.4;

      span {
        color: #cecece;
      }
    }

    &__left-managers-edit {
    }

    &__left-managers-bo-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__left-managers-bo-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      margin: 0 10px 0 0;
    }

    &__left-managers-bo-icon {
    }

    /* left => contacts */

    &__left-contacts-wrap {
      margin: 0 0 60px 0;
    }

    &__left-contacts-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__left-contacts-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    &__left-contacts-title-arrow {
      &--show {
        transform: rotate(180deg);
      }
    }

    &__left-contacts-list {
    }

    &__left-contacts-item {
      display: flex;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__left-contacts-item-type {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-contacts-item-contact {
      margin: 0 0 0 auto;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }

    &__left-contacts-item-link {
      display: flex;
      align-items: center;
    }

    &__left-contacts-item-data {
      margin: 0 10px 0 0;
    }

    &__left-contacts-item-img {
      line-height: 0;
    }

    /* left => accounts sum */

    &__left-accounts-sum-wrap {
      margin: 0 0 60px 0;
    }

    &__left-accounts-sum-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__left-accounts-sum-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      display: flex;

      :global(.MoneyCell__inner) {
        display: flex;
        height: auto;
        :global(span) {
          font-size: rem(14px) !important;
        }
      }
    }

    &__left-accounts-sum-title-arrow {
      &--show {
        transform: rotate(180deg);
      }
    }

    &__left-accounts-sum-list {
    }

    &__left-accounts-sum-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__left-accounts-sum-item-type {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-accounts-sum-item-sum {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;

      :global(.MoneyCell__inner) {
        height: auto;
        :global(span) {
          font-size: rem(14px) !important;
        }
      }
    }

    /* left => client info */

    &__left-client-info-wrap {
      margin: 0 0 60px 0;
    }

    &__left-client-info-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__left-client-info-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    &__left-client-info-title-arrow {
      &--show {
        transform: rotate(180deg);
      }
    }

    &__left-client-info-list {
    }

    &__left-client-info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__left-client-info-item-type {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-client-info-item-date {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }

    &__left-hide-btn-wrapper {
      display: none;

      @include respond-to(sm) {
        display: flex;
        justify-content: center;
      }
    }

    &__left-hide-btn {
      width: 14px;
      height: 14px;
      border: 1px solid #cecece;
      border-width: 2px 0 0 2px;
      transform: rotate(45deg);

      &--content-hidden {
        transform: rotate(-135deg);
      }
    }

    /* right */

    &__right {
      padding: 0 24px 25px 20px;
      width: calc(100% - 430px);

      @include respond-to(lg) {
        width: calc(100% - 350px);
      }

      @include respond-to(sm) {
        width: 100%;
        padding: 16px 0;
        padding-left: 0;
      }
    }

    &__right-top-menu {
      position: absolute;
      right: 24px;
      top: 20px;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include respond-to(xl) {
        position: static;
        margin-bottom: 24px;
      }

      @include respond-to(xs) {
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(2, 1fr);

        .ClientPage__right-button-wrap {
          margin-right: 0;
        }

        .BaseButton,
        .ClientPage__right-button-and-select-wrap {
          width: 100%;
        }

        .BaseButton {
          &::after,
          &::before {
            left: 60px;
          }
        }
      }
    }

    &__right-button-wrap {
      margin-right: 16px;
    }

    &__right-button-create-note {
    }

    &__right-button-create-task {
    }

    &__right-button-and-select-wrap {
      position: relative;
    }

    &__right-tabs {
      .Tabs {
        &__list {
          padding-left: 24px;
          flex-wrap: wrap;
          gap: 24px;
          margin-bottom: 16px;

          @include respond-to(md) {
            padding-left: 12px;
            gap: 12px;
          }

          @include respond-to(sm) {
            padding-left: 8px;
            gap: 16px;
          }

          @include respond-to(xs) {
            gap: 12px;
          }
        }

        &__content {
          padding: 24px;

          @include respond-to(xs) {
            padding: 16px 12px;
          }
        }

        &__tab {
          padding: 0;
          margin: 0 !important;
          font-weight: 500;
          font-size: 24px;
          line-height: 1.4;

          @include respond-to(md) {
            font-size: 16px;
          }
        }
      }
    }

    &__right-tabs-feed {
    }

    &__right-tabs-accounts {
    }

    &__right-tabs-in-out {
    }

    &__right-tabs-logs {
    }

    &__right-tabs-calls {
    }

    &__right-tabs-support {
    }
  }
`;
const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ClientPage {
      &__left {
        background: ${colors.white.hex};

        &-name {
          a {
            color: ${colors.black.hex};
          }
        }
      }
      &__left-title {
        color: ${colors.white.hex};
      }
      &__left-contacts-item-data {
        color: ${colors.black.hex};
      }
      &__left-managers-bo-title {
        color: ${colors.primary.hex};
      }
      &__right-button-doc {
        background: ${colors.primary.hex};
      }
      &__right-tabs {
        .Tabs__content {
          background: ${colors.white.hex};
          border-radius: 16px;
          box-shadow: 0px 0.408px 1.087px 0px rgba(0, 0, 0, 0.01), 0px 1.127px 3.005px 0px rgba(0, 0, 0, 0.01),
            0px 2.713px 7.235px 0px rgba(0, 0, 0, 0.02), 0px 9px 24px 0px rgba(0, 0, 0, 0.03);
        }

        .Tabs__tab {
          &::after {
            background: ${colors.primary.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
