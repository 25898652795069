import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChallengeHistoryItems--Left {
    display: flex;
    gap: 8px;
  }

  .ChallengeHistoryItems--Right {
  }

  .ChallengeHistoryItemWrapper .ReloadIcon {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0aa1e2;
  }

  .ChallengeHistoryItemWrapper .ReloadIcon path {
    stroke: #0aa1e2 !important;
    fill: #0aa1e2 !important;
  }

  .ChallengeHistoryItemWrapper .ReloadIcon svg {
    stroke: #0aa1e2 !important;
    fill: #0aa1e2 !important;
  }

  .ChallengeHistoryItemWrapper .CrossIconDiv {
    background: red;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }

  .CrossIconDiv svg {
    stroke: white;
    fill: white !important;
  }

  .CrossIconDiv path {
    stroke: white;
    fill: white !important;
  }

  .ChallengeHistoryItemWrapper {
    display: flex;
    background: white;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    border-radius: 4px;
  }

  .ChallengeHistoryItem {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 10px;
  }

  .ChallengeHistoryItem--Right {
    justify-content: flex-end;
  }

  .ChallengeHistoryItem__Metrics {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  .ChallengeHistoryItem__Metrics-Item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .ChallengeHistoryItem__Metrics-Item > span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export { staticStyles };
