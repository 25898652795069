import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserDepositProofForm {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px 25px);
    margin-bottom: rem(20px);
    @include respond-to(sm) {
      padding: rem(20px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .UserDepositProofForm__block-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    span {
      display: inline-block;
      font-size: 13px;
      width: 45%;
      padding-right: 15px;
      &:nth-child(2) {
        font-size: 15px;
        width: 55%;
      }
    }
    .UserDocument__status {
      span {
        @include respond-to(lg) {
          display: inline-block;
          width: 100%;
          margin: 0;
        }
      }
    }

    :global(.UserDocument__inner) {
      margin: auto !important;
    }
  }

  .UserDocument__buttons-inner {
    span {
      width: 100%;
      padding: 0;
      color: inherit !important;
    }
  }

  .UserOneDocumentForm {
    .UserDepositProofForm__block-text {
      justify-content: center;
    }
    .UserDocument__inner {
      width: 50%;
      padding: 0;

      @include respond-to(lg) {
        width: 100%;
      }
    }
  }

  .UserTwoDocumentsForm {
    .UserDocument__inner {
      width: 49%;
      @include respond-to(lg) {
        width: 100%;
      }
    }
  }

  .UserFourDocumentsForm {
    .UserDocument__inner {
      width: 49%;
      &:nth-child(2) {
        border: none;
      }
      &:nth-child(3),
      &:nth-child(4) {
        padding-top: 20px;
        @include respond-to(lg) {
          padding-top: 0;
        }
      }
      @include respond-to(lg) {
        width: 100%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserDepositProofForm {
      background-color: ${colors.white.hex};
    }

    .UserDepositProofForm__block-text {
      span {
        color: ${colors.gray.hex};
        &:nth-child(2) {
          color: ${colors.black.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
