import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { Collapsible } from 'common/components';
import IbProgramExceptionsTable from '../IbProgramExceptionsTable';
import { staticStyles, getDynamicStyles } from './style';

const CurrentIBProgramsItem = ({ partnerCode, conditions, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const { ibSystem, percentage, spreadPercentage, subIbEnabled, subIbSettings, table } = conditions;

  return (
    <div className="CurrentIBProgramsItem">
      <FormattedMessage id="partnerCode">
        {txt => (
          <p className="CurrentIBProgramsItem__partnerCode">
            {txt}: <span>{partnerCode}</span>
          </p>
        )}
      </FormattedMessage>
      <div className="CurrentIBProgramsItem__body">
        <ul className="CurrentIBProgramsItem__values-list">
          <li>
            <FormattedMessage id="partnerIBProgram" />
            <p className="CurrentIBProgramsItem__value">
              <span>{ibSystem}</span>
            </p>
          </li>
          {(percentage || spreadPercentage) && (
            <li>
              <FormattedMessage id="partnerIBProgramPercentage" />
              <p className="CurrentIBProgramsItem__value">
                <span>{percentage || spreadPercentage}%</span>
              </p>
            </li>
          )}

          {table && (
            <>
              <li>
                <FormattedMessage id="justRewardForVolume" />
                <p className="CurrentIBProgramsItem__value">
                  <span>{table.baseForVolume} USD</span>
                </p>
              </li>
              <li>
                <FormattedMessage id="justRewardForSpread" />
                <p className="CurrentIBProgramsItem__value">
                  <span>{table.baseForSpread}%</span>
                </p>
              </li>
              <li>
                <FormattedMessage id="justRewardForPercentage" />
                <p className="CurrentIBProgramsItem__value">
                  <span>{table.baseForPercentage}%</span>
                </p>
              </li>
            </>
          )}
        </ul>
        {subIbEnabled && (
          <>
            <FormattedMessage id="partnerSubIBProgram">
              {txt => <h3 className="CurrentIBProgramsItem__sub-caption">{txt}</h3>}
            </FormattedMessage>
            <ul className="CurrentIBProgramsItem__values-list">
              {subIbSettings.map((item, index) => (
                <li key={index}>
                  <FormattedMessage id="partnerSubIBProgramPercentage" values={{ levelNumber: index + 1 }} />
                  <p className="CurrentIBProgramsItem__value">
                    <span>{item}%</span>
                  </p>
                </li>
              ))}
            </ul>
          </>
        )}
        {table && (
          <div className="CurrentIBProgramsItem__exceptions">
            <FormattedMessage id="justExceptions">
              {txt => <h3 className="CurrentIBProgramsItem__exceptions-caption">{txt}</h3>}
            </FormattedMessage>
            <Collapsible captionId="perLotGroupExceptions">
              <IbProgramExceptionsTable
                data={Object.keys(table.groupExceptions).map(item => ({ name: item, ...table.groupExceptions[item] }))}
              />
            </Collapsible>
            <Collapsible captionId="perLotSymbolExceptions">
              <IbProgramExceptionsTable
                data={Object.keys(table.symbolExceptions).map(item => ({
                  name: item,
                  ...table.symbolExceptions[item],
                }))}
              />
            </Collapsible>
          </div>
        )}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

CurrentIBProgramsItem.propTypes = {
  partnerCode: PropTypes.string.isRequired,
  conditions: PropTypes.object,
  theme: PropTypes.object,
};

CurrentIBProgramsItem.defaultProps = {
  conditions: {},
  theme: {},
};

export default withTheme()(CurrentIBProgramsItem);
export { CurrentIBProgramsItem };
