import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NewsTable {
    .ReactTable {
      .rt-thead {
        min-width: 700px !important;
        .rt-tr {
          .rt-th {
            &:first-child {
              flex: 10 0 auto !important;
              width: 40px !important;
            }
            &:nth-child(2) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(3) {
              flex: 120 0 auto !important;
              width: 120px !important;
              padding-left: 10px;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(4) {
              flex: 35 0 auto !important;
              width: 35px !important;
              text-align: center;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(5) {
              flex: 70 0 auto !important;
              width: 70px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(6) {
              flex: 40 0 auto !important;
              width: 40px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
          }
        }
      }
      .rt-tbody {
        min-width: 700px !important;
        .rt-tr-group {
          margin-bottom: 1px;
          &:last-child {
            margin-bottom: rem(15px);
          }
          .rt-tr {
            .rt-td {
              p,
              span {
                font-size: rem(15px);
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:first-child {
                flex: 10 0 auto !important;
                width: 40px !important;
              }
              &:nth-child(2) {
                flex: 80 0 auto !important;
                width: 80px !important;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(3) {
                flex: 120 0 auto !important;
                width: 120px !important;
                padding-left: 10px;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                .body {
                  height: 20px;
                  margin-right: rem(15px);
                  position: relative;
                  @include respond-to(sm) {
                    height: 18px;
                  }
                  * {
                    padding: 0 !important;
                    margin: 0 !important;
                    border: none !important;
                    text-align: left !important;
                    font-size: rem(15px) !important;
                    line-height: 1.35 !important;
                    font-weight: 400 !important;
                  }
                  img {
                    position: absolute;
                    height: 100%;
                    width: auto;
                  }
                  table {
                    position: relative;
                    bottom: rem(2px);
                  }
                  a {
                    pointer-events: none !important;
                  }
                }
              }
              &:nth-child(4) {
                flex: 35 0 auto !important;
                width: 35px !important;
                text-align: center;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                }
              }
              &:nth-child(5) {
                flex: 70 0 auto !important;
                width: 70px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                  padding-left: rem(5px);
                  margin-top: rem(1px);
                }
              }
              &:nth-child(6) {
                flex: 40 0 auto !important;
                width: 40px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
            }
          }
        }
      }
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        flex: 50;
        text-align: center;
        padding: 5px 0;

        &:first-child {
          padding-left: rem(20px);
          flex: 1;
        }

        &:nth-child(3) {
          flex: 20;
        }

        &:nth-child(4) {
          flex: 30;
        }

        &:last-child {
          min-width: 60px;
          padding-right: rem(30px);
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;

      .ResponsiveTable__row-item {
        flex: 50;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          padding-left: rem(20px);
          flex: 1;
        }

        &:nth-child(3) {
          flex: 20;

          span {
            width: 13px;
            height: 13px;
            border-radius: 50%;
          }
        }

        &:nth-child(4) {
          flex: 30;
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 15%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .NewsTable {
      .ReactTable {
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &:nth-child(4) {
                  span {
                    background: ${colors.gray.hex};
                    &.published {
                      background: ${colors.success.hex};
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ResponsiveTable__row-item {
        &:nth-child(3) {
          flex: 20;
          span {
            background: ${colors.gray.hex};
            &.published {
              background: ${colors.success.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
