/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NoItemsPanel } from 'common/components';
import { compose } from 'redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import noPaymentsImage from 'common/images/noPayments.svg';
import ArrowsDown from './icons/ArrowsDown.svg';
import ArrowsUp from './icons/ArrowsUp.svg';
import NoSortArrows from './icons/NoSortArrows.svg';

import { staticStyles, getDynamicStyles } from './style';

const SortIcon = ({ currentOrdering, accessor }) => {
  if (currentOrdering === `-${accessor}`) {
    return <img src={ArrowsDown} alt="SortDown" />;
  }
  if (currentOrdering === accessor) {
    return <img src={ArrowsUp} alt="SortUp" />;
  }

  return <img src={NoSortArrows} alt="NoSort" />;
};

const getManagerReactionClassAfterfix = managerReaction => {
  if (managerReaction === 'Not Success') {
    return 'notSuccess';
  }

  if (managerReaction === 'Success') {
    return 'success';
  }

  if (managerReaction === 'Bad Client') {
    return 'badClient';
  }
  return '';
};

const NewCRMTable = ({
  onMouseLeave,
  setTableWidth,
  columns,
  data,
  hasFooter,
  placeholderTextId,
  renderRowSubComponent,
  onRowClick,
  theme,
  history,
  location,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme, columns) : ` `;

  const bodyRef = useRef(null);
  const cellRef = useRef(null);

  useEffect(() => {
    if (setTableWidth && bodyRef?.current && cellRef?.current) {
      setTableWidth({ tableWidth: bodyRef.current.offsetWidth - 55, cellWidth: cellRef.current.offsetWidth });
    }
  }, [setTableWidth, bodyRef?.current, cellRef?.current]);

  const query = useMemo(() => ({ pageSize: 10, ...queryString.parse(location.search) }), [location.search]);

  const currentOrdering = useMemo(() => query.ordering, [query.ordering]);

  const handleColumnSortChange = columnAccessor => {
    const currentOrdering = query.ordering;
    let newOrdering = null;

    if (currentOrdering !== `-${columnAccessor}` && currentOrdering !== columnAccessor) {
      newOrdering = `-${columnAccessor}`;
    }

    if (currentOrdering === `-${columnAccessor}`) {
      newOrdering = columnAccessor;
    }

    history.replace({
      search: queryString.stringify({
        ...query,
        ordering: newOrdering,
      }),
    });
  };

  if (!data.length) {
    return (
      <NoItemsPanel
        icon={noPaymentsImage}
        containerMaxHeight
        captionSlug={placeholderTextId || 'crmThereAreNoItemsMatchingParameters'}
      />
    );
  }

  return (
    <div className="NewCRMTable__wrapper">
      <table className="NewCRMTable">
        <thead className="NewCRMTable__Head">
          <tr className="NewCRMTable__Head-Row">
            {columns.map(({ Header, accessor, isSortable, blueBg }, ind) => (
              <th
                key={`NewCRMTable-header-${ind}-${accessor}`}
                className={classNames('NewCRMTable__Cell NewCRMTable__Cell--Thead', {
                  'NewCRMTable__Cell--Sortable': isSortable,
                  'NewCRMTable__Cell--BlueBg': blueBg,
                })}
                onClick={() => {
                  if (isSortable) {
                    handleColumnSortChange(accessor);
                  }
                }}>
                <div className="NewCRMTable__Cell-Inner">
                  {typeof Header === 'string' ? <FM id={Header} /> : Header()}
                  {isSortable && <SortIcon currentOrdering={currentOrdering} accessor={accessor} />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="NewCRMTable__Body" ref={bodyRef} onMouseLeave={onMouseLeave}>
          {data.map(rowData => {
            if (rowData.isExpanded) {
              return (
                <>
                  <tr
                    className={`NewCRMTable__Body-Row NewCRMTable__Body-Row--${getManagerReactionClassAfterfix(
                      rowData.managerReaction || rowData.lastTask?.managerReaction
                    )} NewCRMTable__Body-Row--${rowData.color}`}
                    key={`Contact-${rowData.id}-info`}
                    onClick={e => {
                      e.stopPropagation();
                      onRowClick({ rowData });
                    }}>
                    {columns.map(({ Cell, accessor }, ind) => (
                      <td
                        className="NewCRMTable__Cell NewCRMTable__Cell--Tbody"
                        key={`NewCRMTable-body-${accessor}-${ind}`}>
                        {rowData && Cell({ rowData, isExpanded: rowData.isExpanded })}
                      </td>
                    ))}
                  </tr>
                  {renderRowSubComponent({ rowData })}
                </>
              );
            }
            return (
              <tr
                onClick={e => {
                  e.stopPropagation();
                  onRowClick({ rowData });
                }}
                className={`NewCRMTable__Body-Row NewCRMTable__Body-Row--${getManagerReactionClassAfterfix(
                  rowData.managerReaction || rowData.lastTask?.managerReaction
                )} NewCRMTable__Body-Row--${rowData.color}`}
                key={`Contact-${rowData.id}-info`}>
                {columns.map(({ Cell, accessor, overflowText }, ind) =>
                  ind === 1 ? (
                    <td
                      ref={cellRef}
                      className="NewCRMTable__Cell NewCRMTable__Cell--Tbody"
                      key={`NewCRMTable-body-${accessor}-${ind}`}>
                      {overflowText ? (
                        <div className="NewCRMTable__Inner-Cell-OverflowText">{rowData && Cell({ rowData })}</div>
                      ) : (
                        rowData && Cell({ rowData })
                      )}
                    </td>
                  ) : (
                    <td
                      className="NewCRMTable__Cell NewCRMTable__Cell--Tbody"
                      key={`NewCRMTable-body-${accessor}-${ind}`}>
                      {overflowText ? (
                        <div className="NewCRMTable__Inner-Cell-OverflowText">{rowData && Cell({ rowData })}</div>
                      ) : accessor === 'selectCheckBox' ? (
                        <div className="NewCRMTable__Cell-Inner">{rowData && Cell({ rowData })}</div>
                      ) : (
                        rowData && Cell({ rowData })
                      )}
                    </td>
                  )
                )}
              </tr>
            );
          })}

          {hasFooter && (
            <tr
              onClick={e => {
                e.stopPropagation();
              }}
              className="NewCRMTable__Body-Row"
              key="Contact-Footer-info">
              {columns.map(({ Footer, accessor }, ind) => (
                <td
                  className="NewCRMTable__Cell NewCRMTable__Cell--Tbody"
                  key={`NewCRMTable-body-${accessor}-${ind}-footer`}>
                  {Footer && Footer()}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

NewCRMTable.propTypes = {
  setTableWidth: PropTypes.func,
  onMouseLeave: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      key: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  hasFooter: PropTypes.bool,
  placeholderTextId: PropTypes.string,
  renderRowSubComponent: PropTypes.func,
  onRowClick: PropTypes.func,
  theme: PropTypes.object,
};

NewCRMTable.defaultProps = {
  onMouseLeave: () => {},
  setTableWidth: () => {},
  renderRowSubComponent: null,
  placeholderTextId: null,
  onRowClick: () => {},
  hasFooter: false,
  data: [],
  theme: {},
};

export default compose(withTheme(), withRouter)(NewCRMTable);
export { NewCRMTable };
