import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChallengesHistoryBlock__body {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .ChallengesHistoryBlock {
    margin-bottom: 45px;
  }

  .ChallengesHistoryBlock__head {
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    h3 {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      padding-right: 25px;
      @include respond-to(xs) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .ChallengesHistoryBlock__body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .ChallengesHistoryBlock__head-right-buttons {
    display: flex;
    gap: 16px;
  }
`;

export { staticStyles };
