import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { PageContentPreloader, PageLayout } from 'common/components';
import { StrategyInfoPanel } from 'client/invest/_components';

import YieldChart from '../YieldChart';

import { staticStyles, getDynamicStyles } from './style';

const InvestDetailPage = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();
  const match = useRouteMatch();
  const [investStrategyDetail, setInvestStrategyDetail] = useState({});
  const [investStrategyDetailIsLoaded, setInvestStrategyDetailIsLoaded] = useState(false);
  const {
    params: { strategyId },
  } = match;

  const [chartTab, setChartTab] = useState('yield');

  useEffect(() => {
    if (strategyId && !investStrategyDetailIsLoaded) {
      axios.get(`/invest/strategies/${strategyId}/`).then(({ data }) => {
        setInvestStrategyDetail(data);
        setInvestStrategyDetailIsLoaded(true);
      });
    }
  }, [strategyId, investStrategyDetailIsLoaded]);

  const handleBackToClick = () => {
    history.push(`/widgets/invest/`);
  };

  console.log({ chartTab, investStrategyDetail });

  return (
    <PageLayout caption={investStrategyDetailIsLoaded ? investStrategyDetail.title : '...'} showReload={false}>
      <PageContentPreloader ready={investStrategyDetailIsLoaded} type="bigLine">
        {/* eslint-disable */}
        <FormattedMessage id="backToRating">
          {txt => (
            <div className="InvestDetailPage__back-to" onClick={handleBackToClick}>
              {txt}
            </div>
          )}
        </FormattedMessage>
        {/* eslint-enable */}
        <div className="InvestDetailPage__chart-tabs">
          <FormattedMessage id="justYield">
            {txt => (
              <button
                type="button"
                className={classNames('InvestDetailPage__tab', {
                  active: chartTab === 'yield',
                })}
                onClick={() => setChartTab('yield')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        <StrategyInfoPanel strategyDetail={investStrategyDetail} />

        {chartTab === 'yield' && <YieldChart strategyId={investStrategyDetail.id} />}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

InvestDetailPage.propTypes = {
  theme: PropTypes.object,
};

InvestDetailPage.defaultProps = {
  theme: {},
};

export default withTheme()(InvestDetailPage);

export { InvestDetailPage };
