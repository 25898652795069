import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { withTheme } from 'common/styling/theme';
import { TaskIcon } from 'backoffice/managers/_components/TaskIcon';

import { staticStyles, getDynamicStyles } from './style';

const TaskItem = ({ task, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/backoffice/tasks/${task.id}`);
  };

  return (
    <button className="TaskItem" type="button" onClick={() => handleClick()}>
      <div className="TaskItem__icon">
        <TaskIcon type={task.icon} />
      </div>
      <div className="TaskItem__content">
        {!!task.subject && (
          <div className="TaskItem__subject">{`${task.subject.firstName} ${task.subject.lastName}`}</div>
        )}
        <div className="TaskItem__text">{task.title}</div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </button>
  );
};

TaskItem.propTypes = {
  task: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

TaskItem.defaultProps = {
  theme: {},
};

export default withTheme()(TaskItem);
export { TaskItem };
