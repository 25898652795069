import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const BaseButton = ({ textId, onClick, type, plus, color, className, maxContent, noBorder, fill, theme, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <button // eslint-disable-line
      type={type}
      className={classNames(
        'BaseButton',
        { plus, maxContent, fill, noBorder },
        color && `BaseButton__${color}`,
        `${className}`
      )}
      onClick={onClick}
      {...props}>
      <FormattedMessage id={textId}>{txt => <span className="BaseButton__text">{txt}</span>}</FormattedMessage>

      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </button>
  );
};

BaseButton.propTypes = {
  textId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  plus: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.string,
  fill: PropTypes.bool,
  noBorder: PropTypes.bool,
  className: PropTypes.string,
  maxContent: PropTypes.bool,
  theme: PropTypes.object,
};

BaseButton.defaultProps = {
  onClick: () => {},
  plus: false,
  color: '',
  type: 'button',
  noBorder: false,
  className: '',
  maxContent: false,
  fill: false,
  theme: {},
};

export default withTheme()(BaseButton);
