import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DefaultButton, BasicModal } from 'common/components';

import { CreateInvestStrategyForm } from 'client/accounts/_components';
import InvestInvesments from '../InvestInvesments';
import InvestStrategies from '../InvestStrategies';

import { staticStyles } from './style';

const InvestBlock = () => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleAddStrategy = () => {
    toggleModal();
  };

  const handleShowRating = () => {
    history.push('/invest/');
  };
  const investAccountsNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'investAccountsTixee'
      : 'investAccounts';

  return (
    <div className="InvestBlock">
      <div className="InvestBlock__head">
        <FormattedMessage id={investAccountsNew}>{txt => <h3>{txt}</h3>}</FormattedMessage>

        <div className="InvestBlock__btns">
          <DefaultButton textId="rammShowRating" onClick={handleShowRating} filled />
          <DefaultButton textId="rammBecomeAManager" onClick={handleAddStrategy} plus />
        </div>
      </div>

      <div className="InvestBlock__body">
        <InvestInvesments />
        <InvestStrategies />
        <BasicModal isOpen={isOpen} captionId="justCreateStrategy" onRequestClose={toggleModal}>
          <CreateInvestStrategyForm toggleModal={toggleModal} />
        </BasicModal>
      </div>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default InvestBlock;
