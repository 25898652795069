import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  // error handler
  hasErrors: false,
  errorMessage: '',
  redirect: null,

  // partner reports
  reportTemplates: [],
  reportsTemplatesAreLoaded: false,

  periodicalReports: [],
  periodicalReportsAreLoaded: false,

  savedReports: [],
  savedReportsAreLoaded: false,

  reportData: [],
  reportDataIsLoaded: false,

  userExportData: {},
  userExportDataIsLoaded: false,

  userCreateExportDataIsLoading: false,
  userCreateExportDataError: false,
  isExportRedirect: false,

  savedReport: '',
  savedReportIsLoaded: false,

  error: '',

  investNewCreateExportDataIsLoading: false,
  investNewCreateExportDataError: false,
  // isExportRedirect: false,

  investNewExportsData: {},
  investNewExportsDataIsLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // error handler
    case ActionTypes.ERROR_ACCEPT: {
      if (action.error) {
        return { ...state, hasErrors: true, errorMessage: action.error, redirect: action.redirect };
      }
      if (action.critical) {
        return { ...state, hasErrors: true, redirect: action.redirect };
      }

      return state;
    }
    case ActionTypes.ERROR_REJECT:
      return { ...initialState };

    // partner reports
    case ActionTypes.GET_REPORT_TEMPLATES[REQUEST]:
      return { ...state, reportsTemplatesAreLoaded: false };
    case ActionTypes.GET_REPORT_TEMPLATES[SUCCESS]:
      return { ...state, reportTemplates: action.data, reportsTemplatesAreLoaded: true };
    case ActionTypes.GET_REPORT_TEMPLATES[FAILURE]:
      return { ...state, error: action.error, reportsTemplatesAreLoaded: false };

    case ActionTypes.EXECUTE_REPORT_DATA[REQUEST]:
      return { ...state, reportData: [], reportDataIsLoaded: false };
    case ActionTypes.EXECUTE_REPORT_DATA[SUCCESS]:
      return { ...state, reportData: action.data, reportDataIsLoaded: true };
    case ActionTypes.EXECUTE_REPORT_DATA[FAILURE]:
      return { ...state, error: action.error, reportDataIsLoaded: false };

    case ActionTypes.GET_PERIODICAL_REPORT_LIST[REQUEST]:
      return { ...state, periodicalReportsAreLoaded: false };
    case ActionTypes.GET_PERIODICAL_REPORT_LIST[SUCCESS]:
      return { ...state, periodicalReports: action.data, periodicalReportsAreLoaded: true };
    case ActionTypes.GET_PERIODICAL_REPORT_LIST[FAILURE]:
      return { ...state, error: action.error, periodicalReportsAreLoaded: false };

    case ActionTypes.GET_SAVED_REPORTS[REQUEST]:
      return { ...state, savedReportsAreLoaded: false };
    case ActionTypes.GET_SAVED_REPORTS[SUCCESS]:
      return { ...state, savedReports: action.data, savedReportsAreLoaded: true };
    case ActionTypes.GET_SAVED_REPORTS[FAILURE]:
      return { ...state, error: action.error, savedReportsAreLoaded: false };

    case ActionTypes.RETRIEVE_AND_SHOW_SAVED_REPORT[REQUEST]:
      return { ...state, savedReportIsLoaded: false };
    case ActionTypes.RETRIEVE_AND_SHOW_SAVED_REPORT[SUCCESS]:
      return { ...state, savedReport: action.data, savedReportIsLoaded: true };
    case ActionTypes.RETRIEVE_AND_SHOW_SAVED_REPORT[FAILURE]:
      return { ...state, error: action.error, savedReportIsLoaded: false };

    case ActionTypes.GET_BO_USER_EXPORTS[REQUEST]:
      return { ...state, userExportDataIsLoaded: false, isExportRedirect: false };
    case ActionTypes.GET_BO_USER_EXPORTS[SUCCESS]:
      return { ...state, userExportData: action.data, userExportDataIsLoaded: true };
    case ActionTypes.GET_BO_USER_EXPORTS[FAILURE]:
      return { ...state, error: action.error, userExportDataIsLoaded: false };

    case ActionTypes.CREATE_BO_USER_EXPORTS[REQUEST]:
      return {
        ...state,
        userCreateExportDataIsLoading: true,
        isExportRedirect: false,
      };
    case ActionTypes.CREATE_BO_USER_EXPORTS[SUCCESS]:
      return {
        ...state,
        userCreateExportDataIsLoading: false,
        isExportRedirect: true,
      };
    case ActionTypes.CREATE_BO_USER_EXPORTS[FAILURE]:
      return {
        ...state,
        error: action.error,
        userCreateExportDataIsLoading: false,
        isExportRedirect: false,
      };

    case ActionTypes.SET_CREATE_ERROR:
      return {
        ...state,
        userCreateExportDataError: true,
      };

    case ActionTypes.CLEAR_CREATE_ERROR:
      return {
        ...state,
        userCreateExportDataError: false,
      };

    // InvestNewExports

    case ActionTypes.CREATE_INVEST_NEW_EXPORTS[REQUEST]:
      return {
        ...state,
        investNewCreateExportDataIsLoading: true,
        // isExportRedirect: false,
      };
    case ActionTypes.CREATE_INVEST_NEW_EXPORTS[SUCCESS]:
      return {
        ...state,
        investNewCreateExportDataIsLoading: false,
        // isExportRedirect: true,
      };
    case ActionTypes.CREATE_INVEST_NEW_EXPORTS[FAILURE]:
      return {
        ...state,
        error: action.error,
        investNewCreateExportDataIsLoading: false,
        // isExportRedirect: false,
      };

    case ActionTypes.GET_INVEST_NEW_EXPORTS[REQUEST]:
      return { ...state, investNewExportsDataIsLoaded: false, isExportRedirect: false };
    case ActionTypes.GET_INVEST_NEW_EXPORTS[SUCCESS]:
      return { ...state, investNewExportsData: action.data, investNewExportsDataIsLoaded: true };
    case ActionTypes.GET_INVEST_NEW_EXPORTS[FAILURE]:
      return { ...state, error: action.error, investNewExportsDataIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
