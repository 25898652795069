import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { compose } from 'redux';

import { PageLayout, NoItemsPanel, PageContentPreloader, DefaultButton } from 'common/components';

import noPaymentsImage from 'common/images/noPayments.svg';
import TemplatesTable from '../../_components/TemplatesTable';

import { getTemplateList, deleteTemplate } from '../../_redux';

import { staticStyles } from './style';

const TemplatesPage = ({ templates, templatesAreLoaded, getTemplateList, deleteTemplate, profile }) => {
  const location = useLocation();
  const history = useHistory();
  const query = { page_size: 10, ...queryString.parse(location.search) };

  /* eslint-disable */
  useEffect(() => {
    getTemplateList({ search: query });
  }, [getTemplateList]);
  /* eslint-enable */

  return (
    <PageLayout captionSlug="justCommentTemplates">
      {profile.perms.templateMessagesCreatePerm && (
        <div className="TemplatesPage__header">
          <DefaultButton
            textId="justAddTemplate"
            plus
            filled
            onClick={() => history.push('/backoffice/templates/create/')}
          />
        </div>
      )}
      <PageContentPreloader ready={templatesAreLoaded} type="smallLines" firstLaunchOnly>
        {templates.count ? (
          <TemplatesTable
            data={templates}
            loading={!templatesAreLoaded}
            location={location}
            history={history}
            getList={getTemplateList}
            onRowClick={() => {}}
            handleDelete={deleteTemplate}
            params={{ search: query }}
            profile={profile}
          />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noData" />
        )}
      </PageContentPreloader>

      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

TemplatesPage.propTypes = {
  templates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  templatesAreLoaded: PropTypes.bool.isRequired,
  getTemplateList: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      templates: state.backoffice.templates.templates,
      templatesAreLoaded: state.backoffice.templates.templatesAreLoaded,
      profile: state.user.profile,
    }),
    {
      getTemplateList: search => getTemplateList.request(search),
      deleteTemplate: data => deleteTemplate.request(data),
    }
  )
)(TemplatesPage);
