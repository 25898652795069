import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import next from './next.svg';
import prev from './prev.svg';

import { staticStyles, getDynamicStyles } from './style';

const NewCRMPagination = ({ count = 40, history, location, fixedPageSize, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const search = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const [currentPage, setCurrentPage] = useState(search.page || 1);

  const pageSize = useMemo(() => Number(search.page_size), [search.page_size]);

  const pagesCount = useMemo(() => Math.ceil(count / pageSize), [count, pageSize]);

  const currentPageInputLength = useMemo(() => {
    const pagesCountLength = pagesCount.toString().length;
    if (pagesCountLength < 3) {
      return '33px';
    }
    return `${pagesCountLength * 10}px`;
  }, [pagesCount]);

  useEffect(() => {
    if (search.page) {
      setCurrentPage(search.page);
    }
  }, [search.page]);

  const handleCurrentPage = newParam => {
    if (typeof +newParam === 'number' && newParam >= 1 && newParam <= pagesCount) {
      history.replace({
        ...location,
        search: queryString.stringify({
          ...search,
          page: newParam,
          page_size: fixedPageSize || pageSize,
        }),
      });
    } else {
      setCurrentPage(1);
    }
  };

  const handleChangePageSize = newParam => {
    history.replace({
      ...location,
      search: queryString.stringify({
        ...search,
        page_size: newParam,
      }),
    });
  };

  const handleCurrentPageInputChange = ({ target: { value } }) => {
    setCurrentPage(value);
  };

  return (
    <div className={classNames('NewCRMPagination')}>
      {pagesCount !== 0 && (
        <div className="NewCRMPagination__left">
          {/* prev button */}
          <button
            type="button"
            className="NewCRMPagination__left-prev"
            onClick={() => handleCurrentPage(Number(currentPage) - 1)}
            disabled={currentPage <= 1}>
            <img src={prev} alt="prev" />
          </button>

          {/* input page */}
          <div className="NewCRMPagination__left-pages">
            <input
              className="NewCRMPagination__left-pages-number"
              type="number"
              value={currentPage}
              style={{ width: currentPageInputLength }}
              onChange={e => handleCurrentPageInputChange(e)}
              onKeyUp={e => {
                if (e.key === 'Enter') handleCurrentPage(currentPage);
              }}
              onClick={e => e.target.select()}
            />
            <div className="NewCRMPagination__left-pages-count" style={{ width: currentPageInputLength }}>
              {pagesCount}
            </div>
          </div>

          <button
            type="button"
            className="NewCRMPagination__left-next"
            onClick={() => handleCurrentPage(Number(currentPage) + 1)}
            disabled={currentPage >= pagesCount}>
            <img src={next} alt="next" />
          </button>
        </div>
      )}

      {!fixedPageSize && (
        <div className="NewCRMPagination__right">
          {[10, 50, 100].map(size => (
            <button
              type="button"
              className={classNames('NewCRMPagination__right-count', {
                'NewCRMPagination__right-count--active': pageSize === size,
              })}
              onClick={() => handleChangePageSize(size)}
              key={`NewCRMPagination-size-${size}`}>
              {size}
            </button>
          ))}
        </div>
      )}
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

NewCRMPagination.propTypes = {
  count: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  theme: PropTypes.object,
};

NewCRMPagination.defaultProps = { theme: {} };

export default compose(withRouter, withTheme())(NewCRMPagination);
