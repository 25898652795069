import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  @mixin afterLine {
    content: '';
    height: 2px;
    position: absolute;
    bottom: -2px;
    z-index: 2;
    transition: 0.3s all ease;
  }

  .PanelTabs {
    width: 100%;
    margin-bottom: rem(25px);

    ul {
      @include respond-to(sm) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
      }

      li {
        display: inline-block;
        margin: rem(0 65px 20px 0);

        @include respond-to(sm) {
          text-align: center;
          margin: rem(0 25px 15px 0);
        }

        &.PanelTab__active {
          .PanelTabs__button {
            cursor: default;
          }
        }

        &.PanelTab__disabled {
          .PanelTabs__button {
            cursor: not-allowed;
          }
        }
      }
    }

    .PanelTabs__button {
      font-weight: 500;
      font-size: rem(16px);
      line-height: rem(19px);
      padding: rem(0 0 10px 0);
      text-align: center;
      position: relative;

      @include respond-to(sm) {
        font-size: 14px;
        line-height: 18px;
        padding: 0 5px 10px;
      }

      &:after {
        @include afterLine;
        left: 100%;
        right: 0;
      }

      &:hover {
        &:after {
          left: 0;
          right: 100%;
        }
      }
    }

    .PanelTabs__content {
      .BigCharts__tabs {
        .BigCharts__tabsItem-index {
          min-width: 22.5px;
        }
      }

      .BigCharts__account-types-list {
        .BigCharts__accountTypesItem {
          @include respond-to(sm) {
            min-width: 150px;
            margin: 0 0 0 15px;
          }

          @include respond-to(xs) {
            min-width: 0;
          }
        }
      }
    }
  }

  .PanelTabs .PanelTabs__button::after {
    left: 0;
    right: 100%;
  }

  .PanelTabs .PanelTab__active .PanelTabs__button::after {
    left: 100%;
    right: 0;
  }

  .PanelTabs .PanelTabs__button:hover ~ .PanelTabs__button::after {
    left: 0;
    right: 100%;
  }

  .PanelTabs .PanelTabs__button:hover::after,
  .PanelTabs .PanelTab__active .PanelTabs__button::after {
    left: 0;
    right: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .PanelTabs {
      .PanelTabs__button {
        transition: none;
        &:after {
          transition: none;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PanelTabs {
      ul {
        li {
          &.PanelTab__active {
            .PanelTabs__button {
              color: ${colors.primary.hex};
            }
          }
          &.PanelTab__disabled {
            .PanelTabs__button {
              color: ${colors.gray.hex};
              opacity: 0.7;

              &:hover {
                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
      .PanelTabs__button {
        color: ${colors.black.hex};
        &:hover {
          color: ${colors.primary.hex};
        }
        &:after {
          background: ${gradient(73.29, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
