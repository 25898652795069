import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatementCard {
    position: relative;

    .StatementCard__caption {
      margin-bottom: rem(20px);
      font-size: rem(20px);
    }

    .StatementCard__list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .StatementCard__cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 200px;
      margin-bottom: rem(10px);
    }

    .StatementCard__cell-caption {
      font-size: rem(13px);
    }

    .StatementCard__cell-value {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .StatementCard {
      background-color: ${colors.white.hex};

      .StatementCard__cell-caption {
        color: ${colors.gray.hex};
      }

      :global(.StatementCard__cell-value--neg) {
        color: ${colors.error.hex};
      }

      :global(.StatementCard__cell-value--pos) {
        color: ${colors.success.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
