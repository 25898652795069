import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const BasicModal = ({
  isOpen,
  caption,
  captionId,
  captionValues,
  description,
  descriptionId,
  onRequestClose,
  children,
  error,
  overlayClassName,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const hasVScroll = document.body.scrollHeight > document.body.clientHeight;
  const isDeviceTouch = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (!hasVScroll) {
    document.body.classList.add('Body__no-scroll');
  } else {
    document.body.classList.remove('Body__no-scroll');
  }

  if (isDeviceTouch()) {
    document.body.classList.add('Body__touch-screen');
  } else {
    document.body.classList.remove('Body__touch-screen');
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} overlayClassName={overlayClassName}>
      <button className="ReactModal__close BasicModal__close" type="button" onClick={onRequestClose}>
        <div>
          <span />
          <span />
        </div>
      </button>
      <div
        className={classNames('ReactModal__inner BasicModal__inner', {
          'BasicModal__inner-error': error,
        })}>
        <h2>{caption || <FormattedMessage id={captionId} values={captionValues} />}</h2>
        <p>{description || (descriptionId && <FormattedMessage id={descriptionId} />)}</p>
        {children}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </Modal>
  );
};

BasicModal.propTypes = {
  isOpen: PropTypes.bool,
  caption: PropTypes.string,
  captionId: PropTypes.string,
  captionValues: PropTypes.object,
  description: PropTypes.string,
  descriptionId: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  error: PropTypes.bool,
  overlayClassName: PropTypes.string,
  theme: PropTypes.object,
};

BasicModal.defaultProps = {
  isOpen: false,
  caption: '',
  captionId: '',
  captionValues: {},
  description: '',
  descriptionId: '',
  children: false,
  error: false,
  overlayClassName: '',
  theme: {},
};

export default withTheme()(BasicModal);
export { BasicModal };
