import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestmentInfoPanel {
    margin-top: rem(30px);

    :global(.InfoPanel) {
      &:after {
        display: none;
      }
    }
  }
`;

export { staticStyles };
