import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames';

import Tooltip from '../Tooltip';

import ValueLoader from '../ValueLoader';

import { staticStyles } from './style';

export const MoneyCell = ({
  value,
  maxDigitsAfterDecimal,
  inTable,
  className,
  tooltipId,
  crmStyle,
  heightMaxContent,
  success,
  danger,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      'MoneyCell__inner',
      { inTable, heightMaxContent, crmStyle, success, danger },
      `${className}`
    )}>
    <Tooltip id={tooltipId} textId={tooltipId && value && `${value.amount} ${value.currency}`} noTranslate>
      <FormattedNumber value={value && value.amount} maximumFractionDigits={maxDigitsAfterDecimal} />
      <span className="currency">{value && value.currency}</span>
    </Tooltip>
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

MoneyCell.propTypes = {
  value: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
  }),
  maxDigitsAfterDecimal: PropTypes.number,
  tooltipId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  inTable: PropTypes.bool,
  className: PropTypes.string,
  heightMaxContent: PropTypes.bool,
};

MoneyCell.defaultProps = {
  value: {
    amount: '',
    currency: '',
  },
  maxDigitsAfterDecimal: 8,
  tooltipId: null,
  inTable: false,
  className: '',
  heightMaxContent: false,
};

const WrappedMoneyCell = ValueLoader(MoneyCell);
WrappedMoneyCell.propTypes = MoneyCell.propTypes;

export default WrappedMoneyCell;
