import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WithdrawApprovalListTable {
    :global(.ReactDataTable) {
      tr {
        grid-template-columns: repeat(3, minmax(100px, 2fr)) repeat(3, minmax(100px, 1fr)) minmax(20px, 0.5fr);

        .ReactDataTable__tbody-cell {
          .time {
            display: block;
          }
        }
      }
    }

    .ResponsiveTable__thead {
      min-width: 400px;

      span {
        &:nth-of-type(2) {
          @include respond-to(sm) {
            text-align: left;
            padding: 5px 0 5px 15px;
          }
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 400px !important;
    }

    .ResponsiveTable__row-item {
      &:nth-of-type(2) {
        @include respond-to(sm) {
          overflow: hidden;
          justify-content: flex-start !important;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .WithdrawApprovalListTable {
      :global(.ReactDataTable) {
        tr {
          .ReactDataTable__tbody-cell {
            .time {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
