import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { ActionsButton, DefaultButton } from '../../index';

import CustomTable from '../CustomTable';
import { staticStyles, getDynamicStyles } from './style';

const pageSizeDelimiters = [5, 10, 20, 25, 50, 100];

const NewPaginationTable = ({ data, columns, params, theme, onRowClick, loading, renderRowSubComponent, ...props }) => {
  const { history, location, getList } = props;
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const [currentPage, setCurrentPage] = useState(params.search.page || 1);
  const [pageSize, setPageSize] = useState(params.search.page_size || 10);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isLoading, setIsLoading] = useState(loading);
  const tableData = useMemo(() => data.results, [data.results]);
  const pagesCount = useMemo(() => Math.ceil(data.count / pageSize), [data.count, pageSize]);

  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    if (pagesCount === 0) {
      setCurrentPage(0);
    } else if (pagesCount !== 0 && currentPage <= 0) {
      setCurrentPage(1);
    }
  }, [setCurrentPage, pagesCount, currentPage]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (pagesCount === 0) {
      setCurrentPage(0);
    } else if (pagesCount !== 0 && currentPage <= 0) {
      setCurrentPage(1);
    }
  }, [setCurrentPage, pagesCount, currentPage]);

  const handleChange = (queryName, pageParam) => {
    history.replace({
      ...location,
      search: queryString.stringify({
        ...params.search,
        [queryName]: pageParam,
      }),
    });
    getList({
      search: { page_size: pageSize, ...params.search, [queryName]: pageParam },
    });
    if (queryName === 'page') {
      setCurrentPage(pageParam);
    } else if (queryName === 'page_size') {
      setCurrentPage(1);
      setPageSize(pageParam);
    }
  };

  const handleInputChange = ({ target }) => {
    if (target.value === '' || (target.value >= 1 && target.value <= pagesCount)) {
      setCurrentPage(target.value);
    }
  };

  // If page is too high -> show last page
  if (params.search.page > pagesCount) {
    props.history.replace({
      ...props.location,
      search: queryString.stringify({ ...params.search, page: pagesCount }),
    });
  }

  const handleColumnSortChange = columnAccessor => {
    const currentOrdering = query.ordering;
    let newOrdering = null;

    if (currentOrdering !== `-${columnAccessor}` && currentOrdering !== columnAccessor) {
      newOrdering = `-${columnAccessor}`;
    }

    if (currentOrdering === `-${columnAccessor}`) {
      newOrdering = columnAccessor;
    }

    history.replace({
      search: queryString.stringify({
        ...query,
        ordering: newOrdering,
      }),
    });

    const { ordering, ...paramsSearch } = params.search;

    getList({
      search: {
        ...paramsSearch,
        page: 1,
        page_size: pageSize,
        ...(newOrdering ? { ordering: newOrdering } : {}),
      },
    });
  };

  return (
    <div className="NewPaginationTable">
      <CustomTable
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        renderRowSubComponent={renderRowSubComponent}
        handleColumnSortChange={handleColumnSortChange}
        onRowClick={onRowClick}
        currentOrdering={query.ordering}
      />

      <div className="NewPaginationTable__pagination">
        <div className="NewPaginationTable__select-wrap">
          <select
            onChange={({ target }) => handleChange('page_size', target.value)}
            value={pageSize}
            className="NewPaginationTable__select">
            {pageSizeDelimiters.map(delimiter => (
              <option value={delimiter} key={delimiter}>
                {delimiter}{' '}
              </option>
            ))}
          </select>
        </div>
        <div className="NewPaginationTable__pagination-nav">
          <div className="NewPaginationTable__nav-info">
            {isTabletOrMobile && (
              <>
                <ActionsButton
                  onClickFunc={() => handleChange('page', Number(currentPage) - 1)}
                  disabled={currentPage <= 1}
                />
                <ActionsButton
                  onClickFunc={() => handleChange('page', Number(currentPage) + 1)}
                  disabled={currentPage >= pagesCount}
                />
              </>
            )}
            <FormattedMessage id="justPage">{txt => <span>{txt}</span>}</FormattedMessage>
            <input
              className="NewPaginationTable__nav-input"
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              onBlur={() => handleChange('page', currentPage)}
            />
            <FormattedMessage id="justOf">
              {txt => (
                <span>
                  {txt} {pagesCount}
                </span>
              )}
            </FormattedMessage>
          </div>
          {!isTabletOrMobile && (
            <>
              <DefaultButton
                textId="justPrevious"
                onClick={() => handleChange('page', Number(currentPage) - 1)}
                disabled={currentPage <= 1}
                previous
              />
              <DefaultButton
                textId="justNext"
                onClick={() => handleChange('page', Number(currentPage) + 1)}
                disabled={currentPage >= pagesCount}
                next
              />
            </>
          )}
        </div>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

NewPaginationTable.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  renderRowSubComponent: PropTypes.func,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  theme: PropTypes.object,
};

NewPaginationTable.defaultProps = { renderRowSubComponent: null, onRowClick: () => {}, loading: false, theme: {} };

export default withTheme()(NewPaginationTable);
