import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { NoItemsPanel, PageLayout, SearchPanel, PageContentPreloader, DefaultButton } from 'common/components';

import noNewsImage from 'common/images/noNews.svg';

import { getNewsList, deleteNews, publishNews, unPublishNews } from '../../_redux';
import { NewsTable } from '../../_components';

import { staticStyles } from './style';

const NewsListPage = ({ getNewsList, newsList, newsListAreLoaded, deleteNews, publishNews, unPublishNews, intl }) => {
  const location = useLocation();
  const history = useHistory();
  const query = {
    page: 1,
    page_size: 10,
    ...queryString.parse(location.search),
  };

  const handleGetNewsList = () => {
    getNewsList({
      search: query,
    });
  };

  /* eslint-disable */
  useEffect(() => {
    handleGetNewsList();
  }, []);
  /* eslint-enable */

  const handleRowClick = id => history.push(`/backoffice/news/${id}/`);
  const handleCreateClick = () => history.push('/backoffice/news/create/');

  const filters = [
    {
      title: 'justPublished',
      accessor: 'is_published',
      options: [
        {
          value: '',
          label: intl.formatMessage({
            id: 'justAll',
          }),
        },
        {
          value: 'True',
          label: intl.formatMessage({
            id: 'justYes',
          }),
        },
        {
          value: 'False',
          label: intl.formatMessage({
            id: 'justNo',
          }),
        },
      ],
    },
  ];

  return (
    <PageLayout captionSlug="justNews" showReload onReloadClick={handleGetNewsList} reloading={!newsListAreLoaded}>
      <div className="NewsListPage__create-inner">
        <DefaultButton textId="justCreate" plus filled onClick={() => handleCreateClick()} />
      </div>

      <PageContentPreloader ready={newsListAreLoaded} firstLaunchOnly type="smallLines">
        {!newsList.count && !(Object.keys(query).length > 1) ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getNewsList}
            params={{
              search: query,
            }}
            filters={filters}
          />
        )}
        {newsList.count ? (
          <NewsTable
            data={newsList}
            loading={!newsListAreLoaded}
            location={location}
            history={history}
            getList={getNewsList}
            onRowClick={handleRowClick}
            params={{
              search: query,
            }}
            deleteNews={deleteNews}
            publishNews={publishNews}
            unPublishNews={unPublishNews}
          />
        ) : (
          <NoItemsPanel
            icon={noNewsImage}
            captionSlug={Object.keys(query).length > 1 ? 'noNewsMatchingTheParameters' : 'noNewsYet'}
          />
        )}
      </PageContentPreloader>

      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

NewsListPage.propTypes = {
  newsList: PropTypes.object.isRequired,
  getNewsList: PropTypes.func.isRequired,
  newsListAreLoaded: PropTypes.bool.isRequired,
  deleteNews: PropTypes.func.isRequired,
  publishNews: PropTypes.func.isRequired,
  unPublishNews: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    newsList: state.backoffice.news.newsList,
    newsListAreLoaded: state.backoffice.news.newsListAreLoaded,
  }),
  {
    getNewsList: search => getNewsList.request(search),
    deleteNews: (id, queryParams) => deleteNews.request(id, queryParams),
    publishNews: (id, queryParams) => publishNews.request(id, queryParams),
    unPublishNews: (id, queryParams) => unPublishNews.request(id, queryParams),
  }
)(injectIntl(NewsListPage));
export { NewsListPage };
