import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTheme } from 'common/styling/theme';
import { PageLayout, PageContentPreloader, BaseTextField, BaseSelectField, NoItemsPanel } from 'common/components';
import noFilesDownload from 'common/images/noFilesDownload.svg';
import ContractSpecsTable from '../_components/ContractSpecsTable';

import { getGroupsList, getContractSpecs } from '../_redux';
import { staticStyles, getDynamicStyles } from './style';

const ContractSpecsPage = ({
  groups,
  groupsAreLoaded,
  specs,
  specsAreLoaded,
  getGroupsList,
  getContractSpecs,
  lang,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState('');
  const [searchableSpecs, setSearchableSpecs] = useState(specs);
  const [selectedValue, setSelectedValue] = useState({});
  const [mobileSelectedValue, setMobileSelectedValue] = useState({});

  useEffect(() => {
    getGroupsList();
  }, [getGroupsList]);

  useEffect(() => {
    if (groups.length && groupsAreLoaded) {
      getContractSpecs({ groupId: groups[activeTab].id, lang });
      setMobileSelectedValue({
        value: 0,
        label: `${groups[activeTab].typeName}`,
      });
    }
  }, [getContractSpecs, lang, groupsAreLoaded, activeTab, groups]);

  const handleTabClick = (index, groupId) => {
    setActiveTab(index);
    getContractSpecs({ groupId, lang });
    setSelectedValue({});
  };

  const handleChangeSearch = e => {
    const value = e.target.value.toLowerCase();

    const filter = specs.filter(spec => spec.symbol.toLowerCase().includes(value));

    setSearch(value);
    setSearchableSpecs(filter);
  };

  const qs = search ? searchableSpecs : specs;

  const options =
    groups[activeTab]?.priceGroups &&
    Object.entries(groups[activeTab].priceGroups).map(([key, value]) => ({
      label: key,
      options: value.map(item => ({ label: item, value: item })),
    }));

  const handleGroupChange = ({ value, label }) => {
    setSelectedValue({ value, label });
    getContractSpecs({ groupId: groups[activeTab].id, groupName: value, lang });
  };

  const mobileOptions = groups.map((group, index) => ({
    value: index,
    label: `${group.typeName}`,
  }));

  const handleMobileChange = ({ value, label }) => {
    handleTabClick(value, groups[value].id);
    setMobileSelectedValue({ value, label });
  };

  return (
    <PageLayout captionSlug="justContractSpecifications">
      <PageContentPreloader ready={groupsAreLoaded} type="bigLine">
        {groupsAreLoaded && !!groups.length ? (
          <div className="ContractSpecsPage">
            <div className="ContractSpecsPage__select ContractSpecsPage__select--mobile">
              <BaseSelectField
                value={mobileSelectedValue}
                name="userAccount"
                options={mobileOptions}
                onChange={value => handleMobileChange(value)}
                textId="selectAccount"
              />
            </div>
            <div className="ContractSpecsPage__tabs">
              {groups.map((group, index) => (
                <button
                  key={group.id}
                  type="button"
                  className={classNames('ContractSpecsPage__tab', { active: activeTab === index })}
                  onClick={() => {
                    handleTabClick(index, group.id);
                  }}>
                  <span className="ContractSpecsPage__tab-label">{group.typeName[0]}</span>
                  {group.typeName}
                </button>
              ))}
            </div>
            <BaseSelectField
              value={selectedValue}
              name="userAccount"
              options={options}
              onChange={value => handleGroupChange(value)}
              textId="selectPriceGroup"
            />
            <div className="SearchPanel">
              <BaseTextField
                type="text"
                name="search"
                textId="justSearchSymbol"
                onChange={handleChangeSearch}
                value={search}
              />
            </div>
            {specsAreLoaded && !!qs.length && (
              <ContractSpecsTable data={qs.map((item, itemI) => ({ ...item, id: itemI }))} />
            )}
            <style jsx global>
              {dynamicStyles}
            </style>
            <style jsx global>
              {staticStyles}
            </style>
          </div>
        ) : (
          <NoItemsPanel icon={noFilesDownload} captionSlug="noData" />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

ContractSpecsPage.propTypes = {
  groups: PropTypes.array.isRequired,
  groupsAreLoaded: PropTypes.bool.isRequired,
  getGroupsList: PropTypes.func.isRequired,
  specs: PropTypes.array.isRequired,
  specsAreLoaded: PropTypes.bool.isRequired,
  getContractSpecs: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

ContractSpecsPage.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      groups: state.client.information.groups,
      groupsAreLoaded: state.client.information.groupsAreLoaded,
      specs: state.client.information.specs,
      specsAreLoaded: state.client.information.specsAreLoaded,
      lang: state.interfaceConfig.lang,
    }),
    {
      getGroupsList: () => getGroupsList.request(),
      getContractSpecs: data => getContractSpecs.request(data),
    }
  )
)(ContractSpecsPage);
export { ContractSpecsPage };
