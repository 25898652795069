import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage as FM } from 'react-intl';
import { BaseButton } from 'crm/_components';

import { staticStyles } from './style';

const TopTab = ({ openCreateNoteModal }) => (
  <div className="TopTab">
    <div className="TopTab__Items">
      <div className="TopTab__Item">
        <FM id="createNote">{txt => txt}</FM>
      </div>
      <div className="TopTab__Item">
        <FM id="justAuthor">{txt => txt}</FM>
      </div>
      <div className="TopTab__Item">
        <FM id="justDate">{txt => txt}</FM>
      </div>
    </div>
    <div className="TopTab__GreyLine" />
    <div className="TopTab__CreateNoteButtonWrapper">
      <BaseButton className="TopTab__CreateNoteButton" textId="crmAddNewNote" onClick={openCreateNoteModal} plus fill />

      {/* {showSelect && <CRMSelect closeShowSelect={() => setShowSelect(false)} />} */}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

TopTab.propTypes = {
  openCreateNoteModal: PropTypes.func.isRequired,
};
export default TopTab;
