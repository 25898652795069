import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksPlan {
    &__header {
      margin-bottom: 25px;
    }

    &__list {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(35px);

      @include respond-to(sm) {
        flex-direction: column;

        :global(.TasksPlanList) {
          width: 100%;
          margin-bottom: rem(15px);
        }
      }
    }
  }
`;

export { staticStyles };
