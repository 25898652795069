import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ShareSocialButtons {
    display: flex;
    align-items: center;
  }

  .ShareSocialIcon__inner {
    position: relative;
    margin-right: rem(${isRTL ? '0' : '7px'});
    margin-left: rem(${isRTL ? '7px' : '0'});
    &:last-child {
      margin: 0;
    }
    .ShareCount {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
      border-radius: 50%;
      font-size: 12px;
      padding: 2px 6px;
      display: none;
      &__active {
        display: block;
      }
    }
  }

  .SocialMediaShareButton  {
    outline: none !important;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      opacity: .7;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ShareSocialIcon__inner {
      .ShareCount {
        background: ${colors.lightGray.hex};
        color: ${colors.white.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
