import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksData {
    padding-right: 64px;
    display: flex;
    gap: 12px;
    height: 100%;
    align-items: center;

    @include respond-to(sm) {
      padding-right: 16px;
      gap: 4px;
    }

    .Tooltip {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    }

    &__item {
      padding: 5px 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      background: #bac2ca;
      border-radius: 4px;

      @include respond-to(xs) {
        padding: 2px 6px;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .TasksData {
      .Tooltip {
        background-color: ${colors.white.hex} !important;
        color: ${colors.black.hex};
      }

      .__react_component_tooltip.type-dark.place-bottom:after {
        border-bottom-color: ${colors.white.hex} !important;
      }

      &__item {
        color: ${colors.white.hex};

        &.success {
          background: ${colors.success.hex};
        }

        &.overdue {
          background: ${colors.error.hex};
        }
        &.reassign {
          background: ${colors.primary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
