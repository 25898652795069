import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { withTheme } from 'common/styling/theme';

import ChangeEmailForm from '../ChangeEmailForm';
import ChangePasswordForm from '../ChangePasswordForm';
import { staticStyles, getDynamicStyles } from './style';

const SecurityPanelContent = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="SecurityPanelContent">
      <ChangeEmailForm />
      <ChangePasswordForm />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

SecurityPanelContent.propTypes = {
  theme: PropTypes.object,
};

SecurityPanelContent.defaultProps = {
  theme: {},
};

export default compose(withTheme())(SecurityPanelContent);

export { SecurityPanelContent };
