import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';
import { NoItemsPanel, PageContentPreloader, TransfersTable, SearchPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';

import { getUserInternalTransfers } from '../../_redux';

const UserInternalTransfers = ({ internalTransfers, internalTransfersAreLoaded, getUserInternalTransfers, intl }) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getUserInternalTransfers({ userId: match.params.id, search: query });
  }, [getUserInternalTransfers, match.params.id, query]);

  const getList = query => getUserInternalTransfers({ userId: match.params.id, ...query });

  const handleRowClick = id => history.push(`/backoffice/transfers/${id}/`);

  const filters = [
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'IN_PROGRESS', label: intl.formatMessage({ id: 'status_in_progress' }) },
        { value: 'WAITING_FOR_APPROVAL', label: intl.formatMessage({ id: 'status_waiting_for_approval' }) },
        { value: 'COMPLETED', label: intl.formatMessage({ id: 'status_completed' }) },
        { value: 'CANCELED', label: intl.formatMessage({ id: 'status_canceled' }) },
      ],
    },
    {
      title: 'boTransferType',
      accessor: 'transferType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'INNER', label: intl.formatMessage({ id: 'boInnerTransfer' }) },
        { value: 'TO_TRADER', label: intl.formatMessage({ id: 'boAnotherTrader' }) },
      ],
    },
  ];

  return (
    <PageContentPreloader ready={internalTransfersAreLoaded} type="smallLines" firstLaunchOnly>
      {!internalTransfers.count && !location.search?.includes('page') ? (
        ''
      ) : (
        <SearchPanel
          location={location}
          history={history}
          getList={getList}
          filters={filters}
          params={{ id: match.params.id, search: query }}
        />
      )}
      {internalTransfers.count ? (
        <TransfersTable
          pageLink="backoffice/transfers"
          data={internalTransfers}
          loading={!internalTransfersAreLoaded}
          onRowClick={handleRowClick}
          getList={getList}
          location={location}
          history={history}
          params={{ search: query }}
        />
      ) : (
        <NoItemsPanel
          icon={noPaymentsImage}
          captionSlug={location.search?.includes('page') ? 'noTransfersMatchingTheParameters' : 'noInternalTransfers'}
        />
      )}
    </PageContentPreloader>
  );
};

UserInternalTransfers.propTypes = {
  getUserInternalTransfers: PropTypes.func.isRequired,
  internalTransfers: PropTypes.object.isRequired,
  internalTransfersAreLoaded: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    internalTransfers: state.backoffice.users.internalTransfers,
    internalTransfersAreLoaded: state.backoffice.users.internalTransfersAreLoaded,
  }),
  {
    getUserInternalTransfers: data => getUserInternalTransfers.request(data),
  }
)(injectIntl(UserInternalTransfers));

export { UserInternalTransfers };
