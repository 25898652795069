import React from 'react';
import { RadioTabs } from 'crm/_components';
import { staticStyles } from './style';
import TabInOutTableDAW from './TabInOutTableDAW';
import TabInOutTableIT from './TabInOutTableIT';

const TabInOut = () => (
  <div className="TabInOut">
    <RadioTabs externalClass="TabInOut__radio-tabs">
      <TabInOutTableDAW tabTextId="crmDepositsAndWithdraws" tabSlug="depositsAndWithdraws" />
      <TabInOutTableIT tabTextId="crmInternalTransfers" tabSlug="internalTransfers" />
    </RadioTabs>
    <style jsx>{staticStyles}</style>
  </div>
);

export default TabInOut;
