import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { deleteContactTag } from 'crm/users/_redux';
import { setTagColor } from 'common/utils/helpers';
import iconDelete from './images/iconDelete.svg';
import { staticStyles } from './style';

const Tag = ({ contactId, id, name }) => {
  const dispatch = useDispatch();
  const handleTagDelete = id => {
    dispatch(deleteContactTag.request({ id: contactId, tag: id }));
  };
  const tagStyle = useMemo(() => setTagColor(name), [name]);
  return (
    <div className="TagItem" style={tagStyle}>
      {name}
      <button className="TagItem__delete" onClick={() => handleTagDelete(id)}>
        <img className="ClientPage__left-tags-delete" width="10" height="12" src={iconDelete} alt="delete" />
      </button>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

Tag.propTypes = {
  contactId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Tag;
