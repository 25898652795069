const toArray = value => (Array.isArray(value) ? value : [value]);

// Helper function to construct a query string for the search object
const buildQueryString = search =>
  Object.entries(search)
    .map(([key, value]) => toArray(value).map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`))
    .flat()
    .join('&');

export { toArray, buildQueryString };
