import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader } from 'common/components';

import ChallengesBlock from 'client/_components/ChallengesBlock/ChallengesBlock';

import { getChallenges, getUserChallenges } from '../../_redux';

import { ChallengesHistoryBlock, SelectChallengeBlock } from '../../_components';

const ChallengesPage = ({ challengesAreLoaded, getChallenges, getUserChallenges, userChallengesAreLoaded }) => {
  useEffect(() => {
    getChallenges();
    getUserChallenges();
  }, [getChallenges, getUserChallenges]);

  return (
    <PageLayout
      captionSlug="justChallenges"
      showReload
      onReloadClick={() => {
        getChallenges();
        getUserChallenges();
      }}
      reloading={false}>
      <PageContentPreloader ready={challengesAreLoaded && userChallengesAreLoaded} type="bigLine">
        <ChallengesBlock />
        <ChallengesHistoryBlock />
        <SelectChallengeBlock />
      </PageContentPreloader>
    </PageLayout>
  );
};

export default connect(
  state => ({
    challenges: state.client.challenges.challenges,
    challengesAreLoaded: state.client.challenges.challengesAreLoaded,
    userChallenges: state.client.challenges.userChallenges,
    userChallengesAreLoaded: state.client.challenges.userChallengesAreLoaded,
  }),
  {
    getChallenges: () => getChallenges.request(),
    getUserChallenges: () => getUserChallenges.request(),
  }
)(ChallengesPage);
