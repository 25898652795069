import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .PartnershipAccountPage__caption {
    margin-bottom: 15px;
  }

  .PartnershipAccountPage__table {
    :global(.NoItemsPanel) {
      height: rem(250px);
      min-height: auto;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
      :global(img) {
        margin-bottom: rem(20px);
        width: 230px;
        height: 100px;
      }
    }

    :global(.PartnerAccountInfoTable) {
      :global(.ReactTable) {
        width: 100%;

        :global(.rt-thead.-header) {
          min-width: 600px !important;
        }

        :global(.rt-tbody) {
          min-width: 600px !important;
          :global(.rt-tr-group) {
            box-shadow: none;
            cursor: default;
            :global(.rt-tr) {
              :global(.rt-td) {
                &:nth-child(3) {
                  :global(span) {
                    font-size: rem(15px) !important;
                  }
                }
                &:last-child {
                  :global(span) {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }

      :global(.account) {
        font-size: rem(16px);
      }
      :global(.MoneyCell__inner) {
        height: auto;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .PartnershipAccountPage__table {
      :global(.NoItemsPanel) {
        background: ${colors.white.hex};
        border-radius: ${border.radius};
      }

      :global(.PartnerAccountInfoTable) {
        :global(.account) {
          color: ${colors.secondary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
