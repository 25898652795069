import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxSelectField, DefaultButton } from 'common/components';

import { createAccountConvertation } from '../../accounts/_redux';

import staticStyles from './style';

const AccountConvertForm = ({ id, handleSubmit, options, dispatch, submitting, invalid, error }) => {
  const submit = handleSubmit(values => createAccountConvertation({ id, ...values }, dispatch));

  return (
    <div className="RejectModal__content">
      <form className="AccountConvertForm" onSubmit={submit}>
        <div className="AccountConvertForm__inputs-inner">
          <Field
            name="accountTypeId"
            textId="justSelectAccountType"
            validate={required()}
            options={options.map(option => ({ value: option.id, label: option.title }))}
            component={ReduxSelectField}
            disabled={submitting}
          />
        </div>
        <DefaultButton
          textId="justConvert"
          type="submit"
          loading={submitting}
          disabled={invalid || submitting}
          filled
        />
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

AccountConvertForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
  id: PropTypes.number.isRequired,
};

AccountConvertForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'AccountConvertForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(AccountConvertForm);
export { AccountConvertForm };
