import { put, call, fork, take } from 'redux-saga/effects';
import Raven from 'raven-js';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as ActionTypes from '../actions';
import * as api from '../../api';
import websocketWatcherSaga from './websocket';

export function* getClientCustomPagesSaga() {
  try {
    const { data } = yield call(api.getClientCustomPages);
    yield put(ActionTypes.getClientCustomPages.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getClientCustomPages.failure());
  }
}

export function* getClientCustomPagesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CLIENT_CUSTOM_PAGES[REQUEST]);
    yield call(getClientCustomPagesSaga);
  }
}

export function* getBackofficeCustomPagesSaga() {
  try {
    const { data } = yield call(api.getBackofficeCustomPages);
    yield put(ActionTypes.getBackofficeCustomPages.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBackofficeCustomPages.failure());
  }
}

export function* getBackofficeCustomPagesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BACKOFFICE_CUSTOM_PAGES[REQUEST]);
    yield call(getBackofficeCustomPagesSaga);
  }
}

export function* getProfileSaga() {
  try {
    const { data } = yield call(api.getUser);
    yield put(ActionTypes.getProfile.success(data));
    Raven.setUserContext({ email: data.email });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getProfile.failure());
  }
}

export function* getProfileWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PROFILE[REQUEST]);
    yield call(getProfileSaga);
  }
}

export function* stopImpersonateSaga() {
  try {
    yield call(api.stopImpersonate);
    yield put(ActionTypes.stopImpersonate.success());
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.stopImpersonate.failure(e));
  }
}
function* stopImpersonateWatcherSaga() {
  while (true) {
    yield take(ActionTypes.STOP_IMPERSONATE[REQUEST]);
    yield call(stopImpersonateSaga);
  }
}

export default [
  fork(getClientCustomPagesWatcherSaga),
  fork(getBackofficeCustomPagesWatcherSaga),
  fork(getProfileWatcherSaga),
  fork(stopImpersonateWatcherSaga),
  fork(websocketWatcherSaga),
];
