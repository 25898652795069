import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .ManagerClientInfo {
    max-width: 430px;

    .ManagerClientInfo__row {
      display: flex;
      margin-bottom: rem(20px);

      .ManagerClientInfo__row-caption {
        margin-right: rem(30px);
      }
    }

    .ManagerClientInfo__buttons {
      display: flex;
      margin-top: rem(30px);

      :global(.DefaultButton:first-child) {
        margin-right: rem(30px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .ManagerClientInfo__row-caption {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
