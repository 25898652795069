import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const confirmPaymentsOperation = createFormAction('CONFIRM_PAYMENTS_OPERATION');

export const GET_DEPOSIT_SYSTEM_LIST = createRequestTypes('GET_DEPOSIT_SYSTEM_LIST');
export const getDepositSystemList = {
  request: () => action(GET_DEPOSIT_SYSTEM_LIST[REQUEST]),
  success: systems => action(GET_DEPOSIT_SYSTEM_LIST[SUCCESS], { systems }),
  failure: error => action(GET_DEPOSIT_SYSTEM_LIST[FAILURE], { error }),
};

export const GET_WITHDRAWAL_SYSTEM_LIST = createRequestTypes('GET_WITHDRAWAL_SYSTEM_LIST');
export const getWithdrawalSystemList = {
  request: () => action(GET_WITHDRAWAL_SYSTEM_LIST[REQUEST]),
  success: systems => action(GET_WITHDRAWAL_SYSTEM_LIST[SUCCESS], { systems }),
  failure: error => action(GET_WITHDRAWAL_SYSTEM_LIST[FAILURE], { error }),
};

export const makeDeposit = createFormAction('MAKE_DEPOSIT');
export const makeWithdraw = createFormAction('MAKE_WITHDRAW');
export const resetPaymentForm = createFormAction('RESET_PAYMENT_FORM');

export const finishPayment = createFormAction('FINISH_PAYMENT');
export const sendRequisites = createFormAction('SEND_REQUISITES');

// export const resendPaymentConfirmationMessage = createFormAction('RESEND_PAYMENT_CONFIRMATION_MESSAGE');
export const RESEND_PAYMENT_CONFIRMATION_MESSAGE = createRequestTypes('RESEND_PAYMENT_CONFIRMATION_MESSAGE');
export const resendPaymentConfirmationMessage = {
  request: id => action(RESEND_PAYMENT_CONFIRMATION_MESSAGE[REQUEST], { id }),
  success: info => action(RESEND_PAYMENT_CONFIRMATION_MESSAGE[SUCCESS], { info }),
  failure: error => action(RESEND_PAYMENT_CONFIRMATION_MESSAGE[FAILURE], { error }),
};

export const GET_PAYMENTS = createRequestTypes('GET_PAYMENTS');
export const getPayments = {
  request: id => action(GET_PAYMENTS[REQUEST], { id }),
  success: info => action(GET_PAYMENTS[SUCCESS], { info }),
  failure: error => action(GET_PAYMENTS[FAILURE], { error }),
};

export const GET_PAYMENTS_INVOICE = createRequestTypes('GET_PAYMENTS_INVOICE');
export const getPaymentsInvoice = {
  request: id => action(GET_PAYMENTS_INVOICE[REQUEST], { id }),
  success: info => action(GET_PAYMENTS_INVOICE[SUCCESS], { info }),
  failure: error => action(GET_PAYMENTS_INVOICE[FAILURE], { error }),
};

export const GET_PAYMENTS_LIST = createRequestTypes('GET_PAYMENTS_LIST');
export const getPaymentsList = {
  request: search => action(GET_PAYMENTS_LIST[REQUEST], { search }),
  success: data => action(GET_PAYMENTS_LIST[SUCCESS], { data }),
  failure: error => action(GET_PAYMENTS_LIST[FAILURE], { error }),
};

export const GET_IN_OUT_LIST = createRequestTypes('GET_IN_OUT_LIST');
export const getInOutList = {
  request: search => action(GET_IN_OUT_LIST[REQUEST], { search }),
  success: data => action(GET_IN_OUT_LIST[SUCCESS], { data }),
  failure: error => action(GET_IN_OUT_LIST[FAILURE], { error }),
};

export const CANCEL_WITHDRAWAL = createRequestTypes('CANCEL_WITHDRAWAL');
export const cancelWithdrawal = {
  request: id => action(CANCEL_WITHDRAWAL[REQUEST], { id }),
  success: () => action(CANCEL_WITHDRAWAL[SUCCESS]),
  failure: error => action(CANCEL_WITHDRAWAL[FAILURE], { error }),
};

export const USER_GET_EDIT_HISTORY = createRequestTypes('USER_GET_EDIT_HISTORY');
export const userGetEditHistory = {
  request: id => action(USER_GET_EDIT_HISTORY[REQUEST], { id }),
  success: data => action(USER_GET_EDIT_HISTORY[SUCCESS], { data }),
  failure: error => action(USER_GET_EDIT_HISTORY[FAILURE], { error }),
};

export const UPLOD_PAYMENT_PROOF = createRequestTypes('UPLOD_PAYMENT_PROOF');
export const uploadPaymentProof = {
  request: data => action(UPLOD_PAYMENT_PROOF[REQUEST], { ...data }),
  success: id => action(UPLOD_PAYMENT_PROOF[SUCCESS], { id }),
  failure: error => action(UPLOD_PAYMENT_PROOF[FAILURE], { error }),
};

export const makePaymentLpa = createFormAction('MAKE_PAYMENT_LPA');

export const GET_AVAILABLE_LPAS = createRequestTypes('GET_AVAILABLE_LPAS');
export const getAvailableLpas = {
  request: slug => action(GET_AVAILABLE_LPAS[REQUEST], { slug }),
  success: data => action(GET_AVAILABLE_LPAS[SUCCESS], { data }),
  failure: error => action(GET_AVAILABLE_LPAS[FAILURE], { error }),
};

export const GET_LPA_AGENT_DEALS = createRequestTypes('GET_LPA_AGENT_DEALS');
export const getLpaAgentDeals = {
  request: search => action(GET_LPA_AGENT_DEALS[REQUEST], { search }),
  success: data => action(GET_LPA_AGENT_DEALS[SUCCESS], { data }),
  failure: error => action(GET_LPA_AGENT_DEALS[FAILURE], { error }),
};

export const GET_LPA_CLIENT_DEALS = createRequestTypes('GET_LPA_CLIENT_DEALS');
export const getLpaClientDeals = {
  request: search => action(GET_LPA_CLIENT_DEALS[REQUEST], { search }),
  success: data => action(GET_LPA_CLIENT_DEALS[SUCCESS], { data }),
  failure: error => action(GET_LPA_CLIENT_DEALS[FAILURE], { error }),
};

export const GET_LPA_DEAL = createRequestTypes('GET_LPA_DEAL');
export const getLpaDeal = {
  request: id => action(GET_LPA_DEAL[REQUEST], { id }),
  success: data => action(GET_LPA_DEAL[SUCCESS], { data }),
  failure: error => action(GET_LPA_DEAL[FAILURE], { error }),
};
