import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .TinyMCEField {
    width: 100%;
  }

  .mce-tinymce {
    margin-bottom: 3px !important;
    box-sizing: border-box;
    padding: 1px !important;
  }

  .mce-edit-area > iframe {
    min-height: rem(400px) !important;
    height: auto !important;
  }

  .mce-panel {
    font-family: $ubuntu-font !important;
  }

  .mce-tinymce {
    box-shadow: none !important;
  }

  .TinyMCEField {
    label {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .mce-panel {
      border-color: ${colors.lightGray.hex} !important;
    }

    .TinyMCEField__errorInner {
      .mce-panel {
        border-color: ${colors.error.hex} !important;
      }
    }

    .TinyMCEField__error {
      color: ${colors.error.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
