import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const TransferIcon = ({ grayColor }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="notFillIcon">
    <g clipPath="url(#clip0)">
      <path
        d="M10.8355 5.43654L8.04297 2.77157L11.2823 0.53299"
        stroke={grayColor.hex}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0533 15.5634L12.8458 18.2284L9.60645 20.467"
        stroke={grayColor.hex}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4306 19.934C19.541 19.934 20.4412 19.0749 20.4412 18.0152C20.4412 16.9555 19.541 16.0964 18.4306 16.0964C17.3201 16.0964 16.4199 16.9555 16.4199 18.0152C16.4199 19.0749 17.3201 19.934 18.4306 19.934Z"
        stroke={grayColor.hex}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.56923 4.90354C3.67968 4.90354 4.57987 4.04447 4.57987 2.98476C4.57987 1.92505 3.67968 1.06598 2.56923 1.06598C1.45879 1.06598 0.558594 1.92505 0.558594 2.98476C0.558594 4.04447 1.45879 4.90354 2.56923 4.90354Z"
        stroke={grayColor.hex}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.56934 7.99493V13.6447C2.56934 15.9899 4.57997 17.9086 7.03742 17.9086H12.5108"
        stroke={grayColor.hex}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4307 12.6853V7.03553C18.4307 4.69036 16.4201 2.77158 13.9627 2.77158H8.48926"
        stroke={grayColor.hex}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="21" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TransferIcon.propTypes = {
  grayColor: colorType,
};

TransferIcon.defaultProps = {
  grayColor: {},
};

export default withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(TransferIcon);
