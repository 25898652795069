import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const STATUSES = {
  success: [
    'allow',
    'done',
    'success',
    'received',
    'deposited',
    'verified',
    'open',
    'created',
    'execute',
    'executed',
    'completed',
    'buy',
    'buy_stop',
    'buy_limit',
    'balance',
    'verified_user',
    'verified_passport',
    'verified_identification_proof',
    'verified_address_proof',
    'verified_additional_document',
    'active',
    'valid',
    'partner',
    'unblocked_user',
    'withdraw',
    'yes',
    'true',
    'new',
    'mc',
    'approved',
    'proof_verified',
    'appoint',
  ],
  warning: [
    'waiting_confirmation',
    'waiting_for_approval',
    'money_waiting',
    'processing',
    'partnership_requested',
    'in_process',
    'in_progress',
    'payout',
    'marked_payout',
    'unverified',
    'answered',
    'marked_processing',
    'marked_deposited',
    'changed_password',
    'changed_info',
    'ready_for_withdraw',
    'ready_for_pay',
    'uploaded',
    'id_verification',
    'protection_target',
    'paused',
    'waiting_approval',
    'verified_new_documents',
    'pmr_init',
    'payment_proof',
    'proof_uploaded',
    'pending',
    'change',
    'need_finish',
  ],
  error: [
    'disallow',
    'no',
    'none',
    'rejected',
    'archived',
    'canceled',
    'failed',
    'false',
    'incomplete',
    'sell',
    'sell_stop',
    'sell_limit',
    'rejected_user',
    'rejected_passport',
    'rejected_identification_proof',
    'rejected_address_proof',
    'rejected_additional_document',
    'photo_required',
    'proof_rejected',
  ],
  blocked: ['blocked', 'blocked_user'],
};

const StatusCell = ({ statusCode, noDot, theme, noTranslate }) => {
  const activeStatus =
    Object.keys(STATUSES).find(status => STATUSES[status].includes(statusCode?.toLowerCase())) || 'error';

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <span
      className={classNames('StatusCell', `StatusCell__${activeStatus}`, {
        'StatusCell__no-dot': noDot,
      })}>
      {noTranslate ? (
        statusCode
      ) : (
        <FormattedMessage id={`status_${statusCode?.toLowerCase()}`}>{txt => txt}</FormattedMessage>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </span>
  );
};

StatusCell.propTypes = {
  statusCode: PropTypes.string.isRequired,
  noDot: PropTypes.bool,
  noTranslate: PropTypes.bool,
  theme: PropTypes.object,
};

StatusCell.defaultProps = {
  noDot: false,
  noTranslate: false,
  theme: {},
};

const ThemedStatusCell = withTheme()(StatusCell);

export default ThemedStatusCell;
export { StatusCell };
