import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BannerFilterForm {
    &__title {
      margin-bottom: 10px;
    }

    &__block {
      &--half {
        display: flex;
        justify-content: space-between;

        :global(.ReduxSelectField) {
          width: calc(50% - 10px);
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
    }

    &__label {
      margin-right: 20px;
    }

    :global(.BaseTextField) {
      margin-bottom: 0;
      margin-right: 20px;
    }

    :global(.BaseTextField__label) {
      display: none;
    }

    :global(.BaseTextField__inp) {
      padding-top: 11px;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .BannerFilterForm {
      :global(.isMulti .Select__multi-value) {
        background: ${colors.lightGray.hex};
      }
      :global(.Select__multi-value__label) {
        color: ${colors.black.hex} !important;
      }
      :global(.isMulti .Select__multi-value__remove:after, .isMulti .Select__multi-value__remove:before) {
        background: ${colors.black.hex} !important;
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
