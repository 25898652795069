import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_NEWS = createRequestTypes('GET_NEWS');
export const getNews = {
  request: paginationParams => action(GET_NEWS[REQUEST], { paginationParams }),
  success: data => action(GET_NEWS[SUCCESS], { data }),
  failure: error => action(GET_NEWS[FAILURE], { error }),
};

export const GET_ANALYTIC_REVIEWS = createRequestTypes('GET_ANALYTIC_REVIEWS');
export const getAnalyticReviews = {
  request: paginationParams => action(GET_ANALYTIC_REVIEWS[REQUEST], { paginationParams }),
  success: data => action(GET_ANALYTIC_REVIEWS[SUCCESS], { data }),
  failure: error => action(GET_ANALYTIC_REVIEWS[FAILURE], { error }),
};
