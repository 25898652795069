/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage as FM } from 'react-intl';
import queryString from 'query-string';
import ProvidersPopup from 'backoffice/users/_components/ProvidersPopup';
import { PhoneIcon } from 'backoffice/managers/_components/TaskIcon/icons';
import classNames from 'classnames';
import { PageContentPreloader, MoneyCell, DateCell, ActionsButton } from 'common/components';
import { NewCRMPagination, NewCRMTable } from '../../../_newComponents';
import { getContacts, getContactsFavorites, crmSetSelectedContactsIds } from '../../_redux';
import SelectCheckbox from '../SelectCheckbox';
import FullNameCell from '../FullNameCell';

import { staticStyles } from './style';

const ContactsTable = ({
  profilePerms,
  addons,
  contacts,
  contactsAreLoaded,
  getContacts,
  getContactsFavorites,
  onTagSelect,
  location,
}) => {
  const dispatch = useDispatch();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const [tableWidth, setTableWidth] = useState({
    tableWidth: '100%',
    cellWidth: '100%',
  });
  const [openTagsId, setOpenTagsId] = useState(4);
  const [hideAllTags, setHideAllTags] = useState(false);
  const withClick2Call = !!profilePerms.managerViewUser && !!profilePerms.viewUser && addons.includes('click2call');
  const [buttonPosition, setButtonPosition] = useState([0, 0]);
  const [isCallModalOpen, setCallModalOpen] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const contactsTableRef = useRef(null);

  const totalDiff = contacts?.total_in_sum?.amount
    ? {
        amount: +contacts?.total_in_sum?.amount - +contacts?.total_out_sum?.amount,
        currency: contacts?.total_in_sum?.currency,
      }
    : null;

  const getPopupPosition = (buttonPosition, tablePosition) => {
    const topDiff = isMobile ? 30 : 0;
    const rightDiff = isMobile ? 80 : 210;
    const top = buttonPosition.y - tablePosition.y + topDiff;
    const right = buttonPosition.x - tablePosition.x - rightDiff;
    setButtonPosition([top, right]);
  };

  const toggleCallModalOpen = (id, elem, isHover) => {
    if (isHover) {
      setCallModalOpen(true);
    } else {
      setCallModalOpen(!isCallModalOpen);
    }

    if (id) {
      setModalId(id);
      getPopupPosition(elem.getBoundingClientRect(), contactsTableRef.current.getBoundingClientRect());
    }
  };

  useEffect(() => {
    dispatch(crmSetSelectedContactsIds([]));
    getContacts({ search: query });
    if (query.search) {
      setHideAllTags(true);
    } else {
      setHideAllTags(false);
    }
  }, [query]);

  useEffect(() => {
    getContactsFavorites();
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const columns = [
    {
      accessor: 'selectCheckBox',
      Header: () => <SelectCheckbox header />,
      Cell: ({ rowData: { id } }) => <SelectCheckbox contactId={id} />,
    },
    {
      accessor: 'fullName',
      Header: 'justFullName',
      isSortable: true,
      blueBg: true,
      Cell: ({ rowData: { id, fullName, tags, isManager } }) => (
        <FullNameCell
          onTagSelect={onTagSelect}
          id={id}
          fullName={fullName}
          tags={tags}
          tableWidth={tableWidth}
          setOpenTagsId={setOpenTagsId}
          openTagsId={openTagsId}
          hideAllTags={hideAllTags}
          isManager={isManager}
        />
      ),
    },
    {
      accessor: 'lastSeen',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ContactsTable__LastSeenHeaderWrapper">
          <FM id="justLastSeen">{txt => <span className="ContactsTable__LastSeenTitle">{txt}</span>}</FM>
          <FM id="justSortBy">{txt => <span className="ContactsTable__LastSeenInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { lastSeen } }) => (lastSeen ? <DateCell value={lastSeen} hideTime /> : '---'),
    },
    {
      accessor: 'totalIn',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ContactsTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell
            className="ContactsTable__CustomMoneyHeaderCellTitle"
            heightMaxContent
            value={contacts.total_in_sum}
          />
          <FM id="amountIn">{txt => <span className="ContactsTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalIn } }) => <MoneyCell crmStyle value={totalIn} />,
    },
    {
      accessor: 'totalOut',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ContactsTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell
            className="ContactsTable__CustomMoneyHeaderCellTitle"
            heightMaxContent
            value={contacts.total_out_sum}
          />
          <FM id="amountOut">{txt => <span className="ContactsTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalOut } }) => <MoneyCell crmStyle value={totalOut} />,
    },
    {
      accessor: 'totalDiff',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ContactsTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell className="ContactsTable__CustomMoneyHeaderCellTitle" heightMaxContent value={totalDiff} />
          <FM id="amountInOut">{txt => <span className="ContactsTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalDiff } }) => <MoneyCell crmStyle value={totalDiff} />,
    },
    {
      Header: 'justBalance',
      isSortable: false,
      Cell: ({ rowData: { balance } }) => <MoneyCell crmStyle value={balance} />,
    },
    {
      Header: 'justLastComment',
      Cell: ({ rowData: { lastTask } }) => <span className="LastCommentCell">{lastTask?.comment ?? '—'}</span>,
    },
  ];
  if (withClick2Call) {
    columns.push({
      Header: 'justCall',
      Cell: ({ rowData: { id } }) => (
        <ActionsButton
          onClickFunc={evt => {
            evt.stopPropagation();
            toggleCallModalOpen(id, evt.target);
          }}
          onMouseEnterFunc={evt => {
            evt.stopPropagation();
            toggleCallModalOpen(id, evt.target, true);
          }}>
          <PhoneIcon />
        </ActionsButton>
      ),
    });
  }

  return (
    <div
      className={classNames('ContactsTable', {
        'ContactsTable--click2call': withClick2Call,
      })}
      ref={contactsTableRef}>
      <PageContentPreloader ready={contactsAreLoaded} type="crmTable">
        {/* temporaly full data due backend not sending count, and otehrs */}
        <NewCRMTable
          data={contacts.results ? contacts.results : contacts}
          columns={columns}
          setTableWidth={setTableWidth}
          onMouseLeave={evt => {
            evt.stopPropagation();
            setOpenTagsId('');
          }}
        />
        {!!contacts.count && <NewCRMPagination count={contacts.count} />}
      </PageContentPreloader>
      {isCallModalOpen ? (
        <ProvidersPopup id={modalId} buttonPosition={buttonPosition} toggleModalOpen={toggleCallModalOpen} />
      ) : null}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ContactsTable.propTypes = {
  contacts: PropTypes.object.isRequired,
  contactsAreLoaded: PropTypes.bool.isRequired,
  getContacts: PropTypes.func.isRequired,
  getContactsFavorites: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      contacts: state.crm.contacts.contacts,
      contactsAreLoaded: state.crm.contacts.contactsAreLoaded,
      profilePerms: state.user.profile.perms,
      addons: state.interfaceConfig.addons,
    }),
    {
      getContacts: search => getContacts.request(search),
      getContactsFavorites: () => getContactsFavorites.request(),
    }
  )
)(ContactsTable);
