import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm, Field, change } from 'redux-form';
import { required } from 'redux-form-validators';

import {
  ReduxTextField,
  TextAreaField,
  FileField,
  DefaultButton,
  ReduxSelectField,
  Agreements,
} from 'common/components';

import { createIssue, toggleCreateIssueModal, getClientIssues, getClientIssue } from 'client/support/_redux';

import { staticStyles } from './style';

const IssueForm = ({
  handleSubmit,
  isPartnershipRequest,
  partnership,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const [supportAgentLanguageOptions, setSupportAgentLanguageOptions] = useState([]);
  const submit = handleSubmit(values => createIssue({ ...values, isPartnershipRequest }, dispatch));

  useEffect(() => {
    axios.get('/support/languages/').then(({ data: { languages } }) => {
      setSupportAgentLanguageOptions(languages);
      if (languages.length === 1) {
        dispatch(change('IssueForm', `language`, languages[0].value));
      }
    });
  }, []);

  return (
    <form className="IssueForm" onSubmit={submit}>
      <div className="IssueForm__inputs-inner">
        <Field
          component={ReduxTextField}
          autoComplete="off"
          type="text"
          name="title"
          textId="justTitle"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={ReduxSelectField}
          options={supportAgentLanguageOptions}
          name="language"
          textId="createIssueModalSupportAgentLanguage"
          searchable
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={TextAreaField}
          name="description"
          textId="justDescription"
          rows="4"
          cols="150"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={FileField}
          name="attachments"
          formatNameId="imagesFormats"
          fileNameId="justAttachments"
          accept="image/png,image/jpeg"
          multiple
          disabled={submitting}
        />
        {isPartnershipRequest && (
          <div className="IssueForm__agreements">
            <Agreements agreements={partnership.agreements} submitting={submitting} />
          </div>
        )}
      </div>
      <DefaultButton
        textId="justSubmit"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

IssueForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isPartnershipRequest: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  partnership: PropTypes.object,
};

IssueForm.defaultProps = {
  isPartnershipRequest: false,
  partnership: {},
  error: '',
};

export default reduxForm({
  form: 'IssueForm',
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch(toggleCreateIssueModal());
    dispatch(getClientIssues.request());
    dispatch(getClientIssue.request(result.id));
    if (props.onSubmitFunc) {
      dispatch(props.onSubmitFunc());
    }
  },
})(IssueForm);
export { IssueForm };
