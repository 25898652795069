import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextAreaField {
    position: relative;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    textarea:disabled {
      opacity: 1 !important;
    }
    textarea:disabled + label {
      cursor: default !important;
    }

    &__inp {
      border-radius: 8px;
      border: 1px solid #f3f3f3;
      background: #fff;
    }
  }

  .TextAreaField__label {
    position: absolute;
    top: 0;
    left: 16px;
    z-index: 10;

    font-size: 10px;
    line-height: 1.4;
    color: #999;
    background: #fff;
    padding: 0 4px;

    &--text {
      position: relative;
      top: -7px;
      z-index: 10;
    }

    &--error {
      color: #ff4085;
    }
  }

  .TextAreaField__error {
    width: 100%;
    display: inline-block;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .TextAreaField__label {
      transition: none;
    }
  }

  @supports (-ms-ime-align: auto) {
    .TextAreaField__label {
      transition: none;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .TextAreaField {
      &__error {
        textarea,
        textarea:focus {
          border-color: ${colors.error.hex};
        }
        label {
          color: ${colors.error.hex};
        }
      }
      &__haveText {
        textarea {
          color: ${colors.black.hex} !important;
        }
      }
      textarea:disabled {
        background: ${colors.disabled.hex};
        color: ${colors.black.hex};
        -webkit-text-fill-color: ${colors.black.hex};
      }
    }

    .TextAreaField__label {
      /* color: ${colors.gray.hex}; */
      &.haveText {
        color: ${colors.gray.hex};
      }
    }

    .TextAreaField__error {
      color: ${colors.error.hex};
      label {
        color: ${colors.error.hex};
      }
      textarea {
        border-color: ${colors.error.hex};
      }
    }

    .TextAreaField__inp {
      color: ${colors.white.hex};
      &.active {
        color: ${colors.black.hex};
      }
      &.haveText {
        color: ${colors.black.hex};
      }
      /* &:-webkit-autofill + .TextAreaField__label {
        color: ${colors.gray.hex};
      } */
    }
  `;
};

export { staticStyles, getDynamicStyles };
