import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  investCRMStrategies: {},
  investCRMStrategiesAreLoaded: false,

  investCRMStrategyInvestors: {},
  investCRMStrategyInvestorsAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.BO_GET_INVEST_CRM_STRATEGIES[REQUEST]:
      return { ...state, investCRMStrategiesAreLoaded: false };
    case ActionTypes.BO_GET_INVEST_CRM_STRATEGIES[SUCCESS]:
      return { ...state, investCRMStrategies: action.data, investCRMStrategiesAreLoaded: true };
    case ActionTypes.BO_GET_INVEST_CRM_STRATEGIES[FAILURE]:
      return { ...state, error: action.error, investCRMStrategiesAreLoaded: false };

    case ActionTypes.BO_TOGGLE_INVEST_CRM_STRATEGY[REQUEST]:
      return { ...state, investCRMStrategiesAreLoaded: false };
    case ActionTypes.BO_TOGGLE_INVEST_CRM_STRATEGY[SUCCESS]:
      return { ...state, investCRMStrategiesAreLoaded: true };
    case ActionTypes.BO_TOGGLE_INVEST_CRM_STRATEGY[FAILURE]:
      return { ...state, error: action.error, investCRMStrategiesAreLoaded: true };

    case ActionTypes.BO_GET_INVEST_CRM_STRATEGY_INVESTORS[REQUEST]:
      return { ...state, investCRMStrategyInvestorsAreLoaded: false };
    case ActionTypes.BO_GET_INVEST_CRM_STRATEGY_INVESTORS[SUCCESS]:
      return { ...state, investCRMStrategyInvestors: action.data, investCRMStrategyInvestorsAreLoaded: true };
    case ActionTypes.BO_GET_INVEST_CRM_STRATEGY_INVESTORS[FAILURE]:
      return { ...state, error: action.error, investCRMStrategyInvestorsAreLoaded: false };

    case ActionTypes.BO_TOGGLE_CRM_INVESTMENT[REQUEST]:
      return { ...state, investCRMStrategyInvestorsAreLoaded: false };
    case ActionTypes.BO_TOGGLE_CRM_INVESTMENT[SUCCESS]:
      return { ...state, investCRMStrategyInvestorsAreLoaded: true };
    case ActionTypes.BO_TOGGLE_CRM_INVESTMENT[FAILURE]:
      return { ...state, error: action.error, investCRMStrategyInvestorsAreLoaded: true };

    default:
      return state;
  }
};

export default reducer;
