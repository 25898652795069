import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import { processChoiceValues } from 'crm/_newComponents/FastFilters/helpers';
import { setModifiedFastFilters, getCRMCustomFilter } from 'crm/_redux/actions';

import InputIntegerChoice from './_components/InputIntegerChoice';
import InputDate from './_components/InputDate';
import InputStr from './_components/InputStr';
import { staticStyles } from './style';
import InputRange from './_components/InputRange';

const FastFilters = ({ fastFiltersModified, onChange, getFastFiltersFunc, fastFiltersSelector }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isFirstLaunch, setIsFirstLaunch] = useState(true);
  const fastFilters = useSelector(fastFiltersSelector);
  const currentCustomFilter = useSelector(state => state.crm.common.customFilter);

  const currentUrl = useMemo(() => location.search.replace('?', ''), [location.search]);
  const customFilterIdParam = queryString.parse(currentUrl).filterId;

  useEffect(() => {
    if (Object.keys(fastFilters).length !== 0) {
      const modifiedFilters = processChoiceValues(
        fastFilters,
        queryString.parse(sessionStorage.getItem('fastFiltersQuery') || ''),
        currentCustomFilter
      );

      dispatch(setModifiedFastFilters(modifiedFilters));
    }
  }, [fastFilters, currentCustomFilter]);

  useEffect(() => {
    dispatch(getFastFiltersFunc.request());
  }, [dispatch]);

  useEffect(() => {
    if (customFilterIdParam) {
      dispatch(getCRMCustomFilter.request({ id: customFilterIdParam }));
    }
  }, [customFilterIdParam]);

  const urlParams = useMemo(() => {
    if (Object.keys(fastFiltersModified).length === 0) return '';
    const queryObjects = {};
    const justArrayOfQueryParams = [];
    Object.keys(fastFiltersModified).forEach(key => {
      const currentFilter = fastFiltersModified[key];
      justArrayOfQueryParams.push(currentFilter.query_param);

      if (currentFilter.inputType === 'inputStr') {
        queryObjects[currentFilter.query_param] = currentFilter.value;
      }

      if (currentFilter.inputType === 'inputDate') {
        let selectedValues = null;
        currentFilter.choiceValues.forEach(({ value, label, isSelected }) => {
          if (isSelected && label !== 'justAll') {
            selectedValues = value.map(val => moment(val).format('YYYY-MM-DD'));
          }
        });

        if (selectedValues) {
          queryObjects[currentFilter.query_param] = selectedValues;
        }
      }

      if (
        currentFilter.inputType === 'inputIntegerChoice' ||
        currentFilter.inputType === 'inputStringChoice' ||
        currentFilter.inputType === 'inputBoolean'
      ) {
        let selectedValues = null;

        currentFilter.choiceValues.forEach(({ value, isSelected }) => {
          if (isSelected && value !== 'All') {
            if (!selectedValues) {
              selectedValues = [];
            }
            selectedValues.push(value);
          }
        });

        if (selectedValues) {
          queryObjects[currentFilter.query_param] = selectedValues;
        }
      }

      if (currentFilter.inputType === 'inputRange') {
        const enteredValues = currentFilter.enteredValues;
        if (enteredValues.length > 0) {
          queryObjects[currentFilter.query_param] = enteredValues;
        }
      }
    });

    const allParams = queryString.parse(location.search);
    justArrayOfQueryParams.forEach(param => delete allParams[param]);
    Object.keys(queryObjects).forEach(key => {
      if (queryObjects[key]) {
        allParams[key] = queryObjects[key];
      }
    });

    const stringified = queryString.stringify({ ...allParams });

    return stringified;
  }, [fastFiltersModified, currentUrl, location]);

  useEffect(() => {
    if (!isFirstLaunch) {
      history.replace({ ...location, search: urlParams });

      sessionStorage.setItem('fastFiltersQuery', urlParams);
    } else {
      const savedQuery = sessionStorage.getItem('fastFiltersQuery') || '';
      history.replace({ ...location, search: savedQuery });

      setIsFirstLaunch(false);
    }
  }, [urlParams]);

  const onInputChange = filterName => inputValue => {
    dispatch(
      setModifiedFastFilters({
        ...fastFiltersModified,
        [filterName]: {
          ...fastFiltersModified[filterName],
          choiceValuesAfterSearch: fastFiltersModified[filterName].choiceValues.filter(it =>
            it.name.toLowerCase().includes(inputValue.toLowerCase())
          ),
        },
      })
    );
  };

  return (
    <div className="FastFilters">
      {Object.keys(fastFiltersModified)
        .filter(filterName => fastFiltersModified[filterName].isDisplayed)
        .map(filterName => {
          const currentFastFilter = fastFiltersModified[filterName];
          const withInput =
            currentFastFilter.inputType === 'inputIntegerChoice' || currentFastFilter.inputType === 'inputStringChoice';
          if (
            currentFastFilter.inputType === 'inputIntegerChoice' ||
            currentFastFilter.inputType === 'inputStringChoice' ||
            currentFastFilter.inputType === 'inputBoolean'
          ) {
            return (
              <InputIntegerChoice
                isDisabled={!!customFilterIdParam}
                key={`fastFilter-${filterName}`}
                onInputChange={withInput ? onInputChange(filterName) : () => {}}
                options={
                  withInput
                    ? fastFiltersModified[filterName].choiceValuesAfterSearch
                    : fastFiltersModified[filterName].choiceValues
                }
                name={filterName}
                multi={currentFastFilter.inputType !== 'inputBoolean'}
                isNew={
                  filterName === 'accountTypeContactFilter' ||
                  filterName === 'tagContactFilter' ||
                  filterName === 'clientTypeContactFilter'
                }
                withInput={withInput}
                onSelect={value => {
                  onChange(filterName, currentFastFilter.inputType, value);
                }}
              />
            );
          }

          if (currentFastFilter.inputType === 'inputDate') {
            return (
              <InputDate
                isDisabled={!!customFilterIdParam}
                key={`fastFilter-${filterName}`}
                options={fastFiltersModified[filterName].choiceValues}
                name={filterName}
                onSelect={value => {
                  onChange(filterName, currentFastFilter.inputType, value);
                }}
              />
            );
          }

          if (currentFastFilter.inputType === 'inputStr') {
            return (
              <InputStr
                isDisabled={!!customFilterIdParam}
                key={`fastFilter-${filterName}`}
                name={filterName}
                value={currentFastFilter.value}
                onChange={value => {
                  onChange(filterName, currentFastFilter.inputType, value);
                }}
              />
            );
          }

          if (currentFastFilter.inputType === 'inputRange') {
            return (
              <InputRange
                isDisabled={!!customFilterIdParam}
                key={`fastFilter-${filterName}`}
                name={filterName}
                enteredValues={currentFastFilter.enteredValues}
                onChange={enteredValues => {
                  onChange(filterName, currentFastFilter.inputType, enteredValues);
                }}
              />
            );
          }

          return '';
        })}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

FastFilters.propTypes = {
  fastFiltersModified: PropTypes.object.isRequired,
  getFastFiltersFunc: PropTypes.object.isRequired,
  fastFiltersSelector: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FastFilters;
