import React from 'react';
import PropTypes from 'prop-types';
import { ReactDataTable, DateCell } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const InnerWithdrawRequestTable = ({ data, theme }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: 'justId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span className="InnerWithdrawRequestTable__id">{id}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justAccount',
      accessor: 'accountLogin',
      Cell: ({
        row: {
          original: { accountLogin },
        },
      }) => <span className="InnerWithdrawRequestTable__account">{accountLogin}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justCommission',
      accessor: 'commission',
      Cell: ({
        row: {
          original: { commission },
        },
      }) => (
        <span className="InnerWithdrawRequestTable__commission">
          {commission.amount} {commission.currency}
        </span>
      ),
    },
    {
      Header: 'justAmount',
      accessor: 'amount',
      Cell: ({
        row: {
          original: { amount },
        },
      }) => (
        <span className="InnerWithdrawRequestTable__amount">
          {amount.amount} {amount.currency}
        </span>
      ),
    },
    {
      Header: 'boPaymentSystem',
      accessor: 'paymentSystem',
      Cell: ({
        row: {
          original: { paymentMethodTitle },
        },
      }) => <span className="InnerWithdrawRequestTable__payment-system">{paymentMethodTitle}</span>,
    },
    {
      Header: 'boCreated',
      accessor: 'created',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
  ];
  /* eslint-enable */

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="InnerWithdrawRequestTable">
      <ReactDataTable data={data} columns={columns} />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

InnerWithdrawRequestTable.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

InnerWithdrawRequestTable.defaultProps = {
  theme: {},
};

export default withTheme()(InnerWithdrawRequestTable);
export { InnerWithdrawRequestTable };
