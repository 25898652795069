import { put, call, fork, take } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* requestEmailConfirmationSaga(email) {
  try {
    const { status, data } = yield call(api.requestEmailConfirmation, email);
    if (status < 300) {
      yield put(ActionTypes.requestEmailConfirmation.success());
    } else {
      const formError = new SubmissionError(data.detail ? { _error: data.detail } : data);
      yield put(ActionTypes.requestEmailConfirmation.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.requestEmailConfirmation.failure());
    throw e;
  }
}

export function* requestEmailConfirmationWatcherSaga() {
  while (true) {
    const {
      payload: { email },
    } = yield take(ActionTypes.requestEmailConfirmation.REQUEST);
    yield call(requestEmailConfirmationSaga, email);
  }
}

export default [fork(requestEmailConfirmationWatcherSaga)];
