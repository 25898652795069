import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserMarksTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(tr) {
          grid-template-columns: repeat(7, minmax(100px, 1fr)) minmax(100px, 2fr);
        }
        :global(.ReactDataTable__tbody-cell) {
          :global(.time) {
            display: block;
            color: #777777;
            font-weight: 300;
          }
        }
        :global(.ReactDataTable__tbody-row) {
          box-shadow: none;
        }
      }
    }
  }
`;

export { staticStyles };
