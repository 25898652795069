import css from 'styled-jsx/css'

const staticStyles = css.global`
  .ConfirmResetPasswordForm {
    text-align: left;
  }

  .ConfirmResetPasswordForm__button-inner {
    text-align: center;
  }
`

export default staticStyles
