import React from 'react';

const RequestApproveIcon = () => (
  <div>
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M13.3 11.1C13.3 10.9 13.3 10.7 13.2 10.6L9.8 7.2C9.8 7.1 9.6 7 9.5 7C9.4 7 9.2 7.1 9.1 7.2L5.8 10.7C5.7 10.9 5.6 11.1 5.7 11.3C5.8 11.5 6 11.6 6.2 11.6C6.3 11.6 6.5 11.5 6.6 11.4L9 8.8V16.5C9 16.8 9.2 17 9.5 17C9.8 17 10 16.8 10 16.5V8.8L12.5 11.4C12.6 11.5 12.8 11.6 13 11.5C13.1 11.5 13.2 11.3 13.3 11.1ZM19 8.9V2.2C19 1 18 0 16.8 0H2.2C1 0 0 1 0 2.2V11.8C0 12.4 0.2 13 0.6 13.4C1 13.8 1.6 14 2.2 14H3C3.3 14 3.6 13.7 3.6 13.4C3.6 13.1 3.4 12.9 3.1 12.9H2.2C1.6 13 1 12.4 1 11.8V3.8H18V8.8C15.3 9.1 13.2 11.4 13.2 14.2C13.2 17.2 15.7 19.7 18.7 19.7C21.7 19.7 24.2 17.2 24.2 14.2C24.1 11.4 21.8 9.1 19 8.9ZM21.5 13L18.5 16.8C18.4 16.9 18.3 17 18.1 17C18 17 17.8 16.9 17.7 16.8L15.8 14.9C15.6 14.7 15.6 14.4 15.8 14.2C16 14 16.3 14 16.5 14.2L18 15.7L20.6 12.3C20.8 12.1 21.1 12 21.3 12.2C21.7 12.4 21.7 12.7 21.5 13Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="4.25728"
          y1="21.159"
          x2="26.0599"
          y2="13.119"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#43B6E8" />
          <stop offset="1" stopColor="#33EEC7" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="24.1" height="19.8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default RequestApproveIcon;
