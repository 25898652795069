import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_BONUSES_INFO_LIST = createRequestTypes('GET_BONUSES_INFO_LIST');
export const getBonusesInfoList = {
  request: search => action(GET_BONUSES_INFO_LIST[REQUEST], { search }),
  success: data => action(GET_BONUSES_INFO_LIST[SUCCESS], { data }),
  failure: error => action(GET_BONUSES_INFO_LIST[FAILURE], { error }),
};

export const GET_BONUS_INFO = createRequestTypes('GET_BONUS_INFO');
export const getBonusInfo = {
  request: data => action(GET_BONUS_INFO[REQUEST], data),
  success: data => action(GET_BONUS_INFO[SUCCESS], { data }),
  failure: error => action(GET_BONUS_INFO[FAILURE], { error }),
};

export const GET_BONUS_ACCOUNT_TYPES = createRequestTypes('GET_BONUS_ACCOUNT_TYPES');
export const getBonusAccountTypes = {
  request: () => action(GET_BONUS_ACCOUNT_TYPES[REQUEST]),
  success: data => action(GET_BONUS_ACCOUNT_TYPES[SUCCESS], { data }),
  failure: error => action(GET_BONUS_ACCOUNT_TYPES[FAILURE], { error }),
};

export const DELETE_BONUS_INFO = createRequestTypes('DELETE_BONUS_INFO');
export const deleteBonusInfo = {
  request: data => action(DELETE_BONUS_INFO[REQUEST], data),
  success: () => action(DELETE_BONUS_INFO[SUCCESS]),
  failure: error => action(DELETE_BONUS_INFO[FAILURE], { error }),
};

export const createBonusInfo = createFormAction('CREATE_BONUS_INFO');

export const editBonusInfo = createFormAction('EDIT_BONUS_INFO');

export const GET_BONUS_TYPES = createRequestTypes('GET_BONUS_TYPES');
export const getBonusTypes = {
  request: () => action(GET_BONUS_TYPES[REQUEST]),
  success: data => action(GET_BONUS_TYPES[SUCCESS], { data }),
  failure: error => action(GET_BONUS_TYPES[FAILURE], { error }),
};
