import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCallProvidersList, makeACall } from 'backoffice/users/_redux';
import { splitCamelCase } from 'common/utils/helpers';
import { FormattedMessage } from 'react-intl';
import staticStyles from './style';

const ProvidersPopup = ({
  getCallProvidersList,
  callProvidersList,
  callProvidersListIsLoaded,
  makeACall,
  id,
  buttonPosition,
  toggleModalOpen,
}) => {
  useEffect(() => {
    getCallProvidersList();
  }, [getCallProvidersList]);

  const handleProviderChoose = (id, provider) => {
    makeACall({ id, provider });
  };

  const callProviders = useMemo(() => {
    const providers = [];
    Object.keys(callProvidersList).forEach(elem => {
      const editedElem = elem.replace('Setting', '');
      if (callProvidersList[elem]) {
        providers.push({
          value: elem,
          label: splitCamelCase(editedElem),
        });
      }
    });
    return providers;
  }, [callProvidersList]);

  const style = {
    top: buttonPosition[0],
    left: buttonPosition[1],
  };

  return (
    <div
      className="ProvidersPopup__popup"
      style={style}
      onMouseLeave={evt => {
        evt.stopPropagation();
        toggleModalOpen(id, evt.target);
      }}>
      {callProvidersListIsLoaded && (
        <div className="ProvidersPopup__items">
          {callProviders.length ? (
            callProviders.map((provider, index) => (
              <button
                className="ProvidersPopup__item"
                onClick={() => handleProviderChoose(id, provider.value)}
                key={`provider-${index}`}>
                {provider.label}
              </button>
            ))
          ) : (
            <FormattedMessage id="noProviders">{txt => <p>{txt}</p>}</FormattedMessage>
          )}
        </div>
      )}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

ProvidersPopup.propTypes = {
  getCallProvidersList: PropTypes.func.isRequired,
  callProvidersList: PropTypes.object.isRequired,
  callProvidersListIsLoaded: PropTypes.bool.isRequired,
  makeACall: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  buttonPosition: PropTypes.array.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
};

export default compose(
  connect(
    state => ({
      callProvidersList: state.backoffice.users.callProvidersList,
      callProvidersListIsLoaded: state.backoffice.users.callProvidersListIsLoaded,
    }),
    {
      getCallProvidersList: () => getCallProvidersList.request(),
      makeACall: (id, provider) => makeACall.request(id, provider),
    }
  )
)(ProvidersPopup);
export { ProvidersPopup };
