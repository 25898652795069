import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ModalHOC } from 'common';
import {
  deleteContactNote,
  getContactNoteDocument,
  getContactNotes,
  removeContactNoteDocument,
} from 'crm/users/_redux/actions';
import { PageContentPreloader } from 'common/components';

import { BaseButton, BasicCRMModal } from 'crm/_components';
import { TopTab, ContactNote, NoContactNotes } from './_components';
import { staticStyles } from './style';
import ContactNoteCreateForm from '../ContactNoteCreateForm';
import ContactNoteEditForm from '../ContactNoteEditForm';

const TabNotes = ({
  notes,
  notesAreLoaded,
  externalClass,
  getContactNotes,
  deleteContactNote,
  removeContactNoteDocument,
  getContactNoteDocument,
}) => {
  const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const { id } = useParams();
  const [noteId, setNoteId] = useState(null);

  useEffect(() => {
    getContactNotes(id);
  }, [getContactNotes, id]);

  const openCreateNoteModal = () => {
    setIsCreateNoteModalOpen(true);
  };

  const closeCreateNoteModal = () => {
    setIsCreateNoteModalOpen(false);
  };

  const handleDownloadNote = documentId => {
    getContactNoteDocument({ userId: id, documentId });
  };

  const handleRemoveNoteDocument = documentId => {
    removeContactNoteDocument({ userId: id, documentId });
  };

  const toggleEditNoteModal = () => {
    setIsEditNoteModalOpen(!isEditNoteModalOpen);
  };
  const handleDeleteNote = id => {
    setIsDeleteNoteModalOpen(true);
    setNoteId(id);
  };

  const handleEditNote = id => {
    toggleEditNoteModal();
    setNoteId(id);
  };

  return (
    <div className={classNames('TabNotes', externalClass)}>
      {notesAreLoaded && !!notes.length && <TopTab openCreateNoteModal={openCreateNoteModal} />}
      <PageContentPreloader ready={notesAreLoaded} type="smallLines">
        {notesAreLoaded && notes.length ? (
          notes.map(note => (
            <ContactNote
              key={note.id}
              noteId={note.id}
              createdBy={note.createdBy}
              created={note.created}
              title={note.title}
              text={note.text}
              documents={note.documents}
              handleDownloadNote={handleDownloadNote}
              handleEditNote={handleEditNote}
              handleDeleteNote={handleDeleteNote}
              handleRemoveNoteDocument={handleRemoveNoteDocument}
            />
          ))
        ) : (
          <NoContactNotes openCreateNoteModal={openCreateNoteModal} />
        )}
      </PageContentPreloader>

      <BasicCRMModal captionId="crmAddingNewNote" isOpen={isCreateNoteModalOpen} onRequestClose={closeCreateNoteModal}>
        <ContactNoteCreateForm onRequestClose={closeCreateNoteModal} onSubmitSuccess={closeCreateNoteModal} />
      </BasicCRMModal>
      <BasicCRMModal captionId="crmEditNote" isOpen={isEditNoteModalOpen} onRequestClose={toggleEditNoteModal}>
        <ContactNoteEditForm
          onRequestClose={toggleEditNoteModal}
          userId={id}
          noteId={noteId}
          onSubmitSuccess={toggleEditNoteModal}
          initialValues={notes.find(note => note.id === noteId)}
        />
      </BasicCRMModal>
      <BasicCRMModal
        captionId="confirmText"
        isOpen={isDeleteNoteModalOpen}
        onRequestClose={() => {
          setIsDeleteNoteModalOpen(false);
        }}>
        <FormattedMessage id="confirmDeleteNote" />
        <div className="TabNotes__ModalButtons">
          <BaseButton
            color="white"
            textId="justCancel"
            onClick={() => {
              setIsDeleteNoteModalOpen(false);
            }}
          />
          <BaseButton
            color="red"
            textId="justDelete"
            onClick={() => {
              deleteContactNote({ userId: id, noteId });
              setIsDeleteNoteModalOpen(false);
            }}
          />
        </div>
      </BasicCRMModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

TabNotes.propTypes = {
  notes: PropTypes.array.isRequired,
  notesAreLoaded: PropTypes.bool.isRequired,
  externalClass: PropTypes.string,
  getContactNotes: PropTypes.func.isRequired,
  deleteContactNote: PropTypes.func.isRequired,
  getContactNoteDocument: PropTypes.func.isRequired,
};

TabNotes.defaultProps = {
  externalClass: '',
};

export default compose(
  ModalHOC,
  connect(
    state => ({
      notes: state.crm.users.notes,
      notesAreLoaded: state.crm.users.notesAreLoaded,
    }),
    {
      getContactNotes: id => getContactNotes.request(id),
      deleteContactNote: data => deleteContactNote.request(data),
      getContactNoteDocument: data => getContactNoteDocument.request(data),
      removeContactNoteDocument: data => removeContactNoteDocument.request(data),
    }
  )
)(TabNotes);
