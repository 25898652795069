import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserNote {
    margin-bottom: 2px;
    &:last-child {
      margin: 0;
      border: none;
    }
  }

  .UserNote__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: #fff;
    padding: rem(20px);

    &-isPinned {
      position: absolute;
      top: 6px;
      right: 10px;
      width: 20px;
    }
  }

  .UserNote__top-line {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, minmax(150px, 1fr)) minmax(100px, 0.3fr) 0.5fr minmax(100px, 0.2fr);
    margin-bottom: rem(10px);
    border-bottom: 1px solid;
    padding-bottom: rem(20px);

    @include respond-to(sm) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    :global(span) {
      &:first-child {
        font-size: 11px;
        text-transform: uppercase;
        margin-bottom: 3px;
        display: block;
      }
    }
  }

  .UserNote__createdBy {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include respond-to(sm) {
      margin-bottom: rem(20px);
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      overflow: hidden;
    }
    .UserNote__line {
      @include respond-to(sm) {
        display: none;
      }
    }
  }

  .UserNote__line {
    padding: rem(0 5px);
    @include respond-to(sm) {
      margin-bottom: rem(20px);
    }
  }

  .UserNote__avatar {
    line-height: 0;
    margin-right: rem(15px);
    :global(img) {
      border-radius: 50%;
      width: rem(50px) !important;
      height: rem(50px) !important;
    }
  }

  .UserNote__title {
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      overflow: hidden;
    }
  }

  .UserNote__bottom-line {
    padding: rem(0 5px);
    :global(span) {
      font-size: rem(13px);
    }
    pre {
      word-break: break-all;
    }
  }

  .UserNote__payment {
    font-size: rem(13px);

    :global(.UserNote__payment-link) {
      display: flex;
      justify-content: flex-start;
      gap: 4px;
    }

    :global(span) {
      &:first-child {
        display: inline;
        text-transform: none;
        font-size: rem(13px);
      }
    }
  }

  .UserNote__data-action {
    @include respond-to(sm) {
      margin-bottom: 0 !important;
    }
  }

  .UserNote__files {
    margin-top: rem(20px);
    padding: rem(0 5px);
    :global(span) {
      font-size: rem(13px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserNote {
      border-color: ${colors.lightGray.hex};
    }

    .UserNote__top-line {
      border-bottom-color: ${colors.lightGray.hex};
      :global(span) {
        &:first-child {
          color: ${colors.gray.hex};
        }
      }
    }

    .UserNote__payment {
      :global(span) {
        &:first-child {
          color: ${colors.black.hex};
        }
      }
    }

    .UserNote__bottom-line {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }

    .UserNote__files {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
