import axios from 'axios';

const validateStatus = status => status < 500;

export const getClientCustomPages = () => axios.get('/utils/custom_pages/client/');

export const getTradingAccountTypes = () => axios.get('/platforms/account_type/');

export const getTradingAccountList = () => axios.get('/platforms/account/');

export const getAccountsPrefetch = () => axios.get('/platforms/account_prefetch/');

export const getTradingAccount = id => axios.get(`/platforms/account/${id}/`, { validateStatus });

export const getMTServersAPI = () => axios.get(`/invest/metadata/`);

export const getMTServersNewAPI = () => axios.get(`/invest_crm/strategy/metadata`);

export const getTradingAccountOperations = (id, status = 'open', start, end) =>
  axios.get(`/platforms/account/${id}/${status}_trades/`, {
    params: start && end ? { start, end } : undefined,
  });

export const getPartnerAccounts = () => axios.get(`/partnership/systems/account/list/`);
export const getPartnerAccount = accountId => axios.get(`/partnership/systems/account/${accountId}/`);
export const getPartnerAccountCommissions = (accountId, search) =>
  axios.get(`/partnership/systems/account/${accountId}/commissions/`, { params: { ...search } });
