import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteContactTag } from 'crm/users/_redux';
import { setTagColor } from 'common/utils/helpers';
import iconDelete from './images/iconDelete.svg';

import { staticStyles } from './style';

const TagItem = ({ tag, id, tagId, onTagSelect }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const handleTagDelete = e => {
    e.stopPropagation();
    dispatch(deleteContactTag.request({ id, tag: tagId, search: query }));
  };
  const tagStyle = useMemo(() => setTagColor(tag), [tag]);

  return (
    <button
      type="button"
      className="TagItem"
      style={tagStyle}
      onClick={e => {
        e.stopPropagation();
        onTagSelect(tagId);
      }}>
      {tag}
      <button className="TagItem__delete" onClick={e => handleTagDelete(e)}>
        <img className="ClientPage__left-tags-delete" width="10" height="12" src={iconDelete} alt="delete" />
      </button>
      <style jsx global>
        {staticStyles}
      </style>
    </button>
  );
};
// const history = useHistory();
// const location = useLocation();
// const handleTagClick = e => {
//   e.stopPropagation();
//   history.replace({
//     search: queryString.stringify({
//       ...query,
//       tag,
//     }),
//   });
// };

TagItem.propTypes = {
  tag: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tagId: PropTypes.string.isRequired,
};

export default compose()(TagItem);
