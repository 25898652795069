import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .SelectChallengeBlockTable {
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }

  .SelectChallengeBlockTableColumn__name > .Round {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 16px;
  }

  .SelectChallengeBlockTableColumn__name > .Name {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .SelectChallengeBlockTable > .SelectChallengeBlockTableColumn:nth-child(2) {
    background: #f2fafd;
  }

  .SelectChallengeBlockTable > .SelectChallengeBlockTableColumn:nth-child(2) .Round {
    border: 2px rgb(10, 161, 226) solid;
  }
  .SelectChallengeBlockTable > .SelectChallengeBlockTableColumn:nth-child(3) {
    background: #fffaf1;
  }
  .SelectChallengeBlockTable > .SelectChallengeBlockTableColumn:nth-child(3) .Round {
    border: 2px rgb(255, 153, 0) solid;
  }
  .SelectChallengeBlockTable > .SelectChallengeBlockTableColumn:nth-child(4) {
    background: #f5fbf5;
  }
  .SelectChallengeBlockTable > .SelectChallengeBlockTableColumn:nth-child(4) .Round {
    border: 2px rgb(57, 184, 65) solid;
  }

  .SelectChallengeBlockTableColumn {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 1200px) {
      width: 100%;
    }
  }

  .SelectChallengeBlockTableColumn__InfoColumn {
    background: white;
  }

  .SelectChallengeBlockTableColumn > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 58px;
    padding-left: 24px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media only screen and (min-width: 1200px) {
      width: 100%;
    }
  }

  .SelectChallengeBlockTableColumn > div:first-child {
    height: 88px;
  }

  .SelectChallengeBlockTableColumn > div:not(:first-child) {
    border-bottom: 1px solid #0000000d;
  }

  .SelectChallengeBlockTableColumn__InfoColumn > div {
    justify-content: flex-start !important;
  }

  .SelectChallengeBlockTableColumn > div > span {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export { staticStyles };
