import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import { staticStyles } from './style';

const AccountItem = ({ login, accountType }) => (
  <div className="AccountItem">
    <div className="TabFeedItem__Title">
      <FM id="crmAccountCreate" />
    </div>
    <div className="TabFeedItem__Description">
      {accountType?.replace('/', ' ')} {login}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

AccountItem.propTypes = {
  accountType: PropTypes.string,
  login: PropTypes.string,
};

AccountItem.defaultProps = {
  accountType: '',
  login: '',
};

export default AccountItem;
