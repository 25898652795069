import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const ClientsCellWithDropDown = ({ ibAccounts, theme }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const handleShowClients = ibAccount => history.push(`/backoffice/users/?partner_account=${ibAccount}`);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="ClientsCellWithDropDown">
      <FM id="boSeeClients">
        {txt => (
          <button
            className="ClientsCellWithDropDown__clients"
            type="button"
            onClick={e => {
              e.stopPropagation();
              if (ibAccounts.length === 1) {
                handleShowClients(ibAccounts[0]);
              } else {
                setIsOpen(!isOpen);
              }
            }}>
            {txt}
          </button>
        )}
      </FM>
      {isOpen && (
        <div className="ClientsCellWithDropDown__dropdown">
          {ibAccounts.map((ibAccount, ind) => (
            <button
              key={`ClientsCellWithDropDown-ibAccount-${ind}`}
              type="button"
              onClick={e => {
                e.stopPropagation();
                handleShowClients(ibAccount);
              }}
              className="ClientsCellWithDropDown__dropdown-item">
              {ibAccount}
            </button>
          ))}
        </div>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

ClientsCellWithDropDown.propTypes = {
  ibAccounts: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

ClientsCellWithDropDown.defaultProps = {
  theme: {},
};

export default withTheme()(ClientsCellWithDropDown);
