import css from 'styled-jsx/css';
import radioIcon from './radioIcon.svg';
import radioIconActive from './radioIconActive.svg';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RadioButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      width: 15px;
      height: 15px;
      background-image: url(${radioIcon});
      background-size: cover;

      &--active {
        background-image: url(${radioIconActive});
      }
    }

    &__txt {
      margin: 0 0 0 10px;
      user-select: none;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
