import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/vars';
  .InsertTemplate {
    &__template-selector {
    }
    &__description {
      display: flex;
      font-size: 13px;
      color: darkslategray;
    }
    &__textarea {
      height: 50px;
      padding: 15px 10px;
      font-weight: 500;
      color: darkgray;
    }
    .DefaultButton {
      margin-top: 3px;
    }
  }

  .InsertTemplateModal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .TextAreaRawHtmlField {
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
    &--size-big {
      margin-bottom: 13.5px;
    }
    &__label {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: rem(10px);
    }
    &__textarea {
      width: 100%;
      height: 320px;
      padding: 10px 10px;
      font-family: $ubuntu-font !important;
      font-size: 14px;
      line-height: 1.3;
      &--size-big {
        height: 499px;
      }
    }
  }
  :global(.DefaultButton) {
    width: max-content;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TextAreaRawHtmlField__textarea {
      &:focus {
        border-color: ${colors.primary.hex};
      }
      &--error {
        &:focus {
          border-color: ${colors.error.hex};
        }
        &:disabled {
          background: ${colors.disabled.hex};
          color: ${colors.black.hex};
          -webkit-text-fill-color: ${colors.black.hex};
        }
      }
    }

    .TextAreaRawHtmlField__error-message {
      color: ${colors.error.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
