import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/screen-size';

  .LangButton {
    display: flex;
    align-items: center;
    width: 200px;
    padding-left: 30px;
  }

  .LangButton__image {
    display: inline-block;
    vertical-align: middle;
    width: rem(30px);

    :global(img) {
      width: 100%;
      height: auto;
      position: relative;
      border: none;
      background: none;
      top: 1px;

      @include respond-to(sm) {
        top: 2px;
      }
    }
  }

  .LangButton__select-inner {
    display: inline-block;
    width: 100px;
    vertical-align: top;
    text-align: left;
  }

  .LangButton__label {
    display: flex;
    align-items: center;

    :global(img) {
      display: inline-block;
      width: 25px;
      height: auto;
      position: relative;
      bottom: 1px;
      border: none;
      background: none;
      margin-right: rem(${isRTL ? "0" : "5px"});
      margin-left: rem(${isRTL ? "5px" : "0"});
    }
  }

  .LangButton {
    .Select__control {
      border: none !important;
      padding: 0 !important;
    }

    .Select__input {
      padding: 0 !important;
      background: transparent !important;
    }

    .Select__input > input {
      background: transparent !important;
    }

    .Select__control > *:last-child {
      padding-right: rem(5px) !important;
    }

    .Select__menu {
      width: 120% !important;
      min-width: 180px;
      outline: none !important;
      right: ${isRTL ? "-105%" : "-50%"};
    }

    .Select__single-value{
      color: #fff;
    }

    .Select__indicators {
      display: none;
    }
  }
`

export { staticStyles }
