import queryString from 'query-string';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DefaultButton, NoItemsPanel, PageContentPreloader, PageLayout, SearchPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getPromoMaterialList } from '../../_redux';
import { PromoMaterialsTable } from '../../_components';

import { staticStyles } from './style';

const PromoManagementPage = ({
  promoMaterialList,
  promoMaterialListIsLoaded,
  getPromoMaterialList,
  availableLangs,
  history,
  location,
  intl,
}) => {
  const search = { page_size: 10, ...queryString.parse(location.search) };

  /* eslint-disable */
  useEffect(() => {
    getPromoMaterialList({ search })
  }, [getPromoMaterialList])
  /* eslint-enable */

  const handleCreateCampaign = () => history.push('/backoffice/promo/add');

  const handleRowClick = () => {};
  const filters = [
    {
      title: 'selectTypeOfPromo',
      accessor: 'type',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'banner', label: intl.formatMessage({ id: 'justBanner' }) },
        {
          value: 'widget',
          label: intl.formatMessage({ id: 'justWidgets' }),
        },
        { value: 'frame', label: intl.formatMessage({ id: 'justFrame' }) },
        { value: 'logo', label: intl.formatMessage({ id: 'justLogo' }) },
      ],
    },
    {
      title: 'selectLanguage',
      accessor: 'language',
      options: availableLangs.map(lang => ({ value: lang.slug, label: lang.niceName })),
    },
  ];

  return (
    <PageLayout captionSlug="justPromoManagement">
      <div className="PromoManagementPage__create-inner">
        <DefaultButton textId="justNewPromo" plus filled onClick={handleCreateCampaign} />
      </div>
      <PageContentPreloader ready={promoMaterialListIsLoaded} type="bigLine" firstLaunchOnly>
        {(!!location.search || !!promoMaterialList.count) && (
          <SearchPanel
            location={location}
            history={history}
            getList={getPromoMaterialList}
            filters={filters}
            params={{ search }}
          />
        )}
        {promoMaterialList.count ? (
          <PromoMaterialsTable
            data={promoMaterialList}
            loading={!promoMaterialList}
            location={location}
            history={history}
            getList={getPromoMaterialList}
            onRowClick={handleRowClick}
            params={{ search }}
          />
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={location.search ? 'noPromoMatchingTheParameters' : 'noPromoMaterialsYet'}
          />
        )}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

PromoManagementPage.propTypes = {
  promoMaterialList: PropTypes.object.isRequired,
  promoMaterialListIsLoaded: PropTypes.bool.isRequired,
  getPromoMaterialList: PropTypes.func.isRequired,
  availableLangs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      promoMaterialList: state.backoffice.promo.promoMaterialList,
      promoMaterialListIsLoaded: state.backoffice.promo.promoMaterialListIsLoaded,
      availableLangs: state.interfaceConfig.availableLangs,
    }),
    {
      getPromoMaterialList: data => getPromoMaterialList.request(data),
    }
  )
)(PromoManagementPage);
export { PromoManagementPage };
