import React from 'react';

import PropTypes from 'prop-types';

const CheckIcon = ({ className }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3ZM12.5 4.125C8.15076 4.125 4.625 7.65076 4.625 12C4.625 16.3492 8.15076 19.875 12.5 19.875C16.8492 19.875 20.375 16.3492 20.375 12C20.375 7.65076 16.8492 4.125 12.5 4.125ZM16.2782 9.35225C16.4735 9.54751 16.4952 9.85062 16.3433 10.0698L16.2782 10.1477L11.7727 14.6532C11.5775 14.8485 11.2744 14.8702 11.0552 14.7183L10.9773 14.6532L8.72725 12.4032C8.50758 12.1836 8.50758 11.8274 8.72725 11.6077C8.92251 11.4125 9.22562 11.3908 9.44484 11.5426L9.52275 11.6077L11.375 13.4603L15.4827 9.35225C15.7024 9.13258 16.0586 9.13258 16.2782 9.35225Z"
      fill="#389700"
    />
  </svg>
);

CheckIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

export default CheckIcon;
