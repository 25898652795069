import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';

import Phone from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const PhoneField = ({ input, textId, disabled = false, meta: { touched, active, error }, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div className={classNames('PhoneField', { error: touched && error }, { PhoneField__disabled: disabled })}>
      <div>
        <FM id={textId}>{() => <Phone international {...input} flags={flags} disabled={disabled} />}</FM>
        <label className={classNames('PhoneField__label', { active }, { haveText: input.value })} htmlFor={input.name}>
          <FM id={textId} />
        </label>
      </div>
      <span className="PhoneField__error">{touched && error}</span>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PhoneField.propTypes = {
  input: PropTypes.object.isRequired,
  textId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  theme: PropTypes.object,
};

PhoneField.defaultProps = {
  disabled: false,
  theme: {},
};

const ThemedPhone = withTheme()(PhoneField);

export default ThemedPhone;
export { PhoneField };
