import css from "styled-jsx/css";
import { rgba } from "common/styling/theme";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .UserCardsPanel {
    position: relative;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px);
    margin-bottom: rem(20px);
    @include respond-to(sm) {
      padding: rem(20px 15px);
    }

    .UserCardsPanel__cards {
      display: flex;

      > div {
        flex: 2;
        margin-right: rem(20px);

        @include respond-to(sm) {
          margin-right: 0;
        }
      }

      .UserCardsPanel__add-button {
        display: flex;
        margin-right: 0;
        justify-content: center;
        align-items: center;
      }
    }

    .UserCardsPanel__nav-info {
      position: absolute;
      top: 30px;
      right: 20px;

      display: flex;
      align-items: center;

      :global(.ActionsButton) {
        width: rem(10px);
        height: rem(10px);
        transform: rotate(45deg);

        &:first-child {
          margin-right: rem(5px);
        }

        &:nth-child(2) {
          margin-right: rem(30px);
        }
      }
      @include respond-to(sm) {
        display: none;
      }
    }

    .UserCardsPanel__mobile-cards {
      display: flex;
      flex-direction: column;
      margin-top: rem(10px);

      :global(.DefaultButton) {
        margin-top: rem(20px);
        max-width: 150px;
      }
    }
  }

  .UserCardsPanel {
    position: relative;
    margin: 0 0 20px 0;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 15px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__nav-info {
      top: auto !important;
      bottom: 42px;
      right: 0 !important;

      &--hide {
        display: none !important;
      }
    }

    &__title {
      margin: 0 0 30px 0;

      &--hide {
        margin: 0 0 0 0;
      }
    }

    &__cards {
      &--hide {
        display: none !important;
      }
    }

    &__mobile-cards {
      &--hide {
        display: none !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserCardsPanel {
      background-color: ${colors.white.hex};

      .UserCardsPanel__cards {
        > div:not(:last-child) {
          border-right: 1px solid ${colors.lightGray.hex};
        }
      }

      .UserCardsPanel__mobile-cards {
        > div {
          border-bottom: 1px solid ${colors.lightGray.hex};
        }
      }

      .UserCardsPanel__nav-info {
        :global(.ActionsButton) {
          border: solid ${rgba([colors.primary.rgb, 0.7])};
          &:first-child {
            &:disabled {
              border: solid ${colors.gray.hex};
              border-width: 0 0 1px 1px;
            }
            border-width: 0 0 1px 1px;
          }

          &:nth-child(2) {
            &:disabled {
              border: solid ${colors.gray.hex};
              border-width: 1px 1px 0 0;
            }
            border-width: 1px 1px 0 0;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
