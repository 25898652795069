import React from 'react';

const NoteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.28571 1.14286C1.65357 1.14286 1.14286 1.65357 1.14286 2.28571V13.7143C1.14286 14.3464 1.65357 14.8571 2.28571 14.8571H9.71429V11.4286C9.71429 10.4821 10.4821 9.71429 11.4286 9.71429H14.8571V2.28571C14.8571 1.65357 14.3464 1.14286 13.7143 1.14286H2.28571ZM10.8571 14.3821L14.3821 10.8571H11.4286C11.1143 10.8571 10.8571 11.1143 10.8571 11.4286V14.3821ZM0 2.28571C0 1.025 1.025 0 2.28571 0H13.7143C14.975 0 16 1.025 16 2.28571V9.91072C16 10.5179 15.7607 11.1 15.3321 11.5286L11.525 15.3321C11.0964 15.7607 10.5143 16 9.90714 16H2.28571C1.025 16 0 14.975 0 13.7143V2.28571Z"
      fill="#333333"
    />
  </svg>
);

export default NoteIcon;
