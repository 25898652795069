import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  templates: {},
  templatesAreLoaded: false,

  templateData: {},
  templateDataIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TEMPLATE_LIST[REQUEST]:
      return { ...state, templatesAreLoaded: false };
    case ActionTypes.GET_TEMPLATE_LIST[SUCCESS]:
      return { ...state, templates: action.data, templatesAreLoaded: true };
    case ActionTypes.GET_TEMPLATE_LIST[FAILURE]:
      return { ...state, error: action.error, templatesAreLoaded: false };

    case ActionTypes.GET_TEMPLATE_ITEM[REQUEST]:
      return { ...state, templateDataIsLoaded: false };
    case ActionTypes.GET_TEMPLATE_ITEM[SUCCESS]:
      return { ...state, templateData: action.data, templateDataIsLoaded: true };
    case ActionTypes.GET_TEMPLATE_ITEM[FAILURE]:
      return { ...state, error: action.error, templateDataIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
