import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CreateTaskForm {
    width: 456px;
    max-width: 100%;
    padding: 0 10px;
    &__title {
      text-align: center;
      font-size: rem(18px);
      font-weight: 500;
    }

    &__line {
      margin-bottom: rem(16px);
    }

    &__submit {
      text-align: center;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .CreateTaskForm__formError {
      color: ${colors.error.hex};
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 7px;
    }
  `;
};

export { staticStyles, getDynamicStyles };
