import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { CheckboxField, SwitchField, DefaultButton, ReduxTextField, FileField } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { createFile } from 'admin/files/_redux';
import { required } from 'redux-form-validators';
import { getDefaultCampaign } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const CampaignForm = ({
  campaignId,
  logo,
  handleSubmit,
  defaultCampaign,
  getDefaultCampaign,
  submitFunction,
  submitting,
  invalid,
  dispatch,
  theme,
  history,
  error,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getDefaultCampaign();
  }, [getDefaultCampaign]);

  const submit = handleSubmit(values =>
    submitFunction(
      {
        ...values,
        campaignId,
        logo: typeof values.file === 'string' ? values.logo : logo,
      },
      dispatch
    )
  );

  const handleAfterUpload = file => {
    const formData = new FormData();
    formData.set('title', file[0].name);
    formData.append('file', file[0]);
    createFile({ body: formData }, dispatch);
  };

  return (
    <form className="CampaignForm" onSubmit={submit}>
      <div className="CampaignForm__content">
        <div className="CampaignForm__settings-block">
          <FormattedMessage id="justSettings">
            {txt => <span className="CampaignForm__caption">{txt}</span>}
          </FormattedMessage>
          <Field component={ReduxTextField} type="text" name="title" textId="campaignName" validate={[required()]} />
          <Field component={CheckboxField} name="isDefault" textId="defaultCampaign" />
          {defaultCampaign && (
            <FormattedMessage id="onlyOneDefaultCampaignDescription" values={{ title: defaultCampaign.title }}>
              {txt => <span className="CampaignForm__description">{txt}</span>}
            </FormattedMessage>
          )}
          <div className="CampaignForm__active">
            <FormattedMessage id="justActive">{txt => <span>{txt}</span>}</FormattedMessage>
            <Field component={SwitchField} name="isActive" withText />
          </div>
        </div>
        <div className="CampaignForm__logo-block">
          <FormattedMessage id="campaignLogo">
            {txt => <span className="CampaignForm__caption">{txt}</span>}
          </FormattedMessage>
          <FormattedMessage id="extendedFileSupports">
            {txt => <span className="CampaignForm__description">{txt}</span>}
          </FormattedMessage>
          <Field
            component={FileField}
            name="file"
            formatNameId="imagesFormats"
            fileNameId="justAttachments"
            accept="image/png,image/jpeg,image/gif"
            handleAfterUpload={handleAfterUpload}
            disabled={submitting}
            validate={[required()]}
          />
        </div>
      </div>
      <div className="CampaignForm__buttons">
        <DefaultButton textId="doSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
        <DefaultButton textId="justCancel" onClick={() => history.goBack()} disabled={submitting} />
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

CampaignForm.propTypes = {
  logo: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitFunction: PropTypes.func.isRequired,
  getDefaultCampaign: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  defaultCampaign: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  campaignId: PropTypes.string,
  error: PropTypes.string,
  theme: PropTypes.object,
};

CampaignForm.defaultProps = {
  campaignId: null,
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      logo: state.admin.filesManagement.file.id || '',
      defaultCampaign: state.backoffice.promo.defaultCampaign,
    }),
    {
      getDefaultCampaign: () => getDefaultCampaign.request(),
    }
  ),
  reduxForm({
    form: 'CampaignForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.history.push('/backoffice/promo/campaigns');
    },
  })
)(CampaignForm);
export { CampaignForm };
