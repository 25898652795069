import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const Notification = ({ history, text, id, onCloseButtonClick, getIssue, markIssueAsViewed, openIssue, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleCloseButtonClick = () => onCloseButtonClick(id);
  const handleGoToIssueClick = () => {
    history.push('/support/');
    getIssue(id);
    openIssue(id);
    markIssueAsViewed(id);
    onCloseButtonClick(id);
  };

  return (
    <div className="Notification">
      <button type="button" className="Notification__close-button" onClick={handleCloseButtonClick} />
      {!openIssue ? (
        <Link to={`/mail/#${id}`} onClick={handleCloseButtonClick}>
          {text}
        </Link>
      ) : (
        <button type="button" onClick={handleGoToIssueClick}>
          {text}
        </button>
      )}

      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

Notification.propTypes = {
  history: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  getIssue: PropTypes.func,
  markIssueAsViewed: PropTypes.func,
  openIssue: PropTypes.func,
  theme: PropTypes.object,
};

Notification.defaultProps = {
  getIssue: null,
  markIssueAsViewed: null,
  openIssue: null,
  theme: {},
};

export default compose(withTheme(), withRouter)(Notification);
export { Notification };
