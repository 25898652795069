import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getUserPayments } from 'backoffice/users/_redux';
import { MoneyCell, StatusCell } from 'common/components/';
import { withTheme, isRTL } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const UserOperations = ({ user, getUserPayments, payments, loadingPayments, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [delayIsDone, setDelayIsDone] = useState(false);

  useEffect(() => {
    getUserPayments({ id: user.id });
  }, [getUserPayments, user.id]);

  useEffect(() => {
    const timer = setTimeout(() => setDelayIsDone(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const renderView = props => (
    <div
      {...props}
      style={{
        ...props.style,
        marginLeft: isRTL ? props.style.marginRight : 0,
        marginRight: isRTL ? 0 : props.style.marginRight,
      }}
    />
  );

  const OPERATIONS = {
    deposit: 'justDeposit',
    withdraw: 'justWithdraw',
    wallet_deposit: 'justDeposit',
    wallet_withdraw: 'justWithdraw',
    compensation: 'justCompensation',
    freeze: 'justFreeze',
    refund: 'justRefund',
    wallet_freeze: 'justFreeze',
    wallet_refund: 'justRefund',
  };

  return (
    <div className="UserOperations">
      {loadingPayments && delayIsDone ? (
        <Scrollbars renderView={renderView} className="BasicModal__scroll" autoHeight autoHeightMax={220}>
          {payments.length ? (
            <div>
              {payments.map(({ id, operation, amount, accountLogin, paymentMethodTitle, created, status }) => (
                <div className="UserOperationsTable" key={id}>
                  <div className="UserOperationsTable__col">
                    <div className="UserOperationsTable__line">
                      <FormattedMessage id="justType">
                        {txt => <span className="UserOperationsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <FormattedMessage id={OPERATIONS[operation]}>
                        {txt => <span className="UserOperationsTable__value">{`${txt}`}</span>}
                      </FormattedMessage>
                    </div>
                    <div className="UserOperationsTable__line">
                      <FormattedMessage id="justSum">
                        {txt => <span className="UserOperationsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserOperationsTable__value">
                        <MoneyCell value={amount || { amount: '0' }} />
                      </span>
                    </div>
                    <div className="UserOperationsTable__line">
                      <FormattedMessage id="justAccount">
                        {txt => <span className="UserOperationsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserOperationsTable__value">{accountLogin}</span>
                    </div>
                  </div>
                  <div className="UserOperationsTable__col">
                    <div className="UserOperationsTable__line">
                      <FormattedMessage id="justPayment">
                        {txt => <span className="UserOperationsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserOperationsTable__value">{paymentMethodTitle}</span>
                    </div>
                    <div className="UserOperationsTable__line">
                      <FormattedMessage id="justDate">
                        {txt => <span className="UserOperationsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserOperationsTable__value">
                        <FormattedDate month="2-digit" day="2-digit" year="numeric" value={created} />
                      </span>
                    </div>
                    <div className="UserOperationsTable__line">
                      <FormattedMessage id="boStatus">
                        {txt => <span className="UserOperationsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserOperationsTable__value">
                        <StatusCell statusCode={status} noDot />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <FormattedMessage id="noUserOperations">
              {txt => <div className="UserOperationsTable__description">{txt}</div>}
            </FormattedMessage>
          )}
        </Scrollbars>
      ) : (
        <FormattedMessage id="justLoading">{txt => <div className="LoadingText">{txt}</div>}</FormattedMessage>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserOperations.propTypes = {
  user: PropTypes.object.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  payments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  loadingPayments: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  // eslint-disable-next-line
  style: PropTypes.object,
};

UserOperations.defaultProps = {
  theme: {},
  style: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      payments: state.backoffice.users.payments,
      loadingPayments: state.backoffice.users.loadingPayments,
    }),
    {
      getUserPayments: id => getUserPayments.request(id),
    }
  )
)(UserOperations);

export { UserOperations };
