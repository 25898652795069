import { FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  // NEW
  investStrategiesNew: {},
  investStrategiesNewAreLoaded: false,

  myInvestStrategiesNew: {},
  myInvestStrategiesNewAreLoaded: false,
  // NEW-END

  investStrategyDetailNew: {},
  investStrategyDetailNewIsLoaded: false,

  strategyInvestmentsNew: {},
  strategyInvestmentsNewAreLoaded: false,

  investAwaitinigApproveNew: {},
  investAwaitinigApproveNewAreLoaded: false,

  pendingInvestApproveStrategyNew: false,
  pendingInvestRejectStrategyNew: false,
  investStrategyId: null,

  investments: {},
  investmentsAreLoaded: false,

  investmentsRequests: {},
  investmentsRequestsAreLoaded: false,

  investStatement: {},
  investStatementIsLoaded: false,

  investmentTrades: {},
  investmentTradesAreLoaded: false,

  investYieldChart: [],
  investYieldChartIsLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INVEST_YIELD_CHART_NEW[REQUEST]:
      return { ...state, investYieldChartIsLoaded: false };
    case ActionTypes.GET_INVEST_YIELD_CHART_NEW[SUCCESS]:
      return { ...state, investYieldChart: action.data, investYieldChartIsLoaded: true };
    case ActionTypes.GET_INVEST_YIELD_CHART_NEW[FAILURE]:
      return { ...state, error: action.error, investYieldChartIsLoaded: false };

    case ActionTypes.GET_INVEST_STATEMENT_NEW[REQUEST]:
      return { ...state, investStatementIsLoaded: false };
    case ActionTypes.GET_INVEST_STATEMENT_NEW[SUCCESS]:
      return { ...state, investStatement: action.data, investStatementIsLoaded: true };
    case ActionTypes.GET_INVEST_STATEMENT_NEW[FAILURE]:
      return { ...state, error: action.error, investStatementIsLoaded: false };

    case ActionTypes.GET_INVESTMENT_TRADES_NEW[REQUEST]:
      return { ...state, investmentTradesAreLoaded: false };
    case ActionTypes.GET_INVESTMENT_TRADES_NEW[SUCCESS]:
      return { ...state, investmentTrades: action.data, investmentTradesAreLoaded: true };
    case ActionTypes.GET_INVESTMENT_TRADES_NEW[FAILURE]:
      return { ...state, error: action.error, investmentTradesAreLoaded: false };

    case ActionTypes.GET_INVESTMENTS_NEW[REQUEST]:
      return { ...state, investments: {}, investmentsAreLoaded: false };
    case ActionTypes.GET_INVESTMENTS_NEW[SUCCESS]:
      return { ...state, investments: action.data, investmentsAreLoaded: true };
    case ActionTypes.GET_INVESTMENTS_NEW[FAILURE]:
      return { ...state, error: action.error, investmentsAreLoaded: false };

    case ActionTypes.GET_INVESTMENTS_REQUESTS_NEW[REQUEST]:
      return { ...state, investmentsRequestsAreLoaded: false };
    case ActionTypes.GET_INVESTMENTS_REQUESTS_NEW[SUCCESS]:
      return { ...state, investmentsRequests: action.data, investmentsRequestsAreLoaded: true };
    case ActionTypes.GET_INVESTMENTS_REQUESTS_NEW[FAILURE]:
      return { ...state, error: action.error, investmentsRequestsAreLoaded: false };

    case ActionTypes.GET_INVEST_STRATEGIES_APPROVE_NEW[REQUEST]:
      return { ...state, investAwaitinigApproveNewAreLoaded: false };
    case ActionTypes.GET_INVEST_STRATEGIES_APPROVE_NEW[SUCCESS]:
      return { ...state, investAwaitinigApproveNew: action.data, investAwaitinigApproveNewAreLoaded: true };
    case ActionTypes.GET_INVEST_STRATEGIES_APPROVE_NEW[FAILURE]:
      return { ...state, error: action.error, investAwaitinigApproveNewAreLoaded: false };

    case ActionTypes.SET_INVESTMENTS_ACTION_NEW[REQUEST]:
      return action.action === 'approve'
        ? {
            ...state,
            pendingInvestApproveStrategyNew: true,
            investStrategyId: action.investmentId,
          }
        : {
            ...state,
            pendingInvestRejectStrategyNew: true,
            investStrategyId: action.investmentId,
          };
    case ActionTypes.SET_INVESTMENTS_ACTION_NEW[SUCCESS]:
    case ActionTypes.SET_INVESTMENTS_ACTION_NEW[FAILURE]:
      return {
        ...state,
        pendingInvestApproveStrategyNew: false,
        pendingInvestRejectStrategyNew: false,
        investStrategyId: null,
      };

    case ActionTypes.GET_STRATEGY_INVESTMENTS_NEW[REQUEST]:
      return { ...state, strategyInvestmentsNewAreLoaded: false };
    case ActionTypes.GET_STRATEGY_INVESTMENTS_NEW[SUCCESS]:
      return { ...state, strategyInvestmentsNew: action.data, strategyInvestmentsNewAreLoaded: true };
    case ActionTypes.GET_STRATEGY_INVESTMENTS_NEW[FAILURE]:
      return { ...state, error: action.error, strategyInvestmentsNewAreLoaded: false };

    case ActionTypes.RETRIEVE_INVEST_STRATEGY_NEW[REQUEST]:
      return { ...state, investStrategyDetailNewIsLoaded: false };
    case ActionTypes.RETRIEVE_INVEST_STRATEGY_NEW[SUCCESS]:
      return { ...state, investStrategyDetailNew: action.data, investStrategyDetailNewIsLoaded: true };
    case ActionTypes.RETRIEVE_INVEST_STRATEGY_NEW[FAILURE]:
      return { ...state, error: action.error, investStrategyDetailNewIsLoaded: false };
    // NEW
    case ActionTypes.GET_INVEST_STRATEGIES_NEW[REQUEST]:
      return { ...state, investStrategiesNewAreLoaded: false };
    case ActionTypes.GET_INVEST_STRATEGIES_NEW[SUCCESS]:
      return { ...state, investStrategiesNew: action.data, investStrategiesNewAreLoaded: true };
    case ActionTypes.GET_INVEST_STRATEGIES_NEW[FAILURE]:
      return { ...state, error: action.error, investStrategiesNewAreLoaded: false };
    // NEW-END
    case ActionTypes.GET_MY_INVEST_STRATEGIES_NEW[REQUEST]:
      return { ...state, myInvestStrategiesNewAreLoaded: false };
    case ActionTypes.GET_MY_INVEST_STRATEGIES_NEW[SUCCESS]:
      return { ...state, myInvestStrategiesNew: action.data, myInvestStrategiesNewAreLoaded: true };
    case ActionTypes.GET_MY_INVEST_STRATEGIES_NEW[FAILURE]:
      return { ...state, error: action.error, myInvestStrategiesNewAreLoaded: false };
    default:
      return state;
  }
};

export default reducer;
