import React from 'react';

const FaqIcon = () => (
  <div>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.516 12.46a.39.39 0 00-.391.392v2.695a1.955 1.955 0 01-1.953 1.953H6.954a.39.39 0 00-.39.39v.777l-.933-.932a.39.39 0 00-.358-.235H2.734a1.955 1.955 0 01-1.953-1.953V9.53c0-1.077.876-1.953 1.953-1.953H6.68a.39.39 0 000-.781H2.734A2.737 2.737 0 000 9.53v6.016a2.737 2.737 0 002.734 2.734h2.339l1.604 1.605a.39.39 0 00.667-.276V18.28h3.828a2.737 2.737 0 002.734-2.734v-2.695a.39.39 0 00-.39-.391z"
        fill="#777"
      />
      <path
        d="M14.322 0h-.988a5.684 5.684 0 00-5.678 5.678 5.684 5.684 0 005.678 5.677h.988a5.69 5.69 0 001.441-.185l1.46 1.46a.39.39 0 00.668-.276v-2.26a5.731 5.731 0 001.492-1.84c.41-.803.617-1.67.617-2.576A5.684 5.684 0 0014.322 0zm2.943 9.591a.39.39 0 00-.156.312v1.508l-.956-.955a.391.391 0 00-.39-.097c-.465.143-.95.215-1.44.215h-.99a4.902 4.902 0 01-4.896-4.896c0-2.7 2.197-4.897 4.897-4.897h.989c2.7 0 4.896 2.197 4.896 4.897a4.86 4.86 0 01-1.954 3.913z"
        fill="#777"
      />
      <path
        d="M15.587 4.274a1.657 1.657 0 00-1.533-1.534 1.641 1.641 0 00-1.245.441 1.66 1.66 0 00-.525 1.21.39.39 0 00.78 0c0-.245.1-.472.278-.639a.873.873 0 11.787 1.49.74.74 0 00-.582.724v.939a.39.39 0 10.781 0v-.908a1.645 1.645 0 001.26-1.723zM14.214 8.174a.394.394 0 00-.553 0 .394.394 0 000 .553.393.393 0 00.553 0 .394.394 0 000-.553zM10.742 12.11H2.5a.39.39 0 000 .78h8.242a.39.39 0 000-.78zM11.018 14.411a.394.394 0 00-.552 0 .394.394 0 000 .553.394.394 0 00.667-.276.394.394 0 00-.115-.277zM9.206 14.297H2.5a.39.39 0 000 .781h6.706a.39.39 0 100-.781zM8.203 9.922H2.5a.39.39 0 000 .781h5.703a.39.39 0 100-.781z"
        fill="#777"
      />
    </svg>
  </div>
);

export default FaqIcon;
