import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const Checkbox = ({ onClick, checked, disabled, textId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <button type="button" onClick={onClick} className="Checkbox__wrapper" disabled={disabled}>
      <div
        className={classNames('Checkbox', {
          Checkbox__checked: checked,
          Checkbox__disabled: disabled,
        })}>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
          <path
            d="M9.89454 0.711971C10.0352 0.850356 10.0352 1.07802 9.89454 1.21641L3.82345 7.2875C3.68506 7.42811 3.4574 7.42811 3.31901 7.2875L0.104592 4.07339C-0.0348641 3.93501 -0.0348641 3.70734 0.104592 3.56895C0.24396 3.42834 0.470063 3.42834 0.609564 3.56895L3.57123 6.53084L9.3901 0.711971C9.52849 0.572247 9.75615 0.572247 9.89454 0.711971Z"
            fill="white"
          />
        </svg>
      </div>
      {textId && <FormattedMessage id={textId}>{txt => <div className="Checkbox__label">{txt}</div>}</FormattedMessage>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </button>
  );
};

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
  textId: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  textId: '',
  theme: {},
};

export default withTheme()(Checkbox);
export { Checkbox };
