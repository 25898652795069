import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { ReduxTextField, DefaultButton, CheckboxField, ReduxSelectField, TinyMCEField } from 'common/components';

import { editInvestStrategyNew } from 'client/investNew/_redux/actions';
import staticStyles from './style';

const validate = ({ name }) => {
  const errors = {};

  if (!/^[a-zA-Z0-9\s_!,.?]{0,50}$/.test(name)) {
    errors.name = <FormattedMessage id="rammStrategyNameHelpText" />;
  }
};

const EditInvestStrategyFormNew = ({
  toggleModal,
  handleSubmit,
  submitting,
  pristine,
  invalid,
  dispatch,
  error,
  strategyData,
  strategyId,
}) => {
  const { id, defaultMinInvestmentAmount, currency, frequencyType } = useMemo(() => strategyData, [strategyData]);
  const submit = handleSubmit(values => editInvestStrategyNew({ id, data: values, strategyId }, dispatch));

  const [selectedType, setSelectedType] = useState(frequencyType);
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const handleChangeFrequencyType = type => {
    setSelectedType(type);
  };

  const frequencyTypes = [
    {
      value: 1,
      label: <FormattedMessage id="justMonthly" />,
    },
    {
      value: 2,
      label: <FormattedMessage id="justWeekly" />,
    },
  ];

  const daysOptions = useMemo(
    () =>
      selectedType === 1
        ? Array.from(Array(31), (_, i) => i + 1).map(day => ({ label: day, value: day }))
        : weekdays.map((day, i) => ({
            label: day,
            value: i + 1,
          })),
    [selectedType, weekdays]
  );

  return (
    <div className="EditStrategy__content">
      <form className="EditInvestStrategyForm" onSubmit={submit}>
        <div className="EditInvestStrategyForm__inputs-inner">
          <Field component={TinyMCEField} name="description" textId="justDescription" disabled={submitting} />

          <Field
            component={ReduxSelectField}
            name="frequencyType"
            textId="investRewardFrequency"
            options={frequencyTypes}
            validate={[required()]}
            disabled={submitting}
            onChange={handleChangeFrequencyType}
          />

          <FormattedMessage id="investRewardDays">{txt => <span className="caption">{txt}</span>}</FormattedMessage>
          <Field
            component={ReduxSelectField}
            name="days"
            textId="investRewardDays"
            options={daysOptions}
            validate={[required()]}
            disabled={submitting}
            multi
          />

          <div className="EditStrategy__min-invest-amount">
            <Field
              component={ReduxTextField}
              name="minInvestmentAmount"
              textId="minimalInvestmentAmount"
              validate={[required()]}
              description={currency}
              disabled={submitting}
            />

            <div className="EditStrategy__min-invest-amount-text">
              <FormattedMessage
                id="minimalInvestmentAmountCount"
                values={{
                  count: defaultMinInvestmentAmount?.amount,
                  currency: defaultMinInvestmentAmount?.currency,
                }}>
                {txt => txt}
              </FormattedMessage>
            </div>
          </div>
          {/* TODO: SOON DONE */}
          {/* 
          <div className="EditStrategy__autoinvestment">
            <Field component={CheckboxField} name="autoInvestment" textId="autoInvestment" disabled={submitting} />
          </div> */}

          <div className="EditStrategy__enableoperations">
            <Field
              component={CheckboxField}
              name="enableOperations"
              textId="strategyAutoOperations"
              disabled={submitting}
            />
          </div>

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="EditInvestStrategyForm__btns">
          <DefaultButton
            textId="justEditStrategy"
            type="submit"
            loading={submitting}
            disabled={pristine || invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
      </form>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

/* eslint-disable */
EditInvestStrategyFormNew.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  strategyData: PropTypes.object.isRequired,
  strategyId: PropTypes.string.isRequired,
};

EditInvestStrategyFormNew.defaultProps = {
  error: '',
};
/* eslint-enable */

export default compose(
  reduxForm({
    form: 'EditInvestStrategyFormNew',
    validate,
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  }),
  connect(state => ({
    accountsAreLoaded: state.client.common.accountsIsLoaded,
  }))
)(EditInvestStrategyFormNew);
export { EditInvestStrategyFormNew };
