/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';
import Tooltip from '../Tooltip';

const NewDefaultButton = ({ textId, size, variant, type, theme, className, isUpperCase, tooltipTextId, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const newDefaultButtonClass = classNames('NewDefaultButton', `${className}`, {
    'NewDefaultButton--size-small': size === 'small',
    'NewDefaultButton--size-medium': size === 'medium',
    'NewDefaultButton--secondary': variant === 'secondary',
    'NewDefaultButton--primary': variant === 'primary',
    'NewDefaultButton--red': variant === 'red',
    'NewDefaultButton--ghost': variant === 'ghost',
  });

  const formatedTextClass = classNames('NewDefaultButton__text', {
    'NewDefaultButton__text--size-small': size === 'small',
    'NewDefaultButton__text--size-medium': size === 'medium',
    'NewDefaultButton__text--secondary': variant === 'secondary',
    'NewDefaultButton__text--primary': variant === 'primary',
    'NewDefaultButton__text--red': variant === 'red',
    'NewDefaultButton__text--ghost': variant === 'ghost',
  });

  return (
    <Tooltip id={tooltipTextId} textId={tooltipTextId}>
      <button type={type} className={newDefaultButtonClass} {...props}>
        <FormattedMessage id={textId}>{txt => <span className={formatedTextClass}>{txt}</span>}</FormattedMessage>
        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </button>
    </Tooltip>
  );
};

const basePropTypes = {
  textId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  isUpperCase: PropTypes.bool,
  theme: PropTypes.object,
  tooltipTextId: PropTypes.string,
};

NewDefaultButton.propTypes = basePropTypes;
NewDefaultButton.defaultProps = {
  size: 'medium',
  variant: 'primary',
  type: 'button',
  className: '',
  isUpperCase: false,
  theme: {},
  tooltipTextId: '',
};

const ThemedButton = withTheme()(NewDefaultButton);

ThemedButton.propTypes = basePropTypes;

export default ThemedButton;
export { NewDefaultButton };
