import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TaskPanel {
    background: #ffffff;
    padding: 25px;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: rem(30px);
    }

    &__title {
      font-size: rem(26px);
      line-height: rem(26px);
      margin: 0 20px;
      font-weight: 300;
    }

    &__actions {
      margin-left: auto;

      :global(.DefaultButton) {
        margin-left: 25px;
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;

      @include respond-to(sm) {
        flex-direction: column;
      }
    }

    &__line {
      display: flex;
      flex: 1;
      margin-bottom: rem(15px);
    }

    &__name {
      color: #777777;
      display: inline-block;
      font-size: rem(13px);
      min-width: 110px;
      padding-right: rem(${isRTL ? '0' : '15px'});
      padding-left: rem(${isRTL ? '15px' : '0'});
    }

    &__value {
      display: inline-block;
      max-width: 90%;

      .overdue {
        color: #000;
      }
    }

    :global(.TaskIcon) {
      :global(svg) {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .TaskPanel {
      &__value {
        .overdue {
          font-size: rem(13px);
          color: ${colors.error.hex};
          margin-left: 3px;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
