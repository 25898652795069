import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const WatchIcon = ({ grayColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
    <g fill="none" fillRule="evenodd" stroke={grayColor.hex}>
      <path
        fill={grayColor.hex}
        fillRule="nonzero"
        strokeWidth=".4"
        d="M18.805 6.521c-4.379-4.378-11.502-4.378-15.88 0L0 9.445l2.997 2.997a11.194 11.194 0 0 0 7.94 3.284c2.876 0 5.751-1.094 7.94-3.284l2.925-2.924-2.997-2.997zm-.448 5.4c-4.092 4.091-10.748 4.091-14.84 0L1.042 9.445l2.404-2.404c4.091-4.09 10.748-4.09 14.839 0l2.476 2.477-2.403 2.403z"
      />
      <path strokeLinecap="square" d="M2.325 2.325L4.92 4.92M17 5.597L19.597 3M11 0v3.672" />
      <path
        fill={grayColor.hex}
        fillRule="nonzero"
        strokeWidth=".5"
        d="M11.036 5.448a4.172 4.172 0 0 0-4.168 4.168 4.172 4.172 0 0 0 4.168 4.167 4.172 4.172 0 0 0 4.167-4.167 4.172 4.172 0 0 0-4.167-4.168zm0 7.694a3.53 3.53 0 0 1-3.527-3.526 3.53 3.53 0 0 1 3.527-3.527 3.53 3.53 0 0 1 3.526 3.527 3.53 3.53 0 0 1-3.526 3.526z"
      />
    </g>
  </svg>
);

WatchIcon.propTypes = {
  grayColor: colorType,
};

WatchIcon.defaultProps = {
  grayColor: {},
};

const ThemedWatchIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(WatchIcon);

export default ThemedWatchIcon;
