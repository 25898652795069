import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { withTheme } from 'common/styling/theme';
import { LightBoxGallery } from '../../light-box';

import { staticStyles, getDynamicStyles } from './style';

const LightBoxModal = ({ currentImage, onRequestClose, images, src, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const hasVScroll = document.body.scrollHeight > document.body.clientHeight;
  const isDeviceTouch = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (!hasVScroll) {
    document.body.classList.add('Body__no-scroll');
  } else {
    document.body.classList.remove('Body__no-scroll');
  }

  if (isDeviceTouch()) {
    document.body.classList.add('Body__touch-screen');
  } else {
    document.body.classList.remove('Body__touch-screen');
  }

  return (
    <Modal isOpen={currentImage !== -1} onRequestClose={onRequestClose}>
      <button className="LightBoxModal__close" type="button" onClick={onRequestClose}>
        <span />
      </button>

      {currentImage !== -1 && (
        <LightBoxGallery initialSlide={currentImage}>
          {images.map((image, i) => (
            <div
              role="presentation"
              className="SliderItem"
              onClick={onRequestClose}
              onKeyPress={onRequestClose}
              key={i}>
              <button type="button" onClick={e => e.stopPropagation()}>
                <img src={src ? image[src] : image} alt={image.title} />
              </button>
            </div>
          ))}
        </LightBoxGallery>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </Modal>
  );
};

LightBoxModal.propTypes = {
  currentImage: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  src: PropTypes.string,
  theme: PropTypes.object,
};

LightBoxModal.defaultProps = {
  currentImage: -1,
  src: '',
  theme: {},
};

export default withTheme()(LightBoxModal);
export { LightBoxModal };
