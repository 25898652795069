import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withTheme } from 'common/styling/theme';

import { PaginationTable, TableActions, MoneyCell, ActionsButton, DateCell } from 'common/components';
import { PlayIcon, CrossIcon } from 'common/icons';

import { staticStyles, getDynamicStyles } from './style';

const InvestStrategiesTable = ({
  data,
  activeTab,
  buttons,
  accounts,
  onRowClick,
  loading,
  getList,
  location,
  history,
  params,
  onTableAction,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const numberOfInvestorsNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'rammNumberOfInvestorsTixee'
      : 'rammNumberOfInvestors';

  /* eslint-disable */
  const columns =
    activeTab === 'active'
      ? [
          {
            accessor: 'title',
            Header: 'rammNameStrategy',
            Cell: ({
              row: {
                original: { id, title, masterAccount },
              },
            }) => (
              <div className="InvestStrategiesTable__title">
                <span className="title">{title}</span>
                <div className="sub-title">
                  {!!masterAccount ? (
                    <>
                      <FormattedMessage id="rammManagesViaAccount">{txt => <span>{txt}</span>}</FormattedMessage>
                      {accounts.find(account => account.login === masterAccount) ? (
                        <Link
                          onClick={e => e.stopPropagation()}
                          className="link"
                          to={`/account/${accounts.find(account => account.login === masterAccount).id}`}>
                          {`#${masterAccount}`}
                        </Link>
                      ) : (
                        <span className="account">{`#${masterAccount}`}</span>
                      )}
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="rammManagesVia">{txt => txt}</FormattedMessage>
                      <button
                        className="link"
                        type="button"
                        onClick={e => onTableAction({ e, id, action: 'download', title })}>
                        expert
                      </button>
                    </>
                  )}
                </div>
              </div>
            ),
            isPreviewCell: true,
          },
          {
            accessor: 'account',
            Header: 'rammStrategyEquity',
            Cell: ({
              row: {
                original: { account },
              },
            }) => {
              return account?.equity ? <MoneyCell inTable value={account.equity} /> : '一';
            },
            isPreviewCell: true,
          },
          {
            accessor: 'investorsNumber',
            Header: numberOfInvestorsNew,
            Cell: ({
              row: {
                original: { investorsNumber },
              },
            }) => <span>{investorsNumber}</span>,
          },
          {
            accessor: 'totalIncome',
            Header: 'rammProfit',
            Cell: ({
              row: {
                original: { totalYield },
              },
            }) => <span>{`${(totalYield * 100).toFixed(2)}%`}</span>,
          },
          {
            accessor: 'buttons',
            Header: () => {},
            Cell: ({
              row: {
                original: { id, account, title, status, settings },
              },
            }) => (
              <div className="InvestStrategiesTable__btns">
                {status === 2 && (
                  <ActionsButton
                    tooltipId={`rammActionResume_${id}`}
                    tooltipTextId="rammActionResume"
                    watch
                    onClickFunc={e => onTableAction({ e, id, action: 'resume' })}>
                    <PlayIcon />
                  </ActionsButton>
                )}
                <ActionsButton
                  tooltipId={`rammActionClose${id}`}
                  tooltipTextId="rammActionClose"
                  watch
                  onClickFunc={e => onTableAction({ e, id, action: 'close', settings })}>
                  <CrossIcon />
                </ActionsButton>
                <TableActions
                  hidden={!account || account?.type === 3}
                  tooltipId={`tooltipId_${id}`}
                  tooltipTextId="rammMoreActions"
                  actionButtons={buttons}
                  onActionClick={(e, action) => {
                    onTableAction({ e, id, action, title, status, account });
                  }}
                />
              </div>
            ),
          },
        ]
      : [
          {
            accessor: 'id',
            Header: 'justId',
            Cell: ({
              row: {
                original: { id },
              },
            }) => <span>{id}</span>,
            isPreviewCell: true,
          },
          {
            accessor: 'title',
            Header: 'justName',
            Cell: ({
              row: {
                original: { title },
              },
            }) => <span className="name">{title}</span>,
            isPreviewCell: true,
          },

          {
            accessor: 'created',
            Header: 'justCreated',
            Cell: ({
              row: {
                original: { created },
              },
            }) => <DateCell value={created} />,
          },
          {
            accessor: 'closed',
            Header: 'justClosed',
            Cell: ({
              row: {
                original: { closedAt },
              },
            }) => {
              return closedAt ? <DateCell value={closedAt} /> : '一';
            },
          },
          {
            accessor: 'age',
            Header: 'ageAndWeeks',
            Cell: ({
              row: {
                original: { age },
              },
            }) => <span>{age}</span>,
          },
        ];
  /* eslint-enable */

  return (
    <div
      className={classNames('InvestStrategiesTable', {
        'InvestStrategiesTable-active': activeTab === 'active',
        'InvestStrategiesTable-closed': activeTab === 'closed',
      })}>
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        onRowClick={(state, rowInfo) => onRowClick(rowInfo.original.id)}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestStrategiesTable.propTypes = {
  data: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  onTableAction: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestStrategiesTable.defaultProps = {
  theme: {},
};

export default withTheme()(InvestStrategiesTable);
