import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WelcomeSetupRegistrationPage {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 80px auto 1fr;
    @include respond-to(xs) {
      grid-template-rows: 80px 0 auto;
    }
    &__fmSlug {
      font-size: 36px;
      font-weight: 300;
      line-height: 42px;
      letter-spacing: 0px;
      padding: 48px 0 32px 313px;
      @media screen and (min-width: 1539px) {
        padding-left: calc(264px + 8%);
      }
      @include respond-to(md) {
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
      }
    }
  }

  .CrmSetupBlock {
    display: flex;
    align-items: flex-start;
    justify-items: center;
    padding: 0 49px 51px 49px;
    gap: 24px;
    @media screen and (min-width: 1539px) {
      padding: 0 8% 51px 8%;
    }
    @include respond-to(xs) {
      padding: 0 0 51px 0;
    }
  }

  .ProgressBarAndFormInfoBlock {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 32px;
    width: 100%;

    @include respond-to(xs) {
      padding: 24px;
    }
  }

  .FormAndInfoText {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    padding-top: 32px;
    gap: 32px;
    @include respond-to(sm) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  react-phone-number-input__icon {
    width: 19px;
    height: 19px;
  }

  .RegistrationModal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
    max-width: 350px;
    &__mail {
      font-weight: 500;
    }
  }
`;

export { staticStyles };
