import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  PageContentPreloader,
  PageLayout,
  SearchPanel,
  PaginationTable,
  NoItemsPanel,
  DateCell,
  ActionsButton,
  Tooltip,
} from 'common/components';
import CopyTextIcon from 'common/icons/CopyTextIcon';
import NoRequestsImage from 'common/images/noRequests.svg';

import { withTheme } from 'common/styling/theme';
import { ModalHOC } from 'common';

import { boGetPartnershipRequests } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const RequestsPage = ({ partnershipRequests, partnershipRequestsAreLoaded, getPartnershipRequests, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const openPartnershipRequest = issueId => history.push(`/backoffice/support/${issueId}`);

  useEffect(() => {
    getPartnershipRequests({ search: query });
  }, [getPartnershipRequests, query]);

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: 'boPartnersRequestsFullName',
        accessor: 'first_name',
        Cell: ({
          row: {
            original: { firstName, lastName, email, userId },
          },
        }) => {
          const openUserAccount = e => {
            e.stopPropagation();
            history.push(`/backoffice/users/${userId}`);
          };
          return (
            <div className="PartnershipRequestsTable__cell PartnershipRequestsTable__cell--wide">
              <div className="BasicInfoCellInner">
                <Gravatar md5={md5(email)} size={32} />
                <div className="BasicInfoCellInner__UserName">
                  <span onClick={openUserAccount}>{firstName}</span>
                  <span onClick={openUserAccount}>{lastName}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'boPartnersRequestsEmail',
        accessor: 'email',
        Cell: ({
          row: {
            original: { email, userId },
          },
        }) => (
          <span className="PartnershipRequestsTable__cell PartnershipRequestsTable__cell--email-cell">
            <span>{email}</span>
            <ActionsButton
              watch
              buttonId="actionsCopyEmail"
              onClickFunc={e => {
                e.stopPropagation();
                navigator.clipboard.writeText(email);
              }}>
              <Tooltip id={`boPartnersRequestsEmail-${userId}`} textId={email}>
                <CopyTextIcon />
              </Tooltip>
            </ActionsButton>
          </span>
        ),
      },
      {
        Header: 'boPartnersRequestsPhone',
        accessor: 'phone',
        Cell: ({
          row: {
            original: { phone },
          },
        }) => <span className="PartnershipRequestsTable__cell">{phone}</span>,
      },
      {
        Header: 'boPartnersRequestsDateJoined',
        accessor: 'date_joined',
        Cell: ({
          row: {
            original: { dateJoined },
          },
        }) => <DateCell value={dateJoined} />,
      },
      {
        Header: 'boPartnersRequestsStatus',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status },
          },
        }) => {
          const statusCellStyle = classNames('PartnershipRequestsTable__cell', {
            'PartnershipRequestsTable__cell--verified': status === 'verified',
            'PartnershipRequestsTable__cell--not-verified': status !== 'verified',
          });
          return <span className={statusCellStyle}>{status.toUpperCase()}</span>;
        },
      },
      {
        Header: 'boPartnersRequestsIsActive',
        accessor: 'is_active',
        Cell: ({
          row: {
            original: { isActive },
          },
        }) => {
          return (
            <div className="PartnershipRequestsTable__cell">
              <div className={`status ${!!isActive ? 'active' : ''}`} />
            </div>
          );
        },
      },
    ],
    []
  );
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      openPartnershipRequest(rowInfo.original.issueId);
    },
  });

  return (
    <PageLayout captionSlug="boPartnershipRequestsMenu">
      <PageContentPreloader ready={partnershipRequestsAreLoaded} type="bigLine" firstLaunchOnly>
        <SearchPanel
          location={location}
          loading={!partnershipRequestsAreLoaded}
          history={history}
          getList={getPartnershipRequests}
          params={{ search: query }}
          searchTextId="boPartnersRequestsSearchByAll"
        />
        {partnershipRequests.count ? (
          <div className="PartnershipRequestsTable">
            <PaginationTable
              data={partnershipRequests}
              columns={columns}
              loading={!partnershipRequestsAreLoaded}
              getList={getPartnershipRequests}
              getTrProps={rowProps}
              onRowClick={(_, { original: { issueId } }) => {
                openPartnershipRequest(issueId);
              }}
              location={location}
              history={history}
              params={{ search: query }}
              isNewTable
              noResponsive
            />
          </div>
        ) : (
          <NoItemsPanel icon={NoRequestsImage} captionSlug="boPartnersRequestsNoRequests" />
        )}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

RequestsPage.propTypes = {
  getPartnershipRequests: PropTypes.func.isRequired,
  partnershipRequests: PropTypes.array.isRequired,
  partnershipRequestsAreLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

RequestsPage.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      partnershipRequests: state.backoffice.partnership.partnershipRequests,
      partnershipRequestsAreLoaded: state.backoffice.partnership.partnershipRequestsAreLoaded,
    }),
    { getPartnershipRequests: search => boGetPartnershipRequests.request(search) }
  )
)(RequestsPage);
