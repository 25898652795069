import { put, call, fork, take, takeEvery } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { getProfileSaga } from 'interface/user/_redux/sagas';
import { sendYandexMetrikaEvent } from 'common/utils';

import * as ActionTypes from './actions';
import * as api from '../api';

export function* getCountriesSaga() {
  try {
    const { status, data } = yield call(api.getCountries);
    if (status < 300) {
      yield put(ActionTypes.getCountries.success(data));
    } else {
      yield put(ActionTypes.getCountries.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCountries.failure());
  }
}

export function* getCountriesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_COUNTRIES[REQUEST]);
    yield call(getCountriesSaga);
  }
}

export function* getStatesSaga(gid) {
  try {
    const { status, data } = yield call(api.getStates, gid);
    if (status < 300) {
      yield put(ActionTypes.getStates.success(data));
    } else {
      yield put(ActionTypes.getStates.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStates.failure());
  }
}

export function* getStatesWatcherSaga() {
  while (true) {
    const { gid } = yield take(ActionTypes.GET_STATES[REQUEST]);
    yield call(getStatesSaga, gid);
  }
}

export function* getKYCSaga() {
  try {
    const { data } = yield call(api.getKYC);
    yield put(ActionTypes.getKYC.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getKYC.failure());
  }
}

export function* getKYCWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_KYC[REQUEST]);
    yield call(getKYCSaga);
  }
}

export function* sendKYCSaga(payload) {
  try {
    const sections = ['forex', 'derivative', 'securities', 'investmentFund'];
    sections.forEach(section => {
      // if section was clicked but not filled
      if (payload.experience[section] && !payload.experience[section].checkbox) {
        // set section to null
        payload.experience[section] = null;
      }
    });

    const { status, data } = yield call(api.sendKYC, payload);

    if (status < 300) {
      yield put(ActionTypes.sendKYC.success());
      const EVENT_NAME = 'Send KYC';
      sendYandexMetrikaEvent(EVENT_NAME);
      yield call(getProfileSaga);
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.sendKYC.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.sendKYC.failure());
  }
}

export function* sendKYCWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.sendKYC.REQUEST);
    yield call(sendKYCSaga, payload);
  }
}

export function* sendDocumentsSaga({ name, files }) {
  try {
    const { status } = yield call(api.sendDocuments, name, files);
    if (status === 413) {
      const error = { [name]: 'File is too large' };
      yield put(ActionTypes.sendDocuments.failure(error));
    } else {
      yield put(ActionTypes.sendDocuments.success(name));
      yield call(getProfileSaga);
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.sendDocuments.failure());
  }
}

export function* sendDocumentsWatcherSaga() {
  yield takeEvery(ActionTypes.SEND_DOCUMENTS[REQUEST], sendDocumentsSaga);
}

export function* changePasswordSaga(payload) {
  try {
    const { password, newPassword } = payload;
    const { status, data } = yield call(api.changePassword, {
      password,
      newPassword,
    });

    if (status < 300) {
      yield put(ActionTypes.changePassword.success());
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.changePassword.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changePassword.failure());
  }
}

export function* changePasswordWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.changePassword.REQUEST);
    yield call(changePasswordSaga, payload);
  }
}

export function* getNotificationSettingsSaga() {
  try {
    const { status, data } = yield call(api.getNotificationSettings);
    if (status < 300) {
      yield put(ActionTypes.getNotificationSettings.success(data));
    } else {
      yield put(ActionTypes.getNotificationSettings.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getNotificationSettings.failure());
  }
}

export function* getNotificationSettingsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_NOTIFICATION_SETTINGS[REQUEST]);
    yield call(getNotificationSettingsSaga);
  }
}

export function* changeNotificationSettingsSaga(payload) {
  try {
    const { status, data } = yield call(api.changeNotificationSettings, payload);

    if (status < 300) {
      yield put(ActionTypes.changeNotificationSettings.success());
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.changeNotificationSettings.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changeNotificationSettings.failure());
  }
}

export function* changeNotificationSettingsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.changeNotificationSettings.REQUEST);
    yield call(changeNotificationSettingsSaga, payload);
  }
}

export function* getAgreementsSaga() {
  try {
    const { status, data } = yield call(api.getAgreements);
    if (status < 300) {
      yield put(ActionTypes.getAgreements.success(data));
    } else {
      yield put(ActionTypes.getAgreements.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAgreements.failure());
  }
}

export function* getAgreementsWatherSaga() {
  while (true) {
    yield take(ActionTypes.GET_AGREEMENTS[REQUEST]);
    yield call(getAgreementsSaga);
  }
}

export function* getCardsSaga() {
  try {
    const { status, data } = yield call(api.getCards);
    if (status < 300) {
      yield put(ActionTypes.getCards.success(data));
    } else {
      yield put(ActionTypes.getCards.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCards.failure());
  }
}

export function* getCardsSagaWatherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CARDS[REQUEST]);
    yield call(getCardsSaga);
  }
}

export function* getCardSaga(id) {
  try {
    const { status, data } = yield call(api.getCard, id);
    if (status < 300) {
      yield put(ActionTypes.getCard.success(data));
    } else {
      yield put(ActionTypes.getCard.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCard.failure());
    throw e;
  }
}

export function* getCardWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CARD[REQUEST]);
    yield call(getCardSaga, id);
  }
}

export function* updateCardSaga({ cardId, formData }) {
  try {
    const { status, data } = yield call(api.updateCard, cardId, formData);
    if (status < 300) {
      yield put(ActionTypes.updateCard.success(data));
      yield call(getCardsSaga);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.updateCard.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.updateCard.failure());
    throw e;
  }
}

export function* updateCardWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateCard.REQUEST);
    yield call(updateCardSaga, payload);
  }
}

export function* deleteCardSaga(id) {
  try {
    const { status } = yield call(api.deleteCard, id);
    if (status === 409) {
      const error = 'Unable to delete card: being used by another person';
      yield put(ActionTypes.deleteCard.failure(error));
    } else {
      yield put(ActionTypes.deleteCard.success());
      yield call(getCardsSaga);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteCard.failure(e));
  }
}
export function* deleteCardWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.DELETE_CARD[REQUEST]);
    yield call(deleteCardSaga, id);
  }
}

export function* addCardSaga(payload) {
  try {
    const { status, data } = yield call(api.addCard, payload);
    if (status < 300) {
      yield put(ActionTypes.addCard.success(data));
      yield call(getCardsSaga);
    } else if (status === 413) {
      yield put(ActionTypes.addCard.failure(new SubmissionError({ _error: 'Request Entity Too Large' })));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.addCard.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.addCard.failure());
  }
}

export function* addCardSagaWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.addCard.REQUEST);
    yield call(addCardSaga, payload);
  }
}

export function* getSumSubTokenSaga() {
  try {
    const { status, data } = yield call(api.getSumSubToken);
    if (status < 300) {
      yield put(ActionTypes.getSumSubToken.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getSumSubToken.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCards.failure());
  }
}

export function* getSumSubTokenWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_SUM_SUB_TOKEN[REQUEST]);
    yield call(getSumSubTokenSaga);
  }
}

export default [
  fork(getCountriesWatcherSaga),
  fork(getStatesWatcherSaga),
  fork(getKYCWatcherSaga),
  fork(sendKYCWatcherSaga),
  fork(sendDocumentsWatcherSaga),
  fork(changePasswordWatcherSaga),
  fork(getNotificationSettingsWatcherSaga),
  fork(changeNotificationSettingsWatcherSaga),
  fork(getAgreementsWatherSaga),
  fork(getCardsSagaWatherSaga),
  fork(getCardWatcherSaga),
  fork(updateCardWatcherSaga),
  fork(deleteCardWatcherSaga),
  fork(addCardSagaWatcherSaga),
  fork(getSumSubTokenWatcherSaga),
];
