import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  :global(.FilterEdit__Wrapper) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
