import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { withRouter } from 'react-router-dom';

import { reduxForm, Field } from 'redux-form';
import { withTheme } from 'common/styling/theme';

import { newChangeReassignTaskStatus } from 'crm/tasks/_redux/';

import { BaseButton } from 'crm/_components';
import { TextFormField } from 'crm/_components/forms/';

import { staticStyles } from './style';

const ReassignModal = ({ taskId, newStatus, handleSubmit, submitting, invalid, dispatch, error }) => {
  const submit = handleSubmit(values => {
    const data = {
      taskId,
      ...values,
      status: newStatus,
    };
    newChangeReassignTaskStatus(data, dispatch);
  });

  return (
    <form className="ReassignModal" onSubmit={submit}>
      <div className="ReassignModal__line">
        <Field labelTextId="justReason" component={TextFormField} name="reason" placeholder="Comment" />
      </div>
      {error && <strong className="ReassignModal__formError">{error}</strong>}
      <div className="ReassignModal__submit">
        <BaseButton
          type="submit"
          textId={newStatus === 'accepted' ? 'justAccept' : 'justDecline'}
          disabled={invalid || submitting}
        />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

ReassignModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ReassignModal.defaultProps = {
  error: '',
};

export default compose(
  withTheme(),
  FormMessageHOC,
  withRouter,
  reduxForm({
    form: 'crm-edit-task-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmTaskWasSuccessfullyClosed');
      props.onRequestClose();
    },
  })
)(ReassignModal);
