import css from 'styled-jsx/css';

const staticStyles = css`
  .FilterCreate__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .FilterCreate {
    max-width: 390px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  :global(.TextFormField) {
    width: 100%;
  }
`;

export { staticStyles };
