import css from 'styled-jsx/css';
import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentsInfoPanelWrap {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(4, auto);
    background: white;
    margin-bottom: 20px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }

  .PaymentInfo {
    position: relative;
    display: grid;
    gap: 32px;
    grid-template-columns: 80px auto;
    padding: 32px;
    @include respond-to(xs) {
      padding: 16px;
      gap: 24px;
    }
    &__PaymentSystemIcon {
      display: flex;
      width: 100%;
      max-height: 80px;
      > img {
        width: 80px;
        object-fit: scale-down;
      }
    }
    &__PaymentInfo {
      width: 100%;
      height: 100%;
    }
    .PaymentInfoData {
      display: grid;
      row-gap: 32px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      @include respond-to(xs) {
        grid-template-columns: 1fr;
        row-gap: 12px;
      }
      &__Item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        height: max-content;
        > .title {
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          color: #5d647d;
        }
        > .content {
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;

          &--font-large {
            font-size: 20px;
            line-height: 24px;
          }
          &--money-cell {
            > .MoneyCell__inner {
              height: 22px;
            }
          }
        }
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background: #f0f4f6;
  }

  .Instruction {
    display: grid;
    gap: 32px;
    grid-template-columns: 80px auto;
    padding: 32px;
    @include respond-to(xs) {
      padding: 16px;
      gap: 24px;
    }
    &__Icon {
      display: flex;
      justify-content: center;
      width: 100%;
      max-height: 80px;
      > img {
        width: 80px;
        object-fit: scale-down;
      }
    }

    &__CustomMessage {
      width: 100%;
      word-break: break-word;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PaymentsInfoPanelWrap {
      background-color: ${colors.white.hex};
      &:after {
        background: ${gradient(73.29, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
