import css from 'styled-jsx/css'
import { rgba, gradient } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .hamburger {
    height: 65px;
    width: 65px;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    overflow: visible;
    margin-right: 15px;
    display: none;
    position: relative;
    z-index: 5;
    @include respond-to(md) {
      display: block;
    }
    @include respond-to(sm) {
      margin-right: 10px;
    }
  }

  .hamburger-box {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 24px;
    height: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    background: #FFFFFF;
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }

  .hamburger-inner::before {
    top: -10px;
  }

  .hamburger-inner::after {
    bottom: -10px;
  }

  .hamburger--slider .hamburger-inner {
    top: 2px;
  }

  .hamburger--slider .hamburger-inner::before {
    top: 6px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }

  .hamburger--slider .hamburger-inner::after {
    top: 12px;
  }

  .hamburger--slider.opened .hamburger-inner {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }

  .hamburger--slider.opened .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
  }

  .hamburger--slider.opened .hamburger-inner::after {
    transform: translate3d(0, -12px, 0) rotate(-90deg);
  }
`



export { staticStyles }
