import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const WithdrawalIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 12.5C13.4 12.5 13.2 12.6 13.1 12.7L10.5 15.3V7.5C10.5 7.2 10.3 7 10 7C9.7 7 9.5 7.2 9.5 7.5V15.2L6.9 12.6C6.8 12.5 6.6 12.4 6.4 12.5C6.2 12.5 6.1 12.7 6 12.9C6 13.1 6 13.3 6.1 13.4L9.6 16.9C9.7 16.9 9.9 17 10 17C10.1 17 10.3 16.9 10.4 16.8L13.9 13.3C14 13.2 14 13 14 12.8C13.9 12.6 13.7 12.5 13.5 12.5ZM17.7 0H2.3C1 0 0 1 0 2.2V11.8C0 12.4 0.2 13 0.7 13.4C1.1 13.8 1.7 14 2.4 14H3.2C3.5 14 3.8 13.7 3.8 13.4C3.8 13.1 3.6 12.9 3.3 12.9H2.3C1.6 12.9 1 12.4 1 11.7V3.7H18.9V11.7C18.9 12.4 18.3 12.9 17.6 12.9H16.5C16.2 12.9 16 13.1 16 13.3V13.5C16 13.7 16.2 13.9 16.4 13.9H17.6C18.9 13.9 19.9 12.9 19.9 11.7V2.2C20 1 19 0 17.7 0Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="3.50204"
        y1="18.2591"
        x2="21.6351"
        y2="11.8848"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
    </defs>
  </svg>
);

WithdrawalIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

WithdrawalIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedWithdrawalIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(WithdrawalIconActive);

export default ThemedWithdrawalIconActive;
