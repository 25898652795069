import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  
  .UserEditInfoForm {
    max-width: 400px;
    min-width: 400px;
    @include respond-to(sm) {
      max-width: 100%;
      min-width: 100%;
    }
  }
  
  .UserEditInfoForm__medium__tel {
    .UserEditInfoForm__inputs-inner {
      margin-bottom: 110px;
    }
  }
  
  .UserEditInfoForm__medium {
    .UserEditInfoForm__inputs-inner {
      margin-bottom: 110px;
      @include respond-to(sm) {
        margin-bottom: 0;
      }
    }
  }
  
  .UserEditInfoForm__big {
    .UserEditInfoForm__inputs-inner {
      margin-bottom: 300px;
      @include respond-to(sm) {
        margin-bottom: 0;
      }
    }
    :global(.SingleDatePicker_picker) {
      left: 0 !important;
    }
  }
`

export { staticStyles }
