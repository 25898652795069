import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { SearchPanel, DefaultButton, BasicModal, NoItemsPanel } from 'common/components';
import { getUsers } from 'backoffice/users/_redux';
import noFilesImage from 'common/images/noFilesDownload.svg';
import Checkbox from '../../../partnership/_components/Checkbox';

import { TasksListTable, CreateTaskForm, EditTaskForm, FinishTaskForm } from '..';

import { getTasks, getManagers } from '../../_redux';

import { staticStyles } from './style';

const TasksList = ({
  tasks,
  tasksAreLoaded,
  managers,
  managersAreLoaded,
  users,
  usersAreLoaded,
  getTasks,
  getManagers,
  getUsers,
  profile,
  intl,
}) => {
  const location = useLocation();
  const history = useHistory();
  const overdue = location.search.includes('overdue') ? queryString.parse(location.search).overdue : '0';
  const query = useMemo(
    () => ({ page_size: 10, overdue, ...queryString.parse(location.search) }),
    [overdue, location.search]
  );

  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState({ type: 'add' });
  const [checked, setChecked] = useState(overdue === '1');

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getTasks({ search: query });
  }, [getTasks, query]);

  useEffect(() => {
    getManagers();
    getUsers();
  }, [getManagers, getUsers]);

  const usersList = useMemo(
    () =>
      Array.isArray(users) && usersAreLoaded
        ? users.map(user => ({
            value: user.id,
            label: user.fullName,
          }))
        : [],
    [users, usersAreLoaded]
  );

  const handleAddTask = () => {
    setActiveModal({ type: 'add' });
    toggleModal();
  };

  const handleTaskAction = (e, id, type) => {
    e.stopPropagation();
    let payload;
    if (type === 'edit') {
      const task = tasks.results.find(item => item.id === id);
      if (task) {
        const subject = usersList.find(user => user.value === task.subject?.id);
        payload = {
          title: task.title,
          icon: task.icon,
          subject,
          assignedTo: task.assignedTo.id,
          expirationDate: task.expirationDate,
          text: task.text,
        };
      }
    }
    setActiveModal({ id, type, payload });
    toggleModal();
  };

  const handleCheck = e => {
    e.stopPropagation();

    history.replace({
      ...location,
      search: queryString.stringify({ ...query, overdue: checked ? '0' : '1' }),
    });
    setChecked(!checked);
  };

  const handleRowClick = id => history.push(`/backoffice/tasks/${id}`);

  const modalTitle = str => {
    const firstLetterToUpperCase = str.charAt(0).toUpperCase() + str.substring(1);
    return `just${firstLetterToUpperCase}Task`;
  };

  const filters = [
    {
      title: 'justAssigned',
      accessor: 'assignedTo',
      options: managersAreLoaded
        ? [
            { value: '', label: intl.formatMessage({ id: 'justAll' }) },
            ...managers.map(manager => ({
              value: manager.id,
              label: `${manager.firstName} ${manager.lastName}`,
            })),
          ]
        : [],
    },
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'OPEN', label: intl.formatMessage({ id: 'status_open' }) },
        { value: 'CLOSED', label: intl.formatMessage({ id: 'status_closed' }) },
      ],
    },
  ];

  // const inputs = [
  //   {
  //     title: 'justSubject',
  //     accessor: 'subject',
  //   },
  // ];

  return (
    <div className="TasksList">
      <div className="TasksList__header">
        <FormattedMessage id="justAllTasks">{txt => <h2>{txt}</h2>}</FormattedMessage>
        {profile.perms.createTaskPerm && (
          <DefaultButton textId="justAddTask" type="button" onClick={() => handleAddTask()} filled plus />
        )}
      </div>

      {!tasks.count && !Object.keys(query).length ? null : (
        <SearchPanel
          location={location}
          history={history}
          getList={getTasks}
          filters={filters}
          // inputs={inputs}
          params={{ search: query }}
          withoutSearch
        />
      )}

      <div className="TasksList__overdue">
        <Checkbox onClick={e => handleCheck(e)} checked={checked} />
        <FormattedMessage id="justOverdue" />
      </div>
      {tasks.count ? (
        <TasksListTable
          data={tasks}
          loading={!tasksAreLoaded}
          location={location}
          history={history}
          getList={getTasks}
          onRowClick={(_, { original }) => handleRowClick(original.id)}
          params={{
            search: query,
          }}
          user={profile}
          onActionClick={handleTaskAction}
        />
      ) : (
        <NoItemsPanel
          icon={noFilesImage}
          captionSlug={location.search ? 'noTasksMatchingTheParameters' : 'noTasksYet'}
        />
      )}

      <BasicModal isOpen={isOpen} captionId={modalTitle(activeModal.type)} onRequestClose={toggleModal}>
        {activeModal.type === 'add' && (
          <CreateTaskForm
            successMessageId="crmTaskWasCreated"
            toggleModal={toggleModal}
            managers={managers}
            initialValues={{ assignedTo: profile.id }}
          />
        )}

        {activeModal.type === 'edit' && (
          <EditTaskForm
            toggleModal={toggleModal}
            managers={managers}
            id={activeModal.id}
            initialValues={activeModal.payload}
          />
        )}

        {activeModal.type === 'finish' && <FinishTaskForm toggleModal={toggleModal} id={activeModal.id} />}
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TasksList.propTypes = {
  profile: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  tasksAreLoaded: PropTypes.bool.isRequired,
  managers: PropTypes.array.isRequired,
  managersAreLoaded: PropTypes.bool.isRequired,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  usersAreLoaded: PropTypes.bool.isRequired,
  getTasks: PropTypes.func.isRequired,
  getManagers: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    profile: state.user.profile,
    tasks: state.backoffice.managers.tasks,
    tasksAreLoaded: state.backoffice.managers.tasksAreLoaded,
    managers: state.backoffice.managers.managers,
    managersAreLoaded: state.backoffice.managers.managersAreLoaded,
    users: state.backoffice.users.users,
    usersAreLoaded: state.backoffice.users.usersAreLoaded,
  }),
  {
    getTasks: data => getTasks.request(data),
    getManagers: () => getManagers.request(),
    getUsers: () => getUsers.request({ search: {} }),
  }
)(injectIntl(TasksList));
