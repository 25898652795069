import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InnerWithdrawRequestTable {
    :global(.ReactDataTable) {
      tr {
        grid-template-columns: minmax(30px, 1fr) repeat(5, minmax(100px, 2fr));
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InnerWithdrawRequestTable {
      .ReactDataTable__thead-row,
      .ReactDataTable__tbody-row {
        background-color: ${rgba([colors.white.rgb, 0.7])} !important;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
