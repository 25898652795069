import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

import { CRMSettingAccountsForm } from '..';

import { NewDefaultButton } from '../..';

import { wsSetStep, wsGetMtGroups, wsConfigureAccountAndCreateBroker } from '../../../_redux';

import { staticStyles } from './style';

const CRMSettingForm = ({ handleSubmit, pristine, invalid, submitting, dispatch, wsGetMtGroups, wsSetStep, error }) => {
  useEffect(() => {
    wsGetMtGroups();
  }, []);
  const handlePreviousStepClick = () => wsSetStep(4);

  const submit = handleSubmit(({ accountsConfig }) => {
    const configWithParsedGroups = accountsConfig.map(account => ({
      ...account,
      isDemo: account.isDemo === 'true',
      openAccountInGroup: Boolean(account.openAccountInGroup),
      groups: account.groups.map(jsonedGroup => JSON.parse(jsonedGroup)),
    }));
    return wsConfigureAccountAndCreateBroker({ accountsConfig: configWithParsedGroups }, dispatch);
  });
  return (
    <form className="CRMSettingForm" onSubmit={submit}>
      <FieldArray name="accountsConfig" component={CRMSettingAccountsForm} />
      {error && <strong className="formError">{error}</strong>}
      <div className="CRMSettingForm__nav-buttons">
        <NewDefaultButton
          textId="justBack"
          variant="ghost"
          iconVariant="previous"
          onClick={handlePreviousStepClick}
          type="button"
          disabled={submitting}
          isUpperCase
        />
        <NewDefaultButton
          textId="justFinish"
          type="submit"
          disabled={invalid || pristine || submitting}
          variant="primary"
          wide
          isUpperCase
        />
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

CRMSettingForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  wsSetStep: PropTypes.func.isRequired,
  wsGetMtGroups: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CRMSettingForm.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  connect(null, {
    wsSetStep,
    wsGetMtGroups: () => wsGetMtGroups.request(),
  }),
  reduxForm({
    form: 'wsCRMSettingForm',
    initialValues: {
      accountsConfig: [
        {
          maxLeverage: 100,
          isDemo: 'true',
        },
      ],
    },
    onSubmitSuccess: (result, dispatch, { wsSetStep }) => {
      wsSetStep(6);
    },
  })
)(CRMSettingForm);
export { CRMSettingForm };
