import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as actions from './actions';

const initialState = {
  loading: false,
  schema: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_BROKER_COLOR_SCHEMA[REQUEST]:
      return { ...state, loading: true };
    case actions.GET_BROKER_COLOR_SCHEMA[SUCCESS]:
      return { ...state, schema: action.schema, loading: false };
    case actions.GET_BROKER_COLOR_SCHEMA[FAILURE]:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
