import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TaskEditHistory {
    margin-top: rem(30px);
    :global(.Collapsible) {
      margin-bottom: 20px;

      :global(.ReactDataTable__wrapper) {
        :global(.ReactDataTable) {
          :global(tr) {
            grid-template-columns: repeat(2, minmax(100px, 1fr)) minmax(150px, 2fr);
          }

          :global(.ReactDataTable__tbody-row) {
            margin-left: 0;
          }

          :global(.ReactDataTable__thead-row) {
            :global(th) {
              :global(span) {
                white-space: normal;
              }
            }
          }
        }
      }

      :global(.Collapsible__caption) {
        line-height: 32px;
        font-size: 26px;
        font-weight: 300;

        @include respond-to(sm) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      :global(.Collapsible__header) {
        padding: rem(15px 25px);
        position: relative;
      }

      :global(.Collapsible__content) {
        padding: rem(0 15px);
      }

      :global(.Collapsible__arrow) {
        position: absolute;
        right: ${isRTL ? 'auto' : '30px'};
        left: ${isRTL ? '30px' : 'auto'};
        top: 50%;
      }

      :global(.Collapsible__arrow-right) {
        transform: ${isRTL
          ? 'translateY(-50%) rotate(135deg) !important'
          : ' translateY(-50%) rotate(-45deg) !important'};
      }

      :global(.Collapsible__arrow-down) {
        top: 40%;
        transform: rotate(45deg) translateY(-50%) !important;
      }

      .TaskEditHistory__select {
        padding: 0 25px 5px 25px;
      }

      :global(.NoItemsPanel) {
        height: auto;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .TaskEditHistory {
      :global(.Collapsible) {
        background: ${colors.white.hex};
      }

      :global(.time) {
        margin-left: 10px;
        color: ${colors.gray.hex};
      }

      :global(.TaskEditHistory__author) {
        font-weight: 500;
        color: ${colors.secondary.hex};
      }

      :global(.TaskEditHistory__comment-item) {
        font-weight: 400;
        color: ${colors.gray.hex};
        line-height: 1.5;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
