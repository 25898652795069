import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WalletTransfer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: rem(25px);

    .WalletTransfer__line {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      :global(.ReduxSelectField, .ReduxTextField) {
        width: 400px;
        margin-right: rem(30px);
      }
    }

    .WalletTransfer__description {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
    }

    :global(.DefaultButton) {
      width: 120px;
      margin-top: rem(30px);
    }

    .WalletTransfer__wallet-balance {
      display: flex;
      width: 400px;
      height: 55px;
      margin-right: rem(30px);
      align-items: center;

      .WalletTransfer__wallet-caption {
        margin: rem(0 10px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .WalletTransfer {
      .WalletTransfer__description {
        color: ${colors.gray.hex};
      }

      .WalletTransfer__error {
        color: ${colors.error.hex};
      }

      .WalletTransfer__wallet-balance {
        background: ${colors.disabled.hex};
        color: ${colors.gray.hex};
        border: 1px solid ${colors.lightGray.hex};
        border-radius: ${border.radius};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
