import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NoPreview {
    background: #ececec;
    border: 1px dashed #dadada;
    box-sizing: border-box;
    width: 100%;
    max-width: 360px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-to(sm) {
      max-width: 100%;
    }

    &__icon {
      margin-bottom: 15px;
      max-width: 35px;
    }

    &__text {
      color: #979797;
      font-size: rem(13px);
      text-align: center;
    }
  }
`

export { staticStyles }
