import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import TagManager from 'react-gtm-module';
import queryString from 'query-string';
import { AppEntryPreloader } from 'common/components';
import { setAuthorized } from 'auth/login/_redux';

import InterfaceErrorHandler from './_components/InterfaceErrorHandler';
import { initializeApp } from './_redux/actions';

// This function will map the current _redux state to the props for the component that it is "connected" to.
// When the state of the _redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
// https://goo.gl/harfXM

const intlProviderHOC = WrappedComponent => {
  class intlProviderWrapped extends React.Component {
    componentDidMount() {
      const { token } = queryString.parse(window.location.search);
      if (token) {
        localStorage.setItem('jwtToken', token.toString());
        this.props.setAuthorized();
      }
      this.props.initializeApp();
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.initialization.ready &&
        this.props.initialization.ready !== prevProps.initialization.ready &&
        this.props.gtmId
      ) {
        TagManager.initialize({
          gtmId: this.props.gtmId,
        });
      }
    }

    render() {
      const { initialization, locale, messages } = this.props;
      const localeSlug = locale.split('_')[0];

      if (!initialization.ready) {
        if (initialization.error) {
          return <InterfaceErrorHandler error={initialization.error} />;
        }
        return <AppEntryPreloader />;
      }
      return <WrappedComponent {...this.props} key={localeSlug} locale={localeSlug} messages={messages} />;
    }
  }

  intlProviderWrapped.propTypes = {
    initializeApp: PropTypes.func.isRequired,
    setAuthorized: PropTypes.func.isRequired,
    initialization: PropTypes.object.isRequired,
    gtmId: PropTypes.string,
    locale: PropTypes.string,
    messages: PropTypes.object.isRequired,
  };

  intlProviderWrapped.defaultProps = {
    gtmId: undefined,
    locale: 'no-lang',
  };

  return intlProviderWrapped;
};

export default compose(
  connect(
    ({ interfaceConfig: { lang, messages, initialization, gtmId } }) => ({
      locale: lang,
      messages,
      initialization,
      gtmId,
    }),
    { initializeApp: () => initializeApp.request(), setAuthorized: () => setAuthorized() }
  ),
  intlProviderHOC
)(IntlProvider);
