import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { PageLayout, PageContentPreloader, SearchPanel, NoItemsPanel } from 'common/components';
import FaqCategory from 'common/components/FaqCategory';

import noCategoriesImage from 'common/images/noFilesDownload.svg';
import { getCategories } from '../../_redux';

import { staticStyles } from './style';

const FaqPage = ({ getCategories, categories, categoriesAreLoaded, location, history }) => {
  const query = queryString.parse(location.search);

  /* eslint-disable */
  useEffect(() => {
    getCategories(query)
  }, [getCategories])
  /* eslint-enable */

  const handleSelectCategory = id => {
    history.push(`/backoffice/faq/category/${id}`);
  };

  return (
    <PageLayout captionSlug="justFaq">
      {categories.length || location.search ? (
        <SearchPanel location={location} history={history} getList={getCategories} params={{ search: query }} />
      ) : null}
      <PageContentPreloader ready={categoriesAreLoaded} type="bigLine">
        {categories.length ? (
          <div className="FaqPage">
            <div className="FaqPage__search" />
            <div className="FaqPage__categories">
              {categories.map(category => (
                <FaqCategory category={category} key={category.id} onSelectCategory={handleSelectCategory} />
              ))}
            </div>
          </div>
        ) : (
          <NoItemsPanel icon={noCategoriesImage} captionSlug="noCategoriesYet" />
        )}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </PageLayout>
  );
};

FaqPage.propTypes = {
  getCategories: PropTypes.func.isRequired,
  categories: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  categoriesAreLoaded: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      categories: state.backoffice.faq.categories,
      categoriesAreLoaded: state.backoffice.faq.categoriesAreLoaded,
    }),
    {
      getCategories: data => getCategories.request(data),
    }
  )
)(FaqPage);
export { FaqPage };
