import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Collapsible {
    margin: rem(5px 0);
  }

  .Collapsible__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    padding: rem(20px);
    font-size: rem(16px);

    .Collapsible__arrow {
      display: inline-block;
      padding: 5px;
      height: 13px;
    }

    .Collapsible__arrow-right {
      transform: rotate(-45deg);
      transition: all 0.3s ease;
    }

    .Collapsible__arrow-down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: all 0.3s ease;
    }
  }

  .Collapsible__content {
    margin-bottom: rem(20px);
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .Collapsible__header {
      border-radius: ${border.radius};
      background-color: ${colors.white.hex};

      .Collapsible__arrow {
        border: solid ${colors.gray.hex};
        border-width: 0 2px 2px 0;
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
