import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNotificationSettings } from '../../_redux';

import { VerificationPanel, NotificationSettingsForm } from '../../_components';

const NotificationSettingsPage = ({
  notificationSettings,
  getNotificationSettings,
  notificationLocaleCode,
  notificationSettingsAreLoaded,
}) => {
  useEffect(() => {
    getNotificationSettings();
  }, [getNotificationSettings]);

  const initialSettings = useMemo(
    () => notificationSettings.settings?.reduce((acc, cur) => ({ ...acc, [cur.slug]: cur.value }), {}),
    [notificationSettings]
  );

  return (
    <VerificationPanel captionId="justNotificationSettings" warningMessageTextId="justNotificationSettings">
      {notificationSettingsAreLoaded && (
        <NotificationSettingsForm
          notificationLanguages={notificationSettings.languages ?? []}
          notificationSettings={notificationSettings.settings ?? []}
          initialValues={{ localeCode: notificationLocaleCode, settings: initialSettings }}
        />
      )}
    </VerificationPanel>
  );
};

NotificationSettingsPage.propTypes = {
  notificationSettings: PropTypes.object.isRequired,
  notificationSettingsAreLoaded: PropTypes.bool.isRequired,
  notificationLocaleCode: PropTypes.string.isRequired,
  getNotificationSettings: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    notificationLocaleCode: state.user.profile.notificationLocaleCode,
    notificationSettings: state.client.kyc.notificationSettings,
    notificationSettingsAreLoaded: state.client.kyc.notificationSettingsAreLoaded,
  }),
  {
    getNotificationSettings: () => getNotificationSettings.request(),
  }
)(NotificationSettingsPage);
export { NotificationSettingsPage };
