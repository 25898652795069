import { FAILURE, REQUEST, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

const GET_PAYMENT_METHODS_TEMPLATES = createRequestTypes('GET_PAYMENT_METHODS_TEMPLATES');
const getPaymentMethodsTemplates = {
  request: () => action(GET_PAYMENT_METHODS_TEMPLATES[REQUEST]),
  success: data => action(GET_PAYMENT_METHODS_TEMPLATES[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_METHODS_TEMPLATES[FAILURE], { error }),
};

const GET_PAYMENT_METHODS_SETTINGS = createRequestTypes('GET_PAYMENT_METHODS_SETTINGS');
const getPaymentMethodsSettings = {
  request: data => action(GET_PAYMENT_METHODS_SETTINGS[REQUEST], { data }),
  success: data => action(GET_PAYMENT_METHODS_SETTINGS[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_METHODS_SETTINGS[FAILURE], { error }),
};

const GET_PAYMENT_METHODS_LIST = createRequestTypes('GET_PAYMENT_METHODS_LIST');
const getPaymentMethodsList = {
  request: data => action(GET_PAYMENT_METHODS_LIST[REQUEST], { data }),
  success: data => action(GET_PAYMENT_METHODS_LIST[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_METHODS_LIST[FAILURE], { error }),
};

const DELETE_PAYMENT_METHOD_SETTINGS = createRequestTypes('DELETE_PAYMENT_METHOD_SETTINGS');
const deletePaymentMethodSettings = {
  request: id => action(DELETE_PAYMENT_METHOD_SETTINGS[REQUEST], { id }),
  success: () => action(DELETE_PAYMENT_METHOD_SETTINGS[SUCCESS]),
  failure: error => action(DELETE_PAYMENT_METHOD_SETTINGS[FAILURE], { error }),
};

const GET_PAYMENT_METHOD_SETTINGS = createRequestTypes('GET_PAYMENT_METHOD_SETTINGS');
const getPaymentMethodSettings = {
  request: id => action(GET_PAYMENT_METHOD_SETTINGS[REQUEST], { id }),
  success: data => action(GET_PAYMENT_METHOD_SETTINGS[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_METHOD_SETTINGS[FAILURE], { error }),
};

export const GET_COUNTRIES = createRequestTypes('GET_COUNTRIES_PAYMENT');
export const getCountries = {
  request: () => action(GET_COUNTRIES[REQUEST]),
  success: data => action(GET_COUNTRIES[SUCCESS], { data }),
  failure: error => action(GET_COUNTRIES[FAILURE], { error }),
};

export const createPaymentMethodSettings = createFormAction('CREATE_PAYMENT_METHOD_SETTINGS');
export const editPaymentMethodSettings = createFormAction('EDiT_PAYMENT_METHOD_SETTINGS');

export const GET_PAYMENT_TAGS = createRequestTypes('GET_PAYMENT_TAGS');
export const getPaymentTags = {
  request: () => action(GET_PAYMENT_TAGS[REQUEST]),
  success: data => action(GET_PAYMENT_TAGS[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_TAGS[FAILURE], { error }),
};

export const GET_PAYMENT_TAG_INFO = createRequestTypes('GET_PAYMENT_TAG_INFO');
export const getPaymentTagInfo = {
  request: data => action(GET_PAYMENT_TAG_INFO[REQUEST], data),
  success: data => action(GET_PAYMENT_TAG_INFO[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_TAG_INFO[FAILURE], { error }),
};

export const createPaymentTag = createFormAction('CREATE_PAYMENT_TAG');
export const deletePaymentTag = createFormAction('DELETE_PAYMENT_TAG');
export const editPaymentTag = createFormAction('EDIT_PAYMENT_TAG');

export {
  GET_PAYMENT_METHODS_TEMPLATES,
  GET_PAYMENT_METHODS_SETTINGS,
  GET_PAYMENT_METHODS_LIST,
  DELETE_PAYMENT_METHOD_SETTINGS,
  GET_PAYMENT_METHOD_SETTINGS,
  getPaymentMethodsTemplates,
  getPaymentMethodsSettings,
  getPaymentMethodsList,
  deletePaymentMethodSettings,
  getPaymentMethodSettings,
};
