import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .YieldChart {
    margin-bottom: rem(30px);

    .YieldChart__description {
      font-size: rem(15px);
      line-height: 1.6;
    }

    .YieldChart__chart-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      height: 360px;
      padding: rem(20px);
      margin-bottom: rem(30px);

      :global(.NoItemsPanel) {
        min-height: 200px;
        height: 200px;
      }

      :global(.YieldChart__inner) {
        width: 100%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .YieldChart__chart-wrapper {
      background: ${colors.white.hex};
    }
  `;
};
export { staticStyles, getDynamicStyles };
