import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Raven from 'raven-js';
import { Auth } from 'auth';
import { User } from 'interface/user';

import { CheckAuthHOC } from 'common/HOCs';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from 'common/styling/globalStyle';
import datepickerStyle from 'common/styling/datepickerStyle';

import {
  staticStyles as monthPickerStaticStyles,
  getDynamicStyles as getMonthPickerDynamicStyles,
} from 'common/styling/monthPickerStyle';

import { isRTL, setCookie } from 'common/utils';
import Widgets from './widgets';
import WelcomeSetup from './welcome-setup';

Raven.config(process.env.REACT_APP_SENTRY_DSN).install();

const App = ({ theme, title, favicon, history, customCss }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const monthPickerDynamicStyles = Object.keys(theme).length ? getMonthPickerDynamicStyles(theme) : ` `;
  const dir = isRTL ? 'rtl' : 'ltr';

  const query = queryString.parse(history.location.search);

  Object.entries(query).forEach(([key, value]) => {
    if (key.startsWith('utm_')) {
      setCookie(key, value, { expires: 0 });
    }
  });

  return (
    <div className="App" dir={dir}>
      <Helmet
        title={title}
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            href: favicon,
            sizes: '194x194',
          },
        ]}
        meta={[
          { property: 'og:image', content: favicon },
          { property: 'og:title', content: title },
        ]}
      />
      <Switch>
        <Route path="/widgets" component={Widgets} />
        <Route path="/auth" component={Auth} />
        <Route path="/welcome-setup" component={WelcomeSetup} />
        <Route component={User} />
      </Switch>
      <style className="custom-styles">{customCss}</style>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
      <style jsx global>
        {datepickerStyle}
      </style>
      <style jsx global>
        {monthPickerStaticStyles}
      </style>
      <style jsx global>
        {monthPickerDynamicStyles}
      </style>
    </div>
  );
};

App.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.string,
  customCss: PropTypes.string,
  favicon: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

App.defaultProps = {
  theme: {},
  title: "Trader's area",
  customCss: '',
  favicon: '',
  history: {},
};

export default compose(
  withRouter,
  CheckAuthHOC,
  withTheme(),
  connect(
    state => ({
      title: state.interfaceConfig.clientThemeConfig.client.title,
      favicon: state.interfaceConfig.clientThemeConfig.client.images?.favicon || null,
      customCss: state.interfaceConfig.cssConfig?.css ?? '',
    }),
    null
  )
)(App);
