import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import TINYMCE_CONFIG from './tinymce-config';
import { PageContentPreloader } from '../../preloaders';

import { staticStyles, getDynamicStyles } from './style';

const TinyMCEField = ({ input, textId, textValues, meta: { touched, error }, schema, disabled, withScript, theme }) => {
  const emptyValue = '<p />';
  const location = useLocation();
  const isEmailCategoryLocation = useMemo(
    () => /^\/admin\/EmailCategory.+$/.test(location?.pathname),
    [location?.pathname]
  );

  const [loaded, setLoaded] = useState(false);

  const fetchPlugins = useCallback(async () => {
    try {
      await import('tinymce');
      await Promise.all([
        import('tinymce/themes/modern'),
        import('tinymce/plugins/link/plugin'),
        import('tinymce/plugins/paste/plugin'),
        import('tinymce/plugins/table/plugin'),
        import('tinymce/plugins/image/plugin'),
        import('tinymce/plugins/textcolor/plugin'),
        import('tinymce/plugins/colorpicker/plugin'),
        import('tinymce/plugins/lists/plugin'),
        import('tinymce/plugins/code/plugin'),
        import('tinymce/plugins/template/plugin'),
        import('tinymce/plugins/noneditable/plugin'),
      ]);
      setLoaded(true);
    } catch (e) {
      setLoaded(false);
    }
  }, []);

  useEffect(() => {
    fetchPlugins();
  }, [fetchPlugins]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  if (schema.variables) {
    TINYMCE_CONFIG.templates = schema.variables;
  }

  if (withScript) {
    TINYMCE_CONFIG.extended_valid_elements = 'script[*]';
  }

  const handleChange = (event, editor) => {
    if (isEmailCategoryLocation) {
      if (!input.value) {
        input.onChange(editor.getContent({ format: 'text' }));
      } else {
        input.onChange(editor.getContent({ format: 'html' }));
      }
    } else {
      let value = editor.getContent();
      if (value === emptyValue) value = '';
      input.onChange(value);
    }
  };

  const props = isEmailCategoryLocation ? { value: input.value } : {};

  return (
    <div
      className={classNames('TinyMCEField', {
        TinyMCEField__errorInner: touched && error,
        TinyMCEField__disabled: disabled,
      })}>
      <label className="TinyMCEField__label">
        {textId ? <FM id={textId} values={textValues} /> : <FM id={schema.description} />}
        {isEmailCategoryLocation && <FM id="categoryEmailMakeSure" />}
      </label>
      <PageContentPreloader ready={loaded} type="tinyMce">
        {loaded && (
          <Editor
            {...props}
            id={input.name}
            disabled={disabled}
            init={TINYMCE_CONFIG}
            initialValue={input.value}
            onEditorChange={handleChange}
            onBlur={input.onBlur}
          />
        )}
        <span className="TinyMCEField__error">{touched && error}</span>
      </PageContentPreloader>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TinyMCEField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  withScript: PropTypes.bool,
  textId: PropTypes.string,
  textValues: PropTypes.object,
  schema: PropTypes.object,
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

TinyMCEField.defaultProps = {
  withScript: false,
  textId: '',
  textValues: {},
  theme: {},
  schema: {},
  disabled: false,
};

export default withTheme()(TinyMCEField);
export { TinyMCEField };
