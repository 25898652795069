import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector } from 'redux-form';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { ReduxTextField, ValueSwitcherField, ReduxSelectField, CheckboxField } from '../../form-fields';

import { CrossIcon } from '../..';

import DefaultButton from '../../DefaultButton';

import { staticStyles } from './style';

const leverages = [1, 5, 10, 25, 50, 100, 500, 1000];

const CRMSettingAccountForm = ({ fields, mtGroups, mtGroupsAreLoading, fieldArrayIsDemoValues, mtGroupsError }) => (
  <div className="AccountsForm">
    {mtGroupsError && <span className="AccountsForm__Error">{mtGroupsError}</span>}
    {fields.map((member, index) => (
      <div key={index} className="AccountForm">
        {fields.length > 1 && (
          <div className="AccountForm__remove-item">
            <div />
            <button type="button" onClick={() => fields.remove(index)}>
              <CrossIcon />
            </button>
          </div>
        )}
        <FM id="welcomeSetupSettingGroupResource">
          {txt => <span className="AccountForm__title">{`${txt} ${index + 1}`}</span>}
        </FM>
        <Field
          name={`${member}.groups`}
          textId="justAccounts"
          type="text"
          multi
          tooltipTextId="welcomeSetupStep4Tooltip1"
          options={mtGroups.map(({ groupName, groupCurrency }) => ({
            value: JSON.stringify({ groupName, groupCurrency }),
            label: `${groupName} (${groupCurrency})`,
          }))}
          disabled={mtGroupsAreLoading}
          isLoading={mtGroupsAreLoading}
          isLoadingTextId="justPleaseWait"
          component={ReduxSelectField}
          validate={[required()]}
        />
        {mtGroupsAreLoading && (
          <FM id="welcomeSetupAccountsWaitTime">{text => <span className="AccountWaitTimeText">{text}</span>}</FM>
        )}
        <ValueSwitcherField
          leftTextId="justDemo"
          rightTextId="justReal"
          leftValue="true"
          rightValue="false"
          checkedValue={fieldArrayIsDemoValues[index]}
          name={`${member}.isDemo`}
          validate={[required()]}
        />
        <Field
          component={CheckboxField}
          name={`${member}.openAccountInGroup`}
          textId="settingAccountTypeAllowCreation"
          customDefaultValue={false}
        />
        <Field
          component={ReduxTextField}
          name={`${member}.title`}
          tooltipHoverTextId="welcomeSetupStep4Tooltip2"
          textId="welcomeSetupMtGroupsNamingInCRM"
          validate={[required()]}
        />
        <Field
          component={ReduxTextField}
          name={`${member}.description`}
          textId="welcomeSetupDescriptionOptional"
          tooltipHoverTextId="welcomeSetupStep4Tooltip3"
          validate={[required()]}
        />
        <Field
          name={`${member}.maxLeverage`}
          options={leverages.map(value => ({ value, label: `1:${value}` }))}
          textId="justMaxLeverage"
          component={ReduxSelectField}
          validate={[required()]}
        />
      </div>
    ))}
    <div>
      <div className="AccountsForm__add-account">
        <div />
        <DefaultButton
          type="button"
          plus
          noBorder
          textId="welcomeSetupAddAccountGroup"
          onClick={() => {
            fields.push({
              maxLeverage: 100,
              isDemo: 'true',
            });
          }}
        />
      </div>
    </div>
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

CRMSettingAccountForm.propTypes = {
  mtGroups: PropTypes.array.isRequired,
  mtGroupsAreLoading: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  fieldArrayIsDemoValues: PropTypes.array.isRequired,
  mtGroupsError: PropTypes.string,
};

CRMSettingAccountForm.defaultProps = {
  mtGroupsError: '',
};

const selector = formValueSelector('wsCRMSettingForm');

export default compose(
  withRouter,
  connect(state => ({
    fieldArrayIsDemoValues: selector(state, 'accountsConfig').map(({ isDemo }) => isDemo),
    mtGroups: state.welcomeSetup.mtGroups || [],
    mtGroupsAreLoading: state.welcomeSetup.mtGroupsAreLoading,
    mtGroupsError: state.welcomeSetup.mtGroupsError,
  }))
)(CRMSettingAccountForm);
export { CRMSettingAccountForm };
