import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BonusDisplayPanel {
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    padding: 25px 30px;
    @include respond-to(sm) {
      padding: 20px 15px 1px;
    }

    .BonusDisplayPanel__caption {
      font-weight: 300;
      font-size: rem(24px);
      margin-bottom: rem(20px);
    }

    .BonusDisplayPanel__line {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      :global(> div) {
        flex: 1;

        margin-right: rem(20px);
      }

      &:last-child {
        :global(> div) {
          &:nth-child(1) {
            flex: 10;
          }
          &:nth-child(2) {
            flex: 5;
          }
          &:nth-child(3) {
            flex: 16;
          }
        }
      }

      :global(.BonusDisplayPanel__checkbox-section) {
        display: flex;
        flex-wrap: wrap;
        margin: rem(20px 0 0);

        :global(.CheckboxField) {
          margin-right: rem(${isRTL ? '0' : '20px'});
          margin-left: rem(${isRTL ? '20px' : '0'});
        }
      }
    }

    .BonusDisplayPanel__duration {
      font-size: rem(15px);
      padding: rem(15px 0);
      margin-right: rem(25px);
    }

    .BonusDisplayPanel__title {
      font-size: rem(15px);
      margin-right: rem(20px);
    }
  }

  .BonusDisplayPanel {
    &__top-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &__top-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 25px 30px 0;

      @include respond-to(sm) {
        margin: 0 20px 25px 0;
      }
    }

    &__top-left-title {
      margin: 0 20px 0 0;

      @include respond-to(sm) {
        margin: 0 18px 0 0;
      }
    }

    &__top-left {
      :global(.DateRangeField) {
        margin: 0 0 -20px 0;
      }
    }

    &__top-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 25px 0;

      @include respond-to(sm) {
        margin: 0 0 20px 0;
      }
    }

    &__top-right-title {
      margin: 0 20px 0 0;

      @include respond-to(sm) {
        margin: 0 18px 0 0;
      }
    }

    &__top-right {
      :global(.SwitchField__error) {
        display: none !important;
      }
    }

    &__lang-title {
      margin: 0 0 0 0;
    }

    &__available-accounts {
      margin: 20px 0 0 0;

      @include respond-to(sm) {
        margin: 15px 0 0 0;
      }
    }

    :global(&__available-accounts-list) {
      padding: 0 0 10px 0;

      @include respond-to(sm) {
        padding: 0 0 15px 0;
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .BonusDisplayPanel {
      background: ${colors.white.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }
