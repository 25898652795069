import { combineReducers } from 'redux';

import { reducer as login } from 'auth/login';
import { reducer as registration } from 'auth/registration';

const reducers = combineReducers({
  login,
  registration,
});

const reducer = (state, action) => reducers(state, action);

export default reducer;
