import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserBonuses {
    :global(.NoItemsPanel) {
      height: calc(60vh - 133px);
      @include respond-to(sm) {
        height: calc(60vh - 160px);
      }
    }
  }

  .UserBonuses__dissalowBonusesCheckBox {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    margin-bottom: 1rem;
  }

  .UserBonuses__head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    justify-content: space-between;
    margin-bottom: rem(25px);

    @include respond-to(sm) {
      flex-direction: column;
    }

    :global(.BaseSelectField) {
      min-width: 300px;

      @include respond-to(sm) {
        min-width: 120px;
      }
    }

    :global(.DefaultButton) {
      min-width: 150px;
      height: 50px;

      @include respond-to(sm) {
        max-width: fit-content;
        margin-left: auto;
      }
      @include respond-to(xs) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;

export { staticStyles };
