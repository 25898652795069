import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { ProgressBar, TopMenu, LeftMenu, RegistrationForm, InfoBlock } from '../../_components';

import { staticStyles } from './style';

const WelcomeSetupRegistrationPage = ({ intl }) => {
  const history = useHistory();
  const [didEmailChange, setDidEmailChange] = useState(false);
  const [didSubmitHappend, setDidSubmitHappend] = useState(false);

  useEffect(() => {
    const wasIntroPageReaded = localStorage.getItem('wasIntroPageReaded');
    if (!wasIntroPageReaded) history.push('/welcome-setup/intro');
  }, []);

  return (
    <div className="WelcomeSetupRegistrationPage">
      <Helmet>
        <title>{intl.formatMessage({ id: 'welcomeSetupRegistrationTitle' })}</title>
      </Helmet>
      <TopMenu />
      <div className="WelcomeSetupRegistrationPage__fmSlug">
        <FM id="menuCrmSetup" />
      </div>
      <div className="CrmSetupBlock">
        <LeftMenu />
        <div className="ProgressBarAndFormInfoBlock">
          <ProgressBar wsStep={1} />
          <div className="FormAndInfoText">
            <RegistrationForm
              setDidSubmitHappend={setDidSubmitHappend}
              didSubmitHappend={didSubmitHappend}
              setDidEmailChange={setDidEmailChange}
              didEmailChange={didEmailChange}
            />
            <InfoBlock wsStep={1} />
          </div>
        </div>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

WelcomeSetupRegistrationPage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(WelcomeSetupRegistrationPage);
