import css from 'styled-jsx/css';
import loopIcon from 'common/images/loop.svg';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ContractSpecsPage {
    &__tabs {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;

      @include respond-to(sm) {
        display: none;
      }
    }

    &__tab {
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-right: ${isRTL ? '0' : '30px'};
      margin-left: ${isRTL ? '30px' : '0'};
      margin-bottom: 30px;

      &:last-child {
        margin-right: 0;
      }

      &-label {
        width: 40px;
        height: 40px;
        font-size: 14px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${isRTL ? '0' : '10px'};
        margin-left: ${isRTL ? '10px' : '0'};
      }
    }

    &__select {
      &--mobile {
        display: none;

        @include respond-to(sm) {
          display: block;
        }
      }
    }

    .SearchPanel {
      margin-bottom: rem(30px);

      :global(.BaseTextField) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          right: ${isRTL ? 'auto' : '15px'};
          left: ${isRTL ? '15px' : 'auto'};
          top: 50%;
          transform: translateY(-50%);
          width: 17px;
          height: 17px;
          background-image: url(${loopIcon});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ContractSpecsPage {
      &__tab {
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          color: ${colors.primary.hex};
          border-bottom-color: ${colors.primary.hex};
        }

        &-label {
          color: ${colors.primary.hex};
          border: 1px solid ${colors.primary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
