import React from 'react';

const StatusesIcon = () => (
  <div>
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.71 17.498a.429.429 0 01-.429-.426c0-4.004-3.291-7.264-7.335-7.264a.429.429 0 01-.43-.425c0-.234.194-.426.43-.426 4.044 0 7.335-3.26 7.335-7.263a.429.429 0 01.86 0c0 4.004 3.29 7.263 7.334 7.263.236 0 .43.192.43.426a.429.429 0 01-.43.425c-4.043 0-7.335 3.256-7.335 7.264a.429.429 0 01-.43.426zM4.568 9.383a8.186 8.186 0 015.144 5.094 8.186 8.186 0 015.143-5.094 8.186 8.186 0 01-5.143-5.094 8.206 8.206 0 01-5.144 5.094zM13.836 16.17c1.895 0 3.437 1.528 3.437 3.404a.429.429 0 00.86 0c0-1.876 1.542-3.404 3.437-3.404.237 0 .43-.191.43-.425a.429.429 0 00-.43-.426c-1.895 0-3.437-1.528-3.437-3.404a.429.429 0 00-.43-.426.429.429 0 00-.43.426c0 1.876-1.542 3.404-3.437 3.404a.429.429 0 00-.43.426c0 .234.194.425.43.425zm3.867-2.4a4.291 4.291 0 001.994 1.975 4.291 4.291 0 00-1.994 1.974 4.291 4.291 0 00-1.994-1.974 4.291 4.291 0 001.994-1.975zM.43 3.404c1.186 0 2.148.953 2.148 2.128a.429.429 0 00.86 0c0-1.175.962-2.128 2.148-2.128.236 0 .43-.191.43-.425a.429.429 0 00-.43-.426A2.139 2.139 0 013.437.426a.429.429 0 00-.859 0A2.139 2.139 0 01.43 2.553.429.429 0 000 2.98c0 .234.193.425.43.425zm2.578-1.447c.253.417.61.77 1.031 1.022A3.022 3.022 0 003.008 4a3.022 3.022 0 00-1.031-1.021 3.021 3.021 0 001.03-1.022z" />
    </svg>
  </div>
);

export default StatusesIcon;
