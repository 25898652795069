import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  .ApproveWithdrawalForm {
    margin-top: 30px;

    &__btns {
      :global(.DefaultButton) {
        &:first-child {
          margin-right: ${isRTL ? '0' : '30px'};
          margin-left: ${isRTL ? '30px' : '0'};
        }
      }
    }
  }
`;

export default staticStyles;
