import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';

import PaginationTable from '../PaginationTable';

import { staticStyles, getDynamicStyles } from './style';

const UsersTable = ({
  data,
  columns,
  getTrProps,
  loading,
  location,
  history,
  getList,
  className,
  params,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className={classNames(className || 'UsersTable')}>
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        getTrProps={getTrProps}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

UsersTable.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  getTrProps: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  className: PropTypes.string,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

UsersTable.defaultProps = {
  getTrProps: () => ({ onClick: () => null }),
  loading: false,
  className: '',
  theme: {},
};

export default withTheme()(UsersTable);
export { UsersTable };
