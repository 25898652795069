import css from 'styled-jsx/css';
import loupe from './loupe.svg';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CRMSearchBar {
    width: 100%;
    height: max-content;
    @include respond-to(xs) {
    }

    &__input {
      height: 44px;
      padding: 12px 16px;
      padding-right: 30px;
      border-color: #f3f3f3;
      border-radius: 8px;
      font-size: 14px;
      color: #333;
      background-color: #fff;
      background-image: url(${loupe});
      background-repeat: no-repeat;
      background-position: 94% 47%;
      background-size: auto;

      &::placeholder {
        font-weight: 400;
        color: #cecece;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }

    &__input--noLoupe {
      background-image: initial;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
