/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import useOutsideClick from 'hooks/useOutsideClick';

import { SearchBar } from 'crm/_components';
import { staticStyles } from './style';

const DropDown = ({ setIsOpen, onChange, enteredValues, position }) => {
  const optionsRightRef = useRef();

  const [from, setFrom] = useState(enteredValues[0] ? enteredValues[0] : '0');

  const [to, setTo] = useState(enteredValues[1] ? enteredValues[1] : '999999999');
  const handleOutSideClick = () => {
    setIsOpen(false);
  };

  const isError = useMemo(
    () => (from.startsWith('0') && from.length > 1) || (to.startsWith('0') && from.length > 1) || +from > +to,
    [from, to]
  );

  useOutsideClick(optionsRightRef, handleOutSideClick);

  const portalStyle = {
    position: 'absolute',
    top: +position?.top + +position?.height,
    left: position?.left,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    width: '211px',
  };

  return ReactDOM.createPortal(
    <div style={portalStyle} className="congratulations-portal">
      <div ref={optionsRightRef} className={classNames('RangeSelector__Options')}>
        <div className="RangeSelector__OptionsLeft">
          <SearchBar
            initValue={from}
            placeholder="From"
            noLoupe
            onChange={newVal => {
              setFrom(newVal);
            }}
          />
          <SearchBar initValue={to} placeholder="To" noLoupe onChange={newVal => setTo(newVal)} />
          <button
            className={classNames('RangeSelector__ApplyButton', { 'RangeSelector__ApplyButton--Disabled': isError })}
            disabled={isError}
            onClick={e => {
              e.stopPropagation();
              if ((from && from.length > 0) || (to && to.length > 0)) {
                onChange([from.length ? from : '0', to.length ? to : '999999999']);
              }
              setIsOpen(false);
            }}>
            Apply
          </button>
          <button
            className="RangeSelector__ApplyButton"
            disabled={from && from.length === 0 && to && to.length === 0}
            onClick={e => {
              e.stopPropagation();

              onChange([]);

              setIsOpen(false);
            }}>
            Reset
          </button>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </div>,
    document.body
  );
};

export default DropDown;
