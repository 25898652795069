/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { NoItemsPanel, Tooltip } from 'common/components';
import { compose } from 'redux';
import { useMediaQuery } from 'react-responsive';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import noPaymentsImage from 'common/images/noPayments.svg';
import ArrowsDown from './icons/ArrowsDown.svg';
import ArrowsUp from './icons/ArrowsUp.svg';
import NoSortArrows from './icons/NoSortArrows.svg';
import ClosedSubRow from './icons/ClosedSubRow.svg';
import OpenedSubRow from './icons/OpenedSubRow.svg';

import { staticStyles, getDynamicStyles } from './style';

const SortIcon = ({ currentOrdering, accessor }) => {
  if (currentOrdering === `-${accessor}`) {
    return <img style={{ height: '10px' }} src={ArrowsDown} alt="SortDown" />;
  }
  if (currentOrdering === accessor) {
    return <img style={{ height: '10px' }} src={ArrowsUp} alt="SortUp" />;
  }

  return <img style={{ height: '10px' }} src={NoSortArrows} alt="NoSort" />;
};

const UpdatedCRMTable = ({ data, tableSetup, placeholderTextId, history, location, whereOpenSubRowIconIndex }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1150px)' });
  const { columns, initSubRow, initGrid } = tableSetup;
  const isExpandable = !!initSubRow;
  const dynamicStyles = getDynamicStyles({ initGrid });

  const query = useMemo(() => ({ pageSize: 10, ...queryString.parse(location.search) }), [location.search]);

  const currentOrdering = useMemo(() => query.ordering, [query.ordering]);

  const handleColumnSortChange = columnAccessor => {
    const currentOrdering = query.ordering;
    let newOrdering = null;

    if (currentOrdering !== `-${columnAccessor}` && currentOrdering !== columnAccessor) {
      newOrdering = `-${columnAccessor}`;
    }

    if (currentOrdering === `-${columnAccessor}`) {
      newOrdering = columnAccessor;
    }

    history.replace({
      search: queryString.stringify({
        ...query,
        ordering: newOrdering,
      }),
    });
  };

  if (!data?.length) {
    return (
      <NoItemsPanel
        icon={noPaymentsImage}
        containerMaxHeight
        captionSlug={placeholderTextId || 'crmThereAreNoItemsMatchingParameters'}
      />
    );
  }
  return (
    <div className="UpdatedCRMTable__wrapper">
      <div className="UpdatedCRMTable__Thead">
        <div className="UpdatedCRMTable__Thead-Row">
          {columns.map(({ Header, isCheckbox, HeaderTop, accessor, isSortable, removeInMobile }, ind) => (
            <div
              key={`${ind}UpdatedCRMTable__Thead__Cell`}
              className={classNames('UpdatedCRMTable__Thead__Cell', {
                'UpdatedCRMTable__Thead__Cell--Sortable': isSortable,
                'UpdatedCRMTable__Thead__Cell--IsCheckboxTemp': isCheckbox,
                RemoveInMobile: removeInMobile,
              })}
              onClick={() => {
                if (isSortable) {
                  handleColumnSortChange(accessor);
                }
              }}>
              <div className="UpdatedCRMTable__Thead__Cell-Inner">
                <div className="UpdatedCRMTable__Thead__Cell-Inner-Top">{HeaderTop}</div>
                <div className="UpdatedCRMTable__Thead__Cell-Inner-Bottom">
                  {typeof Header === 'string' ? <FM id={Header} /> : Header()}
                  {isSortable && <SortIcon currentOrdering={currentOrdering} accessor={accessor} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="UpdatedCRMTable__Tbody">
        {data.map((rowData, ind) => (
          <UpdatedCRMTableBodyRow
            whereOpenSubRowIconIndex={whereOpenSubRowIconIndex}
            key={`${ind} + UpdatedCRMTableBodyRow`}
            rowIndex={ind}
            isMobile={isMobile}
            rowData={rowData}
            columns={columns}
            initSubRow={initSubRow}
            isExpandable={isExpandable}
          />
        ))}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

function SubRow({ initSubRow, rowData }) {
  console.log({ initSubRow });
  return (
    <div className="UpdatedCRMTable__Tbody__SubRow__Wrapper">
      <div className="UpdatedCRMTable__Tbody__SubRow">
        {initSubRow
          .filter(({ isDisabled }) => typeof isDisabled === 'undefined' || !isDisabled({ rowData }))
          .map(({ Cell, Header }, ind) => (
            <div
              key={`UpdatedCRMTable__Tbody__SubRow ${ind}`}
              className={classNames('UpdatedCRMTable__Tbody__Cell__SubRow')}>
              <div className="UpdatedCRMTable__Tbody__Cell-Inner__SubRow">
                {/* {HeaderTop && <div className="UpdatedCRMTable__Tbody__Cell-Inner-Top__SubRow">{HeaderTop}</div>} */}
                <div className="UpdatedCRMTable__Tbody__Cell-Inner-Bottom__SubRow">
                  {typeof Header === 'string' ? <FM id={Header} /> : Header({ rowData })}
                </div>
              </div>
              <div>{Cell({ rowData })}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

function UpdatedCRMTableBodyRow({ rowData, columns, initSubRow, rowIndex, isMobile, whereOpenSubRowIconIndex }) {
  const [isOpen, setIsOpen] = useState(false);

  const combined = [...columns.filter(({ removeInMobile }) => removeInMobile), ...[...initSubRow].reverse()];

  const notExpandable = !initSubRow?.length;

  return (
    <div>
      <div className="UpdatedCRMTable__Tbody-Row">
        {columns.map(({ Cell, accessor, removeInMobile }, ind) => {
          if (ind === whereOpenSubRowIconIndex) {
            const OpenSubComponent = isOpen ? OpenedSubRow : ClosedSubRow;
            return (
              <div
                key={`${accessor} ${ind}`}
                className={classNames('UpdatedCRMTable__Tbody__Cell', {
                  RemoveInMobile: removeInMobile,
                })}>
                <img
                  src={OpenSubComponent}
                  className={classNames('UpdatedCRMTable__Tbody__Cell__SubRowIcon', {
                    'UpdatedCRMTable__Tbody__Cell__SubRowIcon--NotExpandable': notExpandable,
                  })}
                  alt="SubRowIcon"
                  onClick={() => setIsOpen(!isOpen)}
                />
                {Cell({ rowData })}
              </div>
            );
          }
          return (
            <div
              key={`${accessor} ${ind}`}
              className={classNames('UpdatedCRMTable__Tbody__Cell', {
                RemoveInMobile: removeInMobile,
              })}>
              {ind === 0 && window.location.pathname === '/crm/contacts' && rowData?.status?.color && (
                <Tooltip
                  textExtraclass="UpdatedCRMTable__Tbody__Cell__SalesStatus__Tooltip"
                  id={`UpdatedCRMTable__Tbody__Cell__SalesStatus-${rowIndex}`}
                  textId={rowData?.status?.title}>
                  <div
                    style={{ background: rowData.status.color }}
                    className="UpdatedCRMTable__Tbody__Cell__SalesStatus"
                  />
                </Tooltip>
              )}
              {Cell({ rowData })}
            </div>
          );
        })}
      </div>
      {isOpen && <SubRow initSubRow={isMobile ? combined : initSubRow} rowData={rowData} />}
    </div>
  );
}

export default compose(withTheme(), withRouter)(UpdatedCRMTable);
