import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { PageLayout } from 'common/components';
import { connect } from 'react-redux';
import { createBonusInfo, getBonusTypes } from '../../_redux';
import { BonusesForm } from '../../_components';

const CreateBonusPage = ({ bonusTypes, getBonusTypes, defaultBonusType, history, lang }) => {
  useEffect(() => {
    getBonusTypes();
  }, [getBonusTypes]);

  return (
    <PageLayout captionSlug="bonusCreateTitle">
      <BonusesForm
        submitFunction={createBonusInfo}
        buttonTextId="justCreate"
        history={history}
        initialValues={{ availableLanguages: { [lang]: true }, lifetime: 0, defaultBonusType }}
        successMessageId="bonusesCreatedSuccessfully"
        bonusTypes={bonusTypes}
      />
    </PageLayout>
  );
};

CreateBonusPage.propTypes = {
  bonusTypes: PropTypes.array.isRequired,
  getBonusTypes: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  defaultBonusType: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    bonusTypes: state.backoffice.bonuses.bonusTypes,
    bonusTypesAreLoaded: state.backoffice.bonuses.bonusTypesAreLoaded,
    lang: state.interfaceConfig.lang,
    defaultBonusType: state.interfaceConfig.defaultBonusType,
  }),
  {
    getBonusTypes: () => getBonusTypes.request(),
  }
)(CreateBonusPage);
