import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { PageContentPreloader, PageLayout } from 'common/components';
import { getBonusInfo, editBonusInfo, getBonusTypes } from '../../_redux';
import { BonusesForm } from '../../_components';

const EditBonusPage = ({
  bonusInfo,
  bonusInfoIsLoaded,
  bonusTypes,
  getBonusTypes,
  history,
  match,
  getBonusInfo,
  intl,
}) => {
  useEffect(() => {
    getBonusInfo({ bonusId: match.params.id });
    getBonusTypes();
  }, [getBonusInfo, getBonusTypes, match.params.id]);

  Object.keys(bonusInfo).forEach(key => bonusInfo[key] == null && delete bonusInfo[key]);

  const {
    isActive,
    convRate,
    startDate,
    endDate,
    title,
    description,
    promoTexts,
    availableLanguages,
    rules,
    availableForAccountTypes,
    filterConditions,
    bonusValue,
    minDeposit,
    depositPercent,
    logo,
    ...props
  } = bonusInfo;
  let initialValues;

  if (bonusInfoIsLoaded) {
    initialValues = {
      ...props,
      isActive: !isActive,
      availableLanguages: availableLanguages.reduce((acc, lang) => ({ ...acc, [lang]: true }), {}),
      availableForAccountTypes: availableForAccountTypes.reduce((acc, id) => ({ ...acc, [id]: true }), {}),
      title: title.reduce((acc, title) => ({ ...acc, [title.lang]: title.text }), {}),
      description: description.reduce(
        (acc, description) => ({
          ...acc,
          [description.lang]: description.text,
        }),
        {}
      ),
      rules: rules.reduce((acc, rule) => ({ ...acc, [rule.lang]: rule.text }), {}),
      filterConditions,
      promoTexts: promoTexts.reduce((acc, promo, id) => {
        const promoValue = promo.reduce((acc, cur) => ({ ...acc, [cur.lang]: cur.text }), {});
        return { ...acc, [`bullets_${id}`]: promoValue };
      }, {}),
      file: [logo],
    };
  }
  if (convRate) {
    initialValues = {
      ...initialValues,
      conversion: true,
      convRate: convRate.amount,
    };
  }
  if (bonusValue) {
    initialValues = { ...initialValues, bonusValue: bonusValue.amount };
  }
  if (minDeposit) {
    initialValues = { ...initialValues, minDeposit: minDeposit.amount };
  }
  if (depositPercent) {
    initialValues = { ...initialValues, depositPercent };
  }

  let bonusTypeOptions = bonusTypes;

  if (bonusInfo.bonusType === 'bonus_compensation') {
    bonusTypeOptions = [
      ...bonusTypes,
      {
        slug: 'bonus_compensation',
        title: intl.formatMessage({ id: 'bonusCompensation' }),
      },
    ];
  }

  if (bonusInfo.bonusType === 'bonus_manual') {
    bonusTypeOptions = [
      ...bonusTypes,
      {
        slug: 'bonus_manual',
        title: intl.formatMessage({ id: 'bonusManual' }),
      },
    ];
  }

  return (
    <PageLayout captionSlug="bonusEditTitle">
      <PageContentPreloader ready={bonusInfoIsLoaded} type="bigLine">
        <BonusesForm
          submitFunction={editBonusInfo}
          buttonTextId="doSave"
          history={history}
          initialValues={initialValues}
          promoTexts={promoTexts}
          hasLogo={!!logo}
          successMessageId="bonusesCreatedSuccessfully"
          bonusTypes={bonusTypeOptions}
        />
      </PageContentPreloader>
    </PageLayout>
  );
};

EditBonusPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getBonusInfo: PropTypes.func.isRequired,
  bonusInfo: PropTypes.object.isRequired,
  bonusInfoIsLoaded: PropTypes.bool.isRequired,
  bonusTypes: PropTypes.array.isRequired,
  getBonusTypes: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      bonusInfo: state.backoffice.bonuses.bonusInfo,
      bonusInfoIsLoaded: state.backoffice.bonuses.bonusInfoIsLoaded,
      bonusTypes: state.backoffice.bonuses.bonusTypes,
      bonusTypesAreLoaded: state.backoffice.bonuses.bonusTypesAreLoaded,
    }),
    {
      getBonusInfo: data => getBonusInfo.request(data),
      getBonusTypes: () => getBonusTypes.request(),
    }
  )
)(EditBonusPage);
