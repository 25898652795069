import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/rem";
  @import "src/common/styling/_mixins-scss/screen-size";

  .SavedReportsTable {
    .ReactTable {
      .rt-thead {
        min-width: 850px !important;
        .rt-tr {
          .rt-th {
            &:nth-child(1),
            &:nth-child(3) {
              flex: 70 0 auto !important;
              width: 70px !important;
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }

            &:last-child {
              flex: 40 0 auto !important;
              width: 60px !important;
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
          }
        }
      }
      .rt-tbody {
        min-width: 850px !important;
        .rt-tr-group {
          .rt-tr {
            .rt-td {
              span {
                font-size: rem(14px);
              }
              &:nth-child(1),
              &:nth-child(3) {
                flex: 70 0 auto !important;
                width: 70px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                }
              }

              &:last-child {
                flex: 40 0 auto !important;
                width: 60px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
            }
          }
        }
      }
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        &:first-child {
          flex: 35;
        }
        &:nth-child(2) {
          flex: 150;
        }
        &:nth-child(3) {
          flex: 120;
        }
        &:last-child {
          padding: 0;
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;
      .ResponsiveTable__preview-main {
        height: 40px;

        .ResponsiveTable__row-item {
          flex: 60;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            flex: 35 0 auto !important;
            width: 35px !important;

            @include respond-to(xs) {
              max-width: 35px;
            }
          }

          &:nth-child(2) {
            margin: 0 auto;
            flex: 150 0 auto !important;
            width: 150px !important;

            @include respond-to(xs) {
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &:nth-child(3) {
            margin: 0 auto;
            flex: 120 0 auto !important;
            width: 120px !important;

            @include respond-to(xs) {
              max-width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .ResponsiveTable__preview-description {
        .ResponsiveTable__row-item {
          justify-content: flex-start;
          padding-left: 10%;
        }
        :global(.StatusCell) {
          font-size: rem(12px) !important;
        }
      }
      .ResponsiveTable__details-item {
        padding-left: 15%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .SavedReportsTable__download-button {
      &:hover {
        svg {
          path {
            &:first-child {
              fill: ${colors.primary.hex};
            }

            &:nth-child(2) {
              stroke: ${colors.primary.hex};
            }
          }
        }
      }
      &:disabled {
        &:hover {
          :global(svg) {
            :global(path) {
              fill: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
