import css from 'styled-jsx/css';

import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .DefaultButton {
    position: relative;
    display: inline-block;
    padding: rem(9px 20px);
    cursor: pointer;
    font-size: rem(15px);
    line-height: rem(22px);
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    min-width: rem(105px);
    letter-spacing: rem(0.5px);
    &:active {
      box-shadow: none;
    }
    &:disabled {
      cursor: not-allowed;

      &.filled {
        opacity: 0.5;
      }
    }
  }

  .wide {
    width: 100%;
  }

  .plus {
    padding-left: rem(${isRTL ? '20px' : '40px'});
    padding-right: rem(${isRTL ? '40px' : '20px'});
    &:after,
    &:before {
      content: '';
      position: absolute;
      width: rem(16px);
      height: rem(2px);
      top: 48%;
      transform: translateY(-48%);
      left: rem(${isRTL ? '0' : '13px'});
      right: rem(${isRTL ? '13px' : '0'});
    }
    &:after {
      transform: rotate(-90deg);
      top: 46%;
    }
  }

  .previous {
    &:not(.wide) {
      .DefaultButton__text {
        padding-left: rem(10px);
      }

      &:before {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(10px);
        top: 40%;
        left: 15px;
        transform: rotate(45deg);
      }
    }

    &.wide .DefaultButton__text {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(10px);
        left: -20px;
        top: calc(50% - 5px);
        transform: rotate(45deg);
      }
    }
  }

  .next {
    &:not(.wide) {
      .DefaultButton__text {
        padding-right: rem(10px);
      }

      &:after {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(10px);
        top: calc(50% - 5px);
        right: 20px;
        transform: rotate(-45deg);
      }
    }

    &.wide .DefaultButton__text {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(10px);
        top: calc(50% - 5px);
        right: -20px;
        transform: rotate(-45deg);
      }
    }
  }

  .ghost {
    border: none !important;
  }

  .noBorder {
    border: none !important;
    padding: 0;
    background: transparent !important;
    min-width: auto;
    text-transform: uppercase;
  }

  .DefaultButton__preloader {
    display: none !important;
  }

  .DefaultButton_preload {
    padding: rem(9px 20px 9px 50px);
    border-color: transparent !important;
    transition: none;
    .DefaultButton__preloader {
      display: block !important;
    }
  }

  .DefaultButton__preloader {
    width: rem(22px);
    height: rem(22px);
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    left: rem(15px);
    top: 20%;
    padding: 0;
    :global(svg) {
      width: 180%;
      height: 180%;
      position: relative;
      bottom: rem(8px);
      right: rem(9px);
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .DefaultButton {
      border-radius: ${border.radius};
      border: 1px solid ${colors.primary.hex};
      color: ${colors.primary.hex};
      &:hover {
        border-color: ${rgba([colors.primary.rgb, 0.8])};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
        color: ${rgba([colors.primary.rgb, 0.8])};
      }
      &:disabled:not(.filled) {
        color: #5d647d;
        border-color: #bfc7d7;
        background: transform;

        &:hover {
          background: transparent;
        }
      }
    }

    .filled {
      color: ${colors.white.hex};
      border: none;
      background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};

      &:hover {
        color: ${colors.white.hex};
      }

      &:not(:disabled):hover {
        box-shadow: 0 3px 6px 0 ${rgba([colors.primary.rgb, 0.4])};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }

      &:disabled {
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }
    }

    .plus {
      &:after,
      &:before {
        background-color: ${colors.primary.hex};
      }
    }

    .previous {
      &:not(.wide):before {
        border: solid ${colors.primary.hex};
        border-width: 0 0 1px 1px;
      }

      &.wide .DefaultButton__text:before {
        border: solid ${colors.primary.hex};
        border-width: 0 0 1px 1px;
      }
    }

    .next {
      &:not(.wide):before {
        border: solid ${colors.primary.hex};
        border-width: 0 0 1px 1px;
      }

      &.wide .DefaultButton__text:before {
        border: solid ${colors.primary.hex};
        border-width: 0 0 1px 1px;
      }
    }

    .filled.plus {
      &:after,
      &:before {
        background-color: ${colors.white.hex};
      }
    }

    .noBorder {
      &:hover {
        color: ${colors.secondary.hex};
      }
    }

    .DefaultButton_preload {
      background: ${gradient(53.07, [colors.gradientStart.rgb, 0.2], 0, [colors.gradientEnd.rgb, 0.2], 100)};
    }

    .DefaultButton_preload.filled {
      background: ${gradient(81, [colors.gradientStart.rgb, 0.6], 100, [colors.gradientEnd.rgb, 0.6], 100)};
    }

    .DefaultButton__preloader {
      :global(svg) {
        :global(path) {
          fill: ${colors.primary.hex};
        }
      }
    }

    .filled {
      .DefaultButton__preloader {
        :global(svg) {
          :global(path) {
            fill: ${colors.white.hex};
          }
        }
      }
    }

    .DefaultButton__success {
      border-color: ${rgba([colors.success.rgb, 0.5])};
      color: ${colors.success.hex};
      &:hover {
        background: ${rgba([colors.success.rgb, 0.2])};
        color: ${colors.success.hex};
        border-color: ${colors.success.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.success.rgb, 0.2])};
      }
    }

    .DefaultButton_preload.DefaultButton__success {
      background: ${rgba([colors.success.rgb, 0.2])};
      .DefaultButton__preloader {
        :global(svg) {
          :global(path) {
            fill: ${colors.success.hex};
          }
        }
      }
    }

    .DefaultButton__error {
      border-color: ${rgba([colors.error.rgb, 0.5])};
      color: ${colors.error.hex};
      &:hover {
        background: ${rgba([colors.error.rgb, 0.2])};
        color: ${colors.error.hex};
        border-color: ${colors.error.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.error.rgb, 0.2])};
      }
    }

    .DefaultButton_preload.DefaultButton__error {
      background: ${rgba([colors.error.rgb, 0.2])};
      .DefaultButton__preloader {
        :global(svg) {
          :global(path) {
            fill: ${colors.error.hex};
          }
        }
      }
    }

    .DefaultButton__warning {
      border-color: ${rgba([colors.warning.rgb, 0.5])};
      color: ${colors.warning.hex};
      &:hover {
        background: ${rgba([colors.warning.rgb, 0.2])};
        color: ${colors.warning.hex};
        border-color: ${colors.warning.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.warning.rgb, 0.2])};
      }
    }

    .DefaultButton_preload.DefaultButton__warning {
      background: ${rgba([colors.warning.rgb, 0.2])};
      .DefaultButton__preloader {
        :global(svg) {
          :global(path) {
            fill: ${colors.warning.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
