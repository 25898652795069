import React from 'react';

const IconEmail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
    <path
      d="M1.5 1.25C1.08516 1.25 0.75 1.58516 0.75 2V2.93516L5.33438 6.29609C5.73047 6.58672 6.26953 6.58672 6.66562 6.29609L11.25 2.93516V2C11.25 1.58516 10.9148 1.25 10.5 1.25H1.5ZM0.75 3.86563V8C0.75 8.41484 1.08516 8.75 1.5 8.75H10.5C10.9148 8.75 11.25 8.41484 11.25 8V3.86563L7.10859 6.90078C6.44766 7.38359 5.55 7.38359 4.89141 6.90078L0.75 3.86563ZM0 2C0 1.17266 0.672656 0.5 1.5 0.5H10.5C11.3273 0.5 12 1.17266 12 2V8C12 8.82734 11.3273 9.5 10.5 9.5H1.5C0.672656 9.5 0 8.82734 0 8V2Z"
      fill="white"
    />
  </svg>
);

export default IconEmail;
