import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .IssueCreateMessage__form {
    padding: 0 25px 10px 25px;
    position: relative;
    margin-top: 20px;
    @include respond-to(sm) {
      padding: 0 20px 15px;
      margin-top: 0;
    }
  }

  .IssueCreateMessage__form-field {
    position: relative;

    :global(.TextAreaField__inp) {
      padding-bottom: 30px;
    }
  }

  :global(.IssueCreateMessage__attach) {
    margin-left: 0;
  }

  :global(.FileField__wrapper) {
    display: none;

    &.active {
      display: block;
    }
  }

  :global(.FileField__wrapper.active) {
    display: block;
  }

  .IssueCreateMessage__form-input {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }

  .IssueCreateMessage__form-button {
    display: flex;
    align-items: center;

    :global(.DefaultButton) {
      margin-right: 15px;
    }
  }

  .IssueCreateMessageSwitcher {
    display: flex;
    padding-bottom: 10px;
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .IssueCreateMessage__form {
      background-color: ${colors.white.hex};
    }

    .IssueCreateMessageSwitcher__tab {
      font-size: 15px;
      margin-right: ${isRTL ? '0' : '30px'};
      margin-left: ${isRTL ? '30px' : '0'};
      padding-bottom: 5px;
      cursor: pointer;
      border-bottom: 3px solid transparent;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: ${colors.primary.hex};
        border-bottom-color: ${colors.primary.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
