import { combineReducers } from 'redux';

// internal and external reducers import
import { reducer as formReducer } from 'redux-form';
import { reducer as interfaceConfigReducer } from 'interface/connected-intl';
import { userReducer } from 'interface/user';
import { reducer as authReducer } from 'auth/_redux';
import { reducer as commonReducer } from 'common/_redux';
import { reducer as dashboardsReducer } from 'common/dashboards';
import { reducer as clientReducer } from 'client';
import { reducer as backofficeReducer } from 'backoffice';
import { reducer as adminReducer } from 'admin';
import { reducer as crmReducer } from 'crm';
import { reducer as welcomeSetupReducer } from 'welcome-setup/_redux';

// splitting store to modules with prefix
const reducers = combineReducers({
  form: formReducer,
  interfaceConfig: interfaceConfigReducer,
  user: userReducer,
  auth: authReducer,
  common: commonReducer,
  dashboards: dashboardsReducer,
  client: clientReducer,
  backoffice: backofficeReducer,
  admin: adminReducer,
  welcomeSetup: welcomeSetupReducer,
  crm: crmReducer,
});

export default reducers;
