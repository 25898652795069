import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LightBoxImage } from 'common/components';
import { openLightBox } from 'interface/user/_redux/actions';

import { staticStyles } from './style';

const UserSumSubDocumentPreview = ({ document, openLightBox }) => {
  const imageExtensions = {
    'image/png': '.png',
    'image/jpeg': '.jpeg',
  };

  const currentDocumentFile = useMemo(() => {
    if (document.file) {
      return { url: URL.createObjectURL(document.file), name: document.type + imageExtensions[document.file.type] };
    }

    return { url: '', name: '' };
  }, [document]);

  return (
    <div className="UserSumSubDocument">
      <LightBoxImage
        key={1}
        openLightBox={() => openLightBox(1, [currentDocumentFile.url])}
        image={currentDocumentFile.url}
        titleId={currentDocumentFile.name}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserSumSubDocumentPreview.propTypes = {
  document: PropTypes.object.isRequired,
  openLightBox: PropTypes.func.isRequired,
};

export default connect(undefined, {
  openLightBox: (id, lightBoxImages) => openLightBox(id, lightBoxImages),
})(UserSumSubDocumentPreview);
export { UserSumSubDocumentPreview };
