import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .Question {
    &__content {
      padding: 25px 0;

      p {
        margin-bottom: 25px;
      }

      ul,
      ol {
        margin: 25px 0;
        padding-left: 15px;
      }

      img {
        max-width: 100%;

        @include respond-to(sm) {
          width: 100% !important;
          height: auto !important;
        }
      }
    }

    .Collapsible__header {
      color: #000000;
      font-weight: 500;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0px 1px 7px rgba(10, 161, 226, 0.4);
        transition: box-shadow 0.3s ease;
      }

      .Collapsible__arrow {
        border-color: #000000 !important;
      }

      .Collapsible__caption {
        text-align: left;
      }
    }
  }
`

export { staticStyles }
