import classNames from 'classnames';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PaginationTable, DateCell } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { FormattedMessage } from 'react-intl';
import { staticStyles, getDynamicStyles } from './style';

const StrategyInvestsTable = ({
  data,
  loading,
  getList,
  location,
  history,
  isActiveInvestmentsTable,
  params,
  theme,
  ...props
}) => {
  let sum = null;
  if (!loading && data?.count) {
    sum = data.results.reduce(
      (acc, rec) => ({ ...acc, amount: acc.amount + +rec.equity.amount, currency: rec.equity.currency }),
      { amount: 0, currency: '' }
    );
  }
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'investmentId',
        Cell: ({
          row: {
            original: { id },
          },
        }) => <div className="StrategyInvestsTable__investId">{id}</div>,
        Footer: isActiveInvestmentsTable ? (
          <div className="TotalCell StrategyInvestsTable__TotalCell">
            <FormattedMessage id="Total">{txt => <span>{txt}</span>}</FormattedMessage>
          </div>
        ) : null,
        isPreviewCell: true,
      },
      {
        accessor: 'userName',
        Header: 'justName',
        Cell: ({
          row: {
            original: { userName },
          },
        }) => <div className="StrategyInvestsTable__investId">{userName}</div>,
        isPreviewCell: true,
      },
      {
        accessor: 'created',
        Header: 'justCreated',
        Cell: ({
          row: {
            original: { created },
          },
        }) => <DateCell value={created} />,
        isPreviewCell: true,
      },

      ...(!isActiveInvestmentsTable
        ? [
            {
              accessor: 'closedAt',
              Header: 'justClosed',
              Cell: ({
                row: {
                  original: { closedAt },
                },
              }) => <DateCell value={closedAt} />,
            },
            {
              accessor: 'amount',
              Header: 'justAmount',
              Cell: ({
                row: {
                  original: { amount },
                },
              }) => <span>{amount ?? '一'}</span>,
            },
            {
              accessor: 'age',
              Header: 'ageAndWeeks',
              Cell: ({
                row: {
                  original: { age },
                },
              }) => <span>{age}</span>,
            },
          ]
        : []),
      ...(isActiveInvestmentsTable
        ? [
            {
              accessor: 'equity',
              Header: 'justEquity',
              Cell: ({
                row: {
                  original: { equity },
                },
              }) => (
                <span className="StrategyInvestsTable__equity">
                  {equity ? `${equity.amount} ${equity.currency}` : '一'}
                </span>
              ),
            },
            {
              accessor: 'balance',
              Header: 'justBalance',
              Cell: ({
                row: {
                  original: { balance },
                },
              }) => (
                <span className="StrategyInvestsTable__equity">
                  {balance ? `${balance.amount} ${balance.currency}` : '一'}
                </span>
              ),
              Footer: (
                <div className="TotalCell">
                  <FormattedMessage id="justBalance">{txt => <span className="subtext">{txt}</span>}</FormattedMessage>
                  <span>{sum ? `${sum.amount} ${sum.currency}` : '一'}</span>
                </div>
              ),
            },
          ]
        : []),
    ],
    [isActiveInvestmentsTable, sum]
  );
  /* eslint-enable */

  return (
    <div
      className={classNames('StrategyInvestsTable', {
        'StrategyInvestsTable-active': isActiveInvestmentsTable,
        'StrategyInvestsTable-closed': !isActiveInvestmentsTable,
      })}>
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

StrategyInvestsTable.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isActiveInvestmentsTable: PropTypes.bool,
  theme: PropTypes.object,
};

StrategyInvestsTable.defaultProps = {
  isActiveInvestmentsTable: false,
  theme: {},
};

export default withTheme()(StrategyInvestsTable);
