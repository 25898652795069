import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .AgreementsList {
    .ReactTable {
      .rt-thead {
        min-width: 500px !important;
        .rt-tr {
          .rt-th {
            &:last-child {
              flex: auto !important;
              width: 100px !important;
              text-align: right;
              @include respond-to(sm) {
                flex: auto !important;
                width: 80px !important;
              }
            }
            &:first-child {
              flex: 120 0 auto !important;
              width: 120px !important;
              text-align: left !important;
            }
          }
        }
      }
      .rt-tbody {
        min-width: 500px !important;
        .rt-tr-group {
          .rt-tr {
            .rt-td {
              img {
                width: 5rem;
                height: 5rem;
                object-fit: cover;
              }
              p {
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:last-child {
                flex: auto !important;
                width: 100px !important;
                text-align: right;
                @include respond-to(sm) {
                  flex: auto !important;
                  width: 80px !important;
                }
              }
              &:first-child {
                flex: 120 0 auto !important;
                width: 120px !important;
                text-align: left !important;
              }
            }
          }
        }
      }
    }
  }

  .AgreementsButton {
    display: block;
    width: 100%;
    padding: 1.06667rem 1.2rem;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: #FFFFFF;
    border-radius: 3px;
    transition: all 0.4s ease;
    box-shadow: 0 1px 6px 1px rgba(0,0,0,0.06);
    margin: 0 6px 2px 6px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    text-align: left;

    &:hover {
      box-shadow: 0 3px 9px 4px rgba(10,161,226,0.25);
    }
  }
`

export { staticStyles }
