import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  :global(.ContactsPage) {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export { staticStyles };
