import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const StatusesIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.71074 17.4979C9.47441 17.4979 9.28105 17.3064 9.28105 17.0723C9.28105 13.0681 5.98965 9.80849 1.94629 9.80849C1.70996 9.80849 1.5166 9.617 1.5166 9.38296C1.5166 9.14892 1.70996 8.95743 1.94629 8.95743C5.98965 8.95743 9.28105 5.69785 9.28105 1.6936C9.28105 1.45956 9.47441 1.26807 9.71074 1.26807C9.94707 1.26807 10.1404 1.45956 10.1404 1.6936C10.1404 5.69785 13.4318 8.95743 17.4752 8.95743C17.7115 8.95743 17.9049 9.14892 17.9049 9.38296C17.9049 9.617 17.7115 9.80849 17.4752 9.80849C13.4318 9.80849 10.1404 13.0638 10.1404 17.0723C10.1404 17.3064 9.94707 17.4979 9.71074 17.4979Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M13.8359 16.1701C15.7309 16.1701 17.2734 17.6978 17.2734 19.5744C17.2734 19.8084 17.4668 19.9999 17.7031 19.9999C17.9395 19.9999 18.1328 19.8084 18.1328 19.5744C18.1328 17.6978 19.6754 16.1701 21.5703 16.1701C21.8066 16.1701 22 15.9786 22 15.7446C22 15.5105 21.8066 15.319 21.5703 15.319C19.6754 15.319 18.1328 13.7914 18.1328 11.9148C18.1328 11.6807 17.9395 11.4893 17.7031 11.4893C17.4668 11.4893 17.2734 11.6807 17.2734 11.9148C17.2734 13.7914 15.7309 15.319 13.8359 15.319C13.5996 15.319 13.4062 15.5105 13.4062 15.7446C13.4062 15.9786 13.5996 16.1701 13.8359 16.1701ZM17.7031 13.7701C18.1242 14.6297 18.8289 15.3276 19.6969 15.7446C18.8289 16.1616 18.1242 16.8595 17.7031 17.719C17.282 16.8595 16.5773 16.1616 15.7094 15.7446C16.5773 15.3276 17.282 14.6297 17.7031 13.7701Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M0.429688 3.40426C1.61563 3.40426 2.57812 4.35745 2.57812 5.53191C2.57812 5.76596 2.77148 5.95745 3.00781 5.95745C3.24414 5.95745 3.4375 5.76596 3.4375 5.53191C3.4375 4.35745 4.4 3.40426 5.58594 3.40426C5.82227 3.40426 6.01562 3.21277 6.01562 2.97872C6.01562 2.74468 5.82227 2.55319 5.58594 2.55319C4.4 2.55319 3.4375 1.6 3.4375 0.425532C3.4375 0.191489 3.24414 0 3.00781 0C2.77148 0 2.57812 0.191489 2.57812 0.425532C2.57812 1.6 1.61563 2.55319 0.429688 2.55319C0.193359 2.55319 0 2.74468 0 2.97872C0 3.21277 0.193359 3.40426 0.429688 3.40426ZM3.00781 1.95745C3.26133 2.37447 3.61797 2.72766 4.03906 2.97872C3.61797 3.22979 3.26133 3.58298 3.00781 4C2.7543 3.58298 2.39766 3.22979 1.97656 2.97872C2.39766 2.72766 2.7543 2.37447 3.00781 1.95745Z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="4.39964"
          y1="18.6999"
          x2="19.7608"
          y2="14.0435"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="14.9181"
          y1="20.6302"
          x2="22.9732"
          y2="18.1885"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="1.05827"
          y1="6.39867"
          x2="6.69688"
          y2="4.68947"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

StatusesIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

StatusesIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedStatusesIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(StatusesIconActive);

export default ThemedStatusesIconActive;
