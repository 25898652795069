import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { required, length } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormMessageHOC } from 'common/HOCs';
import { DefaultButton, ReduxTextField, FileField } from 'common/components';
import { withTheme } from 'common/styling/theme';

import exampleFront from './example-front.png';
import exampleBack from './example-back.png';
import { staticStyles, getDynamicStyles } from './style';

const validate = values => {
  const errors = {};

  const cardNumberR = new RegExp('^[0-9]+$');
  const { firstDigits, lastDigits } = values;

  if (!cardNumberR.test(firstDigits)) {
    errors.firstDigits = <FormattedMessage id="form.errors.cardNumber" />;
  }

  if (!cardNumberR.test(lastDigits)) {
    errors.lastDigits = <FormattedMessage id="form.errors.cardNumber" />;
  }

  return errors;
};

const AddCardForm = ({
  submitFunction,
  handleCancel,
  handleSubmit,
  invalid,
  pristine,
  submitting,
  error,
  dispatch,
  theme,
  withoutPhoto,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const submit = handleSubmit(formValues => submitFunction(formValues, dispatch));

  const [frontPhotoSelected, setFrontPhotoSelected] = useState(false);
  const [backPhotoSelected, setBackPhotoSelected] = useState(false);

  const frontPhotoRef = useRef(null);
  const backPhotoRef = useRef(null);

  const handleSelectedBackPhoto = (name, files) => {
    if (name || files) {
      setFrontPhotoSelected(true);
    }
  };

  const handleSelectedFrontPhoto = (name, files) => {
    if (name || files) {
      setBackPhotoSelected(true);
    }
  };

  return (
    <form className="AddCardForm" onSubmit={submit}>
      <div className="AddCardForm__face">
        <FormattedMessage id="justCardFrontFace">
          {txt => <h2 className="AddCardForm__face-title">{txt}</h2>}
        </FormattedMessage>
        <div className="AddCardForm__row">
          <div className="AddCardForm__fields">
            <div className="AddCardForm__fields-half">
              <Field
                name="firstDigits"
                type="text"
                component={ReduxTextField}
                textId="justFirstSixDigits"
                validate={[required(), length({ min: 6, max: 6 })]}
                disabled={submitting}
              />
              <Field
                name="lastDigits"
                type="text"
                component={ReduxTextField}
                textId="justLastFourDigits"
                validate={[required(), length({ min: 4, max: 4 })]}
                disabled={submitting}
              />
            </div>
            <Field
              name="nameOnCard"
              type="text"
              component={ReduxTextField}
              textId="justNameOnCard"
              validate={[required()]}
              disabled={submitting}
            />
            <div className="AddCardForm__fields-attach">
              <FormattedMessage id="justPicture">{txt => <label htmlFor="frontPhoto">{txt}</label>}</FormattedMessage>
              <label className="AddCardForm__fields-label" htmlFor="frontPhoto" ref={frontPhotoRef}>
                <FormattedMessage id={frontPhotoSelected ? 'justFileSelected' : 'justChoseFile'} />
              </label>
              <FormattedMessage id="addCardFormChooseField">
                {txt => <span className="AddCardForm__fields-prompt">{txt}</span>}
              </FormattedMessage>
              <Field
                component={FileField}
                sendHandler={handleSelectedBackPhoto}
                name="frontPhoto"
                formatNameId="imagesFormats"
                fileNameId="justAttachments"
                accept="image/*"
                validate={!withoutPhoto ? [required()] : []}
                disabled={submitting}
              />
            </div>
          </div>
          <div className="AddCardForm__example">
            <div className="AddCardForm__example-img">
              <img src={exampleFront} alt="Front face" />
            </div>
            <FormattedMessage id="addCardFrontHint">
              {txt => <p className="AddCardForm__example-text">{txt}</p>}
            </FormattedMessage>
          </div>
        </div>
      </div>

      <div className="AddCardForm__face">
        <FormattedMessage id="justCardBackFace">
          {txt => <h2 className="AddCardForm__face-title">{txt}</h2>}
        </FormattedMessage>
        <div className="AddCardForm__row">
          <div className="AddCardForm__fields">
            <div className="AddCardForm__fields-attach">
              <FormattedMessage id="justPicture">{txt => <label htmlFor="backPhoto">{txt}</label>}</FormattedMessage>
              <label className="AddCardForm__fields-label" htmlFor="backPhoto" ref={backPhotoRef}>
                <FormattedMessage id={backPhotoSelected ? 'justFileSelected' : 'justChoseFile'}>
                  {txt => txt}
                </FormattedMessage>
              </label>
              <FormattedMessage id="addCardFormChooseField">
                {txt => <span className="AddCardForm__fields-prompt">{txt}</span>}
              </FormattedMessage>
              <Field
                sendHandler={handleSelectedFrontPhoto}
                component={FileField}
                name="backPhoto"
                formatNameId="imagesFormats"
                fileNameId="justAttachments"
                accept="image/*"
                validate={!withoutPhoto ? [required()] : []}
                disabled={submitting}
              />
            </div>
          </div>
          <div className="AddCardForm__example">
            <div className="AddCardForm__example-img">
              <img src={exampleBack} alt="Back face" />
            </div>
            <div>
              <FormattedMessage id="addCardBackHintBefore">
                {txt => (
                  <div className="AddCardForm__example-text">
                    <p>{txt}</p>
                  </div>
                )}
              </FormattedMessage>
              <ul className="AddCardForm__example-list">
                <FormattedMessage id="addCardBackHintListOne">{txt => <li>{txt}</li>}</FormattedMessage>
                <FormattedMessage id="addCardBackHintListTwo">{txt => <li>{txt}</li>}</FormattedMessage>
                <FormattedMessage id="addCardBackHintListThree">{txt => <li>{txt}</li>}</FormattedMessage>
              </ul>
              <FormattedMessage id="addCardBackHintCheckout">
                {txt => (
                  <div className="AddCardForm__example-text">
                    <p>{txt}</p>
                  </div>
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
      {error && <strong className="formError">{error}</strong>}
      <div className="AddCardForm__btns">
        <DefaultButton
          textId="justSubmitCard"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={() => handleCancel()} />
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

AddCardForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  withoutPhoto: PropTypes.bool,
  submitFunction: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

AddCardForm.defaultProps = {
  withoutPhoto: false,
  theme: {},
  error: '',
};

export default compose(
  FormMessageHOC,
  withTheme(),
  withRouter,
  reduxForm({
    form: 'add-new-card',
    validate,
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage(props.successMessageId);
      props.handleCancel();
    },
  })
)(AddCardForm);
export { AddCardForm };
