import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

import { createFormAction } from 'redux-form-saga';

export const editCRMManagersCustomFilter = createFormAction('EDIT_CRM_MANAGERS_CUSTOM_FILTER');
export const deleteCRMManagersCustomFilter = createFormAction('DELETE_CRM_MANAGERS_CUSTOM_FILTER');

export const GET_CRM_MANAGERS_FILTER = createRequestTypes('GET_CRM_MANAGERS_FILTER');
export const getCRMManagersFilter = {
  request: data => action(GET_CRM_MANAGERS_FILTER[REQUEST], data),
  success: data => action(GET_CRM_MANAGERS_FILTER[SUCCESS], { data }),
  failure: error => action(GET_CRM_MANAGERS_FILTER[FAILURE], { error }),
};

export const GET_CRM_MANAGERS_FILTERS_LIST = createRequestTypes('GET_CRM_FILTERS_LIST');
export const getCRMManagersFiltersList = {
  request: () => action(GET_CRM_MANAGERS_FILTERS_LIST[REQUEST]),
  success: data => action(GET_CRM_MANAGERS_FILTERS_LIST[SUCCESS], { data }),
  failure: error => action(GET_CRM_MANAGERS_FILTERS_LIST[FAILURE], { error }),
};

export const createCRMManagersCustomFilter = createFormAction('CREATE_CRM_MANAGERS_CUSTOM_FILTER');

export const GET_CRM_MANAGERS_FILTERS_OPTIONS = createRequestTypes('GET_CRM_MANAGERS_FILTERS_OPTIONS');
export const getCRMManagersFiltersOptions = {
  request: () => action(GET_CRM_MANAGERS_FILTERS_OPTIONS[REQUEST]),
  success: data => action(GET_CRM_MANAGERS_FILTERS_OPTIONS[SUCCESS], { data }),
  failure: error => action(GET_CRM_MANAGERS_FILTERS_OPTIONS[FAILURE], { error }),
};

export const GET_CRM_MANAGERS_ANALYTICS = createRequestTypes('GET_CRM_MANAGERS_ANALYTICS');
export const getCrmManagersAnalytics = {
  request: search => action(GET_CRM_MANAGERS_ANALYTICS[REQUEST], { search }),
  success: data => action(GET_CRM_MANAGERS_ANALYTICS[SUCCESS], { data }),
  failure: error => action(GET_CRM_MANAGERS_ANALYTICS[FAILURE], { error }),
};

export const GET_CRM_CLIENTS_ANALYTICS = createRequestTypes('GET_CRM_CLIENTS_ANALYTICS');
export const getCrmClientsAnalytics = {
  request: search => action(GET_CRM_CLIENTS_ANALYTICS[REQUEST], { search }),
  success: data => action(GET_CRM_CLIENTS_ANALYTICS[SUCCESS], { data }),
  failure: error => action(GET_CRM_CLIENTS_ANALYTICS[FAILURE], { error }),
};
