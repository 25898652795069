import React from 'react';

const AnalyticsIcon = () => (
  <div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.388 31.388">
      <path
        d="M31.388,14.457l-0.374-1.28l-5.175,1.522c-0.284-0.519-0.813-0.883-1.444-0.883c-0.22,0-0.427,0.048-0.619,0.126
            L20.099,9.36c0.107-0.222,0.183-0.463,0.183-0.726c0-0.92-0.748-1.668-1.668-1.668s-1.667,0.748-1.667,1.668
            c0,0.469,0.199,0.89,0.51,1.193l-4.568,11.259l0,0c-0.085,0-0.155,0.036-0.236,0.048l-4.854-7.252
            c0.18-0.265,0.311-0.567,0.311-0.913c0-0.919-0.748-1.667-1.668-1.667c-0.919,0-1.668,0.748-1.668,1.667
            c0,0.313,0.109,0.591,0.261,0.841L0,20.068l1.039,0.836l5.093-6.329c0.104,0.021,0.2,0.063,0.31,0.063
            c0.079,0,0.148-0.036,0.226-0.047l4.858,7.26c-0.176,0.264-0.304,0.562-0.304,0.903c0,0.919,0.748,1.667,1.667,1.667
            c0.921,0,1.669-0.748,1.669-1.667c0-0.437-0.178-0.825-0.45-1.122l4.606-11.35c0.126-0.009,0.241-0.04,0.356-0.073l3.773,4.699
            c-0.066,0.182-0.115,0.371-0.115,0.574c0,0.92,0.748,1.668,1.666,1.668c0.717,0,1.316-0.459,1.553-1.095L31.388,14.457z"
      />
    </svg>
  </div>
);

export default AnalyticsIcon;
