import axios from 'axios';

// filters
export const getCRMFiltersList = () => axios.get('/crm/contacts/filter/list/');
export const getCRMFilter = id => axios.get(`/crm/contacts/filter/${id}`);
export const getCRMFiltersOptions = () => axios.get('/crm/contacts/filter/options/');
export const createCRMCustomFilter = payload => axios.post('/crm/contacts/filter/create/', { ...payload });
export const changeCRMCustomFilter = (id, payload) => axios.patch(`/crm/contacts/filter/${id}/`, { ...payload });

export const changeReassignTaskStatus = ({ taskId, ...payload }) =>
  axios.put(`/crm/task/reassign/${taskId}/`, { ...payload });
export const getCRMFastFilters = () => axios.get('/crm/contacts/filter/options/');
