import React from 'react';
import { withTheme } from 'common/styling/theme';

const ReassignIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M8.8346 0.149404C9.01779 -0.0324282 9.29396 -0.0508574 9.47443 0.108252L12.9743 3.1472C13.0701 3.22923 13.1247 3.37415 13.1247 3.49993C13.1247 3.6257 13.0701 3.74601 12.9743 3.82804L9.47443 6.89048C9.29396 7.0518 9.01779 7.03266 8.8346 6.84946C8.69788 6.669 8.71702 6.39283 8.90022 6.20963L11.5224 3.91281H3.93743C2.72941 3.91281 1.74998 4.9163 1.74998 6.10026V7.41273C1.74998 7.67796 1.5542 7.85022 1.31249 7.85022C1.07089 7.85022 0.875016 7.67796 0.875016 7.41273V6.10026C0.875016 4.43233 2.24599 3.03783 3.93743 3.03783H11.5224L8.90022 0.766703C8.71702 0.607566 8.69788 0.331127 8.8346 0.149404ZM6.20965 7.15024C6.39285 6.96704 6.66902 6.9479 6.84948 7.08461L10.3494 10.147C10.4451 10.2291 10.4998 10.374 10.4998 10.4998C10.4998 10.6256 10.4451 10.7459 10.3494 10.8279L6.84948 13.8903C6.66902 14.0517 6.39285 14.0325 6.20965 13.8493C6.07294 13.6688 6.09208 13.3927 6.27527 13.2095L8.89748 10.9373H3.06245C2.33759 10.9373 1.74998 11.5251 1.74998 12.2497V13.5622C1.74998 13.8028 1.5542 13.9997 1.31249 13.9997C1.07089 13.9997 0.875 13.8028 0.875 13.5622V12.2497C0.875 11.0412 1.85443 10.0623 3.06245 10.0623H8.89748L6.27527 7.76546C6.09208 7.60687 6.07294 7.3307 6.20965 7.15024Z"
      fill="#333333"
    />
  </svg>
);

ReassignIcon.defaultProps = {
  grayColor: {},
};

const ThemedReassignIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(ReassignIcon);

export default ThemedReassignIcon;
