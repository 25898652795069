import css from 'styled-jsx/css';
import xlsIcon from './xlsIcon.svg';
import deleteFileIcon from './deleteFileIcon.svg';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UploadContactForm {
    &__info {
      font-weight: 300;
      color: #696969;
    }

    &__file {
      border: 1px dashed rgba(119, 119, 119, 0.49);
      margin: 16px 0 0 0;
      padding: 6px 8px 5px 8px;

      &-item {
        position: relative;
        border-bottom: 1px solid #b1b1b1;
        padding: 0 17px 7px 0;
        margin: 0 0 7px 0;
        display: flex;
        align-items: flex-end;

        &:last-child {
          margin: 0 0 5px 0;
        }

        &-icon {
          width: 13px;
          height: 15px;
          background-image: url(${xlsIcon});
          background-size: cover;
          margin: 0 9px 0 0;
        }

        &-name {
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          color: #0f0f0f;
          text-overflow: ellipsis;
        }

        &-delete {
          position: absolute;
          top: 2px;
          right: 0;
          width: 11px;
          height: 10px;
          background-image: url(${deleteFileIcon});
          cursor: pointer;
        }
      }

      &-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      &-text {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        div {
          line-height: 0;
          margin: 0 8px 0 0;
        }

        span {
          font-weight: 300;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.2px;
          color: #0aa1e2;
        }
      }

      &-info {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #818181;
      }
    }

    &__submit-wrap {
      text-align: center;
      margin: 18px 0 0 0;
    }

    :global(&__submit) {
      :global(span) {
        font-weight: 300;
        letter-spacing: 0.2px;
        font-size: 14px;
      }
    }
  }
`;

export { staticStyles };
