import React from 'react';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import { staticStyles } from './style';

const CheckDomainInstruction = ({ isActive, instructionRef }) => (
  <div className={classNames('CheckDomainInstruction', { active: isActive })}>
    <div className="CheckDomainInstruction__content" ref={instructionRef}>
      <FM id="welcomeSetupCheckDomainMainTitle" />
      <FM id="welcomeSetupCheckDomainStep1Title">{txt => <p>{txt}</p>}</FM>
      <FM id="welcomeSetupCheckDomainStep1Text" />
      <FM id="welcomeSetupCheckDomainStep2Title">{txt => <p>{txt}</p>}</FM>
      <FM id="welcomeSetupCheckDomainStep2Text" />
      <FM id="welcomeSetupCheckDomainStep3Title">{txt => <p>{txt}</p>}</FM>
      <FM id="welcomeSetupCheckDomainStep3Text" />
      <FM id="welcomeSetupCheckDomainStep3Text2">{txt => <p>{txt}</p>}</FM>
      <FM id="welcomeSetupCheckDomainStep4Title">{txt => <p>{txt}</p>}</FM>
      <FM id="welcomeSetupCheckDomainStep4Text" />
      <FM id="welcomeSetupCheckDomainStep5Title">{txt => <p>{txt}</p>}</FM>
      <FM id="welcomeSetupCheckDomainStep5Text" />
    </div>
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

CheckDomainInstruction.propTypes = {
  isActive: PropTypes.bool,
  instructionRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

CheckDomainInstruction.defaultProps = {
  isActive: false,
  instructionRef: null,
};

export default CheckDomainInstruction;
