import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .SelectChallengeBlock {
    margin-bottom: 45px;
  }

  .SelectChallengeBlock__head {
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    h3 {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      padding-right: 25px;
      @include respond-to(xs) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .SelectChallengeBlock__body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .SelectChallengeBlock__head-right-buttons {
    display: flex;
    gap: 16px;
  }
  .StartNewChallengeButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
  }
  .CollapsibleAboutChallenges {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    gap: 16px;
  }
`;

export { staticStyles };
