import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .InternalTransferItem {
    &__Transfer {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;
      align-items: center;
      font-size: 12px;
      line-height: 1.4;
      color: #999;

      &--description {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      @include respond-to(xs) {
        margin-left: 44px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
    }

    &__Status {
      padding: 4px 12px;
      font-size: 10px;
      font-weight: 400;
      line-height: 1.4;
      color: #fff;
      border-radius: 24px;

      &--success {
        background: #39b841;
      }

      &--waiting {
        background: #f2b233;
      }

      &--failed {
        background: #ee3235;
      }
    }

    &__Description {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      color: #999;
    }

    &--bold {
      font-weight: 500;
      font-size: 14px;
      color: #333;
    }
  }
`;

export { staticStyles };
