/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { buttonIcons } from './icons';

import { staticStyles, getDynamicStyles } from './style';
import Tooltip from '../Tooltip';

const NewDefaultButton = ({
  textId,
  size,
  variant,
  type,
  theme,
  className,
  wide,
  isUpperCase,
  tooltipTextId,
  iconVariant,
  loading,
  ...props
}) => {
  let CurrentIcon;
  if (iconVariant) {
    CurrentIcon = buttonIcons[iconVariant];
  }
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const newDefaultButtonClass = classNames('NewDefaultButton', `${className}`, {
    'NewDefaultButton--size-small': size === 'small',
    'NewDefaultButton--size-medium': size === 'medium',
    'NewDefaultButton--secondary': variant === 'secondary',
    'NewDefaultButton--primary': variant === 'primary',
    'NewDefaultButton--red': variant === 'red',
    'NewDefaultButton--ghost': variant === 'ghost',
    'NewDefaultButton--check': variant === 'check',
    'NewDefaultButton--wide': wide,
    'NewDefaultButton--no-left-padding': iconVariant === 'previous',
    'NewDefaultButton--no-right-padding': iconVariant === 'next',
    'NewDefaultButton--loading': iconVariant === 'loading',
    'NewDefaultButton--retry': iconVariant === 'retry',
  });

  const iconClass = classNames('NewDefaultButton__icon', {
    enable: iconVariant,
    'NewDefaultButton__icon--previous': iconVariant === 'previous',
    'NewDefaultButton__icon--next': iconVariant === 'next',
    'NewDefaultButton__icon--check': iconVariant === 'check',
    'NewDefaultButton__icon--retry': iconVariant === 'retry',
    'NewDefaultButton__icon--loading': iconVariant === 'loading',
  });

  const formatedTextClass = classNames('NewDefaultButton__text', {
    'NewDefaultButton__text--size-small': size === 'small',
    'NewDefaultButton__text--size-medium': size === 'medium',
    'NewDefaultButton__text--secondary': variant === 'secondary',
    'NewDefaultButton__text--primary': variant === 'primary',
    'NewDefaultButton__text--red': variant === 'red',
    'NewDefaultButton__text--ghost': variant === 'ghost',
    'NewDefaultButton__text--check': variant === 'check',
  });

  return (
    <Tooltip id={tooltipTextId} textId={tooltipTextId}>
      <button type={type} className={newDefaultButtonClass} {...props}>
        {iconVariant && <CurrentIcon className={iconClass} />}
        <FormattedMessage id={textId}>{txt => <span className={formatedTextClass}>{txt}</span>}</FormattedMessage>
      </button>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </Tooltip>
  );
};

const basePropTypes = {
  textId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  wide: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  theme: PropTypes.object,
  tooltipTextId: PropTypes.string,
  iconVariant: PropTypes.string,
  loading: PropTypes.bool,
};

NewDefaultButton.propTypes = basePropTypes;
NewDefaultButton.defaultProps = {
  size: 'medium',
  variant: 'primary',
  type: 'button',
  className: '',
  wide: false,
  isUpperCase: false,
  theme: {},
  tooltipTextId: '',
  iconVariant: '',
  loading: false,
};

const ThemedButton = withTheme()(NewDefaultButton);

ThemedButton.propTypes = basePropTypes;

export default ThemedButton;
export { NewDefaultButton };
