import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage as FM } from 'react-intl';

import { NewDefaultButton, TopMenu } from '../../_components';

import { staticStyles } from './style';

function IntroPage({ intl }) {
  const history = useHistory();
  return (
    <div className="IntroPageLayout">
      <Helmet>
        <title>{intl.formatMessage({ id: 'welcomeSetupIntroBeforeYouStart' })}</title>
      </Helmet>
      <TopMenu />
      <div className="IntroPage">
        <div className="IntroPage__BlocksContainer">
          <div className="IntroPage__BeforeYouStartTitle">
            <FM id="welcomeSetupBeforeYouStart" />
          </div>
          <div className="IntroPageBlock">
            <div className="IntroPageBlock__MessagesContainer">
              <FM id="welcomeSetupIntroPageblock1part1">{txt => <span>{txt}</span>}</FM>
              <FM id="welcomeSetupIntroPageblock1part2">{txt => <span>{txt}</span>}</FM>
            </div>
            <NewDefaultButton
              isUpperCase
              wide
              textId="justStart"
              type="button"
              onClick={() => {
                localStorage.setItem('wasIntroPageReaded', true);
                history.push('/welcome-setup/registration');
              }}
            />
          </div>
          <div className="IntroPageBlock">
            <div className="IntroPageBlock__MessagesContainer">
              <FM id="welcomeSetupIntroPageblock2part2">
                {txt => (
                  <span>
                    <FM id="welcomeSetupIntroPageblock2part1">
                      {txt => <span className="IntroPageBlock__Message--Red">{txt}</span>}
                    </FM>{' '}
                    {txt}
                  </span>
                )}
              </FM>
              <FM id="welcomeSetupIntroPageblock2part3">{txt => <span>{txt}</span>}</FM>
            </div>
            <NewDefaultButton
              isUpperCase
              wide
              variant="secondary"
              textId="justContactUs"
              type="button"
              onClick={() => {
                window.location = 'https://uptrader.io/';
              }}
            />
          </div>
        </div>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
}

IntroPage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(IntroPage);
