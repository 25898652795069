import axios from 'axios';

export const getPaymentSettings = id =>
  id
    ? axios.get(`/closed_loop_policy/payment_system/list/?setting=${id}`)
    : axios.get('/closed_loop_policy/payment_system/list/');

export const getPolicySettings = () => axios.get('/closed_loop_policy/setting/list/');
export const getPolicySetting = id => axios.get(`/closed_loop_policy/setting/${id}`);
export const createPolicySetting = payload => axios.post('/closed_loop_policy/setting/create/', { ...payload });
export const editPolicySetting = (id, payload) => axios.patch(`/closed_loop_policy/setting/${id}/`, { ...payload });
export const deletePolicySetting = id => axios.delete(`/closed_loop_policy/setting/${id}/`);
