import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  campaignList: {},
  campaignListIsLoaded: false,

  campaign: {},
  campaignIsLoaded: false,

  defaultCampaign: {},
  defaultCampaignIsLoaded: false,

  promoMaterialList: {},
  promoMaterialListIsLoaded: false,

  promoMaterial: {},
  promoMaterialIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMPAIGN_LIST[REQUEST]:
      return { ...state, campaignListIsLoaded: false };
    case ActionTypes.GET_CAMPAIGN_LIST[SUCCESS]:
      return { ...state, campaignList: action.data, campaignListIsLoaded: true };
    case ActionTypes.GET_CAMPAIGN_LIST[FAILURE]:
      return { ...state, error: action.error, campaignListIsLoaded: false };

    case ActionTypes.GET_CAMPAIGN[REQUEST]:
      return { ...state, campaignIsLoaded: false };
    case ActionTypes.GET_CAMPAIGN[SUCCESS]:
      return { ...state, campaign: action.data, campaignIsLoaded: true };
    case ActionTypes.GET_CAMPAIGN[FAILURE]:
      return { ...state, error: action.error, campaignIsLoaded: false };

    case ActionTypes.GET_DEFAULT_CAMPAIGN[REQUEST]:
      return { ...state, defaultCampaignIsLoaded: false };
    case ActionTypes.GET_DEFAULT_CAMPAIGN[SUCCESS]:
      return { ...state, defaultCampaign: action.data, defaultCampaignIsLoaded: true };
    case ActionTypes.GET_DEFAULT_CAMPAIGN[FAILURE]:
      return { ...state, error: action.error, defaultCampaign: null, defaultCampaignIsLoaded: false };

    case ActionTypes.GET_PROMO_MATERIAL_LIST[REQUEST]:
      return { ...state, promoMaterialListIsLoaded: false };
    case ActionTypes.GET_PROMO_MATERIAL_LIST[SUCCESS]:
      return { ...state, promoMaterialList: action.data, promoMaterialListIsLoaded: true };
    case ActionTypes.GET_PROMO_MATERIAL_LIST[FAILURE]:
      return { ...state, error: action.error, promoMaterialListIsLoaded: false };

    case ActionTypes.GET_PROMO[REQUEST]:
      return { ...state, promoMaterialIsLoaded: false };
    case ActionTypes.GET_PROMO[SUCCESS]:
      return { ...state, promoMaterial: action.data, promoMaterialIsLoaded: true };
    case ActionTypes.GET_PROMO[FAILURE]:
      return { ...state, error: action.error, promoMaterialIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
