import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .SelectActionAndTextField {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 69px;
    &__Buttons {
      width: 100%;
      display: flex;
      border-radius: 4px;
      overflow: hidden;
    }

    &__TextField {
      width: 100%;
      height: 31px;
      padding: 0;
      border-radius: 4px;
    }
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
