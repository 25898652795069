import { createFormAction } from 'redux-form-saga';
import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';

export const closeInvestmentRequestNew = createFormAction('CLOSE_INVESTMENT_REQUEST_NEW');

export const GET_INVESTMENTS_NEW = createRequestTypes('GET_INVESTMENTS_NEW');
export const getInvestmentsNew = {
  request: data => action(GET_INVESTMENTS_NEW[REQUEST], data),
  success: data => action(GET_INVESTMENTS_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVESTMENTS_NEW[FAILURE], { error }),
};

export const GET_INVESTMENTS_REQUESTS_NEW = createRequestTypes('GET_INVESTMENTS_REQUESTS_NEW');
export const getInvestmentsRequestsNew = {
  request: data => action(GET_INVESTMENTS_REQUESTS_NEW[REQUEST], data),
  success: data => action(GET_INVESTMENTS_REQUESTS_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVESTMENTS_REQUESTS_NEW[FAILURE], { error }),
};

export const SET_INVESTMENTS_ACTION_NEW = createRequestTypes('SET_INVESTMENTS_ACTION_NEW');
export const setInvestmentsActionNew = {
  request: data => action(SET_INVESTMENTS_ACTION_NEW[REQUEST], data),
  success: data => action(SET_INVESTMENTS_ACTION_NEW[SUCCESS], { data }),
  failure: error => action(SET_INVESTMENTS_ACTION_NEW[FAILURE], { error }),
};

export const GET_INVEST_STRATEGIES_APPROVE_NEW = createRequestTypes('GET_INVEST_STRATEGIES_APPROVE_NEW');
export const getInvestStrategyApproveNew = {
  request: data => action(GET_INVEST_STRATEGIES_APPROVE_NEW[REQUEST], data),
  success: data => action(GET_INVEST_STRATEGIES_APPROVE_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVEST_STRATEGIES_APPROVE_NEW[FAILURE], { error }),
};
export const createInvestStrategyNew = createFormAction('CREATE_INVEST_STRATEGY_NEW');
export const editInvestStrategyNew = createFormAction('EDIT_INVEST_STRATEGY_NEW');

export const addStrategyInvestmentsNew = createFormAction('ADD_STRATEGY_INVESTMENTS_NEW');

// NEW
export const GET_INVEST_STRATEGIES_NEW = createRequestTypes('GET_INVEST_STRATEGIES_NEW');
export const getInvestStrategiesNew = {
  request: data => action(GET_INVEST_STRATEGIES_NEW[REQUEST], data),
  success: data => action(GET_INVEST_STRATEGIES_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVEST_STRATEGIES_NEW[FAILURE], { error }),
};

// NEW-END

export const SET_INVEST_STRATEGY_ACTION_NEW = createRequestTypes('SET_INVEST_STRATEGY_ACTION_NEW');
export const setInvestStrategyActionNew = {
  request: data => action(SET_INVEST_STRATEGY_ACTION_NEW[REQUEST], data),
  success: data => action(SET_INVEST_STRATEGY_ACTION_NEW[SUCCESS], { data }),
  failure: error => action(SET_INVEST_STRATEGY_ACTION_NEW[FAILURE], { error }),
};

export const GET_MY_INVEST_STRATEGIES_NEW = createRequestTypes('GET_MY_INVEST_STRATEGIES_NEW');
export const getMyInvestStrategiesNew = {
  request: data => action(GET_MY_INVEST_STRATEGIES_NEW[REQUEST], data),
  success: data => action(GET_MY_INVEST_STRATEGIES_NEW[SUCCESS], { data }),
  failure: error => action(GET_MY_INVEST_STRATEGIES_NEW[FAILURE], { error }),
};

export const RETRIEVE_INVEST_STRATEGY_NEW = createRequestTypes('RETRIEVE_INVEST_STRATEGY_NEW');
export const retrieveInvestStrategyNew = {
  request: data => action(RETRIEVE_INVEST_STRATEGY_NEW[REQUEST], data),
  success: data => action(RETRIEVE_INVEST_STRATEGY_NEW[SUCCESS], { data }),
  failure: error => action(RETRIEVE_INVEST_STRATEGY_NEW[FAILURE], { error }),
};

export const GET_STRATEGY_INVESTMENTS_NEW = createRequestTypes('GET_STRATEGY_INVESTMENTS_NEW');
export const getStrategyInvestmentsNew = {
  request: data => action(GET_STRATEGY_INVESTMENTS_NEW[REQUEST], data),
  success: data => action(GET_STRATEGY_INVESTMENTS_NEW[SUCCESS], { data }),
  failure: error => action(GET_STRATEGY_INVESTMENTS_NEW[FAILURE], { error }),
};

export const GET_INVEST_STATEMENT_NEW = createRequestTypes('GET_INVEST_STATEMENT_NEW');
export const getInvestStatementNew = {
  request: data => action(GET_INVEST_STATEMENT_NEW[REQUEST], data),
  success: data => action(GET_INVEST_STATEMENT_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVEST_STATEMENT_NEW[FAILURE], { error }),
};

export const GET_INVESTMENT_TRADES_NEW = createRequestTypes('GET_INVESTMENT_TRADES_NEW');
export const getInvestmentTradesNew = {
  request: data => action(GET_INVESTMENT_TRADES_NEW[REQUEST], data),
  success: data => action(GET_INVESTMENT_TRADES_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVESTMENT_TRADES_NEW[FAILURE], { error }),
};

export const closeInvestmentNew = createFormAction('CLOSE_INVESTMENT_NEW');

export const withdrawInvestmentsNew = createFormAction('WITHDRAW_INVESTMENTS_NEW');

export const setInvestmentsDepositNew = createFormAction('SET_INVESTMENTS_DEPOSIT_NEW');

export const GET_INVEST_YIELD_CHART_NEW = createRequestTypes('GET_INVEST_YIELD_CHART_NEW');
export const getInvestYieldChartNew = {
  request: data => action(GET_INVEST_YIELD_CHART_NEW[REQUEST], data),
  success: data => action(GET_INVEST_YIELD_CHART_NEW[SUCCESS], { data }),
  failure: error => action(GET_INVEST_YIELD_CHART_NEW[FAILURE], { error }),
};
