import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import Triangle from './icons/Triangle';
import { staticStyles } from './style';

const BaseStaticSelect = ({ labelTextId, isDisabled, options, onSelect, fastFilter, highliteSelected }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="BaseStaticSelect">
      <button
        className={classNames('BaseStaticSelect__OpenOptionsButton', {
          'BaseStaticSelect__OpenOptionsButton--FastFilter': fastFilter,
          'BaseStaticSelect__OpenOptionsButton--FastFilterClosed': !isOpen,
          highliteSelected,
        })}
        disabled={isDisabled || !options?.length}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        {!fastFilter && <FM id={labelTextId}>{txt => txt}</FM>}
        <Triangle />
      </button>
      {isOpen && (
        <div className="BaseStaticSelect__Options">
          {options.map(({ label, value, isSelected }) => (
            <button
              key={`BaseStaticSelect-options-${label}`}
              className={classNames('BaseStaticSelect__Option', {
                'BaseStaticSelect__Option--FastFilter': fastFilter,
                'BaseStaticSelect__OptionMulti--Selected': isSelected,
              })}
              type="button"
              onClick={() => {
                onSelect(value);
                setIsOpen(!isOpen);
              }}>
              <FM id={label}>{txt => txt}</FM>
            </button>
          ))}
        </div>
      )}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

BaseStaticSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  labelTextId: PropTypes.string,
  isDisabled: PropTypes.bool,
  fastFilter: PropTypes.bool,
};

BaseStaticSelect.defaultProps = {
  isDisabled: false,
  labelTextId: '',
  fastFilter: false,
};

export default BaseStaticSelect;
