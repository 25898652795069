import css from 'styled-jsx/css';
import icon from './icon.svg';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CustomFilterButton {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    cursor: pointer;

    &__button {
      width: 100%;
      height: 100%;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .CustomFilterButton {
      background: url(${icon}) center / 24px 18px no-repeat ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
