import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { DefaultButton, ReduxTextField, FileField, ReduxSelectField } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { createFile, uploadFiles } from 'admin/files/_redux';
import { required, url } from 'redux-form-validators';

import { staticStyles, getDynamicStyles } from './style';

const PromoMaterialsForm = ({
  campaignList,
  isEditForm,
  promoId,
  availableLangs,
  formValues,
  file,
  handleSubmit,
  submitFunction,
  submitting,
  invalid,
  dispatch,
  theme,
  history,
  error,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(values =>
    submitFunction(
      {
        ...values,
        promoId,
        file: typeof values.uploadFile === 'string' ? values.file : file,
      },
      dispatch
    )
  );

  const handleAfterUpload = uploadFile => {
    if (isEditForm) {
      const formData = new FormData();
      formData.set('title', uploadFile[0].name);
      formData.append('file', uploadFile[0]);
      createFile({ body: formData }, dispatch);
    } else {
      uploadFiles(uploadFile, dispatch);
      history.push('/backoffice/promo/upload', { banner: formValues, campaignList });
    }
  };

  const campaignOptions = Array.isArray(campaignList)
    ? campaignList.map(item => ({ value: item.id, label: item.title }))
    : [];

  return (
    <form className="PromoMaterialsForm" onSubmit={submit}>
      <div className="PromoMaterialsForm__content">
        <div className="PromoMaterialsForm__settings-block">
          <FormattedMessage id="justBanner">
            {txt => <span className="PromoMaterialsForm__caption">{txt}</span>}
          </FormattedMessage>
          <Field
            component={ReduxSelectField}
            name="campaign"
            textId="campaignName"
            options={campaignOptions}
            disabled={submitting}
            validate={[required()]}
          />
          <Field
            component={ReduxSelectField}
            name="language"
            textId="justLanguage"
            options={availableLangs.map(item => ({ value: item.slug, label: item.niceName }))}
            disabled={submitting}
            validate={[required()]}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="link"
            textId="justLink"
            validate={[required(), url({ protocols: ['http', 'https'] })]}
          />
        </div>
        <div className="PromoMaterialsForm__logo-block">
          <FormattedMessage id="justPreview">
            {txt => <span className="PromoMaterialsForm__caption">{txt}</span>}
          </FormattedMessage>
          <Field
            component={FileField}
            name="uploadFile"
            formatNameId="imagesFormats"
            fileNameId="justAttachments"
            accept="image/png,image/jpeg,image/gif"
            handleAfterUpload={handleAfterUpload}
            disabled={submitting}
            validate={[required()]}
            multiple={!isEditForm}
          />
        </div>
      </div>
      <div className="PromoMaterialsForm__buttons">
        <DefaultButton textId="doSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
        <DefaultButton textId="justCancel" onClick={() => history.goBack()} disabled={submitting} />
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

PromoMaterialsForm.propTypes = {
  file: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitFunction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool,
  promoId: PropTypes.string,
  campaignList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  availableLangs: PropTypes.array,
  error: PropTypes.string,
  theme: PropTypes.object,
};

PromoMaterialsForm.defaultProps = {
  isEditForm: false,
  promoId: null,
  campaignList: [],
  availableLangs: [],
  file: '',
  error: '',
  theme: {},
};

const selector = formValueSelector('PromoMaterialsForm');

export default compose(
  withTheme(),
  withRouter,
  connect(state => ({
    file: state.admin.filesManagement.file.id || '',
    formValues: selector(state, 'campaign', 'language', 'link') || {},
  })),
  reduxForm({
    form: 'PromoMaterialsForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.history.push('/backoffice/promo/list');
    },
  })
)(PromoMaterialsForm);
export { PromoMaterialsForm };
