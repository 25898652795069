import css from 'styled-jsx/css';

import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ResponsiveTable {
    position: relative;
    display: flex;
    flex: auto 1;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;

    .ResponsiveTable__thead {
      display: flex;
      flex: 1 0 auto;

      span {
        flex: 60;
        text-align: center;
        padding: 5px 0;
        line-height: normal;
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;

        &:last-child {
          padding-right: rem(30px);
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      width: 100% !important;
      min-width: 340px !important;
      display: flex;
      flex-direction: column;
      flex: 99999 1 auto;
      overflow: auto;

      .ResponsiveTable__row {
        display: flex;
        position: relative;
        flex-direction: column;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
      }

      .ResponsiveTable__row-preview {
        display: flex;
        position: relative;
        flex-direction: column;
        padding: rem(10px);
        height: auto;
      }

      .ResponsiveTable__preview-main {
        display: flex;
        align-items: center;
        height: 50px;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: rem(10px);
      }

      .ResponsiveTable__preview-description {
        display: flex;
        align-items: center;
        font-size: rem(12px);
      }

      .ResponsiveTable__row-item {
        flex: 60;
        display: flex;
        align-items: center;
        justify-content: center;

        :global(.time) {
          display: block;
          color: #777777;
        }
      }

      :global(.ResponsiveTable__ellipsis) {
        position: absolute;
        top: calc(50% - 5px);
        right: 2%;
      }

      .ResponsiveTable__row--opened {
        :global(.ResponsiveTable__ellipsis) {
          svg {
            transform: rotate(-90deg) translate(0, -40%);
            transition: transform 0.5s ease;
          }
        }
      }

      .ResponsiveTable__details-item {
        margin-bottom: rem(20px);
        padding-left: 12%;

        @include respond-to(sm) {
          display: flex;
        }
      }

      .ResponsiveTable__detailed-caption {
        margin-right: rem(15px);
      }
    }

    .ResponsiveTable__tfoot {
      .ResponsiveTable__row-details {
        padding: rem(10px 17px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .ResponsiveTable__details-item {
        &:first-child {
          display: none;
        }
        flex: 1;

        .TotalCell {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ResponsiveTable {
      .ResponsiveTable__thead {
        span {
          color: ${colors.gray.hex};
        }
      }

      .ResponsiveTable__row {
        background-color: ${colors.white.hex};
        border-radius: ${border.radius};
        &:hover {
          box-shadow: 0 1px 6px ${rgba([colors.primary.rgb, 0.25])};
        }
      }

      .ResponsiveTable__preview-description {
        color: ${colors.gray.hex};
      }

      .ResponsiveTable__detailed-caption {
        color: ${colors.gray.hex};
      }

      .ResponsiveTable__tfoot {
        .ResponsiveTable__row-details {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
