import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_PAYMENT_NOTE_DOCUMENT = createRequestTypes('GET_PAYMENT_NOTE_DOCUMENT');
export const getPaymentNoteDocument = {
  request: data => action(GET_PAYMENT_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(GET_PAYMENT_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_NOTE_DOCUMENT[FAILURE], { error }),
};

export const REMOVE_PAYMENT_NOTE_DOCUMENT = createRequestTypes('REMOVE_PAYMENT_NOTE_DOCUMENT');
export const removePaymentNoteDocument = {
  request: data => action(REMOVE_PAYMENT_NOTE_DOCUMENT[REQUEST], { data }),
  success: data => action(REMOVE_PAYMENT_NOTE_DOCUMENT[SUCCESS], { data }),
  failure: error => action(REMOVE_PAYMENT_NOTE_DOCUMENT[FAILURE], { error }),
};

export const deletePaymentNote = createFormAction('DELETE_PAYMENT_NOTE');

export const editPaymentNote = createFormAction('EDIT_PAYMENT_NOTE');

export const createPaymentNote = createFormAction('CREATE_PAYMENT_NOTE');

export const createPaymentDocument = createFormAction('CREATE_PAYMENT_DOCUMENT');

export const GET_PAYMENT_NOTES = createRequestTypes('GET_PAYMENT_NOTES');
export const getPaymentNotes = {
  request: ({ id, paymentType }) => action(GET_PAYMENT_NOTES[REQUEST], { id, paymentType }),
  success: data => action(GET_PAYMENT_NOTES[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_NOTES[FAILURE], { error }),
};

export const GET_PARTNERS = createRequestTypes('GET_PARTNERS');
export const getPartners = {
  request: search => action(GET_PARTNERS[REQUEST], { search }),
  success: data => action(GET_PARTNERS[SUCCESS], { data }),
  failure: error => action(GET_PARTNERS[FAILURE], { error }),
};

export const EXPORT_USER_TABLE_DATA = createRequestTypes('EXPORT_USER_TABLE_DATA');
export const exportUserTableData = {
  request: params => action(EXPORT_USER_TABLE_DATA[REQUEST], { params }),
  success: () => action(EXPORT_USER_TABLE_DATA[SUCCESS]),
  failure: error => action(EXPORT_USER_TABLE_DATA[FAILURE], { error }),
};

export const GET_BACKOFFICE_COUNTERS = createRequestTypes('GET_BACKOFFICE_COUNTERS');
export const getBackofficeCounters = {
  request: () => action(GET_BACKOFFICE_COUNTERS[REQUEST]),
  success: data => action(GET_BACKOFFICE_COUNTERS[SUCCESS], { data }),
  failure: error => action(GET_BACKOFFICE_COUNTERS[FAILURE], { error }),
};

export const SET_REDIRECT = 'SET_REDIRECT';
export const setRedirect = () => action(SET_REDIRECT);

export const GET_BO_FILTERS_LIST = createRequestTypes('GET_BO_FILTERS_LIST');
export const getFiltersList = {
  request: () => action(GET_BO_FILTERS_LIST[REQUEST]),
  success: data => action(GET_BO_FILTERS_LIST[SUCCESS], { data }),
  failure: error => action(GET_BO_FILTERS_LIST[FAILURE], { error }),
};

export const GET_BACKOFFICE_SERVERS = createRequestTypes('GET_BACKOFFICE_SERVERS');
export const getBackofficeServers = {
  request: () => action(GET_BACKOFFICE_SERVERS[REQUEST]),
  success: data => action(GET_BACKOFFICE_SERVERS[SUCCESS], { data }),
  failure: error => action(GET_BACKOFFICE_SERVERS[FAILURE], { error }),
};
