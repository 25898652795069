import md5 from 'blueimp-md5';
import React from 'react';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { DateCell } from 'common/components';
import { InfoLine } from '../../../_components';

import { staticStyles, getDynamicStyles } from './style';

const PersonInfo = ({ captionSlug, user, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PersonInfo">
      <FormattedMessage id={captionSlug}>{txt => <span className="PersonInfo__caption">{txt}</span>}</FormattedMessage>
      <div className="PersonInfo__content">
        <div className="PersonInfo__gravatar-inner">
          <Gravatar md5={md5(user.email)} size={145} />
        </div>
        <div className="PersonInfo__main">
          <InfoLine textId="justEmail" data={user.email} />
          {user.phone ? <InfoLine textId="justPhone" data={user.phone} /> : null}
          {user.dateJoined ? (
            <div className="InfoLine">
              <FormattedMessage id="justRegistration" />
              <div className="InfoLine__right">
                <DateCell value={user.dateJoined} />
              </div>
            </div>
          ) : null}
          <InfoLine textId="kycFirstName" data={user.firstName} />
          <InfoLine textId="justLastName" data={user.lastName} />
        </div>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PersonInfo.propTypes = {
  captionSlug: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PersonInfo.defaultProps = {
  theme: {},
};

export default withTheme()(PersonInfo);
export { PersonInfo };
