import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  
  .UserActionsModal__content {
    max-width: 430px;
  } 
   
  .UserActionsModal__buttons-inner {
    :global(button) {
      margin-right: rem(15px);
      &:last-child {
        margin: 0;
      }
    }
  }
`

export { staticStyles }
