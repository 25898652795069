import React from 'react';

const WithdrawalIcon = () => (
  <div>
    <svg viewBox="0 0 20 17" version="1.1">
      <defs />
      <g id="-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-561.000000, -713.000000)" fill="#000000" fillRule="nonzero">
          <g id="widthdrawal" transform="translate(561.000000, 713.000000)">
            <path
              d="M13.1060761,12.6250871 L10.5247085,15.2079993 L10.5247085,7.52505228 C10.5247085,7.23507391 10.2898108,7 10.0000498,7 C9.71028887,7 9.47539119,7.23507391 9.47539119,7.52505228 L9.47539119,15.2079993 L6.89441542,12.6250871 C6.76205779,12.4926302 6.56914234,12.4408999 6.38833845,12.4893825 C6.20753456,12.5378651 6.06631065,12.6791949 6.0178644,12.8601345 C5.96941814,13.041074 6.02110968,13.2341342 6.15346732,13.3665911 L9.63408182,16.8455037 C9.73175136,16.9435072 9.86409436,16.9990202 10.0024008,17 C10.1417777,16.9997279 10.2751457,16.9431736 10.3722871,16.8431509 L13.8470242,13.3658069 C13.9964774,13.2156688 14.0410881,12.9903364 13.9601274,12.7945131 C13.8791667,12.5986899 13.6885091,12.4707722 13.4767461,12.4701987 C13.3372637,12.4705349 13.2039314,12.526167 13.1060761,12.6250871 Z"
              id="Shape"
              transform="translate(10.000000, 12.000000) rotate(-180.000000) translate(-10.000000, -12.000000) "
            />
            <path
              d="M17.6661046,0 L2.3350858,0 C1.04545353,-7.48989053e-17 1.57934404e-16,0.991592996 0,2.21478495 L0,11.7852151 C-6.25894567e-16,12.3726125 0.246017262,12.9359518 0.683930798,13.3513045 C1.12184433,13.7666573 1.71578254,14 2.3350858,14 L3.16238468,14 C3.48780591,14 3.75161194,13.7497849 3.75161194,13.441129 C3.75161194,13.1885924 3.53577065,12.983871 3.26951691,12.983871 L2.3350858,12.983871 C1.63728341,12.983871 1.07154138,12.4474438 1.07132229,11.7855914 L1.07132229,3.82817204 L18.9298681,3.82817204 L18.9298681,11.7855914 C18.929649,12.4474438 18.3639069,12.983871 17.6661046,12.983871 L16.5459776,12.983871 C16.2849832,12.983871 16.0734054,13.1845485 16.0734054,13.4320968 L16.0734054,13.5826344 C16.0734054,13.8112684 16.2688173,13.9966129 16.5098701,13.9966129 L17.6649142,13.9966129 C18.9545465,13.9966129 20,13.0050199 20,11.781828 L20,2.21478495 C20.0000002,0.992033775 18.9552719,0.000623322602 17.6661046,0 Z M1.07132229,2.81204301 L1.07132229,2.21478495 C1.07219674,1.55312942 1.63749102,1.01695844 2.3350858,1.01612903 L17.6661046,1.01612903 C18.3636993,1.01695844 18.9289936,1.55312942 18.9298681,2.21478495 L18.9298681,2.81204301 L1.07132229,2.81204301 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default WithdrawalIcon;
