import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  loadingMessages: true,
  newMessages: [],
  messages: [],
  notifications: [],
  messagesCursor: null,
  activeMessage: -1,
  unreadMessages: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MESSAGE:
      return {
        ...state,
        activeMessage: state.activeMessage === action.id ? -1 : action.id,
      };

    case ActionTypes.GET_MESSAGES[REQUEST]:
      return { ...state, loadingMessages: true };
    case ActionTypes.GET_MESSAGES[SUCCESS]: {
      const newMessages = action.messages.filter(
        newItem => state.messages.findIndex(item => item.id === newItem.id) === -1
      );
      const messages = [...state.messages, ...newMessages];

      return {
        ...state,
        messages,
        loadingMessages: false,
        messagesCursor: action.cursor,
      };
    }
    case ActionTypes.GET_MESSAGES[FAILURE]:
      return { ...state, loadingMessages: false };

    case ActionTypes.DELETE_MESSAGE[REQUEST]:
      return {
        ...state,
        messages: state.messages.filter(item => item.id !== action.id),
      };
    case ActionTypes.DELETE_MESSAGE[FAILURE]: {
      if (state.messages.findIndex(item => item.id === action.message.id) === -1) {
        const messages = [...state.messages, action.message];
        return { ...state, messages };
      }
      return state;
    }

    case ActionTypes.READ_MESSAGE[REQUEST]:
      return {
        ...state,
        messages: state.messages.map(item => (item.id === action.id ? { ...item, isNew: false } : item)),
      };
    case ActionTypes.READ_MESSAGE[FAILURE]:
      return {
        ...state,
        messages: state.messages.map(item => (item.id === action.id ? { ...item, isNew: true } : item)),
      };

    case ActionTypes.ADD_MESSAGE_NOTIFICATION: {
      if (!state.messages.find(item => item.id === action.id)) {
        return {
          ...state,
          messages: [...state.messages, action.message].sort((a, b) => b.id - a.id),
          notifications: [...state.notifications, action.message].sort((a, b) => b.id - a.id),
        };
      }
      return state;
    }
    case ActionTypes.REMOVE_MESSAGE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(item => item.id !== action.id),
      };

    default:
      return state;
  }
};

export const stateSelector = state => state.client.messages;

export default reducer;
