import { SubmissionError } from 'redux-form';
import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getBonusesInfoListSaga({ search }) {
  try {
    const { data } = yield call(api.getBonusesInfoList, search);
    yield put(ActionTypes.getBonusesInfoList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBonusesInfoList.failure(e));
  }
}
export function* getBonusesInfoListWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_BONUSES_INFO_LIST[REQUEST]);
    yield call(getBonusesInfoListSaga, search);
  }
}

export function* getBonusTypesSaga() {
  try {
    const { data } = yield call(api.getBonusTypes);
    yield put(ActionTypes.getBonusTypes.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBonusTypes.failure(e));
  }
}
export function* getBonusTypesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BONUS_TYPES[REQUEST]);
    yield call(getBonusTypesSaga);
  }
}

export function* getBonusAccountTypesSaga() {
  try {
    const { data } = yield call(api.getBonusAccountTypes);
    yield put(ActionTypes.getBonusAccountTypes.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBonusAccountTypes.failure(e));
  }
}
export function* getBonusAccountTypesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BONUS_ACCOUNT_TYPES[REQUEST]);
    yield call(getBonusAccountTypesSaga);
  }
}

export function* getBonusInfoSaga({ bonusId }) {
  try {
    const { data } = yield call(api.getBonusInfo, bonusId);
    yield put(ActionTypes.getBonusInfo.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBonusInfo.failure(e));
  }
}
export function* getBonusInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_BONUS_INFO[REQUEST]);
    yield call(getBonusInfoSaga, data);
  }
}

const normalizeBonusInfoObject = formValues => {
  const { isActive, title, description, promoTexts, availableLanguages, rules, availableForAccountTypes, ...props } =
    formValues;

  return {
    ...props,
    title: title ? Object.entries(title).map(([key, value]) => ({ lang: key, text: value })) : undefined,
    description: description
      ? Object.entries(description).map(([key, value]) => ({ lang: key, text: value }))
      : undefined,
    rules: rules ? Object.entries(rules).map(([key, value]) => ({ lang: key, text: value })) : undefined,
    promoTexts: promoTexts
      ? Object.values(promoTexts).map(promo =>
          Object.entries(promo).map(([key, value]) => ({ lang: key, text: value }))
        )
      : [],
    availableLanguages: Object.entries(availableLanguages).reduce(
      (acc, [key, value]) => (value ? [...acc, key] : acc),
      []
    ),
    availableForAccountTypes:
      typeof availableForAccountTypes === 'object'
        ? Object.entries(availableForAccountTypes).reduce((acc, [key, value]) => (value ? [...acc, key] : acc), [])
        : [],
    isActive: !isActive,
  };
};

export function* createBonusInfoSaga({ file, ...formValues }) {
  try {
    const { status, data } = yield call(api.createBonusInfo, normalizeBonusInfoObject(formValues));
    if (status < 300) {
      yield put(ActionTypes.createBonusInfo.success(data));
    } else {
      yield put(ActionTypes.createBonusInfo.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createBonusInfo.failure(e));
  }
}
export function* createBonusInfoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createBonusInfo.REQUEST);
    yield call(createBonusInfoSaga, payload);
  }
}

export function* deleteBonusInfoSaga({ id, queryParams }) {
  try {
    yield call(api.deleteBonusInfo, id);
    yield put(ActionTypes.deleteBonusInfo.success());
    if (queryParams) {
      yield call(getBonusesInfoListSaga, queryParams);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteBonusInfo.failure(e));
  }
}
export function* deleteBonusInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DELETE_BONUS_INFO[REQUEST]);
    yield call(deleteBonusInfoSaga, data);
  }
}

export function* editBonusInfoSaga({ id, file, ...formValues }) {
  try {
    const { status, data } = yield call(api.editBonusInfo, id, normalizeBonusInfoObject(formValues));
    if (status < 300) {
      yield put(ActionTypes.editBonusInfo.success(data));
    } else {
      yield put(ActionTypes.editBonusInfo.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editBonusInfo.failure(e));
  }
}
export function* editBonusInfoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editBonusInfo.REQUEST);
    yield call(editBonusInfoSaga, payload);
  }
}

export default [
  fork(getBonusesInfoListWatcherSaga),
  fork(createBonusInfoWatcherSaga),
  fork(deleteBonusInfoWatcherSaga),
  fork(editBonusInfoWatcherSaga),
  fork(getBonusInfoWatcherSaga),
  fork(getBonusTypesWatcherSaga),
  fork(getBonusAccountTypesWatcherSaga),
];
