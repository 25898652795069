import axios from 'axios';
import { locale } from 'common/utils/helpers';

export const getNewsList = search => axios.get(`/backoffice/news/`, { params: { ...search } });

export const createNews = (title, body) => axios.post(`/backoffice/news/`, { title, body });

export const getBONews = id => axios.get(`/backoffice/news/${id}/`);

export const editNews = (id, title, body) => axios.patch(`/backoffice/news/${id}/`, { title, body });

export const deleteNews = id => axios.delete(`/backoffice/news/${id}/`);

export const publishNews = id => axios.post(`/backoffice/news/${id}/publish/`);

export const unpublishNews = id => axios.post(`/backoffice/news/${id}/unpublish/`);

export const getStory = id =>
  axios.get(`/meta/news/${id}/`, {
    headers: {
      locale,
    },
  });
