import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  VKShareButton,
  VKIcon,
  TumblrShareButton,
  TumblrIcon,
} from 'react-share';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const ShareSocialButtons = ({ theme, title, favicon }) => {
  const url = window.location.href;
  const iconSize = 26;

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="ShareSocialButtons">
      <div className="ShareSocialIcon__inner">
        <FacebookShareButton url={url} quote={title}>
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
      </div>
      <div className="ShareSocialIcon__inner">
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>
      </div>
      <div className="ShareSocialIcon__inner">
        <VKShareButton url={url} title={title} image={favicon}>
          <VKIcon size={iconSize} round />
        </VKShareButton>
      </div>
      <div className="ShareSocialIcon__inner">
        <TumblrShareButton url={url} title={title}>
          <TumblrIcon size={iconSize} round />
        </TumblrShareButton>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

ShareSocialButtons.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.string,
  favicon: PropTypes.string,
};

ShareSocialButtons.defaultProps = {
  theme: {},
  title: "Trader's area",
  favicon: '',
};

export default compose(
  withTheme(),
  connect(
    state => ({
      title: state.interfaceConfig.clientThemeConfig.client.title,
      favicon: state.interfaceConfig.clientThemeConfig.client.images?.favicon || null,
    }),
    null
  )
)(ShareSocialButtons);
