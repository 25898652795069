import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const RadioButton = ({ textId, name, value, onChange, disabled, checked, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <label
      className={classNames('RadioButton', {
        'RadioButton--disabled': disabled,
      })}>
      <input type="radio" value={value} onChange={onChange} name={name} disabled={disabled} checked={checked} />
      <span className="RadioButton__fake" />
      {textId && (
        <FormattedMessage id={textId}>{txt => <span className="RadioButton__text">{txt}</span>}</FormattedMessage>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>
    </label>
  );
};

RadioButton.propTypes = {
  textId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
};

RadioButton.defaultProps = {
  disabled: false,
  checked: false,
  theme: {},
};

export default withTheme()(RadioButton);
export { RadioButton };
