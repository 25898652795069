import React from 'react';
import css from 'styled-jsx/css';

const staticStyle = css`
  .ArrowUpIcon {
    border-radius: 8px;
    border: 1px solid #0aa1e2;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
    cursor: pointer;
    > svg {
      width: 12px;
      height: 6.002px;
      flex-shrink: 0;
    }
  }

  .ArrowUpIcon:hover {
    cursor: pointer;
    background-color: #0aa1e2;
  }
`;

const ArrowUpIcon = () => (
  <div className="ArrowUpIcon">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
      <path
        d="M11.8654 5.82066C11.6516 6.0566 11.3064 6.05199 11.094 5.86117L6.00268 1.27779L0.910715 5.86158C0.685823 6.06202 0.341492 6.04442 0.139246 5.82106C-0.0612946 5.59556 -0.0432186 5.25109 0.180514 5.04987L5.60671 0.138664C5.81561 -0.0478592 6.12887 -0.0478592 6.33794 0.138664L11.7641 5.04987C12.0496 5.25109 12.0667 5.59556 11.8654 5.82066Z"
        fill="#333333"
      />
    </svg>

    <style jsx>{staticStyle}</style>
  </div>
);

export default ArrowUpIcon;
