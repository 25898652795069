import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const Checkbox = ({ onClick, checked, disabled, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames('Checkbox', {
        Checkbox__checked: checked,
        Checkbox__disabled: disabled,
      })}
      disabled={disabled}>
      <svg viewBox="0 0 12 10" fill="none">
        <path d="M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z" />
      </svg>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </button>
  );
};

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  theme: {},
};

export default withTheme()(Checkbox);
export { Checkbox };
