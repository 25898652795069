import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { change, Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { ReduxTextField, ValueSwitcherField, ReduxSelectField } from '../../form-fields';

import { NewDefaultButton } from '../..';

import { wsConfigureMt, wsSetStep, wsGetMtData, wsSetIsReenteringMtData } from '../../../_redux';

import { staticStyles } from './style';

const mt4EncodingOptions = [
  { label: 'Windows-1251 (Cyrillic)', value: 'cp1251' },
  { label: 'Windows-1250 (Eastern Europe)', value: 'cp1250' },
  { label: 'GBK (Unified Chinese)', value: 'gbk' },
];

const validate = values => {
  const errors = {};

  if (values.mtPassword !== values.mtPasswordConfirm)
    errors.mtPasswordConfirm = <FormattedMessage id="form.errors.passwordRepeat" />;

  return errors;
};

const ConfigureMtForm = ({
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit,
  dispatch,
  mtIsValid,
  changeFieldValue,
  isReenteringMTData,
  wsGetMtData,
  acceptedMtDataIsLoading,
  acceptedMtData,
}) => {
  const [checkedValue, setCheckedValue] = useState('mt4');

  const [passwordFieldType, setPasswordFieldType] = useState('password');

  useEffect(() => {
    if (mtIsValid) {
      wsGetMtData();
    }
  }, []);

  const wsSetIsReenteringMtDataFunc = () => {
    if (mtIsValid && !isReenteringMTData) {
      dispatch(wsSetIsReenteringMtData(true));
    }
  };

  useEffect(() => {
    if (!acceptedMtDataIsLoading && acceptedMtData) {
      Object.keys(acceptedMtData).forEach(key => {
        changeFieldValue(key, acceptedMtData[key]);
      });
    }
  }, [acceptedMtDataIsLoading, acceptedMtData]);
  const actionTooltipId = passwordFieldType === 'password' ? 'showPassword' : 'hidePassword';
  const actionClassName = passwordFieldType === 'password' ? 'show' : 'hide';

  const showPassword = () => {
    if (passwordFieldType === 'password') setPasswordFieldType('text');
    else setPasswordFieldType('password');
  };

  const handleNextStepClick = () => dispatch(wsSetStep(3));

  const submit = handleSubmit(formValues => wsConfigureMt(formValues, dispatch));

  const handleCheckedValueChange = (e, value) => {
    setCheckedValue(value);

    if (value === 'mt5') changeFieldValue('mtServerEncoding', '');
  };

  return (
    <form className="ConfigureMtForm" onSubmit={submit}>
      <ValueSwitcherField
        leftTextId="justMT4"
        rightTextId="justMT5"
        leftValue="mt4"
        rightValue="mt5"
        checkedValue={checkedValue}
        name="mtType"
        disabled={acceptedMtDataIsLoading || submitting}
        validate={[required()]}
        onChange={(...args) => {
          handleCheckedValueChange(...args);
          wsSetIsReenteringMtDataFunc();
        }}
      />
      {checkedValue === 'mt4' && (
        <Field
          component={ReduxSelectField}
          name="mtServerEncoding"
          textId="welcomeSetupMtServerEncoding"
          options={mt4EncodingOptions}
          disabled={acceptedMtDataIsLoading || submitting}
          validate={[required()]}
          onChange={() => {
            wsSetIsReenteringMtDataFunc();
          }}
          maxMenuHeight={140}
        />
      )}
      <Field
        component={ReduxTextField}
        type="text"
        name="mtDomain"
        placeholder="192.168.0.1"
        textId="welcomeSetupMtServerAddress"
        validate={[required()]}
        onChange={() => {
          wsSetIsReenteringMtDataFunc();
        }}
        disabled={acceptedMtDataIsLoading || submitting}
      />
      <Field
        component={ReduxTextField}
        type="number"
        name="mtPort"
        placeholder="443"
        textId="welcomeSetupMtPort"
        onChange={() => {
          wsSetIsReenteringMtDataFunc();
        }}
        validate={[required()]}
        disabled={acceptedMtDataIsLoading || submitting}
      />
      <Field
        component={ReduxTextField}
        type="number"
        name="mtLogin"
        tooltipId="welcomeSetupLoginToYourMetatraderAccount"
        tooltipTextId="welcomeSetupLoginToYourMetatraderAccount"
        textId="welcomeSetupMtLogin"
        validate={[required()]}
        onChange={() => {
          wsSetIsReenteringMtDataFunc();
        }}
        disabled={acceptedMtDataIsLoading || submitting}
      />
      <Field
        component={ReduxTextField}
        type={passwordFieldType}
        onChange={() => {
          wsSetIsReenteringMtDataFunc();
        }}
        tooltipId="welcomeSetupPasswordToYourMetatraderAccount"
        tooltipTextId="welcomeSetupPasswordToYourMetatraderAccount"
        name="mtPassword"
        textId="authPassword"
        validate={[required()]}
        disabled={acceptedMtDataIsLoading || submitting}
        action={showPassword}
        actionTooltipId={actionTooltipId}
        actionClassName={actionClassName}
      />
      {isReenteringMTData && (
        <span className="ConfigureMtForm__YouHaveEnteredNewData">
          <FormattedMessage id="welcomeSetupMtYouveEnteredNewData" />
        </span>
      )}
      <div className="ConfigureMtForm__nav-buttons">
        <NewDefaultButton
          textId="welcomeSetupConnectToMt"
          type="submit"
          disabled={invalid || pristine || submitting}
          variant="secondary"
          wide
          isUpperCase
        />
        <NewDefaultButton
          textId="justNextStep"
          type="button"
          onClick={handleNextStepClick}
          disabled={!mtIsValid || isReenteringMTData}
          variant="primary"
          wide
          isUpperCase
        />
      </div>
      {error && <strong className="formError">{error}</strong>}

      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

ConfigureMtForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  isReenteringMTData: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  mtIsValid: PropTypes.bool.isRequired,
  wsGetMtData: PropTypes.func.isRequired,
  acceptedMtDataIsLoading: PropTypes.bool.isRequired,
  acceptedMtData: PropTypes.object,
};

ConfigureMtForm.defaultProps = {
  error: '',
  acceptedMtData: {},
};

export default compose(
  connect(
    state => ({
      mtIsValid: state.welcomeSetup.mtIsValid,
      acceptedMtData: state.welcomeSetup.acceptedMtData,
      acceptedMtDataIsLoading: state.welcomeSetup.acceptedMtDataIsLoading,
    }),
    {
      changeFieldValue: (field, value) => change('wsConfigureMtForm', field, value),
      wsSetStep,
      wsSetIsReenteringMtData,
      wsGetMtData: () => wsGetMtData.request(),
    }
  ),
  reduxForm({
    form: 'wsConfigureMtForm',
    validate,
    onSubmitSuccess: (result, dispatch, { wsSetStep }) => {
      wsSetStep(3);
      wsSetIsReenteringMtData(false);
    },
    initialValues: {
      mtType: 'mt4',
      mtPort: 443,
    },
  })
)(ConfigureMtForm);
export { ConfigureMtForm };
