import React from 'react';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { ReduxTextField, DefaultButton } from 'common/components';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { staticStyles } from './style';

const BonusValueLevels =
  submitting =>
  ({ fields }) => {
    const validLevels = (previous, next, field) => value => {
      if (previous && previous >= value && next && value >= next) {
        return (
          <>
            <FormattedMessage id={`bo${field}LevelsErrorPrevious`} values={{ previous }}>
              {txt => txt}
            </FormattedMessage>
            {' | '}
            <FormattedMessage id={`bo${field}LevelsErrorNext`} values={{ next }}>
              {txt => txt}
            </FormattedMessage>
          </>
        );
      }
      if (previous && previous >= value) {
        return (
          <FormattedMessage id={`bo${field}LevelsErrorPrevious`} values={{ previous }}>
            {txt => txt}
          </FormattedMessage>
        );
      }
      if (next && value >= next) {
        return (
          <FormattedMessage id={`bo${field}LevelsErrorNext`} values={{ next }}>
            {txt => txt}
          </FormattedMessage>
        );
      }
    };

    return (
      <div className="BonusValueLevels">
        <div
          className={classNames('BonusValueLevels__top', {
            'BonusValueLevels__top--no-levels': !fields.length,
          })}>
          <div
            className={classNames('BonusValueLevels__title-wrap', {
              'BonusValueLevels__title-wrap--no-levels': !fields.length,
            })}>
            <div className="BonusValueLevels__title">
              <FormattedMessage id="boBonusValueLevels">{txt => txt}</FormattedMessage>
            </div>
            <div className="BonusValueLevels__title-button">
              <DefaultButton
                textId="addLevel"
                onClick={() => fields.push({})}
                plus
                loading={submitting}
                disabled={submitting}
              />
            </div>
          </div>

          {!!fields.length && (
            <div className="BonusValueLevels__titles-list">
              <div className="BonusValueLevels__titles-item">
                <FormattedMessage id="boDepositIntervalFrom">{txt => txt}</FormattedMessage>
              </div>
              <div className="BonusValueLevels__titles-item">
                <FormattedMessage id="boDepositIntervalTo">{txt => txt}</FormattedMessage>
              </div>
              <div className="BonusValueLevels__titles-item">
                <FormattedMessage id="boBonusValue">{txt => txt}</FormattedMessage>
              </div>
            </div>
          )}
        </div>

        <div className="BonusValueLevels__list">
          {fields.map((level, i) => (
            <div className="BonusValueLevels__item" key={i}>
              <div className="BonusValueLevels__item-level-wrap">
                <div className="BonusValueLevels__item-level-title">
                  <FormattedMessage id="justLevel">{txt => `${txt} ${i + 1}`}</FormattedMessage>
                </div>
                <button type="button" className="BonusValueLevels__item-level-cross" onClick={() => fields.remove(i)} />
              </div>

              <div className="BonusValueLevels__item-field-title">
                <FormattedMessage id="boDepositIntervalFrom">{txt => txt}</FormattedMessage>
              </div>
              <Field
                component={ReduxTextField}
                type="number"
                name={`${level}.from`}
                textId="justFrom"
                validate={[required(), validLevels(i > 0 ? fields.get(i - 1).to : null, fields.get(i).to, 'From')]}
                description="USD"
                disabled={submitting}
              />

              <div className="BonusValueLevels__item-field-title">
                <FormattedMessage id="boDepositIntervalTo">{txt => txt}</FormattedMessage>
              </div>
              <Field
                component={ReduxTextField}
                type="number"
                name={`${level}.to`}
                textId="justTo"
                validate={[
                  required(),
                  validLevels(fields.get(i).from, i + 1 < fields.length ? fields.get(i + 1).from : null, 'To'),
                ]}
                description="USD"
                disabled={submitting}
              />

              <div className="BonusValueLevels__item-field-title">
                <FormattedMessage id="boBonusValue">{txt => txt}</FormattedMessage>
              </div>
              <Field
                component={ReduxTextField}
                type="number"
                name={`${level}.value`}
                textId="justValue"
                validate={[required()]}
                description="%"
                disabled={submitting}
              />
              <button type="button" className="BonusValueLevels__item-cross" onClick={() => fields.remove(i)} />
            </div>
          ))}
        </div>
        <style jsx>{staticStyles}</style>
      </div>
    );
  };

export default BonusValueLevels;
