import css from 'styled-jsx/css'

import { rgba, isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .PartnershipPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 300px;
    height: 65vh;
    @include respond-to(md) {
      min-height: 250px;
    }
    @include respond-to(sm) {
      min-height: 200px;
      height: 60vh;
    }
    img {
      margin-bottom: 35px;
      width: 210px;
      height: 140px;
    }
    h3 {
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 25px;
    }
  }

  .PartnershipPage__top-inner {
    width: 100%;
    text-align: center;
  }

  .PartnershipPage__inner {
    padding: 15px 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 3px;
    @include respond-to(sm) {
      text-align: center;
    }
    p {
      margin: ${isRTL ? '0 0 0 20px' : '0 20px 0 0'};
      font-size: 16px;
      position: relative;
      padding-left: ${isRTL ? '0' : '30px'};
      padding-right: ${isRTL ? '30px' : '0'};
      @include respond-to(sm) {
        margin: 0 auto 15px auto;
        width: auto;
        display: inline-block;
      }
      &:before {
        content: '';
        position: absolute;
        left: ${isRTL ? 'auto' : '4px'};
        right: ${isRTL ? '4px' : 'auto'};
        width: 10px;
        height: 2px;
        transform: rotate(50deg);
        top: 12px;
        z-index: 2;
      }
      &:after {
        content: '';
        position: absolute;
        left: ${isRTL ? 'auto' : '8px'};
        right: ${isRTL ? '-10px' : 'auto'};
        width: 20px;
        height: 2px;
        transform: rotate(-50deg);
        top: 8px;
        z-index: 2;
      }
    }
    :global(a) {
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 1;
      position: relative;
      top: 1px;
      @include respond-to(sm) {
        width: 100%;
      }
    }
  }

  .PartnershipPage__button-inner {
    height: 50px;
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .PartnershipPage__inner {
      background: ${rgba([colors.warning.rgb, 0.35])};
      border-radius: ${border.radius};
      p {
        &:before {
          background: ${colors.success.hex};
        }
        &:after {
          background: ${colors.success.hex};
        }
      }
      :global(a) {
        color: ${colors.primary.hex};
        &:hover {
          color: ${colors.secondary.hex};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
