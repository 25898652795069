import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageLayout, PageContentPreloader } from 'common/components';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getOrderDetails } from 'backoffice/payments/_redux';
import { EditOrderForm } from '../../_components';
import { staticStyles } from './style';

const EditOrderPage = ({ orderDetails, orderDetailsIsLoaded, getOrderDetails }) => {
  const match = useRouteMatch();
  const { userId, accountId, orderId } = match.params;

  useEffect(() => {
    getOrderDetails({ userId, accountId, orderId });
  }, [getOrderDetails, userId, accountId, orderId]);

  return (
    <PageLayout captionSlug="orderEditTitle" extraCaptionText={orderId ? ` #${orderId}` : ''}>
      <PageContentPreloader ready={orderDetailsIsLoaded} type="bigLine">
        <FormattedMessage id="orderEditDescription">
          {txt => <div className="EditOrderPage__description">{txt}</div>}
        </FormattedMessage>
        <EditOrderForm
          orderDetails={orderDetails}
          initialValues={orderDetails.trade}
          userId={userId}
          accountId={accountId}
          orderId={orderId}
        />
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

EditOrderPage.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  orderDetailsIsLoaded: PropTypes.bool.isRequired,
  getOrderDetails: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    orderDetails: state.backoffice.payments.orderDetails,
    orderDetailsIsLoaded: state.backoffice.payments.orderDetailsIsLoaded,
  }),
  {
    getOrderDetails: data => getOrderDetails.request(data),
  }
)(EditOrderPage);
export { EditOrderPage };
