import React from 'react';

const Triangle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6" fill="none">
    <path
      d="M11.8189 0.951231L6.39523 5.86016C6.18643 6.04659 5.87331 6.04659 5.66434 5.86016L0.180326 0.951231C-0.0433027 0.719421 -0.0613703 0.405796 0.139759 0.180804C0.353843 -0.0544157 0.698831 -0.0510066 0.91121 0.139896L5.99979 4.72156L11.0894 0.139896C11.3142 -0.0604495 11.6583 -0.0428596 11.8605 0.180394C12.0609 0.405796 12.0439 0.719421 11.8189 0.951231Z"
      fill="#CECECE"
    />
  </svg>
);

export default Triangle;
