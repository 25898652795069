import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import angleComment from './angleComment.png';

export const ContractSpecsTableComment = ({ comment }) => {
  const [active, setActive] = useState(false);

  return (
    /* eslint-disable */
    <button
      type="button"
      className={classNames('comment', {
        'comment--hide': !active,
      })}
      onClick={e => e.stopPropagation()}
      onMouseOver={() => {
        if (!comment) return;
        setActive(true);
      }}
      onMouseOut={() => {
        setActive(false);
      }}>
      <svg
        className={classNames('comment__icon', {
          'comment__icon--nocomment': !comment,
        })}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M8.66098 15V8.028H11.335V15H8.66098ZM8.66098 7.006V4.836H11.335V7.006H8.66098Z" fill="#0ABB86" />
        <circle cx="10" cy="10" r="9.5" stroke="#0ABB86" />
      </svg>
      <div className="comment__text">{comment}</div>
      <img className="comment__angle" src={angleComment} alt="angleComment" />
    </button>
    /* eslint-enable */
  );
};

ContractSpecsTableComment.propTypes = {
  comment: PropTypes.string,
};

ContractSpecsTableComment.defaultProps = {
  comment: '',
};
