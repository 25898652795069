import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .CalendarPage {
    height: 75vh;
    min-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #tradingview_widget_wrapper {
    width: 100% !important;
    :global(iframe) {
      width: 100% !important;
    }
    > :global(div) {
      > div {
        width: 100% !important;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;

export { staticStyles };
