import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const StarIcon = ({ grayColor }) => (
  <svg className="noFill" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3253 22.0196L12.3948 17.7152C12.2035 17.6196 12.0122 17.6196 11.8209 17.7152L5.89049 22.0196C5.50789 22.3065 4.93397 21.9239 5.12528 21.4457L7.42093 14.463C7.51658 14.2717 7.42093 14.0804 7.22962 13.8891L1.20354 9.68043C0.820929 9.39348 1.01223 8.72391 1.49049 8.72391H8.85571C9.04701 8.72391 9.23832 8.62826 9.33397 8.3413L11.6296 1.3587C11.8209 0.880435 12.3948 0.880435 12.5861 1.3587L14.8818 8.3413C14.9774 8.53261 15.1688 8.72391 15.3601 8.72391H22.7253C23.2035 8.72391 23.3948 9.29783 23.0122 9.58478L17.0818 13.8891C16.8905 13.9848 16.7948 14.2717 16.8905 14.463L19.1861 21.4457C19.1861 21.9239 18.7079 22.3065 18.3253 22.0196Z"
      stroke={grayColor.hex}
    />
  </svg>
);

StarIcon.propTypes = {
  grayColor: colorType,
};

StarIcon.defaultProps = {
  grayColor: {},
};

const ThemedStarIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(StarIcon);

export default ThemedStarIcon;
