import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .GenerateDnsTxtForm {
    width: 100%;
    grid-column: 1/4;
    > .ReduxTextField {
      margin-bottom: 16px !important;
    }
    > .DefaultButton {
      background: transparent !important;
    }
    &__nav-buttons {
      display: flex;
      margin-top: 24px;
      gap: 16px;
      @include respond-to(sm) {
        flex-direction: column-reverse;
      }
    }
  }
`;

export { staticStyles };
