import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChangePasswordForm {
    padding: rem(20px) rem(40px) rem(40px) rem(40px);

    .ChangePasswordForm__caption {
      margin-bottom: rem(30px);
    }

    :global(.ReduxTextField) {
      width: 400px;
      margin-top: rem(15px);

      :global(label[for='newPassword']) {
        top: 26px;
      }

      @include respond-to(sm) {
        width: 300px;
      }
    }

    :global(.DefaultButton) {
      margin-top: rem(15px);
    }
  }
`;

export { staticStyles };
