import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import { LOGOUT_SUCCESS } from 'auth';

import * as ActionTypes from './actions';

export const initialState = {
  clientCustomPages: [],
  areClientCustomPagesLoaded: false,

  backofficeCustomPages: [],
  areBackofficeCustomPagesLoaded: false,

  isMenuOpen: false,
  profileIsLoaded: false,
  profile: null,
  currentInterface: 'client',

  lightBoxCurrentImage: -1,
  lightBoxImages: [],

  modals: {},
};

const fullProfileFromData = data => {
  const profile = {
    ...data,
    hasAdminAccess: data.isAdmin,
    hasBackofficeAccess: data.isAdmin || data.isStaff,
    hasClientAccess: true,
    hasCRMAccess: false,
  };

  profile.perms = profile.perms && profile.perms.reduce((perms, perm) => ({ ...perms, [perm]: true }), {});
  profile.hasCRMAccess = (profile.isAdmin || profile.isStaff) && profile.perms.managerViewCRMPerm;
  return profile;
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CLIENT_CUSTOM_PAGES[REQUEST]:
      return { ...state, areClientCustomPagesLoaded: false };
    case ActionTypes.GET_CLIENT_CUSTOM_PAGES[SUCCESS]:
      return {
        ...state,
        areClientCustomPagesLoaded: true,
        clientCustomPages: action.data,
      };
    case ActionTypes.GET_CLIENT_CUSTOM_PAGES[FAILURE]:
      return { ...state, areClientCustomPagesLoaded: false };

    case ActionTypes.GET_BACKOFFICE_CUSTOM_PAGES[REQUEST]:
      return { ...state, areBackofficeCustomPagesLoaded: false };
    case ActionTypes.GET_BACKOFFICE_CUSTOM_PAGES[SUCCESS]:
      return {
        ...state,
        areBackofficeCustomPagesLoaded: true,
        backofficeCustomPages: action.data,
      };
    case ActionTypes.GET_BACKOFFICE_CUSTOM_PAGES[FAILURE]:
      return { ...state, areBackofficeCustomPagesLoaded: false };

    case ActionTypes.TOGGLE_MENU:
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case ActionTypes.CLOSE_MENU:
      return { ...state, isMenuOpen: false };

    case ActionTypes.GET_PROFILE[REQUEST]:
      return { ...state };
    case ActionTypes.GET_PROFILE[SUCCESS]:
      return {
        ...state,
        profileIsLoaded: true,
        profile: fullProfileFromData(action.data),
      };
    case ActionTypes.GET_PROFILE[FAILURE]:
      return { ...state, profileIsLoaded: false };

    case ActionTypes.PROFILE_UPDATE:
      return { ...state, profile: fullProfileFromData(action.data) };

    case ActionTypes.OPEN_LIGHTBOX:
      return {
        ...state,
        lightBoxCurrentImage: action.id,
        lightBoxImages: action.lightBoxImages,
      };

    case ActionTypes.CLOSE_LIGHTBOX:
      return { ...state, lightBoxCurrentImage: -1, lightBoxImages: [] };

    case ActionTypes.OPEN_MODAL:
      return { ...state, modals: { ...state.modals, [action.name]: true } };

    case ActionTypes.CLOSE_MODAL:
      return { ...state, modals: { ...state.modals, [action.name]: false } };

    case ActionTypes.CHANGE_CURRENT_INTERFACE:
      return { ...state, currentInterface: action.data };

    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
