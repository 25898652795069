import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TopMenu {
    z-index: 10;
    position: relative;
    height: 80px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #062b3b;
    color: #ffffff;

    @include respond-to(sm) {
      padding: 0 24px;
    }

    &__left {
      display: flex;
      align-items: center;

      .hamburger {
        width: 24px;
        margin-right: ${isRTL ? '0' : '30px'};
        margin-left: ${isRTL ? '30px' : '0'};

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background: #ffffff;
        }
      }
    }

    &__logo {
      display: flex;
      width: 144px;
      margin-right: ${isRTL ? '0' : '30px'};
      margin-left: ${isRTL ? '30px' : '0'};
      transition: opacity 0.3s ease-n-out;

      @include respond-to(sm) {
        display: none;
      }

      &:hover {
        opacity: 0.6;
      }

      > img {
        height: 100%;
        width: 100%;
      }
    }

    &__interface_title {
      font-size: rem(14px);
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;

      @include respond-to(xs) {
        display: none;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 16px;
    }
  }

  .ClientInfo {
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;

    &__avatar {
      margin-right: ${isRTL ? '0' : '12px'};
      margin-left: ${isRTL ? '12px' : '0'};
      border-radius: 50%;
    }

    &__name {
      position: relative;
      padding-right: ${isRTL ? '0' : '17px'};
      padding-left: ${isRTL ? '17px' : '0'};
      transition: color 0.2s ease-in-out;

      @include respond-to(xs) {
        padding-right: ${isRTL ? '0' : '15px'};
        padding-left: ${isRTL ? '15px' : '0'};
        font-size: 0;
      }

      &::after {
        position: absolute;
        content: '';
        top: calc(50% - 6px);
        left: ${isRTL ? '0' : 'unset'};
        right: ${isRTL ? 'unset' : '0'};
        width: 8px;
        height: 8px;
        border: solid;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }

    &:hover &__name {
      color: #0aa1e2;
    }

    &__menu {
      position: absolute;
      display: none;
      min-width: 200px;
      z-index: 1;
      top: 100%;
      left: 0;
      background-color: #ffffff;
      box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    }

    &:hover &__menu {
      display: block;

      li {
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #f0f4f6;
        }
      }

      li a,
      li button {
        display: block;
        width: 100%;
        padding: rem(14px 10px 14px 20px);
        text-align: left;
        font-size: rem(15px);
        color: #0c0c3d;
      }
    }
  }
`;

export { staticStyles };
