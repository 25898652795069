import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .UserAccountsPanel {

    &__head {
      margin-bottom: rem(25px);
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export { staticStyles };
