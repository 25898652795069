import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { staticStyles } from './style';
import testData from './testData';

const TabSupport = ({ externalClass }) => (
  <div className={classNames('TabSupport', externalClass)}>
    <div className="TabSupport__list">
      {testData.map(({ nameTicket, dateTicket, dateUpdate, comments, status }, i) => (
        <div
          className={classNames('TabSupport__item', {
            'TabSupport__item--waiting': status === 'waiting',
            'TabSupport__item--answered': status === 'answered',
            'TabSupport__item--closed': status === 'closed',
          })}
          key={i}>
          <div className="TabSupport__item-left">
            <div className="TabSupport__item-left-name-ticket">{nameTicket}</div>
            <div className="TabSupport__item-left-date-ticket">
              <span className="TabSupport__item-left-date-ticket-title">Дата:</span>
              <span className="TabSupport__item-left-date-ticket-date">{dateTicket}</span>
            </div>
          </div>
          <div className="TabSupport__item-center">
            <div className="TabSupport__item-center-date-update">Последнее обновление: {dateUpdate}</div>
            <div className="TabSupport__item-center-comments">Комментариев: {comments}</div>
          </div>
          <div className="TabSupport__item-right">
            <div className="TabSupport__item-right-status-title">Status</div>
            <div className="TabSupport__item-right-status">{status}</div>
          </div>
        </div>
      ))}
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

TabSupport.propTypes = {
  externalClass: PropTypes.string,
};

TabSupport.defaultProps = {
  externalClass: '',
};

export default TabSupport;
