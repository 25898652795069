import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const AuthRightBg = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <>
      <div className="AuthRightBg" />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </>
  );
};

AuthRightBg.propTypes = {
  theme: PropTypes.object,
};

AuthRightBg.defaultProps = {
  theme: {},
};

export default withTheme()(AuthRightBg);
export { AuthRightBg };
