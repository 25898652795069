import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .PhoneCodeForm {
    display: flex;
    flex-direction: column;
  }

  .PhoneCodeForm__input {
    width: 35%;
    @include respond-to(xs) {
      width: 100%;
    }
  }
`;

export { staticStyles };
