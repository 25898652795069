import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  * {
  }
  .BaseAsyncSelectField--RemoveArrow {
    .select__indicator {
      display: none;
    }
  }

  .BaseAsyncSelectField {
    position: relative;
    width: 100%;

    &__label {
      position: absolute;
      left: 16px;
      z-index: 10;

      font-size: 10px;
      line-height: 1.4;
      color: #999;
      background: #fff;
      padding: 0 4px;

      span {
        position: relative;
        top: -7px;
        z-index: 10;
      }
    }

    .select__control {
      border: 1px solid #f3f3f3;
    }

    .select__menu {
      z-index: 999;
      margin-top: 2px;
      border-radius: 0;
      > .select__menu-list {
        padding: 0;
      }
    }
    .select__indicator-separator {
      display: none;
    }

    .select__single-value {
      margin-left: 0;
      color: #333;
      font-size: 14px;
      line-height: 1.4;
    }

    .select__placeholder {
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .BaseAsyncSelectField__error {
      .BaseAsyncSelectField__label {
        color: ${colors.error.hex} !important;
      }
      .select__control--is-focused {
        border: 1px solid ${colors.error.hex} !important;
        &:after {
          border-color: ${colors.error.hex} !important;
        }
      }
      .select__control {
        border-color: ${colors.error.hex} !important;
        &:after {
          border-color: ${colors.error.hex} !important;
        }
      }
    }

    .BaseAsyncSelectField__error-text {
      color: ${colors.error.hex};
      font-weight: 300;
      font-size: 14px;
    }
  `;
};

export { staticStyles, getDynamicStyles };
