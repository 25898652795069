import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Banners {
    width: 100%;
    padding-left: 30px;
    margin: rem(30px 0 30px);
    @include respond-to(md) {
      display: none;
    }
    div {
      outline: none;
    }
  }
  
  .Banners__item {
    transition: all .3s ease;
    position: relative;
    .Banners__item-image {
      display: block;
      width: 100%;
      transition: all .4s ease;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include respond-to(lg) {
        height: 200px;
      }
    }
    &:hover {
      .Banners__item-image {
        transform: scale(.98);
      }
    }
  }
`

export { staticStyles }
