import axios from 'axios';

export const boGetInvestStrategies = search => axios.get('/backoffice/invest/', { params: { ...search } });

export const boGetInvestStrategyInvestors = (strategyId, search) =>
  axios.get(`/backoffice/invest/${strategyId}/accounts/`, { params: { ...search } });

export const boToggleInvestStrategy = (strategyId, strategyAction) =>
  axios.post(`/backoffice/invest/${strategyId}/${strategyAction}/`);

export const boToggleInvestment = (investmentId, investmentAction, account) =>
  axios.post(`/backoffice/invest/accounts/${investmentId}/${investmentAction}/`, {
    account,
  });

export const boGetUserAccounts = userId =>
  axios.get(`/backoffice/user/${userId}/account/`, { params: { is_demo: false } });

export const editInvestStrategy = (id, values) => axios.put(`/backoffice/invest/${id}/change/`, values);
