import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_CATEGORIES = createRequestTypes('GET_CATEGORIES');
export const getCategories = {
  request: data => action(GET_CATEGORIES[REQUEST], data),
  success: data => action(GET_CATEGORIES[SUCCESS], { data }),
  failure: error => action(GET_CATEGORIES[FAILURE], { error }),
};

export const GET_QUESTIONS = createRequestTypes('GET_QUESTIONS');
export const getQuestions = {
  request: categoryId => action(GET_QUESTIONS[REQUEST], { categoryId }),
  success: data => action(GET_QUESTIONS[SUCCESS], { data }),
  failure: error => action(GET_QUESTIONS[FAILURE], { error }),
};
