import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'common/styling/theme';

import { FormattedMessage } from 'react-intl';
import { BaseTable, NoItemsPanel, StatusCell, DateCell } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';

import { getApprovalHistory } from '../../payments/_redux';

import { staticStyles, getDynamicStyles } from './style';

const ApprovalHistory = ({ getApprovalHistory, approvalHistory, approvalHistoryIsLoaded, groupId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getApprovalHistory(groupId);
  }, [getApprovalHistory, groupId]);

  /* eslint-disable */
  const columns = [
    {
      key: 'justDate',
      Cell: ({ original: { approveTime } }) => <DateCell value={approveTime} />,
    },
    {
      key: 'justStepAndInspection',
      Cell: ({ original: { phase } }) =>
        phase ? (
          <FormattedMessage
            id="approvalStepName"
            values={{
              n: phase.priority + 1,
              who: phase.title,
            }}>
            {txt => <span className="phase">{txt}</span>}
          </FormattedMessage>
        ) : (
          '—'
        ),
    },
    {
      key: 'justId',
      Cell: ({ original: { id } }) => <span>{id}</span>,
    },
    {
      key: 'justComment',
      Cell: ({ original: { comment } }) => <p>{comment}</p>,
    },
    {
      key: 'justResponsible',
      Cell: ({ original: { actor } }) => <span className="name">{`${actor.firstName} ${actor.lastName}`}</span>,
    },
    {
      key: 'justDecision',
      Cell: ({ original: { isApproved } }) => <StatusCell statusCode={isApproved ? 'approved' : 'rejected'} />,
    },
  ];
  /* eslint-enable */

  const onRowClick = () => {};

  return (
    <div className="ApprovalHistory">
      <FormattedMessage id="justApprovalHistory">{txt => <h2>{txt}</h2>}</FormattedMessage>

      {approvalHistoryIsLoaded && approvalHistory.length ? (
        <BaseTable columns={columns} data={approvalHistory} onRowClick={onRowClick} isResponsive />
      ) : (
        <NoItemsPanel icon={noPaymentsImage} captionSlug="noData" />
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ApprovalHistory.propTypes = {
  approvalHistory: PropTypes.array.isRequired,
  approvalHistoryIsLoaded: PropTypes.bool.isRequired,
  getApprovalHistory: PropTypes.func.isRequired,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  theme: PropTypes.object,
};

ApprovalHistory.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      approvalHistory: state.backoffice.payments.approvalHistory,
      approvalHistoryIsLoaded: state.backoffice.payments.approvalHistoryIsLoaded,
    }),
    {
      getApprovalHistory: id => getApprovalHistory.request(id),
    }
  )
)(ApprovalHistory);
export { ApprovalHistory };
