import React from 'react';
import PropTypes from 'prop-types';
import { FormMessageHOC } from 'common';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { required, email } from 'redux-form-validators';
import { createContact } from 'crm/contacts/_redux';
import { isValidNumber } from 'libphonenumber-js';
import { FormattedMessage } from 'react-intl';

import { TextFormField } from 'crm/_components/forms';
import { BaseButton } from 'crm/_components/';

import { staticStyles, getDynamicStyles } from './style';

const validatePhoneNumber = phoneNumber =>
  !isValidNumber(phoneNumber) ? <FormattedMessage id="validationErrorWrongPhone" /> : null;

const CreateContactForm = ({ theme, handleSubmit, dispatch }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(formValues => createContact(formValues, dispatch));

  return (
    <form className="CreateContactForm" onSubmit={submit}>
      <div className="CreateContactForm__row">
        <Field placeholder="First name" component={TextFormField} validate={[required()]} name="firstName" />
        <Field placeholder="Last name" component={TextFormField} validate={[required()]} name="lastName" />
      </div>
      <div className="CreateContactForm__row">
        <Field
          placeholder="Phone"
          component={TextFormField}
          validate={[required(), validatePhoneNumber]}
          name="phone"
        />
        <Field placeholder="Email" component={TextFormField} validate={[required(), email()]} name="email" />
      </div>
      <div className="CreateContactForm__submit-wrap">
        <BaseButton type="submit" className="CreateContactForm__submit" textId="justCreate" onClick={() => {}} />
      </div>
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </form>
  );
};

CreateContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

CreateContactForm.defaultProps = {
  theme: {},
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'add-contact-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmContactWasSuccessfullyCreated');
      props.onSuccess();
    },
  })
)(CreateContactForm);
