import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ContactPageInfoBlock {
    width: 100%;
    margin: 0 0 32px 0;

    &__TitleWrap {
      width: 100%;
      margin-bottom: 16px;
    }

    @include respond-to(sm) {
      margin-bottom: 24px;
    }

    &__Title {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.4;

      @include respond-to(sm) {
        font-size: 18px;
      }
    }

    &__ContentWrap {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__Item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 12px 0;
      margin: 0 0 12px 0;

      &:not(&:last-child)::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, #f3f3f3 48.44%, rgba(243, 243, 243, 0) 100%);
      }

      &:last-child {
        margin: 0 0 0 0;
        padding: 0;
      }
    }

    &__ItemTitle {
      font-size: 14px;
      line-height: 1.4;
      color: #999;
    }

    &__ItemContent {
      font-size: 14px;
      line-height: 1.4;
      display: flex;
      gap: 10px;
      color: #333;
    }
  }
`;

export { staticStyles };
