import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PaginationTable, StatusCell, ActionsButton, DateCell } from 'common/components';
import { StopIcon, EditIcon } from 'common/icons';

import { TaskIcon } from '..';

import { staticStyles } from './style';

const TasksListTable = ({
  user,
  data,
  onRowClick,
  loading,
  getList,
  location,
  history,
  params,
  onActionClick,
  ...props
}) => {
  /* eslint-disable */
  const columns = [
    {
      Header: () => null,
      id: 'icon',
      Cell: ({
        row: {
          original: { icon },
        },
      }) => <TaskIcon type={icon} />,
      isPreviewCell: true,
    },
    {
      accessor: 'subject',
      Header: 'justSubject',
      Cell: ({
        row: {
          original: { subject },
        },
      }) => <span>{subject ? `${subject.firstName} ${subject.lastName}` : '一'}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'assignedTo',
      Header: 'justAssigned',
      Cell: ({
        row: {
          original: { assignedTo },
        },
      }) => {
        return (
          <span>
            {assignedTo.email === user.email ? (
              <FormattedMessage id="justToMe" />
            ) : (
              `${assignedTo.firstName} ${assignedTo.lastName}`
            )}
          </span>
        );
      },
      isPreviewCell: true,
    },
    {
      accessor: 'title',
      Header: 'justTitle',
      Cell: ({
        row: {
          original: { title },
        },
      }) => <span>{title}</span>,
    },
    {
      accessor: 'comment',
      Header: 'justComment',
      Cell: ({
        row: {
          original: { comment },
        },
      }) => <span>{comment ?? '一'}</span>,
    },
    {
      accessor: 'expiration_date',
      Header: 'justExpire',
      Cell: ({
        row: {
          original: { expirationDate, isOverdue },
        },
      }) => {
        return (
          <div className="TasksListTable__expirationDate">
            <DateCell value={expirationDate} />
            {isOverdue && (
              <span className="overdue">
                <FormattedMessage id="justOverdue" />
              </span>
            )}
          </div>
        );
      },
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      Cell: ({
        row: {
          original: { status },
        },
      }) => <StatusCell statusCode={status} />,
    },
    {
      accessor: 'actions',
      Header: () => {},
      Cell: ({
        row: {
          original: { id, status, statusChangeable },
        },
      }) => (
        <div className="actions">
          {user.perms.deleteTaskPerm && (
            <ActionsButton
              deleteType
              onClickFunc={e => onActionClick(e, id, 'finish')}
              disabled={status === 'CLOSED' || !statusChangeable}>
              <StopIcon />
            </ActionsButton>
          )}
          {user.perms.editTaskPerm && (
            <ActionsButton edit onClickFunc={e => onActionClick(e, id, 'edit')} disabled={status === 'CLOSED'}>
              <EditIcon />
            </ActionsButton>
          )}
        </div>
      ),
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="TasksListTable">
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TasksListTable.propTypes = {
  user: PropTypes.object.isRequired,
  onActionClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default TasksListTable;
