import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getCrmUserLogs } from 'crm/users/_redux';
import { Pagination } from 'crm/_components';
import { DateCell, PageContentPreloader } from 'common/components';

import noData from 'common/images/noInfo.svg';

import { FeedTask, DefaultDesc } from './_components';

import cardIcon from './images/cardIcon.svg';

import { staticStyles } from './style';

const TITLES = {
  impersonate: 'crmImpresonate',
  accountcreate: 'crmAccountCreate',
  internaltransferchange: 'crmInternalTransferAction',
  contacttask: 'crmContactTask',
  mailtask: 'crmMailTask',
  monitoringtask: 'crmMonitoringTask',
  changepartnercode: 'crmPartnerCodeChange',
  userstatuschanged: 'crmContactStatusChange',
  registration: 'crmRegistration',
  requestchange: 'crmRequestChange',
  login: 'crmLogin',
  bonusactivate: 'crmBonusActivate',
};

const descriptionVariant = {
  contacttask: FeedTask,
};

const DescriptionComponent = ({ eventType, description }) => {
  let Component;
  if (descriptionVariant[eventType]) {
    Component = descriptionVariant[eventType];
  } else {
    Component = DefaultDesc;
  }
  return <Component eventType={eventType} description={description} />;
};

const TabLogs = () => {
  const { logs, logsAreLoaded } = useSelector(state => state.crm.users);
  const hasFeed = !!Object.keys(logs).length;

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getCrmUserLogs.request({
        id,
        search: {
          page: 1,
          page_size: 10,
        },
      })
    );
  }, []);

  const onPageChange = (page, size) => {
    dispatch(
      getCrmUserLogs.request({
        id,
        search: {
          page,
          page_size: size,
        },
      })
    );
  };

  return (
    <div className="TabLogs">
      <PageContentPreloader ready={logsAreLoaded} type="smallLines">
        <div className="TabLogs__list">
          {hasFeed &&
            logs.results.map(({ id, at, description, eventType }) => (
              <div className="TabLogs__item" key={id}>
                <div className="TabLogs__item-wrap">
                  <div className="TabLogs__item-icon">
                    <img src={cardIcon} alt={eventType} />
                  </div>
                  <div className="TabLogs__item-subwrap">
                    <div className="TabLogs__item-info-wrap">
                      {TITLES[eventType] && (
                        <div className="TabLogs__item-title">
                          <FM id={TITLES[eventType]}>{txt => txt}</FM>
                        </div>
                      )}

                      <DescriptionComponent id={id} description={description} eventType={eventType} />
                    </div>

                    <div className="TabLogs__item-date">
                      <DateCell value={at} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </PageContentPreloader>
      {hasFeed && (
        <Pagination
          externalClass="TabLogs__pagination"
          dataCount={logs ? logs.count : 1}
          handleChangePage={onPageChange}
          sizes={[10, 50, 'all']}
          searchId="TabLogs"
          pending={!logsAreLoaded}
        />
      )}

      {!hasFeed && logsAreLoaded && (
        <div className="TabLogs__no-data">
          <img src={noData} alt="noData" />
          <FM id="noData" />
        </div>
      )}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default TabLogs;
