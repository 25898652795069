import axios from 'axios';

export const getStatusesList = ordering => axios.get(`/backoffice/statuses/info/list/`, { params: { ordering } });
export const getStatusInfo = id => axios.get(`/backoffice/statuses/info/${id}/`);
export const createStatusInfo = payload => axios.post(`/backoffice/statuses/info/create/`, { ...payload });
export const updateStatusInfo = (id, payload) => axios.put(`/backoffice/statuses/info/${id}/`, { ...payload });
export const deleteStatusInfo = id => axios.delete(`/backoffice/statuses/info/${id}/`);

export const getStatusDescription = () => axios.get(`/backoffice/statuses/description/info/`);
export const updateStatusDescription = payload => axios.put(`/backoffice/statuses/description/info/`, { ...payload });
