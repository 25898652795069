import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .FaqCategory {
    width: 24%;
    height: 85px;
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    cursor: pointer;
    padding: 25px 20px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-right: ${isRTL ? 0 : '1%'};
    margin-left: ${isRTL ? '1%' : 0};

    @include respond-to(lg) {
      width: 49%;
      margin-right: 2%;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @include respond-to(xs) {
      width: 100%;
      padding: 15px;
      margin-right: 0;
    }

    &:nth-child(3n + 4) {
      margin: 0;
    }

    &:hover {
      box-shadow: 0px 3px 9px rgba(133, 201, 230, 0.5);
    }

    &__img {
      max-width: 30px;
      margin-right: ${isRTL ? 0 : ' 20px'};
      margin-left: ${isRTL ? ' 20px' : 0};

      img {
        width: 100%;
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .FaqCategory {
      &__name {
        color: ${colors.secondary.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
