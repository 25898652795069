import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PageLayout, PageContentPreloader } from 'common/components';
import { getClientTransferDetails } from 'client/transfers/_redux';
import { TransferDetails } from '../../_components';

const TransferPage = ({ getTransferDetails, transferDetails, transferDetailsIsLoaded, match }) => {
  useEffect(() => {
    getTransferDetails({ transferId: match.params.id });
  }, [getTransferDetails, match.params.id]);

  return (
    <PageLayout
      captionSlug="boInternalTransfer"
      extraCaptionText={match.params.id}
      showReload
      onReloadClick={() => getTransferDetails({ transferId: match.params.id })}
      reloading={!transferDetailsIsLoaded}>
      <PageContentPreloader ready={transferDetailsIsLoaded} type="boPaymentPage">
        <TransferDetails captionSlug="transferDetails" transferDetails={transferDetails} />
      </PageContentPreloader>
    </PageLayout>
  );
};

TransferPage.propTypes = {
  match: PropTypes.object.isRequired,
  transferDetails: PropTypes.object.isRequired,
  transferDetailsIsLoaded: PropTypes.bool.isRequired,
  getTransferDetails: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      transferDetails: state.client.transfers.transferDetails,
      transferDetailsIsLoaded: state.client.transfers.transferDetailsIsLoaded,
    }),
    {
      getTransferDetails: data => getClientTransferDetails.request(data),
    }
  )
)(TransferPage);

export { TransferPage };
