import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs } from 'crm/_components/';
import { MyTasksWrapper, ReassignTasksWrapper, AllTasksWrapper } from '../../_components';
import { staticStyles } from './style';

const TasksPage = ({ perms }) => (
  <div className="TasksPage">
    <Tabs externalClass="TasksPage__tabs">
      <MyTasksWrapper tabTextId="crmMyTasks" tabSlug="myTasks" />
      <AllTasksWrapper tabTextId="crmAllTasks" tabSlug="allTasks" />
      {perms.setManagerPerm && <ReassignTasksWrapper tabTextId="crmReassign" tabSlug="reassign" />}
    </Tabs>
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

TasksPage.propTypes = {
  perms: PropTypes.object.isRequired,
};

export default connect(state => ({
  perms: state.user.profile.perms,
}))(TasksPage);
