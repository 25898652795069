import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';

import Tooltip from '../Tooltip';

import { staticStyles, getDynamicStyles } from './style';

const TableActions = ({ actionButtons, onActionClick, hidden, tooltipId, tooltipTextId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="TableActions">
        <Tooltip id={hidden ? '' : tooltipId} textId={hidden ? '' : tooltipTextId}>
          <button
            type="button"
            className={classNames('TableActions__control', {
              opened: isOpen,
              hidden,
            })}
            disabled={hidden}
            onClick={e => {
              e.stopPropagation();
              toggleModal();
            }}>
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </button>
        </Tooltip>
        {isOpen && (
          <div className="TableActions__btns">
            {actionButtons.map((button, index) => (
              <FormattedMessage
                id={button.textId}
                key={`${tooltipTextId}_${actionButtons[index].textId}_${button.type}`}>
                {txt => (
                  <button
                    type="button"
                    onClick={e => {
                      toggleModal();
                      onActionClick(e, button.type);
                    }}
                    className="TableActions__button">
                    {txt}
                  </button>
                )}
              </FormattedMessage>
            ))}
          </div>
        )}
        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    </OutsideClickHandler>
  );
};

TableActions.propTypes = {
  actionButtons: PropTypes.array.isRequired,
  onActionClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  tooltipId: PropTypes.string,
  tooltipTextId: PropTypes.string,
  theme: PropTypes.object,
};

TableActions.defaultProps = {
  hidden: false,
  tooltipId: '',
  tooltipTextId: '',
  theme: {},
};

export default withTheme()(TableActions);
