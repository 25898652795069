import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BasketIcon } from 'common';
import { DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const Message = ({ id, sender, title, text, created, isNew, active, onCaptionClick, onBucketClick, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div
      id={id}
      className={classNames('Message', {
        Message__new: isNew,
        Message__open: active,
      })}>
      <div
        role="presentation"
        className="Message__caption"
        onClick={() => onCaptionClick(id)}
        onKeyPress={() => onCaptionClick(id)}>
        <div className="Message__caption_left-inner">
          <div className="Message__sender">{sender}</div>
          <div className="Message__title">{title}</div>
        </div>
        <div className="Message__date">
          <DateCell value={created} />
        </div>
        <button type="button" className="Message__caption-bucket" onClick={() => onBucketClick(id)}>
          <BasketIcon />
        </button>
      </div>
      {active && <pre className="Message__text" dangerouslySetInnerHTML={{ __html: text }} />}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

export const messageType = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  sender: PropTypes.string,
  isNew: PropTypes.bool,
};

Message.propTypes = {
  ...messageType,
  active: PropTypes.bool,
  onCaptionClick: PropTypes.func.isRequired,
  onBucketClick: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

Message.defaultProps = {
  active: false,
  theme: {},
};

const ThemedMessage = withTheme()(Message);

export default ThemedMessage;
export { Message };
