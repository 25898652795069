import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { DefaultButton, LoginCell, MoneyCell } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const PartnerAccountInfoPanel = ({
  partnerCode,
  balance,
  withdrawBalance,
  loading,
  loadingFailed,
  onWithdrawClick,
  disabled,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PartnerAccountInfoPanel">
      <div className="PartnerAccountInfoPanel__top">
        <ul>
          <li>
            <FormattedMessage id="justType" />
            <LoginCell title={intl.formatMessage({ id: 'partnerAccount' })} />
          </li>
          <li>
            <FormattedMessage id="accountId" />
            <p className="PartnerAccountInfoPanel__second-line">{partnerCode}</p>
          </li>
          <li>
            <FormattedMessage id="justBalance" />
            <MoneyCell value={balance} loading={loading} loadingFailed={loadingFailed} />
          </li>
          <li>
            <FormattedMessage id="justWithdrawalBalance" />
            <MoneyCell value={withdrawBalance} loading={loading} loadingFailed={loadingFailed} />
          </li>
          <li className="AccountInfoPanel__button">
            <DefaultButton textId="justWithdraw" onClick={onWithdrawClick} disabled={disabled || loading} />
          </li>
        </ul>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

PartnerAccountInfoPanel.propTypes = {
  partnerCode: PropTypes.string.isRequired,
  balance: PropTypes.object.isRequired,
  withdrawBalance: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingFailed: PropTypes.bool.isRequired,
  onWithdrawClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
};

PartnerAccountInfoPanel.defaultProps = {
  disabled: false,
  theme: {},
};

export default withTheme()(injectIntl(PartnerAccountInfoPanel));
export { PartnerAccountInfoPanel };
