import css from 'styled-jsx/css';
import { gradient } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TableActions {
    width: 100%;
    max-width: 250px;

    :global(.Tooltip) {
      background: #ffffff !important;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      :global(pre) {
        color: #000;
      }

      &::after {
        content: none;
      }
    }

    &__control {
      width: 20px;
      height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      :global(.line) {
        height: 1px;
        width: 20px;
        background: #777777;
      }
    }

    &__btns {
      display: block;
      box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
      border: none;
      margin: 0;
      z-index: 11;
      position: absolute;
      top: 50px;
      width: 100%;
      background: white;
    }

    &__button {
      width: 100%;
      display: block;
      border-radius: 0;
      text-align: left;
      padding: rem(12px 15px);
      z-index: 8;
      position: relative;
      cursor: pointer;

      &:last-child {
        border-radius: 0;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .TableActions__control {
      span {
        background: ${colors.lightGray.hex};
      }

      &:hover {
        span {
          background: ${colors.primary.hex};
        }
      }

      &.opened {
        span {
          background: ${colors.primary.hex};
        }
      }

      &.hidden {
        cursor: not-allowed;

        span {
          background: ${colors.lightGray.hex};
          opacity: 0.5;
        }
      }
    }

    .TableActions__button {
      background-color: ${colors.white.hex};
      color: ${colors.black.hex};

      &:hover {
        color: ${colors.secondary.hex};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 0.25], 0, [colors.gradientEnd.rgb, 0.25], 100)};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
