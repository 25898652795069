import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { required } from 'redux-form-validators';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { FormMessageHOC } from 'common/HOCs';
import { DefaultButton, ReduxSelectField } from 'common/components';

import { sendSimpleIBSystemSettings } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const SimpleIBSystemSettingsPanel = ({
  simpleIBSystemSettings,
  handleSubmit,
  history,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(values => sendSimpleIBSystemSettings({ ...values }, dispatch));

  return (
    <div className="SimpleIBSystemSettingsPanel">
      <FormattedMessage id="simpleIBSystemSettingsCaption">
        {txt => <h3 className="SimpleIBSystemSettingsPanel__caption">{txt}</h3>}
      </FormattedMessage>
      <FormattedMessage id="partnerCode">
        {txt => (
          <p className="SimpleIBSystemSettingsPanel__account">
            {txt}: <span>{simpleIBSystemSettings.partnershipAccount}</span>
          </p>
        )}
      </FormattedMessage>
      <FormattedMessage id="simpleIBSystemSettingsDescription">
        {txt => <p className="SimpleIBSystemSettingsPanel__description">{txt}</p>}
      </FormattedMessage>
      <form className="SimpleIBSystemSettingsPanel__form" onSubmit={submit}>
        {Object.entries(simpleIBSystemSettings.options).map((platform, index) => (
          <div className="SimpleIBSystemSettingsPanel__platform-item" key={index}>
            <h4 className="SimpleIBSystemSettingsPanel__platform">{platform[1] && platform[1].displayName}</h4>
            <div
              className={classNames('SimpleIBSystemSettingsPanel__description-inner', {
                SimpleIBSystemSettingsPanel__noItems: !platform[1],
              })}>
              <FormattedMessage
                id="simpleIBSystemSettingDescription"
                values={{ platform: platform[1] && platform[1].displayName }}>
                {txt => <p className="SimpleIBSystemSettingsPanel__platform-description">{txt}</p>}
              </FormattedMessage>
              <div className="SimpleIBSystemSettingsPanel__select-inner">
                {platform[1].options && platform[1].options.length ? (
                  <div className="SimpleIBSystemSettingsPanel__form-fields">
                    <Field
                      component={ReduxSelectField}
                      name={platform[0]}
                      textId="clientAccount"
                      options={platform[1].options.map(option => ({
                        value: option.login,
                        label: option.login,
                      }))}
                      validate={[required()]}
                      disabled={submitting}
                    />
                    <DefaultButton
                      textId="justAddAccount"
                      onClick={() => history.push('/create-account/real')}
                      filled
                      plus
                    />
                  </div>
                ) : (
                  <div className="SimpleIBSystemSettingsPanel__noAccount">
                    <FormattedMessage id="simpleIBSystemSettingNoAccountType">{txt => <p>{txt}</p>}</FormattedMessage>
                    <DefaultButton
                      textId="justAddAccount"
                      onClick={() => history.push('/create-account/real')}
                      filled
                      plus
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        <DefaultButton
          textId="doSave"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          filled
        />

        {error && <strong className="formError">{error}</strong>}
      </form>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

SimpleIBSystemSettingsPanel.propTypes = {
  simpleIBSystemSettings: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

SimpleIBSystemSettingsPanel.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withRouter,
  FormMessageHOC,
  withTheme(),
  connect((state, props) => ({
    initialValues: props.simpleIBSystemSettings.settings,
  })),
  reduxForm({
    form: 'simple-ib-system-settings-form',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage();
    },
  })
)(SimpleIBSystemSettingsPanel);
export { SimpleIBSystemSettingsPanel };
