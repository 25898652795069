import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';

import { staticStyles } from './style';

const CustomFilterInfo = ({ modifiedFiltersSelector, currentFilter }) => {
  const modifiedFilters = useSelector(modifiedFiltersSelector);
  const filterValues = {};

  Object.keys(currentFilter.query).forEach(filterName => {
    const modifiedCurrentFilter = modifiedFilters[filterName];
    const currentFilterValue = currentFilter.query[filterName];

    if (modifiedCurrentFilter.inputType === 'inputDate') {
      if (new Set(currentFilterValue).size === 1) {
        filterValues[filterName] = currentFilterValue[0];
        return;
      }

      filterValues[filterName] = currentFilterValue.join('—');
      return;
    }

    if (
      modifiedCurrentFilter.inputType === 'inputIntegerChoice' ||
      modifiedCurrentFilter.inputType === 'inputStringChoice'
    ) {
      const selectedValues = [];

      currentFilterValue.forEach(value => {
        const selectedValue = modifiedCurrentFilter.choiceValues.find(choiceValue => choiceValue.value === value);

        if (selectedValue) {
          selectedValues.push(selectedValue.name);
        }
      });

      filterValues[filterName] = selectedValues.join(', ');
      return;
    }

    if (modifiedCurrentFilter.inputType === 'inputStr') {
      filterValues[filterName] = currentFilterValue;
      return;
    }

    if (modifiedCurrentFilter.inputType === 'inputBoolean') {
      filterValues[filterName] = currentFilterValue ? <FM id="justEnabled" /> : <FM id="justDisabled" />;
    }
  });

  return (
    <div className="CustomFilterInfo">
      {Object.keys(filterValues).map((filter, index) => (
        <div className="CustomFilterInfo__Filter" key={`${filter}-${index}`}>
          <FM id={filter}>{txt => <span className="CustomFilterInfo__Filter-title">{txt}:</span>}</FM>{' '}
          <span className="CustomFilterInfo__Filter-value">{filterValues[filter]}</span>
        </div>
      ))}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

CustomFilterInfo.propTypes = {
  modifiedFiltersSelector: PropTypes.func.isRequired,
  currentFilter: PropTypes.object.isRequired,
};

export default CustomFilterInfo;
