import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ManagersActionsTab {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    &__Top {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 25px 0;
      gap: 20px;
      @include respond-to(sm) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 5px;
      }
    }
    &__TopLeft {
      width: 250px;
      @include respond-to(sm) {
        width: 100%;
      }
    }
    &__TopRight {
      display: flex;
      @include respond-to(sm) {
        justify-content: flex-end;
      }
    }
  }
`;

const getDynamicStyles = state => {
  return css``;
};

export { staticStyles, getDynamicStyles };
