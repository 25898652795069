import React from 'react';
import PropTypes from 'prop-types';

import {
  EditContactNameForm,
  ReassignManagerForm,
  ReassignIBManagerForm,
  SetPartnerCodeForm,
  SaveSelectionForm,
  CreaeteLetterForm,
  ScheduleACallFrom,
  AddMonitoringForm,
  AddContactsForm,
  CRMEditUserManagerForm,
  CRMEditUserIBManagerForm,
  CRMEditPartnerCodeForm,
} from '../forms';
import BasicCRMModal from '../BasicCRMModal';
import CRMAddContactTagForm from '../forms/forms/CRMAddContactTagForm';

const CRMModals = ({ modalType, onRequestClose }) => {
  if (modalType === 'editContactName') {
    return (
      <BasicCRMModal captionId="crmJustEditName" isOpen onRequestClose={onRequestClose}>
        <EditContactNameForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'reassign') {
    return (
      <BasicCRMModal captionId="justReassignManager" isOpen onRequestClose={onRequestClose}>
        <ReassignManagerForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'reassignManager') {
    return (
      <BasicCRMModal captionId="justReassignIBManager" isOpen onRequestClose={onRequestClose}>
        <ReassignIBManagerForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'setPartnerCode') {
    return (
      <BasicCRMModal captionId="justSetPartnerCode" isOpen onRequestClose={onRequestClose}>
        <SetPartnerCodeForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'saveAs') {
    return (
      <BasicCRMModal captionId="justSaveSelection" isOpen onRequestClose={onRequestClose}>
        <SaveSelectionForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'createLetter') {
    return (
      <BasicCRMModal captionId="crmCreateALetter" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <CreaeteLetterForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'scheduleACall') {
    return (
      <BasicCRMModal captionId="crmScheduleACall" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <ScheduleACallFrom onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'addMonitoring') {
    return (
      <BasicCRMModal captionId="crmAddMonitoring" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <AddMonitoringForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'addContancts') {
    return (
      <BasicCRMModal captionId="crmAddUserContacts" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <AddContactsForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'editManagerCode') {
    return (
      <BasicCRMModal captionId="editManager" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <CRMEditUserManagerForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }
  if (modalType === 'editIBManagerCode') {
    return (
      <BasicCRMModal captionId="editManager" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <CRMEditUserIBManagerForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }

  if (modalType === 'editPartnerCode') {
    return (
      <BasicCRMModal captionId="editPartnerCode" isOpen alignTitleCenter onRequestClose={onRequestClose}>
        <CRMEditPartnerCodeForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }

  if (modalType === 'addTag') {
    return (
      <BasicCRMModal
        captionId="crmAddTag"
        isOpen
        alignTitleCenter
        onRequestClose={onRequestClose}
        className="overflowInitial">
        <CRMAddContactTagForm onRequestClose={onRequestClose} />
      </BasicCRMModal>
    );
  }

  return false;
};

CRMModals.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  modalType: PropTypes.string,
};

CRMModals.defaultProps = {
  modalType: null,
};
export default CRMModals;
