import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FastFilters {
    display: grid;
    gap: 12px;
    position: relative;
    background: #f8faff;
    position: relative;
    grid-template-columns: repeat(auto-fit, 211px);

    @include respond-to(xs) {
      display: flex;
      flex-direction: column;
    }

    &--Closed {
      height: 106px;
      grid-row-gap: 90px;
      overflow: hidden;
    }
    &__OpenMoreFiltersButton {
      position: absolute;
      height: max-content;
      width: max-content;
      color: #0f0f0f;
      right: 16px;
      top: 78px;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
      text-decoration-line: underline;
    }
    &__OpenMoreFiltersButtonOpen {
      top: initial;
      bottom: 8px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
