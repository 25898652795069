import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BaseButton } from '..';

import { staticStyles } from './style';

const UploadContactForm = ({ onSubmit }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const changeHandler = event => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmit = () => {
    onSubmit(selectedFile);
  };

  return (
    <div className="UploadContactForm">
      <div className="UploadContactForm__info">
        <FormattedMessage id="crmUploadFileFormat">{txt => `${txt}:`}</FormattedMessage>
        <FormattedMessage id="crmUploadContactHelpText">{txt => <div>{txt}</div>}</FormattedMessage>
      </div>

      <div className="UploadContactForm__file">
        {isSelected && (
          <div className="UploadContactForm__file-list">
            <div className="UploadContactForm__file-item" key={selectedFile.name}>
              <div className="UploadContactForm__file-item-icon" />
              <div className="UploadContactForm__file-item-name">{selectedFile.name}</div>
            </div>
          </div>
        )}

        <div className="UploadContactForm__file-text">
          <input
            name="files"
            className="UploadContactForm__file-input"
            type="file"
            accept=".csv"
            onChange={changeHandler}
          />
          <div>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.126 8.42795V14.99H6.392V8.42795H0V6.69395H6.392V0.199951H8.126V6.69395H14.518V8.42795H8.126Z"
                fill="#0AA1E2"
              />
            </svg>
          </div>
          <FormattedMessage id="filesDropForUplaodText">{txt => <span>{txt}</span>}</FormattedMessage>
        </div>
      </div>
      <FormattedMessage id="onlyCsvExt">
        {txt => <div className="UploadContactForm__file-info">{txt}</div>}
      </FormattedMessage>

      <div className="UploadContactForm__submit-wrap">
        <BaseButton
          type="button"
          className="UploadContactForm__submit"
          textId="justUpload"
          onClick={() => handleSubmit()}
        />
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UploadContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UploadContactForm;
