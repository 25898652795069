import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withTheme } from 'common/styling/theme';
import { compose } from 'redux';
import Chart from 'react-apexcharts';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { NoItemsPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getInvestYieldChart } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const YieldChart = ({ getInvestYieldChart, strategyId, investYieldChart, intl, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getInvestYieldChart({ strategyId });
  }, [getInvestYieldChart, strategyId]);

  const chartSeries =
    investYieldChart.map(item => ({ x: item.DT, y: Math.round(item.Yield * 100) || item.Yield * 100 })) ?? [];

  const options = {
    chart: {
      fontFamily: 'ubuntu-font, sans-serif',
      width: '100%',
      zoom: {
        enabled: false,
      },
      foreColor: '#C5C5C5',
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
    },
    colors: ['#43B6E8', '#C4C4C4'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    yaxis: {
      labels: {
        offsetX: -10,
      },
      decimalsInFloat: 2,
    },
    grid: {
      show: true,
    },
    plotOptions: {
      area: {
        fillTo: 'end',
      },
    },
    tooltip: {
      y: {
        formatter(value) {
          return `${value}%`;
        },
      },
    },
  };

  const series = [
    {
      name: intl.formatMessage({ id: 'justYield' }),
      data: chartSeries,
    },
  ];
  const rammYieldChartDescriptionNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'rammYieldChartDescriptionTixee'
      : 'rammYieldChartDescription';

  return (
    <div className="YieldChart">
      <div className="YieldChart__chart-wrapper">
        {investYieldChart.length ? (
          <Chart className="YieldChart__inner" options={options} series={series} type="area" height="300" />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noDataToDisplay" />
        )}
      </div>
      <FormattedMessage id={rammYieldChartDescriptionNew}>
        {txt => <div className="YieldChart__description">{txt}</div>}
      </FormattedMessage>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

YieldChart.propTypes = {
  getInvestYieldChart: PropTypes.func.isRequired,
  strategyId: PropTypes.number.isRequired,
  investYieldChart: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

YieldChart.defaultProps = {
  theme: {},
};

export default compose(
  injectIntl,
  withTheme(),
  connect(
    state => ({
      investYieldChart: state.client.invest.investYieldChart,
      investYieldChartIsLoaded: state.client.invest.investYieldChartIsLoaded,
    }),
    {
      getInvestYieldChart: data => getInvestYieldChart.request(data),
    }
  )
)(YieldChart);
export { YieldChart };
