import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_GROUPS_LIST = createRequestTypes('GET_GROUPS_LIST');
export const getGroupsList = {
  request: () => action(GET_GROUPS_LIST[REQUEST]),
  success: data => action(GET_GROUPS_LIST[SUCCESS], { data }),
  failure: error => action(GET_GROUPS_LIST[FAILURE], { error }),
};

export const GET_CONTRACT_SPECS = createRequestTypes('GET_CONTRACT_SPECS');
export const getContractSpecs = {
  request: data => action(GET_CONTRACT_SPECS[REQUEST], data),
  success: data => action(GET_CONTRACT_SPECS[SUCCESS], { data }),
  failure: error => action(GET_CONTRACT_SPECS[FAILURE], { error }),
};

export const GET_COMMENTS_LIST = createRequestTypes('GET_COMMENTS_LIST');
export const getCommentsList = {
  request: () => action(GET_COMMENTS_LIST[REQUEST]),
  success: data => action(GET_COMMENTS_LIST[SUCCESS], { data }),
  failure: error => action(GET_COMMENTS_LIST[FAILURE], { error }),
};
