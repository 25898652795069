import css from 'styled-jsx/css';
import { isRTL, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ReactDataTable__wrapper {
    .ReactDataTable {
      position: relative;
      min-width: 100%;
      border-spacing: 0;
      &__loader {
        padding: 10px;
        text-align: center;
      }

      tr:not(.ReactDataTable__expanded-row):not(.ReactDataTable__loading) {
        display: grid;
        border-collapse: collapse;
        min-width: 100%;
      }

      .ReactDataTable__arrow {
        margin-left: rem(8px);
        display: inline-block;
        padding: 3px;
        height: 7px;
      }

      .ReactDataTable__arrow--up {
        margin-top: 4px;
        transform: rotate(-135deg);
      }

      .ReactDataTable__arrow--down {
        margin-bottom: 2px;
        transform: rotate(45deg);
      }

      th,
      td {
        padding: rem(15px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      :global(.ReactDataTable__thead-caption),
      .ReactDataTable__tbody-cell {
        white-space: normal;
      }

      .ReactDataTable__thead-row {
        position: sticky;
        top: 0;
        text-align: left;
        text-transform: uppercase;
        cursor: pointer;
        margin: rem(2px 4px);
      }

      .ReactDataTable__tbody {
        position: relative;
      }

      .ReactDataTable__noData {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-template-columns: auto !important;

        td {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .ReactDataTable__noData-img {
          width: 60px;
          height: 60px;
          margin-bottom: rem(10px);
        }
      }

      .ReactDataTable__loading {
        z-index: 2;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ReactDataTable__tbody-row {
        position: relative;
        cursor: pointer;
        margin: rem(2px 4px);
      }

      .ReactDataTable__thead-cell {
        font-size: rem(11px);
        font-weight: normal;
        line-height: 1.5;
        text-align: ${isRTL ? 'right' : 'left'};
      }

      .ReactDataTable__tbody-cell {
        display: flex;
        align-items: center;
      }

      .ReactDataTable__tfoot-cell {
        display: flex;
        align-items: center;
      }

      .ReactDataTable__tfoot-cell {
        display: flex;
        align-items: center;
      }

      .ReactDataTable__expanded-row {
        > td {
          padding: 0;
        }
      }

      .ReactDataTable__tfoot {
        .TotalCell {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .subtext {
            margin-bottom: rem(10px);
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global`
    .ReactDataTable__wrapper {
      .ReactDataTable {
        tr {
          grid-template-columns: repeat(${columns.length}, minmax(100px, 1fr));
        }

        .ReactDataTable__thead-row {
          color: ${colors.gray.hex};
        }

        .ReactDataTable__noData {
          background-color: ${colors.white.hex};
          color: ${colors.gray.hex};
        }

        .ReactDataTable__loading {
          background-color: ${rgba([colors.white.rgb, 0.4])};
        }

        .ReactDataTable__tbody-row {
          background-color: ${colors.white.hex};
          transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          box-shadow: inset 0 0 0 0 transparent;
          border-radius: ${border.radius};

          &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }

          &:hover {
            z-index: 1;
            box-shadow: 0 4px 9px 4px ${rgba([colors.primary.rgb, 0.25])};
          }
        }

        .ReactDataTable__tbody-row--unclickable {
          cursor: unset;
          &:hover {
            box-shadow: none;
          }
        }

        .ReactDataTable__tfoot-row {
          background-color: ${colors.white.hex};
          border-top: 2px solid ${rgba([colors.gradientStart.rgb, 0.8])};
        }

        .ReactDataTable__expanded-row {
          grid-column: 1 / ${columns.length + 1};
        }

        .ReactDataTable__tfoot {
          .TotalCell {
            .subtext {
              font-weight: 400 !important;
              color: ${colors.gray.hex};
            }
          }
        }

        .ReactDataTable__arrow {
          border: solid ${colors.gray.hex};
          border-width: 0 1px 1px 0;
        }

        .ReactDataTable__arrow--active {
          border: solid ${colors.primary.hex};
          border-width: 0 1px 1px 0;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
