import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const TasksIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.845 1.375a.465.465 0 00-.47.467V7.27c0 .26.206.467.47.467h5.42c.268 0 .483-.23.47-.472V1.842a.465.465 0 00-.47-.467h-5.42zm-1.22.467c0-.676.544-1.217 1.22-1.217h5.42c.676 0 1.22.541 1.22 1.217v5.395c.029.69-.552 1.25-1.22 1.25h-5.42c-.676 0-1.22-.542-1.22-1.218V1.842z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.514 1.842c0-.676.544-1.217 1.22-1.217h5.42c.676 0 1.22.541 1.22 1.217v5.395c.029.69-.552 1.25-1.22 1.25h-5.42c-.676 0-1.22-.542-1.22-1.218V1.842z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.625 10.731c0-.676.544-1.217 1.22-1.217h5.42c.676 0 1.22.541 1.22 1.217v5.395c.029.69-.552 1.249-1.22 1.249h-5.42c-.676 0-1.22-.541-1.22-1.217V10.73z"
        fill="url(#paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.734 10.264a.465.465 0 00-.47.467v5.427c0 .26.206.467.47.467h5.42c.268 0 .483-.23.47-.472a.39.39 0 010-.018V10.73a.465.465 0 00-.47-.467h-5.42zm-1.22.467c0-.676.544-1.217 1.22-1.217h5.42c.676 0 1.22.541 1.22 1.217v5.395c.029.69-.552 1.249-1.22 1.249h-5.42c-.676 0-1.22-.541-1.22-1.217V10.73z"
        fill="url(#paint3_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.576 11.381c.158.134.177.371.043.529l-2.268 2.666a.375.375 0 01-.561.012l-1.288-1.396a.375.375 0 11.551-.508l1 1.084 1.995-2.344a.375.375 0 01.528-.043zM15.465 2.492c.158.134.177.371.043.529L13.24 5.687a.375.375 0 01-.562.012l-1.287-1.396a.375.375 0 01.551-.508l1 1.084 1.995-2.344a.375.375 0 01.528-.043z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.572"
          y1="18.616"
          x2="19.297"
          y2="13.895"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="3.572"
          y1="18.616"
          x2="19.297"
          y2="13.895"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="3.572"
          y1="18.616"
          x2="19.297"
          y2="13.895"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="3.572"
          y1="18.616"
          x2="19.297"
          y2="13.895"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

TasksIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

TasksIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedTasksIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(TasksIconActive);

export default ThemedTasksIconActive;
