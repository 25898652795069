import css from 'styled-jsx/css'

const staticStyles = css`
  .RequestResetPasswordForm {
    text-align: left;
  }

  .RequestResetPasswordForm__button-inner {
    text-align: center;
  }
`

export { staticStyles }
