import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .DateCell {
    &--nowrap {
      white-space: nowrap;
    }

    &--inLine {
      display: flex;
      gap: 5px;
    }

    &__time {
      display: block !important;
      color: #777777;
      font-weight: 300;
    }
    &--red {
      color: #ff4058;
      span {
        color: #ff4058;
      }
    }
    &--grey {
      color: #999999;
      span {
        color: #999999;
      }
    }
  }
`;

export default staticStyles;
