import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { DefaultButton, LoadingIcon } from '../../_components';

import { wsConfirmEmail, wsSetAuthorized } from '../../_redux';

import { staticStyles } from './style';

const EmailConfirmationPage = ({
  match,
  emailConfirmation,
  emailConfirmationError,
  emailConfirmationCriticalError,
  confirmEmail,
  setAuthorized,
  intl,
}) => {
  const history = useHistory();

  useEffect(() => {
    confirmEmail(match.params.token);
  }, [confirmEmail, match.params.token]);

  let emailConfirmationProgress;

  if (emailConfirmation) {
    emailConfirmationProgress = (
      <>
        <FormattedMessage id="confirmEmailModalBody" />
        <DefaultButton
          textId="justOk"
          filled
          onClick={() => {
            setAuthorized();
            history.push('/welcome-setup/steps');
          }}
        />
      </>
    );
  } else if (!emailConfirmation && emailConfirmationError) {
    emailConfirmationProgress = (
      <>
        <strong className="formError">{emailConfirmationError}</strong>
        <DefaultButton textId="justBackToLogin" filled onClick={() => history.push('/welcome-setup/login')} />
      </>
    );
  } else if (emailConfirmationCriticalError) {
    emailConfirmationProgress = (
      <>
        <FormattedMessage id="welcomeSetupEmailConfirmationCriticalError">
          {txt => <strong className="formError">{txt}</strong>}
        </FormattedMessage>
        <DefaultButton textId="justOk" filled onClick={() => history.push('/welcome-setup/login')} />
      </>
    );
  } else {
    emailConfirmationProgress = (
      <>
        <div className="EmailConfirmationPage__icon">
          <LoadingIcon />
        </div>
        <FormattedMessage id="emailConfirmationInProgress" />
      </>
    );
  }

  return (
    <div className="EmailConfirmationPage">
      <Helmet>
        <title>{intl.formatMessage({ id: 'welcomeSetupEmailConfirmation' })}</title>
      </Helmet>
      <div className="EmailConfirmationPage__inner">
        <FormattedMessage id="confirmEmailModalHeader">{txt => <h3>{txt}</h3>}</FormattedMessage>
        {emailConfirmationProgress}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

EmailConfirmationPage.propTypes = {
  match: PropTypes.object.isRequired,
  emailConfirmation: PropTypes.number.isRequired,
  emailConfirmationError: PropTypes.string,
  emailConfirmationCriticalError: PropTypes.string.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  setAuthorized: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

EmailConfirmationPage.defaultProps = {
  emailConfirmationError: '',
};

export default compose(
  injectIntl,
  connect(
    state => ({
      emailConfirmation: state.welcomeSetup.emailConfirmation,
      emailConfirmationError: state.welcomeSetup.emailConfirmationError,
      emailConfirmationCriticalError: state.welcomeSetup.emailConfirmationCriticalError,
    }),
    {
      confirmEmail: code => wsConfirmEmail.request(code),
      setAuthorized: () => wsSetAuthorized(),
    }
  )
)(EmailConfirmationPage);
export { EmailConfirmationPage };
