import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, ReduxSelectField } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { deleteCampaign } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const DeleteCampaignForm = ({
  campaignList,
  defaultCampaign,
  campaignId,
  params,
  handleSubmit,
  toggleModal,
  dispatch,
  submitting,
  invalid,
  theme,
  error,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const submit = handleSubmit(values => deleteCampaign({ campaignId, params, ...values }, dispatch));
  const campaigns = campaignList
    ? campaignList.filter(item => item.id !== campaignId).map(item => ({ value: item.id, label: item.title }))
    : [];

  return (
    <form className="DeleteCampaignForm" onSubmit={submit}>
      {defaultCampaign && defaultCampaign.id === campaignId && !!campaigns.length ? (
        <>
          <FormattedMessage id="deleteDefaultCampaignDescription">
            {txt => <span className="DeleteCampaignForm__description">{txt}</span>}
          </FormattedMessage>
          <Field
            component={ReduxSelectField}
            name="defaultId"
            textId="campaignName"
            options={campaigns}
            disabled={submitting}
            validate={[required()]}
          />
        </>
      ) : (
        <FormattedMessage id="confirmDeleteCampaign">
          {txt => <span className="DeleteCampaignForm__description">{txt}</span>}
        </FormattedMessage>
      )}
      <div className="DeleteCampaignForm__buttons">
        <DefaultButton
          textId="justProceed"
          type="submit"
          loading={submitting}
          disabled={invalid || submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={toggleModal} disabled={submitting} />
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

DeleteCampaignForm.propTypes = {
  campaignList: PropTypes.array.isRequired,
  campaignId: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  defaultCampaign: PropTypes.object,
  error: PropTypes.string,
  theme: PropTypes.object,
};

DeleteCampaignForm.defaultProps = {
  defaultCampaign: null,
  error: '',
  theme: {},
};

export default reduxForm({
  form: 'DeleteCampaignForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(withTheme()(DeleteCampaignForm));
export { DeleteCampaignForm };
