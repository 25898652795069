import { put, call, fork, take } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { acceptError } from 'common/_redux';
import { sendYandexMetrikaEvent } from 'common/utils';

import * as api from '../api';
import * as ActionTypes from './actions';

const AUTH_TYPES = {
  default: 'default',
  telegram: 'telegram',
};

export function* registrationSaga({ email, isPartnerCodeCustomlyHidden, partnerCode, ...values }) {
  try {
    const { status, data } = yield call(api.registration, { email: email.toLowerCase(), partnerCode, ...values });

    if (status < 300) {
      yield put(ActionTypes.registration.success());
      const EVENT_NAME = 'Registration';
      sendYandexMetrikaEvent(EVENT_NAME);

      if (data) {
        localStorage.setItem('jwtToken', data.token);
      }
    } else if (status === 409 && data.partnerCode && isPartnerCodeCustomlyHidden) {
      yield call(registrationSaga, { email, ...values });
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.registration.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.registration.failure());
  }
}

export function* registrationWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.registration.REQUEST);
    yield call(registrationSaga, payload);
  }
}

export function* confirmEmailSaga(code) {
  try {
    const { status, data } = yield call(api.confirmEmail, code);
    if (status < 300) {
      yield put(ActionTypes.confirmEmail.success(data));
      localStorage.setItem('jwtToken', data.token);
      const EVENT_NAME = 'Confirmed email';
      sendYandexMetrikaEvent(EVENT_NAME);
    } else {
      yield put(ActionTypes.confirmEmail.failure(data[0]));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.confirmEmail.failure());
    throw e;
  }
}

export function* confirmEmailWatcherSaga() {
  while (true) {
    const { code } = yield take(ActionTypes.CONFIRM_EMAIL.REQUEST);
    yield call(confirmEmailSaga, code);
  }
}

export function* getOTPTokenFromRegSaga(payload, authType) {
  try {
    const { status, data } = yield call(api.getOTPToken, payload);

    if (status < 300) {
      localStorage.setItem('otpToken', data.otpToken);
      yield put(ActionTypes.getOTPTokenFromReg.success(data.nextAction));
      return { nextAction: data.nextAction };
    }

    let formError;

    if (payload.brokerToken) formError = data.brokerToken;
    else if (payload.oneoffToken) formError = data;
    else {
      switch (authType) {
        case AUTH_TYPES.telegram:
          formError = data;
          break;
        case AUTH_TYPES.default:
        default:
          formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
          break;
      }
    }

    yield put(ActionTypes.getOTPTokenFromReg.failure(formError));
    return { formError };
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getOTPTokenFromReg.failure());
    throw e;
  }
}

export function* getJWTTokenFromRegSaga(payload) {
  try {
    const { status, data } = yield call(api.getJWTToken, payload);

    if (status < 300) {
      localStorage.setItem('jwtToken', data.token);
      localStorage.removeItem('otpToken');
      yield put(ActionTypes.getJWTTokenFromReg.success());
      return 1;
    }
    if (status === 401) {
      yield put(ActionTypes.getJWTTokenFromReg.failure());
      return 0;
    }
    const formError = new SubmissionError(status === 429 ? { _error: data } : data);
    yield put(ActionTypes.getJWTTokenFromReg.failure(formError));
    return 0;
  } catch (e) {
    localStorage.removeItem('otpToken');
    yield put(acceptError(e, true));
    yield put(ActionTypes.getJWTTokenFromReg.failure());
    throw e;
  }
}

export function* loginWithTelegramFromRegSaga(telegramData) {
  try {
    const { nextAction, formError } = yield call(getOTPTokenFromRegSaga, telegramData, AUTH_TYPES.telegram);

    if (nextAction) {
      if (nextAction === 'login') {
        yield call(getJWTTokenFromRegSaga);
      }
      sessionStorage.removeItem('telegramData');
      yield put(ActionTypes.loginWithTelegramFromReg.success(nextAction === 'login' ? 'success' : nextAction));
    } else {
      sessionStorage.setItem('telegramData', JSON.stringify(telegramData));
      yield put(ActionTypes.loginWithTelegramFromReg.failure(formError));
    }
  } catch (e) {
    yield put(ActionTypes.loginWithTelegramFromReg.failure());
    throw e;
  }
}

export function* loginWithTelegramFromRegWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.LOGIN_WITH_TELEGRAM_FROM_REG.REQUEST);
    yield call(loginWithTelegramFromRegSaga, data);
  }
}

export default [
  fork(registrationWatcherSaga),
  fork(confirmEmailWatcherSaga),
  fork(loginWithTelegramFromRegWatcherSaga),
];
