import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { staticStyles } from './style';

const TelegramAuthWidget = ({ handleTelegramAuth }) => {
  /* eslint-disable */
  const onTelegramAuth = ({id, first_name, last_name, username, photo_url, auth_date, hash}) => {
    const telegramData = {
      telegramId: id ?? null,
      telegramFirstName: first_name ?? null,
      telegramLastName: last_name ?? null,
      telegramUserName: username ?? null,
      telegramPhotoUrl: photo_url ?? null,
      telegramAuthDate: auth_date ?? null,
      telegramHash: hash ?? null,
    };

    handleTelegramAuth(telegramData);
  };
  /* eslint-enable */
  /* eslint-disable */
  useEffect(() => {
    const telegramWidgetSrc = localStorage.getItem('telegramWidgetSrc');
    window.TelegramLoginWidget = {
      callbackOnAuth: onTelegramAuth,
    };
    const script = document.createElement('script');
    const telegramAuthWrapper = document.getElementById('telegramAuthWrapper');
    try {
      if (telegramWidgetSrc !== undefined && telegramWidgetSrc !== null && telegramWidgetSrc !== '') {
        script.src = telegramWidgetSrc?.match(/\"([^\"]*?[^\"]*?)\"/gm)[0].replace('"', "").replace("'", "");
        const botName = telegramWidgetSrc?.match(/\"([^\"]*?[^\"]*?)\"/gm)[1].replaceAll('\"', "").replaceAll("\'", "")
        script.setAttribute('data-telegram-login', botName);
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-onauth', 'TelegramLoginWidget.callbackOnAuth(user)');
        script.setAttribute('data-request-access', 'write');
        script.setAttribute('data-radius', '4');
        script.async = true;
      }
    }
    catch(err) {
    }
    telegramAuthWrapper.appendChild(script);

    return () => {
      telegramAuthWrapper.removeChild(script);
      delete window.TelegramLoginWidget;
    };

  }, []);
  /* eslint-disable */
  return (
    <>
      <div id="telegramAuthWrapper" />
      <style jsx>{staticStyles}</style>
    </>
  );
};

TelegramAuthWidget.propTypes = {
  handleTelegramAuth: PropTypes.func.isRequired,
};

export default TelegramAuthWidget;
