import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { ReduxTextField, DefaultButton, MoneyCell, ReduxSelectField } from 'common/components';

import { setInvestmentsDeposit } from 'client/invest/_redux';

import staticStyles from './style';

const InvestmentDepositForm = ({
  accounts,
  investmentId,
  strategyAccountId,
  strategyCurrency,
  isWallet,
  walletBalance,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState('');
  const [selectedAccountAmount, setSelectedAccountAmount] = useState(0);
  const [exchangeCoefficient, setExchangeCoefficient] = useState(1);
  const [amountToInvest, setAmountToInvest] = useState(null);

  useEffect(() => {
    if (isWallet) {
      axios
        .get('/internal_transfers/transfer/invest_from_wallet/', {
          params: { recipientAccount: strategyAccountId },
        })
        .then(({ data }) => setExchangeCoefficient(data?.details?.exchangeCoefficient || 1));
    }
  }, []);

  const submit = handleSubmit(values => setInvestmentsDeposit({ investmentId, action: 'fund', ...values }, dispatch));

  const handleChangeAccount = value => {
    const selectedAccount = accounts.find(account => account.id === value);
    setSelectedAccountCurrency(selectedAccount.balance.currency);
    setSelectedAccountAmount(Number(selectedAccount.balance.amount));
    axios
      .get('/internal_transfers/transfer/invest/', {
        params: { senderAccount: selectedAccount.id, recipientAccount: strategyAccountId },
      })
      .then(({ data }) => setExchangeCoefficient(data?.details?.exchangeCoefficient || 1));
  };

  return (
    <div className="InvestmentDepositForm__content">
      <form className="InvestmentDepositForm" onSubmit={submit}>
        <div className="InvestmentDepositForm__inputs-inner">
          <Field
            component={ReduxTextField}
            type="text"
            name="strategy"
            textId="justStrategy"
            validate={[required()]}
            disabled
          />

          {!isWallet && (
            <Field
              component={ReduxSelectField}
              name="account"
              maxMenuHeight={140}
              textId="justAccount"
              options={accounts.map(account => ({
                value: account.id,
                label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
              }))}
              disabled={submitting}
              validate={[required()]}
              onChange={handleChangeAccount}
              searchable
            />
          )}

          <Field
            component={ReduxTextField}
            type="number"
            name="amount"
            textId="justAmount"
            validate={[numericality({ '>=': 0, '<=': isWallet ? walletBalance : selectedAccountAmount }), required()]}
            disabled={submitting}
            onChange={newValue => setAmountToInvest(Number(newValue))}
            description={isWallet ? 'USD' : selectedAccountCurrency}
          />
        </div>

        {isWallet && (
          <div className="InvestmentDepositForm__desc">
            <FormattedMessage id="rammAccountAmount" />
            <MoneyCell value={{ amount: walletBalance, currency: 'USD' }} />
          </div>
        )}

        {typeof amountToInvest === 'number' && amountToInvest !== 0 && (
          <div className="InvestmentDepositForm__desc">
            <FormattedMessage
              id={
                document.location.pathname === 'portal.motforex.com'
                  ? 'clinetYouWillInvestAroundMot'
                  : 'clinetYouWillInvestAround'
              }
            />
            <MoneyCell value={{ amount: amountToInvest * exchangeCoefficient, currency: strategyCurrency }} />
          </div>
        )}
        <div className="InvestmentDepositForm__btns">
          <DefaultButton
            textId="justSubmit"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
        {error && <strong className="formError">{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestmentDepositForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  isWallet: PropTypes.bool.isRequired,
  investmentId: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  strategyAccountId: PropTypes.number.isRequired,
  strategyCurrency: PropTypes.string.isRequired,
  walletBalance: PropTypes.string,
  error: PropTypes.string,
};

InvestmentDepositForm.defaultProps = {
  walletBalance: '0',
  error: '',
};

export default reduxForm({
  form: 'InvestmentDepositForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(InvestmentDepositForm);
