import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LoopIcon from 'common/images/loupePlus.svg';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const LightBoxImage = ({ className, key, openLightBox, titleId, image, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div
      role="presentation"
      className={classNames('LightBoxImage', className)}
      onClick={openLightBox}
      onKeyPress={openLightBox}
      key={key || false}>
      <div className="LightBoxImage__inner">
        <img border="0" src={image} alt={titleId} loading="lazy" />
        <div className="LightBoxImage__hover">
          <img border="0" className="LightBoxImage__loop-icon" src={LoopIcon} alt={titleId} />
        </div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

LightBoxImage.propTypes = {
  className: PropTypes.string.isRequired,
  key: PropTypes.number,
  openLightBox: PropTypes.func.isRequired,
  titleId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

LightBoxImage.defaultProps = {
  key: null,
  theme: {},
};

export default withTheme()(LightBoxImage);
export { LightBoxImage };
