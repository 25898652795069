import React from 'react';
import css from 'styled-jsx/css';

const staticStyle = css`
  .EditTaskIcon {
    border-radius: 8px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
    cursor: pointer;
    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .EditTaskIcon:hover {
    background-color: #0aa1e2;
    cursor: pointer;
  }
`;

const EditTaskIcon = () => (
  <div className="EditTaskIcon">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#0AA1E2" />
      <path
        d="M19.8346 10.0683C20.092 9.81102 20.5081 9.81102 20.7627 10.0683L21.9289 11.2346C22.1862 11.4919 22.1862 11.908 21.9289 12.1626L20.4314 13.6656L18.3344 11.5686L19.8346 10.0683ZM17.7157 12.1873L19.8127 14.2843L13.8174 20.2796V19.9347C13.8174 19.6938 13.6203 19.4967 13.3794 19.4967H12.5033V18.6206C12.5033 18.3797 12.3062 18.1826 12.0653 18.1826H11.7204L17.7157 12.1873ZM10.8307 19.3735C10.8635 19.264 10.9046 19.1572 10.9593 19.0586H11.6273V19.9347C11.6273 20.1756 11.8244 20.3727 12.0653 20.3727H12.9414V21.0407C12.8401 21.0927 12.736 21.1365 12.6238 21.1693L10.0833 21.9167L10.8307 19.3762V19.3735ZM21.3841 9.44965C20.7846 8.85012 19.8155 8.85012 19.2159 9.44965L10.6527 18.0101C10.3406 18.3222 10.1134 18.7055 9.99022 19.1271L9.01837 22.4396C8.97183 22.5929 9.01564 22.7599 9.12788 22.8721C9.24012 22.9844 9.40711 23.0282 9.56042 22.9816L12.8729 22.007C13.2945 21.8838 13.6778 21.6539 13.9899 21.3445L22.5503 12.7841C23.1499 12.1845 23.1499 11.2154 22.5503 10.6159L21.3841 9.44965ZM18.0689 14.5334C18.2386 14.3636 18.2386 14.0844 18.0689 13.9147C17.8992 13.745 17.6199 13.745 17.4502 13.9147L13.9461 17.4188C13.7763 17.5885 13.7763 17.8678 13.9461 18.0375C14.1158 18.2072 14.395 18.2072 14.5648 18.0375L18.0689 14.5334Z"
        fill="#333333"
      />
    </svg>
    <style jsx>{staticStyle}</style>
  </div>
);

export default EditTaskIcon;
