import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .CreateAccountForm {
    min-width: 450px;
    max-width: 500px;

    .formError {
      display: none;
    }

    @media only screen and (max-height: 420px) {
      padding-bottom: 30px;
    }

    @include respond-to(sm) {
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

export { staticStyles };
