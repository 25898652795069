import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormMessage } from '../components';

const FormMessageContext = React.createContext();

const FormMessageHOC = Wrapped => props =>
  (
    <FormMessageContext.Consumer>
      {({ showFormSuccessMessage, showFormFailMessage }) => (
        <Wrapped {...props} showFormSuccessMessage={showFormSuccessMessage} showFormFailMessage={showFormFailMessage} />
      )}
    </FormMessageContext.Consumer>
  );

const FormMessageProvider = ({ children }) => {
  const [isShowFormSuccessMessage, setIsShowFormSuccessMessage] = useState(false);
  const [isShowFormFailMessage, setIsShowFormFailMessage] = useState(false);
  const [successMessageTextId, setSuccessMessageTextId] = useState('formSuccessMessageText');
  const [failMessageTextId, setFailMessageTextId] = useState('formFailMessageText');
  const [currentTimeout, setCurrentTimeout] = useState(null);
  useEffect(() => () => clearTimeout(currentTimeout), []);

  const showFormMessagesFunctions = useMemo(() => {
    const showFormSuccessMessage = (message = 'formSuccessMessageText') => {
      setIsShowFormSuccessMessage(true);
      setSuccessMessageTextId(message);
      const timeout = setTimeout(() => setIsShowFormSuccessMessage(false), 4000);
      setCurrentTimeout(timeout);
    };

    const showFormFailMessage = (message = 'formFailMessageText') => {
      setIsShowFormFailMessage(true);
      setFailMessageTextId(message);
      const timeout = setTimeout(() => setIsShowFormFailMessage(false), 4000);
      setCurrentTimeout(timeout);
    };
    return { showFormSuccessMessage, showFormFailMessage };
  }, []);

  return (
    <FormMessageContext.Provider value={showFormMessagesFunctions}>
      {children}
      <FormMessage messageTextId={successMessageTextId} showMessage={isShowFormSuccessMessage} success />
      <FormMessage messageTextId={failMessageTextId} showMessage={isShowFormFailMessage} fail />
    </FormMessageContext.Provider>
  );
};

FormMessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FormMessageContext, FormMessageHOC, FormMessageProvider };
