import React from 'react';

const IconCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
    <path
      d="M13.8524 0.396711C14.0492 0.59045 14.0492 0.909182 13.8524 1.10292L5.35283 9.60245C5.15909 9.79931 4.84035 9.79931 4.64662 9.60245L0.146429 5.1027C-0.0488098 4.90896 -0.0488098 4.59023 0.146429 4.39649C0.341543 4.19962 0.658088 4.19962 0.85339 4.39649L4.99972 8.54313L13.1461 0.396711C13.3399 0.201096 13.6586 0.201096 13.8524 0.396711Z"
      fill="white"
    />
  </svg>
);

export default IconCheck;
