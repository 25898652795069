import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { FormMessageHOC } from 'common/HOCs';
import { required } from 'redux-form-validators';
import { CheckboxField } from 'common/components';
import { FormattedMessage } from 'react-intl';
import BaseButton from 'crm/_components/BaseButton';
import { crmSetUserManager } from 'crm/users/_redux/actions';
import { getManagerList } from 'crm/users/api';
import BaseAsyncSelectField from '../../fields/BaseAsyncSelectField';
import TextFormField from '../../fields/TextFormField';

import { staticStyles } from './style';

const CRMEditUserManagerForm = ({ handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const { id } = useParams();
  const profile = useSelector(state => state.user.profile);
  const submit = handleSubmit(values => {
    let redirect = null;
    if (!profile.isAdmin && profile.perms?.setManagerPerm && !profile.perms?.managerViewAllCRMContactsPerm) {
      redirect = '/crm/contacts/';
    }
    return crmSetUserManager({ ...values, userId: id, redirect }, dispatch);
  });

  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    getManagerList('')
      .then(({ data }) =>
        setDefaultOptions(
          data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
        )
      )
      .catch(() => console.log('ERROR'));
  }, []);

  const loadOptions = search =>
    getManagerList(search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    );

  return (
    <form className="CRMEditUserManagerForm" onSubmit={submit}>
      <FormattedMessage id="editManagerDescription">
        {txt => <p className="CRMEditUserManagerForm__description">{txt}</p>}
      </FormattedMessage>
      <div className="CRMEditUserManagerForm__inputs-inner">
        <Field
          defaultOptions={defaultOptions}
          textId="justManager"
          labelTextId="justManager"
          removeArrow={!defaultOptions.length}
          component={BaseAsyncSelectField}
          searchPromptTextId="Select manager"
          name="manager"
          loadOptions={loadOptions}
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={150}
        />
        <Field name="reason" component={TextFormField} labelTextId="justReason" validate={[required()]} />
        <Field name="includeClients" component={CheckboxField} textId="crmAssignManagerToIbIfAny" type="checkbox" />
      </div>

      <BaseButton type="submit" textId="justChange" maxContent disabled={invalid || pristine || submitting} />

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

CRMEditUserManagerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CRMEditUserManagerForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-edit-user-manager',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
    onSubmitFail(result, dispatch, error, props) {
      props.showFormFailMessage(props.failMessageTextId);
      props.onRequestClose();
    },
  })
)(CRMEditUserManagerForm);
export { CRMEditUserManagerForm };
