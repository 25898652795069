import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';

import { NoItemsPanel, PageContentPreloader, SearchPanel, PaymentsTable } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';

import { getPaymentsList } from '../../_redux';

const PaymentsListPage = ({ paymentsList, getPaymentsList, loading, intl }) => {
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(query => getPaymentsList({ search: query }), [getPaymentsList]);

  useEffect(() => {
    getList(query);
  }, [getList, query]);

  const handleRowClick = id => history.push(`/payments/dw/${id}`);

  const filters = [
    {
      title: 'justStatus',
      accessor: '_status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'money_waiting',
          label: intl.formatMessage({ id: 'status_money_waiting' }),
        },
        {
          value: 'waiting_confirmation',
          label: intl.formatMessage({ id: 'status_waiting_confirmation' }),
        },
        {
          value: 'rejected',
          label: intl.formatMessage({ id: 'status_rejected' }),
        },
        {
          value: 'archived',
          label: intl.formatMessage({ id: 'status_archived' }),
        },
        {
          value: 'canceled',
          label: intl.formatMessage({ id: 'status_canceled' }),
        },
        {
          value: 'failed',
          label: intl.formatMessage({ id: 'status_failed' }),
        },
        {
          value: 'processing',
          label: intl.formatMessage({ id: 'status_processing' }),
        },
        {
          value: 'received',
          label: intl.formatMessage({ id: 'status_received' }),
        },
        {
          value: 'payout',
          label: intl.formatMessage({ id: 'status_payout' }),
        },
        { value: 'done', label: intl.formatMessage({ id: 'status_done' }) },
        {
          value: 'deposited',
          label: intl.formatMessage({ id: 'status_deposited' }),
        },
      ],
    },
    {
      title: 'justType',
      accessor: 'operation',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'deposit',
          label: intl.formatMessage({ id: 'justDeposit' }),
        },
        {
          value: 'withdraw',
          label: intl.formatMessage({ id: 'justWithdraw' }),
        },
      ],
    },
  ];

  return (
    <PageContentPreloader ready={loading} firstLaunchOnly type="smallLines">
      {!paymentsList.count && !Object.keys(query).length ? (
        ''
      ) : (
        <SearchPanel
          location={location}
          history={history}
          getList={getPaymentsList}
          filters={filters}
          params={{ search: query }}
        />
      )}
      {paymentsList.count ? (
        <PaymentsTable
          pageLink="payments/dw"
          data={paymentsList}
          loading={!loading}
          onRowClick={handleRowClick}
          getList={getPaymentsList}
          location={location}
          history={history}
          params={{ search: query }}
        />
      ) : (
        <NoItemsPanel
          icon={noPaymentsImage}
          captionSlug={Object.keys(query).length ? 'noPaymentsMatchingTheParameters' : 'noPaymentsYet'}
          descriptionSlug={!Object.keys(query).length ? 'noPaymentsYetDescr' : ''}
        />
      )}
    </PageContentPreloader>
  );
};

PaymentsListPage.propTypes = {
  paymentsList: PropTypes.object.isRequired,
  getPaymentsList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    paymentsList: state.client.payments.paymentsList,
    loading: state.client.payments.loadingPaymentsList,
  }),
  { getPaymentsList: search => getPaymentsList.request(search) }
)(injectIntl(PaymentsListPage));
export { PaymentsListPage };
