import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .ClientsBalanceTab {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    @include respond-to(xl) {
      flex-direction: column;
      justify-content: flex-start;
    }
    &__Item {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 5px;
    }
    &__Item-container {
      display: flex;
      flex-direction: column;
    }
    &__Item-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400 !important;
    }
    &__Item-number-cell-and-Tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      :global(.MoneyCell__inner) {
        margin-right: 5px;
        height: 2rem;
      }
    }
    &__Item-number-cell {
      font-size: 1.06667rem !important;
      margin-right: 5px;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .ClientsBalanceTab {
    }
  `;
};

export { staticStyles, getDynamicStyles };
