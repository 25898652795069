import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';

import {
  PageLayout,
  PageTabs,
  TranslatedNavLink,
  Calendar,
  PageContentPreloader,
  NoItemsPanel,
} from 'common/components';
import noOrdersImage from 'common/images/noOrders.svg';
import { withTheme } from 'common/styling/theme';
import { getFullPartnershipClientAccount, getPartnershipClientTrades } from '../../_redux';

import AccountInfoPanel from '../../../_components/AccountInfoPanel';
import TradeOperationsTable from '../../../_components/TradeOperationsTable';

import { staticStyles, getDynamicStyles } from './style';

const startDate = moment().add(-1, 'day').startOf('day').unix();

const endDate = moment().add(1, 'day').startOf('day').unix();

const startCalendar = moment().add(-1, 'day').startOf('day');

const endCalendar = moment().add(1, 'day').startOf('day');

const PartnershipClientAccountPage = ({
  account,
  operations,
  operationsInitialized,
  getFullAccount,
  getTrades,
  partnershipClientAccountsIsLoaded,
  isMt5Account,
  isCfhAccount,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const match = useRouteMatch();
  const { id, accountId } = match.params;
  const openOrdersSupported = !isMt5Account && !isCfhAccount;

  const OPERATION_STATUSES_BY_SLUG = {
    cfh: 'closed',
    mt5: 'closed',
  };

  const getOperationStatus = useCallback(
    slug => match.params.operationStatus ?? OPERATION_STATUSES_BY_SLUG[slug],
    [OPERATION_STATUSES_BY_SLUG, match.params.operationStatus]
  );

  const convertedPendingStatus = isMt5Account ? 'deferred' : 'delayed';

  const operationStatus = useMemo(() => {
    const status = getOperationStatus(account.platformSlug);

    return status === 'pending' ? convertedPendingStatus : status;
  }, [getOperationStatus, account.platformSlug, convertedPendingStatus]);

  const memoizedOnChangeParams = useMemo(
    () => ({
      clientId: id,
      accountId: Number(accountId),
      operationStatus,
    }),
    [id, accountId, operationStatus]
  );

  useEffect(() => {
    getFullAccount(id, Number(accountId));
  }, [getFullAccount]);

  useEffect(() => {
    if (partnershipClientAccountsIsLoaded) getTrades(id, Number(accountId), operationStatus, startDate, endDate);
  }, [getTrades, id, Number(accountId), operationStatus, partnershipClientAccountsIsLoaded]);

  return (
    <PageLayout
      captionSlug="partnershipClientAccount"
      extraCaptionText={account && account.login ? ` #${account.login}` : ''}>
      <PageContentPreloader ready={partnershipClientAccountsIsLoaded}>
        {!!account.id && <AccountInfoPanel {...account} partnershipAccount />}
      </PageContentPreloader>
      <div className="PartnershipClientAccountPage__top-line">
        <PageTabs>
          {openOrdersSupported && (
            <TranslatedNavLink id="justOpen" to={`/partnership/clients/user/${id}/account/${accountId}/`} />
          )}

          <TranslatedNavLink
            id={openOrdersSupported ? 'justClosed' : 'justDeals'}
            to={`/partnership/clients/user/${id}/account/${accountId}/${openOrdersSupported ? 'closed' : ''}`}
          />

          <TranslatedNavLink id="justPending" to={`/partnership/clients/user/${id}/account/${accountId}/pending`} />
        </PageTabs>
        {operationStatus !== 'delayed' && (
          <div className="PartnershipClientAccountPage__date">
            <Calendar
              daysLimit={31}
              onChangeFunc={getTrades}
              onChangeParams={memoizedOnChangeParams}
              defaultStartDate={startCalendar}
              defaultEndDate={endCalendar}
            />
          </div>
        )}
      </div>
      <div className="PartnershipClientAccountPage__table">
        <PageContentPreloader ready={operationsInitialized} type="smallLines">
          {Object.keys(operations).length ? (
            <TradeOperationsTable
              data={operations.trades}
              summary={operations.summary}
              operationStatus={operationStatus}
            />
          ) : (
            <NoItemsPanel icon={noOrdersImage} captionSlug="partnershipClientHasNoOrdersYet" />
          )}
        </PageContentPreloader>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

PartnershipClientAccountPage.propTypes = {
  account: PropTypes.object.isRequired,
  operations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  operationsInitialized: PropTypes.bool.isRequired,
  getFullAccount: PropTypes.func.isRequired,
  getTrades: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  partnershipClientAccountsIsLoaded: PropTypes.bool.isRequired,
  isMt5Account: PropTypes.bool.isRequired,
  isCfhAccount: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

PartnershipClientAccountPage.defaultProps = {
  operations: [],
  theme: {},
};

export default withTheme()(
  connect(
    (state, { match: { params } }) => {
      const account =
        state.client.partnership.partnershipClientAccounts.find(account => account.id === Number(params.accountId)) ||
        {};
      const isMt5Account = account.platformSlug === 'mt5';
      const isCfhAccount = account.platformSlug === 'cfh';
      const initialOperationsStatus = isMt5Account || isCfhAccount ? 'closed' : 'open';

      const convertedPendingStatus = isMt5Account ? 'deferred' : 'delayed';
      const operationStatus = params.operationStatus === 'pending' ? convertedPendingStatus : params.operationStatus;
      const operations = account?.operations ? account.operations[operationStatus || initialOperationsStatus] : [];

      return {
        account,
        operations,
        operationsInitialized: !!operations,
        partnershipClientAccountsIsLoaded: state.client.partnership.partnershipClientAccountsIsLoaded,
        isMt5Account,
        isCfhAccount,
      };
    },
    {
      getFullAccount: (clientId, accountId) => getFullPartnershipClientAccount.request(clientId, accountId),
      getTrades: (clientId, accountId, operationStatus, startDate, endDate) =>
        getPartnershipClientTrades.request(clientId, accountId, operationStatus, startDate, endDate),
    }
  )(PartnershipClientAccountPage)
);
export { PartnershipClientAccountPage };
