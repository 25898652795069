import axios from 'axios';

export const getAllBonuses = () => axios.get(`/bonuses/info/list/`);

export const getUserBonuses = search => axios.get(`/bonuses/record/list/`, { params: { ...search } });

export const getPerBonuses = search => axios.get(`/bonuses/subscription/list/`, { params: { ...search } });

export const rejectBonus = (id, data) =>
  axios.post(
    `/bonuses/record/${id}/cancel/`,
    { ...data },
    { validateStatus: status => status < 300 || status === 409 }
  );

export const rejectPerBonus = id => axios.post(`/bonuses/subscription/${id}/cancel/`);

export const activateBonus = (id, data) => axios.post(`/bonuses/${id}/activate/`, { ...data });
