import * as ActionTypes from './actions';

export const initialState = {
  partnerCodeIsOpen: false,
  promoCodeIsOpen: false,
  emailConfirmation: null,
  emailConfirmationError: '',
  confirmEmailModalIsOpen: false,
  telegramAuthError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_PARTNER_CODE:
      return { ...state, partnerCodeIsOpen: !state.partnerCodeIsOpen };

    case ActionTypes.TOGGLE_PROMO_CODE:
      return { ...state, promoCodeIsOpen: !state.promoCodeIsOpen };

    case ActionTypes.CONFIRM_EMAIL.REQUEST:
      return { ...state };
    case ActionTypes.CONFIRM_EMAIL.SUCCESS:
      return { ...state, emailConfirmation: action.data, confirmEmailModalIsOpen: true };
    case ActionTypes.CONFIRM_EMAIL.FAILURE:
      return {
        ...state,
        emailConfirmationError: action.error,
        confirmEmailModalIsOpen: true,
      };

    case ActionTypes.TOGGLE_CONFIRM_EMAIL_MODAL:
      return {
        ...state,
        confirmEmailModalIsOpen: !state.confirmEmailModalIsOpen,
      };

    case ActionTypes.LOGIN_WITH_TELEGRAM_FROM_REG.FAILURE:
      return { ...state, telegramAuthError: true };

    default:
      return state;
  }
};

export default reducer;
