import React from 'react';

const TooltipIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM12 4.125C7.65076 4.125 4.125 7.65076 4.125 12C4.125 16.3492 7.65076 19.875 12 19.875C16.3492 19.875 19.875 16.3492 19.875 12C19.875 7.65076 16.3492 4.125 12 4.125ZM12 15.9375C12.466 15.9375 12.8438 16.3153 12.8438 16.7812C12.8438 17.2472 12.466 17.625 12 17.625C11.534 17.625 11.1562 17.2472 11.1562 16.7812C11.1562 16.3153 11.534 15.9375 12 15.9375ZM12 6.9375C13.5533 6.9375 14.8125 8.1967 14.8125 9.75C14.8125 10.5708 14.4579 11.3337 13.8577 11.8617L13.6838 12.0027L13.4379 12.1862L13.3395 12.2668C13.2766 12.3207 13.219 12.3754 13.1639 12.4342C12.7833 12.8397 12.5625 13.3975 12.5625 14.25C12.5625 14.5607 12.3107 14.8125 12 14.8125C11.6893 14.8125 11.4375 14.5607 11.4375 14.25C11.4375 13.1115 11.7673 12.2784 12.3435 11.6643C12.4685 11.5311 12.5985 11.4149 12.7436 11.3011L13.0211 11.0937L13.1538 10.9815C13.4906 10.6661 13.6875 10.2248 13.6875 9.75C13.6875 8.81802 12.932 8.0625 12 8.0625C11.068 8.0625 10.3125 8.81802 10.3125 9.75C10.3125 10.0607 10.0607 10.3125 9.75 10.3125C9.43934 10.3125 9.1875 10.0607 9.1875 9.75C9.1875 8.1967 10.4467 6.9375 12 6.9375Z"
      fill="#5D647D"
    />
  </svg>
);

export default TooltipIcon;
