import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const PlayIcon = ({ grayColor }) => (
  <svg className="notFillIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23">
    <path
      fill={grayColor.hex}
      d="M11 .28c-6.066 0-11 4.935-11 11 0 6.066 4.934 11 11 11s11-4.934 11-11c0-6.065-4.934-11-11-11zm0 21.084C5.44 21.364.917 16.841.917 11.281.917 5.72 5.44 1.197 11 1.197c5.56 0 10.083 4.524 10.083 10.084S16.56 21.364 11 21.364z"
    />
    <path d="M9 16.28v-11l3 2.75 3 2.75-6 5.5z" fill="none" stroke={grayColor.hex} />
  </svg>
);

PlayIcon.propTypes = {
  grayColor: colorType,
};

PlayIcon.defaultProps = {
  grayColor: {},
};

const ThemedPlayIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(PlayIcon);

export default ThemedPlayIcon;
