import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  newsList: {},
  newsListAreLoaded: false,

  news: {},
  newsIsLoaded: false,

  story: {},
  storyIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_NEWS_LIST[REQUEST]:
      return { ...state, newsListAreLoaded: false };
    case ActionTypes.GET_NEWS_LIST[SUCCESS]:
      return { ...state, newsList: action.data, newsListAreLoaded: true };
    case ActionTypes.GET_NEWS_LIST[FAILURE]:
      return { ...state, error: action.error, newsListAreLoaded: false };

    case ActionTypes.GET_BO_NEWS[REQUEST]:
      return { ...state, newsIsLoaded: false };
    case ActionTypes.GET_BO_NEWS[SUCCESS]:
      return { ...state, news: action.data, newsIsLoaded: true };
    case ActionTypes.GET_BO_NEWS[FAILURE]:
      return { ...state, error: action.error, newsIsLoaded: false };

    case ActionTypes.GET_STORY[REQUEST]:
      return { ...state, storyIsLoaded: false };
    case ActionTypes.GET_STORY[SUCCESS]:
      return { ...state, story: action.data, storyIsLoaded: true };
    case ActionTypes.GET_STORY[FAILURE]:
      return { ...state, error: action.error, storyIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
