import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PageLayout, PageContentPreloader } from 'common/components';
import { getBONews, editNews } from '../../_redux';
import { NewsForm } from '../../_components';

const NewsEditPage = ({ getBONews, match, news, newsIsLoaded, history }) => {
  useEffect(() => {
    getBONews(match.params.id);
  }, [getBONews, match.params.id]);

  return (
    <PageLayout captionSlug="editNews">
      <PageContentPreloader ready={newsIsLoaded} type="bigLine">
        <NewsForm
          submitFunction={editNews}
          initialValues={{ id: match.params.id, title: news.title, body: news.body }}
          buttonTextId="doSave"
          successMessageId="newsEditedSuccessfully"
          history={history}
        />
      </PageContentPreloader>
    </PageLayout>
  );
};

NewsEditPage.propTypes = {
  getBONews: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  news: PropTypes.object.isRequired,
  newsIsLoaded: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    news: state.backoffice.news.news,
    newsIsLoaded: state.backoffice.news.newsIsLoaded,
  }),
  {
    getBONews: id => getBONews.request(id),
  }
)(withRouter(NewsEditPage));
export { NewsEditPage };
