import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* boGetInvestStrategiesSaga({ search }) {
  try {
    const { status, data } = yield call(api.boGetInvestStrategies, search);
    if (status < 300) {
      yield put(ActionTypes.boGetInvestStrategies.success(data));
    } else {
      yield put(ActionTypes.boGetInvestStrategies.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boGetInvestStrategies.failure());
    throw e;
  }
}

export function* boGetInvestStrategiesWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.BO_GET_INVEST_STRATEGIES[REQUEST]);
    yield call(boGetInvestStrategiesSaga, search);
  }
}

export function* boGetInvestStrategyInvestorsSaga(strategyId, search) {
  try {
    const { status, data } = yield call(api.boGetInvestStrategyInvestors, strategyId, search);
    if (status < 300) {
      yield put(ActionTypes.boGetInvestStrategyInvestors.success(data));
    } else {
      yield put(ActionTypes.boGetInvestStrategyInvestors.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boGetInvestStrategyInvestors.failure());
    throw e;
  }
}

export function* boGetInvestStrategyInvestorsWatcherSaga() {
  while (true) {
    const { strategyId, search } = yield take(ActionTypes.BO_GET_INVEST_STRATEGY_INVESTORS[REQUEST]);
    yield call(boGetInvestStrategyInvestorsSaga, strategyId, search);
  }
}

export function* boToggleInvestStrategySaga(strategyId, strategyAction, search) {
  try {
    const { status, data } = yield call(api.boToggleInvestStrategy, strategyId, strategyAction);
    yield call(boGetInvestStrategiesSaga, search);

    if (status < 300) {
      yield put(ActionTypes.boToggleInvestStrategy.success());
    } else {
      yield put(ActionTypes.boToggleInvestStrategy.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boToggleInvestStrategy.failure());
    throw e;
  }
}

export function* boToggleInvestStrategyWatcherSaga() {
  while (true) {
    const { strategyId, strategyAction, search } = yield take(ActionTypes.BO_TOGGLE_INVEST_STRATEGY[REQUEST]);
    yield call(boToggleInvestStrategySaga, strategyId, strategyAction, search);
  }
}

export function* boToggleInvestmentSaga(investmentId, investmentAction, strategyId, search, account) {
  try {
    const { status } = yield call(api.boToggleInvestment, investmentId, investmentAction, account);
    yield call(boGetInvestStrategyInvestorsSaga, strategyId, search);

    if (status < 300) {
      yield put(ActionTypes.boToggleInvestment.success());
    } else {
      yield put(ActionTypes.boToggleInvestment.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boToggleInvestment.failure());
    throw e;
  }
}

export function* boToggleInvestmentWatcherSaga() {
  while (true) {
    const { investmentId, investmentAction, strategyId, search, account } = yield take(
      ActionTypes.BO_TOGGLE_INVESTMENT[REQUEST]
    );
    yield call(boToggleInvestmentSaga, investmentId, investmentAction, strategyId, search, account);
  }
}

export function* boGetUserAccountsSaga(userId) {
  try {
    const { data, status } = yield call(api.boGetUserAccounts, userId);
    if (status < 300) {
      yield put(ActionTypes.boGetUserAccounts.success(data));
    } else {
      yield put(ActionTypes.boGetUserAccounts.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.boGetUserAccounts.failure());
    throw e;
  }
}

export function* boGetUserAccountsWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.BO_GET_USER_ACCOUNTS[REQUEST]);
    yield call(boGetUserAccountsSaga, userId);
  }
}

export function* editInvestStrategySaga(payload) {
  const { search, id, values } = payload;
  try {
    const { status, data: error } = yield call(api.editInvestStrategy, id, values);
    if (status < 300) {
      yield call(boGetInvestStrategiesSaga, search);
      yield put(ActionTypes.editInvestStrategy.success());
    } else {
      const formError = new SubmissionError(error);
      yield put(ActionTypes.editInvestStrategy.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
  }
}

export function* editInvestStrategyWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editInvestStrategy.REQUEST);
    yield call(editInvestStrategySaga, payload);
  }
}

export default [
  fork(boGetInvestStrategiesWatcherSaga),
  fork(boGetInvestStrategyInvestorsWatcherSaga),
  fork(boToggleInvestStrategyWatcherSaga),
  fork(boToggleInvestmentWatcherSaga),
  fork(boGetUserAccountsWatcherSaga),
  fork(editInvestStrategyWatcherSaga),
];
