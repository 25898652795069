import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { LightBoxImage } from 'common/components/light-box';
import { openLightBox } from 'interface/user/_redux/actions';
import { ActionsButton, BasicModal, DefaultButton, StatusCell, AddCardForm } from 'common/components';
import { EditIcon } from 'common/icons';
import { getCardPhoto } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';
import RejectCardsForm from '../RejectCardsForm';
import VerifyCardsForm from '../VerifyCardsForm';

const UserCard = ({
  card: { nameOnCard, frontPhoto, backPhoto, truncatedNumber, status, id },
  userId,
  displayId,
  handleSubmit,
  openLightBox,
  getCardPhoto,
  setOpenedModal,
  openedModal,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isOpen, setisOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState('reject');

  useEffect(() => {
    if (status !== 'PHOTO_REQUIRED') {
      getCardPhoto({ userId, cardId: id, side: 'front' });
      getCardPhoto({ userId, cardId: id, side: 'back' });
    }
  }, [getCardPhoto, userId, id, status]);

  const firstDigits = truncatedNumber ? truncatedNumber.slice(0, 6) : '';
  const lastDigits = truncatedNumber ? truncatedNumber.substr(-4) : '';

  const toggleModal = (modalName = 'reject') => {
    setCurrentModal(modalName);
    setisOpen(!isOpen);
  };

  let frontPhotoUrl = '';
  let backPhotoUrl = '';

  if (backPhoto) {
    backPhotoUrl = URL.createObjectURL(backPhoto);
  }

  if (frontPhoto) {
    frontPhotoUrl = URL.createObjectURL(frontPhoto);
  }

  const displayNumber = `${truncatedNumber.slice(0, 6)}******${truncatedNumber.slice(6, 11)}`;

  return (
    <div className="UserCard">
      <FormattedMessage id="boCard" values={{ cardId: displayId + 1 }}>
        {txt => <h3 className="UserCard__caption">{txt}</h3>}
      </FormattedMessage>
      <div className="UserCard__images">
        <LightBoxImage
          className="UserCard__lightBoxImage"
          openLightBox={() => openLightBox(id, [frontPhotoUrl])}
          image={frontPhotoUrl}
          titleId={nameOnCard}
        />
        <LightBoxImage
          className="UserCard__lightBoxImage"
          openLightBox={() => openLightBox(id, [backPhotoUrl])}
          image={backPhotoUrl}
          titleId={nameOnCard}
        />
      </div>
      <div className="UserCard__line">
        <FormattedMessage id="justCardNumber">
          {txt => <span className="UserCard__field-title">{txt}:</span>}
        </FormattedMessage>
        <span>{displayNumber}</span>
      </div>
      <div className="UserCard__line">
        <FormattedMessage id="justName">
          {txt => <span className="UserCard__field-title">{txt}:</span>}
        </FormattedMessage>
        <span>{nameOnCard}</span>
      </div>
      <div className="UserCard__line">
        {status === 'UPLOADED' ? (
          <div className="UserCard__buttons">
            <DefaultButton
              onClick={() => toggleModal('verify')}
              textId="boVerify"
              butColor="success"
              disabled={false}
            />
            <DefaultButton onClick={() => toggleModal('reject')} textId="boReject" butColor="error" disabled={false} />
          </div>
        ) : (
          <>
            <FormattedMessage id="justStatus">
              {txt => <span className="UserCard__field-title">{txt}:</span>}
            </FormattedMessage>
            <div className="UserCard__status">
              <StatusCell statusCode={status} />
              <ActionsButton
                edit
                buttonId="actionPartnerStatus"
                onClickFunc={status === 'PHOTO_REQUIRED' ? () => setOpenedModal('editModal') : toggleModal}
                disabled={status === 'REJECTED'}>
                <EditIcon />
              </ActionsButton>
            </div>
          </>
        )}
      </div>

      <BasicModal
        isOpen={isOpen}
        captionId={currentModal === 'reject' ? 'justRejectCard' : 'justVerifyCard'}
        onRequestClose={toggleModal}>
        {currentModal === 'reject' && <RejectCardsForm userId={userId} cardId={id} toggleModal={toggleModal} />}
        {currentModal === 'verify' && <VerifyCardsForm userId={userId} cardId={id} toggleModal={toggleModal} />}
      </BasicModal>

      <BasicModal isOpen={!!openedModal} captionId="justAddNewCard" onRequestClose={() => setOpenedModal(null)}>
        {openedModal === 'editModal' ? (
          <AddCardForm
            handleCancel={() => setOpenedModal(null)}
            submitFunction={handleSubmit}
            initialValues={{ nameOnCard, firstDigits, lastDigits }}
            withoutPhoto={openedModal === 'editModal'}
          />
        ) : (
          <AddCardForm handleCancel={() => setOpenedModal(null)} submitFunction={handleSubmit} />
        )}
      </BasicModal>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

UserCard.propTypes = {
  getCardPhoto: PropTypes.func.isRequired,
  openLightBox: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setOpenedModal: PropTypes.func,
  openedModal: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  userId: PropTypes.string.isRequired,
  displayId: PropTypes.number.isRequired,
  card: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

UserCard.defaultProps = {
  setOpenedModal: () => {},
  openedModal: null,
  theme: {},
};
export default compose(
  withRouter,
  withTheme(),
  connect(null, {
    getCardPhoto: data => getCardPhoto.request(data),
    openLightBox: (id, lightBoxImages) => openLightBox(id, lightBoxImages),
  })
)(UserCard);

export { UserCard };
