import css from 'styled-jsx/css';
import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseTextField {
    position: relative;
    margin-bottom: 10px;

    > div {
      position: relative;
    }

    input:disabled {
      opacity: 1 !important;
    }

    input:disabled + label {
      cursor: default !important;
      z-index: 5;
      opacity: 1 !important;
    }

    input:focus + label {
      top: 6px !important;
      font-size: rem(12px);
      transform: translateY(0);
      /* height: rem(20px); */
      z-index: 5;
    }

    input:focus + label {
      height: 14px;
      max-width: calc(100% - 20px);
      width: calc(100% - 20px);
      left: 10px;
      overflow: hidden;

      span {
        height: 14px;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    input.haveText + label {
      height: 14px;
      max-width: calc(100% - 20px);
      width: calc(100% - 20px);
      left: 10px;
      overflow: hidden;

      span {
        height: 14px;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .BaseTextField__label {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: ${isRTL ? 'auto' : '0.86667rem'};
    right: ${isRTL ? '0.86667rem' : 'auto'};
    transform: translateY(-50%);
    transition: all 0.25s ease;
    z-index: 1;
    cursor: text;

    &.haveText {
      top: 6px !important;
      font-size: rem(12px);
      transform: translateY(0);
      height: rem(20px);
      overflow: hidden;
    }
  }

  .BaseTextField__inp {
    &:-webkit-autofill + .BaseTextField__label {
      top: 6px !important;
      font-size: rem(12px);
      transform: translateY(0);
      z-index: 5;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .BaseTextField__label {
      transition: none;
    }
  }

  @supports (-ms-ime-align: auto) {
    .BaseTextField__label {
      transition: none;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .BaseTextField {
      input:focus {
        border-color: ${colors.primary.hex};
      }

      input:focus + label {
        color: ${colors.primary.hex} !important;
      }

      &.haveText {
        input {
          color: ${colors.black.hex} !important;
        }
      }

      input:disabled {
        background: ${colors.disabled.hex};
        color: ${colors.disabled.hex};
      }

      input:disabled {
        &.BaseTextField__inp {
          &.haveText {
            color: ${colors.black.hex};
            -webkit-text-fill-color: ${colors.black.hex};
          }
        }
      }
    }

    .BaseTextField__label {
      color: ${colors.gray.hex};
      &.haveText {
        color: ${colors.gray.hex};
      }
    }

    .BaseTextField__inp {
      color: ${rgba([colors.white.rgb, 0.0])};
      &:focus {
        color: ${colors.black.hex};
      }
      &.haveText {
        color: ${colors.black.hex};
      }
      &:-webkit-autofill + .BaseTextField__label {
        color: ${colors.gray.hex};
      }
    }

    .ReduxTextField__error {
      color: ${colors.error.hex};
    }

    .ReduxTextField {
      &.error {
        input,
        input:focus {
          border-color: ${colors.error.hex} !important;
        }
        .BaseTextField__label {
          color: ${colors.error.hex} !important;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
