import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksList {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }

    &__overdue {
      display: flex;
      align-items: center;
      margin-bottom: rem(10px);

      :global(.Checkbox) {
        margin-right: 5px;
      }
    }
  }
`;

export { staticStyles };
