import PropTypes from 'prop-types';
import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

import { staticStyles } from './style';

const BigChart = ({ chartType, chartSeries, periodType, days, color }) => {
  let categories;
  if (periodType === 1) {
    categories = moment.weekdaysShort(true);
  } else {
    categories = days;
  }

  let options;
  if (chartType === 'area') {
    options = {
      chart: {
        fontFamily: 'ubuntu-font, sans-serif',
        width: '100%',
        zoom: {
          enabled: false,
        },
        foreColor: '#C5C5C5',
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: periodType === 1 ? 'category' : 'datetime',
        categories,
      },
      colors: [color, '#C4C4C4'],
      fill: {
        type: 'solid',
        opacity: 0.2,
      },
      yaxis: {
        labels: {
          offsetX: -10,
        },
        decimalsInFloat: 2,
      },
      grid: {
        show: true,
        borderColor: 'transparent',
      },
    };
  } else {
    options = {
      chart: {
        fontFamily: 'ubuntu-font, sans-serif',
        width: '100%',
        zoom: {
          enabled: false,
        },
        foreColor: '#C5C5C5',
        animations: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: [color],
      xaxis: {
        type: 'category',
        categories,
      },
      yaxis: {
        labels: {
          offsetX: -10,
        },
        decimalsInFloat: 2,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
    };
  }

  return (
    <div className="BigChart">
      <Chart className="BigChart__inner" options={options} series={chartSeries} type={chartType} height="300" />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

BigChart.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartSeries: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
  periodType: PropTypes.number.isRequired,
  color: PropTypes.string,
};

BigChart.defaultProps = {
  color: '#43B6E8',
};

export default BigChart;
