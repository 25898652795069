import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ProgressBar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 15px;
    line-height: 22px;
  }

  .ProgressStage {
    display: grid;
    grid-template-rows: 1fr 1fr;
    &__status {
      display: flex;
      align-items: center;
    }
    &__divider {
      height: 1px;
      background-color: #bfc7d7;
      width: calc(100% - 16px);
      &--green {
        background-color: #389700;
      }
    }
    &__description {
      padding-right: 4px;

      &--hide {
        @include respond-to(xs) {
          display: none;
        }
      }
    }
  }
  .Divider {
    height: 1px;
    width: 100%;
    background: #f0f4f6;
    grid-column: 1/5;
    margin-top: 32px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    .ProgressBar--step1 {
      @include respond-to(xs) {
        grid-template-columns: auto repeat(2, 34px) 16px;
      }
    }
    .ProgressBar--step2 {
      @include respond-to(xs) {
        grid-template-columns: 34px auto 34px 16px;
      }
    }
    .ProgressBar--step3 {
      @include respond-to(xs) {
        grid-template-columns: repeat(2, 34px) auto 16px;
      }
    }
    .ProgressBar--step4 {
      @include respond-to(xs) {
        grid-template-columns: repeat(2, 34px) auto 16px;
      }
    }
    .ProgressBar--step5 {
      @include respond-to(xs) {
        grid-template-columns: repeat(2, 34px) auto 64px;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
