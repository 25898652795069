import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import * as api from '../api';
import * as ActionTypes from './actions';

export function* getCampaignListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getCampaignList, search);
    if (status < 300) {
      yield put(ActionTypes.getCampaignList.success(data));
    } else {
      yield put(ActionTypes.getCampaignList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCampaignList.failure());
    throw e;
  }
}

export function* getCampaignListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CAMPAIGN_LIST[REQUEST]);
    yield call(getCampaignListSaga, data);
  }
}

export function* getCampaignSaga({ campaignId }) {
  try {
    const { status, data } = yield call(api.getCampaign, campaignId);
    if (status < 300) {
      yield put(ActionTypes.getCampaign.success(data));
    } else {
      yield put(ActionTypes.getCampaign.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCampaign.failure());
    throw e;
  }
}

export function* getCampaignWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CAMPAIGN[REQUEST]);
    yield call(getCampaignSaga, data);
  }
}

export function* getDefaultCampaignSaga() {
  try {
    const { status, data } = yield call(api.getDefaultCampaign);
    if (status < 300) {
      yield put(ActionTypes.getDefaultCampaign.success(data));
    } else {
      yield put(ActionTypes.getDefaultCampaign.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDefaultCampaign.failure());
    throw e;
  }
}

export function* getDefaultCampaignWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_DEFAULT_CAMPAIGN[REQUEST]);
    yield call(getDefaultCampaignSaga);
  }
}

export function* deleteCampaignSaga({ campaignId, defaultId, params }) {
  try {
    const { data, status } = yield call(api.deleteCampaign, campaignId, defaultId);
    if (status < 300) {
      yield put(ActionTypes.deleteCampaign.success(data));
      yield call(getCampaignListSaga, params);
    } else {
      yield put(ActionTypes.deleteCampaign.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.deleteCampaign.failure());
    throw e;
  }
}

export function* deleteCampaignWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.deleteCampaign.REQUEST);
    yield call(deleteCampaignSaga, payload);
  }
}

export function* createCampaignSaga({ file, ...formValues }) {
  try {
    const { data, status } = yield call(api.createCampaign, formValues);
    if (status < 300) {
      yield put(ActionTypes.createCampaign.success(data));
    } else {
      yield put(ActionTypes.createCampaign.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createCampaign.failure());
    throw e;
  }
}

export function* createCampaignWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createCampaign.REQUEST);
    yield call(createCampaignSaga, payload);
  }
}

export function* updateCampaignSaga({ file, campaignId, ...formValues }) {
  try {
    const { data, status } = yield call(api.updateCampaign, campaignId, formValues);
    if (status < 300) {
      yield put(ActionTypes.updateCampaign.success(data));
    } else {
      yield put(ActionTypes.updateCampaign.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.updateCampaign.failure());
    throw e;
  }
}

export function* updateCampaignWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateCampaign.REQUEST);
    yield call(updateCampaignSaga, payload);
  }
}

export function* getPromoMaterialListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getPromoMaterialList, search);
    if (status < 300) {
      yield put(ActionTypes.getPromoMaterialList.success(data));
    } else {
      yield put(ActionTypes.getPromoMaterialList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPromoMaterialList.failure());
    throw e;
  }
}

export function* getPromoMaterialListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_PROMO_MATERIAL_LIST[REQUEST]);
    yield call(getPromoMaterialListSaga, data);
  }
}

export function* deletePromoSaga({ promoId, params }) {
  try {
    const { status, data } = yield call(api.deletePromo, promoId);
    if (status < 300) {
      yield put(ActionTypes.deletePromo.success());
      yield call(getPromoMaterialListSaga, params);
    } else {
      yield put(ActionTypes.deletePromo.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deletePromo.failure(e));
  }
}
export function* deletePromoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DELETE_PROMO[REQUEST]);
    yield call(deletePromoSaga, data);
  }
}

export function* createPromoSaga(formValues) {
  try {
    const { data, status } = yield call(api.createPromo, Object.values(formValues));
    if (status < 300) {
      yield put(ActionTypes.createPromo.success(data));
    } else {
      yield put(ActionTypes.createPromo.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createPromo.failure());
    throw e;
  }
}

export function* createPromoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createPromo.REQUEST);
    yield call(createPromoSaga, payload);
  }
}

export function* updatePromoSaga({ uploadFile, promoId, ...formValues }) {
  try {
    const { data, status } = yield call(api.updatePromo, promoId, formValues);
    if (status < 300) {
      yield put(ActionTypes.updatePromo.success(data));
    } else {
      yield put(ActionTypes.updatePromo.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.updatePromo.failure());
    throw e;
  }
}

export function* updatePromoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updatePromo.REQUEST);
    yield call(updatePromoSaga, payload);
  }
}

export function* getPromoSaga({ promoId }) {
  try {
    const { status, data } = yield call(api.getPromo, promoId);
    if (status < 300) {
      yield put(ActionTypes.getPromo.success(data));
    } else {
      yield put(ActionTypes.getPromo.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPromo.failure());
    throw e;
  }
}

export function* getPromoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_PROMO[REQUEST]);
    yield call(getPromoSaga, data);
  }
}

export default [
  fork(getCampaignListWatcherSaga),
  fork(deleteCampaignWatcherSaga),
  fork(createCampaignWatcherSaga),
  fork(getCampaignWatcherSaga),
  fork(getDefaultCampaignWatcherSaga),
  fork(updateCampaignWatcherSaga),
  fork(getPromoMaterialListWatcherSaga),
  fork(deletePromoWatcherSaga),
  fork(createPromoWatcherSaga),
  fork(updatePromoWatcherSaga),
  fork(getPromoWatcherSaga),
];
