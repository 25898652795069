import React from 'react';
import { connect } from 'react-redux';

import { getPartnerAccountsExports, downloadPartnerAccountsExport } from 'backoffice/partnership/_redux';
import { ExportsPaginationTable } from 'backoffice/partnership/_components';

const ExportPartnerAccountsPage = ({
  partnerAccountsExports,
  partnerAccountsExportsIsLoaded,
  getPartnerAccountsExports,
  downloadPartnerAccountsExport,
}) => (
  <ExportsPaginationTable
    captionSlug="listOfPartnerAccountsExports"
    data={partnerAccountsExports}
    dataIsLoaded={partnerAccountsExportsIsLoaded}
    getList={getPartnerAccountsExports}
    downloadExport={downloadPartnerAccountsExport}
  />
);

export default connect(
  state => ({
    partnerAccountsExports: state.backoffice.partnership.partnerAccountsExportsData,
    partnerAccountsExportsIsLoaded: state.backoffice.partnership.partnerAccountsExportsDataIsLoaded,
  }),
  {
    getPartnerAccountsExports: search => getPartnerAccountsExports.request(search),
    downloadPartnerAccountsExport: data => downloadPartnerAccountsExport.request(data),
  }
)(ExportPartnerAccountsPage);
