import React from 'react';

const WalletIconActive = () => (
  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.55738 18H3.77049V15.5676C2.95082 15.5676 2.29508 15.4054 1.63934 15.2432C1.14754 15.0811 0.655738 14.9189 0.327869 14.7568L0 14.5946L0.983607 12L1.47541 12.1622C1.80328 12.3243 2.29508 12.4865 2.95082 12.6486C3.44262 12.8108 3.93443 12.973 4.7541 12.973C5.73771 12.973 6.22951 12.8108 6.39344 12.6486C6.55738 12.4865 6.72131 12.1622 6.72131 11.8378C6.72131 11.6757 6.72131 11.5135 6.55738 11.3514C6.39344 11.1892 6.22951 11.027 6.06557 10.8649C5.90164 10.7027 5.57377 10.5405 5.2459 10.3784C4.91803 10.2162 4.42623 10.0541 4.09836 9.89189C3.60656 9.89189 3.27869 9.72973 2.78689 9.40541C2.29508 9.24324 1.96721 9.08108 1.63934 8.75676C1.31148 8.43243 0.983607 8.10811 0.819672 7.62162C0.655738 7.13513 0.491803 6.64865 0.491803 6C0.491803 5.02703 0.819672 4.21622 1.47541 3.40541C1.96721 2.75676 2.78689 2.27027 3.93443 2.10811V0H6.55738V2.10811C7.04918 2.10811 7.54098 2.27027 7.86885 2.27027C8.36066 2.43243 8.85246 2.59459 9.18033 2.59459L9.5082 2.75676L8.85246 5.67568L8.36066 5.51351C8.03279 5.35135 7.54098 5.18919 7.04918 5.02703C6.55738 4.86486 6.06557 4.86486 5.40984 4.86486C4.7541 4.86486 4.2623 5.02703 4.09836 5.18919C3.77049 5.35135 3.77049 5.51351 3.77049 5.83784C3.77049 6 3.77049 6.16216 3.93443 6.32432C3.93443 6.48649 3.93443 6.48649 4.09836 6.64865C4.2623 6.81081 4.59016 6.81081 4.7541 6.97297C5.08197 7.13514 5.40984 7.2973 5.73771 7.2973C6.22951 7.45946 6.88525 7.78378 7.37705 7.94595C7.86885 8.10811 8.36066 8.43243 8.68853 8.75676C9.18033 9.24324 9.5082 9.72973 9.67213 10.0541C9.83607 10.5405 10 11.027 10 11.6757C10 12.6486 9.67213 13.4595 9.01639 14.1081C8.52459 14.9189 7.70492 15.2432 6.55738 15.5676V18Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="1.75921"
        y1="19.3331"
        x2="11.7168"
        y2="17.6725"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#43B6E8" />
        <stop offset="1" stopColor="#33EEC7" />
      </linearGradient>
    </defs>
  </svg>
);

export default WalletIconActive;
