import css from 'styled-jsx/css';
import { gradient, isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PageLayout {
    width: 100%;
    margin-top: rem(40px);
    padding-bottom: rem(25px);
    @include respond-to(sm) {
      margin-top: rem(20px);
      padding-bottom: rem(10px);
    }

    &__back {
      color: #777777;
      padding-left: 15px;
      position: relative;
      margin-bottom: 10px;

      &:hover {
        color: #252525;
      }

      &:before {
        content: '';
        width: 10px;
        height: 1px;
        background: #777777;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    h1 {
      padding-right: 35px;
      display: inline;
      position: relative;
      word-break: break-word;
      @include respond-to(sm) {
        display: inline-block;
        max-width: calc(100% - 120px);
      }
      :global(span) {
        display: inline;
      }
      :global(.DefaultButton) {
        margin-right: ${isRTL ? '0' : '25px'};
        margin-left: ${isRTL ? '25px' : '0'};
        vertical-align: middle;
        width: max-content;

        @include respond-to(sm) {
          margin-right: ${isRTL ? '0' : '100%'};
          margin-left: ${isRTL ? '100%' : '0'};
          margin-bottom: 8px;
        }
      }
      .PageLayout__data-reload {
        position: absolute;
        top: 30%;
        right: 0;
        width: 22px;
        height: 22px;
        transition: all 0.2s ease;
        opacity: 0.7;
        &:active {
          :global(svg) {
            animation: buttonActive 0.2s;
          }
        }
        img {
          width: 100%;
          height: auto;
        }
        &:hover {
          opacity: 1;
        }
        @include respond-to(sm) {
          top: 20%;
        }
      }
    }
  }

  @keyframes buttonActive {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .PageLayout__header {
    margin-bottom: rem(25px);
  }

  .PageLayout__body {
    position: relative;
  }

  .PageLayout__description {
    margin-top: rem(15px);
    :global(li) {
      display: block;
      margin-bottom: rem(20px);
      counter-increment: itemVerification;
      padding-left: 35px;
    }
    :global(p) {
      font-size: rem(18px);
      font-weight: 600;
      position: relative;
      &:before {
        content: counter(itemVerification);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -1px;
        left: -35px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        @include respond-to(sm) {
          font-size: 14px;
          width: 20px;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .PageLayout {
      h1 {
        .PageLayout__data-reload {
          top: 10px;
          width: 30px;
          height: 30px;
          :global(svg, img) {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }

  @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
    .PageLayout {
      h1 {
        display: inline-block;
        :global(span) {
          display: inline-block;
        }
      }
    }
  }

  :global(.PageLayout__toTop-wrapper) {
    position: fixed;
    right: 30px;
    bottom: 30px;
    transition: all 0.5s ease;
    cursor: pointer;
    background-color: #fff;
    padding: 10px;
    opacity: 0;
    transform: scale(0.4);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    @include respond-to(xl) {
      width: 35px;
      height: 35px;
      right: 10px;
      bottom: 10px;
    }
    @include respond-to(sm) {
      right: 5px;
      bottom: 5px;
    }
    &:hover {
      background: #f0f0f0;
    }
    &:active {
      transform: scale(0.8);
    }
    :global(p) {
      display: inline-block;
      padding: 5px;
      transform: rotate(-135deg);
      line-height: 0;
      font-size: 0;
      position: absolute;
      top: 42%;
      @include respond-to(xl) {
        padding: 4px;
        top: 42%;
      }
    }
  }

  :global(.PageLayout__toTop-transition) {
    opacity: 1;
    transform: scale(1);
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .PageLayout {
      h1 {
        padding-left: ${isRTL ? '35px' : '0'};
        padding-right: ${isRTL ? '0' : '35px'};

        .PageLayout__data-reload {
          right: ${isRTL ? 'auto' : '0'};
          left: ${isRTL ? '0' : 'auto'};
        }
      }
    }
    .PageLayout__description {
      :global(p) {
        &:before {
          background-image: ${gradient(53, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          color: ${colors.white.hex};
        }
      }
    }

    :global(.PageLayout__toTop-wrapper) {
      border: 1px solid ${colors.primary.hex};
      left: ${isRTL ? '30px' : 'auto'};
      :global(p) {
        border: solid ${colors.primary.hex};
        border-width: 0 2px 2px 0;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
