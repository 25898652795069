import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .Banner {
    width: 100%;

    &__img {
      margin-bottom: 25px;

      img {
        max-width: 100%;
      }
    }
  }
`;

export { staticStyles };
