import { put, call, fork, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getFilesSaga({ search }) {
  try {
    const { status, data } = yield call(api.getFiles, search);
    if (status < 300) {
      yield put(ActionTypes.getFiles.success(data));
      return { filesAreLoaded: true, data };
    }
    yield put(ActionTypes.getFiles.failure(data.error));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getFiles.failure());
    throw e;
  }
}
export function* getFilesWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_FILES[REQUEST]);
    yield call(getFilesSaga, search);
  }
}

export function* getFileSaga(id) {
  try {
    const { status, data } = yield call(api.getFile, id);
    if (status < 300) {
      yield put(ActionTypes.getFile.success(data));
      return { fileIsLoaded: true, data };
    }
    yield put(ActionTypes.getFile.failure(data.error));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getFile.failure());
    throw e;
  }
}
export function* getFileWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_FILE[REQUEST]);
    yield call(getFileSaga, id);
  }
}

export function* createFileSaga(body) {
  try {
    const { status, data } = yield call(api.createFile, body);
    if (status < 300) {
      yield put(ActionTypes.createFile.success(data));
    } else {
      const error = data.error ? data.error : data;
      yield put(ActionTypes.createFile.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createFile.failure(e));
  }
}
export function* createFileWatcherSaga() {
  while (true) {
    const {
      payload: { body },
    } = yield take(ActionTypes.createFile.REQUEST);
    yield call(createFileSaga, body);
  }
}

export function* editFileSaga(id, body) {
  try {
    const { status, data } = yield call(api.editFile, id, body);
    if (status < 300) {
      yield put(ActionTypes.editFile.success());
    } else {
      const error = data.error ? data.error : data;
      yield put(ActionTypes.editFile.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editFile.failure(e));
  }
}
export function* editFileWatcherSaga() {
  while (true) {
    const {
      payload: { id, body },
    } = yield take(ActionTypes.editFile.REQUEST);
    yield call(editFileSaga, id, body);
  }
}

export function* deleteFileSaga(id, queryParams) {
  try {
    const { status } = yield call(api.deleteFile, id);
    if (status === 409) {
      const error = 'Unable to delete file: being used by another person';
      yield put(ActionTypes.deleteFile.failure(error));
    } else {
      yield put(ActionTypes.deleteFile.success());
      if (queryParams) {
        yield call(getFilesSaga, queryParams);
      }
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteFile.failure(e));
  }
}
export function* deleteFileWatcherSaga() {
  while (true) {
    const { id, queryParams } = yield take(ActionTypes.DELETE_FILE[REQUEST]);
    yield call(deleteFileSaga, id, queryParams);
  }
}

export function* getFileExtensionsSaga() {
  try {
    const { data } = yield call(api.getFileExtensions);
    yield put(ActionTypes.getFileExtensions.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getFileExtensions.failure());
    throw e;
  }
}
export function* getFileExtensionsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_FILE_EXTENSIONS[REQUEST]);
    yield call(getFileExtensionsSaga);
  }
}

export function* uploadFilesSaga(attachments) {
  try {
    const files = [];
    if (attachments && attachments.length) {
      for (let i = 0; i < attachments.length; i += 1) {
        const { status, data } = yield call(api.uploadFile, attachments[i].name, attachments[i]);
        if (status < 300) {
          files.push(data);
        } else {
          yield put(ActionTypes.uploadFiles.failure(data));
        }
      }
    }
    yield put(ActionTypes.uploadFiles.success(files));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.uploadFiles.failure(e));
  }
}
export function* uploadFilesWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.uploadFiles.REQUEST);
    yield call(uploadFilesSaga, payload);
  }
}

export default [
  fork(getFilesWatcherSaga),
  fork(getFileWatcherSaga),
  fork(createFileWatcherSaga),
  fork(editFileWatcherSaga),
  fork(deleteFileWatcherSaga),
  fork(getFileExtensionsWatcherSaga),
  fork(uploadFilesWatcherSaga),
];
