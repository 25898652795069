import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { ReduxTextField } from 'common/components/form-fields';
import { FormMessageHOC } from 'common';

import { withTheme } from 'common/styling/theme';
// import { DefaultButton } from 'common/components';
import { staticStyles } from './style';

// Most likely there will be feature to change email

const ChangeEmailForm = ({ userEmail }) => (
  <form className="ChangeEmailForm">
    <FormattedMessage id="justEmail">{txt => <h2 className="ChangeEmailForm__caption">{txt}</h2>}</FormattedMessage>
    <Field
      component={ReduxTextField}
      customDefaultValue={userEmail}
      name="email"
      textId="justCurrentEmail"
      validate={[required()]}
      disabled
    />
    {/* <DefaultButton
        textId="justChange"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      /> */}

    <style jsx>{staticStyles}</style>
  </form>
);

ChangeEmailForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
};

export default compose(
  withTheme(),
  connect(state => ({ userEmail: state.user.profile.email })),
  FormMessageHOC,
  reduxForm({
    form: 'ChangeEmailForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage();
    },
  })
)(ChangeEmailForm);

export { ChangeEmailForm };
