import * as icons from './Icons';

const MENU_ICONS = {
  WebTerminalLinksCategory: {
    default: icons.WebTerminalLinks,
    active: icons.WebTerminalLinks,
  },
  justChallenges: { default: icons.ChallengesIcon, active: icons.ChallengesIconActive },
  AnalyticsCategory: {
    default: icons.AnalyticsIcon,
    active: icons.AnalyticsIcon,
  },
  RegistrationCategory: {
    default: icons.RegistrationIcon,
    active: icons.RegistrationIcon,
  },
  PlatformsCategory: {
    default: icons.TradingPlatforms,
    active: icons.TradingPlatforms,
  },
  technicalAnalysis: {
    default: icons.TechnicalIcon,
    active: icons.TechnicalIcon,
  },
  menuCalendar: {
    default: icons.CalendarIcon,
    active: icons.CalendarIcon,
  },
  menuNews: {
    default: icons.NewsIcon,
    active: icons.NewsIcon,
  },
  boDashboards: {
    default: icons.DashboardIcon,
    active: icons.DashboardIconActive,
  },
  EmailCategory: {
    default: icons.MailIcon,
    active: icons.MailIcon,
  },
  boPartnership: {
    default: icons.PartnershipIcon,
    active: icons.PartnershipIconActive,
  },
  Partnership: {
    default: icons.PartnershipIcon,
    active: icons.PartnershipIconActive,
  },
  menuPartnership: {
    default: icons.PartnershipIcon,
    active: icons.PartnershipIconActive,
  },
  adminCustomizationMenu: {
    default: icons.CustomizationIcon,
    active: icons.CustomizationIconActive,
  },
  PaymentSystemsCategory: {
    default: icons.DepositIcon,
    active: icons.DepositIconActive,
  },
  adminStaffManagement: {
    default: icons.UsersIcon,
    active: icons.UsersIconActive,
  },
  adminSafetySettings: {
    default: icons.SafetyIcon,
    active: icons.SafetyIconActive,
  },
  withdrawRequestApprovingSettings: {
    default: icons.RequestApproveIcon,
    active: icons.RequestApproveIcon,
  },
  bannersManagement: {
    default: icons.PromoMaterialsIcon,
    active: icons.PromoMaterialsIconActive,
  },
  menuWallet: {
    default: icons.WalletIcon,
    active: icons.WalletIconActive,
  },
  menuAccounts: {
    default: icons.AccountsIcon,
    active: icons.AccountsIconActive,
  },
  menuMarket: {
    default: icons.RatingIcon,
    active: icons.RatingIconActive,
  },
  investRating: {
    default: icons.RatingIcon,
    active: icons.RatingIconActive,
  },
  investRatingNew: {
    default: icons.RatingIcon,
    active: icons.RatingIconActive,
  },
  menuDeposit: {
    default: icons.DepositIcon,
    active: icons.DepositIconActive,
  },
  swayCapitalDeposit: {
    default: icons.DepositIcon,
    active: icons.DepositIconActive,
  },
  boLpas: {
    default: icons.DepositIcon,
    active: icons.DepositIconActive,
  },
  justLocalPayments: {
    default: icons.DepositIcon,
    active: icons.DepositIconActive,
  },
  boDeposits: {
    default: icons.DepositIcon,
    active: icons.DepositIconActive,
  },
  justMail: {
    default: icons.MailIcon,
    active: icons.MailIcon,
  },
  justSupport: {
    default: icons.BuoyIcon,
    active: icons.BuoyIconActive,
  },
  justWebsite: {
    default: icons.AccountsIcon,
    active: icons.AccountsIconActive,
  },
  boSupport: {
    default: icons.SupportIcon,
    active: icons.SupportIconActive,
  },
  boUsers: {
    default: icons.UsersIcon,
    active: icons.UsersIconActive,
  },
  menuWithdraw: {
    default: icons.WithdrawalIcon,
    active: icons.WithdrawalIconActive,
  },
  boWithdrawals: {
    default: icons.WithdrawalIcon,
    active: icons.WithdrawalIconActive,
  },
  menuPayments: {
    default: icons.PaymentsIcon,
    active: icons.PaymentsIconActive,
  },
  InternalTransfersCategory: {
    default: icons.TransferIcon,
    active: icons.TransferIconActive,
  },
  menuInternalTransfer: {
    default: icons.TransferIcon,
    active: icons.TransferIconActive,
  },
  FilesCategory: {
    default: icons.FilesToDownloadIcon,
    active: icons.FilesToDownloadIconActive,
  },
  menuFiles: {
    default: icons.FilesToDownloadIcon,
    active: icons.FilesToDownloadIconActive,
  },
  menuAllBonuses: {
    default: icons.BonusesAllIcon,
    active: icons.BonusesAllIconActive,
  },
  boBonusManagement: {
    default: icons.BonusesAllIcon,
    active: icons.BonusesAllIconActive,
  },
  menuMyBonuses: {
    default: icons.BonusesMyIcon,
    active: icons.BonusesMyIconActive,
  },
  adminFilesMenu: {
    default: icons.TechnicalIcon,
    active: icons.TechnicalIcon,
  },
  justPromoMaterials: {
    default: icons.PromoMaterialsIcon,
    active: icons.PromoMaterialsIconActive,
  },
  justFaq: {
    default: icons.FaqIcon,
    active: icons.FaqIconActive,
  },
  justContractSpecifications: {
    default: icons.SpecsIcon,
    active: icons.SpecsIconActive,
  },
  justTasks: {
    default: icons.TasksIcon,
    active: icons.TasksIconActive,
  },
  justCommentTemplates: {
    default: icons.TemplatesIcon,
    active: icons.TemplatesIconActive,
  },
  justStatuses: {
    default: icons.StatusesIcon,
    active: icons.StatusesIconActive,
  },
  menuInvest: {
    default: icons.InvestIcon,
    active: icons.InvestIconActive,
  },
  menuInvestCRM: {
    default: icons.InvestIcon,
    active: icons.InvestIconActive,
  },
  crmContacts: {
    default: icons.CrmContactsIcon,
    active: icons.CrmContactsIconActive,
  },
  crmTasks: {
    default: icons.CrmTasksIcon,
    active: icons.CrmTasksIconActive,
  },
  crmReports: {
    default: icons.CrmReportsIcon,
    active: icons.CrmReportsIconActive,
  },
  crmAnalytics: {
    default: icons.CrmAnalyticsIcon,
    active: icons.CrmAnalyticsIconActive,
  },
  // click2Call: {
  //   default: icons.PhoneIcon,
  //   active: icons.PhoneIcon,
  // },
  Click2Call: {
    default: icons.PhoneIcon,
    active: icons.PhoneIconActive,
  },
  default: {
    default: icons.SettingIcon,
    active: icons.SettingIcon,
  },
};

export default MENU_ICONS;
