import { FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';
import * as actions from './actions';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.REGISTER_FILE_FIELD:
      return {
        ...state,
        [action.fieldName]: { loading: false, uploadedFile: '' },
      };
    case actions.UNREGISTER_FILE_FIELD: {
      const newState = { ...state };
      if (state[action.fieldName]) {
        delete newState[action.fieldName];
      }
      return newState;
    }

    case actions.UPLOAD_FILE[REQUEST]:
      return {
        ...state,
        [action.fieldName]: { ...state[action.fieldName], loading: true },
      };
    case actions.UPLOAD_FILE[SUCCESS]:
      return {
        ...state,
        [action.fieldName]: { uploadedFile: action.url, loading: false },
      };
    case actions.UPLOAD_FILE[FAILURE]:
      return {
        ...state,
        [action.fieldName]: { ...state[action.fieldName], loading: false },
      };

    default:
      return state;
  }
};
