import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .TabNotes {
    .text-row {
      height: 203px !important;
      margin-bottom: 16px;
    }
  }
  .TopTab {
    &__pagination {
      margin: 50px 0 0 0;
    }
  }
  .TabNotes__ModalButtons {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`;

export { staticStyles };
