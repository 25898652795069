import React from 'react';
import classNames from 'classnames';
import { BaseTable, Pagination } from 'crm/_components';
import { useDispatch, useSelector } from 'react-redux';
import { getCrmUserPayments } from 'crm/users/_redux';

import { DateCell, MoneyCell } from 'common/components';

import paymentIcon from './icons/payment.svg';

import { staticStyles } from './style';

const TabInOutTableDAW = () => {
  const { payments, paymentsAreLoaded, userData } = useSelector(state => state.crm.users);
  const hasPayments = !!Object.keys(payments).length;

  const dispatch = useDispatch();
  // const { id } = useParams();

  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

  const onPageChange = (page, size) => {
    dispatch(
      getCrmUserPayments.request({
        id: userData.userId,
        search: {
          page,
          page_size: size,
        },
      })
    );
  };

  /* eslint-disable */
  const columns = [
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount, operation },
        },
      }) => (
        <div className="amount">
          <div className="amount--icon">
            <img src={paymentIcon} alt={operation} />
          </div>
          <MoneyCell value={amount} />
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'account',
      Header: 'justAccount',
      Cell: ({
        row: {
          original: { accountLogin },
        },
      }) => (
        <div className="account">
          <div className="account__number">{accountLogin}</div>
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'date',
      Header: 'crmTransactionDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    {
      accessor: 'paymentSystem',
      Header: 'crmPaymentSystem',
      Cell: ({
        row: {
          original: { paymentMethodTitle },
        },
      }) => <div className="payment-system">{paymentMethodTitle}</div>,
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      Cell: ({
        row: {
          original: { status },
        },
      }) => (
        <div className="status">
          <div className={classNames('status__icon', `status__icon--${status.toLowerCase()}`)}></div>
          <div className="status__text">{capitalize(status)}</div>
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'statusDate',
      Header: 'crmStatusDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
  ];
  /* eslint-enable */

  return (
    <div className="TabInOutTableDAW">
      {hasPayments && <BaseTable columns={columns} data={payments.results} onRowClick={() => {}} />}
      <Pagination
        externalClass="TabInOutTableDAW__pagination"
        dataCount={hasPayments ? payments.count : 1}
        handleChangePage={onPageChange}
        sizes={[10, 50, 100]}
        searchId="tabInOutDAW"
        pending={!paymentsAreLoaded}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default TabInOutTableDAW;
