import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .AuthRightBg {
    width: 72%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media only screen and (max-width: 1880px) {
      width: 70%;
    }
    @media only screen and (max-width: 1700px) {
      width: 68%;
    }
    @media only screen and (max-width: 1350px) {
      width: 65%;
    }
    @media only screen and (max-width: 1180px) {
      width: 55%;
    }
    @media only screen and (min-height: 1024px) {
      height: 100%;
    }
    @include respond-to(md) {
      width: 60%;
    }
    @media only screen and (max-width: 920px) {
      width: 55%;
      background-position: (-150px) center;
    }
    @media only screen and (max-width: 830px) {
      width: 50%;
    }
    @include respond-to(sm) {
      display: none;
    }
  }
`;

const getDynamicStyles = ({ images }) => {
  return css.global`
    .AuthRightBg {
      background-image: url(${images.authImage});
    }
  `;
};

export { staticStyles, getDynamicStyles };
