import css from 'styled-jsx/css';

const staticStyles = css.global`
  .ResendOTPButton {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-left: 15px;
    &:active {
      transform: translateY(2px);
    }
  }
  .ResendOTPButton__timer {
    margin-left: 15px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ResendOTPButton {
      color: ${colors.primary.hex};
      &:hover {
        color: ${colors.secondary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
