/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Range, getTrackBackground } from 'react-range';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const RangeField = ({ textId, formatLabelText, minValue, maxValue, onFinalChange, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [values, setValues] = useState([minValue, maxValue]);

  return (
    <div className="RangeField">
      <FormattedMessage id={textId}>{txt => <p>{txt}</p>}</FormattedMessage>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
        <Range
          values={values}
          step={1}
          min={minValue}
          max={maxValue}
          onChange={values => setValues(values)}
          onFinalChange={onFinalChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}>
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values,
                    colors: ['#ccc', '#0AA1E2', '#ccc'],
                    min: minValue,
                    max: maxValue,
                  }),
                  alignSelf: 'center',
                }}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ index, props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '12px',
                width: '12pxpx',
                outline: 0,
                borderRadius: '100%',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA',
              }}>
              <div
                style={{
                  position: 'absolute',
                  top: '-28px',
                  color: 'gray',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  fontFamily: 'Ubuntu',
                  padding: '4px',
                }}>
                {values[index].toFixed(0)}
                {formatLabelText}
              </div>
              <div
                style={{
                  height: '13px',
                  width: '13px',
                  borderRadius: '100%',
                  backgroundColor: isDragged ? '#0AA1E2' : '#CCC',
                }}
              />
            </div>
          )}
        />
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

RangeField.propTypes = {
  textId: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  formatLabelText: PropTypes.string,
  onFinalChange: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

RangeField.defaultProps = {
  formatLabelText: '',
  theme: {},
};

export default withTheme()(RangeField);
export { RangeField };
