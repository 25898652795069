import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const RowLink = ({ children, link, theme, className, isPreviewCell }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <>
      <MediaQuery query="(min-width: 768px)">
        <Link className={classNames('RowLink', className)} to={link}>
          {children}
          <style jsx>{staticStyles}</style>
          <style jsx>{dynamicStyles}</style>
        </Link>
      </MediaQuery>
      <MediaQuery query="(max-width: 768px)">
        {isPreviewCell ? (
          <Link className={classNames('RowLink', className)} to={link}>
            {children}
            <style jsx>{staticStyles}</style>
            <style jsx>{dynamicStyles}</style>
          </Link>
        ) : (
          children
        )}
      </MediaQuery>
    </>
  );
};

RowLink.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.object,
};

RowLink.defaultProps = {
  link: '',
  className: '',
  theme: {},
};

export default withTheme()(RowLink);
