import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CheckboxField {
    position: relative;
    margin-bottom: 10px;

    input[type='checkbox'] {
      width: auto;
      display: none;
      opacity: 1 !important;
    }

    label {
      cursor: pointer;
      font-size: rem(15px);
      position: relative;
      display: inline-block;
    }

    input:disabled + label {
      cursor: default !important;
      opacity: 1 !important;
    }
  }

  .CheckboxField__label {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .CheckboxField__label span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }

  .CheckboxField__label span:first-child {
    position: absolute;
    left: 0;
    top: 48%;
    width: rem(20px);
    height: rem(20px);
    transform: scale(1) translateY(-50%);
    vertical-align: middle;
    transition: all 0.2s ease;
    border-radius: 3px;
  }

  .CheckboxField__label span:first-child svg {
    position: absolute;
    width: rem(14px);
    height: rem(11px);
    top: rem(4px);
    left: rem(2px);
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: rem(16px);
    stroke-dashoffset: rem(16px);
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }

  .CheckboxField__label span:last-child {
    padding-left: rem(8px);
  }

  .CheckboxField input:checked + .CheckboxField__label span:first-child svg {
    stroke-dashoffset: 0;
  }
  
  .CheckboxField__link {
    position: relative;
    top: rem(4px);
    width: calc(100% - 30px);
    vertical-align: middle;
    @include respond-to(sm) {
      top: rem(3px);
    }
  }

  .CheckboxField__error {
    width: 100%;
    display: inline-block;
  }

  .CheckboxField {
    &__label-simple {
      display: flex !important;
    }
    
    &__checkbox {
      margin: 0 10px 0 0;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      /* css?? border: 1px solid //colors.lightGray.hex//; */
      transition: border 0.1s ease, background 0.1s ease;

      input:checked + .CheckboxField__label-simple & {
        /* css?? background: //colors.primary.hex//; */
        /* css?? border: 1px solid //colors.primary.hex//; */
      }

      &:hover {
        /* css?? border: 1px solid //colors.primary.hex//; */
      }

      svg {
        transition: opacity 0.3s ease;
        opacity: 0;

        input:checked + .CheckboxField__label-simple & {
          opacity: 1;
        }
      }
    }

    &__checkbox-text {}
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .CheckboxField {
      input:disabled + label {
        color: ${colors.gray.hex};
      }

      input[type='checkbox']:disabled + .CheckboxField__label span:first-child {
        border-color: ${colors.disabled.hex};
        background: ${colors.disabled.hex};
      }
    }

    .CheckboxField__label {
      padding-right: ${isRTL ? '2rem': '0'};
      padding-left: ${isRTL ? '0': '2rem'};
    }

    .CheckboxField__label span:first-child {
      border: 1px solid ${colors.lightGray.hex};
      right: ${isRTL ? '0': 'auto'};
      left: ${isRTL ? 'auto': '0'};
    }

    .CheckboxField__label:hover span:first-child {
      border-color: ${colors.primary.hex};
    }

    .CheckboxField input:checked + .CheckboxField__label span:first-child {
      background: ${colors.primary.hex};
      border-color: ${colors.primary.hex};
    }

    .CheckboxField__error {
      color: ${colors.error.hex};
    }

    .CheckboxField {
      &__checkbox {
        border: 1px solid ${colors.lightGray.hex};

        input:checked + .CheckboxField__label-simple & {
          background: ${colors.primary.hex};
          border: 1px solid ${colors.primary.hex};
        }

        &:hover {
          border: 1px solid ${colors.primary.hex};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
