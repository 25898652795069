import { connect } from 'react-redux';

import { getPayments, cancelWithdrawal, getPaymentsInvoice, resendPaymentConfirmationMessage } from '../../_redux';
import { PaymentsConfirmationPanel } from '../../_components';

export default connect(
  state => ({
    payments: state.client.payments.paymentsInfo,
    loading: state.client.payments.loadingPayments,
    cancelWithdrawalLoading: state.client.payments.cancelWithdrawalLoading,
    isConfirmationMessageResent: state.client.payments.isConfirmationMessageResent,
    confirmationMessageIsSending: state.client.payments.confirmationMessageIsSending,
    redirect: state.client.common.redirect,
    addons: state.interfaceConfig.addons,
  }),
  {
    loadPayments: id => getPayments.request(id),
    cancelWithdrawal: id => cancelWithdrawal.request(id),
    getPaymentsInvoice: id => getPaymentsInvoice.request(id),
    resendPaymentConfirmationMessage: id => resendPaymentConfirmationMessage.request(id),
  }
)(PaymentsConfirmationPanel);
