import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';

  .ValueSwitcherField {
    border: 1px solid #BFC7D7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    color: #0C0C3D;

    &__input {
      border-radius: 2px;
      width: calc(50% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 46px;
      cursor: pointer;

      &.checked {
        background-color: #FFFFFF;
        box-shadow:
          0px 2px 14px rgba(0, 84, 121, 0.12),
          0px 1.11187px 5.11023px rgba(0, 84, 121, 0.0827978),
          0px 0.886046px 2.48092px rgba(0, 84, 121, 0.0667549),
          0px 0.683467px 1.21619px rgba(0, 84, 121, 0.0532451),
          0px 0.400779px 0.480885px rgba(0, 84, 121, 0.0372022);
      }

      &:not(.checked):hover {
        background: linear-gradient(62.43deg, rgba(67, 182, 232, 0.2) 0%, rgba(51, 238, 199, 0.2) 100%), #FFFFFF;

        .ValueSwitcherField__text {
          color: #0AA1E2;
        }
      }

      &:not(.checked):active {
        background: none;

        .ValueSwitcherField__text {
          color: #0C0C3D;
        }
      }

      input[type="radio"] {
        padding: 0;
        border: none;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;

        cursor: pointer;
      }
    }

    &__text {
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
`

export { staticStyles }
