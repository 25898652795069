import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';

import { PaginationTable, RowLink } from 'common/components';

import { withTheme } from 'common/styling/theme';

import ClientsCellWithDropDown from './ClientsCellWithDropDown';
import { staticStyles, getDynamicStyles } from './style';

const PartnersTable = ({ data, onRowClick, getList, loading, history, location, params, theme }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: () => null,
      id: 'avatar',
      Cell: ({
        row: {
          original: { email, id },
        },
      }) => (
        <RowLink link={`/backoffice/users/${id}`} isPreviewCell>
          <Gravatar md5={md5(email)} size={35} />
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      Header: 'justFullNameSlashIb',
      accessor: 'full_name',
      Cell: ({
        row: {
          original: { fullName, ibAccounts, id },
        },
      }) => {
        return (
          <RowLink link={`/backoffice/users/${id}`} isPreviewCell>
            <div className="FullNameAndIbCell">
              <span className="FullNameAndIbCell__FullName">{fullName}</span>
              <div className="FullNameAndIbCell__IbAccounts">
                {ibAccounts &&
                  ibAccounts.length &&
                  ibAccounts.map(ibAccount => <span className="FullNameAndIbCell__IbAccount">{ibAccount}</span>)}
              </div>
            </div>
          </RowLink>
        );
      },
      isPreviewCell: true,
    },
    {
      Header: 'justEmail',
      accessor: 'email',
      Cell: ({
        row: {
          original: { email, id },
        },
      }) => (
        <RowLink link={`/backoffice/users/${id}`} isPreviewCell>
          {email}
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      Header: 'justPhone',
      accessor: 'phone',
      Cell: ({
        row: {
          original: { phone, id },
        },
      }) => <RowLink link={`/backoffice/users/${id}`}>{phone}</RowLink>,
    },
    {
      Header: 'justMasterIb',
      accessor: 'masterIb',
      Cell: ({
        row: {
          original: { masterIb, partnerAccount, id },
        },
      }) => (
        <RowLink link={`/backoffice/users/${id}`}>
          <div className="PartnersTable__masterIb">
            {masterIb ? (
              <>
                <span className="PartnersTable__masterIb-caption">{masterIb}</span>
                <span className="PartnersTable__masterIb-name">{partnerAccount}</span>
              </>
            ) : (
              '一'
            )}
          </div>
        </RowLink>
      ),
    },
    {
      Header: 'boPartnerClients',
      accessor: 'clients',
      Cell: ({
        row: {
          original: { clients, id },
        },
      }) => <RowLink link={`/backoffice/users/${id}`}>{clients}</RowLink>,
    },
    {
      Header: () => null,
      id: 'clients-list',
      Cell: ({
        row: {
          original: { ibAccounts },
        },
      }) => <ClientsCellWithDropDown ibAccounts={ibAccounts} />,
    },
  ];
  /* eslint-enable */

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="PartnersTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getList={getList}
        location={location}
        history={history}
        params={params}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        isNewTable
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PartnersTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PartnersTable.defaultProps = {
  theme: {},
};

export default withTheme()(PartnersTable);
export { PartnersTable };
