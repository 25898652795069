import React from 'react';
import PropTypes from 'prop-types';
import { MonitoringIcon } from 'crm/_newComponents/TaskIcon/icons';
import { EmailIcon, PhoneIcon, StarIcon, TimeIcon, UserIcon, WatchIcon } from './icons';

import { staticStyles } from './style';

const ICON_TYPES = ['EMAIL', 'PHONE', 'STAR', 'TIME', 'USER', 'WATCH', 'MAIL', 'CALL', 'MONITORING'];

const Components = {
  EMAIL: EmailIcon,
  MAIL: EmailIcon,
  CALL: PhoneIcon,
  PHONE: PhoneIcon,
  STAR: StarIcon,
  TIME: TimeIcon,
  USER: UserIcon,
  WATCH: WatchIcon,
  MONITORING: MonitoringIcon,
};

const TaskIcon = ({ type }) => {
  const Icon = Components[type];

  return (
    <div className="TaskIcon">
      {Icon ? <Icon /> : <span>{type}</span>}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TaskIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TaskIcon;
export { TaskIcon, ICON_TYPES };
