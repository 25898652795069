import React from 'react';
import { PageLayout } from 'common/components';
import { CampaignForm } from '../../_components';
import { createCampaign } from '../../_redux';

const AddCampaignPage = () => (
  <PageLayout captionSlug="justCampaigns">
    <CampaignForm initialValues={{ isActive: false, isDefault: false }} submitFunction={createCampaign} />
  </PageLayout>
);

export default AddCampaignPage;
export { AddCampaignPage };
