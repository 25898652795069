import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChangeEmailForm {
    margin-top: rem(20px);
    padding: rem(40px) rem(40px) 0 rem(40px);

    .ChangeEmailForm__caption {
      margin-bottom: rem(30px);
    }

    :global(.ReduxTextField) {
      width: 400px;
      margin-top: rem(15px);

      @include respond-to(sm) {
        width: 300px;
      }
    }

    :global(.DefaultButton) {
      margin-top: rem(15px);
    }
  }
`;

export { staticStyles };
