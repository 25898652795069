import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as actions from './actions';

const initialState = {
  members: {},
  groups: {},
};

const reducer = (state = initialState, action) => {
  let members;
  let groups;

  switch (action.type) {
    case actions.GET_STAFF_MEMBER_LIST[REQUEST]:
      return {
        ...state,
        members: {
          loading: true,
        },
      };
    case actions.GET_STAFF_MEMBER_LIST[SUCCESS]:
      return {
        ...state,
        members: {
          data: action.data,
        },
      };
    case actions.GET_STAFF_MEMBER_LIST[FAILURE]:
      return {
        ...state,
        members: {},
      };

    case actions.ADD_STAFF_MEMBER[SUCCESS]:
      members = state.members;
      if (members.data) {
        members.data.push(action.data);
      }

      return {
        ...state,
        members,
        groups: {},
      };

    case actions.saveStaffMember.SUCCESS:
      members = state.members;

      return {
        ...state,
        members: {
          data: members.data.map(m => (m.id === action.payload.id ? action.payload : m)),
        },
        groups: {},
      };

    case actions.REMOVE_STAFF_MEMBER[SUCCESS]:
      members = state.members;

      return {
        ...state,
        members: {
          data: members.data.filter(member => member.id !== action.data.id),
        },
        groups: {},
      };

    case actions.GET_STAFF_GROUP_LIST[REQUEST]:
      return {
        ...state,
        groups: {
          loading: true,
        },
      };
    case actions.GET_STAFF_GROUP_LIST[SUCCESS]:
      return {
        ...state,
        groups: {
          data: action.data,
        },
      };
    case actions.GET_STAFF_GROUP_LIST[FAILURE]:
      return {
        ...state,
        groups: {},
      };

    case actions.ADD_STAFF_GROUP[SUCCESS]:
      groups = state.groups;
      if (groups.data) {
        groups.data.push(action.data);
      }

      return {
        ...state,
        members: {},
        groups,
      };

    case actions.saveStaffGroup.SUCCESS:
      groups = state.groups;

      return {
        ...state,
        members: {},
        groups: {
          data: groups.data.map(g => (g.id === action.payload.id ? action.payload : g)),
        },
      };

    case actions.REMOVE_STAFF_GROUP[SUCCESS]:
      groups = state.groups;

      return {
        ...state,
        members: {},
        groups: {
          data: groups.data.filter(group => group.id !== action.data.id),
        },
      };
    default:
      return state;
  }
};

export default reducer;
