import css from 'styled-jsx/css';

const staticStyles = css.global`
  .UserWalletPaymentForm {
    &__Button {
      margin-top: 10px;
    }
  }
`;

export { staticStyles };
