import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const UserNoInfoPanel = ({ image, textId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="UserNoInfoPanel">
      <div className="UserNoInfoPanel__image-inner">
        <img src={image} alt={textId} />
      </div>
      <FormattedMessage id={textId}>{txt => <p>{txt}</p>}</FormattedMessage>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserNoInfoPanel.propTypes = {
  image: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

UserNoInfoPanel.defaultProps = {
  theme: {},
};

export default withTheme()(UserNoInfoPanel);
export { UserNoInfoPanel };
