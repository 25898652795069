import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseStaticSelect {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: column;

    &__Input {
      border: 0;
      background: transparent;
      width: 100%;
      height: 100%;
      padding-top: 9px;
      padding-left: 0;
    }

    &__NewOptions {
      position: relative;
      top: 4px;
      width: max-content;
      background: white;
      z-index: 4;
      width: 190%;
      padding: 16px;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      box-shadow: 0px 1.237px 2.714px 0px rgba(0, 0, 0, 0.02), 0px 3.127px 6.863px 0px rgba(0, 0, 0, 0.03),
        0px 6.38px 13.999px 0px rgba(0, 0, 0, 0.04), 0px 13.141px 28.836px 0px rgba(0, 0, 0, 0.05),
        0px 36px 79px 0px rgba(0, 0, 0, 0.07);

      &--mobile {
        max-width: 330px;
        padding: 0 16px 16px 16px;
        box-shadow: none;

        @include respond-to(xs) {
          max-width: 300px;
        }
      }
    }

    &__NewOption {
      display: flex;
      padding: 4px 12px;
      align-items: center;
      border-radius: 32px;
      font-size: 12px;
      line-height: 1.4;
      background: gray;
      color: white;

      &:hover {
        background: #ceecf9;
        color: black;
      }
    }

    &__Label {
      color: #0aa1e2;

      &--disabled {
        color: #cecece;
      }
    }

    &__Labels {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
      color: #0aa1e2;

      &--disabled {
        color: #cecece;
      }
    }

    &__LabelsSelected {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
    }

    &__LabelsSelectedCentered {
      flex: 1; /* Expand to fill remaining space */
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__LabelsAmount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 4px 0 4px;
    }

    &__NewOption--isSelected {
      background: #0aa1e2;
    }
  }
`;

export { staticStyles };
