import css from 'styled-jsx/css';

// FIXME: TO STABLE WIDTH

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InputDate {
    display: flex;
    padding-top: 20px;
    position: relative;
    width: 390px;
    &__Label {
      position: absolute;
      top: 0;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }
    &__InputWrapper {
      width: 100%;
      position: relative;
    }
    &__InputLabel {
      position: absolute;
      right: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: left;
      color: #696969;
    }

    &__Input {
      width: 100%;
      text-align: center;
      padding: 2px 8px 2px 8px;
      border: none;
      border-bottom: 0.5px solid #e9e9e9;
    }
    &__Delimetr {
      margin: 0 10px 0 10px;
      font-size: 20px;
      font-weight: 300;
      line-height: 20px;
    }
  }
`;
const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
