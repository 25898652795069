import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Card {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 20px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    padding: 45px 30px 15px 30px;
    margin-bottom: 20px;
    width: 32%;
    position: relative;
    margin-right: ${isRTL ? '0' : '1%'};
    margin-left: ${isRTL ? '1%' : '0'};
    direction: ltr;

    @include respond-to(xl) {
      padding: 30px 15px 15px 15px;
    }

    @include respond-to(sm) {
      width: 100%;
    }

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &__inner {
      position: relative;
      height: 0;
      border: none;
      padding-top: 55%;

      @include respond-to(xl) {
        padding-top: 60%;
      }

      @include respond-to(sm) {
        padding-top: 50%;
      }
    }

    &__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      flex-direction: column;
    }

    &__actions {
      position: absolute;
      right: ${isRTL ? 'auto' : '0'};
      left: ${isRTL ? '0' : 'auto'};
      top: -30px;
      display: flex;
      height: 18px;

      @include respond-to(md) {
        top: -20px;
      }
    }

    &__status {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__icon {
      max-width: 30px;
    }

    &__number {
      display: flex;
      margin-top: 14%;

      @include respond-to(xl) {
        margin-top: 13%;
      }

      @include respond-to(md) {
        margin-top: 15%;
      }

      &-part {
        width: 21%;
        text-align: center;
        background: rgba(197, 197, 197, 0.5);
        border-radius: 2px;
        padding: 10px 0;
        margin-right: 5%;

        &:last-child {
          margin-right: 0;
        }

        @include respond-to(xl) {
          padding: 0;
        }
      }
    }

    &__arrow-icon {
      position: relative;
      width: 14px;
      height: 16px;
      margin-left: rem(10px);

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: inline-block;
        transition: all 0.2s ease;
        width: 8px;
        top: 50%;
        transform: translateX(-50%);
        height: 1px;
      }
      &:before {
        left: 0;
        transform: rotate(45deg);
      }
      &:after {
        right: 0;
        transform: rotate(-45deg);
      }
    }

    &__tooltip {
      display: flex;
      cursor: pointer;

      :global(.Tooltip) {
        padding: rem(10px);
        font-size: rem(14px);
      }
    }

    &__info-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 50%;
      font-size: rem(14px);
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      @include respond-to(xl) {
        margin-top: 10px;
      }

      @include respond-to(sm) {
        margin-top: 10%;
      }
    }

    &__hint {
      margin-left: 30px;
      display: flex;

      @include respond-to(xl) {
        margin-left: 15px;
      }
    }

    &__comment {
      font-size: 18px;
      color: #777777;
      font-weight: normal;

      @include respond-to(xl) {
        font-size: 14px;
      }

      @include respond-to(md) {
        font-size: 12px;
      }
    }

    .CardTip {
      display: flex;
      flex-direction: column;
      max-width: 500px;
      padding: rem(20px);
      position: relative;
      line-height: 1.5;

      @include respond-to(sm) {
        padding: rem(15px);
      }

      &__title {
        font-size: rem(18px);
        font-weight: 500;
        margin-bottom: rem(25px);
      }

      &__text {
        font-size: rem(14px);
        margin-bottom: rem(15px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .Card {
      &__info-icon {
        color: ${colors.error.hex};
        border-color: ${colors.error.hex};
      }

      &__tooltip {
        :global(.Tooltip) {
          background-color: ${colors.white.hex} !important;
        }

        .__react_component_tooltip.type-dark.place-bottom:after {
          border-bottom-color: ${colors.white.hex} !important;
        }

        .__react_component_tooltip.type-dark.place-top:after {
          border-top-color: ${colors.white.hex} !important;
        }

        .__react_component_tooltip.type-dark.place-right:after {
          border-right-color: ${colors.white.hex} !important;
        }

        .__react_component_tooltip.type-dark.place-left:after {
          border-left-color: ${colors.white.hex} !important;
        }
      }
    }

    .CardTip {
      color: ${colors.black.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
