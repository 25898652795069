import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RadioSearch {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    user-select: none;

    &__icon {
      width: 15px;
      height: 15px;
      margin: 0 8px 0 0;
    }

    &__text {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

const getDynamicStyles = () => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
