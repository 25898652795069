import ArrowIcon from '../../TopMenu/_components/LanguageSelector/icons/arrow';
import CheckIcon from './checkIcon';
import RetryIcon from './retryIcon';
import LoadingIcon from './loadingIcon';

export { ArrowIcon, CheckIcon, RetryIcon, LoadingIcon };

export const buttonIcons = {
  previous: ArrowIcon,
  next: ArrowIcon,
  check: CheckIcon,
  retry: RetryIcon,
  loading: LoadingIcon,
};
