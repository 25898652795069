import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import { NoItemsPanel, PageContentPreloader, PaymentsTable, SearchPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';

import { getUserPayments } from '../../_redux';

class UserPayments extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search);
    query.page_size = query.page_size ? query.page_size : 10;
    props.getUserPayments({ id: props.match.params.id, search: query });
  }

  render() {
    const { payments, loadingPayments, getUserPayments, location, history, match, intl, addons } = this.props;

    const operationToRoute = {
      deposit: 'deposits',
      wallet_deposit: 'deposits',
      wallet_withdraw: 'withdrawals/list',
      withdraw: 'withdrawals/list',
    };

    const getList = query => getUserPayments({ id: match.params.id, ...query });

    const handleRowClick = (id, rowInfo) =>
      history.push(`/backoffice/${operationToRoute[rowInfo.original.operation]}/${id}`);

    const isWalletEnabled = addons.includes('walletAccount');

    const filters = [
      {
        title: 'justStatus',
        accessor: '_status',
        options: [
          { value: '', label: intl.formatMessage({ id: 'justAll' }) },
          { value: 'money_waiting', label: intl.formatMessage({ id: 'status_money_waiting' }) },
          {
            value: 'waiting_confirmation',
            label: intl.formatMessage({ id: 'status_waiting_confirmation' }),
          },
          { value: 'rejected', label: intl.formatMessage({ id: 'status_rejected' }) },
          { value: 'archived', label: intl.formatMessage({ id: 'status_archived' }) },
          { value: 'canceled', label: intl.formatMessage({ id: 'status_canceled' }) },
          { value: 'failed', label: intl.formatMessage({ id: 'status_failed' }) },
          { value: 'processing', label: intl.formatMessage({ id: 'status_processing' }) },
          { value: 'received', label: intl.formatMessage({ id: 'status_received' }) },
          { value: 'payout', label: intl.formatMessage({ id: 'status_payout' }) },
          { value: 'done', label: intl.formatMessage({ id: 'status_done' }) },
          { value: 'deposited', label: intl.formatMessage({ id: 'status_deposited' }) },
        ],
      },
      {
        title: 'justType',
        accessor: 'operation',
        options: [
          { value: '', label: intl.formatMessage({ id: 'justAll' }) },
          { value: 'deposit', label: intl.formatMessage({ id: 'justDeposit' }) },
          { value: 'withdraw', label: intl.formatMessage({ id: 'justWithdraw' }) },
          ...(isWalletEnabled
            ? [{ value: 'wallet_deposit', label: intl.formatMessage({ id: 'justWalletDeposit' }) }]
            : []),
          ...(isWalletEnabled
            ? [{ value: 'wallet_withdraw', label: intl.formatMessage({ id: 'justWalletWithdraw' }) }]
            : []),
        ],
      },
    ];

    const query = queryString.parse(location.search);

    return (
      <PageContentPreloader ready={loadingPayments} type="smallLines" firstLaunchOnly>
        {!payments.count && !Object.keys(query).filter(obj => obj !== 'tab').length ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getList}
            filters={filters}
            params={{ id: match.params.id, search: query }}
          />
        )}
        {payments.count ? (
          <PaymentsTable
            data={payments}
            isBackofficePage
            loading={!loadingPayments}
            onRowClick={handleRowClick}
            getList={getList}
            location={location}
            history={history}
            params={{ id: match.params.id, search: query }}
          />
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={
              Object.keys(query).filter(obj => obj !== 'tab').length
                ? 'noPaymentsMatchingTheParameters'
                : 'noPaymentsYet'
            }
          />
        )}
      </PageContentPreloader>
    );
  }
}

UserPayments.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getUserPayments: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  loadingPayments: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  addons: PropTypes.array,
};

UserPayments.defaultProps = {
  addons: [],
};

const ConnectedUserPayments = connect(
  state => ({
    payments: state.backoffice.users.payments,
    loadingPayments: state.backoffice.users.loadingPayments,
    addons: state.interfaceConfig.addons,
  }),
  {
    getUserPayments: (id, search) => getUserPayments.request(id, search),
  }
)(injectIntl(UserPayments));

export default withRouter(ConnectedUserPayments);
export { UserPayments };
