import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageContentPreloader, PageLayout } from 'common/components';
import { PromoMaterialsForm } from '../../_components';
import { getCampaignList, createPromo } from '../../_redux';

const AddPromoPage = ({ campaignList, campaignListIsLoaded, getCampaignList, availableLangs }) => {
  useEffect(() => {
    getCampaignList();
  }, [getCampaignList]);

  return (
    <PageLayout captionSlug="addPromo">
      <PageContentPreloader ready={campaignListIsLoaded} type="bigLine">
        <PromoMaterialsForm availableLangs={availableLangs} campaignList={campaignList} submitFunction={createPromo} />
      </PageContentPreloader>
    </PageLayout>
  );
};

AddPromoPage.propTypes = {
  campaignList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  campaignListIsLoaded: PropTypes.bool.isRequired,
  getCampaignList: PropTypes.func.isRequired,
  availableLangs: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    campaignList: state.backoffice.promo.campaignList,
    campaignListIsLoaded: state.backoffice.promo.campaignListIsLoaded,
    availableLangs: state.interfaceConfig.availableLangs,
  }),
  {
    getCampaignList: data => getCampaignList.request(data),
  }
)(AddPromoPage);
export { AddPromoPage };
