import React from 'react';
import PropTypes from 'prop-types';

const LogsTask = ({ description }) => <div className="TabLogs__item-description">{description}</div>;

LogsTask.propTypes = {
  description: PropTypes.string.isRequired,
};

export default LogsTask;
