import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PeriodicallyReportsPage__caption {
    margin: rem(40px 0 20px);
    font-size: rem(18px);
  }
`;

export { staticStyles };
