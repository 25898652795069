import css from 'styled-jsx/css'

const staticStyles = css`
  .AccountTypesPanel {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media only screen and (max-width: 1200px) {
      justify-content: space-between;
      margin-top: 25px;
    }
  }
`

export default staticStyles
