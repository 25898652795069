import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .UserSumSubDocument {
    :global(.LightBoxImage) {
      width: auto;
      max-width: auto;
      height: auto;
    }

    :global(.LightBoxImage .LightBoxImage__inner img) {
      width: auto !important;
      height: auto !important;
      max-width: 180px !important;
      max-height: 115px !important;

      @include respond-to(sm) {
        max-width: 91px !important;
        max-height: 58px !important;
      }
    }
  }
`;

export { staticStyles };
