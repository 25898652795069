import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { staticStyles } from './style';

const SmallAreaChart = ({ seriesData, categoriesData }) => {
  const options = useMemo(
    () => ({
      chart: {
        fontFamily: 'ubuntu-font, sans-serif',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        foreColor: '#fff',
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.5,
      },
      xaxis: {
        type: 'numeric',
        labels: {
          show: false,
        },
        categories: categoriesData,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        type: 'numeric',
        labels: {
          show: false,
        },
      },
      colors: ['#0AA1E2'],
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          show: false,
          formatter(value) {
            return `${value}%`;
          },
          title: {
            formatter() {
              return '';
            },
          },
        },
      },
      grid: {
        show: false,
      },
      plotOptions: {
        area: {
          fillTo: 'end',
        },
      },
    }),
    [categoriesData]
  );

  const series = useMemo(
    () => [
      {
        name: 'series1',
        data: seriesData,
      },
    ],
    [seriesData]
  );

  return (
    <div className="SmallAreaChart">
      <Chart className="AreaChart" options={options} series={series} type="area" height="100" />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

SmallAreaChart.propTypes = {
  seriesData: PropTypes.array,
  categoriesData: PropTypes.array,
};

SmallAreaChart.defaultProps = {
  seriesData: [],
  categoriesData: [],
};

export default SmallAreaChart;
export { SmallAreaChart };
