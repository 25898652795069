import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_FILES = createRequestTypes('GET_FILES');
export const getFiles = {
  request: search => action(GET_FILES[REQUEST], { search }),
  success: data => action(GET_FILES[SUCCESS], { data }),
  failure: error => action(GET_FILES[FAILURE], { error }),
};

export const GET_FILE = createRequestTypes('GET_FILE');
export const getFile = {
  request: id => action(GET_FILE[REQUEST], { id }),
  success: data => action(GET_FILE[SUCCESS], { data }),
  failure: error => action(GET_FILE[FAILURE], { error }),
};

export const createFile = createFormAction('CREATE_FILE');

export const uploadFiles = createFormAction('UPLOAD_FILES');

export const editFile = createFormAction('EDIT_FILE');

export const DELETE_FILE = createRequestTypes('DELETE_FILE');
export const deleteFile = {
  request: (id, queryParams) => action(DELETE_FILE[REQUEST], { id, queryParams }),
  success: () => action(DELETE_FILE[SUCCESS]),
  failure: error => action(DELETE_FILE[FAILURE], { error }),
};

export const GET_FILE_EXTENSIONS = createRequestTypes('GET_FILE_EXTENSIONS');
export const getFileExtensions = {
  request: () => action(GET_FILE_EXTENSIONS[REQUEST]),
  success: data => action(GET_FILE_EXTENSIONS[SUCCESS], { data }),
  failure: error => action(GET_FILE_EXTENSIONS[FAILURE], { error }),
};

export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL';
export const closeErrorModal = () => action(CLOSE_ERROR_MODAL);
