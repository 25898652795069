import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { removeUserPartnerCode } from '../../_redux';

import { staticStyles } from './style';

const RemoveUserPartnerCodeForm = ({ user, handleSubmit, submitting, invalid, dispatch, closeModal }) => {
  const submit = handleSubmit(() => removeUserPartnerCode({ userId: user.id }, dispatch));

  return (
    <form className="RemoveUserPartnerCodeForm" onSubmit={submit}>
      <FormattedMessage id="removePartnerCodeDesc">{txt => <p>{txt}</p>}</FormattedMessage>
      <div className="RemoveUserPartnerCodeForm__buttons-inner">
        <div className="RemoveUserPartnerCodeForm__button-inner">
          <DefaultButton
            type="submit"
            textId="justYes"
            butColor="error"
            loading={submitting}
            disabled={invalid || submitting}
          />
        </div>
        <div className="RemoveUserPartnerCodeForm__button-inner">
          <DefaultButton type="button" textId="justNo" filled onClick={closeModal} />
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

RemoveUserPartnerCodeForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'remove-user-partner-code',
})(withTheme()(RemoveUserPartnerCodeForm));
export { RemoveUserPartnerCodeForm };
