import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CreateContactForm {
    &__row {
      display: flex;
      justify-content: space-between;
      margin: 0 0 10px 0;

      :global(.TextFormField) {
        width: 48%;
      }
    }

    &__submit-wrap {
      text-align: center;
      margin: 18px 0 0 0;
    }

    :global(&__submit) {
      :global(span) {
        font-weight: 300;
        letter-spacing: 0.2px;
        font-size: 14px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
