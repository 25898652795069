/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getReportTemplates } from 'common/_redux';
import { PageContentPreloader, NoItemsPanel } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noReportImage from 'common/images/noFilesDownload.svg';
import { OrderReportForm } from '../../_components';

interface MatchParams {
  name: string;
}

interface IReportTemplate {
  title?: string;
  slug?: string;
  key?: string;
  form?: any;
}

interface IReportsPageProps extends RouteComponentProps<MatchParams> {
  getReportTemplates: any;
  reportTemplates: Array<IReportTemplate>;
  reportsTemplatesAreLoaded: boolean;
}

const OrderReportPage: React.FC<IReportsPageProps> = ({
  history,
  match,
  reportTemplates,
  reportsTemplatesAreLoaded,
  getReportTemplates,
}: IReportsPageProps) => {
  const isBackOfficePage = match.url.includes('backoffice');
  useEffect(() => {
    getReportTemplates({ isBackOfficePage });
  }, [getReportTemplates, isBackOfficePage]);
  const availableReports = isBackOfficePage
    ? reportTemplates
    : reportTemplates.filter((item: IReportTemplate) => item.slug !== 'full_company');

  const handleSubmitSuccess = () => {
    if (isBackOfficePage) {
      history.push('/backoffice/partners/reports?tab=saved');
    } else {
      history.push('/partnership/reports?tab=saved');
    }
  };

  return (
    <PageContentPreloader ready={reportsTemplatesAreLoaded} firstLaunchOnly>
      {availableReports.length ? (
        <OrderReportForm
          availableReports={availableReports}
          isBackOfficePage={isBackOfficePage}
          onSubmitSuccess={handleSubmitSuccess}
        />
      ) : (
        <NoItemsPanel icon={noReportImage} captionSlug="noReportTemplatesYet" />
      )}
    </PageContentPreloader>
  );
};

export default compose<React.FC<IReportsPageProps>>(
  withRouter,
  withTheme(),
  connect(
    (state: any) => ({
      reportTemplates: state.common.reportTemplates,
      reportsTemplatesAreLoaded: state.common.reportsTemplatesAreLoaded,
    }),
    {
      getReportTemplates: data => getReportTemplates.request(data),
    }
  )
)(OrderReportPage);
export { OrderReportPage };
