import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .AccountLeverageChangeForm {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    @include respond-to(sm) {
      max-width: 100%;
    }
  }
`

export { staticStyles }
