import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StrategyInfoPanel {
    :global(.InfoPanel) {
      margin-bottom: 0;

      &::after {
        content: none;
      }
    }
    :global(.Collapsible) {
      margin: 0;

      &:last-child {
        margin-bottom: rem(30px) !important;
      }

      .Collapsible__content {
        margin-bottom: 0;
      }

      .InfoPanel::after {
        content: none;
      }
    }

    &__info {
      display: flex;
      margin-bottom: rem(30px);
      background-color: #FFFFFF;

      &.description {
        .StrategyInfoPanel__info-content {
          &:first-child {
            max-width: 310px;
            width: 100%;
            flex-shrink: 0;
          }
        }
      }

      &:not(.description) {
        .StrategyInfoPanel__info-content {
          width: 100%;
        }
      }

      h3 {
        font-size: 20px;
        line-height: 23px;
        color: #1D1D1D;
        padding: 25px 25px 20px 25px;
        background-color: #FFFFFF;
      }

      .InfoPanel {
        padding: 0 25px 25px 25px;
      }
    }

    &__description {
      padding: 0 25px 25px 25px;
      background-color: #FFFFFF;
      font-size: 15px;

      @include respond-to(sm) {
        padding: 12px 22px 22px 22px;
      }
    }

    .Collapsible {
      position: relative;

      @include max-width(767) {
        margin: 0 !important;

        &::after {
          content: '';
          position: absolute;
          top: -2px;
          left: 0;
          width: 100%;
          height: 2px;
        }
      }

      &__header {
        font-size: 20px;
        line-height: 23px;
        color: #1D1D1D;
        padding: 25px 25px 20px 25px;

        @include respond-to(sm) {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #005479;
          padding: 15px 22px 15px 22px;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
          margin: 0 0 1px 0;
        }
      }

      .InfoPanel {
        padding: 0 25px 25px 25px;

        @include respond-to(sm) {
          padding: 12px 22px 22px 22px;
        }

        &__list {
          @include respond-to(sm) {
            grid-template-columns: 1fr;
            grid-row-gap: 15px;
          }
        }

        &__cell {
          @include respond-to(sm) {
            display: flex;
            flex-direction: row;
          }
        }

        &__cell-caption {
          font-size: 13px;
          line-height: 18px;
          color: #777777;

          @include respond-to(sm) {
            width: 50%;
            margin: 0 10px 0 0;
          }
        }

        &__cell-value {
          font-size: 15px;
          line-height: 20px;
          color: #1D1D1D;

          @include respond-to(sm) {
            width: 50%;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .StrategyInfoPanel  {
      @include max-width(767) {
        .Collapsible::after {
          background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
