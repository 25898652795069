import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InternalTransferConfirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(25px);
    padding: rem(0 15px);

    .InternalTransferConfirmation__line {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: rem(10px 0);

      &:last-child {
        margin-top: rem(30px);
      }

      span {
        flex: 1;
      }

      :global(.DefaultButton) {
        &:first-child {
          margin-right: rem(40px);

          @include respond-to(xs) {
            margin-right: rem(10px);
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InternalTransferConfirmation__title {
      color: ${colors.gray.hex};
    }
    .InternalTransferConfirmation__line--error {
      color: ${colors.error.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
