import React, { useState, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import { withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { changeReassignTaskStatus } from 'crm/_redux';

import { FormMessageHOC } from 'common';
import { withTheme } from 'common/styling/theme';

import { BaseButton, TextField } from 'crm/_components';

import { staticStyles } from './style';

const SelectActionAndTextField = ({ taskId, setIsEditOpen, location, intl }) => {
  const dispatch = useDispatch();
  const [textValue, setTextValue] = useState('');
  const [isTaskStatusCurrentlyChanging, setIsTaskStatusCurrentlyChanging] = useState(false);

  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const isTaskStatusChanging = useSelector(state => state.crm.common.isTaskStatusChanging);

  useEffect(() => {
    if (!isTaskStatusChanging) {
      setIsTaskStatusCurrentlyChanging(false);
    }
    if (isTaskStatusCurrentlyChanging) {
      setIsEditOpen(false);
    }
  }, [isTaskStatusChanging]);

  const onAcceptButtonClick = () => {
    dispatch(changeReassignTaskStatus.request({ status: 'accepted', comment: textValue, taskId, search: query }));
    setIsTaskStatusCurrentlyChanging(true);
  };
  const onDeclineButtonClick = () => {
    dispatch(changeReassignTaskStatus.request({ status: 'declined', comment: textValue, taskId, search: query }));
    setIsTaskStatusCurrentlyChanging(true);
  };
  return (
    <div className="SelectActionAndTextField">
      <div className="SelectActionAndTextField__Buttons">
        <BaseButton
          name="accept"
          onClick={onAcceptButtonClick}
          className="SelectActionAndTextField__Button"
          fill
          textId="justAccept"
          noBorder
          color="success"
          disabled={!isTaskStatusChanging && !textValue}
        />
        <BaseButton
          name="decline"
          onClick={onDeclineButtonClick}
          className="SelectActionAndTextField__Button"
          fill
          textId="justDecline"
          noBorder
          color="error"
          disabled={!isTaskStatusChanging && !textValue}
        />
      </div>
      <TextField
        externalClass="SelectActionAndTextField__TextField"
        placeholder={intl.formatMessage({ id: 'justReasonRequired' })}
        value={textValue}
        onChange={e => setTextValue(e.target.value)}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

SelectActionAndTextField.propTypes = {
  taskId: PropTypes.number.isRequired,
  setIsEditOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(injectIntl, withTheme(), withRouter, FormMessageHOC)(SelectActionAndTextField);
