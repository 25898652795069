import css from 'styled-jsx/css';
import { isRTL, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserHistoryPagination {
    width: 49%;
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      width: 100%;
    }
    .rmp-popup {
      right: -9rem;
    }
    &__pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ActionsButton {
        width: rem(10px);
        height: rem(10px);
        transform: rotate(45deg);

        &:first-child {
          margin-right: rem(5px);
        }

        &:nth-child(2) {
          margin-right: rem(30px);
        }
      }
    }
  }
  .UserHistoryPagination__pagination-nav {
    display: flex;
    gap: 10px;
  }
  .UserHistoryPagination__select-wrap {
    position: relative;
    cursor: pointer;

    .UserHistoryPagination__select {
      width: 60px;
      padding: rem(10px);
      cursor: pointer;
    }

    &:after {
      content: '';
      position: absolute;
      width: rem(10px);
      height: rem(10px);
      top: 40%;
      right: ${isRTL ? 'auto' : '15%'};
      left: ${isRTL ? '15%' : 'auto'};
      transform: rotate(-45deg);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserHistoryPagination__pagination-nav {
      .ActionsButton {
        border: solid ${rgba([colors.primary.rgb, 0.7])};
        &:first-child {
          &:disabled {
            border: solid ${colors.gray.hex};
            border-width: 0 0 1px 1px;
          }
          border-width: 0 0 1px 1px;
        }

        &:nth-child(2) {
          &:disabled {
            border: solid ${colors.gray.hex};
            border-width: 1px 1px 0 0;
          }
          border-width: 1px 1px 0 0;
        }
      }
    }
    .UserHistoryPagination__select-wrap {
      border: 1px solid ${colors.lightGray.hex};

      .UserHistoryPagination__select {
        background-color: transparent;
        border: none;
      }
      &:after {
        border: solid ${colors.gray.hex};
        border-width: 0 0 1px 1px;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
