import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ColumnChart__wrapper {
    :global(.apexcharts-legend) {
      padding: 0 !important;
      overflow: hidden !important;
      bottom: 0 !important;
      justify-content: flex-end !important;
      :global(.apexcharts-legend-series) {
        &:first-child {
          display: none;
        }
        :global(.apexcharts-legend-marker) {
          width: 15px !important;
          height: 10px !important;
          margin-right: 6px !important;
          top: 2px !important;
        }
      }
    }

      :global(.apexcharts-series) {
        &:nth-child(2) {
          :global(path) {
            &:nth-child(1) {
              transform: scaleX(0.5) translateX(13%);
            }
            &:nth-child(2) {
              transform: scaleX(0.5) translateX(36%);
            }
            &:nth-child(3) {
              transform: scaleX(0.5) translateX(59%);
            }
            &:nth-child(4) {
              transform: scaleX(0.5) translateX(82%);
            }
        }
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .ColumnChart__wrapper {
      :global(.apexcharts-yaxis-label) {
        fill: ${colors.lightGray.hex};
        position: relative !important;
        right: 2px !important;
      }
      :global(.apexcharts-legend) {
        :global(.apexcharts-legend-series) {
          :global(.apexcharts-legend-text) {
            color: ${colors.gray.hex} !important;
          }
        }
      }

      :global(.apexcharts-series) {
        &:nth-child(2) {
          :global(path) {
            fill: ${colors.primary.hex} !important;
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
