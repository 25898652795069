import css from "styled-jsx/css";
import { isRTL } from "common/styling/theme";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .CampaignsPage__create-inner {
    :global(.DefaultButton) {
      position: absolute;
      top: rem(-64px);
      right: ${isRTL ? "auto" : "0"};
      left: ${isRTL ? "0" : "auto"};
      @include respond-to(sm) {
        top: -55px;
      }
    }
  }
`;

export { staticStyles };
