import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ActionsTab {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    @include respond-to(xs) {
      flex-direction: column;
    }

    &__filters-left {
      max-width: 491px;
      flex-grow: 1;
      gap: 12px;
      display: flex;

      @include respond-to(xs) {
        display: grid;
        grid-template-columns: 1fr 0fr;

        :global(.BaseStaticSelect) {
          order: 1;
        }

        :global(.CRMSearchBar) {
          order: 3;
        }
      }
    }

    &__contacts {
      @include respond-to(xs) {
        order: 4;
      }
    }

    &__uploadContacts-mobile {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #0aa1e2;
    }

    &__filter-choose {
      padding: 10px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid #f3f3f3;
      background: #fff;
      cursor: pointer;

      @include respond-to(xs) {
        order: 2;
      }
    }

    &__filters-right {
      flex-grow: 1;
      gap: 24px;
      display: flex;
      justify-content: flex-end;
      @include respond-to(xs) {
        flex-direction: column;
        row-gap: 16px;
      }
    }

    :global(.CustomFilterSelect) {
      min-width: 150px;
      @include respond-to(xs) {
        min-width: 100%;
      }
    }
  }

  .ActionsTab__ModalButtons {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  :global(.FilterChooseModal) {
    :global(.BasicModal__inner) {
      padding: 24px 0 !important;
    }
  }

  :global(.FilterChooseModal.ReactModal__Content) {
    padding-bottom: 0 !important;
  }
`;

export { staticStyles };
