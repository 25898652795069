import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const StopIcon = ({ grayColor }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <path
      d="M11 0C4.934 0 0 4.935 0 11s4.934 11 11 11 11-4.935 11-11S17.066 0 11 0zm0 21.083C5.44 21.083.917 16.56.917 11 .917 5.44 5.44.917 11 .917 16.56.917 21.083 5.44 21.083 11c0 5.56-4.523 10.083-10.083 10.083z"
      fill={grayColor.hex}
    />
    <path
      d="M15.125 6.417h-8.25a.458.458 0 00-.458.458v8.25c0 .254.205.459.458.459h8.25a.458.458 0 00.459-.459v-8.25a.458.458 0 00-.459-.458zm-.458 8.25H7.333V7.333h7.334v7.334z"
      fill={grayColor.hex}
    />
  </svg>
);

StopIcon.propTypes = {
  grayColor: colorType,
};

StopIcon.defaultProps = {
  grayColor: {},
};

const ThemedStopIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(StopIcon);

export default ThemedStopIcon;
