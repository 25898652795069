import { combineReducers } from 'redux';

import { LOGOUT_SUCCESS } from 'auth';

import { reducer as staffManagement } from '../staff-management/_redux';
import { reducer as withdrawRequests } from '../withdraw-requests/_redux';
import { reducer as closedLoopPolicy } from '../closed-loop-policy/_redux';
import { reducer as interfaceCustomization } from '../customization/_redux';
import { reducer as filesManagement } from '../files/_redux';
import { reducer as fileUploader } from '../_components/file-uploader/_redux';
import { reducer as dynamics } from '../dynamics/_redux';
import { reducer as faq } from '../faq/_redux';
import { reducer as banners } from '../banners/_redux';
import { reducer as managers } from '../managers-system/_redux';
import { reducer as payments } from '../payment-system/_redux';

const reducers = combineReducers({
  staffManagement,
  withdrawRequests,
  closedLoopPolicy,
  interfaceCustomization,
  fileUploader,
  filesManagement,
  faq,
  banners,
  managers,
  payments,
  dynamics,
});

const reducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return reducers(state, action);
};

export default reducer;
