import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentNote {
    margin-bottom: 2px;
    &:last-child {
      margin: 0;
      border: none;
    }
  }

  .PaymentNote__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: #fff;
    padding: rem(20px);
  }

  .PaymentNote__top-line {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, minmax(200px, 1fr)) minmax(100px, 0.3fr) minmax(100px, 0.2fr);
    margin-bottom: rem(10px);
    border-bottom: 1px solid;
    padding-bottom: rem(20px);

    @include respond-to(sm) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    :global(span) {
      &:first-child {
        font-size: 11px;
        text-transform: uppercase;
        margin-bottom: 3px;
        display: block;
      }
    }
  }

  .PaymentNote__createdBy {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include respond-to(sm) {
      margin-bottom: rem(20px);
    }

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      overflow: hidden;
    }
    .PaymentNote__line {
      @include respond-to(sm) {
        display: none;
      }
    }
  }

  .PaymentNote__line {
    padding: rem(0 5px);
    @include respond-to(sm) {
      margin-bottom: rem(20px);
    }
  }

  .PaymentNote__avatar {
    line-height: 0;
    margin-right: rem(15px);
    :global(img) {
      border-radius: 50%;
      width: rem(50px) !important;
      height: rem(50px) !important;
    }
  }

  .PaymentNote__title {
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      overflow: hidden;
    }
  }

  .PaymentNote__bottom-line {
    padding: rem(0 5px);
    :global(span) {
      font-size: rem(13px);
    }
    pre {
      word-break: break-all;
    }
  }

  .PaymentNote__data-action {
    @include respond-to(sm) {
      margin-bottom: 0 !important;
    }
  }

  .PaymentNote__files {
    margin-top: rem(20px);
    padding: rem(0 5px);
    :global(span) {
      font-size: rem(13px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .PaymentNote {
      border-color: ${colors.lightGray.hex};
    }

    .PaymentNote__top-line {
      border-bottom-color: ${colors.lightGray.hex};
      :global(span) {
        &:first-child {
          color: ${colors.gray.hex};
        }
      }
    }

    .PaymentNote__bottom-line {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }

    .PaymentNote__files {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
