import FileDownload from 'js-file-download';
import { SubmissionError } from 'redux-form';
import { call, fork, put, take, delay } from 'redux-saga/effects';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';
import { getTradingAccountListSaga, getAccountsPrefetchSaga } from '../../_redux/commonSagas/sagas';

import * as ActionTypes from './actions';
import * as api from '../api';

export function* createInvestStrategySaga({ ...values }) {
  try {
    const { data, status } = yield call(api.createInvestStrategy, {
      ...values,
    });
    if (status < 300) {
      yield put(ActionTypes.createInvestStrategy.success(data));
      yield call(getAccountsPrefetchSaga);
    } else {
      const error = data.error ? { _error: data.error } : data;
      yield put(ActionTypes.createInvestStrategy.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createInvestStrategy.failure());
    throw e;
  }
}

export function* getRammStrategyScriptSaga({ strategyId, ...values }) {
  try {
    const { data, status, headers } = yield call(api.getRammStrategyScript, strategyId, { ...values });
    const fileName = headers['content-disposition'].replace('attachment; filename=', '').replaceAll('"', '');

    FileDownload(data, fileName);
    if (status < 300) {
      yield put(ActionTypes.getRammStrategyScript.success(data));
      yield call(getAccountsPrefetchSaga);
    } else {
      yield put(ActionTypes.getRammStrategyScript.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getRammStrategyScript.failure());
    throw e;
  }
}

export function* getRammStrategyScriptWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.getRammStrategyScript.REQUEST);
    yield call(getRammStrategyScriptSaga, payload);
  }
}

export function* getMyInvestStrategiesSaga({ isActive, search }) {
  try {
    const { status, data } = yield call(api.getMyInvestStrategies, { isActive, ...search });
    if (status < 300) {
      yield put(ActionTypes.getMyInvestStrategies.success(data));
    } else {
      yield put(ActionTypes.getMyInvestStrategies.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getMyInvestStrategies.failure());
    throw e;
  }
}

export function* getMyInvestStrategiesWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_MY_INVEST_STRATEGIES[REQUEST]);
    yield call(getMyInvestStrategiesSaga, data);
  }
}

export function* getInvestStrategiesSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInvestStrategies, search);
    if (status < 300) {
      yield put(ActionTypes.getInvestStrategies.success(data));
    } else {
      yield put(ActionTypes.getInvestStrategies.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestStrategies.failure());
    throw e;
  }
}

export function* getInvestStrategiesWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_STRATEGIES[REQUEST]);
    yield call(getInvestStrategiesSaga, data);
  }
}

export function* setInvestStrategyActionSaga({ strategyId, action }) {
  try {
    const { data, status } = yield call(api.setInvestStrategyAction, strategyId, action);
    if (status < 300) {
      yield put(ActionTypes.setInvestStrategyAction.success(data));
      yield delay(1000);
      yield call(getMyInvestStrategiesSaga, { isActive: 1 });
    } else {
      const error = data?.error ?? '';
      yield put(acceptError(error, true));
      yield put(ActionTypes.setInvestStrategyAction.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setInvestStrategyAction.failure());
    throw e;
  }
}

export function* setInvestStrategyActionWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.SET_INVEST_STRATEGY_ACTION[REQUEST]);
    yield call(setInvestStrategyActionSaga, data);
  }
}

export function* getInvestmentsSaga({ isActive, search }) {
  try {
    const { status, data } = yield call(api.getInvestments, { isActive, ...search });
    if (status < 300) {
      yield put(ActionTypes.getInvestments.success(data));
    } else {
      yield put(ActionTypes.getInvestments.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInvestments.failure());
  }
}

export function* getInvestmentsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVESTMENTS[REQUEST]);
    yield call(getInvestmentsSaga, data);
  }
}

export function* getInvestmentsRequestsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInvestmentsRequests, search);
    if (status < 300) {
      yield put(ActionTypes.getInvestmentsRequests.success(data));
    } else {
      yield put(ActionTypes.getInvestmentsRequests.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInvestmentsRequests.failure());
  }
}

export function* getInvestmentsRequestsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVESTMENTS_REQUESTS[REQUEST]);
    yield call(getInvestmentsRequestsSaga, data);
  }
}

export function* closeInvestmentRequestSaga({ requestId, search }) {
  try {
    const { data, status } = yield call(api.closeInvestmentRequest, requestId);

    if (status < 300) {
      yield put(ActionTypes.closeInvestmentRequest.success(data));
      yield call(getInvestmentsRequestsSaga, { search });
    } else {
      yield put(ActionTypes.closeInvestmentRequest.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.closeInvestmentRequest.failure());
    throw e;
  }
}

export function* closeInvestmentRequestWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.closeInvestmentRequest[REQUEST]);
    yield call(closeInvestmentRequestSaga, payload);
  }
}

export function* getStrategyInvestmentsSaga({ strategyId, isActive, search }) {
  try {
    const { status, data } = yield call(api.getStrategyInvestments, strategyId, {
      isActive,
      ...search,
    });
    if (status < 300) {
      yield put(ActionTypes.getStrategyInvestments.success(data));
    } else {
      yield put(ActionTypes.getStrategyInvestments.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getStrategyInvestments.failure());
  }
}

export function* getStrategyInvestmentsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_STRATEGY_INVESTMENTS[REQUEST]);
    yield call(getStrategyInvestmentsSaga, data);
  }
}

export function* addStrategyInvestmentsSaga({ strategy, ...values }) {
  try {
    const { data, status } = yield call(api.addStrategyInvestments, {
      strategy,
      ...values,
    });

    if (status < 300) {
      yield put(ActionTypes.addStrategyInvestments.success(data));
      yield call(getStrategyInvestmentsSaga, { strategyId: strategy, isActive: '1', search: {} });
    } else {
      let error;
      if (data.error) {
        error = { _error: data.error };
      } else {
        // TODO: Разобраться с приходящими данными
        error = data.detail ? { _error: data.detail } : data;
      }
      yield put(ActionTypes.addStrategyInvestments.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.addStrategyInvestments.failure());
    throw e;
  }
}

export function* addStrategyInvestmentsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.addStrategyInvestments.REQUEST);
    yield call(addStrategyInvestmentsSaga, payload);
  }
}

export function* retrieveInvestStrategySaga({ strategyId }) {
  try {
    const { status, data } = yield call(api.retrieveInvestStrategy, strategyId);
    if (status < 300) {
      yield put(ActionTypes.retrieveInvestStrategy.success(data));
    } else {
      yield put(ActionTypes.retrieveInvestStrategy.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.retrieveInvestStrategy.failure());
    throw e;
  }
}

export function* retrieveInvestStrategyWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.RETRIEVE_INVEST_STRATEGY[REQUEST]);
    yield call(retrieveInvestStrategySaga, data);
  }
}

export function* getInvestStrategyApproveSaga({ strategyId, search }) {
  try {
    const { status, data } = yield call(api.getInvestStrategyApprove, strategyId, search);
    if (status < 300) {
      yield put(ActionTypes.getInvestStrategyApprove.success(data));
    } else {
      yield put(ActionTypes.getInvestStrategyApprove.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getInvestStrategyApprove.failure());
  }
}

export function* getInvestStrategyApproveWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_STRATEGIES_APPROVE[REQUEST]);
    yield call(getInvestStrategyApproveSaga, data);
  }
}

export function* setInvestmentsActionSaga({ investmentId, action, strategyId = null, isWallet }) {
  try {
    const { data, status } = yield call(api.setInvestmentsAction, investmentId, action);
    if (status < 300) {
      yield put(ActionTypes.setInvestmentsAction.success(data));
      yield delay(1000);
      if (strategyId) {
        yield call(getInvestStrategyApproveSaga, { strategyId, search: {} });
      }
      yield call(getInvestmentsSaga, { isActive: 1 });
      yield call(getTradingAccountListSaga, false);
      yield call(getMyInvestStrategiesSaga, { isActive: '1' });
    } else {
      if (isWallet) {
        const error = data?.error ?? '';
        yield put(acceptError(error, true));
      }
      yield put(ActionTypes.setInvestmentsAction.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.setInvestmentsAction.failure());
    throw e;
  }
}

export function* setInvestmentsActionWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.SET_INVESTMENTS_ACTION[REQUEST]);
    yield call(setInvestmentsActionSaga, data);
  }
}

export function* getInvestYieldChartSaga({ strategyId }) {
  try {
    const { status, data } = yield call(api.getInvestYieldChart, strategyId);
    if (status < 300) {
      yield put(ActionTypes.getInvestYieldChart.success(data));
    } else {
      yield put(ActionTypes.getInvestYieldChart.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestYieldChart.failure());
    throw e;
  }
}

export function* getInvestYieldChartWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_YIELD_CHART[REQUEST]);
    yield call(getInvestYieldChartSaga, data);
  }
}

export function* setInvestmentsDepositSaga({ investmentId, action, ...values }) {
  try {
    const { data, status } = yield call(api.setInvestmentsAction, investmentId, action, values);
    if (status < 300) {
      yield put(ActionTypes.setInvestmentsDeposit.success(data));
      yield delay(1000);
      yield call(getMyInvestStrategiesSaga, { isActive: 1 });
      yield call(getInvestmentsSaga, { isActive: 1 });
      yield call(getTradingAccountListSaga, false);
    } else {
      yield put(ActionTypes.setInvestmentsDeposit.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setInvestmentsDeposit.failure());
    throw e;
  }
}

export function* setInvestmentsDepositWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.setInvestmentsDeposit.REQUEST);
    yield call(setInvestmentsDepositSaga, payload);
  }
}

export function* getInvestStatementSaga({ investmentId }) {
  try {
    const { status, data } = yield call(api.getInvestStatement, investmentId);
    if (status < 300) {
      yield put(ActionTypes.getInvestStatement.success(data));
    } else {
      yield put(ActionTypes.getInvestStatement.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestStatement.failure());
  }
}

export function* getInvestStatementWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_STATEMENT[REQUEST]);
    yield call(getInvestStatementSaga, data);
  }
}

export function* withdrawInvestmentsSaga({ investmentId, action, account, amount }) {
  try {
    const { data, status } = yield call(api.setInvestmentsAction, investmentId, action, { account, amount });

    if (status < 300) {
      yield put(ActionTypes.withdrawInvestments.success(data));
      yield delay(1000);
      yield call(getInvestmentsSaga, { isActive: 1 });
    } else {
      yield put(ActionTypes.withdrawInvestments.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.withdrawInvestments.failure());
    throw e;
  }
}

export function* withdrawInvestmentsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.withdrawInvestments.REQUEST);
    yield call(withdrawInvestmentsSaga, payload);
  }
}

export function* getInvestmentTradesSaga({ investmentId, tradeType = 'open', startDate, endDate }) {
  try {
    const { status, data } = yield call(api.getInvestmentTrades, investmentId, tradeType, startDate, endDate);
    if (status < 300) {
      yield put(ActionTypes.getInvestmentTrades.success(data));
    } else {
      yield put(ActionTypes.getInvestmentTrades.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInvestmentTrades.failure());
  }
}

export function* getInvestmentTradesWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVESTMENT_TRADES[REQUEST]);
    yield call(getInvestmentTradesSaga, data);
  }
}

export function* closeInvestStrategySaga({ strategyId, ...values }) {
  try {
    const { data, status } = yield call(api.closeInvestStrategy, strategyId, { ...values });

    if (status < 300) {
      yield put(ActionTypes.closeInvestStrategy.success(data));
      yield delay(1000);
      yield call(getMyInvestStrategiesSaga, { isActive: 1 });
    } else {
      yield put(ActionTypes.closeInvestStrategy.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.closeInvestStrategy.failure());
    throw e;
  }
}

export function* closeInvestStrategyWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.closeInvestStrategy.REQUEST);
    yield call(closeInvestStrategySaga, payload);
  }
}

export function* closeInvestmentSaga({ investmentId, ...values }) {
  try {
    const { data, status } = yield call(api.closeInvestment, investmentId, { ...values });

    if (status < 300) {
      yield put(ActionTypes.closeInvestment.success(data));
      yield delay(1000);
      yield call(getInvestmentsSaga, { isActive: 1 });
    } else {
      yield put(ActionTypes.closeInvestment.failure(new SubmissionError({ _error: data.error })));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.closeInvestment.failure());
    throw e;
  }
}

export function* closeInvestmentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.closeInvestment.REQUEST);
    yield call(closeInvestmentSaga, payload);
  }
}

export function* editInvestmentSaga({ investmentId, takeProfit, stopLoss, account }) {
  try {
    const { data, status } = yield call(api.editInvestment, investmentId, { takeProfit, stopLoss, account });

    if (status < 300) {
      yield put(ActionTypes.editInvestment.success(data));
      yield delay(1000);
      yield call(getInvestmentsSaga, { isActive: 1 });
    } else {
      const error = data.error ? { _error: data.error } : data;
      yield put(ActionTypes.editInvestment.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editInvestment.failure());
    throw e;
  }
}

export function* editInvestmentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editInvestment.REQUEST);
    yield call(editInvestmentSaga, payload);
  }
}

export function* createInvestStrategyWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createInvestStrategy.REQUEST);
    yield call(createInvestStrategySaga, payload);
  }
}

export function* editInvestStrategySaga({ data, strategyId }) {
  try {
    const { status, data: error } = yield call(api.editInvestStrategy, { strategyId, data });
    if (status < 300) {
      yield call(retrieveInvestStrategySaga, { strategyId });
      yield put(ActionTypes.editInvestStrategy.success());
    } else {
      const formError = new SubmissionError(error);
      yield put(ActionTypes.editInvestStrategy.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
  }
}

export function* editInvestStrategyWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editInvestStrategy.REQUEST);
    yield call(editInvestStrategySaga, payload);
  }
}

export default [
  fork(createInvestStrategyWatcherSaga),
  fork(getRammStrategyScriptWatcherSaga),
  fork(getInvestStrategiesWatcherSaga),
  fork(getMyInvestStrategiesWatcherSaga),
  fork(setInvestStrategyActionWatcherSaga),
  fork(getInvestmentsWatcherSaga),
  fork(getInvestmentsRequestsWatcherSaga),
  fork(closeInvestmentRequestWatcherSaga),
  fork(getStrategyInvestmentsWatcherSaga),
  fork(addStrategyInvestmentsWatcherSaga),
  fork(retrieveInvestStrategyWatcherSaga),
  fork(getInvestStrategyApproveWatcherSaga),
  fork(setInvestmentsActionWatcherSaga),
  fork(getInvestYieldChartWatcherSaga),
  fork(setInvestmentsDepositWatcherSaga),
  fork(getInvestStatementWatcherSaga),
  fork(withdrawInvestmentsWatcherSaga),
  fork(getInvestmentTradesWatcherSaga),
  fork(closeInvestStrategyWatcherSaga),
  fork(closeInvestmentWatcherSaga),
  fork(editInvestStrategyWatcherSaga),
  fork(editInvestmentWatcherSaga),
];
