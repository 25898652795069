import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  PageLayout,
  PageContentPreloader,
  StatusCell,
  DateCell,
  PaginationTable,
  DefaultButton,
} from 'common/components';
import { getInvestNewExports, downloadInvestNewExport } from 'common/_redux';

import { staticStyles } from './style';

const InvestExportsPageNew = ({
  investNewExportsData,
  investNewExportsDataIsLoaded,
  getInvestNewExports,
  downloadInvestNewExport,
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getInvestNewExports({ search: query });
  }, [getInvestNewExports, query]);

  const downloadExport = (id, name) => {
    downloadInvestNewExport({ id, name });
  };

  const STATUSES = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
  };

  /* eslint-disable */
  const columns = [
    {
      Header: 'justId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
    },
    {
      Header: 'justName',
      accessor: 'name',
      Cell: ({
        row: {
          original: { name },
        },
      }) => <span>{name}</span>,
    },
    {
      Header: 'justCreatedBy',
      accessor: 'createdBy',
      Cell: ({
        row: {
          original: { createdBy },
        },
      }) => <span>{createdBy}</span>,
    },
    {
      Header: 'created',
      accessor: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      Header: () => null,
      accessor: 'justStatus',
      Cell: ({
        row: {
          original: { id, name, status },
        },
      }) =>
        status === STATUSES.SUCCESS ? (
          <DefaultButton textId="justDownload" type="button" onClick={() => downloadExport(id, name)} />
        ) : (
          <StatusCell statusCode={status} />
        ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  return (
    <PageLayout captionSlug="listOfInvestPammExports">
      <PageContentPreloader ready={investNewExportsDataIsLoaded} type="bigLine">
        <div className="InvestExportsPageNew">
          <PaginationTable
            data={investNewExportsData}
            columns={columns}
            loading={!investNewExportsDataIsLoaded}
            getList={getInvestNewExports}
            onRowClick={() => {}}
            location={location}
            history={history}
            params={{ search: query }}
            isNewTable
            withoutSort
          />
        </div>
        <style jsx global>
          {staticStyles}
        </style>
      </PageContentPreloader>
    </PageLayout>
  );
};

InvestExportsPageNew.propTypes = {
  investNewExportsData: PropTypes.array.isRequired,
  investNewExportsDataIsLoaded: PropTypes.bool.isRequired,
  getInvestNewExports: PropTypes.func.isRequired,
  downloadInvestNewExport: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    investNewExportsData: state.common.investNewExportsData,
    investNewExportsDataIsLoaded: state.common.investNewExportsDataIsLoaded,
  }),
  {
    getInvestNewExports: search => getInvestNewExports.request(search),
    downloadInvestNewExport: data => downloadInvestNewExport.request(data),
  }
)(InvestExportsPageNew);
export { InvestExportsPageNew };
