import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  :global(.CustomTable__wrapper) {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }
  :global(.CustomTable) {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  :global(.CustomTable__Body-Row) {
    background: #fff;
    margin-top: 2px;
    border-radius: 5px;
  }

  :global(.CustomTable__Cell) {
    max-width: 200px;
    min-width: 100px;
    text-align: center;
    vertical-align: middle;
    text-align: left;
    word-break: break-all;
    min-width: 160px;
  }
  :global(.CustomTable__Cell--Thead) {
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 400;
  }
  :global(.CustomTable__Cell--Sortable) {
    cursor: pointer;
  }
  :global(.CustomTable__Cell--Tbody),
  :global(.CustomTable__Cell--Thead) {
    padding: 1rem;
  }
  :global(.CustomTable__Arrow) {
    padding-left: 4px;
    padding-right: 4px;
  }
  :global(.CustomTable__Arrow--up) {
    transform: rotate(180deg);
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    :global(.CustomTable__Body-Row) {
      background-color: ${colors.white.hex};
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent;
      border-radius: 5px;

      &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:hover {
        z-index: 1;

        box-shadow: 0 4px 9px 4px ${rgba([colors.primary.rgb, 0.25])};
      }
    }
    :global(.CustomTable__Arrow) {
      path {
        stroke: ${colors.gray.hex};
      }
    }
    :global(.CustomTable__Arrow--up),
    :global(.CustomTable__Arrow--down) {
      path {
        stroke: ${colors.primary.hex} !important;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
