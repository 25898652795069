import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'common/styling/theme';

import { staticStyles } from './style';

const Brokeree = ({ brokereeLink }) => (
  <div id="AccountPage__Brokeree-elem" className="AccountPage__Brokeree">
    {brokereeLink && (
      <iframe frameBorder="0" title="brokeree-iframe" style={{ width: '100%', height: '800px' }} src={brokereeLink} />
    )}
    <style jsx>{staticStyles}</style>
  </div>
);

export default compose(
  withTheme(),
  connect(state => ({
    brokereeConfig: state.interfaceConfig.brokereeConfig,
  }))
)(Brokeree);
export { Brokeree };
