import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .UserNoInfoPanel {
    text-align: center;
    width: 100%;
    padding: 15px 0 15px 0;
    .UserNoInfoPanel__image-inner {
      margin-bottom: rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      img {
        width: rem(50px);
        height: rem(45px);
      }
    }
    p {
      font-size: rem(16px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserNoInfoPanel {
      p {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
