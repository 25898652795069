import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ApprovalHistory {
    background: #ffffff;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);
    padding: rem(25px 30px 25px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
      width: 100%;
    }

    :global(.ReactTable) {
      :global(.rt-thead.-header) {
        min-width: 600px !important;
        :global(.rt-tr) {
          margin: 0 28px;
          @include respond-to(md) {
            margin: 0 10px;
          }

          :global(.rt-th) {
            flex: 60 !important;
            text-align: center;

            &:first-child {
              flex: 20 !important;
              text-align: left;
            }

            &:nth-child(3) {
              flex: 10 !important;
            }

            &:nth-child(4) {
              flex: 50 !important;
            }

            &:last-child {
              flex: 30 !important;
              text-align: left;
            }
          }
        }
      }

      :global(.rt-tbody) {
        min-width: 600px !important;

        :global(.rt-tr-group) {
          margin: 0 0 2px 0;
          cursor: default;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06) !important;
          z-index: 1;

          :global(.rt-tr) {
            padding: rem(10px 29px);
            @include respond-to(lg) {
              padding: rem(10px);
            }

            :global(.rt-td) {
              flex: 60 !important;
              text-align: center;

              :global(span) {
                font-size: 1rem;
              }

              &:first-child {
                flex: 20 !important;
                text-align: left;
              }

              &:nth-child(3) {
                flex: 10 !important;
              }

              &:nth-child(4) {
                flex: 50 !important;
              }

              &:last-child {
                flex: 30 !important;
                text-align: left;
              }

              :global(.MoneyCell__inner) {
                justify-content: center;
              }

              :global(.phase) {
                font-weight: 500;
              }

              :global(.name) {
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    :global(.NoItemsPanel) {
      height: auto;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .ApprovalHistory {
    }
  `;
};

export { staticStyles, getDynamicStyles };
