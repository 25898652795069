import React from 'react';

import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import BaseButton from 'crm/_components/BaseButton';
import { crmSetUserPartnerCode } from 'crm/users/_redux/actions';

import { getAvailablePartnerCodes } from 'crm/users/api';
import BaseAsyncSelectField from '../../fields/BaseAsyncSelectField';

import { staticStyles } from './style';

const CRMEditPartnerCodeForm = ({ userToEditId, handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const { id: contactId } = useParams();
  const submit = handleSubmit(values =>
    crmSetUserPartnerCode({ ...values, contactId, userId: userToEditId }, dispatch)
  );

  const loadOptions = search =>
    getAvailablePartnerCodes(userToEditId, search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: manager.partnerCode }))
    );

  return (
    <form className="CRMEditPartnerCodeForm" onSubmit={submit}>
      <FormattedMessage id="editPartnerCode">
        {txt => <p className="CRMEditPartnerCodeForm__description">{txt}</p>}
      </FormattedMessage>
      <div className="CRMEditPartnerCodeForm__inputs-inner">
        <Field
          removeArrow
          textId="addPartnerAccount"
          component={BaseAsyncSelectField}
          name="partnerAccount"
          loadOptions={loadOptions}
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={270}
        />
      </div>

      <BaseButton type="submit" textId="justChange" maxContent disabled={invalid || pristine || submitting} />

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

CRMEditPartnerCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  userToEditId: PropTypes.number.isRequired,
  error: PropTypes.string,
};

CRMEditPartnerCodeForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(state => ({ userToEditId: state.crm.users.userData.userId })),
  reduxForm({
    form: 'crm-edit-user-manager',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
  })
)(CRMEditPartnerCodeForm);
export { CRMEditPartnerCodeForm };
