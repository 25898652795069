import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CustomFilterSelect {
    min-width: 250px;
    display: flex;
    align-items: center;

    &__inner {
      width: 100%;
    }

    .Select__control {
      border: none;
      padding: 0;
    }

    .Select__menu {
      @include respond-to(xs) {
        width: 100%;
      }
      width: calc(100% + 100px);
      left: -45px;
      top: 180%;

      border-radius: 8px !important;
      box-shadow: 0px 1.237px 2.714px 0px rgba(0, 0, 0, 0.02), 0px 3.127px 6.863px 0px rgba(0, 0, 0, 0.03),
        0px 6.38px 13.999px 0px rgba(0, 0, 0, 0.04), 0px 13.141px 28.836px 0px rgba(0, 0, 0, 0.05),
        0px 36px 79px 0px rgba(0, 0, 0, 0.07) !important;
    }
    .Select__menu-list {
      border-radius: 8px;

      .Select__option {
        padding: rem(12px) !important;
        font-size: rem(14px);
        color: #333;
        line-height: 1.4;

        &--is-focused {
          background: #f3fafe;
        }

        &--is-disabled {
          background: #f8f8f8;
          color: #a9a9a9;
        }

        .Tooltip__text {
          width: 100%;
        }
      }
    }

    .Select__indicators {
      display: none;
    }

    .Select__placeholder,
    .Select__single-value {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;

      color: #000000;
    }
  }
  .CustomFilterSelect__inner {
    display: flex;
    align-items: center;
    gap: 10px;
    & > div {
      width: 100%;
    }
  }
  .CustomFilterSelect__option-buttons {
    padding-right: 10px;
    display: flex;
    gap: rem(4px);
  }

  .CustomFilterSelect__option-tooltip {
    svg {
      width: 21px;
      height: 21px;
    }
  }

  .CustomFilterSelect__option {
    background: #fff;
    display: flex;
    border-bottom: 1px solid #e7e7e7;

    &--selected {
      .CustomFilterSelect__option-buttons {
        background: #cef4f5;
      }
      &:hover,
      &:focus {
        .CustomFilterSelect__option-buttons {
          background: #ceecf9;
        }
      }
    }
    &--focused {
      .CustomFilterSelect__option-buttons {
        background: #f3fafe;
      }
    }
  }
  .CustomFilterSelect__delete-icon {
    transform: scale(0.9);
  }
  .CustomFilterSelect__option-text {
    width: 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &--whole {
      max-width: 100%;
      font-weight: 500;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .CustomFilterSelect {
      .Select__menu {
        background-color: ${colors.white.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
