import css from 'styled-jsx/css';
import { isRTL, gradient } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestInvestments {
    :global(.Collapsible) {
      background: #ffffff;
      margin: 0;
    }

    :global(.Collapsible__header) {
      box-shadow: none;
      padding: rem(25px 18px);
      position: relative;
      margin-bottom: 0;
    }

    :global(.Collapsible__content) {
      //padding: rem(0 8px);
      //margin-bottom: 0;

      :global(.ReactDataTable) {
        :global(.ReactDataTable__thead-row) {
          :global(.ReactDataTable__thead-cell) {
            padding: rem(18px);
          }
        }

        :global(.ReactDataTable__tbody-row) {
          :global(.ReactDataTable__tbody-cell) {
            position: relative;
            overflow: visible;
            padding: rem(18px);
          }
        }
      }
    }

    :global(.Collapsible__caption) {
      line-height: 32px;
      font-size: 26px;
      font-weight: 300;

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    :global(.Collapsible__arrow) {
      position: absolute;
      right: ${isRTL ? 'auto' : '30px'};
      left: ${isRTL ? '30px' : 'auto'};
      top: 50%;
    }

    :global(.Collapsible__arrow-right) {
      transform: ${isRTL
        ? 'translateY(-50%) rotate(135deg) !important'
        : ' translateY(-50%) rotate(-45deg) !important'};
    }

    :global(.Collapsible__arrow-down) {
      top: 40%;
      transform: rotate(45deg) translateY(-50%) !important;
    }

    :global(.NoItemsPanel) {
      height: auto;
    }

    :global(.PaginationTable__pagination) {
      padding: rem(0 18px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    .InvestInvestments {
      &__tabs {
        padding: rem(5px 18px 25px);
        background: #ffffff;

        @include respond-to(sm) {
          padding: rem(0 8px);
          padding-bottom: 20px;
          width: 100%;
        }
      }

      &__tab {
        font-size: 20px;
        line-height: 23px;
        margin-right: ${isRTL ? '0' : '35px'};
        margin-left: ${isRTL ? '35px' : '0'};
        padding-bottom: 15px;
        cursor: pointer;
        border-bottom: 3px solid transparent;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          color: ${colors.primary.hex};
          border-bottom-color: ${colors.primary.hex};
        }

        @include respond-to(sm) {
          font-size: 15px;
          line-height: normal;
          padding-bottom: 5px;
          border-bottom-width: 2px;
          margin-right: ${isRTL ? '0' : '15px'};
          margin-left: ${isRTL ? '15px' : '0'};
        }
      }

      :global(.Collapsible) {
        margin: 0;
        :global(.ReactTable) {
          :global(.rt-tfoot) {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: -2px;
              left: 0;
              width: 100%;
              height: 2px;
              background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
