import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

class CheckboxField extends React.Component {
  componentDidMount() {
    if (typeof this.props.customDefaultValue !== 'undefined') {
      this.props.input.onChange(this.props.customDefaultValue);
    }
  }

  componentDidUpdate() {
    if (
      !this.props.input.value &&
      !this.props.disabled &&
      this.props.customDefaultValue &&
      this.props.input.value !== false
    ) {
      this.props.input.onChange(this.props.customDefaultValue);
    }
  }

  render() {
    const {
      input,
      text,
      textId,
      disabled,
      meta: { touched, error },
      onChange,
      link,
      simple,
      theme,
    } = this.props;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    return (
      <div className="CheckboxField">
        <input
          {...input}
          checked={input.value}
          id={`${input.name}FieldId`}
          type="checkbox"
          disabled={disabled}
          onChange={onChange || input.onChange}
        />

        {!simple ? (
          <label className="CheckboxField__label" htmlFor={`${input.name}FieldId`}>
            <span>
              <svg viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1" />
              </svg>
            </span>
            {text}
            {textId && <FormattedMessage id={textId} />}
          </label>
        ) : (
          <label className="CheckboxField__label-simple" htmlFor={`${input.name}FieldId`}>
            <div className="CheckboxField__checkbox">
              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="CheckboxField__checkbox-text">
              {text}
              {textId && <FormattedMessage id={textId}>{txt => txt}</FormattedMessage>}
            </div>
          </label>
        )}

        {link && link.link && link.title && (
          <a className="CheckboxField__link" href={link.link} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id={link.title}>{txt => txt}</FormattedMessage>
          </a>
        )}

        {touched && error && <span className="CheckboxField__error">{error}</span>}

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    );
  }
}

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  text: PropTypes.string,
  textId: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  onChange: PropTypes.func,
  link: PropTypes.object,
  customDefaultValue: PropTypes.bool,
  simple: PropTypes.bool,
  theme: PropTypes.object,
};

CheckboxField.defaultProps = {
  text: '',
  textId: '',
  disabled: false,
  onChange: null,
  link: {},
  customDefaultValue: undefined,
  simple: false,
  theme: {},
};

const ThemedCheckbox = withTheme()(CheckboxField);

export default ThemedCheckbox;
export { CheckboxField };
