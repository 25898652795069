import { createFormAction } from 'redux-form-saga';
import { FAILURE, REQUEST, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_BROKER_COLOR_SCHEMA = createRequestTypes('GET_BROKER_COLOR_SCHEMA');
export const getBrokerColorSchema = {
  request: () => action(GET_BROKER_COLOR_SCHEMA[REQUEST]),
  success: schema => action(GET_BROKER_COLOR_SCHEMA[SUCCESS], { schema }),
  failure: error => action(GET_BROKER_COLOR_SCHEMA[FAILURE], { error }),
};

export const updateBrokerColorSchema = createFormAction('UPDATE_BROKER_COLOR_Schema');
