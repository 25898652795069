import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .LpaAgreementsForm {
    max-width: rem(388px);

    &__inputs-inner,
    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      :global(.BaseTextField),
      :global(.DefaultButton) {
        width: 48%;
      }
    }
  }
  .LpaAgreementsForm__items {
    margin-bottom: 20px;
  }
  .LpaAgreementsForm__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .LpaAgreementsForm__item-left {
    display: flex;
    align-items: center;
    max-width: 100px;
  }
  .LpaAgreementsForm__button {
    color: $secondary-color;
  }
`;

export default staticStyles;
