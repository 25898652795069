import css from 'styled-jsx/css';

import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  // default style for ReactTable
  .ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .rt-table {
      flex: auto 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      border-collapse: collapse;
      overflow: auto;
    }
    .rt-thead {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      user-select: none;
      &.-headerGroups {
        background: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
      &.-filters {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        input,
        select {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 5px 7px;
          font-size: inherit;
          border-radius: 3px;
          font-weight: normal;
          outline: none;
        }
        .rt-th {
          border-right: 1px solid rgba(0, 0, 0, 0.02);
        }
      }
      &.-header {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      }
      .rt-tr {
        text-align: center;
      }
      .rt-th,
      .rt-td {
        padding: 5px 5px;
        line-height: normal;
        position: relative;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;
      }
      .rt-th.-sort-asc,
      .rt-td.-sort-asc {
        box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
      }
      .rt-th.-sort-desc,
      .rt-td.-sort-desc {
        box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
      }
      .rt-th.-cursor-pointer,
      .rt-td.-cursor-pointer {
        cursor: pointer;
      }
      .rt-th:last-child,
      .rt-td:last-child {
        border-right: 0;
      }
      .rt-resizable-header {
        overflow: visible;
        &:last-child {
          overflow: hidden;
        }
      }
      .rt-resizable-header-content {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .rt-header-pivot {
        border-right-color: #f7f7f7;
        &:after,
        &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 8px;
          margin-block-start: -8px;
        }
        &:before {
          border-color: rgba(102, 102, 102, 0);
          border-left-color: #f7f7f7;
          border-width: 10px;
          margin-block-start: -10px;
        }
      }
    }
    .rt-tbody {
      flex: 99999 1 auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .rt-tr-group {
        border-bottom: solid 1px rgba(0, 0, 0, 0.05);
        &:last-child {
          border-bottom: 0;
        }
      }
      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.02);
        &:last-child {
          border-right: 0;
        }
      }
      .rt-expandable {
        cursor: pointer;
        text-overflow: clip;
      }
    }
    .rt-tfoot {
      min-width: 600px !important;
      .rt-tr {
        padding: rem(10px 29px);
        @include respond-to(lg) {
          padding: rem(10px);
        }

        .rt-td {
          flex: 60 !important;
          text-align: center;
          border-right: none;
          padding: 0 !important;

          &:first-child {
            text-align: left;
            font-weight: 500;
            font-size: rem(20px);

            span {
              display: flex;
              height: 100%;
              align-items: center;
            }
          }

          &:last-child {
            flex: 32 !important;
            padding-right: rem(10px);
            text-align: left;

            .MoneyCell__inner {
              justify-content: start;
            }
          }

          .MoneyCell__inner {
            justify-content: center;
          }

          .TotalCell {
            .subtext {
              font-size: 14px;
              letter-spacing: 0.6px;
              color: #777777;
            }
          }
        }
      }
    }
    .rt-tr-group {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .rt-tr {
      flex: 1 0 auto;
      display: inline-flex;
    }
    .rt-th,
    .rt-td {
      flex: 1 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 7px 5px;
      overflow: hidden;
      transition: 0.3s ease;
      transition-property: width, min-width, padding, opacity;
    }
    .rt-th.-hidden,
    .rt-td.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }
    .rt-expander {
      display: inline-block;
      position: relative;
      color: transparent;
      margin: 0 10px;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        border-left: 5.04px solid transparent;
        border-right: 5.04px solid transparent;
        border-top: 7px solid rgba(0, 0, 0, 0.8);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        cursor: pointer;
      }
      &.-open:after {
        transform: translate(-50%, -50%) rotate(0);
      }
    }
    .rt-resizer {
      display: inline-block;
      position: absolute;
      width: 36px;
      top: 0;
      bottom: 0;
      right: -18px;
      cursor: col-resize;
      z-index: 10;
    }
    .rt-tfoot {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        &:last-child {
          border-right: 0;
        }
      }
    }
    &.-striped .rt-tr.-odd {
      background: rgba(0, 0, 0, 0.03);
    }
    &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
      background: rgba(0, 0, 0, 0.05);
    }
    .-pagination {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      padding: 3px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      input,
      select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
      }
      .-btn {
        appearance: none;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 3px;
        padding: 6px;
        font-size: 1em;
        color: rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.1s ease;
        cursor: pointer;
        outline: none;
        &[disabled] {
          opacity: 0.5;
          cursor: default;
        }
        &:not([disabled]):hover {
          background: rgba(0, 0, 0, 0.3);
          color: #fff;
        }
      }
      .-previous,
      .-next {
        flex: 1;
        text-align: center;
      }
      .-center {
        flex: 1.5;
        text-align: center;
        margin-block-end: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }
      .-pageInfo {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap;
      }
      .-pageJump {
        display: inline-block;
        input {
          width: rem(70px);
          text-align: center;
        }
      }
      .-pageSizeOptions {
        margin: 3px 10px;
      }
    }
    .rt-noData {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.3s ease;
      z-index: 1;
      pointer-events: none;
      padding: rem(20px);
      color: rgba(0, 0, 0, 0.5);
    }
    .-loading {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.3s ease;
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      > div {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        left: 0;
        font-size: rem(15px);
        color: rgba(0, 0, 0, 0.6);
        transform: translateY(-52%);
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
      &.-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;
        > div {
          -webkit-transform: translateY(50%);
          transform: translateY(50%);
        }
      }
    }
    .rt-resizing {
      .rt-th,
      .rt-td {
        transition: none !important;
        cursor: col-resize;
        user-select: none;
      }
    }
  }
  // end default style

  .ReactTable {
    width: 100%;
    border: none;
    display: block !important;
    .-loading {
      z-index: 2 !important;
    }
    .-loading.-active {
      z-index: 5 !important;
    }
    .rt-thead.-header {
      width: 100% !important;
      background-color: transparent;
      box-shadow: none;
      min-width: 340px !important;

      .rt-tr {
        box-shadow: none;
        border: none;
        margin: 0 20px;
        @include respond-to(lg) {
          margin: 0 15px;
        }
      }

      .rt-th {
        border: none;
        text-align: ${isRTL ? 'right' : 'left'};
        padding: 0;
        outline: none;
        margin-block-end: rem(3px);
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          -ms-flex-preferred-size: auto !important;
          flex: auto !important;
        }
        span {
          font-size: 11px;
          line-height: 18px;
          text-transform: uppercase;
        }
        &.-cursor-pointer {
          span {
            position: relative;
            &:after {
              content: '';
              border: solid black;
              border-width: 0 1px 1px 0;
              display: inline-block;
              padding: 3px;
              transform: rotate(45deg);
              position: relative;
              bottom: 2px;
            }
          }
        }
      }
    }

    .rt-tbody {
      width: 100% !important;
      min-width: 340px !important;

      .rt-tr-group {
        transition: all 0.4s ease;
        box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
        border: none;
        margin: 0 6px 2px 6px;
        cursor: pointer;
        position: relative;
        z-index: 1;

        &:hover {
          z-index: 2;
        }

        &:last-child {
          margin-block-end: 15px;
        }

        .rt-tr {
          padding: rem(16px 18px);
          margin: 0;
          align-items: center;
          justify-content: space-between;
          box-shadow: none;
          border: none;
          @include respond-to(lg) {
            padding: rem(13px 12px);
          }
          .rt-td {
            padding: 0;
            margin: 0;
            border: none;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              -ms-flex-preferred-size: auto !important;
              flex: auto !important;
            }
            span {
              display: inline-block;
              font-size: rem(17px);
              transition: all 0.4s ease;
            }
          }
        }
      }
    }
    .rt-noData {
      position: static;
      transform: translate(0, 0);
      width: 100%;
      text-align: center;
    }
  }

  .pagination-bottom {
    .-pagination {
      padding: 0;
      box-shadow: none;
      border: none;
      margin-block-start: 15px;
      align-items: center;

      .-btn {
        height: auto;
        width: auto;
        font-size: rem(15px);
        line-height: rem(22px);
        padding: rem(9px 20px);
        background: transparent;
        letter-spacing: rem(0.5px);
        font-weight: 500;
        display: inline-block;
        position: relative;

        &:disabled {
          opacity: 0.5;
          cursor: default;
          border-color: transparent;
        }

        &:after {
          content: '';
          position: absolute;
          top: 41%;
          width: rem(9px);
          height: rem(9px);
          border: solid;
          border-width: 0 1px 1px 0;
          display: inline-block;
          @include respond-to(xl) {
            top: 39%;
          }
        }
      }

      .-center {
        justify-content: space-between;
        flex: 1 1;
        order: 0;
        display: flex;

        @media only screen and (max-width: 800px) {
          flex: 100%;
          order: 2;
          margin-block-start: 10px;
        }

        .-pageInfo {
          order: 1;
          margin-inline-end: 20px;
          @include respond-to(sm) {
            margin: 0;
          }

          .-pageJump {
            margin: 0 6px 0 3px;

            input {
              padding: rem(10px);
              background: transparent;
              position: relative;
            }
          }
        }

        .-pageSizeOptions {
          order: 0;
          position: relative;
          margin: 0;

          &:after {
            content: '';
            width: rem(8px);
            height: rem(8px);
            position: absolute;
            top: 39%;
            right: rem(10px);
            border: solid;
            border-width: 0 1px 1px 0;
            display: inline-block;
            transform: rotate(45deg);
          }

          select {
            padding: rem(10px 30px 10px 10px);
            background: transparent;
            position: relative;

            option {
              border: none;
            }
          }
        }
      }

      .-previous {
        flex: 0 1;
        order: 1;
        text-align: right;
        margin-inline-end: 15px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex: 0 1 auto;
        }

        @media only screen and (max-width: 800px) {
          flex: 50% 50%;
          text-align: center;
          order: 0;
        }

        .-btn {
          padding-left: rem(${isRTL ? '20px' : '30px'});
          padding-right: rem(${isRTL ? '30px' : '20px'});

          &:after {
            transform: rotate(${isRTL ? '-45deg' : '135deg'});
            left: rem(${isRTL ? 'auto' : '10px'});
            right: rem(${isRTL ? '10px' : 'auto'});
          }
          span {
            white-space: nowrap;
          }
        }
      }

      .-next {
        flex: 0 1;
        order: 2;
        text-align: right;
        margin-inline-end: 5px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex: 0 1 auto;
        }

        @media only screen and (max-width: 800px) {
          text-align: center;
          width: 50%;
          flex: 50% 50%;
          order: 1;
        }

        .-btn {
          padding-left: rem(${isRTL ? '30px' : '20px'});
          padding-right: rem(${isRTL ? '20px' : '30px'});

          &:after {
            transform: rotate(${isRTL ? '135deg' : '-45deg'});
            left: rem(${isRTL ? '10px' : 'auto'});
            right: rem(${isRTL ? 'auto' : '10px'});
          }
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .BaseTable__action-buttons {
    text-align: right;
    margin-block-end: rem(10px);
    margin-bottom: rem(15px);

    :global(.DefaultButton) {
      margin-inline-start: rem(20px);
      margin-block-end: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ReactTable {
      .rt-thead.-header {
        .rt-th {
          span {
            color: ${colors.gray.hex};
            padding-left: ${isRTL ? '8px' : '0'};

            &::after {
              left: ${isRTL ? '-5px' : 'auto'};
              right: ${isRTL ? 'auto' : '-5px'};
            }
          }
        }
      }

      .rt-tbody {
        .rt-tr-group {
          background-color: ${colors.white.hex};
          border-radius: ${border.radius};
          &:hover {
            box-shadow: 0 3px 9px 4px ${rgba([colors.primary.rgb, 0.25])};
          }
        }
      }
    }

    .pagination-bottom {
      .-pagination {
        .-btn {
          color: ${colors.primary.hex};
          border: 1px solid ${rgba([colors.primary.rgb, 0.8])};
          &:after {
            border-color: ${colors.primary.hex};
          }
          &:not([disabled]) {
            &:hover {
              border-color: ${rgba([colors.primary.rgb, 0.6])};
              background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
              color: ${rgba([colors.primary.rgb, 0.7])};
            }
          }
          &:disabled {
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
          }
        }

        .-center {
          .-pageInfo {
            .-pageJump {
              input {
                border: 1px solid ${colors.lightGray.hex};
                color: ${colors.gray.hex};
              }
            }
          }

          .-pageSizeOptions {
            &:after {
              border-color: ${colors.black.hex};
            }
            select {
              border: 1px solid ${colors.lightGray.hex};
              color: ${colors.gray.hex};
              option {
                color: ${colors.black.hex};
              }
            }
          }
        }
      }
    }

    .-sort-desc {
      box-shadow: none !important;
      span {
        &:after {
          border: solid ${colors.primary.hex} !important;
          border-width: 0 1px 1px 0 !important;
        }
      }
    }

    .-sort-asc {
      box-shadow: none !important;
      span {
        &:after {
          transform: rotate(-135deg) !important;
          border: solid ${colors.primary.hex} !important;
          border-width: 0 1px 1px 0 !important;
          bottom: -2px !important;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
