import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { PageLayout, NoItemsPanel, PageContentPreloader, SearchPanel } from 'common/components';

import noPaymentsImage from 'common/images/noPayments.svg';

import { PaymentsTable } from '../../_components';

const PaymentsPage = ({
  type,
  exportButtonCaptionSlug,
  items,
  noPaymentsCaption,
  noMatchingParametersCaption,
  getItems,
  getReloadItems,
  captionSlug,
  itemsAreLoaded,
  filters,
  history,
  location,
  allowManagePaymentsExports,
  hasPaymentOperationManagers,
}) => {
  const handleRowClick = id => {
    if (type === 'deposits') {
      history.push(`/backoffice/${type}/${id}/`);
    } else {
      history.push(`/backoffice/${type}/list/${id}/`);
    }
  };
  const query = queryString.parse(location.search);

  return (
    <PageLayout captionSlug={captionSlug} showReload onReloadClick={getReloadItems} reloading={!itemsAreLoaded}>
      <PageContentPreloader ready={itemsAreLoaded} type="smallLines" firstLaunchOnly>
        {!items.count && !Object.keys(query).length ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getItems}
            filters={filters}
            params={{ search: query }}
          />
        )}
        {items.count ? (
          <PaymentsTable
            data={items}
            exportButtonCaptionSlug={exportButtonCaptionSlug}
            type={type}
            loading={!itemsAreLoaded}
            location={location}
            history={history}
            getList={getItems}
            onRowClick={handleRowClick}
            params={{ search: query }}
            allowManagePaymentsExports={allowManagePaymentsExports}
            hasPaymentOperationManagers={hasPaymentOperationManagers}
          />
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={Object.keys(query).length ? noMatchingParametersCaption : noPaymentsCaption}
          />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

PaymentsPage.propTypes = {
  type: PropTypes.string.isRequired,
  exportButtonCaptionSlug: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  noPaymentsCaption: PropTypes.string.isRequired,
  noMatchingParametersCaption: PropTypes.string.isRequired,
  getItems: PropTypes.func.isRequired,
  getReloadItems: PropTypes.func.isRequired,
  captionSlug: PropTypes.string.isRequired,
  itemsAreLoaded: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  allowManagePaymentsExports: PropTypes.bool.isRequired,
  hasPaymentOperationManagers: PropTypes.bool.isRequired,
};

export default PaymentsPage;
