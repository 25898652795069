import { put, call, fork, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getFilesListSaga() {
  try {
    const { data } = yield call(api.getFilesList);
    yield put(ActionTypes.getFilesList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getFilesList.failure());
  }
}

export function* getFilesListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_FILES_LIST[REQUEST]);
    yield call(getFilesListSaga);
  }
}

export default [fork(getFilesListWatcherSaga)];
