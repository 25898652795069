import axios from 'axios';

export const baseClient = axios.create({
  baseURL: '/api/auth',
  validateStatus: status => status < 300 || status === 409 || status === 429,
});

baseClient.interceptors.request.use(config => {
  const locale = localStorage.getItem('locale');
  if (locale) {
    config.headers.locale = locale;
  }
  return config;
});
