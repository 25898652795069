import React from 'react';

const NewsIcon = () => (
  <div>
    <svg viewBox="0 -19 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="m486.15625 0h-374.597656c-14.25 0-25.839844 11.59375-25.839844 25.84375v39.230469h-59.875c-14.25 0-25.84375 11.59375-25.84375 25.84375v332.539062c0 27.636719 22.394531 50.144531 49.996094 50.34375h.101562c.089844.003907.171875.011719.261719.011719h265.308594c4.140625 0 7.5-3.355469 7.5-7.5 0-4.140625-3.359375-7.5-7.5-7.5h-229.484375c8.980468-9.097656 14.535156-21.589844 14.535156-35.355469v-175.386719c0-4.140624-3.359375-7.5-7.5-7.5-4.144531 0-7.5 3.359376-7.5 7.5v175.386719c0 19.429688-15.757812 35.246094-35.167969 35.351563-.0625 0-.125-.007813-.191406-.007813-19.496094-.003906-35.359375-15.859375-35.359375-35.34375v-332.539062c0-5.976563 4.863281-10.84375 10.84375-10.84375h59.875v139.996093c0 4.144532 3.355469 7.5 7.5 7.5 4.140625 0 7.5-3.355468 7.5-7.5v-194.226562c0-5.980469 4.863281-10.84375 10.839844-10.84375h374.597656c5.980469 0 10.84375 4.863281 10.84375 10.84375v397.613281c0 19.496094-15.863281 35.355469-35.359375 35.355469h-117.972656c-4.144531 0-7.5 3.359375-7.5 7.5 0 4.144531 3.355469 7.5 7.5 7.5h117.972656c27.769531 0 50.359375-22.589844 50.359375-50.355469v-397.613281c0-14.25-11.59375-25.84375-25.84375-25.84375zm0 0" />
      <path d="m164.320312 299.261719h102.863282c9.785156 0 17.746094-7.960938 17.746094-17.746094v-102.863281c0-9.785156-7.960938-17.746094-17.746094-17.746094h-102.863282c-9.785156 0-17.746093 7.960938-17.746093 17.746094v102.863281c0 9.785156 7.960937 17.746094 17.746093 17.746094zm-2.746093-120.609375c0-1.515625 1.230469-2.746094 2.746093-2.746094h102.863282c1.511718 0 2.746094 1.230469 2.746094 2.746094v102.863281c0 1.511719-1.234376 2.746094-2.746094 2.746094h-102.863282c-1.515624 0-2.746093-1.234375-2.746093-2.746094zm0 0" />
      <path d="m312.191406 175.90625h139.199219c4.140625 0 7.5-3.355469 7.5-7.5s-3.359375-7.5-7.5-7.5h-139.199219c-4.140625 0-7.5 3.355469-7.5 7.5s3.359375 7.5 7.5 7.5zm0 0" />
      <path d="m312.191406 237.585938h139.199219c4.140625 0 7.5-3.359376 7.5-7.5 0-4.144532-3.359375-7.5-7.5-7.5h-139.199219c-4.140625 0-7.5 3.355468-7.5 7.5 0 4.140624 3.359375 7.5 7.5 7.5zm0 0" />
      <path d="m312.191406 299.261719h139.199219c4.140625 0 7.5-3.359375 7.5-7.5 0-4.144531-3.359375-7.5-7.5-7.5h-139.199219c-4.140625 0-7.5 3.355469-7.5 7.5 0 4.140625 3.359375 7.5 7.5 7.5zm0 0" />
      <path d="m154.074219 360.9375h297.316406c4.140625 0 7.5-3.355469 7.5-7.5 0-4.140625-3.359375-7.5-7.5-7.5h-297.316406c-4.140625 0-7.5 3.359375-7.5 7.5 0 4.144531 3.359375 7.5 7.5 7.5zm0 0" />
      <path d="m154.074219 422.617188h297.316406c4.140625 0 7.5-3.355469 7.5-7.5 0-4.144532-3.359375-7.5-7.5-7.5h-297.316406c-4.140625 0-7.5 3.355468-7.5 7.5 0 4.144531 3.359375 7.5 7.5 7.5zm0 0" />
      <path d="m146.765625 122.527344v-79.25c0-1.628906.773437-2.871094 2.320313-3.722656 1.546874-.855469 3.421874-1.285157 5.617187-1.285157 2.933594 0 5.167969.53125 6.71875 1.589844 1.542969 1.058594 3.253906 3.339844 5.128906 6.835937l23.566407 45.550782v-49.089844c0-1.628906.773437-2.847656 2.320312-3.664062 1.542969-.8125 3.417969-1.222657 5.617188-1.222657 2.199218 0 4.070312.410157 5.617187 1.222657 1.546875.816406 2.320313 2.035156 2.320313 3.664062v79.371094c0 1.550781-.796876 2.769531-2.382813 3.664062-1.585937.898438-3.4375 1.34375-5.554687 1.34375-4.316407 0-7.371094-1.667968-9.160157-5.007812l-26.253906-49.089844v49.089844c0 1.550781-.792969 2.769531-2.378906 3.664062-1.585938.898438-3.441407 1.34375-5.558594 1.34375-2.195313 0-4.070313-.445312-5.613281-1.34375-1.550782-.894531-2.324219-2.113281-2.324219-3.664062zm0 0" />
      <path d="m220.644531 122.527344v-79.25c0-1.546875.691407-2.765625 2.078125-3.664063 1.382813-.894531 3.007813-1.34375 4.882813-1.34375h43.472656c1.625 0 2.871094.695313 3.722656 2.078125.855469 1.382813 1.285157 2.972656 1.285157 4.761719 0 1.953125-.449219 3.625-1.34375 5.007813-.898438 1.386718-2.121094 2.074218-3.664063 2.074218h-34.558594v24.421875h18.5625c1.542969 0 2.765625.632813 3.664063 1.894531.894531 1.261719 1.34375 2.746094 1.34375 4.457032 0 1.546875-.429688 2.949218-1.285156 4.214844-.855469 1.261718-2.09375 1.890624-3.722657 1.890624h-18.5625v24.546876h34.558594c1.542969 0 2.765625.691406 3.664063 2.074218.894531 1.386719 1.34375 3.054688 1.34375 5.007813 0 1.789062-.429688 3.378906-1.285157 4.761719-.851562 1.386718-2.097656 2.074218-3.722656 2.074218h-43.472656c-1.875 0-3.5-.445312-4.882813-1.339844-1.386718-.898437-2.078125-2.117187-2.078125-3.667968zm0 0" />
      <path d="m284.023438 44.621094c0-1.710938 1.097656-3.195313 3.296874-4.457032 2.195313-1.261718 4.476563-1.894531 6.835938-1.894531 2.933594 0 4.683594 1.058594 5.25 3.175781l19.296875 66.1875 10.378906-42.496093c.730469-2.933594 3.335938-4.398438 7.8125-4.398438 4.398438 0 6.960938 1.464844 7.695313 4.398438l10.378906 42.496093 19.292969-66.1875c.570312-2.117187 2.320312-3.175781 5.253906-3.175781 2.359375 0 4.636719.632813 6.835937 1.894531 2.199219 1.261719 3.296876 2.746094 3.296876 4.457032 0 .488281-.082032.976562-.242188 1.464844l-24.179688 76.808593c-1.222656 3.585938-4.683593 5.375-10.378906 5.375-2.441406 0-4.640625-.46875-6.59375-1.40625-1.953125-.933593-3.136718-2.257812-3.542968-3.96875l-7.8125-32.96875-7.9375 32.96875c-.410157 1.710938-1.589844 3.035157-3.542969 3.96875-1.953125.9375-4.152344 1.40625-6.59375 1.40625-2.523438 0-4.761719-.46875-6.714844-1.40625-1.957031-.933593-3.175781-2.257812-3.664063-3.96875l-24.179687-76.808593c-.164063-.488282-.242187-.976563-.242187-1.464844zm0 0" />
      <path d="m393.679688 113.492188c0-1.871094.710937-3.765626 2.136718-5.679688 1.421875-1.910156 3.03125-2.867188 4.824219-2.867188 1.054687 0 2.257813.507813 3.601563 1.523438 1.34375 1.011719 2.644531 2.136719 3.90625 3.359375 1.261718 1.226563 3.074218 2.347656 5.4375 3.363281 2.359374 1.015625 4.964843 1.519532 7.8125 1.519532 3.910156 0 7.164062-.894532 9.769531-2.6875 2.605469-1.789063 3.90625-4.433594 3.90625-7.933594 0-2.445313-.710938-4.621094-2.136719-6.535156-1.421875-1.910157-3.296875-3.5-5.617188-4.761719-2.316406-1.261719-4.863281-2.484375-7.628906-3.664063-2.769531-1.179687-5.558594-2.503906-8.367187-3.96875-2.808594-1.464844-5.371094-3.113281-7.691407-4.945312-2.320312-1.832032-4.195312-4.273438-5.617187-7.328125-1.425781-3.050781-2.136719-6.53125-2.136719-10.4375 0-4.378907.875-8.246094 2.625-11.589844 1.75-3.347656 4.089844-5.949219 7.019532-7.808594 2.929687-1.859375 6.0625-3.222656 9.402343-4.089843 3.335938-.867188 6.878907-1.304688 10.625-1.304688 2.113281 0 4.351563.148438 6.714844.441406 2.359375.292969 4.863281.773438 7.511719 1.441406 2.644531.667969 4.800781 1.714844 6.46875 3.132813 1.667968 1.421875 2.503906 3.09375 2.503906 5.019531 0 1.8125-.570312 3.6875-1.707031 5.625-1.140625 1.9375-2.6875 2.902344-4.640625 2.902344-.734375 0-2.730469-.773438-5.984375-2.320312-3.257813-1.546876-6.878907-2.320313-10.867188-2.320313-4.398437 0-7.796875.835937-10.195312 2.503906-2.402344 1.671875-3.605469 3.96875-3.605469 6.898438 0 2.363281.976562 4.417969 2.933594 6.167969 1.953125 1.75 4.375 3.175781 7.265625 4.273437 2.886719 1.097656 6.023437 2.484375 9.402343 4.152344 3.375 1.667969 6.511719 3.480469 9.402344 5.433593 2.886719 1.953126 5.3125 4.78125 7.265625 8.484376 1.953125 3.707031 2.929688 8.042968 2.929688 13.007812 0 8.347656-2.667969 14.792969-7.996094 19.328125-5.335937 4.535156-12.355469 6.804687-21.066406 6.804687-7.734375 0-14.367188-1.589843-19.902344-4.765624-5.539063-3.167969-8.304687-6.628907-8.304687-10.375zm0 0" />
    </svg>
  </div>
);

export default NewsIcon;
