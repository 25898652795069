import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';

const ModalDropDown = ({ options, onSelect, setIsOpen, multi, isNew }) => {
  const OldComponent = (
    <div className="BaseStaticSelect__Options BaseStaticSelect__Options--mobile">
      {options.map(({ label, value, isSelected }) => (
        <button
          key={`BaseStaticSelect-options-${label}`}
          className={classNames('BaseStaticSelect__Option BaseStaticSelect__Option--FastFilter', {
            'BaseStaticSelect__OptionMulti--Selected': isSelected,
          })}
          type="button"
          onClick={() => {
            onSelect(value);
            if (!multi || value === 'All') {
              setIsOpen();
            }
          }}>
          <FM id={label}>{txt => txt}</FM>
        </button>
      ))}
    </div>
  );

  const NewComponent = (
    <div className="BaseStaticSelect__NewOptions BaseStaticSelect__NewOptions--mobile">
      {options.map(({ label, value, isSelected }) => (
        <button
          key={`BaseStaticSelect-options-New${label + value}`}
          className={classNames('BaseStaticSelect__NewOption', {
            'BaseStaticSelect__NewOption--isSelected': isSelected,
          })}
          type="button"
          onClick={() => {
            onSelect(value);
            if (!multi || value === 'All') {
              setIsOpen();
            }
          }}>
          <FM id={label}>{txt => txt}</FM>
        </button>
      ))}
    </div>
  );

  return isNew ? NewComponent : OldComponent;
};

ModalDropDown.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  multi: PropTypes.bool,
  isNew: PropTypes.bool,
};

ModalDropDown.defaultProps = {
  multi: false,
  isNew: false,
};

export default ModalDropDown;
