import React from 'react';
import PropTypes from 'prop-types';
import { Field, change, reduxForm, untouch } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxTextField, TextAreaField, DefaultButton } from 'common/components';

import { approveWithdrawal } from 'backoffice/payments/_redux';

import staticStyles from './style';

const ApproveWithdrawalForm = ({
  groupId,
  stepId,
  onReject,
  inSteps,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const submit = handleSubmit(values =>
    approveWithdrawal({ groupId, stepId, isApproved: !inSteps, ...values }, dispatch)
  );

  return (
    <form className="ApproveWithdrawalForm" onSubmit={submit}>
      <div className="ApproveWithdrawalForm__inputs-inner">
        {inSteps ? (
          <>
            <Field
              component={TextAreaField}
              name="comment"
              textId="supportComment"
              rows="4"
              cols="150"
              validate={[required()]}
              disabled={submitting}
            />
            <Field
              component={TextAreaField}
              name="cancelComment"
              textId="editAmountModalPublicComment"
              rows="4"
              cols="150"
              validate={[required()]}
              disabled={submitting}
            />
          </>
        ) : (
          <Field
            component={ReduxTextField}
            name="comment"
            textId="supportComment"
            validate={[required()]}
            disabled={submitting}
          />
        )}
      </div>
      <div className="ApproveWithdrawalForm__btns">
        <DefaultButton
          textId={inSteps ? 'justReject' : 'justApprove'}
          type="submit"
          loading={submitting}
          disabled={invalid || submitting}
          filled
          onClick={() => submit(true)}
        />
        {!inSteps && <DefaultButton textId="justReject" type="button" butColor="error" onClick={onReject} />}
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

ApproveWithdrawalForm.propTypes = {
  groupId: PropTypes.string.isRequired,
  stepId: PropTypes.number.isRequired,
  inSteps: PropTypes.bool,
  onReject: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

ApproveWithdrawalForm.defaultProps = {
  inSteps: false,
  error: '',
};

export default reduxForm({
  form: 'ApproveWithdrawalForm',
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch(change('ApproveWithdrawalForm', 'comment', null));
    dispatch(untouch('ApproveWithdrawalForm', 'comment'));
    if (props.inSteps) {
      props.onReject();
    }
  },
})(ApproveWithdrawalForm);
export { ApproveWithdrawalForm };
