import css from 'styled-jsx/css';

const staticStyles = css.global`
  .Tags {
    position: relative;
    display: flex;
    gap: 10px;
    top: -2px;
    z-index: 10;
    flex-wrap: wrap;
    max-width: 80vw;
  }
`;

export { staticStyles };
