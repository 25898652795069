import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RadioButton } from 'common/components/';
import InnerTransfer from '../InnerTransfer';
import ExternalTransfer from '../ExternalTransfer';
import WalletTransfer from '../WalletTransfer';
import { staticStyles } from './style';

const InternalTransfer = ({ accountsEnabled, tradersEnabled, account, addons }) => {
  const [transferType, setTransferType] = useState('inner');
  const handleChange = transferType => {
    setTransferType(transferType);
  };
  return (
    <div className="InternalTransfer">
      <div className="InternalTransfer__select">
        <FormattedMessage id="justChooseOption">
          {txt => <span className="InternalTransfer__select-caption">{`${txt}:`}</span>}
        </FormattedMessage>
        <RadioButton
          name="transferType"
          textId="clientBetweenYourAccounts"
          value="inner"
          onChange={() => handleChange('inner')}
          checked={transferType === 'inner'}
          disabled={!accountsEnabled}
        />
        <RadioButton
          name="transferType"
          textId="clientToAnotherTrader"
          value="trader"
          onChange={() => handleChange('trader')}
          checked={transferType === 'trader'}
          disabled={!tradersEnabled}
        />
      </div>

      {accountsEnabled && transferType === 'inner' && <InnerTransfer initialValues={{ senderAccount: account }} />}
      {tradersEnabled &&
        transferType === 'trader' &&
        (addons.includes('walletAccount') ? (
          <WalletTransfer />
        ) : (
          <ExternalTransfer initialValues={{ senderAccount: account }} />
        ))}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

InternalTransfer.propTypes = {
  tradersEnabled: PropTypes.bool.isRequired,
  accountsEnabled: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  account: PropTypes.number,
};

InternalTransfer.defaultProps = {
  account: null,
};

export default connect(state => ({
  tradersEnabled: state.interfaceConfig.internalTransfers.traders_enabled,
  accountsEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
  addons: state.interfaceConfig.addons,
}))(InternalTransfer);
