import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NavToCRM {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .NavToCRM__body {
    background: white;
    height: fit-content;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 32px 30px 32px;
    @include respond-to(sm) {
      width: 100%;
    }
  }

  .CheckIcon {
    display: flex;
    margin-bottom: 35px;
  }

  .CongratsTexts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
    &__title {
      font-size: 26px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: center;
    }
    &__instructions {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  .NavToCRMItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
    @include respond-to(sm) {
      margin-bottom: 25px;
    }
  }

  .NavToCRMItem {
    display: grid;
    grid-template-columns: auto max-content;
    background: #f0f4f6;
    padding: 16px;
    @include respond-to(xs) {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
    }
    .IconAndTitle {
      display: flex;
      align-items: center;
      gap: 16px;
      .icon {
        background: white;
        padding: 8px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        width: fit-content;
      }
      .title {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
      }
    }
  }

  .NavToCRMLink {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
  }
`;

export { staticStyles };
