import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_PLAN = createRequestTypes('GET_PLAN');
export const getPlan = {
  request: () => action(GET_PLAN[REQUEST]),
  success: data => action(GET_PLAN[SUCCESS], { data }),
  failure: error => action(GET_PLAN[FAILURE], { error }),
};

export const GET_TASKS = createRequestTypes('GET_TASKS');
export const getTasks = {
  request: data => action(GET_TASKS[REQUEST], data),
  success: data => action(GET_TASKS[SUCCESS], { data }),
  failure: error => action(GET_TASKS[FAILURE], { error }),
};

export const GET_TASK = createRequestTypes('GET_TASK');
export const getTask = {
  request: id => action(GET_TASK[REQUEST], id),
  success: data => action(GET_TASK[SUCCESS], { data }),
  failure: error => action(GET_TASK[FAILURE], { error }),
};

export const createTask = createFormAction('CREATE_TASK');
export const updateTask = createFormAction('UPDATE_TASK');
export const finishTask = createFormAction('FINISH_TASK');

export const GET_MANAGERS = createRequestTypes('GET_MANAGERS');
export const getManagers = {
  request: () => action(GET_MANAGERS[REQUEST]),
  success: data => action(GET_MANAGERS[SUCCESS], { data }),
  failure: error => action(GET_MANAGERS[FAILURE], { error }),
};
