import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PageLayout, BasicModal, DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';

import noPartnersImage from 'common/images/noPartners.svg';

import { toggleCreateIssueModal } from 'client/support/_redux';
import { requestPartnership, requestEasyStart } from '../../_redux';
import IssueForm from '../../../_components/IssueForm';

import { staticStyles, getDynamicStyles } from './style';

const PartnershipPage = ({
  profile,
  requestPartnership,
  requestEasyStart,
  createIssueModalIsOpen,
  toggleCreateIssueModal,
  partnership,
  partnershipTemplate,
  requestEasyStartState,
  requestEasyStartIsSending,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const partnershipFormInitialValues = { description: partnershipTemplate };

  const handleBecomePartner = () => {
    if (!partnership.verification) {
      requestEasyStart();
    } else {
      toggleCreateIssueModal();
    }
  };

  useEffect(() => {
    setLoading(requestEasyStartIsSending);

    if (requestEasyStartState) {
      history.push('/partnership/links');
    }
  }, [requestEasyStartState, requestEasyStartIsSending, history]);

  return (
    <PageLayout captionSlug="partnershipPage">
      <div className="PartnershipPage">
        <div className="PartnershipPage__top-inner">
          <img src={noPartnersImage} alt="Partner" />

          <FormattedMessage id="partnershipPanelCaption">{txt => <h3>{txt}</h3>}</FormattedMessage>

          <FormattedMessage id="partnershipConditionsAfterApplication">{txt => <p>{txt}</p>}</FormattedMessage>
        </div>

        {profile.isPartnershipRequested || requestEasyStartIsSending ? (
          <div className="PartnershipPage__inner">
            <FormattedMessage id="partnershipPanelRequestText">{txt => <p>{txt}</p>}</FormattedMessage>

            <NavLink to="support">
              <FormattedMessage id="partnershipPanelRequestLink" />
            </NavLink>
          </div>
        ) : (
          <div className="PartnershipPage__button-inner">
            <DefaultButton
              type="button"
              onClick={handleBecomePartner}
              textId="partnershipPanelButtonText"
              filled
              loading={isLoading}
            />
          </div>
        )}

        {partnership.verification && (
          <BasicModal
            isOpen={createIssueModalIsOpen}
            captionId="createIssueModalHeader"
            onRequestClose={toggleCreateIssueModal}>
            <IssueForm
              isPartnershipRequest
              partnership={partnership}
              onSubmitFunc={requestPartnership}
              initialValues={partnershipFormInitialValues}
            />
          </BasicModal>
        )}

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    </PageLayout>
  );
};

PartnershipPage.propTypes = {
  profile: PropTypes.object.isRequired,
  createIssueModalIsOpen: PropTypes.bool.isRequired,
  toggleCreateIssueModal: PropTypes.func.isRequired,
  requestPartnership: PropTypes.func.isRequired,
  requestEasyStart: PropTypes.func.isRequired,
  partnership: PropTypes.object.isRequired,
  partnershipTemplate: PropTypes.string.isRequired,
  requestEasyStartState: PropTypes.bool.isRequired,
  requestEasyStartIsSending: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

PartnershipPage.defaultProps = {
  theme: {},
};

const ThemedPartnershipPage = withTheme()(PartnershipPage);

export default connect(
  state => ({
    profile: state.user.profile,
    createIssueModalIsOpen: state.client.support.createIssueModalIsOpen,
    partnership: state.interfaceConfig.partnership,
    partnershipTemplate: state.interfaceConfig.partnership.partnershipTemplate
      .replace(/(<([^>]+)>)/gi, '')
      .replace('&amp;', '&'),
    requestEasyStartState: state.client.partnership.requestEasyStart,
    requestEasyStartIsSending: state.client.partnership.requestEasyStartIsSending,
  }),
  {
    requestPartnership: () => requestPartnership.request(),
    requestEasyStart: () => requestEasyStart.request(),
    toggleCreateIssueModal: () => toggleCreateIssueModal(),
  }
)(ThemedPartnershipPage);
export { PartnershipPage };
