import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .EditTaskForm__content {
    max-width: 600px;
  }

  .EditTaskForm {
    &__btns {
      display: flex;
      justify-content: space-between;

      :global(.DefaultButton) {
        width: 48%;
      }
    }

    &__inputs-inner {
      display: flex;
      justify-content: space-between;

      :global(.BaseSelectField),
      :global(.DateField__inner) {
        width: 48%;
      }

      @include respond-to(xs) {
        flex-direction: column;

        :global(.BaseSelectField),
        :global(.DateField__inner) {
          width: 100%;
        }
      }
    }

    :global(.DateRangeField__picker) {
      max-width: 100%;
    }
  }
`;

export default staticStyles;
