import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ModalHOC } from 'common';
import { EditIcon, BasketIcon } from 'common/icons';
import { PaginationTable, ActionsButton, StatusCell, BasicModal } from 'common/components';
import { withTheme } from 'common/styling/theme';
import DeleteCampaignForm from '../DeleteCampaignForm';
import { staticStyles, getDynamicStyles } from './style';

const CampaignsTable = ({ data, onRowClick, getList, loading, location, history, params, theme }) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [campaignId, setCampaignId] = useState(null);

  const toggleDeleteModal = () => setDeleteModalOpen(!isDeleteModalOpen);

  const editCampaignClick = (e, id) => {
    e.stopPropagation();
    history.push(`/backoffice/promo/campaigns/${id}/edit`);
  };

  const deleteCampaignClick = (e, id) => {
    e.stopPropagation();
    setCampaignId(id);
    toggleDeleteModal();
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justLogo',
      Cell: ({ original: { logoUrl } }) => <img src={logoUrl} className="CampaignsTable__logo" alt="campaignLogo"/>,
      isPreviewCell: true
    },
    {
      key: 'justTitle',
      accessor: 'title',
      Cell: ({ original: { title } }) => <span className="title">{title}</span>,
      isPreviewCell: true
    },
    {
      key: 'justActive',
      accessor: 'is_active',
      Cell: ({ original: { isActive } }) => <StatusCell statusCode={isActive ? 'yes' : 'no'} />,
    },
    {
      key: 'justDefault',
      accessor: 'is_default',
      Cell: ({ original: { isDefault } }) => <StatusCell statusCode={isDefault ? 'yes' : 'no'} />,
    },
    {
      key: 'justActions',
      Cell: ({ original: { id } }) => (
        <>
          <ActionsButton
            tooltipId={`Edit__tooltip-${id}`}
            tooltipTextId='justEdit'
            edit
            onClickFunc={e => editCampaignClick(e, id)}>
            <EditIcon />
          </ActionsButton>
          <ActionsButton
            tooltipId={`Delete__tooltip-${id}`}
            tooltipTextId='justDelete'
            deleteType
            onClickFunc={e => deleteCampaignClick(e, id)}
            disabled={data.results.length <= 1}
          >
            <BasketIcon />
          </ActionsButton>
        </>
      ),
      isPreviewCell: true
    },
  ]
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      onRowClick(rowInfo.original.id);
    },
  });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const defaultCampaign = data.results.find(item => item.isDefault);

  return (
    <div className="CampaignsTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        loading={loading}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <BasicModal captionId="justDeleteCampaign" isOpen={isDeleteModalOpen} onRequestClose={toggleDeleteModal}>
        <DeleteCampaignForm
          toggleModal={toggleDeleteModal}
          campaignList={data.results}
          defaultCampaign={defaultCampaign}
          campaignId={campaignId}
          params={params}
        />
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

CampaignsTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

CampaignsTable.defaultProps = {
  loading: false,
  theme: {},
};

export default withTheme()(ModalHOC(CampaignsTable));
export { CampaignsTable };
