import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MoneyCell, StatusCell, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import InstructionIcon from '../InstractionIcon';
import { staticStyles, getDynamicStyles } from './style';

const PaymentsInfoPanel = ({ data, addons, paymentSystemInstruction, isDeposit, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const paymentsInfoData = useMemo(
    () => [
      { titleId: 'paymentSystem', item: data.paymentMethodTitle },
      {
        titleId: 'justAmount',
        item: <MoneyCell tooltipId="payments-info-data-amount" value={data.amountWithCommission} heightMaxContent />,
      },
      { titleId: 'justID', item: data.id },
      {
        titleId: 'justDate',
        item: <DateCell hideTime value={data.created} />,
      },
      {
        titleId: 'justCommission',
        item: <MoneyCell tooltipId="payments-info-data-commision" value={data.commission} heightMaxContent />,
      },
      ...(!addons.includes('walletAccount') ? [{ titleId: 'justTradingAccount', item: data.accountLogin }] : []),
      {
        titleId: 'justStatus',
        item: <StatusCell statusCode={data.status} />,
      },
    ],
    [data]
  );

  return (
    <div className="PaymentsInfoPanelWrap">
      <div className="PaymentInfo">
        <div className="PaymentInfo__PaymentSystemIcon">
          <img src={data.paymentSystemIconUrl} alt="icon-payment-system" />
        </div>
        <div className="PaymentInfoData">
          {paymentsInfoData.map(({ titleId, item }) => (
            <div
              key={`payments-info-panel-item-${titleId}`}
              id={`client-dw-${isDeposit ? 'deposit' : 'withdrawal'}-${titleId}`}
              className="PaymentInfoData__Item">
              <span className="title">
                <FormattedMessage id={titleId}>{txt => txt}</FormattedMessage>
              </span>
              <span className="content">{item}</span>
            </div>
          ))}
        </div>
      </div>
      {paymentSystemInstruction && (
        <>
          <div className="divider" />
          <div className="Instruction">
            <div className="Instruction__Icon">
              <InstructionIcon />
            </div>
            <div
              className="Instruction__CustomMessage"
              dangerouslySetInnerHTML={{ __html: paymentSystemInstruction }}
            />
          </div>
        </>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

PaymentsInfoPanel.propTypes = {
  data: PropTypes.object,
  addons: PropTypes.array,
  paymentSystemInstruction: PropTypes.string,
  isDeposit: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

PaymentsInfoPanel.defaultProps = {
  data: {},
  addons: [],
  theme: {},
  paymentSystemInstruction: '',
};

export default withTheme()(PaymentsInfoPanel);
export { PaymentsInfoPanel };
