import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NoContactNotes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .NoNotesText {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
  }

  .NoContactNotes__NoteIcon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 48px;
    border: 2px solid #f3f3f3;
    background: #fff;
  }

  :global(.TopTab__CreateNoteButton) {
    margin-top: 24px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
