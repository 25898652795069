import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ProgressBar,
  TopMenu,
  LeftMenu,
  ConfigureMtForm,
  GenerateDnsTxtForm,
  CheckDomainForm,
  InfoBlock,
  NavToCRMBlock,
  CRMSettingForm,
} from '../../_components';

import { wsGetRegistrationStatus } from '../../_redux';

import { staticStyles } from './style';

const wsStepsTitles = {
  1: 'welcomeSetupRegistrationTitle',
  2: 'welcomeSetupConfigureMT',
  3: 'welcomeSetupConnectDomen',
  4: 'welcomeSetupConnectDomen',
  5: 'welcomeSetupCRMSetup',
  6: 'welcomeSetupBrokerCreated',
};

const Steps = ({ getWsStatus, wsStep, intl }) => {
  useEffect(() => {
    getWsStatus();
  }, []);

  const wsHelmetTitle = useMemo(() => intl.formatMessage({ id: wsStepsTitles[wsStep] }), [wsStep]);

  const WelcomeSetupRegistrationPageStyle = classNames('WelcomeSetupRegistrationPage', {
    'WelcomeSetupRegistrationPage--done': wsStep === 6,
  });
  return (
    <div className={WelcomeSetupRegistrationPageStyle}>
      <Helmet>
        <title>{wsHelmetTitle}</title>
      </Helmet>
      <TopMenu />
      {Number(wsStep) === 6 ? (
        <NavToCRMBlock />
      ) : (
        <>
          <div className="WelcomeSetupRegistrationPage__fmSlug">
            <FM id="menuCrmSetup" />
          </div>
          <div className="CrmSetupBlock">
            <LeftMenu />
            <div className="ProgressBarAndFormInfoBlock">
              <ProgressBar wsStep={wsStep} />
              <div className="FormAndInfoText">
                {Number(wsStep) === 2 && <ConfigureMtForm />}
                {Number(wsStep) === 3 && <GenerateDnsTxtForm />}
                {Number(wsStep) === 4 && <CheckDomainForm />}
                {Number(wsStep) === 5 && <CRMSettingForm />}
                <InfoBlock wsStep={wsStep} />
              </div>
            </div>
          </div>
        </>
      )}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

Steps.propTypes = {
  getWsStatus: PropTypes.func.isRequired,
  wsStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  connect(
    state => ({
      authorized: state.welcomeSetup.authorized,
      wsStep: state.welcomeSetup.wsStep || 2,
    }),
    {
      getWsStatus: () => wsGetRegistrationStatus.request(),
    }
  )
)(Steps);
