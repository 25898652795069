import React from 'react';

const CrossIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28902 7.99998L0.642578 1.35353L1.34968 0.646423L7.99613 7.29287L14.6426 0.646423L15.3497 1.35353L8.70324 7.99998L15.3497 14.6464L14.6426 15.3535L7.99613 8.70708L1.34968 15.3535L0.642578 14.6464L7.28902 7.99998Z"
      fill="#0C0C3D"
    />
  </svg>
);

export default CrossIcon;
