import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const AutologinEmailConfirmError = ({ theme, resetAuth }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const handleResetAuth = () => resetAuth('');

  return (
    <div className="AutologinEmailConfirmError">
      <div className="AutologinEmailConfirmError__top">
        <FormattedMessage id="validationErrorEmailIsNotConfirmed">{txt => <h3>{txt}</h3>}</FormattedMessage>
        <FormattedMessage id="registrationModalBody" />
      </div>

      <div className="AutologinEmailConfirmError__bottom">
        <FormattedMessage id="authDoLogin">
          {txt => (
            <button type="button" className="AutologinEmailConfirmError__link" onClick={handleResetAuth}>
              {txt}
            </button>
          )}
        </FormattedMessage>

        <FormattedMessage id="authNoEmailConfirmation">
          {txt => (
            <Link
              className="AutologinEmailConfirmError__link"
              to="/auth/resend-email-confirmation"
              onClick={handleResetAuth}>
              {txt}
            </Link>
          )}
        </FormattedMessage>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

AutologinEmailConfirmError.propTypes = {
  theme: PropTypes.object,
  resetAuth: PropTypes.func.isRequired,
};

AutologinEmailConfirmError.defaultProps = {
  theme: {},
};

export default withTheme()(AutologinEmailConfirmError);
export { AutologinEmailConfirmError };
