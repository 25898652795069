import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseSelect {
    position: relative;
    width: 100%;

    &__label {
      position: absolute;
      left: 16px;
      z-index: 10;

      font-size: 10px;
      line-height: 1.4;
      color: #999;
      background: #fff;
      padding: 0 4px;

      span {
        position: relative;
        top: -7px;
        z-index: 10;
      }

      &--error {
        color: #ff4085;
      }
    }

    .select__control {
      border: 1px solid #e9e9e9;
      border-radius: 8px;

      &--is-focused {
        box-shadow: none;
      }
    }

    .select__menu {
      z-index: 999;
      margin-top: 2px;
      border-radius: 0;
      > .select__menu-list {
        padding: 0;
      }
    }
    .select__indicator-separator {
      display: none;
    }

    .select__placeholder {
    }

    .select__value-container {
      padding-left: 16px;
    }

    .select__single-value {
      margin-left: 0;
      font-size: 14px;
      line-height: 1.4;
      color: #333;
    }
  }
`;

export { staticStyles };
