import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseTable__wrapper {
    overflow: auto;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #343846;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #d6dbe0;
    }

    .BaseTable {
      position: relative;
      min-width: 100%;
      border-spacing: 0;

      tr:not(.BaseTable__expanded-row):not(.BaseTable__loading) {
        display: flex;
        border-collapse: collapse;
        min-width: 100%;
      }

      .BaseTable__arrow {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      .BaseTable__arrow--up {
        border-bottom: 7px solid #000;
      }

      .BaseTable__arrow--down {
        border-top: 7px solid #000;
      }

      td {
        padding: rem(5px 10px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        // &:hover {
        //   background: #ceecf9 !important;
        //   opacity: 0.4;
        // }
      }

      th {
        padding: rem(5px 10px);
        border: 1px solid #fff;
        flex: 1;
      }

      .checkboxWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      :global(.BaseTable__thead-caption),
      .BaseTable__tbody-cell {
        white-space: normal;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #696969;
      }

      .BaseTable__thead-row {
        position: sticky;
        top: 0;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;

        input {
          appearance: auto;
          padding: 0px 30px;
          border: none;
          font-size: rem(14px);
        }

        .BaseTable__thead-cell {
          position: relative;

          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          &:nth-child(2) {
          }
        }
      }

      .BaseTable__filter {
        display: none;
      }

      .BaseTable__tbody {
        position: relative;
        border: 1px solid #e9e9e9;
      }

      .BaseTable__noData {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-template-columns: auto !important;

        td {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .BaseTable__noData-img {
          width: 60px;
          height: 60px;
          margin-bottom: rem(10px);
        }
      }

      .BaseTable__loading {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .BaseTable__tbody-row {
        position: relative;
        cursor: pointer;

        :nth-of-type(1) {
          .BaseTable__tbody-cell {
            border-top: 1px solid #e9e9e9;
          }
        }
      }

      .BaseTable__thead-cell {
        font-size: rem(14px);
        font-weight: 300;

        input[type='checkbox'] {
          appearance: auto;
          opacity: 1;
          width: auto;
        }
      }

      .BaseTable__tbody-cell {
        cursor: default;
        display: flex;
        align-items: center;
        border-right: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;

        :nth-of-type(1) {
          border-left: 1px solid #e9e9e9;
        }

        span {
          font-size: rem(14px);
        }

        input[type='checkbox'] {
          appearance: auto;
          opacity: 1;
          width: auto;
        }
      }

      .BaseTable__tfoot-cell {
        display: flex;
        align-items: center;
      }

      .BaseTable__tfoot-cell {
        display: flex;
        align-items: center;
      }

      .BaseTable__expanded-row {
        > td {
          padding: 0;
        }
      }

      .BaseTable__tfoot {
        .TotalCell {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .subtext {
            margin-bottom: rem(10px);
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global`
    .BaseTable__wrapper {
      .BaseTable {
        tr {
          display: flex;
          /* grid-template-columns: repeat(1, minmax(50px, 0.2fr)) repeat(${columns.length}, minmax(100px, 1fr)); */
          /* grid-template-columns: repeat(1, minmax(50px, 0.2fr)) repeat(1, minmax(250px, 1.5fr)) repeat(${columns.length -
          2}, minmax(100px, 1fr)) repeat(1, minmax(200px, 2fr)); */
        }
        td {
          &:hover {
            background: #ceecf9 !important;
            opacity: 0.4;
          }
        }

        .BaseTable__thead-row {
          color: ${colors.gray.hex};
        }

        .BaseTable__thead-cell:not(:nth-last-child(-n + 2)) {
          background: ${colors.columHead.hex};
          border-radius: 10px 10px 0px 0px;
        }

        .BaseTable__noData {
          background-color: ${colors.white.hex};
          color: ${colors.gray.hex};
        }

        .BaseTable__loading {
          background-color: ${rgba([colors.white.rgb, 0.4])};
        }

        .BaseTable__tbody-row {
          background-color: ${colors.white.hex};
          transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          box-shadow: inset 0 0 0 0 transparent;
          border-radius: ${border.radius};

          &:hover {
            z-index: 1;
          }
        }

        .BaseTable__tbody-row--unclickable {
          cursor: unset;
          &:hover {
            box-shadow: none;
          }
        }

        .BaseTable__tfoot-row {
          background-color: ${colors.white.hex};
          border-top: 2px solid ${rgba([colors.gradientStart.rgb, 0.8])};
        }

        .BaseTable__expanded-row {
          grid-column: 1 / ${columns.length + 1};
        }

        .BaseTable__tfoot {
          .TotalCell {
            .subtext {
              font-weight: 400 !important;
              color: ${colors.gray.hex};
            }
          }
        }

        .BaseTable__arrow--active {
          border-color: ${colors.primary.hex};
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
