import css from 'styled-jsx/css'

import { gradient, rgba } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PagePagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: rem(15px);
    @include respond-to(sm) {
      justify-content: center;
    }
    .previous, .next {
      @include respond-to(sm) {
        width: 50%;
        order: 0;
        margin: 0 0 15px 0;
      }
      a {
        position: relative;
        display: inline-block;
        cursor: pointer;
        font-size: rem(15px);
        line-height: rem(22px);
        padding: rem(9px 20px);
        background: transparent;
        letter-spacing: rem(.5px);
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        transition: all .3s ease;
        &:active {
          box-shadow: none;
        }
        &:after {
          content: '';
          top: 39%;
          position: absolute;
          display: inline-block;
          padding: rem(4px);
        }
      }
      &.disabled {
        a {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
    .previous {
      a {
        padding-left: rem(30px);
        &:after {
          transform: rotate(135deg);
          left: rem(10px);
        }
      }
    }
    .next {
      @include respond-to(sm) {
        text-align: right;
      }
      a {
        padding-right: rem(30px);
        &:after {
          transform: rotate(-45deg);
          right: rem(10px);
        }
      }
    }
    li {
      margin: rem(0 5px);
      @include respond-to(sm) {
        margin: 0 1px;
        order: 1
      }
      a {
        cursor: pointer;
        transition: all .3s ease;
        border: 1px solid transparent;
        padding: rem(3px 8px);
        border-radius: 50%;
      }
    }
    .PagePagination__break {
      a {
        &:hover {
          border: 1px solid transparent;
        }
      }
    }
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .PagePagination {
      .previous, .next {
        a {
          border-radius: ${border.radius};
          border: 1px solid ${colors.primary.hex};
          color: ${colors.primary.hex};
          &:hover {
            border-color: ${rgba([colors.primary.rgb, 0.8])};
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
            color: ${rgba([colors.primary.rgb, 0.8])};
          }
          &:after {
            border: solid ${colors.primary.hex};
            border-width: 0 1px 1px 0;
          }
        }
        &.disabled {
          a {
            border-color: transparent;
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
          }
        }
      }
      li {
        a {
          color: ${colors.primary.hex};
          &:hover {
            border-color: ${colors.primary.hex};
          }
        }
      }
      .PagePagination__active {
        a {
          border-color: ${colors.primary.hex};
          background: ${colors.primary.hex};
          color: ${colors.white.hex};
        }
      }
      .PagePagination__break {
        a {
          color: ${colors.black.hex};
          &:hover {
            color: ${colors.primary.hex};
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
