import React from 'react';
import { colorType, withTheme } from '../../../../../common/styling/theme';

export const DepositsIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H17C18.1046 0 19 0.895431 19 2V9C19 10.1046 18.1046 11 17 11H2C0.895431 11 0 10.1046 0 9V2ZM0 13.5C0 12.6716 0.671573 12 1.5 12H7.5C8.32843 12 9 12.6716 9 13.5V17.5C9 18.3284 8.32843 19 7.5 19H1.5C0.671573 19 0 18.3284 0 17.5V13.5ZM11.5 12C10.6716 12 10 12.6716 10 13.5V17.5C10 18.3284 10.6716 19 11.5 19H17.5C18.3284 19 19 18.3284 19 17.5V13.5C19 12.6716 18.3284 12 17.5 12H11.5Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.34249"
          y1="20.4072"
          x2="21.1806"
          y2="15.0523"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

DepositsIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

DepositsIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedDepositsIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(DepositsIconActive);

export default ThemedDepositsIconActive;
