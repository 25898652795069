import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { required } from 'redux-form-validators';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change, untouch } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormMessageHOC } from 'common';
import { withTheme } from 'common/styling/theme';

import { DefaultButton, ReduxSelectField, BaseTextField } from 'common/components';

import { staticStyles, getDynamicStyles } from './style';

import { createBanners } from '../../_redux';

const BannerFilterForm = ({
  campaigns,
  banners,
  partnerAccounts,
  dimensions,
  languages,
  rotatorLink,
  showFormSuccessMessage,
  submitting,
  error,
  location,
  history,
  dispatch,
  intl,
  theme,
}) => {
  const [isPartnerAccountSelected, setPartnerAccountSelected] = useState(false);
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const query = queryString.parse(location.search);

  const campaignsOptions = campaigns.map(campaign => ({ value: campaign.id, label: campaign.title }));
  const languagesOptions = languages.map(language => ({
    value: language,
    label: intl.formatMessage({ id: `lang_${language}` }),
  }));
  const dimensionsOptions = dimensions.map(dimension => ({ value: dimension, label: dimension }));
  const partnerAccountOptions = partnerAccounts.map(account => ({ value: account.id, label: account.partnerCode }));

  const handleCopyClick = e => {
    e.stopPropagation();
    navigator.clipboard.writeText(rotatorLink);
    showFormSuccessMessage(intl.formatMessage({ id: 'adminUrlCopied' }));
  };

  const handleChangeFilter = (filter, value) => {
    dispatch(change('BannerFilterForm', 'partnerAccount', null));
    dispatch(untouch('BannerFilterForm', 'partnerAccount'));
    if (isPartnerAccountSelected) {
      setPartnerAccountSelected(false);
    }

    history.replace({
      ...location,
      search: queryString.stringify({ ...query, [filter]: value }),
    });
  };

  const handleCampaignsChange = (...values) => {
    if (values[3] === 'campaigns' && values[0]) {
      dispatch(change('BannerFilterForm', 'campaigns', values[0]));
      dispatch(untouch('BannerFilterForm', 'campaigns'));
      handleChangeFilter('campaigns', values[0]);
    } else {
      dispatch(change('BannerFilterForm', 'campaigns', null));
      dispatch(untouch('BannerFilterForm', 'campaigns'));
      handleChangeFilter('campaigns', undefined);
    }
  };

  const handleSelectPartnerAccount = partnerAccount => {
    const bannerIds = banners.map(banner => banner.id);
    if (bannerIds && partnerAccount) {
      setPartnerAccountSelected(true);
      createBanners({ isRotator: true, partnershipAccount: partnerAccount, banners: bannerIds }, dispatch);
    }
  };

  return (
    <form className="BannerFilterForm">
      <FormattedMessage id="chooseCampaigns">
        {txt => <span className="BannerFilterForm__title">{`${txt}:`}</span>}
      </FormattedMessage>
      <div className="BannerFilterForm__fields">
        <div className="BannerFilterForm__block">
          <Field
            component={ReduxSelectField}
            name="campaigns"
            textId="justCampaigns"
            placeholder={intl.formatMessage({ id: 'justCampaigns' })}
            options={campaignsOptions}
            disabled={submitting}
            onChange={handleCampaignsChange}
            validate={[required()]}
            multi
          />
        </div>
        <div className="BannerFilterForm__block BannerFilterForm__block--half">
          <Field
            component={ReduxSelectField}
            name="bannerLanguage"
            textId="bannerLanguage"
            options={languagesOptions}
            disabled={submitting}
            onChange={value => handleChangeFilter('language', value)}
            validate={[required()]}
          />
          <Field
            component={ReduxSelectField}
            name="bannerDimension"
            textId="bannerSize"
            options={dimensionsOptions}
            disabled={submitting}
            onChange={value => handleChangeFilter('dimension', value)}
            validate={[required()]}
          />
        </div>
        <div className="BannerFilterForm__block">
          <Field
            component={ReduxSelectField}
            name="partnerAccount"
            textId="justPartnerAccount"
            options={partnerAccountOptions}
            disabled={submitting}
            onChange={handleSelectPartnerAccount}
            validate={[required()]}
          />
        </div>
      </div>
      <div className="BannerFilterForm__link">
        <FormattedMessage id="rotatorLinkLabel">
          {txt => <span className="BannerFilterForm__label">{`${txt}:`}</span>}
        </FormattedMessage>
        <BaseTextField
          type="text"
          name="rotatorLinkLabel"
          value={isPartnerAccountSelected ? rotatorLink : null}
          disabled
        />
        <DefaultButton textId="justCopy" type="button" onClick={handleCopyClick} filled />
      </div>

      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

BannerFilterForm.propTypes = {
  campaigns: PropTypes.array.isRequired,
  banners: PropTypes.array.isRequired,
  rotatorLink: PropTypes.string.isRequired,
  dimensions: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  partnerAccounts: PropTypes.array.isRequired,
  showFormSuccessMessage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

BannerFilterForm.defaultProps = {
  error: '',
  theme: {},
};

const selector = formValueSelector('BannerFilterForm');

export default compose(
  withTheme(),
  withRouter,
  injectIntl,
  FormMessageHOC,
  connect((state, props) => {
    const query = queryString.parse(props.location.search);
    const campaigns = state.client.partnership.campaigns;
    const selectedCamps = selector(state, 'campaigns') || [];
    const queryCampaigns = Array.isArray(query.campaigns)
      ? query.campaigns.map(i => Number(i))
      : [Number(query.campaigns)];
    const queryLanguage = query.language || null;
    const queryDimension = query.dimension || null;

    return {
      campaigns,
      languages: campaigns
        .filter(item => selectedCamps.includes(item.id))
        .reduce((accumulator, currentValue) => {
          currentValue.languages.forEach(item => {
            if (Array.isArray(accumulator) && !accumulator.includes(item)) {
              accumulator.push(item);
            }
          });
          return accumulator;
        }, []),
      dimensions: campaigns
        .filter(item => selectedCamps.includes(item.id))
        .reduce((accumulator, currentValue) => {
          currentValue.dimensions.forEach(item => {
            if (Array.isArray(accumulator) && !accumulator.includes(item)) {
              accumulator.push(item);
            }
          });
          return accumulator;
        }, []),
      banners: state.client.partnership.partnershipMaterials,
      rotatorLink: state.client.partnership.rotatorLink,
      initialValues: {
        campaigns: queryCampaigns,
        bannerLanguage: queryLanguage,
        bannerDimension: queryDimension,
      },
    };
  }),

  reduxForm({
    form: 'BannerFilterForm',
  })
)(BannerFilterForm);
export { BannerFilterForm };
