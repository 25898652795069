import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, AsyncSelectField } from 'common/components';

import { editDepositAmount } from 'backoffice/payments/_redux';
import { FormattedMessage } from 'react-intl';

import staticStyles from './style';
import { getBackofficeManagerList } from '../../api';

const EditPaymentManagerForm = ({ handleSubmit, pristine, submitting, invalid, dispatch, error, id, type }) => {
  const submit = handleSubmit(values => editDepositAmount({ id, type, ...values }, dispatch));

  const loadOptions = search =>
    getBackofficeManagerList(search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    );

  return (
    <div className="RejectModal__content">
      <form className="EditPaymentManagerForm" onSubmit={submit}>
        <FormattedMessage id="editManagerDescription">
          {txt => <p className="EditUserManagerForm__description">{txt}</p>}
        </FormattedMessage>
        <div className="EditUserManagerForm__inputs-inner">
          <Field
            textId="justManager"
            component={AsyncSelectField}
            name="manager"
            loadOptions={loadOptions}
            validate={[required()]}
            disabled={submitting}
            maxMenuHeight={270}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </div>

        <DefaultButton
          textId="justChange"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          filled
        />
        {error && <strong className="formError">{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

EditPaymentManagerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

EditPaymentManagerForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'EditPaymentManagerForm',
})(EditPaymentManagerForm);
export { EditPaymentManagerForm };
