import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import BaseStaticInput from '../BaseStaticInput';

import { staticStyles } from './style';

const InputStr = ({ name, value, onChange, isDisabled }) => (
  <div className="InputStr">
    <div className="InputStr__Title">
      <FM id={name}>{txt => <span className="InputStr__Title-text">{txt}</span>}</FM>
    </div>
    <BaseStaticInput isDisabled={isDisabled} onlyNumbers value={value} onChange={onChange} />
    <style jsx>{staticStyles}</style>
  </div>
);

InputStr.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
};

InputStr.defaultProps = {
  isDisabled: false,
  value: '',
};

export default InputStr;
