import React from 'react';
import css from 'styled-jsx/css';

const staticStyle = css`
  .CheckedIcon {
    border-radius: 8px;
    border: 1px solid #0aa1e2;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
    cursor: pointer;
    background: #0aa1e2;
    svg {
      path {
        fill: white !important;
      }
    }
  }
  .CheckedIcon--Green {
    border: #39b841 !important;
    background: #39b841 !important;
  }
`;

const CheckedIcon = ({ green }) => (
  <div className={!green ? 'CheckedIcon' : 'CheckedIcon CheckedIcon--Green'}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path
        d="M13.8524 0.396665C14.0492 0.590404 14.0492 0.909136 13.8524 1.10288L5.35283 9.6024C5.15909 9.79926 4.84035 9.79926 4.64662 9.6024L0.146429 5.10265C-0.0488098 4.90891 -0.0488098 4.59018 0.146429 4.39644C0.341543 4.19958 0.658088 4.19958 0.85339 4.39644L4.99972 8.54309L13.1461 0.396665C13.3399 0.201051 13.6586 0.201051 13.8524 0.396665Z"
        fill="white"
      />
    </svg>

    <style jsx>{staticStyle}</style>
  </div>
);

export default CheckedIcon;
