import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  groups: [],
  groupsAreLoaded: false,

  specs: [],
  specsAreLoaded: false,
  commentsListPending: false,
  commentsList: [],
  commentsListError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_GROUPS_LIST[REQUEST]:
      return { ...state, groupsAreLoaded: false };
    case ActionTypes.GET_GROUPS_LIST[SUCCESS]:
      return {
        ...state,
        groups: action.data,
        groupsAreLoaded: true,
      };
    case ActionTypes.GET_GROUPS_LIST[FAILURE]:
      return {
        ...state,
        error: action.error,
        groupsAreLoaded: false,
      };

    case ActionTypes.GET_CONTRACT_SPECS[REQUEST]:
      return { ...state, specsAreLoaded: false };
    case ActionTypes.GET_CONTRACT_SPECS[SUCCESS]:
      return {
        ...state,
        specs: action.data,
        specsAreLoaded: true,
      };
    case ActionTypes.GET_CONTRACT_SPECS[FAILURE]:
      return {
        ...state,
        error: action.error,
        specsAreLoaded: false,
      };
    case ActionTypes.GET_COMMENTS_LIST[REQUEST]:
      return {
        ...state,
        commentsListPending: true,
      };
    case ActionTypes.GET_COMMENTS_LIST[SUCCESS]:
      return {
        ...state,
        commentsListPending: false,
        commentsList: action.data,
      };
    case ActionTypes.GET_COMMENTS_LIST[FAILURE]:
      return {
        ...state,
        commentsListPending: false,
        commentsListError: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
