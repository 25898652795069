import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { PageContentPreloader, PageLayout, PagePagination } from 'common/components';

import { getAnalyticReviews } from '../_redux';

import ArticlesItem from '../_components/ArticlesItem';

class TechnicalAnalysisPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoadRequests();
  }

  handleLoadRequests = () => {
    const query = queryString.parse(this.props.location.search);
    query.limit = 10;
    query.language = this.props.lang === 'ru' ? 'ru' : 'en';
    query.offset = query.offset ? query.offset : 0;
    this.props.getAnalyticReviews({ paginationParams: query });
  };

  render() {
    const { location, history, getAnalyticReviews, analyticReviews, areLoaded, lang } = this.props;

    return (
      <PageLayout
        captionSlug="technicalAnalysis"
        showReload
        onReloadClick={this.handleLoadRequests}
        reloading={!areLoaded}>
        <PageContentPreloader ready={areLoaded} firstLaunchOnly type="pagination">
          <PagePagination
            location={location}
            history={history}
            pageCount={analyticReviews.count}
            getList={getAnalyticReviews}
            lang={lang}
          />
        </PageContentPreloader>
        <PageContentPreloader ready={areLoaded} type="news">
          {areLoaded &&
            analyticReviews.results.map(analyticReview => (
              <ArticlesItem
                key={analyticReview.id}
                id={analyticReview.id}
                date={analyticReview.publicationDate}
                title={analyticReview.title}
                body={analyticReview.content}
              />
            ))}
        </PageContentPreloader>
        <PageContentPreloader ready={areLoaded} firstLaunchOnly type="pagination">
          <PagePagination
            location={location}
            history={history}
            pageCount={analyticReviews.count}
            getList={getAnalyticReviews}
            lang={lang}
          />
        </PageContentPreloader>
      </PageLayout>
    );
  }
}

TechnicalAnalysisPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getAnalyticReviews: PropTypes.func.isRequired,
  analyticReviews: PropTypes.object.isRequired,
  areLoaded: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    analyticReviews: state.client.articles.analyticReviews,
    areLoaded: state.client.articles.analyticReviewsAreLoaded,
    lang: state.interfaceConfig.lang,
  }),
  { getAnalyticReviews: paginationParams => getAnalyticReviews.request(paginationParams) }
)(TechnicalAnalysisPage);
