import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .MonthColumnChart__wrapper {
    padding: 25px 40px 5px 5px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    width: 65%;
    @include respond-to(lg) {
      padding: 20px 30px 5px 5px;
    }
    @include respond-to(sm) {
      padding: 15px 20px 5px 5px;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .MonthColumnChart__top-line {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0 0 5px 30px;
    position: relative;
    padding-bottom: 2px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
    }
  }

  .MonthColumnChart__compare-block {
    display: flex;
    align-items: center;
  }

  .MonthColumnChart__compare-block-text {
    margin: 0 10px;
    text-transform: lowercase;
    font-size: 12px;
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .MonthColumnChart__wrapper {
      background: ${colors.white.hex};
      border-radius: ${border.radius};
    }

    .MonthColumnChart__top-line {
      &:after {
        background: ${colors.lightGray.hex};
      }
    }

    .MonthColumnChart__compare-block-text {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
