import axios from 'axios';

const crmBaseClient = axios.create({ baseURL: '/api/', validateStatus: status => status < 500 });

crmBaseClient.interceptors.request.use(config => {
  const locale = localStorage.getItem('locale');

  if (locale) config.headers.locale = locale;

  return config;
});

crmBaseClient.interceptors.request.use(request => {
  const token = localStorage.getItem('jwtToken');
  const locale = localStorage.getItem('locale');

  if (locale) {
    request.headers.locale = locale;
  }
  if (token) {
    request.headers.Authorization = `JWT ${token}`;
  }
  return request;
});

export const getBrokerTotalInBalance = () => crmBaseClient.get('/crm/contacts/broker_total_balance_in/');

export const getBrokerTotalOutBalance = () => crmBaseClient.get('/crm/contacts/broker_total_balance_out/');

export const getCrmClientsAnalytics = search =>
  crmBaseClient.get(`/crm/dashboards/clients/`, { params: { ...search } });

export const getCrmManagersAnalytics = search =>
  crmBaseClient.get(`/crm/dashboards/managers/`, { params: { ...search } });

export const getCrmManagers = search => crmBaseClient.get(`/crm/contacts/list/`, { params: { ...search } });

export const getCRMManagersFiltersOptions = () => axios.get('/crm/dashboards/managers/filter/options/');

// TODO:Реализовать с бека
export const getCRMManagersFiltersList = () => axios.get('/crm/dashboards/managers/filter/list/');

export const deleteCRMManagersCustomFilter = id => axios.delete(`crm/dashboards/analytics/filter/${id}/`);
export const editCRMManagersCustomFilter = (id, data) =>
  axios.put(`crm/dashboards/analytics/filter/${id}/`, { ...data });
export const getCRMManagersFilter = id => axios.get(`crm/dashboards/analytics/filter/${id}/`);

export const createCRMManagersCustomFilter = payload =>
  axios.post('/crm//dashboards/managers/filter/create/', { ...payload });
