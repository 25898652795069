import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  filtersList: [],
  filtersListPending: false,
  filtersListError: null,

  filtersOptions: {},
  filtersOptionsPending: false,
  filtersOptionsError: null,

  createCustomFilterPending: false,
  createCustomFilterError: null,
  filterCreated: false,

  clients: {},
  clientsAreLoaded: false,

  managersTotal: {},
  managersTotalAreLoaded: false,

  managers: {},
  managersAreLoaded: false,

  crmFilterIsLoaded: false,
  crmFilter: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CRM_MANAGERS_FILTER[REQUEST]:
      return { ...state, crmFilterIsLoaded: false };
    case ActionTypes.GET_CRM_MANAGERS_FILTER[SUCCESS]:
      return { ...state, crmFilterIsLoaded: true, crmFilter: action.data };
    case ActionTypes.GET_CRM_MANAGERS_FILTER[FAILURE]:
      return { ...state, error: action.error, crmFilterIsLoaded: false };

    case ActionTypes.createCRMManagersCustomFilter[REQUEST]:
      return {
        ...state,
        createCustomFilterPending: true,
      };

    case ActionTypes.createCRMManagersCustomFilter[SUCCESS]:
      return {
        ...state,
        filterCreated: action.bool,
        createCustomFilterPending: false,
      };

    case ActionTypes.createCRMManagersCustomFilter[FAILURE]:
      return {
        ...state,
        createCustomFilterError: action.error,
      };
    case ActionTypes.GET_CRM_MANAGERS_FILTERS_OPTIONS[REQUEST]:
      return {
        ...state,
        filtersOptionsPending: true,
      };

    case ActionTypes.GET_CRM_MANAGERS_FILTERS_OPTIONS[SUCCESS]:
      return {
        ...state,
        filtersOptions: action.data,
        filtersOptionsPending: false,
      };

    case ActionTypes.GET_CRM_MANAGERS_FILTERS_OPTIONS[FAILURE]:
      return {
        ...state,
        filtersOptionsError: action.error,
      };
    case ActionTypes.GET_CRM_CLIENTS_ANALYTICS[REQUEST]:
      return { ...state, clientsAreLoaded: false };
    case ActionTypes.GET_CRM_CLIENTS_ANALYTICS[SUCCESS]: {
      return { ...state, clients: action.data, clientsAreLoaded: true };
    }
    case ActionTypes.GET_CRM_CLIENTS_ANALYTICS[FAILURE]:
      return { ...state, error: action.error, clientsAreLoaded: false };

    case ActionTypes.GET_CRM_MANAGERS_ANALYTICS[REQUEST]:
      return { ...state, managersAreLoaded: false };
    case ActionTypes.GET_CRM_MANAGERS_ANALYTICS[SUCCESS]:
      return { ...state, managers: action.data, managersAreLoaded: true };
    case ActionTypes.GET_CRM_MANAGERS_ANALYTICS[FAILURE]:
      return { ...state, error: action.error, managersAreLoaded: false };
    default:
      return state;
  }
};

export default reducer;
