import React from 'react';

const CustomizationIcon = () => (
  <svg className="svgNoFill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill"
      d="M18.306 4.5H17.7143V3.23047C17.7143 2.54109 17.2221 2 16.5918 2H5.16318C4.53289 2 4 2.54109 4 3.23047V6.98047C4 7.66984 4.53289 8.25 5.16318 8.25H16.5918C17.2221 8.25 17.7143 7.66984 17.7143 6.98047V5.75H18.306C18.621 5.75 18.8571 6.01094 18.8571 6.35547V10.1055C18.8571 10.45 18.6211 10.75 18.306 10.75H12.0203C11.075 10.75 10.2857 11.5715 10.2857 12.6055V13.875H9.16318C8.84761 13.875 8.57143 14.1353 8.57143 14.4805V21.3555C8.57143 21.7006 8.84761 22 9.16318 22H12.5918C12.9073 22 13.1429 21.7006 13.1429 21.3555V14.4805C13.1429 14.1353 12.9073 13.875 12.5918 13.875H11.4286V12.6055C11.4286 12.2609 11.7053 12 12.0203 12H18.306C19.2514 12 20 11.1394 20 10.1055V6.35547C20 5.32152 19.2514 4.5 18.306 4.5ZM5.14286 7V3.25H16.5918L16.5926 7H5.14286ZM12 20.75H9.71429V15.125H12V20.75Z"
      fill="#0C0C3D"
    />
  </svg>
);

export default CustomizationIcon;
