/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import classNames from 'classnames';

import queryString from 'query-string';

import { UpdatedCRMTable, NewCRMPagination } from 'crm/_newComponents';

import { PageContentPreloader, DateCell, MoneyCell } from 'common/components';

import NewFullNameCell from 'crm/contacts/_components/NewContactsTable/NewFullNameCell';
import { getCrmClientsAnalytics } from 'crm/analytics/_redux';

import { staticStyles } from './style';

const ClientsTable = ({ clients, date, clientsAreLoaded, getCrmClientsAnalytics, location }) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const totalDiff = clients?.total_in_sum?.amount
    ? {
        // eslint-disable-next-line no-unsafe-optional-chaining
        amount: +clients?.total_in_sum?.amount - +clients?.total_out_sum?.amount,
        currency: clients?.total_in_sum?.currency,
      }
    : null;

  const tableSetup = {
    columns: [
      {
        accessor: 'fullName',
        Header: 'justName',
        onTopInMobile: true,
        isSortable: true,
        Cell: ({ rowData: { id, fullName, isManager } }) => (
          <NewFullNameCell id={id} analytics fullName={fullName} isManager={isManager} />
        ),
      },
      {
        accessor: 'lastSeen',
        isSortable: true,
        Header: 'justLastSeen',
        Cell: ({ rowData: { lastSeen } }) => (lastSeen ? <DateCell value={lastSeen} hideTime grey /> : '---'),
        removeInMobile: true,
      },
      {
        accessor: 'totalIn',
        isSortable: true,
        Header: 'justIn',
        HeaderTop: <MoneyCell heightMaxContent crmStyle value={clients.total_in_sum} />,
        Cell: ({ rowData: { totalIn } }) => <MoneyCell crmStyle heightMaxContent value={totalIn} />,
        removeInMobile: true,
      },
      {
        accessor: 'totalOut',
        isSortable: true,
        Header: 'justOut',
        HeaderTop: <MoneyCell crmStyle heightMaxContent value={clients.total_out_sum} />,
        Cell: ({ rowData: { totalOut } }) => <MoneyCell crmStyle heightMaxContent value={totalOut} />,
        removeInMobile: true,
      },
      {
        accessor: 'totalDiff',
        isSortable: true,
        Header: 'justInAndOut',
        HeaderTop: <MoneyCell crmStyle heightMaxContent value={totalDiff} />,
        Cell: ({ rowData: { totalDiff } }) => <MoneyCell crmStyle heightMaxContent value={totalDiff} />,
        removeInMobile: true,
      },
      {
        Header: 'justBalance',
        accessor: 'balance',
        isSortable: true,
        Cell: ({ rowData: { balance } }) => <MoneyCell crmStyle heightMaxContent value={balance} />,
        removeInMobile: true,
      },
    ],
    initSubRow: [
      {
        Header: 'justLastComment',
        Cell: ({ rowData: { lastTask } }) => (lastTask?.comment ? lastTask?.comment : '---'),
      },
    ],
  };

  useEffect(() => {
    getCrmClientsAnalytics({ search: query });
  }, [query, date]);

  return (
    <div className={classNames('ClientsTable')}>
      <PageContentPreloader ready={clientsAreLoaded} type="crmTable" noPadding>
        <UpdatedCRMTable data={clients.results} tableSetup={tableSetup} whereOpenSubRowIconIndex={0} />
        {!!clients.count && <NewCRMPagination count={clients.count} />}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ClientsTable.propTypes = {
  clients: PropTypes.object.isRequired,
  clientsAreLoaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      clients: state.crm.analytics.clients,
      clientsAreLoaded: state.crm.analytics.clientsAreLoaded,
      profilePerms: state.user.profile.perms,
      addons: state.interfaceConfig.addons,
    }),
    {
      getCrmClientsAnalytics: search => getCrmClientsAnalytics.request(search),
    }
  )
)(ClientsTable);
