import React from 'react';
import PropTypes from 'prop-types';

import { PageLayout, PanelTabs } from 'common/components';
import { PartnershipBannersPage } from '..';

const PartnershipMaterialsPage = ({ match, history, location }) => (
  <PageLayout captionSlug="justPromoMaterials" extraCaptionText={match.params.id} showReload={false}>
    <PanelTabs activeTabSlug="banners" location={location} history={history}>
      <PartnershipBannersPage textId="bannerResourceTitle" slug="banners" match={match} />
    </PanelTabs>
  </PageLayout>
);

PartnershipMaterialsPage.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default PartnershipMaterialsPage;
