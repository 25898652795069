import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { BaseButton } from 'crm/_components';
import { staticStyles } from './style';

import { NoteIcon } from './icons';

const NoContactNotes = ({ openCreateNoteModal }) => (
  <div className="NoContactNotes">
    <div className="NoContactNotes__NoteIcon">
      <NoteIcon />
    </div>
    <FM id="crmNoNotesFound">{txt => <span className="NoNotesText">{txt}</span>}</FM>
    <BaseButton
      maxContent
      className="TopTab__CreateNoteButton"
      textId="crmNote"
      onClick={openCreateNoteModal}
      plus
      color="white"
    />

    <style jsx>{staticStyles}</style>
  </div>
);

NoContactNotes.propTypes = {
  openCreateNoteModal: PropTypes.func.isRequired,
};
export default withTheme()(NoContactNotes);
