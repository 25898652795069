import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import {
  AsyncSelectField,
  ReduxTextField,
  TextAreaField,
  ReduxSelectField,
  DefaultButton,
  DateField,
} from 'common/components';
import { TaskIcon, ICON_TYPES } from '../TaskIcon';

import { createTask } from '../../_redux';

import staticStyles from './style';
import { getContacts } from '../../../users/api';

const CreateTaskForm = ({ managers, toggleModal, handleSubmit, dispatch, submitting, invalid, error }) => {
  const submit = handleSubmit(values => createTask(values, dispatch));

  const yearsOptions = [...Array(10).keys()].map(i => ({
    value: moment().year() + i,
    label: moment().year() + i,
  }));

  const loadOptions = search =>
    getContacts({ search }).then(({ data }) => data.map(user => ({ value: user.id, label: user.fullName })));

  return (
    <div className="CreateTaskForm__content">
      <form className="CreateTaskForm" onSubmit={submit}>
        <div className="CreateTaskForm__inputs">
          <Field
            component={ReduxTextField}
            autoComplete="off"
            type="text"
            name="title"
            textId="justTitle"
            validate={[required()]}
            disabled={submitting}
          />
          <div className="CreateTaskForm__inputs-inner">
            <Field
              component={DateField}
              type="date"
              name="expirationDate"
              textId="justExpirationTime"
              placeholder="dd.mm.yyyy"
              disabled={submitting}
              validate={[required()]}
              yearsOptions={yearsOptions}
              areFutureDaysAvailable
              withTime
            />
            <Field
              component={ReduxSelectField}
              name="icon"
              maxMenuHeight={140}
              textId="justIcon"
              options={ICON_TYPES.map(icon => ({
                value: icon,
                label: <TaskIcon type={icon} />,
              }))}
              validate={[required()]}
              disabled={submitting}
              searchable
            />
          </div>
          <div className="CreateTaskForm__inputs-inner">
            <Field
              component={ReduxSelectField}
              name="assignedTo"
              maxMenuHeight={140}
              textId="justAssignedTo"
              options={managers.map(manager => ({
                value: manager.id,
                label: `${manager.firstName} ${manager.lastName}`,
              }))}
              validate={[required()]}
              disabled={submitting}
              searchable
            />
            <Field
              textId="justSubject"
              component={AsyncSelectField}
              name="subject"
              loadOptions={loadOptions}
              disabled={submitting}
              maxMenuHeight={140}
            />
          </div>
          <Field
            component={TextAreaField}
            name="text"
            textId="justTaskText"
            rows="4"
            cols="150"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
        <div className="CreateTaskForm__btns">
          <DefaultButton
            textId="justCreateTask"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" onClick={toggleModal} type="button" />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

CreateTaskForm.propTypes = {
  managers: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

CreateTaskForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'CreateTaskForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(CreateTaskForm);
export { CreateTaskForm };
