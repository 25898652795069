import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, change, untouch } from 'redux-form';
import { numericality, required } from 'redux-form-validators';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReduxTextField, DefaultButton, ReduxSelectField, SwitchField, DateField } from 'common/components';
import { activateUserBonus } from '../../_redux';
import { staticStyles } from './style';

const UserBonusCreateForm = ({
  userId,
  accounts,
  selectedProgramId,
  bonusPrograms,
  userAvailableDeposits,
  isConvertible,
  isEndless,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  intl,
  error,
}) => {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const manualBonusId = useMemo(
    () => (Array.isArray(bonusPrograms) ? bonusPrograms.find(item => item.typeSlug === 'bonus_manual')?.id : 0),
    [bonusPrograms]
  );

  const submit = useMemo(
    () =>
      handleSubmit(values =>
        activateUserBonus(
          { ...values, ...(isAdvanced ? [] : { simple: true, bonusId: manualBonusId }), userId },
          dispatch
        )
      ),
    [handleSubmit, isAdvanced, manualBonusId, userId, dispatch]
  );

  const toggleAdvanced = () => setIsAdvanced(!isAdvanced);

  const bonusProgramOptions = useMemo(
    () =>
      Array.isArray(bonusPrograms)
        ? bonusPrograms.map(program => ({
            label: program.title,
            value: program.id,
          }))
        : [],
    [bonusPrograms]
  );

  const depositOptions = useMemo(
    () =>
      Array.isArray(userAvailableDeposits)
        ? userAvailableDeposits.map(deposit => ({
            label: deposit.id,
            value: deposit.id,
          }))
        : [],
    [userAvailableDeposits]
  );

  const selectedBonusProgram = useMemo(
    () => (Array.isArray(bonusPrograms) ? bonusPrograms.find(item => item.id === selectedProgramId) : {}),
    [bonusPrograms, selectedProgramId]
  );

  const isManualBonus = useMemo(
    () => (selectedBonusProgram && selectedBonusProgram.typeSlug === 'bonus_manual') || !isAdvanced,
    [selectedBonusProgram, isAdvanced]
  );

  const isRiskFreeBonus = useMemo(
    () => (selectedBonusProgram && selectedBonusProgram.typeSlug === 'bonus_risk_free') || !isAdvanced,
    [selectedBonusProgram, isAdvanced]
  );

  const hasAccountField = useMemo(
    () =>
      (selectedBonusProgram &&
        ['bonus_welcome', 'bonus_monthly_interest', 'bonus_manual', 'bonus_risk_free'].includes(
          selectedBonusProgram.typeSlug
        )) ||
      !isAdvanced,
    [selectedBonusProgram, isAdvanced]
  );

  const isDepositBonus = useMemo(
    () => selectedBonusProgram && ['bonus_on_deposit', 'bonus_compensation'].includes(selectedBonusProgram.typeSlug),
    [selectedBonusProgram]
  );

  const handleSelectBonusProgram = useCallback(() => {
    const formFields = ['account', 'deposit', 'withdrawCoefficient', 'value', 'convRate', 'validUntil', 'date'];
    formFields.forEach(field => {
      dispatch(change('user-bonus-create-form', field, undefined));
      dispatch(untouch('user-bonus-create-form', field));
    });
  }, [dispatch]);

  const bonusAddingTypes = [
    {
      value: 'balance',
      label: intl.formatMessage({ id: 'justBalance' }),
    },
    {
      value: 'credit',
      label: intl.formatMessage({ id: 'justCredit' }),
    },
  ];

  return (
    <form className="UserBonusCreateForm" onSubmit={submit}>
      {isAdvanced && (
        <div className="UserBonusCreateForm__line">
          <Field
            name="bonusId"
            textId="bonusProgram"
            validate={required()}
            options={bonusProgramOptions}
            component={ReduxSelectField}
            disabled={submitting}
            onChange={handleSelectBonusProgram}
          />
          <Field
            name="defaultBonusType"
            textId="bonusAddingType"
            validate={required()}
            options={bonusAddingTypes}
            component={ReduxSelectField}
            disabled={submitting}
          />
        </div>
      )}

      <div className="UserBonusCreateForm__line">
        <Field
          component={ReduxSelectField}
          name="account"
          maxMenuHeight={140}
          textId="justAccount"
          options={accounts.map(account => ({
            value: account.id,
            label: account.login,
          }))}
          validate={hasAccountField ? [required()] : []}
          disabled={submitting || !hasAccountField}
          searchable
        />
        {isAdvanced && (
          <>
            {!isRiskFreeBonus ? (
              <Field
                component={ReduxSelectField}
                name="deposit"
                maxMenuHeight={140}
                textId="justDepositId"
                options={depositOptions}
                validate={isDepositBonus ? [required()] : []}
                disabled={submitting || !isDepositBonus}
              />
            ) : (
              <Field
                component={DateField}
                openDirection="down"
                type="date"
                name="date"
                textId="justDate"
                placeholder="dd.mm.yyyy"
                disabled={submitting || !isEndless || !isRiskFreeBonus}
                validate={[required()]}
              />
            )}
          </>
        )}
      </div>
      <div className="UserBonusCreateForm__line">
        {isAdvanced && (
          <Field
            component={ReduxTextField}
            type="number"
            name="withdrawCoefficient"
            textId="withdrawalCoefficient"
            disabled={submitting || !isManualBonus}
            validate={isManualBonus ? [required(), numericality({ '>=': 0, '<=': 100 })] : []}
            tooltipId="bonusCoefficientDescription"
            tooltipTextId="bonusCoefficientDescription"
          />
        )}

        <Field
          component={ReduxTextField}
          type="number"
          name="value"
          textId="justBonusValue"
          disabled={submitting || !isManualBonus}
          validate={isManualBonus ? [required()] : []}
          description="USD"
        />
      </div>
      {isAdvanced && (
        <>
          <div className="UserBonusCreateForm__line">
            <div className="UserBonusCreateForm__switch-field">
              <FormattedMessage id="justConversion">{txt => <span>{txt}</span>}</FormattedMessage>
              <Field
                component={SwitchField}
                name="conversion"
                type="checkbox"
                disabled={submitting || !isManualBonus}
                withText
              />
            </div>
            <div className="UserBonusCreateForm__switch-field">
              <FormattedMessage id="justEndless">{txt => <span>{txt}</span>}</FormattedMessage>
              <Field
                component={SwitchField}
                name="isEndless"
                type="checkbox"
                disabled={submitting || !isManualBonus}
                withText
              />
            </div>
          </div>
          <div className="UserBonusCreateForm__line">
            <Field
              component={ReduxTextField}
              type="text"
              name="convRate"
              textId="justConversionCourse"
              disabled={submitting || !isConvertible || !isManualBonus}
              description={intl.formatMessage({ id: 'usdPerLot' })}
              tooltipId="bonusExchangeHint"
              tooltipTextId="bonusExchangeHint"
            />
            <Field
              component={DateField}
              openDirection="up"
              type="date"
              name="validUntil"
              textId="justValidUntil"
              placeholder="dd.mm.yyyy"
              disabled={submitting || !isEndless || !isManualBonus}
              areFutureDaysAvailable
            />
          </div>
        </>
      )}

      {error && <div className="formError">{error}</div>}

      <DefaultButton
        textId="justAddBonus"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      <DefaultButton
        textId={isAdvanced ? 'justBackToBasicSettings' : 'justShowAdvancedSettings'}
        type="button"
        onClick={toggleAdvanced}
        disabled={submitting}
        noBorder
      />

      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserBonusCreateForm.propTypes = {
  userId: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
  userAvailableDeposits: PropTypes.array.isRequired,
  bonusPrograms: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isConvertible: PropTypes.bool.isRequired,
  isEndless: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedProgramId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.string,
};

UserBonusCreateForm.defaultProps = {
  error: '',
};

const selector = formValueSelector('user-bonus-create-form');

export default compose(
  injectIntl,
  connect(state => ({
    isConvertible: selector(state, 'conversion') || false,
    isEndless: !selector(state, 'isEndless'),
    selectedProgramId: selector(state, 'bonusId') || 0,
  })),
  reduxForm({
    form: 'user-bonus-create-form',
  })
)(UserBonusCreateForm);
export { UserBonusCreateForm };
