import axios from 'axios';
import { buildQueryString } from 'crm/_api/helpers';

const crmBaseClient = axios.create({ baseURL: '/api/', validateStatus: status => status < 500 });

crmBaseClient.interceptors.request.use(config => {
  const locale = localStorage.getItem('locale');

  if (locale) config.headers.locale = locale;

  return config;
});

crmBaseClient.interceptors.request.use(request => {
  const token = localStorage.getItem('jwtToken');
  const locale = localStorage.getItem('locale');

  if (locale) {
    request.headers.locale = locale;
  }
  if (token) {
    request.headers.Authorization = `JWT ${token}`;
  }
  return request;
});

export const getContacts = search => {
  const queryString = buildQueryString(search);
  return crmBaseClient.get(`/crm/contacts/list/?${queryString}`);
};

export const getCrmClient = () => crmBaseClient.post('/backoffice/managers/get_client/');

export const getContactsFavorites = () => crmBaseClient.get(`/crm/contacts/favorite/`);
export const setContactFavorite = userId => crmBaseClient.put(`/crm/contacts/favorite/`, { id: userId });
export const deleteContactFavorite = userId =>
  crmBaseClient.delete(`/crm/contacts/favorite/`, { data: { id: userId } });

export const createContact = payload => crmBaseClient.post(`/crm/contacts/create/`, { ...payload });

export const uploadContacts = file => {
  const data = new FormData();
  data.append('file', file);

  return crmBaseClient.post(`/crm/contacts/upload/`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const editContactsManager = ({ contactsId, manager, taskComment, partnerAccount, reason, includeClients }) =>
  crmBaseClient.post('/crm/contacts/change_manager/', {
    contactsId,
    manager,
    taskComment,
    partnerAccount,
    reason,
    includeClients,
  });

export const editContactsIBManager = (ibManager, contactsId, taskComment, partnerAccount) =>
  crmBaseClient.post('/crm/contacts/change_ib_manager/', {
    ibManager,
    contactsId,
    taskComment,
    partnerAccount,
  });

export const editContactsPartnerCode = (contactsId, partnerAccount) =>
  crmBaseClient.post('/crm/contacts/change_partner_codes/', { partnerAccount, contactsId });

export const createContactsExports = contactsId => crmBaseClient.post(`/reports/contact_export/`, { contactsId });

export const getAvailablePartnerCodes = (userId, search) =>
  crmBaseClient.get(`/backoffice/partnership/systems/${userId}/change_partner_account/?search=${search}`);

export const getManagerList = search => crmBaseClient.get(`/backoffice/managers/list/?search=${search}`);

export const getIBManagerList = search => crmBaseClient.get(`/backoffice/managers/list/?search=${search}`);

export const getContactsExports = search => crmBaseClient.get(`/reports/contact_export/`, { params: { ...search } });

export const downloadContactsExport = (id, name) =>
  crmBaseClient.get(`/reports/contact_export/${id}/`, { params: { name }, responseType: 'blob' });

export const deleteCrmCustomFilter = id => axios.delete(`crm/contacts/filter/${id}/`);
export const editCrmCustomFilter = (id, data) => axios.put(`crm/contacts/filter/${id}/`, { ...data });
export const getCrmFilter = id => axios.get(`crm/contacts/filter/${id}/`);
