import css from 'styled-jsx/css';
import { isRTL, gradient } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserTradingHistory {
    :global(.Collapsible) {
      background: #ffffff;
      margin-bottom: 20px;

      :global(.ReactTable) {
        :global(.rt-thead.-header) {
          :global(.rt-tr) {
            justify-content: space-between;
            margin: 0 28px;
            @include respond-to(md) {
              margin: 0 10px;
            }

            :global(.rt-th) {
              flex: 10 !important;
              text-align: center;
              max-width: 100px;

              &:first-child {
                text-align: left;
              }

              &:nth-child(12) {
                max-width: 60px;
                flex: 5 !important;

                @include respond-to(xl) {
                  flex: 7 !important;
                }
              }
            }
          }
        }

        :global(.rt-tbody) {
          min-width: 600px !important;
          :global(.rt-tr-group) {
            margin: 0 0 2px 0;
            cursor: default;

            &:hover {
              box-shadow: none;
            }

            :global(.rt-tr) {
              padding: rem(10px 29px);
              @include respond-to(lg) {
                padding: rem(10px);
              }

              :global(.rt-td) {
                flex: 10 !important;
                text-align: center;
                max-width: 100px;

                :global(span) {
                  font-size: rem(15px);
                }

                &:first-child {
                  text-align: left;
                  padding-right: 3px;
                  overflow: unset;
                }

                &:nth-child(12) {
                  flex: 10 !important;
                  max-width: 60px;
                  text-align: center;
                }

                :global(.MoneyCell__inner) {
                  justify-content: center;
                }

                :global(.StatusCell) {
                  font-size: rem(15px);
                }

                :global(.DateCell) {
                  :global(span) {
                    font-size: rem(13px) !important;
                  }
                }
              }
            }
          }
        }

        :global(.rt-tfoot) {
          :global(.rt-tr) {
            justify-content: space-between;

            :global(.rt-td) {
              flex: 10 !important;
              max-width: 100px;

              &:last-child {
                flex: 5 !important;
                max-width: 60px;
                text-align: center;
              }
            }
          }
        }
      }
    }

    :global(.Collapsible__header) {
      box-shadow: none;
      padding: rem(15px 28px);
      position: relative;

      @include respond-to(sm) {
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
      }
    }

    :global(.Collapsible__content) {
      :global(.Calendar) {
        display: flex;
        justify-content: flex-end;
      }

      :global(.Calendar) {
        @include respond-to(sm) {
          padding: 25px 0 10px 0;
          width: 100%;

          :global(.Calendar__data-info) {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }

    :global(.Collapsible__caption) {
      line-height: 32px;
      font-size: 26px;
      font-weight: 300;

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    :global(.Collapsible__arrow) {
      position: absolute;
      right: ${isRTL ? 'auto' : '30px'};
      left: ${isRTL ? '30px' : 'auto'};
      top: 50%;
    }

    :global(.Collapsible__arrow-right) {
      transform: ${isRTL
        ? 'translateY(-50%) rotate(135deg) !important'
        : ' translateY(-50%) rotate(-45deg) !important'};
    }

    :global(.Collapsible__arrow-down) {
      top: 40%;
      transform: rotate(45deg) translateY(-50%) !important;
    }

    .UserTradingHistory__select {
      padding: 0 25px 5px 25px;

      @include respond-to(sm) {
        padding: 20px 15px 5px 15px;
      }
    }

    .UserTradingHistory__heading {
      @include respond-to(sm) {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    :global(.NoItemsPanel) {
      height: auto;
    }
  }

  .UserTradingHistory {
    :global(.rt-table) {
      width: 100%;
      display: grid !important;
      grid-template-columns: 1fr !important;

      :global(.rt-thead) {
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important;

        :global(.rt-tr) {
          display: grid !important;
          grid-template-columns:
            minmax(110px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr) !important;

          :global(.rt-th) {
            text-overflow: clip !important;
            overflow: visible !important;
            width: auto !important;
            min-width: auto !important;
            max-width: auto !important;
          }
        }
      }

      :global(.rt-tbody) {
        display: block;
        overflow: visible;
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important;

        :global(.rt-tr) {
          display: grid !important;
          grid-template-columns:
            minmax(110px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr) !important;

          :global(.rt-td) {
            text-overflow: clip !important;
            overflow: visible !important;
            width: auto !important;
            max-width: auto !important;

            &:nth-of-type(3) {
              :global(span) {
                font-size: 13px !important;
              }
            }
          }
        }
      }

      :global(.rt-tfoot) {
        display: block !important;
        overflow: visible !important;
        width: auto !important;
        min-width: auto !important;
        max-width: auto !important;

        :global(.rt-tr) {
          display: grid !important;
          grid-template-columns:
            minmax(110px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(150px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr)
            minmax(90px, 1fr) !important;

          :global(.rt-td) {
            text-overflow: clip !important;
            overflow: visible !important;
            width: auto !important;
            max-width: auto !important;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    .UserTradingHistory {
      :global(.Collapsible) {
        :global(.ReactTable) {
          :global(.rt-tfoot) {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: -2px;
              left: 0;
              width: 100%;
              height: 2px;
              background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
            }
          }
        }
      }
      :global(.ReactDataTable__wrapper) {
        height: 500px;
        overflow-x: scroll;
      }
      :global(.ReactDataTable__wrapper .ReactDataTable) {
        position: relative;
        min-width: 100%;
        border-spacing: 0px;
      }

      .UserTradingHistory__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(10px 23px);
      }

      .UserTradingHistory__tabs {
        @include respond-to(sm) {
          width: 100%;
        }
      }
      .UserTradingHistory__tab {
        font-size: 20px;
        line-height: 23px;
        margin-right: ${isRTL ? '0' : '35px'};
        margin-left: ${isRTL ? '35px' : '0'};
        padding-bottom: 15px;
        cursor: pointer;
        border-bottom: 3px solid transparent;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          color: ${colors.primary.hex};
          border-bottom-color: ${colors.primary.hex};
        }

        @include respond-to(sm) {
          font-size: 15px;
          line-height: normal;
          padding-bottom: 5px;
          border-bottom-width: 2px;
          margin-right: ${isRTL ? '0' : '15px'};
          margin-left: ${isRTL ? '15px' : '0'};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
