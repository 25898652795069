import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { getAgreements } from '../../_redux';
import { VerificationPanel } from '../../_components';
import { staticStyles } from './style';

const AgreementsPage = ({ agreements, getAgreements }) => {
  useEffect(() => {
    getAgreements();
  }, [getAgreements]);

  const openAgreementLink = (e, link) => {
    e.stopPropagation();

    window.open(link);
  };

  return (
    <VerificationPanel captionId="justAgreements" warningMessageTextId="kycAgreementsMessage">
      <div className="AgreementsList">
        {agreements.map(({ title, link, id }) => (
          <button
            type="button"
            className="AgreementsButton"
            onClick={e => {
              openAgreementLink(e, link);
            }}
            key={id}>
            <FormattedMessage id={title}>{txt => txt}</FormattedMessage>
          </button>
        ))}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </VerificationPanel>
  );
};

AgreementsPage.propTypes = {
  getAgreements: PropTypes.func.isRequired,
  agreements: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    agreements: state.client.kyc.agreements,
  }),
  {
    getAgreements: () => getAgreements.request(),
  }
)(AgreementsPage);
export { AgreementsPage };
