import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const WithdrawalIcon = ({ grayColor }) => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1061 12.6251L10.5247 15.208V7.52505C10.5247 7.23507 10.2898 7 10.0001 7C9.71029 7 9.47539 7.23507 9.47539 7.52505V15.208L6.89442 12.6251C6.76206 12.4926 6.56914 12.4409 6.38834 12.4894C6.20753 12.5379 6.06631 12.6792 6.01786 12.8601C5.96942 13.0411 6.02111 13.2341 6.15347 13.3666L9.63408 16.8455C9.73175 16.9435 9.86409 16.999 10.0024 17C10.1418 16.9997 10.2751 16.9432 10.3723 16.8432L13.847 13.3658C13.9965 13.2157 14.0411 12.9903 13.9601 12.7945C13.8792 12.5987 13.6885 12.4708 13.4767 12.4702C13.3373 12.4705 13.2039 12.5262 13.1061 12.6251Z"
      fill={grayColor.hex}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33509 0H17.6661C18.9553 0.000623323 20 0.992034 20 2.21478V11.7818C20 13.005 18.9545 13.9966 17.6649 13.9966H16.5099C16.2688 13.9966 16.0734 13.8113 16.0734 13.5826V13.4321C16.0734 13.1845 16.285 12.9839 16.546 12.9839H17.6661C18.3639 12.9839 18.9296 12.4474 18.9299 11.7856V3.82817H1.07132V11.7856C1.07154 12.4474 1.63728 12.9839 2.33509 12.9839H3.26952C3.53577 12.9839 3.75161 13.1886 3.75161 13.4411C3.75161 13.7498 3.48781 14 3.16238 14H2.33509C1.71578 14 1.12184 13.7667 0.683931 13.3513C0.246017 12.936 0 12.3726 0 11.7852V2.21478C0 0.991593 1.04545 0 2.33509 0ZM1.07132 2.21479V2.81204H18.9299V2.21479C18.929 1.55313 18.3637 1.01696 17.6661 1.01613H2.33509C1.63749 1.01696 1.0722 1.55313 1.07132 2.21479Z"
      fill={grayColor.hex}
    />
  </svg>
);

WithdrawalIcon.propTypes = {
  grayColor: colorType,
};

WithdrawalIcon.defaultProps = {
  grayColor: {},
};

const ThemedWithdrawalIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(WithdrawalIcon);

export default ThemedWithdrawalIcon;
