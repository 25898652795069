import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { confirmation, length, required } from 'redux-form-validators';
import { ReduxTextField } from 'common/components/form-fields';
import { FormMessageHOC } from 'common';

import { withTheme } from 'common/styling/theme';
import { DefaultButton } from 'common/components';
import { changePassword } from '../../_redux';
import { staticStyles } from './style';

const validate = ({ newPassword }) => {
  const errors = {};

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).+$/;
  if (!passwordRegex.test(newPassword)) {
    errors.newPassword = <FormattedMessage id="form.errors.passwordValidate" />;
  }

  return errors;
};

const ChangePasswordForm = ({ handleSubmit, dispatch, pristine, submitting, invalid, error }) => {
  const submit = handleSubmit(formValues => changePassword(formValues, dispatch));
  const [passwordFieldsType, setPasswordFieldsType] = useState({
    currentPasswordField: 'password',
    newPasswordField: 'password',
    confirmPasswordField: 'password',
  });

  const togglePassword = key => {
    if (passwordFieldsType[key] === 'password') {
      setPasswordFieldsType({ ...passwordFieldsType, [key]: 'text' });
    } else {
      setPasswordFieldsType({ ...passwordFieldsType, [key]: 'password' });
    }
  };

  return (
    <form className="ChangePasswordForm" onSubmit={submit}>
      <FormattedMessage id="authChangePassword">
        {txt => <h2 className="ChangePasswordForm__caption">{txt}</h2>}
      </FormattedMessage>
      <Field
        component={ReduxTextField}
        type={passwordFieldsType.currentPasswordField}
        name="password"
        textId="justCurrentPassword"
        validate={[required()]}
        disabled={submitting}
        action={() => togglePassword('currentPasswordField')}
        actionTooltipId={passwordFieldsType.currentPasswordField === 'password' ? 'showPassword' : 'hidePassword'}
        actionClassName={passwordFieldsType.currentPasswordField === 'password' ? 'show' : 'hide'}
      />
      <Field
        component={ReduxTextField}
        type={passwordFieldsType.newPasswordField}
        name="newPassword"
        textId="justNewPassword"
        validate={[required(), length({ min: 8 })]}
        disabled={submitting}
        action={() => togglePassword('newPasswordField')}
        actionTooltipId={passwordFieldsType.newPasswordField === 'password' ? 'showPassword' : 'hidePassword'}
        actionClassName={passwordFieldsType.newPasswordField === 'password' ? 'show' : 'hide'}
      />
      <Field
        component={ReduxTextField}
        type={passwordFieldsType.confirmPasswordField}
        name="passwordRepeat"
        textId="justConfirmNewPassword"
        validate={[required(), confirmation({ field: 'newPassword' })]}
        disabled={submitting}
        action={() => togglePassword('confirmPasswordField')}
        actionTooltipId={passwordFieldsType.confirmPasswordField === 'password' ? 'showPassword' : 'hidePassword'}
        actionClassName={passwordFieldsType.confirmPasswordField === 'password' ? 'show' : 'hide'}
      />
      <DefaultButton
        textId="doSave"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

ChangePasswordForm.defaultProps = {
  error: '',
};

export default compose(
  withTheme(),
  FormMessageHOC,
  reduxForm({
    form: 'ChangePasswordForm',
    validate,
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage();
    },
  })
)(ChangePasswordForm);

export { ChangePasswordForm };
