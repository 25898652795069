import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const ManagerClientInfo = ({ managerClient, toggleModal, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();

  const handleShowProfile = () => {
    history.push(`/backoffice/users/${managerClient.id}`);
  };

  return (
    <div className="ManagerClientInfo">
      {managerClient ? (
        <>
          <div className="ManagerClientInfo__row">
            <FormattedMessage id="justFullName">
              {txt => <span className="ManagerClientInfo__row-caption">{txt}:</span>}
            </FormattedMessage>
            <span className="ManagerClientInfo__row-value">
              {managerClient.firstName} {managerClient.lastName}
            </span>
          </div>
          <div className="ManagerClientInfo__row">
            <FormattedMessage id="justPhone">
              {txt => <span className="ManagerClientInfo__row-caption">{txt}:</span>}
            </FormattedMessage>
            <span className="ManagerClientInfo__row-value">{managerClient.phone}</span>
          </div>
          <div className="ManagerClientInfo__buttons">
            <DefaultButton textId="justShowProfile" onClick={handleShowProfile} filled />
            <DefaultButton textId="justProceed" onClick={toggleModal} filled />
          </div>
        </>
      ) : (
        <>
          <FormattedMessage id="noClientsDescription">
            {txt => <div className="ManagerClientInfo__row">{txt}</div>}
          </FormattedMessage>
          <DefaultButton textId="justOk" onClick={toggleModal} filled />
        </>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ManagerClientInfo.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  managerClient: PropTypes.object,
  theme: PropTypes.object,
};

ManagerClientInfo.defaultProps = {
  managerClient: null,
  theme: {},
};

export default withTheme()(ManagerClientInfo);
export { ManagerClientInfo };
