import React, { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { staticStyles } from './style';

const ChallengeProgress = ({ activeChallenge }) => {
  const {
    target,
    equity,
    dailyProfit,
    totalProfit,
    level: { maximum_daily_loss: maximumDailyLoss },
  } = activeChallenge;
  const isSuccess = +totalProfit.amount >= 0;

  const [successWidth] = useState(`${(+totalProfit.amount / (+target.amount - +equity.amount)) * 400}px`);

  const [failureWidth] = useState(`${(+dailyProfit.amount / +maximumDailyLoss.amount) * 400}px`);

  if (isSuccess) {
    return (
      <div className="ChallengeProgressSuccess">
        <div className="ChallengeProgressSuccess__MiniChart">
          <div className="ChallengeProgressSuccess__RoundSuccess" />
          <div className="ChallengeProgressSuccess__RelativeGrayLine" />
          <div className="ChallengeProgressSuccess__AbsoluteSuccessLine" />
        </div>
        <div className="Nadpisi">
          <div className="ChallengeProgressSuccess__ProfitTargetText">
            {/* <FM id="justYouAreSoClose">{txt => <div className="SuccessColor">{txt}</div>}</FM> */}
            <FM id="justProfitTarget">{txt => <div>{txt}</div>}</FM>
          </div>
        </div>

        <div className="ChallengeProgressSuccess__ProfitTargetValue">{`${target.amount} ${target.currency}`}</div>
        <style jsx>{staticStyles}</style>
        <style jsx>{`
          .ChallengeProgressSuccess__RoundSuccess {
            width: ${successWidth};
          }
        `}</style>
      </div>
    );
  }
  return (
    <div className="ChallengeProgressFailure">
      <div className="ChallengeProgressFailure__MiniChart">
        <div className="ChallengeProgressFailure__RoundFailure" />
        <div className="ChallengeProgressFailure__RelativeGrayLine" />
        <div className="ChallengeProgressFailure__AbsoluteFailureLine" />
      </div>
      <div className="Nadpisi">
        <div className="ChallengeProgressFailure__ProfitTargetText">
          {/* <FM id="justYouAreSoClose">{txt => <div className="FailureColor">{txt}</div>}</FM> */}
          <FM id="justMaximumDailyLoss">{txt => <div>{txt}</div>}</FM>
        </div>
      </div>

      <div className="ChallengeProgressFailure__ProfitTargetValue">{`-${maximumDailyLoss.amount} ${maximumDailyLoss.currency}`}</div>
      <style jsx>{staticStyles}</style>
      <style jsx>{`
        .ChallengeProgressFailure__RoundFailure {
          width: ${failureWidth};
        }
      `}</style>
    </div>
  );
};
export default ChallengeProgress;
