import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const PhoneIcon = ({ grayColor }) => (
  <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4364 16.3037L17.9625 12.8174C17.1741 12.0215 15.8943 12.0199 15.104 12.814C15.1028 12.8151 15.1017 12.8162 15.1006 12.8174L13.1102 14.822C12.9596 14.974 12.7223 14.9951 12.5477 14.8718C11.4583 14.1051 10.4386 13.2425 9.50034 12.2945C8.66006 11.4501 7.88708 10.5405 7.18849 9.5739C7.06187 9.40026 7.08027 9.15951 7.23173 9.0074L9.27158 6.95298C10.0591 6.15828 10.0591 4.87149 9.27158 4.07679L5.79768 0.578079C4.99717 -0.192693 3.73625 -0.192693 2.93574 0.578079L1.83548 1.6862C0.173829 3.34089 -0.419365 5.79788 0.302478 8.03622C0.841125 9.67384 1.60252 11.2284 2.56485 12.6555C3.43121 13.9639 4.42513 15.1818 5.53191 16.2912C6.73517 17.5118 8.06803 18.5952 9.5065 19.5223C11.0878 20.5615 12.8241 21.3392 14.6494 21.8257C15.1186 21.9422 15.6002 22.0007 16.0834 22C17.7401 21.9898 19.3252 21.3182 20.4907 20.1323L21.4365 19.1798C22.224 18.3852 22.224 17.0984 21.4364 16.3037ZM20.5611 18.3308C20.5603 18.3316 20.5595 18.3325 20.5586 18.3333L20.5648 18.3146L19.619 19.2671C18.4039 20.5064 16.6342 21.0163 14.9521 20.6118C13.2574 20.155 11.6464 19.4277 10.1801 18.4578C8.81783 17.5809 7.55539 16.5559 6.41565 15.401C5.36697 14.3526 4.42484 13.2014 3.60311 11.9646C2.7043 10.6337 1.99289 9.18395 1.48908 7.65649C0.911535 5.86208 1.39013 3.89309 2.72538 2.57027L3.82563 1.46215C4.13154 1.15267 4.62861 1.15158 4.93582 1.45967C4.93661 1.46047 4.93748 1.46127 4.93828 1.46215L8.41218 4.96086C8.71946 5.26895 8.72055 5.76957 8.41464 6.07898C8.41385 6.07978 8.41305 6.08058 8.41218 6.08146L6.37233 8.13588C5.78703 8.71893 5.71344 9.64546 6.19928 10.3148C6.93706 11.3346 7.7535 12.2942 8.64094 13.1848C9.63037 14.1856 10.706 15.096 11.8552 15.9054C12.5193 16.3719 13.4195 16.2932 13.9939 15.7186L15.9658 13.7016C16.2717 13.3921 16.7687 13.391 17.076 13.6991C17.0768 13.6999 17.0776 13.7007 17.0784 13.7016L20.5585 17.2127C20.8659 17.5208 20.867 18.0213 20.5611 18.3308Z"
      fill={grayColor.hex}
    />
  </svg>
);

PhoneIcon.propTypes = {
  grayColor: colorType,
};

PhoneIcon.defaultProps = {
  grayColor: {},
};

const ThemedPhoneIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(PhoneIcon);

export default ThemedPhoneIcon;
