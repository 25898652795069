import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  .MainLogo {
    width: 130px;
    text-align: center;
    margin-right: ${isRTL ? '0' : '30px'};
    margin-left: ${isRTL ? '30px' : '0'};

    @include respond-to(xs) {
      margin-right: ${isRTL ? '0' : '13px'};
      margin-left: ${isRTL ? '13px' : '0'};
    }
    a {
      border: none;
      &:hover {
        opacity: 0.6;
      }
      svg,
      img {
        width: auto;
        max-height: 45px;
        max-width: 130px;
        border: none;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 130px;
        }
      }
      svg {
        height: 45px;
      }
    }
    &__FullWidth {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`;

export default staticStyles;
