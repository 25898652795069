import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ModalHOC } from 'common';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { withTheme } from 'common/styling/theme';

import { DefaultButton, BasicModal, DateField } from 'common/components';
import Checkbox from 'backoffice/partnership/_components/Checkbox';
import { FormFieldsComposer } from 'common/dynamics';
import { staticStyles, getDynamicStyles } from './style';

import { activateBonus } from '../../_redux';

const Bonus = ({
  id,
  data,
  bonusForm,
  availableFrom,
  handleSubmit,
  dispatch,
  pristine,
  submitting,
  invalid,
  history,
  isDepositEnabled,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const { title, description, logo, rules, promoTexts, form, bonusType, isActivated } = data;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isAgree, setIsAgree] = useState(false);

  const submit = handleSubmit(values => activateBonus({ id, ...values }, dispatch));

  const hasOptions =
    (form.properties.account && form.properties.account.options.length) ||
    (form.properties.deposit && form.properties.deposit.options.length);

  const isWelcomeBonus = bonusType === 'bonus_welcome' && !hasOptions;

  const isRiskFreeBonus = bonusType === 'bonus_risk_free';

  const isDepositBonus = bonusType === 'bonus_on_deposit';

  const handleDeposit = () => history.push('/deposit/?type=account');

  const handleCreateAccount = () => {
    history.push('/create-account/real/');
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleTerms = () => {
    toggleModal();
  };

  const handleAgree = () => {
    setIsAgree(prev => !prev);
  };

  const isDateSelected = isRiskFreeBonus ? !!selectedDate : true;

  /* eslint-disable */
  const renderButtons = () => {
    return (
      <>
        {hasOptions ? (
          <DefaultButton
            textId="getBonus"
            type="submit"
            loading={submitting}
            disabled={invalid || pristine || submitting || !isDateSelected || !isAgree}
            filled
          />
        ) : isWelcomeBonus ? (
          !isActivated && (
            <DefaultButton textId="createPlatformAccount" type="button" filled onClick={() => handleCreateAccount()} />
          )
        ) : isDepositEnabled ? (
            <DefaultButton textId="justDeposit" type="button" filled onClick={() => handleDeposit()} />
          ) : null
        }
      </>
    );
  };
  /* eslint-enable */

  return (
    <div className="Bonus">
      <div className="Bonus__heading">
        <div className="Bonus__logo">
          <img src={logo} alt={title} />
        </div>
        <h2 className="Bonus__title">{title}</h2>
      </div>
      <div className="Bonus__content">
        <p className="Bonus__text">{description}</p>
        <ul className="Bonus__list">
          {promoTexts.map((item, id) => (
            <li className="Bonus__item" key={id}>
              {item.text}
            </li>
          ))}
        </ul>
        <form onSubmit={submit} className="Bonus__form">
          <div className="Bonus__btns">
            {renderButtons()}
            <DefaultButton textId="justTerms" type="button" onClick={() => handleTerms()} />
          </div>

          <div className="Bonus__fields">
            {hasOptions ? (
              <div className="Bonus__select">
                <FormattedMessage
                  id={isDepositBonus ? 'bonusDepositTextBeforeChoseAccountText' : 'bonusBeforeChoseAccountText'}
                />
                <FormFieldsComposer name="bonus" schema={form} formName={bonusForm} submitting={submitting} />
              </div>
            ) : null}
            {isRiskFreeBonus && hasOptions && (
              <Field
                component={DateField}
                openDirection="down"
                type="date"
                name="date"
                textId="justDate"
                placeholder="dd.mm.yyyy"
                disabled={submitting}
                minDate={availableFrom}
                onChange={setSelectedDate}
              />
            )}
          </div>
        </form>
        {!!hasOptions && (
          <label className="Bonus__agree">
            <Checkbox onClick={e => handleAgree(e)} checked={isAgree} />
            <FormattedMessage id="bonusAgreeTermsText" />
          </label>
        )}
      </div>
      <BasicModal isOpen={isOpen} captionId="bonusRules" onRequestClose={toggleModal}>
        <div className="Bonus__modal">{rules}</div>
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

Bonus.propTypes = {
  id: PropTypes.number.isRequired,
  bonusForm: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  availableFrom: PropTypes.string,
  isDepositEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

Bonus.defaultProps = {
  availableFrom: '',
  theme: {},
};

export default compose(
  ModalHOC,
  withTheme(),
  withRouter,
  connect((state, { bonusForm }) => {
    const selector = formValueSelector(bonusForm);
    return {
      account: selector(state, 'bonus.account') || null,
      successBonusModal: state.client.bonuses.successBonusModal,
      isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
    };
  }),
  reduxForm({
    onSubmitSuccess: (result, dispatch, { showModal, history }) => {
      showModal({
        caption: { id: 'justBonusReceived' },
        content: { id: 'bonusReceivedSucess' },
        actionButton: {
          textId: 'menuMyBonuses',
          handler: () => history.push('/myBonuses'),
        },
        cancelButton: null,
      });
    },
  })
)(Bonus);
export { Bonus };
