import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WelcomeSetup {
    background-color: #f0f4f6;
    min-height: 100vh;
    color: #0c0c3d;
  }
  .DefaultButton {
    height: 40px;
  }
`;

export { staticStyles };
