/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { InfoBlock } from '../_components';

import { IconEdit, IconEmail, IconPhone } from './icons';

import { staticStyles } from './style';

const ContactsInfoBlock = ({ setCrmModalType }) => {
  const { phone, email } = useSelector(state => state.crm.users.userData);

  const items = useMemo(
    () => [
      {
        titleId: 'justPhone',
        cell: phone ? (
          <a className="FieldWithButton" href={`tel:${phone || phone}`}>
            {phone || phone}{' '}
            <div className="FieldWithButton__iconWrapper">
              <IconPhone />
            </div>
            <button type="button" onClick={() => setCrmModalType('editPhoneNumber')}>
              <div className="ClientPage__left-icon-edit">
                <IconEdit />
              </div>
            </button>
          </a>
        ) : (
          <p>----</p>
        ),
      },
      {
        titleId: 'justEmail',
        cell: email ? (
          <a className="FieldWithButton" href={`mailto:${email}`}>
            {email}
            <div className="FieldWithButton__iconWrapper">
              <IconEmail />
            </div>
            <button type="button" onClick={() => setCrmModalType('editEmail')}>
              <div className="ClientPage__left-icon-edit">
                <IconEdit />
              </div>
            </button>
          </a>
        ) : (
          <p>----</p>
        ),
      },
    ],
    [phone, email]
  );
  return (
    <>
      <InfoBlock title="justGeneral" items={items} />

      <style jsx global>
        {staticStyles}
      </style>
    </>
  );
};

ContactsInfoBlock.propTypes = {
  setCrmModalType: PropTypes.func.isRequired,
};

export default ContactsInfoBlock;
