import React from 'react';
import PropTypes from 'prop-types';

import { PaginationTable, StatusCell, DateCell, RowLink } from 'common/components';
import { getLabelAndValueFromLangSlug } from 'common/utils/helpers';

import { staticStyles } from './style';

const IssuesTable = ({ data, onRowClick, getList, loading, location, history, params }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: 'boId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <RowLink link={`/backoffice/support/${id}`} isPreviewCell>
          {id}
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'user',
      Header: 'justEmail',
      Cell: ({
        row: {
          original: { user, id },
        },
      }) => (
        <RowLink link={`/backoffice/support/${id}`} isPreviewCell>
          <div className="UserWrapper">
            <span className="user">{user.email}</span>
          </div>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'title',
      Header: 'justTitle',
      Cell: ({
        row: {
          original: { title, id },
        },
      }) => (
        <RowLink link={`/backoffice/support/${id}`}>
          <span className="title">{title}</span>
        </RowLink>
      ),
    },
    {
      accessor: 'language',
      Header: 'justLanguage',
      Cell: ({
        row: {
          original: { language, id },
        },
      }) => {
        return (
          <RowLink link={`/backoffice/support/${id}`}>
            <span className="">{getLabelAndValueFromLangSlug(language).label}</span>
          </RowLink>
        );
      },
    },
    {
      accessor: 'last_user_update',
      Header: 'justUpdated',
      Cell: ({
        row: {
          original: { lastUserUpdate, id },
        },
      }) => (
        <RowLink link={`/backoffice/support/${id}`}>
          <DateCell value={lastUserUpdate} />
        </RowLink>
      ),
    },
    {
      accessor: 'status',
      Header: 'boStatus',
      Cell: ({
        row: {
          original: { status, id },
        },
      }) => (
        <RowLink link={`/backoffice/support/${id}`} isPreviewCell>
          <StatusCell statusCode={status} />
        </RowLink>
      ),

      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="IssuesTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        getList={getList}
        loading={loading}
        location={location}
        history={history}
        params={params}
        onRowClick={(_, { original }) => onRowClick(original.id)}
        isNewTable
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

IssuesTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

IssuesTable.defaultProps = {
  loading: false,
};

export default IssuesTable;
export { IssuesTable };
