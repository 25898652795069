import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const BasketIcon = ({ grayColor }) => (
  <svg width="15" height="17" viewBox="0 0 15 17" xmlns="http://www.w3.org/2000/svg">
    <g id="-UI" fill="none" fillRule="evenodd">
      <g id="ui---blocks" transform="translate(-181 -715)" fill={grayColor.hex}>
        <g id="icon-/-trash" transform="translate(181 714)">
          <g id="001-garbage" transform="translate(0 1)" fillRule="nonzero">
            <path
              d="M14.5319001,2.44654605 L11.0228849,2.44654605 L11.0228849,1.8698602 C11.0228849,0.838815789 10.1907074,0 9.16782247,0 L5.83217753,0 C4.80929265,0 3.97711512,0.838815789 3.97711512,1.8698602 L3.97711512,2.44654605 L0.468099861,2.44654605 C0.208044383,2.44654605 0,2.65625 0,2.91837993 C0,3.18050987 0.208044383,3.39021382 0.468099861,3.39021382 L1.31414702,3.39021382 L1.31414702,14.4765625 C1.31414702,15.8675987 2.43758669,17 3.81761442,17 L11.1823856,17 C12.5624133,17 13.685853,15.8675987 13.685853,14.4765625 L13.685853,3.39021382 L14.5319001,3.39021382 C14.7919556,3.39021382 15,3.18050987 15,2.91837993 C15,2.65625 14.7919556,2.44654605 14.5319001,2.44654605 Z M4.91331484,1.8698602 C4.91331484,1.35958059 5.3259362,0.943667763 5.83217753,0.943667763 L9.16782247,0.943667763 C9.6740638,0.943667763 10.0866852,1.35958059 10.0866852,1.8698602 L10.0866852,2.44654605 L4.91331484,2.44654605 L4.91331484,1.8698602 Z M12.7496533,14.4765625 C12.7496533,15.3468339 12.0457698,16.0563322 11.1823856,16.0563322 L3.81761442,16.0563322 C2.95423024,16.0563322 2.25034674,15.3468339 2.25034674,14.4765625 L2.25034674,3.39021382 L12.7531207,3.39021382 L12.7531207,14.4765625 L12.7496533,14.4765625 Z"
              id="Shape"
            />
            <path
              d="M7.5,14 C7.77777778,14 8,13.7966867 8,13.5425452 L8,5.45745482 C8,5.20331325 7.77777778,5 7.5,5 C7.22222222,5 7,5.20331325 7,5.45745482 L7,13.5391566 C7,13.7932982 7.22222222,14 7.5,14 Z"
              id="Shape"
            />
            <path
              d="M4.5,14 C4.77777778,14 5,13.796524 5,13.5421789 L5,6.45782111 C5,6.20347605 4.77777778,6 4.5,6 C4.22222222,6 4,6.20347605 4,6.45782111 L4,13.5421789 C4,13.796524 4.22592593,14 4.5,14 Z"
              id="Shape"
            />
            <path
              d="M10.5,14 C10.7777778,14 11,13.796524 11,13.5421789 L11,6.45782111 C11,6.20347605 10.7777778,6 10.5,6 C10.2222222,6 10,6.20347605 10,6.45782111 L10,13.5421789 C10,13.796524 10.2222222,14 10.5,14 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BasketIcon.propTypes = {
  grayColor: colorType,
};

BasketIcon.defaultProps = {
  grayColor: {},
};

const ThemedBasketIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(BasketIcon);

export default ThemedBasketIcon;
