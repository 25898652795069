import React from 'react';

const CrossIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M13.1462 0.146477C13.34 -0.0488257 13.6587 -0.0488257 13.8524 0.146477C14.0493 0.341467 14.0493 0.658014 13.8524 0.853317L7.70586 6.99957L13.8524 13.1461C14.0493 13.3399 14.0493 13.6586 13.8524 13.8524C13.6587 14.0492 13.34 14.0492 13.1462 13.8524L6.99965 7.70579L0.853395 13.8524C0.658404 14.0492 0.341858 14.0492 0.14643 13.8524C-0.0488101 13.6586 -0.0488101 13.3399 0.14643 13.1461L6.29343 6.99957L0.14643 0.853317C-0.0488101 0.658326 -0.0488101 0.34178 0.14643 0.146477C0.341545 -0.0488257 0.658092 -0.0488257 0.853395 0.146477L6.99965 6.29336L13.1462 0.146477Z"
      fill="#CECECE"
    />
  </svg>
);

export default CrossIcon;
