import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { ExportsPaginationTable } from 'backoffice/payments/_components';

const DepositExportsPage = () => (
  <ExportsPaginationTable captionSlug="listOfDepositExports" exportsType="deposit_export" />
);

export default compose(withRouter)(DepositExportsPage);
export { DepositExportsPage };
