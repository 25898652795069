import React from 'react';
import PropTypes from 'prop-types';

import { ModalHOC } from 'common';
import { EditIcon, BasketIcon, WatchIcon } from 'common/icons';
import { PaginationTable, ActionsButton, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const NewsTable = ({
  data,
  onRowClick,
  getList,
  deleteNews,
  publishNews,
  unPublishNews,
  showModal,
  loading,
  location,
  history,
  params,
  theme,
}) => {
  const editNewsClick = (e, id) => {
    e.stopPropagation();
    history.push(`/backoffice/news/${id}/edit`);
  };

  const deleteNewsClick = (e, id) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmDeleteNews' },
      content: {
        id: 'confirmText',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deleteNews(id, params),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const publishNewsClick = (e, id, isPublished) => {
    e.stopPropagation();
    showModal({
      caption: { id: isPublished ? 'confirmUnPublishNews' : 'confirmPublishNews' },
      content: {
        id: 'confirmText',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => (isPublished ? unPublishNews(id, params) : publishNews(id, params)),
        type: isPublished ? 'error' : 'success',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  /* eslint-disable */
  const columns = [
    { key: 'boId', accessor: 'id', isPreviewCell: true },
    {
      key: 'justTitle',
      accessor: 'title',
      Cell: ({ original: { title } }) => <span className="title">{title}</span>,
      isPreviewCell: true,
    },
    {
      key: 'justBody',
      Cell: ({ original: { body } }) => <span className="body" dangerouslySetInnerHTML={{ __html: `${body}` }} />,
    },
    {
      key: 'justPublished',
      accessor: 'is_published',
      Cell: ({ original: { isPublished } }) => <span className={isPublished ? 'published' : ''} />,
      isPreviewCell: true,
    },
    {
      key: 'boCreated',
      accessor: 'created',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
    },
    {
      key: 'justActions',
      Cell: ({ original: { id, isPublished } }) => (
        <React.Fragment>
          <ActionsButton
            tooltipId={`Publish__tooltip-${id}`}
            tooltipTextId={isPublished ? 'justUnpublish' : 'justPublish'}
            watch
            onClickFunc={e => publishNewsClick(e, id, isPublished)}>
            <WatchIcon />
          </ActionsButton>
          <ActionsButton
            tooltipId={`Edit__tooltip-${id}`}
            tooltipTextId="justEdit"
            edit
            onClickFunc={e => editNewsClick(e, id)}>
            <EditIcon />
          </ActionsButton>
          <ActionsButton
            tooltipId={`Delete__tooltip-${id}`}
            tooltipTextId="justDelete"
            deleteType
            onClickFunc={e => deleteNewsClick(e, id)}>
            <BasketIcon />
          </ActionsButton>
        </React.Fragment>
      ),
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      onRowClick(rowInfo.original.id);
    },
  });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="NewsTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        loading={loading}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

NewsTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  deleteNews: PropTypes.func.isRequired,
  publishNews: PropTypes.func.isRequired,
  unPublishNews: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

NewsTable.defaultProps = {
  loading: false,
  theme: {},
};

export default withTheme()(ModalHOC(NewsTable));
export { NewsTable };
