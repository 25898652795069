import { put, call, fork, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getNewsSaga({ paginationParams }) {
  try {
    const { data } = yield call(api.getNews, paginationParams);
    yield put(ActionTypes.getNews.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getNews.failure(e));
  }
}

export function* getNewsWatcherSaga() {
  while (true) {
    const { paginationParams } = yield take(ActionTypes.GET_NEWS[REQUEST]);
    yield call(getNewsSaga, paginationParams);
  }
}

export function* getAnalyticReviewsSaga({ paginationParams }) {
  try {
    const { data } = yield call(api.getAnalyticReviews, paginationParams);
    yield put(ActionTypes.getAnalyticReviews.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getAnalyticReviews.failure(e));
  }
}

export function* getAnalyticReviewsWatcherSaga() {
  while (true) {
    const { paginationParams } = yield take(ActionTypes.GET_ANALYTIC_REVIEWS[REQUEST]);
    yield call(getAnalyticReviewsSaga, paginationParams);
  }
}

export default [fork(getNewsWatcherSaga), fork(getAnalyticReviewsWatcherSaga)];
