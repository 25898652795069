import axios from 'axios';

export const getCrmManagers = () => axios.get(`/crm/task/managers/`);
export const getAllCrmTasks = search => axios.get(`/crm/task/list/`, { params: { ...search } });
export const getAllOpenCrmTasks = search => axios.get(`/crm/task/list/`, { params: { ...search, status: 'open' } });
export const getAllClosedCrmTasks = search => axios.get(`/crm/task/list/`, { params: { ...search, status: 'closed' } });

export const getAllOpenReassignsTasks = search =>
  axios.get(`/crm/task/reassign/list/`, { params: { ...search, status: 'open' } });
export const getAllClosedReassignsTasks = search =>
  axios.get(`/crm/task/reassign/list/`, { params: { ...search, status: 'closed' } });

export const getAllReassignTasks = search => axios.get(`/crm/task/reassign/list/`, { params: { ...search } });
export const getCrmTask = taskId => axios.get(`/crm/task/${taskId}/`);
export const getCrmPlan = () => axios.get('/crm/task/plan/');
export const getCrmPlanOverdue = search => axios.get(`/crm/task/plan/overdue/`, { params: { ...search } });
export const getCrmPlanToday = search => axios.get(`/crm/task/plan/today/`, { params: { ...search } });
export const getTasksCountsNew = () => axios.get('/crm/task/plan/counts/');
export const getCrmPlanTomorrow = search => axios.get(`/crm/task/plan/tomorrow/`, { params: { ...search } });
export const createCrmTask = data => axios.post(`/crm/task/create/`, { ...data });
export const updateCrmTask = (taskId, data) => axios.patch(`/crm/task/${taskId}/`, { ...data });
export const removeCrmTask = taskId => axios.delete(`/crm/task/${taskId}/`);

export const getAllMyOpenCrmTasks = (search, managerId) =>
  axios.get(`/crm/task/${managerId}/list/`, { params: { ...search, status: 'open' } });
export const getAllMyClosedCrmTasks = (search, managerId) =>
  axios.get(`/crm/task/${managerId}/list/`, { params: { ...search, status: 'closed' } });

export const newChangeReassignTaskStatus = (taskId, status, reason) =>
  axios.put(`/crm/task/reassign/${taskId}/`, { status, comment: reason });
