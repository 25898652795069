import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';

import ShareSocialButtons from '../ShareSocialButtons';
import { staticStyles, getDynamicStyles } from './style';

class ArticlesItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    const bodyArticleHeight = document.getElementById(`ArticlesItem__body-${this.props.id}`).offsetHeight;
    this.setState({ bodyArticleHeight });
  }

  toggleOpen(e, inText) {
    if (this.state.bodyArticleHeight > 200) {
      if (!this.state.open) {
        e.preventDefault();
      }
      if (inText) {
        this.setState({ open: true });
      } else {
        this.setState({ open: !this.state.open }); // eslint-disable-line
      }
    }
  }

  render() {
    const { title, date, body, id, theme } = this.props;
    const { bodyArticleHeight } = this.state;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    return (
      <div className="ArticlesItem" id={id}>
        <div className="ArticlesItem__date">
          <FormattedDate value={date} day="2-digit" />
          <FormattedDate value={date} month="long" />
        </div>

        <div className="ArticlesItem__right-inner">
          <div className="ArticlesItem__caption">
            <h3>{title}</h3>
          </div>
          <div
            role="presentation"
            onClick={e => this.toggleOpen(e, true)}
            className={classNames('ArticlesItem__body', {
              ArticlesItem__body_open: this.state.open || bodyArticleHeight < 200,
            })}
            id={`ArticlesItem__body-${id}`}
            dangerouslySetInnerHTML={{
              __html: `${body}`,
            }}
          />

          <div className="ArticlesItem__bottom-line">
            {bodyArticleHeight > 200 && (
              <FormattedMessage id={this.state.open ? 'justHide' : 'justReadMore'}>
                {txt => (
                  <a href={`#${id}`} className="Open__link" onClick={e => this.toggleOpen(e)}>
                    {txt}
                  </a>
                )}
              </FormattedMessage>
            )}
            <ShareSocialButtons />
          </div>
        </div>

        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </div>
    );
  }
}

ArticlesItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  theme: PropTypes.object,
};

ArticlesItem.defaultProps = {
  theme: {},
};

export default withTheme()(ArticlesItem);
export { ArticlesItem };
