import { fork, put, call, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as actionTypes from './actions';
import * as api from '../api';

export function* getPolicySettingInfoSaga(id) {
  try {
    const { status, data } = yield call(api.getPolicySetting, id);
    if (status < 300) {
      yield put(actionTypes.getPolicySetting.success(data));
    } else {
      yield put(actionTypes.getPolicySetting.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPolicySetting.failure());
  }
}

export function* getPolicySettingInfoSagaWatcher() {
  while (true) {
    const { id } = yield take(actionTypes.GET_POLICY_SETTING[REQUEST]);
    yield call(getPolicySettingInfoSaga, id);
  }
}

export function* getPaymentSettingsSaga(id) {
  try {
    const { status, data } = yield call(api.getPaymentSettings, id);
    if (status < 300) {
      yield put(actionTypes.getPaymentSettings.success(data));
    } else {
      yield put(actionTypes.getPaymentSettings.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentSettings.failure());
  }
}

export function* getPaymentSettingsSagaWatcher() {
  while (true) {
    const { id } = yield take(actionTypes.GET_PAYMENT_SETTINGS[REQUEST]);
    yield call(getPaymentSettingsSaga, id);
  }
}

export function* getPolicySettingsSaga() {
  try {
    const { status, data } = yield call(api.getPolicySettings);
    if (status < 300) {
      yield put(actionTypes.getPolicySettings.success(data));
    } else {
      yield put(actionTypes.getPolicySettings.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPolicySettings.failure());
  }
}

export function* getPolicySettingsSagaWatcher() {
  while (true) {
    yield take(actionTypes.GET_POLICY_SETTINGS[REQUEST]);
    yield call(getPolicySettingsSaga);
  }
}

export function* createPolicySettingSaga(payload) {
  try {
    const { status, data } = yield call(api.createPolicySetting, payload);
    if (status < 300) {
      yield put(actionTypes.createPolicySetting.success(data));
    } else {
      yield put(actionTypes.createPolicySetting.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.createPolicySetting.failure());
  }
}

export function* createPolicySettingSagaWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.createPolicySetting.REQUEST);
    yield call(createPolicySettingSaga, payload);
  }
}

export function* deletePolicySettingSaga({ id }) {
  try {
    const { status, data } = yield call(api.deletePolicySetting, id);
    if (status < 300) {
      yield put(actionTypes.deletePolicySetting.success(data));
      yield call(getPolicySettingsSaga);
    } else {
      yield put(actionTypes.deletePolicySetting.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.deletePolicySetting.failure());
  }
}

export function* deletePolicySettingSagaWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.deletePolicySetting.REQUEST);
    yield call(deletePolicySettingSaga, payload);
  }
}

export function* editPolicySettingSaga({ id, ...payload }) {
  try {
    const { status, data } = yield call(api.editPolicySetting, id, payload);
    if (status < 300) {
      yield put(actionTypes.editPolicySetting.success(data));
      yield call(getPolicySettingsSaga);
    } else {
      yield put(actionTypes.editPolicySetting.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.editPolicySetting.failure());
  }
}

export function* editPolicySettingSagaWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.editPolicySetting.REQUEST);
    yield call(editPolicySettingSaga, payload);
  }
}

export default [
  fork(getPaymentSettingsSagaWatcher),
  fork(getPolicySettingInfoSagaWatcher),
  fork(getPolicySettingsSagaWatcher),
  fork(createPolicySettingSagaWatcher),
  fork(editPolicySettingSagaWatcher),
  fork(deletePolicySettingSagaWatcher),
];
