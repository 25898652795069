import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestStatementPanel {
    position: relative;
    display: flex;
    padding: rem(25px);
    margin-bottom: rem(30px);

    .StatementCard {
      flex: 1;

      &:not(:first-child) {
        padding-left: rem(25px);

        @include respond-to(sm) {
          padding-left: 0;
          padding-top: rem(25px);
        }
      }
    }

    @include respond-to(sm) {
      flex-direction: column;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .InvestStatementPanel {
      background-color: ${colors.white.hex};

      .StatementCard {
        &:not(:last-child) {
          border-right: 1px solid ${colors.lightGray.hex};

          @include respond-to(sm) {
            border-right: none;
            border-bottom: 1px solid ${colors.lightGray.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
