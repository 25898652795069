import React from 'react';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';
import radio from './radio.svg';
import radioActive from './radioActive.svg';

const RadioSearch = ({ externalClass, theme, textId, active, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <div className={classNames('RadioSearch', externalClass)} {...props}>
      {active ? (
        <img className="RadioSearch__icon" src={radioActive} alt="radio active" />
      ) : (
        <img className="RadioSearch__icon" src={radio} alt="radio" />
      )}
      {textId && <FM id={textId}>{txt => <div className="RadioSearch__text">{txt}</div>}</FM>}
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

export default withTheme()(RadioSearch);
