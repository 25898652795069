import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PageContentPreloader } from 'common/components';

import { getCountries } from 'client/KYC/_redux';
import UserKYCForm from '../UserKYCForm';
import { getUserKYC } from '../../_redux';

const UserKYC = ({
  user,
  kycValues,
  kycValuesAreLoaded,
  getUserKYC,
  countries,
  countriesAreLoaded,
  getCountries,
  states,
  statesAreLoaded,
  passportMandatory,
  addressProofMandatory,
  identificationProofMandatory,
  removeKycTaxCheckbox,
}) => {
  useEffect(() => {
    getUserKYC({ id: user.id });
    getCountries();
  }, [getUserKYC, getCountries, user.id]);

  const { passportStatus, addressProofStatus, identificationProofStatus } = user;

  const passportDisplayed = !passportMandatory || (passportMandatory && passportStatus !== 'notUploaded');
  const addressProofDisplayed =
    !addressProofMandatory || (addressProofMandatory && addressProofStatus !== 'notUploaded');
  const identificationProofDisplayed =
    !identificationProofMandatory || (identificationProofMandatory && identificationProofStatus !== 'notUploaded');

  const mandatoryDocumentsAreSending = passportDisplayed && addressProofDisplayed && identificationProofDisplayed;

  return (
    <PageContentPreloader ready={kycValuesAreLoaded} type="text">
      <UserKYCForm
        user={user}
        initialValues={{ ...kycValues, usTax: removeKycTaxCheckbox ? true : kycValues.usTax }}
        countries={countries}
        states={states}
        countriesAreLoaded={countriesAreLoaded}
        statesAreLoaded={statesAreLoaded}
        mandatoryDocumentsAreSending={mandatoryDocumentsAreSending}
      />
    </PageContentPreloader>
  );
};

UserKYC.propTypes = {
  user: PropTypes.object.isRequired,
  kycValues: PropTypes.object.isRequired,
  kycValuesAreLoaded: PropTypes.bool.isRequired,
  getUserKYC: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  getCountries: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  statesAreLoaded: PropTypes.bool.isRequired,
  passportMandatory: PropTypes.bool.isRequired,
  addressProofMandatory: PropTypes.bool.isRequired,
  identificationProofMandatory: PropTypes.bool.isRequired,
  removeKycTaxCheckbox: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    user: state.backoffice.users.user,
    kycValues: state.backoffice.users.kycValues,
    kycValuesAreLoaded: state.backoffice.users.kycValuesAreLoaded,
    countries: state.client.kyc.countries,
    countriesAreLoaded: state.client.kyc.countriesAreLoaded,
    states: state.client.kyc.states,
    statesAreLoaded: state.client.kyc.statesAreLoaded,
    passportMandatory: state.interfaceConfig.kycSettings.passport.mandatory,
    addressProofMandatory: state.interfaceConfig.kycSettings.address_proof.mandatory,
    identificationProofMandatory: state.interfaceConfig.kycSettings.identification_proof.mandatory,
    removeKycTaxCheckbox: state.interfaceConfig.features.includes('removeKycTaxCheckbox'),
  }),
  {
    getUserKYC: data => getUserKYC.request(data),
    getCountries: () => getCountries.request(),
  }
)(UserKYC);
export { UserKYC };
