import css from 'styled-jsx/css';

import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserDocument {
    &:nth-of-type(1) {
      margin: 20px 0 0 0;
    }

    &__inner {
      display: flex;
      padding: 20px 25px 20px 25px;
      border-bottom: 2px solid #e9e9e9;
      margin: 0 0 0 -25px;

      @include respond-to(sm) {
        padding: 18px 13px 18px 13px;
      }

      &.UserDocument--min {
        display: block;
        border: none !important;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        width: calc(20% - 20px);
        margin: 0 10px 10px 10px;
      }

      @include respond-to(sm) {
        margin: 0 0 0 -13px;
      }

      &:nth-of-type(1) {
        margin: 20px 0 0 -25px;
        border-top: 2px solid #e9e9e9;

        @include respond-to(sm) {
          margin: 18px 0 0 -13px;
        }
      }

      &:last-child {
        border-bottom: none;
        padding: 20px 25px 0 25px;

        @include respond-to(sm) {
          padding: 18px 13px 0 13px;
        }
      }

      &.UserDocument--min {
        &:nth-of-type(1) {
          margin: 0 10px 10px 10px;
        }

        &:last-child {
          padding: 0 0 0 0;
        }
      }
    }

    &__photo-inner {
      margin: 0 auto 0 0;
    }

    &__download-inner {
      position: relative;
      display: flex;
      width: 180px;
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      border: 2px solid #dadada;
      border-radius: 8px;

      @include respond-to(sm) {
        width: 91px;
        height: 58px;
      }

      .UserDocument--min & {
        width: 50px;
        height: 32px;
        border-radius: 5px;
      }

      &--document {
        border: 2px solid #dadada;
        border-radius: 8px;

        .UserDocument--min & {
          border-radius: 5px;
        }

        :global(img) {
          max-width: 140px;
          max-height: 75px;

          @include respond-to(sm) {
            max-width: 71px;
            max-height: 38px;
          }

          .UserDocument--min & {
            max-width: 38px;
            max-height: 20px;
          }
        }

        :global(img.pdf),
        :global(img.msword) {
          width: 140px;
          height: 75px;

          @include respond-to(sm) {
            max-width: 71px;
            max-height: 38px;
          }

          .UserDocument--min & {
            max-width: 38px;
            max-height: 20px;
          }
        }
      }

      :global(.LightBoxImage) {
        width: auto;
        max-width: auto;
        height: auto;
      }

      :global(.LightBoxImage .LightBoxImage__inner) {
      }

      :global(.LightBoxImage .LightBoxImage__inner img) {
        width: auto !important;
        height: auto !important;
        max-width: 180px !important;
        max-height: 115px !important;

        @include respond-to(sm) {
          max-width: 91px !important;
          max-height: 58px !important;
        }

        .UserDocument--min & {
          max-width: 50px !important;
          max-height: 32px !important;
        }
      }
    }

    &__icon-status {
      position: absolute;
      width: 26px;
      height: 26px;
      background: #eaeaea;
      border: 2px solid #fff;
      left: calc(100% - 13px);
      bottom: calc(100% - 13px);
      border-radius: 50%;
      z-index: 1;

      @include respond-to(sm) {
        width: 16px;
        height: 16px;
        left: calc(100% - 8px);
        bottom: calc(100% - 8px);
      }

      .UserDocument--min & {
        width: 10px;
        height: 10px;
        left: calc(100% - 5px);
        bottom: calc(100% - 5px);
        border: 2px solid #fff;
      }

      &--verified {
        background: #389700;
      }

      &--unverified {
        background: #f5a623;
      }
    }

    &__buttons-inner {
      display: flex;
      align-items: center;

      .UserDocument--min & {
        display: none;
      }

      :global(.DefaultButton__success) {
        width: auto;
        min-width: 95px;
        margin: 0 10px 0 0;

        @include respond-to(sm) {
          border: none;
          padding: 0 10px 0 10px;
          width: auto;
          min-width: 0;
          margin: 0 5px 0 0;
        }
      }

      :global(.DefaultButton__error) {
        width: auto;
        min-width: 95px;

        @include respond-to(sm) {
          border: none;
          padding: 0 10px 0 10px;
          width: auto;
          min-width: 0;
          margin: 0 5px 0 0;
        }
      }
    }

    &__verified-inner {
      display: flex;
      justify-content: center;
      align-items: center;

      .UserDocument--min & {
        display: none;
      }
    }

    &__status {
      margin: 0 20px 0 0;

      :global(.StatusCell) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__verified-inner {
      :global(.ActionsButton) {
        margin: 0 0 0 0;
      }

      :global(.ActionsButton svg) {
        width: 19px;
        height: 19px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    /* .UserDocument__inner {
      border-right: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      @include respond-to(sm) {
        border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      }
    } */

    .UserDocument__error {
      color: ${colors.error.hex};
      margin: -15px 0 10px;
      display: block;
      width: 100%;
    }
  `;
};

export { staticStyles, getDynamicStyles };
