import axios from 'axios';

export const boGetInvestCRMStrategies = search => axios.get('/backoffice/invest_crm/', { params: { ...search } });

export const boGetInvestCRMStrategyInvestors = (strategyId, search) =>
  axios.get(`/backoffice/invest_crm/${strategyId}/investors/`, { params: { ...search } });

export const boToggleInvestCRMStrategy = (strategyId, strategyAction) =>
  axios.post(`/backoffice/invest_crm/${strategyId}/${strategyAction}/`);

export const boToggleCRMInvestment = (investmentId, investmentAction, account) =>
  axios.post(`/backoffice/invest_crm/investments/${investmentId}/${investmentAction}/`, {
    account,
  });

export const editInvestCRMStrategy = (id, values) => axios.put(`/backoffice/invest_crm/${id}/change/`, values);
