import axios from 'axios';

const wsBaseClient = axios.create({
  baseURL: '/welcome_setup/',
  validateStatus: status => status < 300 || status === 401 || status === 403 || status === 409 || status === 429,
});

wsBaseClient.interceptors.request.use(config => {
  const locale = localStorage.getItem('locale');

  if (locale) config.headers.locale = locale;

  return config;
});

const wsLoginClient = axios.create({
  baseURL: '/welcome_setup/',
  validateStatus: status => status < 300 || status === 401 || status === 409 || status === 429,
});

wsLoginClient.interceptors.request.use(config => {
  const token = localStorage.getItem('wsOtpToken');
  const locale = localStorage.getItem('locale');

  if (token) config.headers.Authorization = `JWT ${token}`;

  if (locale) config.headers.locale = locale;

  return config;
});

const wsSessionClient = axios.create({
  baseURL: '/welcome_setup/',
  validateStatus: status => status < 300 || status === 401 || status === 409 || status === 429,
});

wsSessionClient.interceptors.request.use(config => {
  const token = localStorage.getItem('wsJwtToken');
  const locale = localStorage.getItem('locale');

  if (token) config.headers.Authorization = `JWT ${token}`;

  if (locale) config.headers.locale = locale;

  return config;
});

const wsApiClient = axios.create({
  baseURL: '/api/',
  validateStatus: status => status < 300 || status === 401 || status === 409 || status === 429,
});

wsApiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('wsJwtToken');
  const locale = localStorage.getItem('locale');

  if (token) config.headers.Authorization = `JWT ${token}`;

  if (locale) config.headers.locale = locale;

  return config;
});

export const wsRegister = data =>
  wsBaseClient.post(
    '/registration/',
    { ...data },
    {
      withCredentials: true,
      validateStatus: status => status < 300 || status === 400 || status === 409,
    }
  );

export const wsConfirmEmail = data => wsBaseClient.post('/confirm_email/', { ...data });

export const wsGetOtpToken = data => wsBaseClient.post('/get_otp_token/', { ...data });

export const wsRequestResetPassword = data => wsBaseClient.post('/request_password_reset/', { ...data });

export const wsConfirmPasswordReset = data => wsBaseClient.post('/password_reset/', { ...data });

export const wsGetJwtToken = data => wsLoginClient.post('/get_token/', { ...data });

export const wsGetAvailableLangs = () => wsApiClient.get('/utils/available_langs/');

export const wsGetMtData = () => wsSessionClient.get('/configurate_mt/');

export const wsConfigureMt = data => wsSessionClient.post('/configurate_mt/', { ...data });

export const wsConfigureAccountAndCreateBroker = data =>
  wsSessionClient.post('/configurate_account_and_create_broker/', { ...data });

export const wsGetConfiguretedDnsTxtAndCNAME = () => wsSessionClient.get('/configurate_domain/');

export const wsConfigureDomain = data => wsSessionClient.post('/configurate_domain/', { ...data });

export const wsGetRegistrationStatus = () => wsSessionClient.get('/status/');

export const wsGetMtGroups = () => wsSessionClient.get('/mt_groups/');

export const wsCheckDomain = () => wsSessionClient.post('/check_domain/');

export const wsSendNotificationLetterAgain = email => wsSessionClient.post('/resend_confirm_email/', { email });
