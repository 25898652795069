import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CommissionsFilterForm {
    display: flex;
    flex-direction: column;

    :global(.DefaultButton) {
      max-width: 150px;
    }
    margin-bottom: rem(30px);
  }

  .CommissionsFilterForm__title {
    font-size: 16px;
    margin: rem(25px 0);
  }

  .CommissionsFilterForm__fields {
    display: flex;
    width: 100%;
    margin-bottom: rem(15px);

    :global(.ReduxTextField, .ReduxSelectField) {
      min-width: 320px !important;
    }

    :global(.DateRangeField) {
      :global(.DefaultButton) {
        width: 120px;
      }
      :global(.DateRangeField__picker) {
        width: 100% !important;
        min-width: 320px !important;
      }
    }

    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .CommissionsFilterForm__block {
    flex: 1;

    &:nth-child(2) {
      margin-left: rem(80px);

      @include respond-to(sm) {
        margin-left: 0;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .CommissionsFilterForm {
    }
  `;
};

export { staticStyles, getDynamicStyles };
