import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { FormMessageHOC } from 'common';

import { ReduxTextField, ReduxSelectField, DefaultButton } from 'common/components';

import { depositWalletBalance, withdrawWalletBalance } from '../../_redux';
import { staticStyles } from './style';

const UserWalletPaymentForm = ({ userId, handleSubmit, dispatch, invalid, submitting, error }) => {
  const paymentOptions = [
    { label: <FormattedMessage id="justDeposit" />, value: 'deposit' },
    { label: <FormattedMessage id="justWithdraw" />, value: 'withdraw' },
  ];

  const submit = handleSubmit(({ paymentOperation, amount }) => {
    if (paymentOperation === 'deposit') return depositWalletBalance({ userId, amount }, dispatch);

    return withdrawWalletBalance({ userId, amount }, dispatch);
  });

  return (
    <form className="UserWalletPaymentForm" onSubmit={submit}>
      <div className="UserWalletPaymentForm__FieldWrapper">
        <Field
          component={ReduxSelectField}
          name="paymentOperation"
          textId="justPayment"
          options={paymentOptions}
          disabled={submitting}
          validate={[required()]}
        />
        <Field
          component={ReduxTextField}
          name="amount"
          type="number"
          textId="justAmount"
          disabled={submitting}
          validate={[required()]}
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <div className="UserWalletPaymentForm__Button">
        <DefaultButton textId="justChange" type="submit" disabled={invalid || submitting} loading={submitting} filled />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserWalletPaymentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  error: PropTypes.string,
};

UserWalletPaymentForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'UserWalletPaymentForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageId);
      props.onCloseModal();
    },
  })
)(UserWalletPaymentForm);
