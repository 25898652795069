import { combineReducers } from 'redux';

import { LOGOUT_SUCCESS } from 'auth';

import { reducer as contacts } from '../contacts';

import { reducer as common } from './commonReducer';
import { reducer as users } from '../users';
import { reducer as tasks } from '../tasks';
import { reducer as analytics } from '../analytics';

const reducers = combineReducers({ contacts, common, users, tasks, analytics });

const reducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return reducers(state, action);
};

export default reducer;
