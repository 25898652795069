import React from 'react';
import css from 'styled-jsx/css';

const staticStyle = css`
  .RetryIcon {
    border-radius: 8px;
    border: 1px solid #0aa1e2;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease;
    cursor: pointer;
  }

  .RetryIcon:hover {
    background-color: #0aa1e2;
    background-color: calc(50% + #0aa1e2);
    cursor: pointer;
  }
`;

const RetryIcon = () => (
  <div className="RetryIcon">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7.00313 0.00456491C5.37125 0.00456491 3.77813 0.575502 2.52813 1.61644C2.4875 1.64894 2.4625 1.68956 2.43438 1.72988L0.853438 0.14769C0.710875 0.00512736 0.494062 -0.0359038 0.308437 0.0392837C0.122187 0.11644 0 0.29894 0 0.501127V4.50113C0 4.77613 0.22375 5.00113 0.5 5.00113H4.5C4.70216 5.00113 4.88469 4.87906 4.96187 4.69253C5.03906 4.506 4.99606 4.29003 4.85347 4.14753L3.11875 2.4105C3.13425 2.40025 3.15238 2.39659 3.16691 2.38438C4.24063 1.49238 5.60313 1.00113 7 1.00113C10.3094 1.00113 13 3.69269 13 7.00113C13 10.3096 10.3084 13.0011 7 13.0011C5.14156 13.0011 3.41563 12.1602 2.26875 10.6924C2.09784 10.4746 1.78437 10.4355 1.56656 10.6074C1.34975 10.7773 1.31166 11.0918 1.48159 11.3096C2.81941 13.0196 4.83472 13.9977 7.00347 13.9977C10.8625 13.998 14 10.8605 14 7.00113C14 3.14175 10.8625 0.00456491 7.00313 0.00456491ZM1 4.00113V1.70831L3.29375 4.00113H1Z"
        fill="#333333"
      />
    </svg>
    <style jsx>{staticStyle}</style>
  </div>
);

export default RetryIcon;
