import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change, untouch } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { numericality, required } from 'redux-form-validators';
import { BasicModal, DefaultButton, PageContentPreloader } from 'common/components';
import { ReduxSelectField, ReduxTextField } from 'common/components/form-fields';
import { withTheme } from 'common/styling/theme';
import { FormMessageHOC } from 'common/HOCs';
import { getInnerTransferOptions, getInnerTransferInfo, createInnerTransfer } from '../../_redux';
import InternalTransferConfirmation from '../InternalTransferConfirmation';

import { staticStyles, getDynamicStyles } from './style';

const selector = formValueSelector('inner-transfer-form');
const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

const InnerTransfer = ({
  formValues,
  innerTransferOptions,
  innerTransferInfo,
  innerTransferInfoIsLoaded,
  innerTransferOptionsAreLoaded,
  getInnerTransferOptions,
  getInnerTransferInfo,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  transfersError,
  error,
  dispatch,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReceiveAmountNegative, setIsReceiveAmountNegative] = useState(false);
  const [sendCurrency, setSendCurrency] = useState('');
  const [receiveCurrency, setReceiveCurrency] = useState('');
  const [isSendAmountDisabled, setSendAmountDisabled] = useState(true);
  const submit = handleSubmit(values => createInnerTransfer(values, dispatch));

  const { senderCurrencyDigit = 2, recipientCurrencyDigit = 2 } = useMemo(() => {
    if (innerTransferInfo.details) {
      const { senderCurrencyDigit, recipientCurrencyDigit } = innerTransferInfo.details;
      return { senderCurrencyDigit, recipientCurrencyDigit };
    }
    return {};
  }, [innerTransferInfo.details]);

  const min = innerTransferInfo.minTransfer || 0;
  const maxTransfer = innerTransferInfo.maxTransfer || 0;
  const availableAmount = innerTransferInfo.availableAmount || 0;
  const max = maxTransfer > availableAmount ? availableAmount : maxTransfer;

  const minMessage = intl.formatMessage({ id: 'clientInnerTransferErrorMin' }, { min, currency: sendCurrency });
  const maxMessage = intl.formatMessage({ id: 'clientInnerTransferErrorMax' }, { max, currency: sendCurrency });
  const insufficientFundsMessage = intl.formatMessage({ id: 'clientInnerTransferErrorInsufficientFunds' });

  useEffect(() => {
    getInnerTransferOptions();
  }, [getInnerTransferOptions]);

  const toggleTransferConfirmationModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getInnerTransferCommission = (...values) => {
    const { senderAccount, recipientAccount, sendAmount } = formValues;
    if (values[3] === 'senderAccount' && recipientAccount) {
      if (values[0] !== recipientAccount) {
        getInnerTransferInfo({
          senderAccount: values[0],
          recipientAccount,
        });
        setSendCurrency(innerTransferOptions.find(item => item.id === values[0]).balance?.currency ?? 'USD');

        setReceiveCurrency(innerTransferOptions.find(item => item.id === recipientAccount).balance?.currency ?? 'USD');
        setSendAmountDisabled(false);
      } else {
        dispatch(change('inner-transfer-form', 'recipientAccount', null));
        dispatch(untouch('inner-transfer-form', 'recipientAccount'));
        setSendAmountDisabled(true);
      }
    } else if (values[3] === 'recipientAccount' && senderAccount) {
      if (values[0] !== senderAccount) {
        getInnerTransferInfo({
          recipientAccount: values[0],
          senderAccount,
        });
        setSendCurrency(innerTransferOptions.find(item => item.id === senderAccount).balance?.currency ?? 'USD');
        setReceiveCurrency(innerTransferOptions.find(item => item.id === values[0]).balance?.currency ?? 'USD');
        setSendAmountDisabled(false);
      } else {
        dispatch(change('inner-transfer-form', 'senderAccount', null));
        dispatch(untouch('inner-transfer-form', 'senderAccount'));
        setSendAmountDisabled(true);
      }
    }
    if (sendAmount) {
      dispatch(change('inner-transfer-form', 'sendAmount', null));
      dispatch(untouch('inner-transfer-form', 'sendAmount'));
      dispatch(change('inner-transfer-form', 'receiveAmount', null));
    }
  };

  const calculateReceiveAmount = (_, value) => {
    const sendAmount = parseFloat(value);
    if (
      typeof sendAmount === 'number' &&
      sendAmount <= innerTransferInfo.availableAmount &&
      innerTransferInfo.details
    ) {
      const { exchangeCoefficient = 1, commission } = innerTransferInfo.details;
      let calculatedCommission =
        (sendAmount / 100) * commission.percentage +
        commission.fix * innerTransferInfo.details.exchangeCoefficientForFix;
      if (calculatedCommission > innerTransferInfo.details.maxCommission.amount)
        calculatedCommission = innerTransferInfo.details.maxCommission.amount;
      if (calculatedCommission < innerTransferInfo.details.minCommission.amount)
        calculatedCommission = innerTransferInfo.details.minCommission.amount;
      const receiveAmount = (sendAmount - calculatedCommission) * exchangeCoefficient;
      let roundedReceiveAmount = 0;
      if (receiveAmount > 0) {
        roundedReceiveAmount = getFixedNumber(receiveAmount, recipientCurrencyDigit);
        setIsReceiveAmountNegative(false);
      } else {
        setIsReceiveAmountNegative(true);
      }
      dispatch(change('inner-transfer-form', 'receiveAmount', roundedReceiveAmount.toString()));
    }
  };

  const accounts = innerTransferOptions.map(item => ({
    label: `${item.accountType} ${item.login} ${
      item.balance ? ` - ${item.balance.amount}${item.balance.currency}` : ''
    }`,
    value: item.id,
  }));

  const getAccountInfo = account => innerTransferOptions.find(item => item.id === account);

  const checkForDigitsAfterDecimal = useCallback(
    value => {
      const regex = new RegExp(`^\\d+\\.?\\d{0,${senderCurrencyDigit}}$`);
      if (!regex.test(value)) {
        return intl.formatMessage(
          { id: 'maximumNumberOfDigitsAfterDecimalPoint' },
          { maximumDigits: senderCurrencyDigit }
        );
      }
      return null;
    },
    [senderCurrencyDigit]
  );

  return (
    <PageContentPreloader ready={innerTransferOptionsAreLoaded} type="bigLine">
      <form className="InnerTransfer" onSubmit={submit}>
        <div className="InnerTransfer__line">
          <Field
            component={ReduxSelectField}
            name="senderAccount"
            textId="clientAccountSender"
            options={accounts}
            onChange={getInnerTransferCommission}
            validate={[required()]}
          />
          <Field
            component={ReduxSelectField}
            name="recipientAccount"
            textId="clientAccountRecipient"
            options={accounts}
            onChange={getInnerTransferCommission}
            validate={[required()]}
          />
        </div>
        <div className="InnerTransfer__line">
          <Field
            component={ReduxTextField}
            type="text"
            name="sendAmount"
            textId="clientSendAmount"
            validate={[
              required(),
              checkForDigitsAfterDecimal,
              numericality({
                '>=': min,
                '<=': max,
                msg: {
                  '>=': minMessage,
                  '<=': max === availableAmount ? insufficientFundsMessage : maxMessage,
                },
              }),
            ]}
            disabled={submitting || !innerTransferInfoIsLoaded || isSendAmountDisabled}
            onChange={calculateReceiveAmount}
            description={sendCurrency}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="receiveAmount"
            textId="justWillReceive"
            validate={[required()]}
            description={receiveCurrency}
            disabled
          />
        </div>
        {transfersError && <span className="InnerTransfer__error">{transfersError}</span>}
        {innerTransferInfo.details && (
          <div className="InnerTransfer__description">
            <FormattedMessage
              id={
                sendCurrency === receiveCurrency
                  ? 'commissionForInternalTransferSameCurrencies'
                  : 'commissionForInternalTransferDifferentCurrencies'
              }
              values={{
                commission: `${innerTransferInfo.details.commission.percentage}% + ${innerTransferInfo.details.commission.fix} USD.`,
              }}>
              {txt => <span>{txt}</span>}
            </FormattedMessage>
            {innerTransferInfo.details.exchangeRate && <span>{innerTransferInfo.details.exchangeRate}</span>}
            {innerTransferInfo.details.minCommission && innerTransferInfo.details.maxCommission && (
              <FormattedMessage
                id="commissionLimitDescription"
                values={{
                  min: `${innerTransferInfo.details.minCommission.amount} ${innerTransferInfo.details.minCommission.currency}`,
                  max: `${innerTransferInfo.details.maxCommission.amount} ${innerTransferInfo.details.maxCommission.currency}`,
                }}>
                {txt => <span>{txt}</span>}
              </FormattedMessage>
            )}
          </div>
        )}

        <DefaultButton
          textId="clientSend"
          onClick={toggleTransferConfirmationModal}
          type="button"
          disabled={isReceiveAmountNegative || invalid || pristine || submitting}
          filled
        />
      </form>

      <BasicModal
        isOpen={isModalOpen}
        captionId="internalTransferConfirmation"
        onRequestClose={toggleTransferConfirmationModal}>
        <InternalTransferConfirmation
          payload={{
            senderAccount: getAccountInfo(formValues.senderAccount),
            recipientAccount: getAccountInfo(formValues.recipientAccount),
            sendAmount: `${formValues.sendAmount} ${sendCurrency}`,
            receiveAmount: `${formValues.receiveAmount} ${receiveCurrency}`,
          }}
          submitting={submitting}
          handleSubmit={submit}
          handleCancel={toggleTransferConfirmationModal}
          error={error}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageContentPreloader>
  );
};

InnerTransfer.propTypes = {
  formValues: PropTypes.object.isRequired,
  innerTransferInfo: PropTypes.object.isRequired,
  innerTransferOptions: PropTypes.array.isRequired,
  innerTransferInfoIsLoaded: PropTypes.bool.isRequired,
  innerTransferOptionsAreLoaded: PropTypes.bool.isRequired,
  getInnerTransferOptions: PropTypes.func.isRequired,
  getInnerTransferInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  transfersError: PropTypes.string.isRequired,
  error: PropTypes.string,
  intl: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

InnerTransfer.defaultProps = {
  error: '',
  theme: '',
};

export default compose(
  withTheme(),
  withRouter,
  injectIntl,
  FormMessageHOC,
  connect(
    state => ({
      formValues: selector(state, 'senderAccount', 'recipientAccount', 'sendAmount', 'receiveAmount'),
      innerTransferOptions: state.client.transfers.innerTransferOptions,
      innerTransferOptionsAreLoaded: state.client.transfers.innerTransferOptionsAreLoaded,
      innerTransferInfo: state.client.transfers.innerTransferInfo,
      innerTransferInfoIsLoaded: state.client.transfers.innerTransferInfoIsLoaded,
      transfersError: state.client.transfers.error,
    }),
    {
      getInnerTransferOptions: () => getInnerTransferOptions.request(),
      getInnerTransferInfo: data => getInnerTransferInfo.request(data),
    }
  ),
  reduxForm({
    form: 'inner-transfer-form',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageTextId);
      props.history.push('/payments?tab=transfers');
    },
  })
)(InnerTransfer);
export { InnerTransfer };
