import { take, call, put, race, delay } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import Raven from 'raven-js';

import { addMessage } from 'client/messages/_redux';
import { updateIssue } from 'client/support/_redux';
import { getToken } from 'auth';
import * as userActions from '../actions';

const WS_PROTOCOL = document.location.protocol === 'https:' ? 'wss' : 'ws';
const WS_URL = `${WS_PROTOCOL}://${document.location.host}/ws`;

const WS_RECONNECT_TIMEOUT = 5000;

const MESSAGE_TYPES = {
  new_notification: addMessage,
  user_update: userActions.profileUpdate,
  support_issue_update: updateIssue,
};
const createWebsocketChannel = () =>
  eventChannel(emit => {
    const socket = new WebSocket(WS_URL);

    socket.onopen = () => {
      socket.send(getToken());
    };

    socket.onmessage = event => {
      const data = JSON.parse(event.data);

      const action = MESSAGE_TYPES[data.type];
      if (action) {
        emit(action(data.payload));
      } else {
        Raven.captureMessage(`Unused websocket: ${event}`);
      }
    };

    socket.onclose = event => {
      if (event.code >= 4000 && event.code < 5000) {
        Raven.captureMessage(`Websocket closed with strange status: ${event.code}`);
      }
      emit(END);
    };

    return () => {
      socket.close();
    };
  });

function* socketChannelSaga(socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}

function* websocketWatcherSaga() {
  while (true) {
    yield take(userActions.START_WS);

    while (true) {
      const socketChannel = yield call(createWebsocketChannel);

      const { stop } = yield race({
        channel: call(socketChannelSaga, socketChannel),
        stop: take(userActions.STOP_WS),
      });

      socketChannel.close();

      if (stop) {
        break;
      }

      yield delay(WS_RECONNECT_TIMEOUT);
      // connectType = RECONNECT_TYPE
    }
  }
}

export default websocketWatcherSaga;
