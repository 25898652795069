import React from 'react';

const ChallengesIcon = () => (
  <div>
    <svg
      fill="#c9c9c9"
      height="64px"
      width="64px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      stroke="#c9c9c9">
      <g id="SVGRepo_bgCarrier" stroke-width="0" />
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M383.783,51.897V0H128.217v51.897H73.491v57.897l0.417,1.771c3.905,16.584,20.406,46.298,55.931,52.644 c7.732,48.154,42.514,88.137,89.59,102.14v99.714h-27.516v48.762h-4.875c-26.791,0-48.588,21.797-48.588,48.588V512h235.102 v-48.588c0-26.791-21.796-48.588-48.588-48.588h-4.877v-48.762h-27.515v-99.714c47.076-14.003,81.857-53.986,89.59-102.14 c35.526-6.346,52.028-36.06,55.931-52.644l0.417-1.772V51.897H383.783z M128.217,131.389 c-15.875-6.036-21.754-20.474-23.38-25.587V83.243h23.38V131.389z M320.087,446.171h4.877c9.506,0,17.241,7.734,17.241,17.241 v17.241H169.796v-17.241c0-9.506,7.734-17.241,17.241-17.241h4.875H320.087z M288.74,397.409v17.415h-65.481v-17.415H288.74z M250.776,366.062v-94.496c0.145,0.006,0.29,0.003,0.436,0.009c1.597,0.06,3.193,0.098,4.789,0.098 c1.596,0,3.192-0.039,4.789-0.098c0.145-0.005,0.291-0.003,0.436-0.009v94.496H250.776z M352.435,143.891 c0.001,46.334-32.99,86.184-78.44,94.755c-4.439,0.837-8.945,1.359-13.469,1.568c-1.508,0.07-3.018,0.104-4.526,0.104 c-6.038,0-12.076-0.558-17.994-1.673c-45.452-8.571-78.442-48.421-78.442-94.754V83.243h192.871V143.891z M352.436,51.897H159.564 v-20.55h192.871V51.897z M407.162,105.725c-0.842,2.496-3.034,8.093-7.261,13.565c-4.436,5.743-9.772,9.785-16.119,12.178V83.243 h23.38V105.725z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default ChallengesIcon;
