import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  internalTransfers: {},
  internalTransfersAreLoaded: false,
  transferDetails: {},
  transferDetailsIsLoaded: false,
  transferActionsLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INTERNAL_TRANSFERS[REQUEST]:
      return { ...state, internalTransfersAreLoaded: false };
    case ActionTypes.GET_INTERNAL_TRANSFERS[SUCCESS]:
      return { ...state, internalTransfers: action.data, internalTransfersAreLoaded: true };
    case ActionTypes.GET_INTERNAL_TRANSFERS[FAILURE]:
      return { ...state, error: action.error, internalTransfersAreLoaded: false };

    case ActionTypes.GET_TRANSFER_DETAILS[REQUEST]:
      return { ...state, transferDetailsIsLoaded: false };
    case ActionTypes.GET_TRANSFER_DETAILS[SUCCESS]:
      return { ...state, transferDetails: action.data, transferDetailsIsLoaded: true };
    case ActionTypes.GET_TRANSFER_DETAILS[FAILURE]:
      return { ...state, error: action.error, transferDetailsIsLoaded: false };

    case ActionTypes.ACTION_TRANSFER[REQUEST]:
      return { ...state, transferActionsLoading: true };
    case ActionTypes.ACTION_TRANSFER[SUCCESS]:
      return { ...state, transferDetails: action.data, transferActionsLoading: false };
    case ActionTypes.ACTION_TRANSFER[FAILURE]:
      return { ...state, error: action.error, transferActionsLoading: false };

    default:
      return state;
  }
};

export default reducer;
