import * as ActionTypes from './actions';

export const initialState = {
  authorized: !!localStorage.getItem('wsJwtToken'),
  availableLangs: [],
  showButtonToResendEmailConfirmation: false,
  emailThatNeedsConfirmation: '',
  brokerNiceName: '',
  emailConfirmation: null,
  emailConfirmationError: '',
  emailConfirmationCriticalError: false,
  isMenuOpen: false,
  wsStep: 1,
  isReenteringMTData: false,
  emailIsValid: false,
  mtIsValid: false,
  userDomain: false,
  dnsTxtIsValid: false,
  dnsCnameIsValid: false,
  crmSetup: false,
  cname: '',
  dns_txt: '',
  email: '',
  acceptedMtDataIsLoading: false,
  acceptedMtData: null,
  mtGroups: null,
  mtGroupsAreLoading: false,
  mtGroupsError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.WS_CONFIRM_EMAIL.REQUEST:
      return { ...state };
    case ActionTypes.WS_CONFIRM_EMAIL.SUCCESS:
      return { ...state, emailConfirmation: action.data };
    case ActionTypes.WS_CONFIRM_EMAIL.FAILURE:
      return action.error === 'Critical'
        ? { ...state, emailConfirmationCriticalError: true }
        : { ...state, emailConfirmationError: action.error };

    case ActionTypes.WS_SET_REDIRECT:
      return { ...state, redirect: true };

    case ActionTypes.WS_SET_AUTHORIZED:
      return { ...state, authorized: true };

    case ActionTypes.WS_SHOW_SEND_NOTIFICATION:
      return {
        ...state,
        showButtonToResendEmailConfirmation: action.showButtonToResendEmailConfirmation,
        emailThatNeedsConfirmation: action.emailThatNeedsConfirmation,
      };

    case ActionTypes.WS_SET_EMAIL:
      return { ...state, email: action.email };

    case ActionTypes.WS_SET_USER_DOMAIN:
      return { ...state, userDomain: action.userDomain };

    case ActionTypes.WS_RESET_AUTH:
      return {
        ...initialState,
        authorized: state.authorized,
        authError: action.error,
      };

    case ActionTypes.WS_GET_AVAILABLE_LANGS.REQUEST:
      return { ...state, availableLangsAreLoading: true };
    case ActionTypes.WS_GET_AVAILABLE_LANGS.SUCCESS:
      return { ...state, availableLangsIsLoading: false, availableLangs: action.data };

    case ActionTypes.WS_GET_AVAILABLE_LANGS.FAILURE:
      return { ...state, availableLangsIsLoading: false, availableLangs: [] };

    case ActionTypes.WS_GET_MT_DATA.REQUEST:
      return { ...state, acceptedMtDataIsLoading: true, acceptedMtData: null };
    case ActionTypes.WS_GET_MT_DATA.SUCCESS:
      return { ...state, acceptedMtDataIsLoading: false, acceptedMtData: action.data };

    case ActionTypes.WS_GET_MT_DATA.FAILURE:
      return { ...state, acceptedMtDataIsLoading: false, acceptedMtData: null };

    case ActionTypes.wsConfigureMt.SUCCESS:
      return { ...state, mtIsValid: true };

    case ActionTypes.WS_LOGOUT_SUCCESS:
      return { ...initialState, authorized: false };

    case ActionTypes.WS_TOGGLE_MENU:
      return { ...state, isMenuOpen: !state.isMenuOpen };

    case ActionTypes.WS_GET_REGISTRATION_STATUS.SUCCESS:
      return {
        ...state,
        wsStep: action.data.wsStep,
        brokerNiceName: action.data.brokerNiceName,
        emailIsValid: action.data.emailIsValid,
        mtIsValid: action.data.mtIsValid,
        userDomain: action.data.userDomain,
        dnsTxtIsValid: action.data.dnsTxtIsValid,
        dnsCnameIsValid: action.data.dnsCnameIsValid,
        crmSetup: action.data.crmSetup,
      };

    case ActionTypes.WS_GET_REGISTRATION_STATUS.FAILURE:
      return { ...state, wsStep: state.authorized ? 2 : 1 };

    case ActionTypes.WS_SET_STEP:
      return { ...state, wsStep: action.step };
    case ActionTypes.WS_SET_IS_REENTERING_MT_DATA:
      return { ...state, isReenteringMTData: action.isReenteringMTData };
    case ActionTypes.WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME.SUCCESS:
      return { ...state, cname: action.data.cname, dns_txt: action.data.dns_txt };
    case ActionTypes.WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME.FAILURE:
      return { ...state, step: 3 };

    case ActionTypes.wsCheckDomain.REQUEST:
      return { ...state, dnsTxtIsValid: false, dnsCnameIsValid: false };
    case ActionTypes.wsCheckDomain.SUCCESS:
      return { ...state, dnsTxtIsValid: action.payload.dnsTxtIsValid, dnsCnameIsValid: action.payload.dnsCnameIsValid };
    case ActionTypes.wsCheckDomain.FAILURE:
      return { ...state, dnsTxtIsValid: false, dnsCnameIsValid: false };

    case ActionTypes.wsGenerateDnsTxt.REQUEST:
      return { ...state, cname: null, dns_txt: null };
    case ActionTypes.wsGenerateDnsTxt.SUCCESS:
      return { ...state, cname: action.payload.cname, dns_txt: action.payload.dns_txt };
    case ActionTypes.wsGenerateDnsTxt.FAILURE:
      return { ...state, cname: null, dns_txt: null };

    case ActionTypes.WS_GET_MT_GROUPS.REQUEST:
      return { ...state, mtGroups: null, mtGroupsAreLoading: true, mtGroupsError: null };
    case ActionTypes.WS_GET_MT_GROUPS.SUCCESS:
      return { ...state, mtGroups: action.data, mtGroupsAreLoading: false, mtGroupsError: null };
    case ActionTypes.WS_GET_MT_GROUPS.FAILURE: {
      return { ...state, mtGroups: null, mtGroupsAreLoading: false, mtGroupsError: action.error };
    }

    default:
      return state;
  }
};

export default reducer;
