import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InputBoolean {
    display: flex;
    padding-top: 20px;
    position: relative;
    width: 390px;
    &__Label {
      position: absolute;
      top: 0;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }

    &__InputWrapper {
      display: flex;
      gap: 4px;
    }
    &__InputLabel {
      position: absolute;
      right: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: left;
      color: #696969;
    }

    &__Input {
      appearance: auto;
      width: 13px;
    }
  }
`;
const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
