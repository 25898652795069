import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';

import { DefaultButton, ReduxSelectField, ReduxTextField, TextAreaField } from 'common/components';

import staticStyles from './style';

import { createTemplate } from '../../_redux';

const CreateTemplateForm = ({ languages, submitting, invalid, handleSubmit, dispatch, history, error }) => {
  const submit = handleSubmit(formValues => createTemplate(formValues, dispatch));

  return (
    <form className="CreateTemplateForm" onSubmit={submit}>
      <div className="CreateTemplateForm__top">
        <Field component={ReduxTextField} name="title" textId="formTemplatesTitle" validate={[required()]} />
        <Field
          component={ReduxSelectField}
          name="language"
          maxMenuHeight={100}
          textId="justLanguage"
          options={languages}
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <Field
        component={TextAreaField}
        name="text"
        textId="justText"
        rows="4"
        cols="150"
        validate={[required()]}
        disabled={submitting}
      />
      {error && <strong className="formError">{error}</strong>}
      <div className="CreateTemplateForm__btns">
        <DefaultButton textId="doSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
        <DefaultButton
          textId="justCancel"
          type="button"
          loading={submitting}
          disabled={submitting}
          onClick={() => history.push('/backoffice/templates')}
        />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

CreateTemplateForm.propTypes = {
  languages: PropTypes.array,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.string,
};

CreateTemplateForm.defaultProps = {
  error: '',
  languages: [],
};

export default reduxForm({
  form: 'CreateTemplateForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.history.push('/backoffice/templates');
  },
})(CreateTemplateForm);
