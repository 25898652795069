import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field, FormSection } from 'redux-form';
import { required } from 'redux-form-validators';

import { FormMessageHOC } from 'common';
import { withTheme } from 'common/styling/theme';

import {
  ReduxTextField,
  ReduxSelectField,
  TextAreaField,
  SwitchField,
  DefaultButton,
  QueryBuilder,
} from 'common/components';

import ColorPickerField from 'admin/_components/ColorPickerField';

import { getFiltersList } from '../../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const StatusesForm = ({
  submitFunction,
  history,
  languages,
  currentLang,
  promoTexts,
  filters,
  filtersAreLoaded,
  getFiltersList,
  handleSubmit,
  invalid,
  submitting,
  dispatch,
  error,
  theme,
  initialValues,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isTitleTranslationOpen, setTitleTranslationOpen] = useState(false);
  const [isSubTitleTranslationOpen, setSubTitleTranslationOpen] = useState(false);
  const [isDescriptionTranslationOpen, setDescriptionTranslationOpen] = useState(false);
  const [isTextButtonTranslationOpen, setTextButtonTranslationOpen] = useState(false);
  const [isShowButton, setShowButton] = useState(initialValues.isButtonEnabled);
  const [openedBullets, setOpenedBullets] = useState([]);
  const [bullets, setBullets] = useState(promoTexts.map((bullet, id) => ({ id, position: id + 1 })));
  const [query, setQuery] = useState({});
  const [queryIsDirty, setQueryIsDirty] = useState(false);

  const toggleTitleBlock = () => setTitleTranslationOpen(!isTitleTranslationOpen);
  const toggleSubTitleBlock = () => setSubTitleTranslationOpen(!isSubTitleTranslationOpen);
  const toggleDescriptionBlock = () => setDescriptionTranslationOpen(!isDescriptionTranslationOpen);
  const toggleButtonTextBlock = () => setTextButtonTranslationOpen(!isTextButtonTranslationOpen);
  const toggleBulletsBlock = bulletId => {
    if (!openedBullets.includes(bulletId)) {
      setOpenedBullets([...openedBullets, bulletId]);
    } else {
      setOpenedBullets(openedBullets.filter(item => item !== bulletId));
    }
  };
  const addBullet = () => {
    setBullets([...bullets, { id: bullets.length + 1, position: bullets.length + 1 }]);
  };
  const changeBulletPosition = (bulletId, currentPosition, direction) => {
    if (direction === 'up' && currentPosition < bullets.length) {
      const replacedBullet = bullets.find(bullet => bullet.position === currentPosition + 1);
      const bulletsWithoutCurrent = bullets.filter(item => item.id !== bulletId && item.id !== replacedBullet.id);
      setBullets([
        ...bulletsWithoutCurrent,
        { id: replacedBullet.id, position: currentPosition },
        { id: bulletId, position: currentPosition + 1 },
      ]);
    } else if (direction === 'down' && currentPosition > 1) {
      const replacedBullet = bullets.find(bullet => bullet.position === currentPosition - 1);
      const bulletsWithoutCurrent = bullets.filter(item => item.id !== bulletId && item.id !== replacedBullet.id);
      setBullets([
        ...bulletsWithoutCurrent,
        { id: replacedBullet.id, position: currentPosition },
        { id: bulletId, position: currentPosition - 1 },
      ]);
    }
  };

  useEffect(() => {
    getFiltersList();
  }, [getFiltersList]);

  const levels = Array.from(Array(9), (_, i) => i + 1).map(level => ({ label: level, value: level }));

  const submit = handleSubmit(values =>
    submitFunction({ ...values, statusColor: values.statusColor.hex, query }, dispatch)
  );

  return (
    <form className="StatusesForm" onSubmit={submit}>
      <div className="StatusesForm__inner">
        <div className="StatusesForm__line">
          <div className="StatusesForm__left-block">
            <Field
              component={ReduxTextField}
              type="text"
              name={`title.${currentLang}`}
              textId="justTitle"
              disabled={submitting}
              maxLength="100"
              validate={[required()]}
            />
            {isTitleTranslationOpen &&
              languages.map(lang => (
                <Field
                  component={ReduxTextField}
                  type="text"
                  name={`title.${lang}`}
                  textId={`lang_${lang}`}
                  disabled={submitting}
                  maxLength="100"
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  key={lang}
                />
              ))}
          </div>
          <div className="StatusesForm__right-block">
            <button type="button" onClick={toggleTitleBlock} disabled={!languages.length}>
              <FormattedMessage id="justShowTranslations">
                {txt => <span className="StatusesForm__translations">{txt}</span>}
              </FormattedMessage>
              <i
                className={classNames('StatusesForm__arrow', {
                  'StatusesForm__arrow-up': isTitleTranslationOpen,
                  'StatusesForm__arrow-down': !isTitleTranslationOpen,
                })}
              />
            </button>
          </div>
        </div>
        <div className="StatusesForm__line">
          <div className="StatusesForm__left-block">
            <Field
              component={ReduxTextField}
              type="text"
              name={`subTitle.${currentLang}`}
              textId="justSubTitle"
              disabled={submitting}
              maxLength="100"
              validate={[required()]}
            />
            {isSubTitleTranslationOpen &&
              languages.map(lang => (
                <Field
                  component={ReduxTextField}
                  type="text"
                  name={`subTitle.${lang}`}
                  disabled={submitting}
                  textId={`lang_${lang}`}
                  maxLength="100"
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  key={lang}
                />
              ))}
          </div>
          <div className="StatusesForm__right-block">
            <button type="button" onClick={toggleSubTitleBlock} disabled={!languages.length}>
              <FormattedMessage id="justShowTranslations">
                {txt => <span className="StatusesForm__translations">{txt}</span>}
              </FormattedMessage>
              <i
                className={classNames('StatusesForm__arrow', {
                  'StatusesForm__arrow-up': isSubTitleTranslationOpen,
                  'StatusesForm__arrow-down': !isSubTitleTranslationOpen,
                })}
              />
            </button>
          </div>
        </div>
        <div className="StatusesForm__line">
          <div className="StatusesForm__left-block">
            <Field
              component={ReduxSelectField}
              name="level"
              maxMenuHeight={100}
              textId="justLevel"
              options={levels}
              validate={[required()]}
              disabled={submitting}
            />
          </div>
          <div className="StatusesForm__right-block" />
        </div>
        <div className="StatusesForm__line">
          <div className="StatusesForm__left-block">
            <Field
              component={TextAreaField}
              disabled={submitting}
              name={`description.${currentLang}`}
              textId="justDescription"
              rows="4"
              cols="150"
              validate={[required()]}
            />
            {isDescriptionTranslationOpen &&
              languages.map(lang => (
                <Field
                  component={TextAreaField}
                  disabled={submitting}
                  name={`description.${lang}`}
                  textId={`lang_${lang}`}
                  rows="4"
                  cols="150"
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  key={lang}
                />
              ))}
          </div>
          <div className="StatusesForm__right-block">
            <button type="button" onClick={toggleDescriptionBlock} disabled={!languages.length}>
              <FormattedMessage id="justShowTranslations">
                {txt => <span className="StatusesForm__translations">{txt}</span>}
              </FormattedMessage>
              <i
                className={classNames('StatusesForm__arrow', {
                  'StatusesForm__arrow-up': isDescriptionTranslationOpen,
                  'StatusesForm__arrow-down': !isDescriptionTranslationOpen,
                })}
              />
            </button>
          </div>
        </div>
        <div className="StatusesForm__line">
          <div className="StatusesForm__left-block">
            <Field
              component={ReduxTextField}
              type="number"
              disabled={submitting}
              name="durationTime"
              textId="justDurationTimeDays"
              tooltipId="statusdurationTimeDescription"
              tooltipTextId="statusdurationTimeDescription"
              validate={[required()]}
            />
          </div>
          <div className="StatusesForm__right-block" />
        </div>

        <div className="StatusesForm__line StatusesForm__ColorPickerField">
          <div className="StatusesForm__left-block">
            <FormattedMessage id="justStatusColor">{txt => <h3>{txt}</h3>}</FormattedMessage>
            <Field component={ColorPickerField} type="text" name="statusColor" textId="justStatusColor" />
          </div>
          <div className="StatusesForm__right-block" />
        </div>
        <FormSection name="promoTexts">
          {bullets
            .sort((a, b) => a.position - b.position)
            .map(bullet => (
              <div className="StatusesForm__line" key={`bullet_${bullet.id}`}>
                <div className="StatusesForm__bullets-left-block">
                  <div className="StatusesForm__bullets-content">
                    <Field
                      component={ReduxTextField}
                      type="text"
                      name={`bullets_${bullet.id}.${currentLang}`}
                      textId="justBullet"
                      disabled={submitting}
                    />
                    {openedBullets.includes(bullet.id) &&
                      languages.map(lang => (
                        <Field
                          component={ReduxTextField}
                          type="text"
                          name={`bullets_${bullet.id}.${lang}`}
                          textId={`lang_${lang}`}
                          disabled={submitting}
                          dir={lang === 'ar' ? 'rtl' : 'ltr'}
                          key={lang}
                        />
                      ))}
                  </div>
                  <div className="StatusesForm__bullets-position">
                    <FormattedMessage id="justPosition">
                      {txt => <span className="StatusesForm__bullets-caption">{txt}</span>}
                    </FormattedMessage>
                    <span className="StatusesForm__bullets-count">{bullet.position}</span>
                    <div className="StatusesForm__move-buttons">
                      <button
                        type="button"
                        tabIndex={-1}
                        onClick={() => changeBulletPosition(bullet.id, bullet.position, 'down')}
                        className="StatusesForm__bullets-arrow-up"
                      />
                      <button
                        type="button"
                        tabIndex={-1}
                        onClick={() => changeBulletPosition(bullet.id, bullet.position, 'up')}
                        className="StatusesForm__bullets-arrow-down"
                      />
                    </div>
                  </div>
                </div>
                <div className="StatusesForm__right-block">
                  <button type="button" onClick={() => toggleBulletsBlock(bullet.id)} disabled={!languages.length}>
                    <FormattedMessage id="justShowTranslations">
                      {txt => <span className="StatusesForm__translations">{txt}</span>}
                    </FormattedMessage>
                    <i
                      className={classNames('StatusesForm__arrow', {
                        'StatusesForm__arrow-up': openedBullets.includes(bullet.id),
                        'StatusesForm__arrow-down': !openedBullets.includes(bullet.id),
                      })}
                    />
                  </button>
                </div>
              </div>
            ))}
        </FormSection>
        <DefaultButton
          className="StatusesForm__addBullet"
          textId="addBullet"
          onClick={addBullet}
          loading={submitting}
          disabled={submitting}
          plus
        />
        {filtersAreLoaded && (
          <QueryBuilder
            filters={filters}
            initialQuery={initialValues.query}
            onChangeQuery={q => {
              setQuery(q);
              setQueryIsDirty(true);
            }}
          />
        )}
        <div className="StatusesForm__right-block" />

        <div className="StatusesForm__line">
          <FormattedMessage id="justShowButton">
            {txt => <span className="StatusesForm__title">{txt}</span>}
          </FormattedMessage>
          <Field
            component={SwitchField}
            name="isButtonEnabled"
            type="checkbox"
            disabled={submitting}
            onChange={(_, value) => setShowButton(value)}
          />
        </div>
        {isShowButton && (
          <>
            <div className="StatusesForm__line">
              <div className="StatusesForm__left-block">
                <Field
                  component={ReduxTextField}
                  type="text"
                  name={`buttonText.${currentLang}`}
                  textId="justButtonText"
                  disabled={submitting}
                  maxLength="100"
                  validate={[required()]}
                />
                {isTextButtonTranslationOpen &&
                  languages.map(lang => (
                    <Field
                      component={ReduxTextField}
                      type="text"
                      name={`title.${lang}`}
                      textId={`lang_${lang}`}
                      disabled={submitting}
                      maxLength="100"
                      dir={lang === 'ar' ? 'rtl' : 'ltr'}
                      key={lang}
                    />
                  ))}
              </div>
              <div className="StatusesForm__right-block">
                <button type="button" onClick={toggleButtonTextBlock} disabled={!languages.length}>
                  <FormattedMessage id="justShowTranslations">
                    {txt => <span className="StatusesForm__translations">{txt}</span>}
                  </FormattedMessage>
                  <i
                    className={classNames('StatusesForm__arrow', {
                      'StatusesForm__arrow-up': isTextButtonTranslationOpen,
                      'StatusesForm__arrow-down': !isTextButtonTranslationOpen,
                    })}
                  />
                </button>
              </div>
            </div>
            <div className="StatusesForm__line">
              <div className="StatusesForm__left-block">
                <Field
                  component={ReduxTextField}
                  type="text"
                  name="buttonLink"
                  textId="justButtonLink"
                  disabled={submitting}
                  maxLength="100"
                  validate={[required()]}
                />
              </div>
              <div className="StatusesForm__right-block" />
            </div>
          </>
        )}
      </div>
      <div className="StatusesForm__buttons">
        <DefaultButton
          textId="justSave"
          type="submit"
          disabled={!queryIsDirty || invalid || submitting}
          loading={submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={() => history.goBack()} />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

StatusesForm.propTypes = {
  submitFunction: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  currentLang: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  filtersAreLoaded: PropTypes.bool.isRequired,
  getFiltersList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  promoTexts: PropTypes.array,
  error: PropTypes.string,
  theme: PropTypes.object,
};

StatusesForm.defaultProps = {
  promoTexts: [],
  initialValues: {},
  theme: {},
  error: '',
};

export default compose(
  FormMessageHOC,
  withTheme(),
  connect(
    state => {
      const availableLangs = state.interfaceConfig.availableLangs;
      const currentLang = state.interfaceConfig.lang;
      const languages = availableLangs.map(item => item.slug).filter(slug => slug !== currentLang);
      const filters = state.backoffice.common.filtersList;
      const filtersAreLoaded = state.backoffice.common.filtersListIsLoaded;
      return { languages, currentLang, filters, filtersAreLoaded };
    },
    {
      getFiltersList: () => getFiltersList.request(),
    }
  ),
  reduxForm({
    form: 'StatusesForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageId);
      props.history.push('/backoffice/statuses/');
    },
  })
)(StatusesForm);
export { StatusesForm };
