import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .FullNameCell {
    width: 100%;
    height: 23px;
    display: flex;
    position: relative;
    padding-left: 4px;
    z-index: 1;

    &__FullName {
      width: 93%;
      /* text-overflow: ellipsis; */
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__ManagerIcon {
      padding-bottom: 4px;
      width: 23px;
      margin-right: 20px;
      margin-left: 13px;
      height: 23px;
    }

    &__FullName--IsManager {
      width: 80%;
    }
    &__favorite {
      position: absolute;
      right: 5px;
      top: 4px;
    }
    &__showTags {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #696969;
    }
  }
`;

export { staticStyles };
