import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentNotes {
    :global(.Collapsible) {
      background: #ffffff;
      margin-bottom: 20px;

      :global(.Collapsible__caption) {
        line-height: 32px;
        font-size: 26px;
        font-weight: 300;

        @include respond-to(sm) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      :global(.Collapsible__header) {
        padding: rem(15px 28px);
        position: relative;
      }

      :global(.Collapsible__arrow) {
        position: absolute;
        right: ${isRTL ? 'auto' : '30px'};
        left: ${isRTL ? '30px' : 'auto'};
        top: 50%;
      }

      :global(.Collapsible__arrow-right) {
        transform: ${isRTL
          ? 'translateY(-50%) rotate(135deg) !important'
          : ' translateY(-50%) rotate(-45deg) !important'};
      }

      :global(.Collapsible__arrow-down) {
        top: 40%;
        transform: rotate(45deg) translateY(-50%) !important;
      }

      .PaymentNotes__select {
        padding: 0 25px 5px 25px;
      }

      :global(.NoItemsPanel) {
        height: auto;
      }
    }
  }

  .PaymentNotes__content-inner {
  }

  .PaymentNotes__cels {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr)) minmax(100px, 0.3fr) minmax(100px, 0.2fr);
    margin: rem(10px 20px);

    @include respond-to(sm) {
      display: none;
    }

    :global(span) {
      font-size: rem(11px);
      line-height: rem(18px);
      text-transform: uppercase;
      padding: rem(0 5px);
    }
  }

  .PaymentNotes__head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 15px 10px rem(25px) 10px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .PaymentNotes__cels {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
