/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { staticStyles } from './style';

const BaseStaticInput = ({ value, onChange, onlyNumbers, intl, isDisabled }) => {
  const ref = useRef(null);

  return (
    <div ref={ref} className="BaseStaticInput">
      <button className={classNames('BaseStaticInput__OpenOptionsButton')} type="button">
        <input
          disabled={isDisabled}
          value={value}
          type={onlyNumbers ? 'number' : 'text'}
          placeholder={intl.formatMessage({ id: 'justAll' })}
          onChange={e => {
            onChange(e.target.value);
          }}
          className={classNames('BaseStaticInput__Input', { 'BaseStaticInput__Input--disabled': isDisabled })}
        />
      </button>

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

BaseStaticInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  onlyNumbers: PropTypes.bool,
  value: PropTypes.string,
};

BaseStaticInput.defaultProps = {
  value: '',
  onlyNumbers: false,
};

export default injectIntl(BaseStaticInput);
