import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_AUTH_BANNERS = createRequestTypes('GET_AUTH_BANNERS');
export const getAuthBanners = {
  request: () => action(GET_AUTH_BANNERS[REQUEST]),
  success: data => action(GET_AUTH_BANNERS[SUCCESS], { data }),
  failure: error => action(GET_AUTH_BANNERS[FAILURE], { error }),
};

export const DELETE_AUTH_BANNER = createRequestTypes('DELETE_AUTH_BANNER');
export const deleteAuthBanner = {
  request: data => action(DELETE_AUTH_BANNER[REQUEST], data),
  success: () => action(DELETE_AUTH_BANNER[SUCCESS]),
  failure: error => action(DELETE_AUTH_BANNER[FAILURE], { error }),
};

export const createAuthBanner = createFormAction('CREATE_AUTH_BANNER');

export const editAuthBanner = createFormAction('EDIT_AUTH_BANNER');

export const UPDATE_BANNERS_POSITIONS = createRequestTypes('UPDATE_BANNERS_POSITIONS');
export const updateBannersPositions = {
  request: data => action(UPDATE_BANNERS_POSITIONS[REQUEST], data),
  success: data => action(UPDATE_BANNERS_POSITIONS[SUCCESS], { data }),
  failure: error => action(UPDATE_BANNERS_POSITIONS[FAILURE], { error }),
};
