import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TaskItem {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    margin-bottom: 2px;

    &__icon {
      padding: 5px 20px 5px 5px;
    }

    &__text {
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .TaskItem {
      background: ${colors.white.hex};

      &__text {
        color: ${colors.gray.hex};
      }

      &:hover {
        background: ${rgba([colors.gradientEnd.rgb, 0.35])} !important;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
