import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BaseTable } from 'common/components';

import { staticStyles } from './style';

const rewardTypes = {
  percentage: {
    slug: 'percentageForCommission',
    symbol: '%',
  },
  spread: {
    slug: 'percentageForSpread',
    symbol: '%',
  },
  fix: {
    slug: 'justFixed',
    symbol: 'USD',
  },
};

const IbProgramExceptionsTable = ({ data }) => {
  /* eslint-disable */
  const columns = [
    {
      key: 'justName',
      Cell: ({ original: { name } }) => <span className="IbProgramExceptionsTable__cell">{name}</span>,
    },
    {
      key: 'justReward',
      Cell: ({ original: { value, reward_type } }) => (
        <span className="IbProgramExceptionsTable__cell">
          {value} {rewardTypes[reward_type].symbol}
        </span>
      ),
    },
    {
      key: 'rewardType',
      Cell: ({ original: { reward_type } }) => (
        <FormattedMessage id={rewardTypes[reward_type].slug}>
          {txt => (
            <span className="IbProgramExceptionsTable__cell IbProgramExceptionsTable__cell--rewardType">
              <p>{txt}</p>
            </span>
          )}
        </FormattedMessage>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="IbProgramExceptionsTable">
      <BaseTable columns={columns} data={data} />

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

IbProgramExceptionsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default IbProgramExceptionsTable;
export { IbProgramExceptionsTable };
