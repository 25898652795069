import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserPartnerAccountsTable__noAccount {
    :global(.UserNoInfoPanel) {
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
      padding: rem(35px 30px 25px);
      @include respond-to(sm) {
        padding: rem(30px 15px 15px);
      }
      h2 {
        margin-bottom: rem(25px);
      }
    }
  }

  .UserPartnerAccountsTable__body {
    :global(.ReactTable) {
      :global(.rt-thead.-header) {
        min-width: 600px !important;
        :global(.rt-tr) {
          @include respond-to(md) {
            margin: 0 5px;
          }

          :global(.rt-th) {
            flex: 60 !important;
            &:first-child {
              flex: 100 !important;
            }
            &:last-child {
              flex: 10 !important;
            }
          }
        }
      }
      :global(.rt-tbody) {
        min-width: 600px !important;
        :global(.rt-tr-group) {
          margin: 0 0 2px 0;
          cursor: default;
          box-shadow: none;
          &:hover {
            box-shadow: none;
          }
          :global(.rt-tr) {
            padding: rem(10px 18px);
            @include respond-to(lg) {
              padding: rem(10px);
            }

            :global(.rt-td) {
              flex: 60 !important;

              &:first-child {
                flex: 100 !important;
              }

              &:last-child {
                flex: 10 !important;
                @include respond-to(lg) {
                  padding-right: rem(10px);
                }
              }
            }
          }
        }
      }
    }
  }

  .UserPartnerAccountsTable__small {
    .UserPartnerAccountsTable__body {
      :global(.ReactTable) {
        :global(.rt-thead.-header) {
          min-width: 350px !important;
        }
        :global(.rt-tbody) {
          min-width: 350px !important;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserPartnerAccountsTable__noAccount {
      :global(.UserNoInfoPanel) {
        background-color: ${colors.white.hex};
      }
    }

    :global(.UserPartnerAccountEditForm__description) {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
