import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.FieldWithButton) {
    display: flex;
    align-items: center;
    gap: 10px;

    color: #0aa1e2;

    &:hover {
      color: #0990cb;
    }

    &:hover :global(.FieldWithButton__iconWrapper) {
      background: #0990cb;
    }
  }

  :global(.FieldWithButton__iconWrapper) {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0aa1e2;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
`;

export { staticStyles };
