import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CurrentIBProgramsItem {
    padding: 20px 0;
    margin-bottom: rem(25px);
    @include respond-to(sm) {
      padding: 15px 10px;
    }
  }

  .CurrentIBProgramsItem__partnerCode {
    margin-bottom: rem(15px);
    font-size: rem(16px);
    span {
      font-size: rem(20px);
      display: inline-block;
      margin-left: rem(5px);
    }
  }

  .CurrentIBProgramsItem__sub-caption {
    margin: rem(25px 0 10px);
  }

  .CurrentIBProgramsItem__values-list {
    width: 100%;
    li {
      display: flex;
      align-items: center;
      margin-bottom: rem(20px);
      :global(span) {
        flex: 1;
        transition: all 0.3s ease;
        @include respond-to(lg) {
          flex: 2;
        }
      }
      p {
        font-size: rem(16px);
        flex: 5;
        margin-left: rem(15px);
        @include respond-to(lg) {
          flex: 3;
        }
        span {
          padding: rem(15px);
          transition: all 0.3s ease;
          width: 40%;
          @include respond-to(lg) {
            width: 70%;
          }
          @include respond-to(xs) {
            width: 100%;
          }
        }

        :global(.ActionsButton) {
          margin-left: rem(15px);
        }
      }
    }
  }

  .CurrentIBProgramsItem__exceptions {
    margin: rem(15px 0);
  }
  .CurrentIBProgramsItem__exceptions-caption {
    margin-bottom: rem(20px);
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .CurrentIBProgramsItem__values-list {
      li {
        :global(span) {
          color: ${colors.gray.hex};
        }
        p {
          span {
            background: ${rgba([colors.lightGray.rgb, 0.1])};
            border-radius: ${border.radius};
            border: 1px solid ${colors.lightGray.hex};
            color: ${colors.black.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
