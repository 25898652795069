import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TemplatesTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(tr) {
          grid-template-columns: minmax(200px, 1fr) minmax(300px, 5fr) minmax(100px, 1fr) minmax(80px, 0.5fr);
        }
        :global(.ReactDataTable__tbody-row) {
          box-shadow: none;
          cursor: default;
        }
      }
    }
  }
`;

export { staticStyles };
