import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector, change, untouch } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { numericality, required } from 'redux-form-validators';
import { BasicModal, DefaultButton, PageContentPreloader } from 'common/components';
import { ReduxSelectField, ReduxTextField } from 'common/components/form-fields';
import { withTheme } from 'common/styling/theme';
import { FormMessageHOC } from 'common/HOCs';
import { getTradersTransferInfo, getInnerTransferOptions, createTradersTransfer } from '../../_redux';
import InternalTransferConfirmation from '../InternalTransferConfirmation';

import { staticStyles, getDynamicStyles } from './style';

const selector = formValueSelector('traders-transfer-form');
const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

const ExternalTransfer = ({
  formValues,
  innerTransferOptions,
  tradersTransferInfo,
  innerTransferOptionsAreLoaded,
  tradersTransferInfoIsLoaded,
  getInnerTransferOptions,
  getTradersTransferInfo,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  transfersError,
  dispatch,
  error,
  theme,
  intl,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReceiveAmountNegative, setIsReceiveAmountNegative] = useState(false);
  const [sendCurrency, setSendCurrency] = useState('');
  const submit = handleSubmit(values => createTradersTransfer(values, dispatch));

  useEffect(() => {
    getInnerTransferOptions();
  }, [getInnerTransferOptions]);

  const toggleTransferConfirmationModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getExternalTransferCommission = (...values) => {
    const { senderAccount, recipientAccount, sendAmount } = formValues;
    if (values[3] === 'senderAccount' && values[0] !== recipientAccount && recipientAccount) {
      getTradersTransferInfo({
        senderAccount: values[0],
        recipientAccount,
      });
      setSendCurrency(innerTransferOptions.find(item => item.id === values[0]).balance?.currency ?? 'USD');
    } else if (values[3] === 'recipientAccount' && values[1] && values[1] !== senderAccount && senderAccount) {
      getTradersTransferInfo({
        recipientAccount: values[1],
        senderAccount,
      });
      setSendCurrency(innerTransferOptions.find(item => item.id === senderAccount).balance?.currency ?? 'USD');
    }
    if (sendAmount) {
      dispatch(change('traders-transfer-form', 'sendAmount', null));
      dispatch(untouch('traders-transfer-form', 'sendAmount'));
      dispatch(change('traders-transfer-form', 'receiveAmount', null));
    }
  };
  const calculateReceiveAmount = (_, value) => {
    const sendAmount = parseFloat(value);
    if (
      typeof sendAmount === 'number' &&
      sendAmount <= tradersTransferInfo.availableAmount &&
      tradersTransferInfo.details
    ) {
      const { exchangeCoefficient = 1, commission } = tradersTransferInfo.details;
      let calculatedCommission =
        (sendAmount / 100) * commission.percentage +
        commission.fix * tradersTransferInfo.details.exchangeCoefficientForFix;
      if (calculatedCommission > tradersTransferInfo.details.maxCommission.amount)
        calculatedCommission = tradersTransferInfo.details.maxCommission.amount;
      if (calculatedCommission < tradersTransferInfo.details.minCommission.amount)
        calculatedCommission = tradersTransferInfo.details.minCommission.amount;
      const receiveAmount = (sendAmount - calculatedCommission) * exchangeCoefficient;
      let roundedReceiveAmount = 0;
      if (receiveAmount > 0) {
        roundedReceiveAmount = getFixedNumber(receiveAmount, 2);
        setIsReceiveAmountNegative(false);
      } else {
        setIsReceiveAmountNegative(true);
      }
      dispatch(change('traders-transfer-form', 'receiveAmount', roundedReceiveAmount.toString()));
    }
  };

  const accounts = innerTransferOptions.map(item => ({
    label: `${item.accountType} ${item.login} ${
      item.balance ? ` - ${item.balance.amount}${item.balance.currency}` : ''
    }`,
    value: item.id,
  }));

  const getAccountInfo = account => innerTransferOptions.find(item => item.id === account);

  return (
    <PageContentPreloader ready={innerTransferOptionsAreLoaded} type="bigLine">
      <form className="ExternalTransfer" onSubmit={submit}>
        <div className="ExternalTransfer__line">
          <Field
            component={ReduxSelectField}
            name="senderAccount"
            textId="clientAccountSender"
            options={accounts}
            onChange={getExternalTransferCommission}
            disabled={submitting}
            validate={[required()]}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="recipientAccount"
            textId="clientAccountRecipient"
            validate={[required()]}
            disabled={submitting}
            onBlur={getExternalTransferCommission}
          />
        </div>
        <div className="ExternalTransfer__line">
          <Field
            component={ReduxTextField}
            type="text"
            name="sendAmount"
            textId="clientSendAmount"
            validate={[
              required(),
              numericality({
                msg: tradersTransferInfo.requestedAmount
                  ? {
                      '<=': intl.formatMessage(
                        { id: 'availableAmountForTransfer' },
                        {
                          available: tradersTransferInfo.availableAmount,
                          requested: tradersTransferInfo.requestedAmount,
                        }
                      ),
                    }
                  : {},
                '>=': 0,
                '<=': tradersTransferInfo.availableAmount ?? 10000,
              }),
            ]}
            disabled={submitting || !tradersTransferInfoIsLoaded}
            onChange={calculateReceiveAmount}
            description={sendCurrency}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="receiveAmount"
            textId="justWillReceive"
            validate={[required()]}
            disabled
          />
        </div>
        {transfersError && <span className="ExternalTransfer__error">{transfersError}</span>}
        {tradersTransferInfo.details && (
          <div className="ExternalTransfer__description">
            <FormattedMessage
              id={
                tradersTransferInfo.details.exchangeRate
                  ? 'commissionForInternalTransferDifferentCurrencies'
                  : 'commissionForInternalTransferSameCurrencies'
              }
              values={{
                commission: `${tradersTransferInfo.details.commission.percentage}% + ${tradersTransferInfo.details.commission.fix} USD.`,
              }}>
              {txt => <span>{txt}</span>}
            </FormattedMessage>
            {tradersTransferInfo.details.exchangeRate && <span>{tradersTransferInfo.details.exchangeRate}</span>}
            {tradersTransferInfo.details.minCommission && tradersTransferInfo.details.maxCommission && (
              <FormattedMessage
                id="commissionLimitDescription"
                values={{
                  min: `${tradersTransferInfo.details.minCommission.amount} ${tradersTransferInfo.details.minCommission.currency}`,
                  max: `${tradersTransferInfo.details.maxCommission.amount} ${tradersTransferInfo.details.maxCommission.currency}`,
                }}>
                {txt => <span>{txt}</span>}
              </FormattedMessage>
            )}
          </div>
        )}

        <DefaultButton
          textId="clientSend"
          onClick={toggleTransferConfirmationModal}
          type="button"
          disabled={isReceiveAmountNegative || invalid || pristine || submitting || !tradersTransferInfoIsLoaded}
          filled
        />
      </form>

      <BasicModal
        isOpen={isModalOpen}
        captionId="internalTransferConfirmation"
        onRequestClose={toggleTransferConfirmationModal}>
        <InternalTransferConfirmation
          payload={{
            senderAccount: getAccountInfo(formValues.senderAccount),
            recipientAccount: formValues.recipientAccount,
            sendAmount: `${formValues.sendAmount} ${sendCurrency}`,
            receiveAmount: formValues.receiveAmount,
          }}
          submitting={submitting}
          handleSubmit={submit}
          handleCancel={toggleTransferConfirmationModal}
          error={error}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageContentPreloader>
  );
};

ExternalTransfer.propTypes = {
  formValues: PropTypes.object.isRequired,
  tradersTransferInfo: PropTypes.object.isRequired,
  innerTransferOptions: PropTypes.array.isRequired,
  tradersTransferInfoIsLoaded: PropTypes.bool.isRequired,
  innerTransferOptionsAreLoaded: PropTypes.bool.isRequired,
  transfersError: PropTypes.string.isRequired,
  getInnerTransferOptions: PropTypes.func.isRequired,
  getTradersTransferInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

ExternalTransfer.defaultProps = {
  error: '',
  theme: '',
};

export default compose(
  withTheme(),
  withRouter,
  injectIntl,
  FormMessageHOC,
  connect(
    state => ({
      formValues: selector(state, 'senderAccount', 'recipientAccount', 'sendAmount', 'receiveAmount'),
      innerTransferOptions: state.client.transfers.innerTransferOptions,
      innerTransferOptionsAreLoaded: state.client.transfers.innerTransferOptionsAreLoaded,
      tradersTransferInfo: state.client.transfers.tradersTransferInfo,
      tradersTransferInfoIsLoaded: state.client.transfers.tradersTransferInfoIsLoaded,
      transfersError: state.client.transfers.error,
    }),
    {
      getInnerTransferOptions: () => getInnerTransferOptions.request(),
      getTradersTransferInfo: data => getTradersTransferInfo.request(data),
    }
  ),
  reduxForm({
    form: 'traders-transfer-form',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageTextId);
      props.history.push('/payments?tab=transfers');
    },
  })
)(ExternalTransfer);
export { ExternalTransfer };
