import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ModalHOC } from 'common';
import { PageLayout, PageContentPreloader, DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { getBONews, publishNews, unPublishNews } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    props.getBONews(props.match.params.id);
  }

  render() {
    const { match, news, newsIsLoaded, publishNews, unPublishNews, history, showModal, theme } = this.props;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    const editNewsClick = () => history.push(`/backoffice/news/${news.id}/edit`);

    const publishNewsClick = (id, isPublished) => {
      showModal({
        caption: { id: isPublished ? 'confirmUnPublishNews' : 'confirmPublishNews' },
        content: {
          id: 'confirmText',
        },
        actionButton: {
          textId: 'justYes',
          handler: () => (isPublished ? unPublishNews(id) : publishNews(id)),
          type: isPublished ? 'error' : 'success',
        },
        cancelButton: {
          textId: 'justNo',
        },
      });
    };

    return (
      <PageLayout captionSlug="justNews" extraCaptionText={match.params.id}>
        <PageContentPreloader ready={newsIsLoaded} type="bigLine">
          <div className="NewsPage__action-buttons">
            <DefaultButton
              textId={news.isPublished ? 'justUnpublish' : 'justPublish'}
              butColor={news.isPublished ? 'error' : 'success'}
              onClick={() => publishNewsClick(news.id, news.isPublished)}
            />
            <DefaultButton textId="justEdit" butColor="warning" onClick={() => editNewsClick()} />
          </div>
          <h3 className="NewsPage__caption">{news.title}</h3>
          <div className="NewsPage__body" dangerouslySetInnerHTML={{ __html: `${news.body}` }} />
        </PageContentPreloader>
        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </PageLayout>
    );
  }
}

NewsPage.propTypes = {
  getBONews: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  news: PropTypes.object.isRequired,
  newsIsLoaded: PropTypes.bool.isRequired,
  publishNews: PropTypes.func.isRequired,
  unPublishNews: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

NewsPage.defaultProps = {
  theme: {},
};

export default connect(
  state => ({
    news: state.backoffice.news.news,
    newsIsLoaded: state.backoffice.news.newsIsLoaded,
  }),
  {
    getBONews: id => getBONews.request(id),
    publishNews: id => publishNews.request(id),
    unPublishNews: id => unPublishNews.request(id),
  }
)(withTheme()(ModalHOC(NewsPage)));
export { NewsPage };
