import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { staticStyles } from './style';

const Banners = ({ banners }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    draggable: false,
  };

  let slider = {};
  const mouseWheel = e => {
    e.preventDefault();
    if (e.deltaY < 0) {
      slider.slickPrev();
    } else {
      slider.slickNext();
    }
  };

  return (
    <div className="Banners">
      {/* eslint-disable */}
      <Slider ref={c => (slider = c)} className="Banners__slider" {...settings}>
        {!!banners.length &&
          banners.map((item, index) => (
            <a
              href={item.link}
              rel="noopener noreferrer"
              className="Banners__item"
              target="_blank"
              key={index}
              onWheel={e => mouseWheel(e)}>
              <span className="Banners__item-image" style={{ backgroundImage: `url('${item.imageLink}')` }} />
            </a>
          ))}
      </Slider>
      {/* eslint-enable */}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

Banners.propTypes = {
  banners: PropTypes.array,
};

Banners.defaultProps = {
  banners: [],
};

export default connect(state => ({
  banners: state.interfaceConfig.banners,
}))(Banners);
export { Banners };
