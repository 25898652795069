import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { useSelector } from 'react-redux';

import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import TextFormField from 'crm/_components/forms/fields/TextFormField';

import { staticStyles } from './style';

const FilterCreate = ({
  modifiedFiltersSelector,
  createCustomFilterSubmitFunc,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const [value, setValue] = useState('');
  const modifiedFilters = useSelector(modifiedFiltersSelector);
  const filterValues = {};
  const submit = handleSubmit(({ title }) =>
    createCustomFilterSubmitFunc({ query: { ...filterValues }, title }, dispatch)
  );

  Object.keys(modifiedFilters).forEach(filterName => {
    const currentFilter = modifiedFilters[filterName];

    if (currentFilter.inputType === 'inputDate') {
      let selectedValues = null;
      currentFilter.choiceValues.forEach(({ value, label, isSelected }) => {
        if (isSelected && label !== 'justAll') {
          selectedValues = value.map(val => moment(val).format('YYYY-MM-DD'));
        }
      });

      if (selectedValues) {
        filterValues[filterName] = selectedValues;
      }
    }

    if (currentFilter.inputType === 'inputIntegerChoice' || currentFilter.inputType === 'inputStringChoice') {
      let selectedValues = null;

      currentFilter.choiceValues.forEach(({ value, isSelected }) => {
        if (isSelected && value !== 'All') {
          if (!selectedValues) {
            selectedValues = [];
          }
          selectedValues.push(value);
        }
      });

      if (selectedValues) {
        filterValues[filterName] = selectedValues;
      }
    }

    if (currentFilter.inputType === 'inputBoolean') {
      let selectedValue = null;

      currentFilter.choiceValues.forEach(({ value, isSelected }) => {
        if (isSelected && value !== 'All') {
          if (value === 'True') selectedValue = true;
          else selectedValue = false;
        }
      });

      if (selectedValue !== null) {
        filterValues[filterName] = selectedValue;
      }
    }

    if (currentFilter.inputType === 'inputStr') {
      const selectedValue = currentFilter.value;

      if (selectedValue) {
        filterValues[filterName] = selectedValue;
      }
    }
  });

  return (
    <div className="FilterCreate__Wrapper">
      <form className="FilterCreate" onSubmit={submit}>
        <Field
          name="title"
          component={TextFormField}
          labelTextId="crmFilterName"
          placeholderId="saveAs"
          required={required()}
          onChange={value => setValue(value)}
          value={value}
        />

        <BaseButton textId="justCreate" maxContent type="submit" disabled={pristine || invalid || submitting} />
        {error && <span>{error}</span>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

FilterCreate.propTypes = {
  modifiedFiltersSelector: PropTypes.func.isRequired,
  createCustomFilterSubmitFunc: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

FilterCreate.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-create-crm-filter',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmFilterWasCreated');
      props.onRequestClose();
    },
  })
)(FilterCreate);
