import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

class LightBoxGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { browser: false, sliderWidth: window.innerWidth };
  }

  componentDidMount() {
    this.setState({ browser: true });

    const keyDown = e => {
      if (this.props.children.length > 1) {
        if (e.keyCode === 37) {
          this.slider.slickPrev();
        } else if (e.keyCode === 39) {
          this.slider.slickNext();
        }
      }
    };

    document.onkeydown = keyDown;
  }

  componentWillUnmount() {
    this.setState({ browser: false });

    const keyDown = e => {
      if (e.keyCode === 37) {
        return window.event;
      }
      if (e.keyCode === 39) {
        return window.event;
      }
    };

    document.onkeydown = keyDown;
  }

  render() {
    const { children, className, infinite, theme, ...settings } = this.props;
    const { browser } = this.state;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    const baseSettings = {
      className,
      dots: false,
      infinite: infinite && browser,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const currentSettings = { ...baseSettings, ...settings };

    window.onresize = () => {
      this.setState({ sliderWidth: window.innerWidth });
    };

    return (
      <>
        {/* eslint-disable */}
        <Slider ref={c => (this.slider = c)} {...currentSettings}>
          {browser ? children : React.Children.toArray(children).slice(0, currentSettings.slidesToShow)}
        </Slider>
        {/* eslint-enable */}

        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
        {/* eslint-disable */}
        <style jsx global>{`
          .Slider {
            width: ${this.state.sliderWidth}px !important;
          }
        `}</style>
        {/* eslint-enable */}
      </>
    );
  }
}

LightBoxGallery.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  infinite: PropTypes.bool,
  theme: PropTypes.object,
};

LightBoxGallery.defaultProps = {
  className: 'Slider',
  infinite: false,
  theme: {},
};

export default withTheme()(LightBoxGallery);
export { LightBoxGallery };
