import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FormMessageHOC } from 'common/HOCs';
import { DefaultButton, ReduxSelectField } from 'common/components';

import { boGetUserAccounts } from 'backoffice/invest/_redux';

import { boToggleCRMInvestment } from '../../_redux';
import { staticStyles } from './style';

const InvestCRMWithdrawlForm = ({
  isWalletAccountAddonEnabled,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  userId,
  strategyId,
  search,
  investmentId,
  toggleInvestment,
  getUserAccounts,
  userAccounts,
  userAccountsAreLoaded,
  error,
}) => {
  useEffect(() => {
    getUserAccounts(userId);
  }, []);

  const submit = handleSubmit(({ account }) => toggleInvestment(investmentId, 'close', strategyId, search, account));

  return (
    <form className="CloseStrategyWithdrawalForm" onSubmit={submit}>
      <FormattedMessage
        id={isWalletAccountAddonEnabled ? 'boCloseInvestmentWithWalletAddon' : 'boSelectAccountForWithdrawl'}>
        {txt => <p className="CloseStrategyWithdrawalForm__description">{txt}</p>}
      </FormattedMessage>
      {!isWalletAccountAddonEnabled && (
        <Field
          component={ReduxSelectField}
          name="account"
          textId="clientAccount"
          options={userAccounts.map(account => ({
            value: account.id,
            label: account.login,
          }))}
          disabled={!userAccountsAreLoaded || submitting}
          maxMenuHeight={90}
        />
      )}
      <DefaultButton
        textId="justTransfer"
        type="submit"
        loading={submitting}
        disabled={invalid || (!isWalletAccountAddonEnabled && pristine) || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

InvestCRMWithdrawlForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  search: PropTypes.object.isRequired,
  strategyId: PropTypes.string.isRequired,
  investmentId: PropTypes.number.isRequired,
  toggleInvestment: PropTypes.func.isRequired,
  error: PropTypes.string,
  isWalletAccountAddonEnabled: PropTypes.bool.isRequired,
  userAccounts: PropTypes.array.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  userAccountsAreLoaded: PropTypes.bool.isRequired,
};

InvestCRMWithdrawlForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(
    state => ({
      isWalletAccountAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'walletAccount'),
      userAccounts: state.backoffice.invest.userAccounts,
      userAccountsAreLoaded: state.backoffice.invest.userAccountsAreLoaded,
    }),
    {
      toggleInvestment: (investmentId, investmentAction, strategyId, search, investorAccountId, toWallet) =>
        boToggleCRMInvestment.request(investmentId, investmentAction, strategyId, search, investorAccountId, toWallet),
      getUserAccounts: userId => boGetUserAccounts.request(userId),
    }
  ),
  reduxForm({
    form: 'close-investment-in-strategy-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleWithdrawalModal();
    },
  })
)(InvestCRMWithdrawlForm);
export { InvestCRMWithdrawlForm };
