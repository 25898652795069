import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const REQUEST_PARTNERSHIP = createRequestTypes('REQUEST_PARTNERSHIP');
export const requestPartnership = {
  request: () => action(REQUEST_PARTNERSHIP[REQUEST]),
  success: () => action(REQUEST_PARTNERSHIP[SUCCESS]),
  failure: error => action(REQUEST_PARTNERSHIP[FAILURE], { error }),
};

export const GET_PARTNERSHIP_INFO = createRequestTypes('GET_PARTNERSHIP_INFO');
export const getPartnershipInfo = {
  request: () => action(GET_PARTNERSHIP_INFO[REQUEST]),
  success: data => action(GET_PARTNERSHIP_INFO[SUCCESS], { data }),
  failure: error => action(GET_PARTNERSHIP_INFO[FAILURE], { error }),
};

export const GET_PARTNERSHIP_CLIENTS_BALANCE = createRequestTypes('GET_PARTNERSHIP_CLIENTS_BALANCE');
export const getPartnershipClientsBalance = {
  request: () => action(GET_PARTNERSHIP_CLIENTS_BALANCE[REQUEST]),
  success: data => action(GET_PARTNERSHIP_CLIENTS_BALANCE[SUCCESS], { data }),
  failure: error => action(GET_PARTNERSHIP_CLIENTS_BALANCE[FAILURE], { error }),
};

export const GET_PARTNERSHIP_CLIENTS = createRequestTypes('GET_PARTNERSHIP_CLIENTS');
export const getPartnershipClients = {
  request: data => action(GET_PARTNERSHIP_CLIENTS[REQUEST], { data }),
  success: data => action(GET_PARTNERSHIP_CLIENTS[SUCCESS], { data }),
  failure: error => action(GET_PARTNERSHIP_CLIENTS[FAILURE], { error }),
};

export const GET_PARTNERSHIP_CLIENT_ACCOUNTS = createRequestTypes('GET_PARTNERSHIP_CLIENT_ACCOUNTS');
export const getPartnershipClientAccounts = {
  request: (clientId, full = false) => action(GET_PARTNERSHIP_CLIENT_ACCOUNTS[REQUEST], { clientId, full }),
  success: accounts => action(GET_PARTNERSHIP_CLIENT_ACCOUNTS[SUCCESS], { accounts }),
  failure: error => action(GET_PARTNERSHIP_CLIENT_ACCOUNTS[FAILURE], { error }),
};

export const GET_PARTNERSHIP_CLIENT_ACCOUNT = createRequestTypes('GET_PARTNERSHIP_CLIENT_ACCOUNT');
export const getPartnershipClientAccount = {
  request: (clientId, accountId) => action(GET_PARTNERSHIP_CLIENT_ACCOUNT[REQUEST], { clientId, accountId }),
  success: data => action(GET_PARTNERSHIP_CLIENT_ACCOUNT[SUCCESS], { data }),
  failure: (accountId, error) => action(GET_PARTNERSHIP_CLIENT_ACCOUNT[FAILURE], { accountId, error }),
};

export const GET_PARTNERSHIP_CLIENT_TRADES = createRequestTypes('GET_PARTNERSHIP_CLIENT_TRADES');
export const getPartnershipClientTrades = {
  request: (clientId, accountId, status, startDate, endDate) =>
    action(GET_PARTNERSHIP_CLIENT_TRADES[REQUEST], {
      clientId,
      accountId,
      status,
      startDate,
      endDate,
    }),
  success: (accountId, status, data) => action(GET_PARTNERSHIP_CLIENT_TRADES[SUCCESS], { accountId, status, data }),
  failure: (accountId, error) => action(GET_PARTNERSHIP_CLIENT_TRADES[FAILURE], { accountId, error }),
};

export const GET_FULL_PARTNERSHIP_CLIENT_ACCOUNT = createRequestTypes('GET_FULL_PARTNERSHIP_CLIENT_ACCOUNT');
export const getFullPartnershipClientAccount = {
  request: (clientId, accountId, operationStatus, startDate, endDate) =>
    action(GET_FULL_PARTNERSHIP_CLIENT_ACCOUNT[REQUEST], {
      clientId,
      accountId,
      operationStatus,
      startDate,
      endDate,
    }),
  success: (clientId, accountId, data) =>
    action(GET_FULL_PARTNERSHIP_CLIENT_ACCOUNT[SUCCESS], {
      clientId,
      accountId,
      data,
    }),
  failure: (accountId, error) => action(GET_FULL_PARTNERSHIP_CLIENT_ACCOUNT[FAILURE], { accountId, error }),
};

export const GET_CURRENT_IB_PROGRAMS = createRequestTypes('GET_CURRENT_IB_PROGRAMS');
export const getCurrentIBPrograms = {
  request: () => action(GET_CURRENT_IB_PROGRAMS[REQUEST]),
  success: data => action(GET_CURRENT_IB_PROGRAMS[SUCCESS], { data }),
  failure: error => action(GET_CURRENT_IB_PROGRAMS[FAILURE], { error }),
};

export const GET_SIMPLE_IB_SYSTEM_SETTINGS = createRequestTypes('GET_SIMPLE_IB_SYSTEM_SETTINGS');
export const getSimpleIBSystemSettings = {
  request: () => action(GET_SIMPLE_IB_SYSTEM_SETTINGS[REQUEST]),
  success: data => action(GET_SIMPLE_IB_SYSTEM_SETTINGS[SUCCESS], { data }),
  failure: error => action(GET_SIMPLE_IB_SYSTEM_SETTINGS[FAILURE], { error }),
};

export const sendSimpleIBSystemSettings = createFormAction('SEND_SIMPLE_IP_SYSTEM_SETTINGS');

export const GET_PARTNERSHIP_MATERIALS = createRequestTypes('GET_PARTNERSHIP_MATERIALS');
export const getPartnershipMaterials = {
  request: data => action(GET_PARTNERSHIP_MATERIALS[REQUEST], data),
  success: data => action(GET_PARTNERSHIP_MATERIALS[SUCCESS], { data }),
  failure: error => action(GET_PARTNERSHIP_MATERIALS[FAILURE], { error }),
};

export const GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS = createRequestTypes('GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS');
export const getPartnershipWithdrawalAccounts = {
  request: data => action(GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[REQUEST], data),
  success: data => action(GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[FAILURE], { error }),
};

export const withdrawPartnershipAccount = createFormAction('WITHDRAW_PARTNERSHIP_ACCOUNT');

export const changeRebate = createFormAction('CHANGE_REBATE');

export const GET_CAMPAIGNS = createRequestTypes('GET_CAMPAIGNS');
export const getCampaigns = {
  request: () => action(GET_CAMPAIGNS[REQUEST]),
  success: data => action(GET_CAMPAIGNS[SUCCESS], { data }),
  failure: error => action(GET_CAMPAIGNS[FAILURE], { error }),
};

export const createBanners = createFormAction('CREATE_BANNERS');

export const GET_ROTATOR_LINK = createRequestTypes('GET_ROTATOR_LINK');
export const getRotatorLink = {
  request: () => action(GET_ROTATOR_LINK[REQUEST]),
  success: data => action(GET_ROTATOR_LINK[SUCCESS], { data }),
  failure: error => action(GET_ROTATOR_LINK[FAILURE], { error }),
};

export const GET_BANNER_LINK = createRequestTypes('GET_BANNER_LINK');
export const getBannerLink = {
  request: () => action(GET_BANNER_LINK[REQUEST]),
  success: data => action(GET_BANNER_LINK[SUCCESS], { data }),
  failure: error => action(GET_BANNER_LINK[FAILURE], { error }),
};

export const REQUEST_EASY_START = createRequestTypes('REQUEST_EASY_START');
export const requestEasyStart = {
  request: () => action(REQUEST_EASY_START[REQUEST]),
  success: () => action(REQUEST_EASY_START[SUCCESS]),
  failure: error => action(REQUEST_EASY_START[FAILURE], { error }),
};
