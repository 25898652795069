import css from 'styled-jsx/css';

import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/screen-size';

  .TransfersTable {
    .ReactTable {
      .rt-thead {
        min-width: 850px !important;
        .rt-tr {
          .rt-th {
            &:first-child {
              flex: 35 0 auto !important;
              width: 35px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              flex: 120 0 auto !important;
              width: 120px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(4) {
              flex: 150 0 auto !important;
              width: 190px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(5) {
              flex: 90 0 auto !important;
              width: 90px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(6) {
              flex: 90 0 auto !important;
              width: 90px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(7) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:last-child {
              flex: 70 0 auto;
              width: 100px;
            }
          }
        }
      }
      .rt-tbody {
        min-width: 850px !important;
        .rt-tr-group {
          .rt-tr {
            .rt-td {
              span {
                font-size: rem(14px);
              }
              &:first-child {
                flex: 35 0 auto !important;
                width: 35px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(2),
              &:nth-child(3) {
                flex: 120 0 auto !important;
                width: 120px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                  position: relative;
                  &:hover {
                    &:after {
                      width: 0;
                    }
                  }
                  &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: all 0.4s ease;
                  }
                  &:hover {
                    border-color: transparent;
                  }
                }
              }
              &:nth-child(4) {
                flex: 150 0 auto !important;
                width: 190px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(12px);
                }
              }
              &:nth-child(5) {
                flex: 90 0 auto !important;
                width: 90px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                }
              }
              &:nth-child(6) {
                flex: 90 0 auto !important;
                width: 90px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                div {
                  white-space: normal;
                }
              }
              &:nth-child(7) {
                flex: 80 0 auto !important;
                width: 80px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  white-space: normal;
                }
              }
              &:last-child {
                flex: 70 0 auto;
                width: 100px;
                span {
                  font-size: rem(14px);
                  padding-left: 5px;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    width: 85px;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        &:first-child {
          flex: 35;
        }
        &:nth-child(2) {
          flex: 100;
        }
        &:nth-child(3) {
          flex: 100;
        }
        &:nth-child(4) {
          flex: 100;
        }
        &:last-child {
          padding: 0;
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;
      .ResponsiveTable__preview-main {
        height: 40px;

        .ResponsiveTable__row-item {
          flex: 60;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            flex: 35 0 auto !important;
            width: 35px !important;

            @include respond-to(xs) {
              max-width: 35px;
            }
          }

          &:nth-child(2) {
            margin: 0 auto;
            flex: 100 0 auto !important;
            width: 100px !important;

            @include respond-to(xs) {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          &:nth-child(3) {
            @include respond-to(xs) {
              max-width: 150px;
              overflow: hidden;

              .StatusCell {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          &:nth-child(4) {
            margin: 0 auto;
            flex: 100 0 auto !important;
            width: 100px !important;

            @include respond-to(xs) {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TransfersTable {
      :global(.RowLink) {
        justify-content: flex-start;
      }

      .ReactTable {
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &:nth-child(1) {
                  .TransfersTable__sender-id {
                    color: ${colors.black.hex};
                  }
                }

                &:nth-child(2) {
                  .TransfersTable__email {
                    color: ${colors.secondary.hex};
                    &:after {
                      background: ${rgba([colors.secondary.rgb, 0.6])};
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
