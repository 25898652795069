import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnershipRequestsTable {
    .ReactDataTable__wrapper .ReactDataTable tr {
      grid-template-columns:
        minmax(216px, 216fr) minmax(150px, 150fr) minmax(160px, 160fr) minmax(214px, 214fr) minmax(100px, 100fr)
        minmax(100px, 100fr) !important;
      grid-template-rows: 64px;
      &:hover {
        cursor: pointer !important;
      }
    }

    .ReactDataTable__wrapper .ReactDataTable thead tr {
      grid-template-rows: 40px !important;
    }

    &__cell {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      &--font-light {
        font-weight: 300;
      }
      &--font-medium {
        font-weight: 500;
      }

      &--wide {
        width: 100%;
      }
      &--email-cell {
        width: 100%;
        display: flex;
        gap: 6px;
        justify-content: space-between;
        > span {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
          font-size: 13px;
          line-height: 18px;
        }

        > .ActionsButton {
          margin: 0;
          cursor: copy;
        }
      }
    }

    .ReactDataTable__tbody {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .ReactDataTable__thead-cell,
    .ReactDataTable__tbody-cell {
      display: flex;
      justify-content: center;
      text-align: center !important;
    }

    .ReactDataTable__tbody-row {
      margin: 0 !important;
      cursor: auto !important;
    }

    .ReactDataTable__thead-cell:not(.ReactDataTable__thead-cell--sortable) {
      cursor: auto !important;
    }

    @include respond-to(lg) {
      .ReactDataTable__wrapper {
        overflow-x: auto;
      }
    }
  }

  .SearchPanel {
    margin-bottom: 25px !important;

    @include respond-to(sm) {
      &:first-child {
        margin-bottom: 15px !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PartnershipRequestsTable {
      &__cell--blue,
      &__cell--link {
        color: ${colors.primary.hex};
        transition: color 0.2s ease-in-out;
      }

      &__cell--link:hover {
        color: ${colors.secondary.hex};
      }
      &__cell--status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 16px;

        &.active {
          background-color: ${colors.success.hex};
        }
      }

      &__cell--verified {
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.6;
        color: ${colors.success.hex};
      }
      &__cell--not-verified {
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.6;
        color: ${colors.error.hex};
      }
    }
    .status {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: ${colors.gray.hex};
      &.active {
        background-color: ${colors.success.hex};
      }
    }
    .BasicInfoCellInner {
      width: calc(100% - 43px);
      display: flex;
      gap: 10px;
      .react-gravatar {
        border-radius: 50%;
      }
      &__UserName {
        display: flex;
        flex-direction: column;

        width: max-content;
        max-width: 100%;
        &:hover {
          text-decoration: underline;
        }
        > span {
          max-width: 100%;
          width: max-content;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: left;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          color: ${colors.secondary.hex};
        }
      }
      > .ActionsButton__watch {
        &:hover {
          svg:not(.notFillIcon) {
            rect {
              stroke: ${colors.primary.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
