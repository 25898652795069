import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const options = {
  chart: {
    fontFamily: 'ubuntu-font, sans-serif',
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      barHeight: '80%',
      distributed: true,
      horizontal: true,
      dataLabels: {
        position: 'bottom',
      },
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#fff'],
      fontSize: '13px',
    },
    formatter(val) {
      return `${val}%`;
    },
    offsetX: 0,
  },
  xaxis: {
    categories: ['Total on real accounts', 'Opened a demo accounts', 'Opened a live accounts', 'Deposited funds'],
    labels: {
      show: true,
      style: {
        colors: ['#C5C5C5'],
        fontSize: '11px',
      },
      offsetX: 0,
      offsetY: 2,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  grid: {
    show: true,
    borderColor: '#C5C5C5',
    strokeDashArray: 3,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    row: {
      colors: '#cfcfcf',
      opacity: 0.03,
    },
    column: {
      colors: '#cfcfcf',
      opacity: 0.03,
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  legend: {
    show: false,
  },
  colors: ['#33EEC7'],
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      gradientToColors: ['#43B6E8', '#43B6E8', '#43B6E8', '#43B6E8'],
      shadeIntensity: 1,
      type: 'horizontal',
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100, 100, 100],
    },
  },
  dropShadow: {
    enabled: true,
    top: 0,
    left: 0,
    blur: 0,
    opacity: 1,
  },
};

const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

const FunnelChart = ({ theme, lastFunnels, currentMonth }) => {
  const {
    users = 0,
    demo_users: demoUsers = 0,
    real_users: realUsers = 0,
    deposited_users: depositedUsers = 0,
  } = lastFunnels[currentMonth] || {};
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  let usersPercent = 0;
  let demoUsersPercent = 0;
  let realUsersPercent = 0;
  let depositedUsersPercent = 0;
  if (users > 0) {
    usersPercent = 100;
    demoUsersPercent = getFixedNumber((demoUsers / users) * 100, 2);
    realUsersPercent = getFixedNumber((realUsers / users) * 100, 2);
    depositedUsersPercent = getFixedNumber((depositedUsers / users) * 100, 2);
  }

  const series = [
    {
      data: [usersPercent, demoUsersPercent, realUsersPercent, depositedUsersPercent],
    },
  ];

  return (
    <div className="FunnelChart">
      <div className="FunnelChart__inner">
        <div className="ChartInner">
          <Chart className="FunnelChart__chart" options={options} series={series} type="bar" height="298" />
        </div>
        <div className="FunnelChart__chart-description">
          <div className="FunnelChart__text">
            <FormattedMessage id="boRegistredUsers">{txt => <p>{txt}</p>}</FormattedMessage>
            <strong id="FunnelChart__row-statistic-1">{`${users} (${usersPercent}%)`}</strong>
          </div>
          <div className="FunnelChart__text">
            <FormattedMessage id="boOpenedDemoAccount">{txt => <p>{txt}</p>}</FormattedMessage>
            <strong id="FunnelChart__row-statistic-2">{`${demoUsers} (${demoUsersPercent}%)`}</strong>
          </div>
          <div className="FunnelChart__text">
            <FormattedMessage id="boOpenedLiveAccount">{txt => <p>{txt}</p>}</FormattedMessage>
            <strong id="FunnelChart__row-statistic-3">{`${realUsers} (${realUsersPercent}%)`}</strong>
          </div>
          <div className="FunnelChart__text">
            <FormattedMessage id="boDepositedFunds">{txt => <p>{txt}</p>}</FormattedMessage>
            <strong id="FunnelChart__row-statistic-4">{`${depositedUsers} (${depositedUsersPercent}%)`}</strong>
          </div>
        </div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

FunnelChart.propTypes = {
  theme: PropTypes.object,
  lastFunnels: PropTypes.object,
};

FunnelChart.defaultProps = {
  theme: {},
  lastFunnels: {},
};

export default withTheme()(FunnelChart);
export { FunnelChart };
