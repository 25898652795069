import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DefaultButton, ReduxSelectField } from 'common/components';
import { editUserPartnerAccount } from '../../_redux';

import { staticStyles } from './style';

const UserPartnerAccountEditForm = ({
  accountId,
  rewardFrequencies,
  partnershipConditionsList,
  partnerCode,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  intl,
  error,
}) => {
  const submit = handleSubmit(values => editUserPartnerAccount({ ...values, accountId }, dispatch));

  const REWARD_FREQUENCIES = {
    dailyInstant: intl.formatMessage({ id: 'justDailyInstant' }),
    monthlyInstant: intl.formatMessage({ id: 'justMonthlyInstant' }),
    monthlyDelayed: intl.formatMessage({ id: 'justMonthlyDelayed' }),
  };

  return (
    <form className="UserPartnerAccountEditForm" onSubmit={submit}>
      <FormattedMessage id="editPartnerAccountDescription" values={{ partnerCode }}>
        {txt => <p className="UserPartnerAccountEditForm__description">{txt}</p>}
      </FormattedMessage>
      <div className="UserPartnerAccountEditForm__inputs-inner">
        <Field
          component={ReduxSelectField}
          name="conditionsId"
          maxMenuHeight={100}
          textId="rewardTemplate"
          options={partnershipConditionsList.map(cond => ({
            value: cond.id,
            label: cond.title,
          }))}
          validate={[required()]}
          disabled={submitting}
          searchable
        />
        <Field
          component={ReduxSelectField}
          name="frequencyOfPayments"
          maxMenuHeight={100}
          textId="justFrequencyOfReward"
          options={rewardFrequencies.map(freq => ({
            value: freq,
            label: REWARD_FREQUENCIES[freq] ?? '',
          }))}
          validate={[required()]}
          disabled={submitting}
        />
      </div>

      <DefaultButton
        textId="justChange"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserPartnerAccountEditForm.propTypes = {
  accountId: PropTypes.number.isRequired,
  rewardFrequencies: PropTypes.array.isRequired,
  partnershipConditionsList: PropTypes.array.isRequired,
  partnerCode: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.string,
};

UserPartnerAccountEditForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'user-partner-account-edit-form',
})(injectIntl(UserPartnerAccountEditForm));
export { UserPartnerAccountEditForm };
