import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabAccounts {
    .text-row {
      height: 77px !important;
      margin-bottom: 25px;
    }
    &__list {
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 0 24px 0;
      padding: 0 0 24px 0;

      &:not(&:last-child)::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, #f3f3f3 48.44%, rgba(243, 243, 243, 0) 100%);
      }

      &:last-child {
        margin: 0 0 0 0;
        padding: 0;
      }
    }

    &__item-icon {
      position: relative;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 16px 0 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: #333;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid #333;

      transform: translateY(-5px);

      &--wallet {
        border-color: #0aa1e2;
        color: #0aa1e2;
      }
    }

    &__item-info {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include respond-to(xs) {
        flex-direction: column;
      }
    }

    &__item-info-subwrap {
      @include respond-to(xs) {
        order: 2;
      }
    }

    &__item-info-title {
      margin-bottom: 8px;
      color: #333;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
    }

    &__item-info-balance {
      display: flex;
      align-items: center;
      margin: 0 0 5px 0;
      color: #333;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;

      &--caption {
        color: #999;
        font-size: 12px;
        font-weight: 400;
      }

      :global(.MoneyCell__inner) {
        height: auto;
        margin-left: 3px;
      }
    }

    &__item-info-bonus {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin: 0 0 5px 0;
    }

    &__item-info-history {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #0aa1e2;
      cursor: pointer;
    }

    &__item-info-date {
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);

      @include respond-to(xs) {
        order: 1;
      }
    }

    &__no-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin: 0 0 10px 0;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .TabAccounts {
      &__no-data {
        span {
          color: ${colors.gray.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
