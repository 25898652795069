import React from 'react';
import PropTypes from 'prop-types';

import { PageLayout } from 'common/components';
import { createNews } from '../../_redux';
import { NewsForm } from '../../_components';

const NewsCreatePage = ({ history }) => (
  <PageLayout captionSlug="createNews">
    <NewsForm
      submitFunction={createNews}
      buttonTextId="justCreate"
      history={history}
      successMessageId="newsCreatedSuccessfully"
    />
  </PageLayout>
);

NewsCreatePage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default NewsCreatePage;
