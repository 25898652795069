import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .MenuIcon {
    padding: 15px 0;
    min-width: 80px;
    transition: all 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 15;
    @include respond-to(md) {
      padding: 10px 0;
      min-width: 65px;
    }
    @include respond-to(md, customWidth) {
      display: none;
    }
    .MenuIcon__inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 100%;
      svg {
        width: 26px;
        height: 26px;
      }
    }
    .MenuIcon__inner__svg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 1px;
    }
    &.Home__menu-icon {
      .MenuIcon__inner__svg {
        top: 2px;
      }
    }
  }

  .MenuIcon__link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    transition: all 0.4s ease;
    &.active {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
      }
    }
  }

  .MenuIcon__unread-count {
    position: absolute;
    top: -6px;
    right: -7px;
    min-width: 15px;
    padding: 2px;
    height: 15px;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    z-index: 12;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .MenuIcon {
      border-left: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
    }

    .MenuIcon__link--disabled {
      opacity: 0.6;
      &:hover {
        cursor: not-allowed;
      }
    }

    .MenuIcon__link:not(.MenuIcon__link--disabled) {
      &:hover {
        background: ${gradient(0, [colors.gradientStart.rgb, 0.3], 0, [colors.gradientEnd.rgb, 0.1], 100)};
      }
      &.active {
        background: ${gradient(0, [colors.gradientStart.rgb, 0.3], 0, [colors.gradientEnd.rgb, 0.1], 100)};
        &:after {
          background: ${gradient(73.29, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
      }
    }

    .MenuIcon__unread-count {
      background-color: ${colors.error.hex};
      color: ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
