export const locale = localStorage.getItem('locale');
export const isRTL = localStorage.getItem('locale') === 'ar';

export const chunkString = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; i += 1, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
};

export const getCookie = name =>
  document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

export const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path);
};

export const isEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

/* eslint-disable */
export const getCookieObj = () => {
  const cookieArr = document.cookie.split('; ').map(item => item.split('='));
  const obj = {};
  for (const [prop, value] of cookieArr) obj[prop] = value;
  return obj;
};
/* eslint-enable */

export const labelAndValuesOfLanguages = [
  {
    value: 'ar',
    label: 'العربية',
  },
  {
    value: 'bg',
    label: 'български',
  },
  {
    value: 'bs',
    label: 'bosanski',
  },
  {
    value: 'ca',
    label: 'català',
  },
  {
    value: 'cs',
    label: 'čeština',
  },
  {
    value: 'da',
    label: 'dansk',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'el',
    label: 'Ελληνικά',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'español',
  },
  {
    value: 'et',
    label: 'eesti',
  },
  {
    value: 'fa',
    label: 'فارسی',
  },
  {
    value: 'fi',
    label: 'suomi',
  },
  {
    value: 'fr',
    label: 'français',
  },
  {
    value: 'gl',
    label: 'galego',
  },
  {
    value: 'he',
    label: 'עברית',
  },
  {
    value: 'hu',
    label: 'magyar',
  },
  {
    value: 'id',
    label: 'Indonesia',
  },
  {
    value: 'is',
    label: 'íslenska',
  },
  {
    value: 'it',
    label: 'italiano',
  },
  {
    value: 'ja',
    label: '日本語',
  },
  {
    value: 'km',
    label: 'ខ្មែរ',
  },
  {
    value: 'ko',
    label: '한국어',
  },
  {
    value: 'lt',
    label: 'lietuvių',
  },
  {
    value: 'lv',
    label: 'latviešu',
  },
  {
    value: 'mk',
    label: 'македонски',
  },
  {
    value: 'nl',
    label: 'Nederlands',
  },
  {
    value: 'nn',
    label: 'nynorsk',
  },
  {
    value: 'no',
    label: 'norsk bokmål (Norge)',
  },
  {
    value: 'pl',
    label: 'polski',
  },
  {
    value: 'pt',
    label: 'português',
  },
  {
    value: 'ro',
    label: 'română',
  },
  {
    value: 'ru',
    label: 'русский',
  },
  {
    value: 'sk',
    label: 'slovenčina',
  },
  {
    value: 'sl',
    label: 'slovenščina',
  },
  {
    value: 'sv',
    label: 'svenska',
  },
  {
    value: 'th',
    label: 'ไทย',
  },
  {
    value: 'tr',
    label: 'Türkçe',
  },
  {
    value: 'uk',
    label: 'українська',
  },
  {
    value: 'vi',
    label: 'Tiếng Việt',
  },
  {
    value: 'zh',
    label: '中文',
  },
];

export const getLabelAndValueFromLangSlug = slug => {
  const localeForSlug = labelAndValuesOfLanguages.find(locale => locale.value === slug);
  if (!localeForSlug) {
    return { value: slug, label: slug };
  }
  return localeForSlug;
};

export const splitCamelCase = string => {
  const b = string.split('');
  for (let i = 0; i < b.length; i += 1) {
    if (b[i] === b[i].toUpperCase()) {
      b.splice(i, 1, ` ${b[i]}`);
    }
  }
  return b.join('');
};

export const throttle = (callee, timeout) => {
  let timer = null;
  return function perform(...args) {
    if (timer) return;

    timer = setTimeout(() => {
      callee(...args);
      clearTimeout(timer);
      timer = null;
    }, timeout);
  };
};

export const setTagColor = s => {
  const colors = [
    { background: '#CAF0F8', color: '#000000' },
    { background: '#ADE8F4', color: '#000000' },
    { background: '#90E0EF', color: '#000000' },
    { background: '#48CAE4', color: '#FFFFFF' },
    { background: '#00B4D8', color: '#FFFFFF' },
    { background: '#0096C7', color: '#FFFFFF' },
    { background: '#0077B6', color: '#FFFFFF' },
    { background: '#023E8A', color: '#FFFFFF' },
    { background: '#03045E', color: '#FFFFFF' },
    { background: '#80FFDB', color: '#000000' },
    { background: '#72EFDD', color: '#000000' },
    { background: '#64DFDF', color: '#FFFFFF' },
    { background: '#56CFE1', color: '#FFFFFF' },
    { background: '#48BFE3', color: '#FFFFFF' },
    { background: '#4EA8DE', color: '#FFFFFF' },
    { background: '#5390D9', color: '#FFFFFF' },
    { background: '#5E60CE', color: '#FFFFFF' },
    { background: '#6930C3', color: '#FFFFFF' },
    { background: '#7400B8', color: '#FFFFFF' },
    { background: '#C4FFF9', color: '#000000' },
    { background: '#68D8D6', color: '#FFFFFF' },
    { background: '#9CEAEF', color: '#000000' },
    { background: '#3DCCC7', color: '#FFFFFF' },
    { background: '#07BEB8', color: '#FFFFFF' },
  ];
  const hashCode = s =>
    s.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0); // eslint-disable-line no-bitwise
      return a;
    }, 0);

  const hashArr = hashCode(s).toString().split('');
  let tagColorNum = 0;
  for (let i = 0; i < 4; i += 1) {
    if (tagColorNum + +hashArr[i] < colors.length - 1) {
      tagColorNum += +hashArr[i];
    }
  }
  return colors[tagColorNum];
};
