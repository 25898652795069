import React from 'react';

const CrmTasksIcon = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M2.98008 5.64844C2.86758 5.78516 2.70391 5.86719 2.52813 5.83984C2.35273 5.88281 2.18203 5.81641 2.0582 5.69141L0.183047 3.81641C-0.0610156 3.57422 -0.0610156 3.17734 0.183047 2.9332C0.426953 2.68906 0.822656 2.68906 1.0668 2.9332L2.45781 4.32422L5.14453 1.1C5.36719 0.834766 5.76172 0.798829 6.02344 1.01992C6.28906 1.24102 6.32422 1.63477 6.10547 1.9L2.98008 5.64844ZM2.98008 11.8984C2.86758 12.0352 2.70391 12.1172 2.52813 12.0898C2.35273 12.1328 2.18203 12.0664 2.0582 11.9414L0.183047 10.0664C-0.0610156 9.82422 -0.0610156 9.42578 0.183047 9.18359C0.426953 8.9375 0.822656 8.9375 1.0668 9.18359L2.45781 10.5742L5.14453 7.35156C5.36719 7.05078 5.76172 7.05078 6.02344 7.26953C6.28906 7.49219 6.32422 7.88672 6.10547 8.14844L2.98008 11.8984ZM7.46484 3.375C7.46484 3.02969 7.78125 2.75 8.08984 2.75H19.375C19.7188 2.75 20 3.02969 20 3.375C20 3.71875 19.7188 4 19.375 4H8.08984C7.78125 4 7.46484 3.71875 7.46484 3.375ZM7.46484 9.625C7.46484 9.28125 7.78125 9 8.08984 9H19.375C19.7188 9 20 9.28125 20 9.625C20 9.96875 19.7188 10.25 19.375 10.25H8.08984C7.78125 10.25 7.46484 9.96875 7.46484 9.625ZM6.21484 15.875C6.21484 15.5313 6.53125 15.25 6.83984 15.25H19.375C19.7188 15.25 20 15.5313 20 15.875C20 16.2188 19.7188 16.5 19.375 16.5H6.83984C6.53125 16.5 6.21484 16.2188 6.21484 15.875ZM2.46484 17.125C1.80977 17.125 1.21484 16.5664 1.21484 15.875C1.21484 15.1836 1.80977 14.625 2.46484 14.625C3.19023 14.625 3.71484 15.1836 3.71484 15.875C3.71484 16.5664 3.19023 17.125 2.46484 17.125Z"
        fill="white"
      />
    </svg>
  </div>
);

export default CrmTasksIcon;
