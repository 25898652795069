import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  paymentExportsIsLoaded: false,
  paymentExports: {},

  isExportPaymentBeingCreated: false,

  deposits: {},
  depositsAreLoaded: false,

  deposit: {},
  depositIsLoaded: false,
  depositActionsLoading: false,

  depositProof: null,
  depositProofIsLoading: false,

  paymentProof: null,
  paymentProofIsLoading: false,

  documentVerifyIsLoading: false,

  depositSystems: [],
  loadingDepositSystems: true,

  withdrawals: {},
  withdrawalsAreLoaded: false,

  withdrawal: {},
  withdrawalIsLoaded: false,
  withdrawalActionsLoading: false,
  withdrawalError: '',

  withdrawalSystems: [],
  loadingWithdrawalSystems: true,

  userTransactions: {},
  userTransactionsAreLoaded: false,

  orders: {},
  ordersAreLoaded: false,

  userEditHistory: [],
  userEditHistoryIsLoaded: false,

  orderDetails: {},
  orderDetailsIsLoaded: false,

  approvalHistory: [],
  approvalHistoryIsLoaded: false,

  withdrawApprovalGroupList: {},
  withdrawApprovalGroupListIsLoaded: false,

  approvalGroup: {},
  approvalGroupIsLoaded: false,

  approvalSteps: [],
  approvalStepsAreloaded: false,

  depositStatuses: [],
  depositStatusesAreLoaded: false,

  withdrawalStatuses: [],
  withdrawalStatusesAreLoaded: false,

  isRejected: false,

  metadataKeys: [],
  metadataKeysAreLoaded: false,

  paymentTags: [],
  paymentTagsAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.CREATE_BO_PAYMENT_EXPORT[REQUEST]:
    //   return { ...state, paymentsExportsDataIsLoaded: false };
    // case ActionTypes.CREATE_BO_PAYMENT_EXPORT[SUCCESS]:
    //   return { ...state, paymentExportsData: action.data, paymentsExportsDataIsLoaded: true };
    // case ActionTypes.CREATE_BO_PAYMENT_EXPORT[FAILURE]:
    //   return { ...state, error: action.error, paymentsExportsDataIsLoaded: false };

    case ActionTypes.GET_BO_PAYMENT_EXPORTS[REQUEST]:
      return { ...state, paymentExportsIsLoaded: false };
    case ActionTypes.GET_BO_PAYMENT_EXPORTS[SUCCESS]:
      return { ...state, paymentExports: action.data, paymentExportsIsLoaded: true };
    case ActionTypes.GET_BO_PAYMENT_EXPORTS[FAILURE]:
      return { ...state, error: action.error, paymentExportsIsLoaded: false };

    case ActionTypes.CREATE_BO_PAYMENT_EXPORT[REQUEST]:
      return { ...state, isExportPaymentBeingCreated: true };
    case ActionTypes.CREATE_BO_PAYMENT_EXPORT[SUCCESS]:
      return { ...state, isExportPaymentBeingCreated: false };
    case ActionTypes.CREATE_BO_PAYMENT_EXPORT[FAILURE]:
      return { ...state, isExportPaymentBeingCreated: false };

    case ActionTypes.GET_DEPOSIT[REQUEST]:
      return { ...state, depositIsLoaded: false };
    case ActionTypes.GET_DEPOSIT[SUCCESS]:
      return { ...state, deposit: action.data, depositIsLoaded: true };
    case ActionTypes.GET_DEPOSIT[FAILURE]:
      return { ...state, error: action.error, depositIsLoaded: false };

    case ActionTypes.ACTION_DEPOSIT[REQUEST]:
      return { ...state, depositActionsLoading: true };
    case ActionTypes.ACTION_DEPOSIT[SUCCESS]:
      return { ...state, deposit: action.data, depositActionsLoading: false };
    case ActionTypes.ACTION_DEPOSIT[FAILURE]:
      return { ...state, error: action.error, depositActionsLoading: false };

    case ActionTypes.GET_DEPOSITS[REQUEST]:
      return { ...state, depositsAreLoaded: false };
    case ActionTypes.GET_DEPOSITS[SUCCESS]:
      return { ...state, deposits: action.data, depositsAreLoaded: true };
    case ActionTypes.GET_DEPOSITS[FAILURE]:
      return { ...state, error: action.error, depositsAreLoaded: false };

    case ActionTypes.GET_DEPOSIT_PROOF[REQUEST]:
      return { ...state, depositProofIsLoaded: false };
    case ActionTypes.GET_DEPOSIT_PROOF[SUCCESS]:
      return { ...state, depositProof: action.data, depositProofIsLoaded: true };
    case ActionTypes.GET_DEPOSIT_PROOF[FAILURE]:
      return { ...state, error: action.error, depositProofIsLoaded: false };

    case ActionTypes.GET_PAYMENT_PROOF[REQUEST]:
      return { ...state, paymentProofIsLoaded: false, paymentProof: null };
    case ActionTypes.GET_PAYMENT_PROOF[SUCCESS]:
      return { ...state, paymentProof: action.data, paymentProofIsLoaded: true };
    case ActionTypes.GET_PAYMENT_PROOF[FAILURE]:
      return { ...state, error: action.error, paymentProofIsLoaded: false };

    case ActionTypes.VERIFY_DEPOSIT_PROOF[REQUEST]:
      return { ...state, documentVerifyIsLoading: true };
    case ActionTypes.VERIFY_DEPOSIT_PROOF[SUCCESS]:
    case ActionTypes.VERIFY_DEPOSIT_PROOF[FAILURE]:
      return { ...state, documentVerifyIsLoading: false };

    case ActionTypes.GET_USER_DEPOSITS[REQUEST]:
      return { ...state, depositsAreLoaded: false, redirect: false };
    case ActionTypes.GET_USER_DEPOSITS[SUCCESS]:
      return { ...state, deposits: action.data, depositsAreLoaded: true, redirect: false };
    case ActionTypes.GET_USER_DEPOSITS[FAILURE]:
      return { ...state, error: action.error, depositsAreLoaded: false, redirect: action.error === 404 };

    case ActionTypes.GET_DEPOSIT_PAYMENT_SYSTEMS[REQUEST]:
      return { ...state, loadingDepositSystems: true };
    case ActionTypes.GET_DEPOSIT_PAYMENT_SYSTEMS[SUCCESS]:
      return {
        ...state,
        loadingDepositSystems: false,
        depositSystems: action.systems,
      };
    case ActionTypes.GET_DEPOSIT_PAYMENT_SYSTEMS[FAILURE]:
      return { ...state, loadingDepositSystems: false };

    case ActionTypes.GET_WITHDRAWAL[REQUEST]:
      return { ...state, withdrawalIsLoaded: false };
    case ActionTypes.GET_WITHDRAWAL[SUCCESS]:
      return { ...state, withdrawal: action.data, withdrawalIsLoaded: true };
    case ActionTypes.GET_WITHDRAWAL[FAILURE]:
      return { ...state, error: action.error, withdrawalIsLoaded: false };

    case ActionTypes.ACTION_WITHDRAWAL[REQUEST]:
      return { ...state, withdrawalActionsLoading: true };
    case ActionTypes.ACTION_WITHDRAWAL[SUCCESS]:
      return {
        ...state,
        withdrawal: action.data,
        withdrawalActionsLoading: false,
      };
    case ActionTypes.ACTION_WITHDRAWAL[FAILURE]:
      return { ...state, error: action.error, withdrawalActionsLoading: false };

    case ActionTypes.GET_WITHDRAWALS[REQUEST]:
      return { ...state, withdrawalsAreLoaded: false };
    case ActionTypes.GET_WITHDRAWALS[SUCCESS]:
      return { ...state, withdrawals: action.data, withdrawalsAreLoaded: true };
    case ActionTypes.GET_WITHDRAWALS[FAILURE]:
      return { ...state, error: action.error, withdrawalsAreLoaded: false };

    case ActionTypes.SET_WITHDRAWAL_ERROR[REQUEST]:
      return { ...state, withdrawalIsLoaded: false };
    case ActionTypes.SET_WITHDRAWAL_ERROR[SUCCESS]:
      return { ...state, withdrawalError: action.data, withdrawalIsLoaded: true };
    case ActionTypes.SET_WITHDRAWAL_ERROR[FAILURE]:
      return { ...state, error: action.error, withdrawalIsLoaded: false };

    case ActionTypes.GET_USER_WITHDRAWALS[REQUEST]:
      return { ...state, withdrawalsAreLoaded: false };
    case ActionTypes.GET_USER_WITHDRAWALS[SUCCESS]:
      return { ...state, withdrawals: action.data, withdrawalsAreLoaded: true };
    case ActionTypes.GET_USER_WITHDRAWALS[FAILURE]:
      return { ...state, error: action.error, withdrawalsAreLoaded: false };

    case ActionTypes.GET_WITHDRAWAL_PAYMENT_SYSTEMS[REQUEST]:
      return { ...state, loadingWithdrawalSystems: true };
    case ActionTypes.GET_WITHDRAWAL_PAYMENT_SYSTEMS[SUCCESS]:
      return {
        ...state,
        loadingWithdrawalSystems: false,
        withdrawalSystems: action.systems,
      };
    case ActionTypes.GET_WITHDRAWAL_PAYMENT_SYSTEMS[FAILURE]:
      return { ...state, loadingWithdrawalSystems: false };

    case ActionTypes.GET_USER_TRANSACTIONS[REQUEST]:
      return { ...state, userTransactionsAreLoaded: false };
    case ActionTypes.GET_USER_TRANSACTIONS[SUCCESS]:
      return { ...state, userTransactions: action.data, userTransactionsAreLoaded: true };
    case ActionTypes.GET_USER_TRANSACTIONS[FAILURE]:
      return { ...state, error: action.error, userTransactionsAreLoaded: false };

    case ActionTypes.GET_OPEN_ORDERS[REQUEST]:
      return { ...state, ordersAreLoaded: false };
    case ActionTypes.GET_OPEN_ORDERS[SUCCESS]:
      return { ...state, orders: action.data, ordersAreLoaded: true };
    case ActionTypes.GET_OPEN_ORDERS[FAILURE]:
      return { ...state, error: action.error, ordersAreLoaded: false };

    case ActionTypes.GET_CLOSED_ORDERS[REQUEST]:
      return { ...state, ordersAreLoaded: false };
    case ActionTypes.GET_CLOSED_ORDERS[SUCCESS]:
      return { ...state, orders: action.data, ordersAreLoaded: true };
    case ActionTypes.GET_CLOSED_ORDERS[FAILURE]:
      return { ...state, error: action.error, ordersAreLoaded: false };

    case ActionTypes.GET_DEFERRED_ORDERS[REQUEST]:
      return { ...state, ordersAreLoaded: false };
    case ActionTypes.GET_DEFERRED_ORDERS[SUCCESS]:
      return { ...state, orders: action.data, ordersAreLoaded: true };
    case ActionTypes.GET_DEFERRED_ORDERS[FAILURE]:
      return { ...state, error: action.error, ordersAreLoaded: false };

    case ActionTypes.GET_DELAYED_ORDERS[REQUEST]:
      return { ...state, ordersAreLoaded: false };
    case ActionTypes.GET_DELAYED_ORDERS[SUCCESS]:
      return { ...state, orders: action.data, ordersAreLoaded: true };
    case ActionTypes.GET_DELAYED_ORDERS[FAILURE]:
      return { ...state, error: action.error, ordersAreLoaded: false };

    case ActionTypes.GET_EDIT_HISTORY[REQUEST]:
      return { ...state, userEditHistoryIsLoaded: false };
    case ActionTypes.GET_EDIT_HISTORY[SUCCESS]:
      return { ...state, userEditHistory: action.data, userEditHistoryIsLoaded: true };
    case ActionTypes.GET_EDIT_HISTORY[FAILURE]:
      return { ...state, error: action.error, userEditHistoryIsLoaded: false };

    case ActionTypes.GET_APPROVAL_HISTORY[REQUEST]:
      return { ...state, approvalHistoryIsLoaded: false };
    case ActionTypes.GET_APPROVAL_HISTORY[SUCCESS]:
      return { ...state, approvalHistory: action.data, approvalHistoryIsLoaded: true };
    case ActionTypes.GET_APPROVAL_HISTORY[FAILURE]:
      return { ...state, error: action.error, approvalHistoryIsLoaded: false };

    case ActionTypes.GET_APPROVAL_GROUP[REQUEST]:
      return { ...state, approvalGroupIsLoaded: false };
    case ActionTypes.GET_APPROVAL_GROUP[SUCCESS]:
      return { ...state, approvalGroup: action.data, approvalGroupIsLoaded: true };
    case ActionTypes.GET_APPROVAL_GROUP[FAILURE]:
      return { ...state, error: action.error, approvalGroupIsLoaded: false };

    case ActionTypes.GET_WITHDRAW_APPROVAL_GROUP_LIST[REQUEST]:
      return { ...state, withdrawApprovalGroupListIsLoaded: false };
    case ActionTypes.GET_WITHDRAW_APPROVAL_GROUP_LIST[SUCCESS]:
      return {
        ...state,
        withdrawApprovalGroupList: action.data,
        withdrawApprovalGroupListIsLoaded: true,
        isRejected: false,
      };
    case ActionTypes.GET_WITHDRAW_APPROVAL_GROUP_LIST[FAILURE]:
      return { ...state, error: action.error, withdrawApprovalGroupListIsLoaded: false };

    case ActionTypes.GET_APPROVAL_STEPS[REQUEST]:
      return { ...state, approvalStepsAreloaded: false };
    case ActionTypes.GET_APPROVAL_STEPS[SUCCESS]:
      return { ...state, approvalSteps: action.data, approvalStepsAreloaded: true };
    case ActionTypes.GET_APPROVAL_STEPS[FAILURE]:
      return { ...state, error: action.error, approvalStepsAreloaded: false };

    case ActionTypes.REJECT_WITHDRAW[REQUEST]:
      return { ...state, isRejected: false };
    case ActionTypes.REJECT_WITHDRAW[SUCCESS]:
      return { ...state, isRejected: true };
    case ActionTypes.REJECT_WITHDRAW[FAILURE]:
      return { ...state, error: action.error, isRejected: false };

    case ActionTypes.GET_ORDER_DETAILS[REQUEST]:
      return { ...state, orderDetailsIsLoaded: false };
    case ActionTypes.GET_ORDER_DETAILS[SUCCESS]:
      return { ...state, orderDetails: action.data, orderDetailsIsLoaded: true };
    case ActionTypes.GET_ORDER_DETAILS[FAILURE]:
      return { ...state, error: action.error, orderDetailsIsLoaded: false };

    case ActionTypes.GET_PAYMENT_WITHDRAWAL_STATUSES[REQUEST]:
      return { ...state, withdrawalStatusesAreLoaded: false };
    case ActionTypes.GET_PAYMENT_WITHDRAWAL_STATUSES[SUCCESS]:
      return { ...state, withdrawalStatuses: action.data, withdrawalStatusesAreLoaded: true };
    case ActionTypes.GET_PAYMENT_WITHDRAWAL_STATUSES[FAILURE]:
      return { ...state, error: action.error, withdrawalStatusesAreLoaded: false };

    case ActionTypes.GET_PAYMENT_DEPOSIT_STATUSES[REQUEST]:
      return { ...state, depositStatusesAreLoaded: false };
    case ActionTypes.GET_PAYMENT_DEPOSIT_STATUSES[SUCCESS]:
      return { ...state, depositStatuses: action.data, depositStatusesAreLoaded: true };
    case ActionTypes.GET_PAYMENT_DEPOSIT_STATUSES[FAILURE]:
      return { ...state, error: action.error, depositStatusesAreLoaded: false };

    case ActionTypes.GET_PAYMENT_METADATA_KEYS[REQUEST]:
      return { ...state, metadataKeysAreLoaded: false };
    case ActionTypes.GET_PAYMENT_METADATA_KEYS[SUCCESS]:
      return { ...state, metadataKeys: action.data, metadataKeysAreLoaded: true };
    case ActionTypes.GET_PAYMENT_METADATA_KEYS[FAILURE]:
      return { ...state, error: action.error, metadataKeysAreLoaded: false };

    case ActionTypes.GET_BO_PAYMENT_TAGS[REQUEST]:
      return { ...state, paymentTagsAreLoaded: false };
    case ActionTypes.GET_BO_PAYMENT_TAGS[SUCCESS]:
      return { ...state, paymentTags: action.data, paymentTagsAreLoaded: true };
    case ActionTypes.GET_BO_PAYMENT_TAGS[FAILURE]:
      return { ...state, error: action.error, paymentTagsAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
