import { put, call, fork, take } from 'redux-saga/effects';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

const normalizeStatusDescriptionObject = formValues => {
  const { title, description } = formValues;

  return {
    title: title ? Object.entries(title).map(([key, value]) => ({ lang: key, text: value })) : undefined,
    description: description
      ? Object.entries(description).map(([key, value]) => ({ lang: key, text: value }))
      : undefined,
  };
};

const normalizeStatusInfoObject = formValues => {
  const { title, subTitle, description, promoTexts, buttonText, buttonLink, ...props } = formValues;

  return {
    ...props,
    title: title ? Object.entries(title).map(([key, value]) => ({ lang: key, text: value })) : undefined,
    subTitle: subTitle ? Object.entries(subTitle).map(([key, value]) => ({ lang: key, text: value })) : undefined,
    description: description
      ? Object.entries(description).map(([key, value]) => ({ lang: key, text: value }))
      : undefined,
    promoTexts: promoTexts
      ? Object.values(promoTexts).map(promo =>
          Object.entries(promo).map(([key, value]) => ({ lang: key, text: value }))
        )
      : [],
    buttonText:
      buttonText && Object.keys(buttonText).length !== 0
        ? Object.entries(buttonText).map(([key, value]) => ({ lang: key, text: value }))
        : undefined,
    buttonLink: buttonLink || undefined,
  };
};

export function* getStatusesListSaga(sort) {
  try {
    const { data } = yield call(() => api.getStatusesList(sort));
    yield put(ActionTypes.getStatusesList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStatusesList.failure());
  }
}

export function* getStatusesListWatcherSaga() {
  while (true) {
    const { sort } = yield take(ActionTypes.GET_BO_STATUSES_LIST[REQUEST]);
    yield call(getStatusesListSaga, sort);
  }
}

export function* getStatusInfoSaga({ id }) {
  try {
    const { data } = yield call(api.getStatusInfo, id);
    yield put(ActionTypes.getStatusInfo.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStatusInfo.failure(e));
  }
}

export function* getStatusInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_BO_STATUS_INFO[REQUEST]);
    yield call(getStatusInfoSaga, data);
  }
}

export function* updateStatusDescriptionSaga(values) {
  try {
    const { status, data } = yield call(api.updateStatusDescription, normalizeStatusDescriptionObject(values));
    if (status < 300) {
      yield put(ActionTypes.updateStatusDescription.success(data));
    } else {
      yield put(ActionTypes.updateStatusDescription.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.updateStatusDescription.failure(e));
  }
}

export function* updateStatusDescriptionWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateStatusDescription.REQUEST);
    yield call(updateStatusDescriptionSaga, payload);
  }
}

export function* createStatusInfoSaga(values) {
  try {
    const { status, data } = yield call(api.createStatusInfo, normalizeStatusInfoObject(values));
    if (status < 300) {
      yield put(ActionTypes.createStatusInfo.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.createStatusInfo.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createStatusInfo.failure(e));
  }
}

export function* createStatusInfoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createStatusInfo.REQUEST);
    yield call(createStatusInfoSaga, payload);
  }
}

export function* updateStatusInfoSaga({ id, values }) {
  try {
    const { status, data } = yield call(api.updateStatusInfo, id, normalizeStatusInfoObject(values));
    if (status < 300) {
      yield put(ActionTypes.updateStatusInfo.success(data));
    } else {
      yield put(ActionTypes.updateStatusInfo.failure(new SubmissionError(data)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.updateStatusInfo.failure(e));
  }
}

export function* updateStatusInfoWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateStatusInfo.REQUEST);
    yield call(updateStatusInfoSaga, payload);
  }
}

export function* deleteStatusInfoSaga({ id }) {
  try {
    yield call(api.deleteStatusInfo, id);
    yield put(ActionTypes.deleteStatusInfo.success());
    yield call(getStatusesListSaga);
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteStatusInfo.failure(e));
  }
}

export function* deleteStatusInfoWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DELETE_STATUS_INFO[REQUEST]);
    yield call(deleteStatusInfoSaga, data);
  }
}

export function* getStatusDescriptionSaga() {
  try {
    const { data } = yield call(api.getStatusDescription);
    yield put(ActionTypes.getStatusDescription.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getStatusDescription.failure());
  }
}

export function* getStatusDescriptionWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BO_STATUS_DESCRIPTION[REQUEST]);
    yield call(getStatusDescriptionSaga);
  }
}

export default [
  fork(getStatusesListWatcherSaga),
  fork(getStatusInfoWatcherSaga),
  fork(updateStatusDescriptionWatcherSaga),
  fork(createStatusInfoWatcherSaga),
  fork(updateStatusInfoWatcherSaga),
  fork(deleteStatusInfoWatcherSaga),
  fork(getStatusDescriptionWatcherSaga),
];
