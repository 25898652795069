import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const BuoyIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM3.51446 11.5H7.7791C8.00735 9.55241 9.55241 8.00735 11.5 7.7791V3.51446C7.20375 3.76367 3.76367 7.20375 3.51446 11.5ZM12.5 3.51446V7.7791C14.4476 8.00735 15.9927 9.55241 16.2209 11.5H20.4855C20.2363 7.20375 16.7962 3.76367 12.5 3.51446ZM20.4855 12.5H16.2209C15.9927 14.4476 14.4476 15.9927 12.5 16.2209V20.4855C16.7962 20.2363 20.2363 16.7962 20.4855 12.5ZM11.5 20.4855V16.2209C9.55241 15.9927 8.00735 14.4476 7.7791 12.5H3.51446C3.76367 16.7962 7.20375 20.2363 11.5 20.4855ZM12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75Z"
        fill="#0C0C3D"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.34249"
          y1="20.4072"
          x2="21.1806"
          y2="15.0523"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} />
          <stop offset="1" stopColor={gradientEndColor.hex} />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

BuoyIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

BuoyIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedBuoyIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(BuoyIconActive);

export default ThemedBuoyIconActive;
