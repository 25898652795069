import css from 'styled-jsx/css';
import loopIcon from 'common/images/loop.svg';
import loadIcon from 'common/images/loader.svg';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SearchPanel {
    margin-bottom: rem(30px);

    :global(.BaseTextField) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: ${isRTL ? 'auto' : '15px'};
        left: ${isRTL ? '15px' : 'auto'};
        top: 50%;
        transform: translateY(-50%);
        width: 17px;
        height: 17px;
        background-image: url(${loopIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }

    :global(.BaseTextField.loading) {
      &:after {
        background-image: url(${loadIcon});
      }
    }
  }

  .SearchPanel__inputs-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-column-gap: 25px;
    grid-row-gap: 15px;
  }
`;

export { staticStyles };
