import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, change } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, ReduxSelectField } from 'common/components';

import { initializeBackofficeUserImport } from 'backoffice/users/_redux';
import { getBackofficeServers } from 'backoffice/_redux/actions';

import staticStyles from './style';

const validate = ({ name, time, timeEnd }) => {
  const errors = {};

  if (!/^[a-zA-Z0-9\s_!,.?]{0,50}$/.test(name)) errors.name = <FormattedMessage id="rammStrategyNameHelpText" />;

  if (timeEnd <= time) errors.timeEnd = <FormattedMessage id="investRolloversTimeError" />;

  return errors;
};

const ImportServerForm = ({
  toggleModal,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  error,
  getBackofficeServers,
  servers,
  serversAreLoaded,
}) => {
  const [serverSelected, setServerSelected] = useState(null);

  const submit = handleSubmit(() =>
    initializeBackofficeUserImport({ fromServer: true, server: serverSelected }, dispatch)
  );

  const serversData = useMemo(() => {
    if (servers) return servers.map(({ id, title }) => ({ value: id, label: title }));
    return null;
  }, [servers]);

  useEffect(() => {
    getBackofficeServers();
  }, [getBackofficeServers]);

  return (
    <div className="ImportServer__content">
      <form className="ImportServerForm" onSubmit={submit}>
        <div className="ImportServerForm__inputs-inner">
          <Field
            component={ReduxSelectField}
            name="server"
            textId="clientMetaTraderServers"
            options={serversData}
            disabled={submitting || !serversAreLoaded}
            validate={[required()]}
            onChange={value => setServerSelected(value)}
          />

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="ImportServerForm__btns">
          <DefaultButton
            textId="justImport"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
      </form>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ImportServerForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  getBackofficeServers: PropTypes.func.isRequired,
  serversAreLoaded: PropTypes.bool.isRequired,
};

ImportServerForm.defaultProps = {
  error: '',
};

export default compose(
  reduxForm({
    form: 'ImportServerForm',
    validate,
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
      props.history.push('/backoffice/imports');
    },
  }),
  connect(
    state => ({
      accounts: state.client.common.accounts.filter(
        account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
      ),
      accountsAreLoaded: state.client.common.accountsIsLoaded,
      servers: state.backoffice.common.servers,
      serversAreLoaded: state.backoffice.common.serversAreLoaded,
    }),
    dispatch => ({
      getBackofficeServers: () => dispatch(getBackofficeServers.request()),
      changeFieldValue: (field, value) => dispatch(change('ImportServerForm', field, value)),
    })
  )
)(ImportServerForm);
export { ImportServerForm };
