import React from 'react';

const MailIcon = () => (
  <svg className="svgNoFill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1875 5C19.7408 5 21 6.29149 21 7.88462V17.1154C21 18.7085 19.7408 20 18.1875 20H5.8125C4.2592 20 3 18.7085 3 17.1154V7.88462C3 6.29149 4.2592 5 5.8125 5H18.1875ZM19.875 9.57038L12.2852 14.1511C12.1385 14.2396 11.9624 14.2544 11.806 14.1954L11.7148 14.1511L4.125 9.57269V17.1154C4.125 18.0713 4.88052 18.8462 5.8125 18.8462H18.1875C19.1195 18.8462 19.875 18.0713 19.875 17.1154V9.57038ZM18.1875 6.15385H5.8125C4.88052 6.15385 4.125 6.92874 4.125 7.88462V8.23308L12 12.9845L19.875 8.23192V7.88462C19.875 6.92874 19.1195 6.15385 18.1875 6.15385Z"
      className="fill"
      fill="#0C0C3D"
    />
  </svg>
);

export default MailIcon;
