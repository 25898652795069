import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';

import StatisticalCard from '../StatisticalCard';
import { staticStyles, getDynamicStyles } from './style';

const AccountStatisticalBlock = ({ dealStats, statisticLoaded, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const { accountsTotalBalance, mostProfitableDeal, weekNumberOfDeals } = dealStats;
  return (
    <div className="AccountStatisticalBlock">
      <StatisticalCard
        rate={accountsTotalBalance}
        captionId="clientTotalRealAccounts"
        amountIsLoaded={statisticLoaded}
        withCurrency
        withWatch
      />
      <StatisticalCard
        rate={mostProfitableDeal}
        captionId="clientProfitableDeal"
        amountIsLoaded={statisticLoaded}
        withCurrency
        withWatch
      />
      <StatisticalCard captionId="clientNumberOfDeals" rate={weekNumberOfDeals} amountIsLoaded={statisticLoaded} />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

AccountStatisticalBlock.propTypes = {
  dealStats: PropTypes.object.isRequired,
  statisticLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

AccountStatisticalBlock.defaultProps = {
  theme: {},
};

export default withTheme()(AccountStatisticalBlock);
export { AccountStatisticalBlock };
