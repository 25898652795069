import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const getCommissionsList = createFormAction('GET_COMMISSIONS_LIST');

export const editCommission = createFormAction('EDIT_COMMISSION');

export const payCommissions = createFormAction('PAY_COMMISSIONS');

export const rejectRewards = createFormAction('REJECT_REWARDS');

export const GET_REWARD_FREQUENCIES = createRequestTypes('GET_REWARD_FREQUENCIES');
export const getRewardFrequencies = {
  request: () => action(GET_REWARD_FREQUENCIES[REQUEST]),
  success: data => action(GET_REWARD_FREQUENCIES[SUCCESS], { data }),
  failure: error => action(GET_REWARD_FREQUENCIES[FAILURE], { error }),
};

export const BO_GET_PARTNERSHIP_REQUESTS = createRequestTypes('BO_GET_PARTNERSHIP_REQUESTS');
export const boGetPartnershipRequests = {
  request: search => action(BO_GET_PARTNERSHIP_REQUESTS[REQUEST], { search }),
  success: data => action(BO_GET_PARTNERSHIP_REQUESTS[SUCCESS], { data }),
  failure: error => action(BO_GET_PARTNERSHIP_REQUESTS[FAILURE], { error }),
};

export const BO_GET_PARTNERSHIP_REQUEST = createRequestTypes('BO_GET_PARTNERSHIP_REQUEST');
export const boGetPartnershipRequest = {
  request: requestId => action(BO_GET_PARTNERSHIP_REQUEST[REQUEST], { requestId }),
  success: data => action(BO_GET_PARTNERSHIP_REQUEST[SUCCESS], { data }),
  failure: error => action(BO_GET_PARTNERSHIP_REQUEST[FAILURE], { error }),
};

export const CREATE_PARTNER_ACCOUNTS_EXPORT = createRequestTypes('CREATE_PARTNER_EXPORTS');
export const createPartnerAccountsExport = {
  request: ({ ext, timePeriod }) => action(CREATE_PARTNER_ACCOUNTS_EXPORT[REQUEST], { ext, timePeriod }),
  success: data => action(CREATE_PARTNER_ACCOUNTS_EXPORT[SUCCESS], { data }),
  failure: error => action(CREATE_PARTNER_ACCOUNTS_EXPORT[FAILURE], { error }),
};

export const GET_PARTNER_ACCOUNTS_EXPORTS = createRequestTypes('GET_PARTNER_ACCOUNTS_EXPORTS');
export const getPartnerAccountsExports = {
  request: search => action(GET_PARTNER_ACCOUNTS_EXPORTS[REQUEST], search),
  success: data => action(GET_PARTNER_ACCOUNTS_EXPORTS[SUCCESS], { data }),
  failure: error => action(GET_PARTNER_ACCOUNTS_EXPORTS[FAILURE], { error }),
};

export const DOWNLOAD_PARTNER_ACCOUNTS_EXPORT = createRequestTypes('DOWNLOAD_PARTNER_ACCOUNTS_EXPORT');
export const downloadPartnerAccountsExport = {
  request: data => action(DOWNLOAD_PARTNER_ACCOUNTS_EXPORT[REQUEST], data),
  success: data => action(DOWNLOAD_PARTNER_ACCOUNTS_EXPORT[SUCCESS], { data }),
  failure: error => action(DOWNLOAD_PARTNER_ACCOUNTS_EXPORT[FAILURE], { error }),
};

export const CREATE_PARTNER_COMMISSIONS_EXPORT = createRequestTypes('CREATE_PARTNER_COMMISSIONS_EXPORT');
export const createPartnerCommissionsExport = {
  request: ({ ext, timePeriod }) => action(CREATE_PARTNER_COMMISSIONS_EXPORT[REQUEST], { ext, timePeriod }),
  success: data => action(CREATE_PARTNER_COMMISSIONS_EXPORT[SUCCESS], { data }),
  failure: error => action(CREATE_PARTNER_COMMISSIONS_EXPORT[FAILURE], { error }),
};

export const GET_PARTNER_COMMISSIONS_EXPORTS = createRequestTypes('GET_PARTNER_COMMISSIONS_EXPORTS');
export const getPartnerCommissionsExports = {
  request: search => action(GET_PARTNER_COMMISSIONS_EXPORTS[REQUEST], search),
  success: data => action(GET_PARTNER_COMMISSIONS_EXPORTS[SUCCESS], { data }),
  failure: error => action(GET_PARTNER_COMMISSIONS_EXPORTS[FAILURE], { error }),
};

export const DOWNLOAD_PARTNER_COMMISSIONS_EXPORT = createRequestTypes('DOWNLOAD_PARTNER_COMMISSIONS_EXPORT');
export const downloadPartnerCommissionsExport = {
  request: data => action(DOWNLOAD_PARTNER_COMMISSIONS_EXPORT[REQUEST], data),
  success: data => action(DOWNLOAD_PARTNER_COMMISSIONS_EXPORT[SUCCESS], { data }),
  failure: error => action(DOWNLOAD_PARTNER_COMMISSIONS_EXPORT[FAILURE], { error }),
};
