import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { DateCell } from 'common/components';
import { withTheme, isRTL } from 'common/styling/theme';
import { getIssues } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const UserInteractions = ({ user, currentIssueId, getIssues, issues, history, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getIssues({ search: { search: user.email } });
  }, [getIssues, user.email]);

  const renderView = props => (
    <div
      {...props}
      style={{
        ...props.style,
        marginLeft: isRTL ? props.style.marginRight : 0,
        marginRight: isRTL ? 0 : props.style.marginRight,
      }}
    />
  );

  const otherIssues = Array.isArray(issues) ? issues.filter(issue => issue.id !== currentIssueId) : [];

  return (
    <div className="UserInteractions">
      <Scrollbars renderView={renderView} className="BasicModal__scroll" autoHeight autoHeightMax={200}>
        {otherIssues.length ? (
          <div className="UserInteractions__tickets">
            {otherIssues.map(issue => (
              <button
                type="button"
                onClick={() => history.push(`/backoffice/support/${issue.id}/`)}
                className="UserInteractions__tickets-item"
                key={issue.id}>
                <span>{issue.title}</span>
                <DateCell value={issue.created} />
              </button>
            ))}
          </div>
        ) : (
          <FormattedMessage id="noUserInteractions">
            {txt => <div className="UserInteractions__description">{txt}</div>}
          </FormattedMessage>
        )}
      </Scrollbars>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserInteractions.propTypes = {
  user: PropTypes.object.isRequired,
  currentIssueId: PropTypes.number.isRequired,
  getIssues: PropTypes.func.isRequired,
  issues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object,
  // eslint-disable-next-line
  style: PropTypes.object,
};

UserInteractions.defaultProps = {
  theme: {},
  style: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      issues: state.backoffice.support.issues,
      issuesAreLoaded: state.backoffice.support.issuesAreLoaded,
    }),
    { getIssues: search => getIssues.request(search) }
  )
)(UserInteractions);
export { UserInteractions };
