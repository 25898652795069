import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxTextField, TextAreaField, DefaultButton, AsyncSelectField } from 'common/components';
import { createIssueTicket } from 'backoffice/support/_redux';
import { getUsers } from 'backoffice/users/api';

const CreateIssueTicketForm = ({ search, handleSubmit, dispatch, pristine, submitting, invalid, error }) => {
  const submit = handleSubmit(values => createIssueTicket({ ...values, search }, dispatch));
  const loadOptions = search =>
    getUsers({ search }).then(({ data }) => data.map(user => ({ value: user.id, label: user.email })));

  return (
    <form className="CreateIssueTicketForm" onSubmit={submit}>
      <div className="CreateIssueTicketForm__inputs-inner">
        <Field
          textId="chooseUser"
          component={AsyncSelectField}
          name="user"
          loadOptions={loadOptions}
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={100}
        />
        <Field
          component={ReduxTextField}
          autoComplete="off"
          type="text"
          name="title"
          textId="justTitle"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={TextAreaField}
          name="text"
          textId="justDescription"
          rows="4"
          cols="150"
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <DefaultButton
        textId="justSubmit"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong>{error}</strong>}
    </form>
  );
};

CreateIssueTicketForm.propTypes = {
  search: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

CreateIssueTicketForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'CreateIssueTicketForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleCreateIssueModal();
  },
})(CreateIssueTicketForm);
export { CreateIssueTicketForm };
