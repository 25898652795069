/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import TaskIcon from 'crm/_newComponents/TaskIcon';

import { staticStyles } from './style';

const ReassignFullNameCell = ({ id, fullName, history, status }) => (
  <div className="ReassignFullNameCell" onClick={() => history.push(`/crm/contacts/${id}`)}>
    <div
      className={classnames('ReassignFullNameCell__Icon', {
        'ReassignFullNameCell__Icon--Gray': !status,
        'ReassignFullNameCell__Icon--Red': status === 'declined',
        'ReassignFullNameCell__Icon--Green': status === 'accepted',
      })}>
      <TaskIcon type="Reassign" />
    </div>

    <span onClick={() => history.push(`/crm/contancts/${id}`)} className={classnames('ReassignFullNameCell__FullName')}>
      {fullName}
    </span>

    <style jsx>{staticStyles}</style>
  </div>
);

ReassignFullNameCell.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default compose(withRouter)(ReassignFullNameCell);
