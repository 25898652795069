import { fork, put, call, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

function* updateBrokerColorSchemaSaga({ payload }) {
  try {
    yield call(api.updateBrokerColorSchema, payload);
    yield put(ActionTypes.updateBrokerColorSchema.success());
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.updateBrokerColorSchema.failure(e));
  }
}

function* updateBrokerColorSchemaWatcherSaga() {
  while (true) {
    const values = yield take(ActionTypes.updateBrokerColorSchema.REQUEST);
    yield call(updateBrokerColorSchemaSaga, values);
  }
}

function* getBrokerColorSchemaSaga() {
  try {
    const { data } = yield call(api.getBrokerColorSchema);
    yield put(ActionTypes.getBrokerColorSchema.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getBrokerColorSchema.failure(e));
  }
}

function* getBrokerColorSchemaWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BROKER_COLOR_SCHEMA[REQUEST]);
    yield call(getBrokerColorSchemaSaga);
  }
}

export default [fork(updateBrokerColorSchemaWatcherSaga), fork(getBrokerColorSchemaWatcherSaga)];
