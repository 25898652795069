import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserOperations {
    .LoadingText {
      text-align: center;
      padding-bottom: 10px;
    }
  }

  .UserOperationsTable {
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background: #c5c5c5;
      position: absolute;
      left: 50%;
      top: 0;
      max-width: 310px;
      transform: translateX(-50%);

      @include respond-to(sm) {
        max-width: 100%;
      }
    }

    &__col {
      width: 140px;
    }

    &__line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    &__key {
      font-size: 1rem;
      margin-right: rem(10px);
    }

    &__value {
      :global(.StatusCell__warning) {
        text-align: center;
      }
    }

    &__description {
      text-align: center;
      padding-bottom: 10px;
    }

    :global(.LoginCell__name) {
      display: none;
    }
  }

  :global(.UserOperations__loading) {
    text-align: center;
    padding-bottom: 10px;
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserOperationsTable {
      &__key {
        color: ${colors.gray.hex};
      }

      &__value {
        color: ${`rgba(${colors.black.rgb}, .8)`};

        :global(.MoneyCell__inner) {
          height: auto;

          :global(span) {
            font-size: 1rem !important;
            color: inherit !important;
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
