import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .PaymentTag {
    display: flex;
    justify-content: center;
    width: min-content;
    padding: 6px 16px;
    border-radius: 32px;
    border: 1px solid;

    &__title {
      color: #000 !important;
      white-space: nowrap;

      &--short {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export { staticStyles };
