import css from 'styled-jsx/css';

import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TemplatesPage__header {
    position: absolute;
    top: rem(-64px);
    right: ${isRTL ? 'auto' : '0'};
    left: ${isRTL ? '0' : 'auto'};
    @include respond-to(sm) {
      position: static;
      margin: rem(10px 0);
    }
  }
`;

export { staticStyles };
