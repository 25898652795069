import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .DateSelector {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: column;

    &__OpenOptionsButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #f3f3f3;
      background-color: #fff;
      border-radius: 8px;
      height: 100%;
      position: relative;
      font-size: 14px;
      line-height: 1.4;
      color: #cecece;

      &:disabled {
        background: #f9f9f9;
      }
    }

    &__OptionMulti--Selected {
      background: #f3fafe;
    }

    &__OpenOptionsButton--FastFilter {
      justify-content: flex-end;
    }

    &__Options {
      width: 200%;
      top: calc(100% + 4px);
      position: absolute;
      z-index: 4;
      display: grid;
      grid-template-columns: 50% 50%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 1.237px 2.714px 0px rgba(0, 0, 0, 0.02), 0px 3.127px 6.863px 0px rgba(0, 0, 0, 0.03),
        0px 6.38px 13.999px 0px rgba(0, 0, 0, 0.04), 0px 13.141px 28.836px 0px rgba(0, 0, 0, 0.05),
        0px 36px 79px 0px rgba(0, 0, 0, 0.07);

      &--mobile {
        position: static;
        box-shadow: none;
        width: 100%;

        .Calendar__datePicker-inner {
          left: 0;
          top: 0;
        }
      }
    }

    &__Options--OutOfRange {
      right: 0;
    }
    &__OptionsLeft {
      grid-row: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      border-right: 0.5px solid #e7e7e7;
    }
    &__OptionsRight {
      /* grid-row: 1; */
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 15px 10px 0 10px;
      border-left: 0.5px solid #e7e7e7;
    }

    &__OptionsRightTitle {
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #878787;
    }
    &__Option {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      height: 44px;
      width: 100%;
      position: relative;
      font-size: 14px;
      line-height: 1.4;
      color: #333;

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 0 0 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e9e9e9;
      }

      &:hover {
        background: #f3fafe;
      }
    }

    &__Option--FastFilterIsSelected {
      background: #f3fafe;
    }

    &__Option--FastFilter {
      height: 44px;
      min-height: 44px !important;
    }
    &__Labels {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
    }

    &__LabelsSelected {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      &--blue {
        color: #0aa1e2;
      }
    }

    &__LabelsSelectedCentered {
      flex: 1; /* Expand to fill remaining space */
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
    }

    &__LabelsAmount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 4px 0 4px;
    }
  }
`;

export { staticStyles };
