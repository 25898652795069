import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, ReduxTextField } from 'common/components';

import { editAdditionalField } from 'backoffice/payments/_redux';
import staticStyles from './style';

const EditAdditionalFieldsForm = ({
  textId,
  fieldName,
  type,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
  id,
}) => {
  const submit = handleSubmit(values =>
    editAdditionalField(
      { id, type, ...{ key: Object.entries(values)[0][0], value: Object.entries(values)[0][1] } },
      dispatch
    )
  );

  return (
    <div className="RejectModal__content">
      <form className="EditAdditionalFieldsForm" onSubmit={submit}>
        <div className="EditAdditionalFieldsForm__inputs-inner">
          <Field
            component={ReduxTextField}
            autoComplete="off"
            type="text"
            name={fieldName}
            textId={textId}
            validate={[required()]}
          />
        </div>

        <DefaultButton
          textId="justChange"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          filled
        />
        {error && <strong className="formError">{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

EditAdditionalFieldsForm.propTypes = {
  textId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

EditAdditionalFieldsForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'EditAdditionalFieldsForm',
})(EditAdditionalFieldsForm);
export { EditAdditionalFieldsForm };
