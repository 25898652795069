import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { PageLayout, PageContentPreloader, NoItemsPanel } from 'common/components';
import { ReloadIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import noMessagesImage from 'common/images/noMessages.svg';

import { getMessages, deleteMessage, toggleMessage } from '../_redux';
import { MessagesPanel } from '../_components';

import { staticStyles, getDynamicStyles } from './style';

class MessagesPage extends React.Component {
  constructor(props) {
    super(props);
    if (!props.initialized) {
      props.getMessages();
    }
  }

  componentDidMount() {
    const {
      history: {
        location: { hash },
      },
      activeMessage,
      messages,
    } = this.props;

    if (hash) {
      const id = Number(hash.substr(1));

      if (id && id !== activeMessage && messages.find(item => item.id === id)) {
        this.props.onMessageClick(id);
      }
    }
  }

  render() {
    const { messages, onMessageClick, onMessageDeleteClick, activeMessage, getMessages, cursor, loading, theme } =
      this.props;
    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    return (
      <PageLayout captionSlug="userMessages">
        <PageContentPreloader ready={!loading} firstLaunchOnly type="smallLines">
          {messages.length ? (
            <MessagesPanel
              messages={messages}
              onMessageClick={onMessageClick}
              onMessageDeleteClick={onMessageDeleteClick}
              activeMessage={activeMessage}
              loading
            />
          ) : (
            <NoItemsPanel
              icon={noMessagesImage}
              captionSlug="youHaveNoMessagesYet"
              descriptionSlug="youHaveNoMessagesYetDescr"
            />
          )}
          {cursor && (
            <FormattedMessage id="loadMore">
              {txt => (
                <div className="LoadMore__inner">
                  <button type="button" className="LoadMore" onClick={getMessages}>
                    <ReloadIcon /> {txt}
                  </button>

                  <style jsx>{dynamicStyles}</style>
                  <style jsx>{staticStyles}</style>
                </div>
              )}
            </FormattedMessage>
          )}
        </PageContentPreloader>
      </PageLayout>
    );
  }
}

MessagesPage.propTypes = {
  ...MessagesPanel.propTypes,
  getMessages: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  cursor: PropTypes.any,
  theme: PropTypes.object,
};

MessagesPage.defaultProps = {
  theme: {},
  cursor: null,
};

const ThemedMessagesPage = withTheme()(
  connect(
    state => ({
      messages: state.client.messages.messages,
      activeMessage: state.client.messages.activeMessage,
      loading: state.client.messages.loadingMessages,
      cursor: state.client.messages.messagesCursor,
      initialized: false,
    }),
    {
      getMessages: () => getMessages.request(),
      onMessageClick: id => toggleMessage(id),
      onMessageDeleteClick: id => deleteMessage.toggle(id),
    }
  )(MessagesPage)
);

export default ThemedMessagesPage;
export { MessagesPage };
