import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { BaseTable, Collapsible, NoItemsPanel, MoneyCell, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noPaymentsImage from 'common/images/noPayments.svg';
import { userGetEditHistory } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const UserAmountEditHistory = ({ userEditHistory, userEditHistoryIsLoaded, userGetEditHistory, accountId, theme }) => {
  const handleOpenCollapse = useMemo(() => {
    userGetEditHistory(accountId);
  }, [userGetEditHistory, accountId]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  /* eslint-disable */
  const columns = [
    {
      key: 'justDate',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      key: 'justAmount',
      Cell: ({ original: { amount } }) => <MoneyCell inTable value={amount} />,
      isPreviewCell: true,
    },
    {
      key: 'justPreviousAmount',
      Cell: ({ original: { prevAmount } }) => <MoneyCell inTable value={prevAmount} />,
      isPreviewCell: true,
    },

    {
      key: 'justComment',
      Cell: ({ original: { comment } }) => <div className="comment">{comment}</div>,
    },
  ];
  /* eslint-enable */

  const onRowClick = () => {};

  return (
    <div className="UserAmountEditHistory">
      <Collapsible captionId="justAmountEditHistory" handleOpen={handleOpenCollapse}>
        {userEditHistoryIsLoaded && userEditHistory.length ? (
          <BaseTable columns={columns} data={userEditHistory} onRowClick={onRowClick} isResponsive />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="justNoEditHistory" />
        )}
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserAmountEditHistory.propTypes = {
  userEditHistory: PropTypes.array.isRequired,
  userEditHistoryIsLoaded: PropTypes.bool.isRequired,
  userGetEditHistory: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      userEditHistory: state.client.payments.userEditHistory,
      userEditHistoryIsLoaded: state.client.payments.userEditHistoryIsLoaded,
    }),
    {
      userGetEditHistory: id => userGetEditHistory.request(id),
    }
  )
)(UserAmountEditHistory);
export { UserAmountEditHistory };
