import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InfoLine {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: rem(20px);
    span,
    button {
      display: inline-block;
      font-size: rem(13px);
      width: 45%;
      padding-right: rem(${isRTL ? '0' : '15px'});
      padding-left: rem(${isRTL ? '15px' : '0'});
    }

    .InfoLine__right {
      display: inline-block;
      width: 55%;
      padding-right: rem(${isRTL ? '0' : '35px'});
      padding-left: rem(${isRTL ? '35px' : '0'});
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      span,
      button {
        padding: 0;
        width: auto;
        font-size: rem(15px);
      }

      a {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ActionsButton {
        position: absolute;
      }

      :global(.DateCell) {
        display: inline-block;
      }
    }
  }

  .InfoLine__clip {
    .InfoLine__right {
      text-overflow: clip;
    }
  }

  .InfoLine__break {
    span,
    button,
    a {
      word-break: break-all;
    }
  }

  .InfoLine__active-text {
    transition: all 0.4s ease;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InfoLine {
      span {
        color: ${colors.gray.hex};
      }
      .InfoLine__right {
        span {
          color: ${colors.black.hex};
        }
      }
      a {
        color: ${colors.secondary.hex};
        &:hover {
          color: ${colors.primary.hex};
        }
      }
    }

    .InfoLine__active-text {
      color: ${colors.secondary.hex};
      &:hover {
        color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
