import React, { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MainLogo, ChangeLangButton, CustomJs } from 'common/components';
import { ReduxThemeProvider, withTheme } from 'common/styling/theme';
import { LoginPage } from 'auth/login';
import { RegistrationPage, EmailConfirmationPage } from 'auth/registration';
import { ConfirmResetPasswordPage, ResetPasswordPage } from 'auth/resetPassword';
import { ResendEmailConfirmationPage } from 'auth/resendEmailConfirmation';

import AuthSlider from '../AuthSlider';
import AuthRightBg from '../AuthRightBg';

import { staticStyles, getDynamicStyles } from './style';

const Auth = ({ authBanners, match, currentLang, customJsAuthPage, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const images = useMemo(
    () => authBanners.filter(banner => banner.language === currentLang),
    [authBanners, currentLang]
  );

  return (
    <ReduxThemeProvider>
      <div className="Auth">
        <div className="Auth__left-inner">
          <div className="Auth__form-inner">
            <MainLogo className="Auth__logo" currentInterface="auth" />
            <Switch>
              <Route exact path={`${match.path}/login`} component={LoginPage} />
              <Route exact path={`${match.path}/register`} component={RegistrationPage} />
              <Route exact path={`${match.path}/email-confirmation/:code`} component={EmailConfirmationPage} />
              <Route exact path={`${match.path}/password-reset`} component={ResetPasswordPage} />
              <Route exact path={`${match.path}/confirm-reset-password/:code`} component={ConfirmResetPasswordPage} />
              <Route exact path={`${match.path}/resend-email-confirmation`} component={ResendEmailConfirmationPage} />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
          <ChangeLangButton className="AuthLangButton" />
        </div>
        {images.length ? <AuthSlider slides={images} /> : <AuthRightBg />}

        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </div>
      {!!customJsAuthPage && <CustomJs content={customJsAuthPage} />}
    </ReduxThemeProvider>
  );
};

Auth.propTypes = {
  currentLang: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  authBanners: PropTypes.array.isRequired,
  customJsAuthPage: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

Auth.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      authBanners: state.interfaceConfig.authBanners,
      currentLang: state.interfaceConfig.lang,
      customJsAuthPage: state.interfaceConfig.customJs?.authpagejs ?? '',
    }),
    null
  )
)(Auth);
export { Auth };
