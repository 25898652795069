import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InfoBlock {
    grid-column: 6/9;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px;
    background: #f0f4f6;
    border-radius: 4px;
    height: fit-content;
    font-size: 15px;
    line-height: 22px;

    @include respond-to(sm) {
      order: -1;
      width: auto;
      overflow-y: auto;
      max-width: 100%;
    }
  }

  .ConnectToMTInfoBlock {
    grid-column: 6/9;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include respond-to(sm) {
      order: -1;
      width: 100%;
    }
    .ConnectToMTInfoSubBlock {
      width: 100%;
      min-height: 159px;
      display: flex;
      padding: 16px;
      font-size: 15px;
      line-height: 22px;
      background: #f0f4f6;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      @include respond-to(sm) {
        flex-direction: row;
        align-items: flex-end;
        min-height: fit-content;
        width: 100%;
      }
      &__titles {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__title {
        font-size: 18px;
        line-height: 24px;
        @include respond-to(sm) {
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }
      > .DefaultButton {
        width: max-content;
        min-width: max-content;
        height: max-content;
        background-color: white;
        padding: 8px 16px;
      }
    }
  }

  .DomainVerificationInfoBlock {
    display: flex;
    background: #f0f4f6;
    flex-direction: column;
    gap: 24px;
    .DomainVerificationSubBlock &__title {
    }
    &__text {
    }
  }
`;

export { staticStyles };
