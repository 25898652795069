import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .Notification {
    padding: 7px 30px 7px 0;
    position: relative;
    &:last-child {
      margin: 0;
    }
    p {
      max-height: 41px;
      max-width: 230px;
      overflow: hidden;
    }
    a, button {
      font-size: rem(15px);
    }
  }
  
  .Notification__close-button {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 19px;
    height: 19px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 17px;
      height: 1px;
      transform: rotate(45deg);
      transition: all 0.4s ease;
    }
    &:after {
      transform: rotate(135deg);
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Notification {
      a, button {
        color: ${colors.white.hex};
        &:hover {
          color: ${colors.lightGray.hex};
        }
      }
    }

    .Notification__close-button {
      &:hover {
        &:before,
        &:after {
          background: ${colors.error.hex};
        }
      }
      &:before,
      &:after {
        background-color: ${colors.white.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
