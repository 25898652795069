import axios from 'axios';

export const getInvestYieldChartNew = strategyId =>
  axios.get(`/invest_crm/strategy/${strategyId}/chart/`, {
    validateStatus: status => status < 300 || status === 400,
  });

export const getInvestStrategiesNew = search => axios.get(`/invest_crm/strategy/rating/`, { params: { ...search } });

export const getMyInvestStrategiesNew = search => axios.get(`/invest_crm/strategy/list/`, { params: { ...search } });

export const createInvestStrategyNew = data => axios.post(`/invest_crm/strategy/create/`, { ...data });

export const editInvestStrategyNew = ({ strategyId, data }) =>
  axios.put(`/invest_crm/strategy/${strategyId}/change/`, { ...data });

export const retrieveInvestStrategyNew = strategyId => axios.get(`/invest_crm/strategy/${strategyId}/`);

export const getStrategyInvestmentsNew = (strategyId, search) =>
  axios.get(`/invest_crm/strategy/${strategyId}/investment/list/`, {
    params: { ...search },
    validateStatus: status => status < 300 || status === 400,
  });

export const addStrategyInvestmentsNew = payload => axios.post(`/invest_crm/investment_request/open/`, { ...payload });

export const getInvestStrategyApproveNew = (strategyId, search) =>
  axios.get(`/invest_crm/strategy/${strategyId}/investment_request/list/`, {
    params: { ...search, page: +search.page === 0 ? undefined : search.page },
  });

export const setInvestmentsActionNew = (investmentId, action, payload) =>
  axios.post(`/invest_crm/investment_request/${investmentId}/${action}/`, payload);

export const getInvestmentsNew = search => axios.get(`/invest_crm/investment/list/`, { params: { ...search } });

export const getInvestmentsRequestsNew = search =>
  axios.get(`/invest_crm/investment_request/list/`, { params: { ...search } });

export const closeInvestmentRequestNew = (account, strategy) =>
  axios.post(`/invest_crm/investment_request/close/`, {
    account,
    strategy,
  });

export const withdrawInvestmentRequestNew = (strategy, amount, account) =>
  axios.post(`/invest_crm/investment_request/withdraw/`, {
    strategy,
    amount,
    account,
  });

export const depositInvestmentRequestNew = (strategy, amount, account) =>
  axios.post(`/invest_crm/investment_request/deposit/`, {
    strategy,
    amount,
    account,
  });

export const getInvestmentTradesNew = (investmentId, tradeType = 'open', start, end) =>
  axios.get(`/invest_crm/investment/${investmentId}/${tradeType === 'open' ? 'open' : 'closed'}/`, {
    params: start && end && tradeType === 'closed' ? { start, end } : undefined,
  }); // open, closed

export const getInvestStatementNew = investmentId => axios.get(`/invest_crm/investment/${investmentId}/`);

export const setInvestStrategyActionNew = (strategyId, action, data) =>
  axios.post(`/invest_crm/strategy/${strategyId}/${action}/`, { ...data }); // pause, resume, ONLY close for now, chart, symbols_stats
