import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import { Calendar } from 'common/components';

const ModalDropDown = ({ setIsOpen, selectebleOptions, onSelect }) => (
  <div className={classNames('DateSelector__Options', 'DateSelector__Options--mobile')}>
    <div className="DateSelector__OptionsLeft">
      {selectebleOptions.map(({ value, label, isSelected }) => (
        <button
          key={`DateSelector-options-${label}`}
          className={classNames('DateSelector__Option DateSelector__Option--FastFilter', {
            'DateSelector__Option--FastFilterIsSelected': isSelected,
          })}
          type="button"
          onClick={() => {
            onSelect({ value, label });
            setIsOpen();
          }}>
          <FM id={label}>{txt => txt}</FM>
        </button>
      ))}
    </div>
    <div className="DateSelector__OptionsRight">
      <FM id="justChooseCustomRange">{txt => <span className="DateSelector__OptionsRightTitle">{txt}</span>}</FM>
      <Calendar
        daysLimit={1000}
        isUnix={false}
        onChangeFunc={(...value) => {
          onSelect({ value: value.map(val => val.valueOf()), label: 'justCustomRange' });
          setIsOpen();
        }}
      />
    </div>
  </div>
);

ModalDropDown.propTypes = {
  selectebleOptions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ModalDropDown;
