import React, { useState } from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { length, required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { ReduxTextField } from '../../form-fields';
import DefaultButton from '../../DefaultButton';

import { wsConfirmPasswordReset } from '../../../_redux';

import { staticStyles } from './style';

const validate = values => {
  const errors = {};

  if (values.newPassword !== values.repeatNewPassword)
    errors.repeatNewPassword = <FormattedMessage id="form.errors.passwordRepeat" />;

  return errors;
};

const ConfirmResetPasswordForm = ({ match, pristine, submitting, invalid, error, handleSubmit, dispatch }) => {
  const { token } = match.params;
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const actionTooltipId = passwordFieldType === 'password' ? 'showPassword' : 'hidePassword';
  const actionClassName = passwordFieldType === 'password' ? 'show' : 'hide';

  const showPassword = () => {
    if (passwordFieldType === 'password') setPasswordFieldType('text');
    else setPasswordFieldType('password');
  };

  const submit = handleSubmit(formValues => wsConfirmPasswordReset({ ...formValues, token }, dispatch));

  return (
    <form className="ConfirmResetPasswordForm" onSubmit={submit}>
      <Field
        component={ReduxTextField}
        type={passwordFieldType}
        name="newPassword"
        textId="authPassword"
        validate={[required(), length({ min: 6 })]}
        disabled={submitting}
        action={showPassword}
        actionTooltipId={actionTooltipId}
        actionClassName={actionClassName}
      />
      <Field
        component={ReduxTextField}
        type="password"
        name="repeatNewPassword"
        textId="authPasswordRepeat"
        validate={[required()]}
        disabled={submitting}
      />
      <DefaultButton
        textId="authChangePassword"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
        wide
        isUpperCase
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

ConfirmResetPasswordForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

ConfirmResetPasswordForm.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  reduxForm({
    form: 'wsConfirmResetPasswordForm',
    validate,
    onSubmitSuccess: (result, dispatch, { history }) => {
      history.push('/welcome-setup/login?message=passwdResetSuccess');
    },
  })
)(ConfirmResetPasswordForm);
export { ConfirmResetPasswordForm };
