import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const registration = createFormAction('REGISTRATION');

export const TOGGLE_PARTNER_CODE = 'TOGGLE_PARTNER_CODE';
export const togglePartnerCodeAction = () => action(TOGGLE_PARTNER_CODE);

export const TOGGLE_PROMO_CODE = 'TOGGLE_PROMO_CODE';
export const togglePromoCodeAction = () => action(TOGGLE_PROMO_CODE);

export const CONFIRM_EMAIL = createRequestTypes('CONFIRM_EMAIL');
export const confirmEmail = {
  request: code => action(CONFIRM_EMAIL[REQUEST], { code }),
  success: data => action(CONFIRM_EMAIL[SUCCESS], { data }),
  failure: error => action(CONFIRM_EMAIL[FAILURE], { error }),
};

export const TOGGLE_CONFIRM_EMAIL_MODAL = 'TOGGLE_CONFIRM_EMAIL_MODAL';
export const toggleConfirmEmailModal = () => action(TOGGLE_CONFIRM_EMAIL_MODAL);

export const getOTPTokenFromReg = createFormAction('GET_OTP_TOKEN_FROM_REG');

export const getJWTTokenFromReg = createFormAction('GET_JWT_TOKEN_FROM_REG');

export const LOGIN_WITH_TELEGRAM_FROM_REG = createRequestTypes('LOGIN_WITH_TELEGRAM_FROM_REG');
export const loginWithTelegramFromReg = {
  request: data => action(LOGIN_WITH_TELEGRAM_FROM_REG[REQUEST], { data }),
  success: data => action(LOGIN_WITH_TELEGRAM_FROM_REG[SUCCESS], { data }),
  failure: error => action(LOGIN_WITH_TELEGRAM_FROM_REG[FAILURE], { error }),
};
