import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ModalHOC } from 'common';
import { PaginationTable, MoneyCell, DefaultButton, DateCell } from 'common/components';

import { FormattedMessage } from 'react-intl';
import { getInvestStrategyApprove, setInvestmentsAction } from '../../_redux';
import { staticStyles } from './style';

const InvestStrategyApproveTable = ({
  strategyId,
  hideActionButtons,
  investAwaitinigApprove,
  investAwaitinigApproveAreLoaded,
  location,
  history,
  params,
  getInvestStrategyApprove,
  setInvestmentsAction,
  showModal,
  pendingInvestApproveStrategy,
  pendingInvestRejectStrategy,
  investStrategyId,
}) => {
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(
    search => {
      getInvestStrategyApprove({
        strategyId,
        search,
      });
    },
    [getInvestStrategyApprove, strategyId]
  );

  useEffect(() => {
    getList(query);
  }, [getList, query]);

  const handleApprove = id => {
    setInvestmentsAction({
      investmentId: id,
      action: 'approve',
      strategyId,
    });
  };

  const handleRejectApprove = id => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'investConfirmRejectInvesting',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => {
          setInvestmentsAction({
            investmentId: id,
            action: 'reject',
            strategyId,
          });
        },
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const renderActionButtons = (id, inAutoRollover) =>
    inAutoRollover ? (
      <FormattedMessage id="investAutoOrderApprove" />
    ) : (
      <div className="InvestStrategyApproveTable__actions">
        <DefaultButton
          textId="justApprove"
          type="button"
          butColor="success"
          onClick={() => handleApprove(id)}
          loading={pendingInvestApproveStrategy && id === investStrategyId}
          disabled={pendingInvestApproveStrategy && id === investStrategyId}
        />
        <DefaultButton
          textId="justReject"
          type="button"
          butColor="error"
          onClick={() => handleRejectApprove(id)}
          loading={pendingInvestRejectStrategy && id === investStrategyId}
          disabled={pendingInvestRejectStrategy && id === investStrategyId}
        />
      </div>
    );

  /* eslint-disable */
  const columns = [
    {
      accessor: 'userId',
      Header: 'justId',
      Cell: ({
        row: {
          original: { userId },
        },
      }) => <div className="InvestStrategyApproveTable__investId">{userId}</div>,

      isPreviewCell: true,
    },
    {
      accessor: 'userName',
      Header: 'justName',
      Cell: ({
        row: {
          original: { userName },
        },
      }) => <div className="InvestStrategyApproveTable__investId">{userName}</div>,

      isPreviewCell: true,
    },
    {
      accessor: 'type',
      Header: 'justType',
      Cell: ({
        row: {
          original: { operation },
        },
      }) => <span>{operation}</span>,

      isPreviewCell: true,
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount },
        },
      }) => <MoneyCell value={{ amount: amount.amount, currency: amount.currency }} />,
    },
    {
      accessor: 'created',
      Header: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    ...(!hideActionButtons
      ? [
          {
            accessor: 'actions',
            Header: () => null,
            Cell: ({
              row: {
                original: { id, inAutoRollover },
              },
            }) => renderActionButtons(id, inAutoRollover),
          },
        ]
      : []),
  ];
  /* eslint-enable */

  return (
    <div className="InvestStrategyApproveTable">
      <PaginationTable
        data={investAwaitinigApprove}
        columns={columns}
        loading={!investAwaitinigApproveAreLoaded}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestStrategyApproveTable.propTypes = {
  strategyId: PropTypes.number.isRequired,
  investAwaitinigApprove: PropTypes.object.isRequired,
  investAwaitinigApproveAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategyApprove: PropTypes.func.isRequired,
  setInvestmentsAction: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  pendingInvestApproveStrategy: PropTypes.bool.isRequired,
  pendingInvestRejectStrategy: PropTypes.bool.isRequired,
  investStrategyId: PropTypes.number,
  hideActionButtons: PropTypes.bool.isRequired,
};

InvestStrategyApproveTable.defaultProps = {
  investStrategyId: null,
};

export default compose(
  ModalHOC,
  connect(
    state => ({
      investAwaitinigApprove: state.client.invest.investAwaitinigApprove,
      investAwaitinigApproveAreLoaded: state.client.invest.investAwaitinigApproveAreLoaded,
      pendingInvestApproveStrategy: state.client.invest.pendingInvestApproveStrategy,
      pendingInvestRejectStrategy: state.client.invest.pendingInvestRejectStrategy,
      investStrategyId: state.client.invest.investStrategyId,
    }),
    {
      getInvestStrategyApprove: data => getInvestStrategyApprove.request(data),
      setInvestmentsAction: data => setInvestmentsAction.request(data),
    }
  )
)(InvestStrategyApproveTable);
