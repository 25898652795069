import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { DefaultButton, BaseSelectField } from 'common/components';

import { getPaymentTags, changePaymentTags } from 'backoffice/payments/_redux';

import { staticStyles } from './style';

const EditTagsForm = ({
  existingTags,
  toggleModal,
  tags,
  paymentType,
  paymentId,
  getPaymentTags,
  changePaymentTags,
}) => {
  const paymentsMap = {
    deposits: 'deposit',
    withdrawals: 'withdraw',
  };

  useEffect(() => {
    getPaymentTags(paymentsMap[paymentType]);
  }, [getPaymentTags]);

  const tagsMap = tags.reduce((acc, item) => {
    acc[item.id] = { ...item };

    return acc;
  }, {});

  const [currentTags, setCurrentTags] = useState(existingTags);

  const paymentOptions = tags.map(tag => ({ label: tag.name, value: tag.id }));

  const handleChange = (selectedTags, { removedValue }) => {
    if (removedValue) {
      const removedTag = tagsMap[removedValue.value];
      setCurrentTags(tags => tags.filter(tag => tag.id !== removedTag.id));

      return;
    }

    const newTags = selectedTags.map(selectedTag => tagsMap[selectedTag.value]);
    setCurrentTags(newTags);
  };

  const handleChangeTags = () => {
    changePaymentTags({ type: paymentsMap[paymentType], paymentId, tags: currentTags.map(tag => tag.id) });

    toggleModal();
  };

  return (
    <div className="EditTagsForm">
      <div className="EditTagsForm__inner">
        <FormattedMessage id="justAddNewTag" />
        <BaseSelectField
          name="tags"
          textId="justSelectNewTag"
          value={currentTags.map(tag => ({ label: tag.name, value: tag.id }))}
          onChange={handleChange}
          isMulti
          options={paymentOptions}
        />
      </div>
      <DefaultButton onClick={handleChangeTags} textId="justChange" filled />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

EditTagsForm.propTypes = {
  getPaymentTags: PropTypes.func.isRequired,
  changePaymentTags: PropTypes.func.isRequired,
  paymentType: PropTypes.string.isRequired,
  existingTags: PropTypes.array,
  tags: PropTypes.array,
};

EditTagsForm.defaultProps = {
  existingTags: [],
  tags: [],
};

export default connect(
  state => ({
    tags: state.backoffice.payments.paymentTags,
  }),
  {
    getPaymentTags: type => getPaymentTags.request(type),
    changePaymentTags: data => changePaymentTags.request(data),
  }
)(EditTagsForm);
export { EditTagsForm };
