import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';

import { resendOTPToken } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const ResendOTPButton = ({ sending, codeTimer, resend, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <>
      <FormattedMessage id="justResend">
        {txt => (
          <button
            className="ResendOTPButton"
            type="button"
            onClick={() => sending || resend()}
            disabled={sending || codeTimer}>
            {txt}
          </button>
        )}
      </FormattedMessage>

      {!!codeTimer && (
        <FormattedMessage id="justWaitSec" values={{ timer: codeTimer }}>
          {txt => <span className="ResendOTPButton__timer">{txt}</span>}
        </FormattedMessage>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </>
  );
};

ResendOTPButton.propTypes = {
  sending: PropTypes.bool.isRequired,
  resend: PropTypes.func.isRequired,
  theme: PropTypes.object,
  codeTimer: PropTypes.number,
};

ResendOTPButton.defaultProps = {
  theme: {},
  codeTimer: 0,
};

const ThemedResendOTPButton = withTheme()(
  connect(
    state => ({
      sending: state.auth.login.resendingOTP,
      codeTimer: state.auth.login.codeTimer,
    }),
    { resend: () => resendOTPToken.request() }
  )(ResendOTPButton)
);

export default ThemedResendOTPButton;
export { ResendOTPButton };
