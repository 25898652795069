import css from 'styled-jsx/css';

const staticStyles = css`
  .RejectModal__content {
    width: 450px;
  }

  .EditLPAForm {
    &__revoke {
      margin-bottom: 15px;
      display: flex;
      justify-content: end;
    }
    &__btns {
      display: flex;
      :global(.DefaultButton) {
        margin-right: 15px;
      }

      :global(.DefaultButton:last-child) {
        margin-left: auto;
        text-transform: unset;
      }
    }
  }
`;

export default staticStyles;
