import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const LeftNavigationOpenLinks = ({ prefix, item, onClick }) => {
  const handleClick = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    onClick();
  };

  return (
    <NavLink to={prefix + item.url} onClick={handleClick} className="some">
      <FormattedMessage id={item.title} />
    </NavLink>
  );
};

LeftNavigationOpenLinks.propTypes = {
  prefix: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

LeftNavigationOpenLinks.defaultProps = {
  onClick: null,
};

export default LeftNavigationOpenLinks;
