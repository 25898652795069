import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.ReportsPage) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 25px 25px 25px 25px;

    @include respond-to(md) {
      padding: 15px 15px 0 15px;
    }

    :global(.ReportsPage__tabs) {
      height: 97%;
    }
  }
`;

export { staticStyles };
