import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const HomeIcon = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 20 19" version="1.1">
      <defs>
        <linearGradient x1="49.9102156%" y1="100.052329%" x2="49.9102156%" y2="0.0348610068%" id="linearGradient-1">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ui---blocks"
          transform="translate(-423.000000, -712.000000)"
          fill="url(#linearGradient-1)"
          className="white"
          fillRule="nonzero">
          <g id="accounts" transform="translate(423.000000, 712.000000)">
            <path
              d="M19.7827515,8.59331183 L10.3864476,0.233311828 C10.3704806,0.217476776 10.3525382,0.203744674 10.3330595,0.192451613 C10.1237477,-0.00517058693 9.79722945,-0.0104962936 9.58151951,0.180193548 L0.185215606,8.53978495 C-0.0378027325,8.74424884 -0.054854285,9.0888635 0.146900806,9.31413331 C0.348655898,9.53940311 0.694688521,9.56211252 0.924435318,9.36516129 L2.6164271,7.85741935 L2.6164271,17.6066667 C2.6164271,18.3739268 3.24157366,18.995914 4.01273101,18.995914 L6.97782341,18.995914 C7.7011627,18.9954632 8.28743193,18.4121568 8.28788501,17.6924731 L8.28788501,12.7365376 C8.28811094,12.6260552 8.37807338,12.5365474 8.48911704,12.5363226 L11.4829569,12.5363226 C11.5940005,12.5365474 11.683963,12.6260552 11.6841889,12.7365376 L11.6841889,17.6924731 C11.684642,18.4121568 12.2709112,18.9954632 12.9942505,18.995914 L15.9593429,18.995914 C16.7396304,18.995914 17.3556468,18.231828 17.3556468,17.2552688 L17.3556468,7.91094624 L19.0476386,9.41868817 C19.2772905,9.62014341 19.6271444,9.60009658 19.8320329,9.37374194 C20.0296711,9.14289205 20.0078757,8.79773496 19.7827515,8.59331183 Z M16.2427105,11.3182796 L16.2427105,17.2470968 C16.2427105,17.6434409 16.0455852,17.8845161 15.9552361,17.8845161 L12.9901437,17.8845161 C12.8791001,17.8842913 12.7891376,17.7947836 12.7889117,17.6843011 L12.7889117,12.7365376 C12.7884586,12.0168539 12.2021894,11.4335476 11.4788501,11.4330968 L8.48501027,11.4330968 C7.76167098,11.4335476 7.17540174,12.0168539 7.17494867,12.7365376 L7.17494867,17.6924731 C7.17472274,17.8029556 7.0847603,17.8924634 6.97371663,17.8926882 L4.00862423,17.8926882 C3.84985654,17.8926882 3.7211499,17.764632 3.7211499,17.6066667 L3.7211499,6.86901075 L9.95523614,1.32836559 L16.2427105,6.92212903 L16.2427105,11.3182796 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

HomeIcon.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

HomeIcon.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedHomeIcon = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(HomeIcon);

export default ThemedHomeIcon;
