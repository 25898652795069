import css from 'styled-jsx/css';

const staticStyles = css.global`
  .ReportOnlineTable {
    .rt-th,
    .rt-td {
      width: 150px !important;
      white-space: break-spaces !important;
    }
    .rt-tbody {
      overflow: initial;
    }
    .rt-tr-group {
      box-shadow: none !important;
    }
  }
`;

export { staticStyles };
