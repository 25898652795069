import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .PaymentItem__Description {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .PaymentItem__accountType {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #333;

    @include respond-to(xs) {
      margin-left: 44px;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }

  .PaymentItem__status {
    padding: 4px 12px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
    border-radius: 24px;

    &--success {
      background: #39b841;
    }

    &--waiting {
      background: #f2b233;
    }

    &--failed {
      background: #ee3235;
    }
  }

  .PaymentItem__info {
    display: flex;
    gap: 24px;

    @include respond-to(xs) {
      flex-direction: column;
      gap: 4px;
    }

    &-title {
      font-size: 12px;
      color: #999;
    }

    &-content {
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }
  }
`;

export { staticStyles };
