import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';

import { staticStyles } from './style';

const InternalTransferItem = ({ paymentStatus, sender, recipient, sendAmount }) => {
  const statuses = {
    success: <FM id="justSuccess" />,
    waiting: <FM id="justInProgress" />,
    failed: <FM id="justError" />,
  };

  return (
    <div className="InternalTransferItem">
      <div className="TabFeedItem__Title">
        <FM id="crmInternalTransfer" />
      </div>
      <div className="InternalTransferItem__Transfer">
        <div className="InternalTransferItem__Transfer--description">
          <span className="InternalTransferItem--bold">{sender}</span>
          <FM id="justTo" />
          <span className="InternalTransferItem--bold">{recipient}</span>
        </div>
        <div className={classNames('InternalTransferItem__Status', `InternalTransferItem__Status--${paymentStatus}`)}>
          {statuses[paymentStatus]}
        </div>
      </div>
      <div className="InternalTransferItem__Description">
        <span>Summ: </span>
        <span className="InternalTransferItem--bold">{`${sendAmount?.amount} ${sendAmount?.currency}`}</span>
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InternalTransferItem.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  sender: PropTypes.string,
  recipient: PropTypes.string,
  sendAmount: PropTypes.object,
};

InternalTransferItem.defaultProps = {
  sender: '',
  recipient: '',
  sendAmount: {},
};

export default InternalTransferItem;
