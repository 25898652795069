import React from 'react';
import PropTypes from 'prop-types';

import { DateCell } from 'common/components';
import { useHistory } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { TaskIcon } from '../TaskIcon';

import { staticStyles, getDynamicStyles } from './style';

const TasksPlanItem = ({ task, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/backoffice/tasks/${task.id}`);
  };

  return (
    <button className="TasksPlanItem" type="button" onClick={() => handleClick()}>
      <div className="TasksPlanItem__icon">
        <TaskIcon type={task.icon} />
      </div>
      <div className="TasksPlanItem__content">
        <div className="TasksPlanItem__top">
          <DateCell value={task.expirationDate} />
          {!!task.subject && (
            <span className="TasksPlanItem__subject">{`${task.subject.firstName} ${task.subject.lastName}`}</span>
          )}
        </div>
        <div className="TasksPlanItem__text">{task.title}</div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </button>
  );
};

TasksPlanItem.propTypes = {
  task: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

TasksPlanItem.defaultProps = {
  theme: {},
};

export default withTheme()(TasksPlanItem);
export { TasksPlanItem };
