import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { toggleReuploadDocuments, sendDocuments } from '../../_redux';
import DocumentsLoadPanel from '../DocumentsLoadPanel';

const DocumentsForm = ({
  error,
  documents,
  toggleReuploadDocuments,
  sendDocuments,
  documentsReupload,
  documentsUploadErrors,
  profileStatus,
  passport,
  addressProof,
  identificationProof,
  passportWithFace,
  additionalDocument,
  uploadedDocuments,
}) => {
  const {
    passport: passportP,
    address_proof: addressP,
    identification_proof: identificationP,
    passport_with_face: passportWithFaceP,
    additional_document: additionalDocumentP,
  } = uploadedDocuments || {};

  return (
    <form>
      {passport && passport.enable && (
        <DocumentsLoadPanel
          captionId="copyOfPassportWithResidAddress"
          descriptionId="sngCaseWithPassportAndRegistration"
          name="passport"
          status={documents.passportStatus}
          onClickAction={toggleReuploadDocuments}
          reupload={documentsReupload.passport}
          uploadError={documentsUploadErrors.passport}
          profileStatus={profileStatus}
          mandatory={passport.mandatory}
          fileNameId="justPassport"
          onUplaod={sendDocuments}
          docLimit={10}
          uploadedDocs={passportP || 0}
        />
      )}
      {passportWithFace && passportWithFace.enable && (
        <DocumentsLoadPanel
          captionId="proofOfLife"
          descriptionId="proofOfLIfeDescription"
          name="passport_with_face"
          status={documents.passportWithFaceStatus}
          onClickAction={toggleReuploadDocuments}
          reupload={documentsReupload.identification_proof}
          uploadError={documentsUploadErrors.identification_proof}
          profileStatus={profileStatus}
          mandatory={passportWithFace.mandatory}
          fileNameId="proofOfLife"
          onUplaod={sendDocuments}
          docLimit={10}
          uploadedDocs={passportWithFaceP || 0}
        />
      )}
      {addressProof && addressProof.enable && (
        <DocumentsLoadPanel
          captionId="proofResidAddress"
          descriptionId="proofResidAddressDescr"
          name="address_proof"
          status={documents.addressProofStatus}
          onClickAction={toggleReuploadDocuments}
          reupload={documentsReupload.address_proof}
          uploadError={documentsUploadErrors.address_proof}
          profileStatus={profileStatus}
          mandatory={addressProof.mandatory}
          fileNameId="addressProof"
          onUplaod={sendDocuments}
          docLimit={10}
          uploadedDocs={addressP || 0}
        />
      )}
      {identificationProof && identificationProof.enable && (
        <DocumentsLoadPanel
          captionId="proofOfIdent"
          descriptionId="poiDescription"
          name="identification_proof"
          status={documents.identificationProofStatus}
          onClickAction={toggleReuploadDocuments}
          reupload={documentsReupload.identification_proof}
          uploadError={documentsUploadErrors.identification_proof}
          profileStatus={profileStatus}
          mandatory={identificationProof.mandatory}
          fileNameId="identificationPoof"
          onUplaod={sendDocuments}
          docLimit={10}
          uploadedDocs={identificationP || 0}
        />
      )}

      {additionalDocument && additionalDocument.enable && (
        <DocumentsLoadPanel
          captionId="additionalDocumentCaption"
          descriptionId="additionalDocumentDescription"
          name="additional_document"
          status={documents.additionalDocumentStatus}
          onClickAction={toggleReuploadDocuments}
          reupload={documentsReupload.additional_document}
          uploadError={documentsUploadErrors.additional_document}
          profileStatus={profileStatus}
          fileNameId="additionalDocument"
          onUplaod={sendDocuments}
          mandatory
          docLimit={10}
          uploadedDocs={additionalDocumentP || 0}
        />
      )}

      <style jsx>{`
        .SUMSUB_FORM {
          width: 100%;
          height: 100px;
          background: white;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>

      {error && <strong className="formError">{error}</strong>}
    </form>
  );
};

DocumentsForm.propTypes = {
  documentsReupload: PropTypes.object,
  toggleReuploadDocuments: PropTypes.func.isRequired,
  error: PropTypes.string,
  documents: PropTypes.object,
  documentsUploadErrors: PropTypes.object,
  profileStatus: PropTypes.string.isRequired,
  passport: PropTypes.object.isRequired,
  identificationProof: PropTypes.object.isRequired,
  passportWithFace: PropTypes.object.isRequired,
  addressProof: PropTypes.object.isRequired,
  additionalDocument: PropTypes.object.isRequired,
  sendDocuments: PropTypes.func.isRequired,
  uploadedDocuments: PropTypes.object,
};

DocumentsForm.defaultProps = {
  documentsReupload: {},
  error: '',
  documents: {},
  documentsUploadErrors: {},
  uploadedDocuments: {},
};

const mapStateToProps = (state, ownProps) => ({
  documents: { ...ownProps.documents },
});

const ThemedDocumentsForm = compose(
  connect(
    state => ({
      documentsReupload: state.client.kyc.documentsReupload,
      documentsUploadErrors: state.client.kyc.documentsUploadErrors,
      passport: state.interfaceConfig.kycSettings.passport,
      identificationProof: state.interfaceConfig.kycSettings.identification_proof,
      addressProof: state.interfaceConfig.kycSettings.address_proof,
      passportWithFace: state.interfaceConfig.kycSettings.passport_with_face,
      additionalDocument: state.interfaceConfig.kycSettings.additional_document,
      uploadedDocuments: state.user.profile.uploadedDocuments,
    }),
    {
      toggleReuploadDocuments: name => toggleReuploadDocuments(name),
      sendDocuments: data => sendDocuments.request(data),
    }
  ),
  reduxForm({ form: 'DocumentsForm' }, mapStateToProps)
)(DocumentsForm);

export default ThemedDocumentsForm;
export { DocumentsForm };
