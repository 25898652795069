import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { PageLayout, PageContentPreloader, BaseTextField } from 'common/components';
import Question from 'common/components/Question';

import { getQuestions } from '../../_redux';

import { staticStyles } from './style';

const FaqDetailPage = ({ questions, questionsAreLoaded, getQuestions, categoryTitle, match }) => {
  const categoryId = match.params.id;

  const [search, setSearch] = useState('');
  const [searchableQuestions, setSearchableQuestions] = useState(questions);

  /* eslint-disable */
  useEffect(() => {
    getQuestions(categoryId)
  }, [getQuestions])
  /* eslint-enable */

  const handleChangeSearch = e => {
    const value = e.target.value.toLowerCase();

    const filter = questions.filter(question => question.title.toLowerCase().includes(value));

    setSearch(value);
    setSearchableQuestions(filter);
  };
  const qs = search ? searchableQuestions : questions;

  return (
    <PageLayout captionSlug="justFaq" withBackLink>
      <PageContentPreloader ready={questionsAreLoaded} type="bigLine">
        <div className="FaqDetailPage">
          <h2 className="FaqDetailPage__name">{categoryTitle}</h2>
          <div className="FaqDetailPage__search">
            <BaseTextField type="text" name="search" textId="justSearch" onChange={handleChangeSearch} value={search} />
          </div>
          {questionsAreLoaded && (
            <div className="FaqDetailPage__questions">
              {qs.map(question => (
                <Question question={question} key={question.id} />
              ))}
            </div>
          )}
        </div>
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </PageLayout>
  );
};

FaqDetailPage.propTypes = {
  questions: PropTypes.array,
  questionsAreLoaded: PropTypes.bool.isRequired,
  getQuestions: PropTypes.func.isRequired,
  categoryTitle: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

FaqDetailPage.defaultProps = {
  questions: [],
  categoryTitle: '',
};

export default compose(
  withRouter,
  connect(
    state => ({
      categoryTitle: state.backoffice.faq.questions.title,
      questions: state.backoffice.faq.questions.items,
      questionsAreLoaded: state.backoffice.faq.questionsAreLoaded,
    }),
    {
      getQuestions: categoryId => getQuestions.request(categoryId),
    }
  )
)(FaqDetailPage);
export { FaqDetailPage };
