import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .month-picker__box {
    display: flex;
    padding: 5px;
    cursor: pointer;
    font-size: 15px;
  }
  .month-picker__icon {
    position: relative;
    width: 14px;
    height: 16px;
    top: 1px;
    margin-left: rem(${isRTL ? '0' : '10px'});
    margin-right: rem(${isRTL ? '10px' : '0'});

    &:before,
    &:after {
      content: '';
      border: 1px solid;
      position: absolute;
      display: inline-block;
      transition: all 0.2s ease;
      width: 8px;
      top: 50%;
      transform: translateX(-50%);
      height: 1px;
    }
    &:before {
      left: 0;
      transform: rotate(45deg);
    }
    &:after {
      right: 1px;
      transform: rotate(-45deg);
      @include respond-to(xs) {
        right: 0;
      }
    }
  }

  .month-picker__box_open {
    i {
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }

  :global(.crmStyle) {
    :global(.month-picker) {
      width: 211px;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #f3f3f3;
      background: #fff;
    }
    :global(.month-picker__box) {
      justify-content: space-between;
      width: 100%;
    }

    :global(.crmStyle__UpperText) {
      position: absolute;
      z-index: 10;
      right: 140px;
      top: -60px;
      color: #999;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
`;

export { staticStyles };
