import css from 'styled-jsx/css';

import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentSystemButton {
    position: relative;
    height: 90px;
    border-bottom: 2px solid transparent;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    padding: rem(15px);

    .PaymentSystemButton__inner {
      width: 100%;
      height: 100%;
      min-width: 100px;
      display: flex;
      align-items: center;

      .PaymentSystemButton__icon {
        max-width: 120px;
        width: auto;
        max-height: 60px;
        margin-right: rem(20px);
      }

      .PaymentSystemButton__title {
        font-weight: 500;
      }
    }

    :global(.Tooltip__text) {
      width: 100%;
      height: 100%;
    }

    :global(.Tooltip) {
      position: absolute;
      width: 100%;
      top: 92px !important;
      left: 0 !important;

      :global(.PaymentSystemButton__description) {
        margin-bottom: rem(10px);
      }

      :global(.PaymentSystemButton__commission-caption) {
        line-height: 1.5;
        margin-right: rem(10px);
      }

      :global(.PaymentSystemButton__commission-text) {
        line-height: 2;
      }
    }

    &.disabled {
      border: none;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .PaymentSystemButton {
    padding: 10px 10px 10px 10px;
    min-height: 62px;
    height: auto;
    transition: border-color 0.5s ease;
    position: relative;

    @include respond-to(sm) {
      border-bottom: none;
    }

    &--active {}

    &__inner {
      width: auto !important;
      height: auto !important;
      min-width: 0 !important;
    }

    &__icon {
      max-width: 72px !important;
      max-height: 42px !important;
      margin: 0 17px 0 0 !important;
      filter: grayscale(100%);
      transition: filter 0.5s ease;

      @include respond-to(sm) {
        filter: none;
      }

      .PaymentSystemButton:hover & {
        transition: none;
        filter: none;
      }

      .PaymentSystemButton--active & {
        filter: none;
      }
    }

    &__title {
      font-size: 15px;
      line-height: 20px;
    }

    &__more-icon {
      position: absolute;
      width: 24px;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;

      &--active {
        width: 34px;
      }

      &--active {
        img {
          transform: rotate(90deg);
        }
      }
    }

    &__info {
      position: absolute;
      background: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      padding: 20px 20px 20px 20px;
      width: calc(200% + 16px);
      cursor: default;
      opacity: 0;
      transform: scale(0);
      z-index: 1;

      @include respond-to(sm) {
        position: static;
        opacity: 1;
        display: none;
        transform: scale(1);
        width: 100%;
        padding: 0 0 0 0;
        box-shadow: none;
        margin: 10px 0 5px 0;
      }

      &--active {
        transform: scale(1);
        opacity: 1;

        @include respond-to(sm) {
          display: block;
        }
      }

      &--left {
        left: -20px;
        top: calc(100% + 13px);
      }

      &--right {
        right: -20px;
        top: calc(100% + 13px);
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;
        height: 13px;

        @include respond-to(sm) {
          display: none;
        }
      }
    }

    &__info-angle {
      position: absolute;

      @include respond-to(sm) {
        display: none;
      }

      &--left {
        left: 68px;
        bottom: 100%;
        transform: scale(-1, 1);
      }

      &--right {
        right: 68px;
        bottom: 100%;
      }
    }

    &__info-list {
      margin: 0 0 12px 0;
    }

    &__info-line {
      display: flex;
      margin: 0 0 12px 0;

      @include respond-to(sm) {
        display: block;
      }

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__info-line-title {
      margin: 0 20px 0 0;
      font-size: 13px;
      line-height: 18px;
      color: #828282;
      min-width: 102px;

      @include respond-to(sm) {
        font-size: 10px;
        line-height: 18px;
      }
    }

    &__info-line-desc {
      font-size: 13px;
      line-height: 18px;

      @include respond-to(sm) {
        font-size: 12px;
        line-height: 15px;
      }
    }

    &__info-desc {
      font-size: 13px;
      line-height: 20px;

      @include respond-to(sm) {
        margin: 0 0 12px 0;
        font-size: 13px;
        line-height: 20px;
      }
    }

    &__info-desc-2 {
      display: none;

      @include respond-to(sm) {
        display: block;
        margin: 0 0 12px 0;
        font-size: 13px;
        line-height: 20px;
      }
    }

    :global(.DefaultButton) {
      width: 100%;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .PaymentSystemButton {
      background: ${colors.white.hex};
      border-radius: ${border.radius};

      .PaymentSystemButton__title {
        color: ${colors.secondary.hex};
      }

      :global(.Tooltip) {
        color: ${colors.black.hex} !important;
        background-color: ${colors.white.hex} !important;
        opacity: 1 !important;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
        border-radius: ${border.radius} !important;

        :global(.PaymentSystemButton__commission-caption) {
          color: ${colors.gray.hex};
        }
      }

      :global(.__react_component_tooltip.type-dark.place-bottom:after) {
        left: 80%;
        border-bottom-color: ${colors.white.hex} !important;
      }
    }

    .PaymentSystemButton {
      &:hover {
        border-color: ${rgba([colors.primary.rgb, 0.4])};
      }

      &--active {
        border-color: ${rgba([colors.primary.rgb, 0.4])};
      }

      &--disabled {
        background: ${rgba([colors.lightGray.rgb, 0.6])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
