import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';

  .AppEntryPreloader__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 96vh;
  }

  .AppEntryPreloader__image {
    position: relative;
    top: 7px;
    text-align: center;
    :global(svg) {
      width: 190px;
      height: 45px;
      animation: load1 2s infinite ease;
      :global(.white) {
        fill: $black !important;
      }
      @include respond-to(sm) {
        width: 135px;
        height: 30px;
      }
    }
  }

  @keyframes load1 {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.98);
    }

    100% {
      transform: scale(1);
    }
  }

  .AppEntryPreloader-three-bounce {
    $animationDuration: 1.6s;
    $delayRange: 0.32s;
    margin-top: 20px;
    text-align: center;
    width: 100%;

    .AppEntryPreloader-child {
      background-color: rgba($primary-color,.9);
      width: (2.2em / 2);
      height: (2.2em / 2);
      margin: 0 8px;
      border-radius: 100%;
      display: inline-block;
      animation: AppEntryPreloader-three-bounce $animationDuration ease-in-out 0s infinite both;
      @include respond-to(sm) {
        width: (1.8em / 2);
        height: (1.8em / 2);
        margin: 0 5px;
      }
    }

    .AppEntryPreloader-bounce-1 {
      animation-delay: -$delayRange;
    }
    .AppEntryPreloader-bounce-2 {
      animation-delay: (-$delayRange / 2);
    }
  }

  @keyframes AppEntryPreloader-three-bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`

export { staticStyles }
