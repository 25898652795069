import axios from 'axios';

export const getClientIssues = () => axios.get('/support/issues/');

export const markIssueAsViewed = id => axios.post(`/support/issues/${id}/mark_as_viewed/`);

export const getClientIssue = id => axios.get(`/support/issues/${id}/`);

export const createIssue = (title, language, description, isPartnershipRequest) =>
  axios.post(`/support/issues/create/`, { title, language, description, isPartnershipRequest });

export const updateMessage = (messageId, payload) => axios.put(`/support/messages/${messageId}/edit/`, { ...payload });

export const closeIssue = id => axios.post(`/support/issues/${id}/close/`);

export const issueCreateMessage = (issue, text) => axios.post(`/support/messages/create/`, { issue, text });

export const deleteMessageAttachment = attachmentId => axios.delete(`support/attachments/${attachmentId}/delete/`);
