import queryString from 'query-string';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageLayout, PanelTabs } from 'common/components';
import TransferListPage from '../TransferListPage';
import PaymentsListPage from '../PaymentsListPage';

const OperationsPage = ({ accountsEnabled, tradersEnabled, match, history, location }) => {
  const activeTabSlug = useMemo(
    () => (location.search.includes('tab') ? queryString.parse(location.search).tab : 'dw'),
    [location.search]
  );

  return (
    <PageLayout captionSlug="userPayments" extraCaptionText={match.params.id} showReload={false}>
      <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
        <PaymentsListPage textId="depositsAndWithdrawals" slug="dw" id="dw" />
        {(accountsEnabled || tradersEnabled) && (
          <TransferListPage textId="boInternalTransfers" slug="transfers" id="transfers" />
        )}
      </PanelTabs>
    </PageLayout>
  );
};

OperationsPage.propTypes = {
  tradersEnabled: PropTypes.bool.isRequired,
  accountsEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(state => ({
  tradersEnabled: state.interfaceConfig.internalTransfers.traders_enabled,
  accountsEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
}))(OperationsPage);
