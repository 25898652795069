import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';

import { Notification } from '../_components';

import { staticStyles, getDynamicStyles } from './style';

const NotificationsPanel = ({ notifications, hideNotification, getIssue, markIssueAsViewed, openIssue, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    !!notifications.length && (
      <div className="NotificationsPanel">
        {notifications.map((item, index) => (
          <Notification
            id={item.id}
            text={item.title}
            onCloseButtonClick={hideNotification}
            key={index}
            getIssue={getIssue}
            markIssueAsViewed={markIssueAsViewed}
            openIssue={openIssue}
          />
        ))}

        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </div>
    )
  );
};

NotificationsPanel.propTypes = {
  hideNotification: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  getIssue: PropTypes.func,
  markIssueAsViewed: PropTypes.func,
  openIssue: PropTypes.func,
  theme: PropTypes.object,
};

NotificationsPanel.defaultProps = {
  getIssue: null,
  markIssueAsViewed: null,
  openIssue: null,
  theme: {},
};

export default withTheme()(NotificationsPanel);
export { NotificationsPanel };
