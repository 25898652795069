import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { AsyncSelectField, DefaultButton } from 'common/components';
import { editUserPartnerCode } from '../../_redux';
import { getAvailablePartnerCodes } from '../../api';

import { staticStyles } from './style';

const EditUserPartnerCodeForm = ({ user, handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const submit = handleSubmit(values => editUserPartnerCode({ ...values, userId: user.id }, dispatch));

  const loadOptions = search =>
    getAvailablePartnerCodes(user.id, search).then(({ data }) =>
      data.map(partner => ({ value: partner.id, label: partner.partnerCode }))
    );

  return (
    <form className="UserPartnerAccountEditForm" onSubmit={submit}>
      <FormattedMessage id="editPartnerCodeDescription">
        {txt => <p className="UserPartnerAccountEditForm__description">{txt}</p>}
      </FormattedMessage>
      <div className="UserPartnerAccountEditForm__inputs-inner">
        <Field
          textId="partnerCode"
          component={AsyncSelectField}
          name="partnerAccount"
          loadOptions={loadOptions}
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={100}
        />
      </div>

      <DefaultButton
        textId="justChange"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditUserPartnerCodeForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditUserPartnerCodeForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'edit-user-partner-code',
})(EditUserPartnerCodeForm);
export { EditUserPartnerCodeForm };
