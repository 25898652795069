/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import TaskIcon from 'crm/_newComponents/TaskIcon';

import { staticStyles } from './style';

const TaskFullNameCell = ({ type, subjectId, fullName, history, status, isOverdue }) => (
  <div className="TaskFullNameCell" onClick={() => history.push(`/crm/contacts/${subjectId}`)}>
    <div
      className={classnames('TaskFullNameCell__Icon', {
        'TaskFullNameCell__Icon--Blue': status === 'OPEN',
        'TaskFullNameCell__Icon--Gray': status === 'CLOSED',
        'TaskFullNameCell__Icon--Red': isOverdue && status !== 'CLOSED',
      })}>
      <TaskIcon type={type} />
    </div>

    <span
      onClick={() => history.push(`/crm/contancts/${subjectId}`)}
      className={classnames('TaskFullNameCell__FullName')}>
      {fullName}
    </span>

    <style jsx>{staticStyles}</style>
  </div>
);

TaskFullNameCell.propTypes = {
  subjectId: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default compose(withRouter)(TaskFullNameCell);
