import axios from 'axios';

const URLS = {
  dynamicSettingSchema: slug => `/config/setting/${slug}/dsl/`,
  dynamicSettingValue: slug => `/config/setting/${slug}/`,
};

const requestDynamicSettingSchema = slug => axios.get(URLS.dynamicSettingSchema(slug));

const requestDynamicSettingValue = slug => axios.get(URLS.dynamicSettingValue(slug));

const validateStatus = status => status < 500;

const requestDynamicSetting = slug =>
  Promise.all([requestDynamicSettingSchema(slug), requestDynamicSettingValue(slug)]).then(result => ({
    schema: result[0].data,
    value: result[1].data,
  }));

const saveDynamicSetting = (slug, formData) =>
  axios.post(URLS.dynamicSettingValue(slug), formData, {
    headers: { 'Content-Type': 'application/json' },
    validateStatus,
  });

const getDynamicDisplay = slug => axios.get(`/config/display/${slug}/`).then(response => response.data);

const getDynamicResourceList = slug => axios.get(`/config/resource/${slug}/resource/`).then(response => response.data);

const getDynamicResourceCreateForm = slug =>
  axios.get(`/config/resource/${slug}/create_metadata/`).then(response => response.data);

const createDynamicResource = (slug, data) =>
  axios.post(`/config/resource/${slug}/resource/`, data, { validateStatus });

const getDynamicResource = (slug, id) =>
  axios.get(`/config/resource/${slug}/resource/${id}/`).then(response => response.data);

const saveDynamicResource = (slug, id, data) =>
  axios.put(`/config/resource/${slug}/resource/${id}/`, data, { validateStatus });

const deleteDynamicResource = (slug, id) =>
  axios.delete(`/config/resource/${slug}/resource/${id}/`).then(response => response.data);

const getDynamicResourceDisplay = (slug, id) =>
  axios.get(`/config/resource/${slug}/resource/${id}/display/`).then(response => response.data);

export {
  requestDynamicSetting,
  saveDynamicSetting,
  getDynamicDisplay,
  getDynamicResourceList,
  getDynamicResourceCreateForm,
  createDynamicResource,
  getDynamicResource,
  saveDynamicResource,
  deleteDynamicResource,
  getDynamicResourceDisplay,
};
