import css from 'styled-jsx/css';

import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ChooseLpaButton {
    position: relative;
    height: 90px;
    border-bottom: 2px solid transparent;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    padding: rem(15px);

    .ChooseLpaButton__inner {
      width: 100%;
      height: 100%;
      min-width: 100px;
      display: flex;
      align-items: center;

      .ChooseLpaButton__title {
        font-weight: 500;
      }
    }
  }

  .ChooseLpaButton {
    padding: 10px 10px 10px 10px;
    min-height: 62px;
    height: auto;
    transition: border-color 0.5s ease;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 40px;

    @include respond-to(sm) {
      border-bottom: none;
    }

    &--active {}

    &__inner {
      width: auto !important;
      height: auto !important;
      min-width: 0 !important;
    }
    
    &__title {
      font-size: 15px;
      line-height: 20px;
    }

    :global(.DefaultButton) {
      width: 100%;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .ChooseLpaButton {
      background: ${colors.white.hex};
      border-radius: ${border.radius};

      .ChooseLpaButton__title {
        color: ${colors.secondary.hex};
      }
    }

    .ChooseLpaButton {
      &:hover {
        border-color: ${rgba([colors.primary.rgb, 0.4])};
      }

      &--active {
        border-color: ${rgba([colors.primary.rgb, 0.4])};
      }

      &--disabled {
        background: ${rgba([colors.lightGray.rgb, 0.6])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
