import { uploadImage } from 'admin/_api';
import { isRTL } from 'common/utils/helpers';

const IMAGE_FORMATS = ['png', 'jpg', 'jpeg'];
const IMAGE_MAX_SIZE = 10;

const VALID_ELEMENTS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a[href|title|id|target]',
  'img[src|alt|width|height|style|data-mce-selected]',
  'strong[class|id]',
  'em',
  'sup',
  'code',
  'span[style|contenteditable|data-mce-contenteditable|class]',
  'p[style]',
  'div[style|id|class]',
  'pre[style]',
  'table[style|border|width|cellspacing|cellpadding]',
  'tbody',
  'tr[style]',
  'td[style|colspan]',
  'ul',
  'ol',
  'li[style]',
  'br[data-mce-bogus]',
  'input[onclick|class|dir<ltr?rtl|disabled<disabled|id|lang|name|value|type]',
  'button[onclick|type|style|class|className]',
  'style[type]',
  'script[src|type|charset|async|defer|id]',
];

const TINYMCE_CONFIG = {
  skin_url: `${process.env.PUBLIC_URL}/tinymce/skins/lightgray`,
  plugins: ['link', 'paste', 'table', 'image', 'textcolor', 'colorpicker', 'lists', 'code', 'template', 'noneditable'],
  resize: false,
  toolbar: ['undo redo | styleselect | bold italic | link image template | alignleft aligncenter alignright'],
  height: 300,
  images_upload_url: '/redux-config/image_upload/',
  relative_urls: true,
  template_popup_height: 320,
  template_popup_width: 320,
  noneditable_regexp: [/{{\s*\w+\s*}}/g],
  valid_elements: VALID_ELEMENTS.join(','),
  valid_children: '+body[style]',
  valid_styles: '"*": "text-decoration,padding-left,text-align,float,width,height,background-color,border-color"',
  images_upload_handler: async (blobInfo, success, failure) => {
    const formData = new FormData();
    const file = blobInfo.blob();

    if (!IMAGE_FORMATS.map(f => `image/${f}`).includes(file.type)) {
      const formats = IMAGE_FORMATS.join(', ');
      return failure(`Not allowed image format. Allowed formats: ${formats}.`);
    }

    if (file.size / (1024 * 1024) > IMAGE_MAX_SIZE) {
      return failure(`Too big image size. Max size: ${IMAGE_MAX_SIZE} MB.`);
    }

    formData.append('file', file, blobInfo.filename());

    try {
      const { data } = await uploadImage(formData);
      success(data.location);
    } catch (error) {
      failure(error);
    }
  },
  content_style: '.mceNonEditable, a {outline-color: #0AA1E2 !important; color: #0AA1E2 !important;}',
  directionality: isRTL ? 'rtl' : 'ltr',
  link_assume_external_targets: true,
  allow_unsafe_link_target: true,
  convert_urls: false,
  forced_root_block: false,
  apply_source_formatting: true,
};

export default TINYMCE_CONFIG;
