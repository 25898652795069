import React from 'react';

const InstructionIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.32309 4.32309C9.29828 3.3479 10.6209 2.80005 12 2.80005H28C28.3183 2.80005 28.6235 2.92648 28.8486 3.15152L40.8486 15.1515C41.0736 15.3766 41.2001 15.6818 41.2001 16V40C41.2001 41.3792 40.6522 42.7018 39.677 43.677C38.7018 44.6522 37.3792 45.2001 36 45.2001H12C10.6209 45.2001 9.29828 44.6522 8.32309 43.677C7.3479 42.7018 6.80005 41.3792 6.80005 40V8.00005C6.80005 6.62092 7.3479 5.29828 8.32309 4.32309ZM12 5.20005C11.2574 5.20005 10.5453 5.49505 10.0201 6.02015C9.49505 6.54525 9.20005 7.25744 9.20005 8.00005V40C9.20005 40.7427 9.49505 41.4548 10.0201 41.9799C10.5453 42.5051 11.2574 42.8 12 42.8H36C36.7427 42.8 37.4548 42.5051 37.9799 41.9799C38.5051 41.4548 38.8 40.7427 38.8 40V17.2H28C27.3373 17.2 26.8 16.6628 26.8 16V5.20005H12ZM29.2001 6.89711L37.103 14.8H29.2001V6.89711Z"
      fill="#BFC7D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 10C12.8 9.33731 13.3373 8.80005 14 8.80005H22C22.6628 8.80005 23.2 9.33731 23.2 10C23.2 10.6628 22.6628 11.2 22 11.2H14C13.3373 11.2 12.8 10.6628 12.8 10Z"
      fill="#BFC7D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 16C12.8 15.3373 13.3373 14.8 14 14.8H22C22.6628 14.8 23.2 15.3373 23.2 16C23.2 16.6628 22.6628 17.2 22 17.2H14C13.3373 17.2 12.8 16.6628 12.8 16Z"
      fill="#BFC7D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 22C12.8 21.3373 13.3373 20.8 14 20.8H34C34.6628 20.8 35.2001 21.3373 35.2001 22C35.2001 22.6628 34.6628 23.2 34 23.2H14C13.3373 23.2 12.8 22.6628 12.8 22Z"
      fill="#BFC7D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 38C12.8 37.3373 13.3373 36.8 14 36.8H34C34.6628 36.8 35.2001 37.3373 35.2001 38C35.2001 38.6628 34.6628 39.2 34 39.2H14C13.3373 39.2 12.8 38.6628 12.8 38Z"
      fill="#BFC7D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0291 26.3826C35.3701 26.9509 35.1858 27.688 34.6175 28.029L24.6175 34.029C24.1874 34.287 23.6421 34.2504 23.2505 33.937L19.0001 30.5367L14.7497 33.937C14.2322 34.351 13.4771 34.2671 13.0631 33.7496C12.649 33.2321 12.7329 32.477 13.2505 32.0629L18.2505 28.0629C18.6887 27.7123 19.3115 27.7123 19.7497 28.0629L24.0982 31.5417L33.3827 25.971C33.951 25.63 34.6881 25.8143 35.0291 26.3826Z"
      fill="#BFC7D7"
    />
  </svg>
);

export default InstructionIcon;
