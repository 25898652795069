import css from 'styled-jsx/css';

import { rgba } from 'common/styling/theme';

import iconDocument from 'common/images/documentDownload.png';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserDocument__inner {
    width: 32%;
    padding-right: 20px;
    align-self: stretch;
    @include respond-to(xl) {
      width: 49%;
      &:nth-child(2) {
        border: none;
      }
    }
    @include respond-to(lg) {
      width: 100%;
      border: none;
      padding: rem(0 0 15px);
      margin-bottom: rem(20px);
      text-align: center;
    }
    &:last-child {
      border: none;
      margin: 0;
      @include respond-to(xl) {
        margin: 20px auto 0;
        padding: 0;
      }
      @include respond-to(lg) {
        margin: 0;
      }
    }
  }

  .UserDocument__caption {
    margin-bottom: rem(15px);
    text-align: center;
  }

  .UserDocument__status {
    span {
      width: auto !important;
    }
  }

  .UserDocument__photo-inner {
    margin-bottom: rem(15px);
    :global(.UserDocument__photo) {
      height: rem(125px);
      width: auto;
      position: relative;
      display: inline-block;
      line-height: 125px;
      text-align: center;
      @include respond-to(lg) {
        margin: 0 auto;
      }
      :global(img) {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: rem(190px);
      }
    }
  }

  .UserDocument__verified-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include respond-to(lg) {
      justify-content: center;
    }
  }

  .UserDocument__buttons-inner {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include respond-to(lg) {
      text-align: center;
      display: block;
    }
    :global(button) {
      margin-right: 10px;
      margin-bottom: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .UserDocument__download-inner {
    text-align: center;
    :global(img) {
      width: auto;
      height: auto;
      max-height: 75px;
      margin-bottom: 5px;
      transition: all 0.3s ease 0s;
      &:hover {
        transform: scale(0.95);
      }
    }
    :global(span) {
      padding: 0;
      margin: 0;
      width: 100% !important;
      display: block;
      text-align: center;
    }
  }

  .UserDocument__upload-inner {
    :global(.FileField__dropzone > div span) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    :global(.FileField__file) {
      :global(.FileField__item-inner) {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(${iconDocument});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        :global(> *) {
          display: none;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .UserDocument__inner {
      border-right: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      @include respond-to(sm) {
        border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      }
    }

    .UserDocument__error {
      color: ${colors.error.hex};
      margin: -15px 0 10px;
      display: block;
      width: 100%;
    }
  `;
};

export { staticStyles, getDynamicStyles };
