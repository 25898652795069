import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .LoginForm {
    &__check-mail {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;

      > span {
        word-break: break-all;
        margin-left: 2px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &__nav-buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;
    }
  }
`;

export { staticStyles };
