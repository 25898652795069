import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const SpecsIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <svg width="19" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.918 7.113c-.693 0-1.263.57-1.263 1.264 0 .693.57 1.263 1.263 1.263s1.263-.57 1.263-1.263c0-.694-.57-1.264-1.263-1.264zm2.213-1.928c-.105 0-.2.029-.285.095l-.456.333c-.18-.095-.37-.171-.56-.228l-.095-.55a.49.49 0 00-.485-.41h-.703a.49.49 0 00-.484.41l-.095.56c-.19.056-.37.132-.541.227L6.97 5.29a.512.512 0 00-.285-.095.451.451 0 00-.342.142l-.494.494a.49.49 0 00-.057.627l.333.465a2.69 2.69 0 00-.219.542l-.55.095a.49.49 0 00-.409.484v.693c0 .238.171.447.408.485l.56.095c.058.19.134.37.229.541l-.323.456a.478.478 0 00.057.627l.494.494a.482.482 0 00.626.047l.466-.332c.17.085.342.161.522.218l.095.56a.49.49 0 00.484.409h.703a.49.49 0 00.485-.409l.095-.56c.19-.057.37-.133.541-.228l.456.333c.085.057.18.095.285.095a.451.451 0 00.342-.143l.493-.494a.491.491 0 00.058-.626l-.333-.456c.095-.171.171-.352.228-.542l.56-.095a.49.49 0 00.409-.484v-.693a.49.49 0 00-.409-.485l-.56-.095a2.726 2.726 0 00-.228-.541l.332-.456a.478.478 0 00-.056-.627l-.494-.494a.492.492 0 00-.342-.152zm-2.213 4.91c-.94 0-1.71-.769-1.71-1.709 0-.94.77-1.71 1.71-1.71s1.71.77 1.71 1.71-.77 1.71-1.71 1.71zm7.845.257a6.084 6.084 0 01-6.079 6.078H4.511V17h6.173a6.655 6.655 0 006.648-6.648V8.785h-.57v1.567zm-.152-3.837a2.503 2.503 0 01-2.28-1.491h2.28v-.57H14.17a2.97 2.97 0 01-.038-.418c0-.142.01-.285.038-.418h2.393v-.57h-2.222a2.483 2.483 0 014.15-.636l.427-.37A3.072 3.072 0 0016.611.988c-1.34 0-2.47.864-2.888 2.06h-.987v.57h.854a2.986 2.986 0 000 .836h-.797v.57h.94a3.053 3.053 0 005.195 1.007l-.427-.37a2.537 2.537 0 01-1.89.854zM6.98.57h4.938V0H6.98A6.655 6.655 0 00.332 6.648h.57A6.084 6.084 0 016.98.57zM1.661 9.877c.598 0 1.092.494 1.092 1.092h.57c0-.807-.58-1.49-1.358-1.633v-.599h-.57v.59A1.673 1.673 0 000 10.968c0 .912.75 1.662 1.662 1.662.598 0 1.092.494 1.092 1.092 0 .599-.494 1.093-1.092 1.093A1.098 1.098 0 01.57 13.723H0c0 .827.608 1.51 1.406 1.643v.59h.57v-.599a1.663 1.663 0 001.358-1.633 1.67 1.67 0 00-1.662-1.662A1.1 1.1 0 01.57 10.969c0-.598.494-1.092 1.092-1.092z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="3.33" y1="18.259" x2="20.755" y2="12.435" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} offset="0%" />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
    </defs>
  </svg>
);

SpecsIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

SpecsIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedSpecsIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(SpecsIconActive);

export default ThemedSpecsIconActive;
