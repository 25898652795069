import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import { PaginationTable } from 'common/components';
import { REWARD_TYPES_TR_KEYS } from 'client/invest/consts';
import { SmallAreaChart } from 'client/invest/_components';

import { FormattedMessage } from 'react-intl';
import { staticStyles, getDynamicStyles } from './style';

const drawTradersFee = fee => (REWARD_TYPES_TR_KEYS[fee] ? <FormattedMessage id={REWARD_TYPES_TR_KEYS[fee]} /> : '一');

const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));
const toPercentage = num => (!Number.isNaN(num) ? `${getFixedNumber(num * 100, 2)}%` : '一');

const InvestRatingTable = ({ data, onRowClick, loading, getList, location, history, params, theme, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'justStrategy',
        Cell: ({
          row: {
            original: { title, symbols },
          },
        }) => (
          <div className="InvestRatingTable__strategy">
            <span className="InvestRatingTable__strategy-caption">{title}</span>
            <span className="InvestRatingTable__strategy-symbols">{symbols}</span>
          </div>
        ),
        isPreviewCell: true,
      },
      {
        accessor: 'total_yield',
        Header: 'investIncomeMonthlyAndTotal',
        Cell: ({
          row: {
            original: { monthlyYield, totalYield },
          },
        }) => (
          <div className="InvestRatingTable__yield">
            <span className="InvestRatingTable__monthlyYield">{toPercentage(monthlyYield)}/</span>
            <span className="InvestRatingTable__totalYield">{toPercentage(totalYield)}</span>
          </div>
        ),
        isPreviewCell: true,
      },
      {
        accessor: 'strategysettings__rollovers',
        Header: 'investBalanceOperations',
        Cell: ({
          row: {
            original: { balanceOperation },
          },
        }) => <span>{balanceOperation ? 'Rollover' : 'Auto'}</span>,
      },
      {
        accessor: 'fee',
        Header: 'investTradersFee',
        Cell: ({
          row: {
            original: { fee },
          },
        }) => drawTradersFee(fee),
      },
      {
        accessor: 'strategysettings__reward_percentage',
        Header: 'justFeeRate',
        Cell: ({
          row: {
            original: { feeRate },
          },
        }) => <span>{feeRate}%</span>,
      },
      {
        Header: 'justDynamics',
        Cell: ({
          row: {
            original: { chart },
          },
        }) => {
          const chartData = chart.length ? chart : Array(10).fill(0);
          return <SmallAreaChart seriesData={chartData} categoriesData={[...Array(chartData.length).keys()]} />;
        },
      },
      {
        accessor: 'investors_number',
        Header: 'justInvestorsNumber',
        Cell: ({
          row: {
            original: { investorsNumber },
          },
        }) => <span>{investorsNumber ?? '一'}</span>,
      },
      {
        accessor: 'age',
        Header: 'ageAndWeeks',
        Cell: ({
          row: {
            original: { age },
          },
        }) => <span>{age}</span>,
      },
    ],
    []
  );
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="InvestRatingTable">
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestRatingTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

InvestRatingTable.defaultProps = {
  theme: {},
};

export default withTheme()(InvestRatingTable);
