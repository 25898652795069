import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_CRM_CLIENT = createRequestTypes('GET_CRM_CLIENT');
export const getCrmClient = {
  request: data => action(GET_CRM_CLIENT[REQUEST], data),
  success: data => action(GET_CRM_CLIENT[SUCCESS], { data }),
  failure: error => action(GET_CRM_CLIENT[FAILURE], { error }),
};

export const GET_CONTACTS_EXPORTS = createRequestTypes('GET_CONTACTS_EXPORTS');
export const getContactsExports = {
  request: data => action(GET_CONTACTS_EXPORTS[REQUEST], data),
  success: data => action(GET_CONTACTS_EXPORTS[SUCCESS], { data }),
  failure: error => action(GET_CONTACTS_EXPORTS[FAILURE], { error }),
};

export const DOWNLOAD_CONTACTS_EXPORT = createRequestTypes('DOWNLOAD_CONTACTS_EXPORT');
export const downloadContactsExport = {
  request: id => action(DOWNLOAD_CONTACTS_EXPORT[REQUEST], id),
  success: data => action(DOWNLOAD_CONTACTS_EXPORT[SUCCESS], { data }),
  failure: error => action(DOWNLOAD_CONTACTS_EXPORT[FAILURE], { error }),
};

export const createContactsExports = createFormAction('CREATE_CONTACTS_EXPORTS');

export const editContactsManager = createFormAction('EDIT_CONTACTS_MANAGER');
export const editContactsIBManager = createFormAction('EDIT_CONTACTS_IB_MANAGER');
export const editContactsPartnerCode = createFormAction('EDIT_CONTACTS_PARTNER_CODE');

export const CRM_SET_SELECTED_CONTACTS_IDS = 'CRM_SET_SELECTED_CONTACTS_IDS';

export const createContact = createFormAction('CREATE_CONTACT');

export const UPLOAD_CONTACTS = createRequestTypes('UPLOAD_CONTACTS');
export const uploadContacts = {
  request: file => action(UPLOAD_CONTACTS[REQUEST], { file }),
  success: data => action(UPLOAD_CONTACTS[SUCCESS], { data }),
  failure: error => action(UPLOAD_CONTACTS[FAILURE], { error }),
};

export const crmSetSelectedContactsIds = data => action(CRM_SET_SELECTED_CONTACTS_IDS, { data });

export const GET_CONTACTS = createRequestTypes('GET_CONTACTS');
export const getContacts = {
  request: search => action(GET_CONTACTS[REQUEST], { search }),
  success: data => action(GET_CONTACTS[SUCCESS], { data }),
  failure: error => action(GET_CONTACTS[FAILURE], { error }),
};

export const GET_CONTACTS_FAVORITES = createRequestTypes('GET_CONTACT_FAVORITES');
export const getContactsFavorites = {
  request: () => action(GET_CONTACTS_FAVORITES[REQUEST]),
  success: data => action(GET_CONTACTS_FAVORITES[SUCCESS], { data }),
  failure: error => action(GET_CONTACTS_FAVORITES[FAILURE], { error }),
};

export const SET_CONTACT_FAVORITE = createRequestTypes('SET_CONTACT_FAVORITE');
export const setContactFavorite = {
  request: userId => action(SET_CONTACT_FAVORITE[REQUEST], { userId }),
  success: data => action(SET_CONTACT_FAVORITE[SUCCESS], { data }),
  failure: error => action(SET_CONTACT_FAVORITE[FAILURE], { error }),
};

export const DELETE_CONTACT_FAVORITE = createRequestTypes('DELETE_CONTACT_FAVORITE');
export const deleteContactFavorite = {
  request: userId => action(DELETE_CONTACT_FAVORITE[REQUEST], { userId }),
  success: data => action(DELETE_CONTACT_FAVORITE[SUCCESS], { data }),
  failure: error => action(DELETE_CONTACT_FAVORITE[FAILURE], { error }),
};

export const editCrmCustomFilter = createFormAction('EDIT_CRM_CUSTOM_FILTER');
export const deleteCrmCustomFilter = createFormAction('DELETE_CRM_CUSTOM_FILTER');

export const GET_CRM_FILTER = createRequestTypes('GET_CRM_FILTER');
export const getCrmFilter = {
  request: data => action(GET_CRM_FILTER[REQUEST], data),
  success: data => action(GET_CRM_FILTER[SUCCESS], { data }),
  failure: error => action(GET_CRM_FILTER[FAILURE], { error }),
};
