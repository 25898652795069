/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { ReduxTextField } from '../../form-fields';
import TextActionsInput from '../../TextActionsInput';

import { NewDefaultButton } from '../..';

import { wsCheckDomain, wsSetStep, wsGetConfiguretedDnsTxtAndCNAME } from '../../../_redux';

import { staticStyles } from './style';

const CheckDomainForm = ({
  cname,
  dns_txt,
  userDomain,
  submitting,
  error,
  handleSubmit,
  dispatch,
  wsSetStep,
  dnsCnameIsValid,
  dnsTxtIsValid,
  wsGetConfiguretedDnsTxtAndCNAME,
}) => {
  const [isCopied, setIsCopied] = useState({ dns_txt: false, cname: false });
  const [isAttempted, setIsAttepmpted] = useState(false);
  const [statusSuccess, isStatusSuccess] = useState(dnsCnameIsValid && dnsTxtIsValid);

  useEffect(() => {
    wsGetConfiguretedDnsTxtAndCNAME();
  }, []);

  useEffect(() => {
    if (dnsCnameIsValid && dnsTxtIsValid) {
      isStatusSuccess(true);
    }
  }, [dnsCnameIsValid, dnsTxtIsValid]);

  const submit = handleSubmit(formValues => wsCheckDomain(formValues, dispatch));

  const handlePreviousStepClick = () => wsSetStep(3);
  const handleNextStepClick = () => wsSetStep(5);

  const handleCopy = (value, fieldName) => {
    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(prev => ({ ...prev, [fieldName]: true }));

      setTimeout(() => {
        setIsCopied(prev => ({ ...prev, [fieldName]: false }));
      }, 1300);
    });
  };

  return (
    <>
      <form className="CheckDomainForm" onSubmit={submit}>
        <Field
          component={ReduxTextField}
          type="text"
          name="userDomain"
          customDefaultValue={userDomain}
          textId="welcomeSetupDomain"
          validate={[required()]}
          disabled
        />

        <TextActionsInput
          labelTextId="welcomeSetupCName"
          textId={isCopied.cname ? 'justCopiedAlt' : 'justCopy'}
          inputValue={cname}
          onClick={() => handleCopy(cname, 'cname')}
          disabled={submitting}
          success={isCopied.cname}
          error={!!error || (!dnsCnameIsValid && isAttempted)}
        />

        <TextActionsInput
          labelTextId="welcomeSetupDNSTxt"
          textId={isCopied.dns_txt ? 'justCopiedAlt' : 'justCopy'}
          inputValue={dns_txt}
          onClick={() => handleCopy(dns_txt, 'dns_txt')}
          disabled={submitting}
          success={isCopied.dns_txt}
          error={!!error || (!dnsTxtIsValid && isAttempted)}
        />

        {error && <strong className="formError">{error}</strong>}
        {error && (
          <strong className="formError">
            <FM id="welcomeSetupDomainCheckTryAgainLater" />
          </strong>
        )}

        {statusSuccess && (
          <NewDefaultButton
            textId="justSuccess"
            type="sumbit"
            variant="check"
            disabled
            iconVariant="check"
            wide
            isUpperCase
          />
        )}

        {!statusSuccess && (
          <NewDefaultButton
            textId={submitting ? 'justVerifying' : isAttempted ? 'justRetry' : 'justVerify'}
            type="submit"
            onClick={() => setIsAttepmpted(true)}
            variant="secondary"
            wide
            disabled={submitting}
            iconVariant={submitting ? 'loading' : isAttempted ? 'retry' : ''}
          />
        )}

        <div className="CheckDomainForm__nav-buttons">
          <NewDefaultButton
            textId="justBack"
            onClick={handlePreviousStepClick}
            type="button"
            variant="ghost"
            disabled={submitting}
            iconVariant="previous"
            isUpperCase
          />
          <NewDefaultButton
            textId="justNextStep"
            onClick={handleNextStepClick}
            type="button"
            disabled={submitting || !statusSuccess}
            variant="primary"
            wide
            isUpperCase
          />
        </div>
      </form>

      <style jsx global>
        {staticStyles}
      </style>
    </>
  );
};

CheckDomainForm.propTypes = {
  userDomain: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  wsSetStep: PropTypes.func.isRequired,
  cname: PropTypes.string.isRequired,
  dns_txt: PropTypes.string.isRequired,
  dnsCnameIsValid: PropTypes.bool.isRequired,
  dnsTxtIsValid: PropTypes.bool.isRequired,
  wsGetConfiguretedDnsTxtAndCNAME: PropTypes.func.isRequired,
};

CheckDomainForm.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  connect(
    state => ({
      userDomain: state.welcomeSetup.userDomain,
      cname: state.welcomeSetup.cname || '',
      dns_txt: state.welcomeSetup.dns_txt || '',
      dnsCnameIsValid: state.welcomeSetup.dnsCnameIsValid,
      dnsTxtIsValid: state.welcomeSetup.dnsTxtIsValid,
    }),
    {
      wsSetStep,
      wsGetConfiguretedDnsTxtAndCNAME: () => wsGetConfiguretedDnsTxtAndCNAME.request(),
    }
  ),
  reduxForm({
    form: 'wsCheckDomainForm',
  })
)(CheckDomainForm);
export { CheckDomainForm };
