import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required, confirmation, length } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { ReduxTextField, DefaultButton } from '../index';

import staticStyles from './style';

const PasswordChangeForm = ({
  changePasswordFunc,
  id,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  dispatch,
  customRegex,
  customLengthOptions,
}) => {
  const submit = handleSubmit(values => changePasswordFunc({ ...values, id }, dispatch));

  const passwordValidate = useCallback(password => {
    const regex = customRegex || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!/])(?!.*\\)[@#$%^&+=!/a-zA-Z0-9]{6,}$/;

    return regex.test(password) ? undefined : (
      <FormattedMessage id="validationErrorMetaTraderPassword">{txt => txt}</FormattedMessage>
    );
  }, []);

  const lengthValidateOptions = customLengthOptions || { min: 6, max: 15 };

  return (
    <form onSubmit={submit} className="PasswordChangeForm">
      <Field
        name="password"
        type="password"
        component={ReduxTextField}
        textId="authPassword"
        validate={[required(), length(lengthValidateOptions), passwordValidate]}
        disabled={submitting}
      />
      <Field
        name="passwordRepeat"
        type="password"
        component={ReduxTextField}
        textId="authPasswordRepeat"
        validate={[required(), confirmation({ field: 'password' })]}
        disabled={submitting}
      />
      <div className="PasswordChangeForm__button-inner">
        <DefaultButton
          textId="authChangePassword"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

PasswordChangeForm.propTypes = {
  changePasswordFunc: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  customRegex: PropTypes.object,
  customLengthOptions: PropTypes.object,
};

PasswordChangeForm.defaultProps = {
  error: '',
  customRegex: null,
  customLengthOptions: null,
};

export default reduxForm({ form: 'password-change-form' })(PasswordChangeForm);
export { PasswordChangeForm };
