import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader, NoItemsPanel } from 'common/components';
import noFilesImage from 'common/images/noFilesDownload.svg';

import { getFilesList } from '../_redux';
import FilesItem from '../_components/FileItem';

class FilesListPage extends React.Component {
  constructor(props) {
    super(props);
    props.getFilesList();
  }

  handleLoadRequests = () => {
    this.props.getFilesList();
  };

  render() {
    const { files, loaded } = this.props;

    return (
      <PageLayout captionSlug="userFiles" showReload onReloadClick={this.handleLoadRequests} reloading={!loaded}>
        <PageContentPreloader ready={loaded} type="fileItemBlock">
          {files.length ? (
            files.map(item => <FilesItem key={item.id} item={item} />)
          ) : (
            <NoItemsPanel icon={noFilesImage} captionSlug="noFilesToDownloadYet" />
          )}
        </PageContentPreloader>
      </PageLayout>
    );
  }
}

FilesListPage.propTypes = {
  files: PropTypes.array.isRequired,
  getFilesList: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    files: state.client.files.files,
    loaded: state.client.files.filesAreLoaded,
  }),
  { getFilesList: () => getFilesList.request() }
)(FilesListPage);
export { FilesListPage };
