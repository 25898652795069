import css from 'styled-jsx/css';

const staticStyles = css.global`
  .ChangePaymentStatusForm {
    &__disclaimer {
      margin-bottom: 20px;

      &__title {
        font-size: 16px;
        margin-bottom: 4px;
      }
    }
  }
`;

export { staticStyles };
