import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Chart from 'react-apexcharts';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const SmallAreaChart = ({ color, captionId, amountNow, amountLastMonth, theme, seriesData, categoriesData }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const options = {
    chart: {
      fontFamily: 'ubuntu-font, sans-serif',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      foreColor: '#fff',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: false,
      },
      categories: categoriesData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: [color],
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        title: {
          formatter() {
            return '';
          },
        },
      },
    },
    grid: {
      show: false,
    },
  };

  const series = [
    {
      name: 'series1',
      data: seriesData,
    },
  ];

  return (
    <div className="SmallAreaChart">
      <div className="SmallAreaChart__wrapper">
        <div className="SmallAreaChart__inner">
          <FormattedMessage id={captionId}>{txt => <h4>{txt}</h4>}</FormattedMessage>
          <Chart className="AreaChart" options={options} series={series} type="area" height="100" />
        </div>
        <div className="SmallAreaChart__inner">
          <FormattedMessage id="justNow" />
          <span className="Amount">{amountNow}</span>
        </div>
        <div className="SmallAreaChart__inner">
          <FormattedMessage id="lastMonth" />
          <span className="Amount">{amountLastMonth}</span>
        </div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
      {/* eslint-disable */}
      <style jsx>
        {`
          .SmallAreaChart {
            &:after {
              background: ${color};
            }
          }
        `}
      </style>
      {/* eslint-enable */}
    </div>
  );
};

SmallAreaChart.propTypes = {
  color: PropTypes.string.isRequired,
  captionId: PropTypes.string.isRequired,
  amountNow: PropTypes.number.isRequired,
  amountLastMonth: PropTypes.number.isRequired,
  theme: PropTypes.object,
  seriesData: PropTypes.array,
  categoriesData: PropTypes.array,
};

SmallAreaChart.defaultProps = {
  theme: {},
  seriesData: [],
  categoriesData: [],
};

export default withTheme()(SmallAreaChart);
export { SmallAreaChart };
