import React from 'react';

const FilesToDownloadIcon = () => (
  <div>
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49811 0C7.3071 0 7.15429 0.152809 7.15429 0.34382V12.8498L4.58779 10.2834C4.45235 10.1479 4.23703 10.1479 4.10158 10.2834C3.96614 10.4188 3.96614 10.6341 4.10158 10.7696L7.255 13.923C7.32099 13.989 7.41129 14.0237 7.49811 14.0237C7.58493 14.0237 7.67523 13.989 7.74121 13.923L10.8946 10.7696C11.0301 10.6341 11.0301 10.4188 10.8946 10.2834C10.7592 10.1479 10.5439 10.1479 10.4084 10.2834L7.84193 12.8498V0.34382C7.84193 0.152809 7.68912 0 7.49811 0Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M0.34382 12.7422C0.152809 12.7422 0 12.895 0 13.086V16.6562C0 16.8472 0.152809 17 0.34382 17H14.6523C14.8433 17 14.9961 16.8472 14.9961 16.6562V13.086C14.9961 12.895 14.8433 12.7422 14.6523 12.7422C14.4613 12.7422 14.3085 12.895 14.3085 13.086V16.3124H0.68764V13.086C0.68764 12.895 0.534831 12.7422 0.34382 12.7422Z"
        fill="url(#paint1_linear)"
      />
      <defs />
    </svg>
  </div>
);

export default FilesToDownloadIcon;
