import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Field } from 'redux-form';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const RadioField = ({ textId, name, value, validate, disabled, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <label className="RadioField">
      <Field component="input" name={name} type="radio" value={value} validate={validate} disabled={disabled} />
      <span className="RadioField__fake" />
      {textId && (
        <FormattedMessage id={textId}>{txt => <span className="RadioField__text">{txt}</span>}</FormattedMessage>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>
    </label>
  );
};

RadioField.propTypes = {
  textId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  validate: PropTypes.array,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
};

RadioField.defaultProps = {
  validate: [],
  disabled: false,
  theme: {},
};

export default withTheme()(RadioField);
export { RadioField };
