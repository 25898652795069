import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .CRMEditUserIBManagerForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > .BaseButton {
      margin-top: 5px;
    }
  }
`;

export { staticStyles };
