import React from 'react';

const IconPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M11.3578 7.74844L8.97187 6.72516C8.53828 6.54497 8.02969 6.66949 7.73555 7.03172L6.95883 7.98023C5.72344 7.30781 4.69453 6.27891 4.02187 5.04375L4.97156 4.26586C5.33883 3.96305 5.45977 3.46523 5.2725 3.02742L4.25156 0.641485C4.04766 0.170602 3.53437 -0.0875851 3.0375 0.0270477L0.824297 0.54047C0.339141 0.651095 0 1.07578 0 1.57313C0 7.32188 4.67812 12 10.4273 12C10.9249 12 11.351 11.6616 11.4626 11.1766L11.9735 8.9625C12.0867 8.46328 11.8289 7.95234 11.3578 7.74844ZM10.732 11.0086C10.6998 11.1485 10.5716 11.2502 10.4278 11.2502C5.09109 11.2502 0.750469 6.90961 0.750469 1.57289C0.750469 1.42786 0.849703 1.30383 0.992812 1.27102L3.20555 0.757501C3.22898 0.752007 3.25242 0.749443 3.27548 0.749443C3.39816 0.749443 3.51244 0.821959 3.56259 0.937669L4.584 3.32126C4.63828 3.45 4.60312 3.59766 4.49531 3.68672L3.31172 4.65703C3.17812 4.76719 3.13594 4.95469 3.21328 5.11172C3.99562 6.70102 5.3018 8.00625 6.89062 8.78906C7.04627 8.86671 7.23586 8.82495 7.34625 8.69018L8.31633 7.50659C8.40312 7.39964 8.55445 7.36448 8.67961 7.41649L11.0609 8.43743C11.1993 8.4975 11.2755 8.64764 11.2418 8.79415L10.732 11.0086Z"
      fill="white"
    />
  </svg>
);

export default IconPhone;
