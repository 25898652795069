import React, { useMemo, useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import { staticStyles } from './style';

const InputRange = ({ values, label, min, max, currency, onChange }) => {
  const [minValue, maxValue] = useMemo(() => {
    if (values.length) {
      return values;
    }
    return [min, max];
  }, [values, min, max]);

  useEffect(() => {
    onChange([minValue, maxValue]);
  }, []);

  return (
    <>
      <div className="InputRange">
        <span className="InputRange__Label">
          <FM id={label}>{txt => txt}</FM>
        </span>
        <div className="InputRange__InputWrapper">
          <input
            value={minValue}
            className="InputRange__Input"
            onChange={e => {
              const newValue = e.target.value;
              if (newValue >= min && newValue <= max) {
                onChange([newValue, maxValue]);
              }
            }}
          />
          <span className="InputRange__InputLabel">{currency}</span>
        </div>

        <div className="InputRange__Delimetr">~</div>
        <div className="InputRange__InputWrapper">
          <input
            value={maxValue}
            className="InputRange__Input"
            onChange={e => {
              const newValue = +e.target.value;
              if (newValue >= min && newValue <= max) {
                onChange([minValue, newValue]);
              }
            }}
          />
          <span className="InputRange__InputLabel">{currency}</span>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </>
  );
};

InputRange.propTypes = {
  values: PropTypes.array,
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  currency: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
InputRange.defaultProps = {
  values: [],
  currency: 'USD',
};

export default InputRange;
