import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CheckDomainInstruction {
    grid-column: 4/9;
    white-space: pre-line;
    background-color: white;
    border-radius: 4px;
    padding: 24px;
    width: 100%;
    height: 404px;
    overflow: hidden;
    position: relative;
    right: ${isRTL ? 'unset' : '0'};
    left: ${isRTL ? '0' : 'unset'};
    background: #f0f4f6;

    @include respond-to(sm) {
      height: 316px;
      width: 100%;
      order: -1;
    }

    &:not(.active) {
      display: none;
    }

    &__content {
      overflow-y: auto;
      max-height: 100%;
      padding-right: ${isRTL ? '0' : '15px'};
      padding-left: ${isRTL ? '15px' : '0'};

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f4f6;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bfc7d7;
      }
    }

    h2 {
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      opacity: 0.6;
      margin-bottom: 8px;
    }
  }
`;

export { staticStyles };
