export const TYPES = {
  MAIL: 'MAIL',
  CALL: 'CALL',
  MONITORING: 'MONITORING',
};

export const PLATFORMS = {
  TELEGRAM: 'TELEGRAM',
  VIBER: 'VIBER',
  MESSENGER: 'MESSENGER',
  WHATSAPP: 'WHATSAPP',
  WECHAT: 'WECHAT',
  EMAIL: 'EMAIL',
};
