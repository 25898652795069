import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .EditCommissionForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(25px);

    .EditCommissionForm__line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: rem(10px 0);

      &:last-child {
        margin-top: rem(30px);
      }

      span,
      :global(.StatusCell),
      :global(.ReduxTextField),
      :global(.DefaultButton) {
        flex: 1;
      }

      :global(.ReduxTextField),
      :global(.ReduxTextField div) {
        max-height: 40px;
      }

      :global(.ReduxTextField input) {
        max-height: 40px;
        padding: rem(10px);
      }

      :global(.DefaultButton) {
        width: 120px;
        &:first-child {
          margin-right: rem(50px);

          @include respond-to(xs) {
            margin-right: rem(10px);
          }
        }
      }
    }

    .formError {
      align-self: flex-start;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .EditCommissionForm__title {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
