import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, change } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { ReduxTextField, DefaultButton, CheckboxField, ReduxSelectField, TinyMCEField } from 'common/components';
import { createInvestStrategy } from 'client/invest/_redux';
import { getMTServersAction } from 'client/_redux/actions';

import staticStyles from './style';

const validate = ({ name, time, timeEnd }) => {
  const errors = {};

  if (!/^[a-zA-Z0-9\s_!,.?]{0,50}$/.test(name)) errors.name = <FormattedMessage id="rammStrategyNameHelpText" />;
  if (timeEnd <= time) errors.timeEnd = <FormattedMessage id="investRolloversTimeError" />;

  return errors;
};

// TODO: Если мотфорекс motforex уйдет, то убрать этот костыль
const CreateInvestStrategyForm = ({
  toggleModal,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  accounts,
  error,
  getMTServers,
  mtServersPending,
  mtServers,
  changeFieldValue,
}) => {
  const submit = handleSubmit(values => {
    if (document.location.hostname === 'portal.motforex.com') {
      return createInvestStrategy({ ...values, rewardType: 1, copyType: 0 }, dispatch);
    }

    return createInvestStrategy({ ...values }, dispatch);
  });
  const [isRollover, setIsRollover] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [showExternalAccountsSelect, setShowExternalAccountsSelect] = useState(false);
  const [selectedRewardAccountId, setSelectedRewardAccountId] = useState(null);
  const [selectedServerAccountType, setSelectedServerAccountType] = useState(null);
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const handleChangeIsRollover = value => {
    setIsRollover(value);
    changeFieldValue('rewardAccount', '');
    changeFieldValue('rewardType', '');
    changeFieldValue('rewardPercentage', '');
    changeFieldValue('timeEnd', '');
  };

  useEffect(() => {
    if ((selectedTime === 0 || !!selectedTime) && selectedTime >= selectedTimeEnd)
      changeFieldValue('timeEnd', selectedTime + 1);
  }, [selectedTime]);

  useEffect(() => {
    if ((selectedTimeEnd === 0 || !!selectedTimeEnd) && selectedTimeEnd <= selectedTime)
      changeFieldValue('time', selectedTimeEnd - 1);
  }, [selectedTimeEnd]);

  const frequencyTypes = [
    {
      value: 1,
      label: <FormattedMessage id="justMonthly" />,
    },
    {
      value: 2,
      label: <FormattedMessage id="justWeekly" />,
    },
  ];

  const rewardTypes = [
    {
      value: 1,
      label: <FormattedMessage id="investShareOfProfit" />,
    },
    {
      value: 2,
      label: <FormattedMessage id="investHighWaterMark" />,
    },
  ];

  const copyTypeOptions = [
    {
      value: 0,
      label: <FormattedMessage id="investOpenOnlyNewDeals" />,
    },
    {
      value: 1,
      label: <FormattedMessage id="investOpenCurrentDeals" />,
    },
  ];

  const accountsOptions = accounts.map(account => ({
    value: account.id,
    label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
  }));

  const daysOptions = useMemo(
    () =>
      selectedType === 1
        ? Array.from(Array(31), (_, i) => i + 1).map(day => ({ label: day, value: day }))
        : weekdays.map((day, i) => ({
            label: <FormattedMessage id={`just${day}`} />,
            value: i + 1,
          })),
    [selectedType, weekdays]
  );

  const timeOptions = useMemo(
    () =>
      Array.from(Array(24), (_, i) => i).map(hour => ({
        label: `${hour >= 10 ? hour : `0${hour}`}:00`,
        value: hour,
      })),
    []
  );

  const [mtSelected, setMTSelected] = useState(null);
  const [serverSelected, setServerSelected] = useState(null);

  useEffect(() => {
    getMTServers();
  }, [getMTServersAction.request]);

  const handleMtChange = value => {
    setMTSelected(value);
    setServerSelected(null);
    setSelectedServerAccountType(null);
  };

  const mt = useMemo(
    () => (mtServers ? Object.keys(mtServers).map(item => ({ label: item.toUpperCase(), value: item })) : []),
    [mtServers]
  );

  const mtData = useMemo(() => {
    if (mtServers && mtSelected) {
      return {
        servers: mtServers[mtSelected].servers.map(([value, label]) => ({ value, label })),
      };
    }
    return null;
  }, [mtServers, mtSelected]);

  const minimalInvestAmount =
    mtServers && mtSelected && serverSelected
      ? mtServers[mtSelected].servers.filter(server => server[0] === serverSelected)[0][2]
      : null;

  const minRewardPercent =
    mtServers && mtSelected && serverSelected
      ? mtServers[mtSelected].servers.filter(server => server[0] === serverSelected)[0][6]
      : null;

  const maxRewardPercent =
    mtServers && mtSelected && serverSelected
      ? mtServers[mtSelected].servers.filter(server => server[0] === serverSelected)[0][5]
      : null;

  const serverAccountTypes = useMemo(
    () =>
      serverSelected && mtServers && mtSelected
        ? mtServers[mtSelected].account_types
            .filter(type => type[2] === serverSelected)
            .map(([value, label]) => ({ value, label }))
        : [],
    [serverSelected]
  );

  const selectedServerAccountTypeCurrency =
    serverSelected && mtServers && mtSelected && selectedServerAccountType
      ? mtServers[mtSelected].account_types.filter(type => type[0] === selectedServerAccountType)[0][3]
      : '';

  const [investExternalServerId, defaultStrategyType] = useMemo(
    () =>
      mtServers && mtSelected && serverSelected
        ? mtServers[mtSelected].servers.find(server => server[0] === serverSelected).slice(3, 5)
        : [null, null],
    [mtServers, mtSelected, serverSelected]
  );

  const externalAccounts = useMemo(
    () =>
      investExternalServerId
        ? accounts.filter(
            ({ accountTypeServerId, isSocialTradingManagers }) =>
              accountTypeServerId === investExternalServerId && isSocialTradingManagers
          )
        : [],
    [accounts, investExternalServerId]
  );

  const externalAccountsRef = useRef();
  externalAccountsRef.current = externalAccounts;

  const externalAccountsOptions = useMemo(
    () =>
      externalAccounts
        .filter(({ id }) => id !== selectedRewardAccountId)
        .map(({ login, id, balance: { amount, currency } }) => ({
          label: `${login} (${amount} ${currency})`,
          value: id,
        })),
    [externalAccounts, selectedRewardAccountId]
  );

  const balanceValidate = useCallback(value => {
    const externalAccount = externalAccountsRef.current.find(({ id }) => id === value);
    return Number(externalAccount.balance.amount) === 0 ? undefined : (
      <FormattedMessage id="investAccountBalanceError">{txt => txt}</FormattedMessage>
    );
  }, []);

  const renderConvertAccountFields = () => (
    <>
      {!externalAccountsOptions.length && (
        <p className="CreateStrategy__label-error">
          <FormattedMessage id="noExternalAccsToConvert">{txt => txt}</FormattedMessage>
        </p>
      )}

      <Field
        component={ReduxSelectField}
        name="externalAccount"
        textId="clientMetaConvertAccount"
        options={externalAccountsOptions}
        disabled={submitting}
        validate={[required(), balanceValidate]}
        onChange={() => {}}
      />

      <style jsx>{staticStyles}</style>
    </>
  );

  return (
    <div className="CreateStrategy__content">
      <form className="CreateInvestStrategyForm" onSubmit={submit}>
        <div className="CreateInvestStrategyForm__inputs-inner">
          <Field
            component={ReduxTextField}
            name="name"
            textId="justStrategyName"
            validate={[required()]}
            disabled={submitting}
          />

          <Field component={TinyMCEField} name="description" textId="justDescription" disabled={submitting} />
          <div className="CreateStrategy__checkbox">
            <Field
              component={CheckboxField}
              descriptionId="investRolloverSettings"
              name="rollovers"
              textId="investEnableRollovers"
              type="checkbox"
              disabled={submitting}
              onChange={(e, value) => handleChangeIsRollover(value)}
              withText
            />
          </div>

          <Field
            component={ReduxSelectField}
            name="rewardAccount"
            maxMenuHeight={140}
            textId="investRewardAccount"
            options={accountsOptions}
            disabled={submitting}
            validate={[required()]}
            searchable
            onChange={value => setSelectedRewardAccountId(value)}
          />

          <Field
            component={ReduxSelectField}
            name="selectMT"
            textId="clientMetaTrader"
            options={mt}
            disabled={submitting || mtServersPending || !mt.length}
            validate={[required()]}
            onChange={handleMtChange}
          />

          {mtData && (
            <>
              <Field
                component={ReduxSelectField}
                name="server"
                textId="clientMetaTraderServers"
                options={mtData.servers}
                disabled={submitting}
                validate={[required()]}
                onChange={value => setServerSelected(value)}
              />

              {serverSelected && (
                <Field
                  component={ReduxSelectField}
                  name="accountType"
                  textId="clientMetaTraderAccountTypes"
                  options={serverAccountTypes}
                  disabled={submitting}
                  validate={[required()]}
                  onChange={value => setSelectedServerAccountType(value)}
                />
              )}

              {/* social trading */}
              {defaultStrategyType === 2 && (
                <Field
                  component={ReduxSelectField}
                  name="externalAccount"
                  textId="clientMetaExternalAccount"
                  options={externalAccountsOptions}
                  disabled={submitting}
                  validate={[required(), balanceValidate]}
                  onChange={() => {}}
                />
              )}

              {Number.isInteger(defaultStrategyType) && defaultStrategyType !== 2 && (
                <>
                  <div className="CreateStrategy__checkbox">
                    <Field
                      component={CheckboxField}
                      onChange={() => setShowExternalAccountsSelect(!showExternalAccountsSelect)}
                      name="autoCreate"
                      textId="autoCreateExternalAccount"
                      disabled={submitting}
                    />
                  </div>

                  {showExternalAccountsSelect && renderConvertAccountFields()}
                </>
              )}
            </>
          )}

          <Field
            component={ReduxSelectField}
            name="frequencyType"
            textId={isRollover ? 'investRolloversFrequency' : 'investRewardFrequency'}
            options={frequencyTypes}
            validate={[required()]}
            disabled={submitting}
            onChange={value => setSelectedType(value)}
          />

          {selectedType && (
            <>
              <FormattedMessage id={isRollover ? 'investRolloversDays' : 'investRewardDays'}>
                {txt => <span className="caption">{txt}:</span>}
              </FormattedMessage>
              <Field
                component={ReduxSelectField}
                name="days"
                textId={isRollover ? 'investRolloversDays' : 'investRewardDays'}
                options={daysOptions}
                validate={[required()]}
                disabled={submitting}
                multi
              />
              {isRollover && (
                <>
                  <Field
                    component={ReduxSelectField}
                    name="time"
                    textId="investRolloversTime"
                    options={timeOptions.slice(0, 23)}
                    validate={[required()]}
                    disabled={submitting}
                    onChange={value => setSelectedTime(value)}
                  />

                  <Field
                    component={ReduxSelectField}
                    name="timeEnd"
                    textId="investRolloversEndTime"
                    options={timeOptions.slice(1)}
                    validate={[required()]}
                    disabled={submitting}
                    onChange={value => setSelectedTimeEnd(value)}
                  />
                </>
              )}
              {!isRollover && (
                <Field
                  component={ReduxSelectField}
                  name="time"
                  textId="investRewardTime"
                  options={timeOptions}
                  validate={[required()]}
                  disabled={submitting}
                />
              )}
              <div className="row">
                {document.location.hostname !== 'portal.motforex.com' && (
                  <Field
                    component={ReduxSelectField}
                    name="rewardType"
                    textId="rewardType"
                    options={rewardTypes}
                    validate={[required()]}
                    disabled={submitting}
                  />
                )}

                <Field
                  component={ReduxTextField}
                  name="rewardPercentage"
                  textId="investRewardAmount"
                  validate={[
                    required(),
                    numericality({
                      '<=': maxRewardPercent || 100,
                      '>=': minRewardPercent || 0,
                    }),
                  ]}
                  disabled={submitting}
                />
              </div>

              {document.location.hostname !== 'portal.motforex.com' && (
                <Field
                  component={ReduxSelectField}
                  name="copyType"
                  textId="strategyCopyType"
                  options={copyTypeOptions}
                  validate={[required()]}
                  disabled={submitting}
                />
              )}

              {typeof minimalInvestAmount === 'number' && (
                <div className="CreateStrategy__min-invest-amount">
                  <Field
                    component={ReduxTextField}
                    name="minInvestmentAmount"
                    textId="minimalInvestmentAmount"
                    validate={[
                      required(),
                      numericality({
                        '>=': minimalInvestAmount,
                      }),
                    ]}
                    description={selectedServerAccountTypeCurrency}
                    disabled={submitting}
                  />

                  <div className="CreateStrategy__min-invest-amount-text">
                    <FormattedMessage
                      id="minimalInvestmentAmountCount"
                      values={{
                        count: minimalInvestAmount,
                        currency: selectedServerAccountTypeCurrency,
                      }}>
                      {txt => txt}
                    </FormattedMessage>
                  </div>
                </div>
              )}
              <div className="CreateStrategy__checkbox">
                <Field component={CheckboxField} name="autoInvestment" textId="autoInvestment" disabled={submitting} />
              </div>
              <div className="CreateStrategy__checkbox">
                <Field
                  component={CheckboxField}
                  name="enableOperations"
                  textId="strategyAutoOperations"
                  disabled={submitting}
                />
              </div>
            </>
          )}

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="CreateInvestStrategyForm__btns">
          <DefaultButton
            textId="justCreateStrategy"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting || !selectedType}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
      </form>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

CreateInvestStrategyForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  getMTServers: PropTypes.func.isRequired,
  mtServersPending: PropTypes.bool.isRequired,
  mtServers: PropTypes.object,
  changeFieldValue: PropTypes.func.isRequired,
};

CreateInvestStrategyForm.defaultProps = {
  error: '',
  mtServers: null,
};

export default compose(
  reduxForm({
    form: 'CreateInvestStrategyForm',
    validate,
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
    initialValues: {
      autoCreate: true,
    },
  }),
  connect(
    state => ({
      accounts: state.client.common.accounts.filter(
        account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
      ),
      accountsAreLoaded: state.client.common.accountsIsLoaded,
      mtServers: state.client.common.mtServers,
      mtServersPending: state.client.common.mtServersPending,
    }),
    dispatch => ({
      getMTServers: () => dispatch(getMTServersAction.request()),
      changeFieldValue: (field, value) => dispatch(change('CreateInvestStrategyForm', field, value)),
    })
  )
)(CreateInvestStrategyForm);
export { CreateInvestStrategyForm };
