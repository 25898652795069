import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserKYCForm {
    margin-top: rem(20px);
  }

  .UserKYCForm__inputs-inner {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);
    padding: rem(25px 30px 10px);
    @include respond-to(sm) {
      padding: rem(20px 15px 10px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .UserKYCForm__text {
    padding-bottom: rem(25px);
  }

  .UserKYCForm__inputs-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
    @include respond-to(sm) {
      margin: 0;
    }
    .ReduxTextField,
    .BaseSelectField,
    .PhoneField,
    .DateField__inner {
      width: 32%;
      @include respond-to(sm) {
        width: 100%;
      }
    }
  }

  .block-two-inp {
    .ReduxTextField,
    .BaseSelectField,
    .DateField__inner,
    .PhoneField {
      width: 49%;
      @include respond-to(sm) {
        width: 100%;
      }
    }
  }

  .block-experience {
    .CheckboxField {
      width: 100%;
    }
    .ReduxTextField,
    .BaseSelectField,
    .PhoneField {
      width: 49%;
      @include respond-to(sm) {
        width: 100%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserKYCForm__inputs-inner {
      background-color: ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
