import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextField {
    &__input {
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.2px;
      padding: 6px 9px 6px 9px;

      &::placeholder {
        color: #696969;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
