import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageLayout, PageContentPreloader } from 'common/components';
import { TasksPlan, TasksList } from '../../_components';

const TasksPage = ({ plans, plansAreLoaded }) => {
  const displayPlans = plans.overdue?.length || plans.today?.length || plans.tomorrow?.length;

  return (
    <PageLayout captionSlug="justTasks">
      <PageContentPreloader ready={plansAreLoaded} type="bigLine" firstLaunchOnly>
        {!!displayPlans && <TasksPlan plans={plans} />}
        <TasksList />
      </PageContentPreloader>
    </PageLayout>
  );
};

TasksPage.propTypes = {
  plans: PropTypes.object.isRequired,
  plansAreLoaded: PropTypes.bool.isRequired,
};

export default compose(
  connect(state => ({
    plans: state.backoffice.managers.plans,
    plansAreLoaded: state.backoffice.managers.plansAreLoaded,
  }))
)(TasksPage);
export { TasksPage };
