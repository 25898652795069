import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { PageContentPreloader, PageLayout } from 'common/components';
import { UploadPromoForm } from '../../_components';

const UploadPromoPage = ({ availableLangs, files, filesAreLoaded, history, location }) => {
  const { campaignList, banner } = location.state;

  if (history.action === 'POP') {
    history.push('/backoffice/promo/add');
  }

  const banners = Array.isArray(files)
    ? files.reduce(
        (acc, cur, id) => ({ ...acc, [`banner${id}`]: { file: cur.id, uploadFile: cur.relativeUrl, ...banner } }),
        {}
      )
    : {};
  return (
    <PageLayout captionSlug="uploadBanners">
      <PageContentPreloader ready={filesAreLoaded} type="bigLine">
        <UploadPromoForm
          promoCount={files.length}
          initialValues={banners}
          availableLangs={availableLangs}
          campaignList={campaignList}
        />
      </PageContentPreloader>
    </PageLayout>
  );
};

UploadPromoPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  files: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filesAreLoaded: PropTypes.bool.isRequired,
  availableLangs: PropTypes.array.isRequired,
};

UploadPromoPage.defaultProps = {
  files: [],
};

export default compose(
  withRouter,
  connect(state => ({
    availableLangs: state.interfaceConfig.availableLangs,
    files: state.admin.filesManagement.files || [],
    filesAreLoaded: state.admin.filesManagement.filesAreLoaded,
  }))
)(UploadPromoPage);
export { UploadPromoPage };
