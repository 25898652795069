import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { NoItemsPanel, PageContentPreloader, PageLayout } from 'common/components';
import noPartnersImage from 'common/images/noPartners.svg';

import { getCurrentIBPrograms, getSimpleIBSystemSettings } from '../../_redux';
import { SimpleIBSystemSettingsPanel, CurrentIBProgramsItem } from '../../_components';

const PartnershipCurrentIBProgramsPage = ({
  partnershipSystem,
  getCurrentIBPrograms,
  currentIBPrograms,
  currentIBProgramsIsLoaded,
  getSimpleIBSystemSettings,
  simpleIBSystemSettings,
  simpleIBSystemSettingsAreLoaded,
}) => {
  const simplePartnerSystem = partnershipSystem === 'simple';

  useEffect(() => {
    if (!simplePartnerSystem) {
      getCurrentIBPrograms();
    } else {
      getSimpleIBSystemSettings();
    }
  }, [getCurrentIBPrograms, simplePartnerSystem, getSimpleIBSystemSettings]);

  return (
    <PageLayout captionSlug="partnershipPrograms">
      {simplePartnerSystem ? (
        <PageContentPreloader ready={simpleIBSystemSettingsAreLoaded} type="line">
          <SimpleIBSystemSettingsPanel simpleIBSystemSettings={simpleIBSystemSettings} />
        </PageContentPreloader>
      ) : (
        <PageContentPreloader ready={currentIBProgramsIsLoaded} type="line">
          {currentIBPrograms.some(ibProgram => ibProgram.conditions) ? (
            currentIBPrograms
              .filter(ibProgram => ibProgram.conditions)
              .map((ibProgram, index) => (
                <CurrentIBProgramsItem
                  key={index}
                  partnerCode={ibProgram.partnerCode}
                  conditions={ibProgram.conditions}
                />
              ))
          ) : (
            <NoItemsPanel
              icon={noPartnersImage}
              captionSlug="noPartnershipProgramsCaption"
              descriptionSlug="noPartnershipProgramsDescription"
            />
          )}
        </PageContentPreloader>
      )}
    </PageLayout>
  );
};

PartnershipCurrentIBProgramsPage.propTypes = {
  partnershipSystem: PropTypes.string.isRequired,
  getCurrentIBPrograms: PropTypes.func.isRequired,
  currentIBPrograms: PropTypes.array.isRequired,
  currentIBProgramsIsLoaded: PropTypes.bool.isRequired,
  getSimpleIBSystemSettings: PropTypes.func.isRequired,
  simpleIBSystemSettings: PropTypes.object.isRequired,
  simpleIBSystemSettingsAreLoaded: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    partnershipSystem: state.interfaceConfig.partnership.partnershipSystem,
    currentIBPrograms: state.client.partnership.currentIBPrograms,
    currentIBProgramsIsLoaded: state.client.partnership.currentIBProgramsIsLoaded,
    simpleIBSystemSettings: state.client.partnership.simpleIBSystemSettings,
    simpleIBSystemSettingsAreLoaded: state.client.partnership.simpleIBSystemSettingsAreLoaded,
  }),
  {
    getCurrentIBPrograms: () => getCurrentIBPrograms.request(),
    getSimpleIBSystemSettings: () => getSimpleIBSystemSettings.request(),
  }
)(PartnershipCurrentIBProgramsPage);
export { PartnershipCurrentIBProgramsPage };
