/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { compose } from 'redux';
import { RadioSearch } from 'crm/_components';

import { injectIntl, FormattedMessage as FM } from 'react-intl';

import { DateCell, StatusCell, Calendar } from 'common/components';

import { getCrmAccountTrades } from 'crm/users/_redux';
import queryString from 'query-string';

import { useParams, withRouter } from 'react-router-dom';
import { NewCRMTable } from 'crm/_newComponents';

import { staticStyles } from './style';

const startDate = moment().add(-6, 'day').startOf('day').unix();

const endDate = moment().add(1, 'day').startOf('day').unix();

const startCalendar = moment().add(-6, 'day').startOf('day');

const endCalendar = moment().add(1, 'day').startOf('day');

const ClientAccountOrderHistoryOrdersTable = ({ platformSlug, accountTrades, getCrmAccountTrades, location }) => {
  const { summary } = accountTrades;
  const { id, idAccount } = useParams();
  const [radio, setRadio] = useState('open');
  const [dates, setDates] = useState({ start: startDate, end: endDate });
  const query = useMemo(() => ({ ...queryString.parse(location.search) }), [location.search]);

  const onCalendarChange = (start, end) => {
    setDates(prev => ({ ...prev, start, end }));
  };

  useEffect(() => {
    getCrmAccountTrades({
      id,
      accountId: idAccount,
      operation: radio,
      search: {
        start: moment(dates.start).format('x'),
        end: moment(dates.end).format('x'),
      },
    });
  }, [radio, query.page, dates]);

  const memoizedOnChangeParams = useMemo(() => ({}), []);

  const columns = [
    {
      Header: 'justOrder',
      Cell: ({ rowData: { id } }) => <span>{id}</span>,

      isPreviewCell: true,
    },
    {
      Header: 'justSymbol',
      Cell: ({ rowData: { symbol } }) => (symbol ? <span>{symbol}</span> : '一'),
      isPreviewCell: true,
    },
    {
      Header: 'justType',
      Cell: ({ rowData: { command } }) => <StatusCell statusCode={command} noDot noTranslate />,
    },
    {
      Header: 'justOpenTime',
      Cell: ({ rowData: { open_time } }) => <DateCell value={open_time} hideTime />,
    },
    {
      Header: () => <FM id={radio === 'delayed' ? 'justExpiration' : 'justCloseTime'}>{txt => txt}</FM>,
      Cell: ({ rowData: { close_time, expiration } }) => (
        <div>{close_time || expiration ? <DateCell value={close_time || expiration} hideTime /> : '一'}</div>
      ),
    },
    {
      Header: 'justOpenPrice',
      Cell: ({ rowData: { price, open_price } }) => (open_price ? <span>{price || open_price}</span> : '一'),
    },
    {
      Header: 'justClosePrice',
      Cell: ({ rowData: { close_price } }) => (close_price ? <span>{close_price}</span> : '一'),
      Footer: () => <FM id="justTotal">{txt => <span className="Footer__Total">{txt}</span>}</FM>,
    },
    {
      Header: 'justLots',
      Cell: ({ rowData: { lots, command } }) => <span>{lots === 0.01 && command === 'In/Out' ? 0 : lots}</span>,
      Footer: () => <div className="TotalCell">{summary ? summary.lots : ''}</div>,
    },
    {
      Header: 'justCommission',
      Cell: ({ rowData: { commission } }) => <span>{commission}</span>,
      Footer: () => <div className="TotalCell">{summary ? summary.commission : '一'}</div>,
    },
    {
      Header: 'justSwap',
      Cell: ({ rowData: { swap } }) => <span>{swap}</span>,
      Footer: () => <div className="TotalCell">{summary ? summary.swap : '一'}</div>,
    },
    {
      Header: 'justProfit',
      Cell: ({ rowData: { profit } }) => <span>{profit}</span>,
      Footer: () => <div className="TotalCell">{summary ? summary.profit : '一'}</div>,
      isPreviewCell: true,
    },
  ];

  return (
    <div className="ClientPageHistory__bottom">
      <div className="ClientPageHistory__radio-list">
        <RadioSearch
          externalClass="ClientPageHistory__radio-open-orders"
          onClick={() => setRadio('open')}
          textId="crmOpenOrders"
          active={radio === 'open'}
        />
        {platformSlug !== 'mt5' && (
          <RadioSearch
            externalClass="ClientPageHistory__radio-closed-orders"
            onClick={() => setRadio('closed')}
            textId="crmClosedOrders"
            active={radio === 'closed'}
          />
        )}
        <RadioSearch
          externalClass="ClientPageHistory__radio-pending-orders"
          onClick={() => setRadio('delayed')}
          textId="crmPendingOrders"
          active={radio === 'delayed'}
        />
        <Calendar
          daysLimit={31}
          onChangeFunc={onCalendarChange}
          onChangeParams={memoizedOnChangeParams}
          defaultStartDate={startCalendar}
          defaultEndDate={endCalendar}
        />
      </div>

      <NewCRMTable columns={columns} data={accountTrades.trades} hasFooter />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    state => ({
      accountTrades: state.crm.users.accountTrades,
      accountTradesAreLoaded: state.crm.users.accountTradesAreLoaded,
    }),
    { getCrmAccountTrades: data => getCrmAccountTrades.request(data) }
  )
)(ClientAccountOrderHistoryOrdersTable);
