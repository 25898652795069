import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const FormMessage = ({ messageTextId, showMessage, success, fail, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div
      className={classNames('FormMessage', {
        success,
        fail,
        FormMessage__active: showMessage,
      })}>
      <FormattedMessage id={messageTextId}>
        {txt => (
          <p>
            {txt}
            <i />
          </p>
        )}
      </FormattedMessage>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

FormMessage.propTypes = {
  messageTextId: PropTypes.string.isRequired,
  showMessage: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  fail: PropTypes.bool,
  theme: PropTypes.object,
};

FormMessage.defaultProps = {
  success: false,
  fail: false,
  theme: {},
};

const FormMessageTheme = withTheme()(FormMessage);

export default FormMessageTheme;
export { FormMessage };
