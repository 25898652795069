import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import { getIBManagerList, getAvailablePartnerCodes } from 'crm/users/api';
import { editContactsIBManager } from 'crm/contacts/_redux';
import BaseAsyncSelectField from '../../fields/BaseAsyncSelectField';
import TextFormField from '../../fields/TextFormField';
import { staticStyles } from './style';

const ReassignIBManagerForm = ({ handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const selectedContactsId = useSelector(state => state.crm.contacts.selectedContactsIds);
  const location = useLocation();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const submit = handleSubmit(values =>
    editContactsIBManager({ ...values, contactsId: selectedContactsId, search: query }, dispatch)
  );
  const loadManagerOptions = search =>
    getIBManagerList(search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    );

  const loadParnterOptions = search =>
    // temp 2 instead of id
    getAvailablePartnerCodes(2, search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: manager.partnerCode }))
    );
  return (
    <form className="ReassignIBManagerForm" onSubmit={submit}>
      <Field
        name="ibManager"
        component={BaseAsyncSelectField}
        validate={[required()]}
        loadOptions={loadManagerOptions}
        disabled={submitting}
        maxMenuHeight={150}
        labelTextId="justManager"
      />
      <Field
        name="taskComment"
        component={TextFormField}
        labelTextId="crmTaskCommentOptional"
        placeholderId="justTaskComment"
      />
      <Field
        name="partnerAccount"
        component={BaseAsyncSelectField}
        loadOptions={loadParnterOptions}
        type="number"
        labelTextId="crmSetPartnerCodeOptional"
        placeholderId="authPartnerCodeField"
      />
      <BaseButton textId="justReassign" maxContent type="submit" disabled={pristine || invalid || submitting} />
      {error && <span>{error}</span>}
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

ReassignIBManagerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ReassignIBManagerForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-reassign-ib-manager',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
  })
)(ReassignIBManagerForm);
