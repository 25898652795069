import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withTheme, taggedStylesToString } from 'common/styling/theme';

import { toggleMenu } from '../../_redux/actions';

import { staticStyles, getDynamicStyles } from './style';

const BurgerButton = ({ onClick, isMenuOpen, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <button
      className={classNames('hamburger hamburger--slider', {
        opened: isMenuOpen,
      })}
      type="button"
      onClick={onClick}>
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </button>
  );
};

BurgerButton.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

BurgerButton.defaultProps = {
  theme: {},
};

const ThemedBurgerButton = withTheme()(
  connect(state => ({ isMenuOpen: state.user.isMenuOpen }), { onClick: () => toggleMenu() })(BurgerButton)
);

export default ThemedBurgerButton;
export { BurgerButton };
