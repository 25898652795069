import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withTheme } from 'common/styling/theme';

import { RadioButton, BasicCRMModal } from 'crm/_components';
import { uploadContacts } from 'crm/contacts/_redux';
import CreateContactForm from '../CreateContactForm';
import UploadContactForm from '../UploadContactForm';

import { staticStyles, getDynamicStyles } from './style';

const AddContact = ({ isOpen, onRequestClose, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const dispatch = useDispatch();
  const [activeForm, setActiveForm] = useState('create');

  const handleUploadContacts = uploadFile => {
    dispatch(uploadContacts.request(uploadFile));
    onRequestClose();
  };

  return (
    <BasicCRMModal captionId="crmUploadContact" isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="AddContact">
        <div className="AddContact__controls">
          <RadioButton
            textId="crmCreateContact"
            active={activeForm === 'create'}
            onClick={() => setActiveForm('create')}
          />
          <RadioButton
            textId="crmUploadContact"
            active={activeForm === 'upload'}
            onClick={() => setActiveForm('upload')}
          />
        </div>
        {activeForm === 'create' ? (
          <CreateContactForm onSuccess={onRequestClose} />
        ) : (
          <UploadContactForm onSubmit={handleUploadContacts} />
        )}
        <style jsx>{staticStyles}</style>
        <style>{dynamicStyles}</style>
      </div>
    </BasicCRMModal>
  );
};

AddContact.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

AddContact.defaultProps = {
  theme: {},
};

export default withTheme()(AddContact);
