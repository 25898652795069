import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FieldHelpText {
    height: 0;
    .Tooltip__text {
      width: rem(20px);
      height: rem(20px);
      border-radius: 50%;
      position: absolute;
      top: 18px;
      right: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      transition: all 0.3s ease;
      font-size: rem(13px);
      &:after {
        content: '?';
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .FieldHelpText {
      .Tooltip__text {
        background: ${rgba([colors.gray.rgb, 0.4])};
        color: ${colors.white.hex};
        &:hover {
          background: ${rgba([colors.primary.rgb, 0.4])};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
