import React from 'react';
import PropTypes from 'prop-types';

import { PaginationTable, MoneyCell, StatusCell, DateCell } from 'common/components';

const PartnerAccountInfoTable = ({ data, onRowClick, getList, loading, history, location, params }) => {
  /* eslint-disable */
  const columns = [
    {
      key: 'accountId',
      Cell: ({ original: { account } }) => <p className="account">{account}</p>,
    },
    {
      key: 'justAmount',
      Cell: ({ original: { calculatedAmount } }) => (
        <MoneyCell value={calculatedAmount} loading={false} loadingFailed={false} />
      ),
    },
    {
      key: 'justStatus',
      Cell: ({ original: { status } }) => <StatusCell statusCode={status} />,
    },
    {
      key: 'clientAccountRecipient',
      Cell: ({ original: { withdrawAccount } }) => <p className="recipient-account">{withdrawAccount || '-'}</p>,
    },
    {
      key: 'justDate',
      Cell: ({ original: { date } }) => <DateCell value={date} hideTime/>,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="PartnerAccountInfoTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getList={getList}
        location={location}
        history={history}
        params={params}
        getTrProps={rowProps}
      />
    </div>
  );
};

PartnerAccountInfoTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default PartnerAccountInfoTable;
