import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { wsGetAvailableLangs } from 'welcome-setup/_redux';
import { staticStyles } from './style';
import Arrow from './icons/arrow';

const FlagImage = ({ flag }) => <img src={`/countryIcons/${flag}.png`} alt="country" />;

FlagImage.propTypes = {
  flag: PropTypes.string.isRequired,
};

const currentLangSlug = localStorage.getItem('locale');

function LanguageSelector({ availableLangs, wsGetAvailableLangs }) {
  useEffect(() => {
    wsGetAvailableLangs();
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const [currentLang, ...options] = useMemo(() => {
    let currentLang;
    const langsWithoutCurrent = availableLangs.filter(lang => {
      if (currentLangSlug === lang.slug) {
        currentLang = lang;
        return false;
      }
      return true;
    });
    if (!currentLang) {
      currentLang = { slug: 'en', niceName: 'English', flag: 'gb' };
    }
    return [currentLang, ...langsWithoutCurrent];
  }, [availableLangs]);

  return (
    <span className="LanguageSelectorContainer">
      <button type="button" className="LanguageSelectorButton" onClick={() => setIsOpen(!isOpen)}>
        <div className="LanguageSelectorButton__Image">
          <FlagImage flag={currentLang.flag} />
        </div>
        <div>
          <Arrow />
        </div>
      </button>
      {isOpen && (
        <div className="LanguageSelectorSelect">
          {options.map(({ slug, niceName, flag }) => (
            <button
              key={`LanguageSelectorSelect-language-${slug}`}
              className="LanguageSelectorSelect__Item"
              onClick={() => {
                localStorage.setItem('locale', slug);
                window.location.reload();
              }}>
              <div className="LanguageSelectorSelect__Image">
                <FlagImage flag={flag} />
              </div>
              <span>{niceName}</span>
            </button>
          ))}
        </div>
      )}
      <style jsx global>
        {staticStyles}
      </style>
    </span>
  );
}

LanguageSelector.propTypes = {
  availableLangs: PropTypes.array.isRequired,
  wsGetAvailableLangs: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    availableLangs: state.welcomeSetup.availableLangs,
  }),
  {
    wsGetAvailableLangs: () => wsGetAvailableLangs.request(),
  }
)(LanguageSelector);
