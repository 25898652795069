import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RadioButton {
    display: flex;
    cursor: pointer;

    input {
      display: none;
      visibility: hidden;
      opacity: 0;
      clip-path: polygon(0 0);

      &:checked + .RadioButton__fake {
        border-width: 7px;
      }
    }

    &__fake {
      width: 20px;
      height: 20px;
      min-width: 20px;
      border-radius: 50%;
      border: 1px solid;
      display: inline-block;
      margin-right: 10px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .RadioButton {
      &__fake {
        border-color: ${colors.gray.hex};
        transition: all 0.3s ease;
      }

      :global(input:checked + .RadioButton__fake) {
        border-color: ${colors.primary.hex} !important;
        transition: all 0.3s ease;
      }
    }

    .RadioButton--disabled {
      .RadioButton__fake {
        border-color: ${colors.lightGray.hex} !important;
      }
      .RadioButton__text {
        color: ${colors.lightGray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
