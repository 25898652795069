import css from 'styled-jsx/css';

import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .GetClientButton {
    display: flex;
    align-items: center;
    margin-right: 64px;

    font-size: rem(14px);
    font-weight: 500;
    line-height: 1.4;

    &:hover &__button {
      background: #0990cb;
    }

    &__button {
      position: relative;
      width: 32px;
      height: 32px;

      margin-right: 12px;
      border-radius: 4px;

      cursor: pointer;
      transition: all 0.3s ease;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        width: rem(14px);
        height: rem(2px);
        top: 51%;
        transform: translateY(-51%);
        left: 9px;
      }

      &:after {
        transform: rotate(-90deg);
        top: 48%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .GetClientButton {
      color: ${colors.black.hex};

      &__button {
        background: ${colors.primary.hex};

        &::after,
        &::before {
          background: ${colors.white.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
