import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormMessageHOC } from 'common/HOCs';
import { required } from 'redux-form-validators';
import { withTheme } from 'common/styling/theme';

import { reduxForm, Field } from 'redux-form';

import { updateCrmTask } from 'crm/tasks/_redux/';

import { BaseButton, BaseStaticSelect } from 'crm/_components';
import { TextFormField, DateFormField } from 'crm/_components/forms/';
import { getManagerList } from 'crm/users/api';
import BaseAsyncSelectField from 'crm/_components/forms/fields/BaseAsyncSelectField';
import { staticStyles } from './style';

const EditTaskForm = ({
  id,
  subject,
  title: oldtitle,
  status: oldStatus,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const managerId = useSelector(state => state.user.profile.id);
  const [comment, setComment] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState(oldtitle);
  const [status, setStatus] = useState(oldStatus === 'OPEN' ? 'Open' : 'Closed');

  const submit = handleSubmit(values => {
    const notEmptyData = {};
    Object.keys(values).forEach(key => {
      if (values[key]) {
        notEmptyData[key] = values[key];
      }
    });
    const data = {
      ...notEmptyData,
      id,
      subject,
      managerId,
      status: status.toUpperCase(),
      assignedTo: values.assignedTo.value,
    };
    updateCrmTask(data, dispatch);
  });

  const yearsOptions = [...Array(10).keys()].map(i => ({
    value: moment().year() + i,
    label: moment().year() + i,
  }));

  // const platformOptions = [
  //   { value: PLATFORMS.TELEGRAM, label: 'Telegram' },
  //   { value: PLATFORMS.VIBER, label: 'Viber' },
  //   { value: PLATFORMS.MESSENGER, label: 'Messenger' },
  //   { value: PLATFORMS.WHATSAPP, label: 'Whatsapp' },
  //   { value: PLATFORMS.WECHAT, label: 'WeChat' },
  //   { value: PLATFORMS.EMAIL, label: 'Email' },
  // ];

  const loadOptions = search =>
    getManagerList(search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    );

  return (
    <form className="EditTaskForm" onSubmit={submit}>
      <div className="EditTaskForm__line">
        <Field
          labelTextId="justTitle"
          component={TextFormField}
          name="title"
          placeholder="Task Title"
          validate={[required()]}
          onChange={value => setTitle(value)}
          value={title}
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          labelTextId="justDescription"
          component={TextFormField}
          name="description"
          placeholder="Task Description"
          onChange={value => setDescription(value)}
          value={description}
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          searchPromptTextId="Start typing..."
          labelTextId="crmJustAssignToManager"
          component={BaseAsyncSelectField}
          name="assignedTo"
          loadOptions={loadOptions}
          disabled={submitting}
          maxMenuHeight={150}
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          component={DateFormField}
          type="date"
          name="expirationDate"
          label="Expiration date"
          placeholder="dd.mm.yyyy"
          disabled={submitting}
          yearsOptions={yearsOptions}
          areFutureDaysAvailable
          withTime
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          labelTextId="justManagerComment"
          component={TextFormField}
          name="comment"
          validate={[required()]}
          placeholder="Manager Comment"
          onChange={value => setComment(value)}
          value={comment}
        />
      </div>
      <BaseStaticSelect
        highliteSelected
        labelTextId={status}
        options={[
          { label: 'Open', value: 'OPEN', isSelected: false },
          { label: 'Closed', value: 'CLOSED', isSelected: false },
        ]}
        onSelect={value => {
          setStatus(value);
        }}
      />
      {error && <strong className="EditTaskForm__formError">{error}</strong>}
      <div className="EditTaskForm__submit">
        <BaseButton type="submit" textId="justSave" disabled={invalid || submitting} />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditTaskForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditTaskForm.defaultProps = {
  error: '',
};

export default compose(
  withTheme(),
  FormMessageHOC,
  withRouter,
  reduxForm({
    form: 'crm-edit-task-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmTaskWasSuccessfullyEdited');
      props.onRequestClose();
    },
  })
)(EditTaskForm);
