import React, { useState } from 'react';
import { FastFilters } from 'crm/_newComponents';
import { getCRMFastFilters } from 'crm/_redux/actions';
import { ActionsTab, NewContactsTable } from '../../_components';

import { staticStyles } from './style';

const ContactsPage = () => {
  const [customValueOfOneFilter, setCustomValueOfOneFilter] = useState({
    filterName: 'tagContactFilter',
    inputType: 'inputIntegerChoice',
    newValue: [],
  });
  const customOnChangeForOneFilter = tagValue => {
    // FIXME: НЕ работает Сломались быстрые фильтры
    setCustomValueOfOneFilter(prevState => ({ ...prevState, tagValue }));
  };
  return (
    <div className="ContactsPage">
      <ActionsTab />
      <FastFilters
        getFastFiltersFunc={getCRMFastFilters}
        fastFiltersSelector={state => state.crm.common.fastFilters}
        fastFiltersModifiedSelector={state => state.crm.common.modifiedFilters}
        customValueOfOneFilter={customValueOfOneFilter}
      />

      {/* <ContactsTable onTagSelect={customOnChangeForOneFilter} /> */}
      <NewContactsTable onTagSelect={customOnChangeForOneFilter} />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default ContactsPage;
