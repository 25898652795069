import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';
import { isValidNumber } from 'libphonenumber-js';
import { compose } from 'redux';

import { FormMessageHOC } from 'common';
import {
  ReduxSelectField,
  ReduxTextField,
  PhoneField,
  CheckboxField,
  DateField,
  DefaultButton,
} from 'common/components';
import { withTheme } from 'common/styling/theme';

import { sendKYC } from '../../_redux';
import { SelectCountryField } from './fields';

import { staticStyles, getDynamicStyles } from './style';

const validatePhoneNumber = phoneNumber => {
  if (!isValidNumber(phoneNumber)) {
    return <FormattedMessage id="validationErrorWrongPhone" />;
  }
};

const TrederoCustomKYCForm = ({
  handleSubmit,
  submitting,
  invalid,
  error,
  user,
  countries,
  states,
  countriesAreLoaded,
  statesAreLoaded,
  mandatoryDocumentsAreSending,
  removeKycTaxCheckbox,
  dispatch,
  intl,
  theme,
}) => {
  const submit = handleSubmit(formValues => {
    if (removeKycTaxCheckbox) {
      formValues = { ...formValues, usTax: true };
    }
    return sendKYC(formValues, dispatch);
  });

  const countriesOptions = countries
    .map(option => ({
      value: option.gid,
      label: option.name,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const statesOptions = states
    .map(state => ({
      value: state.gid,
      label: state.name,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const generateOptionsFromTranslations = keys =>
    keys.map(key => ({ value: key, label: intl.formatMessage({ id: key }) }));
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const disabled = user.status === 'verified' || submitting;

  return (
    <form className="KYCForm" onSubmit={submit}>
      <div className="KYCForm__inputs-inner">
        <FormattedMessage id="generalInfo">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="KYCForm__inputs-block">
          <Field
            component={ReduxTextField}
            type="text"
            name="firstName"
            textId="kycFirstName"
            disabled={disabled}
            validate={[required()]}
          />
          <Field component={ReduxTextField} type="text" name="middleName" textId="kycMiddleName" disabled={disabled} />
          <Field
            component={ReduxTextField}
            type="text"
            name="lastName"
            textId="kycLastName"
            disabled={disabled}
            validate={[required()]}
          />
        </div>

        <div className="KYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxSelectField}
            name="taxResidence"
            textId="kycTaxResidence"
            options={countriesOptions}
            disabled={disabled}
            validate={[required()]}
            searchable
          />
          <Field
            component={DateField}
            type="date"
            name="birthday"
            textId="kycBirthday"
            placeholder="dd.mm.yyyy"
            disabled={disabled}
            validate={[required()]}
          />
        </div>

        <div className="KYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxSelectField}
            name="nationality"
            textId="kycNationality"
            options={countriesOptions}
            disabled={disabled}
            validate={[required()]}
            searchable
          />
          <Field
            component={PhoneField}
            name="phone"
            textId="kycPhone"
            disabled={disabled || user.phoneConfirmed}
            validate={[required(), validatePhoneNumber]}
          />
        </div>

        {!removeKycTaxCheckbox && (
          <Field component={CheckboxField} name="usTax" textId="kycUsTax" disabled={disabled} />
        )}
      </div>
      <div className="KYCForm__inputs-inner">
        <FormattedMessage id="kycAddress">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="KYCForm__inputs-block block-two-inp">
          <Field
            component={SelectCountryField}
            name="address.country"
            textId="kycCountry"
            options={countriesOptions}
            disabled={disabled || !countriesAreLoaded}
            validate={[required()]}
          />
          <Field
            component={ReduxSelectField}
            name="address.state"
            textId="kycState"
            options={statesOptions}
            disabled={disabled || !statesAreLoaded}
            validate={[required()]}
            searchable
          />
        </div>

        <div className="KYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxTextField}
            type="text"
            name="address.city"
            textId="kycCity"
            disabled={disabled}
            validate={[required()]}
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="address.address"
            textId="kycAddress"
            disabled={disabled}
            validate={[required()]}
          />
        </div>
      </div>

      <div className="KYCForm__inputs-inner">
        <FormattedMessage id="trederoCustomKYCEmploymentStatus">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block block-full-width">
          <Field
            component={ReduxSelectField}
            name="employmentStatus"
            textId="trederoCustomKYCEmploymentStatus"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCEmploymentStatusEmployed',
              'trederoCustomKYCEmploymentStatusSelfEmployed',
              'trederoCustomKYCEmploymentStatusUnemployed',
              'trederoCustomKYCEmploymentStatusStudent',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCIndustry">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="industry"
            textId="trederoCustomKYCIndustry"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCIndustryAccountancy',
              'trederoCustomKYCIndustryAdminSecretarial',
              'trederoCustomKYCIndustryAgriculture',
              'trederoCustomKYCIndustryFinancialServices',
              'trederoCustomKYCIndustryHealthMedicine',
              'trederoCustomKYCIndustryIT',
              'trederoCustomKYCIndustryLegal',
              'trederoCustomKYCIndustryMarketingAdvertising',
              'trederoCustomKYCIndustryPropertyConstructions',
              'trederoCustomKYCIndustryRetail',
              'trederoCustomKYCIndustrySales',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCEstimatedAnnualIncome">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="estimatedAnnualIncome"
            textId="trederoCustomKYCEstimatedAnnualIncome"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCEstimatedAnnualIncomeLessThan5k',
              'trederoCustomKYCEstimatedAnnualIncome5kTo10k',
              'trederoCustomKYCEstimatedAnnualIncome10kTo50k',
              'trederoCustomKYCEstimatedAnnualIncome50kTo100k',
              'trederoCustomKYCEstimatedAnnualIncome100kTo500k',
              'trederoCustomKYCEstimatedAnnualIncomeMoreThan500k',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCIndustry">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="estimatedNetWorth"
            textId="trederoCustomKYCEstimatedNetWorth"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCEstimatedNetWorthLessThan5k',
              'trederoCustomKYCEstimatedNetWorth5kTo10k',
              'trederoCustomKYCEstimatedNetWorth10kTo50k',
              'trederoCustomKYCEstimatedNetWorth50kTo100k',
              'trederoCustomKYCEstimatedNetWorth100kTo500k',
              'trederoCustomKYCEstimatedNetWorthMoreThan500k',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCSavings">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="savings"
            textId="trederoCustomKYCSavings"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCSavingsLessThan5k',
              'trederoCustomKYCSavings5kTo10k',
              'trederoCustomKYCSavings10kTo50k',
              'trederoCustomKYCSavings50kTo100k',
              'trederoCustomKYCSavings100kTo500k',
              'trederoCustomKYCSavingsMoreThan500k',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCSourceOfIncome">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="sourceOfIncome"
            textId="trederoCustomKYCSourceOfIncome"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCSourceOfIncomeEarnedIncome',
              'trederoCustomKYCSourceOfIncomeSavings',
              'trederoCustomKYCSourceOfIncomeInvestmentsAndDividends',
              'trederoCustomKYCSourceOfIncomePension',
              'trederoCustomKYCSourceOfIncomeInheritance',
              'trederoCustomKYCSourceOfIncomeOther',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCExpectation">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="expectation"
            textId="trederoCustomKYCExpectation"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCExpectationLessThan5k',
              'trederoCustomKYCExpectation5kTo10k',
              'trederoCustomKYCExpectation10kTo50k',
              'trederoCustomKYCExpectation50kTo100k',
              'trederoCustomKYCExpectation100kTo500k',
              'trederoCustomKYCExpectationMoreThan500k',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCPurpose">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="purpose"
            textId="trederoCustomKYCPurpose"
            options={generateOptionsFromTranslations([
              'trederoCustomKYCPurposeSpeculation',
              'trederoCustomKYCPurposeAdditional',
              'trederoCustomKYCPurposeSavings',
              'trederoCustomKYCPurposeOther',
            ])}
            disabled={disabled}
          />
        </div>
        <FormattedMessage id="trederoCustomKYCFXCFD">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="KYCForm__inputs-block  block-full-width">
          <Field
            component={ReduxSelectField}
            name="fxCfd"
            textId="trederoCustomKYCFXCFD"
            options={generateOptionsFromTranslations(['trederoCustomKYCFXCFDYes', 'trederoCustomKYCFXCFDNo'])}
            disabled={disabled}
          />
        </div>
      </div>

      {user.status === 'verified' && (
        <div id="" className="KYCForm__inputs-inner KYCForm__text">
          <FormattedMessage
            id="ifUserSettingIsChange"
            values={{
              support: (
                <Link to="/support/" id="tredero-KYCForm-Link-to-support">
                  <FormattedMessage id="contactCustomerSupport" />
                </Link>
              ),
            }}
          />
        </div>
      )}

      {!mandatoryDocumentsAreSending && (
        <div className="KYCForm__inputs-inner KYCForm__text">
          <FormattedMessage id="kycUploadeMandatoryDocuments" />
        </div>
      )}

      {user.status !== 'verified' && (
        <DefaultButton
          textId="sendToVerify"
          type="submit"
          disabled={invalid || submitting || user.status === 'verified' || !mandatoryDocumentsAreSending}
          loading={submitting}
          filled
        />
      )}
      {error && <strong className="formError">{error}</strong>}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </form>
  );
};

TrederoCustomKYCForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  statesAreLoaded: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  mandatoryDocumentsAreSending: PropTypes.bool.isRequired,
  removeKycTaxCheckbox: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

TrederoCustomKYCForm.defaultProps = {
  error: '',
  theme: {},
};

const ThemedKYCForm = compose(
  withTheme(),
  FormMessageHOC,
  injectIntl,
  connect(state => ({
    removeKycTaxCheckbox: state.interfaceConfig.features.includes('removeKycTaxCheckbox'),
  })),
  reduxForm({
    form: 'TrederoCustomKYCForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage();
    },
  })
)(TrederoCustomKYCForm);

export default ThemedKYCForm;
export { TrederoCustomKYCForm };
