import axios from 'axios';

const URLS = {
  categories: '/config/metadata/',
  uploadImage: '/config/image_upload/',
};

const uploadImage = data => axios.post(URLS.uploadImage, data);

const getCategoryList = () => axios.get(URLS.categories);

export { uploadImage, getCategoryList };
