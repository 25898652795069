import React from 'react';
import { colorType, withTheme } from '../../../../styling/theme';

const BarChartIcon = ({ primaryColor }) => (
  <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1V15H0V1Z" fill={primaryColor.hex} />
    <path d="M3 8C3 7.44772 3.44772 7 4 7C4.55228 7 5 7.44772 5 8V15H3V8Z" fill={primaryColor.hex} />
    <path d="M6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4V15H6V4Z" fill={primaryColor.hex} />
    <path d="M9 1C9 0.447715 9.44772 0 10 0C10.5523 0 11 0.447715 11 1V15H9V1Z" fill={primaryColor.hex} />
    <path d="M12 12C12 11.4477 12.4477 11 13 11C13.5523 11 14 11.4477 14 12V15H12V12Z" fill={primaryColor.hex} />
    <path d="M15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8V15H15V8Z" fill={primaryColor.hex} />
    <path d="M18 1C18 0.447715 18.4477 0 19 0C19.5523 0 20 0.447715 20 1V15H18V1Z" fill={primaryColor.hex} />
    <path d="M21 4C21 3.44772 21.4477 3 22 3C22.5523 3 23 3.44772 23 4V15H21V4Z" fill={primaryColor.hex} />
  </svg>
);

BarChartIcon.propTypes = {
  primaryColor: colorType,
};

BarChartIcon.defaultProps = {
  primaryColor: {},
};

const ThemedBarChartIcon = withTheme(({ colors: { lightGray } }) => ({
  primaryColor: lightGray,
}))(BarChartIcon);

export default ThemedBarChartIcon;
