import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TasksPlanList from '../TasksPlanList';

import { staticStyles } from './style';

const TasksPlan = ({ plans }) => (
  <div className="TasksPlan">
    <div className="TasksPlan__header">
      <FormattedMessage id="justMyPlan">{txt => <h2>{txt}</h2>}</FormattedMessage>
    </div>
    <div className="TasksPlan__list">
      <TasksPlanList type="overdue" tasks={plans.overdue} textId="justOverdueTasks" />
      <TasksPlanList type="today" tasks={plans.today} textId="justTodayTasks" />
      <TasksPlanList type="tomorrow" tasks={plans.tomorrow} textId="justTomorrowTasks" />
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

TasksPlan.propTypes = {
  plans: PropTypes.object.isRequired,
};

export default TasksPlan;
