import React from 'react';
import { FormattedMessage } from 'react-intl';

import noPreviewImg from './noPreview.svg';

import { staticStyles } from './style';

const NoPreview = () => (
  <div className="NoPreview">
    <div className="NoPreview__icon">
      <img src={noPreviewImg} alt="" />
    </div>
    <FormattedMessage id="noPreviewMessage">{txt => <p className="NoPreview__text">{txt}</p>}</FormattedMessage>
    <style jsx>{staticStyles}</style>
  </div>
);

export default NoPreview;
