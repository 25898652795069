import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxTextField, TextAreaField, DefaultButton } from 'common/components';

import { editDepositAmount } from 'backoffice/payments/_redux';

import staticStyles from './style';

const EditAmountForm = ({ id, amount, type, toggleModal, handleSubmit, dispatch, submitting, invalid, error }) => {
  const submit = handleSubmit(values => editDepositAmount({ id, type, ...values }, dispatch));

  return (
    <div className="RejectModal__content">
      <form className="EditAmountForm" onSubmit={submit}>
        <div className="EditAmountForm__inputs-inner">
          <Field
            component={ReduxTextField}
            autoComplete="off"
            type="text"
            name="currentAmount"
            textId="justCurrentAmount"
            validate={[required()]}
            description={amount.currency}
            disabled
          />
          <Field
            component={ReduxTextField}
            autoComplete="off"
            type="number"
            name="amount"
            textId="justNewAmount"
            validate={[required()]}
            disabled={submitting}
            description={amount.currency}
          />
          <Field
            component={TextAreaField}
            name="comment"
            textId="editAmountModalPublicComment"
            rows="4"
            cols="150"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
        <div className="EditAmountForm__line">
          <DefaultButton textId="justSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
          <DefaultButton textId="justCancel" onClick={toggleModal} />
        </div>
        {error && <strong className="formError">{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

EditAmountForm.propTypes = {
  amount: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  id: PropTypes.number.isRequired,
};

EditAmountForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'EditAmountForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(EditAmountForm);
export { EditAmountForm };
