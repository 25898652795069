import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, ReduxTextField } from 'common/components';
import { editInvestCRMStrategy } from 'backoffice/investNew/_redux';

import { staticStyles } from './style';

const validate = ({ name }) => {
  const errors = {};

  if (!/^[a-zA-Z0-9\s_!,.?]{0,50}$/.test(name)) {
    errors.name = <FormattedMessage id="rammStrategyNameHelpText" />;
  }

  return errors;
};

const EditInvestCRMStrategyForm = ({
  type,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
  id,
  search,
}) => {
  const submit = handleSubmit(values => {
    editInvestCRMStrategy({ search, id, values }, dispatch);
  });

  return (
    <>
      <form className="EditInvestCRMStrategyForm" onSubmit={submit}>
        <div className="EditInvestCRMStrategyForm__inputs-inner">
          <Field
            component={ReduxTextField}
            autoComplete="off"
            type={type === 'name' ? 'text' : 'number'}
            name={type}
            textId={type === 'name' ? 'justInvestStrategyName' : 'justInvestRewardPercentage'}
            validate={[required()]}
          />
        </div>

        <DefaultButton
          textId="justChange"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          filled
        />
        {error && <strong className="formError">{error}</strong>}
        <style jsx>{staticStyles}</style>
      </form>
    </>
  );
};

EditInvestCRMStrategyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

EditInvestCRMStrategyForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'EditInvestCRMStrategyForm',
  validate,
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(EditInvestCRMStrategyForm);
export { EditInvestCRMStrategyForm };
