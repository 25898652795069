import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .ContactNote {
    margin-bottom: 16px;
  }

  .ContactNote__content {
    background: #f8faff;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 70px 2fr 1fr 1fr;
    padding: 16px;
  }

  .ContactNote__NoteIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background: #e9e9e9;
    margin-right: 16px;
    @include respond-to(lg) {
      display: none;
    }
  }

  .ContactNote__Info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include respond-to(lg) {
      grid-area: 2/1/2/5;
      border-top: 1px solid #e8ebf0;
      margin-top: 16px;
      padding-top: 16px;
    }
  }

  .ContactNote__Title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    word-break: break-all;
    white-space: break-spaces;
  }

  .ContactNote__Text {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
    word-break: break-all;
    white-space: break-spaces;
  }

  .ContactNote__Author {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: 0.2px;
    text-decoration-line: underline;

    color: #000000;
    @include respond-to(lg) {
      grid-area: 4/1/4/5;
      border-top: 1px solid #e8ebf0;
      margin-top: 16px;
      padding-top: 16px;
      margin-bottom: 8px;
    }
    word-break: break-all;
    white-space: break-spaces;
  }

  .ContactNote__FilesText {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #696969;
  }

  .ContactNote__Files {
    grid-column-start: 2;
    @include respond-to(lg) {
      grid-area: 3/1/3/5;
    }
  }

  .ContactNote__DateCreated {
    font-family: $ubuntu-font !important;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #696969;
    @include respond-to(lg) {
      grid-area: 5/1/5/5;
    }
    :global(.DateCell) {
      justify-content: flex-end;
      @include respond-to(lg) {
        justify-content: flex-start;
      }
    }
  }

  .ContactNote__Actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-row-start: 3;
    grid-column-start: 4;
    @include respond-to(lg) {
      grid-area: 1/1/1/5;
    }
  }
  :global(.ActionsButton) > :global(span) {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
