import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  approvePhaseList: [],
  approvePhaseListIsLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_APPROVE_PHASE_LIST[REQUEST]:
      return { ...state, approvePhaseListIsLoaded: false };
    case ActionTypes.GET_APPROVE_PHASE_LIST[SUCCESS]:
      return { ...state, approvePhaseList: action.data, approvePhaseListIsLoaded: true };
    case ActionTypes.GET_APPROVE_PHASE_LIST[FAILURE]:
      return { ...state, error: action.error, approvePhaseListIsLoaded: false };

    case ActionTypes.DELETE_APPROVE_PHASE[REQUEST]:
      return { ...state, approvePhaseListIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
