import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .GetBannerForm {
    max-width: 430px;

    &__img {
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }

    &__label {
      margin-bottom: 10px;
    }

    :global(.BaseTextField) {
      margin-bottom: 20px;
    }

    :global(.BaseTextField__label) {
      display: none;
    }

    :global(.BaseTextField__inp) {
      padding-top: 11px;
    }
  }
`

export { staticStyles }
