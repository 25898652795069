import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  .RejectModal__content {
    max-width: 430px;
  }

  .EditPaymentManagerForm__line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: rem(10px 0);

    :global(.DefaultButton) {
      &:first-child {
        margin-right: ${isRTL ? '0' : '10px'};
        margin-left: ${isRTL ? '10px' : '0'};
      }
    }
  }
`;

export default staticStyles;
