import axios from 'axios';

export const getManagersSampleList = search => axios.get(`/managers/list/`, { params: { ...search } });
export const getManagerInfo = managerId => axios.get(`/managers/${managerId}/`);
export const getManagerSample = search => axios.get(`/managers/samples/list/`, { params: { ...search } });
export const createManagerSample = payload => axios.post(`/managers/samples/create/`, { ...payload });
export const editManagerSample = (sampleId, payload) => axios.put(`/managers/samples/${sampleId}/`, { ...payload });

export const getManagersGroupList = search => axios.get(`/managers/groups/list/`, { params: { ...search } });
export const createManagerGroup = payload => axios.post(`/managers/groups/create/`, { ...payload });
export const getManagerGroupDetails = managerGroupId => axios.get(`/managers/groups/${managerGroupId}/`);
export const editManagerGroup = (managerGroupId, payload) =>
  axios.put(`/managers/groups/${managerGroupId}/`, { ...payload });
export const deleteManagerGroup = managerGroupId => axios.delete(`/managers/groups/${managerGroupId}/`);

export const getManagerSalesStatusInfo = salesStatusId => axios.get(`/config/sales_status/${salesStatusId}`);
export const getManagerSalesStatuses = () => axios.get('/config/sales_status/');
export const createSalesStatus = payload => axios.post('/config/sales_status/', { ...payload });
export const deleteSalesStatus = id => axios.delete(`/config/sales_status/${id}`);
export const editSalesStatus = (salesStatusId, payload) =>
  axios.put(`/config/sales_status/${salesStatusId}/`, { ...payload });

export const getFiltersList = () => axios.get(`/backoffice/filters/get_filters/?target=contact`);
