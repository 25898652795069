/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ActionsButton } from 'common/components';
import Tags from '../Tags';
import { deleteContactFavorite, setContactFavorite } from '../../_redux';

import favoriteIcon32 from './icons/favorite.svg';
import unFavoriteIcon from './icons/unfovarite.svg';
import managerIcon from './icons/managerIcon.svg';
import { staticStyles } from './style';

const FavoriteIcon = ({ isFavorite }) =>
  isFavorite ? <img src={favoriteIcon32} alt="Unfavorite" /> : <img src={unFavoriteIcon} alt="Unfavorite" />;

const FullNameCell = ({
  setOpenTagsId,
  openTagsId,
  tableWidth,
  tags,
  onTagSelect,
  hideAllTags,
  isManager,
  id,
  fullName,
  favorites,
  setContactFavorite,
  deleteContactFavorite,
  history,
}) => {
  // FIXME: ЗАЧЕМ ОТПРАВЛЯТЬ МНЕ ОБЪЕКТ
  const isFavorite = useMemo(() => favorites.find(favorite => favorite.id === id), [favorites]);
  const cellRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleFavoriteClick = (e, id) => {
    e.stopPropagation();

    return isFavorite ? deleteContactFavorite(id) : setContactFavorite(id);
  };

  const toggleModalOpen = isHover => {
    if (isHover) {
      setIsOpen(true);
      setOpenTagsId(id);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const onMouseEnter = tags
    ? evt => {
        evt.stopPropagation();
        toggleModalOpen(true);
      }
    : () => {};

  useEffect(() => {
    if (id !== openTagsId) {
      setIsOpen(false);
    }
  }, [id, openTagsId]);

  return (
    <div
      className="FullNameCell"
      onClick={() => history.push(`/crm/contacts/${id}`)}
      ref={cellRef}
      onMouseEnter={onMouseEnter}>
      <span className={classNames('FullNameCell__FullName', { 'FullNameCell__FullName--IsManager': isManager })}>
        {fullName}
      </span>
      {isManager && (
        <ActionsButton tooltipId={`ContacsTable__manager-${id}`} tooltipTextId="crmUserIsManager">
          <img className="FullNameCell__ManagerIcon" src={managerIcon} alt="manager_icon" />
        </ActionsButton>
      )}
      <button type="button" className="FullNameCell__favorite" onClick={e => handleFavoriteClick(e, id, fullName)}>
        <FavoriteIcon isFavorite={isFavorite} />
      </button>
      {isOpen && !hideAllTags ? (
        <Tags
          onTagSelect={onTagSelect}
          tags={tags}
          tableWidth={tableWidth}
          id={id}
          onMouseLeave={evt => {
            evt.stopPropagation();
            toggleModalOpen();
          }}
        />
      ) : null}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

FullNameCell.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  favorites: PropTypes.array.isRequired,
  setContactFavorite: PropTypes.func.isRequired,
  deleteContactFavorite: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(state => ({ favorites: state.crm.contacts.favorites }), {
    setContactFavorite: data => setContactFavorite.request(data),
    deleteContactFavorite: data => deleteContactFavorite.request(data),
  })
)(FullNameCell);
