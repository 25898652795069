/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { DateCell } from 'common/components';
import { BaseButton, BasicCRMModal } from 'crm/_components';
import { deleteContactNote } from 'crm/users/_redux/actions';

import FeedIcon from './_components/FeedIcon';
import ReassignItem from './_components/ReassignItem';
import DepositWithdrawalItem from './_components/DepositWithdrawalItem';
import TaskItem from './_components/TaskItem';
import AccountItem from './_components/AccountItem';
import NoteItem from './_components/NoteItem';
import InternalTransferItem from './_components/InternalTransferItem';

import { staticStyles } from './style';

const TITLES = {
  deposits: 'crmDeposit',
  withdrawals: 'crmWithdraws',
};

const STATUSES = {
  accepted: 'success',
  deposited: 'success',
  done: 'success',
  COMPLETED: 'success',
  CANCELED: 'failed',
  failed: 'failed',
  rejected: 'failed',
  canceled: 'failed',
  declined: 'failed',
  processing: 'waiting',
  payout: 'waiting',
  waiting_confirmation: 'waiting',
  money_waiting: 'waiting',
  WAITING_FOR_APPROVAL: 'waiting',
};

const TabFeedItem = ({
  changedBy,
  reason,
  newManager,
  oldManager,
  id,
  objectType,
  login,
  whichEditTaskOpen,
  setWhichEditTaskOpen,
  accountType,
  handleEditNote,
  created,
  expirationDate,
  icon,
  paymentSystemSlug,
  paymentTo,
  amount,
  assignedTo,
  sender,
  recipient,
  sendAmount,
  createdBy,
  documents,
  text,
  title,
  description,
  taskTitle,
  comment,
  managerReaction,
  status,
}) => {
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { id: userId } = useParams();

  const openDeleteModal = () => {
    setIsDeleteNoteModalOpen(true);
  };

  const handleNoteDelete = () => {
    dispatch(deleteContactNote.request({ userId, noteId: id, reloadFeed: true }));
    setIsDeleteNoteModalOpen(false);
  };

  return (
    <div className={classNames('TabFeedItem', `TabFeedItem--${objectType}`)} key={id}>
      <div className="TabFeedItem__Wrap">
        <FeedIcon objectType={objectType} icon={icon} status={STATUSES[status]} taskStatus={managerReaction} />
        <div className="TabFeedItem__Subwrap">
          <div className="TabFeedItem__Info-wrap">
            <div className="TabFeedItem__Date">
              <DateCell value={created} />
            </div>
            <div className="TabFeedItem__Info">
              {objectType === 'reassign' && (
                <ReassignItem
                  oldManager={oldManager}
                  newManager={newManager}
                  reason={reason}
                  comment={comment}
                  changedBy={changedBy}
                />
              )}
              {(objectType === 'deposits' || objectType === 'withdrawals') && (
                <DepositWithdrawalItem
                  paymentStatus={STATUSES[status]}
                  title={TITLES[objectType]}
                  id={id}
                  paymentTo={paymentTo}
                  amount={amount}
                  paymentSystemSlug={paymentSystemSlug}
                />
              )}
              {objectType === 'tasks' && (
                <TaskItem
                  assignedTo={assignedTo}
                  title={taskTitle}
                  description={description}
                  expirationDate={expirationDate}
                  managerReaction={managerReaction}
                  whichEditTaskOpen={whichEditTaskOpen}
                  setWhichEditTaskOpen={setWhichEditTaskOpen}
                  id={id}
                  comment={comment}
                />
              )}
              {objectType === 'accounts' && <AccountItem login={login} accountType={accountType} />}
              {objectType === 'notes' && (
                <NoteItem
                  createdBy={createdBy}
                  title={title}
                  text={text}
                  handleEditNote={handleEditNote}
                  openDeleteModal={openDeleteModal}
                  id={id}
                  userId={userId}
                  documents={documents}
                />
              )}
              {objectType === 'internalTransfers' && (
                <InternalTransferItem
                  paymentStatus={STATUSES[status]}
                  sender={sender}
                  recipient={recipient}
                  sendAmount={sendAmount}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <BasicCRMModal
        captionId="confirmText"
        isOpen={isDeleteNoteModalOpen}
        onRequestClose={() => {
          setIsDeleteNoteModalOpen(false);
        }}>
        <FM id="confirmDeleteNote" />
        <div className="TabFeed__ModalButtons">
          <BaseButton
            color="white"
            textId="justCancel"
            onClick={() => {
              setIsDeleteNoteModalOpen(false);
            }}
          />
          <BaseButton color="red" textId="justDelete" onClick={handleNoteDelete} />
        </div>
      </BasicCRMModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TabFeedItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  objectType: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  setWhichEditTaskOpen: PropTypes.func.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  whichEditTaskOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  documents: PropTypes.array,
  paymentTo: PropTypes.object,
  changedBy: PropTypes.object,
  oldManager: PropTypes.object,
  newManager: PropTypes.object,
  amount: PropTypes.object,
  sendAmount: PropTypes.object,
  status: PropTypes.string,
  description: PropTypes.string,
  accountType: PropTypes.string,
  reason: PropTypes.string,
  expirationDate: PropTypes.string,
  login: PropTypes.string,
  assignedTo: PropTypes.string,
  icon: PropTypes.string,
  paymentSystemSlug: PropTypes.string,
  sender: PropTypes.string,
  recipient: PropTypes.string,
  createdBy: PropTypes.string,
  managerReaction: PropTypes.string,
  comment: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

TabFeedItem.defaultProps = {
  accountType: '',
  status: '',
  description: '',
  reason: '',
  expirationDate: '',
  assignedTo: '',
  login: '',
  icon: '',
  paymentSystemSlug: '',
  managerReaction: '',
  comment: '',
  title: '',
  text: '',
  documents: [],
  amount: {},
  paymentTo: {},
  whichEditTaskOpen: null,
  changedBy: null,
  newManager: null,
  oldManager: null,
  sendAmount: null,
  recipient: null,
  createdBy: null,
  sender: null,
};

export default TabFeedItem;
