import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { length, required } from 'redux-form-validators';
import { withTheme } from 'common/styling/theme';
import { TextFormField, TextAreaField } from 'crm/_components/forms';
import { BaseButton } from 'crm/_components';
import { FileField } from 'common/components';
import { useParams } from 'react-router-dom';

import { createContactDocument, createContactNote } from '../../_redux';

import AttachIcon from '../_icons/attach.svg';

import { staticStyles, getDynamicStyles } from './style';

const ContactNoteCreateForm = ({
  reloadFeed,
  handleSubmit,
  file,
  submitting,
  pristine,
  invalid,
  dispatch,
  error,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [documentsSelected, setDocumentsSelected] = useState(false);
  const [maxSizeError, setMaxSizeError] = useState({ error: false, size: 0 });
  const documentsRef = useRef(null);
  const { id } = useParams();
  const documentss = JSON.stringify(file) === '{}' || !file ? [] : [file];
  const submit = handleSubmit(values => createContactNote({ id, documentss, ...values }, dispatch));

  const handleAfterUpload = files => {
    if (files.length) {
      const formData = new FormData();
      const trimmerFileName = files[0].name.length > 30 ? files[0].name.substring(0, 30) : files[0].name;

      formData.set('name', trimmerFileName.replace(/\.[^.]+$/, ''));
      formData.append('file', files[0]);
      if (files) {
        setDocumentsSelected(true);
      }
      createContactDocument({ id, body: formData, reloadFeed }, dispatch);
    }
  };

  const maxSize = 5 * 1024 * 1024;

  return (
    <form className="ContactNoteCreateForm" onSubmit={submit}>
      <div className="ContactNoteCreateForm__inputs-inner">
        <Field
          component={TextFormField}
          name="title"
          labelTextId="crmNoteTitle"
          autoComplete="off"
          type="text"
          validate={[required(), length({ max: 100 })]}
          disabled={submitting}
        />
        <Field
          component={TextAreaField}
          name="text"
          textId="crmNoteText"
          rows="4"
          cols="150"
          validate={[required()]}
          disabled={submitting}
        />
        <div className="ContactNoteCreateForm__attach">
          <div className="ContactNoteCreateForm__attach-caption">
            <img src={AttachIcon} alt="attach" />
            <FormattedMessage id="justUploadFiles">
              {txt => (
                <label className="ContactNoteCreateForm__attach-caption-text" htmlFor="documents">
                  {txt}
                </label>
              )}
            </FormattedMessage>
          </div>
          <label className="ContactNoteCreateForm__attach-label" htmlFor="documents" ref={documentsRef}>
            <FormattedMessage id={documentsSelected ? 'justFileSelected' : 'justChoseFile'} />
          </label>
          <Field
            component={FileField}
            handleAfterUpload={handleAfterUpload}
            onSizeError={setMaxSizeError}
            name="documents"
            formatNameId="documentsFormats"
            fileNameId="justAttachments"
            accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .csv"
            maxSize={maxSize}
            multiple={false}
            disabled={submitting}
          />
        </div>
        {maxSizeError.error && (
          <FormattedMessage
            id="maxSizeIsExceeded"
            values={{ size: maxSizeError.size, maxSize: +(maxSize / 1024 / 1024).toFixed(1) }}>
            {txt => <p className="formError">{txt}</p>}
          </FormattedMessage>
        )}
      </div>
      <div className="ContactNoteCreateForm__button">
        <BaseButton
          textId="justSubmit"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          maxContent
        />
      </div>
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

ContactNoteCreateForm.propTypes = {
  reloadFeed: PropTypes.bool,
  file: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

ContactNoteCreateForm.defaultProps = {
  reloadFeed: false,
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      file: state.crm.users.notesDocuments,
    }),
    null
  ),
  reduxForm({
    form: 'contact-note-create-form',
  })
)(ContactNoteCreateForm);
export { ContactNoteCreateForm };
