import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksPlanList {
    width: 32%;

    &__title {
      margin-bottom: 10px;
      font-size: rem(15px);
      position: relative;
      padding-left: 20px;

      &::before {
        content: '';
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .TasksPlanList {
      .TasksPlanList__title {
        &::before {
          background: ${colors.gray.hex};
        }
      }

      :global(.TaskIcon) {
        :global(svg.noFill path) {
          fill: none;
        }
      }

      &__overdue {
        .TasksPlanList__title {
          &::before {
            background: ${colors.error.hex};
          }
        }

        :global(.TaskIcon) {
          :global(path) {
            fill: ${colors.error.hex};
          }

          :global(svg.noFill path) {
            stroke: ${colors.error.hex};
          }
        }

        :global(.TasksPlanItem:hover) {
          background: ${rgba([colors.error.rgb, 0.15])} !important;
        }
      }

      &__today {
        .TasksPlanList__title {
          &::before {
            background: ${colors.success.hex};
          }
        }

        :global(.TaskIcon) {
          :global(path) {
            fill: ${colors.success.hex};
          }

          :global(svg.noFill path) {
            stroke: ${colors.success.hex};
          }
        }

        :global(.TasksPlanItem:hover) {
          background: ${rgba([colors.gradientEnd.rgb, 0.2])} !important;
        }
      }

      &__tomorrow {
        .TasksPlanList__title {
          &::before {
            background: ${colors.primary.hex};
          }
        }

        :global(.TaskIcon) {
          :global(path) {
            fill: ${colors.primary.hex};
          }

          :global(svg.noFill path) {
            stroke: ${colors.primary.hex};
          }
        }

        :global(.TasksPlanItem:hover) {
          background: ${rgba([colors.primary.rgb, 0.2])} !important;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
