import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ObjectField {
    h2 {
      margin-bottom: 15px;
    }
    h3 {
      margin-bottom: 5px;
    }
  }

  .ObjectField_items {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    padding: 25px 30px 1px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    @include respond-to(sm) {
      padding: 25px 15px 1px;
      align-items: flex-start;
    }
    :global(.FormSchemaComposer__inner) {
      margin: 0;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      width: 100%;
      @include respond-to(sm) {
        width: 100%;
      }
    }
    :global(.ObjectField) {
      width: 100%;
      h3 {
        font-weight: 300;
        margin-bottom: 15px;
      }
      :global(.ObjectField_items) {
        box-shadow: none;
        margin-bottom: 0;
        padding: 0;
      }
    }
    :global(.FieldArray) {
      width: 100%;
      :global(.FieldArray__item) {
        margin-bottom: 45px;
        &:last-child {
          margin: 0 0 25px;
        }
        :global(.FieldArray__item) {
          border: none;
          margin: 0;
        }
      }
    }
  }

  .ObjectField__hidden {
    display: none;
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .ObjectField_items {
      :global(.FieldArray) {
        :global(.FieldArray__item) {
          border-radius: ${border.radius};
          border: 2px dashed ${colors.lightGray.hex};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
