import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { closeModal, openModal } from 'interface/user/_redux/actions';

import BonusesTable from 'common/components/tables/BonusesTable';
import { BasicModal, DefaultButton, NoItemsPanel, PageContentPreloader, SearchPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { Checkbox } from '../../../invest/_components';
import { getBonusesInfoList } from '../../../bonuses/_redux';

import {
  getUserAccounts,
  getUserBonus,
  getUserAvailableDeposits,
  cancelUserBonus,
  editUserCanActivateBonuses,
} from '../../_redux';

import UserBonusCreateForm from '../UserBonusCreateForm';

import { staticStyles } from './style';

const UserBonuses = ({
  profile,
  accounts,
  bonuses,
  bonusesAreLoaded,
  userAvailableDeposits,
  userCanActivateBonuses,
  getUserBonus,
  getUserAccounts,
  editUserCanActivateBonuses,
  bonusPrograms,
  getBonusesInfoList,
  getUserAvailableDeposits,
  cancelUserBonus,
  modals,
  openModal,
  closeModal,
  location,
  defaultBonusType,
  history,
  match,
  intl,
}) => {
  const query = {
    page: 1,
    page_size: 10,
    ...queryString.parse(location.search),
  };

  /* eslint-disable */
  useEffect(() => {
    getUserBonus({ id: match.params.id, search: query });
    getUserAccounts({ userId: match.params.id, is_archived: false, noDetails: true });
  }, [match.params.id]);
  /* eslint-enable */

  const addBonus = () => {
    getBonusesInfoList({ search: null });
    getUserAvailableDeposits({ userId: match.params.id });
    openModal('createBonus');
  };

  const filters = [
    {
      title: 'justBonusType',
      accessor: 'bonusType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'menuAllBonuses' }) },
        {
          value: 'subscriptions',
          label: intl.formatMessage({ id: 'justPeriodicalBonuses' }),
        },
      ],
    },
  ];

  return (
    <div className="UserBonuses">
      <PageContentPreloader ready={bonusesAreLoaded} type="smallLines" firstLaunchOnly>
        <div className="UserBonuses__head">
          <SearchPanel
            location={location}
            history={history}
            getList={params => getUserBonus({ id: match.params.id, ...params })}
            filters={filters}
            params={{ search: query }}
            withoutSearch
          />
          {(profile.perms.addBonuses || profile.perms.managerAddBonuses) && (
            <DefaultButton onClick={addBonus} textId="createBonus" plus />
          )}
        </div>
        {(profile.perms.editUser || profile.perms.managerEditUser) && userCanActivateBonuses !== null && (
          <div className="UserBonuses__dissalowBonusesCheckBox">
            <Checkbox
              checked={userCanActivateBonuses}
              onClick={
                () =>
                  editUserCanActivateBonuses({
                    userId: match.params.id,
                    canActivateBonuses: !userCanActivateBonuses,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
            <FM id="boDissalowBonuses" />
          </div>
        )}

        {bonuses.count ? (
          <BonusesTable
            userId={match.params.id}
            data={bonuses}
            loading={!bonusesAreLoaded}
            getList={params => getUserBonus({ id: match.params.id, ...params })}
            location={location}
            history={history}
            query={query}
            params={{ id: match.params.id, search: query }}
            isPeriodical={query.bonusType === 'subscriptions'}
            onRowClick={() => {}}
            rejectBonus={cancelUserBonus}
            profile={profile}
            isBroker
          />
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={
              Object.keys(query).filter(obj => obj !== 'tab' && obj !== 'page_size').length
                ? 'noBonusesMatchingTheParameters'
                : 'noBonusesYet'
            }
          />
        )}
      </PageContentPreloader>
      <BasicModal isOpen={modals.createBonus} captionId="justAddBonus" onRequestClose={() => closeModal('createBonus')}>
        <UserBonusCreateForm
          userId={match.params.id}
          accounts={accounts}
          userAvailableDeposits={userAvailableDeposits}
          onSubmitSuccess={() => {
            closeModal('createBonus');
            getUserBonus({ id: match.params.id, search: query });
          }}
          bonusPrograms={bonusPrograms}
          initialValues={{ defaultBonusType }}
        />
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserBonuses.propTypes = {
  profile: PropTypes.object.isRequired,
  getUserBonus: PropTypes.func.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  getBonusesInfoList: PropTypes.func.isRequired,
  getUserAvailableDeposits: PropTypes.func.isRequired,
  editUserCanActivateBonuses: PropTypes.func.isRequired,
  userCanActivateBonuses: PropTypes.bool,
  accounts: PropTypes.array,
  bonuses: PropTypes.object.isRequired,
  userAvailableDeposits: PropTypes.array.isRequired,
  bonusPrograms: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  bonusesAreLoaded: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  cancelUserBonus: PropTypes.func.isRequired,
  defaultBonusType: PropTypes.string.isRequired,
};

UserBonuses.defaultProps = {
  accounts: [],
  userCanActivateBonuses: null,
};

const ConnectedUserBonuses = connect(
  state => ({
    profile: state.user.profile,
    bonuses: state.backoffice.users.bonuses,
    bonusesAreLoaded: state.backoffice.users.bonusesAreLoaded,
    userAvailableDeposits: state.backoffice.users.userAvailableDeposits,
    bonusPrograms: state.backoffice.bonuses.bonusesInfoList,
    accounts: state.backoffice.users.accounts.filter(account => !account.isDemo),
    userCanActivateBonuses: state.backoffice.users.user.disallowBonuses,

    modals: state.user.modals,
    defaultBonusType: state.interfaceConfig.defaultBonusType,
  }),
  {
    getUserBonus: (id, search) => getUserBonus.request(id, search),
    getUserAccounts: data => getUserAccounts.request(data),
    getBonusesInfoList: data => getBonusesInfoList.request(data),
    getUserAvailableDeposits: data => getUserAvailableDeposits.request(data),
    editUserCanActivateBonuses: data => editUserCanActivateBonuses.request(data),
    cancelUserBonus: data => cancelUserBonus.request(data),
    openModal: name => openModal(name),
    closeModal: name => closeModal(name),
  }
)(UserBonuses);

export default withRouter(injectIntl(ConnectedUserBonuses));
export { UserBonuses };
