import css from 'styled-jsx/css';

const staticStyles = css.global`
  .EditTagsForm {
    &__inner {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 12px;
      margin-bottom: 12px;
      max-width: 400px;
    }
  }
`;

export { staticStyles };
