import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RemoveTradeAccountPartnerCode {
    &__buttons-inner {
      width: 100%;
      margin-top: rem(35px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 3px;
    }
    &__button-inner {
      padding: rem(0 15px);
    }
  }
`;

export { staticStyles };
