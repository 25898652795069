import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPartnerAccounts } from 'client/_redux';
import { BannerFilterForm, Banner } from '../../_components';

import { getPartnershipMaterials, getCampaigns } from '../../_redux';
import { staticStyles } from './style';

const PartnershipBannersPage = ({
  getPartnershipMaterials,
  getPartnerAccounts,
  getCampaigns,
  partnerAccounts,
  banners,
  location,
}) => {
  const query = queryString.parse(location.search);

  /* eslint-disable */
  useEffect(() => {
    getPartnershipMaterials({ promoType: 'banners', query })
  }, [getPartnershipMaterials, location.search])
  /* eslint-enable */

  useEffect(() => {
    getPartnerAccounts();
  }, [getPartnerAccounts]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return (
    <div className="PartnershipBannersPage">
      <BannerFilterForm partnerAccounts={partnerAccounts} />
      <div className="PartnershipBannersPage__banners">
        {banners.length
          ? banners.map(banner => <Banner key={banner.id} data={banner} partnerAccounts={partnerAccounts} />)
          : null}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

PartnershipBannersPage.propTypes = {
  getPartnershipMaterials: PropTypes.func.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  getPartnerAccounts: PropTypes.func.isRequired,
  partnerAccounts: PropTypes.array.isRequired,
  banners: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      campaignsAreLoaded: state.client.partnership.campaignsAreLoaded,
      banners: state.client.partnership.partnershipMaterials,
      partnerAccounts: state.client.common.partnerAccounts,
    }),
    {
      getPartnershipMaterials: data => getPartnershipMaterials.request(data),
      getCampaigns: () => getCampaigns.request(),
      getPartnerAccounts: () => getPartnerAccounts.request(),
    }
  )
)(PartnershipBannersPage);
export { PartnershipBannersPage };
