import css from 'styled-jsx/css';
import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksInfo__inner {
    padding: 15px 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    cursor: pointer;
    z-index: 15;
    &:hover {
      .TasksInfo__menu {
        display: block;
      }
    }
    @include respond-to(md) {
      padding: 10px 15px;
    }
    @include respond-to(sm) {
      border: none !important;
    }

    :global(.TaskIcon) {
      width: 30px;
      height: 30px;
    }
  }

  .TasksInfo__menu {
    position: absolute;
    z-index: 4;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    min-width: 180px;
    width: 250px;
    display: none;
    @media only screen and (max-width: 1070px) {
      min-width: 160px;
    }
    @include respond-to(sm) {
      left: -110%;
    }
  }

  .TaskInfo__noTasks {
    padding: 15px;
  }

  .TasksInfo__link {
    padding: 15px;

    span:hover {
      text-decoration: underline;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .TasksInfo__inner {
      border-left: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      border-left-width: ${isRTL ? '0' : '0.5px'};
    }

    .TasksInfo__menu {
      background-color: ${colors.white.hex};
    }

    .TaskInfo__noTasks {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
