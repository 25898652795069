import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'common/styling/theme';

import { DefaultButton, CheckboxField } from 'common/components';
import staticStyles from './style';

const LpaAgreementsForm = ({
  setLpaAgreements,
  getLpaAgreements,
  lpaAgreements,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  error,
}) => {
  useEffect(() => {
    getLpaAgreements();
  }, [getLpaAgreements]);

  const openAgreementLink = (e, link) => {
    e.stopPropagation();
    window.open(link);
  };

  const submit = handleSubmit(values => setLpaAgreements({ ...values }, dispatch));

  return (
    <form className="LpaAgreementsForm" onSubmit={submit}>
      <ul className="LpaAgreementsForm__items">
        {lpaAgreements.map(({ title, link, id }) => (
          <li className="LpaAgreementsForm__item" key={id}>
            <div className="LpaAgreementsForm__item-left">
              <Field component={CheckboxField} name={title} textId={title} disabled={submitting} />
            </div>
            <button
              type="button"
              className="LpaAgreementsForm__button"
              onClick={e => {
                openAgreementLink(e, link);
              }}
              key={id}>
              <FormattedMessage id="justReadTheAgreement">{txt => txt}</FormattedMessage>
            </button>
          </li>
        ))}
      </ul>
      <div className="LpaAgreementsForm__actions">
        <DefaultButton textId="justAccept" type="submit" loading={submitting} filled />
        <DefaultButton textId="justDecline" type="submit" loading={submitting} onClick={toggleModal} />
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

LpaAgreementsForm.propTypes = {
  getLpaAgreements: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

LpaAgreementsForm.defaultProps = {
  error: '',
};

export default compose(
  connect(state => ({
    lpaAgreements: state.client.accounts.lpaAgreements,
  })),
  reduxForm({
    form: 'lpa-agreements-form',
    onSubmitSuccess: (result, dispatch, { setIsAgreementSigned, toggleModal }) => {
      setIsAgreementSigned(true);
      toggleModal();
    },
  }),
  withTheme()
)(LpaAgreementsForm);
