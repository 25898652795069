import css from 'styled-jsx/css';

const staticStyles = css.global`
  .month-picker {
    position: relative;
    > .rmp-container {
      position: relative;
      box-sizing: border-box;
      z-index: 100;
      top: 1px;
      left: -10000px;
      -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
      -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
      .rmp-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 9;
        top: 0;
        left: -10000px;
        opacity: 1;
        will-change: opacity;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
          opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      }
      .rmp-cell {
        display: table-cell;
        vertical-align: middle;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
      }
      .rmp-popup {
        position: absolute;
        -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
        -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
        margin: 0 auto;
        z-index: 10;
        font-size: 1.2rem;
        opacity: 0;
        border-radius: 3px;
        padding: 0.8rem;
        box-sizing: content-box;
        &:after {
          content: ' ';
          clear: both;
          display: table;
        }
        .rmp-pad {
          position: relative;
          > div {
            label {
              display: block;
              font-size: 1.2rem;
              text-align: center;
              font-weight: 500;
              line-height: 2.8rem;
              b {
                font-weight: normal;
                margin-right: 0.5em;
              }
            }
            i {
              font-style: normal;
              text-align: center;
              width: 2.8rem;
              height: 2.8rem;
              line-height: 2.8rem;
              position: absolute;
              top: 0;
            }
            i.prev {
              left: 0;
            }
            i.next {
              right: 0;
            }
          }
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            &:after {
              content: ' ';
              clear: both;
              display: table;
            }
          }
          li {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: block;
            float: left;
            text-align: center;
            line-height: 2.8rem;
            font-size: 1.15rem;
            border-radius: 2px;
            text-overflow: ellipsis;
            white-space: nowrap;
            *white-space: nowrap;
            overflow: hidden;
            -webkit-font-smoothing: antialiased;
            -webkit-text-stroke-width: 0.2px;
            -moz-osx-font-smoothing: grayscale;
            -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
            -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
            transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
          }
        }
      }
      .rmp-popup.show {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      .rmp-popup.light {
        .rmp-pad {
          .rmp-btn {
            border-radius: 3px;
            cursor: pointer;
          }
          li.active {
            cursor: default;
            &:hover {
              cursor: default;
            }
          }
          .disable {
            cursor: default;
            &:hover {
              cursor: default;
            }
          }
        }
      }
      .rmp-popup.dark {
        .rmp-pad {
          .rmp-btn {
            cursor: pointer;
          }
          .rmp-btn.select {
          }
          li.active {
            cursor: default;
            &:hover {
              cursor: default;
            }
          }
          .disable {
            cursor: default;
            &:hover {
              cursor: default;
            }
          }
        }
      }
    }
    > .rmp-container.rmp-table {
      display: table;
    }
    > .rmp-container.show {
      left: 0;
      -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      .rmp-overlay {
        left: 0;
      }
    }
  }
  .list-area {
    margin-top: 5rem;
  }
  @media screen and (max-width: 767px) {
    .month-picker {
      > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
        -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
        .rmp-cell {
          vertical-align: bottom;
        }
        .rmp-popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          -webkit-transform: translate3d(0, 14.4rem, 0);
          -moz-transform: translate3d(0, 14.4rem, 0);
          -ms-transform: translate3d(0, 14.4rem, 0);
          -o-transform: translate3d(0, 14.4rem, 0);
          transform: translate3d(0, 14.4rem, 0);
          .rmp-pad {
            li {
              width: 25%;
            }
          }
        }
        .rmp-popup.light {
          border-top: 1px solid #ccc;
          box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
        }
        .rmp-popup.light.range {
          .rmp-pad {
            border-top: 1px solid rgba(204, 204, 204, 0.5);
            &:first-of-type {
              border-top: 0;
            }
          }
        }
        .rmp-popup.dark.range {
          .rmp-pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41);
            &:first-of-type {
              border-top: 0;
            }
          }
        }
      }
    }
    .list-area {
      > ul {
        border-bottom: 1px solid #e8e8e8;
        > li {
          position: relative;
          border-top: 1px solid #e8e8e8;
          padding: 0 1.6rem;
          &:after {
            content: ' ';
            clear: both;
            display: table;
          }
          > label {
            float: left;
            display: block;
            line-height: 5rem;
            font-size: 1.12rem;
            b {
              font-size: 1.1em;
            }
            span {
              position: absolute;
              display: block;
              font-size: 0.8rem;
              left: 1.6rem;
              top: 1.5em;
            }
          }
          .edit {
            margin-left: 9rem;
            line-height: 5rem;
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .month-picker {
      > .rmp-container {
        .rmp-popup {
          -webkit-transform: translate3d(0, -64px, 0);
          -moz-transform: translate3d(0, -64px, 0);
          -ms-transform: translate3d(0, -64px, 0);
          -o-transform: translate3d(0, -64px, 0);
          transform: translate3d(0, -64px, 0);
          top: 0;
          width: 15rem;
          .rmp-pad {
            box-sizing: border-box;
            float: left;
            width: 15rem;
            &:nth-of-type(2) {
              float: right;
            }
            li {
              width: 33.3333333333%;
            }
          }
        }
      }
    }
  }
`;
function getDynamicStyles({ colors }) {
  return css.global`
    .month-picker {
      > .rmp-container {
        -webkit-tap-highlight-color: ${colors.white.hex};
        .rmp-popup.light {
          color: ${colors.gray.hex};
          background-color: ${colors.white.hex};
          .rmp-pad {
            > div {
              border-bottom: 1.2px solid ${colors.lightGray.hex};
              label {
                color: ${colors.primary.hex};
              }
            }
            .rmp-btn.select {
              background-color: ${colors.gradientStart.hex};
            }
            li.active {
              background-color: ${colors.primary.hex};
              color: ${colors.white.hex};
              &:hover {
                background-color: ${colors.primary.hex};
                color: ${colors.white.hex};
              }
            }
            .disable {
              background-color: transparent;
              color: ${colors.lightGray.hex};
              &:hover {
                background-color: transparent;
                color: ${colors.lightGray.hex};
              }
            }
          }
        }
        .rmp-popup.dark {
          color: ${colors.white.hex};
          background-color: ${colors.lightGray.hex};
          .rmp-pad {
            .rmp-btn.select {
              background-color: ${colors.black.hex};
            }
            li.active {
              background-color: ${colors.lightGray.hex};
              color: ${colors.mobileMenu.hex};
              &:hover {
                background-color: ${colors.lightGray.hex};
                color: ${colors.mobileMenu.hex};
              }
            }
            .disable {
              background-color: transparent;
              color: ${colors.gray.hex};
              &:hover {
                background-color: transparent;
                color: ${colors.gray.hex};
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      .month-picker {
        > .rmp-container {
          .rmp-overlay {
            background-color: rgba(0, 0, 0, 0.4);
          }
          .rmp-popup.light {
            border-top: 1px solid #ccc;
          }
          .rmp-popup.light.range {
            .rmp-pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5);
            }
          }
        }
      }
      .list-area {
        > ul {
          border-bottom: 1px solid #e8e8e8;
          > li {
            background-color: ${colors.white.hex};
            > label {
              color: ${colors.black.hex};
              span {
                color: ${colors.lightGray.hex};
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      .month-picker {
        > .rmp-container {
          .rmp-popup.light {
            border: 1.3px solid ${colors.gradientStart.hex};
            .rmp-pad {
              .rmp-btn {
                &:hover {
                  color: ${colors.white.hex};
                  background-color: ${colors.gradientStart.hex};
                }
              }
            }
          }
          .rmp-popup.light.range {
            .rmp-pad {
              background-color: ${colors.white.hex};
            }
          }
          .rmp-popup.dark.range {
            .rmp-pad {
              background-color: ${colors.gray.hex};
            }
          }
          .rmp-popup.dark {
            .rmp-pad {
              .rmp-btn {
                &:hover {
                  background-color: ${colors.gradientStart.hex};
                }
              }
            }
          }
        }
      }
      .list-area {
        > ul {
          > li {
            > label {
              color: ${colors.gray.hex};
              span {
                color: ${colors.lightGray.hex};
              }
            }
          }
        }
      }
    }
  `;
}

export { staticStyles, getDynamicStyles };
