import React from 'react';

const TradingPlatforms = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
      <path d="M481.091 27.937H30.909C13.866 27.937 0 41.803 0 58.846v310.819c0 17.043 13.866 30.909 30.909 30.909h154.26v22.49c0 20.617-16.774 37.391-37.391 37.391h-33.997c-6.518 0-11.803 5.284-11.803 11.803 0 6.519 5.285 11.803 11.803 11.803h284.436c6.518 0 11.803-5.284 11.803-11.803 0-6.519-5.285-11.803-11.803-11.803h-33.998c-20.617 0-37.391-16.774-37.391-37.391v-22.489h154.26c17.043 0 30.91-13.866 30.91-30.909V58.846c.002-17.043-13.864-30.909-30.907-30.909zM195.92 460.457c8.046-10.336 12.857-23.308 12.857-37.391v-22.49h94.447v22.49c0 14.083 4.811 27.056 12.857 37.391H195.92zm292.474-90.791c0 4.027-3.276 7.304-7.304 7.304H30.909c-4.027 0-7.304-3.276-7.304-7.304v-62.033h464.789v62.033zm0-85.64H23.606V58.846c0-4.027 3.276-7.304 7.304-7.304h450.18c4.027 0 7.305 3.276 7.305 7.304v225.18z" />
      <circle cx="256.003" cy="342.305" r="12.738" />
      <path d="M276.238 109.254c-4.61-4.609-12.081-4.609-16.693 0l-83.414 83.414c-4.609 4.609-4.609 12.083 0 16.693a11.767 11.767 0 0 0 8.347 3.457c3.022 0 6.041-1.152 8.346-3.457l83.414-83.414c4.609-4.609 4.609-12.083 0-16.693zM325.678 157.593c-4.608-4.609-12.079-4.609-16.692-.001l-33.23 33.228c-4.609 4.61-4.609 12.084 0 16.693a11.765 11.765 0 0 0 8.346 3.457c3.02 0 6.041-1.152 8.346-3.457l33.23-33.228c4.609-4.609 4.609-12.083 0-16.692z" />
    </svg>
  </div>
);

export default TradingPlatforms;
