import React from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { staticStyles } from './style';

const refundFeeCalc = (ind, dataLength, cost) => {
  if (ind === 0) {
    return cost;
  }

  if (ind === dataLength - 1) {
    return 'Refund';
  }

  return 'Free';
};
const SelectChallengeBlockTable = ({ data }) => (
  <div className="SelectChallengeBlockTable">
    <div className="SelectChallengeBlockTableColumn SelectChallengeBlockTableColumn__InfoColumn">
      <div className="Empty__InfoCell" />
      <div>
        <FM id="justMaxTradingDays" />
      </div>
      <div>
        <FM id="justMinimalTradingDays" />
      </div>
      <div>
        <FM id="justMaximumDailyLoss" />
      </div>
      <div>
        <FM id="justMaximumLoss" />
      </div>
      <div>
        <FM id="justProfitTarget" />
      </div>
      <div>
        <FM id="justRefundableFee" />
      </div>
      <div>
        <FM id="justRewardPercent" />
      </div>
    </div>
    {data.levels.map((level, ind) => (
      <div className="SelectChallengeBlockTableColumn" key={`${level.name + ind}`}>
        <div className="SelectChallengeBlockTableColumn__name">
          <div className="Round">{ind + 1}</div>
          <div className="Name">{level.name}</div>
        </div>
        <div>{level.maximum_trading_days} days</div>
        <div>{level.minimum_trading_days} days</div>
        <div>{`${level.maximum_daily_loss.amount} ${level.maximum_daily_loss.currency}`}</div>
        <div>{`${level.maximum_total_loss.amount} ${level.maximum_total_loss.currency}`}</div>
        <div>{`${level.target.amount} ${level.target.currency}`}</div>
        <div style={{ color: data.levels.length - 1 === ind ? 'green' : 'initial' }}>
          {refundFeeCalc(ind, data.levels?.length, data.cost)}
        </div>
        <div>{ind === data.levels.length - 1 ? `${data.rewardPercent}%` : '---'}</div>
      </div>
    ))}
    <style jsx>{staticStyles}</style>
  </div>
);

export default SelectChallengeBlockTable;
