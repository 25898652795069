import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const SupportIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="4.248%" y1="86.836%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="-UI" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-634 -744)" fill="url(#linearGradient-4)" fillRule="nonzero">
          <g id="support1" transform="translate(634 744)">
            <path
              d="M15.3582622,2.6372093 C17.0593192,4.33826638 17.999277,6.594926 17.999277,9 C17.999277,11.405074 17.0631247,13.6617336 15.3620677,15.3627907 C13.6267611,17.0980973 11.32063,18 8.99166596,18 C7.54177167,18 6.08046089,17.6498943 4.7447315,16.9306554 C3.546,17.7945032 2.37390698,17.9809725 1.57855814,17.9809725 C1.28172939,17.9809725 1.03817759,17.954334 0.866930233,17.9276956 C0.501602537,17.8706131 0.219995772,17.5852008 0.162913319,17.2198732 C0.105830867,16.8545455 0.292300211,16.5006342 0.623378436,16.3369979 C1.31217336,15.9983087 1.77644397,15.3209302 2.06566173,14.7386892 C-0.883598309,11.1881607 -0.655268499,5.92515856 2.63268076,2.6372093 C4.33373784,0.93615222 6.59039746,0 8.99547146,0 C11.4005455,0 13.6572051,0.93615222 15.3582622,2.6372093 Z M13.0534836,9.63171247 C13.5766242,9.63171247 14.0007134,9.20762322 14.0007134,8.68448261 C14.0007134,8.16134201 13.5766242,7.73725275 13.0534836,7.73725275 C12.530343,7.73725275 12.1062537,8.16134201 12.1062537,8.68448261 C12.1062537,9.20762322 12.530343,9.63171247 13.0534836,9.63171247 Z M9.05348356,9.63171247 C9.57662417,9.63171247 10.0007134,9.20762322 10.0007134,8.68448261 C10.0007134,8.16134201 9.57662417,7.73725275 9.05348356,7.73725275 C8.53034295,7.73725275 8.1062537,8.16134201 8.1062537,8.68448261 C8.1062537,9.20762322 8.53034295,9.63171247 9.05348356,9.63171247 Z M5.05348356,9.63171247 C5.57662417,9.63171247 6.00071342,9.20762322 6.00071342,8.68448261 C6.00071342,8.16134201 5.57662417,7.73725275 5.05348356,7.73725275 C4.53034295,7.73725275 4.1062537,8.16134201 4.1062537,8.68448261 C4.1062537,9.20762322 4.53034295,9.63171247 5.05348356,9.63171247 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

SupportIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

SupportIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedSupportIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(SupportIconActive);

export default ThemedSupportIconActive;
