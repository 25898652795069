import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .TransferDetailsPage__buttons-inner {
    button {
      padding: 7px 10px;
      margin: 0 30px 20px 0;
      @include respond-to(sm) {
        margin: 0 15px 20px 0;
      }
    }
  }
  .TransferDetails {
    background: rgb(255, 255, 255);
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 60px 10px 30px);
    margin-bottom: rem(20px);
    position: relative;
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 5px;
        span {
          font-size: rem(13px);
          display: block;
          margin-bottom: 5px;
        }
        &:first-child {
          font-size: rem(18px);
        }
      }
    }

    .TransferDetails__caption {
      font-size: 18px;
      margin-bottom: rem(25px);
    }
  }
`;

export { staticStyles };
