import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ConfirmResetPasswordForm {
    .DefaultButton {
      margin-top: 10px;
    }
  }
`;

export { staticStyles };
