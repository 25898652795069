import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { email, required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { ReduxTextField } from '../../form-fields';
import { NewDefaultButton } from '../..';

import { wsRequestResetPassword } from '../../../_redux';

import { staticStyles } from './style';

const ResetPasswordForm = ({ history, pristine, submitting, invalid, error, handleSubmit, dispatch }) => {
  const submit = handleSubmit(formValues => wsRequestResetPassword(formValues, dispatch));

  return (
    <form className="ResetPasswordForm" onSubmit={submit}>
      <Field
        component={ReduxTextField}
        type="email"
        name="email"
        textId="authEmail"
        validate={[required(), email()]}
        disabled={submitting}
      />
      <div className="ResetPasswordForm__nav-btns">
        <NewDefaultButton
          textId="authResetPassword"
          type="submit"
          iconVariant={submitting && 'loading'}
          disabled={invalid || pristine || submitting}
          wide
          isUpperCase
        />

        <NewDefaultButton
          textId="justBackToLogin"
          onClick={() => history.push('/welcome-setup/login')}
          type="button"
          variant="ghost"
          iconVariant="previous"
          disabled={submitting}
          wide
          isUpperCase
        />
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

ResetPasswordForm.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  reduxForm({
    form: 'wsResetPasswordForm',
    onSubmitSuccess: (result, dispatch, { history }) => {
      history.push('/welcome-setup/login?message=authPasswdResetMailSent');
    },
  })
)(ResetPasswordForm);
export { ResetPasswordForm };
