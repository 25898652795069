import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ReduxTextField, ReduxSelectField, DefaultButton } from 'common/components';
import { getServerList, createUserAccount } from '../../_redux';

import { staticStyles } from './style';

const UserAddAccountForm = ({
  userId,
  serverList,
  serverListIsLoaded,
  getServerList,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  pristine,
  error,
}) => {
  const submit = handleSubmit(values => createUserAccount({ userId, ...values }, dispatch));

  const serverOptions = serverList.map(server => {
    const serverData = Object.entries(server)[0];

    return { value: serverData[0], label: serverData[1] };
  });

  useEffect(() => {
    getServerList();
  }, [getServerList]);

  return (
    <form className="UserCreateAccountForm" onSubmit={submit}>
      <Field
        component={ReduxSelectField}
        name="server"
        textId="justServer"
        options={serverOptions}
        validate={[required()]}
        disabled={!serverListIsLoaded || submitting}
      />

      <Field component={ReduxTextField} type="number" name="login" textId="justAccountNumber" validate={[required()]} />

      <div className="UserCreateAccountForm__actions">
        <DefaultButton
          textId="justAdd"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={() => toggleModal()} />
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserAddAccountForm.propTypes = {
  userId: PropTypes.string.isRequired,
  serverList: PropTypes.array.isRequired,
  serverListIsLoaded: PropTypes.bool.isRequired,
  getServerList: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

UserAddAccountForm.defaultProps = {
  error: '',
};

export default compose(
  connect(
    state => ({
      serverList: state.backoffice.users.serverList,
      serverListIsLoaded: state.backoffice.users.serverListIsLoaded,
    }),
    {
      getServerList: () => getServerList.request(),
    }
  ),
  reduxForm({
    form: 'user-create-account-form',
    onSubmitSuccess: (result, dispatch, { userId, getUserAccounts, toggleModal, showModal }) => {
      getUserAccounts({ userId });
      toggleModal();
      showModal({
        caption: { id: 'boUserAddAccountSuccessMessageTitle' },
        content: { id: 'boUserAddAccountSuccessMessageText' },
        actionButton: null,
        cancelButton: {
          textId: 'justOk',
        },
      });
    },
  })
)(UserAddAccountForm);
export { UserAddAccountForm };
