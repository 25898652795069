import React from 'react';
import { compose } from 'redux';
import { staticStyles } from './style';
import TagItem from '../TagItem/TagItem';

const Tags = ({ tags, onTagSelect, id }) => (
  <div className="Tags">
    {tags.map(item => (
      <TagItem onTagSelect={onTagSelect} tag={item.name} key={`TagItem__${item.id}`} tagId={item.id} id={id} />
    ))}
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

Tags.propTypes = {};

Tags.defaultProps = {};

export default compose()(Tags);
