import { FAILURE, REQUEST, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const REGISTER_FILE_FIELD = 'REGISTER_FILE_FIELD';
export const registerFileField = fieldName => action(REGISTER_FILE_FIELD, { fieldName });

export const UNREGISTER_FILE_FIELD = 'UNREGISTER_FILE_FIELD';
export const unregisterFileField = fieldName => action(UNREGISTER_FILE_FIELD, { fieldName });

export const UPLOAD_FILE = createRequestTypes('UPLOAD_FILE');
export const uploadFile = {
  request: (fieldName, file) => action(UPLOAD_FILE[REQUEST], { fieldName, file }),
  success: (fieldName, url) => action(UPLOAD_FILE[SUCCESS], { fieldName, url }),
  failure: (fieldName, error) => action(UPLOAD_FILE[FAILURE], { fieldName, error }),
};
