import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TaskFullNameCell__FullName {
    display: flex;
    align-items: center;
  }

  .TaskFullNameCell__FullName:hover {
    cursor: pointer;
    color: #0aa1e2;
  }

  .TaskFullNameCell__Icon {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
  }

  .TaskFullNameCell__Icon--Gray {
    border: 1px solid #f3f3f3 !important;
  }
  .TaskFullNameCell__Icon--Blue {
    border: 1px solid #0aa1e2;
  }
  .TaskFullNameCell__Icon--Red {
    border: 1px solid #ee3235;
  }
  .TaskFullNameCell {
    width: 100%;
    display: flex;
    position: relative;
    gap: 12px;
    padding-left: 4px;
    z-index: 1;

    &__FullName {
      text-overflow: ellipsis;
      word-break: break-all;
    }

    &__ManagerIcon {
      padding-bottom: 4px;
      width: 23px;
      height: 23px;
    }

    &__favorite {
    }
  }
`;

export { staticStyles };
