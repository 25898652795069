import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PageLayout, PageContentPreloader, DefaultButton, BasicModal, ActionsButton } from 'common/components';
import { PaymentNotes } from 'backoffice/_components';
import { EditIcon } from 'common';
import EditTransferManagerForm from '../_components/EditTransferManagerForm/editTransferManagerForm';
import { OperationsHistory } from '../../_components';
import { TransferDetails, PersonInfo } from '../_components';
import { getTransferDetails, actionTransfer } from '../_redux';
import RejectTransferForm from '../_components/RejectTransferForm';
import { staticStyles } from './style';

const TransferDetailsPage = ({
  profile,
  getTransferDetails,
  actionTransfer,
  transferDetails,
  transferActionsLoading,
  transferDetailsIsLoaded,
  match,
  features,
}) => {
  const [loading, setLoading] = useState({
    execute: false,
    reject: false,
  });
  const hasPaymentOperationManagers = features.includes('paymentOperationManager');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenManagerForm, setIsOpenManagerForm] = useState(false);
  useEffect(() => {
    getTransferDetails({ transferId: match.params.id });
  }, [getTransferDetails, match.params.id]);
  const handleTransferAction = type => {
    setLoading({ ...loading, [type]: true });
    actionTransfer(match.params.id, type);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleRejectTransfer = () => {
    toggleModal();
  };
  const toggleManagerModal = () => {
    setIsOpenManagerForm(!isOpenManagerForm);
  };
  const onEditManagerModal = () => {
    toggleManagerModal();
  };
  return (
    <PageLayout
      captionSlug="boInternalTransfer"
      showReload
      onReloadClick={() => getTransferDetails({ transferId: match.params.id })}
      reloading={!transferDetailsIsLoaded}>
      <PageContentPreloader ready={transferDetailsIsLoaded} type="boPaymentPage">
        {profile.perms.internalTransfersExecute && (
          <div className="TransferDetailsPage__buttons-inner">
            {transferDetails.status === 'WAITING_FOR_APPROVAL' && (
              <DefaultButton
                onClick={() => handleTransferAction('execute')}
                textId="justConfirm"
                butColor="success"
                disabled={transferActionsLoading}
                loading={loading.execute && transferActionsLoading}
              />
            )}
            {transferDetails.status === 'WAITING_FOR_APPROVAL' && (
              <DefaultButton
                onClick={() => {
                  handleRejectTransfer();
                }}
                textId="justReject"
                butColor="error"
                disabled={transferActionsLoading}
                loading={loading.reject && transferActionsLoading}
              />
            )}
          </div>
        )}
        {transferDetails.senderAccount && (
          <TransferDetails
            captionSlug="boSenderDetails"
            accountInfo={transferDetails.senderAccount}
            amount={transferDetails.sendAmount}
            commissionInfo={transferDetails.commissionInfo}
            status={transferDetails.status}
          />
        )}
        {transferDetails.recipientAccount && (
          <TransferDetails
            captionSlug="boRecipientDetails"
            accountInfo={transferDetails.recipientAccount}
            amount={transferDetails.receiveAmount}
            commissionInfo={transferDetails.commissionInfo}
            status={transferDetails.status}
          />
        )}
        {match.params.id && (
          <PaymentNotes
            id={match.params.id}
            fullAccess={profile.perms.fullAccessInternalTransfers}
            paymentType="internal_transfers"
          />
        )}
        {hasPaymentOperationManagers ? (
          <div className="TransferDetails">
            <FormattedMessage id="justManager">
              {txt => <span className="TransferDetails__caption">{txt}</span>}
            </FormattedMessage>
            <ul>
              <li>
                <div>
                  {transferDetails.manager
                    ? `${transferDetails.manager.firstName} ${transferDetails.manager.lastName}`
                    : '---'}
                  {profile.perms.internalTransfersExecute && (
                    <ActionsButton edit buttonId="actionsEditValue" onClickFunc={onEditManagerModal}>
                      <EditIcon />
                    </ActionsButton>
                  )}
                </div>
              </li>
            </ul>
          </div>
        ) : null}
        {transferDetails.history && <OperationsHistory items={transferDetails.history} />}
        {transferDetails.senderUser && (
          <PersonInfo captionSlug="boSenderPersonalInfo" user={transferDetails.senderUser} />
        )}
        {transferDetails.recipientUser && (
          <PersonInfo captionSlug="boRecipientPersonalInfo" user={transferDetails.recipientUser} />
        )}
        <BasicModal isOpen={isOpen} captionId="createRejectNotification" onRequestClose={toggleModal}>
          <RejectTransferForm toggleModal={toggleModal} id={match.params.id} />
        </BasicModal>
        <BasicModal captionId="editManager" isOpen={isOpenManagerForm} onRequestClose={() => toggleManagerModal()}>
          <EditTransferManagerForm onSubmitSuccess={() => toggleManagerModal()} id={match.params.id} />
        </BasicModal>
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </PageLayout>
  );
};
TransferDetailsPage.propTypes = {
  profile: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  transferDetails: PropTypes.object.isRequired,
  transferDetailsIsLoaded: PropTypes.bool.isRequired,
  transferActionsLoading: PropTypes.bool.isRequired,
  getTransferDetails: PropTypes.func.isRequired,
  actionTransfer: PropTypes.func.isRequired,
};
export default compose(
  withRouter,
  connect(
    state => ({
      profile: state.user.profile,
      transferDetails: state.backoffice.transfers.transferDetails,
      transferDetailsIsLoaded: state.backoffice.transfers.transferDetailsIsLoaded,
      transferActionsLoading: state.backoffice.transfers.transferActionsLoading,
      features: state.interfaceConfig.features,
    }),
    {
      getTransferDetails: data => getTransferDetails.request(data),
      actionTransfer: (id, actionType) => actionTransfer.request(id, actionType),
    }
  )
)(TransferDetailsPage);
export { TransferDetailsPage };
