import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .PromoMaterialsForm {
    display: flex;
    flex-direction: column;

    .PromoMaterialsForm__content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: rem(30px);

      .PromoMaterialsForm__caption {
        font-size: rem(24px);
        font-weight: 300;
        margin-bottom: rem(30px);
      }

      .PromoMaterialsForm__settings-block {
        display: flex;
        flex-direction: column;
        flex: 3;
        margin-right: rem(30px);
        padding: rem(40px 40px 10px);
      }

      .PromoMaterialsForm__logo-block {
        display: flex;
        flex-direction: column;
        flex: 2;
        padding: rem(40px 40px 10px);

        :global(.FileField) {
          margin-top: rem(30px);
        }
      }

      @include respond-to(sm) {
        flex-direction: column;

        .PromoMaterialsForm__settings-block {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }

    .PromoMaterialsForm__buttons {
      display: flex;

      :global(.DefaultButton) {
        &:first-child {
          margin-right: rem(20px);
        }
      }
    }
  }
`;
const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PromoMaterialsForm__settings-block,
    .PromoMaterialsForm__logo-block {
      background-color: ${colors.white.hex};
    }

    .PromoMaterialsForm__description {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
