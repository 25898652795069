import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextLink span {
    color: #005479;
    position: relative;
    transition: color .2s ease-in-out;
    font-size: 15px;

    &:hover {
      color: #0AA1E2;

      &::before {
        background-color: transparent;
      }
    }

    &:active {
      color: #0AA1E2;

      &::before {
        background-color: #B6E3F7;
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      left: 0;
      bottom: -4px;
      background-color: #B3CCD7;
      transition: background-color .2s ease-in-out;
    }
  }

`;

export { staticStyles };
