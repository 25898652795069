import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { getFixedNumber, today as currentDay } from 'common/dashboards/helpers';
import { SplineChartIcon, BarChartIcon } from './icons';
import BigChart from '../BigChart';

import { staticStyles, getDynamicStyles } from './style';

const ACCOUNT_TYPES = [
  {
    id: 1,
    title: 'boRegistered',
    name: 'users',
    color: '#43B6E8',
  },
  {
    id: 2,
    title: 'boDemoAccount',
    name: 'demo_users',
    color: '#F5A623',
  },
  {
    id: 3,
    title: 'boLiveAccount',
    name: 'real_users',
    color: '#6AC347',
  },
  {
    id: 4,
    title: 'boFTD',
    name: 'deposited_users',
    color: '#39DBD3',
  },
  {
    id: 5,
    title: 'boDeposit',
    name: 'deposited_amount',
    color: '#F27186',
  },
  {
    id: 6,
    title: 'boWithdrawal',
    name: 'withdrawn_amount',
    color: '#6A5ACD',
  },
];

const PERIODS = [
  {
    id: 1,
    name: 'justWeek',
  },
  {
    id: 2,
    name: 'justMonth',
  },
];

const BigCharts = ({ intl, theme, lastFunnels, dailyFunnels, displayToday, startOfWeek, endOfWeek, currentMonth }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [accountTypeName, setAccountTypeName] = useState('users');
  const [periodType, setPeriodType] = useState(1);
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const weekData = [];
  const monthData = [];
  const weekForecastData = [];
  const monthForecastData = [];

  let usersAcc = 0;
  let usersForecastAcc = null;
  let usersForecastAvg = 0;
  let isRealData = true;
  let isWeeklyData = false;
  let counter = 0;

  Object.entries(dailyFunnels).forEach(([key, value]) => {
    isWeeklyData = moment(key).isBetween(startOfWeek, endOfWeek, null, '[]');
    if (activeTab === 1) {
      counter += 1;
      if (isRealData) {
        usersAcc = value[accountTypeName] + usersAcc;
      } else {
        usersAcc = null;
        usersForecastAcc += usersForecastAvg;
      }

      if (key === currentDay) {
        isRealData = false;
        usersForecastAcc = usersAcc;
        usersForecastAvg = Math.ceil(usersAcc / counter);
      }

      monthData.push(usersAcc);
      monthForecastData.push(usersForecastAcc);
      if (isWeeklyData) {
        weekData.push(usersAcc);
        weekForecastData.push(usersForecastAcc);
      }
    } else {
      monthData.push(value[accountTypeName]);
      if (isWeeklyData) {
        weekData.push(value[accountTypeName]);
      }
    }
    if (key === endOfWeek) {
      isWeeklyData = false;
    }
  });

  const days = Object.keys(dailyFunnels);
  let total = 0;
  if (lastFunnels[currentMonth]) {
    total = lastFunnels[currentMonth][accountTypeName];
  }

  let series;
  if (activeTab === 1) {
    if (periodType === 1) {
      series = [
        {
          name: intl.formatMessage({ id: 'justRealData' }),
          data: weekData,
        },
        {
          name: intl.formatMessage({ id: 'justForecast' }),
          data: weekForecastData,
        },
      ];
    } else {
      series = [
        {
          name: intl.formatMessage({ id: 'justRealData' }),
          data: monthData,
        },
        {
          name: intl.formatMessage({ id: 'justForecast' }),
          data: monthForecastData,
        },
      ];
    }
  } else if (periodType === 1) {
    series = [
      {
        name: intl.formatMessage({ id: 'justWeek' }),
        data: weekData,
      },
    ];
  } else {
    series = [
      {
        name: intl.formatMessage({ id: 'justMonth' }),
        data: monthData,
      },
    ];
  }
  const chartColor = ACCOUNT_TYPES.find(item => item.name === accountTypeName).color;

  return (
    <div className="BigCharts">
      <div className="BigCharts__tabs">
        <button
          type="button"
          className={classNames('BigCharts__tabsItem', {
            BigCharts__tabsItem_selected: activeTab === 1,
          })}
          onClick={() => setActiveTab(1)}>
          <span className="BigCharts__tabsItem-index">1</span>
          <FormattedMessage id="boDashboardChart1">
            {txt => <span className="BigCharts__tabsItem-text">{txt}</span>}
          </FormattedMessage>
          <SplineChartIcon />
        </button>
        <button
          type="button"
          className={classNames('BigCharts__tabsItem', {
            BigCharts__tabsItem_selected: activeTab === 2,
          })}
          onClick={() => setActiveTab(2)}>
          <span className="BigCharts__tabsItem-index">2</span>
          <FormattedMessage id="boDashboardChart2">
            {txt => <span className="BigCharts__tabsItem-text">{txt}</span>}
          </FormattedMessage>
          <BarChartIcon />
        </button>
      </div>

      <div className="BigCharts__content">
        <div className="BigCharts__content-top-line">
          <div className="BigCharts__account-types-list">
            {ACCOUNT_TYPES.map(item => (
              <button
                type="button"
                key={item.id}
                className={classNames('BigCharts__accountTypesItem', {
                  BigCharts__item_blue: item.name === 'users',
                  BigCharts__item_blue_selected: item.name === 'users' && item.name === accountTypeName,
                  BigCharts__item_orange: item.name === 'demo_users',
                  BigCharts__item_orange_selected: item.name === 'demo_users' && item.name === accountTypeName,
                  BigCharts__item_green: item.name === 'real_users',
                  BigCharts__item_green_selected: item.name === 'real_users' && item.name === accountTypeName,
                  BigCharts__item_gray: item.name === 'deposited_users',
                  BigCharts__item_gray_selected: item.name === 'deposited_users' && item.name === accountTypeName,
                  BigCharts__item_pink: item.name === 'deposited_amount',
                  BigCharts__item_pink_selected: item.name === 'deposited_amount' && item.name === accountTypeName,
                  BigCharts__item_violet: item.name === 'withdrawn_amount',
                  BigCharts__item_violet_selected: item.name === 'withdrawn_amount' && item.name === accountTypeName,
                })}
                onClick={() => setAccountTypeName(item.name)}>
                <FormattedMessage id={item.title}>{txt => txt}</FormattedMessage>
              </button>
            ))}
          </div>
          <div className="BigCharts__periods">
            {PERIODS.map(item => (
              <button
                type="button"
                key={item.id}
                className={classNames('BigCharts__periodItem', {
                  BigCharts__item_blue: accountTypeName === 'users',
                  BigCharts__item_orange: accountTypeName === 'demo_users',
                  BigCharts__item_green: accountTypeName === 'real_users',
                  BigCharts__item_gray: accountTypeName === 'deposited_users',
                  BigCharts__item_pink: accountTypeName === 'deposited_amount',
                  BigCharts__item_violet: accountTypeName === 'withdrawn_amount',
                })}
                style={{ color: periodType === item.id && chartColor }}
                onClick={() => setPeriodType(item.id)}>
                <FormattedMessage id={item.name}>{txt => txt}</FormattedMessage>
              </button>
            ))}
          </div>
        </div>

        <div className="BigCharts__chart">
          {activeTab === 1 && periodType === 1 && (
            <BigChart chartType="area" chartSeries={series} periodType={periodType} days={days} color={chartColor} />
          )}
          {activeTab === 1 && periodType === 2 && (
            <BigChart chartType="area" chartSeries={series} periodType={periodType} days={days} color={chartColor} />
          )}
          {activeTab === 2 && periodType === 1 && (
            <BigChart chartType="bar" chartSeries={series} periodType={periodType} days={days} color={chartColor} />
          )}
          {activeTab === 2 && periodType === 2 && (
            <BigChart chartType="bar" chartSeries={series} periodType={periodType} days={days} color={chartColor} />
          )}
        </div>

        <div className="BigCharts__statistics">
          <div className="BigCharts__statistical-item">
            <FormattedMessage id="justDate">
              {txt => <span className="statistical-item__title">{txt}</span>}
            </FormattedMessage>
            <span className="statistical-item__info" style={{ color: chartColor }}>
              {displayToday}
            </span>
          </div>
          <div className="BigCharts__statistical-item">
            <FormattedMessage id="justTotal">
              {txt => <span className="statistical-item__title">{txt}</span>}
            </FormattedMessage>
            <span className="statistical-item__info" style={{ color: chartColor }}>
              {getFixedNumber(total, 2)}
            </span>
          </div>
          {activeTab === 1 && (
            <div className="BigCharts__statistical-item">
              <FormattedMessage id="boEndOfWeekForecast">
                {txt => <span className="statistical-item__title">{txt}</span>}
              </FormattedMessage>
              <span className="statistical-item__info" style={{ color: chartColor }}>
                {getFixedNumber(weekForecastData[weekForecastData.length - 1], 2)}
              </span>
            </div>
          )}
        </div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

BigCharts.propTypes = {
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
  lastFunnels: PropTypes.object,
  dailyFunnels: PropTypes.object,
  displayToday: PropTypes.string.isRequired,
  startOfWeek: PropTypes.string.isRequired,
  endOfWeek: PropTypes.string.isRequired,
  currentMonth: PropTypes.string.isRequired,
};

BigCharts.defaultProps = {
  theme: {},
  lastFunnels: {},
  dailyFunnels: {},
};

export default withTheme()(injectIntl(BigCharts));
export { BigCharts };
