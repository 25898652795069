import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .PromoMaterialsTable {
    .ReactTable {
      .rt-thead {
        min-width: 700px !important;
        .rt-tr {
          .rt-th {
            &:first-child {
              flex: 30 0 auto !important;
              width: 40px !important;
              opacity: 0;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5) {
              flex: 40 0 auto !important;
              width: 40px !important;

              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }

            &:nth-child(4) {
              flex: 90 0 auto !important;
              width: 90px !important;

              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(6) {
              flex: 30 0 auto !important;
              width: 30px !important;
              text-align: center;

              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
          }
        }
      }
      .rt-tbody {
        min-width: 700px !important;
        .rt-tr-group {
          margin-bottom: 1px;
          &:last-child {
            margin-bottom: rem(15px);
          }
          .rt-tr {
            .rt-td {
              .PromoMaterialsTable__logo {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }

              p,
              span {
                font-size: rem(15px);
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &:first-child {
                flex: 30 0 auto !important;
                padding-left: rem(10px);
                width: 40px !important;
              }
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(5) {
                flex: 40 0 auto !important;
                width: 40px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }

              &:nth-child(4) {
                flex: 90 0 auto !important;
                width: 90px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }

              &:nth-child(6) {
                flex: 30 0 auto !important;
                width: 30px !important;
                text-align: center;

                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
            }
          }
          &:hover {
            box-shadow: none;
            cursor: default;
          }
        }
      }
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        flex: 50;
        text-align: center;
        padding: 5px 0;

        &:first-child {
          opacity: 0;
          flex: 1;
        }

        &:nth-child(3) {
          flex: 15;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;

      .ResponsiveTable__row-item {
        flex: 50;
        display: flex;
        align-items: center;
        justify-content: center;

        .PromoMaterialsTable__logo {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }

        &:first-child {
          padding-left: rem(20px);
          flex: 1;
        }

        &:nth-child(3) {
          flex: 20;
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 15%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PromoMaterialsTable {
    }
  `;
};

export { staticStyles, getDynamicStyles };
