import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserDocuments {
    padding: 20px 0 20px 25px;
    border-bottom: 2px solid #e9e9e9;

    @include respond-to(sm) {
      padding: 18px 0 18px 13px;
    }

    &:nth-of-type(1) {
      border-top: 2px solid #e9e9e9;
    }

    &__small {
      display: flex;
      align-items: center;

      @include respond-to(sm) {
        flex-wrap: wrap;
        margin: -8px 0 0 0;
      }
    }

    &__small-title {
      font-size: 20px;
      line-height: 23px;
      min-width: 220px;
      width: 30%;
      margin: 0 20px 0 0;

      @include respond-to(sm) {
        min-width: 0;
        width: auto;
        order: 1;
        margin: 0 auto 15px 0;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }

    &__small-images-wrap {
      margin: 0 auto -10px -10px;

      @include respond-to(sm) {
        order: 3;
        width: 100%;
        margin: 0 -10px -10px -10px;
      }
    }

    &__small-images {
      width: 290px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__small-dotted {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @include respond-to(sm) {
        order: 2;
      }

      img {
        transition: transform 0.3s ease;
      }

      &--showMore {
        img {
          transform: rotate(90deg);
        }
      }
    }

    &__caption {
      margin-bottom: rem(25px);
    }

    &__list {}
  }
`;

export { staticStyles };
