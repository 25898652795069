import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { wsToggleMenu } from '../../_redux';

import { staticStyles } from './style';

const BurgerButton = ({ toggleMenu, isMenuOpen }) => (
  <button
    className={classNames('hamburger hamburger--slider', {
      opened: isMenuOpen,
    })}
    type="button"
    onClick={toggleMenu}>
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
    <style jsx>{staticStyles}</style>
  </button>
);

BurgerButton.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default connect(state => ({ isMenuOpen: state.welcomeSetup.isMenuOpen }), { toggleMenu: () => wsToggleMenu() })(
  BurgerButton
);
