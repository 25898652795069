import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { staticStyles } from './style';

const PaymentTag = ({ title, color, isShortWidth }) => {
  const styles = {
    borderColor: color || '',
    backgroundColor: color ? `${color}0D` : '',
  };

  return (
    <div className="PaymentTag" style={styles}>
      <span className={classNames('PaymentTag__title', { 'PaymentTag__title--short': isShortWidth })}>{title}</span>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

PaymentTag.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  isShortWidth: PropTypes.bool,
};

PaymentTag.defaultProps = {
  color: '',
  isShortWidth: false,
};

export default PaymentTag;
