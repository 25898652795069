import React from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import SelectFormField from '../../fields/SelectFormField';
import TextFormField from '../../fields/TextFormField';
import { staticStyles } from './style';

const ScheduleACallFrom = () => (
  <div className="ScheduleACallFrom">
    <Field name="task" component={SelectFormField} labelTextId="crmCreatetask" options={[]} placeholder="" />
    <Field name="platform" component={SelectFormField} labelTextId="crmSelectPlatform" options={[]} placeholder="" />
    <Field name="date" component={SelectFormField} labelTextId="crmSelectDate" options={[]} placeholder="" />
    <Field
      name="agentCode"
      component={TextFormField}
      type="number"
      labelTextId="setPartnerCode"
      placeholderId="justPartnerCode"
    />
    <BaseButton textId="justReassign" maxContent />
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-schedule-a-call-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
  })
)(ScheduleACallFrom);
