import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const SwitchField = ({ input, disabled, descriptionId, meta: { touched, error }, onChange, theme, withText }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div className="SwitchField">
      <div className="SwitchField__row">
        {descriptionId && (
          <FormattedMessage id={descriptionId}>
            {txt => <span className="SwitchField__description">{txt}</span>}
          </FormattedMessage>
        )}
        {withText && (
          <FormattedMessage id="justNo">
            {txt => (
              <span
                className={classNames('SwitchField__text SwitchField__no', {
                  active: !input.value,
                  disabled,
                })}>
                {txt}
              </span>
            )}
          </FormattedMessage>
        )}
        <input
          className="SwitchField__input"
          {...input}
          checked={input.value}
          id={`${input.name}FieldId`}
          type="checkbox"
          disabled={disabled}
          onChange={onChange || input.onChange}
        />

        <label className="SwitchField__label" htmlFor={`${input.name}FieldId`} />

        {withText && (
          <FormattedMessage id="justYes">
            {txt => (
              <span
                className={classNames('SwitchField__text SwitchField__yes', {
                  active: input.value,
                  disabled,
                })}>
                {txt}
              </span>
            )}
          </FormattedMessage>
        )}
      </div>
      <span className="SwitchField__error">{touched && error}</span>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

SwitchField.propTypes = {
  input: PropTypes.object.isRequired,
  descriptionId: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  onChange: PropTypes.func,
  theme: PropTypes.object,
  withText: PropTypes.bool,
};

SwitchField.defaultProps = {
  disabled: false,
  descriptionId: '',
  onChange: null,
  theme: {},
  withText: false,
};

export default withTheme()(SwitchField);
export { SwitchField };
