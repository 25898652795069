import css from 'styled-jsx/css';
import { gradient } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserWithdrawRequest {
    background: #ffffff;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);

    :global(.ReactDataTable) {
      overflow: hidden;

      :global(tr) {
        grid-template-columns: minmax(20px, 1fr) repeat(5, minmax(100px, 3fr)) minmax(150px, 1.5fr);
        padding: 0 12px;
      }

      :global(.ReactDataTable__tbody) {
        :global(.ReactDataTable__tbody-row) {
          box-shadow: none !important;
          cursor: default;
          z-index: 1;

          :global(.ReactDataTable__tbody-cell) {
            :global(.MoneyCell__inner) {
              font-weight: 500;
            }
          }
        }
      }

      :global(.ReactDataTable__tfoot) {
        :global(.ReactDataTable__tfoot-row) {
          :global(.ReactDataTable__tfoot-cell) {
            :global(span) {
              height: 100%;
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: rem(18px) !important;
            }
          }
        }
      }
    }

    &__title {
      padding: rem(25px 30px 25px);
      line-height: 32px;
      font-size: 26px;
      font-weight: 300;

      @include respond-to(sm) {
        padding: rem(20px 15px 15px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserWithdrawRequest {
      :global(.ReactDataTable) {
        :global(.rt-tfoot) {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          }

          :global(.ReactDataTable__tbody-row) {
            :global(ReactDataTable__tbody-cell) {
              flex: 50 !important;
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
