import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { withTheme } from 'common/styling/theme';
import { ActionsButton, DefaultButton } from 'common/components';
import { compose } from 'redux';
import { getUserLoginHistory, getUserOperationHistory } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const UserHistoryPagination = ({
  type,
  firstMonthValue,
  secondMonthValue,
  id,
  itemsCount = 0,
  children,
  getUserLoginHistory,
  getUserOperationHistory,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const pageSizeDelimiters = [5, 10, 20, 25, 50, 100];
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // FIXME:
  const pagesCount = useMemo(() => Math.ceil(itemsCount / pageSize), [itemsCount, pageSize]);

  const handleQueryChange = (queryName, pageParam) => {
    if (queryName === 'page') {
      setCurrentPage(pageParam);
    } else if (queryName === 'page_size') {
      setCurrentPage(1);
      setPageSize(pageParam);
    }
  };
  const getYearMonth = monthValue => moment(`${monthValue?.year}-${monthValue?.month}`).format('YYYY-MM');

  useEffect(() => {
    if (type === 'login') {
      getUserLoginHistory({
        id,
        params: {
          pageSize,
          currentPage,
          firstMonth: getYearMonth(firstMonthValue),
          secondMonth: getYearMonth(secondMonthValue),
        },
      });
    } else {
      getUserOperationHistory({
        id,
        params: {
          pageSize,
          currentPage,
          firstMonth: getYearMonth(firstMonthValue),
          secondMonth: getYearMonth(secondMonthValue),
        },
      });
    }
  }, [firstMonthValue, secondMonthValue, pageSize, currentPage]);

  return (
    <div className="UserHistoryPagination">
      {children}
      <div className="UserHistoryPagination__pagination">
        <div className="UserHistoryPagination__select-wrap">
          <select
            onChange={({ target }) => handleQueryChange('page_size', target.value)}
            value={pageSize}
            className="UserHistoryPagination__select">
            {pageSizeDelimiters.map(delimiter => (
              <option value={delimiter} key={delimiter}>
                {delimiter}{' '}
              </option>
            ))}
          </select>
        </div>
        <div className="UserHistoryPagination__pagination-nav">
          {!isTabletOrMobile ? (
            <>
              <DefaultButton
                textId="justPrevious"
                onClick={() => handleQueryChange('page', Number(currentPage) - 1)}
                disabled={currentPage <= 1}
                previous
              />
              <DefaultButton
                textId="justNext"
                onClick={() => handleQueryChange('page', Number(currentPage) + 1)}
                disabled={currentPage >= pagesCount}
                next
              />
            </>
          ) : (
            <>
              <ActionsButton
                onClick={() => handleQueryChange('page', Number(currentPage) - 1)}
                disabled={currentPage <= 1}
              />
              <ActionsButton
                onClick={() => handleQueryChange('page', Number(currentPage) + 1)}
                disabled={currentPage >= pagesCount}
              />
            </>
          )}
        </div>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
  // )
};

UserHistoryPagination.propTypes = {
  type: PropTypes.string.isRequired,
  firstMonthValue: PropTypes.object.isRequired,
  secondMonthValue: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
};

UserHistoryPagination.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(() => ({}), {
    getUserOperationHistory: (id, params) => getUserOperationHistory.request(id, params),
    getUserLoginHistory: (id, params) => getUserLoginHistory.request(id, params),
  })
)(UserHistoryPagination);
export { UserHistoryPagination };
