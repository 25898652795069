import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserBonusWithdrawForm {
    width: 400px;
    @include respond-to(sm) {
      max-width: 100%;
    }

    .UserBonusWithdrawForm__line {
      display: flex;
      flex-wrap: wrap;

      span {
        margin-right: rem(20px);
      }
    }

    &__btns {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      :global(.DefaultButton) {
        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }
`;

export { staticStyles };
