import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { AsyncSelectField, DefaultButton } from 'common/components';
import { editTradeAccountPartnerCode } from '../../_redux';
import { getAvailablePartnerCodes } from '../../api';

import { staticStyles } from './style';

const EditTradeAccountPartnerCode = ({
  user,
  tradeAccountId,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const submit = handleSubmit(values =>
    editTradeAccountPartnerCode({ ...values, userId: user.id, tradeAccountId }, dispatch)
  );

  const loadOptions = search =>
    getAvailablePartnerCodes(user.id, search).then(({ data }) =>
      data.map(partner => ({ value: partner.partnerCode, label: partner.partnerCode }))
    );

  return (
    <form className="EditTradeAccountPartnerCode" onSubmit={submit}>
      <FormattedMessage id="editPartnerCodeDescription">
        {txt => <p className="EditTradeAccountPartnerCode__description">{txt}</p>}
      </FormattedMessage>
      <div className="EditTradeAccountPartnerCode__inputs-inner">
        <Field
          textId="partnerCode"
          component={AsyncSelectField}
          name="partnerCode"
          loadOptions={loadOptions}
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={100}
        />
      </div>
      <DefaultButton
        textId="justChange"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditTradeAccountPartnerCode.propTypes = {
  user: PropTypes.object.isRequired,
  tradeAccountId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditTradeAccountPartnerCode.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'edit-trade-account-partner-code',
})(EditTradeAccountPartnerCode);
export { EditTradeAccountPartnerCode };
