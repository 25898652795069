import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const editAuthBanner = createFormAction('EDIT_AUTH_BANNER');

export const GET_BO_STATUSES_LIST = createRequestTypes('GET_BO_STATUSES_LIST');
export const getStatusesList = {
  request: sort => action(GET_BO_STATUSES_LIST[REQUEST], { sort }),
  success: data => action(GET_BO_STATUSES_LIST[SUCCESS], { data }),
  failure: error => action(GET_BO_STATUSES_LIST[FAILURE], { error }),
};

export const GET_BO_STATUS_INFO = createRequestTypes('GET_BO_STATUS_INFO');
export const getStatusInfo = {
  request: id => action(GET_BO_STATUS_INFO[REQUEST], { id }),
  success: data => action(GET_BO_STATUS_INFO[SUCCESS], { data }),
  failure: error => action(GET_BO_STATUS_INFO[FAILURE], { error }),
};

export const updateStatusDescription = createFormAction('UPDATE_STATUS_DESCRIPTION');

export const createStatusInfo = createFormAction('CREATE_STATUS_INFO');
export const updateStatusInfo = createFormAction('UPDATE_STATUS_INFO');

export const DELETE_STATUS_INFO = createRequestTypes('DELETE_STATUS_INFO');
export const deleteStatusInfo = {
  request: id => action(DELETE_STATUS_INFO[REQUEST], { id }),
  success: () => action(DELETE_STATUS_INFO[SUCCESS]),
  failure: error => action(DELETE_STATUS_INFO[FAILURE], { error }),
};

export const GET_BO_STATUS_DESCRIPTION = createRequestTypes('GET_BO_STATUS_DESCRIPTION');
export const getStatusDescription = {
  request: () => action(GET_BO_STATUS_DESCRIPTION[REQUEST]),
  success: data => action(GET_BO_STATUS_DESCRIPTION[SUCCESS], { data }),
  failure: error => action(GET_BO_STATUS_DESCRIPTION[FAILURE], { error }),
};
