import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .EditClientTypeForm {
    max-width: 450px;
    min-width: 450px;
    @include respond-to(sm) {
      max-width: 100%;
      min-width: 100%;
    }

    :global(.DefaultButton) {
      margin-top: 10px;
    }
  }
`;

export { staticStyles };
