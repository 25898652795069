import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserSumSubDocuments {
    position: relative;
    padding: 25px 30px 25px 30px;
    margin-top: 20px;
    display: block;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 15px 0;
    }

    &__documents {
      display: flex;
      gap: 16px;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__title {
      margin: 0 0 30px 0;

      &--hide {
        margin: 0 0 0 0 !important;
        display: inline;
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__documents {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      &-wrapper {
        &--hide {
          display: none;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 16px;
        cursor: pointer;

        &-title {
          font-weight: 500;
        }

        &-icon {
          width: 25px;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .UserSumSubDocuments {
      box-shadow: 0 1px 6px 1px ${rgba([colors.black.rgb, 0.06])};
      background-color: ${colors.white.hex};

      &__documents {
        &-item {
          border: 1px solid ${rgba([colors.black.rgb, 0.06])};
          box-shadow: 0 1px 6px 1px ${rgba([colors.black.rgb, 0.06])};
          border-radius: ${border.radius};

          &:hover {
            background-color: ${rgba([colors.black.rgb, 0.06])} !important;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
