import css from 'styled-jsx/css';

import { rgba, gradient, isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SwitchField {
    input[type='checkbox'] {
      width: auto;
      display: none;
      opacity: 1 !important;

      &:checked {
        & + label {
          transition: 350ms;
          background-position: 0 0;
          &::before {
            left: 31px;
          }
        }
      }

      &:disabled {
        & + label {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    label {
      position: relative;
      display: block;
      width: 60px;
      height: 25px;
      cursor: pointer;
      border-radius: 80px;
      transition: 350ms;

      &::before {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        top: -3px;
        left: 0;
        border-radius: 50%;
        transition: 250ms ease-in-out;
      }
    }

    .SwitchField__row {
      display: flex;
      align-items: center;
      flex-direction: ${isRTL ? 'row-reverse' : 'row'};
    }

    .SwitchField__no {
      margin-right: 10px;
    }

    .SwitchField__yes {
      margin-left: 10px;
    }

    .SwitchField__error {
      width: 100%;
      display: inline-block;
    }

    .SwitchField__description {
      margin-right: rem(20px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .SwitchField {
      input[type='checkbox'] {
        &:checked {
          & + label {
            background: ${gradient(25, [colors.gradientStart.rgb, 1], 100, [colors.gradientEnd.rgb, 1], 45)};
          }
        }
      }

      label {
        background: ${rgba([colors.lightGray.rgb, 0.4])};

        &::before {
          background: ${colors.primary.hex};
          border: 7px solid ${colors.white.hex};
          box-shadow: 0px 3px 6px ${rgba([colors.black.rgb, 0.15])};
        }
      }

      .SwitchField__text {
        color: ${colors.lightGray.hex};

        &.active {
          color: ${colors.black.hex};
        }

        &.disabled {
          color: ${colors.lightGray.hex};
        }
      }

      .SwitchField__error {
        color: ${colors.error.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
