import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const TextAreaField = ({
  input,
  textId,
  text,
  placeholder = '',
  rows,
  cols,
  textValues = {},
  disabled = false,
  meta: { touched, active, error },
  handleKeydown,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [stateValue, setStateValue] = useState(input.value);

  useEffect(() => {
    setStateValue(input.value);
  }, [input.value]);

  const handleChange = ({ target }) => {
    input.onChange(target.value);
    setStateValue(target.value);
  };

  return (
    <>
      <div
        className={classNames('TextAreaField', {
          TextAreaField__error: touched && error,
        })}>
        <div>
          <textarea
            className={classNames('TextAreaField__inp', { active }, { haveText: input.value })}
            {...input}
            id={input.name}
            placeholder={placeholder}
            rows={rows}
            cols={cols}
            disabled={disabled}
            value={stateValue}
            onChange={handleChange}
            onKeyDown={handleKeydown}
          />
          <label
            className={classNames('TextAreaField__label', { active }, { haveText: input.value })}
            htmlFor={input.name}>
            {textId ? <FM id={textId} values={textValues} /> : <span>{text}</span>}
          </label>
          <span className="TextAreaField__error">{touched && error}</span>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </>
  );
};

TextAreaField.propTypes = {
  input: PropTypes.object.isRequired,
  textId: PropTypes.string,
  rows: PropTypes.string.isRequired,
  cols: PropTypes.string.isRequired,
  handleKeydown: PropTypes.func,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  textValues: PropTypes.object,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  theme: PropTypes.object,
};

TextAreaField.defaultProps = {
  textId: '',
  text: '',
  placeholder: '',
  disabled: false,
  textValues: {},
  handleKeydown: null,
  theme: {},
};

const ThemedTextArea = withTheme()(TextAreaField);

export default ThemedTextArea;
export { TextAreaField };
