import { SubmissionError } from 'redux-form';
import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getPlanSaga() {
  try {
    const { status, data } = yield call(api.getPlan);
    if (status < 300) {
      yield put(ActionTypes.getPlan.success(data));
    } else {
      yield put(ActionTypes.getPlan.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPlan.failure());
    throw e;
  }
}

export function* getPlanWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PLAN[REQUEST]);
    yield call(getPlanSaga);
  }
}

export function* getTasksListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getTasks, search);
    if (status < 300) {
      yield put(ActionTypes.getTasks.success(data));
    } else {
      yield put(ActionTypes.getTasks.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTasks.failure());
    throw e;
  }
}

export function* getTasksListSagaWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_TASKS[REQUEST]);
    yield call(getTasksListSaga, data);
  }
}

export function* getTaskDetailSaga({ id }) {
  try {
    const { status, data } = yield call(api.getTask, id);
    if (status < 300) {
      yield put(ActionTypes.getTask.success(data));
    } else {
      yield put(ActionTypes.getTask.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTask.failure());
    throw e;
  }
}

export function* getTaskDetailWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_TASK[REQUEST]);
    yield call(getTaskDetailSaga, data);
  }
}

export function* createTaskSaga(payload) {
  try {
    const { data, status } = yield call(api.createTask, payload);
    if (status < 300) {
      yield put(ActionTypes.createTask.success(data));
      yield call(getPlanSaga);
      yield call(getTasksListSaga, { search: { page_size: 10 } });
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.createTask.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createTask.failure());
    throw e;
  }
}

export function* createTaskWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createTask.REQUEST);
    yield call(createTaskSaga, payload);
  }
}

export function* updateTaskSaga({ id, page, ...values }) {
  try {
    const { data, status } = yield call(api.updateTask, id, values);
    if (status < 300) {
      yield put(ActionTypes.updateTask.success(data));
      if (page === 'detail') {
        yield call(getTaskDetailSaga, { id });
      } else {
        yield call(getTasksListSaga, { search: { page_size: 10 } });
      }
      yield call(getPlanSaga);
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.updateTask.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.updateTask.failure());
    throw e;
  }
}

export function* updateTaskWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateTask.REQUEST);
    yield call(updateTaskSaga, payload);
  }
}

export function* finishTaskSaga({ id, page, ...values }) {
  try {
    const { data, status } = yield call(api.finishTask, id, values);
    if (status < 300) {
      yield put(ActionTypes.finishTask.success(data));
      if (page === 'detail') {
        yield call(getTaskDetailSaga, { id });
      } else {
        yield call(getTasksListSaga, { search: { page_size: 10 } });
      }
      yield call(getPlanSaga);
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.finishTask.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.finishTask.failure());
    throw e;
  }
}

export function* finishTaskWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.finishTask.REQUEST);
    yield call(finishTaskSaga, payload);
  }
}

export function* getManagersSaga() {
  try {
    const { status, data } = yield call(api.getManagers);
    if (status < 300) {
      yield put(ActionTypes.getManagers.success(data));
    } else {
      yield put(ActionTypes.getManagers.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getManagers.failure());
    throw e;
  }
}

export function* getManagersWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_MANAGERS[REQUEST]);
    yield call(getManagersSaga);
  }
}

export default [
  fork(getPlanWatcherSaga),
  fork(getTasksListSagaWatcherSaga),
  fork(getTaskDetailWatcherSaga),
  fork(createTaskWatcherSaga),
  fork(updateTaskWatcherSaga),
  fork(finishTaskWatcherSaga),
  fork(getManagersWatcherSaga),
];
