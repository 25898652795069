import css from 'styled-jsx/css'

import { gradient } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
    
  .PartnerAccountInfoPanel__top {
    margin-bottom: rem(35px);
    padding: rem(30px 30px 20px 20px);
    position: relative;
    @include respond-to(sm) {
      overflow: hidden;
    }
    :global(.LoginCell__inner) {
      :global(.LoginCell__name) {
        max-width: 90%;
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        padding: rem(0 10px);
        margin-bottom: rem(15px);
        min-width: rem(115px);
        .PartnerAccountInfoPanel__second-line {
          display: flex;
          height: rem(45px);
          align-items: center;
          font-size: rem(16px);
        }
        :global(span) {
          &:first-child {
            margin-bottom: 5px;
            font-size: 13px;
          }
        }
      }
      .AccountInfoPanel__button {
        flex-direction: row;
        height: rem(70px);
        align-items: center;
        .DefaultButton, span {
          margin-bottom: 0 !important;
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .PartnerAccountInfoPanel__top {
      background-color: ${colors.white.hex};
      &:after { 
        background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }
      
      ul {
        li {
          :global(span) {
            &:first-child {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
