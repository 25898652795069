import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required, confirmation, length } from 'redux-form-validators';

import { DefaultButton, ReduxTextField, CheckboxField } from 'common/components';

import { confirmPasswordReset } from '../../_redux/actions';

import staticStyles from './style';

const ConfirmResetPasswordForm = ({ match, handleSubmit, pristine, submitting, invalid, error, dispatch }) => {
  const code = match.params.code;
  const submit = handleSubmit(values => confirmPasswordReset({ ...values, code }, dispatch));

  return (
    <form className="ConfirmResetPasswordForm" onSubmit={submit}>
      <Field
        name="password"
        type="password"
        component={ReduxTextField}
        textId="authPassword"
        validate={[required(), length({ min: 6 })]}
        disabled={submitting}
      />
      <Field
        name="passwordRepeat"
        type="password"
        component={ReduxTextField}
        textId="authPasswordRepeat"
        validate={[required(), confirmation({ field: 'password' })]}
        disabled={submitting}
      />
      <Field component={CheckboxField} name="finishAllSession" textId="finishAllSession" disabled={submitting} />
      <div className="ConfirmResetPasswordForm__button-inner">
        <DefaultButton
          textId="authChangePassword"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

ConfirmResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ConfirmResetPasswordForm.defaultProps = {
  error: '',
};

export default withRouter(
  reduxForm({
    form: 'request-password-reset-form',
    onSubmitSuccess: (result, dispatch, { history }) => {
      history.push('/auth/login?message=passwdResetSuccess');
    },
    initialValues: {
      finishAllSession: true,
    },
  })(ConfirmResetPasswordForm)
);
export { ConfirmResetPasswordForm };
