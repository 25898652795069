import React from 'react';
import PropTypes from 'prop-types';

import { Collapsible } from 'common/components';

import { staticStyles } from './style';

const Question = ({ question }) => {
  const { title, body } = question;

  return (
    <div className="Question">
      <Collapsible title={title}>
        <div className="Question__content" dangerouslySetInnerHTML={{ __html: body }} />
      </Collapsible>

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.object.isRequired,
};

export default Question;
