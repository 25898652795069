import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .DashboardsPage__funnel,
  .DashboardsPage__month {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .DashboardsPage__month-title {
    margin-bottom: 25px;
  }

  .DashboardsPage__funnel-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: rem(20px);
  }

  .DashboardsPage__month-topCharts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: rem(25px);
  }
  .TopMonthPicker {
    position: relative;
    .month-picker {
      position: absolute;
      right: 0;
    }
    .rmp-popup {
      right: -9rem;
      @include respond-to(sm) {
        right: 0;
      }
    }
  }
  .DashboardsPage__totals {
    padding-bottom: rem(35px);
    border-bottom: 1px solid #dedede;
    margin-bottom: rem(35px);
    display: flex;
    gap: 24px;
  }
  .DashboardsPage__total-title {
    font-size: rem(13px);
    line-height: rem(18px);
    margin-bottom: rem(8px);
  }
  .DashboardsPage__total-number {
    font-size: rem(30px);

    @include respond-to(sm) {
      font-size: rem(20px);
    }
  }
  .DashboardsPage__total-card {
    min-width: 223px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .DashboardsPage__total-number {
      color: ${colors.primary.hex};
    }
    .DashboardsPage__total-title {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
