import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_LAST_FUNNELS = createRequestTypes('GET_LAST_FUNNELS');
export const getLastFunnels = {
  request: data => action(GET_LAST_FUNNELS[REQUEST], data),
  success: data => action(GET_LAST_FUNNELS[SUCCESS], { data }),
  failure: error => action(GET_LAST_FUNNELS[FAILURE], { error }),
};

export const GET_MONTHS_FUNNELS = createRequestTypes('GET_MONTHS_FUNNELS');
export const getMonthsFunnels = {
  request: data => action(GET_MONTHS_FUNNELS[REQUEST], data),
  success: data => action(GET_MONTHS_FUNNELS[SUCCESS], { data }),
  failure: error => action(GET_MONTHS_FUNNELS[FAILURE], { error }),
};

export const GET_TARGET = createRequestTypes('GET_TARGET');
export const getTarget = {
  request: data => action(GET_TARGET[REQUEST], data),
  success: data => action(GET_TARGET[SUCCESS], { data }),
  failure: error => action(GET_TARGET[FAILURE], { error }),
};

export const putTarget = createFormAction('PUT_TARGET');

export const GET_DAILY_FUNNELS = createRequestTypes('GET_DAILY_FUNNELS');
export const getDailyFunnels = {
  request: data => action(GET_DAILY_FUNNELS[REQUEST], data),
  success: data => action(GET_DAILY_FUNNELS[SUCCESS], { data }),
  failure: error => action(GET_DAILY_FUNNELS[FAILURE], { error }),
};

export const GET_USERS_HISTORY = createRequestTypes('GET_USERS_HISTORY');
export const getUsersHistory = {
  request: data => action(GET_USERS_HISTORY[REQUEST], data),
  success: data => action(GET_USERS_HISTORY[SUCCESS], { data }),
  failure: error => action(GET_USERS_HISTORY[FAILURE], { error }),
};

export const GET_TOTALS = createRequestTypes('GET_TOTALS');
export const getTotals = {
  request: data => action(GET_TOTALS[REQUEST], data),
  success: data => action(GET_TOTALS[SUCCESS], { data }),
  failure: error => action(GET_TOTALS[FAILURE], { error }),
};

export const RESET_DASHBOARD_LOADED_FLAGS = 'RESET_DASHBOARD_LOADED_FLAGS';
export const resetDahboardLoadedFlags = () => action(RESET_DASHBOARD_LOADED_FLAGS);
