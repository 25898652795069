import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  depositSystems: [],
  loadingDepositSystems: true,

  withdrawalSystems: [],
  loadingWithdrawalSystems: true,

  paymentsInfo: null,
  loadingPayments: false,

  paymentsList: {},
  loadingPaymentsList: false,

  inOutList: {},
  inOutListIsLoaded: false,

  userEditHistory: [],
  userEditHistoryIsLoaded: false,

  paymentForm: null,
  paymentFormIsLoaded: null,

  paymentProofUploaded: false,
  paymentProofUploadError: null,

  availableLpas: [],
  availableLpasAreLoaded: false,

  lpaAgentDeals: {},
  lpaAgentDealsIsLoaded: false,

  lpaClientDeals: {},
  lpaClientDealsIsLoaded: false,

  lpaDeal: {},
  lpaDealIsLoaded: false,

  isConfirmationMessageResent: null,
  confirmationMessageIsSending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DEPOSIT_SYSTEM_LIST[REQUEST]:
      return { ...state, paymentForm: null, paymentFormIsLoaded: null, loadingDepositSystems: true };
    case ActionTypes.GET_DEPOSIT_SYSTEM_LIST[SUCCESS]:
      return {
        ...state,
        loadingDepositSystems: false,
        depositSystems: action.systems,
      };
    case ActionTypes.GET_DEPOSIT_SYSTEM_LIST[FAILURE]:
      return { ...state, loadingDepositSystems: false };

    case ActionTypes.GET_WITHDRAWAL_SYSTEM_LIST[REQUEST]:
      return { ...state, paymentForm: null, paymentFormIsLoaded: null, loadingWithdrawalSystems: true };
    case ActionTypes.GET_WITHDRAWAL_SYSTEM_LIST[SUCCESS]:
      return {
        ...state,
        loadingWithdrawalSystems: false,
        withdrawalSystems: action.systems,
      };
    case ActionTypes.GET_WITHDRAWAL_SYSTEM_LIST[FAILURE]:
      return { ...state, loadingWithdrawalSystems: false };

    case ActionTypes.GET_PAYMENTS[REQUEST]:
      return { ...state, loadingPayments: true };
    case ActionTypes.GET_PAYMENTS[SUCCESS]:
      return {
        ...state,
        loadingPayments: false,
        paymentsInfo: { type: action.operationType, ...action.info },
      };
    case ActionTypes.GET_PAYMENTS[FAILURE]:
      return { ...state, loadingPayments: false };

    case ActionTypes.GET_PAYMENTS_LIST[REQUEST]:
      return { ...state, loadingPaymentsList: false };
    case ActionTypes.GET_PAYMENTS_LIST[SUCCESS]:
      return {
        ...state,
        loadingPaymentsList: true,
        paymentsList: action.data,
      };
    case ActionTypes.GET_PAYMENTS_LIST[FAILURE]:
      return { ...state, loadingPaymentsList: false };

    case ActionTypes.GET_IN_OUT_LIST[REQUEST]:
      return { ...state, inOutListIsLoaded: false };
    case ActionTypes.GET_IN_OUT_LIST[SUCCESS]:
      return {
        ...state,
        inOutListIsLoaded: true,
        inOutList: action.data,
      };
    case ActionTypes.GET_IN_OUT_LIST[FAILURE]:
      return { ...state, inOutListIsLoaded: false };

    case ActionTypes.USER_GET_EDIT_HISTORY[REQUEST]:
      return { ...state, userEditHistoryIsLoaded: false };
    case ActionTypes.USER_GET_EDIT_HISTORY[SUCCESS]:
      return { ...state, userEditHistory: action.data, userEditHistoryIsLoaded: true };
    case ActionTypes.USER_GET_EDIT_HISTORY[FAILURE]:
      return { ...state, error: action.error, userEditHistoryIsLoaded: false };

    case ActionTypes.makeDeposit[REQUEST]:
      return {
        ...state,
        paymentFormIsLoaded: null,
      };
    case ActionTypes.makeDeposit[SUCCESS]:
      return {
        ...state,
        paymentForm: action.payload,
        paymentFormIsLoaded: true,
      };
    case ActionTypes.makeDeposit[FAILURE]:
      return {
        ...state,
        error: action.error,
        paymentFormIsLoaded: false,
      };

    case ActionTypes.makeWithdraw[REQUEST]:
      return { ...state, paymentFormIsLoaded: null };
    case ActionTypes.makeWithdraw[SUCCESS]:
      return { ...state, paymentForm: action.payload, paymentFormIsLoaded: true };
    case ActionTypes.makeWithdraw[FAILURE]:
      return { ...state, error: action.error, paymentFormIsLoaded: false };

    case ActionTypes.RESEND_PAYMENT_CONFIRMATION_MESSAGE[REQUEST]:
      return { ...state, isConfirmationMessageResent: null, confirmationMessageIsSending: true };
    case ActionTypes.RESEND_PAYMENT_CONFIRMATION_MESSAGE[SUCCESS]:
      return { ...state, isConfirmationMessageResent: true, confirmationMessageIsSending: false };
    case ActionTypes.RESEND_PAYMENT_CONFIRMATION_MESSAGE[FAILURE]:
      return { ...state, error: action.error, isConfirmationMessageResent: false, confirmationMessageIsSending: false };

    case ActionTypes.resetPaymentForm[REQUEST]:
      return { ...state, paymentForm: null, paymentFormIsLoaded: null };
    case ActionTypes.resetPaymentForm[SUCCESS]:
      return { ...state, paymentFormIsLoaded: true };
    case ActionTypes.resetPaymentForm[FAILURE]:
      return { ...state, paymentForm: null, error: action.error, paymentFormIsLoaded: false };

    case ActionTypes.UPLOD_PAYMENT_PROOF[REQUEST]:
      return { ...state, paymentProofUploadError: null, paymentProofUploaded: false };
    case ActionTypes.UPLOD_PAYMENT_PROOF[SUCCESS]: {
      return {
        ...state,
        paymentProofUploaded: true,
        paymentProofUploadError: null,
      };
    }
    case ActionTypes.UPLOD_PAYMENT_PROOF[FAILURE]:
      return {
        ...state,
        paymentProofUploaded: false,
        paymentProofUploadError: action.error,
      };

    case ActionTypes.GET_AVAILABLE_LPAS[REQUEST]:
      return { ...state, availableLpasAreLoaded: false };
    case ActionTypes.GET_AVAILABLE_LPAS[SUCCESS]:
      return { ...state, availableLpas: action.data, availableLpasAreLoaded: true };
    case ActionTypes.GET_AVAILABLE_LPAS[FAILURE]:
      return { ...state, error: action.error, availableLpasAreLoaded: false };

    case ActionTypes.GET_LPA_AGENT_DEALS[REQUEST]:
      return { ...state, lpaAgentDealsIsLoaded: false };
    case ActionTypes.GET_LPA_AGENT_DEALS[SUCCESS]:
      return {
        ...state,
        lpaAgentDealsIsLoaded: true,
        lpaAgentDeals: action.data,
      };
    case ActionTypes.GET_LPA_AGENT_DEALS[FAILURE]:
      return { ...state, lpaAgentDealsIsLoaded: false };

    case ActionTypes.GET_LPA_CLIENT_DEALS[REQUEST]:
      return { ...state, lpaClientDealsIsLoaded: false };
    case ActionTypes.GET_LPA_CLIENT_DEALS[SUCCESS]:
      return {
        ...state,
        lpaClientDealsIsLoaded: true,
        lpaClientDeals: action.data,
      };
    case ActionTypes.GET_LPA_CLIENT_DEALS[FAILURE]:
      return { ...state, lpaClientDealsIsLoaded: false };

    case ActionTypes.GET_LPA_DEAL[REQUEST]:
      return { ...state, lpaDealIsLoaded: false };
    case ActionTypes.GET_LPA_DEAL[SUCCESS]:
      return { ...state, lpaDeal: action.data, lpaDealIsLoaded: true };
    case ActionTypes.GET_LPA_DEAL[FAILURE]:
      return { ...state, error: action.error, lpaDealIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
