import css from 'styled-jsx/css'
import { isRTL } from "common/styling/theme";

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SmallAreaChart {
    padding: rem(${isRTL ? '25px 0 0 40px' : '25px 40px 0 0'});
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    margin-bottom: 20px;
    position: relative;
    @include respond-to(lg) {
      padding-right: 15px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: ${isRTL ? 'auto' : '0'};
      right: ${isRTL ? '0' : 'auto'};
      width: rem(13px);
      height: 100%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  .SmallAreaChart__wrapper {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    padding-left: rem(${isRTL ? '0' : '40px'});
    padding-right: rem(${isRTL ? '40px' : '0'});
    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  .SmallAreaChart__inner {
    display: flex;
    flex-direction: column;
    height: 95px;
    width: 120px;
    @include respond-to(sm) {
      width: 100px;
    }
    @include respond-to(xs) {
      width: 49%;
    }
    &:first-child {
      width: 280px;
      @include respond-to(sm) {
        width: 180px;
      }
      @include respond-to(xs) {
        width: 100%;
      }
    }
    &:last-child {
      .Amount {
        padding-right: 0;
      }
    }
    :global(.AreaChart) {
      min-height: 100px !important;
      margin: -10px 0 0 -10px;
      :global(span) {
        color: #fff;
        top: 2px;
      }
    }
    h4 {
      font-size: 15px;
      font-weight: 400;
      position: relative;
      padding-bottom: 2px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: ${isRTL ? 'auto' : '0'};
        right: ${isRTL ? '0' : 'auto'};
        width: 200vw;
        height: 1px;
      }
    }
    :global(span) {
      font-size: 13px;
      text-transform: lowercase;
      text-align: center;
      position: relative;
      top: 4px;
    }
    span.Amount {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: rem(20px);
      text-transform: none;
      white-space: nowrap;
    }
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .SmallAreaChart {
      background: ${colors.white.hex};
      border-radius: ${border.radius};
    }

    .SmallAreaChart__inner {
      h4 {
        &:after {
          background: ${colors.lightGray.hex};
        }
      }
      :global(span) {
        color: ${colors.gray.hex};
      }
      span.Amount {
        color: ${colors.primary.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
