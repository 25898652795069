import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import UserDocument from '../UserDocument';
import { staticStyles } from './style';
import dottedImage from './dottedImage.svg';

const UserDocuments = ({ doc, user, titleId, documents, fileNameId, uploadError, name }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="UserDocuments" id={`backoffice-fileNameId-${fileNameId}`}>
      <div className="UserDocuments__small">
        <FormattedMessage id={titleId}>
          {txt => <div className="UserDocuments__small-title">{txt}</div>}
        </FormattedMessage>
        <div className="UserDocuments__small-images-wrap">
          <div className="UserDocuments__small-images">
            {documents.map(document => (
              <UserDocument
                min
                doc={doc}
                documentId={document.id}
                user={user}
                titleId={titleId}
                statusId={document.status}
                file={document?.file ?? null}
                name={name}
                fileNameId={fileNameId}
                uploadError={uploadError}
                inputId={`${name}_${document.id}`}
                key={document.id}
              />
            ))}
          </div>
        </div>
        {/* eslint-disable */}
        <div
          className={classNames('UserDocuments__small-dotted', {
            'UserDocuments__small-dotted--showMore': showMore,
          })}
          onClick={() => setShowMore(!showMore)}>
          <img src={dottedImage} alt="dotted-image" />
        </div>
        {/* eslint-enable */}
      </div>

      {showMore && (
        <div className="UserDocuments__list">
          {documents.map(document => (
            <UserDocument
              doc={doc}
              documentId={document.id}
              user={user}
              titleId={titleId}
              statusId={document.status}
              file={document?.file ?? null}
              name={name}
              fileNameId={fileNameId}
              uploadError={uploadError}
              inputId={`${name}_${document.id}`}
              key={document.id}
            />
          ))}
        </div>
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserDocuments.propTypes = {
  doc: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  titleId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fileNameId: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  uploadError: PropTypes.string,
};

UserDocuments.defaultProps = {
  uploadError: '',
};

export default UserDocuments;
