import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .FilterChoose {
    padding: 0 16px;
    border-top: 1px solid #f3f3f3;

    &__options {
      height: 500px;
      overflow-y: scroll;

      @include respond-to(sm) {
        height: 400px;
      }
    }

    &__option {
      display: flex;
      padding: 12px 0;

      &-label {
        width: 100%;
      }

      &-checkbox {
        width: auto;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        max-width: 30px !important;
        min-width: 30px !important;
      }
    }

    &__button {
      width: 100%;
      margin-top: 12px !important;
    }
  }
`;

export { staticStyles };
