import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { MainLogo, Tooltip, Banners, CompanyNews } from 'common/components';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import InterfaceSwitcher, { userInterfaceType } from '../_components/InterfaceSwitcher';
import NavigationLink from '../_components/LeftNavigationLink';
import BurgerButton from '../_components/BurgerButton';
import FavoritItem from '../FavoritItem';

import { closeMenu } from '../_redux/actions';

import { staticStyles, getDynamicStyles } from './style';

const LeftNavigation = ({
  isMenuOpen,
  items,
  prefix,
  closeMenu,
  currentInterface,
  interfaces,
  forceVerification,
  profile,
  clientThemeConfig,
  customJsIsActive,
  theme,
  partnershipSystem,
  favorites,
}) => {
  const handleLinkClick = () => isMenuOpen && closeMenu();
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const isVerified = profile && profile.status !== 'incomplete';
  const disableMenu = forceVerification && !isVerified;

  return (
    <Tooltip id="leftNavigationTooltip" textId={disableMenu ? 'verifyToUnlock' : ''} effect="float">
      <div
        className={classNames(
          'LeftNavigation',
          { mobileOpen: isMenuOpen, LeftNavigation__disabled: disableMenu },
          { [`LeftNavigation__${currentInterface.slug}`]: true, 'Left__menu-open': isMenuOpen }
        )}>
        <div className="LeftNavigation__mobile-inner">
          <BurgerButton />
          <MainLogo className="LeftNavigation__logo" />
          <div className="LeftNavigation__interface-switcher">
            {interfaces.length > 1 && (
              <InterfaceSwitcher
                currentInterface={currentInterface}
                interfaces={interfaces}
                closeMenu={handleLinkClick}
                customJsIsActive={customJsIsActive}
              />
            )}
          </div>
        </div>
        {!!items.length &&
          items.map(item => {
            if (item.title === 'menuPartnership' && partnershipSystem === 'simple' && item.items) {
              item.items = item.items.filter(
                subItem => subItem.title !== 'boReports' || window.location.hostname === 'office.profixone.com'
              );
            }

            return item.caption ? (
              <p
                id={`LeftNavigation__${item.caption}-${currentInterface.slug}${
                  item.caption === 'partnershipPage' && profile.isPartner ? '-isPartner' : ''
                }`}
                key={item.caption}
                className="LeftNavigation__caption">
                <FormattedMessage id={item.caption} />
              </p>
            ) : item.title === 'default' ? (
              <></>
            ) : (
              <NavigationLink
                isPartner={profile.isPartner}
                key={item.title}
                prefix={prefix}
                currentInterface={currentInterface}
                {...item}
                onClick={handleLinkClick}
              />
            );
          })}
        {currentInterface.slug === 'crm' && (
          <div className="LeftNavigation__favorites">
            {favorites
              .filter(contact => !contact.fullname)
              .map(contact => (
                <FavoritItem {...contact} key={contact.id} />
              ))}
          </div>
        )}
        <div className="LeftNavigation__mobile-link">
          <NavigationLink
            prefix=""
            title="justSupport"
            url="/support"
            onClick={handleLinkClick}
            unreadCounter={profile.unreadIssuesCount}
          />
          <NavigationLink
            prefix=""
            title="justMail"
            url="/mail"
            onClick={handleLinkClick}
            unreadCounter={profile.unreadNotificationsCount}
          />
        </div>
        {clientThemeConfig.client.website && (
          <div className="LeftNavigation__absolute-link">
            <NavigationLink
              prefix=""
              absolute
              title="justWebsite"
              url={clientThemeConfig.client.website}
              onClick={handleLinkClick}
            />
          </div>
        )}
        {currentInterface.slug === 'client' && <Banners />}
        {currentInterface.slug === 'backoffice' && <CompanyNews />}
        <style jsx global>
          {staticStyles}
        </style>
        <style>{dynamicStyles}</style>
      </div>
    </Tooltip>
  );
};

LeftNavigation.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  closeMenu: PropTypes.func.isRequired,
  interfaces: PropTypes.arrayOf(userInterfaceType).isRequired,
  currentInterface: userInterfaceType.isRequired,
  prefix: PropTypes.string,
  profile: PropTypes.object.isRequired,
  clientThemeConfig: PropTypes.object.isRequired,
  forceVerification: PropTypes.bool.isRequired,
  customJsIsActive: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  partnershipSystem: PropTypes.string.isRequired,
  favorites: PropTypes.array.isRequired,
};

LeftNavigation.defaultProps = {
  prefix: '',
  theme: {},
};

const ThemedLeftNavigation = withTheme()(
  connect(
    ({
      interfaceConfig: {
        kycSettings,
        clientThemeConfig,
        partnership: { partnershipSystem },
        customJs,
      },
      user: { isMenuOpen, profile },
      crm: {
        contacts: { favorites },
      },
    }) => {
      const mandatoryList = [
        kycSettings.passport.mandatory,
        kycSettings.address_proof.mandatory,
        kycSettings.identification_proof.mandatory,
      ];

      return {
        forceVerification: kycSettings.force || mandatoryList.some(enable => enable),
        isMenuOpen,
        profile,
        clientThemeConfig,
        partnershipSystem,
        favorites,
        customJsIsActive: !!customJs?.js,
      };
    },
    { closeMenu }
  )(LeftNavigation)
);

export default ThemedLeftNavigation;
export { LeftNavigation };
