import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnershipInfoLinks__item {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    padding: 20px 35px 10px;
    margin-bottom: rem(25px);
    @include respond-to(sm) {
      padding: 15px 10px 5px;
    }
  }
  
  .PartnershipInfoLinks__partner-code {
    margin-bottom: rem(15px);
    font-size: rem(16px);
    span {
      font-size: rem(20px);
      display: inline-block;
      margin-left: rem(5px);
    }
  }
  
  .PartnershipNoInfoLinks {
    text-align: center;
    width: 100%;
    margin-bottom: rem(20px);
    &__image-inner {
      margin-bottom: rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      img {
        width: rem(50px);
        height: rem(45px);
      }
    }
    p {
      font-size: rem(16px);
    }
  }
  
  .PartnershipInfoLinks__item-links {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: rem(20px);
    :global(.DefaultButton) {
      width: 23%;
      @include respond-to(lg) {
        width: 28%;
      }
      @include respond-to(sm) {
        width: 100%;
        margin-top: 5px;
      }
    }
  }
  
  .Link__inner {
    width: 75%;
    position: relative;
    @include respond-to(lg) {
      width: 70%;
    }
    @include respond-to(sm) {
      width: 100%;
    }
    .Link__text {
      font-size: rem(16px);
      padding: rem(10px 100px 10px 15px);
      border: 1px solid;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
    }
  }
  
  .PartnershipInfoLinks__chart-inner {
    width: 100%;
    .PartnershipInfoLinks__chart {
      width: 100%;
      margin-top: rem(20px);
    }
  }

  .Copy-button,
  .Copy-button__url {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 3;
    font-size: rem(14px);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }

  .Copy-button__url {
    top: 50%;
    transform: translateY(-50%);
  }

  .Copied__inner {
    position: fixed;
    bottom: rem(10px);
    left: 50%;
    background: rgba(#000, 0.8);
    padding: rem(10px 10px 10px 5px);
    color: #fff;
    border-radius: 3px;
    transition: all 0.5s ease;
    transform: translateX(-50%) scale(0.6);
    opacity: 0;
    min-width: 180px;
    p {
      position: relative;
      padding-left: 30px;
      &:before {
        content: '';
        position: absolute;
        left: rem(4px);
        width: rem(10px);
        height: 2px;
        transform: rotate(50deg);
        top: rem(12px);
        z-index: 2;
      }
      &:after {
        content: '';
        position: absolute;
        left: rem(9px);
        width: rem(16px);
        height: 2px;
        transform: rotate(-50deg);
        top: rem(9px);
        z-index: 2;
      }
    }
  }

  .Copied__inner-active {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .PartnershipInfoLinks__item {
      background: ${colors.white.hex};
    }
    
    .PartnershipNoInfoLinks {
      p {
        color: ${colors.gray.hex};
      }
    }
    
    .Link__inner {
      .Link__text {
        border-color: ${colors.lightGray.hex};
        border-radius: ${border.radius};
      }
    }

    .Copy-button,
    .Copy-button__url {
      color: ${colors.primary.hex};
      &:hover {
        color: ${colors.secondary.hex};
      }
    }

    .Copied__inner {
      p {
        &:before,
        &:after {
          background: ${colors.success.hex};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
