import React from 'react';

export const CrmContactsIconActive = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
      <path
        d="M9.99687 10C11.7909 10 13.2188 8.545 13.2188 6.75C13.2188 4.955 11.7644 3.5 9.99687 3.5C8.20281 3.5 6.775 4.955 6.775 6.75C6.74688 8.54375 8.20312 10 9.99687 10ZM9.99687 4.5C11.2369 4.5 12.2456 5.50938 12.2456 6.75C12.2456 7.99062 11.2344 9 9.99687 9C8.75937 9 7.74687 7.99062 7.74687 6.75C7.74687 5.50938 8.75625 4.5 9.99687 4.5ZM11.5594 11H8.44063C5.9875 11 4 12.8656 4 15.1656C4 15.6281 4.39687 16 4.8875 16H15.1125C15.6031 16 16 15.6281 16 15.1656C16 12.8656 14.0125 11 11.5594 11ZM5.00625 15C5.10066 13.3309 6.60594 12 8.41562 12H11.5594C13.3962 12 14.9 13.3309 14.9688 15H5.00625ZM16 5C17.3806 5 18.5 3.88063 18.5 2.5C18.5 1.11937 17.3813 0 16 0C14.6194 0 13.5 1.11937 13.5 2.5C13.5 3.88063 14.6187 5 16 5ZM16 1C16.8272 1 17.5 1.67281 17.5 2.5C17.5 3.32719 16.8281 4 16 4C15.1719 4 14.5 3.32719 14.5 2.5C14.5 1.67281 15.1719 1 16 1ZM4 5C5.38062 5 6.5 3.88063 6.5 2.5C6.5 1.11937 5.38125 0 4 0C2.61937 0 1.5 1.11937 1.5 2.5C1.5 3.88063 2.61937 5 4 5ZM4 1C4.82719 1 5.5 1.67281 5.5 2.5C5.5 3.32719 4.82812 4 4 4C3.17188 4 2.5 3.32812 2.5 2.5C2.5 1.67188 3.17188 1 4 1ZM17.5344 6H15.5C15.1512 6 14.81 6.07812 14.4853 6.23244C14.2358 6.35109 14.1294 6.64931 14.248 6.89838C14.3666 7.14744 14.6661 7.25431 14.9139 7.13569C15.1031 7.04688 15.3 7 15.5 7H17.5338C18.3156 7 19 7.70938 19 8.58438V9C19 9.27637 19.2236 9.5 19.5 9.5C19.7764 9.5 20 9.275 20 9V8.58438C20 7.15938 18.8937 6 17.5344 6ZM5.0875 7.13438C5.33556 7.25303 5.63531 7.14609 5.75344 6.89706C5.87209 6.64803 5.76564 6.34956 5.51613 6.23112C5.19063 6.07812 4.85 6 4.5 6H2.46625C1.10656 6 0 7.15938 0 8.58438V9C0 9.27637 0.223625 9.5 0.5 9.5C0.776375 9.5 1 9.275 1 9V8.58438C1 7.70938 1.65781 7 2.46625 7H4.5C4.7 7 4.89687 7.04688 5.0875 7.13438Z"
        fill="white"
      />
    </svg>
  </div>
);

export default CrmContactsIconActive;
