import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  plans: {},
  plansAreLoaded: false,

  tasks: {},
  tasksAreLoaded: false,

  task: {},
  taskIsLoaded: false,

  managers: [],
  managersAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PLAN[REQUEST]:
      return { ...state, plansAreLoaded: false };
    case ActionTypes.GET_PLAN[SUCCESS]:
      return { ...state, plans: action.data, plansAreLoaded: true };
    case ActionTypes.GET_PLAN[FAILURE]:
      return { ...state, error: action.error, plansAreLoaded: false };

    case ActionTypes.GET_TASKS[REQUEST]:
      return { ...state, tasksAreLoaded: false };
    case ActionTypes.GET_TASKS[SUCCESS]:
      return { ...state, tasks: action.data, tasksAreLoaded: true };
    case ActionTypes.GET_TASKS[FAILURE]:
      return { ...state, error: action.error, tasksAreLoaded: false };

    case ActionTypes.GET_TASK[REQUEST]:
      return { ...state, taskIsLoaded: false };
    case ActionTypes.GET_TASK[SUCCESS]:
      return { ...state, task: action.data, taskIsLoaded: true };
    case ActionTypes.GET_TASK[FAILURE]:
      return { ...state, error: action.error, taskIsLoaded: false };

    case ActionTypes.GET_MANAGERS[REQUEST]:
      return { ...state, managersAreLoaded: false };
    case ActionTypes.GET_MANAGERS[SUCCESS]:
      return { ...state, managers: action.data, managersAreLoaded: true };
    case ActionTypes.GET_MANAGERS[FAILURE]:
      return { ...state, error: action.error, managersAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
