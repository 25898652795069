import React from 'react';

const TemplatesIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.538 2.619C17.173 1.249 15.91.242 14.475.242H3.17A3.176 3.176 0 000 3.412v5.245a3.185 3.185 0 002.463 3.084 3.15 3.15 0 003.062 2.377h.47v3.169c0 .171.108.343.258.428a.634.634 0 00.214.043.45.45 0 00.278-.107l4.583-3.533h5.503A3.176 3.176 0 0020 10.948V5.703a3.167 3.167 0 00-2.462-3.083zM2.355 5.702v5.032A2.218 2.218 0 01.942 8.657V3.411A2.24 2.24 0 013.17 1.184h11.328c.9 0 1.691.557 2.034 1.35H5.525a3.176 3.176 0 00-3.17 3.168zM19.08 10.95h-.021a2.24 2.24 0 01-2.227 2.227h-5.653a.407.407 0 00-.279.107l-3.961 3.062v-2.698a.475.475 0 00-.471-.471h-.942a2.24 2.24 0 01-2.227-2.227V5.702a2.24 2.24 0 012.227-2.227h11.327a2.24 2.24 0 012.227 2.227v5.247z" />
    <path d="M15.996 5.552H6.36a.475.475 0 00-.471.472c0 .257.214.47.47.47h9.637a.475.475 0 00.47-.47.475.475 0 00-.47-.472zM15.996 7.608H6.36a.475.475 0 00-.471.471c0 .257.214.471.47.471h9.637a.475.475 0 00.47-.47.475.475 0 00-.47-.472zM15.996 9.685H6.36a.475.475 0 00-.471.471c0 .257.214.471.47.471h9.637a.475.475 0 00.47-.47.462.462 0 00-.47-.472z" />
  </svg>
);

export default TemplatesIcon;
