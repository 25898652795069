import css from 'styled-jsx/css';

const staticStyles = css `
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SecondPaymentsForm {
    &__step {
      font-size: rem(26px);
      margin-bottom: rem(30px);
      margin-top: rem(50px);
      font-weight: 300;
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(auto-fit, 32%);
      grid-column-gap: 25px;

      @include respond-to(sm) {
        grid-template-columns: repeat(auto-fit, 100%);
      }
    }

    &__btns {
      :global(.DefaultButton:last-child) {
        margin-left: rem(20px);
      }
    }
  }
`;

export { staticStyles };