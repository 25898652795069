import css from 'styled-jsx/css'
import { rgba } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .AccountsPage__lpa {
    :global(.DefaultButton.not-button) {
      position: absolute !important;
      top: rem(-60px);
      right: 0;
      @include respond-to(sm) {
        top: -35px;
      }
    }
  }

  .AccountsPage__ibSettings-warning {
     padding: rem(20px);
     margin-bottom: rem(40px);
     text-align: center;
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .AccountsPage__ibSettings-warning {
      background-color: ${rgba([colors.warning.rgb, 0.35])};
      border-radius: ${border.radius};
    }
  `
}

export { staticStyles, getDynamicStyles }
