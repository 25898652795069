import React from 'react';

import DefaultAuthPage from '../../_components/DefaultAuthPage';
import ResendEmailConfirmationForm from '../_components/ResendEmailConfirmationForm';

const ResendEmailConfirmationPage = () => (
  <DefaultAuthPage captionId="authResendEmailConfirmation" descriptionId="authResendEmailConfirmationDescription">
    <ResendEmailConfirmationForm />
  </DefaultAuthPage>
);

export default ResendEmailConfirmationPage;
