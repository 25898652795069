import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';
import Select from 'react-select';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import Tooltip from 'welcome-setup/_components/Tooltip';
import TooltipIcon from '../ReduxTextField/TooltipIcon';
import { LoadingIcon } from './icons';

import { staticStyles, getDynamicStyles } from './style';

const BaseSelectField = ({
  value,
  onChange,
  name,
  textId,
  textValues,
  tooltipTextId,
  label,
  options,
  searchable,
  disabled,
  error,
  children,
  additionalClass,
  customDefaultValue,
  isMulti,
  noLabel,
  isLoading,
  isLoadingTextId,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const [isActive, setActive] = useState(false);

  const toggleActive = () => setActive(!isActive);
  useEffect(() => {
    if (value !== customDefaultValue && !disabled && !isMulti && customDefaultValue) {
      onChange(options.find(option => option.value === customDefaultValue));
    }
  }, [customDefaultValue, value, disabled, isMulti, options, onChange]);

  const haveText = value?.length ?? value;

  return (
    <div
      className={classNames('BaseSelectField', {
        ReduxSelectField: additionalClass,
        ReduxSelectField__error: error,
        BaseSelectField__noLabel: noLabel,
        active: isActive,
        disabled,
        isMulti,
      })}>
      <div onFocus={toggleActive} onBlur={toggleActive}>
        <div />
        <Select
          {...props}
          value={value}
          onChange={onChange}
          id={name}
          placeholder=""
          classNamePrefix="Select"
          isClearable={false}
          isSearchable={searchable}
          isDisabled={disabled}
          noOptionsMessage={() => null}
          hideSelectedOptions
          options={options}
          maxMenuHeight={270}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isMulti={isMulti}
        />
        {tooltipTextId && (
          <div className="icon">
            <Tooltip id={`ws-tooltip-id-${tooltipTextId}`} textId={tooltipTextId}>
              <TooltipIcon style={{ position: 'relative' }} />
            </Tooltip>
          </div>
        )}

        {!noLabel && (
          <>
            {isLoading && <LoadingIcon />}
            <label
              className={classNames('BaseSelectField__label', { active: isActive, haveText, isLoading })}
              htmlFor={name}>
              {label || <FM id={isLoading ? isLoadingTextId : textId} values={textValues} />}
            </label>
          </>
        )}
      </div>
      {children}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

BaseSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object, PropTypes.array]),
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  additionalClass: PropTypes.bool,
  input: PropTypes.object,
  textId: PropTypes.string,
  textValues: PropTypes.object,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  customDefaultValue: PropTypes.string,
  onFocus: PropTypes.func,
  noLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingTextId: PropTypes.string,
  tooltipTextId: PropTypes.string,
  theme: PropTypes.object,
};

BaseSelectField.defaultProps = {
  value: null,
  children: false,
  additionalClass: false,
  input: {},
  disabled: false,
  textId: '',
  textValues: {},
  label: '',
  searchable: false,
  error: null,
  isMulti: false,
  customDefaultValue: undefined,
  onFocus: null,
  noLabel: false,
  isLoading: false,
  isLoadingTextId: '',
  tooltipTextId: '',
  theme: {},
};

export default withTheme()(BaseSelectField);
export { BaseSelectField };
