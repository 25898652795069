import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextActionsInput {
    position: relative;
    border-radius: 4px;
    border: 1px solid #bfc7d7;
    color: #0c0c3d;
    display: flex;
    align-items: center;
    min-height: rem(54px);
    padding: rem(0 5px 0 13px);
    transition: border-color 0.2s ease-in-out;

    &.active {
      border: 1px solid #005479;
    }

    &.success {
      border: 1px solid #389700;

      .TextActionsInput__button {
        color: #389700;
        background: transparent;
      }
    }

    &--error {
      border: 1px solid #ff4058;
    }

    &--noMarginBottom {
      margin-bottom: 0px !important;
    }

    input {
      position: relative;
      border: none !important;
      padding: 0 !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      align-self: flex-end;
      margin-bottom: 10px;
    }

    &__button {
      position: relative;
      padding: 8px 16px;
      letter-spacing: 1px;
      color: #0aa1e2;
      font-weight: 500;
      border-radius: 4px;
      text-transform: uppercase;
      background: transparent;
      transition: none;
      min-height: 40px;
      margin-left: ${isRTL ? '0' : '10px'};
      margin-right: ${isRTL ? '10px' : '0'};
      transition: color 0.2s ease-in-out;

      &:hover {
        background: linear-gradient(62.43deg, rgba(67, 182, 232, 0.2) 0%, rgba(51, 238, 199, 0.2) 100%), #ffffff;
      }

      &:active {
        color: #005479;
        background: transparent;
      }
    }

    &__label {
      position: absolute;
      top: 0;
      top: 6px !important;
      font-size: rem(12px);
      transform: translateY(0);
      height: rem(20px);
      overflow: hidden;
      color: #5d647d;
      &--error {
        color: #ff4058;
      }
    }
  }
`;

export { staticStyles };
