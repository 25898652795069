import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Tooltip__text {
    position: relative;
    margin: 0;
    opacity: 1 !important;
    line-height: 1;
  }

  .Tooltip {
    padding: rem(10px);
    font-size: rem(14px);
    pre {
      max-width: 450px;
      @include respond-to(sm) {
        max-width: 250px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Tooltip {
      background-color: ${colors.secondary.hex} !important;
    }

    .__react_component_tooltip.type-dark.place-bottom:after {
      border-bottom-color: ${colors.secondary.hex} !important;
      background: inherit;
    }

    .__react_component_tooltip.type-dark.place-top:after {
      border-top-color: ${colors.secondary.hex} !important;
    }

    .__react_component_tooltip.type-dark.place-right:after {
      border-right-color: ${colors.secondary.hex} !important;
    }

    .__react_component_tooltip.type-dark.place-left:after {
      border-left-color: ${colors.secondary.hex} !important;
    }
  `;
};

export { getDynamicStyles, staticStyles };
