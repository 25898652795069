import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .QueryBuilder__wrapper {
    display: flex;
    flex-direction: column;

    .ruleGroup {
      padding: rem(10px);
    }

    .ruleGroup,
    .rule {
      margin-top: rem(20px);
    }

    .rule {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .ruleGroup-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .ruleGroup-combinators {
      position: relative;
      margin-right: auto;
    }

    input[type='text'],
    input[type='number'] {
      padding: rem(10px 20px);
      max-width: rem(300px);
      height: auto;
      margin-right: rem(20px);
    }

    input[type='checkbox'] {
      all: unset;
      appearance: checkbox;
      width: 20px;
      height: 20px;
    }

    input[type='date'] {
      max-width: rem(300px);
      padding: rem(8px 20px);
      height: auto;
    }

    select {
      min-width: 100px;
      padding: rem(10px 30px 10px 15px);
      cursor: pointer;
      margin-right: rem(20px);

      option {
        font-size: rem(15px);
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: rem(15px);
      padding: rem(5px 15px);
      cursor: pointer;
      font-size: rem(15px);
      font-weight: 500;
      vertical-align: middle;
    }

    .ruleGroup-notToggle {
      margin-right: rem(30px);
      display: flex;
      align-items: center;

      input {
        margin-right: rem(10px);
      }
    }

    :global(.CheckboxField) {
      margin-top: rem(30px);
    }

    :global(.DefaultButton) {
      align-self: flex-start;
      margin: 0;
      margin-top: 25px;
    }

    .ruleGroup-remove,
    .rule-remove {
      font-size: rem(20px);
    }

    .rule-remove {
      margin-left: auto;
    }

    .formError {
      margin: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .QueryBuilder__wrapper {
      background-color: ${colors.white.hex};

      input {
        border-radius: ${border.radius};
        background-color: ${colors.white.hex};
        border-color: ${colors.lightGray.hex};
        color: ${colors.gray.hex};

        &:hover {
          border-color: ${rgba([colors.primary.rgb, 0.8])};
        }
      }

      select {
        background-color: ${colors.white.hex};
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 240.811 240.811' style='enable-background:new 0 0 240.811 240.811;' xml:space='preserve'%3E%3Cpath id='Expand_More' d='M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0 c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859 c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
        border-radius: ${border.radius};
        border-color: ${colors.lightGray.hex};
        color: ${colors.gray.hex};

        &:hover {
          border-color: ${rgba([colors.primary.rgb, 0.8])};
          color: ${rgba([colors.primary.rgb, 0.8])};
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 240.811 240.811' style='enable-background:new 0 0 240.811 240.811;' xml:space='preserve'%3E%3Cpath id='Expand_More' d='M220.088,57.667l-99.671,99.695L20.746,57.655c-4.752-4.752-12.439-4.752-17.191,0 c-4.74,4.752-4.74,12.451,0,17.203l108.261,108.297l0,0l0,0c4.74,4.752,12.439,4.752,17.179,0L237.256,74.859 c4.74-4.752,4.74-12.463,0-17.215C232.528,52.915,224.828,52.915,220.088,57.667z' fill='%230AA1E2'/%3E%3C/svg%3E%0A");

          option {
            color: ${colors.black.hex};
          }
        }
      }

      .ruleGroup {
        border: 1px solid;
        background-color: ${rgba([colors.lightGray.rgb, 0.1])};
        border-color: ${rgba([colors.gray.rgb, 0.3])};
      }

      .ruleGroup-addGroup {
        background-color: ${colors.white.hex};
        border-radius: ${border.radius};
        border: 1px solid ${colors.primary.hex};
        color: ${colors.primary.hex};

        &:hover {
          border-color: ${rgba([colors.primary.rgb, 0.8])};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
          color: ${rgba([colors.primary.rgb, 0.8])};
        }
      }

      .ruleGroup-addRule {
        border-radius: ${border.radius};
        color: ${colors.white.hex};
        border: none;
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};

        &:hover {
          box-shadow: 0 3px 6px 0 ${rgba([colors.primary.rgb, 0.4])};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          color: ${colors.white.hex};
        }
      }

      .ruleGroup-remove,
      .rule-remove {
        background-color: ${colors.white.hex};
        border-radius: ${border.radius};
        border: 1px solid ${rgba([colors.error.rgb, 0.5])};
        color: ${colors.error.hex};
        &:hover {
          background: ${rgba([colors.error.rgb, 0.2])};
          color: ${colors.error.hex};
          border-color: ${colors.error.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
