import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const BO_GET_INVEST_STRATEGIES = createRequestTypes('BO_GET_INVEST_STRATEGIES');
export const boGetInvestStrategies = {
  request: search => action(BO_GET_INVEST_STRATEGIES[REQUEST], { search }),
  success: data => action(BO_GET_INVEST_STRATEGIES[SUCCESS], { data }),
  failure: error => action(BO_GET_INVEST_STRATEGIES[FAILURE], { error }),
};

export const BO_GET_INVEST_STRATEGY_INVESTORS = createRequestTypes('BO_GET_INVEST_STRATEGY_INVESTORS');
export const boGetInvestStrategyInvestors = {
  request: (strategyId, search) => action(BO_GET_INVEST_STRATEGY_INVESTORS[REQUEST], { strategyId, search }),
  success: data => action(BO_GET_INVEST_STRATEGY_INVESTORS[SUCCESS], { data }),
  failure: error => action(BO_GET_INVEST_STRATEGY_INVESTORS[FAILURE], { error }),
};

export const BO_TOGGLE_INVEST_STRATEGY = createRequestTypes('BO_TOGGLE_INVEST_STRATEGY');
export const boToggleInvestStrategy = {
  request: (strategyId, strategyAction, search) =>
    action(BO_TOGGLE_INVEST_STRATEGY[REQUEST], { strategyId, strategyAction, search }),
  success: () => action(BO_TOGGLE_INVEST_STRATEGY[SUCCESS]),
  failure: error => action(BO_TOGGLE_INVEST_STRATEGY[FAILURE], { error }),
};

export const BO_TOGGLE_INVESTMENT = createRequestTypes('BO_TOGGLE_INVESTMENT');
export const boToggleInvestment = {
  request: (investmentId, investmentAction, strategyId, search, account) =>
    action(BO_TOGGLE_INVESTMENT[REQUEST], {
      investmentId,
      investmentAction,
      strategyId,
      search,
      account,
    }),
  success: () => action(BO_TOGGLE_INVESTMENT[SUCCESS]),
  failure: error => action(BO_TOGGLE_INVESTMENT[FAILURE], { error }),
};

export const BO_GET_USER_ACCOUNTS = createRequestTypes('BO_GET_USER_ACCOUNTS');
export const boGetUserAccounts = {
  request: userId => action(BO_GET_USER_ACCOUNTS[REQUEST], { userId }),
  success: data => action(BO_GET_USER_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(BO_GET_USER_ACCOUNTS[FAILURE], { error }),
};

export const editInvestStrategy = createFormAction('BO_EDIT_INVEST_STRATEGY');
