import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import { ActionsButton, StatusCell } from 'common/components';
import { BasketIcon, EditIcon } from 'common';
import { chunkString } from 'common/utils/helpers';

import { staticStyles, getDynamicStyles } from './style';

import cardIcon from './card-icon.svg';

const Card = ({ card, onDeleteCard, onEditCard, theme, intl }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const { id, rejectReason, status, truncatedNumber } = card;

  const formattedStatus = status.toLowerCase();

  const displayNumber = `${truncatedNumber.slice(0, 6)}******${truncatedNumber.slice(6, 11)}`;

  const cardChunks = chunkString(displayNumber, 4);

  const textStatuses = [
    { value: 'UPLOADED', label: intl.formatMessage({ id: 'status_card_uploaded' }) },
    { value: 'REJECTED', label: intl.formatMessage({ id: 'status_card_rejected' }) },
    { value: 'VERIFIED', label: intl.formatMessage({ id: 'status_card_verified' }) },
    { value: 'PHOTO_REQUIRED', label: intl.formatMessage({ id: 'status_card_photo_required' }) },
  ];

  const cardStatusComment = textStatuses.filter(textStatus => textStatus.value === status).map(str => str.label);

  return (
    <div className="Card">
      <div className="Card__inner">
        <div className="Card__content">
          <div className="Card__actions">
            {(formattedStatus === 'rejected' || formattedStatus === 'photo_required') && (
              <ActionsButton
                tooltipId={`Edit__tooltip-${id}`}
                tooltipTextId="justEditCard"
                edit
                onClickFunc={() => onEditCard(id)}>
                <EditIcon />
              </ActionsButton>
            )}
            {formattedStatus === 'rejected' && (
              <ActionsButton
                tooltipId={`Delete__tooltip-${id}`}
                tooltipTextId="justDeleteCard"
                deleteType
                onClickFunc={() => onDeleteCard(id)}>
                <BasketIcon />
              </ActionsButton>
            )}
          </div>
          <div className="Card__content">
            <div className="Card__status">
              <div className="Card__icon">
                <img src={cardIcon} alt={`Card ${id}`} />
              </div>
              <StatusCell statusCode={formattedStatus} />
            </div>
            <div className="Card__number">
              {cardChunks.map((cardChunk, id) => (
                <span className="Card__number-part" key={id}>
                  {cardChunk}
                </span>
              ))}
            </div>
            <div className="Card__bottom">
              <div className="Card__comment">{cardStatusComment}</div>
              <div className="Card__hint">
                <i className="Card__arrow-icon" />
                {formattedStatus === 'rejected' && !!rejectReason && (
                  <div className="Card__tooltip">
                    <span data-tip data-for="card" className="Card__info-icon Tooltip__text">
                      !
                    </span>
                    <ReactTooltip id="card" className="Tooltip" place="bottom" delayHide={500} effect="solid" clickable>
                      <div className="CardTip">
                        <FormattedMessage id="cardRejectedText">
                          {txt => <h3 className="CardTip__title">{txt}</h3>}
                        </FormattedMessage>
                        <p className="CardTip__text">{rejectReason}</p>
                      </div>
                    </ReactTooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

Card.propTypes = {
  card: PropTypes.object.isRequired,
  rejectReason: PropTypes.string,
  status: PropTypes.string,
  truncatedNumber: PropTypes.string,
  onDeleteCard: PropTypes.func.isRequired,
  onEditCard: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

Card.defaultProps = {
  rejectReason: null,
  status: '',
  truncatedNumber: '',
  theme: {},
};

const ThemedCard = withTheme()(injectIntl(Card));

export default ThemedCard;
export { Card };
