import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { MoneyCell, NewFieldHelpText } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

function ClientsBalanceTab({
  partnershipClientsBalance: { balanceWithout, balanceWith, clientsWithout, clientsWith },
  theme,
}) {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="ClientsBalanceTab">
      <div className="ClientsBalanceTab__Item-container">
        <div className="ClientsBalanceTab__Item">
          <FM id="partnershipBalanceWithout">{txt => <span className="ClientsBalanceTab__Item-title">{txt}</span>}</FM>
          <div className="ClientsBalanceTab__Item-number-cell-and-Tooltip">
            <MoneyCell maxDigitsAfterDecimal={2} value={balanceWithout} loading={!balanceWithout} />
            <NewFieldHelpText
              tooltipId="ClientsBalanceTab-BalanceWithout-tooltip"
              tooltipTextId="justUpdatedOnceADay"
            />
          </div>
        </div>
        <div className="ClientsBalanceTab__Item">
          <FM id="partnershipTotalNumberOfYourDirectCleints">
            {txt => <span className="ClientsBalanceTab__Item-title">{txt}</span>}
          </FM>
          <div className="ClientsBalanceTab__Item-number-cell-and-Tooltip">
            <span className="ClientsBalanceTab__Item-number-cell">
              {typeof clientsWithout === 'number' ? clientsWithout : '...'}
            </span>

            <NewFieldHelpText
              tooltipId="ClientsBalanceTab-NumberOfClientsWithout-tooltip"
              tooltipTextId="justUpdatedOnceADay"
            />
          </div>
        </div>
      </div>
      <div className="ClientsBalanceTab__Item-container">
        <div className="ClientsBalanceTab__Item">
          <FM id="partnershipBalanceWith">{txt => <span className="ClientsBalanceTab__Item-title">{txt}</span>}</FM>
          <div className="ClientsBalanceTab__Item-number-cell-and-Tooltip">
            <MoneyCell maxDigitsAfterDecimal={2} value={balanceWith} loading={!balanceWith} />
            <NewFieldHelpText tooltipId="ClientsBalanceTab-BalanceWith-tooltip" tooltipTextId="justUpdatedOnceADay" />
          </div>
        </div>
        <div className="ClientsBalanceTab__Item">
          <FM id="partnershipTotalNumberOfClientsOfYourIbNetwork">
            {txt => <span className="ClientsBalanceTab__Item-title">{txt}</span>}
          </FM>
          <div className="ClientsBalanceTab__Item-number-cell-and-Tooltip">
            <span className="ClientsBalanceTab__Item-number-cell">
              {typeof clientsWith === 'number' ? clientsWith : '...'}
            </span>

            <NewFieldHelpText
              tooltipId="ClientsBalanceTab-NumberOfClientsOfYourIbNetwork-tooltip"
              tooltipTextId="justUpdatedOnceADay"
            />
          </div>
        </div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
}

ClientsBalanceTab.propTypes = {
  partnershipClientsBalance: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

ClientsBalanceTab.defaultProps = {
  theme: {},
};

export default withTheme()(ClientsBalanceTab);
