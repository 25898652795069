import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { NoItemsPanel, PageContentPreloader, PageLayout, PagePagination } from 'common/components';
import noNewsImage from 'common/images/noNews.svg';

import { getNews } from '../_redux';

import ArticlesItem from '../_components/ArticlesItem';

class TechnicalAnalysisPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoadRequests();
  }

  handleLoadRequests = () => {
    const query = queryString.parse(this.props.location.search);
    query.limit = 10;
    query.offset = query.offset ? query.offset : 0;
    this.props.getNews({ paginationParams: query });
  };

  render() {
    const { location, history, getNews, news, areLoaded } = this.props;

    const isPaginationNeed = news.count > 10;

    return (
      <PageLayout captionSlug="justNews" showReload onReloadClick={this.handleLoadRequests} reloading={!areLoaded}>
        {isPaginationNeed && (
          <PageContentPreloader ready={areLoaded} firstLaunchOnly type="pagination">
            <PagePagination location={location} history={history} pageCount={news.count} getList={getNews} />
          </PageContentPreloader>
        )}

        <PageContentPreloader ready={areLoaded} type="news">
          {areLoaded && news.results.length ? (
            news.results.map(n => <ArticlesItem key={n.id} id={n.id} date={n.created} title={n.title} body={n.body} />)
          ) : (
            <NoItemsPanel icon={noNewsImage} captionSlug="noNewsYet" />
          )}
        </PageContentPreloader>

        {isPaginationNeed && (
          <PageContentPreloader ready={areLoaded} firstLaunchOnly type="pagination">
            <PagePagination location={location} history={history} pageCount={news.count} getList={getNews} />
          </PageContentPreloader>
        )}
      </PageLayout>
    );
  }
}

TechnicalAnalysisPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getNews: PropTypes.func.isRequired,
  news: PropTypes.object.isRequired,
  areLoaded: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    news: state.client.articles.news,
    areLoaded: state.client.articles.newsAreLoaded,
  }),
  { getNews: paginationParams => getNews.request(paginationParams) }
)(TechnicalAnalysisPage);
