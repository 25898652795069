import React from 'react';
import PropTypes from 'prop-types';
import { FormMessageHOC } from 'common/HOCs';

import GenericForm from './GenericForm';

const GenericFormFailInner = ({ showFormFailMessage, failMessageTextId, children, ...props }) => (
  <GenericForm {...props} onSubmitFail={() => showFormFailMessage(failMessageTextId)}>
    {children}
  </GenericForm>
);

GenericFormFailInner.propTypes = {
  showFormFailMessage: PropTypes.func.isRequired,
  failMessageTextId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

GenericFormFailInner.defaultProps = {
  failMessageTextId: 'formFailMessageText',
};

export default FormMessageHOC(GenericFormFailInner);
