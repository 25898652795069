import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const PaymentsIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="9.402%" y1="188.149%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
        <linearGradient x1="5.246%" y1="186.279%" x2="95.826%" y2="18.008%" id="linearGradient-5">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="-UI" fill="none" fillRule="evenodd">
        <g
          id="ui---blocks"
          transform="translate(-458 -744)"
          fill="url(#linearGradient-4)"
          fillRule="nonzero"
          stroke="url(#linearGradient-5)">
          <g id="inout1" transform="translate(459 745)">
            <path
              d="M11.4571738,0.457870303 C11.4571177,0.579580655 11.5129242,0.696252357 11.6121546,0.781880028 L14.2023798,3.04069068 L0.526698775,3.04069068 C0.235811071,3.04069069 3.54825925e-17,3.24623653 0,3.49979017 C-3.57555355e-17,3.75334382 0.235811071,3.95888966 0.526698775,3.95888967 L14.2023798,3.95888967 L11.6113679,6.21735745 C11.4059651,6.39639776 11.4059651,6.68667946 11.6113679,6.86571977 C11.8167707,7.04476008 12.1497944,7.04476008 12.3551972,6.86571977 L15.8450192,3.82002836 C15.94333,3.7345632 15.9990172,3.61875723 16,3.49773297 C15.999727,3.37577204 15.9429954,3.25906911 15.8426591,3.17406611 L12.3559839,0.133860579 C12.205375,0.00308243108 11.9793359,-0.0359539199 11.7828986,0.0348902698 C11.5864612,0.10573446 11.4581424,0.272568303 11.4575671,0.457870303 L11.4571738,0.457870303 Z"
              id="Shape"
            />
            <path
              d="M4.5431079,9.45819004 C4.54316399,9.57989425 4.48735878,9.69656007 4.38813088,9.78218342 L1.79718268,12.0408801 L15.4733142,12.0408801 C15.7641947,12.0408801 16,12.2464155 16,12.4999564 C16,12.7534972 15.7641947,12.9590327 15.4733142,12.9590327 L1.79718268,12.9590327 L4.38813088,15.2173865 C4.52099993,15.3331994 4.57289119,15.5020005 4.52425775,15.6602039 C4.47562431,15.8184073 4.33385473,15.9419782 4.15235225,15.9843687 C3.97084977,16.0267591 3.77718892,15.981529 3.64431989,15.8657161 L0.154977014,12.8198356 C0.0567639493,12.734459 0.0010860631,12.6187984 0,12.4978993 C0.000272963474,12.3759445 0.0570032019,12.2592475 0.15733707,12.1742488 L3.64471323,9.13385382 C3.7953184,9.00308228 4.02135193,8.96404789 4.21778444,9.03488851 C4.41421695,9.10572912 4.54253261,9.27255455 4.5431079,9.45784719 L4.5431079,9.45819004 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

PaymentsIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

PaymentsIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedPaymentsIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(PaymentsIconActive);

export default ThemedPaymentsIconActive;
