import React from 'react';

const SupportIcon = () => (
  <svg version="1.1" viewBox="0 0 25 25">
    <g id="Symbols">
      <g id="top-panel-_x2F_-default" transform="translate(-870.000000, -27.000000)">
        <g id="Group-4-Copy-3" transform="translate(735.000000, -2.000000)">
          <g id="buoy-blue1" transform="translate(136.000000, 30.000000)">
            <line className="Gradient" x1="17.7" y1="11.5" x2="23.5" y2="11.5" />
            <line className="Gradient" x1="0" y1="11.5" x2="5.8" y2="11.5" />
            <line className="Gradient" x1="11.5" y1="17.9" x2="11.5" y2="23.6" />
            <line className="Gradient" x1="11.5" y1="0" x2="11.5" y2="5.8" />
            <circle id="Oval-3" className="StrokeWhite" cx="11.6" cy="11.6" r="11.6" stroke="url(#linearGradient-1)" />
            <circle
              id="Oval-3_1_"
              className="StrokeWhite"
              cx="11.5"
              cy="11.5"
              r="11.6"
              stroke="url(#linearGradient-1)"
            />
            <circle
              id="Oval-3-Copy_1_"
              className="StrokeWhite"
              cx="11.5"
              cy="11.5"
              r="6"
              stroke="url(#linearGradient-1)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SupportIcon;
