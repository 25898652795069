import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import TasksPlanItem from '../TasksPlanItem';

import { staticStyles, getDynamicStyles } from './style';

const TasksPlanList = ({ type, tasks, textId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className={classNames('TasksPlanList', `TasksPlanList__${type}`)}>
      <FormattedMessage id={textId}>{txt => <h3 className="TasksPlanList__title">{txt}</h3>}</FormattedMessage>

      {tasks.slice(0, 5).map(task => (
        <TasksPlanItem task={task} key={task.id} />
      ))}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TasksPlanList.propTypes = {
  type: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  textId: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

TasksPlanList.defaultProps = {
  theme: {},
};

export default withTheme()(TasksPlanList);
export { TasksPlanList };
