import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { BaseTable, LoginCell, MoneyCell, DefaultButton, RowLink } from 'common/components';

import { staticStyles } from './style';

const PartnerAccountsTable = ({ data, onRowClick, onWithdrawClick, intl }) => {
  /* eslint-disable */
  const columns = [
    {
      key: 'accountId',
      Cell: ({ original: { partnerCode, id } }) => (
        <RowLink link={`/partnership/account/${id}`} isPreviewCell>
          <LoginCell login={partnerCode} title={intl.formatMessage({ id: 'partnerAccount' })} inTable />
        </RowLink>
      ),
    },
    {
      key: 'justBalance',
      Cell: ({ original: { balance, loading, loadingFailed, id } }) => (
        <RowLink link={`/partnership/account/${id}`} isPreviewCell>
          <MoneyCell inTable value={balance} loading={loading} loadingFailed={loadingFailed} />
        </RowLink>
      ),
    },
    {
      key: 'justWithdrawalBalance',
      Cell: ({ original: { withdrawBalance, loading, loadingFailed, id } }) => (
        <RowLink link={`/partnership/account/${id}`} isPreviewCell>
          <MoneyCell inTable value={withdrawBalance} loading={loading} loadingFailed={loadingFailed} />
        </RowLink>
      ),
    },
    {
      Cell: ({ original: { id, withdrawBalance, loading, loadingFailed } }) => (
        <DefaultButton
          textId="clientWithdraw"
          onClick={e => {
            e.stopPropagation();
            onWithdrawClick(id);
          }}
          noBorder
          disabled={parseFloat(withdrawBalance.amount) <= 0 || loading || loadingFailed}
        />
      ),
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => !rowInfo.original.loadingFailed && onRowClick(rowInfo.original.id),
  });

  return (
    <div className="PartnerAccountsTable">
      <BaseTable columns={columns} data={data} getTrProps={rowProps} />

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

PartnerAccountsTable.propTypes = {
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onWithdrawClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(PartnerAccountsTable);
export { PartnerAccountsTable };
