import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ActionsButton, PaginationTable } from 'common/components';
import { ModalHOC } from 'common';
import { BasketIcon } from '../../../icons';
import { staticStyles } from './style';

const PeriodicalReportTable = ({
  data,
  onRowClick,
  getList,
  loading,
  history,
  location,
  params,
  intl,
  showModal,
  deleteReport,
}) => {
  const FREQUENCY = {
    'EVERY MONTH': intl.formatMessage({ id: 'justEveryMonth' }),
    'EVERY WEEK': intl.formatMessage({ id: 'justEveryWeek' }),
    'EVERY DAY': intl.formatMessage({ id: 'justDaily' }),
  };

  const WEEK_DAYS = [
    intl.formatMessage({ id: 'justMonday' }),
    intl.formatMessage({ id: 'justTuesday' }),
    intl.formatMessage({ id: 'justWednesday' }),
    intl.formatMessage({ id: 'justThursday' }),
    intl.formatMessage({ id: 'justFriday' }),
    intl.formatMessage({ id: 'justSaturday' }),
    intl.formatMessage({ id: 'justSunday' }),
  ];

  const handleDeleteReport = (e, reportId) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeleteReport',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deleteReport({ reportId, reportType: 'periodical', queryParams: params }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justIbAccount',
      accessor: 'ibAccount',
      Cell: ({ original: { ibAccount } }) => <span className="PeriodicalReportTable__ib-account">{ibAccount}</span>,
      isPreviewCell: true,
    },
    {
      key: 'justReportType',
      accessor: 'template',
      Cell: ({ original: { template } }) => <span className="PeriodicalReportTable__template">{template}</span>,
      isPreviewCell: true,
    },
    {
      key: 'justFrequency',
      accessor: 'frequency',
      Cell: ({ original: { frequency } }) => (
        <span className="PeriodicalReportTable__frequency">{FREQUENCY[frequency]}</span>
      ),
    },
    {
      key: 'justOption',
      accessor: 'day',
      Cell: ({ original: { day, frequency } }) => (
        <span className="PeriodicalReportTable__option">
          {frequency === 'EVERY WEEK'
            ? WEEK_DAYS[day - 1]
            : frequency === 'EVERY MONTH'
            ? `${intl.formatMessage({ id: 'startsOnDay' })} 1`
            : '-'}
        </span>
      ),
    },
    {
      key: 'justActions',
      Cell: ({ original: { id } }) => (
        <ActionsButton
          tooltipId={`Delete__tooltip-${id}`}
          tooltipTextId="justDelete"
          deleteType
          onClickFunc={e => handleDeleteReport(e, id)}>
          <BasketIcon />
        </ActionsButton>
      ),
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="PeriodicalReportTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

PeriodicalReportTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ModalHOC(PeriodicalReportTable));
export { PeriodicalReportTable };
