import queryString from 'query-string';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { PageLayout, PageContentPreloader, SearchPanel, NoItemsPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { WithdrawApprovalListTable } from '../../_components';
import { getWithdrawApprovalGroupList } from '../../_redux';
import { staticStyles } from './style';

const WithdrawApprovalListPage = ({
  getWithdrawApprovalGroupList,
  withdrawApprovalGroupList,
  withdrawApprovalGroupListIsLoaded,
  history,
  location,
  intl,
}) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };

  /* eslint-disable */
  useEffect(() => {
    getWithdrawApprovalGroupList({ search: query });
  }, [getWithdrawApprovalGroupList]);
  /* eslint-enable */

  const filters = [
    {
      title: 'selectStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'IN_PROGRESS', label: intl.formatMessage({ id: 'status_in_progress' }) },
        { value: 'WAITING_FOR_APPROVAL', label: intl.formatMessage({ id: 'status_waiting_for_approval' }) },
        { value: 'COMPLETED', label: intl.formatMessage({ id: 'status_completed' }) },
        { value: 'REJECTED', label: intl.formatMessage({ id: 'status_rejected' }) },
      ],
    },
    {
      title: 'justSelectAssignment',
      accessor: 'assignment',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAllRecords' }) },
        { value: 'ALL', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'ME', label: intl.formatMessage({ id: 'justToMe' }) },
      ],
    },
  ];

  const handleRowClick = (_, row) => history.push(`/backoffice/withdrawals/approve/${row.original.id}`);

  return (
    <PageLayout captionSlug="justWithdrawalsApproving">
      <PageContentPreloader ready={withdrawApprovalGroupListIsLoaded} type="bigLine" firstLaunchOnly>
        {!withdrawApprovalGroupList.count && !Object.keys(query).length ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getWithdrawApprovalGroupList}
            params={{ search: query }}
            filters={filters}
          />
        )}
        {withdrawApprovalGroupList.count ? (
          <WithdrawApprovalListTable
            data={withdrawApprovalGroupList}
            loading={!withdrawApprovalGroupListIsLoaded}
            location={location}
            history={history}
            getList={getWithdrawApprovalGroupList}
            onRowClick={handleRowClick}
            params={{ search: query }}
          />
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={
              location.search ? 'noWithdrawApprovalGroupsMatchingTheParameters' : 'noWithdrawApprovalGroupsYet'
            }
          />
        )}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </PageLayout>
  );
};

WithdrawApprovalListPage.propTypes = {
  getWithdrawApprovalGroupList: PropTypes.func.isRequired,
  withdrawApprovalGroupList: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  withdrawApprovalGroupListIsLoaded: PropTypes.bool.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      withdrawApprovalGroupList: state.backoffice.payments.withdrawApprovalGroupList,
      withdrawApprovalGroupListIsLoaded: state.backoffice.payments.withdrawApprovalGroupListIsLoaded,
    }),
    {
      getWithdrawApprovalGroupList: data => getWithdrawApprovalGroupList.request(data),
    }
  )
)(WithdrawApprovalListPage);
export { WithdrawApprovalListPage };
