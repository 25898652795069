import { SubmissionError } from 'redux-form';
import { put, call, fork, take, delay, takeEvery } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { createIssueAttachment } from 'common/_api';
import { getBackofficeCountersSaga } from 'backoffice/_redux/commonSagas/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getIssueSaga(id) {
  try {
    const { status, data } = yield call(api.getIssue, id);
    if (status < 300) {
      yield put(ActionTypes.getIssue.success(data));
      return { issueIsLoaded: true, data };
    }
    yield put(ActionTypes.getIssue.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getIssue.failure());
    throw e;
  }
}

export function* getIssueWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_ISSUE[REQUEST]);
    yield call(getIssueSaga, id);
  }
}

export function* getIssuesSaga({ search }) {
  try {
    const { status, data } = yield call(api.getIssues, search);
    if (status < 300) {
      yield put(ActionTypes.getIssues.success(data));
      return { issuesAreLoaded: true, data };
    }
    yield put(ActionTypes.getIssues.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getIssues.failure());
    throw e;
  }
}

export function* getIssuesWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_ISSUES[REQUEST]);
    yield call(getIssuesSaga, search);
  }
}

export function* createMessageSaga(issue, text, attachments, isNote) {
  try {
    const { data, status } = yield call(api.createMessage, issue, text, isNote);
    if (status < 300) {
      yield put(ActionTypes.createMessage.success(data));
      if (attachments && attachments.length) {
        for (let i = 0; i < attachments.length; i += 1) {
          yield put(ActionTypes.createAttachment.request(data.id, attachments[i]));
        }
        yield delay(100);
      }
      yield call(getIssueSaga, issue);
      yield call(getBackofficeCountersSaga);
    } else if (status === 401) {
      yield put(ActionTypes.createMessage.failure());
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.createMessage.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createMessage.failure());
    throw e;
  }
}

export function* createMessageWatcherSaga() {
  while (true) {
    const {
      payload: { issue, text, attachments, isNote },
    } = yield take(ActionTypes.createMessage.REQUEST);
    yield call(createMessageSaga, issue, text, attachments, isNote);
  }
}

export function* createAttachmentSaga(message, file) {
  try {
    yield call(createIssueAttachment, message, file);
    yield put(ActionTypes.createAttachment.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createAttachment.failure());
  }
}

export function* createAttachmentWatcherSaga() {
  while (true) {
    const { message, file } = yield take(ActionTypes.CREATE_ATTACHMENT[REQUEST]);
    yield call(createAttachmentSaga, message, file);
  }
}

export function* deleteAttachmentSaga({ attachmentId }) {
  try {
    yield call(api.deleteAttachment, attachmentId);
    yield put(ActionTypes.deleteAttachment.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteAttachment.failure());
  }
}

export function* deleteAttachmentWatcherSaga() {
  yield takeEvery(ActionTypes.DELETE_ATTACHMENT[REQUEST], deleteAttachmentSaga);
}

export function* editMessageSaga({ issue, messageId, deletedFiles, ...payload }) {
  try {
    if (deletedFiles && deletedFiles.length) {
      for (let i = 0; i < deletedFiles.length; i += 1) {
        yield put(ActionTypes.deleteAttachment.request({ attachmentId: deletedFiles[i] }));
      }
    }
    const { data, status } = yield call(api.editMessage, issue, messageId, payload);
    if (status < 300) {
      yield put(ActionTypes.editMessage.success(data));
      yield call(getIssueSaga, issue);
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.editMessage.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editMessage.failure());
    throw e;
  }
}

export function* editMessageWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editMessage.REQUEST);
    yield call(editMessageSaga, payload);
  }
}

export function* createIssueTicketSaga(payload) {
  try {
    const { search, ...values } = payload;
    const { data, status } = yield call(api.createIssueTicket, values);
    if (status < 300) {
      yield put(ActionTypes.createIssueTicket.success(data));
      yield call(getIssuesSaga, { search });
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.createIssueTicket.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createIssueTicket.failure());
    throw e;
  }
}

export function* createIssueTicketWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createIssueTicket.REQUEST);
    yield call(createIssueTicketSaga, payload);
  }
}

export function* changeIssueLanguageSaga(issueId, language) {
  try {
    const { data, status } = yield call(api.changeIssueLanguage, issueId, language);
    if (status < 300) {
      yield put(ActionTypes.changeIssueLanguage.success(data));
      yield put(ActionTypes.getIssue.success(data));
    } else if (status === 401) {
      yield put(ActionTypes.changeIssueLanguage.failure());
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.changeIssueLanguage.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changeIssueLanguage.failure());
  }
}

export function* changeIssueLanguageWatcherSaga() {
  while (true) {
    const {
      payload: { issueId, language },
    } = yield take(ActionTypes.changeIssueLanguage.REQUEST);
    yield call(changeIssueLanguageSaga, issueId, language);
  }
}

export function* verifyPartnerFromTicketSaga({ id, issue }) {
  try {
    const { status, data } = yield call(api.verifyPartnerFromTicket, id);
    if (status < 300) {
      yield put(ActionTypes.verifyPartnerFromTicket.success(data));
      yield call(getIssueSaga, issue);
    } else {
      yield put(ActionTypes.verifyPartnerFromTicket.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.verifyPartnerFromTicket.failure());
  }
}
export function* verifyPartnerWatcherSaga() {
  while (true) {
    const { id, issue } = yield take(ActionTypes.VERIFY_PARTNER_FROM_TICKET[REQUEST]);
    yield call(verifyPartnerFromTicketSaga, { id, issue });
  }
}

export function* rejectPartnerFromTicketSaga(payload) {
  try {
    const { id, issue, ...values } = payload;
    const { status, data } = yield call(api.rejectPartnerFromTicket, id, values);
    if (status < 300) {
      yield put(ActionTypes.rejectPartnerFromTicket.success(data));
      yield call(getIssueSaga, issue);
    } else {
      yield put(ActionTypes.rejectPartnerFromTicket.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectPartnerFromTicket.failure());
    throw e;
  }
}
export function* rejectPartnerFromTicketWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectPartnerFromTicket.REQUEST);
    yield call(rejectPartnerFromTicketSaga, payload);
  }
}

export function* closeIssueBackofficeSaga(payload) {
  try {
    const { issueId } = payload;
    const { status, data } = yield call(api.closeIssueBackoffice, issueId);
    if (status < 300) {
      yield put(ActionTypes.closeIssueBackoffice.success(data));
      yield call(getIssueSaga, issueId);
    } else {
      yield put(ActionTypes.closeIssueBackoffice.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.closeIssueBackoffice.failure());
    throw e;
  }
}
export function* closeIssueBackofficeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.closeIssueBackoffice.REQUEST);
    yield call(closeIssueBackofficeSaga, payload);
  }
}

export default [
  fork(closeIssueBackofficeWatcherSaga),
  fork(getIssueWatcherSaga),
  fork(getIssuesWatcherSaga),
  fork(createMessageWatcherSaga),
  fork(createAttachmentWatcherSaga),
  fork(createIssueTicketWatcherSaga),
  fork(editMessageWatcherSaga),
  fork(deleteAttachmentWatcherSaga),
  fork(changeIssueLanguageWatcherSaga),
  fork(verifyPartnerWatcherSaga),
  fork(rejectPartnerFromTicketWatcherSaga),
];
