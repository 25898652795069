import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WithdrawApprovalDetailPage__info {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 60px 10px 30px);
    margin-bottom: rem(20px);
    position: relative;

    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 5px;

        span {
          font-size: rem(13px);
          display: block;
          margin-bottom: 5px;
        }
        &:first-child {
          font-size: rem(18px);
        }

        .amount {
          font-weight: 500;
        }

        .assigned {
          span {
            color: #000;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .WithdrawApprovalDetailPage__info {
      background-color: ${colors.white.hex};

      &:after {
        background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }

      ul {
        li {
          span {
            color: ${colors.gray.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
