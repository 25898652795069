import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabInOut {
    .RadioTabs {
      &__list {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        margin-bottom: 24px;
      }

      &__tab {
        user-select: none;

        span {
          font-size: 14px;
          font-weight: 400;
          color: #333;
          transition: color 0.3s ease;

          &:hover {
            color: #0074a7;
          }
        }

        img {
          margin-right: 12px;
        }
      }
    }

    .Pagination {
      &__right-count {
        font-weight: 400;
        color: #333;
        border-radius: 4px;
        border-color: #f3f3f3;
      }

      &__right-count--active {
        border-color: #0aa1e2;
      }
    }

    /* Deposits and withdraws */

    .TabInOutTableDAW {
      .BaseTable {
        &__thead-cell {
          border-radius: none !important;
          background: initial !important;
          border-bottom: 1px solid #f3f3f3;
          padding: 8px 0;
        }

        &__thead-caption {
          color: #999;
          font-size: 10px;
          font-weight: 400;
          line-height: 1.4;
        }

        &__thead-cell {
          &:nth-of-type(1) {
            min-width: 160px;
          }

          &:nth-of-type(2) {
            min-width: 130px;
          }

          &:nth-of-type(3) {
            min-width: 120px;
          }

          &:nth-of-type(4) {
            min-width: 110px;
          }

          &:nth-of-type(5) {
            min-width: 170px;
          }

          &:nth-of-type(6) {
            min-width: 120px;
          }
        }

        &__tbody-cell {
          padding: 18px 8px 18px 0;
          border-top: none !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: 1px solid #f3f3f3;

          span {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
          }

          &:hover {
            opacity: 1;
            background: initial !important;
          }

          &:nth-of-type(1) {
            min-width: 160px;
          }

          &:nth-of-type(2) {
            min-width: 130px;
          }

          &:nth-of-type(3) {
            min-width: 120px;
          }

          &:nth-of-type(4) {
            min-width: 110px;
          }

          &:nth-of-type(5) {
            min-width: 170px;
          }

          &:nth-of-type(6) {
            min-width: 120px;
          }

          .amount {
            display: flex;
            align-items: center;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;

            &--icon {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 32px;
              border: 1px solid #0aa1e2;
            }
          }

          .account {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;

            &__number {
              margin: 0 0 3px 0;
            }
          }

          .date {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;

            &__date {
              margin: 0 0 3px 0;
            }
          }

          .payment-system {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
          }

          .status {
            display: flex;
            align-items: center;

            &__text {
              color: #333;
              font-size: 14px;
              font-weight: 400;
            }

            &__icon {
              width: 9px;
              min-width: 9px;
              height: 9px;
              min-height: 9px;
              border-radius: 50%;
              background: grey;
              margin: 0 8px 0 0;

              &--money-waiting {
                background: #ffe34e;
              }

              &--failed {
                background: #ff4085;
              }

              &--done {
                background: #04eb84;
              }

              &--deposited {
                background: #04eb84;
              }
            }
          }

          .status-date {
            &__date {
              margin: 0 0 3px 0;
            }
          }
        }
      }

      &__pagination {
        margin: 15px 0 0 0;
      }

      .ResponsiveTable__row-item {
        .amount {
          display: flex;
          gap: 10px;
        }
      }
    }

    /* Internal transfers */

    .TabInOutTableIT {
      .BaseTable {
        &__thead-cell {
          border-radius: none !important;
          background: initial !important;
          border-bottom: 1px solid #f3f3f3;
          padding: 8px 0;

          &:nth-of-type(1) {
            min-width: 160px;
          }

          &:nth-of-type(2) {
            min-width: 110px;
          }

          &:nth-of-type(3) {
            min-width: 110px;
          }

          &:nth-of-type(4) {
            min-width: 130px;
          }

          &:nth-of-type(5) {
            min-width: 120px;
          }

          &:nth-of-type(6) {
            min-width: 170px;
          }
        }

        &__tbody-cell {
          padding: 18px 8px 18px 0;
          border-top: none !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: 1px solid #f3f3f3;

          span {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
          }

          &:hover {
            opacity: 1;
            background: initial !important;
          }

          &:nth-of-type(1) {
            min-width: 160px;
          }

          &:nth-of-type(2) {
            min-width: 110px;
          }

          &:nth-of-type(3) {
            min-width: 110px;
          }

          &:nth-of-type(4) {
            min-width: 130px;
          }

          &:nth-of-type(5) {
            min-width: 120px;
          }

          &:nth-of-type(6) {
            min-width: 170px;
          }

          .amount {
            display: flex;
            align-items: center;

            &--icon {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 32px;
              border: 1px solid #0aa1e2;
            }

            span {
              color: #333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.4;
            }
          }

          .id {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
          }

          .from {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;
          }

          .to {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;

            &__number {
              margin: 0 0 3px 0;
            }

            &__type {
              color: #979797;
            }
          }

          .date {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.4;

            &__date {
              margin: 0 0 3px 0;
            }

            &__time {
              color: #979797;
            }
          }

          .status {
            display: flex;
            align-items: center;

            &__text {
              color: #333;
              font-size: 14px;
              font-weight: 400 !important;
              line-height: 1.4;
            }

            &__icon {
              width: 9px;
              min-width: 9px;
              height: 9px;
              min-height: 9px;
              border-radius: 50%;
              background: grey;
              margin: 0 5px 0 0;

              &--completed {
                background: #04eb84;
              }

              &--waiting-for-approval {
                background: #ffe34e;
              }

              &--canceled {
                background: #ff4085;
              }
            }

            &__text {
              font-weight: 300;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }

      &__pagination {
        margin: 15px 0 0 0;
      }
    }
  }
`;

export { staticStyles };
