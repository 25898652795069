import React from 'react';

const PromoMaterialsIcon = () => (
  <div>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M19.3822 13.1916C19.0933 13.1916 18.8592 13.4258 18.8592 13.7146V18.3584C18.8581 19.2246 18.1564 19.9266 17.2901 19.9274H2.61504C1.74881 19.9266 1.04704 19.2246 1.04602 18.3584V4.7293C1.04704 3.86327 1.74881 3.1613 2.61504 3.16028H7.25879C7.54767 3.16028 7.7818 2.92615 7.7818 2.63727C7.7818 2.34859 7.54767 2.11426 7.25879 2.11426H2.61504C1.17146 2.11589 0.0016344 3.28572 0 4.7293V18.3586C0.0016344 19.8022 1.17146 20.972 2.61504 20.9736H17.2901C18.7337 20.972 19.9035 19.8022 19.9052 18.3586V13.7146C19.9052 13.4258 19.6711 13.1916 19.3822 13.1916Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M19.698 0.769439C18.7789 -0.149709 17.2887 -0.149709 16.3696 0.769439L7.03879 10.1002C6.97484 10.1642 6.92867 10.2435 6.90456 10.3305L5.67753 14.7603C5.62707 14.942 5.67835 15.1365 5.81155 15.2699C5.94496 15.4031 6.13946 15.4543 6.32108 15.4041L10.7509 14.1769C10.838 14.1527 10.9172 14.1066 10.9812 14.0426L20.3118 4.71162C21.2295 3.79186 21.2295 2.30292 20.3118 1.38316L19.698 0.769439ZM8.17837 10.4402L15.8149 2.80345L18.2778 5.26629L10.641 12.903L8.17837 10.4402ZM7.68642 11.4274L9.65403 13.3952L6.93234 14.1493L7.68642 11.4274ZM19.5722 3.97205L19.0175 4.52673L16.5545 2.06368L17.1094 1.50901C17.6199 0.998459 18.4477 0.998459 18.9583 1.50901L19.5722 2.12272C20.0819 2.63388 20.0819 3.4611 19.5722 3.97205Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="9.95259"
          y1="2.11426"
          x2="9.95259"
          y2="20.9736"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#34ECC8" />
          <stop offset="1" stopColor="#3ECADC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="13.3293"
          y1="0.0800781"
          x2="13.3293"
          y2="15.423"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#34ECC8" />
          <stop offset="1" stopColor="#3ECADC" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default PromoMaterialsIcon;
