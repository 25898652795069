import css from 'styled-jsx/css';

import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css``;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    .NewDefaultButton {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: ${colors.white.hex};
      border-radius: 4px;
      text-transform: uppercase;

      &--size-medium {
        padding: 8px 18px;
      }

      &--size-small {
        padding: 4px 8px;
      }

      &--secondary {
        border: 1px solid ${colors.primary.hex};
        background: ${colors.white.hex};
        &:active {
          background: ${gradient(62.43, [colors.gradientStart.rgb, 0.2], 0, [colors.gradientEnd.rgb, 0.2], 100)};
          border-color: transparent;
        }
        &:hover {
          background: ${gradient(62.43, [colors.gradientStart.rgb, 0.2], 0, [colors.gradientEnd.rgb, 0.2], 100)};
        }
        &:disabled {
          border-color: #bfc7d7;
          .NewDefaultButton__text {
            color: ${colors.gray.hex};
          }
          &:hover {
            background: none;
          }
        }
      }

      &--primary {
        background: ${gradient(62.43, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        &:hover {
          box-shadow: 0 3px 6px 0 ${rgba([colors.primary.rgb, 0.35])};
        }
        &:active {
          box-shadow: none;
        }
        &:disabled {
          border-color: transparent;
          background: ${gradient(62.43, [colors.gradientStart.rgb, 0.5], 0, [colors.gradientEnd.rgb, 0.5], 100)};
        }
      }

      &--red {
        border: 1px solid #e33046;
        background: ${colors.white.hex};
        &:hover {
          background: #edcdd3;
        }
        &:active {
          border-color: transparent;
          background: #edcdd3;
        }
        &:disabled {
          border-color: #ff99a6;
          > .NewDefaultButton__text--red {
            color: #ff99a6;
          }
        }
      }

      &--ghost {
        &:hover {
          > .NewDefaultButton__text--ghost {
            color: ${colors.secondary.hex};
          }
        }
        &:active {
          background: ${gradient(62.43, [colors.gradientStart.rgb, 0.2], 0, [colors.gradientEnd.rgb, 0.2], 100)};
        }
        &:disabled {
          background: ${gradient(62.43, [colors.gradientStart.rgb, 0.2], 0, [colors.gradientEnd.rgb, 0.2], 100)};
          > .NewDefaultButton__text--ghost {
            color: ${colors.primary.hex};
          }
        }
      }

      &:disabled {
        cursor: not-allowed;
      }

      .NewDefaultButton__text {
        &--primary {
          color: ${colors.white.hex};
        }

        &--secondary {
          color: ${colors.primary.hex};
        }

        &--red {
          color: #e33046;
        }

        &--ghost {
          color: ${colors.primary.hex};
        }

        &--size-meduim {
          font-size: 14px;
          line-height: 24px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        &--size-small {
          font-size: 11px;
          line-height: 18px;
          font-weight: 400;
          letter-spacing: 0.6px;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
