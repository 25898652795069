import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { PageContentPreloader, NoItemsPanel, ReactDataTable, DateCell } from 'common/components';

import noIssuesImage from 'common/images/noIssues.svg';
import { getUserIssues } from '../../_redux';

import { staticStyles } from './style';

const UserIssues = ({ getUserIssues, userIssues, userIssuesAreLoaded, email, history }) => {
  useEffect(() => {
    getUserIssues({
      search: email,
    });
  }, [getUserIssues, email]);

  /* eslint-disable */
  const columns = [
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'title',
      Header: 'justTitle',
      Cell: ({
        row: {
          original: { title },
        },
      }) => <span>{title}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'description',
      Header: 'justDescription',
      Cell: ({
        row: {
          original: { description },
        },
      }) => <span>{description}</span>,
    },
    {
      accessor: 'created',
      Header: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
  ];
  /* eslint-enable */

  const handleRowClick = id => {
    history.push(`/backoffice/support/${id}/`);
  };

  const onRowClick = (state, rowInfo) => {
    handleRowClick(rowInfo.original.id);
  };

  return (
    <div className="UserIssues">
      <PageContentPreloader ready={userIssuesAreLoaded} type="smallLines">
        {userIssues.length ? (
          <ReactDataTable columns={columns} data={userIssues} onRowClick={onRowClick} />
        ) : (
          <NoItemsPanel icon={noIssuesImage} captionSlug="noIssuesYet" />
        )}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserIssues.propTypes = {
  userIssues: PropTypes.array,
  userIssuesAreLoaded: PropTypes.bool,
  getUserIssues: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
};

UserIssues.defaultProps = {
  userIssues: [],
  userIssuesAreLoaded: false,
};

export default compose(
  withRouter,
  connect(
    state => ({
      profile: state.user.profile,
      userIssues: state.backoffice.users.userIssues,
      userIssuesAreLoaded: state.backoffice.users.userIssuesAreLoaded,
      email: state.backoffice.users.user.email,
    }),
    {
      getUserIssues: id => getUserIssues.request(id),
    }
  )
)(UserIssues);
export { UserIssues };
