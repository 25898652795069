import css from "styled-jsx/css";
import { isRTL } from "common/styling/theme";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .AreaChart {
    padding: rem(22px 40px 0 40px);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    position: relative;
    width: 33%;
    @include respond-to(md) {
      padding-right: 15px;
    }
    @include respond-to(sm) {
      width: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: ${isRTL ? 'auto' : '0'};
      right: ${isRTL ? '0' : 'auto'};
      width: rem(13px);
      height: 100%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      background: #f27186;
    }
  }

  .AreaChart__description {
    overflow: hidden;
    .AreaChart__caption {
      margin-bottom: 5px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
      }
      h4 {
        font-weight: 400;
        font-size: rem(18px);
      }
    }
  }

  .AreaChart__data-line {
    margin-bottom: 15px;
    &:last-child {
      margin: 0;
    }
    :global(span) {
      text-transform: lowercase;
      font-size: rem(15px);
      margin-bottom: 1px;
    }
    p {
      font-size: rem(24px);
      line-height: 1.1;
    }
  }

  .AreaChart__chart {
    :global(.AreaChartInner) {
      min-height: 200px !important;
      margin-top: -15px;
      :global(span) {
        position: relative;
        top: 2px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .AreaChart {
      background: ${colors.white.hex};
      border-radius: ${border.radius};
    }

    .AreaChart__caption {
      &:after {
        background: ${colors.lightGray.hex};
      }
    }

    .AreaChart__data-line {
      :global(span) {
        color: ${colors.gray.hex};
      }
      p {
        color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
