import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

import { PassedStageIcon, NextStageIcon, CurrentStageIcon } from '../icons';

const stageIcon = {
  1: [1, 2, 2, 2],
  2: [0, 1, 2, 2],
  3: [0, 0, 1, 2],
  4: [0, 0, 1, 2],
  5: [0, 0, 0, 1],
  6: [0, 0, 0, 0],
};

const ProgressBar = ({ wsStep, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const ProgressBarStage = stageIcon[wsStep].map((step, ind) => {
    let stageIcon;
    const descriptionClass = classNames('ProgressStage__description', {
      'ProgressStage__description--hide': step !== 1,
    });
    const dividerClass = classNames('ProgressStage__divider', {
      'ProgressStage__divider--green': step === 0,
    });
    if (step === 0) {
      stageIcon = <PassedStageIcon />;
    }
    if (step === 1) {
      stageIcon = <CurrentStageIcon />;
    }
    if (step === 2) {
      stageIcon = <NextStageIcon />;
    }

    return (
      <div key={`progress-bar-stage${ind + 1}`} className="ProgressStage">
        <div className="ProgressStage__status">
          {stageIcon}
          <div className={dividerClass} />
        </div>
        <FormattedMessage id={`welcomeSetupSetupStep${ind + 1}`}>
          {txt => <p className={descriptionClass}>{txt}</p>}
        </FormattedMessage>
      </div>
    );
  });

  return (
    <div className={`ProgressBar ProgressBar--step${wsStep}`}>
      {ProgressBarStage}
      <div className="Divider" />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

ProgressBar.propTypes = {
  wsStep: PropTypes.number.isRequired,
  theme: PropTypes.object,
};

ProgressBar.defaultProps = {
  theme: {},
};

export default withTheme()(ProgressBar);

export { ProgressBar };
