import { REQUEST, FAILURE, SUCCESS, TOGGLE, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_MESSAGES = createRequestTypes('GET_MESSAGES');
export const getMessages = {
  request: () => action(GET_MESSAGES[REQUEST]),
  success: (messages, cursor) => action(GET_MESSAGES[SUCCESS], { messages, cursor }),
  failure: error => action(GET_MESSAGES[FAILURE], { error }),
};

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const addMessage = message => action(ADD_MESSAGE, { message });

export const TOGGLE_MESSAGE = 'TOGGLE_MESSAGE';
export const toggleMessage = id => action(TOGGLE_MESSAGE, { id });

export const READ_MESSAGE = createRequestTypes('READ_MESSAGE');
export const readMessage = {
  request: id => action(READ_MESSAGE[REQUEST], { id }),
  success: id => action(READ_MESSAGE[SUCCESS], { id }),
  failure: (id, error) => action(READ_MESSAGE[FAILURE], { id, error }),
};

export const DELETE_MESSAGE = createRequestTypes('DELETE_MESSAGE');
export const deleteMessage = {
  toggle: id => action(DELETE_MESSAGE[TOGGLE], { id }),
  request: id => action(DELETE_MESSAGE[REQUEST], { id }),
  success: id => action(DELETE_MESSAGE[SUCCESS], { id }),
  failure: (message, error) => action(DELETE_MESSAGE[FAILURE], { message, error }),
};

export const ADD_MESSAGE_NOTIFICATION = 'ADD_MESSAGE_NOTIFICATION';
export const REMOVE_MESSAGE_NOTIFICATION = 'REMOVE_MESSAGE_NOTIFICATION';
export const HIDE_MESSAGE_NOTIFICATION = 'HIDE_MESSAGE_NOTIFICATION';
export const addMessageNotification = message => action(ADD_MESSAGE_NOTIFICATION, { message });
export const removeMessageNotification = id => action(REMOVE_MESSAGE_NOTIFICATION, { id });
export const hideMessageNotification = id => action(HIDE_MESSAGE_NOTIFICATION, { id });
