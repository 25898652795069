import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { PageLayout, PageContentPreloader } from 'common/components';
import EditTemplateForm from '../../_components/EditTemplateForm';

import { getTemplateItem } from '../../_redux';

const EditTemplatePage = ({ templateData, templateDataIsLoaded, getTemplateItem, availableLangs }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { id } = match.params;

  useEffect(() => {
    getTemplateItem({ id });
  }, [getTemplateItem, id]);

  return (
    <PageLayout captionSlug="justAddTemplate">
      <PageContentPreloader ready={templateDataIsLoaded} type="bigLine">
        <div className="EditTemplatePage">
          <EditTemplateForm
            id={id}
            history={history}
            initialValues={templateData}
            languages={availableLangs.map(item => ({ value: item.slug, label: item.niceName }))}
          />
        </div>
      </PageContentPreloader>
    </PageLayout>
  );
};

EditTemplatePage.propTypes = {
  templateData: PropTypes.object.isRequired,
  templateDataIsLoaded: PropTypes.bool.isRequired,
  getTemplateItem: PropTypes.func.isRequired,
  availableLangs: PropTypes.array,
};

EditTemplatePage.defaultProps = {
  availableLangs: [],
};

export default connect(
  state => ({
    templateData: state.backoffice.templates.templateData,
    templateDataIsLoaded: state.backoffice.templates.templateDataIsLoaded,
    availableLangs: state.interfaceConfig.availableLangs,
  }),
  {
    getTemplateItem: id => getTemplateItem.request(id),
  }
)(EditTemplatePage);
