import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';
import { updateItemInArray } from '../../_redux/commonReducer';

export const initialState = {
  issue: {
    messages: [],
  },
  issueIsLoaded: false,

  issues: [],
  activeIssue: -1,
  issueIsCreating: false,
  issuesAreLoaded: false,
  createIssueModalIsOpen: false,
  messageIsCreating: false,
  issueNotification: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CLIENT_ISSUES[REQUEST]:
      return { ...state, issuesAreLoaded: false };
    case ActionTypes.GET_CLIENT_ISSUES[SUCCESS]:
      return {
        ...state,
        issues: action.data,
        issuesAreLoaded: true,
      };
    case ActionTypes.GET_CLIENT_ISSUES[FAILURE]:
      return { ...state, error: action.error, issuesAreLoaded: false };

    case ActionTypes.MARK_ISSUE_AS_VIEWED[REQUEST]:
      return { ...state, issue: { ...state.issue, haveNewMessages: false } };
    case ActionTypes.MARK_ISSUE_AS_VIEWED[SUCCESS]:
      return {
        ...state,
        issues: updateItemInArray(state.issues, state.issue.id, item => ({
          ...item,
          haveNewMessages: false,
        })),
      };
    case ActionTypes.MARK_ISSUE_AS_VIEWED[FAILURE]:
      return { ...state };

    case ActionTypes.GET_CLIENT_ISSUE[REQUEST]:
      return {
        ...state,
        issueIsLoaded: false,
        issue: { ...state.issue, messages: [] },
      };
    case ActionTypes.GET_CLIENT_ISSUE[SUCCESS]:
      return {
        ...state,
        issue: action.data,
        issueIsLoaded: true,
      };
    case ActionTypes.GET_CLIENT_ISSUE[FAILURE]:
      return { ...state, error: action.error, issueIsLoaded: false };

    case ActionTypes.TOGGLE_ISSUE:
      return {
        ...state,
        activeIssue: state.activeIssue === action.id ? -1 : action.id,
      };

    case ActionTypes.OPEN_ISSUE:
      return {
        ...state,
        activeIssue: action.id,
      };

    case ActionTypes.issueCreateMessage[REQUEST]:
      return { ...state, messageIsCreating: true };
    case ActionTypes.issueCreateMessage[SUCCESS]:
      return {
        ...state,
        issue: {
          ...state.issue,
          status: 'open',
          messages: [...state.issue.messages.sort((a, b) => a.id - b.id), action.payload],
        },
        messageIsCreating: false,
      };
    case ActionTypes.issueCreateMessage[FAILURE]:
      return { ...state, error: action.error, messageIsCreating: false };

    case ActionTypes.createIssue[REQUEST]:
      return { ...state, issueIsCreating: true };
    case ActionTypes.createIssue[SUCCESS]:
      return {
        ...state,
        issueIsCreating: false,
        activeIssue: action.payload.id,
        issue: { ...state.issue, id: action.payload.id },
      };
    case ActionTypes.createIssue[FAILURE]:
      return { ...state, error: action.error, issueIsCreating: false };

    case ActionTypes.TOGGLE_CREATE_ISSUE_MODAL:
      return { ...state, createIssueModalIsOpen: !state.createIssueModalIsOpen };

    case ActionTypes.ADD_ISSUE_NOTIFICATION: {
      return {
        ...state,
        issueNotification: [...state.issueNotification, action.issue].sort((a, b) => b.id - a.id),
      };
    }
    case ActionTypes.REMOVE_ISSUE_NOTIFICATION:
      return {
        ...state,
        issueNotification: state.issueNotification.filter(item => item.id !== action.id),
      };

    default:
      return state;
  }
};

export default reducer;
