/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ActionsButton } from 'common/components';
import { deleteContactFavorite, setContactFavorite } from '../../../_redux';

import favoriteIcon32 from './icons/favorite.svg';
import unFavoriteIcon from './icons/unfovarite.svg';
import managerIcon from './icons/managerIcon.svg';
import { staticStyles } from './style';

const FavoriteIcon = ({ isFavorite }) =>
  isFavorite ? <img src={favoriteIcon32} alt="Unfavorite" /> : <img src={unFavoriteIcon} alt="Unfavorite" />;

const NewFullNameCell = ({
  isManager,
  id,
  fullName,
  favorites,
  setContactFavorite,
  deleteContactFavorite,
  analytics,
  history,
}) => {
  // FIXME: ЗАЧЕМ ОТПРАВЛЯТЬ МНЕ ОБЪЕКТ
  const isFavorite = useMemo(() => favorites?.find(favorite => favorite.id === id), [favorites]);

  const handleFavoriteClick = (e, id) => {
    e.stopPropagation();

    return isFavorite ? deleteContactFavorite(id) : setContactFavorite(id);
  };

  return (
    <div className="NewFullNameCell" onClick={() => history.push(`/crm/contacts/${id}`)}>
      {!analytics && (
        <button type="button" className="NewFullNameCell__favorite" onClick={e => handleFavoriteClick(e, id, fullName)}>
          <FavoriteIcon isFavorite={isFavorite} />
        </button>
      )}
      <span
        onClick={() => history.push(`/crm/contancts/${id}`)}
        className={classNames('NewFullNameCell__FullName', { 'NewFullNameCell__FullName--IsManager': isManager })}>
        {fullName}
      </span>
      {isManager && (
        <ActionsButton tooltipId={`ContacsTable__manager-${id}`} tooltipTextId="crmUserIsManager">
          <img className="NewFullNameCell__ManagerIcon" src={managerIcon} alt="manager_icon" />
        </ActionsButton>
      )}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

NewFullNameCell.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  favorites: PropTypes.array.isRequired,
  setContactFavorite: PropTypes.func.isRequired,
  deleteContactFavorite: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(state => ({ favorites: state.crm.contacts.favorites }), {
    setContactFavorite: data => setContactFavorite.request(data),
    deleteContactFavorite: data => deleteContactFavorite.request(data),
  })
)(NewFullNameCell);
