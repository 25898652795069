import css from 'styled-jsx/css';
import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Tabs {
    &__list {
      display: flex;
    }

    &__tab {
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;

      z-index: 1;
      margin: 0 65px 20px 0;
      padding: 0 0 10px 0;
      cursor: pointer;
      position: relative;
      user-select: none;

      &:last-child {
        margin: 0 0 20px 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: calc(100% + 20px);
        left: 50%;
        height: 12px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }

      &--active {
        &:after {
          width: 12px;
        }
      }

      &:hover {
        &:after {
          width: 12px;
        }
      }

      &--disabled {
        cursor: default;

        &:after {
          content: none;
        }
      }
    }

    &__content {
      margin-right: 24px;
      &--display-none {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Tabs {
      &__tab {
        color: ${colors.black.hex};

        &:after {
          background: ${colors.primary.hex};
        }

        &:not(.Tabs__tab--active):hover {
          &:after {
            background: transparent;
            border: 1px solid ${colors.primary.hex};
          }
        }

        &--active {
          color: ${colors.primary.hex};
        }

        &--disabled {
          color: ${colors.gray.hex};
          opacity: 0.7;

          &:hover {
            color: ${colors.gray.hex};

            &:after {
              content: none;
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
