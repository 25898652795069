import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'
import iconDocument from 'common/images/documentDownload.png'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .DocumentsLoadPanel {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);
    padding: rem(25px 30px 10px);
    &:first-child {
      margin-top: rem(20px);
    }
    @include respond-to(sm) {
      padding: rem(20px 15px 10px);
    }
    :global(.FileField__file) {
      :global(.FileField__item-inner) {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(${iconDocument});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        :global(> *) {
          display: none;
        }
      }
    }
  }

  .DocumentsLoadPanel {
    &__input-inner {
      &--error {
        :global(.FileField__dropzone > div) {
          border-color: red !important;
        }
      }
    }
  }

  .DocumentsLoadPanel__caption {
    h2 {
      margin-bottom: 20px;
    }
    p {
      font-size: 13px;
      line-height: 18px;
      display: block;
      margin-bottom: 25px;
    }
  }

  .DocumentsForm__upload {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 30px;
  }

  .DocumentsForm__upload-text {
    position: relative;
    padding-left: 35px;
    width: 50%;
    @include respond-to(sm) {
      width: 100%;
      margin-bottom: 15px;
    }
    &:after {
      content: '';
      height: 2px;
      width: 23px;
      transform: rotate(-51deg);
      position: absolute;
      left: 6px;
      top: 9px;
    }
    i {
      height: 2px;
      width: 13px;
      transform: rotate(50deg);
      position: absolute;
      left: 0;
      top: 13px;
    }
    p {
      margin-bottom: 3px;
    }
    :global(span) {
      font-size: 13px;
    }
  }

  .DocumentsForm__upload-button {
    @include respond-to(sm) {
      width: 100%;
      text-align: right;
    }
    button {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .Document__status-verified {
    display: block;
    position: relative;
    padding: rem(0 0 3px 35px);
    font-size: rem(17px);
    margin-bottom: 20px;
    &:after {
      content: '';
      height: 2px;
      width: rem(23px);
      transform: rotate(-51deg);
      position: absolute;
      left: rem(6px);
      top: 35%;
    }
    i {
      height: 2px;
      width: rem(13px);
      transform: rotate(50deg);
      position: absolute;
      left: 0;
      top: 50%;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .DocumentsLoadPanel {
      background-color: ${colors.white.hex};
    }

    .DocumentsLoadPanel__caption {
      p {
        color: ${colors.gray.hex};
      }
    }

    .DocumentsForm__upload {
      background: ${rgba([colors.warning.rgb, 0.35])};
    }

    .DocumentsForm__upload-text {
      &:after,
      i {
        background: ${colors.success.hex};
      }
    }

    .DocumentsForm__upload-button {
      button {
        color: ${colors.primary.hex};
        &:hover {
          color: ${colors.secondary.hex};
        }
      }
    }

    .Document__status-verified {
      color: ${colors.success.hex};
      &:after,
      i {
        background: ${colors.success.hex};
      }
    }

    .DocumentsLoadPanel__error {
      color: ${colors.error.hex};
      margin: -15px 0 10px;
      display: block;
      width: 100%;
    }
  `
}

export { staticStyles, getDynamicStyles }
