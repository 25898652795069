import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SketchColorPicker from 'react-color/lib/Sketch';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const ColorPickerField = ({ input: { value, onChange, ...input }, meta: { initial }, theme }) => {
  const [isPickerOpened, setPickerOpened] = useState(false);

  const togglePicker = () => setPickerOpened(!isPickerOpened);

  const handleOnChange = ({ hex, rgb }) => onChange({ hex, rgb: Object.values(rgb).slice(0, 3) });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleResetValue = e => {
    e.preventDefault();
    onChange(initial || '#000');
  };

  const hasVScroll = document.body.scrollHeight > document.body.clientHeight;
  const isDeviceTouch = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (!hasVScroll) {
    document.body.classList.add('Body__no-scroll');
  } else {
    document.body.classList.remove('Body__no-scroll');
  }

  if (isDeviceTouch()) {
    document.body.classList.add('Body__touch-screen');
  } else {
    document.body.classList.remove('Body__touch-screen');
  }

  return (
    <div className="ColorPickerField">
      <button className="ColorPicker__inner" type="button" onClick={togglePicker} />

      <FormattedMessage id="justReset">
        {txt => (
          <button type="button" className="ColorPickerField__reset-but" onClick={handleResetValue}>
            {txt}
          </button>
        )}
      </FormattedMessage>

      <Modal isOpen={isPickerOpened} onRequestClose={togglePicker}>
        <SketchColorPicker {...input} color={value} onChange={handleOnChange} />
        <button className="Modal__close" type="button" onClick={togglePicker}>
          <span />
        </button>
      </Modal>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>
        {`
          .ColorPicker__inner {
            background-color: ${value.hex || '#000'};
          }
        `}
      </style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ColorPickerField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

ColorPickerField.defaultProps = {
  theme: {},
};

export default withTheme()(ColorPickerField);
export { ColorPickerField };
