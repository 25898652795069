import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .ArticlesItem {
    margin-bottom: rem(20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: rem(30px 50px 25px 50px);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    @include respond-to(lg) {
      padding: 20px 30px 15px 0;
    }
    @include respond-to(sm) {
      padding: 15px;
    }
  }

  .ArticlesItem__date {
    width: 12%;
    text-align: center;
    @include respond-to(lg) {
      width: 14%;
    }
    @include respond-to(sm) {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      padding-bottom: 15px;
    }
    span {
      vertical-align: middle;
      &:first-child {
        font-size: rem(70px);
        font-weight: 300;
        line-height: 1;
        display: inline-block;
        width: 100%;
        @include respond-to(sm) {
          width: auto;
          margin-right: 5px;
        }
      }
      &:last-child {
        font-size: 16px;
      }
    }
  }

  .ArticlesItem__right-inner {
    width: 88%;
    @include respond-to(lg) {
      width: 85%;
    }
    @include respond-to(sm) {
      width: 100%;
    }
  }

  .ArticlesItem__caption {
    h3 {
      font-size: rem(22px) !important;
      margin-bottom: rem(25px);
    }
  }

  .ArticlesItem__body {
    max-height: rem(250px);
    overflow: hidden;
    text-align: left;
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      bottom: rem(-20px);
      left: -20px;
      right: -20px;
      filter: blur(20px);
      background: linear-gradient(0deg, #f1f1f1 0,hsla(0,0%,100%,0));
      height: rem(150px);
    }
    &_open {
      max-height: 100%;
      cursor: default;
      overflow-x: auto;
      &:after {
        display: none;
      }
    }
    * {
      font-family: $ubuntu-font !important;
    }
    p {
      font-size: rem(16px) !important;
      margin-top: rem(15px);
      margin-left: 0 !important;
      overflow: auto !important;
    }
    strong {
      font-size: rem(16px) !important;
      display: inline-block;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
    table {
      width: 100%;
      table-layout: fixed;
      background: #f0eff0;
      overflow-x: scroll;
      * {
        margin: 0;
      }
      tbody {
        tr:nth-of-type(even) {
          background: #e7dfd3;
        }
        td {
          text-align: center;
          padding: 10px;
        }
      }
    }
  }

  .ArticlesItem__bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: rem(10px);
  }

  .Open__link {
    font-size: rem(16px);
    margin-top: rem(10px);
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .ArticlesItem {
      background: ${colors.white.hex};
    }

    .ArticlesItem__date {
      @include respond-to(sm) {
        border-bottom: 1px solid ${colors.gray.hex};
      }
      span {
        &:first-child {
          color: ${colors.primary.hex};
        }
      }
    }

    .ArticlesItem__body {
      table {
        border: 5px solid ${colors.primary.hex};
        tbody {
          td {
            border-right: 1px solid ${colors.primary.hex};
            border-left: 1px solid ${colors.primary.hex};
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
