import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import {
  PageLayout,
  PageContentPreloader,
  StatusCell,
  DateCell,
  PaginationTable,
  DefaultButton,
} from 'common/components';

import { staticStyles } from './style';

const ExportsPaginationTable = ({ captionSlug, data, dataIsLoaded, getList, downloadExport }) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getList({ search: query });
  }, [getList, query]);

  const onReloadClick = () => getList({ search: query });

  const STATUSES = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
  };

  /* eslint-disable */
  const columns = [
    {
      Header: 'justId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
    },
    {
      Header: 'justName',
      accessor: 'name',
      Cell: ({
        row: {
          original: { name },
        },
      }) => <span>{name}</span>,
    },
    {
      Header: 'justCreatedBy',
      accessor: 'justCreatedBy',
      Cell: ({
        row: {
          original: { createdBy },
        },
      }) => <span>{createdBy}</span>,
    },
    {
      Header: 'justCreated',
      accessor: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      Header: () => null,
      accessor: 'justStatus',
      Cell: ({
        row: {
          original: { id, name, status },
        },
      }) =>
        status === STATUSES.SUCCESS ? (
          <DefaultButton textId="justDownload" type="button" onClick={() => downloadExport({ id, name })} />
        ) : (
          <StatusCell statusCode={status} />
        ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  return (
    <PageLayout captionSlug={captionSlug} reloading={!dataIsLoaded} showReload onReloadClick={onReloadClick}>
      <PageContentPreloader ready={dataIsLoaded} type="bigLine">
        <div className="ExportPaginationTable">
          <PaginationTable
            data={data}
            columns={columns}
            loading={!dataIsLoaded}
            getList={({ search }) => {
              getList({ search });
            }}
            onRowClick={() => {}}
            location={location}
            history={history}
            params={{ search: query }}
            isNewTable
            withoutSort
          />
        </div>
        <style jsx global>
          {staticStyles}
        </style>
      </PageContentPreloader>
    </PageLayout>
  );
};

ExportsPaginationTable.propTypes = {
  captionSlug: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dataIsLoaded: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  downloadExport: PropTypes.func.isRequired,
};

export default ExportsPaginationTable;
