import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import { FormattedMessage } from 'react-intl';
import { staticStyles, getDynamicStyles } from './style';

const InfoPanel = ({ cells, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="InfoPanel">
      <div className="InfoPanel__list">
        {cells.map(cell => (
          <div className="InfoPanel__cell" key={cell.captionId}>
            <FormattedMessage id={cell.captionId}>
              {txt => <span className="InfoPanel__cell-caption">{txt}</span>}
            </FormattedMessage>
            <span
              className={classNames('InfoPanel__cell-value', {
                'InfoPanel__cell-value--pos': cell.value > 0,
                'InfoPanel__cell-value--neg': cell.value < 0,
              })}>
              {cell.displayValue}
            </span>
          </div>
        ))}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InfoPanel.propTypes = {
  cells: PropTypes.array,
  theme: PropTypes.object,
};

InfoPanel.defaultProps = {
  cells: [],
  theme: {},
};

export default withTheme()(InfoPanel);
