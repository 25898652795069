import css from 'styled-jsx/css';

const staticStyles = css.global`
  .UserTotalPayments__Table {
    &__row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 2px;

      &--header {
        font-size: 11px;
        text-transform: uppercase;

        .UserTotalPayments__Table__col {
          padding: 3px 15px;
        }
      }
    }

    &__col {
      padding: 24px 18px;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .UserTotalPayments__Table {
      &__row {
        background-color: ${colors.white.hex};
        border-radius: ${border.radius};
        box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);

        &--header {
          background-color: initial;
          box-shadow: none;
          color: ${colors.gray.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
