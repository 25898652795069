import React from 'react';

const ArrowIcon = () => (
  <svg
    className="LeftMenu__link-arrow"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M3 6L8.00022 11L13 6.00043" stroke="#0C0C3D" />
  </svg>
);

export default ArrowIcon;
