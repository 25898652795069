import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as actionTypes from './actions';

export const initialState = {
  managersSampleList: {},
  managersSampleListIsLoaded: false,

  managerInfo: {},
  managerInfoIsLoaded: false,

  managerSample: [],
  managerSampleIsLoaded: false,

  managersGroupList: {},
  managersGroupListIsLoaded: false,

  managerGroupDetails: {},
  managerGroupDetailsIsLoaded: false,

  managerSalesStatusInfo: {},
  managerSalesStatusInfoIsLoaded: false,

  managerSalesStatuses: [],
  managerSalesStatusesIsLoaded: false,

  filtersList: [],
  filtersListIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MANAGERS_SAMPLE_LIST[REQUEST]:
      return { ...state, managersSampleListIsLoaded: false };
    case actionTypes.GET_MANAGERS_SAMPLE_LIST[SUCCESS]:
      return { ...state, managersSampleList: action.data, managersSampleListIsLoaded: true };
    case actionTypes.GET_MANAGERS_SAMPLE_LIST[FAILURE]:
      return { ...state, error: action.error, managersSampleListIsLoaded: false };

    case actionTypes.GET_MANAGER_INFO[REQUEST]:
      return { ...state, managerInfoIsLoaded: false };
    case actionTypes.GET_MANAGER_INFO[SUCCESS]:
      return { ...state, managerInfo: action.data, managerInfoIsLoaded: true };
    case actionTypes.GET_MANAGER_INFO[FAILURE]:
      return { ...state, error: action.error, managerInfoIsLoaded: false };

    case actionTypes.GET_MANAGER_SAMPLE[REQUEST]:
      return { ...state, managerSampleIsLoaded: false };
    case actionTypes.GET_MANAGER_SAMPLE[SUCCESS]:
      return { ...state, managerSample: action.data, managerSampleIsLoaded: true };
    case actionTypes.GET_MANAGER_SAMPLE[FAILURE]:
      return { ...state, error: action.error, managerSampleIsLoaded: false };

    case actionTypes.GET_MANAGERS_GROUP_LIST[REQUEST]:
      return { ...state, managersGroupListIsLoaded: false };
    case actionTypes.GET_MANAGERS_GROUP_LIST[SUCCESS]:
      return { ...state, managersGroupList: action.data, managersGroupListIsLoaded: true };
    case actionTypes.GET_MANAGERS_GROUP_LIST[FAILURE]:
      return { ...state, error: action.error, managersGroupListIsLoaded: false };

    case actionTypes.GET_MANAGER_GROUP_DETAILS[REQUEST]:
      return { ...state, managerGroupDetailsIsLoaded: false };
    case actionTypes.GET_MANAGER_GROUP_DETAILS[SUCCESS]:
      return { ...state, managerGroupDetails: action.data, managerGroupDetailsIsLoaded: true };
    case actionTypes.GET_MANAGER_GROUP_DETAILS[FAILURE]:
      return { ...state, error: action.error, managerGroupDetailsIsLoaded: false };

    case actionTypes.GET_MANAGER_SALES_STATUS_INFO[REQUEST]:
      return { ...state, managerSalesStatusInfoIsLoaded: false };
    case actionTypes.GET_MANAGER_SALES_STATUS_INFO[SUCCESS]:
      return { ...state, managerSalesStatusInfo: action.data, managerSalesStatusInfoIsLoaded: true };
    case actionTypes.GET_MANAGER_SALES_STATUS_INFO[FAILURE]:
      return { ...state, error: action.error, managerSalesStatusInfoIsLoaded: false };

    case actionTypes.GET_MANAGER_SALES_STATUSES[REQUEST]:
      return { ...state, managerSalesStatusesIsLoaded: false };
    case actionTypes.GET_MANAGER_SALES_STATUSES[SUCCESS]:
      return { ...state, managerSalesStatuses: action.data, managerSalesStatusesIsLoaded: true };
    case actionTypes.GET_MANAGER_SALES_STATUSES[FAILURE]:
      return { ...state, error: action.error, managerSalesStatusesIsLoaded: false };

    case actionTypes.GET_FILTERS_LIST[REQUEST]:
      return { ...state, filtersListIsLoaded: false };
    case actionTypes.GET_FILTERS_LIST[SUCCESS]:
      return { ...state, filtersList: action.data, filtersListIsLoaded: true };
    case actionTypes.GET_FILTERS_LIST[FAILURE]:
      return { ...state, error: action.error, filtersListIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
