/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';

import BaseButton from 'crm/_components/BaseButton';

import cross from './icons/cross.svg';
import selectTriangle from './icons/selectTriangle.svg';

import { staticStyles } from './style';

const NewCRMSelect = ({ areOptionsOpen, setAreOptionsOpen, label, options, selectedOptions, onChange, loading }) => {
  const [possibleSelectedOptions, setPossibleSelectedOptions] = useState(selectedOptions);
  useEffect(() => {
    setPossibleSelectedOptions([...selectedOptions]);
  }, [areOptionsOpen]);

  return (
    <>
      <div className={classNames('NewCRMSelect__Wrapper')}>
        <button
          type="button"
          disabled={loading}
          className={classNames('NewCRMSelect', { 'NewCRMSelect--Loading': loading })}
          onClick={e => {
            e.stopPropagation();
            setAreOptionsOpen(!areOptionsOpen);
          }}>
          {selectedOptions.map(({ value, label }) => (
            <div
              key={`NewCRMSelect-selectedOptions-${(label, value)}`}
              onClick={e => e.stopPropagation()}
              className="NewCRMSelect__SelectedOption">
              <span className="NewCRMSelect__SelectedOptionTitle">{label}</span>
              <button
                className="NewCRMSelect__SelectedOptionCross"
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  onChange(selectedOptions.filter(selectedOption => selectedOption.value !== value));
                  if (areOptionsOpen) {
                    setPossibleSelectedOptions(prevState =>
                      prevState.filter(possibleSelectedOption => possibleSelectedOption.value !== value)
                    );
                  }
                }}>
                <img src={cross} alt="deleteCross" />
              </button>
            </div>
          ))}

          <span
            className={classNames('NewCRMSelect__Label', {
              'NewCRMSelect__Label--Active': selectedOptions.length,
            })}>
            <FM id={label}>{txt => txt}</FM>
          </span>
          <img
            className={classNames('NewCRMSelect__SelectTriangle', {
              'NewCRMSelect__SelectTriangle--Active': areOptionsOpen,
              'NewCRMSelect__SelectTriangle--NoOptions': !selectedOptions.length,
            })}
            src={selectTriangle}
            alt="selectTriangle"
          />
        </button>
        {areOptionsOpen && (
          <>
            <div className="NewCRMSelect__OptionsWrapper">
              <div className="NewCRMSelect__Options">
                {options.map(({ label, value }) => {
                  const isCurrentOptionSelected = possibleSelectedOptions.find(
                    possibleSelectedOption => possibleSelectedOption.value === value
                  );
                  return (
                    <div
                      key={`NewCRMSelect-Option-${(label, value)}`}
                      className="NewCRMSelect__Option"
                      onClick={() => {
                        if (isCurrentOptionSelected) {
                          setPossibleSelectedOptions(
                            possibleSelectedOptions.filter(
                              possibleSelectedOption => possibleSelectedOption.value !== value
                            )
                          );
                        } else {
                          setPossibleSelectedOptions(prevState => [...prevState, { label, value }]);
                        }
                      }}>
                      <span className="NewCRMSelect__OptionTitle">{label}</span>
                      <input
                        type="checkbox"
                        className="NewCRMSelect__OptionSelectCheckbox"
                        checked={
                          !!possibleSelectedOptions.find(
                            possibleSelectedOption => possibleSelectedOption.value === value
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <BaseButton
              type="button"
              textId="justSave"
              onClick={() => {
                onChange([...possibleSelectedOptions]);
                setAreOptionsOpen(!areOptionsOpen);
              }}
              maxContent
            />
          </>
        )}
      </div>
      <style jsx>{staticStyles}</style>
    </>
  );
};

NewCRMSelect.propTypes = {
  areOptionsOpen: PropTypes.bool.isRequired,
  setAreOptionsOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewCRMSelect.defaultProps = {
  loading: false,
};

export default NewCRMSelect;
