import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { required, email } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import BaseButton from 'crm/_components/BaseButton';
import { crmEditContact } from 'crm/users/_redux/actions';
// import { isValidNumber } from 'libphonenumber-js';
import { getCountries, getStates } from 'client/KYC/_redux';
import { PhoneField, ReduxSelectField, ReduxTextField } from 'common/components';

import { staticStyles } from './style';

export const FlagImage = ({ flag }) => <img src={`/countryIcons/${flag}.png`} alt="country" />;

const CRMEditContactInfoForm = ({
  initialValues,
  fieldType,
  fieldName,
  fieldTitle,
  getCountries,
  countries,
  countriesAreLoaded,
  getStates,
  states,
  statesAreLoaded,
  salesStatuses,
  salesStatusesAreLoaded,
  availableLangs,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  disabled,
  dispatch,
  error,
}) => {
  const { id: contactId } = useParams();

  const countriesOptions = countries.map(option => ({
    value: option.gid,
    label: option.name,
  }));
  const textFieldsType = fieldType === 'email' || fieldType === 'text';
  const emailValidation = fieldType === 'email' && [required(), email()];

  const statusesOptions = salesStatusesAreLoaded
    ? salesStatuses.map(option => ({
        value: option.id,
        label: option.title,
      }))
    : [];

  useEffect(() => {
    if (fieldName === 'country') {
      getCountries();
    }
  }, [getCountries]);

  useEffect(() => {
    if (fieldName === 'state' && initialValues.country) {
      getStates(initialValues.country);
    }
  }, [getStates]);

  const submit = handleSubmit(values => {
    let formattedValues = { ...values };

    if (['country', 'state', 'city', 'address'].includes(Object.keys(values)[0])) {
      formattedValues = { ...values };
    }
    if (values.country && !values.state) {
      formattedValues = { country: values.country, state: null };
    }

    crmEditContact({ ...formattedValues, userId: contactId }, dispatch);
  });
  // const validatePhoneNumber = phoneNumber => {
  //   if (!isValidNumber(phoneNumber)) {
  //     return <FormattedMessage id="validationErrorWrongPhone" />;
  //   }
  // };

  return (
    <form className="CRMEditContactInfoForm" onSubmit={submit}>
      <div className="CRMEditContactInfoForm__inputs-inner">
        {textFieldsType && (
          <Field
            component={ReduxTextField}
            name={fieldName}
            textId={fieldTitle}
            autoComplete="off"
            type={fieldType}
            validate={emailValidation || [required()]}
            disabled={submitting}
          />
        )}
        {fieldName === 'language' ? (
          <Field
            component={ReduxSelectField}
            name={fieldName}
            textId={fieldTitle}
            options={availableLangs.map(({ slug, niceName, flag }) => ({
              value: slug,
              label: (
                <div className="LangButton__label">
                  <FlagImage flag={flag === 'ar' ? 'sa' : flag} /> {niceName}
                </div>
              ),
            }))}
            searchable
            disabled={disabled}
          />
        ) : null}
        {fieldName === 'status' ? (
          <Field
            component={ReduxSelectField}
            name={fieldName}
            textId={fieldTitle}
            options={statusesOptions}
            disabled={!salesStatusesAreLoaded || disabled}
          />
        ) : null}
        {fieldType === 'tel' ? (
          <Field
            component={PhoneField}
            name={fieldName}
            textId={fieldTitle}
            // FIXME: не пойму почему validatePhoneNumber вызывает бесконечный ререндер
            validate={[required()]}
            disabled={submitting}
          />
        ) : null}
        {fieldName === 'country' ? (
          <Field
            component={ReduxSelectField}
            name={fieldName}
            textId={fieldTitle}
            options={countriesOptions}
            searchable
            disabled={disabled || !countriesAreLoaded}
          />
        ) : null}
        {fieldName === 'state' &&
          (initialValues.country ? (
            <Field
              component={ReduxSelectField}
              name={fieldName}
              textId="kycState"
              options={states.map(option => ({
                value: option.gid,
                label: option.name,
              }))}
              disabled={disabled || !statesAreLoaded}
              searchable
            />
          ) : (
            <strong className="formError">
              <FormattedMessage id="justChooseCountryFirst" />
            </strong>
          ))}
      </div>

      {fieldName === 'state' && !initialValues.country ? null : (
        <BaseButton type="submit" textId="justChange" maxContent disabled={invalid || pristine || submitting} />
      )}

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

FlagImage.propTypes = {
  flag: PropTypes.string.isRequired,
};

CRMEditContactInfoForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  availableLangs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      niceName: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
    })
  ).isRequired,
  salesStatuses: PropTypes.array.isRequired,
  salesStatusesAreLoaded: PropTypes.bool.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  getCountries: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  statesAreLoaded: PropTypes.bool.isRequired,
  getStates: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CRMEditContactInfoForm.defaultProps = {
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(
    state => ({
      countries: state.client.kyc.countries,
      countriesAreLoaded: state.client.kyc.countriesAreLoaded,
      states: state.client.kyc.states,
      statesAreLoaded: state.client.kyc.statesAreLoaded,
      availableLangs: state.interfaceConfig.availableLangs,
      salesStatuses: state.crm.users.salesStatuses,
      salesStatusesAreLoaded: state.crm.users.salesStatusesAreLoaded,
    }),
    {
      getCountries: () => getCountries.request(),
      getStates: country => getStates.request(country),
    }
  ),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false,
    asyncBlurFields: [],
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
  })
)(CRMEditContactInfoForm);
export { CRMEditContactInfoForm };
