import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';
import ReactPaginate from 'react-paginate';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const PagePagination = ({ intl, location, history, pageCount, getList, lang, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const query = queryString.parse(location.search);
  query.limit = 10;

  const pages = Math.ceil(pageCount / query.limit);
  const initialPage = Math.ceil(query.offset / query.limit);
  let forcePage = Math.ceil(query.offset / query.limit);

  const handlePageClick = data => {
    const selected = data.selected;
    query.offset = Math.ceil(selected * query.limit);
    forcePage = Math.ceil(selected * query.limit);

    if (lang) {
      query.language = lang === 'ru' ? 'ru' : 'en';
    }

    history.replace({
      ...location,
      search: queryString.stringify(query),
    });
    getList({ paginationParams: query });
  };

  return (
    <div className="PagePagination__wrapper">
      <ReactPaginate
        previousLabel={intl.formatMessage({ id: 'justPrevious' })}
        nextLabel={intl.formatMessage({ id: 'justNext' })}
        breakLabel="..."
        breakClassName="PagePagination__break"
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        disableInitialCallback
        forcePage={forcePage || 0}
        initialPage={initialPage || 0}
        containerClassName="PagePagination"
        activeClassName="PagePagination__active"
      />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PagePagination.propTypes = {
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageCount: PropTypes.number,
  getList: PropTypes.func.isRequired,
  lang: PropTypes.string,
  theme: PropTypes.object,
};

PagePagination.defaultProps = {
  lang: '',
  theme: {},
};

PagePagination.defaultProps = {
  pageCount: 0,
};

export default withTheme()(injectIntl(PagePagination));
export { PagePagination };
