import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { compose } from 'redux';

import { connect } from 'react-redux';
import { getCrmClient } from 'crm/contacts/_redux';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const GetClientButton = ({ isGetClientLoading, getCrmClient, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <button type="button" className="GetClientButton" disabled={isGetClientLoading} onClick={() => getCrmClient()}>
      <span className="GetClientButton__button" />
      <FM id="justGetClient" />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </button>
  );
};

GetClientButton.propTypes = {
  isGetClientLoading: PropTypes.bool.isRequired,
  getCrmClient: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

GetClientButton.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({ isGetClientLoading: state.crm.contacts.isGetClientLoading }), {
    getCrmClient: () => getCrmClient.request(),
  })
)(GetClientButton);
