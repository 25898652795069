import css from "styled-jsx/css"

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";
  
  .UserPartnerAccountsPanel__button-inner {
    margin-bottom: rem(15px);
    width: 100%;
    text-align: right;
  }
`

export { staticStyles }
