import queryString from 'query-string';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BasicModal, NoItemsPanel, PageContentPreloader, PageLayout, SearchPanel } from 'common/components';
import { useLocation, useHistory } from 'react-router-dom';
import noPayments from 'common/images/noPayments.svg';
import { getTradingAccountList } from '../../../_redux';
import { InvestRatingTable } from '../../_components';
import InvestStrategyForm from '../../_components/InvestStrategyForm';
import { staticStyles } from './style';
import { getInvestStrategies } from '../../_redux';

const RammRatingPage = ({
  profile,
  investStrategies,
  investStrategiesAreLoaded,
  getInvestStrategies,
  getTradingAccountList,
  accounts,
  addons,
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(query => getInvestStrategies({ requestType: 'all', ...query }), [getInvestStrategies]);
  const [investModal, setInvestModal] = useState({ isOpen: false, payload: {} });
  const toggleInvestModal = (
    strategyId,
    isRollover,
    tpslInRollovers,
    strategyCurrency,
    accountId,
    minInvestmentAmount
  ) =>
    setInvestModal({
      isOpen: !investModal.isOpen,
      payload: {
        strategyId,
        isRollover,
        tpslInRollovers,
        strategyCurrency,
        accountId,
        minInvestmentAmount,
      },
    });

  useEffect(() => {
    getList({ search: query });
  }, [getList, query]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  const handleRowClick = (_rowId, rowInfo) => {
    const strategyId = rowInfo?.original?.id ?? rowInfo.id;
    history.push(`/invest/${strategyId}`);
  };

  const handleSubmitSuccess = () => {
    toggleInvestModal();
    getList({ search: query });
  };
  const justInvestNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justInvestTixee'
      : 'justInvest';
  const investRatingNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'investRatingTixee'
      : 'investRating';

  return (
    <PageLayout externalClass="Algotrading" captionSlug={investRatingNew} showReload={false}>
      <PageContentPreloader ready={investStrategiesAreLoaded} type="bigLine" firstLaunchOnly>
        <SearchPanel location={location} history={history} getList={getList} params={{ search: query }} />
        {investStrategies.count ? (
          <InvestRatingTable
            data={investStrategies}
            loading={!investStrategiesAreLoaded}
            location={location}
            history={history}
            getList={getList}
            toggleInvestModal={toggleInvestModal}
            onRowClick={handleRowClick}
            params={{
              search: query,
            }}
          />
        ) : (
          <NoItemsPanel icon={noPayments} captionSlug="noStrategiesMatchingTheParameters" />
        )}
        <BasicModal captionId={justInvestNew} isOpen={investModal.isOpen} onRequestClose={toggleInvestModal}>
          <InvestStrategyForm
            strategyId={investModal.payload.strategyId}
            strategyAccountId={investModal.payload.accountId}
            strategyCurrency={investModal.payload.strategyCurrency}
            minInvestmentAmount={investModal.payload.minInvestmentAmount}
            accounts={accounts}
            walletBalance={profile.walletBalance}
            isWallet={addons.includes('walletAccount')}
            isRollover={investModal.payload.isRollover}
            tpslInRollovers={investModal.payload.tpslInRollovers}
            afterSubmitSuccess={handleSubmitSuccess}
          />
        </BasicModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

RammRatingPage.propTypes = {
  profile: PropTypes.object.isRequired,
  investStrategies: PropTypes.object.isRequired,
  investStrategiesAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategies: PropTypes.func.isRequired,
  getTradingAccountList: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  addons: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    profile: state.user.profile,
    investStrategies: state.client.invest.investStrategies,
    investStrategiesAreLoaded: state.client.invest.investStrategiesAreLoaded,
    accounts: state.client.common.accounts.filter(
      account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
    ),
    addons: state.interfaceConfig.addons,
  }),
  {
    getInvestStrategies: data => getInvestStrategies.request(data),
    getTradingAccountList: () => getTradingAccountList.request(),
  }
)(RammRatingPage);
