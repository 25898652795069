import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const EditIcon = ({ grayColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24">
    <path
      fill={grayColor.hex}
      d="M10.133 10.704a.84.84 0 0 0 0-1.197.865.865 0 0 0-1.212 0l-4.534 4.48a3.926 3.926 0 0 0-1.19 2.829c0 1.073.417 2.064 1.19 2.828 1.588 1.569 4.158 1.569 5.725 0L20.35 9.528A5.511 5.511 0 0 0 22 5.585c0-1.487-.585-2.89-1.65-3.944a5.697 5.697 0 0 0-8.002 0L2.13 11.737A7.078 7.078 0 0 0 0 16.816c0 1.92.752 3.736 2.131 5.078A7.25 7.25 0 0 0 7.271 24c1.943 0 3.781-.743 5.14-2.106l4.533-4.48a.84.84 0 0 0 0-1.197.865.865 0 0 0-1.212 0l-4.534 4.48a5.567 5.567 0 0 1-3.927 1.61 5.566 5.566 0 0 1-3.928-1.61 5.435 5.435 0 0 1 0-7.763L13.559 2.84a3.91 3.91 0 0 1 2.78-1.136c1.044 0 2.047.413 2.778 1.136a3.818 3.818 0 0 1 1.149 2.746 3.868 3.868 0 0 1-1.15 2.745L8.88 18.447c-.898.887-2.381.887-3.3 0a2.283 2.283 0 0 1-.69-1.631c0-.62.25-1.198.69-1.631l4.554-4.48z"
    />
  </svg>
);

EditIcon.propTypes = {
  grayColor: colorType,
};

EditIcon.defaultProps = {
  grayColor: {},
};

const ThemedEditIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(EditIcon);

export default ThemedEditIcon;
