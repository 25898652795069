import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxSelectField, CheckboxField } from 'common/components';

const ExperienceFields = ({ prefix, textId, active, disabled, generateOptionsFromTranslations }) => (
  <>
    <Field component={CheckboxField} name={`${prefix}.checkbox`} textId={textId} disabled={disabled} />
    {active && (
      <>
        <Field
          component={ReduxSelectField}
          name={`${prefix}.frequency`}
          textId="freqOfTransSelect"
          options={generateOptionsFromTranslations([
            'kycFreqDaily',
            'kycFreqWeekly',
            'kycFreqMonthly',
            'kycFreqYearly',
          ])}
          disabled={disabled}
          validate={[required()]}
        />
        <Field
          component={ReduxSelectField}
          name={`${prefix}.training`}
          textId="trainingRecieved"
          options={generateOptionsFromTranslations([
            'kycTrainSeminar',
            'kycTrainCourse',
            'kycTrainSelf',
            'kycTrainDegree',
          ])}
          disabled={disabled}
          validate={[required()]}
        />
        <Field
          component={ReduxSelectField}
          name={`${prefix}.volume`}
          textId="avgVolumeOfTransPerYear"
          options={generateOptionsFromTranslations([
            'kycVolumeBelow10',
            'kycVolume10to50',
            'kycVolume50to100',
            'kycVolume100to250',
            'kycVolumeOver250',
          ])}
          disabled={disabled}
          validate={[required()]}
        />
        <Field
          component={ReduxSelectField}
          name={`${prefix}.years`}
          textId="yearsOfExp"
          options={generateOptionsFromTranslations([
            'kycDurationBelow1Year',
            'kycDuration1to5Years',
            'kycDurationOver5Years',
          ])}
          disabled={disabled}
          validate={[required()]}
        />
      </>
    )}
  </>
);

ExperienceFields.propTypes = {
  prefix: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  generateOptionsFromTranslations: PropTypes.func.isRequired,
};

export default ExperienceFields;
