import FileDownload from 'js-file-download';
import Raven from 'raven-js';
import { put, call, fork, take, takeEvery } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../_api';
import * as ActionTypes from './actions';

// error handler
function* errorHandlerSaga({ error }) {
  Raven.captureException(error);
  yield 1;
}

function* errorHandlerWatcherSaga() {
  yield takeEvery(ActionTypes.ERROR_ACCEPT, errorHandlerSaga);
}

// partner reports
export function* executeReportDataSaga(data) {
  try {
    yield put(ActionTypes.executeReportData.success(data));
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.executeReportData.failure(e));
  }
}
export function* executeReportDataWatcherSaga(data) {
  while (true) {
    yield take(ActionTypes.EXECUTE_REPORT_DATA[REQUEST]);
    yield call(executeReportDataSaga, data);
  }
}

export function* getReportTemplatesSaga({ isBackOfficePage }) {
  try {
    const { status, data } = yield call(isBackOfficePage ? api.getBackofficeReportTemplates : api.getReportTemplates);
    if (status < 300) {
      yield put(ActionTypes.getReportTemplates.success(data));
    } else {
      yield put(ActionTypes.getReportTemplates.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.getReportTemplates.failure(e));
  }
}
export function* getReportTemplatesWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_REPORT_TEMPLATES[REQUEST]);
    yield call(getReportTemplatesSaga, data);
  }
}

export function* executeReportSaga({ reportType, isBackOfficePage, ...formValues }) {
  try {
    const { status, data } = yield call(
      isBackOfficePage ? api.executeBackofficeReport : api.executeReport,
      reportType,
      formValues
    );
    if (status < 300) {
      yield put(ActionTypes.executeReport.success());
    } else {
      yield put(ActionTypes.executeReport.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.executeReport.failure(e));
  }
}

export function* executeReportWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.executeReport.REQUEST);
    yield call(executeReportSaga, payload);
  }
}

export function* exportReportsSaga(params) {
  const { report, type, ...formValues } = params;
  const fileName = `${report.title}_${formValues.partner}_${formValues.start_end_dates}.${type}`;
  try {
    const { data } = yield call(api.exportReports, report.key, formValues, type);
    FileDownload(data, fileName);
    yield put(ActionTypes.exportReports.success());
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.exportReports.failure());
  }
}
export function* exportReportsWatcherSaga() {
  while (true) {
    const {
      payload: { ...values },
    } = yield take(ActionTypes.exportReports.REQUEST);
    yield call(exportReportsSaga, values);
  }
}

export function* getPeriodicalReportListSaga({ isBackOfficePage, search }) {
  try {
    const { status, data } = yield call(
      isBackOfficePage ? api.getBackofficePeriodicalReportList : api.getPeriodicalReportList,
      search
    );
    if (status < 300) {
      yield put(ActionTypes.getPeriodicalReportList.success(data));
    } else {
      yield put(ActionTypes.getPeriodicalReportList.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.getPeriodicalReportList.failure(e));
  }
}
export function* getPeriodicalReportListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_PERIODICAL_REPORT_LIST[REQUEST]);
    yield call(getPeriodicalReportListSaga, data);
  }
}

export function* createPeriodicalReportSaga({ isBackOfficePage, ...formValues }) {
  try {
    const { status, data } = yield call(
      isBackOfficePage ? api.createBackofficePeriodicalReport : api.createPeriodicalReport,
      formValues
    );
    if (status < 300) {
      yield put(ActionTypes.createPeriodicalReport.success());
      yield call(getPeriodicalReportListSaga, { search: { page_size: 10 } });
    } else {
      yield put(ActionTypes.createPeriodicalReport.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.createPeriodicalReport.failure(e));
  }
}

export function* createPeriodicalReportWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createPeriodicalReport.REQUEST);
    yield call(createPeriodicalReportSaga, payload);
  }
}

export function* getSavedReportsSaga({ isBackOfficePage, search }) {
  try {
    const { status, data } = yield call(isBackOfficePage ? api.getBackofficeSavedReports : api.getSavedReports, search);
    if (status < 300) {
      yield put(ActionTypes.getSavedReports.success(data));
    } else {
      yield put(ActionTypes.getSavedReports.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.getSavedReports.failure(e));
  }
}
export function* getSavedReportsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_SAVED_REPORTS[REQUEST]);
    yield call(getSavedReportsSaga, data);
  }
}

export function* deleteReportSaga({ reportId, reportType, queryParams }) {
  try {
    const { status, data } = yield call(api.deleteReport, reportId, reportType);
    if (status < 300) {
      yield put(ActionTypes.deleteReport.success());
      if (queryParams) {
        if (reportType === 'periodical') {
          yield call(getPeriodicalReportListSaga, queryParams);
        } else {
          yield call(getSavedReportsSaga, queryParams);
        }
      }
    } else {
      yield put(ActionTypes.deleteReport.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.deleteReport.failure(e));
  }
}
export function* deleteReportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DELETE_REPORT[REQUEST]);
    yield call(deleteReportSaga, data);
  }
}

export function* retrieveSavedReportSaga({ report, format, isBackOfficePage }) {
  const formatList = Object.entries(format).reduce((acc, [key, value]) => (value ? [...acc, key] : acc), []);

  try {
    let response;
    for (let i = 0; i < formatList.length; i += 1) {
      const fileName = `${report.name}_${report.updated}.${formatList[i]}`;
      response = yield call(
        isBackOfficePage ? api.retrieveBackofficeSavedReport : api.retrieveSavedReport,
        report.id,
        formatList[i]
      );

      FileDownload(response.data, fileName);
    }
    if (response.status < 300) {
      yield put(ActionTypes.retrieveSavedReport.success());
    } else {
      yield put(ActionTypes.retrieveSavedReport.failure(response.data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.retrieveSavedReport.failure(e));
  }
}

export function* retrieveSavedReportWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.retrieveSavedReport.REQUEST);
    yield call(retrieveSavedReportSaga, payload);
  }
}

export function* retrieveAndShowSavedReportSaga({ report, isBackOfficePage }) {
  try {
    const { data, status } = yield call(
      isBackOfficePage ? api.retrieveAndShowBackofficeSavedReport : api.retrieveAndShowSavedReport,
      report.id
    );

    if (status < 300) {
      yield put(ActionTypes.retrieveAndShowSavedReport.success(data));
    } else {
      yield put(ActionTypes.retrieveAndShowSavedReport.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.retrieveAndShowSavedReport.failure(e));
  }
}

export function* retrieveAndShowSavedReportWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.RETRIEVE_AND_SHOW_SAVED_REPORT[REQUEST]);
    yield call(retrieveAndShowSavedReportSaga, payload);
  }
}

export function* getBackofficeUserExportsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getBackofficeUserExports, search);
    if (status < 300) {
      yield put(ActionTypes.getBackofficeUserExports.success(data));
    } else {
      yield put(ActionTypes.getBackofficeUserExports.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.getBackofficeUserExports.failure(e));
  }
}
export function* getBackofficeUserExportsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_BO_USER_EXPORTS[REQUEST]);
    yield call(getBackofficeUserExportsSaga, data);
  }
}

export function* createBackofficeUserExportsSaga({ timePeriod }) {
  try {
    const { status, data } = yield call(api.createBackofficeUserExports, timePeriod);
    if (status < 300) {
      yield put(ActionTypes.createBackofficeUserExports.success(data));
    } else if (status === 409) {
      yield put(ActionTypes.setCreateError());
      yield put(ActionTypes.createBackofficeUserExports.failure());
    } else {
      yield put(ActionTypes.createBackofficeUserExports.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.createBackofficeUserExports.failure(e));
  }
}
export function* createBackofficeUserExportsWatcherSaga() {
  while (true) {
    const { timePeriod } = yield take(ActionTypes.CREATE_BO_USER_EXPORTS[REQUEST]);
    yield call(createBackofficeUserExportsSaga, { timePeriod });
  }
}

export function* downloadBackofficeUserExportSaga({ id, name }) {
  try {
    const { status, data } = yield call(api.downloadBackofficeUserExport, id, name);
    if (status < 300) {
      yield put(ActionTypes.downloadBackofficeUserExport.success(data));
      FileDownload(data, name);
    } else {
      yield put(ActionTypes.downloadBackofficeUserExport.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.downloadBackofficeUserExport.failure(e));
  }
}
export function* downloadBackofficeUserExportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DOWNLOAD_BO_USER_EXPORT[REQUEST]);
    yield call(downloadBackofficeUserExportSaga, data);
  }
}

// Invest NEW Exports

export function* getInvestNewExportsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInvestNewExports, search);
    if (status < 300) {
      yield put(ActionTypes.getInvestNewExports.success(data));
    } else {
      yield put(ActionTypes.getInvestNewExports.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.getInvestNewExports.failure(e));
  }
}
export function* getInvestNewExportsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INVEST_NEW_EXPORTS[REQUEST]);
    yield call(getInvestNewExportsSaga, data);
  }
}

export function* createInvestNewExportsSaga({ timePeriod, strategyId, investmentId, ext }) {
  try {
    const { status, data } = yield call(api.createInvestNewExports, { timePeriod, strategyId, investmentId, ext });
    if (status < 300) {
      yield put(ActionTypes.createInvestNewExports.success(data));
      if (window.location.pathname.includes('backoffice')) {
        window.open('/backoffice/invest_crm/exports', '_blank');
      } else {
        window.open('/exports/invest_crm', '_blank');
      }
    } else if (status === 409) {
      yield put(ActionTypes.setCreateError());
      yield put(ActionTypes.createInvestNewExports.failure());
    } else {
      yield put(ActionTypes.createInvestNewExports.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.createInvestNewExports.failure(e));
  }
}
export function* createInvestNewExportsWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.CREATE_INVEST_NEW_EXPORTS[REQUEST]);

    console.log({ payload });

    const { timePeriod, strategyId, investmentId, ext } = payload;
    yield call(createInvestNewExportsSaga, { timePeriod, strategyId, investmentId, ext });
  }
}

export function* downloadInvestNewExportSaga({ id, name }) {
  try {
    const { status, data } = yield call(api.downloadInvestNewExport, id, name);
    if (status < 300) {
      yield put(ActionTypes.downloadInvestNewExport.success(data));
      FileDownload(data, name);
    } else {
      yield put(ActionTypes.downloadInvestNewExport.failure(data.detail));
    }
  } catch (e) {
    yield put(ActionTypes.acceptError(e, true));
    yield put(ActionTypes.downloadInvestNewExport.failure(e));
  }
}
export function* downloadInvestNewExportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DOWNLOAD_INVEST_NEW_EXPORT[REQUEST]);
    yield call(downloadInvestNewExportSaga, data);
  }
}

export default [
  fork(errorHandlerWatcherSaga),
  fork(executeReportDataWatcherSaga),
  fork(getReportTemplatesWatcherSaga),
  fork(executeReportWatcherSaga),
  fork(exportReportsWatcherSaga),
  fork(createPeriodicalReportWatcherSaga),
  fork(getPeriodicalReportListWatcherSaga),
  fork(deleteReportWatcherSaga),
  fork(getSavedReportsWatcherSaga),
  fork(retrieveSavedReportWatcherSaga),
  fork(retrieveAndShowSavedReportWatcherSaga),
  fork(getBackofficeUserExportsWatcherSaga),
  fork(createBackofficeUserExportsWatcherSaga),
  fork(downloadBackofficeUserExportWatcherSaga),
  fork(getInvestNewExportsWatcherSaga),
  fork(createInvestNewExportsWatcherSaga),
  fork(downloadInvestNewExportWatcherSaga),
];
