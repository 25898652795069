import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getTemplateListSaga({ search }) {
  try {
    const { data } = yield call(api.getTemplateList, search);
    yield put(ActionTypes.getTemplateList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getTemplateList.failure(e));
  }
}

export function* getTemplateListWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_TEMPLATE_LIST[REQUEST]);
    yield call(getTemplateListSaga, search);
  }
}

export function* createTemplateSaga(payload) {
  try {
    const { status, data } = yield call(api.createTemplate, payload);
    if (status < 300) {
      yield put(ActionTypes.createTemplate.success(data));
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.createTemplate.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.createTemplate.failure());
  }
}

export function* createTemplateeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createTemplate.REQUEST);
    yield call(createTemplateSaga, payload);
  }
}

export function* deleteTemplateSaga({ id, queryParams }) {
  try {
    yield call(api.deleteTemplate, id);
    yield put(ActionTypes.deleteTemplate.success());
    if (queryParams) {
      yield call(getTemplateListSaga, queryParams);
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteTemplate.failure(e));
  }
}

export function* deleteTemplateWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DELETE_TEMPLATE[REQUEST]);
    yield call(deleteTemplateSaga, data);
  }
}

export function* getTemplateItemSaga(id) {
  try {
    const { data } = yield call(api.getTemplateItem, id);
    yield put(ActionTypes.getTemplateItem.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getTemplateItem.failure(e));
  }
}

export function* getTemplateItemWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_TEMPLATE_ITEM[REQUEST]);
    yield call(getTemplateItemSaga, id);
  }
}

export function* editTemplateSaga({ id, ...values }) {
  try {
    const { status, data } = yield call(api.editTemplate, id, values);
    if (status < 300) {
      yield put(ActionTypes.editTemplate.success(data));
    } else {
      const error = data.detail ? data.detail : data;
      yield put(ActionTypes.editTemplate.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editTemplate.failure());
  }
}

export function* editTemplateWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editTemplate.REQUEST);
    yield call(editTemplateSaga, payload);
  }
}

export default [
  fork(getTemplateListWatcherSaga),
  fork(createTemplateeWatcherSaga),
  fork(deleteTemplateWatcherSaga),
  fork(getTemplateItemWatcherSaga),
  fork(editTemplateWatcherSaga),
];
