/* eslint-disable camelcase */
import { put, call, fork, take } from 'redux-saga/effects';
import axios from 'axios';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getDynamicResourceListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getDynamicResourceList, search);
    if (status < 300) {
      yield put(ActionTypes.getDynamicResourceList.success(data));
    } else {
      yield put(ActionTypes.getDynamicResourceList.failure(data.error));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDynamicResourceList.failure());
    throw e;
  }
}
export function* getDynamicResourceListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_DYNAMIC_RESOURCE_LIST[REQUEST]);
    yield call(getDynamicResourceListSaga, data);
  }
}

const ifAccountTypeCreatedFunc = (filterQuery, accountTypeId) =>
  axios.put(`/platforms/account_type/settings/${accountTypeId}/filter`, {
    filterQuery,
  });

const removeAccountType = accountTypeId =>
  axios.delete(`/config/resource/PlatformAccountTypeResource/resource/${accountTypeId}/`);

const getLastAccountTypeId = async title => {
  const { data: newResourse } = await axios.get('/config/resource/PlatformAccountTypeResource/resource/');
  newResourse.sort((a, b) => {
    const idA = parseInt(a.id, 10);
    const idB = parseInt(b.id, 10);

    return idA - idB;
  });
  for (let i = newResourse.length - 1; i >= 0; i -= 1) {
    if (newResourse[i].title === title) {
      return newResourse[i].id;
    }
  }
};
export function* createDynamicResourceSaga({ slug, data: formData }) {
  try {
    const isCreateAccountTypePage =
      window.location.pathname === '/admin/PlatformsCategory/PlatformAccountTypeResource/create';
    let filterQuery = null;
    if (isCreateAccountTypePage) {
      filterQuery = formData.filterQuery;
      const perem = 'filterQuery';
      delete formData[perem];
    }

    const { status, data } = yield call(api.createDynamicResource, slug, formData);

    if (status < 300) {
      if (isCreateAccountTypePage) {
        const lastAccountTypeId = yield call(getLastAccountTypeId, formData.title);

        const updateFilterResult = yield call(ifAccountTypeCreatedFunc, filterQuery, lastAccountTypeId);

        if (updateFilterResult.status < 300) {
          yield put(ActionTypes.createDynamicResource.success(data));
        } else {
          yield call(removeAccountType, lastAccountTypeId);

          const error = updateFilterResult?.data?.details
            ? updateFilterResult?.data?.details
            : updateFilterResult?.data?.detail;
          yield put(ActionTypes.createDynamicResource.failure(new SubmissionError(error)));
        }
      } else {
        yield put(ActionTypes.createDynamicResource.success(data));
      }
    } else {
      const error = data.details ? data.details : data.detail;
      yield put(ActionTypes.createDynamicResource.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createDynamicResource.failure(e));
  }
}
export function* createDynamicResourceWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createDynamicResource.REQUEST);
    yield call(createDynamicResourceSaga, payload);
  }
}

const regexForAccoutTypeEdit = /^\/admin\/PlatformsCategory\/PlatformAccountTypeResource\/\d+$/;

const ifAccountTypeUpdateFunc = async (accountTypeId, filterQuery) =>
  axios.put(`/platforms/account_type/settings/${accountTypeId}/filter`, {
    filterQuery,
  });

export function* saveDynamicResourceSaga({ slug, id, data: formData }) {
  try {
    const isUpdateAccountTypePage = regexForAccoutTypeEdit.test(window.location.pathname);
    let filterQuery = null;
    if (isUpdateAccountTypePage) {
      filterQuery = formData.filterQuery;
      const perem = 'filterQuery';
      delete formData[perem];
    }
    const { status, data } = yield call(api.saveDynamicResource, slug, id, formData);
    if (status < 300) {
      if (isUpdateAccountTypePage) {
        const updateFilterResult = yield call(ifAccountTypeUpdateFunc, id, filterQuery);
        if (updateFilterResult.status < 300) {
          yield put(ActionTypes.saveDynamicResource.success(data));
        } else {
          const error = updateFilterResult?.data?.details
            ? updateFilterResult?.data?.details
            : updateFilterResult?.data?.detail;
          yield put(ActionTypes.saveDynamicResource.failure(new SubmissionError(error)));
        }
      } else {
        yield put(ActionTypes.saveDynamicResource.success(data));
      }
    } else {
      const error = data.details ? data.details : data.detail;
      yield put(ActionTypes.saveDynamicResource.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.saveDynamicResource.failure(e));
  }
}
export function* saveDynamicResourceWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.saveDynamicResource.REQUEST);
    yield call(saveDynamicResourceSaga, payload);
  }
}

export function* saveDynamicSettingsSaga({ slug, data: formData }) {
  try {
    const { status, data } = yield call(api.saveDynamicSetting, slug, formData);
    if (status < 300) {
      yield put(ActionTypes.saveDynamicSetting.success(data));
    } else {
      const error = data.details ? data.details : data.detail;
      yield put(ActionTypes.saveDynamicSetting.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.saveDynamicSetting.failure(e));
  }
}
export function* saveDynamicSettingWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.saveDynamicSetting.REQUEST);
    yield call(saveDynamicSettingsSaga, payload);
  }
}

export default [
  fork(getDynamicResourceListWatcherSaga),
  fork(createDynamicResourceWatcherSaga),
  fork(saveDynamicResourceWatcherSaga),
  fork(saveDynamicSettingWatcherSaga),
];
