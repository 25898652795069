import css from 'styled-jsx/css';
import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Bonus {
    margin-bottom: 30px;
    padding: 30px;

    &__form {
      display: flex;
      justify-content: space-between;
    }

    &__logo {
      display: block;
      margin-right: ${isRTL ? '0' : '25px'};
      margin-left: ${isRTL ? '25px' : '0'};
      width: 60px;
      height: 60px;

      @include respond-to(md) {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    &__heading {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__content {
      width: 100%;
      padding-left: 85px;

      @include respond-to(md) {
        padding-left: 0;
      }
    }

    &__title {
      font-size: 20px;
      color: #000000;
    }

    &__modal {
      width: 100%;
      max-width: 600px;
      white-space: break-spaces;
    }

    &__fields {
      display: flex;

      :global(.DateField__inner) {
        margin-bottom: 0;
        margin-left: 20px;
      }
      @include respond-to(md) {
        margin-top: 10px;
      }
    }

    &__agree {
      display: flex;
      align-items: center;
      cursor: pointer;

      :global(.Checkbox) {
        margin-right: 10px;
      }
    }
  }

  .Bonus {
    @include respond-to(sm) {
      padding: 12px 20px 20px 20px;
      margin: 0 0 20px 0;
    }

    &__logo {
      @include respond-to(sm) {
        margin: 0 20px 0 0;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 23px;

      @include respond-to(sm) {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }

    &__text {
      @include respond-to(sm) {
        margin: 0 0 10px 0 !important;
      }
    }

    &__list {
      @include respond-to(sm) {
        margin: 0 0 -10px 0;
      }
    }

    &__form {
      @include respond-to(sm) {
        margin: 16px 0 0 0 !important;
      }
    }

    &__btns {
      @include respond-to(xs) {
        width: 100%;
      }

      button {
        @include respond-to(xs) {
          width: calc(50% - 10px);
        }

        &:nth-child(1) {
          @include respond-to(xs) {
            margin: 0 20px 0 0 !important;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .Bonus {
      background-color: ${colors.white.hex};
      box-shadow: 0px 1px 6px ${rgba([colors.black.rgb, 0.06])};
      border-radius: 3px;

      &__text {
        color: ${colors.gray.hex};
        margin-bottom: 20px;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
      }

      &__item {
        position: relative;
        padding-left: ${isRTL ? '0' : '20px'};
        padding-right: ${isRTL ? '20px' : '0'};
        flex: 50%;
        margin-bottom: 10px;
        color: ${colors.gray.hex};

        &::before {
          content: '';
          position: absolute;
          left: ${isRTL ? 'auto' : '0'};
          right: ${isRTL ? '0' : 'auto'};
          top: 6px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: ${colors.primary.hex};
        }
      }

      &__form {
        margin-top: 20px;
        display: flex;
        align-items: center;

        @include respond-to(md) {
          flex-wrap: wrap;
        }

        .DefaultButton {
          @include respond-to(xs) {
            flex: 1;
          }

          &:first-child {
            margin-right: ${isRTL ? '0' : '20px'};
            margin-left: ${isRTL ? '20px' : '0'};
          }
        }
      }

      &__select {
        margin-right: ${isRTL ? 'auto' : '0'};
        margin-left: ${isRTL ? '0' : 'auto'};
        width: 300px;

        @include respond-to(md) {
          order: -1;
          width: 100%;
        }

        :global(.ObjectField_items) {
          padding: 0 !important;
          margin: 0 !important;
          box-shadow: none !important;

          .BaseSelectField {
            margin-bottom: 0;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
