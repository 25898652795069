import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const AuthSlider = ({ slides, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const settings = {
    dots: true,
    customPaging: () => <button type="button" />,
    dotsClass: 'slick-dots',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    draggable: false,
  };

  return (
    <div className="AuthSlider">
      <Slider className="AuthSlider__items" {...settings}>
        {!!slides.length &&
          slides.map((slide, index) => (
            <div className="AuthSlider__item" key={index}>
              <img className="AuthSlider__item-image" src={slide.image} alt="background" loading="lazy" />
            </div>
          ))}
      </Slider>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

AuthSlider.propTypes = {
  slides: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

AuthSlider.defaultProps = {
  theme: {},
};

export default withTheme()(AuthSlider);
