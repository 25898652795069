/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import { PageContentPreloader, DateCell, StatusCell } from 'common/components';
import { BaseButton } from 'crm/_components';

import { NewCRMPagination, UpdatedCRMTable } from '../../../_newComponents';
import { downloadContactsExport, getContactsExports } from '../../_redux';

import { staticStyles } from './style';

const STATUSES = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
};
const ReportsTable = ({
  contactsExportData,
  contactsExportDataIsLoaded,
  getContactsExports,
  downloadContactsExport,
  location,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getContactsExports({ search: query });
  }, [query]);

  const downloadExport = (id, name) => {
    downloadContactsExport({ id, name });
  };
  const tableSetup = {
    columns: [
      {
        accessor: 'id',
        Header: 'justId',
        isSortable: true,
        Cell: ({ rowData: { id } }) => id,
      },
      {
        accessor: 'name',
        isSortable: true,
        Header: 'justName',
        Cell: ({ rowData: { name } }) => name,
      },

      {
        accessor: 'createdBy',
        isSortable: true,
        Header: 'crmWasCreatedBy',
        removeInMobile: true,
        Cell: ({ rowData: { createdBy } }) => createdBy,
      },
      {
        accessor: 'creationDate',
        isSortable: true,
        Header: 'crmCreationDate',
        removeInMobile: true,
        Cell: ({ rowData: { created } }) => (created ? <DateCell value={created} /> : '---'),
      },
      {
        accessor: 'justStatus',
        Header: 'justStatus',
        Cell: ({ rowData: { id, name, status } }) =>
          status === STATUSES.SUCCESS ? (
            <BaseButton textId="justDownload" type="button" onClick={() => downloadExport(id, name)} />
          ) : (
            <StatusCell statusCode={status} />
          ),
      },
    ],
    initSubRow: [],
  };
  /* eslint-enable */

  return (
    <div className="ReportsTable">
      <PageContentPreloader ready={contactsExportDataIsLoaded} firstLaunchOnly type="accounts">
        <UpdatedCRMTable data={contactsExportData?.results} tableSetup={tableSetup} whereOpenSubRowIconIndex={0} />
        {!!contactsExportData?.count && <NewCRMPagination count={contactsExportData?.count} />}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ReportsTable.propTypes = {
  contactsExportData: PropTypes.object.isRequired,
  contactsExportDataIsLoaded: PropTypes.bool.isRequired,
  getContactsExports: PropTypes.func.isRequired,
  downloadContactsExport: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      contactsExportData: state.crm.contacts.contactsExportData,
      contactsExportDataIsLoaded: state.crm.contacts.contactsExportDataIsLoaded,
    }),
    {
      getContactsExports: search => getContactsExports.request(search),
      downloadContactsExport: data => downloadContactsExport.request(data),
    }
  )
)(ReportsTable);
