import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  categories: {},
  categoriesAreLoaded: false,

  questions: {},
  questionsAreLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BO_CATEGORIES[REQUEST]:
      return { ...state, categoriesAreLoaded: false };
    case ActionTypes.GET_BO_CATEGORIES[SUCCESS]:
      return { ...state, categories: action.data, categoriesAreLoaded: true };
    case ActionTypes.GET_BO_CATEGORIES[FAILURE]:
      return { ...state, error: action.error, categoriesAreLoaded: false };

    case ActionTypes.GET_BO_QUESTIONS[REQUEST]:
      return { ...state, questionsAreLoaded: false };
    case ActionTypes.GET_BO_QUESTIONS[SUCCESS]:
      return { ...state, questions: action.data, questionsAreLoaded: true };
    case ActionTypes.GET_BO_QUESTIONS[FAILURE]:
      return { ...state, error: action.error, questionsAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
