import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { compose } from 'redux';
import PropTypes from 'prop-types';

import { getAllMyCrmTasks } from 'crm/tasks/_redux';
import TasksTable from '../TasksTable';

import { staticStyles } from './style';
import InnerTabs from '../InnerTabs';

const MyTasksWrapper = ({
  userId,
  allMyOpenTasks,
  allMyClosedTasks,
  allMyTasksAreLoaded,
  getAllMyCrmTasks,
  location,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const [currentInnerTab, setCurrentInnerTab] = useState('open');

  const currentlySelectedTasks = useMemo(
    () => (currentInnerTab === 'open' ? allMyOpenTasks : allMyClosedTasks),
    [currentInnerTab, allMyOpenTasks, allMyClosedTasks]
  );

  useEffect(() => {
    getAllMyCrmTasks({ search: query, managerId: userId });
  }, [query]);

  return (
    <div className="MyTasksWrapper">
      <InnerTabs
        currentTab={currentInnerTab}
        setCurrentInnerTab={setCurrentInnerTab}
        openCount={allMyOpenTasks?.count}
        closedCount={allMyClosedTasks?.count}
      />
      <TasksTable tasks={currentlySelectedTasks} tasksAreLoaded={allMyTasksAreLoaded} />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

MyTasksWrapper.propTypes = {
  allMyOpenTasks: PropTypes.object.isRequired,
  allMyClosedTasks: PropTypes.object.isRequired,
  getAllMyCrmTasks: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      allMyOpenTasks: state.crm.tasks.allMyOpenTasks,
      allMyClosedTasks: state.crm.tasks.allMyClosedTasks,
      allMyTasksAreLoaded: state.crm.tasks.allMyTasksAreLoaded,
      userId: state.user?.profile?.id,
    }),
    {
      getAllMyCrmTasks: search => getAllMyCrmTasks.request(search),
    }
  )
)(MyTasksWrapper);
