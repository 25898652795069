import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const BO_GET_INVEST_CRM_STRATEGIES = createRequestTypes('BO_GET_INVEST_CRM_STRATEGIES');
export const boGetInvestCRMStrategies = {
  request: search => action(BO_GET_INVEST_CRM_STRATEGIES[REQUEST], { search }),
  success: data => action(BO_GET_INVEST_CRM_STRATEGIES[SUCCESS], { data }),
  failure: error => action(BO_GET_INVEST_CRM_STRATEGIES[FAILURE], { error }),
};

export const BO_GET_INVEST_CRM_STRATEGY_INVESTORS = createRequestTypes('BO_GET_INVEST_CRM_STRATEGY_INVESTORS');
export const boGetInvestCRMStrategyInvestors = {
  request: (strategyId, search) => action(BO_GET_INVEST_CRM_STRATEGY_INVESTORS[REQUEST], { strategyId, search }),
  success: data => action(BO_GET_INVEST_CRM_STRATEGY_INVESTORS[SUCCESS], { data }),
  failure: error => action(BO_GET_INVEST_CRM_STRATEGY_INVESTORS[FAILURE], { error }),
};

export const BO_TOGGLE_INVEST_CRM_STRATEGY = createRequestTypes('BO_TOGGLE_INVEST_CRM_STRATEGY');
export const boToggleInvestCRMStrategy = {
  request: (strategyId, strategyAction, search) =>
    action(BO_TOGGLE_INVEST_CRM_STRATEGY[REQUEST], { strategyId, strategyAction, search }),
  success: () => action(BO_TOGGLE_INVEST_CRM_STRATEGY[SUCCESS]),
  failure: error => action(BO_TOGGLE_INVEST_CRM_STRATEGY[FAILURE], { error }),
};

export const BO_TOGGLE_CRM_INVESTMENT = createRequestTypes('BO_TOGGLE_CRM_INVESTMENT');
export const boToggleCRMInvestment = {
  request: (investmentId, investmentAction, strategyId, search, account) =>
    action(BO_TOGGLE_CRM_INVESTMENT[REQUEST], {
      investmentId,
      investmentAction,
      strategyId,
      search,
      account,
    }),
  success: () => action(BO_TOGGLE_CRM_INVESTMENT[SUCCESS]),
  failure: error => action(BO_TOGGLE_CRM_INVESTMENT[FAILURE], { error }),
};

export const editInvestCRMStrategy = createFormAction('BO_EDIT_INVEST_CRM_STRATEGY');
