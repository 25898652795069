import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { staticStyles } from './style';

const BaseSelect = ({
  labelTextId,
  isDisabled,
  isSearchable,
  options,
  name,
  onChange,
  classNamePrefix,
  input,
  placeholder,
}) => {
  const customStyles = {
    control: base => ({
      ...base,
      height: 44,
      minHeight: 44,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '4px 8px',
    }),
  };

  return (
    <div className="BaseSelect">
      {labelTextId && (
        <span className="BaseSelect__label">
          <FM id={labelTextId} />
        </span>
      )}

      <Select
        className="basic-single"
        placeholder={placeholder || <FM id="justActions" />}
        classNamePrefix={classNamePrefix}
        value={input?.value}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        maxMenuHeight={150}
        name={name}
        options={options}
        onChange={onChange}
        styles={customStyles}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

BaseSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  labelTextId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  classNamePrefix: PropTypes.string,
};

BaseSelect.defaultProps = {
  isDisabled: false,
  isSearchable: false,
  classNamePrefix: 'select',
  labelTextId: '',
};

export default BaseSelect;
