import React from 'react';

const MonitoringIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      d="M3.5 0.5C3.225 0.5 3 0.725 3 1C3 1.275 3.225 1.5 3.5 1.5H5.5C5.775 1.5 6 1.275 6 1C6 0.725 5.775 0.5 5.5 0.5H3.5ZM10.5 0.5C10.225 0.5 10 0.725 10 1C10 1.275 10.225 1.5 10.5 1.5H12.5C12.775 1.5 13 1.275 13 1C13 0.725 12.775 0.5 12.5 0.5H10.5ZM6 13C6 13.275 5.775 13.5 5.5 13.5H1.5C1.225 13.5 1 13.275 1 13V10.6531C1 9.75313 1.24375 8.86875 1.70938 8.09375C2.20937 7.2625 2.52813 6.33437 2.65 5.37187L2.82812 3.9375C2.85938 3.6875 3.07188 3.5 3.325 3.5H6V4V8V13ZM7 13V8.5H9V13C9 13.8281 9.67188 14.5 10.5 14.5H14.5C15.3281 14.5 16 13.8281 16 13V10.6531C16 9.57187 15.7062 8.50938 15.15 7.58125C14.7219 6.86875 14.4469 6.075 14.3438 5.25L14.1656 3.8125C14.0719 3.0625 13.4344 2.5 12.6781 2.5H9.75C9.33438 2.5 9 2.83437 9 3.25V3.5H7V3.25C7 2.83437 6.66563 2.5 6.25 2.5H3.325C2.56875 2.5 1.93125 3.0625 1.8375 3.8125L1.65625 5.25C1.55313 6.075 1.27813 6.86875 0.85 7.58125C0.29375 8.50938 0 9.57187 0 10.6531V13C0 13.8281 0.671875 14.5 1.5 14.5H5.5C6.32812 14.5 7 13.8281 7 13ZM7 7.5V4.5H9V7.5H7ZM10 8V4V3.5H12.675C12.9281 3.5 13.1406 3.6875 13.1719 3.9375L13.35 5.37187C13.4719 6.33437 13.7906 7.2625 14.2906 8.09375C14.7531 8.86563 15 9.75 15 10.6531V13C15 13.275 14.775 13.5 14.5 13.5H10.5C10.225 13.5 10 13.275 10 13V8Z"
      fill="#333333"
    />
  </svg>
);

export default MonitoringIcon;
