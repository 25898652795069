import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserCard {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    padding: rem(25px 0);
    @include respond-to(sm) {
      padding: rem(20px 0);
    }

    .UserCard__caption {
      margin-bottom: rem(20px);
    }

    .UserCard__line {
      display: flex;
      width: 100%;
      margin: rem(10px 0);

      span {
        &:first-child {
          flex: 1;
          margin-right: rem(20px);
        }

        &:nth-child(2) {
          flex: 2;
        }
      }

      :global(.DefaultButton) {
        span {
          margin-right: 0;
        }
        &:first-child {
          margin-right: rem(20px);
        }
      }

      :global(.StatusCell) {
        font-size: rem(14px);
      }

      .UserCard__status {
        flex: 2;
      }
    }

    .UserCard__images {
      display: flex;
      width: 100%;
      margin-bottom: rem(20px);
    }

    :global(.UserCard__lightBoxImage) {
      margin: 0 auto;
      width: auto;
      height: 105px;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        max-width: 100%;
        margin: 0 !important;
        max-height: 105px;
        height: auto !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserCard {
      background-color: ${colors.white.hex};

      .UserCard__field-title {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
