import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ResetPasswordPage {
    width: 100%;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0c0c3d;

    @include respond-to(xs) {
      justify-content: flex-end;
      padding: 0;
    }

    &__desc {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
    }

    &__inner {
      position: relative;
      padding: 24px;
      max-width: 370px;
      background: white;

      @include respond-to(xs) {
        width: 100%;
        max-width: 100%;
      }

      > span {
        margin-bottom: 32px;
      }
    }

    .innerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      &__resetText {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &__background-image {
      position: absolute;
      height: 100%;
      width: 100%;
      @include respond-to(sm) {
        width: auto;
      }
    }
  }
`;

export { staticStyles };
