import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  statuses: [],
  statusesAreLoaded: false,

  statusInfo: {},
  statusInfoIsLoaded: false,

  statusDescription: {},
  statusDescriptionIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_STATUSES_LIST[REQUEST]:
      return { ...state, statusesAreLoaded: false };
    case ActionTypes.GET_STATUSES_LIST[SUCCESS]:
      return {
        ...state,
        statuses: action.data,
        statusesAreLoaded: true,
      };
    case ActionTypes.GET_STATUSES_LIST[FAILURE]:
      return {
        ...state,
        error: action.error,
        statusesAreLoaded: false,
      };

    case ActionTypes.GET_STATUS_INFO[REQUEST]:
      return { ...state, statusInfoIsLoaded: false };
    case ActionTypes.GET_STATUS_INFO[SUCCESS]:
      return {
        ...state,
        statusInfo: action.data,
        statusInfoIsLoaded: true,
      };
    case ActionTypes.GET_STATUS_INFO[FAILURE]:
      return {
        ...state,
        error: action.error,
        statusInfoIsLoaded: false,
      };

    case ActionTypes.GET_STATUS_DESCRIPTION[REQUEST]:
      return { ...state, statusDescriptionIsLoaded: false };
    case ActionTypes.GET_STATUS_DESCRIPTION[SUCCESS]:
      return {
        ...state,
        statusDescription: action.data,
        statusDescriptionIsLoaded: true,
      };
    case ActionTypes.GET_STATUS_DESCRIPTION[FAILURE]:
      return {
        ...state,
        error: action.error,
        statusDescriptionIsLoaded: false,
      };

    default:
      return state;
  }
};

export default reducer;
