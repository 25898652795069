import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { getPlan } from 'backoffice/managers/_redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TaskIcon } from '../../TopMenu/icons';
import TaskItem from '../TaskItem';
import { staticStyles, getDynamicStyles } from './style';

const TasksInfo = ({ plans, plansAreLoaded, getPlan, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  useEffect(() => {
    getPlan();
  }, [getPlan]);

  const todayTasks = useMemo(() => {
    if (plansAreLoaded) {
      return plans.today.slice(-5);
    }
    return [];
  }, [plans, plansAreLoaded]);

  return (
    <div className="TasksInfo__inner">
      <TaskIcon />

      <div className="TasksInfo__menu">
        {todayTasks.length ? (
          <ul>
            {todayTasks.map(task => (
              <TaskItem key={task.id} task={task} />
            ))}
          </ul>
        ) : (
          <FormattedMessage id="notTasksForToday">
            {txt => <span className="TaskInfo__noTasks">{txt}</span>}
          </FormattedMessage>
        )}

        <Link to="/backoffice/tasks/" className="TasksInfo__link">
          <FormattedMessage id="justAllTasks" />
        </Link>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TasksInfo.propTypes = {
  plans: PropTypes.object.isRequired,
  plansAreLoaded: PropTypes.bool.isRequired,
  getPlan: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

TasksInfo.defaultProps = {
  theme: {},
};

export default withTheme()(
  connect(
    state => ({
      plans: state.backoffice.managers.plans,
      plansAreLoaded: state.backoffice.managers.plansAreLoaded,
    }),
    {
      getPlan: () => getPlan.request(),
    }
  )(TasksInfo)
);

export { TasksInfo };
