import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getReportTemplates, getPeriodicalReportList, deleteReport } from 'common/_redux';
import { getPartnerAccounts } from 'client/_redux';
import { PageContentPreloader, NoItemsPanel } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noReportImage from 'common/images/noFilesDownload.svg';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { PeriodicallyReportForm, PeriodicalReportTable } from '../../_components';
import { staticStyles } from './style';

interface MatchParams {
  name: string;
}

interface IReportTemplate {
  title?: string;
  slug?: string;
  key?: string;
  form?: any;
}

interface IPartnerAccount {
  id: string;
  partnerCode: string;
}

interface IPeriodicallyReportsPageProps extends RouteComponentProps<MatchParams> {
  getReportTemplates: any;
  getPartnerAccounts: any;
  getPeriodicalReportList: any;
  deleteReport: any;
  reportTemplates: Array<IReportTemplate>;
  partnerAccounts: Array<IPartnerAccount>;
  reportsTemplatesAreLoaded: boolean;
  partnerAccountsIsLoaded: boolean;
  periodicalReports: any;
  periodicalReportsAreLoaded: boolean;
}

const PeriodicallyReportsPage: React.FC<IPeriodicallyReportsPageProps> = ({
  match,
  location,
  history,
  reportTemplates,
  reportsTemplatesAreLoaded,
  periodicalReports,
  periodicalReportsAreLoaded,
  partnerAccounts,
  partnerAccountsIsLoaded,
  getReportTemplates,
  getPartnerAccounts,
  getPeriodicalReportList,
  deleteReport,
}: IPeriodicallyReportsPageProps) => {
  const isBackOfficePage = match.url.includes('backoffice');
  const query = { page_size: 10, ...queryString.parse(location.search) };

  useEffect(() => {
    getReportTemplates({ isBackOfficePage });
  }, [getReportTemplates, isBackOfficePage]);

  /* eslint-disable */
  useEffect(() => {
    getPeriodicalReportList({ search: query, isBackOfficePage });
  }, [getPeriodicalReportList]);
  /* eslint-enable */

  useEffect(() => {
    if (!isBackOfficePage) {
      getPartnerAccounts();
    }
  }, [getPartnerAccounts, isBackOfficePage]);

  const availableReports = isBackOfficePage
    ? reportTemplates
    : reportTemplates.filter((item: IReportTemplate) => item.slug !== 'full_company');

  return (
    <PageContentPreloader
      ready={reportsTemplatesAreLoaded && (isBackOfficePage || partnerAccountsIsLoaded)}
      firstLaunchOnly
      type="bigBlock">
      {!!availableReports.length && (
        <>
          <PeriodicallyReportForm availableReports={availableReports} partnerAccounts={partnerAccounts} />
          <FormattedMessage id="myPeriodicalReport">
            {txt => <div className="PeriodicallyReportsPage__caption">{txt}</div>}
          </FormattedMessage>
        </>
      )}
      {periodicalReports.count ? (
        <PeriodicalReportTable
          data={periodicalReports}
          loading={!periodicalReportsAreLoaded}
          location={location}
          history={history}
          getList={getPeriodicalReportList}
          deleteReport={deleteReport}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRowClick={() => {}}
          params={{ search: query, isBackOfficePage }}
        />
      ) : (
        <NoItemsPanel icon={noReportImage} captionSlug="noPeriodicalReportsYet" />
      )}
      <style jsx>{staticStyles}</style>
    </PageContentPreloader>
  );
};

export default compose<React.FC<IPeriodicallyReportsPageProps>>(
  withRouter,
  withTheme(),
  connect(
    (state: any) => ({
      reportTemplates: state.common.reportTemplates,
      reportsTemplatesAreLoaded: state.common.reportsTemplatesAreLoaded,
      periodicalReports: state.common.periodicalReports,
      periodicalReportsAreLoaded: state.common.periodicalReportsAreLoaded,
      partnerAccounts: state.client.common.partnerAccounts,
      partnerAccountsIsLoaded: state.client.common.partnerAccountsIsLoaded,
    }),
    {
      getReportTemplates: data => getReportTemplates.request(data),
      getPartnerAccounts: () => getPartnerAccounts.request(),
      getPeriodicalReportList: (data: any) => getPeriodicalReportList.request(data),
      deleteReport: (data: any) => deleteReport.request(data),
    }
  )
)(PeriodicallyReportsPage);
export { PeriodicallyReportsPage };
