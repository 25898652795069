import React, { useMemo, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

import { NoItemsPanel, PageContentPreloader, PageLayout, SearchPanel } from 'common/components';
import noPayments from 'common/images/noPayments.svg';
import { getInvestStrategies } from 'client/invest/_redux';
import PropTypes from 'prop-types';
import InvestRatingTable from '../InvestRatingTable';

import { staticStyles } from './style';

const InvestRatingPage = ({ investStrategies, investStrategiesAreLoaded, getInvestStrategies }) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(query => getInvestStrategies({ requestType: 'all', ...query }), [getInvestStrategies]);

  useEffect(() => {
    getList({ search: query });
  }, [getList, query]);

  const handleRowClick = (_, rowInfo) => {
    const strategyId = rowInfo?.original?.id;
    if (strategyId) history.push(`/widgets/invest/${strategyId}`);
  };

  return (
    <PageLayout captionSlug="investRating" showReload={false}>
      <PageContentPreloader ready={investStrategiesAreLoaded} type="bigLine" firstLaunchOnly>
        <FormattedMessage id="rammRiskWarning">
          {txt => <span className="InvestRatingPage__description">{txt}</span>}
        </FormattedMessage>
        <SearchPanel location={location} history={history} getList={getInvestStrategies} params={{ search: query }} />
        {investStrategies.count ? (
          <InvestRatingTable
            data={investStrategies}
            loading={!investStrategiesAreLoaded}
            location={location}
            history={history}
            getList={getInvestStrategies}
            onRowClick={handleRowClick}
            params={{ search: query }}
          />
        ) : (
          <NoItemsPanel icon={noPayments} captionSlug="noStrategiesMatchingTheParameters" />
        )}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

InvestRatingPage.propTypes = {
  investStrategies: PropTypes.object.isRequired,
  investStrategiesAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategies: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    investStrategies: state.client.invest.investStrategies,
    investStrategiesAreLoaded: state.client.invest.investStrategiesAreLoaded,
  }),
  {
    getInvestStrategies: data => getInvestStrategies.request(data),
  }
)(InvestRatingPage);
