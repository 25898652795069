import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .LoginForm {
    width: 100%;
    padding: 30px 0;
    text-align: center;

    span {
      display: block;
      text-align: left;
    }

    strong {
      display: block;
      text-align: left;
    }

    .DefaultButton__text {
      text-align: center;
    }

    .ForgotPass__inner {
      text-align: left;
      margin-bottom: 29px;
      display: flex;
      gap: 15px;
      flex-direction: column;
      @include respond-to(lg) {
        flex-direction: row;
      }

      a {
        font-size: 14px;
        position: relative;
        padding-bottom: 1px;
        width: fit-content;

        &:last-child {
          margin: 0;
        }

        &:active {
          transform: translateY(2px);

          &:after {
            transform: scale(0);
          }
        }

        &:hover {
          &:after {
            transform: scale(0);
          }
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          transform: scale(0.99);
          transition: all 0.4s ease;
          opacity: 0.4;
        }
      }
    }

    &__inputs-inner {
      .CheckboxField {
        text-align: left;
      }
    }

    #telegramAuthWrapper {
      margin-top: 25px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .LoginForm {
      .ForgotPass__inner {
        a {
          color: ${colors.secondary.hex};
          &:hover {
            color: ${colors.primary.hex};
            &:after {
              background-color: ${colors.primary.hex};
            }
          }
          &:after {
            background-color: ${colors.secondary.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
