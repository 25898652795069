import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { ChallengeItem } from 'client/accounts/_components';
import { getChallenges, getUserChallenges } from 'client/challenges/_redux';

import { DefaultButton, BasicModal } from 'common/components';
import { StartChallengeForm } from 'client/challenges/_components/SelectChallengeBlock/_components';
import { ModalHOC } from 'common';
import NoChallengesPanel from '../NoChallengesPanel';

import { staticStyles } from './style';

const ChallengesBlock = ({
  challenges,
  getChallenges,
  userChallenges,
  userChallengesAreLoaded,
  showModal,
  getUserChallenges,
  isAccountsPage,
  history,
}) => {
  const [isStartNewChallengeFormOpen, setIsStartNewChallengeOpen] = useState(false);

  const toogleModalFunc = () => {
    setIsStartNewChallengeOpen(prev => !prev);
  };
  useEffect(() => {
    if (isAccountsPage) {
      getChallenges();
      getUserChallenges();
    }
  }, [getChallenges, getUserChallenges]);
  return (
    <div className="ChallengesBlock">
      <div className="ChallengesBlock__head">
        <FormattedMessage id="justActiveChallenges">{txt => <h3>{txt}</h3>}</FormattedMessage>
        <div className="ChallengesBlock__head-right-buttons">
          {isAccountsPage && (
            <DefaultButton
              textId="justAboutChallenges"
              onClick={() => {
                showModal({
                  caption: { id: 'justAboutChallenges' },
                  content: {
                    id: 'justAboutChallengesTogether',
                  },
                  actionButton: {
                    textId: 'justGoToChallengePage',
                    handler: () => {
                      history.push('/challenges/');
                    },
                  },
                  cancelButton: {
                    textId: 'justClose',
                  },
                });
              }}
            />
          )}
          {isAccountsPage && <DefaultButton textId="justChallenge" plus filled onClick={toogleModalFunc} />}
        </div>
      </div>
      <div className="ChallengesBlock__body">{!userChallenges.length && <NoChallengesPanel />}</div>
      {userChallengesAreLoaded &&
        userChallenges
          .filter(({ status }) => status === 'active')
          .map(activeChallenge => (
            <ChallengeItem key={`${activeChallenge.challenge.name} block`} activeChallenge={activeChallenge} />
          ))}
      <BasicModal
        captionId="justNewChallenge"
        isOpen={isStartNewChallengeFormOpen}
        onRequestClose={() => toogleModalFunc()}>
        <StartChallengeForm
          challenges={challenges}
          toggleModal={() => toogleModalFunc()}
          initialValues={{ type: null, budget: null }}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

ChallengesBlock.propTypes = {
  challenges: PropTypes.array.isRequired,
  userChallenges: PropTypes.array.isRequired,
  userChallengesAreLoaded: PropTypes.bool.isRequired,
  getChallenges: PropTypes.func.isRequired,
  getUserChallenges: PropTypes.func.isRequired,
};

export default compose(
  ModalHOC,
  withRouter,
  withTheme(),
  connect(
    state => ({
      challenges: state.client.challenges.challenges,
      challengesAreLoaded: state.client.challenges.challengesAreLoaded,
      userChallenges: state.client.challenges.userChallenges,
      userChallengesAreLoaded: state.client.challenges.userChallengesAreLoaded,
    }),
    {
      getChallenges: () => getChallenges.request(),
      getUserChallenges: () => getUserChallenges.request(),
    }
  )
)(ChallengesBlock);
