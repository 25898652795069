import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CRMSettingForm {
    width: 100%;
    grid-column: 1/6;
    display: flex;
    flex-direction: column;
    &__nav-buttons {
      display: flex;
      gap: 16px;
      @include respond-to(sm) {
        flex-direction: column-reverse;
        gap: 24px;
      }
    }
    .formError {
      margin-bottom: 10px;
    }
  }
`;

export { staticStyles };
