import axios from 'axios';

// payments
export const getDepositSystemList = () => axios.get('/payments/v2/deposit/');
export const getWithdrawalSystemList = () => axios.get('/payments/v2/withdraw/');
export const getPaymentSystemSlugs = () => axios.get('/payments/v2/payment_system/slugs/');

// issue attachment
export const createIssueAttachment = (message, file) => {
  const data = new FormData();
  data.append('message', message);
  data.append('file', file);
  const config = { headers: { 'content-type': 'multipart/form-data' } };
  axios.post(`/support/attachments/create/`, data, config);
};

// partner reports
export const getReportTemplates = () => axios.get(`/reports/`);
export const executeReport = (reportId, values) => axios.post(`/reports/${reportId}/execute/`, { ...values });
export const exportReports = (reportId, values, type) =>
  axios.post(`/reports/${reportId}/export/${type}`, { ...values }, { responseType: 'blob', values });
export const createPeriodicalReport = formValues => axios.post(`/reports/periodical/create/`, { ...formValues });
export const getPeriodicalReportList = search => axios.get(`/reports/periodical/`, { params: { ...search } });
export const deleteReport = (reportId, type) => axios.delete(`/reports/${type}/${reportId}/`);
export const getSavedReports = search => axios.get(`/reports/saved/`, { params: { ...search } });
export const retrieveSavedReport = (reportId, format) =>
  axios.get(`/reports/saved/${reportId}/${format}/`, { responseType: 'blob' });
export const getBackofficeUserExports = search => axios.get(`/reports/user_export/`, { params: { ...search } });
export const createBackofficeUserExports = timePeriod =>
  axios.post(
    `/reports/user_export/`,
    { startEndDate: timePeriod },
    { validateStatus: status => status < 300 || status === 409 }
  );
export const downloadBackofficeUserExport = (id, name) =>
  axios.get(`/reports/user_export/${id}/`, { params: { name }, responseType: 'blob' });

export const getBackofficeReportTemplates = () => axios.get(`/backoffice/reports/`);
export const executeBackofficeReport = (reportId, values) =>
  axios.post(`/backoffice/reports/${reportId}/execute/`, { ...values });
export const createBackofficePeriodicalReport = formValues =>
  axios.post(`/backoffice/reports/periodical/create/`, { ...formValues });
export const getBackofficePeriodicalReportList = search =>
  axios.get(`/backoffice/reports/periodical/`, { params: { ...search } });
export const getBackofficeSavedReports = search => axios.get(`/backoffice/reports/saved/`, { params: { ...search } });
export const retrieveBackofficeSavedReport = (reportId, format) =>
  axios.get(`/backoffice/reports/saved/${reportId}/${format}/`, { responseType: 'blob' });
export const retrieveAndShowBackofficeSavedReport = reportId =>
  axios.get(`/backoffice/reports/saved/${reportId}/json/`);
export const retrieveAndShowSavedReport = reportId => axios.get(`/reports/saved/${reportId}/json/`);

// Invest New exports

export const getInvestNewExports = search => axios.get(`/reports/investmentdeal_export/`, { params: { ...search } });
// TODO: Могут быть в снейк кейсе strategyId and investmentId
export const createInvestNewExports = ({ timePeriod, strategyId, investmentId, ext }) =>
  axios.post(
    `/reports/investmentdeal_export/`,
    { startEndDate: timePeriod, strategyId, investmentId, ext },
    { validateStatus: status => status < 300 || status === 409 }
  );
export const downloadInvestNewExport = (id, name) =>
  axios.get(`/reports/investmentdeal_export/${id}/`, { params: { name }, responseType: 'blob' });
