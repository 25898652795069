import React from 'react';

import LoginForm from 'auth/login/_components/LoginForm';
import ConfirmEmail from '../_components/ConfirmEmail';

const EmailConfirmationPage = () => (
  <div className="EmailConfirmationPage">
    <LoginForm />
    <ConfirmEmail />
  </div>
);

export default EmailConfirmationPage;
