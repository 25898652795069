import { createFormAction } from 'redux-form-saga';
import { REQUEST, SUCCESS, FAILURE, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const wsRegistration = createFormAction('WS_REGISTRATION');

export const wsLogin = createFormAction('WS_LOGIN');

export const WS_SHOW_SEND_NOTIFICATION = 'WS_SHOW_SEND_NOTIFICATION';
export const wsShowSendNotification = data => action(WS_SHOW_SEND_NOTIFICATION, data);

export const wsRequestResetPassword = createFormAction('WS_REQUEST_RESET_PASSWORD');
export const wsConfirmPasswordReset = createFormAction('WS_CONFIRM_PASSWORD_RESET');

export const WS_CONFIRM_EMAIL = createRequestTypes('WS_CONFIRM_EMAIL');
export const wsConfirmEmail = {
  request: token => action(WS_CONFIRM_EMAIL[REQUEST], { token }),
  success: data => action(WS_CONFIRM_EMAIL[SUCCESS], { data }),
  failure: error => action(WS_CONFIRM_EMAIL[FAILURE], { error }),
};

export const wsGetOtpToken = createFormAction('WS_GET_OTP_TOKEN');

export const wsGetJwtToken = createFormAction('WS_GET_JWT_TOKEN');

export const WS_SET_REDIRECT = 'WS_SET_REDIRECT';
export const wsSetRedirect = action(WS_SET_REDIRECT);

export const WS_SET_AUTHORIZED = 'WS_SET_AUTHORIZED';
export const wsSetAuthorized = () => action(WS_SET_AUTHORIZED);

export const WS_RESET_AUTH = 'WS_RESET_AUTH';
export const wsResetAuth = error => action(WS_RESET_AUTH, { error });

export const WS_LOGOUT_REQUEST = 'WS_LOGOUT_REQUEST';
export const WS_LOGOUT_SUCCESS = 'WS_LOGOUT_SUCCESS';
export const wsLogout = () => action(WS_LOGOUT_REQUEST);
export const wsLogoutSuccess = () => action(WS_LOGOUT_SUCCESS);

export const WS_TOGGLE_MENU = 'WS_TOGGLE_MENU';
export const wsToggleMenu = () => action(WS_TOGGLE_MENU);

export const WS_GET_REGISTRATION_STATUS = createRequestTypes('WS_GET_REGISTRATION_STATUS');
export const wsGetRegistrationStatus = {
  request: () => action(WS_GET_REGISTRATION_STATUS[REQUEST]),
  success: data => action(WS_GET_REGISTRATION_STATUS[SUCCESS], { data }),
  failure: error => action(WS_GET_REGISTRATION_STATUS[FAILURE], { error }),
};

export const WS_SET_STEP = 'WS_SET_STEP';
export const WS_IS_STEP = 'WS_SET_STEP';
export const WS_SET_IS_REENTERING_MT_DATA = 'WS_SET_IS_REENTERING_MT_DATA';
export const WS_SET_EMAIL = 'WS_SET_EMAIL';
export const WS_SET_USER_DOMAIN = 'WS_SET_USER_DOMAIN';
export const WS_SET_MT_IS_INVALID = 'WS_SET_MT_IS_INVALID';
export const wsSetIsReenteringMtData = isReenteringMtData =>
  action(WS_SET_IS_REENTERING_MT_DATA, { isReenteringMtData });
export const wsSetEmail = email => action(WS_SET_EMAIL, { email });
export const wsSetStep = step => action(WS_SET_STEP, { step });
export const wsSetUserDomain = userDomain => action(WS_SET_USER_DOMAIN, { userDomain });

export const WS_GET_MT_DATA = createRequestTypes('WS_GET_MT_DATA');
export const wsGetMtData = {
  request: () => action(WS_GET_MT_DATA[REQUEST]),
  success: data => action(WS_GET_MT_DATA[SUCCESS], { data }),
  failure: error => action(WS_GET_MT_DATA[FAILURE], { error }),
};

export const WS_GET_AVAILABLE_LANGS = createRequestTypes('WS_GET_AVAILABLE_LANGS');
export const wsGetAvailableLangs = {
  request: () => action(WS_GET_AVAILABLE_LANGS[REQUEST]),
  success: data => action(WS_GET_AVAILABLE_LANGS[SUCCESS], { data }),
  failure: error => action(WS_GET_AVAILABLE_LANGS[FAILURE], { error }),
};

export const WS_GET_MT_GROUPS = createRequestTypes('WS_GET_MT_GROUPS');
export const wsGetMtGroups = {
  request: () => action(WS_GET_MT_GROUPS[REQUEST]),
  success: data => action(WS_GET_MT_GROUPS[SUCCESS], { data }),

  failure: error => action(WS_GET_MT_GROUPS[FAILURE], { error }),
};

export const wsConfigureMt = createFormAction('WS_CONFIGURE_MT');

export const WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME = createRequestTypes('WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME');
export const wsGetConfiguretedDnsTxtAndCNAME = {
  request: () => action(WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME[REQUEST]),
  success: data => action(WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME[SUCCESS], { data }),
  failure: error => action(WS_GET_CONFIGURETED_DNS_TXT_AND_CNAME[FAILURE], { error }),
};

export const wsGenerateDnsTxt = createFormAction('WS_GENERATE_DNS_TXT');

export const wsCheckDomain = createFormAction('WS_CHECK_DOMAIN');

export const wsConfigureAccountAndCreateBroker = createFormAction('WS_CONFIGURE_ACCOUNT_AND_CREATE_BROKER');
