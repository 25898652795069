import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { BasicModal } from 'common/components';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, reset } from 'redux-form';
import { email, length, required } from 'redux-form-validators';
import PropTypes from 'prop-types';

import { wsSendNotificationLetterAgain } from 'welcome-setup/_api';
import { ReduxTextField } from '../../form-fields';
import { NewDefaultButton } from '../..';

import { wsLogin, wsResetAuth, wsShowSendNotification } from '../../../_redux';

import { staticStyles } from './style';

const LoginForm = ({
  showButtonToResendEmailConfirmation,
  emailThatNeedsConfirmation,
  history,
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit,
  dispatch,
}) => {
  const [showResendEmailConfirmationModal, setShowResendEmailConfirmationModal] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const actionTooltipId = passwordFieldType === 'password' ? 'showPassword' : 'hidePassword';
  const actionClassName = passwordFieldType === 'password' ? 'show' : 'hide';

  const showPassword = () =>
    passwordFieldType === 'password' ? setPasswordFieldType('text') : setPasswordFieldType('password');

  const submit = handleSubmit(formValues => wsLogin(formValues, dispatch));

  return (
    <form className="LoginForm" onSubmit={submit}>
      <Field
        component={ReduxTextField}
        type="email"
        name="emailOrPhone"
        textId="authEmail"
        validate={[required(), email()]}
        disabled={submitting}
      />
      <Field
        component={ReduxTextField}
        type={passwordFieldType}
        name="password"
        textId="authPassword"
        validate={[required(), length({ min: 6 })]}
        disabled={submitting}
        action={showPassword}
        actionTooltipId={actionTooltipId}
        actionClassName={actionClassName}
      />
      {!showButtonToResendEmailConfirmation && emailThatNeedsConfirmation && (
        <div>
          <FormattedMessage id="welcomeSetupCheckYourInbox">
            {txt => (
              <div className="LoginForm__check-mail">
                {txt}
                <br />
                <span>{emailThatNeedsConfirmation}</span>
              </div>
            )}
          </FormattedMessage>
        </div>
      )}

      <div className="LoginForm__nav-buttons">
        {showButtonToResendEmailConfirmation && (
          <NewDefaultButton
            textId="welcomeSetupResendConfirmationEmail"
            onClick={() => {
              setShowResendEmailConfirmationModal(true);
            }}
            type="button"
            disabled={submitting}
            variant="secondary"
            wide
          />
        )}
        <NewDefaultButton
          textId="authDoLogin"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          wide
        />
        <NewDefaultButton
          onClick={() => history.push('/welcome-setup/password-reset')}
          textId="justForgotPassword"
          type="button"
          disabled={submitting}
          variant="secondary"
          wide
        />
        <NewDefaultButton
          onClick={() => history.push('/welcome-setup/registration')}
          textId="authDoRegister"
          type="button"
          disabled={submitting}
          variant="ghost"
          wide
          iconVariant={submitting && 'loading'}
        />
      </div>
      {error && <strong className="formError">{error}</strong>}

      <BasicModal
        isOpen={showResendEmailConfirmationModal}
        captionId="welcomeSetupResendConfirmationLetter"
        captionValues={{ email: emailThatNeedsConfirmation }}
        onRequestClose={() => setShowResendEmailConfirmationModal(false)}>
        <NewDefaultButton
          textId="justSend"
          onClick={() => {
            wsSendNotificationLetterAgain(emailThatNeedsConfirmation).finally(() => {
              dispatch(
                wsShowSendNotification({
                  showButtonToResendEmailConfirmation: false,
                  emailThatNeedsConfirmation,
                })
              );
              setShowResendEmailConfirmationModal(false);
            });
          }}
          type="button"
          disabled={submitting}
          variant="secondary"
        />
      </BasicModal>

      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

LoginForm.propTypes = {
  showButtonToResendEmailConfirmation: PropTypes.bool.isRequired,
  emailThatNeedsConfirmation: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

LoginForm.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  connect(state => ({
    showButtonToResendEmailConfirmation: state.welcomeSetup.showButtonToResendEmailConfirmation,
    emailThatNeedsConfirmation: state.welcomeSetup.emailThatNeedsConfirmation,
  })),
  reduxForm({
    form: 'wsLoginForm',
    onSubmitSuccess: (result, dispatch, { history }) => {
      dispatch(reset('ws_login'));

      if (result === 'success') {
        dispatch(wsResetAuth(''));
        history.push('/welcome-setup/');
      }
    },
  })
)(LoginForm);
export { LoginForm };
