import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatusesForm {
    background: #ffffff;
    flex-direction: column;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin: 25px 0;
    padding: 25px 30px;

    @include respond-to(sm) {
      padding: 20px 15px 1px;
    }

    &__title {
      margin-bottom: rem(20px);
    }

    &__line {
      display: flex;
      width: 100%;
    }

    &__left-block {
      flex: 8;
      margin-right: rem(${isRTL ? '0' : '30px'});
      margin-left: rem(${isRTL ? '30px' : '0'});
    }

    &__right-block {
      display: flex;
      flex: 2;
      align-items: flex-start;
      justify-content: center;
    }

    &__translations {
      font-size: rem(14px);
      font-weight: 500;
    }

    &__arrow {
      display: inline-block;
      padding: rem(5px);
      margin-left: rem(${isRTL ? '0' : '30px'});
      margin-right: rem(${isRTL ? '30px' : '0'});
    }

    &__arrow-up {
      transform: translateY(5px) rotate(-135deg);
      transition: transform 0.3s ease;
    }

    &__arrow-down {
      transform: rotate(45deg);
      transition: transform 0.3s ease;
    }

    &__bullets-left-block {
      display: flex;
      flex: 8;
      margin-right: rem(${isRTL ? '0' : '30px'});
      margin-left: rem(${isRTL ? '30px' : '0'});
    }

    &__bullets-content {
      flex: 7;
      margin-right: rem(${isRTL ? '0' : '30px'});
      margin-left: rem(${isRTL ? '30px' : '0'});
    }

    &__bullets-position {
      display: flex;
      padding-top: rem(10px);
      flex: 1;
    }

    &__bullets-count {
      margin-right: rem(${isRTL ? '0' : '10px'});
      margin-left: rem(${isRTL ? '10px' : '0'});
    }

    &__bullets-caption {
      margin-right: rem(${isRTL ? '0' : '10px'});
      margin-left: rem(${isRTL ? '10px' : '0'});
    }

    &__move-buttons {
      position: relative;
    }

    &__bullets-arrow-up {
      position: absolute;
      top: 0;
      width: rem(8px);
      height: rem(8px);
      transform: rotate(-135deg);
    }

    &__bullets-arrow-down {
      position: absolute;
      top: 10px;
      width: rem(8px);
      height: rem(8px);
      transform: rotate(45deg);

      @include respond-to(sm) {
        flex-direction: column;
      }
    }

    &__right-block {
      justify-content: flex-start;
      margin-bottom: rem(30px);
    }

    &__title {
      font-size: rem(15px);
      margin-right: rem(20px);
    }

    &__buttons {
      display: flex;

      :global(.DefaultButton) {
        margin-right: rem(20px);
      }
    }

    :global(&__addBullet) {
      margin-bottom: rem(30px);
    }

    &__ColorPickerField {
      margin-bottom: 25px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .StatusesForm {
      background: ${colors.white.hex};

      &__translations {
        color: ${colors.primary.hex};
      }

      &__arrow {
        border: solid ${colors.primary.hex};
        border-width: 0 2px 2px 0;
      }

      &__bullets-caption {
        color: ${colors.gray.hex};
      }

      &__bullets-arrow-up,
      &__bullets-arrow-down {
        border: solid ${colors.primary.hex};
        border-width: 0 2px 2px 0;
      }
    }

    button:disabled {
      .StatusesForm__translations {
        color: ${colors.lightGray.hex};
      }

      .StatusesForm__arrow {
        border: solid ${colors.lightGray.hex};
        border-width: 0 2px 2px 0;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
