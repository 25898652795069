import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const CustomFilterButton = ({ onClick, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="CustomFilterButton">
      <button className="CustomFilterButton__button" type="button" onClick={onClick} />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

CustomFilterButton.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.object,
};

CustomFilterButton.defaultProps = {
  onClick: null,
  theme: {},
};

export default withTheme()(CustomFilterButton);
