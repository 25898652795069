import React from 'react';

const IconEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M10.8346 1.06835C11.092 0.811015 11.5081 0.811015 11.7627 1.06835L12.9289 2.23457C13.1862 2.4919 13.1862 2.90802 12.9289 3.16262L11.4314 4.66556L9.33444 2.56856L10.8346 1.06835ZM8.71574 3.18725L10.8127 5.28426L4.81739 11.2796V10.9347C4.81739 10.6938 4.62029 10.4967 4.37938 10.4967H3.50334V9.62062C3.50334 9.37971 3.30624 9.18261 3.06533 9.18261H2.72039L8.71574 3.18725ZM1.83067 10.3735C1.86352 10.264 1.90458 10.1572 1.95933 10.0586H2.62731V10.9347C2.62731 11.1756 2.82442 11.3727 3.06533 11.3727H3.94136V12.0407C3.84007 12.0927 3.73604 12.1365 3.6238 12.1693L1.0833 12.9167L1.83067 10.3762V10.3735ZM12.3841 0.449651C11.7846 -0.149884 10.8155 -0.149884 10.2159 0.449651L1.65272 9.01014C1.34064 9.32222 1.11342 9.70549 0.990223 10.1271L0.018374 13.4396C-0.0281652 13.5929 0.0156364 13.7599 0.127878 13.8721C0.24012 13.9844 0.407114 14.0282 0.56042 13.9816L3.87292 13.007C4.29451 12.8838 4.67778 12.6539 4.98986 12.3445L13.5503 3.78405C14.1499 3.18452 14.1499 2.21541 13.5503 1.61587L12.3841 0.449651ZM9.06889 5.53338C9.23862 5.36365 9.23862 5.08441 9.06889 4.91468C8.89916 4.74495 8.61992 4.74495 8.45019 4.91468L4.94606 8.41881C4.77633 8.58855 4.77633 8.86778 4.94606 9.03751C5.11579 9.20724 5.39503 9.20724 5.56476 9.03751L9.06889 5.53338Z"
      fill="#333333"
    />
  </svg>
);

export default IconEdit;
