import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.UnderChartInfo) {
    top: 350px;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    width: 100%;
    margin-left: 10px;
    gap: 24px;
    @include respond-to(xl) {
      bottom: 20px;
    }
  }

  :global(.UnderChartInfo__Title) {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #999;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }

  :global(.UnderChartInfo__Items) {
    position: relative;
    display: flex;
    gap: 4px;
  }

  :global(.UnderChartInfo__Item) {
    position: relative;
    color: white;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 3px;
  }
  :global(.UnderChartInfo__ItemCircleColor) {
    width: 8px;
    height: 8px;
    margin-right: 5px;

    border-radius: 50%;
  }
  :global(.UnderChartInfo__ItemTitle) {
    position: relative;
    white-space: nowrap;
    color: #333;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }

  :global(.UnderChartInfo__ItemValue) {
    position: relative;
    color: #333;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
