import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { getWithdrawals, getWithdrawalPaymentSystems } from '../../_redux';
import PaymentsPage from '../PaymentsPage';

const WithdrawalsPage = ({
  withdrawals,
  getWithdrawals,
  withdrawalsAreLoaded,
  paymentSystemList,
  paymentSystemListLoading,
  getPaymentSystems,
  isWithdrawExport,
  location,
  history,
  intl,
  features,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const hasPaymentOperationManagers = features.includes('paymentOperationManager');

  useEffect(() => {
    getWithdrawals({ search: query });
  }, [getWithdrawals, getPaymentSystems, query]);

  useEffect(() => {
    getPaymentSystems();
  }, [getPaymentSystems]);

  const optionsPaymentSystemList = [];
  if (!paymentSystemListLoading) {
    paymentSystemList.map(ps =>
      optionsPaymentSystemList.push({ value: ps.paymentSystemSlug, label: ps.paymentSystemSlug })
    );
  }

  const [paymentMethods, setPaymentMethods] = useState(null);

  useEffect(() => {
    axios
      .get('/payments/v2/payment_methods/')
      .catch(err => {
        console.log('err', err);
      })
      .then(({ data }) => {
        setPaymentMethods([{ value: '', label: intl.formatMessage({ id: 'justAll' }) }, ...data]);
      });
  }, []);

  const filters = [
    {
      title: 'justStatus',
      accessor: '_status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'waiting_confirmation',
          label: intl.formatMessage({ id: 'status_waiting_confirmation' }),
        },
        {
          value: 'waiting_verification',
          label: intl.formatMessage({ id: 'status_waiting_verification' }),
        },
        {
          value: 'waiting_approval',
          label: intl.formatMessage({ id: 'status_waiting_approval' }),
        },
        { value: 'rejected', label: intl.formatMessage({ id: 'status_rejected' }) },
        { value: 'archived', label: intl.formatMessage({ id: 'status_archived' }) },
        { value: 'canceled', label: intl.formatMessage({ id: 'status_canceled' }) },
        { value: 'failed', label: intl.formatMessage({ id: 'status_failed' }) },
        { value: 'processing', label: intl.formatMessage({ id: 'status_processing' }) },
        { value: 'payout', label: intl.formatMessage({ id: 'status_payout' }) },
        { value: 'done', label: intl.formatMessage({ id: 'status_done' }) },
      ],
    },
    {
      title: 'paymentSystem',
      accessor: 'payment_system_slug',
      options: [{ value: '', label: intl.formatMessage({ id: 'justAll' }) }, ...optionsPaymentSystemList],
    },
    ...(paymentMethods ? [{ title: 'boPaymentMethod', accessor: 'payment_method', options: paymentMethods }] : []),
  ];

  return (
    <PaymentsPage
      type="withdrawals"
      items={withdrawals}
      allowManagePaymentsExports={isWithdrawExport}
      noPaymentsCaption="noPaymentsWithdrawals"
      exportButtonCaptionSlug="showWithdrawExportList"
      noMatchingParametersCaption="noWithdrawalsMatchingTheParameters"
      getItems={getWithdrawals}
      getReloadItems={() => getWithdrawals({ search: query })}
      captionSlug="boWithdrawals"
      itemsAreLoaded={withdrawalsAreLoaded}
      filters={filters}
      history={history}
      location={location}
      hasPaymentOperationManagers={hasPaymentOperationManagers}
    />
  );
};

WithdrawalsPage.propTypes = {
  withdrawals: PropTypes.object.isRequired,
  getWithdrawals: PropTypes.func.isRequired,
  withdrawalsAreLoaded: PropTypes.bool.isRequired,
  getPaymentSystems: PropTypes.func.isRequired,
  paymentSystemList: PropTypes.array.isRequired,
  paymentSystemListLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isWithdrawExport: PropTypes.bool,
  features: PropTypes.array.isRequired,
};

WithdrawalsPage.defaultProps = {
  isWithdrawExport: false,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      withdrawals: state.backoffice.payments.withdrawals,
      withdrawalsAreLoaded: state.backoffice.payments.withdrawalsAreLoaded,
      paymentSystemList: state.backoffice.payments.withdrawalSystems,
      paymentSystemListLoading: state.backoffice.payments.loadingWithdrawalSystems,
      isWithdrawExport: state.user.profile.perms.manageWithdrawExports,
      features: state.interfaceConfig.features,
    }),
    {
      getWithdrawals: search => getWithdrawals.request(search),
      getPaymentSystems: () => getWithdrawalPaymentSystems.request(),
    }
  )
)(WithdrawalsPage);
export { WithdrawalsPage };
