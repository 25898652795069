import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DefaultButton } from 'common/components';
import { removeTradeAccountUserPartnerCode } from '../../_redux';
import { staticStyles } from './style';

const RemoveTradeAccountPartnerCodeForm = ({
  user,
  tradeAccountId,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  closeModal,
}) => {
  const submit = handleSubmit(() => removeTradeAccountUserPartnerCode({ userId: user.id, tradeAccountId }, dispatch));

  return (
    <form className="RemoveTradeAccountPartnerCode" onSubmit={submit}>
      <FormattedMessage id="removeTradeAccountPartnerCodeDesc">{txt => <p>{txt}</p>}</FormattedMessage>
      <div className="RemoveTradeAccountPartnerCode__buttons-inner">
        <div className="RemoveTradeAccountPartnerCode__button-inner">
          <DefaultButton
            type="submit"
            textId="justYes"
            butColor="error"
            loading={submitting}
            disabled={invalid || submitting}
          />
        </div>
        <div className="RemoveTradeAccountPartnerCode__button-inner">
          <DefaultButton type="button" textId="justNo" filled onClick={closeModal} />
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

RemoveTradeAccountPartnerCodeForm.propTypes = {
  user: PropTypes.object.isRequired,
  tradeAccountId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'remove-trade-account-partner-code',
})(RemoveTradeAccountPartnerCodeForm);
export { RemoveTradeAccountPartnerCodeForm };
