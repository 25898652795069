import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .GenerateAndDownloadSumSubReportForm__btns {
    display: flex;
    justify-content: space-between;

    :global(.DefaultButton) {
      width: 48%;
    }
  }
`;

export default staticStyles;
