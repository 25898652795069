import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .ConfigureMtForm {
    width: 100%;
    grid-column: 1/6;
    > div {
      margin-bottom: 16px;
    }

    &__switcher-field {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      > span {
        color: #5d647d;
      }

      .ValueSwitcherField {
        max-width: 140px;
        width: 100%;
        margin-bottom: 0;
      }
    }
    &__YouHaveEnteredNewData {
      margin-top: 18px;
      margin-bottom: 8px;
      font-family: $ubuntu-font !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      color: #ff253f;
    }

    &__nav-buttons {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }
  }
`;

export { staticStyles };
