import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .EditOrderPage__description {
    font-size: rem(16px);
    line-height: 1.6;
    margin-bottom: rem(30px);
  }
`;

export { staticStyles };
