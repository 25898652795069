import axios from 'axios';

export const changeTradingAccountPassword = (id, password) =>
  axios.post(`/platforms/account/${id}/change_password/`, { password });

export const changeTradingAccountLeverage = (id, leverage) =>
  axios.post(`/platforms/account/${id}/change_leverage/`, { leverage });

export const createTradingAccount = (accountTypeId, options) =>
  axios.post(
    `/platforms/account_type/${accountTypeId}/create_account/`,
    {
      ...options,
    },
    { validateStatus: status => status < 300 || status === 402 }
  );

export const createDemoDeposit = (amount, accountId) =>
  axios.post(`/platforms/account/${accountId}/demo_deposit/`, {
    amount,
    currency: 'USD',
  });

export const getDealStats = () => axios.get('platforms/account/deal_stats/');

export const getAccountTradeLink = accountId => axios.get(`platforms/account/${accountId}/trade_link/`);

export const getConvertationOptions = id => axios.get(`/platforms/account/${id}/convertation/options/`);

export const createAccountConvertation = (id, data) =>
  axios.post(
    `/platforms/account/${id}/convertation/`,
    { ...data },
    { validateStatus: status => status < 300 || status === 409 }
  );

export const getLpaAgreements = () => axios.get(`lpa/agreements/`);

export const setLpaAgreements = data => axios.post(`lpa/agreements/`, data);

export const getOuterVerifyExternalLink = program => axios.get(`${program}/get_external_url/`);
