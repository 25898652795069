import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  authBanners: [],
  authBannersAreLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_AUTH_BANNERS[REQUEST]:
      return { ...state, authBannersAreLoaded: false };
    case ActionTypes.GET_AUTH_BANNERS[SUCCESS]:
      return { ...state, authBanners: action.data, authBannersAreLoaded: true };
    case ActionTypes.GET_AUTH_BANNERS[FAILURE]:
      return { ...state, error: action.error, authBannersAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
