import React from 'react';

const DocumentsIcon = () => (
  <svg className="svgNoFill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill"
      d="M4.16142 2.16155C4.64902 1.67395 5.31034 1.40002 5.9999 1.40002H13.9999C14.159 1.40002 14.3116 1.46324 14.4242 1.57576L20.4242 7.57576C20.5367 7.68828 20.5999 7.84089 20.5999 8.00002V20C20.5999 20.6896 20.326 21.3509 19.8384 21.8385C19.3508 22.3261 18.6895 22.6 17.9999 22.6H5.9999C5.31034 22.6 4.64902 22.3261 4.16142 21.8385C3.67383 21.3509 3.3999 20.6896 3.3999 20V4.00002C3.3999 3.31046 3.67383 2.64914 4.16142 2.16155ZM5.9999 2.60002C5.6286 2.60002 5.2725 2.74752 5.00995 3.01007C4.7474 3.27263 4.5999 3.62872 4.5999 4.00002V20C4.5999 20.3713 4.7474 20.7274 5.00995 20.99C5.2725 21.2525 5.6286 21.4 5.9999 21.4H17.9999C18.3712 21.4 18.7273 21.2525 18.9899 20.99C19.2524 20.7274 19.3999 20.3713 19.3999 20V8.60002H13.9999C13.6685 8.60002 13.3999 8.3314 13.3999 8.00002V2.60002H5.9999ZM14.5999 3.44855L18.5514 7.40002H14.5999V3.44855ZM7.3999 9.00002C7.3999 8.66865 7.66853 8.40002 7.9999 8.40002H9.9999C10.3313 8.40002 10.5999 8.66865 10.5999 9.00002C10.5999 9.3314 10.3313 9.60002 9.9999 9.60002H7.9999C7.66853 9.60002 7.3999 9.3314 7.3999 9.00002ZM7.3999 13C7.3999 12.6687 7.66853 12.4 7.9999 12.4H15.9999C16.3313 12.4 16.5999 12.6687 16.5999 13C16.5999 13.3314 16.3313 13.6 15.9999 13.6H7.9999C7.66853 13.6 7.3999 13.3314 7.3999 13ZM7.3999 17C7.3999 16.6687 7.66853 16.4 7.9999 16.4H15.9999C16.3313 16.4 16.5999 16.6687 16.5999 17C16.5999 17.3314 16.3313 17.6 15.9999 17.6H7.9999C7.66853 17.6 7.3999 17.3314 7.3999 17Z"
      fill="#5D647D"
    />
  </svg>
);

export default DocumentsIcon;
