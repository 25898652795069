/* eslint-disable camelcase */
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import UnderChartInfo from '../UnderChartInfo';

import { staticStyles } from './style';

const UsersChart = ({ dayDates, allDayData }) => {
  const series = [
    {
      name: 'New clients',
      data: allDayData.map(({ users }) => users * 1),
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 4],
      curve: 'smooth',
      dashArray: [0, 0],
    },
    title: {
      text: 'New clients',
      align: 'left',
    },
    legend: {
      show: false,
      tooltipHoverFormatter(val, opts) {
        return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: dayDates,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter(val) {
              return `${val} registered`;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          xaxis: {
            labels: {
              rotate: -70,
              style: {
                fontSize: '9px',
              },
            },
          },
        },
      },
    ],
  };

  const newUsersCount = allDayData.reduce((acc, rec) => acc + rec.users, 0);

  return (
    <div id="chart" className="ClientsPageAnalytics__Chart">
      <ReactApexChart options={options} series={series} type="area" height={350} />
      <UnderChartInfo
        textId="crmTotalForSelectedPeriod"
        values={[{ textId: 'crmNewClients', value: newUsersCount, color: '#0AA1E2' }]}
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default UsersChart;
