import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { email, length, required } from 'redux-form-validators';
import PropTypes from 'prop-types';
import { isValidNumber } from 'libphonenumber-js';

import { connect } from 'react-redux';
import { PhoneField } from 'common/components';
import { ReduxTextField } from '../../form-fields';

import { NewDefaultButton } from '../..';

import { wsRegistration } from '../../../_redux';

import { staticStyles } from './style';

const validatePhoneNumber = phoneNumber => {
  if (!isValidNumber(phoneNumber)) return <FormattedMessage id="validationErrorWrongPhone" />;
};

const validate = values => {
  const errors = {};

  if (values.passwordRepeat !== values.password)
    errors.passwordRepeat = <FormattedMessage id="form.errors.passwordRepeat" />;

  return errors;
};

const RegistrationForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  dispatch,
  didEmailChange,
  setDidSubmitHappend,
  setDidEmailChange,
  didSubmitHappend,
  userMail,
}) => {
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const actionTooltipId = passwordFieldType === 'password' ? 'showPassword' : 'hidePassword';
  const actionClassName = passwordFieldType === 'password' ? 'show' : 'hide';

  const showPassword = () => {
    if (passwordFieldType === 'password') setPasswordFieldType('text');
    else setPasswordFieldType('password');
  };

  const submit = handleSubmit(formValues => wsRegistration(formValues, dispatch));

  return (
    <form className="RegistrationForm" onSubmit={submit}>
      <div className="RegistrationForm__bio">
        <Field
          component={ReduxTextField}
          type="text"
          name="firstName"
          textId="justFirstName"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          type="text"
          name="lastName"
          textId="justLastName"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          type="text"
          name="brokerNiceName"
          textId="welcomeSetupYourCompanyName"
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <div className="RegistrationForm__additional-info">
        <Field
          component={ReduxTextField}
          type="email"
          name="email"
          onChange={() => {
            setDidEmailChange(true);
            setDidSubmitHappend(false);
          }}
          textId="authEmail"
          validate={[required(), email()]}
          disabled={submitting}
        />
        <Field
          component={PhoneField}
          name="phone"
          textId="justPhone"
          validate={[required(), validatePhoneNumber]}
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          type={passwordFieldType}
          name="password"
          textId="authPassword"
          validate={[required(), length({ min: 6 })]}
          disabled={submitting}
          action={showPassword}
          actionTooltipId={actionTooltipId}
          actionClassName={actionClassName}
        />
        <Field
          component={ReduxTextField}
          type="password"
          name="passwordRepeat"
          textId="authPasswordRepeat"
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      {didSubmitHappend && !didEmailChange && (
        <FormattedMessage id="welcomeSetupCheckYourInbox">
          {txt => (
            <div className="RegistrationForm__check-mail">
              {txt}
              <span>{userMail}</span>
            </div>
          )}
        </FormattedMessage>
      )}
      <NewDefaultButton
        textId={didSubmitHappend && !didEmailChange ? 'welcomeSetupConfirmationEmailSent' : 'justNextStep'}
        type="submit"
        variant="primary"
        isUpperCase
        wide
        disabled={invalid || pristine || submitting || !didEmailChange}
      />
      {error && <strong className="formError">{error}</strong>}
      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  setDidEmailChange: PropTypes.func.isRequired,
  didEmailChange: PropTypes.bool.isRequired,
  didSubmitHappend: PropTypes.bool.isRequired,
  userMail: PropTypes.string.isRequired,
  setDidSubmitHappend: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  error: '',
};

export default compose(
  connect(state => ({ userMail: state.welcomeSetup.email })),
  reduxForm({
    form: 'wsRegistrationForm',
    validate,
    onSubmitSuccess: (result, dispatch, { setDidSubmitHappend, setDidEmailChange }) => {
      setDidSubmitHappend(true);
      setDidEmailChange(false);
    },
  })
)(RegistrationForm);
export { RegistrationForm };
