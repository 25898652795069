import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UserNoInfoPanel } from 'backoffice/_components/';
import arrowTopKYC from 'common/images/arrowTopKYC.svg';
import noInfoImage from 'common/images/noInfo.svg';
import { withTheme } from 'common/styling/theme';

import { UserSumSubDocumentPreview } from './_components';
import { staticStyles, getDynamicStyles } from './style';

const UserSumSubDocuments = ({ documents, documentsAreLoaded, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [openBlock, setOpenBlock] = useState(false);

  return (
    <div className="UserSumSubDocuments">
      {/* eslint-disable */}
      <div
        className={classNames('UserSumSubDocuments__open-close', {
          'UserSumSubDocuments__open-close--close': !openBlock,
        })}
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <FormattedMessage id="justSumSubDocuments">
        {txt => (
          <h2
            className={classNames('UserSumSubDocuments__title', {
              'UserSumSubDocuments__title--hide': !openBlock,
            })}>
            {txt}
          </h2>
        )}
      </FormattedMessage>
      {/* eslint-disable */}
      <img
        className={classNames('UserSumSubDocuments__arrowTopKYC', {
          'UserSumSubDocuments__arrowTopKYC--hide': !openBlock,
        })}
        src={arrowTopKYC}
        alt="arrowTopKYC"
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <div
        className={classNames('UserSumSubDocuments__documents-wrapper', {
          'UserSumSubDocuments__documents-wrapper--hide': !openBlock,
        })}>
        {documents.length > 0 && documentsAreLoaded ? (
          <div className="UserSumSubDocuments__documents">
            {documents.map(document => (
              <UserSumSubDocumentPreview key={document.id} document={document} />
            ))}
          </div>
        ) : (
          <UserNoInfoPanel image={noInfoImage} textId="boUserDocumentsNoInform" />
        )}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserSumSubDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  documentsAreLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

UserSumSubDocuments.defaultProps = {
  theme: {},
};

export default withTheme()(UserSumSubDocuments);
