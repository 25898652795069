import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InfoPanel {
    position: relative;
    padding: rem(25px);
    margin-bottom: rem(30px);

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }

    .InfoPanel__list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-auto-flow: row;
      grid-gap: 2vw;
    }

    .InfoPanel__cell {
      display: flex;
      flex-direction: column;
    }

    .InfoPanel__cell-caption {
      font-size: rem(13px);
      margin-bottom: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InfoPanel {
      background-color: ${colors.white.hex};

      &:after {
        background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }

      .InfoPanel__cell-caption {
        color: ${colors.gray.hex};
      }

      :global(.InfoPanel__cell-value--neg) {
        color: ${colors.error.hex};
      }

      :global(.InfoPanel__cell-value--pos) {
        color: ${colors.success.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
