import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { brokereeTypes } from 'common/utils';

import { PageLayout, PageTabs, TranslatedNavLink, BasicModal, DefaultButton } from 'common/components';

import { getTradingAccountTypes } from '../../_redux';
import { AccountTypesPanel, CreateAccountForm } from '../_components';
import DemoDepositForm from '../../_components/DemoDepositForm';

import staticStyles from './style';

const CreateAccountPage = ({ accountTypes, getAccountTypes, creationAllowed, createdAccount, isDepositEnabled }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [modalState, setModalState] = useState({ modalOpened: false, accountType: {}, accountCreated: false });
  const [demoModalIsOpen, setDemoModalIsOpen] = useState(false);
  const toggleDemoModal = () => {
    setDemoModalIsOpen(!demoModalIsOpen);
  };

  useEffect(() => {
    if (!accountTypes.length) {
      getAccountTypes();
    }
  });

  const openModal = accountType => {
    setModalState({ ...modalState, modalOpened: true, accountType });
  };

  const closeModal = () => {
    setModalState({
      ...modalState,
      modalOpened: false,
      accountCreated: false,
    });
  };

  const accountCreated = () => setModalState({ ...modalState, accountCreated: true });

  const currentTypeParam = match.params.type;
  const currentTypes = accountTypes.filter(type => {
    if (currentTypeParam === 'demo') {
      return type.isDemo && !type.isBrokeree;
    }

    if (currentTypeParam === 'real') {
      return !type.isDemo && !type.isBrokeree;
    }

    if (currentTypeParam === 'brokeree-investor') {
      return type.isBrokeree === brokereeTypes.INVESTOR;
    }
    if (currentTypeParam === 'brokeree-manager') {
      return type.isBrokeree === brokereeTypes.MANAGER;
    }
    console.log('SMTH went wrond', type);
    return null;
  });

  const hasDemoAccountTypes = accountTypes.filter(type => type.isDemo && !type.isBrokeree);
  const hasRealAccountTypes = accountTypes.filter(type => !type.isDemo && !type.isBrokeree);

  const hasBrokereeInvestorAccountTypes = accountTypes.filter(type => type.isBrokeree === brokereeTypes.INVESTOR);

  const hasBrokereeManagerAccountTypes = accountTypes.filter(type => type.isBrokeree === brokereeTypes.MANAGER);

  let accountsTextId;

  if (isDepositEnabled) {
    if (currentTypeParam === 'demo' && !modalState.accountType.allowDemoDeposits) {
      accountsTextId = 'goToAccountsPage';
    } else {
      accountsTextId = 'returnToAccountsPage';
    }
  } else {
    accountsTextId = 'goToAccountsPage';
  }

  return (
    <>
      <PageLayout captionSlug="chooseTradingAccountType">
        {creationAllowed && (
          <PageTabs>
            {!!hasRealAccountTypes.length && <TranslatedNavLink id="realAccounts" to="/create-account/real" />}
            {!!hasDemoAccountTypes.length && <TranslatedNavLink id="demoAccounts" to="/create-account/demo" />}
            {!!hasBrokereeInvestorAccountTypes.length && (
              <TranslatedNavLink id="pammInvestor" to="/create-account/brokeree-investor" />
            )}
            {!!hasBrokereeManagerAccountTypes.length && (
              <TranslatedNavLink id="pammManager" to="/create-account/brokeree-manager" />
            )}
          </PageTabs>
        )}
        <AccountTypesPanel accountTypes={currentTypes} onAddAccountClick={openModal} />
      </PageLayout>
      {modalState.accountType && (
        <BasicModal captionId="newAccountCaption" isOpen={modalState.modalOpened} onRequestClose={closeModal}>
          {!modalState.accountCreated ? (
            <CreateAccountForm
              accountSchema={currentTypes.find(schema => schema.id === modalState.accountType.id)}
              accountTypeId={modalState.accountType.id}
              onSuccessfulCreateAccount={accountCreated}
            />
          ) : (
            <div className="CreateAccountPage__created">
              <FormattedMessage
                id={isDepositEnabled ? 'accountCreatedSuccessfully' : 'accountCreatedSuccessfullyNoDeposit'}>
                {txt => <p>{txt}</p>}
              </FormattedMessage>
              {isDepositEnabled && (
                <DefaultButton
                  className={classNames({
                    'CreateAccountPage__created__depositBtn-hidden':
                      currentTypeParam === 'demo' && !modalState.accountType.allowDemoDeposits,
                  })}
                  textId="justDeposit"
                  filled
                  onClick={() => {
                    if (currentTypeParam === 'demo') {
                      closeModal();
                      toggleDemoModal();
                      return;
                    }
                    history.push('/deposit/');
                  }}
                />
              )}
              <div className="CreateAccountPage__link">
                <FormattedMessage
                  id={accountsTextId}
                  values={{
                    link: (
                      <DefaultButton textId="justAccounts" type="button" onClick={() => history.push('/')} noBorder />
                    ),
                  }}
                />
              </div>
            </div>
          )}
        </BasicModal>
      )}
      <BasicModal captionId="depositToDemoAccount" isOpen={demoModalIsOpen} onRequestClose={toggleDemoModal}>
        {!!modalState.accountType && (
          <DemoDepositForm accountId={createdAccount.id} onSuccessfulDeposit={() => history.push('/')} />
        )}
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
    </>
  );
};

CreateAccountPage.propTypes = {
  accountTypes: AccountTypesPanel.propTypes.accountTypes.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  creationAllowed: PropTypes.bool.isRequired,
  createdAccount: PropTypes.object.isRequired,
  isDepositEnabled: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    accountTypes: state.client.common.accountTypes,
    loading: state.client.common.loadingAccountTypes,
    creationAllowed: state.user.profile.status === 'verified',
    createdAccount: state.client.accounts.createdAccount,
    isDepositEnabled: state.interfaceConfig.paymentSettings.isDepositEnabled,
  }),
  { getAccountTypes: () => getTradingAccountTypes.request() }
)(CreateAccountPage);
export { CreateAccountPage };
