import moment from 'moment';

const currentDate = moment();
const todayDate = moment().format('YYYY-MM-DD');
const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
const lastWeek = moment().subtract(7, 'day').format('YYYY-MM-DD');
const lastTwoWeeks = moment().subtract(14, 'day').format('YYYY-MM-DD');
const lastThreeWeeks = moment().subtract(21, 'day').format('YYYY-MM-DD');
const currentMonth = moment().format('YYYY-MM');
const lastMonth = moment().subtract(1, 'months').format('YYYY-MM');
const currentMonthName = moment().format('MMMM');
const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
const today = moment().format('YYYY-MM-DD');
const displayToday = moment().format('DD.MM.YYYY');
const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

const getFixedNumber = (num, decimal) => {
  const result = Number(Number(num).toFixed(decimal));
  if (!Number.isNaN(result)) {
    return result;
  }
  return '';
};

const reportsDatesSelectValues = [
  { value: undefined, label: 'justAll' },
  { value: `${todayDate}_${todayDate}`, label: 'justForToday' },
  { value: `${yesterday}_${todayDate}`, label: 'justLastTwoDays' },
  { value: `${lastWeek}_${todayDate}`, label: 'justLastWeek' },
  { value: `${lastTwoWeeks}_${todayDate}`, label: 'justLastTwoWeeks' },
  { value: `${lastThreeWeeks}_${todayDate}`, label: 'justLastThreeWeeks' },
  { value: `${lastMonth}_${todayDate}`, label: 'justLastMonth' },
];

export {
  reportsDatesSelectValues,
  currentDate,
  currentMonth,
  lastMonth,
  currentMonthName,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  today,
  displayToday,
  tomorrow,
  getFixedNumber,
};
