import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';


  .UserCreateAccountForm {

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      :global(.DefaultButton) {
        width: 48%;
      }
    }
  }
`;

export { staticStyles };
