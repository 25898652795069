import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const BonusesMyIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 26 29" width="26" height="29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.128 25.032l-3.053-2.951c-.203-.153-.101-.458.153-.51l3.714-.559c.051 0 .102-.102.051-.152l-1.221-.662c-1.018-.56-1.628-1.679-1.628-2.849v-1.374l.102-.101c.763-.916 1.984-2.697 2.035-3.41v-.152l.865-.712c.152-.153.305-.407.305-.61V8.495c0-.356-.153-.66-.458-.814l-.203-.05-.051-.051V4.986C16.789 2.239 14.55 0 11.803 0h-1.068A4.994 4.994 0 005.75 4.986v2.595c0 .05 0 .05-.05.05l-.103.051a.978.978 0 00-.457.814v2.493c0 .204.152.51.305.611l.865.712v.153c.05.712 1.272 2.442 2.035 3.409l.102.101v1.374c0 1.17-.61 2.239-1.628 2.85l-4.834 2.747C.764 23.607 0 24.93 0 26.303v.865c0 .051.05.051.05.051h15.62c.051 0 .051-.05.051-.05l.356-2.137c.051.05.051 0 .051 0zm9.31-3.206l-3.917-.56-1.73-3.51c-.05-.152-.254-.152-.356 0l-1.73 3.51-3.917.56c-.153 0-.204.204-.102.306l2.798 2.747-.661 3.867c-.051.152.152.254.254.203l3.51-1.832 3.511 1.832c.153.051.306-.05.255-.203l-.61-3.816 2.797-2.747c.102-.102.051-.306-.101-.357z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="4.502"
          y1="31.107"
          x2="28.974"
          y2="24.616"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

BonusesMyIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

BonusesMyIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedBonusesMyIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(BonusesMyIconActive);

export default ThemedBonusesMyIconActive;
