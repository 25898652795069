import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.checkboxWrapper) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  :global(.CheckBox) {
    appearance: auto;
    opacity: 1;
    width: auto;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    /* max-width: 30px !important;
    min-width: 30px !important; */
  }

  .NewFullNameCell__FullName:hover {
    cursor: pointer;
    color: #0aa1e2;
  }
  .NewFullNameCell {
    width: 100%;
    display: flex;
    position: relative;
    gap: 12px;
    padding-left: 4px;
    z-index: 1;

    &__FullName {
      text-overflow: ellipsis;
      word-break: break-all;
    }

    &__ManagerIcon {
      padding-bottom: 4px;
      width: 23px;
      height: 23px;
    }

    &__favorite {
    }
  }
`;

export { staticStyles };
