import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';

const TextField = ({ externalClass, theme, placeholder, ...props }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <div className={classNames('TextField', externalClass)}>
      <input className="TextField__input" type="text" placeholder={placeholder} {...props} />
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TextField.propTypes = {
  externalClass: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.object,
};

TextField.defaultProps = {
  externalClass: '',
  placeholder: null,
  theme: {},
};

export default withTheme()(TextField);
