import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTheme } from 'common/styling/theme';

import { IssueMessages, IssueCreateMessage, DefaultButton, StatusCell, DateCell } from 'common/components';
import { updateMessage } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const Issue = ({
  item,
  issue,
  active,
  getIssue,
  markIssueAsViewed,
  toggleIssue,
  createMessage,
  closeIssue,
  haveNewMessages,
  id,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const closableStatuses = ['open', 'answered'];
  const [isMessageEditing, setMessageEditing] = useState(false);

  const handleOpenIssue = ({ target }) => {
    if (!target.classList.contains('DefaultButton')) {
      getIssue(item.id);
      toggleIssue(item.id);

      if (haveNewMessages) {
        markIssueAsViewed(item.id);
      }
    }
  };

  return (
    <div
      id={`Issue__${id}`}
      className={classNames('Issue', {
        Issue__open: active,
        Issue__new_messages: item.haveNewMessages,
      })}>
      <div
        role="presentation"
        className="Issue__caption"
        onClick={e => {
          handleOpenIssue(e);
        }}
        onKeyPress={handleOpenIssue}>
        <div className="Issue__title">
          <FormattedMessage id="justTitle">{txt => <span className="Issue__key">{`${txt}:`}</span>}</FormattedMessage>
          <span>{item.title}</span>
        </div>
        <div className="Issue__date">
          <FormattedMessage id="justDate">{txt => <span className="Issue__key">{`${txt}:`}</span>}</FormattedMessage>
          <DateCell value={item.created} />
        </div>
        <div className="Issue__status">
          <FormattedMessage id="justStatus">{txt => <span className="Issue__key">{`${txt}:`}</span>}</FormattedMessage>
          <StatusCell statusCode={active ? issue.status : item.status} />
        </div>
        {active && closableStatuses.includes(issue.status) && (
          <div className="IssueClose__button-inner">
            <DefaultButton textId="closeIssue" butColor="error" onClick={() => closeIssue(issue.id)} />
          </div>
        )}
      </div>

      {active && (
        <IssueCreateMessage
          initialValues={{ issue: issue.id }}
          createMessage={createMessage}
          editMessage={updateMessage}
          isMessageEditing={isMessageEditing}
          setMessageEditing={setMessageEditing}
        />
      )}
      {active && <IssueMessages issue={issue} setMessageEditing={setMessageEditing} />}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

Issue.propTypes = {
  item: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  issue: PropTypes.object.isRequired,
  getIssue: PropTypes.func.isRequired,
  markIssueAsViewed: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  closeIssue: PropTypes.func.isRequired,
  toggleIssue: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  haveNewMessages: PropTypes.bool,
  theme: PropTypes.object,
};

Issue.defaultProps = {
  haveNewMessages: false,
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      profile: state.user.profile,
      haveNewMessages: state.client.support.issue.haveNewMessages,
    }),
    null
  )
)(Issue);
export { Issue };
