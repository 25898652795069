import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.TasksPage) {
    width: 100%;
    padding: 24px 0 24px 24px;
  }

  :global(.Tabs__content) {
    background: white;
    border-radius: 16px;
    padding: 24px;
  }
`;

export { staticStyles };
