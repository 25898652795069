import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .OrderReportForm {
    :global(.ReduxSelectField) {
      width: rem(360px);

      @include respond-to(sm) {
        width: 100%;
      }
    }

    :global(.OrderReportForm__composer-fields) {
      display: flex;
      margin-bottom: rem(10px);

      :global(.DateRangeField__picker) {
        width: rem(360px);
      }

      @include respond-to(sm) {
        flex-direction: column;

        :global(.DateRangeField__picker) {
          width: 100%;
        }
      }
    }

    :global(.FormSchemaComposer__inner) {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      width: rem(360px);
      margin: 0 rem(20px) 0 0;
      @include respond-to(sm) {
        width: 100%;
        margin: 0;
      }
      :global(p) {
        display: none;
      }
    }
  }
`;

export { staticStyles };
