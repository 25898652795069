import { fork, put, call, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as actionTypes from './actions';
import * as api from '../api';

function* getStaffMemberListSaga() {
  try {
    const { data } = yield call(api.getStaffMemberList);

    yield put(actionTypes.getStaffMemberList.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.getStaffMemberList.failure(e));
  }
}

function* getStaffMemberListWatcherSaga() {
  while (true) {
    yield take(actionTypes.GET_STAFF_MEMBER_LIST[REQUEST]);
    yield call(getStaffMemberListSaga);
  }
}

function* addStaffMemberSaga(_data) {
  try {
    const { data } = yield call(api.saveStaffMember, _data);

    yield put(actionTypes.addStaffMember.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.addStaffMember.failure(e));
  }
}

function* addStaffMemberWatcherSaga() {
  while (true) {
    const { data } = yield take(actionTypes.ADD_STAFF_MEMBER[REQUEST]);
    yield call(addStaffMemberSaga, data);
  }
}

function* removeStaffMemberSaga(_data) {
  try {
    const payload = {
      id: _data.id,
      perms: [],
      groups: [],
    };
    const { data } = yield call(api.saveStaffMember, payload);

    yield put(actionTypes.removeStaffMember.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.removeStaffMember.failure(e));
  }
}

function* removeStaffMemberWatcherSaga() {
  while (true) {
    const { data } = yield take(actionTypes.REMOVE_STAFF_MEMBER[REQUEST]);
    yield call(removeStaffMemberSaga, data);
  }
}

function* saveStaffMemberSaga(payload) {
  try {
    const { data } = yield call(api.saveStaffMember, payload);

    yield put(actionTypes.saveStaffMember.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.saveStaffMember.failure(e));
  }
}

function* saveStaffMemberWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.saveStaffMember[REQUEST]);
    yield call(saveStaffMemberSaga, payload);
  }
}

function* getStaffGroupListSaga() {
  try {
    const { data } = yield call(api.getStaffGroupList);

    yield put(actionTypes.getStaffGroupList.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.getStaffGroupList.failure(e));
  }
}

function* getStaffGroupListWatcherSaga() {
  while (true) {
    yield take(actionTypes.GET_STAFF_GROUP_LIST[REQUEST]);
    yield call(getStaffGroupListSaga);
  }
}

function* addStaffGroupSaga(_data) {
  try {
    const { data } = yield call(api.addStaffGroup, _data);

    yield put(actionTypes.addStaffGroup.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.addStaffGroup.failure(e));
  }
}

function* addStaffGroupWatcherSaga() {
  while (true) {
    const { data } = yield take(actionTypes.ADD_STAFF_GROUP[REQUEST]);
    yield call(addStaffGroupSaga, data);
  }
}

function* removeStaffGroupSaga(data) {
  try {
    yield call(api.removeStaffGroup, data);

    yield put(actionTypes.removeStaffGroup.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.removeStaffGroup.failure(e));
  }
}

function* removeStaffGroupWatcherSaga() {
  while (true) {
    const { data } = yield take(actionTypes.REMOVE_STAFF_GROUP[REQUEST]);
    yield call(removeStaffGroupSaga, data);
  }
}

function* saveStaffGroupSaga(payload) {
  try {
    const { data } = yield call(api.saveStaffGroup, payload);

    yield put(actionTypes.saveStaffGroup.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.saveStaffGroup.failure(e));
  }
}

function* saveStaffGroupWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.saveStaffGroup[REQUEST]);
    yield call(saveStaffGroupSaga, payload);
  }
}

export default [
  fork(getStaffMemberListWatcherSaga),
  fork(addStaffMemberWatcherSaga),
  fork(removeStaffMemberWatcherSaga),
  fork(saveStaffMemberWatcherSaga),
  fork(getStaffGroupListWatcherSaga),
  fork(addStaffGroupWatcherSaga),
  fork(removeStaffGroupWatcherSaga),
  fork(saveStaffGroupWatcherSaga),
];
