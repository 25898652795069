import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { getMonthsFunnels } from 'common/dashboards/_redux';
import MonthPicker from 'common/components/MonthPicker';
import ColumnChart from 'common/dashboards/_components/ColumnChart';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const formatMonth = ({ year, month }) => {
  if (month < 10) return `${year}-0${month}`;
  return `${year}-${month}`;
};
const months = moment.monthsShort();
const beautifyFormat = value => `${months[value.month - 1]}. ${value.year}`;

// Data for current and previous month is stored in lastFunnels,
// monthsFunnels stores data for selected months
const MonthColumnChart = ({
  theme,
  hasBrokerAccess,
  monthsFunnels,
  getMonthsFunnels,
  partnerId,
  viewMode,
  currentYear,
  currentMonth,
  lastMonthYear,
  lastMonth,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [firstMonthValue, setFirstMonthValue] = useState({
    year: currentYear,
    month: currentMonth,
  });

  const [secondMonthValue, setSecondMonthValue] = useState({
    year: lastMonthYear,
    month: lastMonth,
  });

  let firstMonthData = [];
  let secondMonthData = [];

  if (monthsFunnels[formatMonth(firstMonthValue)]) {
    firstMonthData = Object.values(monthsFunnels[formatMonth(firstMonthValue)]).slice(0, 4);
  }

  if (monthsFunnels[formatMonth(secondMonthValue)]) {
    secondMonthData = Object.values(monthsFunnels[formatMonth(secondMonthValue)]).slice(0, 4);
  }

  const handleFirstMonthChange = monthValue => {
    setFirstMonthValue(monthValue);
    getMonthsFunnels({
      hasBrokerAccess,
      firstMonth: formatMonth(monthValue),
      secondMonth: formatMonth(secondMonthValue),
      partnerId,
      viewMode,
    });
  };

  const handleSecondMonthChange = monthValue => {
    setSecondMonthValue(monthValue);
    getMonthsFunnels({
      hasBrokerAccess,
      firstMonth: formatMonth(firstMonthValue),
      secondMonth: formatMonth(monthValue),
      partnerId,
      viewMode,
    });
  };

  return (
    <div className="MonthColumnChart__wrapper">
      <div className="MonthColumnChart__top-line">
        <div className="MonthColumnChart__compare-block">
          <MonthPicker
            monthValue={firstMonthValue}
            years={{ min: { year: 2010, month: 1 }, max: { year: currentYear, month: currentMonth } }}
            handleChange={handleFirstMonthChange}
          />
          <FormattedMessage id="justCompareWith">
            {txt => <span className="MonthColumnChart__compare-block-text">{txt}</span>}
          </FormattedMessage>
          <MonthPicker
            monthValue={secondMonthValue}
            years={{ min: { year: 2010, month: 1 }, max: { year: currentYear, month: currentMonth } }}
            handleChange={handleSecondMonthChange}
          />
        </div>
      </div>
      <div className="MonthColumnChart__inner">
        <ColumnChart
          firstMonthTitle={beautifyFormat(firstMonthValue)}
          firstMonthData={firstMonthData}
          secondMonthTitle={beautifyFormat(secondMonthValue)}
          secondMonthData={secondMonthData}
        />
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

MonthColumnChart.propTypes = {
  theme: PropTypes.object,
  monthsFunnels: PropTypes.object,
  getMonthsFunnels: PropTypes.func,
  partnerId: PropTypes.number,
  hasBrokerAccess: PropTypes.bool,
  viewMode: PropTypes.string,
  currentMonth: PropTypes.number.isRequired,
  lastMonth: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  lastMonthYear: PropTypes.number.isRequired,
};

MonthColumnChart.defaultProps = {
  theme: {},
  monthsFunnels: {},
  getMonthsFunnels: null,
  viewMode: null,
  partnerId: 0,
  hasBrokerAccess: false,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      monthsFunnels: state.dashboards.monthsFunnels,
    }),
    {
      getMonthsFunnels: data => getMonthsFunnels.request(data),
    }
  )
)(MonthColumnChart);
export { MonthColumnChart };
