import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StrategyInvestsTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(.ReactDataTable__thead-cell:not(:first-child), .ReactDataTable__tbody-cell:not(:first-child)) {
          display: flex;
          justify-content: center;
        }

        :global(.ReactDataTable__thead-cell:first-child, .ReactDataTable__tbody-cell:first-child) {
          padding-left: rem(20px) !important;
        }

        :global(.StrategyInvestsTable__created) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        :global(.StrategyInvestsTable__strategy) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        :global(.StrategyInvestsTable__strategy-caption) {
          margin-bottom: rem(5px);
        }

        :global(.StrategyInvestsTable__strategy-symbols) {
          font-size: rem(12px);
        }

        :global(.StrategyInvestsTable__TotalCell) {
          justify-content: flex-start;
          align-items: flex-start;
        }

        :global(.ReactDataTable__tbody-row),
        :global(.ReactDataTable__thead-row) {
          margin: 0;
        }
      }
    }
  }

  .StrategyInvestsTable-active {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(50px, 1fr) repeat(3, minmax(100px, 2fr));
      }
    }
  }

  .StrategyInvestsTable-closed {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(50px, 1fr) repeat(4, minmax(100px, 2fr)) minmax(50px, 1.5fr);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    :global(.StrategyInvestsTable__created) {
      :global(span:nth-child(2)) {
        color: ${colors.gray.hex};
      }
    }

    :global(.StrategyInvestsTable__income--neg) {
      color: ${colors.error.hex};
    }

    :global(.StrategyInvestsTable__income--pos) {
      color: ${colors.success.hex};
    }

    :global(.StrategyInvestsTable__strategy-symbols) {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
