import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import { IconEdit } from './icons';

import { staticStyles } from './style';

const ContactPageInfoBlock = ({ title, titleValues, items, setCrmModalType }) => (
  <div className="ContactPageInfoBlock">
    <div className="ContactPageInfoBlock__TitleWrap">
      <div className="ContactPageInfoBlock__Title">
        <FM id={title} values={titleValues} />
      </div>
    </div>

    <div className="ContactPageInfoBlock__ContentWrap">
      {items.map(({ titleId, cell, isEditVisible }) => {
        let modalType;
        switch (titleId) {
          case 'crmLanguage':
            modalType = 'editLanguage';
            break;
          case 'justCountry':
            modalType = 'editCountry';
            break;
          case 'justState':
            modalType = 'editState';
            break;
          case 'justCity':
            modalType = 'editCity';
            break;
          case 'justAddress':
            modalType = 'editAddress';
            break;
          default:
            modalType = '';
        }
        return (
          <div className="ContactPageInfoBlock__Item" key={`contact-page-info-block-${titleId}`}>
            <FM id={titleId}>{txt => <span className="ContactPageInfoBlock__ItemTitle">{txt}</span>}</FM>
            <div className="ContactPageInfoBlock__ItemContent">
              {cell || '----'}
              {['crmLanguage', 'justCountry', 'justState', 'justCity', 'justAddress'].includes(titleId) &&
              isEditVisible ? (
                <button type="button" onClick={() => setCrmModalType(modalType)}>
                  <div className="ClientPage__left-icon-edit">
                    <IconEdit />
                  </div>
                </button>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>

    <style jsx>{staticStyles}</style>
  </div>
);

ContactPageInfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  titleValues: PropTypes.object,
  setCrmModalType: PropTypes.func.isRequired,
};

ContactPageInfoBlock.defaultProps = {
  titleValues: {},
};

export default ContactPageInfoBlock;
