import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .Auth__message {
    padding: 15px 20px;
    margin: 25px 0 0;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Auth__message {
      background: ${rgba([colors.warning.rgb, 0.35])};
    }
  `;
};

export { staticStyles, getDynamicStyles };
