import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { LoginCell, MoneyCell, BaseTable, BasicModal, ActionsButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';
import { EditIcon } from 'common/icons';

import UserNoInfoPanel from '../../../_components/UserNoInfoPanel';
import UserPartnerAccountEditForm from '../UserPartnerAccountEditForm';

import { staticStyles, getDynamicStyles } from './style';

const UserPartnerAccountsTable = ({
  data,
  rewardFrequencies,
  accountCreatePerm,
  intl,
  partnershipConditionsList,
  userId,
  getUserPartnerAccounts,
  simplePartnerSystem,
  theme,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [partnerCode, setPartnerCode] = useState(undefined);

  const openEditUserPartnerAccountModal = (accountId, partnerCode) => {
    setSelectedAccount(accountId);
    setPartnerCode(partnerCode);
  };

  const closeEditUserPartnerAccountModal = () => {
    setSelectedAccount(undefined);
    setPartnerCode(undefined);
  };

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const REWARD_FREQUENCIES = {
    dailyInstant: intl.formatMessage({ id: 'justDailyInstant' }),
    monthlyInstant: intl.formatMessage({ id: 'justMonthlyInstant' }),
    monthlyDelayed: intl.formatMessage({ id: 'justMonthlyDelayed' }),
  };

  const initialConditionId = useMemo(() => {
    const selectedCondition = data.find(item => item.id === selectedAccount)?.partnershipConditions?.title ?? '';
    return partnershipConditionsList.find(item => item.title === selectedCondition)?.id ?? '';
  }, [data, partnershipConditionsList, selectedAccount]);

  const initialFrequency = useMemo(
    () => data.find(item => item.id === selectedAccount)?.frequencyOfPayments,
    [data, selectedAccount]
  );

  /* eslint-disable */
  const editColumn =
    accountCreatePerm && !simplePartnerSystem
      ? [
          {
            Cell: ({ original: { id, partnerCode } }) => (
              <ActionsButton
                edit
                onClickFunc={e => {
                  e.stopPropagation();
                  openEditUserPartnerAccountModal(id, partnerCode);
                }}>
                <EditIcon />
              </ActionsButton>
            ),
            isPreviewCell: true,
          },
        ]
      : [];

  let advancePartnerSystemColumns;
  if (!simplePartnerSystem) {
    advancePartnerSystemColumns = [
      {
        key: 'rewardTemplate',
        Cell: ({ original: { partnershipConditions } }) => (
          <p className="RewardType__cell">{partnershipConditions ? partnershipConditions.title : '—'}</p>
        ),
      },
      {
        key: 'justBalance',
        Cell: ({ original: { balance, loading, loadingFailed } }) => (
          <MoneyCell inTable value={balance} loading={loading} loadingFailed={loadingFailed} />
        ),
        isPreviewCell: true,
      },
      {
        key: 'justFrequencyOfReward',
        Cell: ({ original: { frequencyOfPayments } }) => (
          <p className="RewardType__cell">{REWARD_FREQUENCIES[frequencyOfPayments] ?? (frequencyOfPayments || '—')}</p>
        ),
      },
    ];
  } else {
    advancePartnerSystemColumns = [];
  }

  const columns = [
    {
      key: 'accountId',
      Cell: ({ original: { partnerCode } }) => (
        <LoginCell login={partnerCode} title={intl.formatMessage({ id: 'partnerAccount' })} inTable />
      ),
      isPreviewCell: true,
    },
    {
      key: 'rewardType',
      Cell: ({ original: { partnershipConditions } }) => (
        <p className="RewardType__cell">{partnershipConditions ? partnershipConditions.rewardType : '—'}</p>
      ),
      isPreviewCell: true,
    },
    ...advancePartnerSystemColumns,
    ...editColumn,
  ];
  /* eslint-enable */
  return (
    <div
      className={classNames('UserPartnerAccountsTable', {
        UserPartnerAccountsTable__noAccount: !data.length,
        UserPartnerAccountsTable__small: simplePartnerSystem,
      })}>
      {data.length ? (
        <div className="UserPartnerAccountsTable__body">
          <BaseTable columns={columns} data={data} isResponsive />
        </div>
      ) : (
        <UserNoInfoPanel image={noInfoImage} textId="boNoPartnerAccounts" />
      )}

      <BasicModal
        captionId="editPartnerAccountCaption"
        isOpen={!!selectedAccount}
        onRequestClose={closeEditUserPartnerAccountModal}>
        {!!selectedAccount && (
          <UserPartnerAccountEditForm
            accountId={selectedAccount}
            partnershipConditionsList={partnershipConditionsList}
            partnerCode={+partnerCode}
            rewardFrequencies={rewardFrequencies}
            onSubmitSuccess={() => {
              closeEditUserPartnerAccountModal();
              getUserPartnerAccounts(userId);
            }}
            initialValues={{
              conditionsId: initialConditionId,
              frequencyOfPayments: initialFrequency,
            }}
          />
        )}
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserPartnerAccountsTable.propTypes = {
  data: PropTypes.array.isRequired,
  rewardFrequencies: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  accountCreatePerm: PropTypes.bool.isRequired,
  partnershipConditionsList: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  getUserPartnerAccounts: PropTypes.func.isRequired,
  simplePartnerSystem: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

UserPartnerAccountsTable.defaultProps = {
  theme: {},
};

export default withTheme()(injectIntl(UserPartnerAccountsTable));
export { UserPartnerAccountsTable };
