import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Chart from 'react-apexcharts';
import { injectIntl } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const ColumnChart = ({ intl, theme, firstMonthTitle, firstMonthData, secondMonthTitle, secondMonthData }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const options = {
    chart: {
      fontFamily: 'ubuntu-font, sans-serif',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: '70%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 10,
      colors: 'transparent',
      lineCap: 'round',
    },
    xaxis: {
      categories: [
        intl.formatMessage({ id: 'boRegistered' }),
        intl.formatMessage({ id: 'boDemoAccount' }),
        intl.formatMessage({ id: 'boLiveAccount' }),
        intl.formatMessage({ id: 'boFTD' }),
      ],
      labels: {
        show: true,
        style: {
          colors: ['#43B6E8', '#FF9C2C', '#6AC347', '#39DBD3'],
          fontSize: '14px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: ['#33EEC7', '#FF9C2C', '#6AC347', '#39DBD3', '#F06A66'],
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: ['#43B6E8', '#FFD744', '#A6D750', '#39DBD3', '#F27498'],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    grid: {
      show: true,
      borderColor: '#C5C5C5',
      strokeDashArray: 3,
      position: 'back',
      row: {
        colors: '#cfcfcf',
        opacity: 0.03,
      },
      column: {
        colors: '#cfcfcf',
        opacity: 0.03,
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: firstMonthTitle,
      data: firstMonthData,
    },
    {
      name: secondMonthTitle,
      data: secondMonthData,
    },
  ];

  return (
    <div className="ColumnChart__wrapper">
      <Chart className="ColumnChart" options={options} series={series} type="bar" height="300" />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ColumnChart.propTypes = {
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
  firstMonthTitle: PropTypes.string,
  firstMonthData: PropTypes.array,
  secondMonthTitle: PropTypes.string,
  secondMonthData: PropTypes.array,
};

ColumnChart.defaultProps = {
  theme: {},
  firstMonthTitle: '',
  firstMonthData: [],
  secondMonthTitle: '',
  secondMonthData: [],
};

export default compose(injectIntl, withTheme())(ColumnChart);

export { ColumnChart };
