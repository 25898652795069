import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { AsyncSelectField, DefaultButton } from 'common/components';
import { setUserManager } from '../../_redux';
import { getBackofficeManagerList } from '../../api';

import { staticStyles } from './style';

const EditUserManagerForm = ({ user, handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const submit = handleSubmit(values => setUserManager({ ...values, userId: user.id }, dispatch));

  const loadOptions = search =>
    getBackofficeManagerList(search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    );

  return (
    <form className="EditUserManagerForm" onSubmit={submit}>
      <FormattedMessage id="editManagerDescription">
        {txt => <p className="EditUserManagerForm__description">{txt}</p>}
      </FormattedMessage>
      <div className="EditUserManagerForm__inputs-inner">
        <Field
          textId="justManager"
          component={AsyncSelectField}
          name="manager"
          loadOptions={loadOptions}
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={270}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
        />
      </div>

      <DefaultButton
        textId="justChange"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditUserManagerForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditUserManagerForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'edit-user-manager',
})(EditUserManagerForm);
export { EditUserManagerForm };
