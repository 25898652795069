import React from 'react';
import PropTypes from 'prop-types';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import Tooltip from '../Tooltip';

import { staticStyles, getDynamicStyles } from './style';

const FieldHelpText = ({ tooltipId, tooltipTextId, formattedValues, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  return (
    <div className="FieldHelpText">
      {tooltipTextId && (
        <Tooltip id={tooltipId} textId={tooltipTextId} formattedValues={formattedValues}>
          <span />
        </Tooltip>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

FieldHelpText.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  tooltipTextId: PropTypes.string.isRequired,
  formattedValues: PropTypes.object,
  theme: PropTypes.object,
};

FieldHelpText.defaultProps = {
  formattedValues: {},
  theme: {},
};

export default withTheme()(FieldHelpText);
export { FieldHelpText };
