import React from 'react';
import PropTypes from 'prop-types';
import { MoneyCell } from 'common/components';
import InfoPanel from '../InfoPanel';
import { staticStyles } from './style';

const InvestmentInfoPanel = ({ investStatement }) => {
  const cells = [
    {
      captionId: 'justEquity',
      displayValue: <MoneyCell value={investStatement.equity} />,
    },
  ];

  return (
    <div className="InvestmentInfoPanel">
      <InfoPanel cells={cells} />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestmentInfoPanel.propTypes = {
  investStatement: PropTypes.object,
};

InvestmentInfoPanel.defaultProps = {
  investStatement: {},
};

export default InvestmentInfoPanel;
