import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .mce-edit-area > iframe {
    min-height: rem(200px) !important;
    height: auto !important;
  }

  .CreateStrategy {
    &__content {
      width: 450px;
      max-width: 100%;
    }

    &__checkbox {
      margin: 0 0 25px 0;
    }

    &__error {
      color: #e33046;
      margin-top: -30px;
      margin-bottom: 25px;
    }

    &__label-error {
      color: #e33046;
    }

    &__min-invest-amount-text {
      font-size: 12px;
      line-height: 18px;
      color: #777777;
      margin: -28px 0 20px 0;
    }

    :global(.ReduxTextField.error + &__min-invest-amount-text) {
      margin: -12px 0 20px 0;
    }

    &__autoinvestment {
      margin: 0 0 25px 0;
    }

    &__enableoperations {
      margin: 0 0 25px 0;
    }
  }

  .CreateInvestStrategyForm {
    .caption {
      margin-bottom: 10px;
    }

    &__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      :global(.DefaultButton) {
        width: calc(50% - 10px);
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      :global(.BaseSelectField) {
        width: 100%;
      }
      :global(.BaseTextField) {
        width: 100%;
      }
    }

    .formError {
      color: #e33046;
      margin-bottom: 25px;
    }

    @include respond-to(xs) {
      .BaseSelectField__label[for='rewardAccount'],
      .BaseSelectField__label[for='frequencyType'] {
        span {
          max-width: 215px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .CreateStrategy {
    &__min-invest-amount {
    }

    &__min-invest-amount-text {
      font-size: 12px;
      line-height: 18px;
      color: #777777;
      margin: -28px 0 20px 0;
    }

    :global(.ReduxTextField.error + &__min-invest-amount-text) {
      margin: -12px 0 20px 0;
    }
  }
`;

export default staticStyles;
