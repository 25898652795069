import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .CardsPage {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
  }
`

export { staticStyles }
