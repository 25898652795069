import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const CHANGE_REASSIGN_TASK_STATUS = createRequestTypes('CHANGE_REASSIGN_TASK_STATUS');
export const changeReassignTaskStatus = {
  request: data => action(CHANGE_REASSIGN_TASK_STATUS[REQUEST], data),
  success: data => action(CHANGE_REASSIGN_TASK_STATUS[SUCCESS], { data }),
  failure: error => action(CHANGE_REASSIGN_TASK_STATUS[FAILURE], { error }),
};

export const GET_CRM_FAST_FILTERS = createRequestTypes('GET_CRM_FAST_FILTERS');
export const getCRMFastFilters = {
  request: () => action(GET_CRM_FAST_FILTERS[REQUEST]),
  success: data => action(GET_CRM_FAST_FILTERS[SUCCESS], { data }),
  failure: error => action(GET_CRM_FAST_FILTERS[FAILURE], { error }),
};

export const GET_CRM_CUSTOM_FILTER = createRequestTypes('GET_CRM_CUSTOM_FILTER');
export const getCRMCustomFilter = {
  request: id => action(GET_CRM_CUSTOM_FILTER[REQUEST], id),
  success: data => action(GET_CRM_CUSTOM_FILTER[SUCCESS], { data }),
  failure: error => action(GET_CRM_CUSTOM_FILTER[FAILURE], { error }),
};

export const SET_CRM_CUSTOM_FILTER = 'SET_CRM_CUSTOM_FILTER';
export const setCRMCustomFilter = data => action(SET_CRM_CUSTOM_FILTER, { data });

export const SET_SELECTED_FAST_FILTERS = 'SET_SELECTED_FAST_FILTERS';
export const setSelectedFastFilters = data => action(SET_SELECTED_FAST_FILTERS, { data });

export const SET_MODIFIED_FAST_FILTERS = 'SET_MODIFIED_FAST_FILTERS';
export const setModifiedFastFilters = data => action(SET_MODIFIED_FAST_FILTERS, { data });

export const GET_CRM_FILTERS_LIST = createRequestTypes('GET_CRM_FILTERS_LIST');
export const getCRMFiltersList = {
  request: () => action(GET_CRM_FILTERS_LIST[REQUEST]),
  success: data => action(GET_CRM_FILTERS_LIST[SUCCESS], { data }),
  failure: error => action(GET_CRM_FILTERS_LIST[FAILURE], { error }),
};

export const GET_CRM_FILTERS_OPTIONS = createRequestTypes('GET_CRM_FILTERS_OPTIONS');
export const getCRMFiltersOptions = {
  request: () => action(GET_CRM_FILTERS_OPTIONS[REQUEST]),
  success: data => action(GET_CRM_FILTERS_OPTIONS[SUCCESS], { data }),
  failure: error => action(GET_CRM_FILTERS_OPTIONS[FAILURE], { error }),
};

export const createCRMCustomFilter = createFormAction('CREATE_CRM_CUSTOM_FILTER');

export const GET_USER_ACCOUNT_TRADE_OPEN = createRequestTypes('GET_USER_ACCOUNT_TRADE_OPEN');
export const getUserAccountTradeOpen = {
  request: (id, idAccount, search = '') => action(GET_USER_ACCOUNT_TRADE_OPEN[REQUEST], { id, idAccount, search }),
  success: data => action(GET_USER_ACCOUNT_TRADE_OPEN[SUCCESS], { data }),
  failure: error => action(GET_USER_ACCOUNT_TRADE_OPEN[FAILURE], { error }),
};

export const GET_USER_ACCOUNT_TRADE_CLOSED = createRequestTypes('GET_USER_ACCOUNT_TRADE_CLOSED');
export const getUserAccountTradeClosed = {
  request: (id, idAccount, search = '') => action(GET_USER_ACCOUNT_TRADE_CLOSED[REQUEST], { id, idAccount, search }),
  success: data => action(GET_USER_ACCOUNT_TRADE_CLOSED[SUCCESS], { data }),
  failure: error => action(GET_USER_ACCOUNT_TRADE_CLOSED[FAILURE], { error }),
};

export const GET_USER_ACCOUNT_TRADE_DELAYED = createRequestTypes('GET_USER_ACCOUNT_TRADE_DELAYED');
export const getUserAccountTradeDelayed = {
  request: (id, idAccount, search = '') => action(GET_USER_ACCOUNT_TRADE_DELAYED[REQUEST], { id, idAccount, search }),
  success: data => action(GET_USER_ACCOUNT_TRADE_DELAYED[SUCCESS], { data }),
  failure: error => action(GET_USER_ACCOUNT_TRADE_DELAYED[FAILURE], { error }),
};
