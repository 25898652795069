import React from 'react';
import PropTypes from 'prop-types';

import { FormattedDate } from 'react-intl';

import classNames from 'classnames';
import staticStyles from './style';

const DateCell = ({ value, hideTime, nowrap, inLine, red, grey }) => (
  <div
    className={classNames('DateCell', {
      'DateCell--nowrap': nowrap,
      'DateCell--inLine': inLine,
      'DateCell--red': red,
      'DateCell--grey': grey,
    })}>
    <FormattedDate month="long" day="2-digit" year="numeric" value={value} />
    {!hideTime && (
      <span className="DateCell__time">
        <FormattedDate hour="2-digit" minute="2-digit" value={value} />
      </span>
    )}
    <style jsx>{staticStyles}</style>
  </div>
);

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
  hideTime: PropTypes.bool,
  nowrap: PropTypes.bool,
  inLine: PropTypes.bool,
  red: PropTypes.bool,
};

DateCell.defaultProps = {
  hideTime: false,
  nowrap: false,
  inLine: false,
  red: false,
};

export default DateCell;
