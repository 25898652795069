import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/screen-size';
  .ProvidersPopup__popup {
    position: absolute;
    z-index: 10;
    min-height: 50px;
    background-color: #ffffff;
    width: max-content;
    border-radius: 3px;
    box-shadow: 13px 2px 31px 17px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      position: absolute;
      content: '';
      border: 8px solid transparent;
      border-left: 9px solid #fff;
      right: -17px;
      top: 6px;
      z-index: 2;
      @include respond-to(sm) {
        border: 8px solid transparent;
        border-bottom: 9px solid #fff;
        right: 104px;
        top: -16px;
      }
    }
  }
  .ProvidersPopup__item {
    position: relative;
    display: inline-flex;
    flex: 1 0 auto;
    justify-content: center;
    transition: all 0.4s ease;
    width: 100%;
    border: none;
    padding: 1.06667rem 1.2rem;
    cursor: pointer;
    &:hover,
    &:focus {
      box-shadow: 0 1px 6px 1px rgba(10, 161, 226, 0.5);
      z-index: 1;
    }
  }
`;

export default staticStyles;
