import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageLayout } from 'common/components';
import CreateTemplateForm from '../../_components/CreateTemplateForm';

import { getTemplateList } from '../../_redux';

const CreateTemplatePage = ({ availableLangs }) => {
  const history = useHistory();

  return (
    <PageLayout captionSlug="justAddTemplate">
      <div className="CreateTemplatePage">
        <CreateTemplateForm
          history={history}
          languages={availableLangs.map(item => ({ value: item.slug, label: item.niceName }))}
        />
      </div>
    </PageLayout>
  );
};

CreateTemplatePage.propTypes = {
  availableLangs: PropTypes.array,
};

CreateTemplatePage.defaultProps = {
  availableLangs: [],
};

export default connect(
  state => ({
    availableLangs: state.interfaceConfig.availableLangs,
  }),
  {
    getTemplateList: search => getTemplateList.request(search),
  }
)(CreateTemplatePage);
