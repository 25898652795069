import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .ClientsCellWithDropDown {
    font-size: rem(15px);
    &__dropdown {
      z-index: 999;
      position: absolute;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 3px #ccc;
    }
    &__dropdown-item {
      padding: 10px;
      font-size: rem(17px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    :global(.ClientsCellWithDropDown__clients) {
      color: ${colors.primary.hex};
    }

    :global(.ClientsCellWithDropDown__clients:hover) {
      font-weight: 500;
      color: ${colors.secondary.hex};
    }
    :global(.ClientsCellWithDropDown__dropdown) {
      background-color: ${colors.white.hex};
    }
    :global(.ClientsCellWithDropDown__dropdown-item):hover {
      color: ${colors.primary.hex};
      background-color: ${colors.secondary.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
