import { put, call, fork, take, takeEvery, takeLatest, race, select, delay } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST, TOGGLE } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';
import { stateSelector } from './reducer';

const NEW_MESSAGE_DISPLAY_TIMEOUT = 20000;

export function* getMessagesSaga() {
  try {
    const state = yield select(stateSelector);
    const { data } = yield call(api.getMessages, state.messagesCursor);
    yield put(ActionTypes.getMessages.success(data.results, data.next));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getMessages.failure(e));
  }
}

export function* getMessagesWatcherSaga() {
  yield takeLatest(ActionTypes.GET_MESSAGES[REQUEST], getMessagesSaga);
}

export function* toggleMessageSaga({ id }) {
  const { messages } = yield select(stateSelector);
  const message = messages.find(item => item.id === id);
  if (message && message.isNew) {
    yield put(ActionTypes.readMessage.request(id));
  }
}
export function* toggleMessageWatcherSaga() {
  yield takeEvery(ActionTypes.TOGGLE_MESSAGE, toggleMessageSaga);
}

export function* readMessageSaga({ id }) {
  try {
    yield call(api.readMessage, id);
    yield put(ActionTypes.readMessage.success(id));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.readMessage.failure(id, e));
  }
}
export function* readMessageWatcherSaga() {
  yield takeEvery(ActionTypes.READ_MESSAGE[REQUEST], readMessageSaga);
}

export function* deleteMessageSaga({ id }) {
  const { messages } = yield select(stateSelector);
  yield put(ActionTypes.deleteMessage.request(id));

  try {
    yield call(api.deleteMessage, id);
    yield put(ActionTypes.deleteMessage.success(id));
  } catch (e) {
    yield put(acceptError(e));
    const failedMessage = messages.find(item => item.id === id);
    yield put(ActionTypes.deleteMessage.failure(failedMessage, e));
  }
}
export function* deleteMessageWatcherSaga() {
  yield takeEvery(ActionTypes.DELETE_MESSAGE[TOGGLE], deleteMessageSaga);
}

export function* addMessageNotificationSaga({ message }) {
  yield put(ActionTypes.addMessageNotification(message));
  yield race({
    timeout: delay(NEW_MESSAGE_DISPLAY_TIMEOUT),
    hide: take(action => action.type === ActionTypes.HIDE_MESSAGE_NOTIFICATION && action.id === message.id),
  });
  yield put(ActionTypes.removeMessageNotification(message.id));
}
export function* addMessageWatcherSaga() {
  yield takeEvery(ActionTypes.ADD_MESSAGE, addMessageNotificationSaga);
}

export default [
  fork(getMessagesWatcherSaga),
  fork(readMessageWatcherSaga),
  fork(deleteMessageWatcherSaga),
  fork(toggleMessageWatcherSaga),
  fork(addMessageWatcherSaga),
];
