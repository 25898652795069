import css from "styled-jsx/css";

import { rgba, isRTL } from "common/styling/theme";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .IssueMessages__inner {
    direction: ltr;
    padding: rem(${isRTL ? "20px 40px 20px 0" : "20px 0 20px 30px"});
    @include respond-to(sm) {
      padding: rem(${isRTL ? "15px 20px 15px 0" : "15px 0 15px 20px"});
    }
  }

  .IssueMessage {
    margin-bottom: rem(25px);
    position: relative;
    transition: all 0.4s ease;
    width: 100%;
    padding-right: 25px;

    img {
      margin-right: 10px;
      border-radius: 50%;
      width: rem(45px);
      height: rem(45px);
    }
  }

  :global(.IssueMessages__click) {
    cursor: pointer;
  }

  .IssueMessages__line {
    display: flex;
    margin-bottom: 10px;

    .IssueMessages__avatar {
      @include respond-to(sm) {
        .IssueMessages__avatar {
          img {
            width: 100%;
            height: 100%;
            max-width: 30px;
            max-height: 30px;
          }
        }
      }
    }

    :global(.ActionsButton) {
      align-self: center;
      margin-right: rem(10px);
      margin-top: rem(10px);
    }
  }

  .IssueMessages__line:last-child {
    margin-bottom: 0;
  }

  .IssueMessage__support {
    .IssueMessages__line {
      flex-direction: row;

      &.IssueMessage__broker {
        flex-direction: row-reverse;
      }

      .IssueMessages__avatar {
        img {
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }

    .IssueMessages__avatar {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .IssueMessages__info {
    display: flex;
  }

  .IssueMessages__user {
    display: flex;
    align-items: center;
  }

  .IssueMessages__text {
    display: block;
    position: relative;
    width: 100%;
    min-width: 280px;

    pre {
      font-size: 1rem;
      display: block;
      width: 100%;
      word-wrap: break-word;
      word-break: normal;
      border-radius: 0px 15px 15px 15px;
      padding: rem(10px 15px);
    }
    @include respond-to(sm) {
      min-width: auto;
      max-width: 100%;
      pre {
        padding: 10px;
      }
    }
  }

  .IssueMessages__image {
    margin: 10px 10px 0 0;
    position: relative;
    line-height: 0.7;
    :global(img) {
      width: auto;
      max-width: 100%;
      height: 150px;
      border-radius: 0;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 100%;
      }
      @include respond-to(sm) {
        height: 100px;
      }
    }
  }

  .IssueMessage__broker {
    .IssueMessages__text {
      margin-left: 0;
    }
  }

  .IssueMessage__support {
    .IssueMessages__text {
      margin-left: auto;
      pre {
        border-radius: 0px 15px 15px 15px;
      }
    }

    img {
      margin-right: 10px;
      margin-left: 0;
    }

    .IssueMessage__broker {
      .IssueMessages__text {
        pre {
          border-radius: 15px 0 15px 15px;
        }
      }

      img {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  .IssueMessage__with_image {
    .IssueMessages__text {
      border-radius: 20px;
      padding: 15px 20px;
      pre {
        background: transparent !important;
        padding: 0;
        width: 100%;
      }
      @include respond-to(sm) {
        padding: 10px;
      }
    }
  }

  .IssueMessages__scroll {
    max-height: 420px !important;
    @media screen and (min-width: 1921px) {
      max-height: 650px !important;
    }
    @include respond-to(md) {
      max-height: 400px !important;
    }
    > div {
      max-height: 450px !important;
      @media screen and (min-width: 1920px) {
        max-height: 670px !important;
      }
      @include respond-to(md) {
        max-height: 420px !important;
      }
      &:last-child {
        width: 10px !important;
      }
    }
    .IssueMessage__user {
      .IssueMessages__line {
        flex-direction: row-reverse;

        &.IssueMessage__broker {
          flex-direction: row;

          .IssueMessages__info {
            justify-content: flex-start;
          }

          .IssueMessages__text pre {
            border-radius: 0 15px 15px 15px;
          }

          img {
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }

      .IssueMessages__info {
        justify-content: flex-end;
      }

      .IssueMessages__text pre {
        border-radius: 15px 0px 15px 15px;
      }

      img {
        margin-left: 10px;
        margin-right: 0;
      }
    }

    .IssueMessages__edit-description {
      display: flex;
      justify-content: flex-end;
      margin-top: rem(5px);

      span {
        padding-left: rem(5px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import "src/common/styling/_mixins-scss/rem";

    .IssueMessage {
      .IssueMessages__text {
        pre {
          background-color: ${rgba([colors.gradientEnd.rgb, 0.4])};
        }
        a {
          &:hover {
            color: ${colors.primary.hex};
          }
        }
      }
    }

    .IssueMessages__user-name {
      color: ${rgba([colors.black.rgb, 0.6])};
      font-size: 13px;
      font-weight: normal;
    }

    .IssueMessages__edit-description {
      color: ${rgba([colors.gray.rgb, 0.6])};
      font-size: 12px;
      font-weight: 400;
    }

    .IssueMessages__date {
      color: ${rgba([colors.gray.rgb, 0.6])};
      font-size: 13px;
      font-weight: 300;
      display: inline-block;
      margin-bottom: 10px;

      :global(span) {
        margin-left: rem(5px);
      }
    }

    .IssueMessage__with_image {
      .IssueMessages__text {
        background-color: ${rgba([colors.gradientEnd.rgb, 0.4])};
      }
    }

    .IssueMessage__with_image__support {
      .IssueMessages__text {
        margin-left: 0;
        background-color: ${rgba([colors.primary.rgb, 0.3])};
      }

      .IssueMessage__broker {
        .IssueMessages__text {
          margin-left: auto;
        }
      }
    }

    .IssueMessages__scroll {
      > div {
        &:last-child {
          div {
            background: ${rgba([colors.primary.rgb, 0.37])} !important;
          }
        }
      }
    }
    .IssueMessages__content {
      max-width: 80%;
    }

    .IssueMessage__note {
      display: flex;
      flex-direction: column-reverse;
      padding: 10px 15px;
      padding-right: 40px;
      position: relative;

      &::before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        right: 25px;
        top: 0;
        background: ${colors.primary.hex};
      }

      .IssueMessages__line {
        flex-direction: row-reverse;
        margin-bottom: 0;
        margin-top: 10px;
      }

      .IssueMessages__avatar {
        display: none;
      }

      .IssueMessages__text {
        background: transparent;
        pre {
          font-size: rem(13px);
          color: #818181;
          padding: 0;
          background-color: transparent;
          text-align: ${isRTL ? "right" : "left"};
          min-width: auto;
        }
      }
      .IssueMessages__date {
        font-size: rem(13px);
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
