import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import moment from 'moment';

import { DateCell } from 'common/components';
import ReactionForm from 'crm/tasks/_components/ReactionForm';
import { BaseIconButton } from 'crm/_components';

import UserIcon from './icons/UserIcon.svg';

import { staticStyles } from './style';

const TaskItem = ({
  assignedTo,
  title,
  description,
  expirationDate,
  managerReaction,
  whichEditTaskOpen,
  setWhichEditTaskOpen,
  id,
  comment,
}) => {
  const [selectedReaction, setSelectedReaction] = useState(null);

  useEffect(() => {
    setSelectedReaction(managerReaction);
  }, [managerReaction]);

  const handleSelectReaction = reaction => {
    setWhichEditTaskOpen(id);
    setSelectedReaction(reaction);
  };

  const doesTaskExpireSoon = expirationDate => {
    const receivedDate = moment(expirationDate);
    const currentDate = moment();
    const hoursDifference = currentDate.diff(receivedDate, 'hours');

    return Math.abs(hoursDifference) <= 24;
  };

  return (
    <div className="TaskItem">
      <div className="TabFeedItem__Title">
        <FM id="justTask" />
      </div>
      <div className="TaskItem__Title">{title}</div>
      <div className="TaskItem__ExpirationDate">
        <FM id="crmDueDate">{txt => txt}</FM>:
        <DateCell red={doesTaskExpireSoon(expirationDate)} value={expirationDate} />
      </div>
      <div className="TaskItem__Description">{description !== null ? description : ''}</div>
      <div className="TaskItem__AssignedTo">
        <div className="TaskItem__AssignedTo--userIcon">
          <img src={UserIcon} alt="user" />
        </div>
        {assignedTo}
      </div>
      <div className="TaskItem__Reaction">
        {!managerReaction && !selectedReaction && (
          <BaseIconButton
            iconType="check"
            className="TaskItem__CheckBtn"
            onClick={() => handleSelectReaction('Success')}
          />
        )}
        {!managerReaction && selectedReaction && whichEditTaskOpen === id ? (
          <ReactionForm
            taskId={id}
            setSelectedReaction={setSelectedReaction}
            selectedReaction={selectedReaction}
            setWhichEditTaskOpen={setWhichEditTaskOpen}
          />
        ) : null}
      </div>
      {comment && (
        <>
          <div className="TaskItem__Comment">{comment}</div>
          {managerReaction && whichEditTaskOpen !== id && (
            <div className="TabFeedItem__Buttons">
              <BaseIconButton onClick={() => setWhichEditTaskOpen(id)} iconType="edit" />
            </div>
          )}

          {!!managerReaction && whichEditTaskOpen === id && (
            <ReactionForm
              taskId={id}
              setSelectedReaction={setSelectedReaction}
              selectedReaction={selectedReaction}
              setWhichEditTaskOpen={setWhichEditTaskOpen}
            />
          )}
        </>
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TaskItem.propTypes = {
  setWhichEditTaskOpen: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  whichEditTaskOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assignedTo: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  expirationDate: PropTypes.string,
  managerReaction: PropTypes.string,
  comment: PropTypes.string,
};

TaskItem.defaultProps = {
  assignedTo: '',
  description: '',
  title: '',
  expirationDate: '',
  managerReaction: '',
  comment: '',
  whichEditTaskOpen: null,
};

export default TaskItem;
