import css from 'styled-jsx/css';

import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NewFieldHelpText {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    &__Question {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .NewFieldHelpText {
      .Tooltip__text {
        background: ${rgba([colors.gray.rgb, 0.4])};
        color: ${colors.white.hex};
        &:hover {
          background: ${rgba([colors.primary.rgb, 0.4])};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
