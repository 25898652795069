import React from 'react';
import PropTypes from 'prop-types';

import { staticStyles } from './style';

const FlagImage = ({ flag, country }) => (
  <div className="FlagImage">
    {flag === 'Unknown' || !flag ? (
      <img className="FlagImage__image" src="/countryIcons/_generic.png" alt="unknown country" />
    ) : (
      <img className="FlagImage__image" src={`/countryIcons/${flag.toLowerCase()}.png`} alt="country" />
    )}
    <span>{country}</span>
    <style jsx>{staticStyles}</style>
  </div>
);

FlagImage.propTypes = {
  flag: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default FlagImage;
