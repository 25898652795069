import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.PhoneInputCountry) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
  }

  .PhoneInputCountrySelectArrow {
    width: 6px;
    height: 6px;
  }

  .PhoneInputInput {
    padding-top: rem(15px);
    padding-left: rem(15px);
  }

  .PhoneInputCountryIcon {
    width: 19px;
    height: 14px;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .PhoneInputCountryIcon--international {
    border: none !important;
    width: 16px;
    height: 16px;
  }

  @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
    .PhoneInputCountryIcon--international {
      width: 15px;
    }
  }

  .PhoneInputCountryIconImg {
    width: 100%;
    height: 100%;
  }

  .PhoneField {
    position: relative;
    margin-bottom: 10px;
    input {
      min-height: rem(54px);
      position: relative;
      z-index: 4;
    }
  }

  .PhoneField__label {
    display: inline-block;
    position: absolute;
    top: calc(37% + 1px);
    transform: translateY(-50%);
    left: ${isRTL ? 'auto' : '70px'};
    right: ${isRTL ? '70px' : 'auto'};
    transition: all 0.25s ease;
    cursor: text;

    &.haveText {
      top: 6px;
      font-size: rem(12px);
      transform: translateY(0);
      z-index: 4;
    }

    &.active {
      top: 6px;
      font-size: rem(12px);
      transform: translateY(0);
      z-index: 4;
    }
  }

  .PhoneField__error {
    width: 100%;
    display: inline-block;
    min-height: 15px;
  }

  .PhoneField {
    input:disabled {
      opacity: 1 !important;
    }
    input:disabled + label {
      cursor: default !important;
      opacity: 1 !important;
    }
    &__disabled {
      .PhoneField__label {
        z-index: 5;
        cursor: default;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .PhoneField__label {
      transition: none;
    }
  }

  @supports (-ms-ime-align: auto) {
    .PhoneField__label {
      transition: none;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .PhoneInputCountry .rrui__select__autocomplete {
      ::placeholder {
        color: ${colors.white.hex} !important;
      }
    }

    .PhoneInputCountry,
    .PhoneInputInput {
      border: 1px solid ${colors.lightGray.hex};
      border-radius: ${border.radius};
      &:focus {
        border-color: ${colors.primary.hex};
      }
    }

    .PhoneInputCountry-select {
      &:focus {
        .rrui__select__arrow {
          &:after {
            border-color: ${colors.primary.hex};
          }
        }
      }
    }

    .PhoneField {
      input:focus {
        border-color: ${colors.primary.hex};
      }
      &.error {
        input,
        input:focus,
        .rrui__select__button,
        .rrui__select__arrow:after,
        .PhoneInputCountry {
          border-color: ${colors.error.hex};
        }
        .PhoneInputCountrySelectArrow {
          border-color: ${colors.error.hex};
          opacity: 1;
        }
        label,
        .rrui__select__arrow {
          color: ${colors.error.hex};
        }
        :global(svg) {
          fill: ${colors.error.hex};
        }
      }
      input:disabled {
        background: ${colors.disabled.hex};
        color: ${colors.disabled.hex};
        -webkit-text-fill-color: ${colors.black.hex};
        &.rrui__input-element {
          color: ${colors.black.hex};
          -webkit-text-fill-color: ${colors.black.hex};
        }
      }
    }

    .PhoneField__label {
      color: ${colors.gray.hex};
      &.haveText {
        color: ${colors.gray.hex};
      }
      &.active {
        color: ${colors.primary.hex};
      }
    }

    .PhoneField__error {
      color: ${colors.error.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
