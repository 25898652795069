import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormMessageHOC } from 'common';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useParams, useHistory } from 'react-router-dom';
import { BasicModal, DefaultButton } from 'common/components';

import { confirmPaymentsOperation } from '../../_redux';

const PaymentsConfirmationPage = ({ handleSubmit }) => {
  const { confirmationId, paymentId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const submit = handleSubmit(() => confirmPaymentsOperation({ confirmationId, paymentId }, dispatch));
  return (
    <BasicModal captionId="paymentsConfirmPaymentsOperation" isOpen onRequestClose={() => history.push('/')}>
      <form onSubmit={submit}>
        <DefaultButton type="submit" textId="justConfirm" />
      </form>
    </BasicModal>
  );
};

PaymentsConfirmationPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'PaymentsConfirmationForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('justOperationConfirmed');
    },
  })
)(PaymentsConfirmationPage);
