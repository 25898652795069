import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, ReduxSelectField } from 'common/components';

import { setUserTag } from '../../_redux';

import { staticStyles } from './style';

const UserAddTagForm = ({ id, tags, handleSubmit, pristine, submitting, invalid, dispatch, error }) => {
  const submit = handleSubmit(values => setUserTag({ ...values, id }, dispatch));

  return (
    <form className="UserAddTagForm" onSubmit={submit}>
      <div className="UserAddTagForm__inputs-inner">
        <Field
          component={ReduxSelectField}
          name="tags"
          maxMenuHeight={100}
          textId="justTag"
          options={tags.map(tag => ({ value: tag.id, label: `${tag.name} (${tag.description})` }))}
          validate={[required()]}
          disabled={submitting}
          searchable
          multi
        />
      </div>
      <DefaultButton
        textId="justAdd"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserAddTagForm.propTypes = {
  id: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UserAddTagForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'user-add-tag-form',
})(UserAddTagForm);
export { UserAddTagForm };
