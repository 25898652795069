import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { PageLayout, PanelTabs } from 'common/components';
import MyBonusesPage from '../MyBonusesPage';
import PerBonusesPage from '../PerBonusesPage';

const BonusCommonPage = ({ match, history, location }) => {
  const activeTabSlug = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : 'bonuses'),
    [location.search]
  );

  return (
    <PageLayout captionSlug="menuMyBonuses" extraCaptionText={match.params.id} showReload={false}>
      <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
        <MyBonusesPage textId="menuAllBonuses" slug="bonuses" id="bonuses" />
        <PerBonusesPage textId="justPeriodicalBonuses" slug="perBonuses" id="perBonuses" />
      </PanelTabs>
    </PageLayout>
  );
};

BonusCommonPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default BonusCommonPage;
