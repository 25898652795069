import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates-gte-react-17';
import { Field } from 'redux-form';
import Select from 'react-select';
import MediaQuery from 'react-responsive';
import { withTheme } from 'common/styling/theme';
import CalendarIcon from './CalendarIcon';

import { staticStyles, getDynamicStyles } from './style';

const monthOptions = moment.months().map((label, value) => ({ value, label }));
const yearsOptions = [...Array(121).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const getBeautyTime = time => {
  if (time > 0 && time < 10) {
    return `0${time}`;
  }
  return time;
};

const DateField = props => {
  const {
    input,
    textId,
    areFutureDaysAvailable,
    text,
    placeholder,
    textValues,
    disabled,
    openDirection,
    meta: { touched, active, error },
    schema,
    theme,
    autoFocus,
    withTime,
    minDate,
  } = props;
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [date, setDate] = useState(input.value ? moment(input.value) : null);
  const [mobileDate, setMobileDate] = useState(
    input.value ? moment(input.value).format(`YYYY-MM-DD${withTime ? '[T]HH:mm' : ''}`) : ''
  );
  const [focused, setFocused] = useState(autoFocus);
  const [hour, setHour] = useState(input.value ? getBeautyTime(moment(input.value).hours()) : '00');
  const [min, setMin] = useState(input.value ? getBeautyTime(moment(input.value).minutes()) : '00');
  const hourRef = useRef();
  const minRef = useRef();
  const dateRef = useRef(null);

  useEffect(() => {
    if (dateRef.current && focused && withTime) {
      dateRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [focused, withTime]);

  const getValueAsString = date => (date ? date.toISOString() : '');

  const handleDateChange = date => {
    setDate(date);
    input.onChange(getValueAsString(date));
  };

  const dateStr = getValueAsString(date);

  const handleFocusChange = e => {
    setFocused(e.focused);
    if (e.focused) {
      input.onFocus(dateStr);
    } else {
      input.onBlur();
    }
  };

  const renderHiddenField = field => <input {...field.input} type="hidden" />;

  const handleMobileDateEvent = (type, e) => {
    setMobileDate(e.target.value);
    input[type](moment(mobileDate).toISOString());
  };

  const isOutsideRange = day => {
    if (areFutureDaysAvailable) return false;
    if (minDate) return !(day.isAfter(moment(minDate)) && day.isBefore(moment(), 'days'));

    return day.isAfter(moment()) && !moment(day).isSame(moment(), 'day');
  };

  const handleHourChange = e => {
    const hour = Number(e.target.value);
    if (hour < 0 || hour > 23) {
      return;
    }
    setHour(getBeautyTime(hour));
    hourRef.current.value = hour;
    const newDate = (date || moment()).set('hour', Number(hour));
    input.onChange(getValueAsString(newDate));
  };

  const handleBlur = time => e => {
    const newDate = (date || moment()).set(time, Number(e.target.value));
    setDate(newDate);
    input.onChange(getValueAsString(newDate));
  };

  const handleMinuteChange = e => {
    const min = Number(e.target.value);
    if (min < 0 || min > 59) {
      return;
    }
    setMin(getBeautyTime(min));
    minRef.current.value = min;
    const newDate = (date || moment()).set('minute', Number(min));
    input.onChange(getValueAsString(newDate));
  };

  const renderDatePresets = () => (
    <div className="DateField__presets">
      <FM id="justTime">{txt => <span className="DateField__time-caption">{txt}</span>}</FM>
      <input
        className="DateField__time"
        value={hour}
        onChange={handleHourChange}
        onBlur={handleBlur('hour')}
        type="number"
        min="0"
        max="23"
        ref={hourRef}
        placeholder="00"
      />
      <span className="DateField__time-separator">:</span>
      <input
        className="DateField__time"
        value={min}
        onChange={handleMinuteChange}
        onBlur={handleBlur('minute')}
        type="number"
        min="0"
        max="59"
        ref={minRef}
        placeholder="00"
      />
    </div>
  );

  return (
    <div ref={dateRef} className={classNames('DateField__inner', { DateField__schema: !!Object.keys(schema).length })}>
      <div
        className={classNames(
          'DateField',
          { DateField__error: touched && error },
          { DateField__disabled: disabled },
          { active },
          { haveText: input.value },
          { 'DateField__openDirection--up': openDirection === 'up' },
          { 'DateField__openDirection--down': openDirection === 'down' }
        )}>
        <MediaQuery query="(min-width: 768px)">
          <Field {...props} name={`_hidden_${input.name}`} value={dateStr} component={renderHiddenField} />
          <SingleDatePicker
            id={input.name}
            date={input.value ? date : null}
            focused={focused}
            onDateChange={handleDateChange}
            onFocusChange={handleFocusChange}
            isOutsideRange={isOutsideRange}
            numberOfMonths={1}
            displayFormat={withTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
            placeholder={placeholder}
            disabled={disabled}
            openDirection={openDirection}
            renderCalendarInfo={withTime ? renderDatePresets : null}
            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
              <div className="DateField__years-navigation">
                <div className="DateField__month-inner">
                  <Select
                    value={monthOptions[month.month()]}
                    onChange={e => {
                      onMonthSelect(month, e.value);
                      handleDateChange(month.set('month', e.value));
                    }}
                    isClearable={false}
                    isSearchable={false}
                    noOptionsMessage={() => null}
                    hideSelectedOptions
                    menuPlacement="bottom"
                    placeholder=""
                    classNamePrefix="Select"
                    maxMenuHeight={240}
                    options={monthOptions}
                  />
                </div>
                <div className="DateField__years-inner">
                  <Select
                    value={{ value: month.year(), label: month.year() }}
                    onChange={e => {
                      onYearSelect(month, e.value);
                      handleDateChange(month.set('year', e.value));
                    }}
                    isClearable={false}
                    isSearchable={false}
                    noOptionsMessage={() => null}
                    hideSelectedOptions
                    menuPlacement="bottom"
                    placeholder=""
                    classNamePrefix="Select"
                    maxMenuHeight={240}
                    options={yearsOptions}
                  />
                </div>
              </div>
            )}
          />
        </MediaQuery>

        <MediaQuery query="(max-width: 767px)">
          <input
            id={input.name}
            name={input.name}
            type={withTime ? 'datetime-local' : 'date'}
            value={input.value ? mobileDate : ''}
            max={moment().format('YYYY-MM-DD')}
            className="DateField__mobile"
            onChange={e => handleMobileDateEvent('onChange', e)}
            onFocus={e => handleMobileDateEvent('onFocus', e)}
            onBlur={e => handleMobileDateEvent('onBlur', e)}
            disabled={disabled}
          />
        </MediaQuery>

        <label className={classNames('DateField__label', { active }, { haveText: input.value })} htmlFor={input.name}>
          {textId ? <FM id={textId} values={textValues} /> : <span>{(schema && schema.title) || text}</span>}
        </label>

        <MediaQuery query="(min-width: 768px)">
          <CalendarIcon />
        </MediaQuery>
      </div>

      <span className="DateField__error-text">{touched && error}</span>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

DateField.propTypes = {
  autoFocus: PropTypes.bool,
  areFutureDaysAvailable: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  textId: PropTypes.string,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  textValues: PropTypes.object,
  openDirection: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  schema: PropTypes.object,
  theme: PropTypes.object,
  withTime: PropTypes.bool,
  minDate: PropTypes.string,
};

DateField.defaultProps = {
  autoFocus: false,
  areFutureDaysAvailable: false,
  textId: '',
  openDirection: 'down',
  placeholder: '',
  disabled: false,
  text: '',
  textValues: {},
  schema: {},
  theme: {},
  withTime: false,
  minDate: '',
};

export default withTheme()(DateField);
export { DateField };
