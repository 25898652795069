import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, PhoneField } from 'common/components';

import { bindPhone, changeAuthStep } from '../../_redux';

import staticStyles from './style';

export const PhoneBindForm = ({ handleSubmit, pristine, invalid, submitting, error }) => {
  const submit = handleSubmit(bindPhone);

  return (
    <form onSubmit={submit}>
      <div className="PhoneBindForm__input">
        <Field component={PhoneField} name="phone" textId="authPhone" validate={[required()]} disabled={submitting} />
      </div>

      <div className="PhoneBindForm__button-inner">
        <DefaultButton
          textId="authGetCode"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
      </div>

      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

PhoneBindForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

PhoneBindForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'bindPhone',
  onSubmitSuccess: (result, dispatch) => {
    dispatch(changeAuthStep('otp_login'));
  },
})(PhoneBindForm);
