import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const TransferIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="notFillIcon">
    <g clipPath="url(#clip0)">
      <path
        d="M18.5004 20.6C17.1004 20.6 15.9004 19.5 15.9004 18.1C15.9004 16.7 17.1004 15.6 18.5004 15.6C19.9004 15.6 21.1004 16.7 21.1004 18.1C21.1004 19.5 19.9004 20.6 18.5004 20.6Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M2.6 5.59998C1.2 5.59998 0 4.39998 0 3.09998C0 1.69998 1.2 0.599976 2.6 0.599976C4 0.599976 5.2 1.69998 5.2 3.09998C5.2 4.39998 4.1 5.59998 2.6 5.59998Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M14 2.20002H10L11.7 1.10002C12 0.900024 12 0.500024 11.9 0.300024C11.6 2.3514e-05 11.3 -0.0999765 11 0.100024L7.7 2.30002C7.6 2.40002 7.5 2.60002 7.5 2.80002C7.5 3.00002 7.6 3.20002 7.7 3.30002L10.5 6.00002C10.6 6.10002 10.8 6.20002 10.9 6.20002C11.1 6.20002 11.2 6.10002 11.3 6.00002C11.5 5.80002 11.5 5.40002 11.3 5.20002L9.6 3.40002H14C16.1 3.40002 17.9 5.00002 17.9 7.10002V12.7C17.9 13 18.2 13.3 18.5 13.3C18.8 13.3 19.1 13 19.1 12.7V7.10002C19.1 4.40002 16.8 2.20002 14 2.20002ZM10.5 15.2C10.3 15 9.9 15 9.7 15.2C9.5 15.4 9.5 15.8 9.7 16L11.1 17.3H7.1C5 17.3 3.2 15.7 3.2 13.6V8.10002C3.2 7.80002 2.9 7.50002 2.6 7.50002C2.3 7.50002 2 7.70002 2 8.10002V13.7C2 16.4 4.3 18.6 7.1 18.6H11.4L9.3 20C9 20.2 9 20.6 9.2 20.9C9.3 21.1 9.5 21.2 9.7 21.2C9.8 21.2 9.9 21.2 10 21.1L13.2 18.9C13.3 18.8 13.4 18.6 13.5 18.5C13.5 18.3 13.4 18.1 13.3 18L10.5 15.2Z"
        fill="url(#paint2_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="16.8152"
        y1="20.9703"
        x2="21.6645"
        y2="19.4563"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0.914788"
        y1="5.97029"
        x2="5.76408"
        y2="4.45633"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="5.00824"
        y1="22.7704"
        x2="21.5404"
        y2="18.7682"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="21.1" height="21.1" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TransferIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

TransferIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

export default withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(TransferIconActive);
