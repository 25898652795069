import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { PageLayout, PageContentPreloader, StatusCell } from 'common/components';
import { withTheme } from 'common/styling/theme';
import {
  UserInfoPanel,
  ApprovalHistory,
  UserTransactionsHistory,
  UserTradingHistory,
  UserWithdrawRequest,
} from '../../../_components';

import { WithdrawRequestSteps } from '../../_components';

import { getApprovalGroup } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const WithdrawApprovalDetailPage = ({
  approvalGroup,
  approvalGroupIsLoaded,
  getApprovalGroup,
  withdrawApprovalConfirm,
  history,
  match,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const groupId = match.params.id;
  const goToUser = () => history.push(`/backoffice/users/${approvalGroup.user.id}`);

  useEffect(() => {
    getApprovalGroup(groupId);
  }, [getApprovalGroup, groupId]);

  const addons = useSelector(state => state.interfaceConfig.addons);
  const walletAccount = addons.includes('walletAccount');

  return (
    <PageLayout captionSlug="justWithdrawRequestApproving">
      <PageContentPreloader ready={approvalGroupIsLoaded} type="news">
        <div className="WithdrawApprovalDetailPage__info">
          <ul>
            {approvalGroup &&
              approvalGroup.totalAmount &&
              approvalGroup.totalAmount.amount &&
              approvalGroup.totalAmount.currency && (
                <li>
                  <FormattedMessage id="boAmount" />
                  <div className="amount">
                    {approvalGroup.totalAmount.amount} {approvalGroup.totalAmount.currency}
                  </div>
                </li>
              )}
            {approvalGroup && approvalGroup.user && (
              <li>
                <FormattedMessage id="justName" />
                <div>{`${approvalGroup.user.firstName} ${approvalGroup.user.lastName}`}</div>
              </li>
            )}

            {approvalGroup && approvalGroup.user && approvalGroup.user.email && (
              <li>
                <FormattedMessage id="justEmail" />
                <Link to={`/backoffice/users/${approvalGroup.user.id}/`}>{approvalGroup.user.email}</Link>
              </li>
            )}

            {approvalGroup && approvalGroup.assignedTo && (
              <li>
                <FormattedMessage id="justAssigned" />
                <div className="assigned">
                  <FormattedMessage id={approvalGroup.assignedTo === 'ALL' ? 'justAll' : 'justToMe'} />
                </div>
              </li>
            )}

            {/* wallet-balance */}
            {walletAccount && (
              <li>
                <FormattedMessage id="justWalletBalance" />
                <div>{approvalGroup?.user?.walletBalance} USD</div>
              </li>
            )}

            <li>
              <FormattedMessage id="justStatus" />
              <StatusCell statusCode={approvalGroup.status} />
            </li>
          </ul>
        </div>

        {approvalGroup.requests && (
          <UserWithdrawRequest
            totalAmount={approvalGroup.totalAmount}
            requests={approvalGroup.requests}
            canReject={withdrawApprovalConfirm}
          />
        )}

        {groupId && <WithdrawRequestSteps groupId={groupId} requestStatus={approvalGroup.status} />}

        {groupId && <ApprovalHistory groupId={groupId} />}

        {approvalGroup.user && <UserInfoPanel user={approvalGroup.user} onClick={goToUser} />}

        {approvalGroup.user && <UserTransactionsHistory userId={approvalGroup.user.id} />}

        {approvalGroup.user && approvalGroup.requests && (
          <UserTradingHistory
            userId={approvalGroup.user.id}
            accountId={approvalGroup.requests[0].account}
            accountLabel={approvalGroup.requests[0].accountLogin}
          />
        )}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

WithdrawApprovalDetailPage.propTypes = {
  approvalGroup: PropTypes.object.isRequired,
  approvalGroupIsLoaded: PropTypes.bool.isRequired,
  getApprovalGroup: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  withdrawApprovalConfirm: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

WithdrawApprovalDetailPage.defaultProps = {
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      approvalGroup: state.backoffice.payments.approvalGroup,
      approvalGroupIsLoaded: state.backoffice.payments.approvalGroupIsLoaded,
      withdrawApprovalConfirm: state.user.profile.perms.withdrawApprovalConfirm,
    }),
    {
      getApprovalGroup: id => getApprovalGroup.request(id),
    }
  )
)(WithdrawApprovalDetailPage);
export { WithdrawApprovalDetailPage };
