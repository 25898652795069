import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';
  
  .RangeField {
    padding: 0 10px;
    position: relative;
    bottom: 3px;
    @include respond-to(sm) {
      bottom: 0;
    }
    p {
      position: relative;
      bottom: 5px;
      right: 12px;
      margin-bottom: 10px;
    }
  }
  
  .input-range__track {
    transition: none;
  }
  
  .input-range__slider-container {
    transition: none;
  }
  
  .input-range__label {
    font-family: $ubuntu-font;
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .RangeField {
      p {
        color: ${colors.gray.hex};
      }
    }
    
    .input-range__track {
      background: ${colors.lightGray.hex};
    }
    
    .input-range__track--active {
      background: ${colors.primary.hex};
    }
    
    .input-range__slider {
      background: ${colors.primary.hex};
      border: none;
    }
    
    .input-range__label {
      color: ${colors.gray.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }
