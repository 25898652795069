import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_TEMPLATE_LIST = createRequestTypes('GET_TEMPLATE_LIST');
export const getTemplateList = {
  request: search => action(GET_TEMPLATE_LIST[REQUEST], { search }),
  success: data => action(GET_TEMPLATE_LIST[SUCCESS], { data }),
  failure: error => action(GET_TEMPLATE_LIST[FAILURE], { error }),
};

export const createTemplate = createFormAction('CREATE_TEMPLATE');

export const DELETE_TEMPLATE = createRequestTypes('DELETE_TEMPLATE');
export const deleteTemplate = {
  request: data => action(DELETE_TEMPLATE[REQUEST], data),
  success: () => action(DELETE_TEMPLATE[SUCCESS]),
  failure: error => action(DELETE_TEMPLATE[FAILURE], { error }),
};

export const GET_TEMPLATE_ITEM = createRequestTypes('GET_TEMPLATE_ITEM');
export const getTemplateItem = {
  request: id => action(GET_TEMPLATE_ITEM[REQUEST], id),
  success: data => action(GET_TEMPLATE_ITEM[SUCCESS], { data }),
  failure: error => action(GET_TEMPLATE_ITEM[FAILURE], { error }),
};

export const editTemplate = createFormAction('EDIT_TEMPLATE');
