import axios from 'axios';
import queryString from 'query-string';

export const getFiles = search => axios.get(`/files/list/?${queryString.stringify(search)}`);

export const getFile = id => axios.get(`/files/${id}/`);

const multipartConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

export const createFile = body => axios.post('/files/create/', body, multipartConfig);

export const uploadFile = (title, file) => {
  const data = new FormData();
  data.append('title', title);
  data.append('file', file);
  return axios.post(`/files/create/`, data, multipartConfig);
};

export const editFile = (id, body) => axios.put(`/files/${id}/`, body, multipartConfig);

export const deleteFile = id => axios.delete(`/files/${id}/`);

export const getFileExtensions = () => axios.get('/files/extensions/');
