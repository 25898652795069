import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const DefaultAuthPage = ({ captionId, descriptionId, children, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div className="DefaultAuthPage">
      <div className="DefaultAuthPage__top-inner">
        {captionId && <FormattedMessage id={captionId}>{txt => <h3>{txt}</h3>}</FormattedMessage>}
        {descriptionId && <FormattedMessage id={descriptionId} />}
      </div>

      {children}

      <FormattedMessage id="authBackToLogin">
        {txt => (
          <Link className="DefaultAuthPage__link" to="/auth/login">
            {txt}
          </Link>
        )}
      </FormattedMessage>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

DefaultAuthPage.propTypes = {
  captionId: PropTypes.string,
  descriptionId: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
};

DefaultAuthPage.defaultProps = {
  captionId: '',
  descriptionId: '',
  theme: {},
};

export default withTheme()(DefaultAuthPage);
export { DefaultAuthPage };
