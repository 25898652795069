import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import fileIcon from 'common/images/documentDownload.png';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const FileField = ({
  input,
  inputId,
  fileNameId,
  formatNameId,
  multiple,
  disabled,
  accept,
  meta: { touched, error },
  sendHandler,
  handleAfterUpload,
  deletedFiles,
  handleDeleteFile,
  maxSize,
  withCrossButton,
  dropZoneDisabled,
  onSizeError,
  className,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [maxSizeError, setmaxSizeError] = useState(false);
  const [size, setsize] = useState(0);

  const onDropRejected = files => {
    setmaxSizeError(true);
    setsize(+(files[0].size / 1024 / 1024).toFixed(1));
    if (onSizeError) {
      onSizeError({ error: true, size: +(files[0].size / 1024 / 1024).toFixed(1) });
    }
  };

  const files = input.value;

  const handleDrop = files => {
    if (sendHandler) {
      files.forEach(file => sendHandler(input.name, file));
    }
    input.onChange(files);
    setmaxSizeError(false);
    if (onSizeError) {
      onSizeError({ error: false, size: 0 });
    }
    if (handleAfterUpload) {
      handleAfterUpload(files);
    }
  };

  const getFileUrl = tmp => {
    if (tmp instanceof File) {
      return URL.createObjectURL(tmp);
    }
    if (tmp && tmp.file) {
      return tmp.file;
    }
    return tmp;
  };

  const checkFileExtension = tmp => {
    const extensions = ['jpg', 'jpeg', 'tif', 'tiff', 'png', 'gif', 'bmp', 'dib', 'svg'];
    let fileExtension;
    if (tmp instanceof File) {
      fileExtension = tmp.name.split('.').pop();
    } else if (tmp && tmp.file) {
      fileExtension = tmp.file.split('.').pop();
    } else if (tmp && tmp.name) {
      fileExtension = tmp.name.split('.').pop();
    } else {
      fileExtension = tmp.split('.').pop();
    }
    return extensions.includes(fileExtension);
  };

  const handleCrossButtonClick = (e, file) => {
    e.stopPropagation();
    handleDeleteFile(file);
  };

  return (
    <div
      className={classNames('FileField', className, {
        FileField__disabled: disabled,
      })}>
      <div className="FileField__dropzone">
        <Dropzone
          accept={accept}
          disabled={disabled || dropZoneDisabled}
          multiple={multiple}
          onDrop={handleDrop}
          onDropRejected={onDropRejected}
          onBlur={handleDrop}
          maxSize={maxSize}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input id={inputId || input.name} name={input.name} {...getInputProps()} />
              <div
                className={classNames({
                  'FileField__dropzone-plus-active':
                    (multiple && Array.isArray(files) && files.length) || (files && !multiple),
                })}>
                <FormattedMessage id={fileNameId}>
                  {docName => (
                    <FormattedMessage id="dropFile" values={{ file: docName }}>
                      {txt => <p>{txt}</p>}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
                <FormattedMessage id={formatNameId} />
              </div>
              {files && (
                <div className="FileField__file">
                  {Array.isArray(files) ? (
                    files
                      .filter(file => !deletedFiles.includes(file.id))
                      .map((file, i) => (
                        <div
                          className={classNames('FileField__item-inner', {
                            FileField__itemInner_notImage: !checkFileExtension(file),
                          })}
                          key={i}>
                          {checkFileExtension(file) ? (
                            <img src={getFileUrl(file)} alt={file.name} />
                          ) : (
                            <img src={fileIcon} alt={file.name} />
                          )}
                          {withCrossButton && (
                            <button
                              className="FileField__cross-button"
                              type="button"
                              onClick={e => handleCrossButtonClick(e, file)}
                            />
                          )}
                        </div>
                      ))
                  ) : (
                    <div
                      className={classNames('FileField__item-inner', {
                        FileField__itemInner_notImage: !checkFileExtension(files),
                      })}>
                      {checkFileExtension(files) ? (
                        <img src={getFileUrl(files)} alt={files.name} />
                      ) : (
                        <img src={fileIcon} alt={files.name} />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>
      <span className="FileField__error">{touched && error}</span>
      {maxSizeError && (
        <FormattedMessage id="maxSizeIsExceeded" values={{ size, maxSize: +(maxSize / 1024 / 1024).toFixed(1) }}>
          {txt => <p className="FileField__error">{txt}</p>}
        </FormattedMessage>
      )}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

FileField.propTypes = {
  input: PropTypes.object.isRequired,
  fileNameId: PropTypes.string,
  inputId: PropTypes.string,
  formatNameId: PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  deletedFiles: PropTypes.array,
  sendHandler: PropTypes.func,
  handleAfterUpload: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  onSizeError: PropTypes.func,
  maxSize: PropTypes.number,
  withCrossButton: PropTypes.bool,
  dropZoneDisabled: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.object,
};

FileField.defaultProps = {
  fileNameId: '',
  formatNameId: '',
  accept: '',
  inputId: '',
  deletedFiles: [],
  multiple: false,
  disabled: false,
  withCrossButton: false,
  dropZoneDisabled: false,
  sendHandler: null,
  handleAfterUpload: null,
  handleDeleteFile: null,
  onSizeError: null,
  maxSize: 10 * 1024 * 1024,
  className: '',
  theme: {},
  meta: {
    touched: false,
    error: [],
  },
};

export default withTheme()(FileField);
export { FileField };
