import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .RemoveInMobile {
    @media only screen and (max-width: 1150px) {
      display: none !important;
    }
  }

  .UpdatedCRMTable__wrapper {
    position: relative;
    background: white;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .UpdatedCRMTable__Thead,
  .UpdatedCRMTable__Tbody {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .UpdatedCRMTable__Tbody__Cell {
    position: relative;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  .UpdatedCRMTable__Tbody__Cell__SalesStatus {
    position: relative;
    width: 6px;
    height: 56px;
    bottom: 0px;
    left: 0;
    height: 100%;
    border-radius: 0px 3px 3px 0px;
    z-index: 1;
  }
  .UpdatedCRMTable__Tbody__Cell__SalesStatus__Tooltip {
    width: 6px;
    height: 56px;
    bottom: 0px;
    left: 0;
    height: 100%;
    border-radius: 0px 3px 3px 0px;
    z-index: 1;
    position: absolute;
  }

  .UpdatedCRMTable__Thead__Cell {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  .UpdatedCRMTable__Thead__Cell--Sortable {
    position: relative;
    cursor: pointer;
  }

  .UpdatedCRMTable__Thead__Cell--IsCheckboxTemp {
    position: relative;
    justify-self: center;
  }

  .UpdatedCRMTable__Thead__Cell-Inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 4px;
  }
  .UpdatedCRMTable__Thead__Cell-Inner-Top {
    position: relative;
    display: flex;
    height: 20px;
    color: #333;

    font-family: $ubuntu-font !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .UpdatedCRMTable__Thead__Cell-Inner-Bottom {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    height: 14px;
    color: #999;

    /* body/10/regular */
    font-family: $ubuntu-font !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
  }

  .UpdatedCRMTable__Tbody__Cell__SubRow {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;
  }

  .UpdatedCRMTable__Tbody__SubRow {
    margin: 16px 24px 12px 54px;
    padding: 12px 16px;
    background: #fefbfb;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    @media only screen and (max-width: 1150px) {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  .UpdatedCRMTable__Tbody__Cell-Inner__SubRow {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 4px;
  }
  .UpdatedCRMTable__Tbody__Cell-Inner-Top__SubRow {
    position: relative;
    display: flex;
    height: 20px;
    color: #333;

    font-family: $ubuntu-font !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .UpdatedCRMTable__Tbody__Cell-Inner-Bottom__SubRow {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    height: 14px;
    color: #999;

    /* body/10/regular */
    font-family: $ubuntu-font !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
  }

  .UpdatedCRMTable__Tbody__Cell__SubRowIcon {
    height: 19px;
    margin-right: 10px;
    z-index: 3;
  }

  .UpdatedCRMTable__Tbody__Cell__SubRowIcon--NotExpandable {
    display: none;
  }

  .UpdatedCRMTable__Tbody__Cell__SubRowIcon:hover {
    cursor: pointer;
  }

  .UpdatedCRMTable__Tbody__SubRow__Wrapper {
    margin-bottom: 16px;
    animation: fade-in 0.5s forwards;
    opacity: 0;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1; /* end with 100% opacity */
    }
  }
`;

const getDynamicStyles = ({ initGrid }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/rem';
    .UpdatedCRMTable__Thead-Row,
    .UpdatedCRMTable__Tbody-Row {
      /* position: relative;
      display: grid;
      grid-template-columns: ${initGrid};
      grid-auto-rows: minmax(56px, auto);
      gap: 2px;
      word-break: break-word; */
    }

    .UpdatedCRMTable__Thead-Row {
      position: relative;
      border-bottom: 1px solid #f3f3f3;
    }
    .UpdatedCRMTable__Tbody-Row:not(:last-child) {
      position: relative;
      border-bottom: 1px solid #f3f3f3;
    }
  `;
};

export { staticStyles, getDynamicStyles };
