import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .DeleteCampaignForm {
    display: flex;
    flex-direction: column;

    .DeleteCampaignForm__description {
      margin-bottom: rem(20px);
    }

    .DeleteCampaignForm__buttons {
      display: flex;

      :global(.DefaultButton) {
        &:first-child {
          margin-right: rem(20px);
        }
      }
    }
  }
`;
const getDynamicStyles = ({ colors }) => {
  return css.global`
    .DeleteCampaignForm__description {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
