import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ReactModal__Body--open {
    overflow-y: hidden;
    padding-right: 15px;
  }
  
  @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
    .ReactModal__Body--open {
      padding: 0 !important;
    }
  }

  .ReactModal__Overlay--after-open {
    z-index: 34;
    background-color: rgba(0, 0, 0, 0.4) !important;
  }

  .ReactModal__Content--after-open {
    border: none !important;
    border-radius: 0 !important;
    background: transparent !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    padding: 0 !important;
  }

  .LightBoxModal__close {
    position: absolute;
    z-index: 2;
    transition: all 0.4s ease;
    width: 28px;
    height: 28px;
    top: 12px;
    right: 12px;
    @include respond-to(md) {
      top: 8px;
      right: 8px;
    }
    &:hover {
      opacity: 0.6;
    }
    span {
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 32px;
        height: 1px;
        top: 12px;
        left: 0;
        transition: all 0.3s ease;
        @include respond-to(md) {
          width: 24px;
          height: 1px;
        }
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .LightBoxModal__close {
      &:hover {
        span {
          &:before,
          &:after {
            background: ${colors.error.hex};
          }
        }
      }
      span {
        &:before,
        &:after {
          background: ${colors.white.hex};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
