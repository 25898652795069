import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { ReduxSelectField, DefaultButton } from 'common/components';

import { getStatusesList } from 'backoffice/statuses/_redux';
import { editUserStatus } from '../../_redux';

import { staticStyles } from './style';

const EditStatusForm = ({
  user,
  statusInfoList,
  getStatusesList,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const submit = handleSubmit(values => editUserStatus({ ...values, userId: user.id }, dispatch));

  useEffect(() => {
    getStatusesList();
  }, [getStatusesList]);

  const statuses = statusInfoList.map(status => ({ label: status.title, value: status.id }));

  return (
    <form className="EditStatusForm" onSubmit={submit}>
      <div className="EditStatusForm__inputs-inner">
        <Field
          name="statusId"
          textId="justStatus"
          options={statuses}
          component={ReduxSelectField}
          validate={required()}
          disabled={submitting}
        />
      </div>

      <DefaultButton
        textId="justChange"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditStatusForm.propTypes = {
  user: PropTypes.object.isRequired,
  statusInfoList: PropTypes.array.isRequired,
  getStatusesList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditStatusForm.defaultProps = {
  error: '',
};

export default compose(
  connect(
    state => ({
      statusInfoList: state.backoffice.statuses.statusInfoList,
    }),
    {
      getStatusesList: () => getStatusesList.request(),
    }
  ),
  reduxForm({
    form: 'edit-user-status',
  })
)(EditStatusForm);

export { EditStatusForm };
