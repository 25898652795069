import React from 'react';

const ArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.45154 7.60489C5.23332 7.35551 4.85426 7.33025 4.60489 7.54846C4.35551 7.76668 4.33025 8.14574 4.54846 8.39511L5.45154 7.60489ZM12.0003 16L11.5488 16.3951C11.6627 16.5253 11.8273 16.6 12.0003 16.6C12.1733 16.6 12.3379 16.5253 12.4518 16.3951L12.0003 16ZM19.4515 8.39581C19.6698 8.14643 19.6445 7.76737 19.3951 7.54916C19.1457 7.33094 18.7667 7.3562 18.5485 7.60558L19.4515 8.39581ZM4.54846 8.39511L11.5488 16.3951L12.4518 15.6049L5.45154 7.60489L4.54846 8.39511ZM12.4518 16.3951L19.4515 8.39581L18.5485 7.60558L11.5488 15.6049L12.4518 16.3951Z"
      fill="white"
    />
  </svg>
);

export default ArrowIcon;
