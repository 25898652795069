import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from '../actions';

export const initialState = {
  partners: [],
  partnersAreLoaded: false,

  paymentNotes: [],
  paymentNotesDocuments: {},
  paymentNotesDocumentsAreLoaded: false,
  paymentNotesAreLoaded: false,

  exportUserLoading: false,

  counters: {
    users: 0,
    deposits: 0,
    withdrawals: 0,
    internal_transfers: 0,
    support: 0,
  },
  countersAreLoaded: false,

  filtersList: [],
  filtersListIsLoaded: false,

  servers: [],
  serversAreLoaded: false,

  redirect: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENT_NOTES[REQUEST]:
      return { ...state, paymentNotesAreLoaded: false };
    case ActionTypes.GET_PAYMENT_NOTES[SUCCESS]:
      return { ...state, paymentNotes: action.data, paymentNotesAreLoaded: true };
    case ActionTypes.GET_PAYMENT_NOTES[FAILURE]:
      return { ...state, error: action.error, paymentNotesAreLoaded: false };

    case ActionTypes.GET_PARTNERS[REQUEST]:
      return { ...state, partnersAreLoaded: false };
    case ActionTypes.GET_PARTNERS[SUCCESS]:
      return { ...state, partners: action.data, partnersAreLoaded: true };
    case ActionTypes.GET_PARTNERS[FAILURE]:
      return { ...state, error: action.error, partnersAreLoaded: false };

    case ActionTypes.EXPORT_USER_TABLE_DATA[REQUEST]:
      return { ...state, exportUserLoading: true };
    case ActionTypes.EXPORT_USER_TABLE_DATA[SUCCESS]:
      return { ...state, exportUserLoading: false };
    case ActionTypes.EXPORT_USER_TABLE_DATA[FAILURE]:
      return { ...state, exportUserLoading: false };

    case ActionTypes.GET_BACKOFFICE_COUNTERS[REQUEST]:
      return { ...state, countersAreLoaded: false };
    case ActionTypes.GET_BACKOFFICE_COUNTERS[SUCCESS]:
      return { ...state, counters: action.data, countersAreLoaded: true };
    case ActionTypes.GET_BACKOFFICE_COUNTERS[FAILURE]:
      return { ...state, error: action.error, countersAreLoaded: false };

    case ActionTypes.SET_REDIRECT:
      return { ...state, redirect: true };

    case ActionTypes.GET_BO_FILTERS_LIST[REQUEST]:
      return { ...state, filtersListIsLoaded: false };
    case ActionTypes.GET_BO_FILTERS_LIST[SUCCESS]:
      return { ...state, filtersList: action.data, filtersListIsLoaded: true };
    case ActionTypes.GET_BO_FILTERS_LIST[FAILURE]:
      return { ...state, error: action.error, filtersListIsLoaded: false };

    case ActionTypes.createPaymentDocument[REQUEST]:
      return {
        ...state,
        paymentNotesDocumentsAreLoaded: false,
      };

    case ActionTypes.createPaymentNote[SUCCESS]: {
      return { ...state, paymentNotesDocuments: {}, paymentNotesDocumentsAreLoaded: false };
    }
    case ActionTypes.createPaymentDocument[SUCCESS]:
      return { ...state, paymentNotesDocuments: action.payload, paymentNotesDocumentsAreLoaded: true };
    case ActionTypes.createPaymentDocument[FAILURE]:
      return { ...state, error: action.error, paymentNotesDocumentsAreLoaded: false };

    case ActionTypes.editPaymentNote[REQUEST]:
      return {
        ...state,
        paymentNotesDocumentsAreLoaded: false,
      };

    case ActionTypes.editPaymentNote[SUCCESS]:
      return { ...state, paymentNotesDocuments: {}, paymentNotesDocumentsAreLoaded: false };
    case ActionTypes.editPaymentNote[FAILURE]:
      return { ...state, error: action.error, paymentNotesDocumentsAreLoaded: false };

    case ActionTypes.GET_BACKOFFICE_SERVERS[REQUEST]:
      return { ...state, serversAreLoaded: false };
    case ActionTypes.GET_BACKOFFICE_SERVERS[SUCCESS]:
      return { ...state, servers: action.data, serversAreLoaded: true };
    case ActionTypes.GET_BACKOFFICE_SERVERS[FAILURE]:
      return { ...state, error: action.error, serversAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
