import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme, taggedStylesToString } from 'common/styling/theme';

import IconEdit from './icons/IconEdit';
import IconTrash from './icons/iconTrash';
import IconCheck from './icons/iconCheck';

import { staticStyles, getDynamicStyles } from './style';

const icons = {
  plus: <></>,
  cancel: <></>,
  edit: <IconEdit />,
  remove: <IconTrash />,
  check: <IconCheck />,
};

const BaseIconButton = ({ onClick, type, className, color, theme, iconType }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <button
      type={type}
      className={classNames(
        'BaseIconButton',
        color && `BaseIconButton__${color}`,
        `BaseIconButton__${iconType}`,
        `${className}`
      )}
      onClick={onClick}>
      {icons[iconType]}
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </button>
  );
};

BaseIconButton.propTypes = {
  iconType: PropTypes.oneOf(['plus', 'edit', 'remove', 'check', 'cancel']).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.object,
};

BaseIconButton.defaultProps = {
  onClick: () => {},
  type: 'button',
  color: '',
  className: '',
  theme: {},
};

export default withTheme()(BaseIconButton);
