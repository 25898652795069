import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_INNER_TRANSFER_OPTIONS = createRequestTypes('GET_INNER_TRANSFER_OPTIONS');
export const getInnerTransferOptions = {
  request: () => action(GET_INNER_TRANSFER_OPTIONS[REQUEST]),
  success: data => action(GET_INNER_TRANSFER_OPTIONS[SUCCESS], { data }),
  failure: error => action(GET_INNER_TRANSFER_OPTIONS[FAILURE], { error }),
};

export const GET_INNER_TRANSFER_INFO = createRequestTypes('GET_INNER_TRANSFER_INFO');
export const getInnerTransferInfo = {
  request: data => action(GET_INNER_TRANSFER_INFO[REQUEST], data),
  success: data => action(GET_INNER_TRANSFER_INFO[SUCCESS], { data }),
  failure: error => action(GET_INNER_TRANSFER_INFO[FAILURE], { error }),
};

export const createInnerTransfer = createFormAction('CREATE_INNER_TRANSFER');

export const GET_TRADERS_TRANSFER_INFO = createRequestTypes('GET_TRADERS_TRANSFER_INFO');
export const getTradersTransferInfo = {
  request: data => action(GET_TRADERS_TRANSFER_INFO[REQUEST], data),
  success: data => action(GET_TRADERS_TRANSFER_INFO[SUCCESS], { data }),
  failure: error => action(GET_TRADERS_TRANSFER_INFO[FAILURE], { error }),
};

export const createTradersTransfer = createFormAction('CREATE_TRADERS_TRANSFER');

export const GET_INTERNAL_TRANSFER_LIST = createRequestTypes('GET_INTERNAL_TRANSFER_LIST');
export const getInternalTransferList = {
  request: data => action(GET_INTERNAL_TRANSFER_LIST[REQUEST], data),
  success: data => action(GET_INTERNAL_TRANSFER_LIST[SUCCESS], { data }),
  failure: error => action(GET_INTERNAL_TRANSFER_LIST[FAILURE], { error }),
};

export const GET_CLIENT_TRANSFER_DETAILS = createRequestTypes('GET_CLIENT_TRANSFER_DETAILS');
export const getClientTransferDetails = {
  request: data => action(GET_CLIENT_TRANSFER_DETAILS[REQUEST], data),
  success: data => action(GET_CLIENT_TRANSFER_DETAILS[SUCCESS], { data }),
  failure: error => action(GET_CLIENT_TRANSFER_DETAILS[FAILURE], { error }),
};

export const GET_LPA_TRANSFER_ACCOUNTS = createRequestTypes('GET_LPA_TRANSFER_ACCOUNTS');
export const getLpaTransferAccounts = {
  request: id => action(GET_LPA_TRANSFER_ACCOUNTS[REQUEST], { id }),
  success: data => action(GET_LPA_TRANSFER_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(GET_LPA_TRANSFER_ACCOUNTS[FAILURE], { error }),
};

export const createLpaTransfer = createFormAction('CREATE_LPA_TRANSFER');

export const createLpaWalletTransfer = createFormAction('CREATE_LPA_WALLET_TRANSFER');
