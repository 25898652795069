import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BaseSelectField, DefaultButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

class FileItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { os: this.props.item.links[0].link };
  }

  componentDidMount() {
    const { item } = this.props;

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    const checkOsFunc = osName => {
      if (item.links.find(item => item.os_type === osName)) {
        this.setState({ os: item.links.find(item => item.os_type === osName).link });
      } else if (item.links.find(item => item.os_type === 'Windows')) {
        this.setState({ os: item.links.find(item => item.os_type === 'Windows').link });
      } else {
        this.setState({ os: item.links[0].link });
      }
    };

    if (macosPlatforms.indexOf(platform) !== -1) {
      checkOsFunc('Mac OS');
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      checkOsFunc('iOS');
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      checkOsFunc('Windows');
    } else if (/Android/.test(userAgent)) {
      checkOsFunc('Android');
    } else if (/Linux/.test(platform)) {
      checkOsFunc('Linux');
    }
  }

  handleDownloadClick = () => {
    window.open(this.state.os);
  };

  handleOSChange = value => {
    this.setState({ os: value.value });
  };

  handleMobileOSChange = e => {
    this.setState({ os: e.target.value });
  };

  render() {
    const { item, theme } = this.props;
    const { os } = this.state;

    const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

    const selectedOption = item.links.find(link => link.link === os);
    const selectValue = { value: selectedOption.link, label: selectedOption.os_type };

    return (
      <div className="FileItem" data-link={item.links[0].link}>
        <div className="FileItem__logo">
          <img src={item.logo} alt={item.title} />
        </div>

        <div className="FileItem__inner">
          <div className="FileItem__title">
            <p>{item.title}</p>
          </div>
          <div className="FileItem__description">
            <p>{item.description}</p>
          </div>
          <div className="FileItem__chooseOs">
            <FormattedMessage id="justPlatform">{txt => <strong>{txt}</strong>}</FormattedMessage>
            {item.links.length > 1 && (
              <BaseSelectField
                value={selectValue}
                name="osChange"
                options={item.links.map(link => ({ value: link.link, label: link.os_type }))}
                noLabel
                onChange={this.handleOSChange}
                onMobileChange={this.handleMobileOSChange}
              />
            )}
            {item.links.length === 1 && <p>{item.links.map(link => link.os_type)}</p>}
          </div>
          <div className="FileItem__download">
            <DefaultButton textId="justDownload" onClick={() => this.handleDownloadClick()} />
          </div>
        </div>

        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    );
  }
}

FileItem.propTypes = {
  item: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

FileItem.defaultProps = {
  theme: {},
};

export default withTheme()(FileItem);
export { FileItem };
