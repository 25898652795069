import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ContactNoteCreateForm {
    max-width: 500px;
    @include respond-to(sm) {
      max-width: 100%;
    }

    &__inputs-inner {
      margin-bottom: rem(20px);
    }

    :global(.TextFormField__input) {
      margin-bottom: rem(20px);
    }

    &__attach {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-caption {
        display: flex;
        align-items: center;
        gap: 8px;

        &-text {
          font-size: 14px;
          color: #8a8b8e;
        }
      }

      &-label {
        position: relative;
        display: inline-block;
        padding: rem(12px 32px);
        cursor: pointer;
        font-size: rem(14px);
        line-height: 1.4;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        min-width: rem(105px);
        margin: rem(0 20px);

        &:active {
          box-shadow: none;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        @include respond-to(xs) {
          padding: 8px 16px;
        }
      }

      :global(.FileField) {
        display: none;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ContactNoteCreateForm {
      &__attach {
        &-label {
          border-radius: 8px;
          border: 1px solid ${colors.primary.hex};
          color: ${colors.black.hex};

          &:hover {
            border-color: ${rgba([colors.primary.rgb, 0.8])};
            color: ${rgba([colors.black.rgb, 0.8])};
          }

          &:disabled {
            border-color: transparent;
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
