import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Gravatar from 'react-gravatar';
import md5 from 'blueimp-md5';
import PropTypes from 'prop-types';

import { LanguageSelector } from './_components';

import BurgerButton from '../BurgerButton';

import whiteLogo from '../../_public/images/whiteLogo.svg';

import { wsLogout } from '../../_redux';

import { staticStyles } from './style';
import { NewDefaultButton } from '..';

const TopMenu = ({ authorized, logout, brokerNiceName }) => {
  const history = useHistory();

  return (
    <div className="TopMenu">
      <div className="TopMenu__left">
        <BurgerButton />
        <span className="TopMenu__logo">
          <img src={whiteLogo} alt="uptrader-logo" />
        </span>
        <FormattedMessage id="adminInterface">
          {txt => <span className="TopMenu__interface_title">{txt}</span>}
        </FormattedMessage>
      </div>

      <div className="TopMenu__right">
        <LanguageSelector />
        <div className="ClientInfo">
          {authorized ? (
            <>
              <Gravatar md5={md5('admin@uptrader.us')} size={42} className="ClientInfo__avatar" />
              <FormattedMessage id="justYou">
                {txt => <span className="ClientInfo__name">{brokerNiceName || txt}</span>}
              </FormattedMessage>
            </>
          ) : (
            <NewDefaultButton
              textId="justLogIn"
              type="button"
              onClick={() => history.push('/welcome-setup/login')}
              variant="secondary"
              isUpperCase
            />
          )}
          <ul className="ClientInfo__menu">
            {authorized && (
              <li>
                <FormattedMessage id="menuExit">
                  {txt => (
                    <button type="button" onClick={() => logout()}>
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              </li>
            )}
          </ul>
        </div>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

TopMenu.propTypes = {
  authorized: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  brokerNiceName: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    authorized: state.welcomeSetup.authorized,
    brokerNiceName: state.welcomeSetup.brokerNiceName || '',
  }),
  {
    logout: () => wsLogout(),
  }
)(TopMenu);
