import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const SplineChartIcon = ({ primaryColor, secondaryColor }) => (
  <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M6.5 9C2.1 9 1.16667 10.8333 1 12V15H24V1C23 5 17.2772 6.22893 14 5C10 3.5 12 9 6.5 9Z"
      fill={secondaryColor.hex}
    />
    <path d="M1 12C1.16667 10.8333 2.1 9 6.5 9C12 9 10 3.5 14 5C17.2772 6.22893 23 5 24 1" stroke={primaryColor.hex} />
  </svg>
);

SplineChartIcon.propTypes = {
  primaryColor: colorType,
  secondaryColor: colorType,
};

SplineChartIcon.defaultProps = {
  primaryColor: {},
  secondaryColor: {},
};

const ThemedSplineChartIcon = withTheme(({ colors: { gray, lightGray } }) => ({
  primaryColor: gray,
  secondaryColor: lightGray,
}))(SplineChartIcon);

export default ThemedSplineChartIcon;
