import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const TemplatesIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.538 3.619c-.365-1.37-1.628-2.377-3.063-2.377H3.17A3.176 3.176 0 000 4.412v5.245a3.185 3.185 0 002.463 3.084 3.15 3.15 0 003.062 2.377h.47v3.169c0 .171.108.343.258.428a.634.634 0 00.214.043.45.45 0 00.278-.107l4.583-3.533h5.503A3.176 3.176 0 0020 11.948V6.703a3.167 3.167 0 00-2.462-3.083zM2.355 6.702v5.032A2.218 2.218 0 01.942 9.657V4.411A2.24 2.24 0 013.17 2.184h11.328c.9 0 1.691.557 2.034 1.35H5.525a3.176 3.176 0 00-3.17 3.168zM19.08 11.95h-.021a2.24 2.24 0 01-2.227 2.227h-5.653a.407.407 0 00-.279.107l-3.961 3.062v-2.698a.475.475 0 00-.471-.471h-.942a2.24 2.24 0 01-2.227-2.227V6.702a2.24 2.24 0 012.227-2.227h11.327a2.24 2.24 0 012.227 2.227v5.247z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M15.996 6.552H6.36a.475.475 0 00-.471.472c0 .257.214.47.47.47h9.637a.475.475 0 00.47-.47.475.475 0 00-.47-.472z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M15.996 8.608H6.36a.475.475 0 00-.471.471c0 .257.214.471.47.471h9.637a.475.475 0 00.47-.47.475.475 0 00-.47-.472z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M15.996 10.685H6.36a.475.475 0 00-.471.471c0 .257.214.471.47.471h9.637a.475.475 0 00.47-.47.462.462 0 00-.47-.472z"
      fill="url(#paint3_linear)"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="3.518" y1="20.055" x2="21.835" y2="13.777" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="7.75" y1="7.564" x2="8.626" y2="4.612" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="7.75" y1="9.62" x2="8.626" y2="6.668" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="7.75" y1="11.697" x2="8.626" y2="8.745" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStartColor.hex} />
        <stop offset="1" stopColor={gradientEndColor.hex} />
      </linearGradient>
    </defs>
  </svg>
);

TemplatesIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

TemplatesIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

export default withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(TemplatesIconActive);
