import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnershipAccountsPage__button-inner {
    :global(.DefaultButton) {
      position: absolute;
      top: rem(-65px);
      right: 0;
      @include respond-to(sm) {
        position: relative;
        top: 0;
      }
    }
    @include respond-to(sm) {
      margin-bottom: 25px;
    }
  }
`

export { staticStyles }
