// in this file use css not scss, it is because react-beautiful-dnd conflicting with styled-jsx

import { rgba } from 'common/styling/theme';
import { isRTL } from 'common/styling/theme';

const staticStyles = `
  .FieldArray .ObjectField_items {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    margin: 0;
  }

  .FieldArray .FieldArray__no-items {
    height: auto;
    min-height: 100%;
    padding: 0 10px 25px;
  }
  @media only screen and (max-width: 767px) {
    .FieldArray .FieldArray__no-items {
      padding: 6px 15px;
    }
  }

  .FieldArray__top-line {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 767px) {
    .FieldArray__title {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .FieldArray__isDragging-inner {
    transition: all 0.4s ease;
    padding: 15px;
  }

  @media only screen and (max-width: 767px) {
    .FieldArray__isDragging-inner {
      padding: 5px;
    }
  }

  .FieldArray__item-inner {
    outline: none !important;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 767px) {
    .FieldArray__item-inner {
      margin-bottom: 15px;
    }
  }

  .FieldArray__item {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: 25px 80px 1px 30px;
    position: relative;
  }
  .FieldArray__item:last-child {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 767px) {
    .FieldArray__item {
      padding: 20px 35px 1px 15px;
    }
  }
  
  .FieldArray__item-number {
    position: absolute;
    top: -7px;
    left: -7px;
    border-radius: 50%;
    padding: 0 5px;
  }
  
  .FieldArray__item .FormSchemaComposer__inner {
    padding: 0;
    box-shadow: none;
    margin: 0;
  }

  .FieldArray__item .FieldArray__item {
    box-shadow: none;
    padding-top: 0;
    margin: 0;
  }

  .FieldArray__item .FieldArray__item .FormSchemaComposer__inner {
    margin: 0;
  }

  .FieldArray__item .FieldArray__item .FieldArray__remove {
    top: 0;
  }

  .FieldArray__remove {
    position: absolute;
    right: 30px;
    top: 25px;
  }
  @media only screen and (max-width: 767px) {
    .FieldArray__remove {
      right: 10px;
      top: 20px;
    }
  }

  .FieldArray__remove svg {
    width: 20px;
    height: 23px;
  }
  @media only screen and (max-width: 767px) {
    .FieldArray__remove svg {
      width: 17px;
      height: 18px;
    }
  }

  .ArrayField__item-error {
    position: relative;
    bottom: 3px;
  }
`;

const getDynamicStyles = ({ colors, border }) => {
    return `
    .FieldArray__item {
      border-radius: ${border.radius};
      border: 2px dashed ${colors.lightGray.hex};
      padding: ${isRTL ? '25px 30px 1px 80px' : '25px 80px 1px 30px'};
    }
    
    .FieldArray__item-number {
      background: ${colors.primary.hex};
      color: ${colors.white.hex};
    }
    
    
    .FieldArray__item_error {
      box-shadow: 0 1px 6px 1px ${rgba([colors.error.rgb, 0.4])};
    }

    .FieldArray__item_error .FieldArray__item {
      border-color: ${rgba([colors.error.rgb, 0.4])};
    }
    
    .FieldArray__item_error .FieldArray__item-number {
      background: ${colors.error.hex};
    }
    
    .ArrayField__item-error {
      color: ${colors.error.hex};
    }

    .isDraggingOver {
      background: ${rgba([colors.lightGray.rgb, 0.3])};
    }

    .FieldArray__item {
      background: ${colors.white.hex};
    }

    .FieldArray__isDraggable .FieldArray__item {
      border-color: ${colors.primary.hex};
      box-shadow: 0 1px 6px 1px ${rgba([colors.primary.rgb, 0.4])};
    }

    .FieldArray__remove {
      right: ${isRTL ? 'auto' : '30px'};
      left: ${isRTL ? '30px' : 'auto'};
    }

    .FieldArray__remove:hover svg g {
      fill: ${colors.error.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };