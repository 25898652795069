import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .BonusesForm {
    .BonusesForm__buttons {
      display: flex;

      :global(.DefaultButton) {
        margin-right: rem(20px);
      }
    }
  }
`;

export { staticStyles };
