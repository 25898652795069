import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { NewCRMSelect } from 'crm/_newComponents';
import { BaseButton } from 'crm/_components';

const FirstStep = ({ options, selectedOptions, loading, onChange, setNextStep }) => {
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  return (
    <>
      <NewCRMSelect
        label="justFilterType"
        areOptionsOpen={areOptionsOpen}
        setAreOptionsOpen={setAreOptionsOpen}
        options={options}
        loading={loading}
        selectedOptions={selectedOptions}
        onChange={onChange}
        disabled={loading}
      />
      {!areOptionsOpen && (
        <BaseButton
          type="button"
          textId="justNextStep"
          onClick={setNextStep}
          maxContent
          disabled={!selectedOptions.length}
        />
      )}
    </>
  );
};

FirstStep.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
};

export default connect(null)(FirstStep);
