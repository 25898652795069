import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { SearchPanel, NoItemsPanel } from 'common/components';
import noPayments from 'common/images/noPayments.svg';
import UserMarksTable from '../UserMarksTable';

import { getUserUtmHistory } from '../../_redux';

const UserMarks = ({ userUtm, userUtmAreLoaded, getUserUtmHistory, location, history, match }) => {
  const query = {
    page_size: 10,
    ...queryString.parse(location.search),
  };

  /* eslint-disable */
  useEffect(() => {
    getUserUtmHistory({
      userId: match.params.id,
      search: query,
    });
  }, [getUserUtmHistory, match.params.id]);
  /* eslint-enable */

  const handleGetList = () => getUserUtmHistory({ userId: match.params.id, search: query });

  return (
    <div className="UserMarks">
      {!!userUtm.count || location.search ? (
        <SearchPanel location={location} history={history} getList={handleGetList} params={{ search: query }} />
      ) : null}
      {userUtmAreLoaded && !!userUtm.count ? (
        <UserMarksTable
          data={userUtm}
          loading={!userUtmAreLoaded}
          location={location}
          history={history}
          getList={getUserUtmHistory}
          onRowClick={() => {}}
          params={{
            search: query,
          }}
        />
      ) : (
        <NoItemsPanel icon={noPayments} captionSlug="noData" />
      )}
      <style>
        {`.SearchPanel__input-inner {
          width: 100% !important;
        }`}
      </style>
    </div>
  );
};

UserMarks.propTypes = {
  userUtm: PropTypes.object.isRequired,
  userUtmAreLoaded: PropTypes.bool.isRequired,
  getUserUtmHistory: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      userUtm: state.backoffice.users.userUtm,
      userUtmAreLoaded: state.backoffice.users.userUtmAreLoaded,
    }),
    {
      getUserUtmHistory: userId => getUserUtmHistory.request(userId),
    }
  )
)(UserMarks);

export { UserMarks };
