import axios from 'axios';

export const getCampaignList = search =>
  axios.get(`/backoffice/promo_materials/campaigns/list/`, { params: { ...search } });

export const getCampaign = campaignId => axios.get(`/backoffice/promo_materials/campaigns/${campaignId}/`);

export const getDefaultCampaign = () =>
  axios.get(`/backoffice/promo_materials/campaigns/default/`, { validateStatus: false });

export const deleteCampaign = (campaignId, defaultId) =>
  axios.delete(`/backoffice/promo_materials/campaigns/${campaignId}/`, { params: { new_default_id: defaultId } });

export const createCampaign = payload => axios.post(`/backoffice/promo_materials/campaigns/create/`, { ...payload });

export const updateCampaign = (campaignId, payload) =>
  axios.put(`/backoffice/promo_materials/campaigns/${campaignId}/`, { ...payload });

export const getPromoMaterialList = search => axios.get(`/backoffice/promo_materials/list/`, { params: { ...search } });

export const getPromo = promoId => axios.get(`/backoffice/promo_materials/${promoId}/`);

export const deletePromo = promoId => axios.delete(`/backoffice/promo_materials/${promoId}/`);

export const createPromo = payload => axios.post(`/backoffice/promo_materials/banner/create/`, payload);

export const updatePromo = (promoId, payload) => axios.put(`/backoffice/promo_materials/${promoId}/`, { ...payload });
