import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNewValueIsAll,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAll,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAllAndTypeBoolean,
} from 'crm/_newComponents/FastFilters/helpers';

import { setModifiedFastFilters } from 'crm/_redux/actions';

import FastFilters from './FastFilters';

const FastFiltersWrapper = ({
  getFastFiltersFunc,
  fastFiltersSelector,
  fastFiltersModifiedSelector,
  customValueOfOneFilter,
}) => {
  const dispatch = useDispatch();
  const fastFiltersModified = useSelector(fastFiltersModifiedSelector);

  const onChange = (filterName, inputType, newValue) => {
    if (inputType === 'inputRange') {
      const currentFilter = fastFiltersModified[filterName];
      dispatch(
        setModifiedFastFilters({
          ...fastFiltersModified,
          [filterName]: {
            ...currentFilter,
            enteredValues: newValue,
          },
        })
      );

      return;
    }
    if (inputType === 'inputStr') {
      const currentFilter = fastFiltersModified[filterName];
      dispatch(
        setModifiedFastFilters({
          ...fastFiltersModified,
          [filterName]: {
            ...currentFilter,
            value: newValue,
          },
        })
      );

      return;
    }
    if (inputType === 'inputDate') {
      const currentFilter = fastFiltersModified[filterName];

      const newChoiceValues = fastFiltersModified[filterName].choiceValues.map(choiceValue => {
        if (choiceValue.label === newValue.label) {
          return { ...choiceValue, value: newValue.value, isSelected: true };
        }
        return { ...choiceValue, isSelected: false };
      });

      dispatch(
        setModifiedFastFilters({
          ...fastFiltersModified,
          [filterName]: {
            ...currentFilter,
            choiceValues: newChoiceValues,
          },
        })
      );
    }
    if (inputType === 'inputIntegerChoice' || inputType === 'inputStringChoice' || inputType === 'inputBoolean') {
      if (newValue === 'All') {
        const currentFilter = fastFiltersModified[filterName];
        const { choiceValues, choiceValuesAfterSearch } =
          getNewChoiceValuesAndChoiceValuesAfterSearchIfNewValueIsAll(currentFilter);

        dispatch(
          setModifiedFastFilters({
            ...fastFiltersModified,
            [filterName]: {
              ...currentFilter,
              choiceValues,
              choiceValuesAfterSearch,
            },
          })
        );
      } else {
        const currentFilter = fastFiltersModified[filterName];

        const { choiceValues, choiceValuesAfterSearch } =
          inputType !== 'inputBoolean'
            ? getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAll(currentFilter, newValue)
            : getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAllAndTypeBoolean(currentFilter, newValue);

        dispatch(
          setModifiedFastFilters({
            ...fastFiltersModified,
            [filterName]: {
              ...currentFilter,
              choiceValues,
              choiceValuesAfterSearch,
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    onChange(customValueOfOneFilter);
  }, [customValueOfOneFilter]);

  return (
    <FastFilters
      getFastFiltersFunc={getFastFiltersFunc}
      fastFiltersSelector={fastFiltersSelector}
      fastFiltersModified={fastFiltersModified}
      onChange={onChange}
    />
  );
};

FastFiltersWrapper.propTypes = {
  getFastFiltersFunc: PropTypes.object.isRequired,
  fastFiltersSelector: PropTypes.func.isRequired,
  fastFiltersModifiedSelector: PropTypes.func.isRequired,
};

export default FastFiltersWrapper;
