import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classNames from 'classnames';

import {
  getInvestments,
  getInvestmentsRequests,
  setInvestmentsAction,
  closeInvestmentRequest,
} from 'client/invest/_redux';

import { ModalHOC } from 'common';
import { Collapsible, BasicModal } from 'common/components';
import { withTheme } from 'common/styling/theme';
import InvestmentsTable from '../InvestmentsTable';
import InvestmentDepositForm from '../InvestmentDepositForm';
import InvestWithdrawForm from '../InvestWithdrawForm';
import InvestEditTpSlForm from '../InvestEditTpSlForm';

import InvestCloseForm from '../InvestCloseForm';

import { staticStyles, getDynamicStyles } from './style';

const InvestInvesments = ({
  accounts,
  addons,
  investments,
  investmentsAreLoaded,
  investmentsRequests,
  investmentsRequestsAreLoaded,
  getInvestments,
  getInvestmentsRequests,
  setInvestmentsAction,
  closeInvestmentRequest,
  showModal,
  profile,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const dispatch = useDispatch();
  const location = useLocation();
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('active');
  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState({ type: 'deposit' });
  const isWallet = addons.includes('walletAccount');
  const prevTabRef = useRef('');

  useEffect(() => {
    prevTabRef.current = activeTab;
  }, [activeTab]);

  const getList = useCallback(
    (query, tab) =>
      getInvestments({
        isActive: tab === 'active' ? '1' : '0',
        ...query,
      }),
    [getInvestments]
  );

  const getInvestmentsList = useCallback(
    query =>
      getInvestments({
        isActive: activeTab === 'active' ? '1' : '0',
        ...query,
      }),
    [getInvestments, activeTab]
  );

  const getRequestsList = useCallback(query => getInvestmentsRequests(query), [getInvestmentsRequests, query]);

  const toggleModal = () => setIsOpen(!isOpen);

  const handleOpenCollapse = () => getList(query, activeTab);

  const handleInvestmentsRowClick = id => history.push(`/invest/investments/${id}/open`);

  const handleRequestsRowClick = () => {};

  const handleDeposit = (id, strategy) => {
    setActiveModal({
      id,
      title: strategy.title,
      strategyAccountId: strategy.accountId,
      strategyCurrency: strategy.currency,
      type: 'deposit',
    });
    toggleModal();
  };

  const handleClose = id => {
    setActiveModal({ id, type: 'close' });

    if (isWallet) {
      showModal({
        caption: { id: 'confirmText' },
        content: {
          id: 'rammConfirmStrategyActionClose',
        },
        actionButton: {
          textId: 'justYes',
          handler: () => {
            setInvestmentsAction({
              investmentId: id,
              action: 'close',
              isWallet,
            });
          },
          type: 'error',
        },
        cancelButton: {
          textId: 'justNo',
        },
      });
    } else {
      toggleModal();
    }
  };

  const handleWithdraw = (id, status, isMyStrategy) => {
    setActiveModal({
      id,
      title: 'justWithdraw',
      type: 'withdraw',
      status,
      isMyStrategy,
    });
    toggleModal();
  };

  const handleEditTpSl = (id, strategyCurrency) => {
    setActiveModal({
      id,
      title: 'justEdit',
      type: 'edit',
      strategyCurrency,
    });
    toggleModal();
  };

  const handleCancelRequest = id => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'investConfirmRequestCancel',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => closeInvestmentRequest({ requestId: id, search: query }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const handleAction = ({ e, id, type, status, strategy, strategyCurrency }) => {
    e.stopPropagation();

    switch (type) {
      case 'withdraw':
        handleWithdraw(id, status, strategy.isMyStrategy);
        break;
      case 'close':
        handleClose(id);
        break;
      case 'deposit':
        handleDeposit(id, strategy);
        break;
      case 'edit':
        handleEditTpSl(id, strategyCurrency);
        break;
      case 'cancel_request':
        handleCancelRequest(id);
        break;
      default:
        break;
    }
  };

  const modalTitle = str => {
    const firstLetterToUpperCase = str.charAt(0).toUpperCase() + str.substring(1);

    switch (activeModal.type) {
      case 'deposit':
        return 'justDeposit';
      case 'edit':
        return 'justEdit';
      default:
        return `rammChange${firstLetterToUpperCase}`;
    }
  };

  const justInvestmentsNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justInvestmentsTixee'
      : 'justInvestments';
  return (
    <div className="InvestInvestments">
      <Collapsible captionId={justInvestmentsNew} handleOpen={handleOpenCollapse}>
        <div className="InvestInvestments__tabs">
          <FormattedMessage id="justActive">
            {txt => (
              <button
                type="button"
                className={classNames('InvestInvestments__tab', {
                  active: activeTab === 'active',
                })}
                onClick={() => {
                  setActiveTab('active');
                  getList(query, 'active');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="justClosed">
            {txt => (
              <button
                type="button"
                className={classNames('InvestInvestments__tab', {
                  active: activeTab === 'closed',
                })}
                onClick={() => {
                  setActiveTab('closed');
                  getList(query, 'closed');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="justRequests">
            {txt => (
              <button
                type="button"
                className={classNames('InvestInvestments__tab', {
                  active: activeTab === 'requests',
                })}
                onClick={() => {
                  setActiveTab('requests');
                  getRequestsList({ search: query });
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        {activeTab === 'active' && (
          <InvestmentsTable
            activeTab={activeTab}
            prevTab={prevTabRef.current}
            data={investments}
            loading={!investmentsAreLoaded}
            location={location}
            history={history}
            getList={getInvestmentsList}
            onRowClick={handleInvestmentsRowClick}
            params={{ search: query }}
            onTableAction={handleAction}
          />
        )}

        {activeTab === 'closed' && (
          <InvestmentsTable
            activeTab={activeTab}
            prevTab={prevTabRef.current}
            data={investments}
            loading={!investmentsAreLoaded}
            location={location}
            history={history}
            getList={getInvestmentsList}
            onRowClick={handleInvestmentsRowClick}
            params={{ search: query }}
            onTableAction={handleAction}
          />
        )}

        {activeTab === 'requests' && (
          <InvestmentsTable
            activeTab={activeTab}
            prevTab={prevTabRef.current}
            data={investmentsRequests}
            loading={!investmentsRequestsAreLoaded}
            location={location}
            history={history}
            getList={getRequestsList}
            onRowClick={handleRequestsRowClick}
            params={{ search: query }}
            onTableAction={handleAction}
          />
        )}

        <BasicModal isOpen={isOpen} captionId={modalTitle(activeModal.type)} onRequestClose={toggleModal}>
          {activeModal.type === 'deposit' && (
            <InvestmentDepositForm
              accounts={accounts}
              isWallet={isWallet}
              strategyAccountId={activeModal.strategyAccountId}
              strategyCurrency={activeModal.strategyCurrency}
              walletBalance={profile.walletBalance}
              toggleModal={toggleModal}
              investmentId={activeModal.id}
              initialValues={{ strategy: activeModal.title }}
            />
          )}
          {activeModal.type === 'withdraw' && (
            <InvestWithdrawForm
              accounts={accounts}
              isWallet={isWallet}
              investmentId={activeModal.id}
              toggleModal={toggleModal}
              dispatch={dispatch}
            />
          )}
          {activeModal.type === 'close' && (
            <InvestCloseForm
              accounts={accounts}
              isWallet={isWallet}
              toggleModal={toggleModal}
              investmentId={activeModal.id}
            />
          )}
          {activeModal.type === 'edit' && (
            <InvestEditTpSlForm
              toggleModal={toggleModal}
              accounts={accounts}
              investmentId={activeModal.id}
              strategyCurrency={activeModal.strategyCurrency}
            />
          )}
        </BasicModal>
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestInvesments.propTypes = {
  profile: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  investments: PropTypes.object.isRequired,
  investmentsAreLoaded: PropTypes.bool.isRequired,
  investmentsRequests: PropTypes.object.isRequired,
  investmentsRequestsAreLoaded: PropTypes.bool.isRequired,
  getInvestments: PropTypes.func.isRequired,
  getInvestmentsRequests: PropTypes.func.isRequired,
  setInvestmentsAction: PropTypes.func.isRequired,
  closeInvestmentRequest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  addons: PropTypes.array.isRequired,
  theme: PropTypes.object,
};
InvestInvesments.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      profile: state.user.profile,
      accounts: state.client.common.accounts.filter(
        account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
      ),
      investments: state.client.invest.investments,
      investmentsAreLoaded: state.client.invest.investmentsAreLoaded,
      investmentsRequests: state.client.invest.investmentsRequests,
      investmentsRequestsAreLoaded: state.client.invest.investmentsRequestsAreLoaded,
      addons: state.interfaceConfig.addons,
    }),
    {
      getInvestments: data => getInvestments.request(data),
      getInvestmentsRequests: data => getInvestmentsRequests.request(data),
      setInvestmentsAction: data => setInvestmentsAction.request(data),
      closeInvestmentRequest: id => closeInvestmentRequest.request(id),
    }
  )
)(InvestInvesments);
