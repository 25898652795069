import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const TimeIcon = ({ grayColor }) => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 22C4.9 22 0 17.1 0 11C0 4.9 4.9 0 11 0C17.1 0 22 4.9 22 11C22 17.1 17.1 22 11 22ZM11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21C16.5 21 21 16.5 21 11C21 5.5 16.5 1 11 1Z"
      fill={grayColor.hex}
    />
    <path
      d="M17.5001 13.3C17.4001 13.3 17.4001 13.3 17.5001 13.3L11.0001 12.3C10.8001 12.3 10.6001 12.1 10.6001 11.8V4.80005C10.6001 4.50005 10.8001 4.30005 11.1001 4.30005C11.4001 4.30005 11.6001 4.50005 11.6001 4.80005V11.4L17.7001 12.3C18.0001 12.3 18.1001 12.6 18.1001 12.8C17.9001 13.1 17.7001 13.3 17.5001 13.3Z"
      fill={grayColor.hex}
    />
  </svg>
);

TimeIcon.propTypes = {
  grayColor: colorType,
};

TimeIcon.defaultProps = {
  grayColor: {},
};

const ThemedTimeIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(TimeIcon);

export default ThemedTimeIcon;
