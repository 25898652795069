import css from 'styled-jsx/css';

import { gradient, isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .LoginCell__inner {
    display: flex;
    align-items: center;
    white-space: nowrap;
    @include respond-to(sm) {
      min-height: 30px;
    }
  }

  .LoginCell__type {
    width: rem(40px);
    height: rem(40px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: rem(20px) !important;
    font-weight: 500;
    margin-right: rem(${isRTL ? '0' : '15px'});
    margin-left: rem(${isRTL ? '15px' : '0'});
    border: 1px solid transparent;
    position: relative;
    background-clip: padding-box;
    z-index: 1;
    i {
      font-style: normal;
      background: #fff;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 38px;
      @include respond-to(sm) {
        min-width: 28px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      left: -1px;
      bottom: -1px;
      border-radius: 50%;
      z-index: -1;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
      min-width: rem(40px);
    }
  }

  .LoginCell__number {
    font-size: rem(20px);
    margin-right: rem(15px);
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include respond-to(sm) {
      margin-right: 0;
      font-size: rem(18px);
    }
  }

  .LoginCell__name {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    @include respond-to(xl) {
      max-width: 30%;
    }
  }

  .LoginCell__challenge .LoginCell__name {
    max-width: 100% !important;
    margin-left: 3px;
  }

  .LoginCell__noColor {
    .LoginCell__type {
      color: #eeeeee !important;
    }
    .LoginCell__type:after {
      background: #eeeeee !important;
    }
  }

  .LoginCell__inTable {
    display: flex;
    align-items: center;
    .LoginCell__type {
      margin-right: rem(${isRTL ? '0' : '10px'});
      margin-left: rem(${isRTL ? '10px' : '0'});
      @include respond-to(sm) {
        display: none !important;
      }
    }
    .LoginCell__number {
      font-size: rem(17px);
      margin-right: rem(${isRTL ? '0' : '10px'});
      margin-left: rem(${isRTL ? '10px' : '0'});
      @include respond-to(sm) {
        margin-right: 0;
      }
    }
    .LoginCell__name {
      font-size: rem(13px) !important;
      position: relative;
      top: 1px;
      @include respond-to(md) {
        display: none !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .LoginCell__type {
      color: ${colors.primary.hex} !important;
      &:after {
        background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }
    }

    .LoginCell__number {
      color: ${colors.secondary.hex};
    }

    .LoginCell__inTable {
      .LoginCell__name {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
