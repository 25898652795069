import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { Field, FormSection, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { ReduxSelectField, CheckboxField } from 'common/components/form-fields';
import { FormMessageHOC } from 'common';

import { withTheme } from 'common/styling/theme';
import { DefaultButton } from 'common/components';
import { changeNotificationSettings } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const NotificationSettingsForm = ({
  notificationLanguages,
  notificationSettings,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const submit = handleSubmit(formValues => changeNotificationSettings(formValues, dispatch));

  return (
    <form className="NotificationSettingsForm" onSubmit={submit}>
      <div className="NotificationSettingsForm__block">
        <FormattedMessage id="selectNotificationsLanguage">
          {txt => <h2 className="NotificationSettingsForm__caption">{txt}</h2>}
        </FormattedMessage>
        <Field
          component={ReduxSelectField}
          name="localeCode"
          maxMenuHeight={180}
          textId="justLanguage"
          options={notificationLanguages}
          validate={[required()]}
          disabled={submitting}
          searchable
        />
      </div>
      <div className="NotificationSettingsForm__block">
        <FormattedMessage id="justNotificationsReceiving">
          {txt => <h2 className="NotificationSettingsForm__caption">{txt}</h2>}
        </FormattedMessage>
        <FormattedMessage id="notificationSettingsDescription">
          {txt => <span className="NotificationSettingsForm__description">{txt}</span>}
        </FormattedMessage>
        <FormSection name="settings" className="NotificationSettingsForm__settings">
          {notificationSettings.map(notification => (
            <Field
              key={notification.slug}
              component={CheckboxField}
              name={notification.slug}
              text={notification.title}
              onChange={() => {}}
            />
          ))}
        </FormSection>
      </div>
      <DefaultButton textId="doSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

NotificationSettingsForm.propTypes = {
  notificationLanguages: PropTypes.array.isRequired,
  notificationSettings: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

NotificationSettingsForm.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  FormMessageHOC,
  reduxForm({
    form: 'NotificationSettingsForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage();
    },
  })
)(NotificationSettingsForm);

export { NotificationSettingsForm };
