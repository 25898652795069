import React from 'react';

const TradingPlatformsIcon = () => (
  <svg className="svgNoFill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="fill"
      d="M12.0002 16.6427C12.3299 16.6427 12.5973 16.3753 12.5973 16.0456C12.5973 15.7158 12.3299 15.4485 12.0002 15.4485C11.6704 15.4485 11.4031 15.7158 11.4031 16.0456C11.4031 16.3753 11.6704 16.6427 12.0002 16.6427Z"
      fill="#0C0C3D"
    />
    <path
      className="fill"
      d="M20.7927 3H3.20739C2.54164 3 2 3.54719 2 4.21976V16.4856C2 17.1582 2.54164 17.7054 3.20739 17.7054H9.23319V18.5929C9.23319 19.4065 8.57796 20.0684 7.7726 20.0684H6.44459C6.18998 20.0684 5.98353 20.277 5.98353 20.5342C5.98353 20.7915 6.18998 21 6.44459 21H17.5554C17.81 21 18.0165 20.7915 18.0165 20.5342C18.0165 20.277 17.81 20.0684 17.5554 20.0684H16.2274C15.422 20.0684 14.7668 19.4065 14.7668 18.5929V17.7054H20.7926C21.4583 17.7054 22 17.1582 22 16.4856V4.21976C22.0001 3.54719 21.4584 3 20.7927 3ZM9.65316 20.0685C9.96745 19.6606 10.1554 19.1487 10.1554 18.593V17.7054H13.8447V18.593C13.8447 19.1487 14.0327 19.6607 14.347 20.0685H9.65316ZM21.078 16.4856C21.078 16.6446 20.95 16.7739 20.7927 16.7739H3.20739C3.05008 16.7739 2.92207 16.6446 2.92207 16.4856V14.0376H21.078V16.4856ZM21.078 13.106H2.92211V4.21976C2.92211 4.06084 3.05008 3.93152 3.20743 3.93152H20.7927C20.95 3.93152 21.078 4.0608 21.078 4.21976L21.078 13.106Z"
      fill="#0C0C3D"
    />
    <path
      className="fill"
      d="M12.8624 5.80916C12.666 5.61472 12.3476 5.61472 12.1511 5.80916L8.59651 9.3282C8.4001 9.52264 8.4001 9.83795 8.59651 10.0324C8.64317 10.0787 8.69861 10.1155 8.75965 10.1405C8.82069 10.1655 8.88613 10.1784 8.9522 10.1783C9.08098 10.1783 9.20963 10.1297 9.30786 10.0324L12.8624 6.5134C13.0588 6.31896 13.0588 6.00365 12.8624 5.80916ZM14.9692 7.84847C14.7729 7.65402 14.4545 7.65402 14.2579 7.84842L12.8419 9.25023C12.6455 9.44472 12.6455 9.76003 12.8419 9.95447C12.8885 10.0008 12.944 10.0375 13.005 10.0625C13.066 10.0876 13.1315 10.1004 13.1975 10.1003C13.3262 10.1003 13.455 10.0517 13.5532 9.95447L14.9692 8.55266C15.1657 8.35822 15.1657 8.04291 14.9692 7.84847Z"
      fill="#0C0C3D"
    />
  </svg>
);

export default TradingPlatformsIcon;
