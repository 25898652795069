import css from 'styled-jsx/css';

const staticStyles = css.global`
  .RangeSelector__ApplyButton {
    width: 100%;
    height: 44px;
    background: white;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    z-index: 42345243;
    &:hover {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      border: 1px solid #777777;
    }
  }

  .RangeSelector__ApplyButton:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  }

  .RangeSelector__ApplyButton--Disabled {
    cursor: not-allowed;
    color: #ff00006e;
  }

  .RangeSelector__ApplyButton--Disabled:hover {
    cursor: not-allowed;
    border: 1px solid #ff00006e;
  }
`;

export { staticStyles };
