import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import InnerHTML from 'dangerously-set-html-content';

const CustomJs = ({ content }) => ReactDOM.createPortal(<InnerHTML html={content} />, document.body);

CustomJs.propTypes = {
  content: PropTypes.string.isRequired,
};

export default CustomJs;
