import React from 'react';
import PropTypes from 'prop-types';

import { DefaultButton } from 'common/components';

const WithdrawError = ({ text, toggleModal }) => (
  <div className="WithdrawError">
    <p>{text}</p>
    <DefaultButton textId="justOk" type="button" onClick={() => toggleModal()} />
  </div>
);

WithdrawError.propTypes = {
  text: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default WithdrawError;
