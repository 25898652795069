import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EconomicCalendar } from 'react-ts-tradingview-widgets';

import { PageLayout } from 'common/components';

import { staticStyles } from './style';

const CalendarPage = ({ lang }) => (
  <PageLayout captionSlug="justCalendar">
    <div className="CalendarPage">
      <EconomicCalendar locale={lang} />
    </div>
    <style jsx global>
      {staticStyles}
    </style>
  </PageLayout>
);

CalendarPage.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default connect(state => ({
  lang: state.interfaceConfig.lang,
}))(CalendarPage);
