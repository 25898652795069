/* eslint-disable prefer-regex-literals */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';

import 'react-dates-gte-react-17/initialize';
import Modal from 'react-modal';
import Validators from 'redux-form-validators';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import initStore from 'redux-config/store';
import networkService from 'networkService';

import ConnectedIntlProvider from 'interface/connected-intl/ConnectedIntlProvider';
import { ErrorHandler } from 'common';

import { GoogleAnalyticsInitialize, YandexMetrikaInitialize } from 'common/utils';
import { WithTrackerHOC } from 'common/HOCs';
import App from './App';

// https://formatjs.io/guides/runtime-environments/#client
if (!global.Intl) {
  global.Intl = require('intl'); // eslint-disable-line
}

// https://goo.gl/V2wcG5
Validators.formatMessage = msg => <FormattedMessage {...(msg.props || msg)} />;

/* eslint-disable */
// Ignore ReactIntl warnings for translated strings
const isDev = process.env.NODE_ENV === 'development';
if (isDev) {
  const consoleError = console.error.bind(console);
  console.error = (message, ...args) => {
    if (typeof message === 'string' && message.startsWith('[React Intl]')) {
      const keyInQuotes = new RegExp(/"([^"]+)"/g);
      const isCamelCase = new RegExp(/^[a-z][a-zA-Z0-9]*$/g);

      const key = keyInQuotes.exec(message)[1];
      const isValidKey = key.startsWith('_') || isCamelCase.test(key);
      if (!isValidKey) {
        return;
      }
    }
    consoleError(message, ...args);
  };
}

// forEach polyfill for IE
(function() {
  if (typeof NodeList.prototype.forEach === 'function') return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();
/* eslint-enable */

Modal.setAppElement('#root');

const store = initStore();

networkService.setupDefaultInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ConnectedIntlProvider>
        <ErrorHandler>
          <>
            <GoogleAnalyticsInitialize />
            <YandexMetrikaInitialize />
            <Route component={WithTrackerHOC(App)} />
          </>
        </ErrorHandler>
      </ConnectedIntlProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
