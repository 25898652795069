import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const dateDiffInDays = date => {
  const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utc2 = Date.now();

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

/* eslint-disable */
const UserActivity = ({ isBlocked, isOnline, lastSeen, onlyDot, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const pastDaysCount = dateDiffInDays(new Date(lastSeen));

  return (
    <span
      className={classNames('UserActivity', {
        'UserActivity__online-now': pastDaysCount < 1 && isOnline && !isBlocked,
        'UserActivity__online-today': pastDaysCount < 1 && !isOnline && !isBlocked,
        'UserActivity__online-recently': pastDaysCount >= 1 && pastDaysCount < 30 && !isBlocked,
        'UserActivity__not-seen-long-time': pastDaysCount >= 30 && !isBlocked,
      })}>
      {onlyDot ? (
        ''
      ) : (
        <>
          {isBlocked ? (
            <FormattedMessage id="status_blocked">{txt => txt}</FormattedMessage>
          ) : (
            <>
              {pastDaysCount < 1 ? (
                <FormattedMessage id={isOnline ? 'justOnline' : 'justWasToday'}>{txt => txt}</FormattedMessage>
              ) : pastDaysCount < 30 ? (
                <FormattedMessage id="justWasNDaysAgo" values={{ count: pastDaysCount }}>
                  {txt => txt}
                </FormattedMessage>
              ) : (
                <FormattedMessage id="justWasOnlineOnDate" values={{ date: moment(lastSeen).format('ll') }}>
                  {txt => txt}
                </FormattedMessage>
              )}
            </>
          )}
        </>
      )}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </span>
  );
};

UserActivity.propTypes = {
  lastSeen: PropTypes.string,
  isOnline: PropTypes.bool,
  isBlocked: PropTypes.bool,
  onlyDot: PropTypes.bool,
  theme: PropTypes.object,
};

UserActivity.defaultProps = {
  lastSeen: null,
  isOnline: false,
  isBlocked: false,
  onlyDot: false,
  theme: {},
};

export default withTheme()(UserActivity);
export { UserActivity };
