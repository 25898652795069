import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { executeReport } from 'common/_redux';
import { DefaultButton, ReduxSelectField } from 'common/components';
import { required } from 'redux-form-validators';
import { FormFieldsComposer } from '../schema';

import { staticStyles } from './style';

const OrderReportForm = ({
  availableReports,
  selectedReportType,
  isBackOfficePage,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  dispatch,
}) => {
  const schema = selectedReportType ? availableReports.find(item => item.key === selectedReportType).form : {};
  const submit = handleSubmit(values => executeReport({ ...values, isBackOfficePage }, dispatch));
  const properties = schema.properties ? Object.keys(schema.properties) : [];

  return (
    <form className="OrderReportForm" onSubmit={submit}>
      <Field
        component={ReduxSelectField}
        name="reportType"
        textId="justReportType"
        options={availableReports.map(report => ({ value: report.key, label: report.name }))}
        disabled={submitting}
        validate={[required()]}
      />
      {!!selectedReportType && (
        <>
          <div className="OrderReportForm__composer-fields">
            {properties.map(key => (
              <FormFieldsComposer
                key={key}
                name={key}
                schema={schema.properties[key]}
                formName="OrderReportForm"
                submitting={submitting}
              />
            ))}
          </div>
          <DefaultButton
            textId="justOrder"
            onClick={submit}
            disabled={invalid || pristine || submitting}
            loading={submitting}
          />
        </>
      )}
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

OrderReportForm.propTypes = {
  availableReports: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isBackOfficePage: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedReportType: PropTypes.number,
  error: PropTypes.string,
};

OrderReportForm.defaultProps = {
  selectedReportType: null,
  error: '',
};

const selector = formValueSelector('OrderReportForm');

export default compose(
  connect(state => ({
    selectedReportType: selector(state, 'reportType'),
  })),
  reduxForm({
    form: 'OrderReportForm',
  })
)(OrderReportForm);

export { OrderReportForm };
