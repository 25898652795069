import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactDataTable } from 'common/components';
import { ContractSpecsTableComment } from 'client/information/_components/ContractSpecsTable/ContractSpecsTableComment';
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsList } from 'client/information/_redux';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const ContractSpecsTable = ({ data, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommentsList.request());
  }, [dispatch]);

  const commentsListLength = useSelector(state => state.client.information.commentsList).length;
  const commentsListPending = useSelector(state => state.client.information.commentsListPending);

  /* eslint-disable */
  const columns = [
    {
      Header: ' ',
      Cell: ({
        row: {
          original: { comment },
        },
      }) => <ContractSpecsTableComment comment={comment} />,
      isPreviewCell: true,
    },
    {
      Header: 'justSymbol',
      Cell: ({
        row: {
          original: { symbol },
        },
      }) => <span>{symbol}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justSymbolName',
      Cell: ({
        row: {
          original: { symbolName },
        },
      }) => <span className="light">{symbolName ? symbolName : '一'}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justTickSize',
      Cell: ({
        row: {
          original: { tickSize },
        },
      }) => <span>{Number(tickSize)}</span>,
    },
    {
      Header: 'justSwapLong',
      Cell: ({
        row: {
          original: { swapLong },
        },
      }) => <span>{swapLong}</span>,
    },
    {
      Header: 'justSwapShort',
      Cell: ({
        row: {
          original: { swapShort },
        },
      }) => <span>{swapShort}</span>,
    },
    {
      Header: 'justMargin',
      Cell: ({
        row: {
          original: { margin },
        },
      }) => <span>{margin}</span>,
    },
    {
      Header: 'justContractSize',
      Cell: ({
        row: {
          original: { contractSize },
        },
      }) => <span>{contractSize}</span>,
    },
    {
      Header: 'justSpreadAvg',
      Cell: ({
        row: {
          original: { spreadAvg },
        },
      }) => <span>{spreadAvg ? spreadAvg : '一'}</span>,
    },
    {
      Header: 'justSpreadMin',
      Cell: ({
        row: {
          original: { spreadMin },
        },
      }) => <span>{spreadMin ? spreadMin : '一'}</span>,
    },
    {
      Header: 'justTradingSessions',
      Cell: ({
        row: {
          original: { sessions },
        },
      }) => (
        <div>
          {sessions.map((session, index) => (
            <span className="light" key={index}>
              {session}
            </span>
          ))}
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div
      className={classNames('ContractSpecsTable', {
        'ContractSpecsTable--no-comments': !commentsListLength && !commentsListPending,
      })}>
      <ReactDataTable data={data} columns={columns} />
      <style jsx global>
        {dynamicStyles}
      </style>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ContractSpecsTable.propTypes = {
  data: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

ContractSpecsTable.defaultProps = {
  theme: {},
};

export default withTheme()(ContractSpecsTable);
