import React from 'react';
import PropTypes from 'prop-types';
import SmallAreaChart from 'common/dashboards/_components/SmallAreaChart';

import { staticStyles } from './style';

const SmallAreaCharts = ({ lastFunnels, dailyFunnels, currentMonth, lastMonth, tomorrow }) => {
  const registered = [];
  let registeredAcc = 0;
  const realUsers = [];
  let realUsersAcc = 0;
  const demoUsers = [];
  let demoUsersAcc = 0;
  const ftd = [];
  let ftdAcc = 0;
  const days = [];
  let isRealData = true;
  Object.entries(dailyFunnels).forEach(([key, value]) => {
    if (key === tomorrow) {
      isRealData = false;
    }
    if (isRealData) {
      days.push(key);
      registeredAcc = value.users + registeredAcc;
      registered.push(registeredAcc);
      realUsersAcc = value.real_users + realUsersAcc;
      realUsers.push(realUsersAcc);
      demoUsersAcc = value.demo_users + demoUsersAcc;
      demoUsers.push(demoUsersAcc);
      ftdAcc = value.deposited_users + ftdAcc;
      ftd.push(ftdAcc);
    }
  });
  const {
    users: currentRegistered = 0,
    real_users: currentRealUsers = 0,
    demo_users: currentDemoUsers = 0,
    deposited_users: currentFtd = 0,
  } = lastFunnels[currentMonth] || {};
  const {
    users: lastMonthRegistered = 0,
    real_users: lastMonthRealUsers = 0,
    demo_users: lastMonthDemoUsers = 0,
    deposited_users: lastMonthFtd = 0,
  } = lastFunnels[lastMonth] || {};

  return (
    <div className="SmallAreaCharts">
      <SmallAreaChart
        color="#43B6E8"
        captionId="Registered"
        amountLastMonth={lastMonthRegistered}
        amountNow={currentRegistered}
        categoriesData={days}
        seriesData={registered}
      />
      <SmallAreaChart
        color="#F5A623"
        captionId="boDemoAccount"
        amountLastMonth={lastMonthDemoUsers}
        amountNow={currentDemoUsers}
        categoriesData={days}
        seriesData={demoUsers}
      />
      <SmallAreaChart
        color="#6AC347"
        captionId="boLiveAccount"
        amountLastMonth={lastMonthRealUsers}
        amountNow={currentRealUsers}
        categoriesData={days}
        seriesData={realUsers}
      />
      <SmallAreaChart
        color="#39DBD3"
        captionId="boFTD"
        amountLastMonth={lastMonthFtd}
        amountNow={currentFtd}
        categoriesData={days}
        seriesData={ftd}
      />

      <style jsx>{staticStyles}</style>
    </div>
  );
};

SmallAreaCharts.propTypes = {
  lastFunnels: PropTypes.object,
  dailyFunnels: PropTypes.object,
  currentMonth: PropTypes.string.isRequired,
  lastMonth: PropTypes.string.isRequired,
  tomorrow: PropTypes.string.isRequired,
};

SmallAreaCharts.defaultProps = {
  lastFunnels: {},
  dailyFunnels: {},
};

export default SmallAreaCharts;
export { SmallAreaCharts };
