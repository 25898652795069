import React from 'react';
import PropTypes from 'prop-types';
import noPaymentsImage from 'common/images/noPayments.svg';
import { BaseTable, NoItemsPanel } from 'common/components';
import { staticStyles } from './style';

const ReportOnlineTable = ({ savedReport, savedReportIsLoaded }) => {
  let columns = [];
  if (savedReportIsLoaded) {
    columns = Object.keys(savedReport[0]).map(item => ({ key: item, Header: item, accessor: item }));
  }

  return (
    <>
      {savedReportIsLoaded ? (
        <BaseTable columns={columns} data={savedReport} className="ReportOnlineTable" />
      ) : (
        <NoItemsPanel icon={noPaymentsImage} captionSlug="noData" />
      )}
      <style jsx global>
        {staticStyles}
      </style>
    </>
  );
};

ReportOnlineTable.propTypes = {
  savedReport: PropTypes.string.isRequired,
  savedReportIsLoaded: PropTypes.bool.isRequired,
};

export default ReportOnlineTable;
