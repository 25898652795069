import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

import LoadingIcon from './icons/LoadingIcon';

const DefaultButton = ({
  textId,
  plus,
  next,
  previous,
  noTranslate,
  filled,
  butColor,
  loading,
  type,
  noBorder,
  selected,
  theme,
  fitContent,
  className,
  isUpperCase,
  ghost,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <button // eslint-disable-line
      type={type}
      className={classNames(
        'DefaultButton',
        { ghost, plus, next, filled, previous, noBorder, fitContent, DefaultButton_preload: loading, selected },
        butColor && `DefaultButton__${butColor}`,
        `${className}`
      )}
      {...props}>
      {noTranslate ? (
        <span className="DefaultButton__text">{isUpperCase ? textId.toUpperCase() : textId}</span>
      ) : (
        <FormattedMessage id={textId}>
          {txt => <span className="DefaultButton__text">{isUpperCase ? txt.toUpperCase() : txt}</span>}
        </FormattedMessage>
      )}
      <span className="DefaultButton__preloader">
        <LoadingIcon />
      </span>
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </button>
  );
};

const basePropTypes = {
  textId: PropTypes.string.isRequired,
  plus: PropTypes.bool,
  previous: PropTypes.bool,
  next: PropTypes.bool,
  filled: PropTypes.bool,
  noBorder: PropTypes.bool,
  butColor: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  fitContent: PropTypes.bool,
  className: PropTypes.string,
  isUpperCase: PropTypes.bool,
  ghost: PropTypes.bool,
  noTranslate: PropTypes.bool,
  theme: PropTypes.object,
};

DefaultButton.propTypes = basePropTypes;
DefaultButton.defaultProps = {
  plus: false,
  previous: false,
  next: false,
  filled: false,
  noBorder: false,
  butColor: '',
  loading: false,
  type: 'button',
  fitContent: false,
  noTranslate: false,
  className: '',
  isUpperCase: false,
  ghost: false,
  theme: {},
};

const ThemedButton = withTheme()(DefaultButton);

ThemedButton.propTypes = basePropTypes;

export default ThemedButton;
export { DefaultButton };
