import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { DefaultButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { getDynamicStyles } from './style';

const WarningMessage = ({ headerTextId, bodyTextId, buttonTextId, onButtonClick, kycEnabled, className, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className={classNames('WarningMessage', className)}>
      {headerTextId && <FormattedMessage id={headerTextId}>{txt => <h3>{txt}</h3>}</FormattedMessage>}
      {bodyTextId && (kycEnabled || bodyTextId) && (
        <div className="WarningMessage__text-inner">
          <FormattedMessage id={bodyTextId} />
        </div>
      )}
      {onButtonClick && (
        <div className="WarningMessage__button-inner">
          <DefaultButton textId={buttonTextId} onClick={onButtonClick} filled />
        </div>
      )}
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

WarningMessage.propTypes = {
  headerTextId: PropTypes.string,
  bodyTextId: PropTypes.string,
  buttonTextId: PropTypes.string,
  onButtonClick: PropTypes.func,
  kycEnabled: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.object,
};

WarningMessage.defaultProps = {
  headerTextId: null,
  bodyTextId: null,
  buttonTextId: null,
  onButtonClick: null,
  kycEnabled: true,
  className: null,
  theme: {},
};

const ThemedWarningMessage = withTheme()(withRouter(WarningMessage));

export default ThemedWarningMessage;
export { WarningMessage };
