import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestStrategyApproveTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(tr) {
          grid-template-columns: 85px repeat(4, minmax(80px, 1fr)) minmax(275px, 1fr);
        }
      }
    }
    :global(.InvestStrategyApproveTable__actions) {
      display: flex;
      width: 100%;
      justify-content: center;

      :global(.DefaultButton:first-child) {
        margin-right: 10px;
      }
    }

    :global(.ResponsiveTable__details-item) {
      @include respond-to(sm) {
        align-items: center;
      }
    }

    :global(.ReactDataTable__tbody-row),
    :global(.ReactDataTable__thead-row) {
      margin: 0;
    }
  }
`;

export { staticStyles };
