/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageLayout, PanelTabs } from 'common/components';

import queryString from 'query-string';
import OrderReportPage from '../OrderReportPage';
import PeriodicallyReportsPage from '../PeriodicallyReportsPage';
import SavedReportsPage from '../SavedReportsPage';

interface MatchParams {
  name: string;
}

type IReportsPageProps = RouteComponentProps<MatchParams>;

const ReportsPage: React.FC<IReportsPageProps> = ({ history, location, match }: IReportsPageProps) => {
  const activeTabSlug = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : 'order'),
    [location.search]
  );
  return (
    <PageLayout captionSlug="boReports">
      <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
        <OrderReportPage textId="justOrderReport" slug="order" match={match} />
        <PeriodicallyReportsPage textId="setupPeriodically" slug="period" match={match} />
        <SavedReportsPage textId="justSavedReports" slug="saved" match={match} />
      </PanelTabs>
    </PageLayout>
  );
};

export default ReportsPage;
export { ReportsPage };
