import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_CLIENT_ISSUES = createRequestTypes('GET_CLIENT_ISSUES');
export const getClientIssues = {
  request: () => action(GET_CLIENT_ISSUES[REQUEST]),
  success: data => action(GET_CLIENT_ISSUES[SUCCESS], { data }),
  failure: error => action(GET_CLIENT_ISSUES[FAILURE], { error }),
};

export const MARK_ISSUE_AS_VIEWED = createRequestTypes('MARK_ISSUE_AS_VIEWED');
export const markIssueAsViewed = {
  request: id => action(MARK_ISSUE_AS_VIEWED[REQUEST], { id }),
  success: data => action(MARK_ISSUE_AS_VIEWED[SUCCESS], { data }),
  failure: error => action(MARK_ISSUE_AS_VIEWED[FAILURE], { error }),
};

export const GET_CLIENT_ISSUE = createRequestTypes('GET_CLIENT_ISSUE');
export const getClientIssue = {
  request: id => action(GET_CLIENT_ISSUE[REQUEST], { id }),
  success: data => action(GET_CLIENT_ISSUE[SUCCESS], { data }),
  failure: error => action(GET_CLIENT_ISSUE[FAILURE], { error }),
};

export const TOGGLE_ISSUE = 'TOGGLE_ISSUE';
export const toggleIssue = id => action(TOGGLE_ISSUE, { id });

export const OPEN_ISSUE = 'OPEN_ISSUE';
export const openIssue = id => action(OPEN_ISSUE, { id });

export const CLOSE_ISSUE = createRequestTypes('CLOSE_ISSUE');
export const closeIssue = {
  request: id => action(CLOSE_ISSUE[REQUEST], { id }),
  success: data => action(CLOSE_ISSUE[SUCCESS], { data }),
  failure: error => action(CLOSE_ISSUE[FAILURE], { error }),
};

export const TOGGLE_CREATE_ISSUE_MODAL = 'TOGGLE_CREATE_ISSUE_MODAL';
export const toggleCreateIssueModal = () => action(TOGGLE_CREATE_ISSUE_MODAL);

export const issueCreateMessage = createFormAction('ISSUE_CREATE_MESSAGE');
export const createIssue = createFormAction('CREATE_ISSUE');

export const CREATE_ISSUE_ATTACHMENT = createRequestTypes('CREATE_ISSUE_ATTACHMENT');
export const createIssueAttachment = {
  request: (message, file) => action(CREATE_ISSUE_ATTACHMENT[REQUEST], { message, file }),
  success: () => action(CREATE_ISSUE_ATTACHMENT[SUCCESS]),
  failure: error => action(CREATE_ISSUE_ATTACHMENT[FAILURE], { error }),
};

export const UPDATE_ISSUE = 'UPDATE_ISSUE';
export const updateIssue = issue => action(UPDATE_ISSUE, { issue });

export const ADD_ISSUE_NOTIFICATION = 'ADD_ISSUE_NOTIFICATION';
export const REMOVE_ISSUE_NOTIFICATION = 'REMOVE_ISSUE_NOTIFICATION';
export const HIDE_ISSUE_NOTIFICATION = 'HIDE_ISSUE_NOTIFICATION';
export const addIssueNotification = issue => action(ADD_ISSUE_NOTIFICATION, { issue });
export const removeIssueNotification = id => action(REMOVE_ISSUE_NOTIFICATION, { id });
export const hideIssueNotification = id => action(HIDE_ISSUE_NOTIFICATION, { id });

export const updateMessage = createFormAction('UPDATE_MESSAGE');

export const DELETE_MESSAGE_ATTACHMENT = createRequestTypes('DELETE_MESSAGE_ATTACHMENT');
export const deleteMessageAttachment = {
  request: data => action(DELETE_MESSAGE_ATTACHMENT[REQUEST], data),
  success: () => action(DELETE_MESSAGE_ATTACHMENT[SUCCESS]),
  failure: error => action(DELETE_MESSAGE_ATTACHMENT[FAILURE], { error }),
};
