import css from 'styled-jsx/css';
import { isRTL, gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  .SecondPaymentsForm {
    min-width: 400px;
    max-width: 400px;
    @include respond-to(sm) {
      max-width: 100%;
      min-width: 100%;
    }
  }
  .SecondPaymentsForm__content {
    display: flex !important;
    flex-direction: column;
  }
  .FormSchemaComposer__inner {
    box-shadow: none !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
  }

  .Payments__bottom-button {
    margin-bottom: 15px;

    .DefaultButton {
      margin-right: ${isRTL ? '0' : '20px'};
      margin-left: ${isRTL ? '20px' : '0'};

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Payments__instruction {
    cursor: pointer;
  }

  @media print {
    .Payments__instruction {
      padding: 0 10px;
    }
  }

  .Payments {
    &__type-image-spec-wrap {
      display: flex;
      gap: 36px;
      background: white;
      padding: 20px;
      margin-bottom: 40px;
      @include respond-to(sm) {
        flex-wrap: wrap;
        justify-content: center;
      }
      > img {
        object-fit: scale-down;
        max-height: 200px;
        max-width: 200px;
      }

      > div {
        min-height: 200px;
        width: 100%;
        padding: 16px;
        background-color: white;
        font-size: 18px;
        @include respond-to(sm) {
          min-height: max-content;
          padding: 10px;
        }
        > p {
          font-size: 15px;
          line-height: 24px;
          word-break: break-all;
          @include respond-to(md) {
            font-size: 14px;
          }
          @include respond-to(sm) {
            font-size: 13px;
            line-height: 18px;
            min-height: fit-content;
          }
        }
      }
    }

    &__widget {
      margin-bottom: 24px;

      svg[role='status'] {
        display: none;
      }

      .hel-w-\[400px\] {
        width: 100% !important;
      }
    }

    &__buttons-wrap {
      display: flex;
      flex-wrap: wrap;

      button {
        margin: 0 0 36px 0;

        @include respond-to(sm) {
          width: calc(50% - 11px);
        }

        &:only-child {
          @include respond-to(sm) {
            width: 100% !important;
            margin-right: 0 !important;
          }
        }

        &:nth-child(1) {
          margin: 0 22px 36px 0;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Payments__instruction {
      &:hover {
        color: ${colors.secondary.hex};
      }
    }

    .Payments__type-image-spec-wrap {
      > div {
        background-color: ${colors.white.hex};
        > span {
          color: ${colors.gray.hex};
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 5px;
        }
        > div {
          word-break: break-word;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
