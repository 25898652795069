import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TableLoader {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100px;
      height: 100px;
      margin: 20px;
      display: inline-block;
    }

    &__text {
      text-align: center;
      font-size: rem(20px);
    }
  }
`;

export { staticStyles };
