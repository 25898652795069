import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  /* :global(.ContactsTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  } */
  :global(.ContactsTable--click2call) {
    :global(.NewCRMTable__Head-Row),
    :global(.NewCRMTable__Body-Row) {
      display: grid;
      grid-template-columns: 40px 1.5fr 1fr 1fr 1fr 1fr 1fr 2fr 50px;
    }
  }

  :global(.NewCRMTable__Body-Row) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(2):before {
      background: #c2c7ce;
    }
  }
  :global(.NewCRMTable__Body-Row--success) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(2):before {
      background: #04eb84;
    }
  }
  :global(.NewCRMTable__Body-Row--notSuccess) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(2):before {
      background: #fdff89;
    }
  }
  :global(.NewCRMTable__Body-Row--badClient) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(2):before {
      background: #ff4085;
    }
  }

  :global(.LastCommentCell) {
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  :global(.NewCRMTable__Cell--Tbody):nth-child(2) {
    overflow: initial;
    border-top: 0 !important;
    border-left: 0 !important;
  }
  :global(.NewCRMTable__Cell--Tbody):nth-child(2):before {
    content: '';
    position: absolute;
    width: 6px;
    bottom: 0px;
    left: 0;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }

  :global(.NewCRMTable__Cell--Tbody) {
    &:hover {
      transition: none;
      background: #ebf7fd;
      border: 1px solid #78b8d6;
      border: none;
    }
  }

  :global(.NewCRMTable__wrapper) {
    @include respond-to(xl) {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #0aa1e2;
      }
    }
  }

  :global(.NewCRMTable) {
    @include respond-to(lg) {
      width: 1500px;
    }
  }

  :global(.PageContentPreloader) {
    padding: 20px 30px 0 11px;
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  :global(th) {
  }

  :global(.checkboxWrapper) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  :global(.CheckBox) {
    appearance: auto;
    opacity: 1;
    width: auto;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    /* max-width: 30px !important;
    min-width: 30px !important; */
  }
  :global(.ContactsTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  :global(.ContactsTable__LastSeenHeaderWrapper) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  :global(.ContactsTable__LastSeenTitle) {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #0f0f0f;
  }
  :global(.ContactsTable__LastSeenInfo) {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #696969;
  }
  :global(.ContactsTable__CustomMoneyHeaderCellTitle) {
    :global(span) {
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 14px !important;
      color: #0f0f0f;
    }
  }
  :global(.ContactsTable__CustomMoneyHeaderCellInfo) {
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #696969;
  }
  :global(.ContactsTable__CustomMoneyHeaderCellWrapper) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  :global(.NewCRMTable__Cell):first-child {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    max-width: max-content;
    min-width: max-content;
    align-self: center;
    :global(.NewCRMTable__Cell-Inner) {
      padding: 0;
      align-self: center;
    }
  }
  :global(.NewCRMTable__Head-Row),
  :global(.NewCRMTable__Body-Row) {
    display: grid;
    grid-template-columns: 40px 1.5fr 1fr 1fr 1fr 1fr 1fr 2.5fr;
  }

  :global(.NewCRMPagination__left) {
    margin-left: 9px;
  }

  :global(.NewCRMTable__Cell--Thead):first-child {
    margin-top: 10px;
  }
`;

export { staticStyles };
