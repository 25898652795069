import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes, { oneOfType } from 'prop-types';
import { staticStyles } from './style';

const TextActionsInput = ({ labelTextId, inputValue, textId, onClick, disabled, success, noMarginBottom, error }) => {
  const buttonRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const removeIsActive = () => setIsActive(false);

  useEffect(() => {
    document.addEventListener('mouseup', removeIsActive);

    return () => {
      document.removeEventListener('mouseup', removeIsActive);
    };
  }, []);

  return (
    <div
      className={classNames('TextActionsInput', {
        active: isActive,
        success,
        'TextActionsInput--noMarginBottom': noMarginBottom,
        'TextActionsInput--error': error,
      })}>
      <input disabled value={inputValue} />
      <span className={classNames('TextActionsInput__label', { 'TextActionsInput__label--error': error })}>
        <FormattedMessage id={labelTextId} />
      </span>
      <button
        ref={buttonRef}
        className="TextActionsInput__button"
        type="button"
        disabled={disabled}
        onMouseDown={() => setIsActive(true)}
        onClick={onClick}>
        <FormattedMessage id={textId} />
      </button>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

TextActionsInput.propTypes = {
  inputValue: oneOfType([PropTypes.string, PropTypes.number]),
  textId: PropTypes.string.isRequired,
  error: PropTypes.bool,
  labelTextId: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
};

TextActionsInput.defaultProps = {
  labelTextId: '',
  inputValue: '',
  onClick: null,
  disabled: false,
  success: false,
  error: false,
  noMarginBottom: false,
};

export default TextActionsInput;
