import css from "styled-jsx/css";
import { isRTL } from "common/styling/theme";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .FulfillmentOfPlans {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: rem(30px 0 30px);
  }

  .FulfillmentOfPlans__top-line {
    margin-bottom: rem(20px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include respond-to(sm) {
      h3 {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .FulfillmentOfPlans__left-inner,
  .FulfillmentOfPlans__right-inner {
    padding: rem(30px 40px 0 40px);
    @include respond-to(sm) {
      padding: rem(20px 20px 0 20px);
    }
  }

  .FulfillmentOfPlans__left-inner {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include respond-to(md) {
      width: 100%;
    }
    .ChartInner {
      width: 48%;
      position: relative;
      @include respond-to(sm) {
        width: 100%;
      }
      &:first-child {
        @include respond-to(md) {
          padding: 0;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          width: 1px;
          height: 85%;
          @include respond-to(md) {
            display: none;
          }
        }
      }
    }
  }

  .FulfillmentOfPlans__right-inner {
    position: relative;
    width: 33%;
    @include respond-to(md) {
      width: 100%;
      padding: rem(0 40px 0 40px);
    }
    @include respond-to(sm) {
      padding: rem(0 20px 0 20px);
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .FulfillmentOfPlans__left-inner,
    .FulfillmentOfPlans__right-inner {
      background: ${colors.white.hex};
      border-radius: ${border.radius};
    }

    .FulfillmentOfPlans__left-inner {
      .ChartInner {
        &:first-child {
          padding-right: ${isRTL ? '0' : '30px'};
          padding-left: ${isRTL ? '30px' : '0'};
        }
        &:after {
          right: ${isRTL ? 'auto' : '0'};
          left: ${isRTL ? '0' : 'auto'};
          background: ${colors.lightGray.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
