import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { compose } from 'redux';
import PropTypes from 'prop-types';

import { getAllReassignsTasks } from 'crm/tasks/_redux';

import { staticStyles } from './style';
import InnerTabs from '../InnerTabs';
import ReassignsTable from '../ReassignsTable';

const ReassignTasksWrapper = ({
  allOpenReassignsTasks,
  allClosedReassignsTasks,
  allReassignsTasksAreLoaded,
  getAllReassignsTasks,
  location,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const [currentInnerTab, setCurrentInnerTab] = useState('open');

  const currentlySelectedTasks = useMemo(
    () => (currentInnerTab === 'open' ? allOpenReassignsTasks : allClosedReassignsTasks),
    [currentInnerTab, allOpenReassignsTasks, allClosedReassignsTasks, allReassignsTasksAreLoaded]
  );

  useEffect(() => {
    getAllReassignsTasks({ search: query });
  }, [query]);

  return (
    <div className="ReassignTasksWrapper">
      <InnerTabs
        currentTab={currentInnerTab}
        setCurrentInnerTab={setCurrentInnerTab}
        openCount={allOpenReassignsTasks?.count}
        closedCount={allClosedReassignsTasks?.count}
      />
      <ReassignsTable reassignTasks={currentlySelectedTasks} reassignTasksAreLoaded={allReassignsTasksAreLoaded} />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ReassignTasksWrapper.propTypes = {
  allOpenReassignsTasks: PropTypes.object.isRequired,
  allClosedReassignsTasks: PropTypes.object.isRequired,
  allReassignsTasksAreLoaded: PropTypes.bool.isRequired,
  getAllReassignsTasks: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      allOpenReassignsTasks: state.crm.tasks.allOpenReassignsTasks,
      allClosedReassignsTasks: state.crm.tasks.allClosedReassignsTasks,
      allReassignsTasksAreLoaded: state.crm.tasks.allReassignsTasksAreLoaded,
    }),
    {
      getAllReassignsTasks: search => getAllReassignsTasks.request(search),
    }
  )
)(ReassignTasksWrapper);
