import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { staticStyles, getDynamicStyles } from './style';
import radio from './radio.svg';
import radioActive from './radioActive.svg';

const RadioTabs = ({ externalClass, theme, tabSlugActive, children, searchId, searchOff, displayMode }) => {
  children = useMemo(
    () => (Object.getPrototypeOf(children).constructor.name === 'Object' ? [children] : children),
    [children]
  );
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);
  const searchTab = searchOff ? null : searchId ? search[`${searchId}_radio`] : search.radio;

  const tabs = useMemo(
    () => React.Children.toArray(children).filter(({ props: { tabTextId, tabSlug } }) => tabTextId && tabSlug),
    [children]
  );

  const updateSearch = tab => {
    if (searchOff) return;
    const propTab = searchId ? `${searchId}_radio` : 'radio';
    history.replace({
      ...location,
      search: queryString.stringify({ ...search, [propTab]: tab }),
    });
  };

  const [tabActive, setTabActive] = useState(searchTab || tabSlugActive || tabs[0].props.tabSlug);

  const tabContent = useMemo(() => {
    let content = tabs.filter(({ props: { tabSlug, tabDisabled } }) => tabSlug === tabActive && !tabDisabled)[0];
    if (!content) {
      setTabActive(children[0].props.tabSlug);
      content = children[0];
    }
    return content;
  }, [tabs, tabActive]);

  const tabsContent = useMemo(
    () =>
      tabs.map(item =>
        React.cloneElement(item, {
          className:
            item.props.tabSlug !== tabActive || item.props.tabDisabled
              ? `${item.props.className} ${'RadioTabs__content--display-none'}`
              : item.props.className,
        })
      ),
    [tabs, tabActive]
  );

  useEffect(() => updateSearch(tabActive), [tabActive]);

  return (
    <div className={classNames('RadioTabs', externalClass)}>
      <div className="RadioTabs__list">
        {tabs.map(tab => {
          const {
            key,
            props: { tabTextId, tabSlug, tabDisabled },
          } = tab;
          return (
            <button
              type="button"
              className={classNames('RadioTabs__tab', {
                'RadioTabs__tab--active': tabSlug === tabActive,
                'RadioTabs__tab--disabled': tabDisabled,
              })}
              disabled={tabDisabled}
              key={key}
              onClick={() => setTabActive(tabSlug)}>
              {tabSlug === tabActive ? <img src={radioActive} alt="radioActive" /> : <img src={radio} alt="radio" />}
              <FormattedMessage id={tabTextId} />
            </button>
          );
        })}
      </div>
      <div className="RadioTabs__content">{displayMode ? tabsContent : tabContent}</div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

RadioTabs.propTypes = {
  externalClass: PropTypes.string,
  theme: PropTypes.object,
  tabSlugActive: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RadioTabs.defaultProps = {
  externalClass: '',
  tabSlugActive: null,
  theme: {},
};

export default withTheme()(RadioTabs);
