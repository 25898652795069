import css from 'styled-jsx/css';

import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .BaseButton {
    position: relative;
    display: inline-block;
    padding: rem(12px 16px);
    cursor: pointer;
    font-size: rem(14px);
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    min-width: rem(92px);
    border-radius: 8px;

    &:active {
      box-shadow: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .plus {
    padding-left: rem(${isRTL ? '20px' : '46px'});
    padding-right: rem(${isRTL ? '40px' : '16px'});

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: rem(12px);
      height: rem(2px);
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      left: rem(${isRTL ? '0' : '16px'});
      right: rem(${isRTL ? '13px' : '0'});
    }

    &:after {
      transform: rotate(-90deg);
      top: 48%;
    }
  }

  .maxContent {
    width: max-content;
  }

  .noBorder {
    border-radius: 0px !important;
  }

  .fill {
    width: 100%;
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .BaseButton {
      border-radius: ${border.radius};
      color: ${colors.white.hex};
      border: none;
      background: ${colors.primary.hex};

      &:hover {
        background: #0990cb;
      }

      &:disabled {
        background: ${colors.gray.hex};
      }

      &__white {
        border: 1px solid ${colors.primary.hex};
        color: ${colors.black.hex};
        background: ${colors.white.hex};

        &:hover {
          color: ${colors.white.hex};
        }

        &:hover.plus {
          &:after,
          &:before {
            background-color: ${colors.white.hex};
          }
        }

        &:disabled {
          background: ${colors.gray.hex};
        }
      }

      &__white.plus {
        &:after,
        &:before {
          background-color: ${colors.black.hex};
        }
      }
    }

    .plus {
      &:after,
      &:before {
        background-color: ${colors.white.hex};
      }
    }
    .BaseButton__red {
      border: 1px solid #ea4c4c;
      color: ${colors.white.hex};
      background: #ea4c4c;
      &:hover {
        border: 1px solid #ea4c4c;
        color: ${colors.white.hex};
        background: #ea4c4c;
      }
      &:disabled {
        background: ${colors.gray.hex};
      }
    }

    .BaseButton__success {
      background: ${colors.success.hex};
      color: ${colors.white.hex};
      &:hover {
        background: ${rgba([colors.success.rgb, 0.5])};
        border-color: ${colors.success.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.success.rgb, 0.5])};
      }
    }

    .BaseButton__error {
      background: ${colors.error.hex};
      color: ${colors.white.hex};
      &:hover {
        background: ${rgba([colors.error.rgb, 0.5])};
        border-color: ${colors.error.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.error.rgb, 0.5])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
