import axios from 'axios';

const URLS = {
  clientList: '/staff/clients/',
  staffMemberList: '/staff/members/',
  staffMemberDetail: id => `/staff/members/${id}/`,
  staffGroupList: '/staff/groups/',
  staffGroupDetail: id => `/staff/groups/${id}/`,
};

const getClientList = search =>
  axios.get(URLS.clientList, {
    params: {
      search,
    },
  });
const getStaffMemberList = () => axios.get(URLS.staffMemberList);
const saveStaffMember = data => axios.patch(URLS.staffMemberDetail(data.id), data);

const getStaffGroupList = () => axios.get(URLS.staffGroupList);
const saveStaffGroup = data => axios.patch(URLS.staffGroupDetail(data.id), data);
const addStaffGroup = data => axios.post(URLS.staffGroupList, data);
const removeStaffGroup = data => axios.delete(URLS.staffGroupDetail(data.id));

export {
  getClientList,
  getStaffMemberList,
  saveStaffMember,
  getStaffGroupList,
  addStaffGroup,
  saveStaffGroup,
  removeStaffGroup,
};
