import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .StatusCell {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.6px;
    padding-left: 13px !important;
    position: relative;
    &:after {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }

  .StatusCell__no-dot {
    padding: 0 !important;
    &:after {
      display: none;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .StatusCell {
      color: ${colors.error.hex} !important;
      &:after {
        background-color: ${colors.error.hex} !important;
      }
      &__success {
        color: ${colors.success.hex} !important;
        &:after {
          background-color: ${colors.success.hex} !important;
        }
      }
      &__warning {
        color: ${colors.warning.hex} !important;
        &:after {
          background-color: ${colors.warning.hex} !important;
        }
      }
      &__blocked {
        color: ${colors.gray.hex} !important;
        &:after {
          background-color: ${colors.gray.hex} !important;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
