import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";

  .FaqDetailPage {
    &__name {
      margin-bottom: 25px;
    }

    &__search {
      .SearchPanel__input-inner {
        width: 100%;
      }
    }
  }
`;

export { staticStyles };
