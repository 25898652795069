import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ModalHOC } from 'common';
import { compose } from 'redux';

import { closeModal, openModal } from 'interface/user/_redux/actions';
import { PageContentPreloader, BasicModal, DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';

import { UserNoInfoPanel } from '../../../_components';

import { getUserNotes, getUserNoteDocument, deleteUserNote, removeUserNoteDocument } from '../../_redux';
import UserNoteCreateForm from '../UserNoteCreateForm';
import UserNoteEditForm from '../UserNoteEditForm';
import UserNote from '../UserNote';

import { staticStyles, getDynamicStyles } from './style';

const UserNotes = ({
  id,
  notes,
  notesIsLoaded,
  getUserNotes,
  getUserNoteDocument,
  deleteUserNote,
  removeUserNoteDocument,
  profile,
  modals,
  openModal,
  closeModal,
  showModal,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [noteId, setNoteId] = useState(null);

  useEffect(() => {
    getUserNotes(id);
  }, [getUserNotes, id]);

  const handleDownloadNote = documentId => {
    getUserNoteDocument({ userId: id, documentId });
  };

  const handleEditNote = noteId => {
    openModal('editNote');
    setNoteId(noteId);
  };

  const handleRemoveNoteDocument = documentId => {
    removeUserNoteDocument({ userId: id, documentId });
  };

  const handleDeleteNote = noteId => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeleteNote',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deleteUserNote({ userId: id, noteId }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  return (
    <div className="UserNotes">
      <PageContentPreloader ready={notesIsLoaded} type="smallLines">
        <div className="UserNotes__content-inner">
          <div className="UserNotes__head">
            <FormattedMessage id="boNotes">{txt => <h2>{txt}</h2>}</FormattedMessage>
            {(profile.perms.notesUser || profile.perms.managerNotesUser) && (
              <DefaultButton onClick={() => openModal('createNote')} textId="createNote" plus filled />
            )}
          </div>
          <div className="UserNotes__cels">
            <FormattedMessage id="justAuthor" />
            <FormattedMessage id="justTitle" />
            <FormattedMessage id="justDate" />
            <FormattedMessage id="justPayment" />
            <span />
          </div>
          {notes.length ? (
            notes.map(note => (
              <UserNote
                key={note.id}
                noteId={note.id}
                createdBy={note.createdBy}
                created={note.created}
                title={note.title}
                text={note.text}
                isPinned={note.isPinned}
                documents={note.documents}
                paymentRequest={note.paymentRequest}
                internalTransfer={note.internalTransfer}
                handleDownloadNote={handleDownloadNote}
                handleEditNote={handleEditNote}
                handleDeleteNote={handleDeleteNote}
                handleRemoveNoteDocument={handleRemoveNoteDocument}
              />
            ))
          ) : (
            <UserNoInfoPanel image={noInfoImage} textId="boNoNotes" />
          )}
        </div>
      </PageContentPreloader>

      <BasicModal
        isOpen={modals.createNote}
        captionId="createNoteModalHeader"
        onRequestClose={() => closeModal('createNote')}>
        <UserNoteCreateForm id={id} onSubmitSuccess={() => closeModal('createNote')} />
      </BasicModal>

      <BasicModal
        isOpen={modals.editNote}
        captionId="editNoteModalHeader"
        onRequestClose={() => closeModal('editNote')}>
        <UserNoteEditForm
          id={id}
          noteId={noteId}
          onSubmitSuccess={() => closeModal('editNote')}
          initialValues={notes.find(note => note.id === noteId)}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserNotes.propTypes = {
  id: PropTypes.string.isRequired,
  notes: PropTypes.array.isRequired,
  getUserNotes: PropTypes.func.isRequired,
  notesIsLoaded: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getUserNoteDocument: PropTypes.func.isRequired,
  removeUserNoteDocument: PropTypes.func.isRequired,
  deleteUserNote: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

UserNotes.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      profile: state.user.profile,
      notes: state.backoffice.users.notes,
      notesIsLoaded: state.backoffice.users.notesIsLoaded,
      modals: state.user.modals,
    }),
    {
      getUserNotes: id => getUserNotes.request(id),
      openModal: name => openModal(name),
      closeModal: name => closeModal(name),
      getUserNoteDocument: data => getUserNoteDocument.request(data),
      deleteUserNote: data => deleteUserNote.request(data),
      removeUserNoteDocument: data => removeUserNoteDocument.request(data),
    }
  )
)(UserNotes);
export { UserNotes };
