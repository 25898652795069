import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const BonusesAllIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 22 21" width="22" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.326.2l3.207 6.516L21.7 7.77c.3.05.45.4.2.651l-5.162 5.012 1.203 7.117c.05.3-.25.551-.551.401l-6.365-3.358-6.414 3.358c-.25.15-.602-.1-.552-.4l1.203-7.118L.1 8.37c-.2-.2-.1-.601.2-.651l7.167-1.053L10.674.201c.15-.251.552-.251.652 0z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="3.872"
          y1="22.549"
          x2="24.381"
          y2="16.085"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

BonusesAllIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

BonusesAllIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedBonusesAllIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(BonusesAllIconActive);

export default ThemedBonusesAllIconActive;
