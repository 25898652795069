import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import { BasicModal, PageTabs, TranslatedNavLink } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { resetAuth } from '../_redux';
import { LoginForm, PhoneBindForm, PhoneCodeForm, AutologinEmailConfirmError } from '../_components';

import { getDynamicStyles } from './style';

const LoginPage = ({
  authError,
  authStep,
  resetAuth,
  isAutologinEmailConfirmError,
  location,
  history,
  theme,
  telegramAuthError,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const query = queryString.parse(location.search);

  const { telegramAuth } = queryString.parse(location.search);
  const isTelegramAuth = telegramAuth === 'true' || telegramAuthError;
  const [rememberUser, setRememberUser] = useState(false);

  useEffect(() => {
    if (telegramAuthError)
      history.replace({
        ...location,
        search: queryString.stringify({
          ...query,
          telegramAuth: true,
        }),
      });
  }, [telegramAuthError]);

  return (
    <div className="LoginPage">
      {isAutologinEmailConfirmError ? (
        <AutologinEmailConfirmError resetAuth={resetAuth} />
      ) : (
        <>
          <PageTabs forAuth>
            <TranslatedNavLink navLinkId="login-page-nav-to-login-page-active" id="authLogin" to="/auth/login" />
            <TranslatedNavLink
              navLinkId="login-page-nav-to-registration-page"
              id="authRegister"
              to={`/auth/register${isTelegramAuth ? `?telegramAuth=true` : ''}`}
            />
          </PageTabs>
          {query.message && (
            <FormattedMessage id={query.message}>
              {txt => (
                <div className="Auth__message">
                  <p>{txt}</p>
                </div>
              )}
            </FormattedMessage>
          )}
          {isTelegramAuth && !authError && (
            <div className="Auth__message">
              <FormattedMessage id="telegramAuthMessage">{txt => <p>{txt}</p>}</FormattedMessage>

              <FormattedMessage id="telegramAuthHint">
                {txt => <p className="Auth__message_hint">{txt}</p>}
              </FormattedMessage>
            </div>
          )}
          <LoginForm authError={authError} isTelegramAuth={isTelegramAuth} setRememberUser={setRememberUser} />
          <BasicModal
            isOpen={!!authStep}
            captionId={authStep === 'bind_phone' ? 'authVerifyNumber' : 'authMsgSent'}
            descriptionId={authStep === 'bind_phone' ? 'authVerifyNumberDescr' : 'authMsgSentDescr'}
            onRequestClose={() => resetAuth('')}>
            {(authStep === 'bind_phone' && <PhoneBindForm />) || <PhoneCodeForm rememberUser={rememberUser} />}
          </BasicModal>
        </>
      )}
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

LoginPage.propTypes = {
  resetAuth: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  authStep: PropTypes.string,
  authError: PropTypes.string,
  isAutologinEmailConfirmError: PropTypes.bool.isRequired,
  telegramAuthError: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

LoginPage.defaultProps = {
  authStep: '',
  authError: '',
  theme: {},
};

const ThemedLoginPage = withTheme()(
  connect(
    state => ({
      authStep: state.auth.login.authStep,
      authError: state.auth.login.authError,
      isAutologinEmailConfirmError: state.auth.login.isAutologinEmailConfirmError,
      telegramAuthError: state.auth.login.telegramAuthError,
    }),
    { resetAuth }
  )(LoginPage)
);

export default ThemedLoginPage;
export { LoginPage };
