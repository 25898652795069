import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PeriodicallyReportForm {
    .PeriodicallyReportForm__line {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: rem(10px);

      :global(> div) {
        max-width: rem(400px);
        &:nth-child(2n + 1) {
          margin-right: rem(20px);
        }
      }

      @include respond-to(sm) {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        margin-down: rem(10px);

        :global(> div) {
          margin-top: 0;
          max-width: unset;
          width: 100%;
        }
      }
    }
  }
`;

export { staticStyles };
