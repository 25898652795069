import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestDetailPage__chart-tabs {
    margin: rem(20px 0);
  }

  .InvestDetailPage__investments-tabs {
    margin-bottom: rem(20px);
  }

  .InvestDetailPage__tab {
    font-size: rem(20px);
    line-height: 23px;
    margin-right: ${isRTL ? '0' : '35px'};
    margin-left: ${isRTL ? '35px' : '0'};
    padding-bottom: 15px;
    cursor: pointer;
    border-bottom: 3px solid transparent;

    &:last-child {
      margin-right: 0;
    }

    @include respond-to(sm) {
      font-size: 15px;
      line-height: normal;
      padding-bottom: 5px;
      border-bottom-width: 2px;
      margin-right: ${isRTL ? '0' : '15px'};
      margin-left: ${isRTL ? '15px' : '0'};
    }
  }

  .InvestDetailPage__invest-caption {
    margin: rem(30px 0);
    font-size: rem(20px);
  }

  .InvestDetailPage {
    :global(&__edit-button) {
      position: absolute !important;
      right: 0;
      bottom: calc(100% + 26px);

      @include respond-to(sm) {
        bottom: calc(100% + 19px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .InvestDetailPage__tab {
      &.active {
        color: ${colors.primary.hex};
        border-bottom-color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
