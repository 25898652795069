import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserInfoAccountInformation {
    position: relative;
    background: #fff;
    padding: 25px 25px 25px 25px;
    margin: 0 0 20px 0;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 15px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__title-ava-wrap {
      display: flex;

      @include respond-to(lg) {
        display: block;
      }
    }

    &__title-wrap {
      flex: 1;
      margin: 0 0 32px 0;
      display: flex;
      align-items: flex-end;

      &--hide {
        margin: 0 0 0 0;
      }
    }

    &__title {
      width: 100%;
      margin: 0 20px 0 0;

      @include respond-to(sm) {
        margin: 0 0 0 0;
      }
    }

    &__wallet-wrap {
      width: 50%;
      margin: 0 20px 0 0;

      @include respond-to(lg) {
        margin: 0 0 0 0;
      }

      &--hide {
        display: none;
      }
    }

    &__wallet-subwrap {
      display: flex;
      max-width: 500px;
    }

    &__wallet-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
    }

    &__wallet-data {
      width: 60%;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
    }

    &__ava-170 {
      width: 170px;

      @include respond-to(lg) {
        display: none;
      }
    }

    &__content-ava-wrap {
      display: flex;

      @include respond-to(lg) {
        display: block;
      }
    }

    &__content-ava-170 {
      width: 170px;

      @include respond-to(lg) {
        display: none;
      }
    }

    &__content {
      display: flex;
      flex: 1;
      margin: 0 20px 0 0;

      @include respond-to(lg) {
        margin: 0 0 0 0;
      }

      @include respond-to(sm) {
        display: block;
      }

      &--hide {
        display: none;
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__content-left {
      width: 50%;
      margin: 0 20px 0 0;

      @include respond-to(sm) {
        width: 100%;
        min-width: 0;
        margin: 0 0 0 0;
      }
    }

    &__reg-date-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__reg-date-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__reg-date-data {
      width: 60%;

      :global(.DateCell) {
        display: flex;
      }

      :global(.DateCell span) {
        line-height: 16px;
      }

      :global(.DateCell > span:nth-of-type(1)) {
        margin: 0 5px 0 0;
      }
    }

    &__last-activity-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__last-activity-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__last-activity-data {
      width: 60%;
    }

    /* old user status */
    &__verification-status-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__verification-status-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__verification-status-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__sumsub-id-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__sumsub-id-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__sumsub-id-data {
      width: 60%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      word-break: break-all;
    }

    &__profile-validity-wrap {
      display: flex;
      margin: 0 0 20px 0;

      @include respond-to(sm) {
        margin: 0 0 20px 0;
      }
    }

    &__profile-validity-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__sumsub-report-wrap {
      display: flex;
      margin: 0 0 20px 0;

      @include respond-to(sm) {
        margin: 0 0 20px 0;
      }
    }

    &__sumsub-report-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__profile-validity-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__sumsub-report-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
      color: #0aa1e2;
      :hover {
        color: #005479;
      }
    }

    &__support-messages-wrap {
      display: flex;
      margin: 0 0 20px 0;

      @include respond-to(sm) {
        margin: 0 0 20px 0;
      }
    }

    &__support-messages-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__support-messages-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__lpa-wrap {
      display: flex;
      margin: 0 0 0 0;

      @include respond-to(sm) {
        margin: 0 0 0 0;
      }
    }

    &__lpa-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__lpa-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__content-right {
      width: 50%;

      @include respond-to(sm) {
        width: 100%;
        min-width: 0;
      }
    }

    &__wallet-balance-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__wallet-balance-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__wallet-balance-data {
      &-wrap {
        display: flex;
        gap: 4px;
      }

      width: 60%;
    }

    &__client-status-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__client-status-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__client-status-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__password-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__password-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__password-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__manager-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__manager-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__manager-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__security-code-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__security-code-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__security-code-data {
      width: 60%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__partner-code-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__partner-code-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__partner-code-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;
      align-items: center;

      :global(.ActionsButton__edit svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__promo-code-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__promo-code-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__promo-code-data {
      width: 60%;
    }

    &__manual_approval-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__manual_approval-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__manual_approval-data {
      width: 60%;
    }
  }
`;

export { staticStyles };
