import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Agreements {
    text-align: left;
    width: auto;
    max-width: 100%;
    :global(.CheckboxField) {
      :global(a) {
        width: auto;
        max-width: calc(100% - 30px);
      }
    }
  }
`

export { staticStyles }
