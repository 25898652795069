import css from 'styled-jsx/css'

const staticStyles = css.global`
  .DefaultAuthPage {
    text-align: center;
    h3 {
      margin-bottom: 20px;
    }
  }

  .DefaultAuthPage__top-inner {
    span {
      margin-bottom: 30px;
    }
  }

  .DefaultAuthPage__link {
    margin: 20px 0 25px;
    font-size: 13px;
    line-height: 1.38;
    position: relative;
    padding-bottom: 1px;
    &:active {
      transform: translateY(2px);
      &:after {
        transform: scale(0);
      }
    }
    &:hover {
      &:after {
        transform: scale(0);
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scale(0.99);
      transition: all 0.4s ease;
      opacity: 0.4;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .DefaultAuthPage__link {
      color: ${colors.gray.hex};
      &:hover {
        color: ${colors.primary.hex};
        &:after {
          background-color: ${colors.primary.hex};
        }
      }
      &:after {
        background-color: ${colors.gray.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
