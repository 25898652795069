/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import { Tooltip } from 'common/components';

import { getTasksCountsNew } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const TasksData = ({ tasksCountsNew, getTasksCountsNew, perms, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const history = useHistory();

  useEffect(() => {
    getTasksCountsNew();
  }, []);

  return (
    <button type="button" className="TasksData">
      <Tooltip id="menuTasksSuccessTooltip" textId="justTodayTasks" place="bottom">
        <div onClick={() => history.push('/crm/tasks/')} className="TasksData__item success">
          {tasksCountsNew.today}
        </div>
      </Tooltip>
      <Tooltip id="menuTasksTomorrowTooltip" textId="justTomorrowTasks" place="bottom">
        <div onClick={() => history.push('/crm/tasks/')} className="TasksData__item">
          {tasksCountsNew.tomorrow}
        </div>
      </Tooltip>
      <Tooltip id="menuTasksOverdueTooltip" textId="justOverdueTasks" place="bottom">
        <div onClick={() => history.push('/crm/tasks/')} className="TasksData__item overdue">
          {tasksCountsNew.overdue}
        </div>
      </Tooltip>
      {perms.setManagerPerm && (
        <Tooltip id="menuTasksReassignTooltip" textId="justReassignTasks" place="bottom">
          <div onClick={() => history.push('/crm/tasks?tab=reassign')} className="TasksData__item reassign">
            {tasksCountsNew.reassign}
          </div>
        </Tooltip>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </button>
  );
};

TasksData.propTypes = {
  getTasksCountsNew: PropTypes.func.isRequired,
  tasksCountsNew: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

TasksData.defaultProps = {
  theme: {},
};

export default withTheme()(
  connect(
    state => ({
      tasksCountsNew: state.crm.tasks.tasksCountsNew,
      tasksCountsNewAreLoaded: state.crm.tasks.tasksCountsNewAreLoaded,
      perms: state.user.profile.perms,
    }),
    { getTasksCountsNew: () => getTasksCountsNew.request() }
  )(TasksData)
);
