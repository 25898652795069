import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .NoteItem {
    position: relative;

    &__Title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: #333;

      @include respond-to(xs) {
        margin-left: 44px;
      }
    }

    &__Author {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      span {
        margin-left: 8px;
      }

      @include respond-to(lg) {
        position: absolute;
        right: 0;
        top: 0;
      }

      @include respond-to(md) {
        left: 0;
        bottom: 0;
        right: initial;
        top: initial;
      }
    }

    &__Description {
      margin-top: 4px;
      max-width: 480px;
      width: 100%;
      font-size: 14px;
      line-height: 1.4;
      color: #666;
    }

    &__Documents {
      margin-top: 12px;
    }

    &__FilesText {
      font-size: 12px;
      line-height: 1.4;
      color: #cecece;
    }
  }
`;

export { staticStyles };
