import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { Link } from 'react-router-dom';

import { staticStyles, getDynamicStyles } from './style';

const PaymentsTip = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div className="PaymentsTip">
      <FormattedMessage id="paymentIsFrozen">
        {txt => <span className="PaymentsTip__caption">{txt}</span>}
      </FormattedMessage>
      <FormattedMessage id="userVerificationIsNeeded">
        {txt => <span className="PaymentsTip__text">{txt}</span>}
      </FormattedMessage>
      <ul className="PaymentsTip__list">
        <FormattedMessage
          id="uploadPassportOrId"
          values={{
            documents: (
              <Link to="/documents/">
                <FormattedMessage id="justDocuments" />
              </Link>
            ),
          }}
        />
        <FormattedMessage
          id="uploadProofOfAddress"
          values={{
            documents: (
              <Link to="/documents/">
                <FormattedMessage id="justDocuments" />
              </Link>
            ),
          }}
        />
        <FormattedMessage
          id="uploadPaymentCard"
          values={{
            cards: (
              <Link to="/cards/">
                <FormattedMessage id="justCards" />
              </Link>
            ),
          }}
        />
      </ul>
      <FormattedMessage
        id="afterVerificationMessage"
        values={{
          support: (
            <Link to="/support/" id="PaymentsTip-Link-to-support">
              <FormattedMessage id="contactCustomerSupport" />
            </Link>
          ),
        }}
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PaymentsTip.propTypes = {
  theme: PropTypes.object,
};

PaymentsTip.defaultProps = {
  theme: {},
};

export default withTheme()(PaymentsTip);

export { PaymentsTip };
