import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { getCookie } from 'common/utils/helpers';
import { PageTabs } from 'common/components';
import queryString from 'query-string';
import { FormattedMessage as FM } from 'react-intl';

import RegistrationForm from 'auth/registration/_components/RegistrationForm';
import ThankForRegistration from 'auth/registration/_components/ThankForRegistration';

import { staticStyles } from './style';

const parentUrl = document.referrer;
let urlObject;
let mainUrl;
if (parentUrl !== '') {
  urlObject = new URL(parentUrl);
  mainUrl = urlObject.origin;
}

const RegistrationPage = ({ isEmailConfirmationEnabled, location, history, partnershipConfig }) => {
  const { partnerCodeParamName } = partnershipConfig;
  const partnerCodeCookie = getCookie(partnerCodeParamName);

  /* eslint-disable */
  const [isRegistered, setRegistered] = useState(false);
  const queryPartnerCode = queryString.parse(location.search)[partnerCodeParamName];

  const partnerCode = queryPartnerCode || localStorage.getItem('partnerCode') || partnerCodeCookie;
  const initialValues = { partnerCode: partnerCode };

  return (
    <div className="Auth__form-inner">
      <PageTabs forAuth noScroll>
        <a className="RegistrationWidjet__SiteHref" href={mainUrl ?? '/'} target="_blank">
          <FM id="widjetGoToSite">{txt => txt}</FM>
        </a>
        <a disable>
          <FM id="authDoRegister">{txt => txt}</FM>
        </a>
      </PageTabs>
      {isEmailConfirmationEnabled && isRegistered ? (
        <ThankForRegistration />
      ) : (
        <RegistrationForm
          initialValues={initialValues}
          displayThankBlock={() => setRegistered(true)}
          isEmailConfirmationEnabled={isEmailConfirmationEnabled}
          history={history}
          isTelegramAuth={false}
        />
      )}
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

RegistrationPage.propTypes = {
  isEmailConfirmationEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  telegramAuthError: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

RegistrationPage.defaultProps = {
  location: {},
  theme: {},
};
export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      isEmailConfirmationEnabled: state.interfaceConfig.emailConfirmation.enabled,
      telegramAuthError: state.auth.registration.telegramAuthError,
      partnershipConfig: state.interfaceConfig.partnership,
    }),
    null
  )
)(RegistrationPage);
export { RegistrationPage };
