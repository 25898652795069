import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { DefaultButton, ReduxTextField, FileField, ReduxSelectField, ActionsButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { createFile } from 'admin/files/_redux';
import { required, url } from 'redux-form-validators';
import { BasketIcon } from '../../../../common/icons';
import { createPromo } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const UploadPromoForm = ({
  campaignList,
  availableLangs,
  promoCount,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  theme,
  history,
  error,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [banners, setBanners] = useState([...Array(promoCount).keys()]);

  const submit = handleSubmit(values => createPromo(values, dispatch));

  const handleAfterUpload = uploadFile => {
    const formData = new FormData();
    formData.set('title', uploadFile[0].name);
    formData.append('file', uploadFile[0]);
    createFile(formData, dispatch);
  };

  const campaignOptions = Array.isArray(campaignList)
    ? campaignList.map(item => ({ value: item.id, label: item.title }))
    : [];

  const deleteBanner = banner => {
    setBanners(banners.filter(item => item !== banner));
  };

  if (!banners.length) {
    history.push('/backoffice/promo/add');
  }

  return (
    <form className="UploadPromoForm" onSubmit={submit}>
      {banners.map((banner, id) => (
        <div className="UploadPromoForm__banner" key={banner}>
          <div className="UploadPromoForm__settings-block">
            <div className="UploadPromoForm__settings-header">
              <FormattedMessage id="justBanner">
                {txt => <span className="UploadPromoForm__caption">{`${txt} ${id + 1}`}</span>}
              </FormattedMessage>
              <ActionsButton
                tooltipId={`Delete__tooltip-${id}`}
                tooltipTextId="justDeleteFile"
                deleteType
                onClickFunc={() => deleteBanner(banner)}>
                <BasketIcon />
              </ActionsButton>
            </div>
            <Field
              component={ReduxSelectField}
              name={`banner${id}.campaign`}
              textId="campaignName"
              options={campaignOptions}
              disabled={submitting}
              validate={[required()]}
            />
            <Field
              component={ReduxSelectField}
              name={`banner${id}.language`}
              textId="justLanguage"
              options={availableLangs.map(item => ({ value: item.slug, label: item.niceName }))}
              disabled={submitting}
              validate={[required()]}
            />
            <Field
              component={ReduxTextField}
              type="text"
              name={`banner${id}.link`}
              textId="justLink"
              validate={[required(), url({ protocols: ['http', 'https'] })]}
            />
          </div>
          <div className="UploadPromoForm__logo-block">
            <FormattedMessage id="justPreview">
              {txt => <span className="UploadPromoForm__caption">{txt}</span>}
            </FormattedMessage>
            <Field
              component={FileField}
              name={`banner${id}.uploadFile`}
              formatNameId="imagesFormats"
              fileNameId="justAttachments"
              accept="image/png,image/jpeg"
              handleAfterUpload={handleAfterUpload}
              disabled
              validate={[required()]}
            />
          </div>
        </div>
      ))}
      <div className="UploadPromoForm__buttons">
        <DefaultButton textId="doSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
        <DefaultButton textId="justCancel" onClick={() => history.goBack()} disabled={submitting} />
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

UploadPromoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  campaignList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  availableLangs: PropTypes.array,
  promoCount: PropTypes.number,
  error: PropTypes.string,
  theme: PropTypes.object,
};

UploadPromoForm.defaultProps = {
  campaignList: [],
  availableLangs: [],
  promoCount: 0,
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  reduxForm({
    form: 'UploadPromoForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.history.push('/backoffice/promo/list');
    },
  })
)(UploadPromoForm);
export { UploadPromoForm };
