import axios from 'axios';

export const getAuthBanners = () => axios.get(`/common/auth_banners/`);

export const createAuthBanner = formData =>
  axios.post(`/common/auth_banners/create/`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const editAuthBanner = (bannerId, formData) =>
  axios.patch(`/common/auth_banners/${bannerId}/`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteAuthBanner = bannerId => axios.delete(`/common/auth_banners/${bannerId}/`);

export const updateBannersPositions = payload => axios.post(`/common/auth_banners/`, payload);
