import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'common/styling/theme';
import { ActionsButton, AddCardForm, BasicModal, DefaultButton } from 'common/components';
import classNames from 'classnames';
import { addUserCard, getUserCards } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';
import UserCard from '../UserCard';
import arrowTopKYC from './arrowTopKYC.svg';

const UserCardsPanel = ({ userCards, getUserCards, match, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isBigScreen = window.innerWidth > 1280;

  const [openedModal, setOpenedModal] = useState(null);
  const [offset, setOffset] = useState(0);

  const cardsCount = isBigScreen ? 3 : 2;
  useEffect(() => {
    getUserCards(match.params.id);
  }, [getUserCards, match.params.id]);

  const handleSubmit = (formValues, dispatch) => {
    const { firstDigits, lastDigits, nameOnCard, frontPhoto, backPhoto } = formValues;
    const truncatedNumber = `${firstDigits}${lastDigits}`;
    const formData = new FormData();

    formData.set('truncatedNumber', truncatedNumber);
    formData.set('nameOnCard', nameOnCard);
    formData.append('frontPhoto', frontPhoto[0]);
    formData.append('backPhoto', backPhoto[0]);

    return addUserCard({ userId: match.params.id, formData }, dispatch);
  };

  const [openBlock, setOpenBlock] = useState(false);

  return (
    <div className="UserCardsPanel">
      {/* eslint-disable */}
      <div
        className={classNames('UserCardsPanel__open-close', {
          'UserCardsPanel__open-close--close': !openBlock,
        })}
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <div
        className={classNames('UserCardsPanel__nav-info', {
          'UserCardsPanel__nav-info--hide': !openBlock,
        })}>
        <ActionsButton onClickFunc={() => setOffset(offset - 1)} disabled={offset <= 0} />
        <ActionsButton
          onClickFunc={() => setOffset(offset + 1)}
          disabled={offset >= userCards.length - cardsCount + 1}
        />
      </div>
      <FormattedMessage id="justCards">
        {txt => (
          <h2
            className={classNames('UserCardsPanel__title', {
              'UserCardsPanel__title--hide': !openBlock,
            })}>
            {txt}
          </h2>
        )}
      </FormattedMessage>
      {/* eslint-disable */}
      <img
        className={classNames('UserCardsPanel__arrowTopKYC', {
          'UserCardsPanel__arrowTopKYC--hide': !openBlock,
        })}
        src={arrowTopKYC}
        alt="arrowTopKYC"
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <MediaQuery query="(min-width: 768px)">
        <div
          className={classNames('UserCardsPanel__cards', {
            'UserCardsPanel__cards--hide': !openBlock,
          })}>
          {userCards.slice(offset, offset + cardsCount).map((card, id) => (
            <UserCard
              card={card}
              displayId={id + offset}
              userId={match.params.id}
              key={card.id}
              handleSubmit={handleSubmit}
              setOpenedModal={setOpenedModal}
              openedModal={openedModal}
            />
          ))}
          {offset >= userCards.length - cardsCount + 1 && (
            <div className="UserCardsPanel__add-button">
              <DefaultButton textId="justAddCard" onClick={() => setOpenedModal('addModal')} plus filled />
            </div>
          )}
        </div>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <div
          className={classNames('UserCardsPanel__mobile-cards', {
            'UserCardsPanel__mobile-cards--hide': !openBlock,
          })}>
          {userCards.map((card, id) => (
            <UserCard
              card={card}
              userId={match.params.id}
              displayId={id + offset}
              key={card.id}
              handleSubmit={handleSubmit}
              setOpenedModal={setOpenedModal}
              openedModal={openedModal}
            />
          ))}
          <DefaultButton textId="justAddCard" onClick={() => setOpenedModal('addModal')} plus filled />
        </div>
      </MediaQuery>
      <BasicModal isOpen={!!openedModal} captionId="justAddNewCard" onRequestClose={() => setOpenedModal(null)}>
        <AddCardForm handleCancel={() => setOpenedModal(null)} submitFunction={handleSubmit} />
      </BasicModal>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

UserCardsPanel.propTypes = {
  userCards: PropTypes.array.isRequired,
  getUserCards: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

UserCardsPanel.defaultProps = {
  theme: {},
};

export default compose(
  withRouter,
  connect(
    state => ({
      userCards: state.backoffice.users.userCards,
      userCardsAreLoaded: state.backoffice.users.userCardsAreLoaded,
    }),
    {
      getUserCards: userId => getUserCards.request(userId),
    }
  ),
  withTheme()
)(UserCardsPanel);
export { UserCardsPanel };
