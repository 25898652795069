import GoogleAnalytics from 'react-ga';

const GA_ID = localStorage.getItem('ga');

export const GoogleAnalyticsInitialize = () => {
  if (GA_ID) {
    return GoogleAnalytics.initialize(GA_ID, {
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });
  }
  return false;
};

export const googleTrackPage = (page, options) => {
  if (GA_ID) {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  }
};

export const sendGoogleAnalyticsEvent = action => {
  if (GA_ID) {
    GoogleAnalytics.event({
      category: 'User',
      action,
    });
  }
};
