import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RadioTabs {
    &__list {
    }

    &__tab {
      display: flex;

      &--active {
      }
      &--disabled {
      }

      img {
        width: 15px;
        height: 15px;
        margin: 0 8px 0 0;
      }

      span {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__content {
      &--display-none {
      }
    }
  }
`;

const getDynamicStyles = () => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
