import React from 'react';

const CalendarIcon = () => (
  <div className="CalendarIcon">
    <svg fill="none" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="m14.344 2.0667h-2.1249v-0.53335c0-0.31998-0.2125-0.53336-0.5313-0.53336s-0.5313 0.21332-0.5313 0.53336v0.53335h-5.3124v-0.53335c0-0.31998-0.21249-0.53336-0.53128-0.53336-0.31872 0-0.53127 0.21332-0.53127 0.53336v0.53335h-2.125c-1.4875 0-2.6562 1.1733-2.6562 2.6667v9.5999c0 1.4934 1.1687 2.6667 2.6562 2.6667h11.687c1.4876 0 2.6563-1.1733 2.6563-2.6667v-9.5999c0-1.4934-1.1687-2.6667-2.6563-2.6667zm-13.281 2.6667c0-0.85333 0.74376-1.6 1.5938-1.6h2.125v0.53336c0 0.31997 0.21248 0.53335 0.53127 0.53335 0.31873 0 0.53128-0.21331 0.53128-0.53335v-0.53336h5.3125v0.53336c0 0.31997 0.2125 0.53335 0.5313 0.53335 0.3187 0 0.5312-0.21331 0.5312-0.53335v-0.53336h2.125c0.85 0 1.5938 0.74668 1.5938 1.6v1.6h-14.875v-1.6zm13.281 11.2c0.8501 0 1.5938-0.7466 1.5938-1.6v-6.9333h-14.875v6.9333c0 0.8534 0.74376 1.6 1.5938 1.6h11.687z"
          fill="#777"
          opacity=".8"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
      <path d="m4 10h1" stroke="#979797" strokeLinecap="square" />
      <path d="m4 13h1" stroke="#979797" strokeLinecap="square" />
      <path d="m8 10h1" stroke="#979797" strokeLinecap="square" />
      <path d="m8 13h1" stroke="#979797" strokeLinecap="square" />
      <path d="m12 10h1" stroke="#979797" strokeLinecap="square" />
      <path d="m12 13h1" stroke="#979797" strokeLinecap="square" />
    </svg>
  </div>
);

export default CalendarIcon;
