import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  files: [],
  filesAreLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FILES_LIST[REQUEST]:
      return { ...state, filesAreLoaded: false };
    case ActionTypes.GET_FILES_LIST[SUCCESS]:
      return {
        ...state,
        files: action.data,
        filesAreLoaded: true,
      };
    case ActionTypes.GET_FILES_LIST[FAILURE]:
      return {
        ...state,
        error: action.error,
        filesAreLoaded: false,
      };

    default:
      return state;
  }
};

export default reducer;
