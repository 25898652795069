import css from 'styled-jsx/css';
import { rgba, gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PaymentsForm {
    margin-top: rem(40px);
    @include respond-to(sm) {
      padding: rem(30px);
    }

    :global(.FormSchemaComposer__inner.FormSchemaComposer__inner) {
      padding: 0;
      box-shadow: none;
      width: 100%;
      margin-bottom: 0;
    }
  }

  .PaymentsForm__step {
    font-size: rem(26px);
    margin-bottom: rem(30px);
    font-weight: 300;
  }

  .PaymentsForm__caption {
    font-size: rem(16px);
    margin-bottom: rem(20px);
  }

  .PaymentsForm__description {
    margin-bottom: rem(20px);
  }

  .PaymentsForm__systems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1vw;
    margin-bottom: rem(30px);
  }

  .PaymentsForm__inputs-inner {
    display: grid;
    grid-template-columns: minmax(500px, 2fr) repeat(auto-fill, minmax(265px, 1fr));
    grid-column-gap: 25px;

    @include respond-to(sm) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
  }

  .PaymentsForm__inputs-get {
    display: flex;
  }

  .PaymentsForm__inputs-label {
    margin-top: rem(20px);
    margin-right: rem(20px);
  }

  .PaymentsForm__instruction {
    margin-bottom: rem(20px);
  }

  .PaymentsForm__wallet-balance {
    display: flex;
    height: 54.25px;
    align-items: center;

    @include respond-to(sm) {
      height: 48px;
    }

    .PaymentsForm__wallet-caption {
      margin: rem(0 10px);
    }
  }

  .PaymentsForm {
    @include respond-to(sm) {
      padding: 0 0 0 0;
      margin: 22px 0 0 0;
    }

    &__step-1 {
      margin: 0 0 50px 0;

      @include respond-to(sm) {
        margin: 0 0 30px 0;
      }

      &--open {
        margin: 0 0 20px 0;
      }
    }

    &__step-1-wrap-title {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0;
      cursor: pointer;
    }

    &__step-1-number {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin: 0 20px 0 0;

      span {
        background: rgba(240, 240, 240, 1);
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background 0.3s ease;

        .PaymentsForm__step-1--open & {
          background: transparent;
          color: #fff;
        }
      }
    }

    &__step-1-title {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &__step-1-arrow {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 0 auto;

      img {
        transition: transform 0.2s ease;
      }

      &--hide {
        img {
          transform: rotate(-90deg);
        }
      }
    }

    &__step-1-systems {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px 0 20px;
      padding: 5px 0 calc(40px - 20px) calc(40px - 8px);
      border-left: 1px dashed #aeaeae;

      @include respond-to(sm) {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        border: none;
      }

      & > div {
        width: calc(25% - 16px);
        margin: 0 8px 20px 8px;

        @media all and (max-width: 1320px) {
          width: calc(50% - 16px);
        }

        @include respond-to(sm) {
          width: 100%;
          margin: 0 0 20px 0;
        }

        &:last-child {
          @include respond-to(sm) {
            margin: 0 0 0 0;
          }
        }
      }
    }

    &__step-1-payment-info-wrap {
      margin: 0 0 0 20px;
      padding: 0 0 24px 40px;
      border-left: 1px dashed #aeaeae;
    }

    &__step-1-payment-info {
      background: #fff;
      display: flex;
      padding: 20px 20px 20px 20px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
      border-radius: 3px;
      border-bottom: 2px solid transparent;
    }

    &__step-1-payment-info-left {
      width: 130px;
      max-height: 130px;
      display: flex;
      align-items: flex-start;
      margin: 0 20px 0 0;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__step-1-payment-info-right {
      flex: 1;
    }

    &__step-1-payment-info-deposits {
      display: flex;
      margin: 0 -10px 0 -10px;
    }

    &__step-1-payment-info-deposits-item {
      flex: 1;
      width: calc(100% / 3 - 20px);
      margin: 0 10px 10px 10px;
    }

    &__step-1-payment-info-deposits-item-title {
      font-size: 13px;
      line-height: 18px;
      color: #777777;
      margin: 0 0 8px 0;
    }

    &__step-1-payment-info-deposits-item-desc {
      font-size: 13px;
      line-height: 18px;
    }

    &__step-1-payment-info-description {
      font-size: 13px;
      line-height: 20px;
      margin: 0 0 10px 0;
    }

    &__step-1-payment-info-description-2 {
      font-size: 13px;
      line-height: 20px;
    }

    &__step-1-payment-info-confirm {
      margin: 0 0 0 20px;
      padding: 0 0 30px 40px;
      border-left: 1px dashed #aeaeae;
    }

    &__step-1-payment-info-confirm-mobile-lpa {
      margin-top: 10px;
    }

    /* step-2 */

    &__step-2 {
      margin: 0 0 50px 0;

      &--open {
        margin: 0 0 20px 0;
      }

      &--disabled {
      }
    }

    &__step-2-wrap-title {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0;
      cursor: pointer;

      @include respond-to(sm) {
        margin: 0 0 10px 0;
      }

      .PaymentsForm__step-2--disabled & {
        cursor: default;
      }
    }

    &__step-2-number {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin: 0 20px 0 0;

      .PaymentsForm__step-2--disabled & {
        background: #c5c5c5;
      }

      span {
        background: #f0f0f0;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background 0.3s ease;

        .PaymentsForm__step-2--disabled & {
          color: #777777;
        }

        .PaymentsForm__step-2--open & {
          background: transparent;
          color: #fff;
        }
      }
    }

    &__step-2-title {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;

      .PaymentsForm__step-2--disabled & {
        color: #777777;
      }

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &__step-2-arrow {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 0 auto;

      img {
        transition: transform 0.2s ease;
      }

      &--hide {
        img {
          transform: rotate(-90deg);
        }
      }
    }

    &__step-2-wrap {
      padding: 0 0 0 40px;
      margin: 0 0 0 20px;
      border-left: 1px dashed #aeaeae;

      @include respond-to(sm) {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        border-left: none;
      }

      &--hide {
        display: none;
      }
    }

    &__step-2-radio-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 10px 0;
    }

    &__step-2-radio {
      cursor: pointer;
      display: flex;
      align-items: center;

      &:nth-of-type(1) {
        padding: 10px 0 10px 0;
        margin: 0 25px 0 0;
      }

      &:nth-of-type(2) {
        padding: 10px 0 10px 0;
      }

      img {
        width: 15px;
        height: 15px;
        margin: 0 10px 0 0;
      }
    }

    &__step-2-form-subwrap {
      display: flex;
      align-items: flex-start;
      margin: 0 0 20px 0;

      @include respond-to(sm) {
        display: block;
      }
    }

    &__step-2-payment-info {
      background: #fff;
      display: flex;
      padding: 20px 20px 20px 20px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
      border-radius: 3px;
      border-bottom: 2px solid transparent;
      width: 100%;
      flex-wrap: wrap;
    }
    &__step-2-payment-info-item {
      flex: 1;
      width: calc(100% / 3 - 20px);
      margin: 0 10px 10px 10px;
    }
    &__step-2-payment-info-item-receive {
      flex: 1;
      width: 100%;
      margin: 20px 10px 0 10px;
    }
    &__step-2-form-info-line-break {
      flex-basis: 100%;
      height: 0;
    }
    &__step-2-payment-info-item-title {
      font-size: 13px;
      line-height: 18px;
      color: #777777;
      margin: 0 0 8px 0;
    }
    &__step-2-payment-info-item-description {
      font-size: 13px;
      line-height: 18px;
      color: #777777;
      margin: 0 0 8px 0;
    }
    &__step-2-payment-info-item-desc {
      font-size: 13px;
      line-height: 18px;
    }

    /* left */
    &__step-2-form-left {
      display: flex;
      flex-wrap: wrap;
      width: calc(55% - 10px);
      margin: 0 20px 0 0;

      /* &--right-empty {
        width: 100%;
        margin: 0 0 0 0;
      } */

      @include respond-to(sm) {
        width: 100%;
        margin: 0 0 0 0;
      }
    }

    &__step-2-form-wallet {
      width: 100%;
      margin: 0 0 20px 0;
    }

    &__step-2-form-account {
      width: 100%;

      .BaseSelectField {
        margin: 0 0 0 0;
      }
    }

    &__step-2-form-amount {
      width: calc(60% - 10px);
      margin: 0 20px 0 0;

      @include respond-to(sm) {
        width: calc(50% - 10px);
      }

      .BaseTextField {
        margin: 0 0 0 0;

        input {
          @include respond-to(sm) {
            padding: 20px 32px 10px 13px;
          }
        }
      }
    }

    &__step-2-form-currency {
      width: calc(40% - 10px);

      @include respond-to(sm) {
        width: calc(50% - 10px);
      }

      .BaseSelectField {
        margin: 0 0 0 0;

        .Select__control {
          @include respond-to(sm) {
            padding: 18px 18px 4px 9px !important;
          }
        }
      }
    }

    /* right */
    &__step-2-form-right {
      width: calc(45% - 10px);
      background: #ffffff;
      padding: 20px 20px 15px 20px;
      min-height: 127px;

      @include respond-to(sm) {
        width: 100%;
        min-height: 0;
      }
    }

    &__step-2-form-info-line-1 {
      display: flex;
      align-items: center;
      margin: 0 0 8px 0;
    }

    &__step-2-form-info-title-1 {
      margin: 0 16px 0 0;
      font-size: 13px;
      line-height: 18px;
      color: #1d1d1d;
    }

    &__step-2-form-info-text-1 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }

    &__step-2-form-info-line-2 {
      font-size: 12px;
      line-height: 20px;
      color: #1d1d1d;
      margin: 0 0 10px 0;
      display: flex;
      flex-wrap: wrap;
    }

    &__step-2-form-info-title-2 {
      margin: 0 5px 0 0;
    }

    &__step-2-form-info-text-2 {
    }

    &__step-2-form-info-line-3 {
      display: flex;
    }

    &__step-2-form-info-line-3-icon {
      line-height: 0;
      margin: 0 10px 0 0;
    }

    &__step-2-form-info-line-3-text {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      color: #777777;
    }

    &__step-2-form-submit {
      button {
        @include respond-to(sm) {
          width: 100%;
        }
      }
    }

    &__step-2-continue {
      button {
        @include respond-to(sm) {
          width: 100%;
        }
      }
    }

    &__step-2-step-3 {
      &-disclaimer {
        margin-bottom: 20px;

        &-title {
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: 500;
        }

        &-text {
          max-width: 550px;
          padding: 8px;
          border-radius: 3px;
        }
      }

      .SecondPaymentsForm__header {
        display: none;
      }
    }

    /* step-3 */

    &__step-3 {
      margin: 0 0 50px 0;

      &--open {
        margin: 0 0 20px 0;
      }

      &--disabled {
      }
    }

    &__step-3-wrap-title {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0;
      cursor: pointer;

      .PaymentsForm__step-3--disabled & {
        cursor: default;
      }
    }

    &__step-3-number {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin: 0 20px 0 0;

      .PaymentsForm__step-3--disabled & {
        background: #c5c5c5;
      }

      span {
        background: #f0f0f0;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background 0.3s ease;

        .PaymentsForm__step-3--disabled & {
          color: #777777;
        }

        .PaymentsForm__step-3--open & {
          background: transparent;
          color: #fff;
        }
      }
    }

    &__step-3-title {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;

      .PaymentsForm__step-3--disabled & {
        color: #777777;
      }

      @include respond-to(sm) {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &__step-3-arrow {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 0 auto;

      img {
        transition: transform 0.2s ease;
      }

      &--hide {
        img {
          transform: rotate(-90deg);
        }
      }
    }

    &__step-3-wrap {
      padding: 0 0 0 40px;
      margin: 0 0 0 20px;
      border-left: 1px dashed #aeaeae;

      @include respond-to(sm) {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        border-left: none;
      }

      .SecondPaymentsForm__btns {
        button {
          &:nth-child(1) {
            @include respond-to(sm) {
              width: calc(50% - 10px);
            }
          }

          &:nth-child(2) {
            @include respond-to(sm) {
              width: calc(50% - 10px);
              margin: 0 0 0 20px;
            }
          }
        }
      }
    }

    &__step-3-second-form {
      .SecondPaymentsForm__header {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .PaymentsForm {
      .PaymentsForm__wallet-balance {
        background: ${colors.disabled.hex};
        color: ${colors.gray.hex};
        border-radius: ${border.radius};
      }
      .PaymentsForm__inputs-label {
        color: ${colors.gray.hex};
      }
    }

    .PaymentsForm {
      &__step-1-number {
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};

        span {
          color: ${colors.primary.hex};
        }
      }

      &__step-1-payment-info {
        border-bottom: 2px solid ${rgba([colors.primary.rgb, 0.4])} !important;
      }

      &__step-2-number {
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};

        span {
          color: ${colors.primary.hex};
        }
      }

      &__step-2-step-3-disclaimer-text {
        background-color: ${colors.warning.hex};
      }

      &__step-3-number {
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};

        span {
          color: ${colors.primary.hex};
        }
      }

      &__step-2-form-info-line-3-icon {
        line-height: 0;
        margin: 0 10px 0 0;

        svg {
          circle {
            stroke: ${colors.primary.hex};
          }

          path {
            fill: ${colors.primary.hex};
          }
        }
      }
    }
    .PaymentSystemButton {
      position: relative;
      height: 90px;
      border-bottom: 2px solid transparent;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
      cursor: pointer;
      padding: rem(15px);

      .PaymentSystemButton__inner {
        width: 100%;
        height: 100%;
        min-width: 100px;
        display: flex;
        align-items: center;

        .PaymentSystemButton__icon {
          max-width: 120px;
          width: auto;
          max-height: 60px;
          margin-right: rem(20px);
        }

        .PaymentSystemButton__title {
          font-weight: 500;
        }
      }

      :global(.Tooltip__text) {
        width: 100%;
        height: 100%;
      }

      :global(.Tooltip) {
        position: absolute;
        width: 100%;
        top: 92px !important;
        left: 0 !important;

        :global(.PaymentSystemButton__description) {
          margin-bottom: rem(10px);
        }

        :global(.PaymentSystemButton__commission-caption) {
          line-height: 1.5;
          margin-right: rem(10px);
        }

        :global(.PaymentSystemButton__commission-text) {
          line-height: 2;
        }
      }

      &.disabled {
        border: none;
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
