import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { staticStyles } from './style';

const ValueSwitcherField = ({
  leftTextId,
  rightTextId,
  leftValue,
  rightValue,
  name,
  validate,
  disabled,
  onChange,
  checkedValue,
}) => {
  const [selfCheckedValue, setSelfCheckedValue] = useState(leftValue);
  const isLeftValue = checkedValue ? checkedValue === leftValue : selfCheckedValue === leftValue;
  const isRightValue = checkedValue ? checkedValue === rightValue : selfCheckedValue === rightValue;

  const handleChange = (e, value) => {
    if (typeof onChange === 'function') return onChange(e, value);

    return setSelfCheckedValue(value);
  };

  return (
    <div className="ValueSwitcherField">
      <div
        className={classNames('ValueSwitcherField__input', {
          checked: isLeftValue,
        })}>
        <FormattedMessage id={leftTextId}>{txt => <p className="ValueSwitcherField__text">{txt}</p>}</FormattedMessage>
        <Field
          component="input"
          name={name}
          type="radio"
          value={leftValue}
          validate={validate}
          disabled={disabled}
          checked={isLeftValue}
          onChange={handleChange}
        />
      </div>

      <div
        className={classNames('ValueSwitcherField__input', {
          checked: isRightValue,
        })}>
        <FormattedMessage id={rightTextId}>{txt => <p className="ValueSwitcherField__text">{txt}</p>}</FormattedMessage>
        <Field
          component="input"
          name={name}
          type="radio"
          value={rightValue}
          validate={validate}
          disabled={disabled}
          checked={isRightValue}
          onChange={handleChange}
        />
      </div>

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ValueSwitcherField.propTypes = {
  leftTextId: PropTypes.string.isRequired,
  rightTextId: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.array,
  disabled: PropTypes.bool,
  checkedValue: PropTypes.string,
  onChange: PropTypes.func,
};

ValueSwitcherField.defaultProps = {
  validate: [],
  disabled: false,
  checkedValue: null,
  onChange: null,
};

export default ValueSwitcherField;
