import { put, call, fork, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getGroupsListSaga() {
  try {
    const { data } = yield call(api.getGroupsList);
    yield put(ActionTypes.getGroupsList.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getGroupsList.failure());
  }
}

export function* getGroupsListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_GROUPS_LIST[REQUEST]);
    yield call(getGroupsListSaga);
  }
}

export function* getContractSpecsSaga({ groupId, groupName, lang }) {
  try {
    const { data } = yield call(api.getContractSpecs, groupId, groupName, lang);
    yield put(ActionTypes.getContractSpecs.success(data));
  } catch (e) {
    window.location.reload();
    // yield put(acceptError(e, true));
    // yield put(ActionTypes.getContractSpecs.failure());
  }
}

export function* getContractSpecsWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_CONTRACT_SPECS[REQUEST]);
    yield call(getContractSpecsSaga, payload);
  }
}

export function* commentsListSaga() {
  try {
    const { data } = yield call(api.getCommentsList);
    yield put(ActionTypes.getCommentsList.success(data));
  } catch (e) {
    yield put(ActionTypes.getCommentsList.failure(e));
  }
}

export function* commentsListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_COMMENTS_LIST[REQUEST]);
    yield call(commentsListSaga);
  }
}

export default [fork(getGroupsListWatcherSaga), fork(getContractSpecsWatcherSaga), fork(commentsListWatcherSaga)];
