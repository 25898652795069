import React from 'react';

import { ReportsTable } from 'crm/contacts/_components';
import { Tabs } from 'crm/_components/';

import { staticStyles } from './style';

const ReportsPage = () => (
  <div className="ReportsPage">
    <Tabs externalClass="ReportsPage__tabs">
      <ReportsTable tabTextId="crmReports" tabSlug="myReports" />
    </Tabs>

    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

export default ReportsPage;
