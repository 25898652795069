import React from 'react';
import { connect } from 'react-redux';

import { getPartnerCommissionsExports, downloadPartnerCommissionsExport } from 'backoffice/partnership/_redux';
import { ExportsPaginationTable } from 'backoffice/partnership/_components';

const ExportPartnerCommissionsPage = ({
  partnerCommissionsExports,
  partnerCommissionsExportsIsLoaded,
  getPartnerCommissionsExports,
  downloadPartnerCommissionsExport,
}) => (
  <ExportsPaginationTable
    captionSlug="listOfPartnerCommissionsExports"
    data={partnerCommissionsExports}
    dataIsLoaded={partnerCommissionsExportsIsLoaded}
    getList={getPartnerCommissionsExports}
    downloadExport={downloadPartnerCommissionsExport}
  />
);

export default connect(
  state => ({
    partnerCommissionsExports: state.backoffice.partnership.partnerCommissionsExportsData,
    partnerCommissionsExportsIsLoaded: state.backoffice.partnership.partnerCommissionsDataIsLoaded,
  }),
  {
    getPartnerCommissionsExports: search => getPartnerCommissionsExports.request(search),
    downloadPartnerCommissionsExport: data => downloadPartnerCommissionsExport.request(data),
  }
)(ExportPartnerCommissionsPage);
