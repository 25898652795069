import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection, formValueSelector } from 'redux-form';
import { retrieveAndShowSavedReport, retrieveSavedReport } from 'common/_redux';
import { DefaultButton, CheckboxField, BasicModal } from 'common/components';
import staticStyles from './style';
import ReportOnlineTable from '../ReportOnlineTable';

const DownloadReportForm = ({
  retrieveAndShowSavedReport,
  savedReport,
  savedReportIsLoaded,
  match,
  report,
  handleSubmit,
  dispatch,
  submitting,
  isFormValid,
  error,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isBackOfficePage = match.url.includes('backoffice');
  const submit = handleSubmit(values => retrieveSavedReport({ report, isBackOfficePage, ...values }, dispatch));
  const showReportOnline = () => {
    retrieveAndShowSavedReport({ report, isBackOfficePage });
    setIsModalOpen(true);
  };

  return (
    <>
      <form className="DownloadReportForm" onSubmit={submit}>
        <FormattedMessage id="downloadReportDescription">
          {txt => <div className="DownloadReportForm__line">{txt}</div>}
        </FormattedMessage>
        <FormSection className="DownloadReportForm__line" name="format">
          <Field component={CheckboxField} name="csv" textId="justCsv" type="checkbox" disabled={submitting} />
          <Field component={CheckboxField} name="xlsx" textId="justXlsx" type="checkbox" disabled={submitting} />
        </FormSection>
        <div className="DownloadReportForm__btns">
          <DefaultButton
            textId="justDownload"
            type="submit"
            loading={submitting}
            disabled={!isFormValid || submitting}
            filled
          />
          <DefaultButton textId="justSeeOnline" onClick={showReportOnline} filled />
        </div>
        {error && <strong>{error}</strong>}
        <style jsx>{staticStyles}</style>
      </form>
      <BasicModal captionId="seeReportOnline" isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <ReportOnlineTable savedReport={savedReport} savedReportIsLoaded={savedReportIsLoaded} />
      </BasicModal>
    </>
  );
};

DownloadReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  error: PropTypes.string,
  report: PropTypes.object.isRequired,
  isFormValid: PropTypes.bool,
  savedReport: PropTypes.string.isRequired,
  savedReportIsLoaded: PropTypes.bool.isRequired,
  retrieveAndShowSavedReport: PropTypes.func.isRequired,
};

DownloadReportForm.defaultProps = {
  isFormValid: false,
  error: '',
};

const selector = formValueSelector('DownloadReportForm');

export default compose(
  withRouter,
  connect(
    state => ({
      isFormValid: selector(state, 'format.csv') || selector(state, 'format.xlsx') || selector(state, 'format.pdf'),
      savedReport: state.common.savedReport,
      savedReportIsLoaded: state.common.savedReportIsLoaded,
    }),
    {
      retrieveAndShowSavedReport: data => retrieveAndShowSavedReport.request(data),
    }
  ),
  reduxForm({
    form: 'DownloadReportForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(DownloadReportForm);
export { DownloadReportForm };
