import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NoItemsPanel {
    position: relative;
    text-align: center;
    width: 100%;
    min-height: rem(300px);
    height: 60vh;
    @include respond-to(md) {
      min-height: rem(250px);
    }
    @include respond-to(sm) {
      height: 55vh;
      min-height: rem(200px);
    }
    img {
      margin-bottom: rem(35px);
      max-width: rem(300px);
      max-height: rem(150px);
      @include respond-to(md) {
        width: auto;
        max-width: rem(150px);
        margin-bottom: rem(25px);
        max-height: rem(100px);
      }
    }
    h3 {
      width: 100%;
      text-align: center;
    }
    p {
      margin-top: rem(20px);
      width: 100%;
      text-align: center;
    }
    :global(button) {
      margin-top: rem(20px);
    }
  }

  .NoItemsPanel__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  .FieldArray__no-items {
    .NoItemsPanel__inner {
      position: static;
      transform: translate(0, 0);
    }
    img {
      @include respond-to(md) {
        width: rem(70px);
        margin-bottom: rem(25px);
      }
    }
  }

  .NoItemsPanel--ContainerMaxHeight {
    height: 100%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .NoItemsPanel {
      img {
        margin-bottom: 30px;
        width: rem(300px);
        height: rem(150px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .NoItemsPanel {
      h3 {
        color: ${colors.gray.hex};
      }
      p {
        color: ${colors.gray.hex};
      }
    }

    .NoItemsPanel__error {
      p {
        color: ${colors.error.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
