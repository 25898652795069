import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const AccountsIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="4.248%" y1="86.836%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="-UI" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-422 -744)" fill="url(#linearGradient-4)" fillRule="nonzero">
          <g id="accounts1" transform="translate(422 744)">
            <path
              d="M19.815351,8.57265803 L10.403516,0.193105135 C10.3870617,0.176722822 10.3706075,0.164436088 10.3500397,0.152149353 C10.1443614,-0.0444383981 9.81527631,-0.0526295544 9.5972574,0.139862619 L0.185422394,8.51941551 C-0.0449372039,8.72419442 -0.0613914609,9.07231856 0.140173187,9.30167094 C0.251239422,9.42453828 0.4034413,9.48597196 0.555643177,9.48597196 C0.687277233,9.48597196 0.818911289,9.4409206 0.925863959,9.3467223 L2.62065243,7.83545396 L2.62065243,8.13033559 L2.62065243,12.5044131 L2.62065243,17.6075034 C2.62065243,18.3733765 3.2459142,19 4.01926428,19 C4.03160497,19 4.0398321,19 4.05217279,19 C4.06451348,19 4.07274061,19 4.0850813,19 L6.98925767,19 C7.71324497,19 8.30148466,18.4143323 8.30148466,17.6935106 L8.30148466,12.7255743 C8.30148466,12.6149937 8.39198308,12.5248909 8.50304931,12.5248909 L11.5018377,12.5248909 C11.6129039,12.5248909 11.7034023,12.6149937 11.7034023,12.7255743 L11.7034023,17.6935106 C11.7034023,18.4143323 12.291642,19 13.0156293,19 L15.9856227,19 C16.7671999,19 17.3842345,18.2341269 17.3842345,17.2552837 L17.3842345,12.7010008 L17.3842345,11.3085042 L17.3842345,7.88869648 L19.079023,9.39996482 C19.1859757,9.49416311 19.3176097,9.53921447 19.4492438,9.53921447 C19.6014457,9.53921447 19.7536475,9.4777808 19.8647138,9.35491346 C20.0621649,9.12556108 20.041597,8.77743694 19.815351,8.57265803 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

AccountsIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

AccountsIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedAccountsIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(AccountsIconActive);

export default ThemedAccountsIconActive;
