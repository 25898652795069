import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PageContentPreloader,
  PageLayout,
  PageTabs,
  TranslatedNavLink,
  Calendar,
  NoItemsPanel,
} from 'common/components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'common/styling/theme';
import { useRouteMatch } from 'react-router-dom';

import TradeOperationsTable from 'client/_components/TradeOperationsTable';
import noOrdersImage from 'common/images/noOrders.svg';

import { InvestStatementPanel, InvestmentInfoPanel } from '../../_components';

import { getTradingAccountList } from '../../../_redux';
import { getInvestStatementNew, getInvestmentTradesNew } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const startDate = moment().startOf('day').unix();

const endDate = moment().add(2, 'day').startOf('day').unix();

const InvestStatementPageNew = ({
  investStatement,
  investmentTrades,
  investStatementIsLoaded,
  investmentTradesAreLoaded,
  getInvestStatement,
  getInvestmentTrades,
  getTradingAccountList,
  theme,
}) => {
  const match = useRouteMatch();
  const {
    params: { investmentId, tradeType },
  } = match;

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  useEffect(() => {
    getInvestStatement({ investmentId });
  }, [getInvestStatement, investmentId]);

  const getList = useCallback(() => {
    getInvestmentTrades({ investmentId, tradeType, startDate, endDate });
  }, [getInvestmentTrades, investmentId, tradeType]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleChangeDate = (start, end) => {
    getInvestmentTrades({ investmentId, tradeType, startDate: start, endDate: end });
  };

  return (
    <PageLayout captionSlug="justInvestStatement" showReload={false}>
      <PageContentPreloader ready={investStatementIsLoaded} type="bigLine" firstLaunchOnly>
        <InvestStatementPanel investStatement={investStatement} />
        <InvestmentInfoPanel investStatement={investStatement} />
        <>
          <div className="InvestStatementPage__top-line">
            <PageTabs>
              <TranslatedNavLink id="justOpen" to={`/investNew/investments/${investmentId}/open`} />

              <TranslatedNavLink id="justClosed" to={`/investNew/investments/${investmentId}/closed`} />
            </PageTabs>
            <div className="InvestStatementPage__date">
              {tradeType === 'closed' && <Calendar daysLimit={31} onChangeFunc={handleChangeDate} />}
            </div>
          </div>
          <div className="InvestStatementPage__table">
            <PageContentPreloader ready={investmentTradesAreLoaded} type="smallLines">
              {investmentTrades.trades?.length ? (
                <TradeOperationsTable
                  data={investmentTrades.trades}
                  summary={investmentTrades.summary}
                  operationStatus={tradeType}
                  isClient
                />
              ) : (
                <NoItemsPanel icon={noOrdersImage} captionSlug="yotHaveNoOrdersYet" />
              )}
            </PageContentPreloader>
          </div>
        </>
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

InvestStatementPageNew.propTypes = {
  investStatement: PropTypes.object.isRequired,
  investmentTrades: PropTypes.object.isRequired,
  investStatementIsLoaded: PropTypes.bool.isRequired,
  investmentTradesAreLoaded: PropTypes.bool.isRequired,
  getInvestStatement: PropTypes.func.isRequired,
  getInvestmentTrades: PropTypes.func.isRequired,
  getTradingAccountList: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestStatementPageNew.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      investStatement: state.client.investNew.investStatement,
      investStatementIsLoaded: state.client.investNew.investStatementIsLoaded,
      investmentTrades: state.client.investNew.investmentTrades,
      investmentTradesAreLoaded: state.client.investNew.investmentTradesAreLoaded,
    }),
    {
      getInvestStatement: data => getInvestStatementNew.request(data),
      getInvestmentTrades: data => getInvestmentTradesNew.request(data),
      getTradingAccountList: () => getTradingAccountList.request({ noDetails: true }),
    }
  )
)(InvestStatementPageNew);
export { InvestStatementPageNew };
