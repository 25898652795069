import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BasicCRMModal from '../BasicCRMModal';
import CRMEditContactInfoForm from '../forms/forms/CRMEditContactInfoForm';

const CRMContactsModals = ({ modalType, onRequestClose }) => {
  const contactData = useSelector(state => state.crm.users.userData);

  const userEditData = [
    {
      form: 'crm-change-email',
      captionId: 'boUserChangeEmail',
      fieldName: 'email',
      fieldTitle: 'justEmail',
      fieldType: 'email',
      initialValue: { email: contactData.email },
      modalType: 'editEmail',
    },
    {
      form: 'crm-change-phone',
      captionId: 'boUserChangePhone',
      fieldName: 'phone',
      fieldTitle: 'justPhone',
      fieldType: 'tel',
      initialValue: { phone: contactData.phone },
      modalType: 'editPhoneNumber',
    },
    {
      form: 'crm-change-language',
      captionId: 'crmUserChangeLanguage',
      fieldName: 'language',
      fieldTitle: 'crmLanguage',
      fieldType: 'select',
      initialValue: { language: contactData.language },
      modalType: 'editLanguage',
    },
    {
      form: 'crm-change-country',
      captionId: 'crmUserChangeCountry',
      fieldName: 'country',
      fieldTitle: 'kycCountry',
      fieldType: 'select',
      initialValue: { country: contactData.address?.country?.value },
      modalType: 'editCountry',
    },
    {
      form: 'crm-change-state',
      captionId: 'crmUserChangeState',
      fieldName: 'state',
      fieldTitle: 'kycState',
      fieldType: 'select',
      initialValue: { state: contactData.address?.state?.value, country: contactData.address?.country?.value },
      modalType: 'editState',
    },
    {
      form: 'crm-change-city',
      captionId: 'crmUserChangeCity',
      fieldName: 'city',
      fieldTitle: 'kycCity',
      fieldType: 'text',
      initialValue: { city: contactData.address?.city },
      modalType: 'editCity',
    },
    {
      form: 'crm-change-address',
      captionId: 'crmUserChangeAddress',
      fieldName: 'address',
      fieldTitle: 'kycAddress',
      fieldType: 'text',
      initialValue: { address: contactData.address?.address },
      modalType: 'editAddress',
    },
    {
      form: 'crm-change-sales-status',
      captionId: 'crmJustEditSalesStatus',
      fieldName: 'status',
      fieldTitle: 'crmSalesStatus',
      fieldType: 'select',
      initialValue: { status: contactData.status?.id },
      modalType: 'editSalesStatus',
    },
  ];

  return (
    <>
      {userEditData.map((item, index) => (
        <BasicCRMModal
          key={index}
          captionId={item.captionId}
          isOpen={modalType === item.modalType}
          alignTitleCenter
          onRequestClose={onRequestClose}>
          <CRMEditContactInfoForm
            onRequestClose={onRequestClose}
            fieldType={item.fieldType}
            fieldName={item.fieldName}
            fieldTitle={item.fieldTitle}
            initialValues={item.initialValue}
            form={item.form}
          />
        </BasicCRMModal>
      ))}
    </>
  );
};

CRMContactsModals.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  modalType: PropTypes.string,
};

CRMContactsModals.defaultProps = {
  modalType: null,
};
export default CRMContactsModals;
