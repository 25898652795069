import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader } from 'common/components';
import TechnicalAnalysisItem from 'client/articles/_components/ArticlesItem';

import { getStory } from '../../_redux';

const CompanyNewsPage = ({ getStory, story, storyIsLoaded, match }) => {
  const storyId = match.params.id;

  useEffect(() => {
    getStory(storyId);
  }, [getStory, storyId]);

  return (
    <PageLayout captionSlug="justNews">
      <PageContentPreloader ready={storyIsLoaded} type="news">
        {storyIsLoaded && !!Object.keys(story).length && (
          <TechnicalAnalysisItem
            key={story.id}
            id={story.id}
            date={story.created}
            title={story.title}
            body={story.body}
            logo={story.logo}
          />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

CompanyNewsPage.propTypes = {
  story: PropTypes.object.isRequired,
  storyIsLoaded: PropTypes.bool.isRequired,
  getStory: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      story: state.backoffice.news.story,
      storyIsLoaded: state.backoffice.news.storyIsLoaded,
    }),
    {
      getStory: storyId => getStory.request(storyId),
    }
  )
)(CompanyNewsPage);
export { CompanyNewsPage };
