import css from 'styled-jsx/css'
import { isRTL } from "common/styling/theme";

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RadialChart {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h3 {
      width: 100%;
      position: relative;
      top: 10px;
      @include respond-to(md) {
        text-align: center;
      }
    }
  }

  .RadialChart__chart-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .RadialChart__chart {
    width: 60%;
    @include respond-to(xl) {
      width: 100%;
    }
  }

  .RadialChart__description {
    position: relative;
    bottom: 15px;
    @include respond-to(xl) {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      bottom: 35px;
    }
  }

  .Description__inner {
    border-bottom: 1px dashed;
    margin-bottom: 5px;
    @include respond-to(xl) {
      margin: rem(0 10px 5px 10px);
      text-align: center;
      border: none;
    }
    :global(span) {
      text-transform: lowercase;
      font-size: rem(13px);
    }
    p {
      font-size: rem(20px);
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .Description__inner {
      text-align: ${isRTL ? 'left' : 'right'};
      border-color: ${colors.lightGray.hex};
      :global(span) {
        color: ${colors.gray.hex};
      }
      p {
        color: ${colors.primary.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
