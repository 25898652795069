import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const EmailIcon = ({ grayColor }) => (
  <svg viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 0H21.5C21.776 0 22 0.219509 22 0.491072V15.2232C22 15.4948 21.776 15.7143 21.5 15.7143H0.5C0.2235 15.7143 0 15.4948 0 15.2232V0.491072C0 0.219509 0.2235 0 0.5 0ZM14.8062 7.46137L21.0002 13.5443V0.982178H1.00018V13.5443L7.19418 7.46137L7.90118 8.15575L1.20518 14.7322H20.7952L14.0992 8.15575L14.8062 7.46137Z"
      fill={grayColor.hex}
    />
    <path
      d="M0.5 0H21.5C21.776 0 22 0.219509 22 0.491072V15.2232C22 15.4948 21.776 15.7143 21.5 15.7143H0.5C0.2235 15.7143 0 15.4948 0 15.2232V0.491072C0 0.219509 0.2235 0 0.5 0ZM14.8062 7.46137L21.0002 13.5443V0.982178H1.00018V13.5443L7.19418 7.46137L7.90118 8.15575L1.20518 14.7322H20.7952L14.0992 8.15575L14.8062 7.46137Z"
      fill={grayColor.hex}
    />
    <path
      d="M20.1821 0.785645L10.9999 9.79072L1.8178 0.785645H0.785645V1.18984L10.6389 10.8531C10.8385 11.0489 11.1613 11.0489 11.361 10.8531L21.2142 1.18984V0.785645H20.1821Z"
      fill={grayColor.hex}
    />
  </svg>
);

EmailIcon.propTypes = {
  grayColor: colorType,
};

EmailIcon.defaultProps = {
  grayColor: {},
};

const ThemedEmailIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(EmailIcon);

export default ThemedEmailIcon;
