import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatisticalCard {
    display: flex;
    align-items: center;
    padding: 30px 0;
    margin-right: ${isRTL ? '0' : '15px'};
    margin-left: ${isRTL ? '15px' : '0'};

    @include respond-to(sm) {
      width: 100%;
      margin: 0;
      padding: 10px 0;
    }

    :global(.StatisticalCard__number) {
      margin-right: 10px;
      font-size: rem(30px);

      @include respond-to(sm) {
        font-size: rem(20px);
      }
    }

    .StatisticalCard__caption {
      font-size: 1rem;
    }

    :global(.Tooltip) {
      background: #ffffff !important;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      :global(pre) {
        color: #000;
      }

      &::after {
        content: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .StatisticalCard {
      :global(&__number) {
        color: ${colors.primary.hex};
      }

      :global(.ActionsButton) {
        :global(svg) {
          width: rem(25px);
          :global(g) {
            stroke: ${colors.gray.hex} !important;
            :global(path) {
              fill: ${colors.gray.hex} !important;
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
