import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SimpleIBSystemSettingsPanel__caption {
    margin-bottom: 15px;
  }

  .SimpleIBSystemSettingsPanel__account {
    margin-bottom: 10px;
    font-size: rem(16px);
    span {
      font-size: rem(18px);
      font-weight: 500;
    }
  }

  .SimpleIBSystemSettingsPanel__description {
    margin-bottom: rem(25px);
  }

  .SimpleIBSystemSettingsPanel__platform-item {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    padding: 20px 35px 10px;
    margin-bottom: rem(25px);
    display: flex;
    flex-wrap: wrap;
    @include respond-to(sm) {
      padding: 15px 10px 5px;
    }
  }

  .SimpleIBSystemSettingsPanel__platform {
    font-weight: 400;
    font-size: rem(18px);
    width: 100%;
    margin-bottom: rem(20px);
  }

  .SimpleIBSystemSettingsPanel__description-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .SimpleIBSystemSettingsPanel__platform-description {
    width: 40%;
    padding-right: rem(15px);
    margin-top: rem(-15px);
    @include respond-to(sm) {
      width: 46%;
    }
  }

  .SimpleIBSystemSettingsPanel__select-inner {
    width: 49%;
    :global(.BaseSelectField) {
      margin: 0;
    }
    @include respond-to(sm) {
      width: 53%;
    }
  }

  .SimpleIBSystemSettingsPanel__form-fields {
    display: flex;

    :global(.BaseSelectField) {
      width: 50%;
      margin-right: rem(${isRTL ? '0' : '20px'});
      margin-left: rem(${isRTL ? '20px' : '0'});

      @include respond-to(sm) {
        width: 100%;
      }
    }

    :global(.DefaultButton) {
      margin-top: rem(5px);
      width: 155px !important;
      height: 45px;
    }

    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .SimpleIBSystemSettingsPanel__noItems {
    .SimpleIBSystemSettingsPanel__platform-description {
      margin: 0;
      width: 100%;
    }
    .SimpleIBSystemSettingsPanel__select-inner {
      width: 100%;
    }
  }

  .SimpleIBSystemSettingsPanel__noAccount {
    text-align: center;
    width: 100%;
    margin: rem(15px) 0;

    p {
      margin-bottom: rem(15px);
    }
  }

  .SimpleIBSystemSettingsPanel__image-inner {
    margin-bottom: rem(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: rem(50px);
      height: rem(45px);
    }
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .SimpleIBSystemSettingsPanel__platform-item {
      border-radius: ${border.radius};
      background: ${colors.white.hex};
    }

    .SimpleIBSystemSettingsPanel__noAccount {
      p {
        color: ${colors.gray.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
