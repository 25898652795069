import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .IssuesPage__button-inner {
    position: absolute;
    right: ${isRTL ? 'auto' : '0'};
    left: ${isRTL ? '0' : 'auto'};
    top: rem(-60px);
    @include respond-to(sm) {
      right: 0;
    }
  }
`

export default staticStyles
