import React from 'react';

const SupportIcon = () => (
  <div>
    <svg viewBox="0 0 18 18" version="1.1">
      <defs />
      <g id="-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ui---blocks" transform="translate(-634.000000, -712.000000)" fill="#000000" fillRule="nonzero">
          <g id="support" transform="translate(634.000000, 712.000000)">
            <path
              d="M15.3582622,2.6372093 C13.6572051,0.93615222 11.4005455,0 8.99547146,0 C6.59039746,0 4.33373784,0.93615222 2.63268076,2.6372093 C-0.655268499,5.92515856 -0.883598309,11.1881607 2.06566173,14.7386892 C1.77644397,15.3209302 1.31217336,15.9983087 0.623378436,16.3369979 C0.292300211,16.5006342 0.105830867,16.8545455 0.162913319,17.2198732 C0.219995772,17.5852008 0.501602537,17.8706131 0.866930233,17.9276956 C1.03817759,17.954334 1.28172939,17.9809725 1.57855814,17.9809725 C2.37390698,17.9809725 3.546,17.7945032 4.7447315,16.9306554 C6.08046089,17.6498943 7.54177167,18 8.99166596,18 C11.32063,18 13.6267611,17.0980973 15.3620677,15.3627907 C17.0631247,13.6617336 17.999277,11.405074 17.999277,9 C17.999277,6.594926 17.0593192,4.33826638 15.3582622,2.6372093 Z M14.6314123,14.6359408 C12.0627019,17.2046512 8.08595772,17.7145877 4.95783932,15.8765328 C4.75234249,15.7547569 4.49737421,15.7928118 4.33373784,15.9564482 C4.31851586,15.9640592 4.30329387,15.9754757 4.29187738,15.9868922 C3.26058774,16.7860465 2.24832558,16.9534884 1.57855814,16.9534884 L1.57475264,16.9534884 C2.3472685,16.3902748 2.83437209,15.5530655 3.11978436,14.9023256 C3.16545032,14.7919662 3.17306131,14.6778013 3.14642283,14.5712474 C3.13500634,14.4684989 3.09314588,14.3657505 3.02084144,14.2820296 C0.235217759,11.1348837 0.379826638,6.33995772 3.35572516,3.3640592 C6.46481607,0.254968288 11.5223214,0.254968288 14.6276068,3.3640592 C17.7405032,6.47315011 17.7405032,11.5268499 14.6314123,14.6359408 Z"
              id="Shape"
            />
            <circle id="Oval" cx="8.99547146" cy="9" r="1" />
            <circle id="Oval" cx="12.2529767" cy="9" r="1" />
            <circle id="Oval" cx="5.73796617" cy="9" r="1" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default SupportIcon;
