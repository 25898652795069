import React from 'react';

const TechnicalIcon = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g>
        <path d="M29 0H7a3 3 0 0 0-3 3v2H3a3 3 0 0 0-3 3v20a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V3a3 3 0 0 0-3-3zm1 28c0 1.102-.898 2-2 2H4c-1.103 0-2-.898-2-2V8a1 1 0 0 1 1-1h1v20a1 1 0 1 0 2 0V3a1 1 0 0 1 1-1h22c.551 0 1 .448 1 1v25z" />
        <path d="M19.498 13.005h8a.5.5 0 1 0 0-1h-8a.5.5 0 0 0 0 1zM19.498 10.005h8a.5.5 0 1 0 0-1h-8a.5.5 0 0 0 0 1zM19.498 7.005h8a.5.5 0 1 0 0-1h-8a.5.5 0 0 0 0 1zM16.5 27.004h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM16.5 24.004h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM16.5 21.004h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM27.5 27.004h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM27.5 24.004h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM27.5 21.004h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM27.5 15.004h-19a.5.5 0 0 0 0 1h19a.5.5 0 0 0 0-1zM27.5 18.004h-19a.5.5 0 0 0 0 1h19a.5.5 0 0 0 0-1zM9 13h7a1 1 0 0 0 1-1V5.004a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1V12a1 1 0 0 0 1 1zm1-7h5v5h-5V6z" />
      </g>
    </svg>
  </div>
);

export default TechnicalIcon;
