import { createFormAction } from 'redux-form-saga';
import { FAILURE, REQUEST, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

const GET_CLIENT_LIST = createRequestTypes('GET_CLIENT_LIST');
const getClientList = {
  request: search => action(GET_CLIENT_LIST[REQUEST], { search }),
  success: data => action(GET_CLIENT_LIST[SUCCESS], { data }),
  failure: error => action(GET_CLIENT_LIST[FAILURE], { error }),
};

const GET_STAFF_MEMBER_LIST = createRequestTypes('GET_STAFF_MEMBER_LIST');
const getStaffMemberList = {
  request: () => action(GET_STAFF_MEMBER_LIST[REQUEST]),
  success: data => action(GET_STAFF_MEMBER_LIST[SUCCESS], { data }),
  failure: error => action(GET_STAFF_MEMBER_LIST[FAILURE], { error }),
};

const ADD_STAFF_MEMBER = createRequestTypes('ADD_STAFF_MEMBER');
const addStaffMember = {
  request: data => action(ADD_STAFF_MEMBER[REQUEST], { data }),
  success: data => action(ADD_STAFF_MEMBER[SUCCESS], { data }),
  failure: error => action(ADD_STAFF_MEMBER[FAILURE], { error }),
};

const REMOVE_STAFF_MEMBER = createRequestTypes('REMOVE_STAFF_MEMBER');
const removeStaffMember = {
  request: (data, callback) => action(REMOVE_STAFF_MEMBER[REQUEST], { data, callback }),
  success: data => action(REMOVE_STAFF_MEMBER[SUCCESS], { data }),
  failure: error => action(REMOVE_STAFF_MEMBER[FAILURE], { error }),
};

const saveStaffMember = createFormAction('SAVE_STAFF_MEMBER');

const GET_STAFF_GROUP_LIST = createRequestTypes('GET_STAFF_GROUP_LIST');
const getStaffGroupList = {
  request: () => action(GET_STAFF_GROUP_LIST[REQUEST]),
  success: data => action(GET_STAFF_GROUP_LIST[SUCCESS], { data }),
  failure: error => action(GET_STAFF_GROUP_LIST[FAILURE], { error }),
};

const ADD_STAFF_GROUP = createRequestTypes('ADD_STAFF_GROUP');
const addStaffGroup = {
  request: data => action(ADD_STAFF_GROUP[REQUEST], { data }),
  success: data => action(ADD_STAFF_GROUP[SUCCESS], { data }),
  failure: error => action(ADD_STAFF_GROUP[FAILURE], { error }),
};

const REMOVE_STAFF_GROUP = createRequestTypes('REMOVE_STAFF_GROUP');
const removeStaffGroup = {
  request: data => action(REMOVE_STAFF_GROUP[REQUEST], { data }),
  success: data => action(REMOVE_STAFF_GROUP[SUCCESS], { data }),
  failure: error => action(REMOVE_STAFF_GROUP[FAILURE], { error }),
};

const saveStaffGroup = createFormAction('SAVE_STAFF_GROUP');

export {
  GET_CLIENT_LIST,
  getClientList,
  GET_STAFF_MEMBER_LIST,
  getStaffMemberList,
  ADD_STAFF_MEMBER,
  addStaffMember,
  REMOVE_STAFF_MEMBER,
  removeStaffMember,
  saveStaffMember,
  GET_STAFF_GROUP_LIST,
  getStaffGroupList,
  ADD_STAFF_GROUP,
  addStaffGroup,
  REMOVE_STAFF_GROUP,
  removeStaffGroup,
  saveStaffGroup,
};
