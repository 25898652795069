import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';

import { PageLayout, NoItemsPanel, PageContentPreloader, DefaultButton } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getBonusesInfoList, deleteBonusInfo } from '../../_redux';
import { BonusManagementTable } from '../../_components';
import { staticStyles } from './style';

const BonusManagementPage = ({
  bonusesInfoList,
  bonusesInfoListIsLoaded,
  getBonusesInfoList,
  deleteBonusInfo,
  profile,
  history,
  location,
}) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };

  /* eslint-disable */
  useEffect(() => {
    getBonusesInfoList({ search: query })
  }, [getBonusesInfoList])
  /* eslint-enable */

  return (
    <PageLayout
      captionSlug="boBonusManagement"
      showReload
      onReloadClick={() => getBonusesInfoList({ search: query })}
      reloading={!bonusesInfoListIsLoaded}>
      <div className="BonusManagementPage__create-inner">
        <DefaultButton
          textId="bonusCreateTitle"
          plus
          filled
          onClick={() => history.push('/backoffice/bonuses/create/')}
        />
      </div>
      <PageContentPreloader ready={bonusesInfoListIsLoaded} type="smallLines" firstLaunchOnly>
        {bonusesInfoList.count ? (
          <BonusManagementTable
            data={bonusesInfoList}
            loading={!bonusesInfoListIsLoaded}
            location={location}
            history={history}
            getList={getBonusesInfoList}
            onRowClick={() => {}}
            handleDelete={deleteBonusInfo}
            params={{ search: query }}
            profile={profile}
          />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noBonuses" />
        )}
      </PageContentPreloader>

      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

BonusManagementPage.propTypes = {
  bonusesInfoList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  bonusesInfoListIsLoaded: PropTypes.bool.isRequired,
  getBonusesInfoList: PropTypes.func.isRequired,
  deleteBonusInfo: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      bonusesInfoList: state.backoffice.bonuses.bonusesInfoList,
      bonusesInfoListIsLoaded: state.backoffice.bonuses.bonusesInfoListIsLoaded,
      profile: state.user.profile,
    }),
    {
      getBonusesInfoList: search => getBonusesInfoList.request(search),
      deleteBonusInfo: data => deleteBonusInfo.request(data),
    }
  )
)(BonusManagementPage);
