import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const UserIcon = ({ grayColor }) => (
  <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M17.4286 15.2381L14.1905 13.619C13.5238 13.3333 13.1429 12.6667 13.1429 12C14.1905 11.2381 14.9524 10.1905 15.2381 8.85714L15.619 7.42857C15.7143 6.85714 15.8095 6.28571 15.7143 5.71429L15.619 5.04762C15.5238 2.19048 13.1429 0 10.2857 0H9.71428C6.85714 0 4.57143 2.19048 4.28571 4.95238V5.71429C4.28571 6.28571 4.28571 6.85714 4.38095 7.42857L4.7619 8.7619C5.04762 10.0952 5.80952 11.1429 6.85714 11.9048C6.85714 12.5714 6.38095 13.2381 5.80952 13.5238L2.57143 15.2381C0.952381 16 0 17.619 0 19.3333V20.381C0 21.3333 0.761905 22.0952 1.71429 22.0952H18.381C19.3333 22.0952 20.0952 21.3333 20.0952 20.381V19.3333C20 17.619 19.0476 16 17.4286 15.2381ZM5.61905 8.57143L5.33333 7.14286C5.23809 6.66667 5.14286 6.19048 5.23809 5.71429L5.33333 5.04762C5.42857 2.7619 7.42857 0.952381 9.71428 0.952381H10.2857C12.5714 0.952381 14.5714 2.7619 14.7619 5.04762V5.71429C14.7619 6.19048 14.7619 6.66667 14.6667 7.14286L14.381 8.57143C13.9048 10.5714 12.0952 12 10 12C7.90476 12 6.09524 10.5714 5.61905 8.57143ZM19.0476 20.381C19.0476 20.7619 18.7619 21.1429 18.2857 21.1429H1.71429C1.33333 21.1429 0.952381 20.8571 0.952381 20.381V19.3333C0.952381 17.9048 1.71429 16.6667 2.95238 16L6.19048 14.381C6.95238 14 7.52381 13.2381 7.71429 12.381C8.38095 12.6667 9.14286 12.8571 10 12.8571C10.8571 12.8571 11.619 12.6667 12.2857 12.381C12.4762 13.2381 13.0476 13.9048 13.8095 14.381L17.0476 16C18.2857 16.6667 19.0476 17.9048 19.0476 19.3333V20.381Z"
        fill={grayColor.hex}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="22" fill={grayColor.hex} />
      </clipPath>
    </defs>
  </svg>
);

UserIcon.propTypes = {
  grayColor: colorType,
};

UserIcon.defaultProps = {
  grayColor: {},
};

const ThemedUserIcon = withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(UserIcon);

export default ThemedUserIcon;
