import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader, NoItemsPanel, BasicModal, DefaultButton } from 'common/components';
import noIssuesImage from 'common/images/noIssues.svg';

import {
  getClientIssue,
  getClientIssues,
  markIssueAsViewed,
  issueCreateMessage,
  closeIssue,
  toggleCreateIssueModal,
  toggleIssue,
} from '../_redux';
import { IssuesPanel } from '../_components';
import IssueForm from '../../_components/IssueForm';

import staticStyles from './style';

const IssuesPage = ({
  getClientIssues,
  profile,
  issue,
  activeIssue,
  createIssueModalIsOpen,
  issues,
  issuesAreLoaded,
  issueIsLoaded,
  getClientIssue,
  markIssueAsViewed,
  toggleIssue,
  issueCreateMessage,
  closeIssue,
  toggleCreateIssueModal,
}) => {
  useEffect(() => {
    getClientIssues();
  }, []);

  return (
    <PageLayout captionSlug="justSupport">
      <div className="IssuesPage__button-inner">
        {!!issues.length && <DefaultButton onClick={toggleCreateIssueModal} textId="createIssue" plus filled />}
        <style jsx>{staticStyles}</style>
      </div>

      <PageContentPreloader ready={issuesAreLoaded} type="smallLines">
        {issues.length ? (
          <IssuesPanel
            profile={profile}
            issue={issue}
            activeIssue={activeIssue}
            issueIsLoaded={issueIsLoaded}
            issues={issues}
            getIssue={getClientIssue}
            toggleIssue={toggleIssue}
            closeIssue={closeIssue}
            createMessage={issueCreateMessage}
            markIssueAsViewed={markIssueAsViewed}
          />
        ) : (
          <NoItemsPanel
            icon={noIssuesImage}
            captionSlug="supportAskIfYouNeedHelp"
            buttonSlug="supportNewIssue"
            onButtonClick={toggleCreateIssueModal}
          />
        )}
      </PageContentPreloader>

      <BasicModal
        isOpen={createIssueModalIsOpen}
        captionId="createIssueModalHeader"
        onRequestClose={toggleCreateIssueModal}>
        <IssueForm />
      </BasicModal>
    </PageLayout>
  );
};

IssuesPage.propTypes = {
  profile: PropTypes.object.isRequired,
  issue: PropTypes.object.isRequired,
  issues: PropTypes.array.isRequired,
  issuesAreLoaded: PropTypes.bool.isRequired,
  getClientIssues: PropTypes.func.isRequired,
  activeIssue: PropTypes.number.isRequired,
  createIssueModalIsOpen: PropTypes.bool.isRequired,
  issueIsLoaded: PropTypes.bool.isRequired,
  getClientIssue: PropTypes.func.isRequired,
  markIssueAsViewed: PropTypes.func.isRequired,
  toggleIssue: PropTypes.func.isRequired,
  issueCreateMessage: PropTypes.func.isRequired,
  closeIssue: PropTypes.func.isRequired,
  toggleCreateIssueModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    profile: state.user.profile,
    issue: state.client.support.issue,
    issues: state.client.support.issues,
    issuesAreLoaded: state.client.support.issuesAreLoaded,
    activeIssue: state.client.support.activeIssue,
    issueIsLoaded: state.client.support.issueIsLoaded,
    createIssueModalIsOpen: state.client.support.createIssueModalIsOpen,
  }),
  {
    getClientIssues: () => getClientIssues.request(),
    getClientIssue: id => getClientIssue.request(id),
    markIssueAsViewed: id => markIssueAsViewed.request(id),
    toggleIssue: id => toggleIssue(id),
    closeIssue: id => closeIssue.request(id),
    issueCreateMessage: (id, text) => issueCreateMessage.request(id, text),
    toggleCreateIssueModal: () => toggleCreateIssueModal(),
  }
)(IssuesPage);
export { IssuesPage };
