import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .Auth {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    min-height: 880px;
    @media only screen and (min-height: 1024px) {
      align-items: center;
    }
    @media only screen and (min-height: 1400px) {
      align-items: flex-start;
    }
    @include respond-to(xl) {
      min-height: 770px;
    }
    @include respond-to(sm) {
      height: auto;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
    }
  }

  .Auth__left-inner {
    width: 28%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 100px 40px 50px;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 0 0 auto;
    @media only screen and (min-height: 1400px) {
      height: 100%;
    }
    @media only screen and (max-width: 1880px) {
      width: 30%;
    }
    @media only screen and (max-width: 1700px) {
      width: 32%;
    }
    @include respond-to(xl) {
      padding: 35px 40px;
    }
    @media only screen and (max-width: 1350px) {
      width: 35%;
    }
    @media only screen and (max-width: 1180px) {
      width: 45%;
    }
    @include respond-to(md) {
      width: 40%;
      padding: 30px 15px;
    }
    @media only screen and (max-width: 920px) {
      width: 45%;
    }
    @media only screen and (max-width: 830px) {
      width: 50%;
    }
    @include respond-to(sm) {
      width: 100%;
      min-height: 100%;
      padding: 50px 15%;
      justify-content: center;
      box-shadow: none;
    }
    @include respond-to(xs) {
      padding: 30px 10px;
    }
  }

  .Auth__form-inner {
    width: 100%;
    height: auto;
    flex: 0 0 auto;
  }

  .Auth__logo {
    text-align: center;
    margin-bottom: 80px;
    width: 100%;
    @include respond-to(xl) {
      margin-bottom: 45px;
    }
    a {
      img,
      svg {
        max-width: 180px;
        width: auto;
        max-height: 100px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          max-height: 45px;
        }
      }
      svg {
        height: 45px;
      }
    }
  }

  .AuthLangButton {
    text-align: center;
    .Select-menu-outer {
      top: -50px !important;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Auth {
      background-color: ${colors.white.hex};
    }

    .Auth__logo {
      svg {
        .white {
          fill: ${colors.black.hex} !important;
        }
      }
    }

    .Auth__left-inner {
      background-color: ${colors.white.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }
