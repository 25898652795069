import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';
import 'date-input-polyfill';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const BaseTextField = ({
  value,
  name,
  textId,
  text,
  textValues,
  disabled,
  autoComplete,
  type,
  children,
  error,
  additionalClass,
  theme,
  specialProps,
  hidden,
  customDefaultValue,
  placeholder,
  loading = false,
  onChange,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [haveValue, setHaveValue] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    if (!value && customDefaultValue) onChange(customDefaultValue);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (value === 0) setHaveValue(true);
  }, [value]);

  const inputProps = {
    className: classNames('BaseTextField__inp', {
      haveText: value || haveValue,
    }),
    id: name,
    type,
    autoComplete,
    disabled,
    value,
    placeholder,
    onChange,
    ...props,
  };

  return (
    <div className={classNames('BaseTextField', { ReduxTextField: additionalClass, loading }, { error }, { hidden })}>
      <div>
        {React.createElement('input', {
          ...inputProps,
          ...specialProps,
        })}
        <label
          className={classNames('BaseTextField__label', {
            haveText: value || haveValue || placeholder,
          })}
          htmlFor={name}>
          {textId ? <FM id={textId} values={textValues} /> : <span>{text}</span>}
        </label>
        {children}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BaseTextField.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  input: PropTypes.object,
  children: PropTypes.node,
  additionalClass: PropTypes.bool,
  type: PropTypes.string,
  textId: PropTypes.string,
  text: PropTypes.string,
  autoComplete: PropTypes.string,
  textValues: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.bool]),
  theme: PropTypes.object,
  specialProps: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customDefaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
};

BaseTextField.defaultProps = {
  value: null,
  input: {},
  textId: '',
  placeholder: '',
  text: '',
  children: false,
  additionalClass: false,
  textValues: {},
  disabled: false,
  autoComplete: 'on',
  type: 'text',
  error: null,
  theme: {},
  specialProps: {},
  customDefaultValue: null,
  hidden: false,
  loading: false,
};

const ThemedBaseTextField = withTheme()(BaseTextField);

export default ThemedBaseTextField;
export { BaseTextField };
