import { fork, put, call, take, delay } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as actionTypes from './actions';
import * as api from '../api';

export function* getPaymentMethodsTemplatesSaga() {
  try {
    const { status, data } = yield call(api.getPaymentMethodsTemplates);
    if (status < 300) {
      yield put(actionTypes.getPaymentMethodsTemplates.success(data));
    } else {
      yield put(actionTypes.getPaymentMethodsTemplates.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentMethodsTemplates.failure());
  }
}

export function* getPaymentMethodsTemplatesWatcherSaga() {
  while (true) {
    yield take(actionTypes.GET_PAYMENT_METHODS_TEMPLATES[REQUEST]);
    yield call(getPaymentMethodsTemplatesSaga);
  }
}

export function* getPaymentMethodsSettingsSaga({ search }) {
  try {
    // FIXME: почему тут передается search, хотя в запросе тело пустое
    const { status, data } = yield call(api.getPaymentMethodsSettings, search);
    if (status < 300) {
      yield put(actionTypes.getPaymentMethodsSettings.success(data));
    } else {
      yield put(actionTypes.getPaymentMethodsSettings.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentMethodsSettings.failure());
  }
}

export function* getPaymentMethodsSettingsWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_PAYMENT_METHODS_SETTINGS[REQUEST]);
    yield call(getPaymentMethodsSettingsSaga, data);
  }
}
export function* getPaymentMethodsListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getPaymentMethodsList, search);
    if (status < 300) {
      yield put(actionTypes.getPaymentMethodsList.success(data));
    } else {
      yield put(actionTypes.getPaymentMethodsList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentMethodsList.failure());
  }
}

export function* getPaymentMethodsListWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_PAYMENT_METHODS_LIST[REQUEST]);
    yield call(getPaymentMethodsListSaga, data);
  }
}

export function* createPaymentMethodSaga(payload) {
  const paymentDescription = payload.paymentDescription.map(description => ({
    [description.lang]: {
      ...description,
    },
  }));

  try {
    const { status, data } = yield call(api.createPaymentMethodSettings, { ...payload, paymentDescription });
    if (status < 300) {
      yield put(actionTypes.createPaymentMethodSettings.success(data));
    } else {
      const error = data.detail ? data.detail : data;
      yield put(actionTypes.createPaymentMethodSettings.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.createPaymentMethodSettings.failure());
  }
}

export function* createPaymentMethodWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.createPaymentMethodSettings.REQUEST);
    yield call(createPaymentMethodSaga, payload);
  }
}

export function* deletePaymentMethodSettingsSaga(id) {
  try {
    const { data, status } = yield call(api.deletePaymentMethodSettings, id);
    if (status < 300) {
      yield put(actionTypes.deletePaymentMethodSettings.success());
      yield delay(1000);
      yield call(getPaymentMethodsSettingsSaga, { search: {} });
    } else {
      yield put(actionTypes.deletePaymentMethodSettings.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.deletePaymentMethodSettings.failure(e));
  }
}
export function* deletePaymentMethodSettingsWatcherSaga() {
  while (true) {
    const { id } = yield take(actionTypes.DELETE_PAYMENT_METHOD_SETTINGS[REQUEST]);
    yield call(deletePaymentMethodSettingsSaga, id);
  }
}

export function* getPaymentMethodSettingsSaga(id) {
  try {
    const { data, status } = yield call(api.getPaymentMethodSettings, id);
    if (status < 300) {
      yield put(actionTypes.getPaymentMethodSettings.success(data));
    } else {
      yield put(actionTypes.getPaymentMethodSettings.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentMethodSettings.failure(e));
  }
}
export function* getPaymentMethodSettingsWatcherSaga() {
  while (true) {
    const { id } = yield take(actionTypes.GET_PAYMENT_METHOD_SETTINGS[REQUEST]);
    yield call(getPaymentMethodSettingsSaga, id);
  }
}

export function* editPaymentMethodSaga({ id, ...values }) {
  const paymentDescription = values.paymentDescription.map(description => ({
    [description.lang]: {
      ...description,
    },
  }));

  try {
    const { status, data } = yield call(api.editPaymentMethodSettings, id, { ...values, paymentDescription });
    if (status < 300) {
      yield put(actionTypes.editPaymentMethodSettings.success(data));
    } else {
      const error = data.detail ? data.detail : data;
      yield put(actionTypes.editPaymentMethodSettings.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.editPaymentMethodSettings.failure());
  }
}

export function* editPaymentMethodWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.editPaymentMethodSettings.REQUEST);
    yield call(editPaymentMethodSaga, payload);
  }
}

export function* getCountriesSaga() {
  try {
    const { status, data } = yield call(api.getCountries);
    if (status < 300) {
      yield put(actionTypes.getCountries.success(data));
    } else {
      yield put(actionTypes.getCountries.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.getCountries.failure());
  }
}

export function* getCountriesWatcherSaga() {
  while (true) {
    yield take(actionTypes.GET_COUNTRIES[REQUEST]);
    yield call(getCountriesSaga);
  }
}

export function* getPaymentTagInfoSaga(paymentTag) {
  try {
    const { status, data } = yield call(api.getPaymentTagInfo, paymentTag);
    if (status < 300) {
      yield put(actionTypes.getPaymentTagInfo.success(data));
    } else {
      yield put(actionTypes.getPaymentTagInfo.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentTagInfo.failure());
  }
}

export function* getPaymentTagInfoWatcherSaga() {
  while (true) {
    const { paymentTag } = yield take(actionTypes.GET_PAYMENT_TAG_INFO[REQUEST]);
    yield call(getPaymentTagInfoSaga, paymentTag);
  }
}

export function* getPaymentTagsSaga() {
  try {
    const { status, data } = yield call(api.getPaymentTags);
    if (status < 300) {
      yield put(actionTypes.getPaymentTags.success(data));
    } else {
      yield put(actionTypes.getPaymentTags.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getPaymentTags.failure());
  }
}

export function* getPaymentTagsWatcherSaga() {
  while (true) {
    yield take(actionTypes.GET_PAYMENT_TAGS[REQUEST]);
    yield call(getPaymentTagsSaga);
  }
}

export function* createPaymentTagSaga(payload) {
  try {
    const { status, data } = yield call(api.createPaymentTag, payload);
    if (status < 300) {
      yield put(actionTypes.createPaymentTag.success(data));
    } else {
      yield put(actionTypes.createPaymentTag.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.createPaymentTag.failure());
  }
}

export function* createPaymentTagWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.createPaymentTag.REQUEST);
    yield call(createPaymentTagSaga, payload);
  }
}

export function* deletePaymentTagSaga({ id }) {
  try {
    const { status, data } = yield call(api.deletePaymentTag, id);
    if (status < 300) {
      yield put(actionTypes.deletePaymentTag.success(data));
      yield call(getPaymentTagsSaga);
    } else {
      yield put(actionTypes.deletePaymentTag.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.deletePaymentTag.failure());
  }
}

export function* deletePaymentTagWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.deletePaymentTag.REQUEST);
    yield call(deletePaymentTagSaga, payload);
  }
}

export function* editPaymentTagSaga({ paymentTag, ...payload }) {
  try {
    const { status, data } = yield call(api.editPaymentTag, paymentTag, payload);
    if (status < 300) {
      yield put(actionTypes.editPaymentTag.success(data));
      yield call(getPaymentTagsSaga);
    } else {
      yield put(actionTypes.editPaymentTag.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.editPaymentTag.failure());
  }
}

export function* editPaymentTagWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.editPaymentTag.REQUEST);
    yield call(editPaymentTagSaga, payload);
  }
}

export default [
  fork(getPaymentMethodsTemplatesWatcherSaga),
  fork(getPaymentMethodsSettingsWatcherSaga),
  fork(getPaymentMethodsListWatcherSaga),
  fork(createPaymentMethodWatcherSaga),
  fork(deletePaymentMethodSettingsWatcherSaga),
  fork(getPaymentMethodSettingsWatcherSaga),
  fork(editPaymentMethodWatcherSaga),
  fork(getCountriesWatcherSaga),
  fork(getPaymentTagInfoWatcherSaga),
  fork(getPaymentTagsWatcherSaga),
  fork(createPaymentTagWatcherSaga),
  fork(deletePaymentTagWatcherSaga),
  fork(editPaymentTagWatcherSaga),
];
