export const STATUSES = ['new', 'active', 'paused', 'paused', 'paused', 'disable', 'closed'];
export const TYPES = [
  'buy',
  'sell',
  'balance',
  'credit',
  'additional_charge',
  'correction',
  'bonus',
  'fee',
  'dividend',
  'interest',
  'cancelled',
  'cancelled',
  'periodical_commission',
];
export const REWARD_TYPES = {
  Shareofprofit: '% from Profit',
  Highwatermark: 'High water mark',
};

export const REWARD_TYPES_TR_KEYS = {
  Shareofprofit: 'investShareOfProfit',
  Highwatermark: 'investHighWaterMark',
};

export const COPY_TYPES_TR_KEYS = ['investOpenOnlyNewDeals', 'investOpenCurrentDeals'];

export const FREQUENCY_TR_KEYS = {
  Monthly: 'justMonthly',
  Weekly: 'justWeekly',
};
