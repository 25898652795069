import css from 'styled-jsx/css'

const staticStyles = css`
  .LoadMore__inner {
    text-align: center;
    position: relative;
    left: -2.2%;
  }

  .LoadMore {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 1px;
    position: relative;
    padding-left: 32px;
    opacity: 0.7;
    transition: all 0.2s ease;
    &:active {
      :global(svg) {
        animation: buttonActiveReload 0.2s;
      }
    }
    &:hover {
      opacity: 1;
    }
    :global(> div) {
      display: inline-block;
      position: absolute;
      top: -2px;
      left: 0;
      width: 22px;
      height: 22px;
    }
  }

  @keyframes buttonActiveReload {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .LoadMore {
      color: ${colors.primary.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }
