import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import staticStyles from './style';

const StatusIndicator = ({ level, statusColor }) => {
  const LEVELS_COUNT = new Array(9).fill('');

  return (
    <div className="StatusIndicator">
      <div className="StatusIndicator__level">
        {LEVELS_COUNT.map((_, index) => (
          <span
            className={classNames('StatusIndicator__line', { 'StatusIndicator__line-active': level >= index + 1 })}
            key={index}
          />
        ))}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>
        {`
          .StatusIndicator {
            background-color: ${statusColor};
          }
        `}
      </style>
    </div>
  );
};

StatusIndicator.propTypes = {
  level: PropTypes.number.isRequired,
  statusColor: PropTypes.string,
};

StatusIndicator.defaultProps = {
  statusColor: '#768485',
};

export default StatusIndicator;
