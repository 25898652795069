import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  investStrategies: {},
  investStrategiesAreLoaded: false,

  investStrategyInvestors: {},
  investStrategyInvestorsAreLoaded: false,

  userAccounts: [],
  userAccountsAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.BO_GET_INVEST_STRATEGIES[REQUEST]:
      return { ...state, investStrategiesAreLoaded: false };
    case ActionTypes.BO_GET_INVEST_STRATEGIES[SUCCESS]:
      return { ...state, investStrategies: action.data, investStrategiesAreLoaded: true };
    case ActionTypes.BO_GET_INVEST_STRATEGIES[FAILURE]:
      return { ...state, error: action.error, investStrategiesAreLoaded: false };

    case ActionTypes.BO_TOGGLE_INVEST_STRATEGY[REQUEST]:
      return { ...state, investStrategiesAreLoaded: false };
    case ActionTypes.BO_TOGGLE_INVEST_STRATEGY[SUCCESS]:
      return { ...state, investStrategiesAreLoaded: true };
    case ActionTypes.BO_TOGGLE_INVEST_STRATEGY[FAILURE]:
      return { ...state, error: action.error, investStrategiesAreLoaded: true };

    case ActionTypes.BO_GET_INVEST_STRATEGY_INVESTORS[REQUEST]:
      return { ...state, investStrategyInvestorsAreLoaded: false };
    case ActionTypes.BO_GET_INVEST_STRATEGY_INVESTORS[SUCCESS]:
      return { ...state, investStrategyInvestors: action.data, investStrategyInvestorsAreLoaded: true };
    case ActionTypes.BO_GET_INVEST_STRATEGY_INVESTORS[FAILURE]:
      return { ...state, error: action.error, investStrategyInvestorsAreLoaded: false };

    case ActionTypes.BO_TOGGLE_INVESTMENT[REQUEST]:
      return { ...state, investStrategyInvestorsAreLoaded: false };
    case ActionTypes.BO_TOGGLE_INVESTMENT[SUCCESS]:
      return { ...state, investStrategyInvestorsAreLoaded: true };
    case ActionTypes.BO_TOGGLE_INVESTMENT[FAILURE]:
      return { ...state, error: action.error, investStrategyInvestorsAreLoaded: true };

    case ActionTypes.BO_GET_USER_ACCOUNTS[REQUEST]:
      return { ...state, userAccountsAreLoaded: false };
    case ActionTypes.BO_GET_USER_ACCOUNTS[SUCCESS]:
      return { ...state, userAccounts: action.data, userAccountsAreLoaded: true };
    case ActionTypes.BO_GET_USER_ACCOUNTS[FAILURE]:
      return { ...state, error: action.error, userAccountsAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
