import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import userImg from '../../newImages/user.svg';

import { staticStyles } from './style';

const ReassignItem = ({ oldManager, newManager, changedBy, reason, comment }) => (
  <div className="ReassignItem">
    <div className="TabFeedItem__Title">
      <FM id="justReassign" />
    </div>
    <div className="ReassignItem__ActionWrapper">
      <div>
        {oldManager && (
          <div className="ReassignItem__Action">
            <FM id="justFrom" />
            <div className="ReassignItem--bold">{oldManager ? oldManager.fullName : '-'}</div>
          </div>
        )}
        {newManager && (
          <div className="ReassignItem__Action">
            <FM id="justTo" />
            <div className="ReassignItem--bold">{newManager ? newManager?.fullName : '-'}</div>
          </div>
        )}
      </div>
      <div className="ReassignItem__Author">
        <img src={userImg} alt="user" />
        <div className="ReassignItem__By">{changedBy && `${changedBy.fullName}`}</div>
      </div>
    </div>
    <div className="ReassignItem__Reason">{reason}</div>
    <div className="ReassignItem__Comment">
      <div className="ReassignItem__Comment--content">{comment}</div>
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

ReassignItem.propTypes = {
  changedBy: PropTypes.object,
  newManager: PropTypes.object,
  oldManager: PropTypes.object,
  reason: PropTypes.string,
  comment: PropTypes.string,
};

ReassignItem.defaultProps = {
  changedBy: null,
  newManager: null,
  oldManager: null,
  reason: '',
  comment: '',
};

export default ReassignItem;
