import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatusPlate {
    border-radius: 50px;
    padding: 3px 11px;
    background: grey;
    display: flex;
    align-items: center;

    &__indicator {
      display: flex;
      align-items: center;
      height: 13px;
    }

    &__line {
      width: 2px;
      border-radius: 2px;
      height: 100%;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      display: inline-block;
      margin-right: 2px;

      &-active {
        background: #ffffff;
      }

      &:first-child {
        max-height: 7px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__name {
      color: #fff;
      font-size: rem(16px);
      margin-left: 10px;
    }

    &--reverse {
      justify-content: flex-end;
      .StatusPlate__name {
        margin-left: 0;
        margin-right: 10px;
      }
      .StatusPlate__indicator {
        transform: scale(-1, 1);
      }
    }
  }
`;

export default staticStyles;
