import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import staticStyles from './style';

const StatusPlate = ({ title, level, statusColor, reverse }) => {
  const LEVELS_COUNT = new Array(9).fill('');

  return (
    <div className={classNames('StatusPlate', { 'StatusPlate--reverse': reverse })}>
      {reverse ? (
        <>
          {' '}
          <span className="StatusPlate__name">{title}</span>
          <div className="StatusPlate__indicator">
            {LEVELS_COUNT.map((_, index) => (
              <span
                className={classNames('StatusPlate__line', { 'StatusPlate__line-active': level >= index })}
                key={index}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          {' '}
          <div className="StatusPlate__indicator">
            {LEVELS_COUNT.map((_, index) => (
              <span
                className={classNames('StatusPlate__line', { 'StatusPlate__line-active': level >= index })}
                key={index}
              />
            ))}
          </div>
          <span className="StatusPlate__name">{title}</span>
        </>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>
        {`
          .StatusPlate {
            background-color: ${statusColor};
          }
        `}
      </style>
    </div>
  );
};

StatusPlate.propTypes = {
  reverse: PropTypes.bool,
  title: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  statusColor: PropTypes.string,
};

StatusPlate.defaultProps = {
  reverse: false,
  statusColor: '#768485',
};

export default StatusPlate;
