import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RangeSelector {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: column;

    &__OpenOptionsButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #f3f3f3;
      background-color: #fff;
      border-radius: 8px;
      height: 100%;
      position: relative;
      font-size: 14px;
      line-height: 1.4;
      color: #cecece;

      &:disabled {
        background: #f9f9f9;
      }
    }

    &--disabled {
      color: #cecece;
    }

    &__OpenOptionsButton--FastFilter {
      justify-content: flex-end;
    }

    &__Labels {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
    }

    &__LabelsSelected {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      &--blue {
        color: #0aa1e2;
      }
    }

    &__LabelsSelectedCentered {
      flex: 1; /* Expand to fill remaining space */
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
    }
  }
`;

export { staticStyles };
