import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.ReassignsTable) {
    background: white;
    border-radius: 16px;
  }

  :global(.UpdatedCRMTable__wrapper) {
    margin-top: 0px !important;
  }

  :global(.UpdatedCRMTable__Thead-Row, .UpdatedCRMTable__Tbody-Row) {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 60px;
    grid-auto-rows: minmax(68px, auto);
    gap: 2px;
    word-break: break-word;

    @media only screen and (max-width: 1150px) {
      grid-template-columns: 1fr 1fr 1fr 60px;
    }
  }
  :global(.actions) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
