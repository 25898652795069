import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  allBonuses: [],
  allBonusesAreLoaded: false,

  userBonuses: [],
  userBonusesAreLoaded: false,

  userPerBonuses: [],
  userPerBonusesAreLoaded: false,

  cancelBonusModal: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_BONUSES[REQUEST]:
      return { ...state, allBonusesAreLoaded: false };
    case ActionTypes.GET_ALL_BONUSES[SUCCESS]:
      return {
        ...state,
        allBonuses: action.data,
        allBonusesAreLoaded: true,
      };
    case ActionTypes.GET_ALL_BONUSES[FAILURE]:
      return {
        ...state,
        error: action.error,
        allBonusesAreLoaded: false,
      };

    case ActionTypes.GET_USER_BONUSES[REQUEST]:
      return { ...state, userBonusesAreLoaded: false };
    case ActionTypes.GET_USER_BONUSES[SUCCESS]:
      return {
        ...state,
        userBonuses: action.data,
        userBonusesAreLoaded: true,
      };
    case ActionTypes.GET_USER_BONUSES[FAILURE]:
      return {
        ...state,
        error: action.error,
        userBonusesAreLoaded: false,
      };

    case ActionTypes.GET_PER_BONUSES[REQUEST]:
      return { ...state, userPerBonusesAreLoaded: false };
    case ActionTypes.GET_PER_BONUSES[SUCCESS]:
      return {
        ...state,
        userPerBonuses: action.data,
        userPerBonusesAreLoaded: true,
      };
    case ActionTypes.GET_PER_BONUSES[FAILURE]:
      return {
        ...state,
        error: action.error,
        userPerBonusesAreLoaded: false,
      };

    case ActionTypes.REJECT_BONUS[REQUEST]:
      return { ...state, cancelBonusModal: true };
    case ActionTypes.REJECT_BONUS[SUCCESS]:
      return {
        ...state,
        cancelBonusModal: false,
      };
    case ActionTypes.REJECT_BONUS[FAILURE]:
      return {
        ...state,
        cancelBonusModal: true,
      };

    case ActionTypes.REJECT_PER_BONUS[REQUEST]:
      return { ...state, cancelBonusModal: true };
    case ActionTypes.REJECT_PER_BONUS[SUCCESS]:
      return {
        ...state,
        cancelBonusModal: false,
      };
    case ActionTypes.REJECT_PER_BONUS[FAILURE]:
      return {
        ...state,
        cancelBonusModal: true,
      };

    default:
      return state;
  }
};

export default reducer;
