import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabFeedItem {
    position: relative;
    margin: 0 0 24px 0;
    padding-bottom: 24px;

    @include respond-to(xs) {
      padding-bottom: 48px;
    }

    &__Reaction-img {
      width: 20px;
      height: 20px;
    }

    &__bold {
      font-weight: 500;
      margin-left: rem(5px);
    }

    &:not(&:last-child)::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, #f3f3f3 48.44%, rgba(243, 243, 243, 0) 100%);
    }

    &:last-child {
      margin: 0 0 0 0;
      padding: 0;

      @include respond-to(xs) {
        padding-bottom: 36px;
      }
    }

    &__Reaction__title {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        font-weight: 500;
        // margin-right: 8px;
      }
    }

    &__Reaction-button {
      display: flex;
      align-items: center;
      gap: 4.5px;
      font-size: 12px;
      font-weight: 300;
      margin: 0;
      &.active {
        font-weight: 500;
      }
    }

    &__Wrap {
      display: grid;
      grid-template-columns: 0fr 1fr;

      @include respond-to(xs) {
        display: block;
      }
    }

    &__Subwrap {
      display: flex;
      align-items: center;
      width: 100%;

      @include respond-to(lg) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__Info {
      @include respond-to(lg) {
        display: flex;
        flex-direction: column;
      }
    }

    &__Info-wrap {
      width: 100%;
      position: relative;
    }

    &__Description {
      width: 100%;
      display: flex;
      gap: 7px;
    }

    &__Title {
      font-weight: 400;
      font-size: 10px;
      line-height: 1.4;
      color: #0aa1e2;
      margin-bottom: 5px;
      &-span {
        margin-right: 5px;
      }

      @include respond-to(xs) {
        margin-left: 44px;
        margin-bottom: 0;
      }
    }

    &__Description {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.2px;

      color: #000000;
      margin-bottom: 5px;
    }

    &__Action {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;

      @include respond-to(lg) {
        order: 3;
      }
    }

    &__Date {
      font-size: 12px;
      line-height: 1.4;
      color: #999;
      margin: 0 0 0 auto;
      min-width: 120px;
      text-align: right;
      .DateCell {
        position: absolute;
        right: 0;
      }

      @include respond-to(lg) {
        min-width: 0;
        text-align: left;
        order: 1;
        margin: 0 0 5px 0;
        .DateCell {
          position: static;
        }
      }

      @include respond-to(xs) {
        .DateCell {
          position: absolute;
          bottom: -32px;
          left: 0;
        }
      }
    }

    &--reassign {
      .DateCell {
        bottom: -17px;
      }
    }

    &__Footer {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
      flex-direction: row;

      @include respond-to(md) {
        flex-direction: column;
      }
      /* &--absolute {
        position: absolute;
        bottom: 10px;
        @include respond-to(md) {
          position: static;
        }
      } */
    }
    &__Buttons {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      .ActionsButton__delete {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }
    }

    &__Log-data {
      padding: 0 0 10px 38px;
      margin: 20px 0 0 0;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #343846;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #d6dbe0;
      }

      .TabFeedItem[data-visible='hide'] & {
        display: none;
      }
    }

    &__Log-data-td-key {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }

    &__Log-data-td-value {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }
    .DateCell {
      display: flex;
      gap: 5px;
    }
  }
`;

export { staticStyles };
