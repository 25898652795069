import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import {
  PageLayout,
  NoItemsPanel,
  PageContentPreloader,
  SearchPanel,
  DefaultButton,
  BasicModal,
} from 'common/components';

import noIssuesImage from 'common/images/noIssues.svg';
import staticStyles from 'backoffice/support/IssuesPage/style';
import { labelAndValuesOfLanguages } from 'common/utils/helpers';
import { getUsers } from '../../users/_redux';

import { getIssues } from '../_redux';
import { IssuesTable, CreateIssueTicketForm } from '../_components';

const IssuesPage = ({ issues, getIssues, issuesAreLoaded, history, location, intl }) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    getIssues({ search: query });
  }, [getIssues]);
  /* eslint-enable */

  const toggleCreateIssueModal = () => {
    setIsIssueModalOpen(!isIssueModalOpen);
  };

  const handleGetIssues = () => {
    getIssues({ search: query });
  };

  const handleRowClick = id => history.push(`/backoffice/support/${id}/`);

  const filters = [
    {
      title: 'justLanguage',
      accessor: 'language',
      options: [{ value: '', label: intl.formatMessage({ id: 'boAllLanguages' }) }, ...labelAndValuesOfLanguages],
    },
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'open', label: intl.formatMessage({ id: 'status_open' }) },
        {
          value: 'answered',
          label: intl.formatMessage({ id: 'status_answered' }),
        },
        { value: 'closed', label: intl.formatMessage({ id: 'status_closed' }) },
      ],
    },
  ];

  return (
    <PageLayout captionSlug="boIssues" showReload onReloadClick={handleGetIssues} reloading={!issuesAreLoaded}>
      <div className="IssuesPage__button-inner">
        {issues.count > 0 && Object.keys(query).length && (
          <DefaultButton onClick={toggleCreateIssueModal} textId="createIssue" plus filled />
        )}
        <style jsx>{staticStyles}</style>
      </div>
      <PageContentPreloader ready={issuesAreLoaded} type="smallLines" firstLaunchOnly>
        {!issues.count && !Object.keys(query).length ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getIssues}
            filters={filters}
            params={{ search: query }}
          />
        )}
        {issues.count ? (
          <IssuesTable
            data={issues}
            loading={!issuesAreLoaded}
            location={location}
            history={history}
            getList={getIssues}
            onRowClick={handleRowClick}
            params={{ search: query }}
          />
        ) : (
          <NoItemsPanel
            icon={noIssuesImage}
            buttonSlug="supportNewIssue"
            captionSlug={Object.keys(query).length ? 'noIssuesMatchingTheParameters' : 'noIssuesYet'}
            onButtonClick={toggleCreateIssueModal}
          />
        )}
      </PageContentPreloader>
      <BasicModal isOpen={isIssueModalOpen} captionId="createIssueModalHeader" onRequestClose={toggleCreateIssueModal}>
        <CreateIssueTicketForm toggleCreateIssueModal={toggleCreateIssueModal} search={query} />
      </BasicModal>
    </PageLayout>
  );
};

IssuesPage.propTypes = {
  issues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  getIssues: PropTypes.func.isRequired,
  issuesAreLoaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      issues: state.backoffice.support.issues,
      users: state.backoffice.users.users,
      issuesAreLoaded: state.backoffice.support.issuesAreLoaded,
    }),
    {
      getIssues: search => getIssues.request(search),
      getUsers: search => getUsers.request(search),
    }
  )
)(IssuesPage);
export { IssuesPage };
