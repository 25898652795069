import { createFormAction } from 'redux-form-saga';
import { action, createRequestTypes, REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';

export const getOTPToken = createFormAction('GET_OTP_TOKEN');
export const resendOTPToken = createFormAction('RESEND_OTP_TOKEN');

export const getJWTToken = createFormAction('GET_JWT_TOKEN');

export const login = createFormAction('LOGIN');

export const LOGIN_WITH_TELEGRAM = createRequestTypes('LOGIN_WITH_TELEGRAM');
export const loginWithTelegram = {
  request: data => action(LOGIN_WITH_TELEGRAM[REQUEST], { data }),
  success: data => action(LOGIN_WITH_TELEGRAM[SUCCESS], { data }),
  failure: error => action(LOGIN_WITH_TELEGRAM[FAILURE], { error }),
};

export const bindPhone = createFormAction('BIND_PHONE');

export const RESET_AUTH = 'RESET_AUTH';
export const resetAuth = error => action(RESET_AUTH, { error });

export const CHANGE_AUTH_STEP = 'CHANGE_AUTH_STEP';
export const changeAuthStep = step => action(CHANGE_AUTH_STEP, { step });

export const REDUCE_CODE_TIMER = 'REDUCE_CODE_TIMER';
export const reduceCodeTimer = codeTimer => action(REDUCE_CODE_TIMER, { codeTimer });

export const SET_AUTHORIZED = 'SET_AUTHORIZED';
export const setAuthorized = () => action(SET_AUTHORIZED);

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logout = () => action(LOGOUT_REQUEST);
export const logoutSuccess = () => action(LOGOUT_SUCCESS);
