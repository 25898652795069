import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .InvestExportsPageNew__Exports {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .InvestExportsPageNew__Exports .BaseSelectField {
    margin-bottom: 0;
    width: 220px;
  }

  .InvestExportsPageNew__Exports .BaseSelectField .BaseSelectField__label {
    font-size: rem(15px);
    line-height: rem(22px);
    font-weight: 500;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  .InvestExportsPageNew__Exports .Select__control {
    min-height: 100% !important;
  }

  .InvestExportsPageNew {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(20px, 0.5fr) minmax(150px, 1.5fr) repeat(2, minmax(100px, 1fr)) minmax(
            250px,
            0.2fr
          );
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InvestStrategiesPage__Exports {
      .BaseSelectField {
        .Select__control {
          border-color: ${colors.primary.hex} !important;
          &::after {
            border-color: ${colors.primary.hex};
          }
        }

        &__label {
          color: ${colors.primary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
