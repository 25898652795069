import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { staticStyles, getDynamicStyles } from './style';
import next from './next.svg';
import prev from './prev.svg';

const Pagination = ({ externalClass, theme, dataCount, handleChangePage, sizes, searchId, searchOff, pending }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);

  const searchCurrentPage = !searchOff ? Number(searchId ? search[`${searchId}_currentPage`] : search.currentPage) : 1;
  const [currentPage, setCurrentPage] = useState(searchCurrentPage || 1);
  const [pageOld, setPageOld] = useState(searchCurrentPage || 1);

  const searchPagesize = !searchOff ? Number(searchId ? search[`${searchId}_pageSize`] : search.pageSize) : sizes[0];
  const [pageSize, setPageSize] = useState(
    Number.isNaN(searchPagesize)
      ? search[`${searchId}_pageSize`] === 'all'
        ? 'all'
        : sizes[0]
      : searchPagesize || sizes[0]
  );

  /* [dataCount, pageSize] */
  const pagesCount = useMemo(() => {
    const count = Math.ceil(dataCount / sizes[0]);
    if (!sizes.includes(Number(pageSize))) {
      if (pageSize === 'all') {
        return 'all';
      }
      setPageSize(sizes[0]);
      return Number.isNaN(count) ? 0 : count;
    }
    return Number.isNaN(count) ? 0 : count;
  }, [sizes, dataCount, pageSize]);

  /* first start */
  useMemo(() => {
    let page = null;
    let size = null;
    if (currentPage < 0 || currentPage > pagesCount) {
      setCurrentPage(1);
      page = 1;
    }
    if (!sizes.includes(Number(pageSize)) && pageSize !== 'all') {
      setPageSize(sizes[0]);
      size = sizes[0];
    }

    if (handleChangePage) {
      handleChangePage(page || currentPage, size || pageSize);
    }
  }, []);

  /* updateSearch() */
  const updateSearch = (page, size) => {
    if (searchOff) return;
    const propCurrentPage = searchId ? `${searchId}_currentPage` : 'currentPage';
    const propPageSize = searchId ? `${searchId}_pageSize` : 'pageSize';
    history.replace({
      ...location,
      search: queryString.stringify({ ...search, [propCurrentPage]: page, [propPageSize]: size }),
    });
  };

  /* changePage() */
  const changePage = (page, size) => {
    page = page || 1;
    if (page !== pageOld) {
      setCurrentPage(page);
      setPageOld(page);
      if (handleChangePage) {
        handleChangePage(page, size);
      }
    }
  };

  /* [currentPage, pageSize] */
  useEffect(() => {
    if (currentPage === '') {
      return;
    }
    if (currentPage < 1) {
      setCurrentPage(1);
      updateSearch(1, pageSize);
      return;
    }
    if (currentPage > pagesCount && pagesCount !== 0) {
      setCurrentPage(pagesCount);
      updateSearch(pagesCount, pageSize);
      return;
    }
    updateSearch(currentPage, pageSize);
  }, [currentPage, pageSize]);

  return (
    <div className={classNames('Pagination', externalClass)}>
      {pagesCount !== 'all' && pagesCount !== 0 && (
        <div className="Pagination__left">
          {/* prev button */}
          <button
            type="button"
            className="Pagination__left-prev"
            onClick={() => {
              const prevPage = Number(currentPage) - 1;
              changePage(prevPage, pageSize);
            }}
            disabled={currentPage <= 1 || pending}>
            <img src={prev} alt="prev" />
          </button>

          {/* input page */}
          <div className="Pagination__left-pages">
            <input
              className="Pagination__left-pages-number"
              type="number"
              value={currentPage}
              onChange={e => {
                const pageInput = e.target.value.replace(/[-.e]/g, '');
                if (pageInput) {
                  setCurrentPage(Number(pageInput));
                } else {
                  setCurrentPage('');
                }
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') changePage(currentPage, pageSize);
              }}
              onBlur={() => changePage(currentPage, pageSize)}
              onClick={e => e.target.select()}
              disabled={pending}
            />
            <div className="Pagination__left-pages-count">{pagesCount}</div>
          </div>

          {/* next button */}
          <button
            type="button"
            className="Pagination__left-next"
            onClick={() => {
              const nextPage = Number(currentPage) + 1;
              changePage(nextPage, pageSize);
            }}
            disabled={currentPage >= pagesCount || pending}>
            <img src={next} alt="next" />
          </button>
        </div>
      )}

      {/* page size */}
      <div className="Pagination__right">
        {sizes.map((size, i) => (
          <button
            type="button"
            className={classNames('Pagination__right-count', {
              'Pagination__right-count--active': pageSize === size,
            })}
            onClick={() => {
              if (pageSize === size) return;
              if (currentPage !== 1) setCurrentPage(1);
              if (pageOld !== 1) setPageOld(1);
              setPageSize(size);
              if (handleChangePage) {
                handleChangePage(1, size);
              }
            }}
            key={i}
            disabled={pending}>
            {size}
          </button>
        ))}
      </div>
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

Pagination.propTypes = {
  dataCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  externalClass: PropTypes.string,
  theme: PropTypes.object,
};

Pagination.defaultProps = {
  externalClass: '',
  theme: {},
};

export default withTheme()(Pagination);
