import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .Auth__form-inner {
    width: 100%;
    height: auto;
    flex: 0 0 auto;
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .Auth__message {
    padding: 15px 20px;
    margin: 25px 0 0;
    background: ${rgba(['#ff4058', 0.35])};
  }

  .RegistrationWidjet__SiteHref:hover {
    color: #0AA1E2 !important;
  }
`;

export { staticStyles };
