import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .UserInteractions__tickets {
    display: flex;
    flex-direction: column;
    padding: rem(15px 25px);
  }

  .UserInteractions__tickets-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-bottom: 1px solid;
    padding-bottom: 10px;

    &:not(:first-child) {
      margin-top: rem(15px);
    }

    :global(span) {
      &:nth-child(2) {
        margin-top: rem(5px) !important;
        font-size: rem(12px) !important;
      }
    }
  }

  .UserInteractions__description {
    text-align: center;
    padding: rem(15px 25px);
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserInteractions__tickets-item {
      border-color: ${colors.lightGray.hex};
      :global(span) {
        &:nth-child(2) {
          color: ${colors.gray.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
