import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import ReactTable from 'react-table-6';
import { FormattedMessage as FM } from 'react-intl';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import ResponsiveTable from '../ResponsiveTable';

import { DefaultButton } from '../..';

import { staticStyles, getDynamicStyles } from './style';

const BaseTable = ({
  columns,
  viewExport,
  exportXlsClick,
  params,
  isResponsive,
  exportXlsLoading,
  exportCsvClick,
  exportCsvLoading,
  theme,
  ...props
}) => {
  const columnsList = columns.map(({ accessor, key, sortable, Cell, Footer }) => ({
    Header: key ? () => <FM id={key} /> : undefined,
    Footer,
    Cell,
    accessor,
    sortable,
  }));

  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  return (
    <>
      <div className="BaseTable__action-buttons">
        {viewExport && !!exportXlsClick && (
          <DefaultButton
            type="button"
            textId="justExportXls"
            onClick={() => exportXlsClick(params.search)}
            loading={exportXlsLoading}
            disabled={exportXlsLoading}
          />
        )}
        {!!exportCsvClick && (
          <DefaultButton
            type="button"
            textId="justExportCsv"
            onClick={() => exportCsvClick(params.search)}
            loading={exportCsvLoading}
            disabled={exportCsvLoading}
          />
        )}
      </div>
      <MediaQuery query={isResponsive ? '(min-width: 768px)' : 'all'}>
        <ReactTable
          {...props}
          columns={columnsList}
          resizable={false}
          previousText={<FM id="justPrevious" />}
          nextText={<FM id="justNext" />}
          loadingText={<FM id="justLoading" />}
          noDataText={<FM id="noData" />}
          pageText={<FM id="justPage" />}
          ofText={<FM id="justOf" />}
          rowsText=""
        />
      </MediaQuery>
      {isResponsive && (
        <MediaQuery query="(max-width: 767px)">
          <ResponsiveTable {...props} columns={columns} params={params} />
        </MediaQuery>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </>
  );
};

BaseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      key: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  pages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  minRows: PropTypes.number,
  showPagination: PropTypes.bool,
  loading: PropTypes.bool,
  viewExport: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  sortable: PropTypes.bool,
  exportXlsClick: PropTypes.func,
  exportCsvClick: PropTypes.func,
  params: PropTypes.object,
  exportXlsLoading: PropTypes.bool,
  exportCsvLoading: PropTypes.bool,
  theme: PropTypes.object,
  isResponsive: PropTypes.bool,
};

BaseTable.defaultProps = {
  data: [],
  page: 0,
  pages: null,
  pageSize: 100,
  minRows: 0,
  loading: false,
  showPagination: false,
  onPageChange: undefined,
  onPageSizeChange: undefined,
  sortable: false,
  exportXlsClick: null,
  exportCsvClick: null,
  params: {},
  viewExport: false,
  exportXlsLoading: false,
  exportCsvLoading: false,
  theme: {},
  isResponsive: false,
};

export default withTheme()(BaseTable);
export { BaseTable };
