import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, ActionsButton, StatusCell, BasicModal, DateCell } from 'common/components';
import { EditIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import { TaskIcon, FinishTaskForm, EditTaskForm } from '..';

import { staticStyles, getDynamicStyles } from './style';

const TaskPanel = ({ task, user, managers, users, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isOpen, setisOpen] = useState(false);
  const [activeModal, setActiveModal] = useState({ type: 'add' });

  const toggleModal = () => {
    setisOpen(!isOpen);
  };

  const handleTaskAction = type => {
    setActiveModal({ id: task.id, type });
    toggleModal();
  };

  const modalTitle = str => {
    const firstLetterToUpperCase = str.charAt(0).toUpperCase() + str.substring(1);
    return `just${firstLetterToUpperCase}Task`;
  };

  return (
    <div className="TaskPanel">
      <div className="TaskPanel__header">
        <div className="TaskPanel__icon">
          <TaskIcon type={task.icon} />
        </div>
        <div className="TaskPanel__title">{task.title}</div>
        <div className="TaskPanel__actions">
          {user.perms.editTaskPerm && (
            <ActionsButton edit onClickFunc={() => handleTaskAction('edit')} disabled={task.status === 'CLOSED'}>
              <EditIcon />
            </ActionsButton>
          )}
          {user.perms.deleteTaskPerm && (
            <DefaultButton
              textId="justFinish"
              type="button"
              onClick={() => handleTaskAction('finish')}
              disabled={task.status === 'CLOSED' || !task.statusChangeable}
            />
          )}
        </div>
      </div>
      <div className="TaskPanel__row">
        <div className="TaskPanel__line">
          <span className="TaskPanel__name">
            <FormattedMessage id="justSubject" />
          </span>
          <span className="TaskPanel__value">
            {task.subject ? `${task.subject.firstName} ${task.subject.lastName}` : '一'}
          </span>
        </div>
        <div className="TaskPanel__line">
          <span className="TaskPanel__name">
            <FormattedMessage id="justExpire" />
          </span>
          <span className="TaskPanel__value">
            <DateCell value={task.expirationDate} />
            {task.isOverdue && (
              <span className="overdue">
                <FormattedMessage id="justOverdue" />
              </span>
            )}
          </span>
        </div>
      </div>
      <div className="TaskPanel__row">
        <div className="TaskPanel__line">
          <span className="TaskPanel__name">
            <FormattedMessage id="justAssigned" />
          </span>
          <span className="TaskPanel__value">
            {task.assignedTo.email === user.email ? (
              <FormattedMessage id="justToMe" />
            ) : (
              `${task.assignedTo.firstName} ${task.assignedTo.lastName}`
            )}
          </span>
        </div>
        <div className="TaskPanel__line">
          <span className="TaskPanel__name">
            <FormattedMessage id="justStatus" />
          </span>
          <span className="TaskPanel__value">
            <StatusCell statusCode={task.status} />
          </span>
        </div>
      </div>
      <div className="TaskPanel__line">
        <span className="TaskPanel__name">
          <FormattedMessage id="justText" />
        </span>
        <p className="TaskPanel__value">{task.text}</p>
      </div>
      <div className="TaskPanel__line">
        <span className="TaskPanel__name">
          <FormattedMessage id="justComment" />
        </span>
        <p className="TaskPanel__value">{task.comment ?? '一'}</p>
      </div>
      <BasicModal isOpen={isOpen} captionId={modalTitle(activeModal.type)} onRequestClose={toggleModal}>
        {activeModal.type === 'edit' && (
          <EditTaskForm
            toggleModal={toggleModal}
            managers={managers}
            users={users}
            id={activeModal.id}
            initialValues={{
              title: task.title,
              icon: task.icon,
              subject: task.subject?.id,
              assignedTo: task.assignedTo.id,
              expirationDate: task.expirationDate,
              text: task.text,
              page: 'detail',
            }}
          />
        )}

        {activeModal.type === 'finish' && (
          <FinishTaskForm toggleModal={toggleModal} id={activeModal.id} initialValues={{ page: 'detail' }} />
        )}
      </BasicModal>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

TaskPanel.propTypes = {
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  managers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

TaskPanel.defaultProps = {
  theme: {},
};

export default withTheme()(TaskPanel);
export { TaskPanel };
