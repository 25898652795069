import { put, call, fork, take } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getCategoriesSaga({ search }) {
  try {
    const { data } = yield call(api.getCategories, search);
    yield put(ActionTypes.getCategories.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCategories.failure());
  }
}

export function* getCategoriesWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_ADMIN_CATEGORIES[REQUEST]);
    yield call(getCategoriesSaga, payload);
  }
}

export function* getQuestionsSaga({ categoryId }) {
  try {
    const { data } = yield call(api.getQuestions, categoryId);
    yield put(ActionTypes.getQuestions.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getQuestions.failure());
  }
}

export function* getQuestionsWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_ADMIN_QUESTIONS[REQUEST]);
    yield call(getQuestionsSaga, payload);
  }
}

export default [fork(getCategoriesWatcherSaga), fork(getQuestionsWatcherSaga)];
