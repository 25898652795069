import React from 'react';
import PropTypes from 'prop-types';
import {
  StarIcon,
  TimeIcon,
  UserIcon,
  WatchIcon,
  ReassignIcon,
} from 'crm/users/_components/TabFeed/_components/TaskIcon/icons';
import { EmailIcon, PhoneIcon, MonitoringIcon } from './icons';
import { staticStyles } from './style';

const ICON_TYPES = ['EMAIL', 'PHONE', 'MONITORING', 'STAR', 'TIME', 'USER', 'WATCH'];

const Components = {
  MAIL: EmailIcon,
  CALL: PhoneIcon,
  MONITORING: MonitoringIcon,
  STAR: StarIcon,
  TIME: TimeIcon,
  USER: UserIcon,
  WATCH: WatchIcon,
  Reassign: ReassignIcon,
};

const TaskIcon = ({ type }) => {
  const Icon = Components[type];

  return (
    <div className="TaskIcon">
      {Icon ? <Icon /> : <span>{type}</span>}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TaskIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TaskIcon;
export { TaskIcon, ICON_TYPES };
