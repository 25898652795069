import React from 'react';

import PropTypes from 'prop-types';

const LoadingIcon = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21C10.7854 21 9.60586 20.7627 8.49668 20.2934C7.42441 19.8398 6.46289 19.1895 5.63672 18.3633C4.81055 17.5371 4.16016 16.5756 3.70664 15.5033C3.2373 14.3941 3 13.2146 3 12C3 11.6502 3.28301 11.3672 3.63281 11.3672C3.98262 11.3672 4.26563 11.6502 4.26563 12C4.26563 13.0441 4.46953 14.0566 4.87383 15.0111C5.26406 15.9322 5.82129 16.7602 6.53145 17.4703C7.2416 18.1805 8.06953 18.7395 8.99063 19.1279C9.94336 19.5305 10.9559 19.7344 12 19.7344C13.0441 19.7344 14.0566 19.5305 15.0111 19.1262C15.9322 18.7359 16.7602 18.1787 17.4703 17.4686C18.1805 16.7584 18.7395 15.9305 19.1279 15.0094C19.5305 14.0566 19.7344 13.0441 19.7344 12C19.7344 10.9559 19.5305 9.94336 19.1262 8.98887C18.7373 8.06999 18.1744 7.23494 17.4686 6.52969C16.7641 5.8229 15.9288 5.25991 15.0094 4.87207C14.0566 4.46953 13.0441 4.26562 12 4.26562C11.6502 4.26562 11.3672 3.98262 11.3672 3.63281C11.3672 3.28301 11.6502 3 12 3C13.2146 3 14.3941 3.2373 15.5033 3.70664C16.5756 4.16016 17.5371 4.81055 18.3633 5.63672C19.1895 6.46289 19.8381 7.42617 20.2916 8.49668C20.7609 9.60586 20.9982 10.7854 20.9982 12C20.9982 13.2146 20.7609 14.3941 20.2916 15.5033C19.8398 16.5756 19.1895 17.5371 18.3633 18.3633C17.5371 19.1895 16.5738 19.8381 15.5033 20.2916C14.3941 20.7627 13.2146 21 12 21Z"
      fill="#5D647D"
    />
  </svg>
);

LoadingIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

export default LoadingIcon;
