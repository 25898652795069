import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const FaqCategory = ({ onSelectCategory, category: { id, title, logo }, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <button type="button" className="FaqCategory" onClick={() => onSelectCategory(id)}>
      <div className="FaqCategory__img">{logo && <img src={logo} alt={title} />}</div>
      <div className="FaqCategory__name">{title}</div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>
    </button>
  );
};

FaqCategory.propTypes = {
  onSelectCategory: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

FaqCategory.defaultProps = {
  theme: {},
};

const ThemedFaqCategory = withTheme()(FaqCategory);

export default ThemedFaqCategory;
export { FaqCategory };
