import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestStrategyTable {
    &.full {
      .ReactDataTable__wrapper .ReactDataTable tr {
        grid-template-columns: repeat(2, minmax(200px, 1fr)) repeat(3, minmax(150px, 1fr)) 150px !important;
      }
    }

    &:not(.full) {
      .ReactDataTable__wrapper .ReactDataTable tr {
        grid-template-columns: repeat(2, minmax(200px, 1fr)) repeat(2, minmax(150px, 1fr)) 150px !important;
      }
    }

    .ReactDataTable__tbody {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .ReactDataTable__thead-cell,
    .ReactDataTable__tbody-cell {
      justify-content: center;
      text-align: center !important;
    }

    .ReactDataTable__tbody-row {
      margin: 0 !important;
      cursor: auto !important;
    }

    .ReactDataTable__thead-cell:not(.ReactDataTable__thead-cell--sortable) {
      cursor: auto !important;
    }

    .ReactDataTable__thead-cell,
    .ReactDataTable__tbody-cell {
      display: flex;
      justify-content: center;
      text-align: center !important;
      // min-width
    }

    @include respond-to(lg) {
      .ReactDataTable__wrapper {
        overflow-x: auto;
      }
    }
  }

  .SearchPanel {
    margin-bottom: 25px !important;

    @include respond-to(sm) {
      &:first-child {
        margin-bottom: 15px !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InvestStrategyTable {
      &__basic-info {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        width: 100%;
        gap: 11px;
        color: ${colors.secondary.hex};
        .react-gravatar {
          border-radius: 50%;
        }
        .user-name {
          white-space: normal;
          cursor: pointer;
          text-align: left;
        }
      }

      &__email {
        font-size: 13px;
        line-height: 20px;
      }

      &__equity {
        font-size: 13px;
        line-height: 20px;
      }

      &__reward-account {
        font-size: 13px;
        line-height: 20px;
      }

      &__status_cell {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 16px;

        &.active {
          background-color: ${colors.success.hex};
        }
      }

      .status {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${colors.gray.hex};
        &.active {
          background-color: ${colors.success.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
