import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_STATUSES_LIST = createRequestTypes('GET_STATUSES_LIST');
export const getStatusesList = {
  request: () => action(GET_STATUSES_LIST[REQUEST]),
  success: data => action(GET_STATUSES_LIST[SUCCESS], { data }),
  failure: error => action(GET_STATUSES_LIST[FAILURE], { error }),
};

export const GET_STATUS_INFO = createRequestTypes('GET_STATUS_INFO');
export const getStatusInfo = {
  request: id => action(GET_STATUS_INFO[REQUEST], { id }),
  success: data => action(GET_STATUS_INFO[SUCCESS], { data }),
  failure: error => action(GET_STATUS_INFO[FAILURE], { error }),
};

export const GET_STATUS_DESCRIPTION = createRequestTypes('GET_STATUS_DESCRIPTION');
export const getStatusDescription = {
  request: () => action(GET_STATUS_DESCRIPTION[REQUEST]),
  success: data => action(GET_STATUS_DESCRIPTION[SUCCESS], { data }),
  failure: error => action(GET_STATUS_DESCRIPTION[FAILURE], { error }),
};
