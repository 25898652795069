import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .NewsPage__action-buttons {
    position: absolute;
    top: rem(-64px);
    right: 0;
    @include respond-to(sm) {
      top: -55px;
    }
    @include respond-to(xs) {
      position: static;
      text-align: center;
      margin-bottom: rem(15px);
    }
    .DefaultButton {
      margin-left: rem(10px);
      &:first-child {
        margin: 0;
      }
    }
  }

  .NewsPage__caption {
    margin-bottom: rem(25px);
  }

  .NewsPage__body {
    text-align: left;
    position: relative;
    padding: rem(10px 25px 25px);
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    * {
      font-family: $ubuntu-font !important;
      margin-top: rem(15px) !important;
    }
    p {
      font-size: rem(16px) !important;
      margin-left: 0 !important;
      overflow: auto !important;
    }
    strong {
      font-size: rem(16px) !important;
      display: inline-block;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
    table {
      width: 100%;
      table-layout: fixed;
      background: #f0eff0;
      overflow-x: scroll;
      * {
        margin: 0;
      }
      tbody {
        tr:nth-of-type(even) {
          background: #e7dfd3;
        }
        td {
          text-align: center;
          padding: 10px;
        }
      }
    }
  }
`
const getDynamicStyles = ({ colors }) => {
  return css.global`
    .NewsPage__body {
      background: ${colors.white.hex};
      table {
        border: 5px solid ${colors.primary.hex};
        tbody {
          td {
            border-right: 1px solid ${colors.primary.hex};
            border-left: 1px solid ${colors.primary.hex};
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
