import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NewCRMSelect } from 'crm/_newComponents';

const InputChoice = ({ label, options, selectedOptions, onChange }) => {
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  return (
    <NewCRMSelect
      areOptionsOpen={areOptionsOpen}
      setAreOptionsOpen={setAreOptionsOpen}
      label={label}
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
    />
  );
};

InputChoice.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputChoice;
