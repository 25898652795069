import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'crm/_components/';
import { ClientsPage, ManagersPage } from 'crm/analytics/_components';
import { staticStyles } from './style';

const AnalyticsPage = () => (
  <div className="AnalyticsPage">
    <Tabs externalClass="AnalyticsPage__tabs">
      <ClientsPage tabTextId="crmClients" tabSlug="crmClients" />
      <ManagersPage tabTextId="crmManagers" tabSlug="crmManagers" />
    </Tabs>
    <style jsx>{staticStyles}</style>
  </div>
);

export default connect()(AnalyticsPage);
