import React from 'react';
import { ManagersTable, ManagersActionsTab } from './_components';

import { staticStyles } from './style';

const ManagersPage = () => (
  <div>
    <ManagersActionsTab />
    <ManagersTable />
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

export default ManagersPage;
