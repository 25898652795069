import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserAmountEditHistory {
    :global(.Collapsible) {
      background: #ffffff;
      margin-bottom: 20px;

      :global(.ReactTable) {
        :global(.rt-thead.-header) {
          min-width: 600px !important;
          :global(.rt-tr) {
            margin: 0 28px;
            @include respond-to(md) {
              margin: 0 10px;
            }

            :global(.rt-th) {
              flex: 10 !important;

              &:last-child {
                flex: 50 !important;
              }
            }
          }
        }

        :global(.rt-tbody) {
          min-width: 600px !important;
          :global(.rt-tr-group) {
            margin: 0 0 2px 0;
            cursor: default;
            :global(.rt-tr) {
              padding: rem(10px 29px);
              @include respond-to(lg) {
                padding: rem(10px);
              }

              :global(.rt-td) {
                flex: 10 !important;

                &:last-child {
                  flex: 50 !important;
                }

                :global(.comment) {
                  color: #777777;
                  font-weight: 300;
                }

                :global(.time) {
                  display: block;
                  color: #777777;
                }
              }
            }
          }
        }

        :global(.rt-tfoot) {
          :global(.rt-tr) {
            justify-content: space-between;

            :global(.rt-td) {
              flex: 10 !important;
              max-width: 100px;
            }
          }
        }
      }

      :global(.Collapsible__caption) {
        line-height: 32px;
        font-size: 26px;
        font-weight: 300;

        @include respond-to(sm) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      :global(.Collapsible__header) {
        position: relative;
      }

      :global(.Collapsible__arrow) {
        position: absolute;
        right: ${isRTL ? 'auto' : '30px'};
        left: ${isRTL ? '30px' : 'auto'};
        top: 50%;
      }

      :global(.Collapsible__arrow-right) {
        transform: ${isRTL
          ? 'translateY(-50%) rotate(135deg) !important'
          : ' translateY(-50%) rotate(-45deg) !important'};
      }

      :global(.Collapsible__arrow-down) {
        top: 40%;
        transform: rotate(45deg) translateY(-50%) !important;
      }

      .UserAmountEditHistory__select {
        padding: 0 25px 5px 25px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .UserAmountEditHistory {
    }
  `;
};

export { staticStyles, getDynamicStyles };
