import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { BasicModal, DefaultButton, QueryBuilder } from 'common/components';
import { FormMessageHOC } from 'common/HOCs';
import ObjectSchemaComposer from '../ObjectSchemaComposer';

const regexForAccoutTypeEdit = /^\/admin\/PlatformsCategory\/PlatformAccountTypeResource\/\d+$/;

const fillEmptyField = (payload, query, value) => {
  const arrayRegex = /^([^[]+)\s*(\[\s*(\d+)\s*\]\s*)+\s*$/;
  const keys = query.split('.');
  const keyName = keys.pop();
  const nestedObject = keys.reduce((object, key) => {
    const match = key.match(arrayRegex);
    if (match) {
      const variableName = match[1];
      const index = match[3];
      return object[variableName][index];
    }
    return object[key];
  }, payload);
  nestedObject[keyName] = value;
};

const DynamicForm = ({
  dsl,
  submitting,
  userFilters,
  submitFunction,
  initQueryFilterOnUpdateAccountType,
  handleSubmit,
  dispatch,
  invalid,
  disabled,
  formError,
  submitFailed,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!isModalOpen);
  const [filters, setFilters] = useState(userFilters);

  useEffect(() => {
    if (submitFailed) setModalOpen(submitFailed);
  }, [submitFailed]);

  useEffect(() => {
    if (window.location.pathname === '/admin/PlatformsCategory/PlatformAccountTypeResource/create') {
      axios.get('/backoffice/filters/get_filters/?target=user').then(({ data }) => {
        setFilters(data);
      });
    }
  }, []);
  /* eslint-disable */
  const handleFormSubmit = handleSubmit(data => {
    const payload = data;

    //  We need to populate empty TinyMCE fields with default value
    try {
      const emptyTinyMCEFields = tinymce.editors.filter(editor => editor.getContent() === '');

      emptyTinyMCEFields.forEach(field => fillEmptyField(payload, field.id, ''));
    } catch (err) {}

    // Basically we check if null field exists and change his value
    const hiddenInputs = document.querySelectorAll('input[type="hidden"]');
    hiddenInputs.forEach(input => {
      if (input.id.includes('null')) {
        const name = input.id.substr(0, input.id.lastIndexOf('.')); // one.two.three -> one.two
        fillEmptyField(payload, name, { null: '' });
      }
    });

    // We need to populate empty CheckboxFields with default value
    const uncheckedCheckboxes = document.querySelectorAll('input[type="checkbox"]:not(:checked)');
    uncheckedCheckboxes.forEach(checkbox => {
      // check if object is object or null, set it to object if null
      fillEmptyField(payload, checkbox.name, false);
    });

    // We need to populate empty ArrayFields with an empty array
    const fieldArrays = Array.from(document.querySelectorAll('.FieldArray'));
    const emptyFieldArrays = fieldArrays.filter(fa => fa.querySelectorAll('.FieldArray__item').length === 0);

    emptyFieldArrays.forEach(array => fillEmptyField(payload, array.id, []));
    /* eslint-enable */
    function removeEmptyStringKeys(obj) {
      Object.keys(obj).forEach(key => {
        if (key === '') {
          delete obj[key];
        }
      });
      return obj;
    }
    return submitFunction(removeEmptyStringKeys(payload), dispatch);
  }, dispatch);

  return (
    <div className="DynamicForm">
      <form onSubmit={handleFormSubmit}>
        <ObjectSchemaComposer schema={dsl} formName="commonDynamicForm" submitting={submitting} />
        {filters &&
          (window.location.pathname === '/admin/PlatformsCategory/PlatformAccountTypeResource/create' ||
            regexForAccoutTypeEdit.test(window.location.pathname)) && (
            <div className="CreatePaymentSystemForm__query" style={{ marginBottom: '40px' }}>
              <Field
                component={QueryBuilder}
                name="filterQuery"
                textId="justFilterConditions"
                disabled={submitting}
                filters={filters}
                initialQuery={initQueryFilterOnUpdateAccountType}
                onChangeQuery={() => {}}
              />
              <style jsx global>
                {`
                  :global(.ruleGroup) {
                    margin-top: 0 !important;
                  }
                `}
              </style>
            </div>
          )}
        <div className="GenericForm__button-inner">
          <DefaultButton
            textId="doSave"
            type="submit"
            filled
            disabled={invalid || submitting || disabled}
            loading={submitting}
          />
        </div>
      </form>
      <BasicModal isOpen={isModalOpen} captionId="justError" description={formError} onRequestClose={toggleModal} />
    </div>
  );
};

DynamicForm.propTypes = {
  dsl: PropTypes.object.isRequired,
  submitFunction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  userFilters: PropTypes.object,
  formError: PropTypes.string,
  submitFailed: PropTypes.bool,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
};

DynamicForm.defaultProps = {
  userFilters: null,
  formError: '',
  submitFailed: false,
  disabled: false,
  submitting: false,
};

export default compose(
  FormMessageHOC,
  connect(state => ({
    formError: state.admin.dynamics.error,
  })),
  reduxForm({
    form: 'commonDynamicForm',
    onSubmitFail(result, dispatch, error, props) {
      props.showFormFailMessage(props.failMessageTextId);
    },
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageTextId);
      if (props.afterSubmitSuccess) {
        props.afterSubmitSuccess();
      }
    },
  })
)(DynamicForm);
export { DynamicForm };
