import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';

import { DateCell, ActionsButton } from 'common/components';
import { EditIcon, BasketIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import PaymentNoteDocuments from '../PaymentNoteDocuments';
import { staticStyles, getDynamicStyles } from './style';

const PaymentNote = ({
  noteId,
  created,
  createdBy,
  title,
  text,
  handleEditNote,
  fullAccess,
  handleDownloadNote,
  handleRemoveNoteDocument,
  handleDeleteNote,
  documents,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PaymentNote">
      <div className="PaymentNote__content">
        <div className="PaymentNote__top-line">
          <div className="PaymentNote__createdBy">
            <div className="PaymentNote__avatar">
              <Gravatar md5={md5(createdBy.email)} size={60} />
            </div>
            <div className="PaymentNote__line">
              <FormattedMessage id="justAuthor" />
              <p>{createdBy.email}</p>
            </div>
          </div>

          <div className="PaymentNote__line PaymentNote__title">
            <p>{title}</p>
          </div>

          <div className="PaymentNote__line PaymentNote__data-created">
            <DateCell value={created} />
          </div>
          <div className="PaymentNote__line PaymentNote__data-action">
            <ActionsButton
              tooltipId="Edit__tooltip"
              tooltipTextId="justEditNote"
              edit
              disabled={!fullAccess}
              onClickFunc={() => handleEditNote(noteId)}>
              <EditIcon />
            </ActionsButton>
            <ActionsButton
              tooltipId="Delete__tooltip"
              tooltipTextId="justDeleteNote"
              deleteType
              disabled={!fullAccess}
              onClickFunc={() => handleDeleteNote(noteId)}>
              <BasketIcon />
            </ActionsButton>
          </div>
        </div>
        <div className="PaymentNote__bottom-line">
          <FormattedMessage id="justText" />
          <pre>{text}</pre>
        </div>
        {!!documents.length && (
          <div className="UserNote__files">
            <FormattedMessage id="menuTitleFiles" />
            <PaymentNoteDocuments
              documents={documents}
              onDownload={handleDownloadNote}
              onRemove={handleRemoveNoteDocument}
            />
          </div>
        )}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

PaymentNote.propTypes = {
  noteId: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  handleDownloadNote: PropTypes.func.isRequired,
  handleRemoveNoteDocument: PropTypes.func.isRequired,
  fullAccess: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

PaymentNote.defaultProps = {
  theme: {},
};

export default withTheme()(PaymentNote);
export { PaymentNote };
