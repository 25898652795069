import React from 'react';

const DashboardIcon = () => (
  <div>
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="18" height="10" rx="1.5" />
      <rect x="0.5" y="12.5" width="8" height="6" rx="1" />
      <rect x="10.5" y="12.5" width="8" height="6" rx="1" />
    </svg>
  </div>
);

export default DashboardIcon;
