/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';

import { DefaultButton } from 'common/components';
import { staticStyles } from './style';
import { CheckDomainInstruction } from '..';

const InfoBlock = ({ wsStep }) => {
  if (wsStep === 1) {
    return (
      <div className="InfoBlock">
        <FM id="welcomeSetupRegistrationIntro1">{txt => <span className="InfoBlock__text">{txt}</span>}</FM>
        <FM id="welcomeSetupRegistrationIntro2">{txt => <span className="InfoBlock__text">{txt}</span>}</FM>
        <style jsx global>
          {staticStyles}
        </style>
      </div>
    );
  }
  if (wsStep === 2) {
    return (
      <div className="ConnectToMTInfoBlock">
        <div className="ConnectToMTInfoSubBlock">
          <div className="ConnectToMTInfoSubBlock__titles">
            <FM id="welcomeSetupWhatToChoose">
              {txt => <span className="ConnectToMTInfoSubBlock__title">{txt}</span>}
            </FM>
            <FM id="welcomeSetupWhiteLabelVsLicenseTitle">
              {txt => <span className="ConnectToMTInfoSubBlock__title">{txt}</span>}
            </FM>
          </div>
          <DefaultButton
            onClick={() =>
              window.open('https://uptrader.io/en/news/75/what-choose-white-label-mt-4-5-vs-metatrader-4-5-f')
            }
            textId="justRead"
            type="button"
            ghost
            isUpperCase
          />
        </div>
        <div className="ConnectToMTInfoSubBlock">
          <div className="ConnectToMTInfoSubBlock__titles">
            <FM id="welcomeSetupHowToConnectToMt">
              {txt => <span className="ConnectToMTInfoSubBlock__title">{txt}</span>}
            </FM>
          </div>
          <DefaultButton
            onClick={() => window.open('https://uptrader.io/en/mt45')}
            textId="justRead"
            type="button"
            ghost
            isUpperCase
          />
        </div>
        <style jsx global>
          {staticStyles}
        </style>
      </div>
    );
  }
  if (wsStep === 3 || wsStep === 4) {
    return <CheckDomainInstruction isActive />;
  }
  if (wsStep === 5) {
    return (
      <div className="InfoBlock">
        <FM id="welcomeSetupSetUpCRMTextTitle1">{txt => <span>{txt}</span>}</FM>
        <FM id="welcomeSetupSetUpCRMTextTitle2">{txt => <span>{txt}</span>}</FM>
        <FM id="welcomeSetupSetUpCRMTextDescription">{txt => <span>{txt}</span>}</FM>
        <style jsx global>
          {staticStyles}
        </style>
      </div>
    );
  }
  return <div className="InfoBlock">forNowEmpty</div>;
};
InfoBlock.propTypes = {
  wsStep: PropTypes.number.isRequired,
};

export default InfoBlock;
