import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { DefaultButton, ReduxTextField, SwitchField } from 'common/components';
import { numericality, required } from 'redux-form-validators';

import { withdrawUserBonus } from '../../_redux';
import { staticStyles } from './style';

const UserBonusWithdrawForm = ({
  userId,
  bonus,
  chargeValue,
  isChargedAll,
  showWithdrawApproveModal,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  onSubmitSuccess,
  error,
}) => {
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    setIsApproved(showWithdrawApproveModal);
  }, [showWithdrawApproveModal]);

  const submit = handleSubmit(values =>
    withdrawUserBonus(
      {
        ...values,
        userId,
        bonusId: bonus.id,
        chargeValue: isChargedAll ? bonus.value.amount : chargeValue,
        approved: showWithdrawApproveModal,
      },
      dispatch
    )
  );
  return (
    <form className="UserBonusWithdrawForm" onSubmit={submit}>
      {!isApproved ? (
        <>
          <Field name="bonus" type="text" textId="justBonus" component={ReduxTextField} disabled />
          <Field component={ReduxTextField} type="text" name="account" textId="justAccount" disabled />
          <Field
            component={ReduxTextField}
            type="number"
            name="chargeValue"
            textId="justAmountOfCharge"
            disabled={submitting || isChargedAll}
            validate={[required(), numericality({ '>': 0, '<=': bonus.value.amount })]}
            description="USD"
          />
          <div className="UserBonusWithdrawForm__line">
            <FormattedMessage id="justChargeAll">{txt => <span>{txt}</span>}</FormattedMessage>
            <Field component={SwitchField} name="chargeAll" type="checkbox" disabled={submitting} withText />
          </div>
          <DefaultButton
            textId="justWithdraw"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
        </>
      ) : (
        <div className="UserBonusWithdrawForm__approve">
          <FormattedMessage id="withdrawApprovedModalText" />
          <div className="UserBonusWithdrawForm__btns">
            <DefaultButton
              textId="justYes"
              type="button"
              onClick={() => {
                setIsApproved(false);
                submit();
              }}
              filled
            />
            <DefaultButton textId="justNo" type="button" onClick={() => onSubmitSuccess()} />
          </div>
        </div>
      )}

      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserBonusWithdrawForm.propTypes = {
  userId: PropTypes.string.isRequired,
  chargeValue: PropTypes.string,
  bonus: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isChargedAll: PropTypes.bool,
  showWithdrawApproveModal: PropTypes.bool.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UserBonusWithdrawForm.defaultProps = {
  isChargedAll: true,
  chargeValue: '',
  error: '',
};
const selector = formValueSelector('user-bonus-withdraw-form');

export default compose(
  connect(state => ({
    showWithdrawApproveModal: state.backoffice.users.showWithdrawApproveModal,
    isChargedAll: selector(state, 'chargeAll'),
    chargeValue: selector(state, 'chargeValue'),
  })),
  reduxForm({
    form: 'user-bonus-withdraw-form',
  })
)(UserBonusWithdrawForm);

export { UserBonusWithdrawForm };
