import axios from 'axios';

export const getTemplateList = search =>
  axios.get(`/backoffice/templates/`, {
    params: { ...search },
  });

export const getTemplatesListWithSearch = search =>
  axios.get(`/backoffice/templates/`, {
    params: { search },
  });
export const createTemplate = data => axios.post(`/backoffice/templates/create/`, { ...data });

export const getTemplateItem = id => axios.get(`/backoffice/templates/${id}/`);

export const editTemplate = (id, data) => axios.patch(`/backoffice/templates/${id}/`, { ...data });

export const deleteTemplate = id => axios.delete(`/backoffice/templates/${id}/`);
