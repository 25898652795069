import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabLogs {
    .text-row {
      height: 100px !important;
      margin-bottom: 10px;
    }
    &__no-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin: 0 0 10px 0;
      }

      span {
        color: #777777;
      }
    }
    &__list {
    }

    &__item {
      position: relative;
      margin: 0 0 24px 0;
      padding-bottom: 24px;

      &:not(&:last-child)::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, #f3f3f3 48.44%, rgba(243, 243, 243, 0) 100%);
      }

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__item-wrap {
      display: flex;
      align-items: center;
    }

    &__item-icon {
      background: #fff;
      width: 48px;
      min-width: 48px;
      height: 48px;
      min-height: 48px;
      border-radius: 50%;
      border: 2px solid #f3f3f3;
      margin: 0 24px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__item-subwrap {
      display: flex;
      align-items: center;
      width: 100%;

      @include respond-to(lg) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__item-info-wrap {
    }

    &__item-title {
      margin-bottom: 4px;
      font-size: 10px;
      line-height: 1.4;
      color: #0aa1e2;
    }

    &__item-description {
      font-size: 14px;
      line-height: 1.4;

      color: #333;
    }

    &__item-action {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;

      @include respond-to(lg) {
        order: 3;
      }
    }

    &__item-date {
      font-size: 12px;
      line-height: 1.4;
      color: rgba(0, 0, 0, 0.5) !important;
      margin: 0 0 0 auto;
      text-align: right;

      .DateCell {
        display: flex;
        gap: 4px;

        &__time {
          font-weight: 400 !important;
        }
      }
    }

    &__item-log-data {
      padding: 0 0 10px 38px;
      margin: 20px 0 0 0;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #343846;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #d6dbe0;
      }

      .TabLogs__item[data-visible='hide'] & {
        display: none;
      }
    }

    &__item-log-data-td-key {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }

    &__item-log-data-td-value {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }

    &__pagination {
      margin: 20px 0 0 0;
    }
  }
`;

export { staticStyles };
