import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';
import { updateItemInArray } from '../../_redux/commonReducer';

export const initialState = {
  partnershipLinks: [],

  partnershipInfoIsLoaded: false,

  partnershipClients: {},
  partnershipClientsAreLoaded: false,

  partnershipClientsBalance: {
    balanceWithout: null,
    balanceWith: null,
    clientsWithout: null,
    clientsWith: null,
  },
  partnershipClientsBalanceIsLoaded: false,

  partnershipClientAccounts: [],
  partnershipClientAccountsIsLoaded: false,

  currentIBPrograms: [],
  currentIBProgramsIsLoaded: false,

  simpleIBSystemSettings: {},
  simpleIBSystemSettingsAreLoaded: false,

  partnershipMaterials: [],
  partnershipMaterialsIsLoaded: false,

  partnershipWithdrawalAccounts: [],
  partnershipWithdrawalAccountsAreLoaded: false,

  campaigns: [],
  campaignsAreLoaded: false,

  rotatorLink: '',
  rotatorLinkIsLoaded: false,

  bannerLink: '',
  bannerLinkIsLoaded: false,

  requestEasyStart: false,
  requestEasyStartIsSending: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PARTNERSHIP_INFO[REQUEST]:
      return { ...state, partnershipInfoIsLoaded: false };
    case ActionTypes.GET_PARTNERSHIP_INFO[SUCCESS]:
      return {
        ...state,
        partnershipLinks: action.data,
        partnershipInfoIsLoaded: true,
      };
    case ActionTypes.GET_PARTNERSHIP_INFO[FAILURE]:
      return { ...state, error: action.error, partnershipInfoIsLoaded: false };

    case ActionTypes.GET_PARTNERSHIP_CLIENTS_BALANCE[REQUEST]:
      return { ...state, partnershipClientsBalanceIsLoaded: false };
    case ActionTypes.GET_PARTNERSHIP_CLIENTS_BALANCE[SUCCESS]:
      return {
        ...state,
        partnershipClientsBalance: action.data,
        partnershipClientsBalanceIsLoaded: true,
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENTS_BALANCE[FAILURE]:
      return {
        ...state,
        partnershipClientsBalanceIsLoaded: false,
      };

    case ActionTypes.GET_PARTNERSHIP_CLIENTS[REQUEST]:
      return { ...state, partnershipClientsAreLoaded: false };
    case ActionTypes.GET_PARTNERSHIP_CLIENTS[SUCCESS]:
      return {
        ...state,
        partnershipClients: action.data,
        partnershipClientsAreLoaded: true,
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENTS[FAILURE]:
      return {
        ...state,
        error: action.error,
        partnershipClientsAreLoaded: false,
      };

    case ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNTS[REQUEST]:
      return {
        ...state,
        partnershipClientLoadingAccounts: true,
        partnershipClientAccountsIsLoaded: false,
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNTS[SUCCESS]: {
      const partnershipClientAccounts = action.accounts.map(actionAccount => ({
        ...actionAccount,
        loading: true,
        loadingFailed: false,
      }));
      return {
        ...state,
        partnershipClientLoadingAccounts: false,
        partnershipClientAccounts,
        partnershipClientAccountsIsLoaded: true,
      };
    }
    case ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNTS[FAILURE]:
      return {
        ...state,
        partnershipClientLoadingAccounts: false,
        partnershipClientAccountsIsLoaded: true,
      };

    case ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNT[REQUEST]:
      return {
        ...state,
        partnershipClientAccounts: updateItemInArray(state.partnershipClientAccounts, action.accountId, item => ({
          ...item,
          loading: true,
          loadingFailed: false,
        })),
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNT[SUCCESS]:
      return {
        ...state,
        partnershipClientAccounts: updateItemInArray(state.partnershipClientAccounts, action.data.id, item => ({
          ...item,
          ...action.data,
          loading: false,
        })),
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNT[FAILURE]:
      return {
        ...state,
        partnershipClientAccounts: updateItemInArray(state.partnershipClientAccounts, action.accountId, item => ({
          ...item,
          ...action.data,
          loading: false,
          loadingFailed: true,
        })),
      };

    case ActionTypes.GET_PARTNERSHIP_CLIENT_TRADES[REQUEST]:
      return {
        ...state,
        partnershipClientAccounts: updateItemInArray(state.partnershipClientAccounts, action.accountId, item => ({
          ...item,
          operations: { ...item.operations, loading: true },
        })),
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENT_TRADES[SUCCESS]:
      return {
        ...state,
        partnershipClientAccounts: updateItemInArray(state.partnershipClientAccounts, action.accountId, item => ({
          ...item,
          operations: {
            ...item.operations,
            loading: false,
            [action.status]: action.data,
          },
        })),
      };
    case ActionTypes.GET_PARTNERSHIP_CLIENT_TRADES[FAILURE]:
      return {
        ...state,
        partnershipClientAccounts: updateItemInArray(state.partnershipClientAccounts, action.accountId, item => ({
          ...item,
          operations: { ...item.operations, loading: false },
        })),
      };

    case ActionTypes.GET_CURRENT_IB_PROGRAMS[REQUEST]:
      return { ...state, currentIBProgramsIsLoaded: false };
    case ActionTypes.GET_CURRENT_IB_PROGRAMS[SUCCESS]:
      return {
        ...state,
        currentIBPrograms: action.data,
        currentIBProgramsIsLoaded: true,
      };
    case ActionTypes.GET_CURRENT_IB_PROGRAMS[FAILURE]:
      return { ...state, error: action.error, currentIBProgramsIsLoaded: false };

    case ActionTypes.GET_SIMPLE_IB_SYSTEM_SETTINGS[REQUEST]:
      return { ...state, simpleIBSystemSettingsAreLoaded: false };
    case ActionTypes.GET_SIMPLE_IB_SYSTEM_SETTINGS[SUCCESS]:
      return {
        ...state,
        simpleIBSystemSettings: action.data,
        simpleIBSystemSettingsAreLoaded: true,
      };
    case ActionTypes.GET_SIMPLE_IB_SYSTEM_SETTINGS[FAILURE]:
      return { ...state, error: action.error, simpleIBSystemSettingsAreLoaded: false };

    case ActionTypes.GET_PARTNERSHIP_MATERIALS[REQUEST]:
      return { ...state, partnershipMaterialsIsLoaded: false };
    case ActionTypes.GET_PARTNERSHIP_MATERIALS[SUCCESS]:
      return {
        ...state,
        partnershipMaterials: action.data,
        partnershipMaterialsIsLoaded: true,
      };
    case ActionTypes.GET_PARTNERSHIP_MATERIALS[FAILURE]:
      return { ...state, error: action.error, partnershipMaterialsIsLoaded: false };

    case ActionTypes.GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[REQUEST]:
      return { ...state, partnershipWithdrawalAccountsAreLoaded: false };
    case ActionTypes.GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[SUCCESS]:
      return {
        ...state,
        partnershipWithdrawalAccounts: action.data,
        partnershipWithdrawalAccountsAreLoaded: true,
      };
    case ActionTypes.GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[FAILURE]:
      return { ...state, error: action.error, partnershipWithdrawalAccountsAreLoaded: false };

    case ActionTypes.GET_CAMPAIGNS[REQUEST]:
      return { ...state, campaignsAreLoaded: false };
    case ActionTypes.GET_CAMPAIGNS[SUCCESS]:
      return { ...state, campaigns: action.data, campaignsAreLoaded: true };
    case ActionTypes.GET_CAMPAIGNS[FAILURE]:
      return { ...state, error: action.error, campaignsAreLoaded: false };

    case ActionTypes.GET_ROTATOR_LINK[REQUEST]:
      return { ...state, rotatorLinkIsLoaded: false, rotatorLink: '' };
    case ActionTypes.GET_ROTATOR_LINK[SUCCESS]:
      return { ...state, rotatorLink: action.data, rotatorLinkIsLoaded: true };
    case ActionTypes.GET_ROTATOR_LINK[FAILURE]:
      return { ...state, error: action.error, rotatorLinkIsLoaded: false, rotatorLink: '' };

    case ActionTypes.GET_BANNER_LINK[REQUEST]:
      return { ...state, bannerLinkIsLoaded: false, bannerLink: '' };
    case ActionTypes.GET_BANNER_LINK[SUCCESS]:
      return { ...state, bannerLink: action.data, bannerLinkIsLoaded: true };
    case ActionTypes.GET_BANNER_LINK[FAILURE]:
      return { ...state, error: action.error, bannerLinkIsLoaded: false, bannerLink: '' };

    case ActionTypes.REQUEST_EASY_START[REQUEST]:
      return { ...state, requestEasyStartIsSending: true, bannerLink: '' };
    case ActionTypes.REQUEST_EASY_START[SUCCESS]:
      return { ...state, requestEasyStart: true, requestEasyStartIsSending: false };
    case ActionTypes.REQUEST_EASY_START[FAILURE]:
      return { ...state, error: action.error, requestEasyStartIsSending: false };

    default:
      return state;
  }
};

export default reducer;
