/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';
import css from 'styled-jsx/css';

const staticStyles = css`
  .InnerTabs {
    display: flex;
    margin-top: 20px;
    gap: 24px;
  }

  .InnerTabs__Tab {
    display: flex;
    cursor: pointer;
    gap: 8px;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 12px 32px;
    border: 2px solid #e6f6fc;
    color: gray;

    /* body/16/medium */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  .InnerTabs__Tab--Selected {
    background: #e6f6fc;
    color: var(--black-1, #333);
  }

  .InnerTabs__Count {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff !important;

    /* body/12/medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
    border-radius: 32px;
    opacity: 0.25;
    background: var(--black-1, #333);
  }

  .InnerTabs__Count--Selected {
    border-radius: 32px;
    opacity: 1;
    background: #0aa1e2;
  }
`;

const InnerTabs = ({ currentTab, openCount, closedCount, setCurrentInnerTab }) => (
  <div className="InnerTabs">
    <div
      onClick={() => setCurrentInnerTab('open')}
      className={classNames('InnerTabs__Tab', { 'InnerTabs__Tab--Selected': currentTab === 'open' })}>
      <FM id="justOpen" />
      <div className={classNames('InnerTabs__Count', { 'InnerTabs__Count--Selected': currentTab === 'open' })}>
        {openCount}
      </div>
    </div>
    <div
      onClick={() => setCurrentInnerTab('closed')}
      className={classNames('InnerTabs__Tab', { 'InnerTabs__Tab--Selected': currentTab === 'closed' })}>
      <FM id="justClosed" />
      <div className={classNames('InnerTabs__Count', { 'InnerTabs__Count--Selected': currentTab === 'closed' })}>
        {closedCount}
      </div>
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default InnerTabs;
