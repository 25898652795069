import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, ReduxSelectField } from 'common/components';

import { changeIssueLanguage } from '../../_redux';

const IssueChangeLanguageForm = ({ issueId, handleSubmit, pristine, submitting, dispatch, invalid, error }) => {
  const [supportAgentLanguageOptions, setSupportAgentLanguageOptions] = useState([]);
  const submit = handleSubmit(values => changeIssueLanguage({ ...values, issueId }, dispatch));

  useEffect(() => {
    axios.get('/support/languages/').then(({ data: { languages } }) => {
      setSupportAgentLanguageOptions(languages);
    });
  }, []);

  return (
    <form onSubmit={submit}>
      <div>
        <Field
          component={ReduxSelectField}
          options={supportAgentLanguageOptions}
          name="language"
          textId="createIssueModalSupportAgentLanguage"
          searchable
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <DefaultButton
        textId="justSubmit"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong>{error}</strong>}
    </form>
  );
};

IssueChangeLanguageForm.propTypes = {
  issueId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

IssueChangeLanguageForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'IssueChangeLanguageForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.onRequestClose();
  },
})(IssueChangeLanguageForm);
export { IssueChangeLanguageForm };
