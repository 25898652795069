import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
    }

    &__left-prev {
      width: 16px;
      height: 28px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &[disabled] {
        opacity: 0.3;
        cursor: default;
      }
    }

    &__left-pages {
      display: flex;
      border: 1px solid #0aa1e2;
      border-radius: 3px;
      height: 28px;
      align-items: center;
    }

    &__left-pages-number {
      padding: 0 0 0 0;
      height: 28px;
      width: 30px;
      text-align: center;
      border: none;
      outline: none;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }

    &__left-pages-count {
      position: relative;
      width: 30px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #e9e9e9;
      user-select: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        height: 18px;
        border-left: 1px solid #0aa1e2;
      }
    }

    &__left-next {
      width: 16px;
      height: 28px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      &[disabled] {
        opacity: 0.3;
        cursor: default;
      }
    }

    &__right {
      display: flex;
      margin: 0 0 0 auto;
    }

    &__right-count {
      width: 30px;
      height: 28px;
      border: 1px solid #f8faff;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 6px 0 0;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
      user-select: none;
      color: #808080;

      &:last-child {
        margin: 0 0 0 0;
      }

      &--active {
        border: 1px solid #0aa1e2;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
