import React from 'react';
import PropTypes from 'prop-types';

import { googleTrackPage, yandexTrackPage } from '../utils';

const WithTrackerHOC = (WrappedComponent, options = {}) => {
  // eslint-disable-next-line
  const HOC = class extends React.Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      googleTrackPage(page, options);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        googleTrackPage(nextPage, options);
        yandexTrackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  HOC.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return HOC;
};

export default WithTrackerHOC;
