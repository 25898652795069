import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { withTheme } from 'common/styling/theme';

// import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { createCrmTask } from 'crm/tasks/_redux/';
import { FormMessageHOC } from 'common/HOCs';

import { BaseButton } from 'crm/_components';
import { SelectFormField, TextFormField, DateFormField } from 'crm/_components/forms/';

import { TYPES, PLATFORMS } from 'crm/constansts';

import { getManagerListForTask } from 'crm/users/api';

import { staticStyles, getDynamicStyles } from './style';

const CreateTaskForm = ({
  type,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  error,
  match,
  intl,
  theme,
  defaultAssignTo,
}) => {
  const { id: contactId } = useParams();
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [managersOptions, setManagersOptions] = useState([]);

  // useEffect(() => {
  //   dispatch(change('create-task-form', 'assignedTo', defaultAssignTo));
  // }, []);

  useEffect(() => {
    getManagerListForTask(contactId)
      .then(({ data }) =>
        setManagersOptions(
          data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
        )
      )
      .catch(() => console.log('ERROR'));
  }, []);
  const submit = handleSubmit(values => {
    let startDate;
    let endDate;
    if (values.dateRange) {
      startDate = values.dateRange.split('_')[0];
      endDate = values.dateRange.split('_')[1];
    }

    let assignedTo;

    if (typeof values.assignedTo === 'object') {
      assignedTo = values.assignedTo.value;
    } else {
      assignedTo = values.assignedTo;
    }

    const data = {
      ...values,
      icon: type,
      assignedTo,
      type,
      subject: match.params.id,
      dateRange: undefined,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      currentPage: 'contactPage',
    };
    return createCrmTask(data, dispatch);
  });

  const yearsOptions = [...Array(10).keys()].map(i => ({
    value: moment().year() + i,
    label: moment().year() + i,
  }));

  const platformOptions = [
    { value: PLATFORMS.TELEGRAM, label: 'Telegram' },
    { value: PLATFORMS.VIBER, label: 'Viber' },
    { value: PLATFORMS.MESSENGER, label: 'Messenger' },
    { value: PLATFORMS.WHATSAPP, label: 'Whatsapp' },
    { value: PLATFORMS.WECHAT, label: 'WeChat' },
    { value: PLATFORMS.EMAIL, label: 'Email' },
  ];

  return (
    <form className="CreateTaskForm" onSubmit={submit}>
      <div className="CreateTaskForm__line">
        <Field labelTextId="justTitle" component={TextFormField} validate={[required()]} name="title" />
      </div>
      <div className="CreateTaskForm__line">
        <Field labelTextId="justDescription" component={TextFormField} validate={[required()]} name="description" />
      </div>
      <div className="CreateTaskForm__line">
        <Field
          placeholder={defaultAssignTo.label}
          labelTextId="crmJustAssignToManager"
          component={SelectFormField}
          initValue={defaultAssignTo}
          options={managersOptions}
          name="assignedTo"
          validate={[required()]}
          disabled={submitting}
          maxMenuHeight={200}
        />
      </div>
      {type === TYPES.MAIL && (
        <div className="CreateTaskForm__line">
          <Field
            label={intl.formatMessage({ id: 'crmChoosePlatform' })}
            labelTextId="justPlatform"
            component={SelectFormField}
            options={platformOptions}
            validate={[required()]}
            name="platform"
          />
        </div>
      )}
      <div className="CreateTaskForm__line">
        <Field
          component={DateFormField}
          type="date"
          name="expirationDate"
          label={intl.formatMessage({ id: 'crmDueDate' })}
          placeholder="dd.mm.yyyy"
          disabled={submitting}
          validate={[required()]}
          yearsOptions={yearsOptions}
          areFutureDaysAvailable
          withTime
        />
      </div>
      {error && <strong className="CreateTaskForm__formError">{error}</strong>}
      <div className="CreateTaskForm__submit">
        <BaseButton type="submit" textId="justCreateTask" disabled={invalid || submitting} />
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

CreateTaskForm.propTypes = {
  type: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
  error: PropTypes.string,
};

CreateTaskForm.defaultProps = {
  theme: {},
  error: '',
};

export default compose(
  injectIntl,
  withTheme(),
  FormMessageHOC,
  withRouter,
  connect(state => ({ profile: state.user.profile })),
  reduxForm({
    form: 'create-task-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage(props.successMessageId);
      props.onRequestClose();
    },
  })
)(CreateTaskForm);
