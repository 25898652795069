import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { DefaultButton, BasicModal } from 'common/components';

import { CreateInvestStrategyFormNew } from 'client/accounts/_components';
import InvestInvesmentsNew from '../InvestInvesmentsNew';
import InvestStrategiesNew from '../InvestStrategiesNew';

import { staticStyles } from './style';

const InvestBlockNew = () => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleAddStrategy = () => {
    toggleModal();
  };

  const handleShowRating = () => {
    history.push('/investnew/');
  };

  return (
    <div className="InvestBlock">
      <div className="InvestBlock__head">
        <FormattedMessage id="investAccountsNew">{txt => <h3>{txt}</h3>}</FormattedMessage>

        <div className="InvestBlock__btns">
          <DefaultButton textId="rammShowRating" onClick={handleShowRating} filled />
          <DefaultButton textId="rammBecomeAManager" onClick={handleAddStrategy} plus />
        </div>
      </div>

      <div className="InvestBlock__body">
        <InvestInvesmentsNew />
        <InvestStrategiesNew />
        <BasicModal isOpen={isOpen} captionId="justCreateStrategy" onRequestClose={toggleModal}>
          <CreateInvestStrategyFormNew toggleModal={toggleModal} />
        </BasicModal>
      </div>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default InvestBlockNew;
