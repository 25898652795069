import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AccountsForm {
    &__add-account {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 16px 0 24px 0;
      > div {
        position: absolute;
        top: 50%;
        height: 1px;
        background: #0aa1e2;
        width: 100%;
      }
      > .DefaultButton {
        padding: 0 10px 0 40px;
        background: white !important;
      }
    }
    &__Error {
      color: red;
      margin-bottom: 12px;
    }
    .AccountForm {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 36px;
      &__remove-item {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 24px;
        > div {
          width: 100%;
          background: #bfc7d7;
          height: 1px;
        }
      }

      &__title {
        font-size: 18px;
        line-height: 24px;
      }

      > div {
        margin: 0;
      }

      > .CheckboxField {
        margin: 8px 0 !important;
      }
      > .AccountWaitTimeText {
        color: #5d647d;
      }
    }
  }
`;

export { staticStyles };
