import css from 'styled-jsx/css';

const staticStyles = css.global`
  .CustomFilterInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__Filter {
      font-size: 16px;

      &-title {
        font-weight: 500;
      }

      &-value {
      }
    }

    &__Modal {
      max-width: 600px !important;
      outline: none;
    }
  }
`;

export { staticStyles };
