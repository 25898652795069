import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DefaultButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { getDynamicStyles } from './style';

const NoAccountsPanel = ({ captionId, buttonId, onAddAccountClick, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <>
      <div className="NoAccountsPanel__inner">
        <FormattedMessage id={captionId}>{txt => <h3>{txt}</h3>}</FormattedMessage>
        {onAddAccountClick && <DefaultButton textId={buttonId} onClick={onAddAccountClick} plus />}
      </div>
      <style jsx>{dynamicStyles}</style>
    </>
  );
};

NoAccountsPanel.propTypes = {
  captionId: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  onAddAccountClick: PropTypes.func,
  theme: PropTypes.object,
};

NoAccountsPanel.defaultProps = {
  onAddAccountClick: null,
  theme: {},
};

export default withTheme()(NoAccountsPanel);
export { NoAccountsPanel };
