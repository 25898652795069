import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .PaymentsTip {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: rem(20px);
    position: relative;
    line-height: 1.5;

    @include respond-to(sm) {
      padding: rem(15px);
    }

    .PaymentsTip__caption {
      font-size: rem(18px);
      font-weight: 500;
      margin-bottom: rem(25px);
    }

    .PaymentsTip__text {
      font-size: rem(14px);
      margin-bottom: rem(15px);
    }

    .PaymentsTip__list {
      display: flex;
      flex-direction: column;
      margin-left: rem(10px);
      margin-bottom: rem(15px);

      :global(> span) {
        text-align: left;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PaymentsTip {
      color: ${colors.black.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
