import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .FormSchemaComposer__inner {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    padding: 25px 30px 1px;
    background: transparent;
    @include respond-to(sm) {
      padding: 20px 15px 1px;
    }
    :global(p) {
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
    :global(.CheckboxField) {
      margin-top: 10px;
    }
  }

  :global(.DynamicForm) {
    .FormSchemaComposer__inner {
      background: #fff;
    }
  }
`;

export { staticStyles };
