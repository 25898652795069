import React from 'react';

import DefaultAuthPage from '../../_components/DefaultAuthPage';
import ConfirmResetPasswordForm from '../_components/ConfirmResetPasswordForm';

const ConfirmResetPasswordPage = () => (
  <DefaultAuthPage captionId="authConfirmResetPasswordDescr">
    <ConfirmResetPasswordForm />
  </DefaultAuthPage>
);

export default ConfirmResetPasswordPage;
