import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import EditTaskForm from 'crm/tasks/_components/EditTaskForm';
import CloseTaskForm from 'crm/tasks/_components/CloseTaskForm';
import { FormattedMessage as FM } from 'react-intl';
import { compose } from 'redux';
import { DateCell, MoneyCell, PageContentPreloader, ActionsButton } from 'common/components';
import PropTypes from 'prop-types';
import { NewCRMPagination, UpdatedCRMTable } from 'crm/_newComponents';
import BasicCRMModal from 'crm/_components/BasicCRMModal';
import { CheckedIcon, EditTaskIcon } from 'crm/_newComponents/Icons';
import { staticStyles } from './style';

import TaskFullNameCell from '../TaskFullNameCell';

const TasksTable = ({ tasks, tasksAreLoaded }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCLoseOpen, setIsCloseOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const tableSetup = {
    columns: [
      {
        accessor: 'fullName',
        Header: 'crmClientName',
        isSortable: true,
        Cell: ({ rowData: { subject, fullName, type, isOverdue, status } }) => (
          <TaskFullNameCell subjectId={subject} fullName={fullName} type={type} status={status} isOverdue={isOverdue} />
        ),
      },
      {
        accessor: 'title',
        isSortable: true,
        Header: 'justTitle',
        Cell: ({ rowData: { title } }) => title ?? '---',
      },

      {
        accessor: 'assignedTo',
        isSortable: true,
        Header: 'crmAssignedTo',
        removeInMobile: true,
        Cell: ({ rowData: { assignedTo } }) => assignedTo.fullName,
      },
      {
        accessor: 'createdBy',
        isSortable: true,
        Header: 'crmWasCreatedBy',
        removeInMobile: true,
        Cell: ({ rowData: { createdBy } }) => (createdBy ? createdBy.fullName : '---'),
      },
      {
        accessor: 'expirationDate',
        isSortable: true,
        Header: 'crmExpirationDate',
        removeInMobile: true,
        Cell: ({ rowData: { expirationDate, isOverdue } }) =>
          expirationDate ? <DateCell value={expirationDate} red={isOverdue} /> : '---',
      },
      {
        accessor: 'creationDate',
        isSortable: true,
        Header: 'crmCreationDate',
        removeInMobile: true,
        Cell: ({ rowData: { created } }) => (created ? <DateCell value={created} /> : '---'),
      },
      {
        Header: () => {},
        isSortable: false,
        onTopInMobile: true,
        Cell: ({ rowData }) => (
          <div className="actions">
            {rowData.status !== 'CLOSED' && (
              <ActionsButton
                onClickFunc={() => {
                  setSelectedTask(rowData);
                  setIsCloseOpen(true);
                }}>
                <CheckedIcon />
              </ActionsButton>
            )}

            <ActionsButton
              onClickFunc={() => {
                setSelectedTask(rowData);
                setIsOpen(true);
              }}>
              <EditTaskIcon />
            </ActionsButton>
          </div>
        ),
      },
    ],
    initSubRow: [
      {
        Header: 'justAbout',
        Cell: ({ rowData: { description } }) => description ?? '',
      },
      {
        Header: 'justBalance',
        Cell: ({ rowData: { balance } }) => <MoneyCell crmStyle heightMaxContent value={balance} />,
      },
      {
        Header: ({ rowData: { comment } }) => (comment ? <FM id="justComment" /> : null),
        Cell: ({ rowData: { comment } }) => comment ?? '',
        isDisabled: ({ rowData: { comment } }) => !comment,
      },
    ],
  };
  /* eslint-enable */

  return (
    <div className="TasksTable">
      <PageContentPreloader ready={tasksAreLoaded} firstLaunchOnly type="accounts">
        <UpdatedCRMTable data={tasks?.results} tableSetup={tableSetup} whereOpenSubRowIconIndex={0} />
        {!!tasks?.count && <NewCRMPagination count={tasks?.count} />}
        <BasicCRMModal
          captionId="crmEditTask"
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
            setSelectedTask(null);
          }}>
          <EditTaskForm
            onRequestClose={() => {
              setIsOpen(false);
              setSelectedTask(null);
            }}
            initialValues={{
              ...selectedTask,
              assignedTo: { value: selectedTask?.assignedTo?.id, label: selectedTask?.assignedTo?.fullName },
            }}
            {...selectedTask}
          />
        </BasicCRMModal>
        <BasicCRMModal
          captionId="crmCloseTask"
          isOpen={isCLoseOpen}
          onRequestClose={() => {
            setIsCloseOpen(false);
            setSelectedTask(null);
          }}>
          <CloseTaskForm
            onRequestClose={() => {
              setIsCloseOpen(false);
              setSelectedTask(null);
            }}
            {...selectedTask}
          />
        </BasicCRMModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TasksTable.propTypes = {
  tasks: PropTypes.object.isRequired,
  tasksAreLoaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(withRouter)(TasksTable);
