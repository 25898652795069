import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { rejectError } from 'common/_redux';

import BasicModal from '../modals/BasicModal';
import DefaultButton from '../DefaultButton';

const ErrorHandler = ({ children, rejectError, hasErrors, redirect, errorMessage }) => {
  const history = useHistory();
  return (
    <>
      {children}
      <BasicModal
        onRequestClose={rejectError}
        isOpen={hasErrors}
        captionId={typeof errorMessage === 'string' ? 'commonErrorModalTitle' : 'somethingWentWrongCaption'}
        description={typeof errorMessage === 'string' ? errorMessage : ''}
        descriptionId="somethingWentWrongDescription"
        overlayClassName="Modal_error-overlay">
        <DefaultButton
          textId="justClose"
          onClick={() => {
            rejectError();
            if (redirect) {
              history.push(redirect);
            }
          }}
        />
      </BasicModal>
    </>
  );
};

ErrorHandler.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  rejectError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  redirect: PropTypes.string,
  errorMessage: PropTypes.string,
};

ErrorHandler.defaultProps = {
  redirect: null,
  errorMessage: '',
};

export default connect(({ common: { errorMessage, hasErrors, redirect } }) => ({ errorMessage, hasErrors, redirect }), {
  rejectError,
})(ErrorHandler);
export { ErrorHandler };
