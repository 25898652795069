import css from 'styled-jsx/css';
import { rgba, gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .WithdrawRequestSteps {
    background: #ffffff;
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);

    &__title {
      padding: rem(25px 30px 25px);
      line-height: 32px;
      font-size: 26px;
      font-weight: 300;

      @include respond-to(sm) {
        padding: rem(20px 15px 15px);
      }
    }

    &__wrapper {
      padding: rem(25px 30px 25px);

      @include respond-to(sm) {
        padding: rem(20px 15px 15px);
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 15px 10px;

      @include respond-to(sm) {
        flex-direction: column;
      }
    }

    &__item {
      width: 100%;
      margin-right: 20px;

      @include respond-to(sm) {
        margin-bottom: 20px;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__header {
      font-weight: 500;
      font-size: rem(20px);
      padding: rem(15px);
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
      border-radius: 3px;
      margin-bottom: 3px;
    }

    &__btns {
      display: flex;
      align-items: flex-start;

      button {
        min-width: 200px;
        flex: 50%;
      }

      @include respond-to(sm) {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    &__status {
      display: block;
      width: 100%;
      text-align: left;
      padding: rem(17px 35px);
      border-radius: 3px;
      position: relative;

      &:after {
        content: '';
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        left: 15px;
      }
    }

    &__auditors,
    &__departments {
      display: block;
      margin-top: 10px;
      color: #000;
    }

    &__reasons {
      margin-top: 30px;

      @include respond-to(sm) {
        margin-top: 10px;
      }

      &-list {
        margin-top: 15px;
      }

      &-item {
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .WithdrawRequestSteps {
      &__status {
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
        color: ${colors.secondary.hex} !important;
        border-bottom: 2px solid transparent;

        &:after {
          background-color: ${colors.gray.hex} !important;
        }

        &.success {
          color: ${colors.secondary.hex} !important;
          &:after {
            background-color: ${colors.success.hex} !important;
          }
        }

        &.error {
          color: ${colors.secondary.hex} !important;
          &:after {
            background-color: ${colors.error.hex} !important;
          }
        }

        &.active {
          background-color: ${colors.white.hex};
          border-bottom: 2px solid ${rgba([colors.gradientStart.rgb, 0.8])};
        }

        &[disabled] {
          opacity: 0.6;

          &:hover {
            cursor: not-allowed;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
          }
        }

        &:hover {
          cursor: pointer;
          box-shadow: 0px 3px 9px ${rgba([colors.primary.rgb, 0.5])};
        }
      }

      &__reasons {
        &-item {
          &::before {
            background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          }
        }
      }

      &__text {
        margin-top: 30px;
        font-weight: 500;

        &.reject {
          color: ${colors.error.hex};
        }

        &.approve {
          color: ${colors.success.hex};
        }
      }

      .BasicModal__scroll {
        min-height: 150px;

        @include respond-to(sm) {
          max-height: none !important;
        }

        & > div:first-child {
          @include respond-to(sm) {
            max-height: none !important;
          }
        }

        & > div:nth-child(2) {
          background: #dedede;
          height: 4px !important;

          & > div {
            background: ${rgba([colors.primary.rgb, 0.3])} !important;
            height: 8px !important;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
