import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_TASKS_COUNTS_NEW = createRequestTypes('GET_TASKS_COUNTS_NEW');
export const getTasksCountsNew = {
  request: data => action(GET_TASKS_COUNTS_NEW[REQUEST], data),
  success: data => action(GET_TASKS_COUNTS_NEW[SUCCESS], { data }),
  failure: error => action(GET_TASKS_COUNTS_NEW[FAILURE], { error }),
};

export const GET_OVERDUE_TASKS = createRequestTypes('GET_OVERDUE_TASKS');
export const getOverdueTasks = {
  request: data => action(GET_OVERDUE_TASKS[REQUEST], data),
  success: data => action(GET_OVERDUE_TASKS[SUCCESS], { data }),
  failure: error => action(GET_OVERDUE_TASKS[FAILURE], { error }),
};
export const GET_TODAY_TASKS = createRequestTypes('GET_TODAY_TASKS');
export const getTodayTasks = {
  request: data => action(GET_TODAY_TASKS[REQUEST], data),
  success: data => action(GET_TODAY_TASKS[SUCCESS], { data }),
  failure: error => action(GET_TODAY_TASKS[FAILURE], { error }),
};
export const GET_TOMORROW_TASKS = createRequestTypes('GET_TOMORROW_TASKS');
export const getTomorrowTasks = {
  request: data => action(GET_TOMORROW_TASKS[REQUEST], data),
  success: data => action(GET_TOMORROW_TASKS[SUCCESS], { data }),
  failure: error => action(GET_TOMORROW_TASKS[FAILURE], { error }),
};

export const GET_ALL_REASSIGN_TASKS = createRequestTypes('GET_ALL_REASSIGN_TASKS');
export const getAllReassignTasks = {
  request: data => action(GET_ALL_REASSIGN_TASKS[REQUEST], data),
  success: data => action(GET_ALL_REASSIGN_TASKS[SUCCESS], { data }),
  failure: error => action(GET_ALL_REASSIGN_TASKS[FAILURE], { error }),
};

export const GET_ALL_CRM_TASKS = createRequestTypes('GET_ALL_CRM_TASKS');
export const getAllCrmTasks = {
  request: data => action(GET_ALL_CRM_TASKS[REQUEST], data),
  success: data => action(GET_ALL_CRM_TASKS[SUCCESS], { data }),
  failure: error => action(GET_ALL_CRM_TASKS[FAILURE], { error }),
};

export const GET_ALL_REASSIGNS_TASKS = createRequestTypes('GET_ALL_REASSIGNS_TASKS');
export const getAllReassignsTasks = {
  request: data => action(GET_ALL_REASSIGNS_TASKS[REQUEST], data),
  success: data => action(GET_ALL_REASSIGNS_TASKS[SUCCESS], { data }),
  failure: error => action(GET_ALL_REASSIGNS_TASKS[FAILURE], { error }),
};

export const GET_ALL_MY_CRM_TASKS = createRequestTypes('GET_ALL_MY_CRM_TASKS');
export const getAllMyCrmTasks = {
  request: data => action(GET_ALL_MY_CRM_TASKS[REQUEST], data),
  success: data => action(GET_ALL_MY_CRM_TASKS[SUCCESS], { data }),
  failure: error => action(GET_ALL_MY_CRM_TASKS[FAILURE], { error }),
};

export const GET_CRM_TASK = createRequestTypes('GET_CRM_TASK');
export const getCrmTask = {
  request: id => action(GET_CRM_TASK[REQUEST], id),
  success: data => action(GET_CRM_TASK[SUCCESS], { data }),
  failure: error => action(GET_CRM_TASK[FAILURE], { error }),
};

export const createCrmTask = createFormAction('CREATE_CRM_TASK');
export const updateCrmTask = createFormAction('UPDATE_CRM_TASK');

export const GET_CRM_MANAGERS = createRequestTypes('GET_CRM_MANAGERS');
export const getCrmManagers = {
  request: () => action(GET_CRM_MANAGERS[REQUEST]),
  success: data => action(GET_CRM_MANAGERS[SUCCESS], { data }),
  failure: error => action(GET_CRM_MANAGERS[FAILURE], { error }),
};

export const newChangeReassignTaskStatus = createFormAction('NEW_CHANGE_REASSIGN_TASK_STATUS');
