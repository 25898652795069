import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .WalletOperation {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .WalletDeposit__line {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      :global(.ReduxSelectField, .ReduxTextField) {
        flex: 1;
        margin-right: rem(30px);

        @include respond-to(sm) {
          margin-right: 0;
        }
      }
    }

    :global(.ReduxSelectField, .ReduxTextField) {
      max-width: 400px;
      min-width: 300px;
    }

    .WalletDeposit__hr {
      max-width: 830px;
      margin: rem(30px 0);
    }

    :global(.WalletDeposit__balance-amount) {
      margin: rem(0 10px);
    }

    .WalletDeposit__transfer-description {
      margin-bottom: rem(20px);
    }

    .WalletDeposit__commission-description {
      display: flex;
      flex-direction: column;
      line-height: 1.5;
    }

    :global(svg) {
      width: 20px;
      height: 20px;
    }

    :global(.DefaultButton) {
      width: 120px;
      margin-top: rem(30px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .WalletOperation {
      .WalletDeposit__balance-caption,
      .WalletDeposit__transfer-description,
      .WalletDeposit__commission-description {
        color: ${colors.gray.hex};
      }

      .WalletDeposit__balance-amount {
        color: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
