import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as actionTypes from './actions';

export const initialState = {
  paymentSettings: [],
  paymentSettingsIsLoaded: false,

  policySettingsList: [],
  policySettingsListIsLoaded: false,

  policySettingInfo: {},
  policySettingInfoIsLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_SETTINGS[REQUEST]:
      return { ...state, paymentSettingsIsLoaded: false };
    case actionTypes.GET_PAYMENT_SETTINGS[SUCCESS]:
      return { ...state, paymentSettings: action.data, paymentSettingsIsLoaded: true };
    case actionTypes.GET_PAYMENT_SETTINGS[FAILURE]:
      return { ...state, error: action.error, paymentSettingsIsLoaded: false };

    case actionTypes.GET_POLICY_SETTINGS[REQUEST]:
      return { ...state, policySettingsListIsLoaded: false };
    case actionTypes.GET_POLICY_SETTINGS[SUCCESS]:
      return { ...state, policySettingsList: action.data, policySettingsListIsLoaded: true };
    case actionTypes.GET_POLICY_SETTINGS[FAILURE]:
      return { ...state, error: action.error, policySettingsListIsLoaded: false };

    case actionTypes.GET_POLICY_SETTING[REQUEST]:
      return { ...state, policySettingInfoIsLoaded: false };
    case actionTypes.GET_POLICY_SETTING[SUCCESS]:
      return { ...state, policySettingInfo: action.data, policySettingInfoIsLoaded: true };
    case actionTypes.GET_POLICY_SETTING[FAILURE]:
      return { ...state, error: action.error, policySettingInfoIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
