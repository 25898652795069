import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const SearchBar = ({ theme, onChange, placeholder, noLoupe, initValue }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [search, setSearch] = useState(typeof initValue !== 'undefined' ? initValue : '');

  const handleChange = event => {
    setSearch(event.target.value);
  };

  const handleOnChange = searchValue => {
    onChange(searchValue);
  };

  return (
    <div className="CRMSearchBar">
      <div className="CRMSearchBar__wrapper">
        <input
          className={`CRMSearchBar__input ${noLoupe && 'CRMSearchBar__input--noLoupe'} `}
          type="search"
          value={search}
          onKeyUp={event => {
            if (event.key === 'Enter' || (event.key === 'Backspace' && !event.target.value)) {
              handleOnChange(event.target.value);
            } else if (noLoupe) {
              handleOnChange(event.target.value);
            }
          }}
          onChange={handleChange}
          placeholder={placeholder || 'Search'}
        />
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

SearchBar.defaultProps = {
  theme: {},
};

export default withTheme()(SearchBar);
