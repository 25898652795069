import css from 'styled-jsx/css'

import { gradient, isRTL } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Issue__info {
    padding: 25px 40px 10px 35px;
    position: relative;
    border-bottom: 1px solid #C5C5C5;

    @include respond-to(sm) {
      padding: 15px 10px 5px 10px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        margin-bottom: 15px;
        :global(img) {
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.4s ease;
          &:hover {
            transform: scale(1.1);
          }
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            width: 50px;
            height: 50px;
          }
        }
        p {
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 485px;
          @include respond-to(xl) {
            max-width: 350px;
          }
          @include respond-to(sm) {
            max-width: 250px;
          }
        }
        :global(span) {
          &:first-child {
            font-size: 11px;
            text-transform: uppercase;
            margin-bottom: 5px;
            display: block;
          }
        }
        :global(.StatusCell) {
          margin-top: rem(5px);
        }
      }
    }
  }

  .IssueInfo__user-inner {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    width: 33%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .IssueInfo__avatar-inner {
      max-width: 50px;
      max-height: 50px;
      height: auto;
      width: auto;
    }
    .IssueInfo__user-info {
      margin-left: ${isRTL ? "auto" : "20px"};
      margin-right: ${isRTL ? "20px" : "auto"};
      width: calc(100% - 50px);
    }
  }

  .IssueInfo__title {
    width: 33%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .IssueInfo__date {
    width: 16%;

    @media screen and (max-width: 768px) {
      width: 50%;
    }
  }

  .IssueInfo__status {
    width: 10%;

    @media screen and (max-width: 768px) {
      width: 50%;
    }
  }

`

const getDynamicStyles = ({ colors }) => {
  return css`
    .Issue__info {
      background: ${colors.white.hex};
      &:after {
        background: ${gradient(
          90,
          [colors.gradientStart.rgb, 1],
          0,
          [colors.gradientEnd.rgb, 1],
          100
        )};
      }
      ul {
        li {
          p {
            color: ${colors.black.hex};
          }
          :global(span) {
            &:first-child {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
