import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* boGetInvestCRMStrategiesSaga({ search }) {
  try {
    const { status, data } = yield call(api.boGetInvestCRMStrategies, search);
    if (status < 300) {
      yield put(ActionTypes.boGetInvestCRMStrategies.success(data));
    } else {
      yield put(ActionTypes.boGetInvestCRMStrategies.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.boGetInvestCRMStrategies.failure());
    throw e;
  }
}

export function* boGetInvestCRMStrategiesWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.BO_GET_INVEST_CRM_STRATEGIES[REQUEST]);
    yield call(boGetInvestCRMStrategiesSaga, search);
  }
}

export function* boGetInvestCRMStrategyInvestorsSaga(strategyId, search) {
  try {
    const { status, data } = yield call(api.boGetInvestCRMStrategyInvestors, strategyId, search);
    if (status < 300) {
      yield put(ActionTypes.boGetInvestCRMStrategyInvestors.success(data));
    } else {
      yield put(ActionTypes.boGetInvestCRMStrategyInvestors.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.boGetInvestCRMStrategyInvestors.failure());
    throw e;
  }
}

export function* boGetInvestCRMStrategyInvestorsWatcherSaga() {
  while (true) {
    const { strategyId, search } = yield take(ActionTypes.BO_GET_INVEST_CRM_STRATEGY_INVESTORS[REQUEST]);
    yield call(boGetInvestCRMStrategyInvestorsSaga, strategyId, search);
  }
}

export function* boToggleInvestCRMStrategySaga(strategyId, strategyAction, search) {
  try {
    const { status, data } = yield call(api.boToggleInvestCRMStrategy, strategyId, strategyAction);
    yield call(boGetInvestCRMStrategiesSaga, search);

    if (status < 300) {
      yield put(ActionTypes.boToggleInvestCRMStrategy.success());
    } else {
      yield put(ActionTypes.boToggleInvestCRMStrategy.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.boToggleInvestCRMStrategy.failure());
    throw e;
  }
}

export function* boToggleInvestCRMStrategyWatcherSaga() {
  while (true) {
    const { strategyId, strategyAction, search } = yield take(ActionTypes.BO_TOGGLE_INVEST_CRM_STRATEGY[REQUEST]);
    yield call(boToggleInvestCRMStrategySaga, strategyId, strategyAction, search);
  }
}

export function* boToggleCRMInvestmentSaga(investmentId, investmentAction, strategyId, search, account) {
  try {
    const { status, data } = yield call(api.boToggleCRMInvestment, investmentId, investmentAction, account);
    yield call(boGetInvestCRMStrategyInvestorsSaga, strategyId, search);

    if (status < 300) {
      yield put(ActionTypes.boToggleCRMInvestment.success());
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.boToggleCRMInvestment.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.boToggleCRMInvestment.failure());
    throw e;
  }
}

export function* boToggleCRMInvestmentWatcherSaga() {
  while (true) {
    const { investmentId, investmentAction, strategyId, search, account } = yield take(
      ActionTypes.BO_TOGGLE_CRM_INVESTMENT[REQUEST]
    );
    yield call(boToggleCRMInvestmentSaga, investmentId, investmentAction, strategyId, search, account);
  }
}

export function* editInvestCRMStrategySaga(payload) {
  const { search, id, values } = payload;
  try {
    const { status, data: error } = yield call(api.editInvestCRMStrategy, id, values);
    if (status < 300) {
      yield call(boGetInvestCRMStrategiesSaga, { search });
      yield put(ActionTypes.editInvestCRMStrategy.success());
    } else {
      const formError = new SubmissionError(error);
      yield put(ActionTypes.editInvestCRMStrategy.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
  }
}

export function* editInvestStrategyWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editInvestCRMStrategy.REQUEST);
    yield call(editInvestCRMStrategySaga, payload);
  }
}

export default [
  fork(boGetInvestCRMStrategiesWatcherSaga),
  fork(boGetInvestCRMStrategyInvestorsWatcherSaga),
  fork(boToggleInvestCRMStrategyWatcherSaga),
  fork(boToggleCRMInvestmentWatcherSaga),
  fork(editInvestStrategyWatcherSaga),
];
