import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FormMessageHOC } from 'common';
import { ReduxSelectField, DefaultButton } from 'common/components';

import { changePaymentStatus } from '../../_redux';

import { staticStyles } from './style';

const ChangePaymentStatusForm = ({ handleSubmit, statuses, requestId, type, dispatch, invalid, submitting, error }) => {
  const submit = handleSubmit(values => changePaymentStatus({ id: requestId, type, ...values }, dispatch));

  return (
    <form className="ChangePaymentStatusForm" onSubmit={submit}>
      <div className="ChangePaymentStatusForm__disclaimer">
        <h4 className="ChangePaymentStatusForm__disclaimer__title">
          <FormattedMessage id="justWarning" />
        </h4>
        <div className="ChangePaymentStatusForm__disclaimer__text">
          <FormattedMessage id="boChangeStatusDisclaimer" />
        </div>
      </div>
      <div className="ChangePaymentStatusForm__fieldWrapper">
        <Field
          component={ReduxSelectField}
          name="status"
          textId="justStatus"
          options={statuses}
          disabled={submitting}
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <DefaultButton textId="justSubmit" type="submit" disabled={invalid || submitting} loading={submitting} filled />
      <style jsx>{staticStyles}</style>
    </form>
  );
};

ChangePaymentStatusForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  requestId: PropTypes.number.isRequired,
  statuses: PropTypes.array,
  error: PropTypes.string,
};

ChangePaymentStatusForm.defaultProps = {
  statuses: [],
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'ChangePaymentStatusForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageId);
      props.closeModal();
    },
  })
)(ChangePaymentStatusForm);
