import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .AccountItem {
    .TabFeedItem__Description {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: #333;

      @include respond-to(xs) {
        margin-left: 44px;
      }
    }
  }
`;

export { staticStyles };
