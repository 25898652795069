import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  
  .TargetForm {
    width: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include respond-to(sm) {
      width: 100%;
    }
  }
  
  .TargetForm__description {
    margin-bottom: 10px;
  }
   
  .TargetForm__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
   
   .TargetForm__input {
     width: 70%;
     @include respond-to(sm) {
      width: 65%;
    }
   }
   
  .TargetForm__input-description {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
  }
   
  .TargetForm__last-month {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    @include respond-to(sm) {
      width: 35%;
    } 
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .TargetForm__last-month, .TargetForm__description {
      color: ${colors.gray.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }