import queryString from 'query-string';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DefaultButton, NoItemsPanel, PageContentPreloader, PageLayout } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getCampaignList } from '../../_redux';
import { CampaignsTable } from '../../_components';

import { staticStyles } from './style';

const CampaignsPage = ({ campaignList, campaignListIsLoaded, getCampaignList, history, location }) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };

  /* eslint-disable */
  useEffect(() => {
    getCampaignList({ search: query })
  }, [getCampaignList])
  /* eslint-enable */

  const handleCreateCampaign = () => history.push('campaigns/create');

  const handleRowClick = () => {};

  return (
    <PageLayout captionSlug="justCampaigns">
      <div className="CampaignsPage__create-inner">
        <DefaultButton textId="justNewCampaign" plus filled onClick={handleCreateCampaign} />
      </div>
      <PageContentPreloader ready={!!campaignList.count} type="bigLine">
        {campaignList.count ? (
          <CampaignsTable
            data={campaignList}
            loading={!campaignListIsLoaded}
            location={location}
            history={history}
            getList={getCampaignList}
            onRowClick={handleRowClick}
            params={{ search: query }}
          />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noCampaignsYet" />
        )}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

CampaignsPage.propTypes = {
  campaignList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  campaignListIsLoaded: PropTypes.bool.isRequired,
  getCampaignList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      campaignList: state.backoffice.promo.campaignList,
      campaignListIsLoaded: state.backoffice.promo.campaignListIsLoaded,
    }),
    {
      getCampaignList: data => getCampaignList.request(data),
    }
  )
)(CampaignsPage);
export { CampaignsPage };
