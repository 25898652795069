import React from 'react';

const CheckIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 8C45.2548 8 56 18.7452 56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8ZM32 11C20.402 11 11 20.402 11 32C11 43.598 20.402 53 32 53C43.598 53 53 43.598 53 32C53 20.402 43.598 11 32 11ZM42.0753 24.9393C42.596 25.46 42.6538 26.2683 42.2488 26.8529L42.0753 27.0607L30.0607 39.0753C29.54 39.596 28.7317 39.6538 28.1471 39.2488L27.9393 39.0753L21.9393 33.0753C21.3536 32.4895 21.3536 31.5397 21.9393 30.9539C22.46 30.4332 23.2683 30.3754 23.8529 30.7804L24.0607 30.9539L29 35.894L39.9539 24.9393C40.5397 24.3536 41.4895 24.3536 42.0753 24.9393Z"
      fill="#389700"
    />
  </svg>
);

export default CheckIcon;
