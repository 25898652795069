import css from 'styled-jsx/css';
import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatusItem {
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
    padding: 30px;
    margin-bottom: 30px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 40px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @include respond-to(md) {
        flex-direction: column;
      }

      &-item {
        position: relative;
        padding-left: ${isRTL ? '0' : '20px'};
        padding-right: ${isRTL ? '20px' : '0'};
        flex: 50%;
        margin-bottom: 10px;

        &::before {
          content: '';
          position: absolute;
          left: ${isRTL ? 'auto' : '0'};
          right: ${isRTL ? '0' : 'auto'};
          top: 6px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    &__btn {
      margin-top: 20px;
      position: relative;
      display: inline-block;
      padding: rem(9px 20px);
      cursor: pointer;
      font-size: rem(15px);
      line-height: rem(22px);
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      min-width: rem(105px);
      letter-spacing: rem(0.5px);
      &:active {
        box-shadow: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .StatusItem {
      &__list {
        &-item {
          &::before {
            background-color: ${colors.primary.hex};
          }
        }
      }

      &__btn {
        border-radius: ${border.radius};
        border: 1px solid ${colors.primary.hex};
        color: ${colors.white.hex};
        border: none;
        background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        &:hover {
          box-shadow: 0 3px 6px 0 ${rgba([colors.primary.rgb, 0.4])};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          color: ${colors.white.hex};
        }
        &:disabled {
          background: ${gradient(53.07, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
