import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { length, numericality, format as pattern, required } from 'redux-form-validators';

import {
  ReduxTextField,
  ReduxSelectField,
  TinyMCEField,
  TextAreaRawHtmlField,
  CheckboxField,
  DateField,
  DateRangeField,
} from 'common/components';

import ArrayField from '../ArrayField';
import ObjectField from '../ObjectField';
import DynamicField from '../DynamicField';

import { staticStyles } from './style';

export const inputComposer = (
  name,
  { widget = 'text', title, ...props },
  submitting,
  withoutHeaderDescription,
  isAllFieldsRequired = true
) => {
  const component = widget === 'checkbox' ? CheckboxField : ReduxTextField;
  const validators = [];
  const type = widget === 'integer' ? 'number' : widget;

  if (widget !== 'checkbox' && isAllFieldsRequired) {
    validators.push(required());
  }

  const numericParams = {};
  if (widget === 'integer') {
    numericParams.int = true;
  }
  if (props.minimum) {
    numericParams.greaterThanOrEqualTo = props.minimum;
  }
  if (props.maximum) {
    numericParams.lessThanOrEqualTo = props.maximum;
  }

  if (Object.keys(numericParams).length) {
    validators.push(numericality(numericParams));
  }

  const stringParams = {};
  if (props.minLength) {
    stringParams.min = props.minLength;
  }
  if (props.maxLength) {
    stringParams.max = props.maxLength;
  }
  if (Object.keys(stringParams).length) {
    validators.push(length(stringParams));
  }

  if (props.validators) {
    props.validators.forEach(validator => {
      validators.push(
        pattern({
          with: new RegExp(validator.pattern),
          message: validator.error,
        })
      );
    });
  }

  // TODO: ПЕРЕДЕЛАТЬ ЭТО ВСЕ!!!
  // TODO: Временное решение для tamam
  const placeholder =
    (title === 'justPhone' || title === 'Phone') &&
    (window.location.href === 'https://my.tamambrokerage.com/deposit' ||
      window.location.href === 'https://my.tamambrokerage.com/withdraw')
      ? '+256XXXXXXXXX'
      : '';

  return (
    <div className="FormSchemaComposer__inner">
      {!withoutHeaderDescription && props.description && (
        <FormattedMessage id={props.description}>{txt => <p>{txt}</p>}</FormattedMessage>
      )}
      <Field
        name={name}
        textId={title}
        placeholder={placeholder}
        type={type}
        component={component}
        validate={validators}
        disabled={submitting}
        customDefaultValue={props.default}
        tooltipId={withoutHeaderDescription ? props.description : ''}
        tooltipTextId={withoutHeaderDescription ? props.description : ''}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

inputComposer.propTypes = {
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  validators: PropTypes.array,
  description: PropTypes.string,
  default: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

inputComposer.defaultProps = {
  minimum: null,
  maximum: null,
  minLength: null,
  maxLength: null,
  validators: [],
  description: '',
  default: '',
};

export const requiredSelectField = value => {
  const errors = [];

  const isEmptyObject = [null, undefined].includes(value) || (typeof value === 'object' && !Object.keys(value).length);

  if (isEmptyObject) {
    errors.push('form.errors.presence');
  }

  return errors.length ? errors.map((error, index) => <FormattedMessage id={error} key={index} />) : null;
};

export const selectComposer = (name, schema, submitting) => (
  <div className="FormSchemaComposer__input-inner FormSchemaComposer__inner">
    {schema.description && <FormattedMessage id={schema.description}>{txt => <p>{txt}</p>}</FormattedMessage>}
    <Field
      name={name}
      options={schema.options}
      validate={[requiredSelectField, required()]}
      textId={schema.title}
      noErrorMessage={schema.no_error_message}
      component={ReduxSelectField}
      disabled={submitting || schema.disable}
      searchable
    />
    <style jsx>{staticStyles}</style>
  </div>
);

export const objectComposer = (title, name, schema, formName, submitting, hidden, isAllFieldsRequired = true) => (
  <ObjectField
    name={name}
    items={schema.properties}
    formName={formName}
    objectTitle={title}
    title={schema.title}
    submitting={submitting}
    hidden={hidden}
    isAllFieldsRequired={isAllFieldsRequired}
  />
);

export const hiddenComposer = (name, title) => (
  <Field name={name} textId={title} type="hidden" hidden component={ReduxTextField} />
);

export const arrLength =
  ({ min, max }) =>
  value => {
    if (!value || value.length < min) {
      return `less than ${min} items`;
    }
    if (value.length > max) {
      return `more than ${max} items`;
    }
  };

export const uniqueValidator = value => {
  if (!value) return;

  const errors = value.map(() => null);

  value.forEach((currentItem, currentIndex) => {
    value.forEach((item, index) => {
      if (typeof currentItem !== 'object') {
        if (currentItem === item) {
          if (currentIndex !== index) {
            errors[currentIndex] = 'uniqueValidationError';
          }
        }
      } else if (JSON.stringify(currentItem) === JSON.stringify(item)) {
        if (currentIndex !== index) {
          errors[currentIndex] = 'uniqueValidationError';
        }
      }
    });
  });

  if (errors.some(elem => elem !== null)) {
    return errors;
  }
};

export const arrayComposer = (name, schema, formName, submitting) => {
  const validators = [];
  const arrLengthParams = {};

  if (schema.minItems) {
    arrLengthParams.min = schema.minItems;
  }
  if (schema.maxItems) {
    arrLengthParams.max = schema.maxItems;
  }
  if (Object.keys(arrLengthParams).length) {
    validators.push(arrLength(arrLengthParams));
  }

  if (schema.unique) {
    validators.push(uniqueValidator);
  }

  return (
    <FieldArray
      name={name}
      schema={schema}
      validate={validators}
      component={ArrayField}
      formName={formName}
      arraysName={name}
      submit={submitting}
    />
  );
};

export const dynamicComposer = (name, schema, formName, submitting, isAllFieldsRequired) => (
  <DynamicField
    name={name}
    schema={schema}
    formName={formName}
    submitting={submitting}
    isAllFieldsRequired={isAllFieldsRequired}
  />
);

export const HtmlComposer = (name, schema, submitting) => {
  const templates = useSelector(state => state.form?.commonDynamicForm?.values?.templates || []);
  let isIndependent = false;
  const index = useMemo(() => {
    if (typeof name === 'string') {
      return +name.replace(/[^\d]/g, '');
    }
    return null;
  }, [name]);

  if (index !== null) {
    isIndependent = templates[index]?.is_independent;
  }
  if (isIndependent || schema.title === 'header' || schema.title === 'footer') {
    return (
      <Field
        component={TextAreaRawHtmlField}
        name={name}
        schema={schema}
        disabled={submitting}
        isIndependent={isIndependent}
      />
    );
  }

  return <Field component={TinyMCEField} name={name} schema={schema} disabled={submitting} withScript />;
};

export const dateComposer = (name, schema, submitting) => (
  <Field component={DateField} name={name} disabled={submitting} schema={schema} validate={[required()]} />
);

export const dateRangeComposer = (name, schema, submitting) => {
  const fieldName = name.includes('.') ? name.substring(0, name.lastIndexOf('.')) : name;
  return (
    <Field component={DateRangeField} name={fieldName} disabled={submitting} schema={schema} validate={[required()]} />
  );
};

export const FormFieldsComposer = ({
  title,
  name,
  schema,
  formName,
  withoutHeaderDescription,
  submitting,
  isAllFieldsRequired,
}) => {
  switch (schema && schema.widget) {
    case 'text':
    case 'number':
    case 'integer':
    case 'checkbox':
      return inputComposer(name, schema, submitting, withoutHeaderDescription, isAllFieldsRequired);
    case 'select':
      return selectComposer(name, schema, submitting);
    case 'array':
      return arrayComposer(name, schema, formName, submitting);
    case 'object':
      return objectComposer(title, name, schema, formName, submitting, false, isAllFieldsRequired);
    case 'nullform': {
      const hidden = true;
      const nullTitle = '';
      const nullSchema = { ...schema, widget: 'object' };
      return objectComposer(nullTitle, name, nullSchema, formName, submitting, hidden);
    }
    case 'null':
      return hiddenComposer(name, title);
    case 'dynamicform':
      return dynamicComposer(name, schema, formName, submitting, isAllFieldsRequired);
    case 'html':
      return HtmlComposer(name, schema, submitting);
    case 'date':
      return dateComposer(name, schema, submitting);
    case 'date_range':
      return dateRangeComposer(name, schema, submitting);
    default:
      return false;
  }
};

FormFieldsComposer.propTypes = {
  name: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  withoutHeaderDescription: PropTypes.bool,
  isAllFieldsRequired: PropTypes.bool,
};

FormFieldsComposer.defaultProps = {
  withoutHeaderDescription: false,
  isAllFieldsRequired: true,
};

export default FormFieldsComposer;
