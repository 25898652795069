import axios from 'axios';
import { logoutSuccess, logout } from 'auth';

export default {
  setupDefaultInterceptors: store => {
    axios.interceptors.request.use(request => {
      const token = localStorage.getItem('jwtToken');
      const locale = localStorage.getItem('locale');

      if (locale) {
        request.headers.locale = locale;
      }
      if (token) {
        request.headers.Authorization = `JWT ${token}`;
      } else {
        store.dispatch(logoutSuccess());
      }
      return request;
    });
    axios.interceptors.response.use(response => {
      if (response.status === 401) {
        store.dispatch(logout());
      }
      return response;
    });
    axios.defaults.baseURL = '/api';
    axios.defaults.validateStatus = status => status < 300 || [400, 401, 409, 413].includes(status);
  },
};
