import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { Tooltip } from 'common/components';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const TopMenuIcon = ({
  id,
  tooltipId,
  tooltipTextId,
  notifications,
  link,
  unreadCount,
  icon,
  disabled,
  children,
  absolute,
  className,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  return (
    <Tooltip id={tooltipId} textId={notifications.length ? '' : tooltipTextId} place="bottom">
      <div id={id} className={classNames('MenuIcon', className)}>
        {absolute ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames('MenuIcon__link', { 'MenuIcon__link--disabled': disabled })}>
            <span className="MenuIcon__inner">
              <span className="MenuIcon__inner__svg">{icon}</span>
            </span>
          </a>
        ) : (
          <NavLink
            to={link}
            onClick={e => {
              if (disabled) e.preventDefault();
            }}
            className={classNames('MenuIcon__link', { 'MenuIcon__link--disabled': disabled })}>
            <span className="MenuIcon__inner">
              <span className="MenuIcon__inner__svg">
                {!!unreadCount && (
                  <span className="MenuIcon__unread-count">{unreadCount < 100 ? unreadCount : '99+'}</span>
                )}
                {icon}
              </span>
            </span>
          </NavLink>
        )}
        {children}
        <style jsx global>
          {staticStyles}
        </style>
        <style>{dynamicStyles}</style>
      </div>
    </Tooltip>
  );
};

TopMenuIcon.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipId: PropTypes.string,
  tooltipTextId: PropTypes.string,
  notifications: PropTypes.array,
  link: PropTypes.string.isRequired,
  unreadCount: PropTypes.number,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  absolute: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.object,
};

TopMenuIcon.defaultProps = {
  tooltipId: '',
  tooltipTextId: '',
  notifications: [],
  unreadCount: 0,
  children: null,
  disabled: false,
  absolute: false,
  className: '',
  theme: {},
};

const ThemedMenuIcon = withTheme()(TopMenuIcon);

export default ThemedMenuIcon;
export { TopMenuIcon };
