import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import BasicCRMModal from 'crm/_components/BasicCRMModal';
import { TYPES } from 'crm/constansts';
import CreateTaskForm from '../CreateTaskForm';

import MailIcon from './icons/mail.svg';
import MonitoringIcon from './icons/monitoring.svg';
import PhoneIcon from './icons/phone.svg';

import { staticStyles } from './style';

const currentDate = moment()
  .add(1, 'days')
  .set({ hour: 9, minute: 30, second: 0, millisecond: 0 })
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const icons = {
  CALL: PhoneIcon,
  MAIL: MailIcon,
  MONITORING: MonitoringIcon,
};

const CRMSelect = ({ closeShowSelect }) => {
  const profile = useSelector(state => state.user.profile);
  const [type, setType] = useState(null);
  const createTaskCaptionId = useMemo(() => {
    if (type === TYPES.CALL) return 'crmCreateCallTask';
    if (type === TYPES.MAIL) return 'crmCreateEmailTask';
    if (type === TYPES.MONITORING) return 'crmCreateMonitoringTask';
    return '';
  }, [type]);
  return (
    <div className="CRMSelect">
      <button type="button" className="CRMSelect__Item" onClick={() => setType(TYPES.CALL)}>
        <FM id="justCall" />
      </button>
      <button type="button" className="CRMSelect__Item" onClick={() => setType(TYPES.MAIL)}>
        <FM id="justMail" />
      </button>
      <button type="button" className="CRMSelect__Item" onClick={() => setType(TYPES.MONITORING)}>
        <FM id="justMonitoring" />
      </button>
      <style jsx global>
        {staticStyles}
      </style>
      {type && (
        <BasicCRMModal
          icon={icons[TYPES[type]]}
          captionId={createTaskCaptionId}
          isOpen
          alignTitleCenter
          onRequestClose={() => closeShowSelect()}>
          <CreateTaskForm
            successMessageId="crmTaskWasCreated"
            type={type}
            defaultAssignTo={{ value: profile.id, label: `${profile.firstName} ${profile.lastName}` }}
            onRequestClose={() => closeShowSelect()}
            initialValues={{
              expirationDate: currentDate,
              assignedTo: { value: profile.id, label: `${profile.firstName} ${profile.lastName}` },
            }}
          />
        </BasicCRMModal>
      )}
    </div>
  );
};

CRMSelect.propTypes = {
  closeShowSelect: PropTypes.func.isRequired,
};

export default CRMSelect;
