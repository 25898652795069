import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'hooks/useOutsideClick';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { length, required } from 'redux-form-validators';

import { getCrmUserFeed } from 'crm/users/_redux';
import { TextFormField } from 'crm/_components/forms';

import { BaseButton, BaseIconButton } from 'crm/_components';
import { updateCrmTask } from 'crm/tasks/_redux';
import { useParams } from 'react-router-dom';
import { staticStyles } from './style';

const ReactionForm = ({
  taskId,
  selectedReaction,
  setSelectedReaction,
  handleSubmit,
  dispatch,
  submitting,
  error,
  setWhichEditTaskOpen,
  reset,
}) => {
  const ref = useRef();
  useOutsideClick(ref, () => setWhichEditTaskOpen(null));
  const { id } = useParams();
  const submit = handleSubmit(values => {
    const data = {
      ...values,
      id: taskId,
      managerReaction: selectedReaction,
      contactId: id,
    };
    updateCrmTask(data, dispatch).then(() => {
      setWhichEditTaskOpen(null);
      reset();
      dispatch(
        getCrmUserFeed.request({
          id,
        })
      );
    });
  });
  return (
    <form ref={ref} className="ReactionForm" onSubmit={submit}>
      <div className="ReactionForm__inputs-inner">
        <BaseIconButton
          className="ReactionButton__cancel"
          iconType="cancel"
          onClick={() => {
            setSelectedReaction(null);
            setWhichEditTaskOpen(null);
          }}
        />
        <div className="ReactionForm__field-wrapper">
          <Field
            labelTextId="justComment"
            component={TextFormField}
            name="comment"
            autoComplete="off"
            type="text"
            validate={[required(), length({ max: 100 })]}
            disabled={submitting}
          />
          <BaseButton type="submit" className="ReactionButton__create" textId="crmSave" />
        </div>
      </div>
      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

ReactionForm.propTypes = {
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setSelectedReaction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  setWhichEditTaskOpen: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  selectedReaction: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

ReactionForm.defaultProps = {
  error: '',
};

export default compose(
  reduxForm({
    form: `contact-note-create-form`,
  })
)(ReactionForm);
export { ReactionForm };
