import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StatusIndicator {
    border-radius: 50px;
    padding: 3px;
    background: grey;
    display: flex;
    align-items: center;

    &__level {
      display: flex;
      align-items: center;
      height: 13px;
    }

    &__line {
      width: 2px;
      border-radius: 2px;
      height: 100%;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 2px;
      display: inline-block;
      margin-right: 2px;

      &-active {
        background: #ffffff;
      }

      &:first-child,
      &:last-child {
        max-height: 7px;
      }
    }
  }
`;

export default staticStyles;
