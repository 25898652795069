import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ReactionForm {
    margin-bottom: 5px;
    positin: relative;

    &__inputs-inner {
      display: flex;
      flex-direction: column;
    }

    &__field-wrapper {
      display: flex;
      gap: 16px;

      .TextFormField {
        width: 100%;
      }
    }

    .ReactionButton__create {
      align-self: flex-start;
    }

    .ReactionButton__cancel {
      margin-bottom: 16px;
      align-self: flex-end;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ReactionForm {
    }
  `;
};

export { staticStyles, getDynamicStyles };
