import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import Arrow from './icons/Arrow';
import { staticStyles, getDynamicStyles } from './style';

const SortIcon = ({ currentOrdering, accessor }) => {
  const arrowClasses = classNames('CustomTable__Arrow', {
    'CustomTable__Arrow--down': currentOrdering === `-${accessor}`,
    'CustomTable__Arrow--up': currentOrdering === accessor,
  });
  return (
    <span className={arrowClasses}>
      <Arrow />
    </span>
  );
};

const CustomTable = ({
  columns,
  data,
  handleColumnSortChange,
  currentOrdering,
  renderRowSubComponent,
  onRowClick,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme, columns) : ` `;

  return (
    <div className="CustomTable__wrapper">
      <table className="CustomTable">
        <thead className="CustomTable__Head">
          <tr className="CustomTable__Head-Row">
            {columns.map(({ Header, accessor, isSortable, ind }) => (
              <th
                key={`CustomTable-header-${ind}-${accessor}`}
                className={classNames('CustomTable__Cell CustomTable__Cell--Thead', {
                  'CustomTable__Cell--Sortable': isSortable,
                })}
                onClick={() => {
                  if (isSortable) {
                    handleColumnSortChange(accessor);
                  }
                }}>
                <div className="CustomTable__Cell-Inner">
                  {typeof Header === 'string' ? <FM id={Header} /> : Header()}
                  {isSortable && <SortIcon currentOrdering={currentOrdering} accessor={accessor} />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="CustomTable__Body">
          {data.map(rowData => {
            if (rowData.isExpanded) {
              return (
                <>
                  <tr
                    className="CustomTable__Body-Row"
                    key={`Contact-${rowData.id}-info`}
                    onClick={e => {
                      e.stopPropagation();
                      onRowClick({ rowData });
                    }}>
                    {columns.map(({ Cell, accessor }, ind) => (
                      <td
                        className="CustomTable__Cell CustomTable__Cell--Tbody"
                        key={`CustomTable-body-${accessor}-${ind}`}>
                        {rowData && Cell({ rowData, isExpanded: rowData.isExpanded })}
                      </td>
                    ))}
                  </tr>
                  {renderRowSubComponent({ rowData })}
                </>
              );
            }

            return (
              <tr
                onClick={e => {
                  e.stopPropagation();
                  onRowClick({ rowData });
                }}
                className="CustomTable__Body-Row"
                key={`Contact-${rowData.id}-info`}>
                {columns.map(({ Cell, accessor }, ind) => (
                  <td
                    className="CustomTable__Cell CustomTable__Cell--Tbody"
                    key={`CustomTable-body-${accessor}-${ind}`}>
                    {rowData && Cell({ rowData })}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

CustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      key: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  handleColumnSortChange: PropTypes.func.isRequired,
  renderRowSubComponent: PropTypes.func,
  currentOrdering: PropTypes.string,
  onRowClick: PropTypes.func,
  theme: PropTypes.object,
};

CustomTable.defaultProps = {
  renderRowSubComponent: null,
  onRowClick: () => {},
  data: [],
  currentOrdering: '',
  theme: {},
};

export default withTheme()(memo(CustomTable));
export { CustomTable };
