import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { TextAreaField, DefaultButton } from 'common/components';

import { finishTask } from '../../_redux';

import staticStyles from './style';

const FinishTaskForm = ({ id, toggleModal, handleSubmit, dispatch, submitting, invalid, error }) => {
  const submit = handleSubmit(values => finishTask({ id, status: 'CLOSED', ...values }, dispatch));

  return (
    <div className="FinishTaskForm__content">
      <form className="FinishTaskForm" onSubmit={submit}>
        <div className="FinishTaskForm__inputs">
          <Field
            component={TextAreaField}
            name="comment"
            textId="justComment"
            rows="4"
            cols="150"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
        <div className="FinishTaskForm__btns">
          <DefaultButton
            textId="justFinishTask"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" onClick={toggleModal} type="button" />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

FinishTaskForm.propTypes = {
  id: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

FinishTaskForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'FinishTaskForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(FinishTaskForm);
export { FinishTaskForm };
