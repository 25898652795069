import React from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { FormMessageHOC } from 'common/HOCs';
import BaseButton from 'crm/_components/BaseButton';
import TextFormField from '../../fields/TextFormField';
import { staticStyles } from './style';

const AddContactsForm = () => (
  <div className="AddContactsForm">
    <Field name="phone" component={TextFormField} labelTextId="crmAddPhone" placeholderId="+7 987 654 32 10" />
    <Field name="skype" component={TextFormField} labelTextId="crmAddSkype" placeholderId="justPartnerCode" />
    <Field name="email" component={TextFormField} labelTextId="crmAddEmail" placeholderId="justPartnerCode" />
    <Field name="website" component={TextFormField} labelTextId="crmAddWebsite" placeholderId="justPartnerCode" />
    <BaseButton textId="justSave" maxContent />
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'crm-add-contacts-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('formSuccessMessageText');
      props.onRequestClose();
    },
  })
)(AddContactsForm);
