import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader, NoItemsPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { StatusItem, StatusPlate } from '../../_components';

import { getStatusesList, getStatusDescription } from '../../_redux';

import staticStyles from './style';

const StatusesPage = ({
  statuses,
  statusesAreLoaded,
  statusDescription,
  getStatusesList,
  getStatusDescription,
  currentStatus,
}) => {
  useEffect(() => {
    getStatusesList();
    getStatusDescription();
  }, [getStatusesList, getStatusDescription]);

  return (
    <PageLayout captionSlug="justStatuses">
      <div className="StatusesPage">
        {currentStatus && (
          <div className="StatusesPage__status">
            <FormattedMessage id="justYourStatus">{txt => `${txt}:`}</FormattedMessage>
            <StatusPlate
              title={currentStatus.title}
              level={currentStatus.level}
              statusColor={currentStatus.statusColor}
            />
          </div>
        )}
        {statusDescription.title && <h3 className="StatusesPage__description-title">{statusDescription.title}</h3>}
        {statusDescription.description && (
          <div
            className="StatusesPage__description-text"
            dangerouslySetInnerHTML={{ __html: statusDescription.description }}
          />
        )}
        <PageContentPreloader ready={statusesAreLoaded} type="bigLine">
          {statuses.length ? (
            <div className="StatusesPage__statuses">
              {statuses
                .sort((a, b) => b.level - a.level)
                .map((status, index) => (
                  <StatusItem {...status} key={index} />
                ))}
            </div>
          ) : (
            <NoItemsPanel icon={noPaymentsImage} captionSlug="noStatusesYet" />
          )}
        </PageContentPreloader>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </PageLayout>
  );
};

StatusesPage.propTypes = {
  statuses: PropTypes.array.isRequired,
  statusesAreLoaded: PropTypes.bool.isRequired,
  getStatusesList: PropTypes.func.isRequired,
  statusDescription: PropTypes.object.isRequired,
  getStatusDescription: PropTypes.func.isRequired,
  currentStatus: PropTypes.object,
};

StatusesPage.defaultProps = {
  currentStatus: null,
};

export default connect(
  state => ({
    statuses: state.client.statuses.statuses,
    statusesAreLoaded: state.client.statuses.statusesAreLoaded,
    statusDescription: state.client.statuses.statusDescription,
    statusDescriptionIsLoaded: state.client.statuses.statusDescriptionIsLoaded,
    currentStatus: state.user.profile.currentStatus,
  }),
  {
    getStatusesList: () => getStatusesList.request(),
    getStatusDescription: () => getStatusDescription.request(),
  }
)(StatusesPage);
