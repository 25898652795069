import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ThankForRegistration {
    text-align: center;
    margin: 45px 0 20px;

    @include respond-to(sm) {
      margin-bottom: 50px;
    }

    h3 {
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      line-height: 1.38;

      @include respond-to(sm) {
        font-size: 14px;
      }
    }
  }

  .ThankForRegistration__image {
    margin-bottom: 24px;

    img {
      width: 67px;
      height: 52px;
    }
  }
`

export { staticStyles }
