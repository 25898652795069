import {
  CustomizationIcon,
  PartnershipIcon,
  ClientsIcon,
  TradingPlatformsIcon,
  StaffIcon,
  PaymentsIcon,
  MarketingIcon,
  DocumentsIcon,
  InvestmentIcon,
} from './Icons';

export const items = [
  {
    title: 'welcomeSetupCustomizationMenu',
    Icon: CustomizationIcon,
  },
  {
    title: 'welcomeSetupClients',
    Icon: ClientsIcon,
  },
  {
    title: 'welcomeSetupStaff',
    Icon: StaffIcon,
  },
  {
    title: 'welcomeSetupPayments',
    Icon: PaymentsIcon,
  },
  {
    title: 'welcomeSetupMarketingMenu',
    Icon: MarketingIcon,
  },
  {
    title: 'welcomeSetupTrading',
    Icon: TradingPlatformsIcon,
  },
  {
    title: 'welcomeSetupPartnership',
    Icon: PartnershipIcon,
  },
  {
    title: 'welcomeSetupDocumentsAndFiles',
    Icon: DocumentsIcon,
  },
  {
    title: 'welcomeSetupInvestment',
    Icon: InvestmentIcon,
  },
];
