import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';

import { withTheme } from 'common/styling/theme';
import { PageLayout, PageContentPreloader, BaseTextField, BaseSelectField, NoItemsPanel } from 'common/components';
import noFilesDownload from 'common/images/noFilesDownload.svg';
import { useSelector } from 'react-redux';
import ContractSpecsTable from '../ContractSpecsTable';

import { staticStyles, getDynamicStyles } from './style';

const ContractSpecsPage = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [groups, setGroups] = useState([]);
  const [groupsAreLoaded, setGroupsAreLoaded] = useState(false);
  const [specs, setSpecs] = useState([]);
  const [specsAreLoaded, setSpecsAreLoaded] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState('');
  const [searchableSpecs, setSearchableSpecs] = useState(specs);
  const [selectedValue, setSelectedValue] = useState({});
  const [mobileSelectedValue, setMobileSelectedValue] = useState({});

  const lang = useSelector(state => state.interfaceConfig.lang);

  const getGroupsList = async () => {
    const { data } = await axios.get('/contract_specs/list/');
    setGroups(data);
    setGroupsAreLoaded(true);
  };

  const getContractSpecs = async (groupId, groupName) => {
    const { data } = await axios.get(`/contract_specs/${groupId}/`, { params: { group: groupName, lang } });
    setSpecs(data);
    setSpecsAreLoaded(true);
  };

  useEffect(() => {
    getGroupsList();
  }, []);

  /* eslint-disable */
  useEffect(() => {
    if (groups.length && groupsAreLoaded) {
      getContractSpecs(groups[activeTab].id);
      setMobileSelectedValue({
        value: 0,
        label: `${groups[activeTab].typeName}`,
      });
    }
  }, [activeTab, groups, groupsAreLoaded]);
  /* eslint-enabled */

  const handleTabClick = (index, groupId) => {
    setActiveTab(index);
    getContractSpecs(groupId);
    setSelectedValue({});
  };

  const handleChangeSearch = e => {
    const value = e.target.value.toLowerCase();

    const filter = specs.filter(spec => {
      return spec.symbol.toLowerCase().includes(value);
    });

    setSearch(value);
    setSearchableSpecs(filter);
  };

  const qs = search ? searchableSpecs : specs;

  const options =
    groups[activeTab]?.priceGroups &&
    Object.entries(groups[activeTab].priceGroups).map(([key, value]) => ({
      label: key,
      options: value.map(item => ({ label: item, value: item })),
    }));

  const handleGroupChange = ({ value, label }) => {
    setSelectedValue({ value, label });
    getContractSpecs(groups[activeTab].id, value);
  };

  const mobileOptions = groups.map((group, index) => ({
    value: index,
    label: `${group.typeName}`,
  }));

  const handleMobileChange = ({ value, label }) => {
    handleTabClick(value, groups[value].id);
    setMobileSelectedValue({ value, label });
  };

  return (
    <PageLayout captionSlug="justContractSpecifications">
      <PageContentPreloader ready={groupsAreLoaded} type="bigLine">
        {groupsAreLoaded && !!groups.length ? (
          <div className="ContractSpecsPage">
            <div className="ContractSpecsPage__select ContractSpecsPage__select--mobile">
              <BaseSelectField
                value={mobileSelectedValue}
                name="userAccount"
                options={mobileOptions}
                onChange={value => handleMobileChange(value)}
                textId="selectAccount"
              />
            </div>
            <div className="ContractSpecsPage__tabs">
              {groups.map((group, index) => (
                <button
                  key={group.id}
                  type="button"
                  className={classNames('ContractSpecsPage__tab', { active: activeTab === index })}
                  onClick={() => {
                    handleTabClick(index, group.id);
                  }}>
                  <span className="ContractSpecsPage__tab-label">{group.typeName[0]}</span>
                  {group.typeName}
                </button>
              ))}
            </div>
            <BaseSelectField
              value={selectedValue}
              name="userAccount"
              options={options}
              onChange={value => handleGroupChange(value)}
              textId="selectPriceGroup"
            />
            <div className="SearchPanel">
              <BaseTextField
                type="text"
                name="search"
                textId="justSearchSymbol"
                onChange={handleChangeSearch}
                value={search}
              />
            </div>
            {specsAreLoaded && !!qs.length && (
              <ContractSpecsTable data={qs.map((item, itemI) => ({ ...item, id: itemI }))} />
            )}
            <style jsx global>
              {dynamicStyles}
            </style>
            <style jsx global>
              {staticStyles}
            </style>
          </div>
        ) : (
          <NoItemsPanel icon={noFilesDownload} captionSlug="noData" />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

ContractSpecsPage.propTypes = {
  theme: PropTypes.object,
};

ContractSpecsPage.defaultProps = {
  theme: {},
};

export default withTheme()(ContractSpecsPage);
