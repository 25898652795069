import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ModalHOC } from 'common';
import { PaginationTable, DefaultButton, DateCell } from 'common/components';

import { getInvestStrategyApproveNew, setInvestmentsActionNew } from '../../_redux';
import { staticStyles } from './style';

const InvestStrategyApproveTable = ({
  strategyId,
  hideActionButtons,
  investAwaitinigApproveNew,
  investAwaitinigApproveNewAreLoaded,
  pendingInvestRejectStrategyNew,
  location,
  history,
  params,
  getInvestStrategyApproveNew,
  setInvestmentsActionNew,
  showModal,
  pendingInvestApproveStrategyNew,
}) => {
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(
    search => {
      getInvestStrategyApproveNew({
        strategyId,
        search,
      });
    },
    [getInvestStrategyApproveNew, strategyId]
  );

  useEffect(() => {
    getList(query);
  }, [getList, query]);

  const handleApprove = id => {
    setInvestmentsActionNew({
      investmentId: id,
      action: 'approve',
      strategyId,
    });
  };

  const handleRejectApprove = id => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'investConfirmRejectInvesting',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => {
          setInvestmentsActionNew({
            investmentId: id,
            action: 'reject',
            strategyId,
          });
        },
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const renderActionButtons = id => (
    <div className="InvestStrategyApproveTable__actions">
      <DefaultButton
        textId="justApprove"
        type="button"
        butColor="success"
        onClick={() => handleApprove(id)}
        disabled={pendingInvestApproveStrategyNew || pendingInvestRejectStrategyNew}
      />
      <DefaultButton
        textId="justReject"
        type="button"
        butColor="error"
        onClick={() => handleRejectApprove(id)}
        disabled={pendingInvestApproveStrategyNew || pendingInvestRejectStrategyNew}
      />
    </div>
  );

  /* eslint-disable */
  const columns = [
    {
      accessor: 'userId',
      Header: 'justId',
      Cell: ({
        row: {
          original: { userId },
        },
      }) => <div className="InvestStrategyApproveTable__investId">{userId}</div>,

      isPreviewCell: true,
    },
    {
      accessor: 'userName',
      Header: 'justName',
      Cell: ({
        row: {
          original: { userName },
        },
      }) => <div className="InvestStrategyApproveTable__investId">{userName}</div>,

      isPreviewCell: true,
    },
    {
      accessor: 'type',
      Header: 'justType',
      Cell: ({
        row: {
          original: { operation },
        },
      }) => <span>{operation}</span>,

      isPreviewCell: true,
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { amount },
        },
      }) => amount,
    },
    {
      accessor: 'created',
      Header: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    ...(!hideActionButtons
      ? [
          {
            accessor: 'actions',
            Header: () => null,
            Cell: ({
              row: {
                original: { id },
              },
            }) => renderActionButtons(id),
          },
        ]
      : []),
  ];
  /* eslint-enable */

  return (
    <div className="InvestStrategyApproveTable">
      <PaginationTable
        data={investAwaitinigApproveNew}
        columns={columns}
        loading={
          !investAwaitinigApproveNewAreLoaded || pendingInvestRejectStrategyNew || pendingInvestApproveStrategyNew
        }
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InvestStrategyApproveTable.propTypes = {
  strategyId: PropTypes.number.isRequired,
  investAwaitinigApproveNew: PropTypes.object.isRequired,
  investAwaitinigApproveNewAreLoaded: PropTypes.bool.isRequired,
  getInvestStrategyApproveNew: PropTypes.func.isRequired,
  setInvestmentsActionNew: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  pendingInvestRejectStrategyNew: PropTypes.bool.isRequired,
  pendingInvestApproveStrategyNew: PropTypes.bool.isRequired,
  hideActionButtons: PropTypes.bool.isRequired,
};

export default compose(
  ModalHOC,
  connect(
    state => ({
      investAwaitinigApproveNew: state.client.investNew.investAwaitinigApproveNew,
      investAwaitinigApproveNewAreLoaded: state.client.investNew.investAwaitinigApproveNewAreLoaded,
      pendingInvestApproveStrategyNew: state.client.investNew.pendingInvestApproveStrategyNew,
      pendingInvestRejectStrategyNew: state.client.investNew.pendingInvestRejectStrategyNew,
      investStrategyId: state.client.investNew.investStrategyId,
    }),
    {
      getInvestStrategyApproveNew: data => getInvestStrategyApproveNew.request(data),
      setInvestmentsActionNew: data => setInvestmentsActionNew.request(data),
    }
  )
)(InvestStrategyApproveTable);
