import axios from 'axios';

const geonamesAPI = axios.create({ baseURL: '' });

export const getPaymentMethodsTemplates = () => axios.get('/payments/payments_editor_variables/');

export const getPaymentMethodsSettings = () => axios.get('/payments/v2/payment_methods/settings/');
export const getPaymentMethodsList = () => axios.get('/payments/v2/payment_system/settings/');
export const getPaymentMethodSettings = id => axios.get(`/payments/v2/payment_methods/settings/${id}/`);
export const deletePaymentMethodSettings = id => axios.delete(`/payments/v2/payment_methods/settings/${id}/`);
export const editPaymentMethodSettings = (id, data) =>
  axios.patch(`/payments/v2/payment_methods/settings/${id}/`, { ...data });
export const getDepositSystem = () => axios.get('/payments/deposit_system/');
export const createPaymentMethodSettings = data =>
  axios.post('/payments/v2/payment_methods/settings/create/', { ...data });
export const getCountries = () => geonamesAPI.get('/geonames/country/');

export const getPaymentTagInfo = paymentTag => axios.get(`/common/payment_tags/${paymentTag}`);
export const getPaymentTags = () => axios.get('/common/payment_tags/');
export const createPaymentTag = payload => axios.post('/common/payment_tags/', { ...payload });
export const deletePaymentTag = id => axios.delete(`/common/payment_tags/${id}`);
export const editPaymentTag = (paymentTag, payload) => axios.put(`/common/payment_tags/${paymentTag}/`, { ...payload });
