import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';
import Select from 'react-select';
import MediaQuery from 'react-responsive';

import { getStates } from 'client/KYC/_redux';

class SelectCountryField extends React.Component {
  componentDidMount() {
    const {
      input,
      meta: { touched },
      getStates,
    } = this.props;

    if (input.value && !touched) {
      getStates(input.value);
      input.onChange(input ? input.value : null);
    }
  }

  render() {
    const {
      input,
      textId,
      label,
      options,
      searchable,
      disabled,
      meta: { touched, active, error },
      getStates,
    } = this.props;

    return (
      <div
        className={classNames('BaseSelectField', 'ReduxSelectField', {
          ReduxSelectField__error: touched && error,
          active,
          disabled,
        })}>
        <div>
          <MediaQuery query="(min-width: 768px)">
            <Select
              {...input}
              value={options.find(option => option.value === input.value.toString())}
              id={input.name}
              placeholder=""
              classNamePrefix="Select"
              isClearable={false}
              isSearchable={searchable}
              isDisabled={disabled}
              noOptionsMessage={() => null}
              hideSelectedOptions
              options={options}
              onChange={option => {
                if (option) {
                  getStates(option.value);
                }
                input.onChange(option ? option.value : null);
              }}
              onBlur={() => input.onBlur(input.value)}
              blurInputOnSelect={false}
            />
          </MediaQuery>

          <MediaQuery query="(max-width: 767px)">
            <select
              id={input.name}
              name={input.name}
              defaultValue={options.find(option => option.value === input.value.toString()) || ''}
              onChange={e => {
                if (e && e.target && e.target.value) {
                  getStates(e.target.value);
                }
                input.onChange(e ? e.target.value : '');
              }}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              className="MobileSelectField"
              disabled={disabled}>
              <option disabled hidden value="" />
              {options.map((option, i) => (
                <option
                  key={i}
                  value={option && option.value}
                  selected={input.value.toString() === (option && option.value)}
                  hidden={input.value.toString() === (option && option.value)}>
                  {option && option.label}
                </option>
              ))}
            </select>
          </MediaQuery>

          <label
            className={classNames('BaseSelectField__label', { active }, { haveText: input.value })}
            htmlFor={input.name}>
            {label || <FM id={textId} />}
          </label>
        </div>

        <span className="ReduxSelectField__error-text">{touched && error}</span>
      </div>
    );
  }
}

SelectCountryField.propTypes = {
  input: PropTypes.object.isRequired,
  textId: PropTypes.string,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  getStates: PropTypes.func.isRequired,
};

SelectCountryField.defaultProps = {
  disabled: false,
  textId: '',
  label: '',
  searchable: true,
};

export default connect(null, { getStates: country => getStates.request(country) })(SelectCountryField);
export { SelectCountryField };
