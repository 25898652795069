import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .NotificationsPanel {
    padding: 8px 0 8px 12px;
    z-index: 30;
    position: absolute;
    top: 75px;
    left: 10px;
    width: 260px;
    @include respond-to(md) {
      padding: 5px 0 5px 10px;
      top: 65px;
      left: 2px;
    }
    @include respond-to(md, customWidth) {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: -9px;
      left: 22px;
      display: inline-block;
      border-style: solid;
      border-width: 0 8px 9px 8px;
    }
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .NotificationsPanel {
      background: ${rgba([colors.secondary.rgb, 0.6])};
      color: ${colors.white.hex};
      border-radius: ${border.radius};
      &:after {
        border-color: transparent transparent transparent;
        border-bottom-color: ${rgba([colors.secondary.rgb, 0.6])};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
