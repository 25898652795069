import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const CustomizationIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="93.596%" x2="9.613%" y2="5.828%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="Symbols" fill="none" fillRule="evenodd">
        <g
          id="menu-/-item-/-customization"
          transform="translate(-27 -13)"
          fill="url(#linearGradient-4)"
          fillRule="nonzero">
          <g transform="translate(27 13)">
            <path d="M13.4119085,2.5 L12.8571429,2.5 L12.8571429,1.23046875 C12.8571429,0.54109375 12.395692,0 11.8047656,0 L1.09047991,0 C0.499587054,0 0,0.54109375 0,1.23046875 L0,4.98046875 C0,5.66984375 0.499587054,6.25 1.09047991,6.25 L11.8047656,6.25 C12.395692,6.25 12.8571429,5.66984375 12.8571429,4.98046875 L12.8571429,3.75 L13.4119085,3.75 C13.707221,3.75 13.9285714,4.0109375 13.9285714,4.35546875 L13.9285714,8.10546875 C13.9285714,8.45 13.7072545,8.75 13.4119085,8.75 L7.51905134,8.75 C6.63284598,8.75 5.89285714,9.57152344 5.89285714,10.6054688 L5.89285714,11.875 L4.84047991,11.875 C4.5446317,11.875 4.28571429,12.1353125 4.28571429,12.4804688 L4.28571429,19.3554688 C4.28571429,19.700625 4.5446317,20 4.84047991,20 L8.05476562,20 C8.35061384,20 8.57142857,19.700625 8.57142857,19.3554688 L8.57142857,12.4804688 C8.57142857,12.1353125 8.35061384,11.875 8.05476562,11.875 L6.96428571,11.875 L6.96428571,10.6054688 C6.96428571,10.2609375 7.22373884,10 7.51905134,10 L13.4119085,10 C14.2981473,10 15,9.13941406 15,8.10546875 L15,4.35546875 C15,3.32152344 14.2981473,2.5 13.4119085,2.5 Z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

CustomizationIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

CustomizationIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedCustomizationIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(CustomizationIconActive);

export default ThemedCustomizationIconActive;
