import React from 'react';

const LanguageIcon = () => (
  <svg className="svgNoFill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5769 16.6107H18.4799C19.5553 16.6107 20.3999 15.7545 20.3999 14.7317V6.67896C20.3999 5.65615 19.5553 4.79998 18.4799 4.79998H5.5199C4.4445 4.79998 3.5999 5.65615 3.5999 6.67896V14.7317C3.5999 15.7545 4.4445 16.6107 5.5199 16.6107H7.4388L7.4399 20.4L7.44406 20.3997L7.44593 20.3986L12.5769 16.6107ZM8.15864 21.364C7.62792 21.7557 6.8759 21.6487 6.47895 21.125C6.32377 20.9202 6.2399 20.6714 6.2399 20.4159L6.23915 17.8107H5.5199C3.79677 17.8107 2.3999 16.4322 2.3999 14.7317V6.67896C2.3999 4.97848 3.79677 3.59998 5.5199 3.59998H18.4799C20.203 3.59998 21.5999 4.97848 21.5999 6.67896V14.7317C21.5999 16.4322 20.203 17.8107 18.4799 17.8107H12.9719L8.15864 21.364Z"
      className="fill"
      fill="#0C0C3D"
    />
  </svg>
);

export default LanguageIcon;
