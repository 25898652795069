import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import {
  BasicModal,
  StatusCell,
  MoneyCell,
  PaginationTable,
  DefaultButton,
  Tooltip,
  DateCell,
} from 'common/components';

import UserBonusWithdrawForm from 'backoffice/users/_components/UserBonusWithdrawForm/';
import { staticStyles, getDynamicStyles } from './style';

const BonusesTable = ({
  userId,
  data,
  loading,
  location,
  history,
  query,
  getList,
  params,
  theme,
  onRowClick,
  rejectBonus,
  isBroker,
  isPeriodical,
  cancelBonusModal,
  cancelUserBonusError,
  intl,
  profile,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [cancelLoading, setCancelLoading] = useState(false);
  const [selectedBonusWithdraw, setSelectedBonusWithdraw] = useState(null);
  const [bonusId, setBonusId] = useState(null);
  const [approveRequiredError, setApproveRequiredError] = useState(false);
  const [nonApprovalError, setNonApprovalError] = useState(false);

  const openBonusWithdrawModal = bonus => setSelectedBonusWithdraw(bonus);
  const closeBonusWithdrawModal = () => setSelectedBonusWithdraw(null);

  const openBonusCancelModal = bonus => setBonusId(bonus);
  const closeBonusCancelModal = () => {
    if (cancelLoading) setCancelLoading(false);
    setBonusId(null);
  };

  useEffect(() => {
    // Approve required error
    if (cancelUserBonusError === 'approve_required') setApproveRequiredError(true);
    if (!cancelUserBonusError && approveRequiredError) setApproveRequiredError(false);
    // Any other error
    if (!!cancelUserBonusError && cancelUserBonusError !== 'approve_required') setNonApprovalError(true);
    if (!cancelUserBonusError && nonApprovalError) setNonApprovalError(false);
  }, [cancelUserBonusError]);

  useEffect(() => {
    if (cancelBonusModal) setCancelLoading(true);
    else closeBonusCancelModal();
  }, [cancelBonusModal]);

  const isDisabled = cancelLoading && !nonApprovalError;

  const renderCancelYesButton = () => {
    if (approveRequiredError) {
      return (
        <DefaultButton
          textId="justYes"
          type="button"
          loading={cancelLoading}
          disabled={cancelLoading}
          onClick={() => rejectBonus({ bonusId, userId, approved: true, search: query })}
          filled
        />
      );
    }

    return (
      <DefaultButton
        textId="justYesCancel"
        type="button"
        filled
        loading={isDisabled}
        disabled={isDisabled}
        onClick={() => rejectBonus({ bonusId, userId, search: query })}
      />
    );
  };

  const rowProps = (state, rowInfo) => ({ onClick: () => onRowClick(rowInfo.original.id) });

  const convertBonusCodeToText = code => {
    const bonusTypes = [
      {
        value: 'bonus_monthly_interest',
        label: intl.formatMessage({ id: 'bonusMonthlyInterest' }),
      },
      {
        value: 'bonus_welcome',
        label: intl.formatMessage({ id: 'bonusWelcome' }),
      },
      {
        value: 'bonus_on_deposit',
        label: intl.formatMessage({ id: 'bonusOnDeposit' }),
      },
      {
        value: 'bonus_compensation',
        label: intl.formatMessage({ id: 'bonusCompensation' }),
      },
      {
        value: 'bonus_manual',
        label: intl.formatMessage({ id: 'bonusManual' }),
      },
      {
        value: 'bonus_risk_free',
        label: intl.formatMessage({ id: 'bonusRiskFree' }),
      },
    ];

    const res = bonusTypes.find(type => type.value === code);
    return res.label;
  };

  const hasWithdrawBonusPermission = profile.perms
    ? !!profile.perms.withdrawBonuses || !!profile.perms.managerWithdrawBonuses
    : false;

  const renderCancelWithdrawButton = (status, id, submitting, account, value) => {
    if (isBroker)
      return (
        hasWithdrawBonusPermission && (
          <Tooltip
            id={`boBonusesTooltip_${id}`}
            textId={status.toLowerCase() !== 'active' ? 'boBonusesTooltipText' : ''}>
            <DefaultButton
              textId="justWithdraw"
              onClick={e => {
                e.stopPropagation();
                openBonusWithdrawModal({ id, account, value });
              }}
              disabled={status.toLowerCase() !== 'active'}
            />
          </Tooltip>
        )
      );
    return (
      <DefaultButton
        onClick={() => openBonusCancelModal(id)}
        textId="justCancel"
        type="submit"
        loading={submitting}
        disabled={status.toLowerCase() !== 'active'}
      />
    );
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justId',
      Cell: ({ original: { id } }) => <span className="BonusesTable__id">{id}</span>,
    },
    {
      key: 'bonusType',
      Cell: ({ original: { bonusType, defaultBonusType } }) => (
        <div className="BonusesTable__types">
          <span>{convertBonusCodeToText(bonusType)}</span>
          {isBroker && (
            <FormattedMessage id={defaultBonusType === 'balance' ? 'justBalance' : 'justCredit'}>
              {txt => <span className="defaultType">{txt}</span>}
            </FormattedMessage>
          )}
        </div>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justAccount',
      Cell: ({ original: { account } }) => <span className="BonusesTable__account">{account}</span>,
    },
    {
      key: 'receivingDate',
      Cell: ({ original: { receivingDate } }) => (
        <span className="BonusesTable__receiving">
          <DateCell value={receivingDate} />
        </span>
      ),
    },
    {
      key: 'validUntil',
      Cell: ({ original: { validUntil, status } }) =>
        validUntil === '9999-01-01' ? (
          <FormattedMessage id="justEndless" />
        ) : (
          <span className={`BonusesTable__until ${status.toLowerCase()}`}>
            <DateCell value={validUntil} />
          </span>
        ),
    },
    {
      key: 'justValue',
      Cell: ({ original: { value } }) => <MoneyCell value={value} />,
      isPreviewCell: true,
    },
    {
      key: 'fulfillmentOfTerms',
      Cell: ({ original: { tradedLots, requiredLots, bonusType } }) => {
        if (!requiredLots) {
          return <span className="BonusesTable__traded">{'—'}</span>;
        }
        return (
          <span className="BonusesTable__traded">
            {tradedLots ? `${tradedLots} ${bonusType === 'bonus_risk_free' ? 'USD' : 'Lots'}` : 0}
          </span>
        );
      },
    },
    {
      key: 'termsOfReceipt',
      Cell: ({ original: { requiredLots, bonusType } }) => (
        <span className="BonusesTable__required">
          {requiredLots ? `${requiredLots} ${bonusType === 'bonus_risk_free' ? 'USD' : 'Lots'}` : '—'}
        </span>
      ),
    },
    {
      key: 'justStatus',
      Cell: ({ original: { status } }) => <StatusCell statusCode={status} />,
      isPreviewCell: true,
    },
    {
      Cell: ({ original: { status, id, submitting, account, value } }) =>
        renderCancelWithdrawButton(status, id, submitting, account, value),
    },
  ];
  /* eslint-enable */

  /* eslint-disable */
  const periodicalColumns = [
    {
      key: 'justId',
      Cell: ({ original: { id } }) => <span className="BonusesTable__id">{id}</span>,
    },
    {
      key: 'bonusType',
      Cell: ({ original: { bonusType } }) => (
        <span className="BonusesTable__type">{convertBonusCodeToText(bonusType)}</span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justAccount',
      Cell: ({ original: { account } }) => <span className="BonusesTable__account">{account}</span>,
    },
    {
      key: 'receivingDate',
      Cell: ({ original: { receivingDate } }) => (
        <span className="BonusesTable__receiving">
          <DateCell value={receivingDate} />
        </span>
      ),
    },
    {
      key: 'validUntil',
      Cell: ({ original: { validUntil, status } }) =>
        validUntil === '9999-01-01' ? (
          <FormattedMessage id="justEndless" />
        ) : (
          <span className={`BonusesTable__until ${status.toLowerCase()}`}>
            <DateCell value={validUntil} />
          </span>
        ),
    },
    {
      key: 'justRate',
      Cell: ({ original: { rate } }) => <span className="BonusesTable__rate">{`${Number(rate)}%`}</span>,
    },
    {
      key: 'justStatus',
      Cell: ({ original: { status } }) => <StatusCell statusCode={status} />,
      isPreviewCell: true,
    },
    {
      Cell: ({ original: { status, id, submitting } }) =>
        (!isBroker || (isBroker && hasWithdrawBonusPermission)) && (
          <DefaultButton
            onClick={() => openBonusCancelModal(id)}
            textId="justCancel"
            type="submit"
            loading={submitting}
            disabled={status.toLowerCase() !== 'active'}
          />
        ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="BonusesTable">
      <PaginationTable
        {...props}
        data={data}
        columns={isPeriodical ? periodicalColumns : columns}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        onRowClick={onRowClick}
        getTrProps={rowProps}
        isResponsive
      />
      <BasicModal captionId="boWithdrawBonus" isOpen={!!selectedBonusWithdraw} onRequestClose={closeBonusWithdrawModal}>
        {!!selectedBonusWithdraw && (
          <UserBonusWithdrawForm
            userId={userId}
            bonus={selectedBonusWithdraw}
            initialValues={{
              account: selectedBonusWithdraw.account,
              bonus: selectedBonusWithdraw.id,
              chargeValue: selectedBonusWithdraw.value.amount,
              chargeAll: true,
            }}
            onSubmitSuccess={() => {
              closeBonusWithdrawModal();
              getList(params);
            }}
          />
        )}
      </BasicModal>
      <BasicModal isOpen={!!bonusId} captionId="justCancelBonus" onRequestClose={closeBonusCancelModal}>
        {!!bonusId && (
          <div className="BonusModal">
            {approveRequiredError ? (
              <FormattedMessage id="withdrawApprovedModalText" />
            ) : (
              <FormattedMessage id="cancelModalSure">{txt => <span>{txt}</span>}</FormattedMessage>
            )}
            {nonApprovalError && <div className="BonusesTable__cancel-error">{cancelUserBonusError}</div>}

            <div className="BonusModal__btns">
              {renderCancelYesButton()}

              <DefaultButton textId="justNo" type="button" onClick={closeBonusCancelModal} />
            </div>
          </div>
        )}
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BonusesTable.propTypes = {
  userId: PropTypes.string,
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  columns: PropTypes.array,
  getTrProps: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  rejectBonus: PropTypes.func,
  className: PropTypes.string,
  params: PropTypes.object.isRequired,
  isBroker: PropTypes.bool,
  isPeriodical: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  cancelUserBonusError: PropTypes.string.isRequired,
  profile: PropTypes.object,
  query: PropTypes.object,
  cancelBonusModal: PropTypes.bool,
  theme: PropTypes.object,
};

BonusesTable.defaultProps = {
  userId: '1',
  onRowClick: null,
  getTrProps: () => ({ onClick: () => null }),
  loading: false,
  className: '',
  columns: [],
  rejectBonus: () => {},
  isBroker: false,
  isPeriodical: false,
  cancelBonusModal: false,
  query: {},
  theme: {},
  profile: {},
};

export default compose(
  injectIntl,
  withTheme(),
  connect(state => ({
    cancelUserBonusError: state.backoffice.users.cancelUserBonusError,
  }))
)(BonusesTable);
export { BonusesTable };
