import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ActionsButton } from 'common/components';
import { EditIcon } from 'common/icons';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const InfoLine = ({
  textId,
  data,
  wordBreak,
  forTranslate,
  onClick,
  actionButtonId,
  actionCondition,
  actionFunc,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <>
      {data && (
        <div className={classNames('InfoLine', { InfoLine__break: wordBreak })}>
          <FormattedMessage id={textId} />
          <div className="InfoLine__right">
            {forTranslate && <FormattedMessage id={data} />}
            {!forTranslate && !onClick && <span>{data}</span>}
            {onClick && (
              <button type="button" onClick={onClick} className="InfoLine__active-text">
                {data}
              </button>
            )}
            {actionCondition && (
              <ActionsButton edit buttonId={actionButtonId} condition={actionCondition} onClickFunc={actionFunc}>
                <EditIcon />
              </ActionsButton>
            )}
          </div>
        </div>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </>
  );
};

InfoLine.propTypes = {
  textId: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wordBreak: PropTypes.bool,
  forTranslate: PropTypes.bool,
  onClick: PropTypes.func,
  actionButtonId: PropTypes.string,
  actionCondition: PropTypes.bool,
  actionFunc: PropTypes.func,
  theme: PropTypes.object,
};

InfoLine.defaultProps = {
  data: '',
  wordBreak: false,
  forTranslate: false,
  onClick: null,
  actionButtonId: '',
  actionCondition: false,
  actionFunc: null,
  theme: {},
};

export default withTheme()(InfoLine);
export { InfoLine };
