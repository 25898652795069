import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabSupport {
    &__list {
    }

    &__item {
      padding: 20px 20px 20px 20px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 1px solid transparent;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      margin: 0 0 15px 0;
      cursor: pointer;

      @include respond-to(lg) {
        display: block;
        padding: 15px 15px 15px 15px;
      }

      &:last-child {
        margin: 0 0 0 0;
      }

      &--waiting {
        border-bottom: 2px solid #fbdba6;
      }

      &--answered {
        border-bottom: 2px solid #81f5c1;
      }

      &--closed {
        background: rgba(248, 250, 255, 0.3);
        border-top: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        border-bottom: 2px solid #dfe2e7;
        border-left: 1px solid #e9e9e9;
        box-shadow: none;
      }
    }

    &__item-left {
      margin: 0 10px 0 0;

      @include respond-to(lg) {
        margin: 0 0 13px 0;
      }
    }

    &__item-left-name-ticket {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin: 0 0 5px 0;

      @include respond-to(lg) {
        margin: 0 0 0 0;
      }

      .TabSupport__item--closed & {
        color: #868687;
      }
    }

    &__item-left-date-ticket-title {
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      margin: 0 5px 0 0;
      color: #000000;

      .TabSupport__item--closed & {
        color: #bebebf;
      }
    }

    &__item-left-date-ticket-date {
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      color: #808080;

      .TabSupport__item--closed & {
        color: #bebebf;
      }
    }

    &__item-center {
      margin: 0 10px 0 0;

      @include respond-to(lg) {
        margin: 0 0 10px 0;
      }
    }

    &__item-center-date-update {
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      color: #808080;
      margin: 0 0 7px 0;

      .TabSupport__item--closed & {
        color: #bebebf;
      }
    }

    &__item-center-comments {
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      color: #808080;

      .TabSupport__item--closed & {
        color: #bebebf;
      }
    }

    &__item-right {
      min-width: 100px;
    }

    &__item-right-status-title {
      font-weight: 300;
      font-size: 12px;
      line-height: 10px;
      color: #808080;
      margin: 0 0 5px 0;

      @include respond-to(lg) {
        display: none;
      }

      .TabSupport__item--closed & {
        color: #7f7f80;
      }
    }

    &__item-right-status {
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      padding: 0 0 0 15px;
      position: relative;

      .TabSupport__item--waiting & {
        color: #f7b84e;
      }

      .TabSupport__item--answered & {
        color: #04eb84;
      }

      .TabSupport__item--closed & {
        color: #c2c7ce;
      }

      &:before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #000;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        .TabSupport__item--waiting & {
          background: #f7b84e;
        }

        .TabSupport__item--answered & {
          background: #04eb84;
        }

        .TabSupport__item--closed & {
          background: #c2c7ce;
        }
      }
    }
  }
`;

export { staticStyles };
