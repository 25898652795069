import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .SimpleModal__buttons-inner {
    width: 100%;
    margin-top: rem(35px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px;
    :global(.SimpleModal__button-inner) {
      padding: rem(0 15px);
    }
  }

  .SimpleModal__description {
     color: #777777;
     font-size: rem(12px);
     margin-top: 10px;
  }
`

export { staticStyles }
