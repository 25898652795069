import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { compose } from 'redux';
import { FormMessageHOC } from 'common';
import { DefaultButton } from 'common/components';

import BonusSettingsPanel from '../BonusSettingsPanel';
import BonusDisplayPanel from '../BonusDisplayPanel';
import BonusDescriptionPanel from '../BonusDescriptionPanel';
import BonusValueLevels from '../BonusValueLevels';

import { staticStyles } from './style';

const BonusesForm = ({
  submitFunction,
  buttonTextId,
  history,
  promoTexts,
  logoId,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  dispatch,
  error,
  ...props
}) => {
  const submit = handleSubmit(values => {
    const formValues = { ...values };

    if (formValues.bonusType !== 'bonus_on_deposit') {
      delete formValues.waitForConversion;
    }

    if (!formValues.conversion) {
      delete formValues.convRate;
      delete formValues.waitForConversion;
    }

    submitFunction({ ...formValues, logoId: typeof values.file[0] === 'string' ? values.logoId : logoId }, dispatch);
  });
  const {
    initialValues: { bonusType },
  } = props;

  const [selectedBonusType, setSelectedBonusType] = useState(bonusType);

  const MemoizedBonusValueLevels = useMemo(() => BonusValueLevels(submitting), [submitting]);

  return (
    <form className="BonusesForm" onSubmit={submit}>
      <BonusSettingsPanel
        {...props}
        initialQuery={props.initialValues.filterConditions}
        submitting={submitting}
        setSelectedBonusType={setSelectedBonusType}
      />
      {['bonus_on_deposit', 'bonus_monthly_interest'].includes(selectedBonusType) && (
        <FieldArray name="depositLevels" component={MemoizedBonusValueLevels} />
      )}
      <BonusDisplayPanel {...props} submitting={submitting} selectedBonusType={selectedBonusType} />
      <BonusDescriptionPanel {...props} promoTexts={promoTexts} submitting={submitting} />
      <div className="BonusesForm__buttons">
        <DefaultButton
          textId={buttonTextId}
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={() => history.goBack()} />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

BonusesForm.propTypes = {
  submitFunction: PropTypes.func.isRequired,
  buttonTextId: PropTypes.string.isRequired,
  logoId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  promoTexts: PropTypes.array,
  error: PropTypes.string,
};

BonusesForm.defaultProps = {
  promoTexts: [],
  error: '',
};

export default compose(
  FormMessageHOC,
  connect(state => ({
    logoId: state.admin.filesManagement.file.id || '',
  })),
  reduxForm({
    form: 'BonusesForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage(props.successMessageId);
      props.history.push('/backoffice/bonuses/');
    },
  })
)(BonusesForm);
export { BonusesForm };
