import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FileItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: rem(25px 30px);
    margin-bottom: 25px;
    @include respond-to(sm) {
      align-items: flex-start;
    }
  }

  .FileItem__logo {
    width: 60px;
    margin-right: rem(20px);
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .FileItem__inner {
    width: calc(100% - 80px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .FileItem__title {
    display: flex;
    align-items: center;
    width: 15%;
    margin-right: rem(10px);
    @include respond-to(lg) {
      width: 10%;
    }
    @include respond-to(sm) {
      margin: 0 0 15px 0;
      width: 100%;
    }
    p {
      font-weight: 500;
    }
  }

  .FileItem__description {
    width: 35%;
    margin-right: rem(10px);
    @include respond-to(sm) {
      margin: 0 0 35px 0;
      width: 100%;
    }
  }

  .FileItem__chooseOs {
    width: 20%;
    margin-right: rem(10px);
    position: relative;
    @include respond-to(lg) {
      width: 25%;
    }
    @include respond-to(sm) {
      width: 45%;
      margin: 0;
    }
    @include respond-to(xs) {
      width: 100%;
      margin-bottom: 15px;
    }
    :global(.BaseSelectField) {
      margin: 0;
    }
    p {
      width: 100%;
      font-size: rem(16px);
      font-weight: 500;
    }
    strong {
      position: absolute;
      top: -23px;
    }
  }

  .FileItem__download {
    position: relative;
    bottom: 1px;
    @include respond-to(sm) {
      bottom: 0;
      width: 45%;
    }
    @include respond-to(xs) {
      width: 100%;
    }
  }

`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .FileItem {
      background: ${colors.white.hex};
      border: ${border.radius};
      box-shadow: 0 1px 6px ${rgba([colors.black.rgb, 0.06])};
    }

    .FileItem__description {
      p {
        color: ${colors.gray.hex};
      }
    }

    .FileItem__chooseOs {
      strong {
        color: ${colors.gray.hex};
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
