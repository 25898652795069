import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTheme } from 'common/styling/theme';
import UserLoginsHistory from 'backoffice/_components/UserLoginsHistory';
import { UserOperationsHistory } from '../../../_components';

import { staticStyles, getDynamicStyles } from './style';

const UserHistory = ({ user, userIsLoaded, theme, id }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  if (userIsLoaded) {
    user.logins.reverse();
  }

  return (
    <>
      <div className="UserHistory">
        <UserLoginsHistory id={id} />
        <UserOperationsHistory id={id} />
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </>
  );
};

UserHistory.propTypes = {
  user: PropTypes.object.isRequired,
  userIsLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

UserHistory.defaultProps = {
  theme: {},
};

const ConnectedUserHistory = connect(state => ({
  user: state.backoffice.users.user,
  userIsLoaded: state.backoffice.users.userIsLoaded,
}))(UserHistory);

export default withTheme()(ConnectedUserHistory);
export { UserHistory };
