import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .LightBoxImage {
    display: inline-block;
    position: relative;
    border-radius: 0;
    &:hover {
      .LightBoxImage__hover {
        opacity: 1;
        img {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
    img {
      border-radius: 0;
    }
  }

  .LightBoxImage__inner {
    position: relative;
    line-height: 0;
  }

  .LightBoxImage__hover {
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    transition: all 0.3s ease;
    opacity: 0;
    cursor: pointer;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transition: none;
    }
    img {
      width: 31px !important;
      height: 32px !important;
      max-width: 100% !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all 0.4s ease;
      transform: translate(-50%, -50%) scale(0.5);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        transition: none;
      }
    }
  }
  
  .UserDocument__lightBoxImage {
    margin: 0 auto;
    width: auto;
    height: 105px;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      max-width: 100%;
      margin: 0 !important;
      max-height: 105px;
      height: auto !important;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .LightBoxImage__hover {
      background-color: ${rgba([colors.black.rgb, 0.35])};
    }
  `
}

export { staticStyles, getDynamicStyles }
