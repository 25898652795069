import { put, call, fork, take, race, delay, takeEvery, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { createIssueAttachment } from 'common/_api';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

const NEW_ISSUE_DISPLAY_TIMEOUT = 15000;

export function* getClientIssuesSaga() {
  try {
    const { status, data } = yield call(api.getClientIssues);
    if (status < 300) {
      yield put(ActionTypes.getClientIssues.success(data));
    } else {
      yield put(ActionTypes.getClientIssues.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getClientIssues.failure());
  }
}

export function* getClientIssuesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CLIENT_ISSUES[REQUEST]);
    yield call(getClientIssuesSaga);
  }
}

export function* markIssueAsViewedSaga(id) {
  try {
    const { status, data } = yield call(api.markIssueAsViewed, id);
    if (status < 300) {
      yield put(ActionTypes.markIssueAsViewed.success(data));
    } else {
      yield put(ActionTypes.markIssueAsViewed.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.markIssueAsViewed.failure());
  }
}

export function* markIssueAsViewedWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.MARK_ISSUE_AS_VIEWED[REQUEST]);
    yield call(markIssueAsViewedSaga, id);
  }
}

export function* getClientIssueSaga(id) {
  try {
    const { status, data } = yield call(api.getClientIssue, id);
    if (status < 300) {
      yield put(ActionTypes.getClientIssue.success(data));
    } else {
      yield put(ActionTypes.getClientIssue.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getClientIssue.failure());
  }
}

export function* getClientIssueWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CLIENT_ISSUE[REQUEST]);
    yield call(getClientIssueSaga, id);
  }
}

export function* closeIssueSaga(id) {
  try {
    const { status, data } = yield call(api.closeIssue, id);
    if (status < 300) {
      yield call(getClientIssuesSaga);
      yield call(getClientIssueSaga, id);
      yield put(ActionTypes.closeIssue.success(data));
    } else {
      yield put(ActionTypes.closeIssue.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.closeIssue.failure());
  }
}

export function* closeIssueWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.CLOSE_ISSUE[REQUEST]);
    yield call(closeIssueSaga, id);
  }
}

export function* issueCreateMessageSaga(issue, text, attachments) {
  try {
    const { data, status } = yield call(api.issueCreateMessage, issue, text);
    if (status < 300) {
      yield put(ActionTypes.issueCreateMessage.success(data));
      if (attachments && attachments.length) {
        for (let i = 0; i < attachments.length; i += 1) {
          yield put(ActionTypes.createIssueAttachment.request(data.id, attachments[i]));
        }
        yield delay(100);
        yield call(getClientIssuesSaga);
      }
      yield call(getClientIssueSaga, issue);
    } else if (status === 401) {
      yield put(ActionTypes.issueCreateMessage.failure());
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.issueCreateMessage.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.issueCreateMessage.failure());
  }
}

export function* issueCreateMessageWatcherSaga() {
  while (true) {
    const {
      payload: { issue, text, attachments },
    } = yield take(ActionTypes.issueCreateMessage.REQUEST);
    yield call(issueCreateMessageSaga, issue, text, attachments);
  }
}

export function* createIssueSaga(title, language, description, attachments, isPartnershipRequest) {
  try {
    const { data, status } = yield call(api.createIssue, title, language, description, isPartnershipRequest);
    if (status < 300) {
      yield put(ActionTypes.createIssue.success(data));
      yield call(issueCreateMessageSaga, data.id, description, attachments);
    } else if (status === 401) {
      yield put(ActionTypes.createIssue.failure());
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.createIssue.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createIssue.failure());
  }
}

export function* createIssueWatcherSaga() {
  while (true) {
    const {
      payload: { title, language, description, attachments, isPartnershipRequest },
    } = yield take(ActionTypes.createIssue.REQUEST);
    yield call(createIssueSaga, title, language, description, attachments, isPartnershipRequest);
  }
}

export function* createIssueAttachmentSaga(message, file) {
  try {
    yield call(createIssueAttachment, message, file);
    yield put(ActionTypes.createIssueAttachment.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createIssueAttachment.failure());
  }
}

export function* createIssueAttachmentWatcherSaga() {
  while (true) {
    const { message, file } = yield take(ActionTypes.CREATE_ISSUE_ATTACHMENT[REQUEST]);
    yield call(createIssueAttachmentSaga, message, file);
  }
}

export function* addIssueNotificationSaga({ issue }) {
  const currentInterface = yield select(state => state.user.currentInterface);
  if (currentInterface === 'client') {
    yield put(ActionTypes.addIssueNotification(issue));
    yield call(getClientIssuesSaga);
    yield call(getClientIssueSaga, issue.id);
  }
  yield race({
    timeout: delay(NEW_ISSUE_DISPLAY_TIMEOUT),
    hide: take(action => action.type === ActionTypes.HIDE_ISSUE_NOTIFICATION && action.id === issue.id),
  });
  yield put(ActionTypes.removeIssueNotification(issue.id));
}
export function* addIssueNotificationWatcherSaga() {
  yield takeEvery(ActionTypes.UPDATE_ISSUE, addIssueNotificationSaga);
}

export function* deleteMessageAttachmentSaga({ attachmentId }) {
  try {
    yield call(api.deleteMessageAttachment, attachmentId);
    yield put(ActionTypes.deleteMessageAttachment.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteMessageAttachment.failure());
  }
}

export function* deleteMessageAttachmentWatcherSaga() {
  yield takeEvery(ActionTypes.DELETE_MESSAGE_ATTACHMENT[REQUEST], deleteMessageAttachmentSaga);
}

export function* updateMessageSaga({ issue, messageId, deletedFiles, ...payload }) {
  try {
    if (deletedFiles && deletedFiles.length) {
      for (let i = 0; i < deletedFiles.length; i += 1) {
        yield put(
          ActionTypes.deleteMessageAttachment.request({
            attachmentId: deletedFiles[i],
          })
        );
      }
    }
    const { data, status } = yield call(api.updateMessage, messageId, payload);
    if (status < 300) {
      yield put(ActionTypes.updateMessage.success(data));
      yield call(getClientIssueSaga, issue);
    } else {
      const formError = new SubmissionError(status === 429 ? { _error: data.detail } : data);
      yield put(ActionTypes.updateMessage.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.updateMessage.failure());
    throw e;
  }
}

export function* updateMessageWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.updateMessage.REQUEST);
    yield call(updateMessageSaga, payload);
  }
}

export default [
  fork(getClientIssuesWatcherSaga),
  fork(getClientIssueWatcherSaga),
  fork(closeIssueWatcherSaga),
  fork(markIssueAsViewedWatcherSaga),
  fork(issueCreateMessageWatcherSaga),
  fork(createIssueWatcherSaga),
  fork(createIssueAttachmentWatcherSaga),
  fork(addIssueNotificationWatcherSaga),
  fork(updateMessageWatcherSaga),
  fork(deleteMessageAttachmentWatcherSaga),
];
