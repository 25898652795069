import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import bg from './bg.png';

const ErrorPage = ({ caption, description }) => {
  useEffect(() => {
    document.body.style.margin = 0;
  });

  return (
    <div className="ErrorPage">
      <h1 className="ErrorPage__title">{caption}</h1>
      {description &&
        description.split('\\n').map((text, ind) => (
          <p key={`error-page-text-${ind}`} className="ErrorPage__text">
            {text}
          </p>
        ))}
      <style jsx>
        {`
          @import 'src/common/styling/_mixins-scss/screen-size';
          @import 'src/common/styling/_mixins-scss/vars';
          @import 'src/common/styling/_mixins-scss/font-face';

          @include font-face('ubuntu-font', '/fonts/UbuntuRegular/UbuntuRegular');

          .ErrorPage {
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: url(${bg}) center / cover no-repeat;

            &__title {
              font-size: 20px;
              line-height: 24px;
              font-weight: normal;
              margin-bottom: 15px;
              color: #ffffff;
              font-family: 'ubuntu-font' !important;
              @include respond-to(sm) {
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 5px;
              }
            }
            &__text {
              font-family: 'ubuntu-font' !important;
              font-size: 14px;
              text-align: center;
              color: #ffffff;
              margin: 0 0 7px 0;
            }
          }
        `}
      </style>
    </div>
  );
};

ErrorPage.propTypes = {
  caption: PropTypes.string.isRequired,
  description: PropTypes.string,
};

ErrorPage.defaultProps = {
  description: '',
};

export default ErrorPage;
