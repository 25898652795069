import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import DefaultButton from '../DefaultButton';

const GenericForm = ({ handleSubmit, submitting, invalid, children, disabled }) => (
  <form onSubmit={handleSubmit}>
    {children}

    <div className="GenericForm__button-inner">
      <DefaultButton
        textId="doSave"
        type="submit"
        filled
        disabled={invalid || submitting || disabled}
        loading={submitting}
      />
    </div>
  </form>
);

GenericForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

GenericForm.defaultProps = {
  disabled: false,
};

export default reduxForm({
  onSubmitSuccess(result, dispatch, props) {
    props.showFormSuccessMessage(props.successMessageTextId);
    if (props.afterSubmitSuccess) {
      props.afterSubmitSuccess();
    }
  },
})(GenericForm);
export { GenericForm };
