import React from 'react';
import PropTypes from 'prop-types';

import FormFieldsComposer from '../FormFieldsComposer';

const ObjectSchemaComposer = ({ schema, formName, submitting }) => (
  <div className="ObjectSchemaComposer">
    <div className="ObjectSchemaComposer_properties">
      {Object.keys(schema.properties).map(key => (
        <FormFieldsComposer
          key={key}
          name={key}
          schema={schema.properties[key]}
          formName={formName}
          submitting={submitting}
        />
      ))}
    </div>
  </div>
);

ObjectSchemaComposer.propTypes = {
  schema: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};

ObjectSchemaComposer.defaultProps = {
  submitting: false,
};

export default ObjectSchemaComposer;
export { ObjectSchemaComposer };
