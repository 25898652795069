import css from 'styled-jsx/css';

const staticStyles = css.global`
  .ExportPaginationTable {
    &__CreateExportButton {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export { staticStyles };
