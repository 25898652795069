import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormattedMessage, injectIntl } from 'react-intl';

import { FormMessageHOC } from 'common';
import { ReduxTextField, ReduxSelectField, DefaultButton, BaseTextField, NoPreview } from 'common/components';

import { staticStyles } from './style';

import { createBanners } from '../../_redux';

const GetBannerForm = ({
  partnerAccountOptions,
  bannerId,
  bannerFields: { preview },
  bannerLink,
  showFormSuccessMessage,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
  intl,
}) => {
  const [isLinkShown, setLinkShown] = useState(false);

  const submit = handleSubmit(({ partnerAccount }) => {
    createBanners({ isRotator: false, partnershipAccount: partnerAccount, banners: [bannerId] }, dispatch);
    setLinkShown(true);
  });

  const handleCopyClick = e => {
    e.stopPropagation();
    navigator.clipboard.writeText(bannerLink);
    showFormSuccessMessage(intl.formatMessage({ id: 'adminUrlCopied' }));
    toggleModal();
    setLinkShown(false);
  };

  return (
    <div className="RejectModal__content">
      <form className="GetBannerForm" onSubmit={submit}>
        <div className="GetBannerForm__inputs-inner">
          <div className="GetBannerForm__img">
            {preview ? <img src={preview} alt={`Banner__${bannerId}`} /> : <NoPreview />}
          </div>
          <Field component={ReduxTextField} name="campaigns" textId="justCampaigns" validate={[required()]} disabled />
          <Field
            component={ReduxSelectField}
            name="partnerAccount"
            textId="justPartnerAccount"
            options={partnerAccountOptions}
            disabled={submitting}
            validate={[required()]}
          />
        </div>
        {!isLinkShown && (
          <DefaultButton
            textId="justGetBanner"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
        )}
        {isLinkShown && (
          <div className="GetBannerForm_link">
            <FormattedMessage id="justYouLink">
              {txt => <span className="GetBannerForm_label">{`${txt}:`}</span>}
            </FormattedMessage>
            <BaseTextField type="text" name="rotatorLinkLabel" onChange={() => {}} value={bannerLink} disabled />
            <DefaultButton textId="justCopy" type="button" onClick={handleCopyClick} filled />
          </div>
        )}
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

GetBannerForm.propTypes = {
  partnerAccountOptions: PropTypes.array.isRequired,
  bannerId: PropTypes.number.isRequired,
  bannerFields: PropTypes.object.isRequired,
  bannerLink: PropTypes.string.isRequired,
  showFormSuccessMessage: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

GetBannerForm.defaultProps = {
  error: '',
};

export default compose(
  injectIntl,
  FormMessageHOC,
  connect(
    (state, props) => ({
      initialValues: { campaigns: props.bannerFields.campaignName },
      bannerLink: state.client.partnership.bannerLink,
    }),
    null
  ),
  reduxForm({
    form: 'GetBannerForm',
  })
)(GetBannerForm);
export { GetBannerForm };
