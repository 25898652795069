import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeModal, openModal } from 'interface/user/_redux/actions';
import { BasicModal, DefaultButton, PageContentPreloader } from 'common/components';
import { getRewardFrequencies } from 'backoffice/partnership/_redux';
import { getUserPartnerAccounts, getPartnershipConditionsList } from '../../_redux';
import UserPartnerAccountsTable from '../UserPartnerAccountsTable';
import UserPartnerAccountCreateForm from '../UserPartnerAccountCreateForm';

import { staticStyles } from './style';

const UserPartnerAccountsPanel = ({
  id,
  profile,
  getUserPartnerAccounts,
  getRewardFrequencies,
  userPartnerAccounts,
  userPartnerAccountsIsLoaded,
  rewardFrequencies,
  modals,
  openModal,
  closeModal,
  getPartnershipConditionsList,
  partnershipConditionsList,
  partnershipSystem,
  addons,
}) => {
  useEffect(() => {
    getUserPartnerAccounts(id);
  }, [getUserPartnerAccounts, id]);

  useEffect(() => {
    if (addons.includes('advancedPartnershipSystem')) {
      getPartnershipConditionsList(id);
    }
  }, [getPartnershipConditionsList, id, addons]);

  useEffect(() => {
    if (addons.includes('advancedPartnershipSystem')) {
      getRewardFrequencies();
    }
  }, [getRewardFrequencies, addons]);

  const handleOpenModal = modalName => {
    getPartnershipConditionsList(id);
    openModal(modalName);
  };

  const accountCreatePermission = profile.perms.partnershipAccountCreate;
  const simplePartnerSystem = partnershipSystem === 'simple';

  return (
    <PageContentPreloader ready={userPartnerAccountsIsLoaded}>
      <div className="UserPartnerAccountsPanel__button-inner">
        {accountCreatePermission && !simplePartnerSystem && (
          <DefaultButton
            textId="createPlatformAccount"
            onClick={() => handleOpenModal('createPartnerAccount')}
            filled
            plus
          />
        )}
      </div>

      <UserPartnerAccountsTable
        data={userPartnerAccounts}
        accountCreatePerm={accountCreatePermission}
        partnershipConditionsList={partnershipConditionsList}
        userId={id}
        getUserPartnerAccounts={getUserPartnerAccounts}
        simplePartnerSystem={simplePartnerSystem}
        rewardFrequencies={rewardFrequencies}
      />

      <BasicModal
        isOpen={modals.createPartnerAccount}
        captionId="createPartnerAccount"
        onRequestClose={() => closeModal('createPartnerAccount')}>
        <UserPartnerAccountCreateForm
          userId={+id}
          rewardFrequencies={rewardFrequencies}
          partnershipConditionsList={partnershipConditionsList}
          onSubmitSuccess={() => {
            closeModal('createPartnerAccount');
            getUserPartnerAccounts(id);
          }}
        />
      </BasicModal>

      <style jsx>{staticStyles}</style>
    </PageContentPreloader>
  );
};

UserPartnerAccountsPanel.propTypes = {
  profile: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  getUserPartnerAccounts: PropTypes.func.isRequired,
  getRewardFrequencies: PropTypes.func.isRequired,
  userPartnerAccountsIsLoaded: PropTypes.bool.isRequired,
  userPartnerAccounts: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modals: PropTypes.object.isRequired,
  getPartnershipConditionsList: PropTypes.func.isRequired,
  partnershipConditionsList: PropTypes.array.isRequired,
  rewardFrequencies: PropTypes.array.isRequired,
  addons: PropTypes.array.isRequired,
  partnershipSystem: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    profile: state.user.profile,
    userPartnerAccounts: state.backoffice.users.userPartnerAccounts,
    userPartnerAccountsIsLoaded: state.backoffice.users.userPartnerAccountsIsLoaded,
    modals: state.user.modals,
    partnershipConditionsList: state.backoffice.users.partnershipConditionsList,
    partnershipSystem: state.interfaceConfig.partnership.partnershipSystem,
    rewardFrequencies: state.backoffice.partnership.rewardFrequencies,
    addons: state.interfaceConfig.addons,
  }),
  {
    getUserPartnerAccounts: userId => getUserPartnerAccounts.request(userId),
    openModal: name => openModal(name),
    closeModal: name => closeModal(name),
    getPartnershipConditionsList: userId => getPartnershipConditionsList.request(userId),
    getRewardFrequencies: () => getRewardFrequencies.request(),
  }
)(UserPartnerAccountsPanel);
export { UserPartnerAccountsPanel };
