import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ModalHOC } from 'common';
import { EditIcon, BasketIcon } from 'common/icons';
import { PaginationTable, ActionsButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';
import { deletePromo } from '../../_redux';

const PromoMaterialsTable = ({
  data,
  onRowClick,
  getList,
  deletePromo,
  showModal,
  loading,
  location,
  history,
  params,
  theme,
}) => {
  const editPromoMaterial = (e, id) => {
    e.stopPropagation();
    history.push(`/backoffice/promo/${id}/edit`);
  };

  const deletePromoMaterialClick = (e, promoId) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeletePromo',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deletePromo({ promoId, params }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };
  /* eslint-disable */
  const columns = [
    {
      key: 'justPreview',
      Cell: ({ original: { preview } }) => <img src={preview} className="PromoMaterialsTable__logo" alt="promoPreview"/>,
      isPreviewCell: true
    },
    {
      key: 'justType',
      accessor: 'type',
      Cell: ({ original: { type } }) => <span className="type">{type}</span>,
    },
    {
      key: 'justSize',
      accessor: 'file__dimensions',
      Cell: ({ original: { dimensions } }) => <span className="size">{dimensions}</span>,
    },
    {
      key: 'campaignName',
      accessor: 'campaign__title',
      Cell: ({ original: { campaignName } }) => <span className="campaignName">{campaignName}</span>,
      isPreviewCell: true
    },
    {
      key: 'justLanguage',
      accessor: 'language',
      Cell: ({ original: { language } }) => <span className="language">{language}</span>,
    },
    {
      key: 'justActions',
      Cell: ({ original: { id } }) => (
        <>
          <ActionsButton
            tooltipId={`Edit__tooltip-${id}`}
            tooltipTextId='justEdit'
            edit
            onClickFunc={e => editPromoMaterial(e, id)}>
            <EditIcon />
          </ActionsButton>
          <ActionsButton
            tooltipId={`Delete__tooltip-${id}`}
            tooltipTextId='justDelete'
            deleteType
            onClickFunc={e => deletePromoMaterialClick(e, id)}
          >
            <BasketIcon />
          </ActionsButton>
        </>
      ),
      isPreviewCell: true
    },
  ]
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      onRowClick(rowInfo.original.id);
    },
  });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PromoMaterialsTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        loading={loading}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PromoMaterialsTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  deletePromo: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PromoMaterialsTable.defaultProps = {
  loading: false,
  theme: {},
};

export default compose(
  ModalHOC,
  withTheme(),
  connect(null, {
    deletePromo: data => deletePromo.request(data),
  })
)(PromoMaterialsTable);
export { PromoMaterialsTable };
