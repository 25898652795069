import React from 'react';
import PropTypes from 'prop-types';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';

import { DefaultButton, ReduxSelectField, ReduxTextField, TextAreaField } from 'common/components';

import staticStyles from './style';

import { editTemplate } from '../../_redux';

const EditTemplateForm = ({ id, languages, submitting, invalid, pristine, handleSubmit, dispatch, history, error }) => {
  const submit = handleSubmit(formValues => editTemplate({ id, ...formValues }, dispatch));

  return (
    <form className="EditTemplateForm" onSubmit={submit}>
      <div className="EditTemplateForm__top">
        <Field component={ReduxTextField} name="title" textId="formTemplatesTitle" validate={[required()]} />
        <Field
          component={ReduxSelectField}
          name="language"
          maxMenuHeight={100}
          textId="justLanguage"
          options={languages}
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <Field
        component={TextAreaField}
        name="text"
        textId="justText"
        rows="4"
        cols="150"
        validate={[required()]}
        disabled={submitting}
      />
      {error && <strong className="formError">{error}</strong>}
      <div className="EditTemplateForm__btns">
        <DefaultButton
          textId="doSave"
          type="submit"
          loading={submitting}
          disabled={invalid || pristine || submitting}
          filled
        />
        <DefaultButton
          textId="justCancel"
          type="button"
          onClick={() => history.push('/backoffice/templates')}
          loading={submitting}
          disabled={submitting}
        />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditTemplateForm.propTypes = {
  id: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  languages: PropTypes.array,
  error: PropTypes.string,
};

EditTemplateForm.defaultProps = {
  error: '',
  languages: [],
};

export default reduxForm({
  form: 'EditTemplateForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.history.push('/backoffice/templates');
  },
})(EditTemplateForm);
