import css from 'styled-jsx/css'

const staticStyles = css`
  .MessagesPanel {
    position: relative;
    left: -2.2%;
    margin-bottom: 25px;
  }
`

export { staticStyles }
