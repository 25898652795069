import React from 'react';

const InvestIcon = () => (
  <div>
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9999 12.5629C20.9553 12.8799 20.9215 13.1992 20.8642 13.5144C20.3973 16.0852 19.077 18.1037 16.8991 19.5432C15.3021 20.5985 13.5267 21.0812 11.6174 20.9892C9.01527 20.8638 6.8406 19.8118 5.10509 17.8633C5.07409 17.8285 5.04309 17.7933 5.01068 17.759C5.00363 17.752 4.99189 17.7496 4.96089 17.7341C4.96089 18.0849 4.96089 18.4254 4.96089 18.7791C4.60299 18.7791 4.26669 18.7791 3.91442 18.7791C3.91442 17.8097 3.91442 16.8361 3.91442 15.8527C4.84723 15.8527 5.77534 15.8527 6.71237 15.8527C6.71237 16.2007 6.71237 16.5375 6.71237 16.8972C6.37561 16.8972 6.04635 16.8972 5.70113 16.8972C7.2032 18.935 10.3553 20.5036 13.6995 19.7592C15.6722 19.3201 17.2659 18.2756 18.4462 16.6347C19.6223 14.9998 20.084 13.1612 19.9046 11.1413C20.0803 11.1215 20.247 11.1032 20.4138 11.0844C20.5819 11.0656 20.7496 11.0464 20.9342 11.0257C20.9572 11.1685 20.9788 11.3033 21.0004 11.4385C20.9999 11.8128 20.9999 12.1876 20.9999 12.5629Z"
        fill="#5D647D"
      />
      <path
        d="M12.5591 3C12.8705 3.04321 13.1838 3.07749 13.4938 3.1315C15.4806 3.47763 17.1654 4.38873 18.5571 5.84557C18.6487 5.94184 18.7384 6.04 18.8577 6.16774C18.8577 5.80189 18.8577 5.47267 18.8577 5.13124C19.2151 5.13124 19.5514 5.13124 19.9032 5.13124C19.9032 6.05878 19.9032 6.98633 19.9032 7.92749C18.9653 7.92749 18.0259 7.92749 17.0757 7.92749C17.0757 7.58512 17.0757 7.24839 17.0757 6.88817C17.3927 6.88817 17.7107 6.88817 18.0456 6.88817C16.4562 4.87716 13.2195 3.41094 9.86076 4.35069C6.25588 5.35996 3.60916 8.90999 4.10516 13.0137C3.76604 13.0579 3.42598 13.102 3.03661 13.1523C3.0244 12.6211 2.98259 12.1068 3.00655 11.5959C3.08358 9.95307 3.5636 8.43048 4.48044 7.06147C5.91112 4.92554 7.90449 3.62744 10.4277 3.14465C10.7715 3.0789 11.1219 3.04743 11.469 3C11.8325 3 12.1961 3 12.5591 3Z"
        fill="#5D647D"
      />
      <path
        d="M17.2861 11.996C17.2917 14.9097 14.9306 17.2757 12.0053 17.2884C9.09042 17.3006 6.70109 14.9134 6.70767 11.9955C6.71424 9.07483 9.08008 6.71206 11.9973 6.71253C14.9155 6.71253 17.2804 9.0753 17.2861 11.996ZM7.76307 12.0063C7.76917 14.347 9.67612 16.2467 12.0077 16.2336C14.3477 16.2209 16.2283 14.3367 16.2311 12.0026C16.2335 9.66423 14.3341 7.76453 11.9955 7.76687C9.65499 7.76922 7.75696 9.67034 7.76307 12.0063Z"
        fill="#5D647D"
      />
      <path
        d="M11.7132 10.1869C11.7132 10.0662 11.7132 9.90328 11.7132 9.72858C11.9424 9.72858 12.1618 9.72858 12.3975 9.72858C12.3975 9.89248 12.3975 10.055 12.3975 10.2024C12.7423 10.302 13.0687 10.3964 13.4107 10.495C13.2796 10.8102 13.1678 11.0788 13.0715 11.3099C12.7352 11.2418 12.4201 11.1685 12.1016 11.1182C11.9974 11.1018 11.879 11.13 11.7766 11.1657C11.7231 11.1845 11.6582 11.2629 11.6573 11.3155C11.6564 11.3667 11.7207 11.4479 11.7733 11.4653C11.9556 11.5264 12.1458 11.5625 12.3318 11.6133C12.5347 11.6687 12.7418 11.7138 12.9367 11.7903C13.7451 12.1083 13.6723 13.0349 13.2078 13.4073C13.0175 13.5599 12.7756 13.6562 12.5446 13.7459C12.4346 13.7886 12.3947 13.8276 12.4008 13.9384C12.4079 14.0601 12.4022 14.1822 12.4022 14.3142C12.1697 14.3142 11.9556 14.3142 11.7165 14.3142C11.7165 14.1517 11.7165 13.9864 11.7165 13.8605C11.3313 13.7525 10.9786 13.6557 10.6273 13.5533C10.5676 13.536 10.5145 13.496 10.4502 13.4622C10.5747 13.1809 10.6944 12.9095 10.7959 12.6803C11.1637 12.7662 11.5112 12.8573 11.8635 12.9245C11.9856 12.948 12.1214 12.9245 12.2458 12.8982C12.3017 12.8865 12.3834 12.8151 12.3839 12.77C12.3844 12.7117 12.3276 12.6216 12.274 12.6004C12.1176 12.5389 11.9499 12.5046 11.7869 12.4595C11.5732 12.3999 11.3544 12.3539 11.1472 12.2769C10.8151 12.1533 10.5742 11.934 10.5202 11.5635C10.4615 11.1615 10.569 10.8036 10.9086 10.5725C11.1397 10.4157 11.4168 10.326 11.7132 10.1869Z"
        fill="#5D647D"
      />
    </svg>
  </div>
);

export default InvestIcon;
