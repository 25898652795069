import React from 'react';
import { useHistory } from 'react-router-dom';

import { PageLayout } from 'common/components';

import { StatusesForm } from '../../_components';

import { createStatusInfo } from '../../_redux';

const CreateStatusPage = () => {
  const history = useHistory();

  return (
    <PageLayout captionSlug="justCreateStatus">
      <StatusesForm
        submitFunction={createStatusInfo}
        history={history}
        successMessageId="statusesCreatedSuccessfully"
        initialValues={{
          durationTime: 0,
          isButtonEnabled: false,
          statusColor: { hex: '#768485', rgb: [118, 132, 133] },
        }}
      />
    </PageLayout>
  );
};

export default CreateStatusPage;
