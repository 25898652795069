import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TaskIcon } from 'crm/_newComponents/TaskIcon';

import accounts from '../../newImages/accounts.svg';
import internalTransfer from '../../newImages/internalTransfer.svg';
import money from '../../newImages/money.svg';
import note from '../../newImages/note.svg';
import reassignIcon from '../../newImages/reassign.svg';

import { staticStyles } from './style';

const ICONS = {
  deposits: money,
  internalTransfers: internalTransfer,
  withdrawals: money,
  notes: note,
  reassign: reassignIcon,
  accounts,
};

const FeedIcon = ({ objectType, icon, status, taskStatus }) => (
  <div
    className={classNames('TabFeedItem__Icon', `TabFeedItem__Icon--${objectType}`, {
      'TabFeedItem__Icon--success': status === 'success',
      'TabFeedItem__Icon--warning': status === 'waiting',
      'TabFeedItem__Icon--failed': status === 'failed',
      'TabFeedItem__Icon--task': objectType === 'tasks' && !taskStatus,
    })}>
    {icon ? <TaskIcon type={icon} /> : <img src={ICONS[objectType]} alt={objectType} />}
    <style jsx>{staticStyles}</style>
  </div>
);

FeedIcon.propTypes = {
  objectType: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['success', 'waiting', 'failed', '']),
  icon: PropTypes.string,
  taskStatus: PropTypes.string,
};

FeedIcon.defaultProps = {
  status: '',
  icon: '',
  taskStatus: null,
};

export default FeedIcon;
