import React from 'react';
import PropTypes from 'prop-types';

import AccountTypeButton from '../AccountTypeButton';

import staticStyles from './style';

const AccountTypesPanel = ({ accountTypes, onAddAccountClick }) => (
  <div className="AccountTypesPanel">
    {accountTypes.map(accountType => (
      <AccountTypeButton {...accountType} onClick={onAddAccountClick} key={accountType.id} />
    ))}
    <style jsx>{staticStyles}</style>
  </div>
);

AccountTypesPanel.propTypes = {
  accountTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string,
    }).isRequired
  ),
  onAddAccountClick: PropTypes.func.isRequired,
};

AccountTypesPanel.defaultProps = {
  accountTypes: [],
};

export default AccountTypesPanel;
