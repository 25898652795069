import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxTextField, ReduxSelectField, DefaultButton, TinyMCEField } from 'common/components';
import { appointLpaStatus, editLpaStatus, revokeLpaStatus } from '../../_redux';

import staticStyles from './style';

const EditLPAForm = ({
  id,
  countries,
  countriesAreLoaded,
  currencies,
  currenciesAreLoaded,
  isLpa,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const countriesOptions = countries.map(option => ({
    value: option.gid,
    label: option.name,
  }));

  const revokeLpa = () => {
    revokeLpaStatus(id, dispatch);
    toggleModal();
  };

  const submit = useMemo(
    () =>
      handleSubmit(values =>
        isLpa
          ? editLpaStatus(
              {
                id,
                ...values,
                user: id,
              },
              dispatch
            )
          : appointLpaStatus(
              {
                user: id,
                ...values,
              },
              dispatch
            )
      ),
    [handleSubmit, id, dispatch]
  );

  return (
    <div className="EditLPAModal__content">
      <form className="EditLPAForm" onSubmit={submit}>
        <Field
          component={ReduxTextField}
          name="depositCommissionPercent"
          textId="lpaDepositComission"
          autoComplete="off"
          disabled={submitting}
          type="number"
          validate={[required()]}
        />
        <Field
          component={ReduxTextField}
          name="withdrawCommissionPercent"
          textId="lpaWithdrawalComission"
          autoComplete="off"
          disabled={submitting}
          type="number"
          validate={[required()]}
        />
        <Field
          component={ReduxSelectField}
          name="availableCountries"
          textId="kycCountry"
          options={countriesOptions}
          disabled={submitting || !countriesAreLoaded}
          validate={[required()]}
          maxMenuHeight={170}
          searchable
          multi
        />
        <Field
          component={ReduxSelectField}
          name="currencies"
          textId="justCurrencies"
          options={currencies}
          disabled={submitting || !currenciesAreLoaded}
          validate={[required()]}
          maxMenuHeight={170}
          searchable
          multi
        />
        <Field
          component={TinyMCEField}
          name="depositDescription"
          textId="lpaDepositDescription"
          disabled={submitting}
        />
        <Field
          component={TinyMCEField}
          name="withdrawDescription"
          textId="lpaWithdrawDescription"
          disabled={submitting}
        />
        {isLpa && (
          <div className="EditLPAForm__revoke">
            <DefaultButton
              textId="revokeLpaStatus"
              type="button"
              onClick={() => revokeLpa()}
              loading={submitting}
              disabled={submitting}
              butColor="error"
            />
          </div>
        )}

        <div className="EditLPAForm__btns">
          <DefaultButton textId="justSend" type="submit" loading={submitting} disabled={invalid || submitting} filled />
          <DefaultButton
            textId="justCancel"
            type="button"
            onClick={() => toggleModal()}
            loading={submitting}
            disabled={submitting}
          />
        </div>
        {error && <strong>{error}</strong>}
      </form>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

EditLPAForm.propTypes = {
  id: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  currencies: PropTypes.array,
  currenciesAreLoaded: PropTypes.bool,
  isLpa: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

EditLPAForm.defaultProps = {
  currencies: [],
  currenciesAreLoaded: false,
  isLpa: false,
  error: '',
};

export default compose(
  connect(state => ({
    profile: state.user.profile,
    countries: state.client.kyc.countries,
    countriesAreLoaded: state.client.kyc.countriesAreLoaded,
    currencies: state.backoffice.users.currencies,
    currenciesAreLoaded: state.backoffice.users.currenciesAreLoaded,
  })),
  reduxForm({
    form: 'disable-lpa-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(EditLPAForm);
export { EditLPAForm };
