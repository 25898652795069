import css from 'styled-jsx/css'

import { rgba } from 'common/styling/theme'

const getDynamicStyles = ({ colors, border }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';

    .WarningMessage {
      padding: 30px 20px;
      text-align: center;
      background-color: ${rgba([colors.warning.rgb, 0.35])};
      border-radius: ${border.radius};
      @include respond-to(sm) {
        padding: 10px;
      }
    }

    .WarningMessage__text-inner {
      margin: rem(15px 0 0);
    }

    .WarningMessage__button-inner {
      margin-top: rem(20px);
    }

    .AccountsPage__warning-message {
      margin-bottom: rem(30px);
    }
  `
}

export { getDynamicStyles }
