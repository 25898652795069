import React from 'react';

import { staticStyles } from './style';

const AppEntryPreloader = () => (
  <div className="AppEntryPreloader__inner">
    <div className="AppEntryPreloader-three-bounce">
      <div className="AppEntryPreloader-bounce-1 AppEntryPreloader-child" />
      <div className="AppEntryPreloader-bounce-2 AppEntryPreloader-child" />
      <div className="AppEntryPreloader-bounce-3 AppEntryPreloader-child" />
    </div>
    <style jsx>{staticStyles}</style>
  </div>
);

export default AppEntryPreloader;
