import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, reset, Field, formValueSelector } from 'redux-form';

import { required } from 'redux-form-validators';

import { withTheme } from 'common/styling/theme';

import { FileField } from 'common/components/form-fields';
import { DefaultButton, ReduxSelectField } from 'common/components';
import { initializeBackofficeUserImport } from 'backoffice/users/_redux';

import { staticStyles, getDynamicStyles } from './style';

const ImportCsvForm = ({
  toggleModal,
  handleSubmit,
  isMessageEditing,
  pristine,
  submitting,
  invalid,
  error,
  disabled,
  dispatch,
  theme,
}) => {
  const [deletedFiles, setDeletedFiles] = useState([]);
  const submit = () => {
    handleSubmit(values => initializeBackofficeUserImport({ fromServer: false, ...values }, dispatch))();
  };

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleDeleteFile = file => {
    setDeletedFiles([...deletedFiles, file.id]);
  };

  return (
    <form className="ImportCsvForm__form" onSubmit={submit}>
      <div className="ImportCsvForm__form-input">
        <Field
          component={ReduxSelectField}
          name="platform"
          maxMenuHeight={100}
          textId="justPlatform"
          options={[
            { label: 'MT4', value: 'MT4' },
            { label: 'MT5', value: 'MT5' },
          ]}
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          withCrossButton={isMessageEditing}
          handleDeleteFile={handleDeleteFile}
          component={FileField}
          deletedFiles={deletedFiles}
          name="file"
          formatNameId="csvFormat"
          fileNameId="justAttachments"
          accept="text/csv"
          disabled={submitting}
          dropZoneDisabled={false}
          validate={[required()]}
        />
        <div className="ImportCsvForm__form-caption">
          <FM id="crmUploadFileFormat">{txt => `${txt}:`}</FM>
          <FM id="importCsvHelpText" />
        </div>
      </div>
      <div className="ImportCsvForm__form-button">
        <DefaultButton
          textId="justImport"
          onClick={submit}
          loading={submitting}
          disabled={disabled || invalid || pristine || submitting}
          filled
        />
        <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
      </div>

      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

ImportCsvForm.propTypes = {
  isMessageEditing: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  theme: PropTypes.object,
};

ImportCsvForm.defaultProps = {
  disabled: false,
  error: '',
  theme: {},
};

const selector = formValueSelector('ImportCsvForm');

export default compose(
  withTheme(),
  connect(state => ({
    attachments: selector(state, 'attachments') || [],
  })),
  reduxForm({
    form: 'ImportCsvForm',
    onSubmitSuccess: (result, dispatch, props) => {
      dispatch(reset('ImportCsvForm'));
      props.history.push('/backoffice/imports');
    },
  })
)(ImportCsvForm);
export { ImportCsvForm };
