import React, { useEffect, useCallback, useMemo } from 'react';

import queryString from 'query-string';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useTable, useSortBy, useRowSelect, useFilters } from 'react-table';
import { withTheme } from 'common/styling/theme';
import noInfo from 'common/images/noInfo.svg';
import ResponsiveTable from 'common/components/tables/ResponsiveTable';

import ColumnFilter from '../ColumnFilter';

// import ArrowsDown from './icons/ArrowsDown.svg';
// import ArrowsUp from './icons/ArrowsUp.svg';
// import NoSortArrows from './icons/NoSortArrows.svg';

import { staticStyles, getDynamicStyles } from './style';

const BaseTable = ({
  columns,
  data,
  onRowClick,
  getTrProps,
  renderRowSubComponent,
  isLoading,
  theme,
  initialSortBy,
  noResponsive,
  onRowSelect,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme, columns) : ` `;

  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => queryString.parse(location.search), [location.search]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  /* eslint-disable */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    selectedFlatRows,
    state: { sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { sortBy: initialSortBy },
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );
  /* eslint-enable */

  /* eslint-disable */
  const handleSorting = useCallback(
    (columnName, desc) => {
      const ordering = desc ? '' : '-';

      history.replace({
        ...location,
        search: queryString.stringify({
          ...query,
          ordering: `${ordering}${columnName}`,
        }),
      });
    },
    [query, history]
  );

  const onChangeSort = useCallback(
    columns => {
      if (columns && columns[0]) {
        const { id, desc } = columns[0];
        handleSorting(id, desc);
      }
    },
    [handleSorting]
  );

  useEffect(() => {
    onChangeSort(sortBy);
  }, [sortBy]);
  /* eslint-enable */

  useEffect(() => {
    if (onRowSelect) {
      const selectedIds = [...selectedFlatRows].map(({ original: { id } }) => id);
      onRowSelect(selectedIds);
    }
  }, [JSON.stringify(selectedRowIds)]);

  const hasFooter = columns.some(column => column.Footer);

  return (
    <div className="BaseTable__wrapper">
      {noResponsive || !isTabletOrMobile ? (
        <table {...getTableProps()} className="BaseTable">
          <thead className="BaseTable__thead">
            {headerGroups.map((headerGroup, groupId) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`thead_${groupId}`} className="BaseTable__thead-row">
                {headerGroup.headers.map((column, columnId) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={`thead_${groupId}_${columnId}`}
                    className="BaseTable__thead-cell">
                    {typeof column.render('Header') === 'string' ? (
                      <>
                        <FormattedMessage id={column.render('Header')}>
                          {txt => <span className="BaseTable__thead-caption">{txt}</span>}
                        </FormattedMessage>
                        {/* {console.log({
                          isSorted: column.isSorted,
                          sortDes: column.isSortedDesc,
                          notDes: column.isSortedDesc,
                        })} */}
                        {/* <img src={NoSortArrows} alt="nos1" />
                        <img src={ArrowsUp} alt="nos2" /> */}
                        {/* {column.canSort &&
                          (column.isSorted ? (
                            <img src={NoSortArrows} alt="nos" />
                          ) : column.isSortedDesc ? (
                            <img src={ArrowsUp} alt="nos" />
                          ) : (
                            <img src={ArrowsDown} alt="nos" />
                          ))
                          } */}
                      </>
                    ) : (
                      column.render('Header')
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="BaseTable__tbody">
            {rows.map((row, rowId) => {
              prepareRow(row);
              return (
                <React.Fragment key={`tbody_${rowId}`}>
                  <tr
                    {...getTrProps(row.getRowProps(), row)}
                    className={classNames('BaseTable__tbody-row', getTrProps(row.getRowProps(), row).className, {
                      'BaseTable__tbody-row--unclickable': row.isExpanded || !onRowClick,
                    })}
                    onClick={() => onRowClick?.(+row.id + 1, row)}>
                    {row.cells.map((cell, cellId) => (
                      <td {...cell.getCellProps()} key={`tbody_${rowId}_${cellId}`} className="BaseTable__tbody-cell">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                  {row.isExpanded && (
                    <tr className="BaseTable__expanded-row">
                      <td>{renderRowSubComponent({ row })}</td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
            {!data.length && (
              <FormattedMessage id="noData">
                {txt => (
                  <tr className="BaseTable__noData">
                    <td>
                      <img src={noInfo} className="BaseTable__noData-img" alt="nodata" />
                      <span>{txt}</span>
                    </td>
                  </tr>
                )}
              </FormattedMessage>
            )}
            {isLoading && (
              <FormattedMessage id="justLoading">
                {txt => (
                  <tr className="BaseTable__loading">
                    <td>{txt}</td>
                  </tr>
                )}
              </FormattedMessage>
            )}
          </tbody>
          {hasFooter && (
            <tfoot className="BaseTable__tfoot">
              {footerGroups.map((group, groupId) => (
                <tr {...group.getFooterGroupProps()} key={`tfoot_${groupId}`} className="BaseTable__tfoot-row">
                  {group.headers.map((column, columnId) => (
                    <td
                      {...column.getFooterProps()}
                      key={`tfoot_${groupId}_${columnId}`}
                      className="BaseTable__tfoot-cell">
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      ) : (
        <ResponsiveTable {...props} onRowClick={onRowClick} columns={columns} data={data} isNewTable />
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BaseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      key: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  getToggleAllRowsSelectedProps: PropTypes.object,
  isLoading: PropTypes.bool,
  renderRowSubComponent: PropTypes.func,
  initialSortBy: PropTypes.array,
  noResponsive: PropTypes.bool,
  rows: PropTypes.array,
  onChangeSort: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  getTrProps: PropTypes.func,
  theme: PropTypes.object,
};

BaseTable.defaultProps = {
  data: [],
  isLoading: false,
  renderRowSubComponent: null,
  initialSortBy: [],
  noResponsive: false,
  getTrProps: props => props,
  onChangeSort: () => {},
  onRowClick: null,
  onRowSelect: null,
  rows: [],
  getToggleAllRowsSelectedProps: {},
  theme: {},
};

export default withTheme()(BaseTable);
export { BaseTable };
