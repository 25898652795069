import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .EditInvestStrategyForm {
    max-width: 360px;
  }
`;
export { staticStyles };
