import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { staticStyles } from './style';

const TextLink = ({ path, textId, buttonLink, onClick }) => (
  <div className="TextLink">
    {path && (
      <Link to={path}>
        <FormattedMessage id={textId} />
      </Link>
    )}
    {buttonLink && (
      <button type="button" onClick={onClick}>
        <FormattedMessage id={textId} />
      </button>
    )}
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

TextLink.propTypes = {
  path: PropTypes.func,
  textId: PropTypes.string.isRequired,
  buttonLink: PropTypes.bool,
  onClick: PropTypes.func,
};

TextLink.defaultProps = {
  path: null,
  onClick: null,
  buttonLink: false,
};

export default TextLink;
