import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

const SafetyIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="4.248%" y1="86.836%" x2="95.826%" y2="18.008%" id="linearGradient-4">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <g id="Symbols" fill="none" fillRule="evenodd">
        <g
          id="menu-/-item-/-account-type"
          transform="translate(-26 -13)"
          fill="url(#linearGradient-4)"
          fillRule="nonzero">
          <g transform="translate(26 13)">
            <path d="M10.6111111,9.73388773 L10.6111111,0.561330561 C10.6111111,0.251316253 10.3528139,1.89829015e-17 10.034188,0 C9.71556222,-1.89829015e-17 9.45726496,0.251316253 9.45726496,0.561330561 L9.45726496,9.73388773 C8.0898396,10.0019107 7.1063012,11.1708705 7.1063012,12.5280665 C7.1063012,13.8852626 8.0898396,15.0542224 9.45726496,15.3222453 L9.45726496,19.4054054 C9.45726496,19.7154197 9.71556222,19.966736 10.034188,19.966736 C10.3528139,19.966736 10.6111111,19.7154197 10.6111111,19.4054054 L10.6111111,15.3222453 C11.9785365,15.0542224 12.9620749,13.8852626 12.9620749,12.5280665 C12.9620749,11.1708705 11.9785365,10.0019107 10.6111111,9.73388773 Z" />
            <path d="M3.54700855,5.02702703 L3.54700855,0.561330561 C3.54700855,0.251316253 3.28871129,1.89829015e-17 2.97008547,0 C2.65145965,-1.89829015e-17 2.39316239,0.251316253 2.39316239,0.561330561 L2.39316239,5.02702703 C1.02573704,5.29504995 0.0421986351,6.46400976 0.0421986351,7.82120582 C0.0421986351,9.17840189 1.02573704,10.3473617 2.39316239,10.6153846 L2.39316239,19.4054054 C2.39316239,19.7154197 2.65145965,19.966736 2.97008547,19.966736 C3.28871129,19.966736 3.54700855,19.7154197 3.54700855,19.4054054 L3.54700855,10.6112266 C4.91412084,10.3441688 5.89778843,9.17583398 5.89778843,7.81912682 C5.89778843,6.46241966 4.91412084,5.29408481 3.54700855,5.02702703 Z" />
            <path d="M17.6324786,5.02702703 L17.6324786,0.56133056 C17.6324786,0.251316265 17.3741814,3.24074909e-08 17.0555556,3.24074908e-08 C16.7369298,3.24074908e-08 16.4786325,0.251316265 16.4786325,0.56133056 L16.4786325,5.02702703 C15.1112071,5.29504995 14.1276687,6.46400975 14.1276687,7.82120582 C14.1276687,9.17840189 15.1112071,10.3473617 16.4786325,10.6153846 L16.4786325,19.4095634 C16.4786325,19.7195777 16.7369297,19.970894 17.0555556,19.970894 C17.3741814,19.970894 17.6324786,19.7195777 17.6324786,19.4095634 L17.6324786,10.6112266 C18.9995909,10.3441688 19.9832585,9.17583397 19.9832585,7.81912682 C19.9832585,6.46241966 18.9995909,5.29408481 17.6324786,5.02702703 Z" />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

SafetyIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

SafetyIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedSafetyIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(SafetyIconActive);

export default ThemedSafetyIconActive;
