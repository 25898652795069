import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_INTERNAL_TRANSFERS = createRequestTypes('GET_INTERNAL_TRANSFERS');
export const getInternalTransfers = {
  request: data => action(GET_INTERNAL_TRANSFERS[REQUEST], data),
  success: data => action(GET_INTERNAL_TRANSFERS[SUCCESS], { data }),
  failure: error => action(GET_INTERNAL_TRANSFERS[FAILURE], { error }),
};

export const GET_TRANSFER_DETAILS = createRequestTypes('GET_TRANSFER_DETAILS');
export const getTransferDetails = {
  request: data => action(GET_TRANSFER_DETAILS[REQUEST], data),
  success: data => action(GET_TRANSFER_DETAILS[SUCCESS], { data }),
  failure: error => action(GET_TRANSFER_DETAILS[FAILURE], { error }),
};

export const ACTION_TRANSFER = createRequestTypes('ACTION_TRANSFER');
export const actionTransfer = {
  request: (id, actionType) => action(ACTION_TRANSFER[REQUEST], { id, actionType }),
  success: data => action(ACTION_TRANSFER[SUCCESS], { data }),
  failure: error => action(ACTION_TRANSFER[FAILURE], { error }),
};

export const rejectTransfer = createFormAction('REJECT_TRANSFER');

export const editTransfer = createFormAction('EDIT_TRANSFER');
