import css from "styled-jsx/css";
import { isRTL } from "common/styling/theme";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .CompanyNews {
    padding-left: ${isRTL ? 0 : "30px"};
    padding-right: ${isRTL ? "30px" : 0};

    margin-top: 40px;

    @include respond-to(md) {
      display: none;
    }

    &__title {
      font-size: rem(18px);
      margin-bottom: 20px;
    }

    :global(&__slider) {
      cursor: n-resize;
    }

    &__item {
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.06);
      border-radius: 3px;
      outline: none;
      margin-bottom: 10px;
    }

    &__date {
      font-weight: 300;
      font-size: rem(11px);
      line-height: rem(13px);
      margin-bottom: 10px;
    }

    &__name {
      font-size: rem(13px);
      line-height: rem(15px);
      margin-bottom: 10px;
      max-height: 45px;
      height: 45px;
      overflow: hidden;
    }

    :global(&__link) {
      font-size: rem(11px);
      line-height: rem(13px);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    :global(.slick-arrow) {
      position: absolute;
      font-size: 0;
      width: 10px;
      height: 10px;
      border: 1px solid #000000;
      transform: rotate(45deg);
      top: -35px;
    }

    :global(.slick-prev) {
      border-right: none;
      border-bottom: none;
      top: -35px;
      right: ${isRTL ? "auto" : "30px"};
      left: ${isRTL ? "30px" : "auto"};
    }

    :global(.slick-next) {
      border-left: none;
      border-top: none;
      top: -40px;
      right: ${isRTL ? "auto" : "0"};
      left: ${isRTL ? "0" : "auto"};
    }
  }

  .CompanyNews__item {
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .CompanyNews {
      :global(.slick-disabled) {
        border-color: ${colors.gray.hex} !important;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
