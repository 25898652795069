import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseIconButton {
    min-width: 24px;
    min-height: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border-radius: 8px;

    svg {
      width: 14px;
      height: 14px;
    }

    &__check {
      padding: 10px 8px;
    }

    &__plus {
      padding: 6px;
      border-radius: 3px;

      &::after,
      &::before {
        content: '';
        position: absolute;
        width: rem(12px);
        height: rem(2px);
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
      }

      &::after {
        transform: rotate(-90deg);
        top: 46%;
      }
    }

    &__cancel {
      width: 32px;
      height: 32px;

      &::after {
        content: '';
        position: absolute;
        top: 56%;
        left: 50%;
        width: rem(8px);
        height: rem(8px);
        border-style: solid;
        border-width: 1px 0 0 1px;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .BaseIconButton {
      border: 1px solid;
      border-color: ${colors.primary.hex};

      &:hover {
        border-color: #0990cb;
      }

      &__plus {
        &::after,
        &::before {
          background-color: ${colors.primary.hex};
        }
      }

      &__check {
        background-color: ${colors.primary.hex};

        &:hover {
          background-color: #0990cb;
        }
      }

      &__cancel {
        &::after {
          border-color: ${colors.black.hex};
        }
      }

      &__red {
        border-color: #ee3235;

        &:hover {
          background: #ee3235;
          border-color: #ee3235;

          svg path {
            fill: ${colors.white.hex};
          }
        }

        svg path {
          fill: #ee3235;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
