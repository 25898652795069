import css from 'styled-jsx/css'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ReactModal__Body--open {
    overflow-y: hidden;
    padding-right: 15px;
  }
  
  @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
    .ReactModal__Body--open {
      padding: 0 !important;
    }
  }

  .ColorPickerField {
    .ColorPicker__inner {
      width: rem(170px);
      height: rem(40px);
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .sketch-picker {
    width: 600px !important;
    margin: 0 auto !important;
    box-shadow: none !important;
    @include respond-to(xl) {
      width: 500px !important;
    }
    @include respond-to(md) {
      width: 350px !important;
    }
    @include respond-to(xs) {
      width: 250px !important;
    }
    .flexbox-fix {
      border: none !important;
      &:last-child {
        display: none !important;
      }
    }
  }

  .ColorPickerField__reset-but {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: rem(12px);
    margin-top: 3px;
    @media only screen and (max-width: 1260px) {
      margin: 0 auto;
    }
  }

  .ReactModal__Overlay--after-open {
    z-index: 34;
    background-color: rgba(0, 0, 0, 0.4) !important;
  }

  .ReactModal__Content--after-open {
    border: none !important;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0 !important;
    padding: 45px 50px 30px !important;
    top: 50% !important;
    bottom: auto !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    right: auto !important;
    text-align: center;
    @include respond-to(md) {
      padding: 30px 40px 15px !important;
    }
    @include respond-to(sm) {
      padding: 40px 10px 20px !important;
      left: 15px !important;
      right: 15px !important;
      transform: translate(0, -50%);
    }
    @media only screen and (max-height: 420px) {
      bottom: 10px !important;
      top: 10px !important;
      transform: translate(0, 0);
    }
    @media only screen and (min-width: 1540px) and (max-height: 620px) {
      bottom: 10px !important;
      top: 10px !important;
      transform: translate(-50%, 0);
    }
    @media only screen and (min-width: 1150px) and (max-height: 540px) {
      bottom: 10px !important;
      top: 10px !important;
      transform: translate(-50%, 0);
    }
    @media only screen and (min-width: 767px) and (max-height: 420px) {
      bottom: 10px !important;
      top: 10px !important;
      transform: translate(-50%, 0);
    }
  }

  .Modal__close {
    position: absolute;
    z-index: 2;
    transition: all 0.4s ease;
    width: 28px;
    height: 28px;
    top: 12px;
    right: 12px;
    @include respond-to(md) {
      top: 8px;
      right: 5px;
    }
    span {
      border: none !important;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 32px;
        height: 1px;
        top: 12px;
        left: 0;
        @include respond-to(md) {
          width: 24px;
          height: 1px;
        }
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .ColorPicker__inner {
      border: 1px solid ${colors.lightGray.hex};
    }

    .ColorPickerField__reset-but {
      color: ${colors.primary.hex};
      &:hover {
        color: ${colors.secondary.hex};
      }
    }

    .Modal__close {
      span {
        &:before,
        &:after {
          background: ${colors.black.hex};
        }
      }
      &:hover {
        span {
          &:before,
          &:after {
            background: ${colors.error.hex};
          }
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
