import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { DefaultButton, BaseSelectField } from 'common/components';

import { BasicModal } from 'common/components/modals';
import { staticStyles, getDynamicStyles } from './style';

const TextAreaRawHtmlField = ({ isIndependent, input, meta: { touched, error }, schema, disabled, theme }) => {
  const textareaRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isInsertTemplateOpen, setIsInsertTemplateOpen] = useState(false);
  const [insertIndex, setInsertIndex] = useState(0);

  const { description, content, title } = useMemo(() => schema.variables[selectedTemplate] || {}, [selectedTemplate]);

  useEffect(() => {
    const setInsertIndexFunc = () => {
      if (textareaRef?.current?.selectionStart) {
        setInsertIndex(textareaRef.current.selectionStart);
      }
    };
    document.addEventListener('click', setInsertIndexFunc);
    return () => document.removeEventListener('click', setInsertIndexFunc);
  }, []);

  const toggleModal = useCallback(() => {
    setIsInsertTemplateOpen(!isInsertTemplateOpen);
  }, [isInsertTemplateOpen]);

  const textAreaRawHtmlField = classNames('TextAreaRawHtmlField', { 'TextAreaRawHtmlField--size-big': isIndependent });
  const textareaClass = classNames('TextAreaRawHtmlField__textarea', {
    'TextAreaRawHtmlField__textarea--error': error,
    'TextAreaRawHtmlField__textarea--size-big': isIndependent,
  });

  const handleTextAreaInsert = value => {
    const textAreaValue = textareaRef.current.value;
    const newValue = `${textAreaValue.slice(0, insertIndex)}${value}${textAreaValue.slice(insertIndex)}`;
    textareaRef.current.value = newValue;
    input.onChange(newValue);
    setTimeout(() => {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = insertIndex + value.length;
      textareaRef.current.selectionEnd = insertIndex + value.length;
    }, 0);
  };

  const handleInsert = () => {
    handleTextAreaInsert(content);
    setIsInsertTemplateOpen(false);
  };

  return (
    <div className={textAreaRawHtmlField}>
      <label className="TextAreaRawHtmlField__label">
        <FM id={schema.description} />
      </label>
      {!!schema.variables.length && (
        <DefaultButton type="button" textId="categoryEmailInsertTemplate" onClick={toggleModal} noBorder />
      )}
      <textarea
        ref={textareaRef}
        id={input.name}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        disabled={disabled}
        onChange={e => {
          input.onChange(e.target.value);
        }}
        className={textareaClass}
        defaultValue={input.value}
      />
      <span className="TextAreaRawHtmlField__error-message">{touched && error}</span>
      <BasicModal isOpen={isInsertTemplateOpen} captionId="selectTemplateToInseert" onRequestClose={toggleModal}>
        <div className="InsertTemplate">
          <div className="InsertTemplate__template-selector">
            <span>
              <FM id="justTemplates" />
            </span>
            <BaseSelectField
              value={{ label: title, value: selectedTemplate }}
              name="templateselector"
              textId="justTemplate"
              options={schema.variables.map(({ title }, index) => ({
                label: title,
                value: index,
              }))}
              onChange={({ value }) => {
                setSelectedTemplate(value);
              }}
            />
          </div>
          <span className="InsertTemplate__description">{description}</span>
          <textarea className="InsertTemplate__textarea" disabled value={content} />
          <DefaultButton type="button" textId="categoryEmailInsertTemplate" onClick={handleInsert} />
        </div>
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TextAreaRawHtmlField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  isIndependent: PropTypes.bool,
  schema: PropTypes.object,
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

TextAreaRawHtmlField.defaultProps = {
  theme: {},
  schema: { variables: [] },
  disabled: false,
  isIndependent: false,
};

export default withTheme()(TextAreaRawHtmlField);
export { TextAreaRawHtmlField };
