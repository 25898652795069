import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .StrategyInfo {
    position: relative;
    border-radius: 3px;
    padding: 30px 25px 25px 25px;
    margin: 0 0 2px 0;

    @include respond-to(sm) {
      padding: 17px 22px 15px 22px;
      margin: 0;
    }

    @include min-width(768) {
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
      }
    }

    &__title {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.87);
      margin: 0 0 25px 0;

      @include respond-to(sm) {
        font-size: 20px;
        margin: 0 0 20px 0;
      }
    }

    &__info-wrap {
      display: flex;

      @include respond-to(sm) {
        display: block;
      }
    }

    &__info-left {
      width: 50%;

      @include respond-to(sm) {
        width: 100%;
      }
    }

    &__info-item {
      display: flex;
      margin: 0 0 20px 0;

      @include respond-to(sm) {
        margin: 0 0 15px 0;
      }

      &:last-child {
        margin: 0 0 0 0;
      }

      @include respond-to(sm) {
        .StrategyInfo__info-left &:last-child {
          margin: 0 0 15px 0;
        }
      }
    }

    &__info-item-title {
      min-width: 150px;
      width: 30%;
      margin: 0 10px 0 0;
      font-size: 13px;
      line-height: 18px;
      color: #777777;

      @include respond-to(sm) {
        min-width: 0;
        width: 50%;
      }
    }

    &__info-item-value {
      width: 70%;
      font-size: 15px;
      line-height: 17px;
      color: #1D1D1D;

      @include respond-to(sm) {
        width: 50%;
      }

      &.Monthly-yield,
      &.Yield {
        font-size: 17px;
        line-height: 20px;

        @include respond-to(sm) {
          font-weight: 500;
          font-size: 15px;
        }
      }
    }

    &__info-right {
      width: 50%;

      @include respond-to(sm) {
        width: 100%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .StrategyInfo {
      background-color: ${colors.white.hex};

      @include min-width(768) {
        &:after {
          background: ${gradient(90, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
      }

      &__info-item-value {
        &.Monthly-yield,
        &.Yield {
          color: ${colors.success.hex};

          &.negative {
            color: ${colors.error.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
