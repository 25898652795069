import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ContractSpecsTable {
    .ReactDataTable {
      .ReactDataTable__thead {
        .ReactDataTable__thead-cell {
          text-align: center;
        }
      }

      .ReactDataTable__tbody {
        .ReactDataTable__tbody-cell {
          justify-content: center;

          > div {
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
          }

          span {
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;

            &.light {
              font-weight: 300;
            }
          }
        }
      }
    }
  }

  .ContractSpecsTable {
    .ReactDataTable {
      overflow: auto;
      display: block;

      &__thead-row {
        grid-template-columns:
          minmax(50px, 50px)
          minmax(90px, 1fr)
          minmax(200px, 1fr)
          minmax(90px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(80px, 1fr)
          minmax(120px, 1fr)
          minmax(100px, 1fr)
          minmax(100px, 1fr)
          minmax(180px, 1fr) !important;
      }
    }
  }

  .ContractSpecsTable--no-comments {
    .ReactDataTable {
      &__thead-row {
        grid-template-columns:
          minmax(0, 0)
          minmax(90px, 1fr)
          minmax(200px, 1fr)
          minmax(90px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(80px, 1fr)
          minmax(120px, 1fr)
          minmax(100px, 1fr)
          minmax(100px, 1fr)
          minmax(180px, 1fr) !important;
      }
    }
  }

  .ContractSpecsTable {
    .ReactDataTable {
      &__thead-cell {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__tbody-row {
        grid-template-columns:
          minmax(50px, 50px)
          minmax(90px, 1fr)
          minmax(200px, 1fr)
          minmax(90px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(80px, 1fr)
          minmax(120px, 1fr)
          minmax(100px, 1fr)
          minmax(100px, 1fr)
          minmax(180px, 1fr) !important;
      }
    }
  }

  .ContractSpecsTable--no-comments {
    .ReactDataTable {
      &__tbody-row {
        grid-template-columns:
          minmax(0, 0)
          minmax(90px, 1fr)
          minmax(200px, 1fr)
          minmax(90px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(80px, 1fr)
          minmax(120px, 1fr)
          minmax(100px, 1fr)
          minmax(100px, 1fr)
          minmax(180px, 1fr) !important;
      }
    }
  }

  .ContractSpecsTable {
    .ReactDataTable {
      &__tbody-cell {
        overflow: visible !important;
        text-overflow: clip !important;

        &:nth-of-type(1) {
          padding: 15px 0 15px 22px;
        }

        span {
          overflow: visible !important;
          text-overflow: clip !important;
          white-space: normal !important;
        }

        .comment {
          position: relative;
          overflow: visible !important;
          z-index: 1;
          line-height: 0;

          &--hide {
            .comment__text,
            .comment__angle {
              display: none;
            }
          }

          &__icon {
            cursor: pointer;

            &--nocomment {
              filter: grayscale(100%);
              cursor: default;
              opacity: 0.2;
              display: none;
            }
          }

          &__text {
            position: absolute;
            top: 50%;
            transform: translateY(-20px);
            left: calc(100% + 17px);
            background: #fff;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
            padding: 15px 17px 15px 17px;
            width: 310px;
            font-size: 11px;
            line-height: 18px;
            color: #313131;
            white-space: normal;
            text-align: left;
            cursor: pointer;
          }

          &__angle {
            position: absolute;
            top: 50%;
            left: calc(100%);
            transform: scale(-1, 1);
          }
        }
      }
    }

    .ResponsiveTable {
      margin: 10px 0 0 0;

      &__thead {
        span {
          padding: 10px 0 10px 0;
          flex: 0 1 auto !important;
          text-align: left !important;

          &:nth-of-type(1) {
            width: 50px;
          }
        }
      }
    }
  }

  .ContractSpecsTable--no-comments {
    .ResponsiveTable {
      &__thead {
        span {
          &:nth-of-type(1) {
            width: 0;
          }
        }
      }
    }
  }

  .ContractSpecsTable {
    .ResponsiveTable {
      &__thead {
        span {
          &:nth-of-type(2) {
            min-width: 20%;
          }
        }
      }
    }
  }

  .ContractSpecsTable--no-comments {
    .ResponsiveTable {
      &__thead {
        span {
          &:nth-of-type(2) {
            padding: 10px 10px 10px 10px;
          }
        }
      }
    }
  }

  .ContractSpecsTable {
    .ResponsiveTable {
      &__thead {
        span {
          &:nth-of-type(3) {
            flex: 1 1 auto !important;
          }
        }
      }

      &__tbody {}

      &__row {
        box-shadow: none !important;
        border-radius: none !important;

        &:nth-of-type(2n+1) {
          background: #f9f9f9;
        }
      }

      &__row-preview {
        padding: 0 0 0 0 !important;
      }

      &__preview-main {
        padding: 0 0 0 0 !important;
        display: flex !important;
        height: auto !important;
      }

      &__row-item {
        flex: 0 1 auto !important;
        padding: 10px 10px 10px 0;
        white-space: normal !important;
        box-sizing: border-box !important;
        display: block !important;

        &:nth-of-type(1) {
          width: 50px;
          padding: 15px 15px 15px 15px;

          .comment {
            position: relative;
            overflow: visible !important;
            z-index: 1;
            line-height: 0;

            &--hide {
              .comment__text,
              .comment__angle {
                display: none;
              }
            }

            &__icon {
              cursor: pointer;

              &--nocomment {
                filter: grayscale(100%);
                cursor: default;
                opacity: 0.2;
                display: none;
              }
            }

            &__text {
              position: absolute;
              top: 50%;
              transform: translateY(-20px);
              left: calc(100% + 17px);
              background: #fff;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
              padding: 15px 17px 15px 17px;
              width: 220px;
              font-size: 11px;
              line-height: 18px;
              color: #313131;
              white-space: normal;
              text-align: left;
              cursor: pointer;
            }

            &__angle {
              position: absolute;
              top: 50%;
              left: calc(100%);
              transform: scale(-1, 1);
            }
          }
        }
      }
    }
  }

  .ContractSpecsTable--no-comments {
    .ResponsiveTable {
      &__row-item {
        &:nth-of-type(1) {
          width: 0;
          padding: 0 0 0 0;
        }
      }
    }
  }

  .ContractSpecsTable {
    .ResponsiveTable {
      &__row-item {
        &:nth-of-type(2) {
          min-width: 20%;
        }
      }
    }
  }

  .ContractSpecsTable--no-comments {
    .ResponsiveTable {
      &__row-item {
        &:nth-of-type(2) {
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }

  .ContractSpecsTable {
    .ResponsiveTable {
      &__row-item {
        &:nth-of-type(3) {
          flex: 1 1 auto !important;
          min-width: 50%;
          padding: 15px 30px 15px 0;
        }

        span {
          white-space: normal;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ContractSpecsTable {
      .ReactDataTable tr {
        grid-template-columns: minmax(100px, 1.2fr) minmax(100px, 3fr) repeat(7, minmax(100px, 1fr)) minmax(
            100px,
            2.2fr
          );
      }
    }

    .ContractSpecsTable {
      .ReactDataTable {
        &__tbody-cell {
          .comment {
            &__icon {
              path {
                fill: ${colors.primary.hex};
              }

              circle {
                stroke: ${colors.primary.hex};
              }
            }
          }
        }
      }

      .ResponsiveTable {
        &__row-item {
          .comment {
            &__icon {
              path {
                fill: ${colors.primary.hex};
              }
              circle {
                stroke: ${colors.primary.hex};
              }
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
