import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Chart from 'react-apexcharts';

import { getFixedNumber } from 'common/dashboards/helpers';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const AreaChart = ({ theme, lastFunnels, dailyFunnels, currentMonth, lastMonth, tomorrow }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const deposited = [];
  let depositedAcc = 0;
  let isRealData = true;
  const days = [];
  Object.entries(dailyFunnels).forEach(([key, value]) => {
    if (key === tomorrow) {
      isRealData = false;
    }
    if (isRealData) {
      days.push(key);
      depositedAcc = value.deposited_amount + depositedAcc;
      deposited.push(depositedAcc);
    }
  });
  const { deposited_amount: currentDeposited = 0 } = lastFunnels[currentMonth] || {};
  const { deposited_amount: lastMonthDeposited = 0 } = lastFunnels[lastMonth] || {};

  const options = {
    chart: {
      fontFamily: 'ubuntu-font, sans-serif',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      foreColor: '#fff',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: false,
      },
      categories: days,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      decimalsInFloat: 2,
    },
    colors: ['#F27186'],
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      y: {
        title: {
          formatter() {
            return '';
          },
        },
      },
    },
    grid: {
      show: false,
    },
  };

  const series = [
    {
      name: 'series1',
      data: deposited,
    },
  ];

  return (
    <div className="AreaChart">
      <div className="AreaChart__description">
        <div className="AreaChart__caption">
          <FormattedMessage id="boDeposit">{txt => <h4>{txt}</h4>}</FormattedMessage>
        </div>
        <div className="AreaChart__data-line">
          <FormattedMessage id="justNow" />
          <p>{getFixedNumber(currentDeposited, 2)}</p>
        </div>
        <div className="AreaChart__data-line">
          <FormattedMessage id="lastMonth" />
          <p>{getFixedNumber(lastMonthDeposited, 2)}</p>
        </div>
      </div>
      <div className="AreaChart__chart">
        <Chart className="AreaChartInner" options={options} series={series} type="area" height="200" />
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

AreaChart.propTypes = {
  theme: PropTypes.object,
  lastFunnels: PropTypes.object,
  dailyFunnels: PropTypes.object,
  currentMonth: PropTypes.string.isRequired,
  lastMonth: PropTypes.string.isRequired,
  tomorrow: PropTypes.string.isRequired,
};

AreaChart.defaultProps = {
  theme: {},
  lastFunnels: {},
  dailyFunnels: {},
};

export default withTheme()(AreaChart);
export { AreaChart };
