import css from 'styled-jsx/css';

const staticStyles = css`
  .FinishTaskForm__content {
    max-width: 360px;
  }

  .FinishTaskForm {
    &__btns {
      display: flex;
      justify-content: space-between;

      :global(.DefaultButton) {
        width: 48%;
      }
    }
  }
`;

export default staticStyles;
