import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TransferDetails {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 60px 10px 30px);
    margin-bottom: rem(20px);
    position: relative;
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      gap: 12px;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 0 5px;
        span {
          font-size: rem(13px);
          display: block;
          margin-bottom: 5px;
        }
        &:first-child {
          font-size: rem(18px);
        }
      }
    }

    &__oldWallet {
      display: flex;
      align-items: center;
      gap: 2px;

      &-tooltip {
        position: relative;
        top: -6px;
        height: 20px;

        span {
          margin-bottom: 0;
        }

        @include respond-to(sm) {
          width: initial !important;
        }

        @include respond-to(xs) {
          top: -1px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .TransferDetails__caption {
      font-size: 18px;
      margin-bottom: rem(25px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .TransferDetails {
      background-color: ${colors.white.hex};
      ul {
        li {
          span {
            color: ${colors.gray.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
