import React from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';
import Tooltip from '../../Tooltip';
import ArrowIcon from './icons/ArrowIcon';

const LeftMenuItem = ({ title, place, Icon, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <div className="LeftMenu__link">
      <div className="LeftMenu__icon">{typeof Icon !== 'undefined' && <Icon />}</div>
      <div className="LeftMenu__title">
        <FormattedMessage id={title}>{txt => <span className="LeftMenu__titele-text">{txt}</span>}</FormattedMessage>
        <Tooltip id="linkTooltip" textId="welcomeSetupMenuTooltip" place={place} />
      </div>
      <ArrowIcon />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

LeftMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

LeftMenuItem.defaultProps = {
  theme: {},
};

export default withTheme()(LeftMenuItem);
