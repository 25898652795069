import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_DYNAMIC_RESOURCE_LIST = createRequestTypes('GET_DYNAMIC_RESOURCE_LIST');
export const getDynamicResourceList = {
  request: search => action(GET_DYNAMIC_RESOURCE_LIST[REQUEST], { search }),
  success: data => action(GET_DYNAMIC_RESOURCE_LIST[SUCCESS], { data }),
  failure: error => action(GET_DYNAMIC_RESOURCE_LIST[FAILURE], { error }),
};

export const createDynamicResource = createFormAction('CREATE_DYNAMIC_RESOURCE');

export const saveDynamicResource = createFormAction('SAVE_DYNAMIC_RESOURCE');

export const saveDynamicSetting = createFormAction('SAVE_DYNAMIC_SETTINGS');
