import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { required, email } from 'redux-form-validators';

import { DefaultButton, ReduxTextField } from 'common/components';

import { requestPasswordReset } from '../../_redux/actions';

import { staticStyles } from './style';

const RequestResetPasswordForm = ({ handleSubmit, pristine, submitting, dispatch, invalid, error }) => {
  const submit = handleSubmit(values => {
    values.email = values.email.toLowerCase();
    return requestPasswordReset(values, dispatch);
  });
  return (
    <form className="RequestResetPasswordForm" onSubmit={submit}>
      <Field
        name="email"
        type="email"
        textId="authEmail"
        component={ReduxTextField}
        validate={[required(), email()]}
        disabled={submitting}
      />
      <div className="RequestResetPasswordForm__button-inner">
        <DefaultButton
          textId="authDoResetPassword"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

RequestResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

RequestResetPasswordForm.defaultProps = {
  error: '',
};

export default withRouter(
  reduxForm({
    form: 'request-password-reset-form',
    onSubmitSuccess: (result, dispatch, { history }) => {
      history.push('/auth/login?message=authPasswdResetMailSent');
    },
  })(RequestResetPasswordForm)
);
export { RequestResetPasswordForm };
