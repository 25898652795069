import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .StartChallengeForm {
    @media only screen and (min-width: 1000px) {
      width: 700px;
    }
  }

  .StartChallengeForm__TopText {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    margin-bottom: 16px;
  }

  .StartChallengeFormTable table {
    margin-bottom: 20px;
  }

  .StartChallengeFormTable__Header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .StartChallengeFormTable__name {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    opacity: 0.3;
  }

  .StartChallengeFormTable__name--fullOpacity {
    opacity: 1;
  }

  .StartChallengeFormTable__name:hover {
    cursor: pointer;
    opacity: 1;
  }

  .StartChallengeFormTable__name > .Round {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 16px;
  }

  .StartChallengeFormTable__name > .Name {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .StartChallengeFormTable__Header > .StartChallengeFormTable__name :nth-child(1) .Round {
    border: 2px rgb(10, 161, 226) solid;
  }

  .StartChallengeFormTable__Header > .StartChallengeFormTable__name :nth-child(2) .Round {
    border: 2px rgb(255, 153, 0) solid;
  }
  .StartChallengeFormTable__Header > .StartChallengeFormTable__name :nth-child(3) .Round {
    border: 2px rgb(57, 184, 65) solid;
  }

  .StartChallengeForm table tr:first-child {
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .StartChallengeForm table tr:nth-child(2) {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .StartChallengeForm table tr td {
    height: 44px;
    text-align: center;
    border: 1px solid #eeeeee !important;
    border-collapse: collapse !important;
  }

  .StartChallengeForm table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .StartChallengeForm__RefundableFee {
    display: flex;
    align-items: center;
    gap: 12px;
    @media only screen and (min-width: 1200px) {
      grid-column: 1/1;
      grid-row: 1/1;
    }
  }

  .StartChallengeForm__RefundableFee > span {
    color: #000;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }

  .StartChallengeForm__RefundableFee > div {
    color: #0aa1e2;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .StartChallengeForm__LeftText {
    color: rgba(0, 0, 0, 0.5);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    @media only screen and (min-width: 1200px) {
      grid-column: 1/1;
      grid-row: 2/2;
    }
  }

  .StartChallengeForm__bottom {
    display: grid;
    row-gap: 8px;
    column-gap: 60px;
    @media only screen and (min-width: 1200px) {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
    }
  }

  .StartChallengeForm__bottom > .DivWithDefButton {
    min-width: 200px;
    @media only screen and (min-width: 1200px) {
      grid-column: 2/2;
      grid-row: 1/3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

export { staticStyles };
