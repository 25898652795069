import React from 'react';

const IconTrash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M4.94102 5.81602C5.11055 5.64375 5.38945 5.64375 5.55898 5.81602L7 7.25703L8.44102 5.81602C8.61055 5.64375 8.88945 5.64375 9.05898 5.81602C9.23125 5.98555 9.23125 6.26445 9.05898 6.43398L7.61797 7.875L9.05898 9.31602C9.23125 9.48555 9.23125 9.76445 9.05898 9.93398C8.88945 10.1062 8.61055 10.1062 8.44102 9.93398L7 8.49297L5.55898 9.93398C5.38945 10.1062 5.11055 10.1062 4.94102 9.93398C4.76875 9.76445 4.76875 9.48555 4.94102 9.31602L6.38203 7.875L4.94102 6.43398C4.76875 6.26445 4.76875 5.98555 4.94102 5.81602ZM9.3543 0.616875L10.0871 1.75H12.6875C12.9281 1.75 13.125 1.94578 13.125 2.1875C13.125 2.42922 12.9281 2.625 12.6875 2.625H12.25V11.8125C12.25 13.0211 11.2711 14 10.0625 14H3.9375C2.72945 14 1.75 13.0211 1.75 11.8125V2.625H1.3125C1.07089 2.625 0.875 2.42922 0.875 2.1875C0.875 1.94578 1.07089 1.75 1.3125 1.75H3.91289L4.62109 0.616875C4.86172 0.233133 5.28281 0 5.73398 0H8.26602C8.71719 0 9.13828 0.233133 9.3543 0.616875ZM4.94648 1.75H9.05352L8.63516 1.08063C8.55586 0.952656 8.41641 0.875 8.26602 0.875H5.73398C5.58359 0.875 5.44414 0.952656 5.36484 1.08063L4.94648 1.75ZM2.625 11.8125C2.625 12.5371 3.21262 13.125 3.9375 13.125H10.0625C10.7871 13.125 11.375 12.5371 11.375 11.8125V2.625H2.625V11.8125Z"
      fill="#EE3235"
    />
  </svg>
);

export default IconTrash;
