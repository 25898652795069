import React from 'react';

const TaskIcon = () => (
  <svg className="TaskIcon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 6.90654L22.1724 9L26 5"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5728 13H2.42555C1.63358 13 1 12.3684 1 11.5789V2.42105C1 1.63158 1.63358 1 2.42555 1H11.5728C12.3648 1 12.9983 1.63158 12.9983 2.42105V11.5395C13.0379 12.3289 12.3648 13 11.5728 13Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5728 13H18.4255C17.6336 13 17 12.3684 17 11.5789V2.42105C17 1.63158 17.6336 1 18.4255 1H27.5728C28.3648 1 28.9983 1.63158 28.9983 2.42105V11.5395C29.0379 12.3289 28.3648 13 27.5728 13Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5728 29H2.42555C1.63358 29 1 28.3684 1 27.5789V18.4211C1 17.6316 1.63358 17 2.42555 17H11.5728C12.3648 17 12.9983 17.6316 12.9983 18.4211V27.5395C13.0379 28.3289 12.3648 29 11.5728 29Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5728 29H18.4255C17.6336 29 17 28.3684 17 27.5789V18.4211C17 17.6316 17.6336 17 18.4255 17H27.5728C28.3648 17 28.9983 17.6316 28.9983 18.4211V27.5395C29.0379 28.3289 28.3648 29 27.5728 29Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 22.9065L6.17241 25L10 21"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TaskIcon;
