import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestStrategiesTable {
    &.full {
      .ReactDataTable__wrapper .ReactDataTable tr {
        grid-template-columns: repeat(9, minmax(100px, 1fr)) 150px !important;
        grid-template-rows: 67px !important;
      }
    }

    &:not(.full) {
      .ReactDataTable__wrapper .ReactDataTable tr {
        grid-template-columns: repeat(8, minmax(100px, 1fr)) 150px !important;
        grid-template-rows: 67px !important;
      }
    }

    &__cell {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      &--font-light {
        font-weight: 300;
      }
      &--font-medium {
        font-weight: 500;
      }
      &--investor-number {
        font-size: 11px;
        line-height: 18px;
      }
      &--link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      &--edit {
        width: 100%;
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        svg {
          width: 15px;
          flex-shrink: 0;
        }
      }
    }
    &__text {
      max-width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .ReactDataTable__tbody {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    &__cell-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .ReactDataTable__thead-cell,
    .ReactDataTable__tbody-cell {
      display: flex;
      justify-content: center;
      text-align: center !important;
    }

    .ReactDataTable__tbody-row {
      margin: 0 !important;
      cursor: auto !important;
    }

    .ReactDataTable__thead-cell:not(.ReactDataTable__thead-cell--sortable) {
      cursor: auto !important;
    }

    @include respond-to(lg) {
      .ReactDataTable__wrapper {
        overflow-x: auto;
      }
    }
  }

  .SearchPanel {
    margin-bottom: 25px !important;

    @include respond-to(sm) {
      &:first-child {
        margin-bottom: 15px !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .InvestStrategiesTable {
      &__cell--blue,
      &__cell--link {
        color: ${colors.primary.hex};
        transition: color 0.2s ease-in-out;
      }

      &__cell--link:hover {
        color: ${colors.secondary.hex};
      }
      &__cell--status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 16px;

        &.active {
          background-color: ${colors.success.hex};
        }
      }
    }
    .status {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: ${colors.gray.hex};
      &.active {
        background-color: ${colors.success.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
