export default [
  {
    nameTicket: 'Название тикета, который открыл клиент',
    dateTicket: '14.06.2020   16:44',
    dateUpdate: 'вчера: 04:12',
    comments: 10,
    status: 'waiting',
  },
  {
    nameTicket: 'Название тикета, который открыл клиент',
    dateTicket: '14.06.2020   16:44',
    dateUpdate: 'вчера: 04:12',
    comments: 10,
    status: 'waiting',
  },
  {
    nameTicket: 'Название тикета, который открыл клиент',
    dateTicket: '14.06.2020   16:44',
    dateUpdate: 'вчера: 04:12',
    comments: 10,
    status: 'answered',
  },
  {
    nameTicket: 'Название тикета, который открыл клиент',
    dateTicket: '14.06.2020   16:44',
    dateUpdate: 'вчера: 04:12',
    comments: 10,
    status: 'answered',
  },
  {
    nameTicket: 'Название тикета, который открыл клиент',
    dateTicket: '14.06.2020   16:44',
    dateUpdate: 'вчера: 04:12',
    comments: 10,
    status: 'closed',
  },
  {
    nameTicket: 'Название тикета, который открыл клиент',
    dateTicket: '14.06.2020   16:44',
    dateUpdate: 'вчера: 04:12',
    comments: 10,
    status: 'closed',
  },
];
