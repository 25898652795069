import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { staticStyles } from './style';

const DepositWithdrawalItem = ({ paymentStatus, title, id, paymentTo, amount, paymentSystemSlug }) => {
  const statuses = {
    success: <FM id="justSuccess" />,
    waiting: <FM id="justInProgress" />,
    failed: <FM id="justError" />,
  };

  return (
    <div>
      <div className={`TabFeedItem__Title TabFeedItem__Title--${paymentStatus}`}>
        <FM id={title}>{txt => `${txt}`}</FM>
      </div>
      <div className="PaymentItem__Description">
        <div className="PaymentItem__accountType">
          {paymentTo?.account_type && (
            <span>
              {paymentTo?.account_type} {paymentTo?.login}
            </span>
          )}
          <div className={classNames('PaymentItem__status', `PaymentItem__status--${paymentStatus}`)}>
            {statuses[paymentStatus]}
          </div>
        </div>
        <div className="PaymentItem__info">
          <div className="PaymentItem__info-title">
            ID: <span className="PaymentItem__info-content">{id}</span>
          </div>
          {paymentTo?.type === 'wallet' && <div>{paymentTo?.type}</div>}
          <div className="PaymentItem__info-title">
            Summ: <span className="PaymentItem__info-content">{`${amount?.amount} ${amount?.currency}`}</span>
          </div>
          <div className="PaymentItem__info-title">
            Payment method: <span className="PaymentItem__info-content">{paymentSystemSlug}</span>
          </div>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

DepositWithdrawalItem.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  paymentTo: PropTypes.object,
  amount: PropTypes.object,
  paymentSystemSlug: PropTypes.string,
};

DepositWithdrawalItem.defaultProps = {
  paymentTo: {},
  amount: {},
  paymentSystemSlug: '',
};

export default DepositWithdrawalItem;
