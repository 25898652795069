import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { getDynamicStyles } from './style';

const NoChallengesPanel = ({ theme, textId = 'justNoActiveChallengesPangel' }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <>
      <div className="NoChallengesPanel__inner">
        <FormattedMessage id={textId}>{txt => <h3>{txt}</h3>}</FormattedMessage>
      </div>
      <style jsx>{dynamicStyles}</style>
    </>
  );
};

NoChallengesPanel.defaultProps = {
  onAddAccountClick: null,
  theme: {},
};

export default withTheme()(NoChallengesPanel);
export { NoChallengesPanel };
