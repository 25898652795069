import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { compose } from 'redux';
import { DateCell, PageContentPreloader, ActionsButton } from 'common/components';
import { NewCRMPagination, UpdatedCRMTable } from 'crm/_newComponents';
import BasicCRMModal from 'crm/_components/BasicCRMModal';
import { RetryIcon, DeclineIcon, CheckedIcon } from 'crm/_newComponents/Icons';
import { staticStyles } from './style';

import ReassignModal from '../ReassignModal';
import ReassignFullNameCell from '../ReassignFullNameCell';

const ReassignsTable = ({ reassignTasks, reassignTasksAreLoaded }) => {
  const [isCLoseOpen, setIsCloseOpen] = useState(false);
  const [selectedReassignTaskId, setSelectedReassignTaskId] = useState(null);
  const [newSelectedReassignTaskStatus, setNewSelectedReassignTaskStatus] = useState(null);

  const tableSetup = {
    columns: [
      {
        accessor: 'fullName',
        Header: 'crmClientName',
        isSortable: true,
        Cell: ({ rowData: { client, status } }) => (
          <ReassignFullNameCell id={client.id} fullName={client.fullName} status={status} />
        ),
      },

      {
        accessor: 'oldManager',
        isSortable: true,
        Header: 'justFrom',
        Cell: ({ rowData: { oldManager } }) =>
          oldManager ? oldManager.fullName : <FM id="crmUserDidNotHaveAManager">{txt => txt}</FM>,
      },
      {
        accessor: 'newManager',
        Header: 'justTo',
        isSortable: true,
        Cell: ({
          rowData: {
            newManager: { fullName },
          },
        }) => fullName,
      },
      {
        accessor: 'creationDate',
        isSortable: true,
        Header: 'crmCreationDate',
        removeInMobile: true,
        Cell: ({ rowData: { created } }) => (created ? <DateCell value={created} /> : '---'),
      },
      {
        accessor: 'createdBy',
        isSortable: true,
        Header: 'crmWasCreatedBy',
        removeInMobile: true,
        Cell: ({
          rowData: {
            actor: { fullName },
          },
        }) => fullName,
      },
      {
        Header: () => {},
        isSortable: false,
        onTopInMobile: true,
        Cell: ({ rowData: { id, status } }) => (
          <div className="actions">
            {status ? (
              <ActionsButton
                onClickFunc={() => {
                  setSelectedReassignTaskId(id);
                  setNewSelectedReassignTaskStatus(status === 'accepted' ? 'declined' : 'accepted');
                  setIsCloseOpen(true);
                }}>
                <RetryIcon />
              </ActionsButton>
            ) : (
              <>
                <ActionsButton
                  onClickFunc={() => {
                    setSelectedReassignTaskId(id);
                    setNewSelectedReassignTaskStatus('accepted');
                    setIsCloseOpen(true);
                  }}>
                  <CheckedIcon green />
                </ActionsButton>
                <ActionsButton
                  onClickFunc={() => {
                    setSelectedReassignTaskId(id);
                    setNewSelectedReassignTaskStatus('declined');
                    setIsCloseOpen(true);
                  }}>
                  <DeclineIcon />
                </ActionsButton>
              </>
            )}
          </div>
        ),
      },
    ],
    initSubRow: [
      {
        Header: 'justReason',
        Cell: ({ rowData: { reason } }) => reason ?? '',
      },
    ],
  };

  /* eslint-enable */

  return (
    <div className="Reassign">
      <PageContentPreloader ready={reassignTasksAreLoaded} firstLaunchOnly type="accounts">
        <UpdatedCRMTable data={reassignTasks?.results} tableSetup={tableSetup} whereOpenSubRowIconIndex={0} />
        {!!reassignTasks?.count && <NewCRMPagination count={reassignTasks?.count} />}
        <BasicCRMModal
          captionId={
            newSelectedReassignTaskStatus === 'accepted' ? 'crmAcceptManagerReassign' : 'crmDeclineManagerReassign'
          }
          isOpen={isCLoseOpen}
          onRequestClose={() => {
            setIsCloseOpen(false);
            setSelectedReassignTaskId(null);
            setNewSelectedReassignTaskStatus(null);
          }}>
          <ReassignModal
            onRequestClose={() => {
              setIsCloseOpen(false);
              setSelectedReassignTaskId(null);
              setNewSelectedReassignTaskStatus(null);
            }}
            taskId={selectedReassignTaskId}
            newStatus={newSelectedReassignTaskStatus}
          />
        </BasicCRMModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(withRouter)(ReassignsTable);
