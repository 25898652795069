import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  :global(.NewCRMTable__wrapper) {
    position: relative;
    width: 100%;
  }
  :global(.NewCRMTable) {
    position: relative;
    display: grid;
    grid-template-rows: 40px 1fr;
  }

  :global(.NewCRMTable__Cell) {
    position: relative;
    min-width: 100%;
    weight: 100%;
    height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  :global(.NewCRMTable__Cell--Tbody) {
    position: relative;
    padding: 6px;
  }
  :global(.NewCRMTable__Cell--Tbody) {
    border-top: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
  }
  :global(.NewCRMTable__Cell--Tbody):last-child {
    border-right: 1px solid #e9e9e9;
  }

  :global(.NewCRMTable__Body-Row-BeforeColor--Green):before {
    content: '';
    position: absolute;
    width: 6px;
    bottom: 0px;
    left: -6px;
    height: 100%;
    background: #04eb84;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }

  :global(.NewCRMTable__Body-Row-BeforeColor--Red):before {
    content: '';
    position: absolute;
    width: 6px;
    bottom: 0px;
    left: -6px;
    height: 100%;
    background: #ea4c4c;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }

  :global(.NewCRMTable__Body-Row-BeforeColor--Gray):before {
    content: '';
    position: absolute;
    width: 6px;
    bottom: 0px;
    left: -6px;
    height: 100%;
    background: #e7e7e7;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }

  :global(.NewCRMTable__Cell--Thead) {
    position: relative;
    line-height: 100%;
    height: 40px;
  }

  :global(.NewCRMTable__Cell--BlueBg) {
    background: #f8faff;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    margin: 0 0.5px 0 0.5px;
  }
  :global(.NewCRMTable__Head-Row),
  :global(.NewCRMTable__Body-Row) {
    position: relative;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  :global(.NewCRMTable__Body-Row) {
    padding-bottom: 1px;
  }

  :global(.NewCRMTable__Body-Row--Green) {
    background: #49d2920d;
  }

  :global(.NewCRMTable__Body-Row--Red) {
    background: #ea4c4c0d;
  }

  :global(.NewCRMTable__Cell-Inner) {
    height: 100%;
    min-width: 100%;
    weight: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 7px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #696969;
  }
  :global(.NewCRMTable__Body-Row) {
    &:last-child {
      :global(.NewCRMTable__Cell--Tbody) {
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }

  :global(.NewCRMTable__Inner-Cell-OverflowText) {
    width: 100%;
    z-index: 10000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

    &:hover {
      position: absolute;
      left: 0;
      top: 0;
      padding: 8px 9px 9px 11px;
      min-height: 30px;
      min-width: 100%;
      background: #e9f6fc;
      border: 1px solid #0aa1e2;
      overflow-wrap: break-word;
      z-index: 10000;
      white-space: normal;
      height: max-content;
    }
  }

  :global(.NewCRMTable__Cell--Thead) > :global(.NewCRMTable__Cell-Inner) {
    margin-left: 1px;
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
