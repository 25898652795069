import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, FormSection, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ActionsButton, DefaultButton, FileField, ReduxTextField, TextAreaField } from 'common/components';
import { createFile } from 'admin/files/_redux';

import { withTheme } from 'common/styling/theme';
import uploadImage from 'common/images/upload.svg';
import { required } from 'redux-form-validators';
import { staticStyles, getDynamicStyles } from './style';

const BonusDescriptionPanel = ({ promoTexts, languages, hasLogo, currentLang, submitting, dispatch, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isTitleTranslationOpen, setTitleTranslationOpen] = useState(false);
  const [isDescriptionTranslationOpen, setDescriptionTranslationOpen] = useState(false);
  const [isTermsTranslationOpen, setTermsTranslationOpen] = useState(false);
  const [openedBullets, setOpenedBullets] = useState([]);
  const [fileExist, setFileExist] = useState(hasLogo);
  const [bullets, setBullets] = useState(promoTexts.map((bullet, id) => ({ id, position: id + 1 })));
  const labelRef = useRef(null);
  const toggleTitleBlock = () => setTitleTranslationOpen(!isTitleTranslationOpen);
  const toggleDescriptionBlock = () => setDescriptionTranslationOpen(!isDescriptionTranslationOpen);
  const toggleTermsBlock = () => setTermsTranslationOpen(!isTermsTranslationOpen);
  const toggleBulletsBlock = bulletId => {
    if (!openedBullets.includes(bulletId)) {
      setOpenedBullets([...openedBullets, bulletId]);
    } else {
      setOpenedBullets(openedBullets.filter(item => item !== bulletId));
    }
  };

  const addBullet = () => {
    setBullets([...bullets, { id: bullets.length + 1, position: bullets.length + 1 }]);
  };

  const changeBulletPosition = (bulletId, currentPosition, direction) => {
    if (direction === 'up' && currentPosition < bullets.length) {
      const replacedBullet = bullets.find(bullet => bullet.position === currentPosition + 1);
      const bulletsWithoutCurrent = bullets.filter(item => item.id !== bulletId && item.id !== replacedBullet.id);
      setBullets([
        ...bulletsWithoutCurrent,
        { id: replacedBullet.id, position: currentPosition },
        { id: bulletId, position: currentPosition + 1 },
      ]);
    } else if (direction === 'down' && currentPosition > 1) {
      const replacedBullet = bullets.find(bullet => bullet.position === currentPosition - 1);
      const bulletsWithoutCurrent = bullets.filter(item => item.id !== bulletId && item.id !== replacedBullet.id);
      setBullets([
        ...bulletsWithoutCurrent,
        { id: replacedBullet.id, position: currentPosition },
        { id: bulletId, position: currentPosition - 1 },
      ]);
    }
  };

  const handleUpload = () => {
    labelRef.current.click();
  };

  const handleExistFile = (name, file) => {
    if (name || file) {
      setFileExist(true);
    }
  };
  const handleAfterUpload = file => {
    const formData = new FormData();
    formData.set('title', file[0].name);
    formData.append('file', file[0]);
    createFile({ body: formData }, dispatch);
  };

  return (
    <div className="BonusDescriptionPanel">
      <FormattedMessage id="justBonusDescription">
        {txt => <div className="BonusDescriptionPanel__caption">{txt}</div>}
      </FormattedMessage>
      <div className="BonusDescriptionPanel__line">
        <div className="BonusDescriptionPanel__left-block">
          <Field
            component={ReduxTextField}
            type="text"
            name={`title.${currentLang}`}
            textId="justTitle"
            disabled={submitting}
            maxLength="100"
            validate={[required()]}
          />
          {isTitleTranslationOpen &&
            languages.map(lang => (
              <Field
                component={ReduxTextField}
                type="text"
                name={`title.${lang}`}
                textId={`lang_${lang}`}
                disabled={submitting}
                maxLength="100"
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                key={lang}
              />
            ))}
        </div>
        <div className="BonusDescriptionPanel__right-block">
          <button type="button" onClick={toggleTitleBlock} disabled={!languages.length}>
            <FormattedMessage id="justShowTranslations">
              {txt => <span className="BonusDescriptionPanel__translations">{txt}</span>}
            </FormattedMessage>
            <i
              className={classNames('BonusDescriptionPanel__arrow', {
                'BonusDescriptionPanel__arrow-up': isTitleTranslationOpen,
                'BonusDescriptionPanel__arrow-down': !isTitleTranslationOpen,
              })}
            />
          </button>
        </div>
      </div>
      <div className="BonusDescriptionPanel__line">
        <div className="BonusDescriptionPanel__left-block">
          <Field
            component={TextAreaField}
            disabled={submitting}
            name={`description.${currentLang}`}
            textId="justDescription"
            rows="4"
            cols="150"
            validate={[required()]}
          />
          {isDescriptionTranslationOpen &&
            languages.map(lang => (
              <Field
                component={TextAreaField}
                disabled={submitting}
                name={`description.${lang}`}
                textId={`lang_${lang}`}
                rows="4"
                cols="150"
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                key={lang}
              />
            ))}
        </div>
        <div className="BonusDescriptionPanel__right-block">
          <button type="button" onClick={toggleDescriptionBlock} disabled={!languages.length}>
            <FormattedMessage id="justShowTranslations">
              {txt => <span className="BonusDescriptionPanel__translations">{txt}</span>}
            </FormattedMessage>
            <i
              className={classNames('BonusDescriptionPanel__arrow', {
                'BonusDescriptionPanel__arrow-up': isDescriptionTranslationOpen,
                'BonusDescriptionPanel__arrow-down': !isDescriptionTranslationOpen,
              })}
            />
          </button>
        </div>
      </div>
      <FormSection name="promoTexts">
        {bullets
          .sort((a, b) => a.position - b.position)
          .map(bullet => (
            <div className="BonusDescriptionPanel__line" key={`bullet_${bullet.id}`}>
              <div className="BonusDescriptionPanel__bullets-left-block">
                <div className="BonusDescriptionPanel__bullets-content">
                  <Field
                    component={ReduxTextField}
                    type="text"
                    name={`bullets_${bullet.id}.${currentLang}`}
                    textId="justBullet"
                    disabled={submitting}
                  />
                  {openedBullets.includes(bullet.id) &&
                    languages.map(lang => (
                      <Field
                        component={ReduxTextField}
                        type="text"
                        name={`bullets_${bullet.id}.${lang}`}
                        textId={`lang_${lang}`}
                        disabled={submitting}
                        dir={lang === 'ar' ? 'rtl' : 'ltr'}
                        key={lang}
                      />
                    ))}
                </div>
                <div className="BonusDescriptionPanel__bullets-position">
                  <FormattedMessage id="justPosition">
                    {txt => <span className="BonusDescriptionPanel__bullets-caption">{txt}</span>}
                  </FormattedMessage>
                  <span className="BonusDescriptionPanel__bullets-count">{bullet.position}</span>
                  <div className="BonusDescriptionPanel__move-buttons">
                    <button
                      type="button"
                      tabIndex={-1}
                      onClick={() => changeBulletPosition(bullet.id, bullet.position, 'down')}
                      className="BonusDescriptionPanel__bullets-arrow-up"
                    />
                    <button
                      type="button"
                      tabIndex={-1}
                      onClick={() => changeBulletPosition(bullet.id, bullet.position, 'up')}
                      className="BonusDescriptionPanel__bullets-arrow-down"
                    />
                  </div>
                </div>
              </div>
              <div className="BonusDescriptionPanel__right-block">
                <button type="button" onClick={() => toggleBulletsBlock(bullet.id)} disabled={!languages.length}>
                  <FormattedMessage id="justShowTranslations">
                    {txt => <span className="BonusDescriptionPanel__translations">{txt}</span>}
                  </FormattedMessage>
                  <i
                    className={classNames('BonusDescriptionPanel__arrow', {
                      'BonusDescriptionPanel__arrow-up': openedBullets.includes(bullet.id),
                      'BonusDescriptionPanel__arrow-down': !openedBullets.includes(bullet.id),
                    })}
                  />
                </button>
              </div>
            </div>
          ))}
      </FormSection>
      <DefaultButton textId="addBullet" onClick={addBullet} loading={submitting} disabled={submitting} plus />
      <div className="BonusDescriptionPanel__bonus-logo">
        <FormattedMessage id="bonusLogo">{txt => <span>{txt}</span>}</FormattedMessage>
        <div
          className={classNames('BonusDescriptionPanel__file-field-wrapper', {
            'BonusDescriptionPanel__file-field--active': fileExist,
          })}>
          <ActionsButton
            className="BonusDescriptionPanel__file-upload-image"
            buttonId="attachImage"
            onClickFunc={handleUpload}>
            <img src={uploadImage} alt="upload" />
            <label htmlFor="file" ref={labelRef} />
          </ActionsButton>
          <Field
            sendHandler={handleExistFile}
            handleAfterUpload={handleAfterUpload}
            className="BonusDescriptionPanel__file-field"
            component={FileField}
            accept="image/png,image/jpeg,image/gif"
            name="file"
            formatNameId="imagesFormats"
            fileNameId="justAttachments"
            multiple={false}
            maxSize={5 * 1024 * 1024}
            disabled={submitting}
            validate={[required()]}
          />
        </div>
      </div>
      <div className="BonusDescriptionPanel__line">
        <div className="BonusDescriptionPanel__left-block">
          <Field
            component={TextAreaField}
            disabled={submitting}
            name={`rules.${currentLang}`}
            textId="justTermsAndConditions"
            rows="4"
            cols="150"
            validate={[required()]}
          />
          {isTermsTranslationOpen &&
            languages.map(lang => (
              <Field
                component={TextAreaField}
                disabled={submitting}
                name={`rules.${lang}`}
                textId={`lang_${lang}`}
                rows="4"
                cols="150"
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                key={lang}
              />
            ))}
        </div>
        <div className="BonusDescriptionPanel__right-block">
          <button type="button" onClick={toggleTermsBlock} disabled={!languages.length}>
            <FormattedMessage id="justShowTranslations">
              {txt => <span className="BonusDescriptionPanel__translations">{txt}</span>}
            </FormattedMessage>
            <i
              className={classNames('BonusDescriptionPanel__arrow', {
                'BonusDescriptionPanel__arrow-up': isTermsTranslationOpen,
                'BonusDescriptionPanel__arrow-down': !isTermsTranslationOpen,
              })}
            />
          </button>
        </div>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

BonusDescriptionPanel.propTypes = {
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentLang: PropTypes.string.isRequired,
  languages: PropTypes.array,
  promoTexts: PropTypes.array,
  hasLogo: PropTypes.bool,
  theme: PropTypes.object,
};

BonusDescriptionPanel.defaultProps = {
  languages: [],
  promoTexts: [],
  hasLogo: false,
  theme: {},
};
const selector = formValueSelector('BonusesForm');

export default compose(
  injectIntl,
  withTheme(),
  connect(state => {
    const availableLanguages = selector(state, 'availableLanguages') || [];
    const currentLang = state.interfaceConfig.lang;
    const languages = Object.entries(availableLanguages)
      .filter(([, value]) => value)
      .map(([key]) => key)
      .filter(lang => lang !== currentLang);
    return { languages, currentLang };
  })
)(BonusDescriptionPanel);
export { BonusDescriptionPanel };
