import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { ModalHOC } from 'common/HOCs/ModalHOC';
import { DefaultButton, NoItemsPanel } from 'common/components';
import { taggedStylesToString, withTheme } from 'common/styling/theme';

import { BasketIcon } from 'common/icons';

import FormFieldsComposer from '../FormFieldsComposer';

import { staticStyles, getDynamicStyles } from './style';

class ArrayField extends Component {
  removeField = index => {
    const { showModal, fields } = this.props;

    showModal({
      caption: { id: 'confirmOfRemoval' },
      content: {
        id: 'confirmDeleteArrayField',
      },
      actionButton: {
        textId: 'doRemove',
        type: 'error',
        handler: () => fields.remove(index),
      },
      cancelButton: {
        textId: 'justCancel',
      },
    });
  };

  onDragEnd = result => {
    const { fields } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return fields;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [removed] = fields.getAll().splice(startIndex, 1);

    fields.splice(endIndex, 0, removed);
  };

  render() {
    const {
      schema,
      fields,
      meta: { error, pristine },
      formName,
      arraysName,
      submitting,
      theme,
    } = this.props;

    const style = taggedStylesToString(staticStyles);
    const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

    const formError = !pristine && error;
    const isDragDisabled = !schema.ordering || fields.length === 1;
    const isFormError = typeof error === 'object';

    return (
      <div className="FieldArray" id={arraysName}>
        <div className="FieldArray__top-line">
          <div className="FieldArray__title">
            <FormattedMessage id={schema.title}>{txt => <h2>{txt}</h2>}</FormattedMessage>
          </div>
          <DefaultButton textId="adminAddButton" plus type="button" onClick={() => fields.push({})} />
        </div>

        {fields.length ? (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={isDragDisabled}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classNames('FieldArray__isDragging-inner', { isDraggingOver: snapshot.isDraggingOver })}>
                  {fields.map((item, index) => (
                    <Draggable
                      key={`${index}_${fields.length}`}
                      draggableId={item}
                      index={index}
                      isDragDisabled={isDragDisabled}>
                      {(provided, snapshot) => (
                        <div
                          className={classNames('FieldArray__item-inner', {
                            FieldArray__notDraggable: !schema.ordering,
                            FieldArray__isDraggable: snapshot.isDragging,
                            FieldArray__item_error: isFormError && error[index],
                          })}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}>
                          <div className="FieldArray__item">
                            <p className="FieldArray__item-number">{index + 1}</p>
                            <button
                              className="FieldArray__remove"
                              type="button"
                              onClick={() => this.removeField(index)}>
                              <BasketIcon />
                            </button>
                            <FormFieldsComposer
                              name={`${item}`}
                              schema={schema.items}
                              formName={formName}
                              submitting={submitting}
                            />
                            {isFormError && error[index] && (
                              <FormattedMessage id={error[index]}>
                                {txt => <span className="ArrayField__item-error">{txt}</span>}
                              </FormattedMessage>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <NoItemsPanel
            captionSlug={schema.title}
            descriptionSlug={schema.description}
            className="FieldArray__no-items"
            isError={!!formError}
          />
        )}

        <style>{style}</style>
        <style>{dynamicStyles}</style>
      </div>
    );
  }
}

ArrayField.propTypes = {
  schema: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  arraysName: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  theme: PropTypes.object,
};

ArrayField.defaultProps = {
  fields: {},
  submitting: false,
  theme: {},
};

const ThemedArrayField = withTheme()(ModalHOC(ArrayField));

export default ThemedArrayField;
export { ArrayField };
