import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const changeIssueLanguage = createFormAction('CHANGE_ISSUE_LANGUAGE');

export const GET_ISSUES = createRequestTypes('GET_ISSUES');
export const getIssues = {
  request: search => action(GET_ISSUES[REQUEST], { search }),
  success: data => action(GET_ISSUES[SUCCESS], { data }),
  failure: error => action(GET_ISSUES[FAILURE], { error }),
};

export const GET_ISSUE = createRequestTypes('GET_ISSUE');
export const getIssue = {
  request: id => action(GET_ISSUE[REQUEST], { id }),
  success: data => action(GET_ISSUE[SUCCESS], { data }),
  failure: error => action(GET_ISSUE[FAILURE], { error }),
};

export const createMessage = createFormAction('CREATE_MESSAGE');

export const editMessage = createFormAction('EDIT_MESSAGE');

export const CREATE_ATTACHMENT = createRequestTypes('CREATE_ATTACHMENT');
export const createAttachment = {
  request: (message, file) => action(CREATE_ATTACHMENT[REQUEST], { message, file }),
  success: () => action(CREATE_ATTACHMENT[SUCCESS]),
  failure: error => action(CREATE_ATTACHMENT[FAILURE], { error }),
};

export const createIssueTicket = createFormAction('CREATE_ISSUE_TICKET');

export const DELETE_ATTACHMENT = createRequestTypes('DELETE_ATTACHMENT');
export const deleteAttachment = {
  request: data => action(DELETE_ATTACHMENT[REQUEST], data),
  success: () => action(DELETE_ATTACHMENT[SUCCESS]),
  failure: error => action(DELETE_ATTACHMENT[FAILURE], { error }),
};

export const VERIFY_PARTNER_FROM_TICKET = createRequestTypes('VERIFY_PARTNER_FROM_TICKET');
export const verifyPartnerFromTicket = {
  request: data => action(VERIFY_PARTNER_FROM_TICKET[REQUEST], data),
  success: data => action(VERIFY_PARTNER_FROM_TICKET[SUCCESS], { data }),
  failure: error => action(VERIFY_PARTNER_FROM_TICKET[FAILURE], { error }),
};

export const rejectPartnerFromTicket = createFormAction('REJECT_PARTNER-FROM_TICKET');

export const closeIssueBackoffice = createFormAction('CLOSE_ISSUE_BACKOFFICE');
