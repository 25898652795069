import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  isGetClientLoading: false,
  selectedContactsIds: [],

  contactsExportDataIsLoaded: false,
  contactsExportData: {},

  contacts: {},
  contactsAreLoaded: false,

  favorites: [],
  favoritesAreLoaded: false,

  crmFilterIsLoaded: false,
  crmFilter: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTACTS_EXPORTS[REQUEST]:
      return { ...state, contactsExportDataIsLoaded: false };
    case ActionTypes.GET_CONTACTS_EXPORTS[SUCCESS]:
      return { ...state, contactsExportData: action.data, contactsExportDataIsLoaded: true };
    case ActionTypes.GET_CONTACTS_EXPORTS[FAILURE]:
      return { ...state, error: action.error, userExportDataIsLoaded: false };
    case ActionTypes.CRM_SET_SELECTED_CONTACTS_IDS: {
      return { ...state, selectedContactsIds: action.data };
    }
    case ActionTypes.GET_CONTACTS[REQUEST]:
      return { ...state, contactsAreLoaded: false };
    case ActionTypes.GET_CONTACTS[SUCCESS]:
      return { ...state, contacts: action.data, contactsAreLoaded: true };
    case ActionTypes.GET_CONTACTS[FAILURE]:
      return { ...state, error: action.error, contactsAreLoaded: false };

    case ActionTypes.GET_CRM_CLIENT[REQUEST]:
      return { ...state, isGetClientLoading: false };
    case ActionTypes.GET_CRM_CLIENT[SUCCESS]:
      return { ...state, isGetClientLoading: true };
    case ActionTypes.GET_CRM_CLIENT[FAILURE]:
      return { ...state, isGetClientLoading: false };

    case ActionTypes.GET_CONTACTS_FAVORITES[REQUEST]:
      return { ...state, favoritesAreLoaded: false };
    case ActionTypes.GET_CONTACTS_FAVORITES[SUCCESS]:
      return { ...state, favorites: action.data, favoritesAreLoaded: true };
    case ActionTypes.GET_CONTACTS_FAVORITES[FAILURE]:
      return { ...state, error: action.error, favoritesAreLoaded: false };

    case ActionTypes.GET_CRM_FILTER[REQUEST]:
      return { ...state, crmFilterIsLoaded: false };
    case ActionTypes.GET_CRM_FILTER[SUCCESS]:
      return { ...state, crmFilterIsLoaded: true, crmFilter: action.data };
    case ActionTypes.GET_CRM_FILTER[FAILURE]:
      return { ...state, error: action.error, crmFilterIsLoaded: false };

    default:
      return state;
  }
};

export default reducer;
