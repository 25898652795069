import css from 'styled-jsx/css'
import { isRTL } from 'common/styling/theme'

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';

  .ReduxTextField {
    .BaseTextField__label {
      top: 37%;
    }
    .ActionsButton {
      position: absolute;
      top: rem(18px);
      z-index: 5;
      right: rem(${isRTL ? 'auto' : '10px'});
      left: rem(${isRTL ? '10px' : 'auto'});
      margin: 0;
      &.hide {
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
    }

    .ReduxTextField__description {
      position: absolute;
      top: rem(18px);
      z-index: 5;
      right: rem(${isRTL ? 'auto' : '15px'});
      left: rem(${isRTL ? '15px' : 'auto'});
      margin: 0;

      &--tooltip {
        right: rem(${isRTL ? 'auto' : '30px'});
        left: rem(${isRTL ? '30px' : 'auto'});
      }
    }
  }

  .ReduxTextField__error {
    width: 100%;
    display: inline-block;
    min-height: 15px;
  }
`

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ReduxTextField__description {
      color: ${colors.gray.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }
