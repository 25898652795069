import React from 'react';
import ReactApexChart from 'react-apexcharts';

// import { tempdata } from './tempData';

const ChallengePageChart = ({
  chart = {},

  challenge,
}) => {
  // const chart = tempdata;

  const series = [
    {
      name: 'Current Balance',
      data: Object.keys(chart).map(key => ({
        x: new Date(key).getTime(),
        y: chart[key],
      })),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'line',
      id: 'areachart-2',
    },
    annotations: {
      yaxis: [
        {
          y: challenge.level.target.amount,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'Profit Target',
          },
        },
        {
          y: challenge.level.maximum_daily_loss.amount,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: {
              color: '#fff',
              background: '#FF4560',
            },
            text: 'Maximum daily loss',
          },
        },
        {
          y: challenge.level.maximum_total_loss.amount,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: {
              color: '#fff',
              background: '#FF4560',
            },
            text: 'Maximum total loss',
          },
        },
      ],
      xaxis: [
        {
          x: new Date(challenge.created).getTime() + challenge.level.minimum_trading_days * 86400000,
          strokeDashArray: 0,
          borderColor: '#0AA1E2',
          label: {
            borderColor: '#0AA1E2',
            style: {
              color: '#fff',
              background: '#0AA1E2',
            },
            text: 'Minimum trading days',
          },
        },
        {
          x: new Date(challenge.created).getTime() + challenge.level.maximum_trading_days * 86400000,
          strokeDashArray: 0,
          borderColor: 'red',
          label: {
            borderColor: 'red',
            style: {
              color: '#fff',
              background: 'red',
            },
            text: 'Maximum trading days',
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: 'Line with Annotations',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="line" height={350} />
      </div>
      <div id="html-dist" />
    </div>
  );
};

export default ChallengePageChart;
