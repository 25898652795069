import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .LeftNavigationLinkItems {
    display: flex;
    flex-direction: column;
    height: 0;
    transition: all 0.3s ease;
    overflow: hidden;
  }

  .LeftMenu {
    min-width: 240px;

    &__mobile-block {
      display: none;
      height: 80px;
      margin-bottom: 20px;
      align-items: center;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.3px;
      font-weight: 500;

      @include respond-to(md) {
        display: flex;
      }
    }

    &__link {
      position: relative;
      display: flex;
      align-items: center;
      padding: ${isRTL ? '8px 20px 8px 15px' : '8px 15px 8px 20px'};
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        transition: all 0.4s ease;
        transform: scale(0);
        opacity: 0;
        background-color: #3ec4df;
      }

      &:not(.active):hover:after {
        transform: scale(1);
        opacity: 1;
      }

      &.active {
        background-color: #ffffff;
        border-left: 3px solid #3ec4df;

        @include respond-to(md) {
          background-color: transparent;
        }
      }
    }

    &__icon {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${isRTL ? '0' : '15px'};
      margin-left: ${isRTL ? '15px' : '0'};

      .svgFill,
      .svgNoFill .fill {
        fill: #5d647d;

        @include respond-to(md) {
          fill: #ffffff;
        }
      }
    }

    &__title {
      position: relative;
      padding-right: 8px;
    }

    .Tooltip {
      max-width: 255px !important;

      &__text {
        position: absolute;
        width: calc(100% + 39px);
        height: 100%;
        top: 0;
        left: ${isRTL ? 'unset' : '-39px'};
        right: ${isRTL ? '-39px' : 'unset'};
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .LeftMenu__titele-text {
      color: ${colors.gray.hex};
    }
    .LeftMenu__link-arrow > path {
      stroke: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
