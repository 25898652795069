import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import PropTypes from 'prop-types';
import { ReduxTextField } from '../../form-fields';
import DefaultButton from '../../DefaultButton';

import { wsGenerateDnsTxt, wsSetStep } from '../../../_redux';

import { NewDefaultButton } from '../..';

import { staticStyles } from './style';

const GenerateDnsTxtForm = ({
  userDomain,
  pristine,
  submitting,
  invalid,
  error,
  handleSubmit,
  dispatch,
  wsSetStep,
}) => {
  const submit = handleSubmit(formValues => wsGenerateDnsTxt(formValues, dispatch));

  const handlePreviousStepClick = () => wsSetStep(2);

  return (
    <form className="GenerateDnsTxtForm" onSubmit={submit}>
      <Field
        component={ReduxTextField}
        type="text"
        name="userDomain"
        textId="welcomeSetupDomain"
        customDefaultValue={userDomain}
        placeholder="yourbrokerage.com"
        validate={[required()]}
        disabled={submitting}
      />
      <DefaultButton
        id="strangeButton"
        textId="welcomeSetupGenerateTxt"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        validate={[required()]}
        wide
        next
        isUpperCase
      />
      <div className="GenerateDnsTxtForm__nav-buttons">
        <NewDefaultButton
          textId="justBack"
          onClick={handlePreviousStepClick}
          type="button"
          variant="ghost"
          iconVariant="previous"
          disabled={submitting}
          isUpperCase
        />
        <NewDefaultButton
          textId="justNextStep"
          onClick={() => wsSetStep(4)}
          variant="primary"
          type="submit"
          disabled={!userDomain}
          isUpperCase
          wide
        />
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx global>
        {staticStyles}
      </style>
    </form>
  );
};

GenerateDnsTxtForm.propTypes = {
  userDomain: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  wsSetStep: PropTypes.func.isRequired,
};

GenerateDnsTxtForm.defaultProps = {
  error: '',
};

export default compose(
  withRouter,
  connect(state => ({ userDomain: state.welcomeSetup.userDomain }), {
    wsSetStep,
  }),
  reduxForm({
    form: 'wsGenerateDnsTxtForm',
    onSubmitSuccess: (result, dispatch, { wsSetStep }) => {
      wsSetStep(4);
    },
  })
)(GenerateDnsTxtForm);
export { GenerateDnsTxtForm };
