import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';

import FormFieldsComposer from '../FormFieldsComposer';

import { staticStyles, getDynamicStyles } from './style';

const ObjectField = ({ objectTitle, title, name, items, formName, hidden, submitting, theme, isAllFieldsRequired }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className={classNames('ObjectField', { ObjectField__hidden: hidden })}>
      {!!objectTitle && <h2>{objectTitle}</h2>}
      {!!title && <FormattedMessage id={title}>{txt => <h3>{txt}</h3>}</FormattedMessage>}
      <div className="ObjectField_items">
        {Object.keys(items).map(key => (
          <FormFieldsComposer
            key={key}
            name={`${name}.${key}`}
            schema={items[key]}
            formName={formName}
            submitting={submitting}
            isAllFieldsRequired={isAllFieldsRequired}
          />
        ))}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ObjectField.propTypes = {
  objectTitle: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  submitting: PropTypes.bool,
  isAllFieldsRequired: PropTypes.bool,
  theme: PropTypes.object,
};

ObjectField.defaultProps = {
  objectTitle: '',
  title: '',
  hidden: false,
  submitting: false,
  isAllFieldsRequired: true,
  theme: {},
};

export default withTheme()(ObjectField);
export { ObjectField };
