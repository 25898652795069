import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, change } from 'redux-form';
import { required, numericality } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

import { ReduxTextField, DefaultButton, CheckboxField, ReduxSelectField, TinyMCEField } from 'common/components';
import { createInvestStrategyNew } from 'client/investNew/_redux';
import { getMTServersActionNew } from 'client/_redux/actions';

import staticStyles from './style';

const validate = ({ name }) => {
  const errors = {};

  if (!/^[a-zA-Z0-9\s_!,.?]{0,50}$/.test(name)) errors.name = <FormattedMessage id="rammStrategyNameHelpText" />;

  return errors;
};

const CreateInvestStrategyForm = ({
  toggleModal,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  accounts,
  error,
  getMTServersActionNew,
  mtServersPending,
  mtServers,
}) => {
  const submit = handleSubmit(values => {
    const formattedValuesNewInvest = {
      selector: { cur: values.currency },
      strategy: { name: values.name, description: values.description, accountType: values.accountType },
      settings: {
        rewardAccount: values.rewardAccount,
        frequencyType: values.frequencyType,
        days: values.days,
        time: values.time,
        rewardType: values.rewardType,
        rewardPercentage: values.rewardPercentage,
        minInvestmentAmount: values.minInvestmentAmount,
        enableOperations: values.enableOperations,
        autoInvestment: values.autoInvestment,
        managementFeePercentage: values.managementFeePercentage,
      },
    };
    return createInvestStrategyNew(formattedValuesNewInvest, dispatch);
  });
  const [selectedType, setSelectedType] = useState(null);
  const [selectedServerAccountType, setSelectedServerAccountType] = useState(null);
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const frequencyTypes = [
    {
      value: 1,
      label: <FormattedMessage id="justMonthly" />,
    },
    {
      value: 2,
      label: <FormattedMessage id="justWeekly" />,
    },
  ];

  const rewardTypes = [
    {
      value: 1,
      label: <FormattedMessage id="investShareOfProfit" />,
    },
    {
      value: 2,
      label: <FormattedMessage id="investHighWaterMark" />,
    },
  ];

  const accountsOptions = accounts.map(account => ({
    value: account.id,
    label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
  }));

  const daysOptions = useMemo(
    () =>
      selectedType === 1
        ? Array.from(Array(31), (_, i) => i + 1).map(day => ({ label: day, value: day }))
        : weekdays.map((day, i) => ({
            label: <FormattedMessage id={`just${day}`} />,
            value: i + 1,
          })),
    [selectedType, weekdays]
  );

  const timeOptions = useMemo(
    () =>
      Array.from(Array(24), (_, i) => i).map(hour => ({
        label: `${hour >= 10 ? hour : `0${hour}`}:00`,
        value: hour,
      })),
    []
  );

  useEffect(() => {
    getMTServersActionNew();
  }, []);

  const minimalInvestAmount = 0;

  const minRewardPercent = 0;

  const maxRewardPercent = 100;

  const serverAccountTypes = useMemo(
    () => (mtServers ? mtServers.map(({ name, id }) => ({ label: name, value: id })) : []),
    [mtServers]
  );

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  return (
    <div className="CreateStrategy__content">
      <form className="CreateInvestStrategyForm" onSubmit={submit}>
        <div className="CreateInvestStrategyForm__inputs-inner">
          <Field
            component={ReduxTextField}
            name="name"
            textId="justStrategyName"
            validate={[required()]}
            disabled={submitting}
          />

          <Field component={TinyMCEField} name="description" textId="justDescription" disabled={submitting} />

          <Field
            component={ReduxSelectField}
            name="rewardAccount"
            maxMenuHeight={140}
            textId="investRewardAccount"
            options={accountsOptions}
            disabled={submitting}
            validate={[required()]}
            searchable
          />

          {!mtServersPending && (
            <>
              {!mtServersPending && (
                <Field
                  component={ReduxSelectField}
                  name="accountType"
                  textId="clientMetaTraderAccountTypes"
                  options={serverAccountTypes}
                  disabled={submitting}
                  validate={[required()]}
                  onChange={value => setSelectedServerAccountType(value)}
                />
              )}

              {selectedServerAccountType && (
                <Field
                  component={ReduxSelectField}
                  name="currency"
                  textId="justCurrency"
                  options={mtServers
                    .find(({ id }) => id === selectedServerAccountType)
                    .currencies.map(oneCurrency => ({ label: oneCurrency, value: oneCurrency }))}
                  disabled={submitting}
                  validate={[required()]}
                  onChange={value => setSelectedCurrency(value)}
                />
              )}

              {/* social trading TODO: В будущем будет пока только 1 тип */}

              {/* {defaultStrategyType === 2 && (
                <Field
                  component={ReduxSelectField}
                  name="externalAccount"
                  textId="clientMetaExternalAccount"
                  options={externalAccountsOptions}
                  disabled={submitting}
                  validate={[required(), balanceValidate]}
                  onChange={() => {}}
                />
              )} */}
            </>
          )}

          <Field
            component={ReduxSelectField}
            name="frequencyType"
            textId="investRewardFrequency"
            options={frequencyTypes}
            validate={[required()]}
            disabled={submitting}
            onChange={value => setSelectedType(value)}
          />

          {selectedType && (
            <>
              <FormattedMessage id="investRewardDays">
                {txt => <span className="caption">{txt}:</span>}
              </FormattedMessage>
              <Field
                component={ReduxSelectField}
                name="days"
                textId="investRewardDays"
                options={daysOptions}
                validate={[required()]}
                disabled={submitting}
                multi
              />

              <Field
                component={ReduxSelectField}
                name="time"
                textId="investRewardTime"
                options={timeOptions}
                validate={[required()]}
                disabled={submitting}
              />

              <div className="row">
                <Field
                  component={ReduxSelectField}
                  name="rewardType"
                  textId="rewardType"
                  options={rewardTypes}
                  validate={[required()]}
                  disabled={submitting}
                />

                <Field
                  component={ReduxTextField}
                  name="rewardPercentage"
                  textId="investRewardAmount"
                  validate={[
                    required(),
                    numericality({
                      '<=': maxRewardPercent || 100,
                      '>=': minRewardPercent || 0,
                    }),
                  ]}
                  disabled={submitting}
                />
              </div>

              <Field
                component={ReduxTextField}
                name="managementFeePercentage"
                textId="justManagementFeePercentage"
                disabled={submitting}
              />

              {typeof minimalInvestAmount === 'number' && (
                <div className="CreateStrategy__min-invest-amount">
                  <Field
                    component={ReduxTextField}
                    name="minInvestmentAmount"
                    textId="minimalInvestmentAmount"
                    validate={[
                      required(),
                      numericality({
                        '>=': minimalInvestAmount,
                      }),
                    ]}
                    description={selectedCurrency}
                    disabled={submitting}
                  />

                  <div className="CreateStrategy__min-invest-amount-text">
                    <FormattedMessage
                      id="minimalInvestmentAmountCount"
                      values={{
                        count: minimalInvestAmount,
                        currency: selectedCurrency,
                      }}>
                      {txt => txt}
                    </FormattedMessage>
                  </div>
                </div>
              )}
              {/* TODO: soon temp ubrali */}
              {/* <div className="CreateStrategy__checkbox">
                <Field component={CheckboxField} name="autoInvestment" textId="autoInvestment" disabled={submitting} />
              </div> */}
              <div className="CreateStrategy__checkbox">
                <Field
                  component={CheckboxField}
                  name="enableOperations"
                  textId="strategyAutoOperations"
                  disabled={submitting}
                />
              </div>
            </>
          )}

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="CreateInvestStrategyForm__btns">
          <DefaultButton
            textId="justPammCreateStrategy"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting || !selectedType}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
      </form>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

CreateInvestStrategyForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  getMTServersActionNew: PropTypes.func.isRequired,
  mtServersPending: PropTypes.bool.isRequired,
  mtServers: PropTypes.object,
};

CreateInvestStrategyForm.defaultProps = {
  error: '',
  mtServers: null,
};

export default compose(
  reduxForm({
    form: 'CreateInvestStrategyFormNew',
    validate,
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
    initialValues: {
      autoCreate: true,
    },
  }),
  connect(
    state => ({
      accounts: state.client.common.accounts.filter(
        account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
      ),
      accountsAreLoaded: state.client.common.accountsIsLoaded,
      mtServers: state.client.common.mtServersNew,
      mtServersPending: state.client.common.mtServersNewPending,
    }),
    dispatch => ({
      getMTServersActionNew: () => dispatch(getMTServersActionNew.request()),
      changeFieldValue: (field, value) => dispatch(change('CreateInvestStrategyFormNew', field, value)),
    })
  )
)(CreateInvestStrategyForm);
export { CreateInvestStrategyForm };
