import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { SimpleModal } from '../components';

const ModalContext = React.createContext({
  showModal: () => {},
});

const ModalHOC = Wrapped => props =>
  <ModalContext.Consumer>{({ showModal }) => <Wrapped {...props} showModal={showModal} />}</ModalContext.Consumer>;

const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const modalContextProviderObject = useMemo(() => {
    const showModal = modalOptions => {
      setModals(prevState => [modalOptions, ...prevState]);
    };
    return { showModal };
  }, []);

  const closeModal = index => {
    setModals(prevState => {
      const modal = prevState.find((modal, i) => i === index);
      if (modal.onClose) {
        modal.onClose();
      }
      return prevState.filter((_, i) => index !== i);
    });
  };

  return (
    <ModalContext.Provider value={modalContextProviderObject}>
      {children}
      {modals.map((modal, index) => (
        <SimpleModal key={index} {...modal} closeHandler={() => closeModal(index)} />
      ))}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ModalContext, ModalHOC, ModalProvider };
