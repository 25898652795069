import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CheckDomainForm {
    width: 100%;
    grid-column: 1/4;
    .BaseTextField {
      margin-bottom: 32px;
    }
    .TextActionsInput {
      margin-bottom: 16px;
    }
    &__message {
      opacity: 0.6;
    }

    &__status-message {
      padding: 0 8px;
      margin-bottom: 16px;
      background-color: #ffffff;
      border-radius: 4px;

      &--error {
        border-left: 4px solid #ff4058;
      }
    }

    &__nav-buttons {
      display: flex;
      gap: 16px;
      @include respond-to(sm) {
        flex-direction: column-reverse;
      }
    }

    .TextActionsInput {
      margin-bottom: 32px;
    }

    > .NewDefaultButton {
      margin-bottom: 32px !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .formError {
      margin-bottom: 10px;
    }
  }
`;

export { staticStyles };
