import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .InternalTransfer {
    .InternalTransfer__select {
      display: flex;

      .InternalTransfer__select-caption, label {
        margin-right: rem(20px);
      }
    }
  }
`;

export { staticStyles };
