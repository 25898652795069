import { fork, put, call, takeEvery } from 'redux-saga/effects';

import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* uploadFileSaga({ fieldName, file }) {
  try {
    const { data } = yield call(api.uploadFile, file);
    yield put(ActionTypes.uploadFile.success(fieldName, data.location));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.uploadFile.failure(fieldName));
  }
}

function* uploadFileWatcherSaga() {
  yield takeEvery(ActionTypes.UPLOAD_FILE[REQUEST], uploadFileSaga);
}

export default [fork(uploadFileWatcherSaga)];
