import React from 'react';

const PaymentsIcon = () => (
  <svg className="svgNoFill" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="fill"
      d="M11.1881 17.0839C10.2644 17.6643 9.17142 18 8 18C4.68629 18 2 15.3137 2 12C2 8.68629 4.68629 6 8 6C9.17142 6 10.2644 6.3357 11.1881 6.91614C9.84058 8.192 9 9.99784 9 12C9 14.0022 9.84058 15.808 11.1881 17.0839ZM12 17.7453C10.8662 18.5362 9.48725 19 8 19C4.13401 19 1 15.866 1 12C1 8.13401 4.13401 5 8 5C9.48725 5 10.8662 5.46381 12 6.25469C13.1338 5.46381 14.5128 5 16 5C19.866 5 23 8.13401 23 12C23 15.866 19.866 19 16 19C14.5128 19 13.1338 18.5362 12 17.7453ZM12.8119 6.91614C13.7356 6.3357 14.8286 6 16 6C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18C14.8286 18 13.7356 17.6643 12.8119 17.0839C14.1594 15.808 15 14.0022 15 12C15 9.99784 14.1594 8.192 12.8119 6.91614ZM12 7.52779C13.2275 8.62643 14 10.223 14 12C14 13.777 13.2275 15.3736 12 16.4722C10.7725 15.3736 10 13.777 10 12C10 10.223 10.7725 8.62643 12 7.52779Z"
      fill="#5D647D"
    />
  </svg>
);

export default PaymentsIcon;
