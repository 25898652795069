import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserBonusCreateForm {
    position: relative;
    width: 600px;
    @include respond-to(sm) {
      max-width: 100%;
    }
    :global(.BaseTextField) {
      &:after {
        display: none;
      }
    }

    .UserBonusCreateForm__line {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include respond-to(sm) {
        flex-direction: column;
        align-items: flex-start;
      }

      :global(> div) {
        min-width: 160px;
        flex: 1;

        &:first-child {
          margin-right: rem(10px);

          @include respond-to(sm) {
            margin-right: 0;
          }
        }

        @include respond-to(sm) {
          width: 100%;
        }
      }
    }

    .formError {
      position: absolute;
      bottom: rem(60px);
    }

    :global(.DefaultButton) {
      margin-top: rem(25px);
      margin-right: rem(25px);
    }

    :global(.DefaultButton:last-child) {
      margin-right: 0;
      text-transform: unset;
      border-bottom: 1px dashed #0aa1e2 !important;
    }

    .UserBonusCreateForm__switch-field {
      display: flex;

      span {
        margin-right: rem(25px);
      }
    }

    :global(.DateField__openDirection--up) {
      :global(.SingleDatePicker_picker) {
        top: auto !important;
        bottom: 66px !important;
        left: -2px !important;
      }
    }

    :global(.DateField__openDirection--down) {
      :global(.SingleDatePicker_picker) {
        bottom: auto !important ;
        top: 66px !important;
        left: -2px !important;
      }
    }
  }
`;

export { staticStyles };
