import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextFormField {
    position: relative;
    margin-bottom: 10px;

    &__label {
      position: absolute;
      left: 16px;
      z-index: 10;

      font-size: 10px;
      line-height: 1.4;
      color: #999;
      background: #fff;
      padding: 0 4px;

      span {
        position: relative;
        top: -7px;
        z-index: 10;
      }

      &--error {
        color: #ff4085;
      }
    }

    &__input {
      border: 1px solid #f3f3f3;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #fff;
      font-size: 14px;
      padding: 12px 16px;
      margin-bottom: 10px;

      &::placeholder {
        color: #696969;
      }

      &--error {
        border: 1px solid #ff4085;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: #333;
        -webkit-box-shadow: 0 0 0px 40rem #fff inset;
      }
    }

    &__error {
      font-weight: 300;
      font-size: 14px;
      color: #ff4085;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
