import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserIssues {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(tr) {
          grid-template-columns: minmax(50px, 0.2fr) minmax(150px, 0.5fr) minmax(300px, 1fr) minmax(100px, 0.4fr);
        }
      }
    }
  }
`;

export { staticStyles };
