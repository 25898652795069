import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { ReactDataTable, MoneyCell, DefaultButton, StatusCell, BasicModal, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { RejectWithdrawalForm } from '../../payments/_components';

import { staticStyles, getDynamicStyles } from './style';

const UserWithdrawRequest = ({ totalAmount, requests, canReject, match, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const groupId = match.params.id;

  const [isOpen, setIsOpen] = useState(false);
  const [activeRequestId, setActiveRequestId] = useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleRejectWithdrawal = id => {
    setActiveRequestId(id);
    toggleModal();
  };

  /* eslint-disable */
  const columns = [
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'account',
      Header: 'justAccount',
      Cell: ({
        row: {
          original: { accountLogin },
        },
      }) => <span>{accountLogin}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'commission',
      Header: 'justCommission',
      Cell: ({
        row: {
          original: { id, commission },
        },
      }) => <MoneyCell tooltipId={`UserWithdrawRequest-justCommission-${id}`} inTable value={commission} />,
      isPreviewCell: true,
    },
    {
      accessor: 'paymentSystem',
      Header: 'boPaymentSystem',
      Cell: ({
        row: {
          original: { paymentMethodTitle },
        },
      }) => <span>{paymentMethodTitle}</span>,
    },
    {
      accessor: 'created',
      Header: 'justCreated',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
    },
    {
      accessor: 'amount',
      Header: 'justAmount',
      Cell: ({
        row: {
          original: { id, amount },
        },
      }) => <MoneyCell tooltipId={`UserWithdrawRequest-justAmount-${id}`} inTable value={amount} />,
      Footer: (
        <div className="TotalCell">
          <FormattedMessage id="justTotalAmount">{txt => <span className="subtext">{txt}</span>}</FormattedMessage>
          <MoneyCell value={totalAmount} />
        </div>
      ),
      isPreviewCell: true,
    },
    {
      accessor: 'status',
      Header: () => null,
      Cell: ({
        row: {
          original: { id, status },
        },
      }) => {
        return status === 'waiting_approval' && canReject ? (
          <DefaultButton
            textId="justReject"
            type="button"
            butColor="error"
            onClick={() => handleRejectWithdrawal(id)}
          />
        ) : (
          <StatusCell statusCode={status} />
        );
      },
    },
  ];
  /* eslint-enable */
  const onRowClick = (state, rowInfo) => {
    setActiveRequestId(rowInfo.original.id);
  };

  return (
    <div className="UserWithdrawRequest">
      <FormattedMessage id="justUsersWithdrawRequests">
        {txt => <div className="UserWithdrawRequest__title">{txt}</div>}
      </FormattedMessage>

      <ReactDataTable columns={columns} data={requests} onRowClick={onRowClick} isResponsive />

      <BasicModal isOpen={isOpen} captionId="justRejectWithdrawal" onRequestClose={toggleModal}>
        <RejectWithdrawalForm toggleModal={toggleModal} groupId={groupId} requestId={activeRequestId} />
      </BasicModal>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserWithdrawRequest.propTypes = {
  totalAmount: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  canReject: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

UserWithdrawRequest.defaultProps = {
  theme: {},
};

export default compose(withTheme(), withRouter)(UserWithdrawRequest);
