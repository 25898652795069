import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageContentPreloader } from 'common/components';

import { getKYC, getCountries } from '../../_redux';
import { VerificationPanel, KYCForm, TrederoCustomKYCForm } from '../../_components';

const KYCPage = ({
  user,
  kycValues,
  kycValuesAreLoaded,
  getKYC,
  countries,
  countriesAreLoaded,
  getCountries,
  states,
  addons,
  statesAreLoaded,
  passportMandatory,
  addressProofMandatory,
  identificationProofMandatory,
}) => {
  useEffect(() => {
    getKYC();
    getCountries();
  }, [getKYC, getCountries]);

  const { passportStatus, addressProofStatus, identificationProofStatus } = user;

  const passportDisplayed = !passportMandatory || (passportMandatory && passportStatus !== 'notUploaded');
  const addressProofDisplayed =
    !addressProofMandatory || (addressProofMandatory && addressProofStatus !== 'notUploaded');
  const identificationProofDisplayed =
    !identificationProofMandatory || (identificationProofMandatory && identificationProofStatus !== 'notUploaded');

  const mandatoryDocumentsAreSending = passportDisplayed && addressProofDisplayed && identificationProofDisplayed;

  return (
    <VerificationPanel
      captionId="persInfo"
      warningMessageTextId={user.needsVerification ? 'kycPersonalMessage' : 'kycPersonalMessageDocuments'}>
      {!addons.includes('trederoCustomKyc') && user && (
        <PageContentPreloader ready={kycValuesAreLoaded} type="text">
          <KYCForm
            user={user}
            initialValues={{ ...kycValues, ...user }}
            countries={countries}
            states={states}
            countriesAreLoaded={countriesAreLoaded}
            statesAreLoaded={statesAreLoaded}
            mandatoryDocumentsAreSending={mandatoryDocumentsAreSending}
          />
        </PageContentPreloader>
      )}
      {addons.includes('trederoCustomKyc') && user && (
        <PageContentPreloader ready={kycValuesAreLoaded} type="text">
          <TrederoCustomKYCForm
            user={user}
            initialValues={{ ...kycValues, ...user }}
            countries={countries}
            states={states}
            countriesAreLoaded={countriesAreLoaded}
            statesAreLoaded={statesAreLoaded}
            mandatoryDocumentsAreSending={mandatoryDocumentsAreSending}
          />
        </PageContentPreloader>
      )}
    </VerificationPanel>
  );
};

KYCPage.propTypes = {
  getKYC: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  kycValues: PropTypes.object.isRequired,
  kycValuesAreLoaded: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  statesAreLoaded: PropTypes.bool.isRequired,
  passportMandatory: PropTypes.bool.isRequired,
  addressProofMandatory: PropTypes.bool.isRequired,
  identificationProofMandatory: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    user: state.user.profile,
    kycValues: state.client.kyc.kycValues,
    kycValuesAreLoaded: state.client.kyc.kycValuesAreLoaded,
    countries: state.client.kyc.countries,
    countriesAreLoaded: state.client.kyc.countriesAreLoaded,
    states: state.client.kyc.states,
    statesAreLoaded: state.client.kyc.statesAreLoaded,
    passportMandatory: state.interfaceConfig.kycSettings.passport.mandatory,
    addressProofMandatory: state.interfaceConfig.kycSettings.address_proof.mandatory,
    identificationProofMandatory: state.interfaceConfig.kycSettings.identification_proof.mandatory,
    addons: state.interfaceConfig.addons,
  }),
  { getKYC: () => getKYC.request(), getCountries: () => getCountries.request() }
)(KYCPage);
export { KYCPage };
