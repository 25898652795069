import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { FormMessageHOC } from 'common';
import { ReduxTextField, ReduxSelectField, DateField, DefaultButton } from 'common/components';
import { editOrder } from 'backoffice/payments/_redux';
import { useHistory } from 'react-router-dom';
import { staticStyles } from './style';

const EditOrderForm = ({
  userId,
  accountId,
  orderId,
  orderDetails,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
  intl,
}) => {
  const submit = handleSubmit(values => editOrder({ userId, accountId, orderId, ...values }, dispatch));
  const history = useHistory();
  const { commands, reasons } = orderDetails;

  return (
    <form className="EditOrderForm" onSubmit={submit}>
      <div className="EditOrderForm__fields">
        <div className="EditOrderForm__wide-field">
          <Field
            component={ReduxTextField}
            name="login"
            textId="justLogin"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
        <Field
          component={ReduxSelectField}
          name="reason"
          textId="justReason"
          options={reasons.map(reason => ({ value: reason, label: reason }))}
          disabled={submitting}
        />
        <Field
          component={ReduxSelectField}
          name="command"
          textId="justType"
          options={commands.map(command => ({ value: command, label: command }))}
          disabled={submitting}
        />
        <Field component={ReduxTextField} name="lots" type="number" textId="justLots" disabled={submitting} />
        <Field component={ReduxTextField} name="symbol" textId="justSymbol" disabled={submitting} />
        <Field
          component={DateField}
          type="date"
          name="open_time"
          textId="justOpenTime"
          placeholder="dd.mm.yyyy"
          disabled={submitting}
          areFutureDaysAvailable
          withTime
        />
        <Field
          component={ReduxTextField}
          name="open_price"
          type="number"
          textId="justOpenPrice"
          disabled={submitting}
        />
        <Field component={ReduxTextField} name="stoploss" type="number" textId="justStopLoss" disabled={submitting} />
        <Field
          component={DateField}
          type="date"
          name="close_time"
          textId="justCloseTime"
          placeholder="dd.mm.yyyy"
          disabled={submitting}
          areFutureDaysAvailable
          withTime
        />
        <Field
          component={ReduxTextField}
          name="close_price"
          type="number"
          textId="justClosePrice"
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          name="takeprofit"
          type="number"
          textId="justTakeProfit"
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          name="conv_rates_0"
          type="number"
          textId="firstConvRate"
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          name="conv_rate"
          type="number"
          textId="secondConvRate"
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          name="margin_rate"
          type="number"
          textId="justMarginRate"
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          name="commission"
          type="number"
          textId="justCommission"
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          name="commission_agent"
          type="number"
          textId="justAgentCommission"
          disabled={submitting}
        />
        <Field component={ReduxTextField} name="swap" type="number" textId="justSwap" disabled={submitting} />
        <div className="EditOrderForm__wide-field">
          <Field component={ReduxTextField} name="comment" textId="justComment" disabled={submitting} />
        </div>
        <Field component={ReduxTextField} name="taxes" textId="justTaxes" disabled={submitting} />
        <Field
          component={DateField}
          type="date"
          name="expiration"
          textId="paymentExpirationDate"
          placeholder="dd.mm.yyyy"
          disabled={submitting}
          areFutureDaysAvailable
        />
        <Field component={ReduxTextField} name="magic" textId="justMagic" disabled />
        <Field component={ReduxTextField} name="profit" type="number" textId="justProfit" disabled={submitting} />
        <Field
          component={ReduxTextField}
          name="gw_order"
          type="number"
          textId="justGatewayOrder"
          validate={[required()]}
          disabled={submitting}
        />
        <div className="EditOrderForm__wide-field">
          <Field
            component={ReduxTextField}
            name="gw_open_price"
            type="number"
            textId="justOpenPriceDelta"
            disabled={submitting}
            description={intl.formatMessage({ id: 'justPips' })}
          />
        </div>
        <Field
          component={ReduxTextField}
          name="gw_volume"
          type="number"
          textId="justGatewayLots"
          disabled={submitting}
        />
        <div className="EditOrderForm__wide-field">
          <Field
            component={ReduxTextField}
            name="gw_close_price"
            type="number"
            textId="justClosePriceDelta"
            disabled={submitting}
            description={intl.formatMessage({ id: 'justPips' })}
          />
        </div>
      </div>
      {error && <strong className="formError">{error}</strong>}

      <DefaultButton textId="doSave" type="submit" loading={submitting} disabled={invalid || submitting} filled />
      <DefaultButton textId="justCancel" onClick={() => history.goBack()} disabled={submitting} />
      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditOrderForm.propTypes = {
  userId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  error: PropTypes.string,
  orderDetails: PropTypes.object,
};

EditOrderForm.defaultProps = {
  orderDetails: {},
  error: '',
};

export default compose(
  injectIntl,
  FormMessageHOC,
  reduxForm({
    form: 'EditOrderForm',
    onSubmitFail(result, dispatch, error, props) {
      props.showFormFailMessage(props.failMessageTextId);
    },
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage(props.successMessageId);
    },
  })
)(EditOrderForm);
export { EditOrderForm };
