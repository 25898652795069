import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const PartnershipIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 16">
      <defs>
        <linearGradient id="linearGradient-4" x1="4.248%" x2="95.826%" y1="86.836%" y2="18.008%">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
      <path
        fill="url(#linearGradient-4)"
        d="M24.569 3.068L22.616.67a1.83 1.83 0 0 0-2.381-.394l-1.624.99a1.76 1.76 0 0 1-1.734.048L15.583.61a2.8 2.8 0 0 0-2.832.096l-.305.19-.293-.179A3.477 3.477 0 0 0 8.856.574l-.451.227a3.04 3.04 0 0 1-2.76-.072L5.34.562c-.89-.489-2.038-.31-2.722.454L.457 3.414c-.33.358-.488.823-.452 1.312.037.477.269.919.647 1.229l1.93 1.587-.868.906a1.726 1.726 0 0 0-.476 1.24c.012.466.22.896.562 1.206.244.215.525.358.83.43a1.67 1.67 0 0 0-.159.775c.013.465.22.895.562 1.205a1.783 1.783 0 0 0 1.843.346c.074.334.257.644.513.883.342.31.77.465 1.209.465.317 0 .622-.084.903-.239.086.287.244.55.476.752.342.31.77.465 1.21.465a1.75 1.75 0 0 0 1.293-.549l.757-.787 1.197.978c.317.25.695.382 1.062.382a1.67 1.67 0 0 0 1.233-.537 1.45 1.45 0 0 0 .354-.62l.366.322a1.842 1.842 0 0 0 2.515-.12c.244-.262.403-.584.452-.918.622.19 1.343.036 1.807-.477.403-.43.525-1.026.39-1.551a1.6 1.6 0 0 0 .928-.513 1.593 1.593 0 0 0-.146-2.315l-.135-.119 3.236-3.59a1.899 1.899 0 0 0 .073-2.494zM2.459 10.19a.804.804 0 0 1-.256-.549.771.771 0 0 1 .22-.572l1.916-2.005a.816.816 0 0 1 .586-.25.804.804 0 0 1 .806.764.79.79 0 0 1-.22.572l-1.916 2.004a.815.815 0 0 1-.562.25.856.856 0 0 1-.574-.214zM3.68 12.6a.804.804 0 0 1-.256-.549.771.771 0 0 1 .22-.573l2.624-2.743a.824.824 0 0 1 1.148-.036c.33.298.342.8.036 1.121l-.537.56-.732.764-1.368 1.42a.82.82 0 0 1-1.135.036zm2.356 1.229a.804.804 0 0 1-.256-.55.771.771 0 0 1 .22-.572l.89-.93.733-.764a.824.824 0 0 1 1.148-.036c.33.299.342.8.037 1.122l-.208.215-.708.74-.708.739a.836.836 0 0 1-1.148.036zm3.748.942a.824.824 0 0 1-1.147.036.78.78 0 0 1-.037-1.121l.916-.955a.815.815 0 0 1 .561-.25.81.81 0 0 1 .586.214c.33.299.342.8.037 1.122l-.916.954zM23.788 4.93L20.54 8.532l-5.09-4.26a2.476 2.476 0 0 0-1.6-.572 2.45 2.45 0 0 0-1.111.262l-.708.346a.86.86 0 0 1-1.087-.274c-.134-.19-.183-.417-.134-.644a.88.88 0 0 1 .378-.549l2.1-1.348a1.794 1.794 0 0 1 1.844-.06l1.294.704c.842.465 1.892.43 2.71-.071l1.624-.99a.85.85 0 0 1 1.11.19l1.954 2.398c.305.37.281.895-.036 1.265z"
      />
    </svg>
  </div>
);

PartnershipIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

PartnershipIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedPartnershipIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(PartnershipIconActive);

export default ThemedPartnershipIconActive;
