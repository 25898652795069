import React from 'react';
import PropTypes from 'prop-types';

import { ErrorPage, BlockedBrokerPage } from 'common/components';

const InterfaceErrorHandler = ({ error }) => {
  switch (error) {
    case 418:
      return <ErrorPage caption="Sorry, the broker is not available now" />;
    case 403:
      return <BlockedBrokerPage />;
    default:
      return (
        <ErrorPage
          caption="Service Unavailable"
          description="We are currently experiencing technical difficulties.\n Please try again"
        />
      );
  }
};

InterfaceErrorHandler.propTypes = {
  error: PropTypes.number.isRequired,
};

export default InterfaceErrorHandler;
