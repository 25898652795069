import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';

import wsAuthBackground from '../../_public/images/wsAuthBackground.png';

import { ResetPasswordForm, ActionsButton, CrossIcon } from '../../_components';

import { staticStyles } from './style';

const ResetPasswordPage = ({ intl }) => {
  const history = useHistory();
  return (
    <div className="ResetPasswordPage">
      <Helmet>
        <title>{intl.formatMessage({ id: 'welcomeSetupResetPasswordTitle' })}</title>
      </Helmet>
      <img className="ResetPasswordPage__background-image" src={wsAuthBackground} alt="ws-reset-password-background" />
      <div className="ResetPasswordPage__inner">
        <div className="innerHeader">
          <FormattedMessage id="authResetPassword">
            {txt => <span className="innerHeader__resetText">{txt}</span>}
          </FormattedMessage>
          <ActionsButton
            tooltipId="Edit__tooltip"
            tooltipTextId="justBackToRegistration"
            edit
            onClickFunc={() => history.push('/welcome-setup/registration')}>
            <CrossIcon />
          </ActionsButton>
        </div>
        <FormattedMessage id="authResetPasswordDescr">
          {txt => <span className="resetPasswordPage__desc">{txt}</span>}{' '}
        </FormattedMessage>
        <ResetPasswordForm />
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ResetPasswordPage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ResetPasswordPage);
