import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getUserAccounts } from 'backoffice/users/_redux';

import { withTheme, isRTL } from 'common/styling/theme';
import { LoginCell, MoneyCell } from 'common/components/';
import { staticStyles, getDynamicStyles } from './style';

const UserAccounts = ({ accounts, accountsAreLoaded, getUserAccounts, user, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [delayIsDone, setDelayIsDone] = useState(false);

  useEffect(() => {
    getUserAccounts({ userId: user.id });
  }, [getUserAccounts, user.id]);

  useEffect(() => {
    const timer = setTimeout(() => setDelayIsDone(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const renderView = props => (
    <div
      {...props}
      style={{
        ...props.style,
        marginLeft: isRTL ? props.style.marginRight : 0,
        marginRight: isRTL ? 0 : props.style.marginRight,
      }}
    />
  );

  return (
    <div className="UserAccounts">
      {accountsAreLoaded && delayIsDone ? (
        <Scrollbars renderView={renderView} className="BasicModal__scroll" autoHeight autoHeightMax={280}>
          {accounts.length ? (
            <div>
              {accounts.map(account => (
                <div className="UserAccountsTable" key={account.id}>
                  <div className="UserAccountsTable__image">
                    <LoginCell title={account.accountTypeTitle} />
                  </div>
                  <div className="UserAccountsTable__content">
                    <div className="UserAccountsTable__line">
                      <span className="UserAccountsTable__value">{account.login}</span>
                      <span className="UserAccountsTable__value">{account.accountTypeTitle}</span>
                    </div>
                    <div className="UserAccountsTable__line">
                      <FormattedMessage id="boBalance">
                        {txt => <span className="UserAccountsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserAccountsTable__value">
                        {account.balance ? <MoneyCell value={account.balance} /> : <span>—</span>}
                      </span>
                    </div>
                    <div className="UserAccountsTable__line">
                      <FormattedMessage id="boEquity">
                        {txt => <span className="UserAccountsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserAccountsTable__value">
                        {account.equity ? <MoneyCell value={account.equity} /> : <span>—</span>}
                      </span>
                    </div>
                    <div className="UserAccountsTable__line">
                      <FormattedMessage id="justLeverage">
                        {txt => <span className="UserAccountsTable__key">{`${txt}:`}</span>}
                      </FormattedMessage>
                      <span className="UserAccountsTable__value">
                        {account.leverage ? `1:${account.leverage}` : <span>—</span>}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <FormattedMessage id="noUserAccounts">
              {txt => <div className="UserAccountsTable__description">{txt}</div>}
            </FormattedMessage>
          )}
        </Scrollbars>
      ) : (
        <FormattedMessage id="justLoading">{txt => <div className="LoadingText">{txt}</div>}</FormattedMessage>
      )}

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserAccounts.propTypes = {
  getUserAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  accountsAreLoaded: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  theme: PropTypes.object,
  // eslint-disable-next-line
  style: PropTypes.object,
};

UserAccounts.defaultProps = {
  theme: {},
  style: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      profile: state.user.profile,
      accounts: state.backoffice.users.accounts.filter(account => !account.isArchived),
      accountsAreLoaded: state.backoffice.users.accountsAreLoaded,
    }),
    {
      getUserAccounts: user => getUserAccounts.request(user),
    }
  )
)(UserAccounts);
