import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const Collapsible = ({ captionId, title, children, handleOpen, theme, opened }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isOpen, setOpen] = useState(opened);

  const togglePanel = () => {
    setOpen(!isOpen);
  };

  /* eslint-disable */
  useMemo(() => {
    if (handleOpen && isOpen) handleOpen();
  }, [isOpen]);
  /* eslint-enable */

  return (
    <div className="Collapsible">
      <button type="button" onClick={togglePanel} className="Collapsible__header">
        {title ? (
          <div className="Collapsible__caption">{title}</div>
        ) : (
          <FormattedMessage id={captionId}>{txt => <div className="Collapsible__caption">{txt}</div>}</FormattedMessage>
        )}
        <i
          className={classNames('Collapsible__arrow', {
            'Collapsible__arrow-right': !isOpen,
            'Collapsible__arrow-down': isOpen,
          })}
        />
      </button>
      {isOpen && <div className="Collapsible__content">{children}</div>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

Collapsible.propTypes = {
  captionId: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  opened: PropTypes.bool,
  handleOpen: PropTypes.func,
  theme: PropTypes.object,
};

Collapsible.defaultProps = {
  captionId: 'justCollapsible',
  title: '',
  opened: false,
  handleOpen: () => {},
  theme: {},
};

export default withTheme()(Collapsible);
export { Collapsible };
