import React, { useEffect, useState, useMemo } from 'react';
import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { required } from 'redux-form-validators';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { DefaultButton, ReduxSelectField } from 'common/components';

import { activateChallenge } from '../../../../_redux/actions';

import { staticStyles } from './style';

const StartChallengeForm = ({
  typeValue,
  budgetValue,
  challenges,
  handleSubmit,
  submitting,
  initialize,
  invalid,
  dispatch,
  error,
}) => {
  const typeOptions = challenges.map(challengeType => ({ label: challengeType.title, value: challengeType.id }));
  let budgetOptions = [];
  const selectedChallengeType = challenges.find(({ id }) => id === typeValue);
  if (selectedChallengeType) {
    budgetOptions = selectedChallengeType.challenges.map(challenge => ({
      label: challenge.initialAccountBalance,
      value: challenge.id,
    }));
  }

  const submit = handleSubmit(values => {
    activateChallenge({ id: values.budget }, dispatch);
  });

  const selectedChallengeData = useMemo(() => {
    if (typeof budgetValue === 'number') {
      const selectedGroup = challenges.find(({ id }) => id === typeValue);

      if (!selectedGroup) return null;

      return selectedGroup.challenges.find(({ id }) => id === budgetValue);
    }
    return null;
  }, [budgetValue, typeValue, challenges]);

  const [selectedLevel, setSelectedLevel] = useState(0);

  useEffect(() => {
    setSelectedLevel(0);
  }, []);

  const selectedLevelData = useMemo(() => {
    if (!selectedChallengeData) {
      return null;
    }

    return selectedChallengeData.levels[selectedLevel];
  }, [budgetValue, selectedLevel, typeValue, selectedChallengeData]);

  return (
    <form className="StartChallengeForm" onSubmit={submit}>
      <FM id="justStartChallengeFormTopText">{txt => <span className="StartChallengeForm__TopText">{txt}</span>}</FM>
      <Field
        component={ReduxSelectField}
        name="type"
        maxMenuHeight={140}
        textId="justChallengeType"
        options={typeOptions}
        validate={[required()]}
        onChange={() => {
          initialize({ type: typeValue, budget: null });
        }}
        disabled={submitting}
        searchable
      />
      <Field
        component={ReduxSelectField}
        name="budget"
        maxMenuHeight={140}
        textId="justChallengeBalance"
        options={budgetOptions}
        validate={[required()]}
        disabled={submitting}
        searchable
      />
      {selectedChallengeData && (
        <div className="StartChallengeFormTable">
          <div className="StartChallengeFormTable__Header">
            {selectedChallengeData.levels.map((level, ind) => (
              <button
                key={`${level.name + ind}`}
                onClick={() => {
                  setSelectedLevel(ind);
                }}
                type="button"
                className={
                  selectedLevel === ind
                    ? 'StartChallengeFormTable__name StartChallengeFormTable__name--fullOpacity'
                    : 'StartChallengeFormTable__name'
                }>
                <div className="Round">{ind + 1}</div>
                <div className="Name">{level.name}</div>
              </button>
            ))}
          </div>
          <table width="100%">
            <tr>
              <td>
                <FM id="justMinimalTradingDays" />
              </td>
              <td>
                <FM id="justMaximumDailyLoss" />
              </td>
              <td>
                <FM id="justMaximumLoss" />
              </td>
              <td>
                <FM id="justProfitTarget" />
              </td>
            </tr>
            <tr>
              <td>{selectedLevelData.minimum_trading_days}</td>
              <td style={{ color: '#EE3235' }}>
                {`${selectedLevelData.maximum_daily_loss.amount} ${selectedLevelData.maximum_daily_loss.currency}`}
              </td>
              <td style={{ color: '#EE3235' }}>
                {`${selectedLevelData.maximum_total_loss.amount} ${selectedLevelData.maximum_total_loss.currency}`}
              </td>
              <td style={{ color: '#39B841' }}>
                {`${selectedLevelData.target.amount} ${selectedLevelData.target.currency}`}
              </td>
            </tr>
          </table>
        </div>
      )}

      <div className="StartChallengeForm__bottom">
        <div className="StartChallengeForm__RefundableFee">
          <FM id="justRefundableFee">{txt => <span>{txt}:</span>}</FM>

          <div>{selectedChallengeData?.cost ?? '---'}</div>
        </div>

        <div className="StartChallengeForm__LeftText">
          <FM id="justStartChallengeFormLeftText">{txt => txt}</FM>
        </div>
        <div className="DivWithDefButton">
          <DefaultButton
            textId="justStartChallenge"
            type="submit"
            disabled={invalid || submitting}
            loading={submitting}
            filled
          />
        </div>
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

const selector = formValueSelector('startChallengeForm');

export default compose(
  FormMessageHOC,
  connect(state => {
    console.log('state', state);
    const { type: typeValue, budget: budgetValue } = selector(state, 'type', 'budget');
    return { typeValue, budgetValue };
  }),
  reduxForm({
    form: 'startChallengeForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
    enableReinitialize: true,
  })
)(StartChallengeForm);
