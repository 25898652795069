import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, ReduxSelectField } from 'common/components';

import { closeInvestment } from 'client/invest/_redux';

import staticStyles from './style';

const InvestCloseForm = ({
  accounts,
  investmentId,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const submit = handleSubmit(values =>
    closeInvestment(
      {
        investmentId,
        ...values,
      },
      dispatch
    )
  );

  const accountsOptions = accounts.map(account => ({
    value: account.id,
    label: `${account.login} (${account.balance.amount} ${account.balance.currency})`,
  }));

  return (
    <div className="Modal__content">
      <form className="InvestWithdrawForm" onSubmit={submit}>
        <div className="InvestWithdrawForm__inputs-inner">
          <Field
            component={ReduxSelectField}
            name="account"
            maxMenuHeight={140}
            textId="justAccount"
            options={accountsOptions}
            disabled={submitting}
            validate={[required()]}
            searchable
          />

          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="InvestWithdrawForm__btns">
          <DefaultButton
            textId="justClose"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />

          <DefaultButton textId="justCancel" type="button" onClick={() => toggleModal()} />
        </div>
        <style jsx>{staticStyles}</style>
      </form>
    </div>
  );
};

InvestCloseForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  investmentId: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

InvestCloseForm.defaultProps = {
  error: '',
};

export default compose(
  reduxForm({
    form: 'InvestCloseForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(InvestCloseForm);
export { InvestCloseForm };
