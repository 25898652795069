import css from 'styled-jsx/css';
import { gradient, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseSelectField {
    position: relative;
    transition: all 0.4s ease;
    margin-bottom: 10px;
    @include respond-to(sm) {
      &:after {
        content: '';
        position: absolute;
        top: 25%;
        right: 15px;
        width: 17px;
        height: 17px;
        border: solid;
        transform: rotate(45deg);
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transition: all 0.4s ease;
      }
      &.disabled {
        &:after {
          opacity: 0.2;
        }
      }
    }

    &.isMulti {
      &:after {
        display: none;
      }
    }

    .Select__value-container {
      padding: 0 !important;
    }

    .Select {
      &--is-disabled {
        .Select__control {
          opacity: 1 !important;
          position: static;
          &:after {
            opacity: 0.2;
          }
        }
      }

      &--is-disabled + label {
        cursor: default !important;
        opacity: 1 !important;
        z-index: 6;
      }
    }

    .Select__control {
      padding: rem(20px 20px 4px 11px) !important;
      transition: all 0.4s ease !important;
      position: relative;
      z-index: 2;
      cursor: default !important;
      min-height: rem(54px) !important;
      &:after {
        content: '';
        position: absolute;
        top: 25%;
        right: 15px;
        width: 17px;
        height: 17px;
        border: solid;
        transform: rotate(45deg);
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transition: all 0.4s ease;
      }
    }

    .Select__dropdown-indicator {
      display: none !important;
    }

    .Select__loading-indicator {
      position: relative;
      bottom: 8px;
      right: 20px;
    }

    .Select__placeholder {
      margin: 0 0 0 3px;
      padding: 0;
    }
  }

  .BaseSelectField__label {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(${isRTL ? '12px' : 'auto'});
    left: rem(${isRTL ? 'auto' : '12px'});
    transition: all 0.25s ease;
    cursor: text;
    @include respond-to(sm) {
      font-size: 14px;
    }

    &.haveText {
      top: 6px;
      font-size: rem(12px);
      transform: translateY(0);
      height: 20px;
      overflow: hidden;
    }

    &.active {
      top: 6px;
      font-size: rem(12px);
      transform: translateY(0);
      height: 20px;
      overflow: hidden;
    }
  }

  .ReduxSelectField__error {
    width: 100%;
    display: inline-block;
    min-height: 15px;
  }

  .ReduxSelectField {
    @include respond-to(sm) {
      &:after {
        top: 18%;
      }
    }
    .BaseSelectField__label {
      top: 37%;
      &.active {
        top: 6px;
      }
      &.haveText {
        top: 6px;
      }
    }
  }

  .AsyncSelectField {
    &:after {
      display: none;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .BaseSelectField__label {
      transition: none;
    }
  }

  @supports (-ms-ime-align: auto) {
    .BaseSelectField__label {
      transition: none;
    }
  }

  .MobileSelectField {
    width: 100%;
    background: transparent;
    position: relative;
    padding: rem(26px 32px 10px 13px);
    border: 1px solid;
    z-index: 2;
    transition: all 0.4s ease;
    &:disabled {
      z-index: 0;
    }
  }

  .isMulti {
    .BaseSelectField__label {
      &.haveText {
        display: none;
      }
    }
    .Select__control {
      padding: rem(${isRTL ? '10px 13px 8px 25px' : '10px 25px 8px 13px'}) !important;
      min-height: rem(54px) !important;
      &:after {
        top: 50%;
        transform: translateY(-75%) rotate(45deg);
      }
    }
    .Select__value-container--is-multi {
      display: flex;
    }
    .Select__multi-value {
      margin-top: 0;
      display: flex;
      width: auto;
      align-items: center;
      vertical-align: middle;
      border: none;
      border-radius: 3px;
      padding: 5px 10px 6px 12px;
      max-width: 95%;
      @include respond-to(sm) {
        padding: 5px 7px 6px 9px;
        max-width: 90%;
      }
    }
    .Select__multi-value__remove {
      order: 1;
      padding: 0;
      border-radius: 0;
      font-size: 0;
      line-height: 0;
      width: 15px;
      height: 15px;
      position: relative;
      background-color: transparent !important;
      cursor: pointer;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 15px;
        height: 1px;
        transition: all 0.4s ease;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
      svg {
        display: none;
      }
    }
    .Select__multi-value__label {
      font-size: 15px;
      padding: 0;
      border-radius: 0;
      line-height: 1.4;
      margin-right: 10px;
      width: calc(100% - 25px);
      @include respond-to(sm) {
        font-size: 13px;
      }
    }
  }

  .BaseSelectField__noLabel {
    .Select__control,
    .MobileSelectField {
      padding: rem(10px 50px 10px 15px) !important;
    }
    @include respond-to(sm) {
      &:after {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    .Select__menu {
      .Select__option {
        background-color: ${colors.white.hex};
        color: ${colors.black.hex};

        &--is-selected {
          color: ${colors.secondary.hex};
          background: ${gradient(
            53.07,
            [colors.gradientStart.rgb, 0.25],
            0,
            [colors.gradientEnd.rgb, 0.25],
            100
          )} !important;
        }

        &--is-focused {
          color: ${colors.secondary.hex};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
        }

        &--is-disabled {
          color: ${colors.gray.hex};
          background-color: ${colors.lightGray.hex};
        }
      }
    }

    .BaseSelectField {
      .Select {
        &--is-disabled {
          .Select__control {
            background: ${colors.disabled.hex} !important;
            color: ${colors.disabled.hex};
            -webkit-text-fill-color: ${colors.black.hex};
          }
        }
      }

      .Select__control {
        border: 1px solid ${colors.lightGray.hex} !important;
        border-radius: ${border.radius} !important;
        &:after {
          border-color: ${colors.gray.hex};
          left: ${isRTL ? '14px' : 'auto'};
          right: ${isRTL ? 'auto' : '14px'};
        }
      }

      .Select__control--is-focused {
        border: 1px solid ${colors.primary.hex} !important;
        &:after {
          border-color: ${colors.primary.hex} !important;
        }
      }

      .Select__loading-indicator {
        span {
          background: ${colors.primary.hex};
        }
      }

      .Select__option {
        background-color: ${colors.white.hex};
        color: ${colors.black.hex};

        &--is-selected {
          color: ${colors.secondary.hex};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 0.25], 0, [colors.gradientEnd.rgb, 0.25], 100)};
        }

        &--is-focused {
          color: ${colors.secondary.hex};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
        }

        &--is-disabled {
          color: ${colors.gray.hex};
          background-color: ${colors.lightGray.hex};
        }
      }
    }

    .BaseSelectField__label {
      color: ${colors.gray.hex};

      &.haveText {
        color: ${colors.gray.hex};
      }
      &.active {
        color: ${colors.primary.hex};
      }
    }

    .ReduxSelectField__error-text {
      color: ${colors.error.hex};
    }

    .ReduxSelectField__error {
      .BaseSelectField__label {
        color: ${colors.error.hex} !important;
      }
      .Select__control--is-focused {
        border: 1px solid ${colors.error.hex} !important;
        &:after {
          border-color: ${colors.error.hex} !important;
        }
      }
      .Select__control {
        border-color: ${colors.error.hex} !important;
        &:after {
          border-color: ${colors.error.hex} !important;
        }
      }
    }

    .BaseSelectField {
      @include respond-to(sm) {
        &:after {
          border-color: ${colors.gray.hex};
        }
      }
      &.active {
        .MobileSelectField {
          border-color: ${colors.primary.hex};
        }
        &:after {
          border-color: ${colors.primary.hex};
        }
      }
    }

    .ReduxSelectField__error {
      .MobileSelectField {
        border-color: ${colors.error.hex};
      }
      &:after {
        border-color: ${colors.error.hex};
      }
      &.active {
        .MobileSelectField {
          border-color: ${colors.error.hex};
        }
        &:after {
          border-color: ${colors.error.hex};
        }
      }
    }

    .MobileSelectField {
      border-radius: ${border.radius};
      border-color: ${colors.lightGray.hex};
      &:disabled {
        background: ${colors.disabled.hex} !important;
        color: ${colors.disabled.hex};
        -webkit-text-fill-color: ${colors.black.hex};
      }
    }

    .Select__multi-value__label {
      color: ${colors.white.hex} !important;
    }

    .isMulti {
      .Select__multi-value {
        background: ${colors.primary.hex};
      }
      .Select__multi-value__remove {
        &:after,
        &:before {
          background: ${colors.white.hex};
        }
        &:hover {
          &:after,
          &:before {
            background: ${colors.error.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
