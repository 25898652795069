import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ResetPasswordForm {
    &__nav-btns {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .DefaultButton {
      margin-top: 10px;

      &:last-child {
        margin-top: 15px;
      }
    }

    > .ReduxTextField {
      margin-bottom: 32px !important;
    }
  }
`;

export { staticStyles };
