import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .RegistrationForm {
    width: 100%;
    grid-column: 1/6;
    &__bio {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      margin-bottom: 14px;
      > div:nth-child(1) {
        margin-right: 8px;
      }
      > div:nth-child(2) {
        margin-left: 8px;
      }

      > div:not(:last-child) {
        margin-bottom: 0 !important;
      }
      > div:last-child {
        margin-top: 8px !important;
        grid-column: 1/3;
      }
      @include respond-to(lg) {
        display: flex;
        flex-direction: column;
        > div:first-child {
          padding-bottom: 8px;
        }
        > div:not(:last-child) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
    &__additional-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      margin-bottom: 16px;
      > div {
        margin-bottom: 8px !important;
      }
      > div:nth-child(1) {
        grid-column: 1/3;
      }
      > div:nth-child(2) {
        grid-column: 1/3;
      }
      > div:nth-last-child(2) {
        margin-right: 8px !important;
      }
      > div:nth-last-child(1) {
        margin-left: 8px !important;
      }
      @include respond-to(lg) {
        display: flex;
        flex-direction: column;
        > div:last-child {
          margin-left: 0 !important;
        }
        > div:nth-last-child(2) {
          margin-right: 0 !important;
        }
      }
    }

    &__check-mail {
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;

      > span {
        margin-left: 3px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
`;

export { staticStyles };
