import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ActionsButton, ReactDataTable } from 'common/components';
import { EditIcon, BasketIcon } from 'common/icons';

import staticStyles from './style';

const StatusesTable = ({ data, onRemoveStatus, onEditStatus, onSortGetData }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: 'justTitle',
      accessor: 'title',
      Cell: ({
        row: {
          original: { title },
        },
      }) => <span>{title}</span>,
    },
    {
      Header: 'justLevel',
      accessor: 'level',
      Cell: ({
        row: {
          original: { level },
        },
      }) => <span>{level}</span>,
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <div className="StatusesTable__actions">
          <ActionsButton
            tooltipId="Edit__tooltip"
            tooltipTextId="justEditStatus"
            edit
            onClickFunc={() => onEditStatus(id)}>
            <EditIcon />
          </ActionsButton>
          <ActionsButton
            tooltipId="Delete__tooltip"
            tooltipTextId="justDeleteStatus"
            deleteType
            onClickFunc={() => onRemoveStatus(id)}>
            <BasketIcon />
          </ActionsButton>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  const sortRef = useRef([1, '']);
  const onChangeSort = arr => {
    if (!arr[0]) {
      onSortGetData();
      return;
    }
    if (arr[0].id !== sortRef.current[1]) {
      sortRef.current = [1, arr[0].id];
    }
    if (sortRef.current[0] === 1) {
      sortRef.current[0] = 2;
      onSortGetData(`-${arr[0].id}`);
      return;
    }
    if (sortRef.current[0] === 2) {
      sortRef.current[0] = 1;
      onSortGetData(arr[0].id);
    }
  };

  return (
    <div className="StatusesTable">
      <ReactDataTable columns={columns} data={data} onRowClick={() => {}} onChangeSort={onChangeSort} />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

StatusesTable.propTypes = {
  data: PropTypes.array.isRequired,
  onRemoveStatus: PropTypes.func.isRequired,
  onEditStatus: PropTypes.func.isRequired,
  onSortGetData: PropTypes.func,
};

StatusesTable.defaultProps = {
  onSortGetData: () => {},
};

export default StatusesTable;
