import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DefaultButton, BasicModal } from 'common/components';
import GetBannerForm from '../GetBannerForm';

import { staticStyles } from './style';

const Banner = ({ partnerAccounts, data: { id, link, preview, campaignName } }) => {
  const [isOpen, setIsOpen] = useState(false);

  const partnerAccountOptions = partnerAccounts.map(account => ({ value: account.id, label: account.partnerCode }));

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    toggleModal();
  };

  return (
    <div className="Banner">
      <div className="Banner__img">
        <img src={preview} alt={`Banner__${id}`} />
      </div>
      <div className="Banner__btn">
        <DefaultButton textId="justGetBanner" type="button" onClick={() => handleClick()} />
      </div>
      <BasicModal isOpen={isOpen} captionId="justGetBanner" onRequestClose={toggleModal}>
        <GetBannerForm
          bannerFields={{ preview, link, campaignName }}
          partnerAccountOptions={partnerAccountOptions}
          bannerId={id}
          toggleModal={toggleModal}
        />
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

Banner.propTypes = {
  data: PropTypes.object.isRequired,
  partnerAccounts: PropTypes.array.isRequired,
};

export default Banner;
