import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .NewCRMSelect__Wrapper {
    width: 390px;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .NewCRMSelect {
    position: relative;
    width: 100%;
    height: max-content;
    border-bottom: 0.5px solid #e9e9e9;
    padding: 15px 20px 5px 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    &--Loading {
      cursor: wait;
      animation-name: fade-loading;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      transition: all 0.9s ease;
      animation-direction: alternate;
      @keyframes fade-loading {
        0% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    }
    &__SelectedOption {
      background: #343846;
      border-radius: 10px;
      padding: 3px 8px;
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
    }
    &__SelectedOptionTitle {
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: center;
      color: white;
    }

    &__SelectTriangle {
      position: absolute;
      right: 12px;
      top: 23px;
      &--NoOptions {
        top: 0;
      }
      &--Active {
        animation-name: rotate-selector;
        animation-duration: 0.3s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        @keyframes rotate-selector {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__Label {
      position: absolute;
      top: -8px;

      line-height: 20px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #696969;
      &--Active {
        position: absolute;
        top: -5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__OptionsWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
      ::-webkit-scrollbar {
        width: 4px;
        background: none;
      }

      ::-webkit-scrollbar-track {
        background-color: none;
      }

      ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }

    &__Options {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__Option {
      position: relative;
      &:hover {
        background: #ceecf9;
        transform: scale(1.01);
        cursor: pointer;
      }
    }

    &__OptionTitle {
      position: relative;
      width: 100%;
      padding: 6px 35px 5px 9px;

      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;

      color: #0f0f0f;
    }

    &__OptionSelectCheckbox {
      position: absolute;
      top: 6px;
      right: 0;
      appearance: auto;
      opacity: 1;
      width: auto;
      border: none;
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: center;
      max-width: 30px !important;
      min-width: 30px !important;
    }
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
