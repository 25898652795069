import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';

  .ReduxTextField {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &__error {
      display: block;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .BaseTextField {
      input {
        padding: ${isRTL ? '25px 15px 10px 32px' : '25px 32px 10px 15px'};
      }

      &__label {
        top: 27px;
        left: ${isRTL ? '0' : '15px'} !important;
        right: ${isRTL ? '15px' : '0'} !important;
        color: #5d647d !important;
      }

      .ReduxTextField__error {
        width: unset !important;
        min-height: unset !important;
        display: block;
        margin-top: 8px;
      }
    }

    .ActionsButton {
      position: absolute;
      top: rem(20px);
      z-index: 5;
      right: rem(${isRTL ? 'auto' : '30px'});
      left: rem(${isRTL ? '30px' : 'auto'});
      margin: 0;
      &.hide {
        &:hover {
          svg {
            path {
              fill: red;
            }
          }
        }
      }
    }

    .ReduxTextField__description {
      position: absolute;
      top: rem(18px);
      z-index: 5;
      right: rem(${isRTL ? 'auto' : '15px'});
      left: rem(${isRTL ? '15px' : 'auto'});
      margin: 0;

      &--tooltip {
        right: rem(${isRTL ? 'auto' : '30px'});
        left: rem(${isRTL ? '30px' : 'auto'});
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ReduxTextField__description {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
