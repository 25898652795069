import classNames from 'classnames';
import queryString from 'query-string';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { BasicModal, DefaultButton, PageContentPreloader, PageLayout, PanelTabs } from 'common/components';
import { withTheme } from 'common/styling/theme';
import EditInvestStrategyFormNew from 'client/accounts/_components/EditInvestStrategyFormNew/EditInvestStrategyFormNew';
import { getTradingAccountList } from '../../../_redux';
import { getStrategyInvestmentsNew, retrieveInvestStrategyNew } from '../../_redux';
import {
  InvestStrategyForm,
  StrategyInfoPanel,
  YieldChartNew,
  StrategyInvestsTable,
  InvestStrategyApproveTable,
} from '../../_components';

import { staticStyles, getDynamicStyles } from './style';

const InvestDetailPageNew = ({
  profile,
  accounts,
  accountsIsLoaded,
  getTradingAccountList,
  retrieveInvestStrategyNew,
  investAgreements,
  investStrategyDetailNew,
  investStrategyDetailNewIsLoaded,
  strategyInvestmentsNew,
  strategyInvestmentsNewAreLoaded,
  getStrategyInvestmentsNew,
  addons,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const {
    params: { strategyId },
  } = match;

  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const activeTabSlug = useMemo(
    () => (location.search.includes('tab') ? queryString.parse(location.search).tab : 'active'),
    [location.search]
  );
  const [chartTab, setChartTab] = useState('yield');
  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  useEffect(() => {
    retrieveInvestStrategyNew({ strategyId });
  }, [retrieveInvestStrategyNew, strategyId]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  const getList = useCallback(
    query => {
      getStrategyInvestmentsNew({
        is_active: activeTabSlug === 'active' ? 'True' : 'False',
        strategyId,
        search: { ...query, page: +query.page === 0 ? undefined : query.page },
      });
    },
    [getStrategyInvestmentsNew, activeTabSlug]
  );

  const toggleInvestModal = () => setIsInvestModalOpen(!isInvestModalOpen);
  useEffect(() => {
    if (query.tab !== 'approve') {
      getList(query);
    }
  }, [getList, query]);

  const [editStrategy, setEditStrategy] = useState(false);
  const toggleModalEditStrategy = () => setEditStrategy(!editStrategy);

  const initialValues = useMemo(
    () =>
      investStrategyDetailNew
        ? {
            description: investStrategyDetailNew.description ? investStrategyDetailNew.description : '',
            frequencyType: investStrategyDetailNew.frequency === 'Monthly' ? 1 : 2,
            days: investStrategyDetailNew.settings?.days,
            minInvestmentAmount: Number(investStrategyDetailNew.settings?.minInvestmentAmount),
            autoInvestment: investStrategyDetailNew.autoInvestment,
            enableOperations: investStrategyDetailNew.settings?.enableOperations,
          }
        : {},
    [investStrategyDetailNew]
  );

  const strategyDataToEdit = {
    minInvestmentAmount: Number(investStrategyDetailNew.settings?.minInvestmentAmount),
    defaultMinInvestmentAmount: investStrategyDetailNew.defaultMinInvestmentAmount,
    currency: investStrategyDetailNew.defaultMinInvestmentAmount?.currency,
    id: investStrategyDetailNew.id,
    frequencyType: investStrategyDetailNew.settings?.frequencyType,
  };

  const hideApproveActionButtons = investStrategyDetailNew.rollovers && !investStrategyDetailNew.rolloverTime;
  const justInvestNew = 'justInvest';
  const justInvestNewShort = 'justInvest';

  return (
    <PageLayout caption={investStrategyDetailNew?.title} showReload={false}>
      {investStrategyDetailNew.isMyStrategy && (
        <DefaultButton
          className="InvestDetailPage__edit-button"
          textId="justEdit"
          type="button"
          onClick={toggleModalEditStrategy}
          filled
        />
      )}
      <PageContentPreloader ready={investStrategyDetailNewIsLoaded} type="bigLine">
        <div className="InvestDetailPage__chart-tabs">
          <FormattedMessage id="justYield">
            {txt => (
              <button
                type="button"
                className={classNames('InvestDetailPage__tab', {
                  active: chartTab === 'yield',
                })}
                onClick={() => setChartTab('yield')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        <StrategyInfoPanel strategyDetail={investStrategyDetailNew} />

        {chartTab === 'yield' && accountsIsLoaded && <YieldChartNew strategyId={investStrategyDetailNew.id} />}
        <DefaultButton type="button" textId={justInvestNewShort} onClick={toggleInvestModal} filled />
        {investStrategyDetailNew.isMyStrategy && (
          <>
            <FormattedMessage id="rammStrategyInvestments">
              {txt => <div className="InvestDetailPage__invest-caption">{txt}</div>}
            </FormattedMessage>
            <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
              <StrategyInvestsTable
                data={strategyInvestmentsNew}
                loading={!strategyInvestmentsNewAreLoaded}
                history={history}
                location={location}
                params={{
                  search: query,
                }}
                getList={params => getList(params.search)}
                textId="justActive"
                isActiveInvestmentsTable
                slug="active"
                match={match}
              />
              <StrategyInvestsTable
                data={strategyInvestmentsNew}
                loading={!strategyInvestmentsNewAreLoaded}
                history={history}
                location={location}
                params={{
                  search: query,
                }}
                getList={params => getList(params.search)}
                textId="justClosed"
                slug="closed"
                match={match}
              />
              <InvestStrategyApproveTable
                strategyId={investStrategyDetailNew.id}
                hideActionButtons={hideApproveActionButtons}
                history={history}
                location={location}
                params={{
                  search: query,
                }}
                textId="justApprove"
                slug="approve"
                match={match}
              />
            </PanelTabs>
          </>
        )}
        {accountsIsLoaded && investStrategyDetailNewIsLoaded && (
          <BasicModal captionId={justInvestNew} isOpen={isInvestModalOpen} onRequestClose={toggleInvestModal}>
            <InvestStrategyForm
              strategyId={investStrategyDetailNew.id}
              strategyAccountId={investStrategyDetailNew.account.id}
              strategyCurrency={investStrategyDetailNew.account.asset}
              minInvestmentAmount={investStrategyDetailNew.settings.minInvestmentAmount}
              investAgreements={investAgreements}
              accounts={accounts}
              walletBalance={profile.walletBalance}
              isWallet={addons.includes('walletAccount')}
              afterSubmitSuccess={toggleInvestModal}
            />
          </BasicModal>
        )}
        <BasicModal isOpen={editStrategy} captionId="justEditStrategy" onRequestClose={toggleModalEditStrategy}>
          <EditInvestStrategyFormNew
            toggleModal={toggleModalEditStrategy}
            initialValues={initialValues}
            strategyData={strategyDataToEdit}
            strategyId={strategyId}
            isRollover={investStrategyDetailNew.rollovers}
          />
        </BasicModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

InvestDetailPageNew.propTypes = {
  profile: PropTypes.object.isRequired,
  getTradingAccountList: PropTypes.func.isRequired,
  getStrategyInvestmentsNew: PropTypes.func.isRequired,
  retrieveInvestStrategyNew: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  accountsIsLoaded: PropTypes.bool.isRequired,
  investStrategyDetailNew: PropTypes.object.isRequired,
  investStrategyDetailNewIsLoaded: PropTypes.bool.isRequired,
  strategyInvestmentsNew: PropTypes.object.isRequired,
  strategyInvestmentsNewAreLoaded: PropTypes.bool.isRequired,
  addons: PropTypes.array.isRequired,
  investAgreements: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

InvestDetailPageNew.defaultProps = {
  theme: {},
};

export default connect(
  state => ({
    profile: state.user.profile,
    accounts: state.client.common.accounts.filter(
      account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
    ),
    accountsIsLoaded: state.client.common.accountsIsLoaded,
    investStrategyDetailNew: state.client.investNew.investStrategyDetailNew,
    investStrategyDetailNewIsLoaded: state.client.investNew.investStrategyDetailNewIsLoaded,
    strategyInvestmentsNew: state.client.investNew.strategyInvestmentsNew,
    strategyInvestmentsNewAreLoaded: state.client.investNew.strategyInvestmentsNewAreLoaded,
    addons: state.interfaceConfig.addons,
    investAgreements: state.interfaceConfig.invest_crm.agreements,
  }),
  {
    getTradingAccountList: () => getTradingAccountList.request(),
    getStrategyInvestmentsNew: data => getStrategyInvestmentsNew.request(data),
    retrieveInvestStrategyNew: data => retrieveInvestStrategyNew.request(data),
  }
)(withTheme()(InvestDetailPageNew));
export { InvestDetailPageNew };
