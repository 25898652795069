import React from 'react';

const BonusesAllIcon = () => (
  <div>
    <svg viewBox="0 0 22 21" width="22" height="21" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.323 21a.897.897 0 01-.339-.097l-6-3.145-6 3.145c-.242.145-.532.097-.774-.048-.242-.145-.339-.436-.29-.726l1.161-6.677L.242 8.71c-.193-.194-.29-.484-.193-.726a.75.75 0 01.58-.484l6.726-.968 3-6.097c.097-.29.339-.435.63-.435.29 0 .531.145.628.387l3 6.097 6.726.968a.75.75 0 01.58.483c.097.242 0 .533-.193.726l-4.839 4.742 1.162 6.678c.048.29-.049.532-.29.726-.097.145-.29.193-.436.193zM10.984.726L7.791 7.16.726 8.177l5.13 4.984-1.21 7.113 6.338-3.338 6.339 3.338-1.21-7.064 5.13-4.984-7.114-1.016L10.984.726z" />
    </svg>
  </div>
);

export default BonusesAllIcon;
