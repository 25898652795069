import axios from 'axios';

export const getManagers = () => axios.get(`/backoffice/managers/list/`);
export const getChangeHistory = userId => axios.get(`/backoffice/user/${userId}/change_manager_history/`);
export const changeManager = (userId, data) => axios.post(`/backoffice/user/${userId}/change_manager/`, { ...data });
export const getClient = () => axios.post('/backoffice/managers/get_client/');

export const getTasks = search => axios.get(`/backoffice/managers/tasks/list/`, { params: { ...search } });
export const getTask = taskId => axios.get(`/backoffice/managers/tasks/${taskId}/`);
export const getPlan = () => axios.get('/backoffice/managers/tasks/plan/');
export const createTask = data => axios.post(`/backoffice/managers/tasks/create/`, { ...data });
export const updateTask = (taskId, data) => axios.patch(`/backoffice/managers/tasks/${taskId}/`, { ...data });
export const removeTask = taskId => axios.delete(`/backoffice/managers/tasks/${taskId}/`);
export const finishTask = (taskId, data) =>
  axios.patch(`/backoffice/managers/tasks/${taskId}/change_status/`, { ...data });
