import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .Checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(16px);
    height: rem(16px);
    border: 1px solid;
    border-radius: 2px;
    cursor: pointer;

    svg {
      width: rem(12px);
      height: rem(10px);
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .Checkbox {
      background: ${colors.white.hex};
      border-color: ${colors.lightGray.hex};

      svg path {
        fill: ${colors.white.hex};
      }
    }

    .Checkbox__checked {
      background: ${colors.primary.hex};
      border-color: ${colors.primary.hex};
    }

    .Checkbox__disabled {
      background: ${rgba([colors.lightGray.rgb, 0.5])};
      border-color: ${colors.lightGray.hex};

      svg path {
        fill: none;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
