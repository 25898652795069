import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SavedReportsPage__description {
    margin-bottom: rem(20px);
    font-size: rem(16px);
    line-height: 1.5;
  }
`;

export { staticStyles };
