import { put, call, fork, take } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import { SubmissionError } from 'redux-form';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as api from '../../_api';
import * as ActionTypes from '../actions';

export function* getPaymentNotesSaga({ id, paymentType }) {
  try {
    const { status, data } = yield call(api.getPaymentNotes, id, paymentType);
    if (status < 300) {
      yield put(ActionTypes.getPaymentNotes.success(data));
    } else {
      yield put(ActionTypes.getPaymentNotes.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPaymentNotes.failure());
    throw e;
  }
}
export function* getPaymentNotesWatcherSaga() {
  while (true) {
    const { id, paymentType } = yield take(ActionTypes.GET_PAYMENT_NOTES[REQUEST]);
    yield call(getPaymentNotesSaga, { id, paymentType });
  }
}

export function* createPaymentNoteSaga({ id, title, text, paymentType, documentss }) {
  try {
    yield call(api.createPaymentNote, id, paymentType, { documents: documentss, title, text });
    yield put(ActionTypes.createPaymentNote.success());
    yield call(getPaymentNotesSaga, { id, paymentType });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createPaymentNote.failure());
  }
}
export function* createPaymentNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createPaymentNote.REQUEST);
    yield call(createPaymentNoteSaga, payload);
  }
}

export function* editPaymentNoteSaga({ id, noteId, paymentType, title, documentss, text }) {
  try {
    yield call(api.editPaymentNote, id, noteId, paymentType, {
      title,
      text,
      documents: documentss,
    });
    yield put(ActionTypes.editPaymentNote.success());
    yield call(getPaymentNotesSaga, { id, paymentType });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editPaymentNote.failure());
  }
}
export function* editPaymentNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editPaymentNote.REQUEST);
    yield call(editPaymentNoteSaga, payload);
  }
}

export function* deletePaymentNoteSaga({ id, noteId, paymentType }) {
  try {
    const { status, data } = yield call(api.deletePaymentNote, id, noteId, paymentType);
    if (status < 300) {
      yield put(ActionTypes.deletePaymentNote.success(data));
      yield call(getPaymentNotesSaga, { id, paymentType });
    } else {
      yield put(ActionTypes.deletePaymentNote.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deletePaymentNote.failure());
  }
}

export function* deletePaymentNoteWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.deletePaymentNote[REQUEST]);
    yield call(deletePaymentNoteSaga, payload);
  }
}

export function* removePaymentNoteDocumentSaga({ userId, documentId, id, paymentType }) {
  try {
    const { status, data } = yield call(api.removePaymentNoteDocument, userId, documentId);
    if (status < 300) {
      yield put(ActionTypes.removePaymentNoteDocument.success(data));
      yield call(getPaymentNotesSaga, { id, paymentType });
    } else {
      yield put(ActionTypes.removePaymentNoteDocument.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removePaymentNoteDocument.failure());
  }
}

export function* removePaymentNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.REMOVE_PAYMENT_NOTE_DOCUMENT[REQUEST]);
    yield call(removePaymentNoteDocumentSaga, data);
  }
}

export function* createPaymentDocumentSaga({ userId, body }) {
  try {
    const { status, data } = yield call(api.createPaymentDocument, userId, body);
    if (status < 300) {
      yield put(ActionTypes.createPaymentDocument.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.createPaymentDocument.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createPaymentDocument.failure(e));
  }
}
export function* createPaymentDocumentWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createPaymentDocument.REQUEST);
    yield call(createPaymentDocumentSaga, payload);
  }
}

export function* getPaymentNoteDocumentSaga({ userId, documentId }) {
  try {
    const { data, headers } = yield call(api.getPaymentNoteDocument, userId, documentId);
    const fileName = headers['content-disposition'].replace('attachment; filename=', '').replaceAll('"', '');
    FileDownload(data, fileName);
    yield put(ActionTypes.getPaymentNoteDocument.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPaymentNoteDocument.failure());
  }
}

export function* getPaymentNoteDocumentWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_PAYMENT_NOTE_DOCUMENT[REQUEST]);
    yield call(getPaymentNoteDocumentSaga, data);
  }
}

export function* getPartnersSaga({ search }) {
  try {
    const { status, data } = yield call(api.getPartners, search);
    if (status < 300) {
      yield put(ActionTypes.getPartners.success(data));
      return { partnersAreLoaded: true, data };
    }
    yield put(ActionTypes.getPartners.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartners.failure());
    throw e;
  }
}

export function* getPartnersWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_PARTNERS[REQUEST]);
    yield call(getPartnersSaga, search);
  }
}

export function* exportUserTableDataSaga(params) {
  try {
    const { data } = yield call(api.exportUserTableData, params);
    FileDownload(data, 'users.xls');
    yield put(ActionTypes.exportUserTableData.success());
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.exportUserTableData.failure());
    throw e;
  }
}

export function* exportUserTableDataWatcherSaga() {
  while (true) {
    const { params } = yield take(ActionTypes.EXPORT_USER_TABLE_DATA[REQUEST]);
    yield call(exportUserTableDataSaga, params);
  }
}

export function* getBackofficeCountersSaga() {
  try {
    const { status, data } = yield call(api.getBackofficeCounters);
    if (status < 300) {
      yield put(ActionTypes.getBackofficeCounters.success(data));
    } else {
      yield put(ActionTypes.getBackofficeCounters.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getBackofficeCounters.failure());
    throw e;
  }
}

export function* getBackofficeCountersWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BACKOFFICE_COUNTERS[REQUEST]);
    yield call(getBackofficeCountersSaga);
  }
}

export function* getFiltersListSaga() {
  try {
    const { status, data } = yield call(api.getFiltersList);
    if (status < 300) {
      yield put(ActionTypes.getFiltersList.success(data));
    } else {
      yield put(ActionTypes.getFiltersList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getFiltersList.failure());
  }
}

export function* getFiltersListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BO_FILTERS_LIST[REQUEST]);
    yield call(getFiltersListSaga);
  }
}

export function* getBackofficeServersSaga() {
  try {
    const { status, data } = yield call(api.getBackofficeServers);
    if (status < 300) {
      yield put(ActionTypes.getBackofficeServers.success(data));
    } else {
      yield put(ActionTypes.getBackofficeServers.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getBackofficeServers.failure());
    throw e;
  }
}

export function* getBackofficeServersWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_BACKOFFICE_SERVERS[REQUEST]);
    yield call(getBackofficeServersSaga);
  }
}

export default [
  fork(createPaymentDocumentWatcherSaga),
  fork(createPaymentNoteWatcherSaga),
  fork(getPaymentNotesWatcherSaga),
  fork(deletePaymentNoteWatcherSaga),
  fork(editPaymentNoteWatcherSaga),
  fork(removePaymentNoteDocumentWatcherSaga),
  fork(getPartnersWatcherSaga),
  fork(exportUserTableDataWatcherSaga),
  fork(getPaymentNoteDocumentWatcherSaga),
  fork(getBackofficeCountersWatcherSaga),
  fork(getFiltersListWatcherSaga),
  fork(getBackofficeServersWatcherSaga),
];
