import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DropDown } from './_components';

import Triangle from './icons/Triangle';

import { staticStyles } from './style';

const RangeSelector = ({ enteredValues, onChange, isDisabled }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const onRequestClose = () => setIsOpen(!isOpen);

  return (
    <div className="RangeSelector">
      <button
        disabled={isDisabled}
        ref={ref}
        className={classNames('RangeSelector__OpenOptionsButton', { 'RangeSelector--disabled': isDisabled })}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        <div className="RangeSelector__Labels">
          <span
            className={classNames('RangeSelector__LabelsSelected', {
              'RangeSelector__LabelsSelected--blue': enteredValues.length,
            })}>
            <div className="RangeSelector__LabelsSelectedCentered">
              {enteredValues.length ? `${enteredValues[0]} ~ ${enteredValues[1]} ` : 'All'}
            </div>
          </span>
        </div>
        <Triangle />
      </button>

      {isOpen && (
        <DropDown
          isOpen={isOpen}
          setIsOpen={onRequestClose}
          onChange={onChange}
          enteredValues={enteredValues}
          position={ref.current.getBoundingClientRect()}
        />
      )}
      {/* 
      <MediaQuery query="(max-width: 767px)">
        <BasicCRMModal
          isOpen={isOpen}
          captionId={dropDownTitle}
          onRequestClose={onRequestClose}
          className="ModalDropDown">
          <ModalDropDown selectebleOptions={selectebleOptions} onSelect={onSelect} setIsOpen={onRequestClose} />
        </BasicCRMModal>
      </MediaQuery>  */}

      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default RangeSelector;
