import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DefaultButton, BasicModal, AddCardForm, NoItemsPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getCards, deleteCard, addCard, updateCard, getCard } from '../../_redux';
import { VerificationPanel, Card } from '../../_components';

import { staticStyles } from './style';

const CardsPage = ({
  cards,
  card: { nameOnCard, truncatedNumber },
  getCards,
  getCard,
  cardsAreLoaded,
  cardIsLoaded,
  deleteCard,
}) => {
  useEffect(() => {
    getCards();
  }, [getCards]);

  const [currentModal, setCurrentModal] = useState('');
  const [currentCardId, setCurrentCardId] = useState(null);

  const firstDigits = truncatedNumber ? truncatedNumber.slice(0, 6) : '';
  const lastDigits = truncatedNumber ? truncatedNumber.substr(-4) : '';

  const handleDeleteCard = cardId => deleteCard(cardId);
  const handleEditCard = cardId => {
    getCard(cardId);
    setCurrentCardId(cardId);
    setCurrentModal('editModal');
  };

  const handleSubmit = useCallback(
    (formValues, dispatch) => {
      const { firstDigits, lastDigits, nameOnCard, frontPhoto, backPhoto } = formValues;
      const truncatedNumber = `${firstDigits}${lastDigits}`;
      const formData = new FormData();

      formData.set('truncatedNumber', truncatedNumber);
      formData.set('nameOnCard', nameOnCard);
      formData.append('frontPhoto', frontPhoto[0]);
      formData.append('backPhoto', backPhoto[0]);

      return currentModal === 'addModal'
        ? addCard(formData, dispatch)
        : updateCard({ cardId: currentCardId, formData }, dispatch);
    },
    [currentModal, currentCardId]
  );

  return (
    <VerificationPanel captionId="justMyCards" warningMessageTextId="justMyCards">
      <DefaultButton textId="justAddCard" onClick={() => setCurrentModal('addModal')} filled plus />
      <div className="CardsPage">
        {cardsAreLoaded && cards.length ? (
          cards.map(card => (
            <Card card={card} key={card.id} onEditCard={handleEditCard} onDeleteCard={handleDeleteCard} />
          ))
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noAddedCards" />
        )}
      </div>
      <BasicModal
        isOpen={(cardIsLoaded && currentModal === 'editModal') || currentModal === 'addModal'}
        captionId="justAddNewCard"
        onRequestClose={() => setCurrentModal('')}>
        <AddCardForm
          handleCancel={() => setCurrentModal('')}
          submitFunction={handleSubmit}
          withoutPhoto={currentModal === 'edit'}
          initialValues={currentModal === 'editModal' ? { nameOnCard, firstDigits, lastDigits } : {}}
        />
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
    </VerificationPanel>
  );
};

CardsPage.propTypes = {
  getCards: PropTypes.func.isRequired,
  getCard: PropTypes.func.isRequired,
  cards: PropTypes.array.isRequired,
  card: PropTypes.object,
  cardsAreLoaded: PropTypes.bool.isRequired,
  cardIsLoaded: PropTypes.bool.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

CardsPage.defaultProps = {
  card: {},
};

export default compose(
  withRouter,
  connect(
    state => ({
      cards: state.client.kyc.cards,
      cardsAreLoaded: state.client.kyc.cardsAreLoaded,
      card: state.client.kyc.card,
      cardIsLoaded: state.client.kyc.cardIsLoaded,
    }),
    {
      getCards: () => getCards.request(),
      getCard: id => getCard.request(id),
      deleteCard: id => deleteCard.request(id),
    }
  )
)(CardsPage);
export { CardsPage };
