import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { createPeriodicalReport } from 'common/_redux';
import { DefaultButton, ReduxSelectField, ReduxTextField } from 'common/components';
import { required } from 'redux-form-validators';

import { staticStyles } from './style';

const PeriodicallyReportForm = ({
  match,
  availableReports,
  partnerAccounts,
  frequency,
  reportType,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  intl,
  dispatch,
}) => {
  const isBackOfficePage = match.url.includes('backoffice');
  const submit = handleSubmit(values => {
    const finalValues = values.frequency === 'EVERY_MONTH' ? { ...values, day: 1 } : values;
    return createPeriodicalReport({ ...finalValues, isBackOfficePage }, dispatch);
  });

  const frequencyOptions = [
    { value: 'EVERY MONTH', label: intl.formatMessage({ id: 'justEveryMonth' }) },
    { value: 'EVERY WEEK', label: intl.formatMessage({ id: 'justEveryWeek' }) },
    { value: 'EVERY DAY', label: intl.formatMessage({ id: 'justDaily' }) },
  ];

  const weeklyOptions = [
    { value: 1, label: intl.formatMessage({ id: 'justMonday' }) },
    { value: 2, label: intl.formatMessage({ id: 'justTuesday' }) },
    { value: 3, label: intl.formatMessage({ id: 'justWednesday' }) },
    { value: 4, label: intl.formatMessage({ id: 'justThursday' }) },
    { value: 5, label: intl.formatMessage({ id: 'justFriday' }) },
    { value: 6, label: intl.formatMessage({ id: 'justSaturday' }) },
    { value: 7, label: intl.formatMessage({ id: 'justSunday' }) },
  ];

  const selectedReportType = availableReports.find(item => item.key === reportType);

  return (
    <form className="PeriodicallyReportForm" onSubmit={submit}>
      <div className="PeriodicallyReportForm__line">
        <Field
          component={ReduxSelectField}
          name="report_type"
          textId="justReportType"
          options={availableReports.map(report => ({ value: report.key, label: report.name }))}
          disabled={submitting}
          validate={[required()]}
        />
        {isBackOfficePage && selectedReportType && selectedReportType.slug !== 'full_company' && (
          <Field
            component={ReduxTextField}
            type="text"
            name="partner"
            textId="justIbAccount"
            validate={[required()]}
            disabled={submitting}
          />
        )}
        {!isBackOfficePage && (
          <Field
            component={ReduxSelectField}
            name="partner"
            textId="justIbAccount"
            options={partnerAccounts.map(item => ({ value: item.partnerCode, label: item.partnerCode }))}
            disabled={submitting}
            validate={[required()]}
          />
        )}
      </div>
      <div className="PeriodicallyReportForm__line">
        <Field
          component={ReduxSelectField}
          name="frequency"
          textId="justFrequency"
          options={frequencyOptions}
          disabled={submitting}
          validate={[required()]}
        />
        {frequency === 'EVERY WEEK' && (
          <Field
            component={ReduxSelectField}
            name="day"
            textId="chooseWeekDay"
            options={weeklyOptions}
            disabled={submitting}
            validate={[required()]}
          />
        )}
      </div>
      <DefaultButton
        textId="justSetUp"
        onClick={submit}
        disabled={invalid || pristine || submitting}
        loading={submitting}
      />
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

PeriodicallyReportForm.propTypes = {
  match: PropTypes.object.isRequired,
  availableReports: PropTypes.array.isRequired,
  partnerAccounts: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  frequency: PropTypes.string.isRequired,
  reportType: PropTypes.number.isRequired,
  error: PropTypes.string,
};

PeriodicallyReportForm.defaultProps = {
  error: '',
};

const selector = formValueSelector('PeriodicallyReportForm');

export default compose(
  injectIntl,
  withRouter,
  connect(state => ({
    partnerCode: state.user.profile.partnerAccounts?.[0],
    frequency: selector(state, 'frequency') || '',
    reportType: selector(state, 'report_type') || 0,
  })),
  reduxForm({
    form: 'PeriodicallyReportForm',
  })
)(PeriodicallyReportForm);

export { PeriodicallyReportForm };
