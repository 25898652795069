import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  
  .NewsForm__input-inner {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: 25px;
    padding: 25px 30px 1px;
    @include respond-to(sm) {
      padding: 20px 15px 1px;
    }
    :global(p) {
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }
  }
`

const getDynamicStyles = ({ colors }) => {
  return css`
    .NewsForm__input-inner {
      background: ${colors.white.hex};
    }
  `
}

export { staticStyles, getDynamicStyles }
