import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestStrategiesTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(.ReactDataTable__thead-cell) {
          text-align: center;

          &:first-child,
          &:last-child {
            text-align: left;
          }
        }

        :global(.ReactDataTable__thead-row) {
          margin: 0;

          :global(th) {
            :global(span) {
              white-space: normal;
            }
          }

          :global(.ReactDataTable__thead-cell) {
            padding: rem(18px);
          }
        }

        :global(.ReactDataTable__tbody-row) {
          height: 75px;
          margin-left: 0;
        }

        :global(.ReactDataTable__tbody-cell) {
          position: relative;
          overflow: visible;
          justify-content: center;
          padding: rem(18px);

          &:first-child,
          &:last-child {
            justify-content: ${isRTL ? 'flex-end' : 'flex-start'};
          }

          :global(.TableActions) {
            text-align: ${isRTL ? 'left' : 'center'};
          }

          :global(.TableActions__btns) {
            min-width: 200px;
            max-width: 200px;
            right: -20px;
          }

          :global(.name) {
            font-weight: 700;
          }

          :global(.feeRate) {
            display: flex;
            flex-direction: column;
            align-items: center;

            :global(.percent) {
              font-size: rem(13px) !important;
              font-weight: 700;
            }
          }

          :global(.MoneyCell__inner) {
            :global(span) {
              font-size: rem(13px) !important;
            }
          }

          &:global(span) {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    :global(&__action-lg) {
      display: flex;
      width: 100%;
      align-items: center;

      @media only screen and (max-width: 1440px) {
        display: none;
      }
    }

    :global(&__action-md) {
      display: none;

      @media only screen and (max-width: 1440px) {
        display: block;
      }
    }

    :global(.ResponsiveTable) {
      :global(.ResponsiveTable__row-item) {
        @include respond-to(sm) {
          max-width: 50%;
        }
      }
      :global(.ResponsiveTable__details-item) {
        @include respond-to(sm) {
          :global(.InvestStrategiesTable__btns) {
            width: 90%;
          }
        }
      }

      :global(.TableActions) {
        @include respond-to(sm) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      :global(.TableActions__btns) {
        width: 260px;
        right: 0%;
        max-height: 110px;
        overflow-y: scroll;
        top: auto;
        bottom: 20px;
      }

      :global(.InvestStrategiesTable__title) {
        @include respond-to(sm) {
          width: 100%;
          overflow: hidden;
        }
      }

      :global(.MoneyCell__inner) {
        @include respond-to(sm) {
          height: auto;
        }
      }
    }

    :global(&__title) {
      width: 100%;
      overflow: hidden;

      :global(.sub-title) {
        display: flex;
        flex-wrap: wrap;
      }

      :global(.account) {
        margin-left: 3px;
      }
    }

    :global(&__btns) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      :global(.ActionsButton) {
        margin: 0;

        :global(svg) {
          width: 25px;
          height: 25px;
        }
      }

      :global(.Tooltip) {
        background: #ffffff !important;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;

        :global(pre) {
          color: #000;
        }

        &::after {
          content: none;
        }
      }

      :global(.DefaultButton) {
        margin-right: ${isRTL ? '0' : '30px'};
        margin-left: ${isRTL ? '30px' : '0'};

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .InvestStrategiesTable-active {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(250px, 2fr) repeat(3, minmax(100px, 1fr)) minmax(50px, 0.4fr);

        @media only screen and (max-width: 1210px) {
          grid-template-columns: minmax(100px, 2fr) repeat(3, minmax(50px, 1fr)) minmax(100px, 0.4fr);
        }
      }

      :global(.ReactDataTable__tbody-cell) {
        position: relative;
        overflow: visible;
        justify-content: center;

        &:first-child {
          flex-wrap: wrap;
        }

        :global(.title) {
          color: #000;
          font-weight: 500;
        }

        :global(.sub-title) {
          width: 100%;
          font-size: rem(13px);
        }

        :global(.link) {
          text-decoration: underline;
          margin-left: 3px;
        }
      }
    }
  }

  .InvestStrategiesTable-closed {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(150px, 0.3fr) minmax(250px, 2fr) repeat(3, minmax(100px, 1fr));
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .InvestStrategiesTable-active {
      :global(.ReactDataTable) {
        :global(.ReactDataTable__tbody-cell) {
          :global(.link) {
            color: ${colors.secondary.hex};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
