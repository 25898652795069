import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PageContentPreloader, PageLayout } from 'common/components';
import { PromoMaterialsForm } from '../../_components';
import { getCampaignList, updatePromo, getPromo } from '../../_redux';

const EditPromoPage = ({
  campaignList,
  campaignListIsLoaded,
  getCampaignList,
  promoMaterial,
  promoMaterialIsLoaded,
  getPromo,
  availableLangs,
  match,
}) => {
  useEffect(() => {
    getCampaignList();
  }, [getCampaignList]);

  useEffect(() => {
    getPromo({ promoId: match.params.id });
  }, [getPromo, match.params.id]);

  return (
    <PageLayout captionSlug="uploadPromo">
      <PageContentPreloader ready={campaignListIsLoaded && promoMaterialIsLoaded} type="bigLine">
        <PromoMaterialsForm
          initialValues={{ ...promoMaterial, uploadFile: promoMaterial.preview }}
          promoId={match.params.id}
          availableLangs={availableLangs}
          campaignList={campaignList}
          submitFunction={updatePromo}
          isEditForm
        />
      </PageContentPreloader>
    </PageLayout>
  );
};

EditPromoPage.propTypes = {
  match: PropTypes.object.isRequired,
  campaignList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  campaignListIsLoaded: PropTypes.bool.isRequired,
  getCampaignList: PropTypes.func.isRequired,
  availableLangs: PropTypes.array.isRequired,
  promoMaterial: PropTypes.object.isRequired,
  promoMaterialIsLoaded: PropTypes.bool.isRequired,
  getPromo: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    campaignList: state.backoffice.promo.campaignList,
    campaignListIsLoaded: state.backoffice.promo.campaignListIsLoaded,
    promoMaterial: state.backoffice.promo.promoMaterial,
    promoMaterialIsLoaded: state.backoffice.promo.promoMaterialIsLoaded,
    availableLangs: state.interfaceConfig.availableLangs,
  }),
  {
    getCampaignList: data => getCampaignList.request(data),
    getPromo: data => getPromo.request(data),
  }
)(withRouter(EditPromoPage));
export { EditPromoPage };
