import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .DashboardHistory {
    padding: rem(25px 35px 25px 40px);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    direction: ltr;
    flex: 1;
    margin-left: 16px;
    @include respond-to(lg) {
      padding: rem(25px 20px 25px 20px);
    }
    @include respond-to(sm) {
      width: 100%;
      flex: initial;
      margin-left: 0;
      margin-top: 25px;
    }
  }

  .DashboardHistory__caption {
    position: relative;
    margin-bottom: rem(20px);
    padding-bottom: 3px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 90%;
    }
  }

  .DashboardHistory__card {
    padding-right: rem(15px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .Card__avatar-inner {
      :global(img) {
        border-radius: 50%;
      }
    }
    .Card__description-inner {
      width: calc(100% - 55px);
    }
    .Card__caption {
      margin-bottom: 3px;
      h4 {
        font-size: 15px;
        font-weight: 500;
      }
    }
    .Card__description,
    .Card__date {
      font-size: 13px;
      margin-bottom: 2px;
    }
    .Card__date {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .DashboardHistory {
      background: ${colors.white.hex};
      border-radius: ${border.radius};
    }

    .DashboardHistory__caption {
      &:after {
        background: ${colors.lightGray.hex};
      }
    }

    .DashboardHistory__card {
      .Card__caption {
        h4 {
          color: ${colors.secondary.hex};
        }
      }
      .Card__description {
        color: ${colors.gray.hex};
      }
      .Card__date {
        color: ${colors.lightGray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
