import moment from 'moment';
import { put, call, fork, take } from 'redux-saga/effects';
import { sendYandexMetrikaEvent } from 'common/utils';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import { cancelUserBonus } from 'backoffice/users/_redux/actions';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getAllBonusesSaga() {
  try {
    const { data } = yield call(api.getAllBonuses);
    yield put(ActionTypes.getAllBonuses.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getAllBonuses.failure());
  }
}

export function* getAllBonusesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_ALL_BONUSES[REQUEST]);
    yield call(getAllBonusesSaga);
  }
}

export function* getUserBonusesSaga({ search }) {
  try {
    const { data } = yield call(api.getUserBonuses, search);
    yield put(ActionTypes.getUserBonuses.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getUserBonuses.failure());
  }
}

export function* getUserBonusesWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_USER_BONUSES[REQUEST]);
    yield call(getUserBonusesSaga, search);
  }
}

export function* getPerBonusesSaga({ search }) {
  try {
    const { data } = yield call(api.getPerBonuses, search);
    yield put(ActionTypes.getPerBonuses.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPerBonuses.failure());
  }
}

export function* getPerBonusesWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_PER_BONUSES[REQUEST]);
    yield call(getPerBonusesSaga, search);
  }
}

export function* rejectBonusSaga({ bonusId, approved, search }) {
  try {
    const { data, status } = yield call(api.rejectBonus, bonusId, { approved });
    if (status < 300) {
      yield put(ActionTypes.rejectBonus.success(data));
      yield call(getUserBonusesSaga, { search });
    } else if (status === 409 && data.reason === 'approve_required') {
      yield put(cancelUserBonus.failure(data.reason));
      yield put(ActionTypes.rejectBonus.failure());
    } else {
      yield put(ActionTypes.rejectBonus.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectBonus.failure());
    yield put(cancelUserBonus.failure(e));
    throw e;
  }
}

export function* rejectBonusWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.REJECT_BONUS[REQUEST]);
    yield call(rejectBonusSaga, payload);
  }
}

export function* rejectPerBonusSaga({ bonusId, search }) {
  try {
    const { data, status } = yield call(api.rejectPerBonus, bonusId);
    if (status < 300) {
      yield put(ActionTypes.rejectPerBonus.success(data));
      yield call(getPerBonusesSaga, { search });
    } else {
      yield put(ActionTypes.rejectPerBonus.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectPerBonus.failure());
    throw e;
  }
}

export function* rejectPerBonusWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.REJECT_PER_BONUS[REQUEST]);
    yield call(rejectPerBonusSaga, payload);
  }
}

export function* activateBonusSaga({ id, date, ...values }) {
  const formattedDate = date ? moment(date).format('x') : undefined;
  try {
    const { data, status } = yield call(api.activateBonus, id, { ...values.bonus, date: formattedDate });
    if (status < 300) {
      yield put(ActionTypes.activateBonus.success(data));
      const EVENT_NAME = 'Bonus';
      sendYandexMetrikaEvent(EVENT_NAME);
      yield call(getAllBonusesSaga);
    } else {
      const error = data.detail || data;
      yield put(acceptError(error, true));
      yield put(ActionTypes.activateBonus.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.activateBonus.failure());
  }
}

export function* activateBonusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.activateBonus.REQUEST);
    yield call(activateBonusSaga, payload);
  }
}

export default [
  fork(getAllBonusesWatcherSaga),
  fork(getUserBonusesWatcherSaga),
  fork(getPerBonusesWatcherSaga),
  fork(rejectBonusWatcherSaga),
  fork(rejectPerBonusWatcherSaga),
  fork(activateBonusWatcherSaga),
];
