import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ConfirmResetPasswordForm } from '../../_components';

import { staticStyles } from './style';

const ConfirmResetPasswordPage = ({ intl }) => (
  <div className="ConfirmResetPasswordPage">
    <Helmet>
      <title>{intl.formatMessage({ id: 'welcomeSetupConfirmResetPassword' })}</title>
    </Helmet>
    <div className="ConfirmResetPasswordPage__inner">
      <FormattedMessage id="authConfirmResetPasswordDescr">{txt => <h3>{txt}</h3>}</FormattedMessage>
      <ConfirmResetPasswordForm />
    </div>
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);
ConfirmResetPasswordPage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ConfirmResetPasswordPage);
