import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { ActionsButton } from 'common/components';
import { EditIcon } from 'common/icons';

import { staticStyles, getDynamicStyles } from './style';

const StatementCard = ({ cells, onEditValue, captionId, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="StatementCard">
      <FormattedMessage id={captionId}>{txt => <div className="StatementCard__caption">{txt}</div>}</FormattedMessage>
      <div className="StatementCard__list">
        {cells.map(cell => (
          <div className="StatementCard__cell" key={cell.captionId}>
            <FormattedMessage id={cell.captionId}>
              {txt => <span className="StatementCard__cell-caption">{txt}:</span>}
            </FormattedMessage>
            <span
              className={classNames('StatementCard__cell-value', {
                'StatementCard__cell-value--pos': cell.value > 0,
                'StatementCard__cell-value--neg': cell.value < 0,
              })}>
              <span>{cell.displayValue}</span>

              {cell?.action && (
                <ActionsButton
                  edit
                  tooltipId={captionId}
                  tooltipTextId={captionId}
                  onClickFunc={() => onEditValue(cell.action)}>
                  <EditIcon />
                </ActionsButton>
              )}
            </span>
          </div>
        ))}
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

StatementCard.propTypes = {
  captionId: PropTypes.string.isRequired,
  onEditValue: PropTypes.func.isRequired,
  cells: PropTypes.array,
  theme: PropTypes.object,
};

StatementCard.defaultProps = {
  cells: [],
  theme: {},
};

export default withTheme()(StatementCard);
