import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const PaymentsIconActive = ({ gradientStartColor, gradientEndColor }) => (
  <div>
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.969 6.797H2.734A2.737 2.737 0 000 9.53v6.016a2.737 2.737 0 002.734 2.734h2.34l1.606 1.606a.393.393 0 00.668-.278v-1.328h3.828a2.737 2.737 0 002.734-2.734V11.25L7.97 6.797zM2.5 9.922h5.703c.215 0 .39.176.39.39 0 .215-.175.391-.39.391H2.5a.392.392 0 01-.39-.39c0-.215.175-.391.39-.391zm6.707 5.156H2.5a.392.392 0 01-.39-.39c0-.215.175-.391.39-.391h6.707c.215 0 .39.176.39.39 0 .215-.175.391-.39.391zm1.813-.113a.386.386 0 01-.278.113.386.386 0 01-.39-.39.387.387 0 01.39-.39.386.386 0 01.39.39.41.41 0 01-.112.277zm-.278-2.074H2.5a.392.392 0 01-.39-.391c0-.215.175-.39.39-.39h8.242c.215 0 .39.175.39.39 0 .215-.175.39-.39.39z" />
      <path d="M14.324 0h-.988a5.684 5.684 0 00-5.68 5.676 5.683 5.683 0 005.676 5.676h.988c.489 0 .973-.063 1.442-.184l1.46 1.46a.393.393 0 00.669-.276V10.09a5.719 5.719 0 001.492-1.84A5.6 5.6 0 0020 5.672 5.682 5.682 0 0014.324 0zm-.11 8.727a.386.386 0 01-.277.113.386.386 0 01-.39-.39.386.386 0 01.39-.39.386.386 0 01.39.39.403.403 0 01-.112.277zm.114-2.73v.905c0 .215-.176.391-.39.391a.392.392 0 01-.391-.39v-.938c0-.352.238-.649.582-.723a.873.873 0 10-.785-1.492.864.864 0 00-.278.637c0 .215-.175.39-.39.39a.392.392 0 01-.39-.39 1.659 1.659 0 011.773-1.653 1.657 1.657 0 01.27 3.262z" />
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse">
          <stop stopColor={gradientStartColor.hex} offset="0%" />
          <stop stopColor={gradientEndColor.hex} offset="100%" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

PaymentsIconActive.propTypes = {
  gradientStartColor: colorType,
  gradientEndColor: colorType,
};

PaymentsIconActive.defaultProps = {
  gradientStartColor: {},
  gradientEndColor: {},
};

const ThemedPaymentsIconActive = withTheme(({ colors: { gradientStart, gradientEnd } }) => ({
  gradientStartColor: gradientStart,
  gradientEndColor: gradientEnd,
}))(PaymentsIconActive);

export default ThemedPaymentsIconActive;
