import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { PageLayout, PageContentPreloader } from 'common/components';

import { StatusesForm } from '../../_components';

import { getStatusInfo, updateStatusInfo } from '../../_redux';

const EditStatusPage = ({ statusInfo, getStatusInfo, updateStatusInfo, statusInfoIsLoaded }) => {
  const match = useRouteMatch();
  const { id } = match.params;
  const history = useHistory();

  useEffect(() => {
    getStatusInfo(id);
  }, [getStatusInfo, id]);

  Object.keys(statusInfo).forEach(key => statusInfo[key] == null && delete statusInfo[key]);

  const { isButtonEnabled, title, subTitle, description, promoTexts, buttonText, statusColor, ...props } = statusInfo;

  let initialValues;
  if (statusInfoIsLoaded) {
    initialValues = {
      ...props,
      isButtonEnabled,
      title: title.reduce((acc, title) => ({ ...acc, [title.lang]: title.text }), {}),
      subTitle: subTitle.reduce((acc, subTitle) => ({ ...acc, [subTitle.lang]: subTitle.text }), {}),
      description: description.reduce(
        (acc, description) => ({
          ...acc,
          [description.lang]: description.text,
        }),
        {}
      ),
      statusColor: { hex: statusColor },
      promoTexts: promoTexts.reduce((acc, promo, id) => {
        const promoValue = promo.reduce((acc, cur) => ({ ...acc, [cur.lang]: cur.text }), {});
        return { ...acc, [`bullets_${id}`]: promoValue };
      }, {}),
      buttonText: buttonText.reduce((acc, buttonText) => ({ ...acc, [buttonText.lang]: buttonText.text }), {}),
    };
  }

  return (
    <PageLayout captionSlug="justEditStatus">
      <PageContentPreloader ready={statusInfoIsLoaded} type="bigLine">
        <StatusesForm
          submitFunction={values => updateStatusInfo({ id, values })}
          history={history}
          successMessageId="statusesCreatedSuccessfully"
          initialValues={initialValues}
          promoTexts={promoTexts}
        />
      </PageContentPreloader>
    </PageLayout>
  );
};

EditStatusPage.propTypes = {
  statusInfo: PropTypes.object.isRequired,
  getStatusInfo: PropTypes.func.isRequired,
  updateStatusInfo: PropTypes.func.isRequired,
  statusInfoIsLoaded: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    statusInfo: state.backoffice.statuses.statusInfo,
    statusInfoIsLoaded: state.backoffice.statuses.statusInfoIsLoaded,
  }),
  {
    getStatusInfo: id => getStatusInfo.request(id),
    updateStatusInfo: (id, data) => updateStatusInfo.request(id, data),
  }
)(EditStatusPage);
