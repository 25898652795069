import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { closeLightBox, openLightBox } from 'interface/user/_redux/actions';
import { withTheme } from 'common/styling/theme';

import { LightBoxModal } from 'common/components/modals';
import { LightBoxImage } from 'common/components/light-box';
import { change } from 'redux-form';
import { EditIcon } from '../../icons';
import { ActionsButton } from '../index';

import { staticStyles, getDynamicStyles } from './style';

const IssueMessages = ({
  lightBoxCurrentImage,
  lightBoxImages,
  openLightBox,
  closeLightBox,
  issue,
  onClick,
  isBroker,
  dispatch,
  setMessageEditing,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleEditMessage = message => {
    dispatch(change('issueCreateMessage', 'text', message.text));
    dispatch(change('issueCreateMessage', 'messageId', message.id));
    dispatch(change('issueCreateMessage', 'attachments', message.attachments));
    setMessageEditing(true);
  };

  const canEditMessage = ({ user, isNote, canEdit }) => {
    if (isBroker) {
      return user.id !== issue.user.id && !isNote;
    }
    return user.id === issue.user.id && canEdit;
  };

  return (
    <div className="IssueMessages__inner">
      <Scrollbars className="IssueMessages__scroll" autoHeight>
        {issue &&
          issue.messages &&
          issue.messages
            .slice(0)
            .reverse()
            .map(({ id, user, isNote, created, canEdit, text, editedBy, editedAt, attachments }) => (
              <div
                key={id}
                className={classNames('IssueMessage', {
                  IssueMessage__user: user.id === issue.user.id,
                  IssueMessage__support: user.id !== issue.user.id,
                  IssueMessage__with_image: attachments.length,
                  IssueMessage__with_image__support: attachments.length && user.id !== issue.user.id,
                  IssueMessage__note: isNote,
                })}>
                <div
                  className={classNames('IssueMessages__line', {
                    IssueMessage__broker: isBroker,
                  })}>
                  <div className="IssueMessages__avatar">
                    <Gravatar
                      className={classNames('IssueMessages__avatar', {
                        IssueMessages__click: onClick,
                      })}
                      md5={md5(user.email)}
                      size={45}
                      onClick={onClick || null}
                    />
                  </div>
                  <div className="IssueMessages__content">
                    <div className="IssueMessages__info">
                      <div className="IssueMessages__user-name">
                        {user.fullName || `${user.firstName} ${user.lastName}`},
                      </div>
                      <div className="IssueMessages__date">
                        <FormattedDate value={created} minute="2-digit" hour="2-digit" />
                        <FormattedDate value={created} month="2-digit" day="2-digit" year="numeric" />
                      </div>
                    </div>
                    <div className="IssueMessages__text">
                      <pre>{text}</pre>
                      <div className="IssueMessages__image-inner">
                        {attachments &&
                          attachments.map((attachment, i) => (
                            <LightBoxImage
                              className="IssueMessages__image"
                              key={attachment.id}
                              openLightBox={() => openLightBox(i, attachments)}
                              image={attachment.url}
                              titleId={attachment.title}
                            />
                          ))}
                      </div>
                    </div>
                    {!!editedBy && (
                      <div className="IssueMessages__edit-description">
                        <FormattedMessage
                          id="messageEditedBy"
                          values={{
                            username: `${editedBy.firstName} ${editedBy.lastName}`,
                          }}
                        />
                        <FormattedDate value={editedAt} minute="2-digit" hour="2-digit" />
                        <FormattedDate value={editedAt} month="2-digit" day="2-digit" year="numeric" />
                      </div>
                    )}
                  </div>
                  <ActionsButton
                    edit
                    buttonId="changeUserEmail"
                    condition={canEditMessage({ user, isNote, canEdit })}
                    onClickFunc={() => handleEditMessage(issue.messages.find(message => message.id === id))}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            ))}
      </Scrollbars>

      <LightBoxModal
        images={lightBoxImages}
        currentImage={lightBoxCurrentImage}
        onRequestClose={closeLightBox}
        src="file"
      />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

IssueMessages.propTypes = {
  issue: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setMessageEditing: PropTypes.func.isRequired,
  openLightBox: PropTypes.func,
  closeLightBox: PropTypes.func,
  lightBoxCurrentImage: PropTypes.number,
  lightBoxImages: PropTypes.array,
  onClick: PropTypes.func,
  isBroker: PropTypes.bool,
  theme: PropTypes.object,
};

IssueMessages.defaultProps = {
  openLightBox: null,
  closeLightBox: null,
  lightBoxCurrentImage: -1,
  lightBoxImages: [],
  onClick: null,
  isBroker: false,
  theme: {},
};

export default withTheme()(
  connect(
    state => ({
      lightBoxCurrentImage: state.user.lightBoxCurrentImage,
      lightBoxImages: state.user.lightBoxImages,
    }),
    dispatch => ({
      openLightBox: (id, lightBoxImages) => dispatch(openLightBox(id, lightBoxImages)),
      closeLightBox: () => dispatch(closeLightBox()),
      dispatch,
    })
  )(IssueMessages)
);

export { IssueMessages };
