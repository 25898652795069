import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { PageContentPreloader, PageLayout } from 'common/components';
import { CampaignForm } from '../../_components';
import { getCampaign, updateCampaign } from '../../_redux';

const EditCampaignPage = ({ campaign, campaignIsLoaded, getCampaign, match }) => {
  useEffect(() => {
    getCampaign({ campaignId: match.params.id });
  }, [getCampaign, match.params.id]);

  return (
    <PageLayout captionSlug="justCampaigns">
      <PageContentPreloader ready={campaignIsLoaded} type="bigLine">
        <CampaignForm
          initialValues={{ ...campaign, file: campaign.logoUrl }}
          submitFunction={updateCampaign}
          campaignId={match.params.id}
        />
      </PageContentPreloader>
    </PageLayout>
  );
};

EditCampaignPage.propTypes = {
  match: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  campaignIsLoaded: PropTypes.bool.isRequired,
  getCampaign: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      campaign: state.backoffice.promo.campaign,
      campaignIsLoaded: state.backoffice.promo.campaignIsLoaded,
    }),
    {
      getCampaign: data => getCampaign.request(data),
    }
  )
)(EditCampaignPage);
export { EditCampaignPage };
