import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_PAYMENT_SETTINGS = createRequestTypes('GET_PAYMENT_SETTINGS');
export const getPaymentSettings = {
  request: data => action(GET_PAYMENT_SETTINGS[REQUEST], data),
  success: data => action(GET_PAYMENT_SETTINGS[SUCCESS], { data }),
  failure: error => action(GET_PAYMENT_SETTINGS[FAILURE], { error }),
};

export const GET_POLICY_SETTINGS = createRequestTypes('GET_POLICY_SETTINGS');
export const getPolicySettings = {
  request: () => action(GET_POLICY_SETTINGS[REQUEST]),
  success: data => action(GET_POLICY_SETTINGS[SUCCESS], { data }),
  failure: error => action(GET_POLICY_SETTINGS[FAILURE], { error }),
};

export const GET_POLICY_SETTING = createRequestTypes('GET_POLICY_SETTING');
export const getPolicySetting = {
  request: data => action(GET_POLICY_SETTING[REQUEST], data),
  success: data => action(GET_POLICY_SETTING[SUCCESS], { data }),
  failure: error => action(GET_POLICY_SETTING[FAILURE], { error }),
};

export const createPolicySetting = createFormAction('CREATE_POLICY_SETTING');
export const editPolicySetting = createFormAction('EDIT_POLICY_SETTING');
export const deletePolicySetting = createFormAction('DELETE_POLICY_SETTING');
