import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .EmailConfirmationPage {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0C0C3D;

    @include respond-to(xs) {
      justify-content: flex-end;
      padding: 50px 20px 20px 20px;
    }

    &__inner {
      max-width: 320px;
      width: 100%;
      text-align: center;

      > h3 {
        margin-bottom: 15px;
      }

      > span, strong {
        margin-bottom: 25px;
      }
    }

    .LoadingIcon {
      width: 24px;
      height: 24px;
    }
  }
`;

export { staticStyles };
