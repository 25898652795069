import React from 'react';

const RegistrationIcon = () => (
  <div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M497,0H15C6.716,0,0,6.716,0,15v482c0,8.284,6.716,15,15,15h482c8.284,0,15-6.716,15-15V15C512,6.716,505.284,0,497,0z
			 M482,482H30V30h452V482z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M250.54,100.79c-2.79-2.79-6.66-4.391-10.61-4.391s-7.81,1.601-10.6,4.391c-2.8,2.8-4.4,6.66-4.4,10.609
			c0,3.95,1.601,7.811,4.4,10.61c2.79,2.791,6.66,4.39,10.6,4.39c3.95,0,7.811-1.6,10.61-4.39c2.79-2.8,4.39-6.66,4.39-10.61
			C254.93,107.45,253.33,103.589,250.54,100.79z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M330.871,100.79c-2.79-2.79-6.66-4.391-10.601-4.391c-3.95,0-7.819,1.601-10.609,4.391c-2.79,2.8-4.391,6.66-4.391,10.609
			c0,3.95,1.601,7.811,4.391,10.61c2.789,2.791,6.658,4.39,10.609,4.39c3.94,0,7.811-1.6,10.601-4.39
			c2.79-2.8,4.399-6.66,4.399-10.61C335.27,107.45,333.661,103.589,330.871,100.79z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M411.21,100.79c-2.8-2.79-6.66-4.391-10.61-4.391c-3.95,0-7.81,1.601-10.61,4.391c-2.79,2.8-4.391,6.66-4.391,10.609
			c0,3.95,1.601,7.811,4.391,10.61c2.8,2.791,6.659,4.39,10.61,4.39c3.95,0,7.811-1.6,10.61-4.39c2.79-2.8,4.39-6.66,4.39-10.61
			C415.6,107.45,414,103.589,411.21,100.79z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M400.6,385.6H111.4c-8.284,0-15,6.716-15,15s6.716,15,15,15h289.2c8.284,0,15-6.716,15-15S408.884,385.6,400.6,385.6z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M400.6,289.199H111.4c-8.284,0-15,6.716-15,15s6.716,15,15,15h289.2c8.284,0,15-6.716,15-15
			S408.884,289.199,400.6,289.199z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M352.4,192.8H159.6c-8.284,0-15,6.716-15,15s6.715,15,15,15h192.8c8.284,0,15-6.716,15-15S360.684,192.8,352.4,192.8z" />
        </g>
      </g>
    </svg>
  </div>
);

export default RegistrationIcon;
