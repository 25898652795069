import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AccountStatisticalBlock {
    margin-bottom: rem(45px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include respond-to(sm) {
      flex-direction: column;
      border-bottom: none !important;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .AccountStatisticalBlock {
      border-bottom: 2px solid ${colors.primary.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
