import { put, call, fork, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getInternalTransfersSaga({ search }) {
  try {
    const { status, data } = yield call(api.getInternalTransfers, search);
    if (status < 300) {
      yield put(ActionTypes.getInternalTransfers.success(data));
    } else {
      yield put(ActionTypes.getInternalTransfers.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getInternalTransfers.failure());
    throw e;
  }
}

export function* getInternalTransfersWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_INTERNAL_TRANSFERS[REQUEST]);
    yield call(getInternalTransfersSaga, data);
  }
}

export function* getTransferDetailsSaga({ transferId }) {
  try {
    const { status, data } = yield call(api.getTransferDetails, transferId);
    if (status < 300) {
      yield put(ActionTypes.getTransferDetails.success(data));
    } else {
      yield put(ActionTypes.getTransferDetails.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTransferDetails.failure());
    throw e;
  }
}

export function* getTransferDetailsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_TRANSFER_DETAILS[REQUEST]);
    yield call(getTransferDetailsSaga, data);
  }
}

export function* actionTransferSaga(id, actionType) {
  try {
    const { status, data } = yield call(api.actionTransfer, id, actionType);
    if (status < 300) {
      yield put(ActionTypes.actionTransfer.success(data));
      yield call(getTransferDetailsSaga, { transferId: id });
    } else {
      yield put(ActionTypes.actionTransfer.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.actionTransfer.failure());
    throw e;
  }
}

export function* actionTransferWatcherSaga() {
  while (true) {
    const { id, actionType } = yield take(ActionTypes.ACTION_TRANSFER[REQUEST]);
    yield call(actionTransferSaga, id, actionType);
  }
}

export function* rejectTransferSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { data, status } = yield call(api.rejectTransfer, id, values);
    if (status < 300) {
      yield put(ActionTypes.rejectTransfer.success(data));
      yield call(getTransferDetailsSaga, { transferId: id });
    } else {
      yield put(ActionTypes.rejectTransfer.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectTransfer.failure());
    throw e;
  }
}

export function* rejectTransferWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectTransfer.REQUEST);
    yield call(rejectTransferSaga, payload);
  }
}

export function* editTransferSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { data, status } = yield call(api.editTransfer, id, values);

    if (status < 300) {
      yield put(ActionTypes.editTransfer.success(data));
      yield call(getTransferDetailsSaga, { transferId: id });
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editTransfer.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editTransfer.failure());
    throw e;
  }
}

export function* editTransferWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editTransfer.REQUEST);
    yield call(editTransferSaga, payload);
  }
}

export default [
  fork(getInternalTransfersWatcherSaga),
  fork(getTransferDetailsWatcherSaga),
  fork(actionTransferWatcherSaga),
  fork(rejectTransferWatcherSaga),
  fork(editTransferWatcherSaga),
];
