import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .AccountPageOnlyTradingTable__table {
    @include respond-to(sm) {
    }
    :global(.NoItemsPanel) {
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
      height: rem(200px) !important;
      min-height: auto !important;
      img {
        margin-bottom: rem(15px) !important;
      }
      h3 {
        font-size: rem(16px) !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .AccountPageOnlyTradingTable__top-line {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px 0 20px;
    justify-content: space-between;
    min-height: 50px;
    @include respond-to(sm) {
      min-height: 54px;
    }
    .PageTabs {
      width: auto;

      a {
        padding-left: 0;
      }

      @include respond-to(sm) {
        order: 0;
        margin-bottom: 15px;
      }
    }
  }

  .AccountPageOnlyTradingTable__date {
    @include respond-to(xs) {
      width: 100%;
      order: 1;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .AccountPageOnlyTradingTable__table {
      :global(.NoItemsPanel) {
        :global(img) {
          width: 100px !important;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .AccountPageOnlyTradingTable__table {
      .NoItemsPanel {
        background-color: ${colors.white.hex};
        border-radius: ${border.radius};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
