import React from 'react';
import PropTypes from 'prop-types';
import { StatusCell, PaginationTable, DateCell, RowLink } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';
import InnerWithdrawRequestTable from '../InnerWithdrawRequestTable';

const WithdrawApprovalListTable = ({ data, onRowClick, getList, loading, history, location, params, theme }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: 'justFullName',
      accessor: 'user__last_name',
      Cell: ({
        row: {
          original: { user, id },
        },
      }) => (
        <RowLink isPreviewCell link={`/backoffice/withdrawals/approve/${id}`}>
          <span className="WithdrawApprovalListTable__fullName">{user.fullName}</span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      Header: 'justEmail',
      accessor: 'user__email',
      Cell: ({
        row: {
          original: { user, id },
        },
      }) => (
        <RowLink isPreviewCell link={`/backoffice/withdrawals/approve/${id}`}>
          <span className="WithdrawApprovalListTable__email">{user.email}</span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      Header: 'boAmount',
      accessor: 'total_amount',
      Cell: ({
        row: {
          original: { totalAmount, id },
        },
      }) => (
        <RowLink link={`/backoffice/withdrawals/approve/${id}`}>
          <span className="WithdrawApprovalListTable__amount">
            {totalAmount.amount} {totalAmount.currency}
          </span>
        </RowLink>
      ),
    },
    {
      Header: 'justAssigned',
      accessor: 'assignedTo',
      Cell: ({
        row: {
          original: { assignedTo, id },
        },
      }) => (
        <RowLink link={`/backoffice/withdrawals/approve/${id}`}>
          <span className="WithdrawApprovalListTable__assignedTo">{assignedTo}</span>
        </RowLink>
      ),
    },
    {
      Header: 'boStatus',
      accessor: '_status',
      Cell: ({
        row: {
          original: { status, id },
        },
      }) => (
        <RowLink isPreviewCell link={`/backoffice/withdrawals/approve/${id}`}>
          <StatusCell statusCode={status} />
        </RowLink>
      ),

      isPreviewCell: true,
    },
    {
      Header: 'justDate',
      accessor: 'created',
      Cell: ({
        row: {
          original: { created, id },
        },
      }) => (
        <RowLink link={`/backoffice/withdrawals/approve/${id}`}>
          <DateCell value={created} />
        </RowLink>
      ),
    },
    {
      Header: () => null,
      id: 'expander',
      Cell: ({ row }) => {
        const expandedProps = row?.getExpandedToggleProps?.();
        const handleRowClick = e => {
          e.stopPropagation();
          row.toggleExpanded();
        };
        return row?.getExpandedToggleProps ? (
          <span {...expandedProps} onClick={handleRowClick}>
            {row.isExpanded ? '…' : '⋮'}
          </span>
        ) : null;
      },
    },
  ];

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const innerTableData = data.results.find(item => item.id === row.original.id)?.requests;
    return <InnerWithdrawRequestTable data={innerTableData} />;
  }, []);

  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="WithdrawApprovalListTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        renderRowSubComponent={renderRowSubComponent}
        isResponsive
        isNewTable
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

WithdrawApprovalListTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

WithdrawApprovalListTable.defaultProps = {
  theme: {},
};

export default withTheme()(WithdrawApprovalListTable);
export { WithdrawApprovalListTable };
