import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PageLayout, PageContentPreloader } from 'common/components';

import { getPartnershipClientAccounts } from '../../_redux';
import AccountsBlock from '../../../_components/AccountsBlock';

class PartnershipClientAccountsPage extends React.Component {
  constructor(props) {
    super(props);
    props.getPartnershipClientAccounts(props.match.params.id);
  }

  handleAccountClick = id =>
    this.props.history.push(`/partnership/clients/user/${this.props.match.params.id}/account/${id}/`);

  render() {
    const { demoAccounts, realAccounts, partnershipClientAccountsIsLoaded } = this.props;

    return (
      <PageLayout captionSlug="partnershipClientAccounts">
        <PageContentPreloader ready={partnershipClientAccountsIsLoaded}>
          <AccountsBlock
            data={realAccounts}
            captionId="realAccounts"
            noAccCaptionId="noClientRealAccsCreated"
            userIsVerified
            paymentButtons={false}
            onAccountClick={this.handleAccountClick}
          />
          <AccountsBlock
            data={demoAccounts}
            captionId="demoAccounts"
            noAccCaptionId="noClientDemoAccsCreated"
            userIsVerified
            paymentButtons={false}
            onAccountClick={this.handleAccountClick}
          />
        </PageContentPreloader>
      </PageLayout>
    );
  }
}

PartnershipClientAccountsPage.propTypes = {
  demoAccounts: PropTypes.array,
  realAccounts: PropTypes.array,
  getPartnershipClientAccounts: PropTypes.func.isRequired,
  partnershipClientAccountsIsLoaded: PropTypes.bool,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

PartnershipClientAccountsPage.defaultProps = {
  realAccounts: [],
  demoAccounts: [],
  partnershipClientAccountsIsLoaded: false,
};

export default connect(
  state => ({
    demoAccounts: state.client.partnership.partnershipClientAccounts.filter(account => account.isDemo),
    realAccounts: state.client.partnership.partnershipClientAccounts.filter(account => !account.isDemo),
    partnershipClientAccountsIsLoaded: state.client.partnership.partnershipClientAccountsIsLoaded,
  }),
  {
    getPartnershipClientAccounts: clientId => getPartnershipClientAccounts.request(clientId, true),
  }
)(PartnershipClientAccountsPage);
