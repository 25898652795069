import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import { DefaultButton } from 'common/components';
import { generateAndDownloadSumSubReport } from 'backoffice/users/_redux';

import staticStyles from './style';

const GenerateAndDownloadSumSubReportForm = ({
  userId,
  toggleModal,
  handleSubmit,
  submitting,
  invalid,
  dispatch,
  error,
}) => {
  const submit = handleSubmit(() => generateAndDownloadSumSubReport({ userId }, dispatch));

  return (
    <div className="GenerateAndDownloadSumSubReportForm__content">
      <form className="GenerateAndDownloadSumSubReportForm" onSubmit={submit}>
        <div className="GenerateAndDownloadSumSubReportForm__inputs-inner">
          {error && <strong className="formError">{error}</strong>}
        </div>
        <div className="GenerateAndDownloadSumSubReportForm__btns">
          <DefaultButton
            textId="justGenerate"
            type="submit"
            loading={submitting}
            disabled={invalid || submitting}
            filled
          />
          <DefaultButton textId="justCancel" type="button" onClick={toggleModal} />
        </div>
      </form>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

GenerateAndDownloadSumSubReportForm.propTyes = {
  userId: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

GenerateAndDownloadSumSubReportForm.defaultProps = {
  error: '',
};

export default compose(
  reduxForm({
    form: 'GenerateAndDownloadSumSubReportForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(GenerateAndDownloadSumSubReportForm);
export { GenerateAndDownloadSumSubReportForm };
