import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .InputRange {
    position: relative;
    width: 211px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media only screen and (max-width: 510px) {
      width: 100%;
    }
  }

  .InputRange__Title {
    position: absolute;
    top: 0;
    left: 16px;
    z-index: 1;
    height: 1px;

    color: #999;
    background-color: #fff;
    font-size: 10px;
    line-height: 1.4;

    &--disabled {
      background-color: #f9f9f9;
    }

    &-text {
      position: relative;
      top: -7px;
      padding: 0 4px;
    }
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
