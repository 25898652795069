import axios from 'axios';
import { locale } from 'common/utils/helpers';

export const getCategories = search =>
  axios.get(`/meta/faq/category/list/user/`, {
    headers: {
      locale,
    },
    params: search,
  });

export const getQuestions = id =>
  axios.get(`/meta/faq/category/${id}/`, {
    headers: {
      locale,
    },
  });
