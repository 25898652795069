import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_CAMPAIGN_LIST = createRequestTypes('GET_CAMPAIGN_LIST');
export const getCampaignList = {
  request: data => action(GET_CAMPAIGN_LIST[REQUEST], data),
  success: data => action(GET_CAMPAIGN_LIST[SUCCESS], { data }),
  failure: error => action(GET_CAMPAIGN_LIST[FAILURE], { error }),
};

export const GET_CAMPAIGN = createRequestTypes('GET_CAMPAIGN');
export const getCampaign = {
  request: data => action(GET_CAMPAIGN[REQUEST], data),
  success: data => action(GET_CAMPAIGN[SUCCESS], { data }),
  failure: error => action(GET_CAMPAIGN[FAILURE], { error }),
};

export const GET_DEFAULT_CAMPAIGN = createRequestTypes('GET_DEFAULT_CAMPAIGN');
export const getDefaultCampaign = {
  request: () => action(GET_DEFAULT_CAMPAIGN[REQUEST]),
  success: data => action(GET_DEFAULT_CAMPAIGN[SUCCESS], { data }),
  failure: error => action(GET_DEFAULT_CAMPAIGN[FAILURE], { error }),
};

export const deleteCampaign = createFormAction('DELETE_CAMPAIGN');

export const createCampaign = createFormAction('CREATE_CAMPAIGN');

export const updateCampaign = createFormAction('UPDATE_CAMPAIGN');

export const GET_PROMO_MATERIAL_LIST = createRequestTypes('GET_PROMO_MATERIAL_LIST');
export const getPromoMaterialList = {
  request: data => action(GET_PROMO_MATERIAL_LIST[REQUEST], data),
  success: data => action(GET_PROMO_MATERIAL_LIST[SUCCESS], { data }),
  failure: error => action(GET_PROMO_MATERIAL_LIST[FAILURE], { error }),
};

export const DELETE_PROMO = createRequestTypes('DELETE_PROMO');
export const deletePromo = {
  request: data => action(DELETE_PROMO[REQUEST], data),
  success: () => action(DELETE_PROMO[SUCCESS]),
  failure: error => action(DELETE_PROMO[FAILURE], { error }),
};

export const createPromo = createFormAction('CREATE_PROMO');

export const updatePromo = createFormAction('UPDATE_PROMO');

export const GET_PROMO = createRequestTypes('GET_PROMO');
export const getPromo = {
  request: data => action(GET_PROMO[REQUEST], data),
  success: data => action(GET_PROMO[SUCCESS], { data }),
  failure: error => action(GET_PROMO[FAILURE], { error }),
};
