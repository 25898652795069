import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserPage__impersonate {
    :global(.DefaultButton) {
      position: absolute;
      top: rem(-64px);
      right: 0;
      @include respond-to(sm) {
        top: -55px;
      }
      @include respond-to(xs) {
        max-width: 130px;
      }
    }
  }
`;

export { staticStyles };
