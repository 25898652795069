import css from 'styled-jsx/css';
import { isRTL, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NewPaginationTable {
    position: relative;
    display: flex;
    flex: auto 1;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;

    .NewPaginationTable__pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: rem(30px 0 10px);

      @include respond-to(sm) {
        align-items: flex-start;
      }
    }

    .NewPaginationTable__select-wrap {
      position: relative;
      cursor: pointer;

      .NewPaginationTable__select {
        width: 60px;
        padding: rem(10px);
        cursor: pointer;
      }

      &:after {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(10px);
        top: 40%;
        right: ${isRTL ? 'auto' : '15%'};
        left: ${isRTL ? '15%' : 'auto'};
        transform: rotate(-45deg);
      }
    }

    .NewPaginationTable__pagination-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .NewPaginationTable__nav-info {
        display: flex;
        align-items: center;

        :global(.ActionsButton) {
          width: rem(10px);
          height: rem(10px);
          transform: rotate(45deg);

          &:first-child {
            margin-right: rem(5px);
          }

          &:nth-child(2) {
            margin-right: rem(30px);
          }
        }
        @include respond-to(sm) {
          margin-bottom: rem(20px);
        }
      }

      .NewPaginationTable__nav-input {
        width: 40px;
        text-align: center;
        padding: rem(10px 0);
        margin: rem(0 10px);
      }

      :global(.DefaultButton) {
        margin-left: rem(${isRTL ? '0' : '15px'});
        margin-right: rem(${isRTL ? '15px' : '0'});
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .NewPaginationTable {
      .NewPaginationTable__select-wrap {
        border: 1px solid ${colors.lightGray.hex};

        .NewPaginationTable__select {
          background-color: transparent;
          border: none;
        }
        &:after {
          border: solid ${colors.gray.hex};
          border-width: 0 0 1px 1px;
        }
      }

      .NewPaginationTable__pagination-nav {
        :global(.ActionsButton) {
          border: solid ${rgba([colors.primary.rgb, 0.7])};
          &:first-child {
            &:disabled {
              border: solid ${colors.gray.hex};
              border-width: 0 0 1px 1px;
            }
            border-width: 0 0 1px 1px;
          }

          &:nth-child(2) {
            &:disabled {
              border: solid ${colors.gray.hex};
              border-width: 1px 1px 0 0;
            }
            border-width: 1px 1px 0 0;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
