export const getCurrentStep = stepsStatuses => {
  if (!stepsStatuses.emailIsValid && !stepsStatuses.brokerNiceName) {
    return 1;
  }
  if (!stepsStatuses.mtIsValid) {
    return 2;
  }
  if (!stepsStatuses.dnsTxtIsValid || !stepsStatuses.dnsCnameIsValid) {
    if (!stepsStatuses.userDomain) return 3;
    return 4;
  }
  if (!stepsStatuses.crmSetup) {
    return 5;
  }
  return 6;
};
