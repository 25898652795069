import React from 'react';
import PropTypes from 'prop-types';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import Tooltip from '../Tooltip';

import { staticStyles, getDynamicStyles } from './style';

const NewFieldHelpText = ({ tooltipId, tooltipTextId, place, formattedValues, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  return (
    <div className="NewFieldHelpText">
      {tooltipTextId && (
        <Tooltip place={place} id={tooltipId} textId={tooltipTextId} formattedValues={formattedValues}>
          <span className="NewFieldHelpText__Question">?</span>
        </Tooltip>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

NewFieldHelpText.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  tooltipTextId: PropTypes.string.isRequired,
  formattedValues: PropTypes.object,
  place: PropTypes.string,
  theme: PropTypes.object,
};

NewFieldHelpText.defaultProps = {
  formattedValues: {},
  place: 'top',
  theme: {},
};

export default withTheme()(NewFieldHelpText);
export { NewFieldHelpText };
