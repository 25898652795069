import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { DefaultButton, ReduxTextField, ReduxSelectField } from 'common/components';

import { editInvestment } from 'client/invest/_redux';

const InvestEditTpSlForm = ({
  accounts,
  investmentId,
  strategyCurrency,
  toggleModal,
  handleSubmit,
  dispatch,
  submitting,
  invalid,
  error,
}) => {
  const submit = handleSubmit(values =>
    editInvestment(
      {
        investmentId,
        ...values,
        takeProfit: { amount: values.takeProfit, currency: strategyCurrency },
        stopLoss: { amount: values.stopLoss, currency: strategyCurrency },
      },
      dispatch
    )
  );

  const accountOptions = accounts
    .filter(({ balance }) => !!balance)
    .map(({ id, login, balance }) => ({ value: id, label: `${login} (${balance.amount} ${balance.currency})` }));

  return (
    <form className="BasicModalForm" onSubmit={submit}>
      <div className="BasicModalForm__inputs-inner">
        <Field
          component={ReduxTextField}
          textId="justTakeProfit"
          type="number"
          name="takeProfit"
          description={strategyCurrency}
          disabled={submitting}
          validate={[required()]}
        />

        <Field
          component={ReduxTextField}
          textId="justStopLoss"
          type="number"
          name="stopLoss"
          description={strategyCurrency}
          disabled={submitting}
          validate={[required()]}
        />
      </div>

      <Field
        component={ReduxSelectField}
        name="account"
        maxMenuHeight={140}
        textId="justTpSlWithdrawalAccount"
        options={accountOptions}
        disabled={submitting}
        validate={required()}
        searchable
      />

      {error && <strong className="formError">{error}</strong>}

      <div className="BasicModalForm__buttons">
        <DefaultButton textId="justEdit" type="submit" loading={submitting} disabled={invalid || submitting} filled />

        <DefaultButton textId="justCancel" type="button" onClick={() => toggleModal()} />
      </div>
    </form>
  );
};

InvestEditTpSlForm.propTypes = {
  accounts: PropTypes.array.isRequired,
  investmentId: PropTypes.number.isRequired,
  strategyCurrency: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

InvestEditTpSlForm.defaultProps = {
  error: '',
};

export default compose(
  reduxForm({
    form: 'InvestEditTpSlForm',
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleModal();
    },
  })
)(InvestEditTpSlForm);
export { InvestEditTpSlForm };
