import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { BasicModal, DefaultButton } from 'common/components';

import { unblockUser, rejectPartner, removePhone, verifyUser } from '../../_redux';

import RejectVerifyForm from '../RejectVerifyForm';
import BlockUserForm from '../BlockUserForm';
import DisableSupportMessagesForm from '../DisableSupportMessagesForm';
import EditLPAForm from '../EditLPAForm';
import { staticStyles } from './style';

const UserActionsModal = ({
  user,
  captionId,
  descriptionId,
  isOpen,
  onRequestClose,
  userVerifiedIsLoading,
  userBlockIsLoading,
  userUnblockIsLoading,
  verifyUser,
  removePhone,
  userRemovePhone,
  unblockUser,
  rejectPartner,
  statusBlock,
  activeBlock,
  userSupport,
  partnerBlock,
  hasLpaEditPerm,
  lpaInfo,
}) => {
  const userBlockedLoading = userBlockIsLoading || userUnblockIsLoading;
  const isUserLpa = user?.lpaStatus?.is_lpa;
  const lpaFormInfo = isUserLpa ? lpaInfo : {};

  return (
    <div className="UserActionsModal">
      <BasicModal captionId={captionId} isOpen={isOpen} onRequestClose={onRequestClose}>
        {isOpen && (
          <div className="UserActionsModal__content">
            <FormattedMessage id={descriptionId}>{txt => <p>{txt}</p>}</FormattedMessage>
            <div className="UserActionsModal__buttons-inner">
              {statusBlock && (
                <>
                  {user.status !== 'verified' && (
                    <DefaultButton
                      onClick={() => verifyUser(user.id)}
                      textId="boVerify"
                      butColor="success"
                      disabled={userVerifiedIsLoading}
                      loading={userVerifiedIsLoading}
                    />
                  )}
                  {!user.isStaff && user.status === 'verified' && (
                    <RejectVerifyForm id={user.id} toggleModal={onRequestClose} />
                  )}
                </>
              )}

              {activeBlock && (
                <>
                  {user.isActive && <BlockUserForm id={user.id} toggleModal={onRequestClose} />}
                  {!user.isActive && (
                    <DefaultButton
                      onClick={() => {
                        unblockUser(user.id);
                      }}
                      disabled={userBlockedLoading}
                      loading={userBlockedLoading}
                      textId="boUnblockedUser"
                    />
                  )}
                </>
              )}

              {userSupport && (
                <DisableSupportMessagesForm
                  id={user.id}
                  toggleModal={onRequestClose}
                  ifSupportMessages={!!user.supportMessages}
                />
              )}

              {hasLpaEditPerm && (
                <EditLPAForm id={user.id} toggleModal={onRequestClose} isLpa={isUserLpa} initialValues={lpaFormInfo} />
              )}

              {partnerBlock && (
                <DefaultButton
                  onClick={() => {
                    rejectPartner(user.id);
                    onRequestClose();
                  }}
                  textId="boDisablePartnershipTitle"
                  butColor="error"
                />
              )}

              {userRemovePhone && (
                <DefaultButton
                  onClick={() => {
                    removePhone(user.id);
                    onRequestClose();
                  }}
                  textId="doRemove"
                  butColor="error"
                />
              )}
            </div>
          </div>
        )}
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserActionsModal.propTypes = {
  user: PropTypes.object.isRequired,
  captionId: PropTypes.string.isRequired,
  descriptionId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  userVerifiedIsLoading: PropTypes.bool.isRequired,
  userBlockIsLoading: PropTypes.bool.isRequired,
  userUnblockIsLoading: PropTypes.bool.isRequired,
  verifyUser: PropTypes.func.isRequired,
  unblockUser: PropTypes.func.isRequired,
  rejectPartner: PropTypes.func.isRequired,
  removePhone: PropTypes.func.isRequired,
  userRemovePhone: PropTypes.bool,
  statusBlock: PropTypes.bool,
  activeBlock: PropTypes.bool,
  partnerBlock: PropTypes.bool,
  userSupport: PropTypes.bool,
  hasLpaEditPerm: PropTypes.bool,
  lpaInfo: PropTypes.object,
};

UserActionsModal.defaultProps = {
  isOpen: false,
  statusBlock: false,
  activeBlock: false,
  partnerBlock: false,
  userSupport: false,
  hasLpaEditPerm: false,
  userRemovePhone: false,
  lpaInfo: {},
};

const ConnectedUserActionsModal = connect(
  state => ({
    userVerifiedIsLoading: state.backoffice.users.userVerifiedIsLoading,
    userRejectedIsLoading: state.backoffice.users.userRejectedIsLoading,
    userBlockIsLoading: state.backoffice.users.userBlockIsLoading,
    userUnblockIsLoading: state.backoffice.users.userUnblockIsLoading,
    lpaInfo: state.backoffice.users.lpaInfo,
  }),
  {
    verifyUser: id => verifyUser.request(id),
    unblockUser: id => unblockUser.request(id),
    rejectPartner: id => rejectPartner.request(id),
    removePhone: id => removePhone.request(id),
  }
)(UserActionsModal);

export default ConnectedUserActionsModal;
export { UserActionsModal };
