import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .UsersTable {
    .ReactTable {
      .rt-thead {
        min-width: 800px !important;
        .rt-tr {
          .rt-th {
            &:first-child {
              flex: 35 0 auto !important;
              width: 35px !important;
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
              span {
                opacity: 0;
              }
            }
            &:nth-child(2) {
              flex: 130 0 auto !important;
              width: 130px !important;
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(3) {
              flex: 120 0 auto !important;
              width: 120px !important;
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
            }
            &:nth-child(4) {
              flex: 80 0 auto !important;
              width: 60px !important;
            }
            &:nth-child(5) {
              flex: 60 0 auto !important;
              width: 40px !important;
            }
            &:nth-child(6) {
              flex: 40 0 auto !important;
              width: 40px !important;
              text-align: center;
              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                flex: auto !important;
              }
              @include respond-to(sm) {
                display: none;
              }
            }

            &:last-child {
              flex: 40 0 auto !important;
              text-align: center;
            }
          }
        }
      }

      .rt-tbody {
        min-width: 800px !important;
        .rt-tr-group {
          .rt-tr {
            padding: rem(12px 18px) !important;
            @include respond-to(lg) {
              padding: rem(10px 12px) !important;
            }
            .rt-td {
              &:first-child {
                flex: 35 0 auto !important;
                width: 35px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                img {
                  border-radius: 50%;
                  @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                    width: rem(35px);
                    height: rem(35px);
                  }
                }
              }
              &:nth-child(2) {
                flex: 130 0 auto !important;
                width: 130px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                  font-weight: 500;
                }
              }
              &:nth-child(3) {
                flex: 120 0 auto !important;
                width: 120px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                span {
                  font-size: rem(14px);
                  font-weight: 400;
                }
              }
              &:nth-child(4) {
                flex: 80 0 auto !important;
                width: 60px !important;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
              }
              &:nth-child(5) {
                flex: 60 0 auto !important;
                width: 40px !important;
                .StatusCell {
                  font-size: rem(12px) !important;
                }
              }
              &:nth-child(6) {
                flex: 40 0 auto !important;
                width: 40px !important;
                text-align: center;
                @media all and (-ms-high-contrast: none),
                  (-ms-high-contrast: active) {
                  flex: auto !important;
                }
                .UserActivity:after {
                  width: 10px;
                  height: 10px;
                }
                @include respond-to(sm) {
                  display: none;
                }
              }
              &:last-child {
                flex: 40 0 auto !important;
                text-align: center;
                span {
                  font-size: rem(14px);
                  padding-left: 5px;
                  @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                    max-width: 90px;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ResponsiveTable__thead {
      min-width: 320px !important;

      span {
        flex: 60;
        text-align: center;
        padding: 5px 0;

        &:first-child {
          padding-left: rem(20px);
          flex: 1;
          opacity: 0;
        }

        &:last-child {
          min-width: 60px;
          padding-right: rem(30px);
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 320px !important;
      .ResponsiveTable__preview-main {
        height: 40px;
        .ResponsiveTable__row-item {
          flex: 60;
          display: flex;
          align-items: center;
          justify-content: center;
          text-overflow: ellipsis;

          &:first-child {
            flex: 35 0 auto !important;
            width: 35px !important;

            @include respond-to(xs) {
              max-width: 35px;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            margin: 0 auto;
            flex: 120 0 auto !important;
            width: 120px !important;

            @include respond-to(xs) {
              max-width: 120px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .ResponsiveTable__preview-description {
        .ResponsiveTable__row-item {
          justify-content: flex-start;
          padding-left: 20%;
        }
      }

      .ResponsiveTable__details-item {
        padding-left: 18%;
      }

      .UserTable__active {
        width: 13px;
        height: 13px;
        border-radius: 50%;
      }
    }
  }

  .PartnershipUsersTable {
    .ReactTable {
      .rt-thead {
        min-width: 300px !important;
      }

      .rt-tbody {
        min-width: 300px !important;
        .rt-tr-group {
          .rt-tr {
            .rt-td {
              &:nth-child(1) {
                span {
                  font-size: rem(14px);
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UsersTable {
      .ReactTable {
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &:nth-child(2) {
                  span {
                    color: ${colors.secondary.hex};
                  }
                }
              }
            }
          }
        }
      }

      .ResponsiveTable__tbody {
        .UserTable__active {
          background-color: ${colors.gray.hex};
          &.active {
            background-color: ${colors.success.hex};
          }
        }
      }
    }

    .PartnershipUsersTable {
      .ReactTable {
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                &:nth-child(1) {
                  span {
                    color: ${colors.secondary.hex};
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
