import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { ModalHOC } from 'common';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getUserAccounts } from 'backoffice/users/_redux';

import {
  Collapsible,
  NoItemsPanel,
  BaseSelectField,
  Calendar,
  StatusCell,
  ActionsButton,
  TableLoader,
  DateCell,
  ReactDataTable,
} from 'common/components';
import { BasketIcon, EditIcon } from 'common/icons';
import noPaymentsImage from 'common/images/noPayments.svg';

import { withTheme } from 'common/styling/theme';
import {
  getOpenOrders,
  getClosedOrders,
  getDelayedOrders,
  getDeferredOrders,
  removeOrder,
} from '../../payments/_redux';

import { staticStyles, getDynamicStyles } from './style';

const startDate = moment().add(0, 'day').startOf('day').unix();

const endDate = moment().add(1, 'day').startOf('day').unix();

const startCalendar = moment().add(0, 'day').startOf('day');

const endCalendar = moment().add(1, 'day').startOf('day');

// const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

const UserTradingHistory = ({
  accounts,
  accountsAreLoaded,
  getUserAccounts,
  getOpenOrders,
  getClosedOrders,
  getDelayedOrders,
  getDeferredOrders,
  removeOrder,
  orders,
  ordersAreLoaded,
  userId,
  accountId,
  accountLabel,
  theme,
  opened,
  addons,
  profile,
  showModal,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const history = useHistory();
  const [selectedAccount, setSelectedAccount] = useState({ value: accountId, label: accountLabel });
  const selectedAccountRef = useRef(selectedAccount);

  const isMt5Account = accounts.find(account => account.id === selectedAccount.value)?.platformSlug === 'mt5' ?? false;
  const isCfhAccount = accounts.find(account => account.id === selectedAccount.value)?.platformSlug === 'cfh' ?? false;
  const isBOAccount = accounts.find(account => account.id === selectedAccount.value)?.platformSlug === 'bo' ?? false;
  const isMt4Account = accounts.find(account => account.id === selectedAccount.value)?.platformSlug === 'mt4' ?? false;

  const openOrdersSupported = !isMt5Account && !isCfhAccount;
  const [activeTab, setActiveTab] = useState(openOrdersSupported ? 'opened' : 'closed');
  const showCalendar = isMt5Account || activeTab !== 'pending';

  const memoizedOnChangeParams = useMemo(
    () => ({
      clientId: userId,
      accountId: selectedAccount.value,
      activeTab,
    }),
    [userId, accountId, activeTab]
  );

  useEffect(() => {
    getUserAccounts({ userId, noDetails: true });
  }, [getUserAccounts, userId]);

  const handleOpenCollapse = () => {
    if (isMt5Account || isCfhAccount) {
      setActiveTab('closed');
      getClosedOrders({ userId, accountId: selectedAccount.value, start: startDate, end: endDate });
    } else {
      getOpenOrders({ userId, accountId: selectedAccount.value, start: startDate, end: endDate });
    }
  };

  const editOrder = (e, orderId) => {
    e.stopPropagation();
    history.push(`/backoffice/users/${userId}/accounts/${selectedAccount.value}/${orderId}`);
  };

  const deleteOrder = (e, orderId) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeleteOrder',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => removeOrder({ userId, accountId: selectedAccount.value, orderId, activeTab }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };
  /* eslint-enable */

  const CellWEmpty = ({ value }) => (value || value === 0 ? <span>{value}</span> : <span>一</span>);

  CellWEmpty.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  CellWEmpty.defaultProps = {
    value: '',
  };

  const DateWEmpty = ({ value }) => (value ? <DateCell value={value} /> : <span>一</span>);

  DateWEmpty.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  DateWEmpty.defaultProps = {
    value: '',
  };
  /* eslint-disable */
  const order = {
    Header: 'justOrder',
    Cell: ({
      row: {
        original: { id },
      },
    }) => <CellWEmpty value={id} />,
    Footer: (
      <span className="summary__order">
        <FormattedMessage id="justTotal">{txt => txt}</FormattedMessage>
      </span>
    ),
    isPreviewCell: true,
  };
  const symbol = {
    Header: 'justSymbol',
    Cell: ({
      row: {
        original: { symbol },
      },
    }) => <CellWEmpty value={symbol} />,
    isPreviewCell: true,
  };
  const status = {
    Header: 'justType',
    Cell: ({
      row: {
        original: { command },
      },
    }) => <StatusCell statusCode={command?.toLowerCase().replace('/', '')} noDot />,
    isPreviewCell: true,
  };
  const openTime = {
    Header: 'openTime',
    Cell: ({
      row: {
        original: { open_time },
      },
    }) => <DateWEmpty value={open_time} />,
  };
  const closeTime = {
    Header: 'justCloseTime',
    Cell: ({
      row: {
        original: { close_time, expiration },
      },
    }) => <DateWEmpty value={close_time || expiration} />,
  };
  const openPrice = {
    Header: 'justOpenPrice',
    Cell: ({
      row: {
        original: { open_price },
      },
    }) => <CellWEmpty value={open_price} />,
  };
  const closePrice = {
    Header: document.location.hostname === 'portal.rock-west.com' ? 'justPrice' : 'justClosePrice',
    Cell: ({
      row: {
        original: { close_price },
      },
    }) => <CellWEmpty value={close_price} />,
  };
  const lots = {
    Header: 'justLots',
    Cell: ({
      row: {
        original: { lots, command },
      },
    }) => <CellWEmpty value={lots === 0.01 && command === 'In/Out' ? 0 : lots} />,
    Footer: (
      <div className="summary__lots">
        <span className="subtext">
          <FormattedMessage id="justLots">{txt => txt}</FormattedMessage>
        </span>
        <div>{orders.summary ? orders.summary.lots : ''}</div>
      </div>
    ),
  };

  const commission = {
    Header: 'justCommission',
    Cell: ({
      row: {
        original: { commission },
      },
    }) => <CellWEmpty value={commission} />,
    Footer: (
      <div className="summary__commission">
        <span className="subtext">
          <FormattedMessage id="justCommission">{txt => txt}</FormattedMessage>
        </span>
        <div>{orders.summary ? orders.summary.commission : ''}</div>
      </div>
    ),
  };
  const swap = {
    Header: 'justSwap',
    Cell: ({
      row: {
        original: { swap },
      },
    }) => <CellWEmpty value={swap} />,
    Footer: (
      <div className="summary__swap">
        <span className="subtext">
          <FormattedMessage id="justSwap">{txt => txt}</FormattedMessage>
        </span>
        <div>{orders.summary ? orders.summary.swap : ''}</div>
      </div>
    ),
  };
  const profit = {
    Header: 'justProfit',
    Cell: ({
      row: {
        original: { profit },
      },
    }) => <CellWEmpty value={profit} />,
    Footer: (
      <div className="summary__profit">
        <span className="subtext">
          <FormattedMessage id="justProfit">{txt => txt}</FormattedMessage>
        </span>
        <div>{orders.summary ? orders.summary.profit : ''}</div>
      </div>
    ),
  };
  const edit = {
    Header: 'justActions',
    Cell: ({
      row: {
        original: { id },
      },
    }) => (
      <>
        <ActionsButton
          tooltipId={`Edit__tooltip-${id}`}
          tooltipTextId="justEdit"
          edit
          onClickFunc={e => editOrder(e, id)}>
          <EditIcon />
        </ActionsButton>
        {!isBOAccount && (
          <ActionsButton
            tooltipId={`Delete__tooltip-${id}`}
            tooltipTextId="justDelete"
            delete
            onClickFunc={e => deleteOrder(e, id)}>
            <BasketIcon />
          </ActionsButton>
        )}
      </>
    ),
  };
  /* eslint-enable */

  const columns = [order, symbol, status, openTime, closeTime, openPrice, closePrice, lots, commission, swap, profit];
  if (addons.includes('editOrderSystem') && profile.perms.tradeEditPerm && !(isMt5Account || isCfhAccount)) {
    columns.push(edit);
  }

  const handleAccountChange = ({ value, label }) => {
    setSelectedAccount({ value, label });
    selectedAccountRef.current = { value, label };
  };

  useEffect(() => {
    if (selectedAccountRef.current.value) {
      switch (activeTab) {
        case 'closed':
          getClosedOrders({ userId, accountId: selectedAccountRef.current.value, start: startDate, end: endDate });
          break;
        case 'pending':
          if (isMt5Account) {
            getDeferredOrders({ userId, accountId: selectedAccountRef.current.value, start: startDate, end: endDate });
          } else {
            getDelayedOrders({ userId, accountId: selectedAccountRef.current.value });
          }
          break;
        default:
          if (isMt5Account || isCfhAccount) {
            setActiveTab('closed');
            getClosedOrders({ userId, accountId: selectedAccountRef.current.value, start: startDate, end: endDate });
          } else getOpenOrders({ userId, accountId: selectedAccountRef.current.value, start: startDate, end: endDate });
      }
    }
  }, [selectedAccountRef.current]);

  const handleChangeTab = activeTab => {
    switch (activeTab) {
      case 'closed':
        getClosedOrders({ userId, accountId: selectedAccount.value, start: startDate, end: endDate });
        break;
      case 'pending':
        if (isMt5Account) {
          getDeferredOrders({ userId, accountId: selectedAccount.value, start: startDate, end: endDate });
        } else {
          getDelayedOrders({ userId, accountId: selectedAccount.value });
        }
        break;
      default:
        getOpenOrders({ userId, accountId: selectedAccount.value, start: startDate, end: endDate });
    }
    setActiveTab(activeTab);
  };

  const handleChangeDate = (userId, accountId, activeTab, startDate, endDate) => {
    switch (activeTab) {
      case 'closed':
        getClosedOrders({
          userId,
          accountId,
          start: moment(startDate).format('x'),
          end: moment(endDate).format('x'),
        });
        break;
      case 'pending':
        if (isMt5Account) {
          getDeferredOrders({
            userId,
            accountId,
            start: moment(startDate).format('x'),
            end: moment(endDate).format('x'),
          });
        } else {
          getDelayedOrders({
            userId,
            accountId,
            start: moment(startDate).format('x'),
            end: moment(endDate).format('x'),
          });
        }
        break;
      default:
        getOpenOrders({
          userId,
          accountId,
          start: moment(startDate).format('x'),
          end: moment(endDate).format('x'),
        });
    }
  };

  useEffect(() => {
    setSelectedAccount(prev => (prev.value ? { ...prev } : { value: accounts[0]?.id, label: accounts[0]?.login }));
  }, [accounts, accountsAreLoaded]);

  return (
    <div className="UserTradingHistory">
      {!!accounts.length && accountsAreLoaded && (
        <Collapsible captionId="justTradingHistory" handleOpen={handleOpenCollapse} opened={opened}>
          <div className="UserTradingHistory__select">
            <BaseSelectField
              value={selectedAccount}
              name="userAccount"
              options={accounts.map(account => ({ value: account.id, label: account.login }))}
              onChange={value => handleAccountChange(value)}
              textId="selectAccount"
              disabled={!accountsAreLoaded}
            />
          </div>
          <div className="UserTradingHistory__heading">
            <div className="UserTradingHistory__tabs">
              {openOrdersSupported && (
                <FormattedMessage id="justOpenOrders">
                  {txt => (
                    <button
                      type="button"
                      className={classNames('UserTradingHistory__tab', {
                        active: activeTab === 'opened',
                      })}
                      onClick={() => handleChangeTab('opened')}>
                      {txt}
                    </button>
                  )}
                </FormattedMessage>
              )}
              <FormattedMessage id={openOrdersSupported ? 'justClosed' : 'justDeals'}>
                {txt => (
                  <button
                    type="button"
                    className={classNames('UserTradingHistory__tab', {
                      active: activeTab === 'closed',
                    })}
                    onClick={() => handleChangeTab('closed')}>
                    {txt}
                  </button>
                )}
              </FormattedMessage>
              <FormattedMessage id="justPending">
                {txt => (
                  <button
                    type="button"
                    className={classNames('UserTradingHistory__tab', {
                      active: activeTab === 'pending',
                    })}
                    onClick={() => handleChangeTab('pending')}>
                    {txt}
                  </button>
                )}
              </FormattedMessage>
            </div>
            {showCalendar && (
              <Calendar
                // only mt4
                daysLimit={activeTab === 'closed' && isMt4Account ? 1 : 31}
                onChangeFunc={handleChangeDate}
                onChangeParams={memoizedOnChangeParams}
                defaultStartDate={startCalendar}
                defaultEndDate={endCalendar}
              />
            )}
          </div>
          {!ordersAreLoaded && <TableLoader />}
          {ordersAreLoaded && (
            <div>
              {orders?.trades?.length || (Array.isArray(orders) && orders.length) ? (
                <ReactDataTable columns={columns} data={orders?.trades} onRowClick={() => {}} isResponsive isInfinite />
              ) : (
                <NoItemsPanel icon={noPaymentsImage} captionSlug="noData" />
              )}
            </div>
          )}
        </Collapsible>
      )}

      {!accounts.length && accountsAreLoaded && (
        <Collapsible captionId="justTradingHistory" handleOpen={() => {}} opened={opened}>
          <NoItemsPanel icon={noPaymentsImage} captionSlug="justNoOrders" />
        </Collapsible>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserTradingHistory.propTypes = {
  accounts: PropTypes.array.isRequired,
  accountsAreLoaded: PropTypes.bool.isRequired,
  getUserAccounts: PropTypes.func.isRequired,
  getOpenOrders: PropTypes.func.isRequired,
  getClosedOrders: PropTypes.func.isRequired,
  getDelayedOrders: PropTypes.func.isRequired,
  getDeferredOrders: PropTypes.func.isRequired,
  removeOrder: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  ordersAreLoaded: PropTypes.bool.isRequired,
  accountId: PropTypes.number.isRequired,
  accountLabel: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  theme: PropTypes.object,
  addons: PropTypes.array,
};

UserTradingHistory.defaultProps = {
  opened: false,
  theme: {},
  addons: [],
};
export default compose(
  ModalHOC,
  withTheme(),
  connect(
    state => ({
      accounts: state.backoffice.users.accounts.filter(account => !account.isArchived),
      accountsAreLoaded: state.backoffice.users.accountsAreLoaded,
      orders: state.backoffice.payments.orders,
      ordersAreLoaded: state.backoffice.payments.ordersAreLoaded,
      addons: state.interfaceConfig.addons,
      profile: state.user.profile,
    }),
    {
      getUserAccounts: data => getUserAccounts.request(data),
      getOpenOrders: (userId, accountId) => getOpenOrders.request(userId, accountId),
      getClosedOrders: data => getClosedOrders.request(data),
      getDelayedOrders: data => getDelayedOrders.request(data),
      getDeferredOrders: data => getDeferredOrders.request(data),
      removeOrder: data => removeOrder.request(data),
    }
  )
)(UserTradingHistory);
export { UserTradingHistory };
