import React from 'react';

const CustomizationIcon = () => (
  <div>
    <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" fill="none" fillRule="evenodd">
        <g id="menu-/-admin" transform="translate(-47 -310)" fillRule="nonzero">
          <g id="Group" transform="translate(19 102)">
            <g id="paint" transform="translate(28 208)">
              <path d="M14.3060357,2.5 L13.7142857,2.5 L13.7142857,1.23046875 C13.7142857,0.54109375 13.2220714,0 12.59175,0 L1.16317857,0 C0.532892857,0 0,0.54109375 0,1.23046875 L0,4.98046875 C0,5.66984375 0.532892857,6.25 1.16317857,6.25 L12.59175,6.25 C13.2220714,6.25 13.7142857,5.66984375 13.7142857,4.98046875 L13.7142857,3.75 L14.3060357,3.75 C14.6210357,3.75 14.8571429,4.0109375 14.8571429,4.35546875 L14.8571429,8.10546875 C14.8571429,8.45 14.6210714,8.75 14.3060357,8.75 L8.02032143,8.75 C7.07503571,8.75 6.28571429,9.57152344 6.28571429,10.6054688 L6.28571429,11.875 L5.16317857,11.875 C4.84760714,11.875 4.57142857,12.1353125 4.57142857,12.4804688 L4.57142857,19.3554688 C4.57142857,19.700625 4.84760714,20 5.16317857,20 L8.59175,20 C8.90732143,20 9.14285714,19.700625 9.14285714,19.3554688 L9.14285714,12.4804688 C9.14285714,12.1353125 8.90732143,11.875 8.59175,11.875 L7.42857143,11.875 L7.42857143,10.6054688 C7.42857143,10.2609375 7.70532143,10 8.02032143,10 L14.3060357,10 C15.2513571,10 16,9.13941406 16,8.10546875 L16,4.35546875 C16,3.32152344 15.2513571,2.5 14.3060357,2.5 Z M1.14285714,5 L1.14285714,1.25 L12.59175,1.25 L12.5925714,5 L1.14285714,5 Z M8,18.75 L5.71428571,18.75 L5.71428571,13.125 L8,13.125 L8,18.75 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default CustomizationIcon;
