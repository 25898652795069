import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .ExportUsersPage {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(20px, 0.5fr) minmax(150px, 1.5fr) repeat(2, minmax(100px, 1fr)) minmax(
            250px,
            0.2fr
          );
      }
    }
  }
`;

export { staticStyles };
