import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { getDynamicStyles, staticStyles } from './style';

const Tooltip = ({ id, textId, noTranslate, place, effect, formattedValues, children, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  return (
    <>
      {textId ? (
        <>
          <span data-tip data-for={id} className="Tooltip__text">
            {children}
          </span>
          <ReactTooltip id={id} className="Tooltip" place={place} effect={effect}>
            {noTranslate ? (
              <pre>{textId}</pre>
            ) : (
              <FormattedMessage id={textId} values={formattedValues}>
                {txt => <pre>{txt}</pre>}
              </FormattedMessage>
            )}
          </ReactTooltip>
        </>
      ) : (
        children
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  textId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  noTranslate: PropTypes.bool,
  place: PropTypes.string,
  effect: PropTypes.string,
  children: PropTypes.node,
  formattedValues: PropTypes.object,
  theme: PropTypes.object,
};

Tooltip.defaultProps = {
  children: null,
  textId: '',
  noTranslate: false,
  place: 'top',
  effect: 'solid',
  formattedValues: {},
  theme: {},
};

export default withTheme()(Tooltip);
export { Tooltip };
