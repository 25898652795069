import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .LoginPage {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-to(xs) {
      justify-content: flex-end;
    }

    &__inner {
      position: relative;
      max-width: 384px;
      background: white;
      padding: 36px 32px 32px 32px;
      @include respond-to(xs) {
        padding: 32px 24px 24px 24px;
        width: 100%;
        max-width: 100%;
      }
    }

    .innerHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .innerLocationText {
      margin-bottom: 32px;
    }

    &__background-image {
      position: absolute;
      height: 100%;
      width: 100%;
      @include respond-to(sm) {
        width: auto;
      }
    }

    &__logo {
      width: 168px;
    }

    &__tabs {
      margin-bottom: 25px;

      a {
        position: relative;
        width: 50%;
        padding-bottom: 20px;
        text-align: center;
        font-size: 20px;
        color: #0c0c3d !important;
        transition: color 0.2s ease-in-out;

        &.active {
          color: #0aa1e2 !important;

          &::after {
            opacity: 1;
            background: linear-gradient(62.43deg, #43b6e8 0%, #33eec7 100%);
          }
        }

        &:not(.active):hover::after {
          opacity: 0.5;
        }

        &:not(.active):active {
          color: #5d647d !important;

          &::after {
            opacity: 1;
          }
        }

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 0;
          background-color: #bfc7d7;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }

    &__message {
      color: #0c0c3d;
      margin-bottom: 25px;
    }
  }
`;

export { staticStyles };
